import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import type { RootState } from '../../../store';
import { transformFilterData } from './utils';
import { AcgUserProfile, Member, MemberSearchResults } from '../../../services/acg/acg.types';

// Define a type for the slice state
export interface ACGMemberSearchState {
  filters: string[];
  search: {};
  pageNumb: number;
  publicProfileData?: AcgUserProfile;
  searchResults: MemberSearchResults;
}

// Define the initial state using that type
export const initialState: ACGMemberSearchState = {
  filters: [],
  search: {},
  pageNumb: 1,
  publicProfileData: undefined,
  searchResults: {
    members: [],
    totalNumberOfRows: 0,
  },
};

export const acgMemberSearchSlice: Slice = createSlice({
  name: 'acgMemberSearch',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateMembers: (
      state,
      action: PayloadAction<{ totalNumberOfRows?: number; members?: Member[] }>,
    ) => {
      state.searchResults.members = action.payload.members;
      state.searchResults.totalNumberOfRows = action.payload.totalNumberOfRows;
    },
    updateSearchFilters: (state, action: PayloadAction<any>) => {
      const transformedDataShape = transformFilterData(action.payload);
      state.filters = transformedDataShape || [];
    },
    removeFilter: (state, action: PayloadAction<string>) => {
      const filtersLeftOver = state.filters.filter((el: any) => el !== action.payload);
      state.filters = filtersLeftOver;
    },
    clearSearch: (state, action: PayloadAction<any[]>) => {
      state.members = action.payload;
      state.filters = action.payload;
    },
    memberSearchQuery: (state, action: PayloadAction<any>) => {
      state.search = action.payload;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumb = action.payload;
    },
    setPublicProfileData: (state, action: PayloadAction<AcgUserProfile>) => {
      state.publicProfileData = action.payload;
    },
  },
});

export const {
  updateMembers,
  updateSearchFilters,
  clearSearch,
  removeFilter,
  memberSearchQuery,
  setPageNumber,
  setPublicProfileData,
} = acgMemberSearchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getMembers = (state: RootState) => state.acgMemberSearch.searchResults.members;
export const getTotalResults = (state: RootState) =>
  state.acgMemberSearch.searchResults.totalNumberOfRows;
export const getPublicProfileData = (state: RootState) => state.acgMemberSearch.publicProfileData;
export const getPageNumber = (state: RootState) => state.acgMemberSearch.pageNumb;
export const getSearchQuery = (state: RootState) => state.acgMemberSearch.search;
export const getFilters = (state: RootState) => state.acgMemberSearch.filters;

export default acgMemberSearchSlice.reducer;
