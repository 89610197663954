import { Avatar, Image, HStack, Text, Flex, Button, potion, Skeleton } from '@companydotcom/potion';
import { useAssets } from '@companydotcom/providers';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

export const FaceCakeTopRail = () => {
  const { data: globalUser, isLoading } = useGetGlobalUserQuery();
  const assets = useAssets();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex
      className="facecake__top-rail"
      pl={['4', null, '9']}
      pr={['4', null, '0']}
      align="center"
      flexDir="row"
      w="full"
      justify="space-between"
      h="68px"
      shadow="md"
      bg="black"
    >
      <Image
        src={assets?.logo}
        alt="Logo"
        height={['5', '7']}
        onClick={() => navigate('/')}
        sx={{ cursor: 'pointer' }}
      />
      <HStack spacing={{ base: '4', md: '8', xl: '12' }} h="full">
        <potion.button
          display="inline-flex"
          aria-label="Go to help page"
          onClick={() => navigate('/facecake/help')}
          padding="0"
        >
          <FontAwesomeIcon
            boxSize={{ base: '4', md: '6' }}
            color="pink.500"
            icon={faCircleQuestion}
            _hover={{
              color: 'pink.400',
            }}
            _active={{
              color: 'pink.300',
            }}
          />
        </potion.button>
        <potion.button
          display="inline-flex"
          color="pink.500"
          _hover={{ color: 'pink.400' }}
          _active={{ color: 'pink.300' }}
          onClick={() => navigate('/facecake/profile')}
        >
          <HStack>
            <Avatar
              size="sm"
              src={globalUser?.avatar ?? undefined}
              _hover={{ bg: 'pink.400' }}
              _active={{ bg: 'pink.300' }}
            />
            {!globalUser?.firstName ? (
              <Skeleton w={112} height="6" />
            ) : (
              <Text
                fontWeight="700"
                display={{ base: 'none', md: 'block' }}
              >{`${globalUser?.firstName} ${globalUser?.lastName}`}</Text>
            )}
          </HStack>
        </potion.button>

        <Button
          display={{ base: 'none', md: 'inline-flex' }}
          borderRadius="none"
          variant="unstyled"
          fontSize={{ base: 'md', xl: 'lg' }}
          bg={globalUser?.account?.logo ? 'white' : 'pink.500'}
          _hover={{ bg: 'pink.400' }}
          _active={{ bg: 'pink.300' }}
          color="white"
          h="full"
          onClick={() => navigate('/facecake/upload-logo')}
          isLoading={isLoading}
        >
          {globalUser?.account?.logo ? (
            <Image maxH="8" w="full" px="10" src={globalUser?.account?.logo} />
          ) : (
            <Text px="10">{t('icommerce.topRail.uploadYourLogo')}</Text>
          )}
        </Button>
      </HStack>
    </Flex>
  );
};
