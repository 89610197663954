import Zuora from '../../../lib/zuora';

// import { SourceSvc } from '@companydotcom/services';
// import  getAccountPaymentDetails,
//  getPaymentMethodDataById,
// '../../../services/payment/old-api/payment-svc';
// const { getPaymentForm } = SourceSvc?.();

export const getPaymentMethod = (
  getAccountPaymentDetails: any,
  getPaymentMethodDataById: any,
  getPaymentForm: any,
  sourceId: any,
  zuoraAccountId: any,
  zuoraPaymentMethodId: any,
  setFormParams: any,
  setLoading: any,
  setPaymentMethodView: any,
  setError: any,
  setPaymentMethod: any,
  allowPartnerBilling: any,
) => {
  try {
    Zuora.initialize(async () => {
      const params = await getPaymentForm({
        sourceId,
        isLocal: window.origin.includes('localhost'),
      }).unwrap();
      setFormParams(params);
      if (allowPartnerBilling) {
        setPaymentMethodView('partnerBilling');
        setLoading(false);
        return;
      }
      if (zuoraPaymentMethodId && zuoraAccountId) {
        const paymentMethodRes = await getAccountPaymentDetails({ zuoraAccountId }).unwrap();
        const { creditCards } = paymentMethodRes;
        if (setPaymentMethod) {
          if (!creditCards) {
            throw new Error('No credit cards found!');
          }
          setPaymentMethod(
            creditCards?.find(({ defaultPaymentMethod }: any) => defaultPaymentMethod),
          );
        }
        setPaymentMethodView('paymentMethods');
      } else if (zuoraPaymentMethodId) {
        const paymentMethodRes = await getPaymentMethodDataById({ zuoraPaymentMethodId }).unwrap();
        const crudStylePaymentMethod = JSON.parse(paymentMethodRes);

        if (crudStylePaymentMethod) {
          const paymentMethod = {
            cardHolderInfo: {
              cardHolderName: crudStylePaymentMethod.CreditCardHolderName,
              zipCode: crudStylePaymentMethod.CreditCardPostalCode,
            },
            cardNumber: crudStylePaymentMethod.CreditCardMaskNumber,
            cardType: crudStylePaymentMethod.CreditCardType,
            defaultPaymentMethod: false,
            expirationMonth: crudStylePaymentMethod.CreditCardExpirationMonth,
            expirationYear: crudStylePaymentMethod.CreditCardExpirationYear,
            id: crudStylePaymentMethod.Id,
          };
          if (setPaymentMethod) {
            setPaymentMethod(paymentMethod);
          }
        }
        setPaymentMethodView('paymentMethods');
      }
      setLoading(false);
    });
  } catch (err) {
    console.error(err);
    setError('paywall.error');
    setLoading(false);
  }
};
