import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Center,
  Box,
  Heading,
  Flex,
  Stack,
  HStack,
  StackDivider,
  Text,
  Icon,
  IconButton,
  Button,
  Tag,
  useMediaQuery,
} from '@companydotcom/potion';
import { PageDivider, FontAwesomeIcon } from '@companydotcom/ui';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';
import { useTranslation, Trans } from 'react-i18next';
import { useAppSelector } from '../../../../hooks';
import type { CheckoutSharedProps } from '..';
import {
  getSelectedChapter,
  // getSelectedPackage,
  getOrderConfirmationData,
} from '../checkout-slice';
import { formatCheckoutPrice, useAcgCartStorage } from '../../shared';

export const CheckoutOrderPending: React.FC<CheckoutSharedProps> = props => {
  const { t } = useTranslation();
  const { onOrderConfirmationComplete } = props;
  const chapter = useAppSelector(getSelectedChapter);
  // const chapterPackage = useAppSelector(getSelectedPackage);

  // REFACTOR/REMOVE AFTER RHYTHM WIRE-UP
  // const orderConfirmationData = useAppSelector(getOrderConfirmationData)?.payment;

  const rhythmOrderConfirmationData = useAppSelector(getOrderConfirmationData);
  const { clearLocalCart, getLocalCart } = useAcgCartStorage();
  const cart = getLocalCart();

  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const webCode = searchParams.get('WebCode')?.toLowerCase();

  const goToDashboard = () => {
    onOrderConfirmationComplete?.();
    clearLocalCart();
    if (search) {
      if (!search.includes('confirmation')) {
        navigate({ pathname: '/redirect', search });
      }
      navigate('/', { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  };

  const goToRedirect = () => {
    onOrderConfirmationComplete?.();
    clearLocalCart();
    if (
      webCode === 'eventregfees' ||
      webCode === 'acgmembershipdirectory' ||
      webCode === 'acgmemberdirectory'
    ) {
      navigate({ pathname: '/redirect', search });
    } else {
      navigate('/', { replace: true });
    }
  };

  useEffect(() => {
    const params = { confirmation: 'membership-pending-approval' };
    if (search && params) {
      setSearchParams({ ...Object.fromEntries([...searchParams]), ...params });
    } else if (params) {
      setSearchParams(params);
    }
  }, [onOrderConfirmationComplete, search, searchParams, setSearchParams]);

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@media print {
      #printPageButton, #goToMyACGButton, #accessYourMembership, #headingBorder {
        display: none;
      }
      #order-content, #thankyou, #pendingInfoContainer {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
      }
      #orderConfirmationHeading {
        margin-top: 2rem;
        font-size: 40px;
      }
      #thankyou {
        font-size: 16px;
      }
      #productName {
        flex-direction: row;
      }

      #totalContainer {
        align-self: flex-end;
      }

      #subtotal, #total {
        flex-direction: row;
        align-items: flex-end;
      }
    }`,
  });

  const getPageMargins = () => {
    return `@page { margin: 4rem !important; }`;
  };

  return (
    <Center
      className="checkout__checkoutOrderPendingStep"
      flexDir="column"
      bg={['transparent', 'white']}
      px={[0, 4]}
    >
      <Box width="full" maxW={908} ref={componentRef}>
        <style>{getPageMargins()}</style>
        {/**Header and Bar */}
        <Box width="full" textAlign={['center', 'left']}>
          <Stack direction={['column-reverse', 'row']} justify="space-between">
            <Heading
              id="orderConfirmationHeading"
              size={isMobile ? 'hs-md' : 'hs-2xl'}
              textAlign={['center', 'left']}
            >
              {t(`acg.checkout.orderPendingStep.orderPending`)}
            </Heading>
            <IconButton
              aria-label="print"
              backgroundColor="white"
              display={['none', 'inherit']}
              icon={<Icon as={FontAwesomeIcon} icon={faPrint} boxSize={6} color="blue.500" />}
              onClick={handlePrint}
              id="printPageButton"
            />
          </Stack>
          <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} id="headingBorder" />
        </Box>
        <Center my={[8, 12]} textAlign="center" flexDir="column">
          <Box pb={6}>
            <Heading size="hs-xl" id="thankyou" color="#717174b5">
              {t(`acg.checkout.orderPendingStep.thankYou`, {
                chapter: `${
                  chapter?.chapterName || cart?.items?.memberships?.[0]?.lineItems?.[0]?.name
                } Chapter`,
              })}
            </Heading>
          </Box>
          <Text id="accessYourMembership" textStyle="lg" fontWeight="semibold">
            <Trans i18nKey="acg.checkout.orderPendingStep.goToDashboard">
              You can go to your
              <Button
                fontWeight="semibold"
                size="lg"
                whiteSpace="normal"
                variant="link"
                onClick={goToDashboard}
              >
                My ACG Dashboard
              </Button>
              and access your current features. Once your membership has been approved, you can
              access all features as a chapter member.
            </Trans>
          </Text>
        </Center>
        <Flex mx={[5, 3]} my={6} direction="column">
          <Stack divider={<StackDivider />} spacing={6} id="pendingInfoContainer">
            <HStack justify="space-between" alignItems={['baseline', 'center']}>
              <Heading size="hs-sm">{t(`acg.checkout.orderPendingStep.reqItem`)}</Heading>
              <Heading size="hs-sm">{t(`acg.checkout.orderPendingStep.price`)}</Heading>
            </HStack>
            <Stack
              direction={['column', 'row']}
              textAlign={['center', 'left']}
              justify="space-between"
              spacing={[4, 10]}
              maxW="full"
              id="productName"
            >
              <Stack direction={['column', 'column', 'row']} alignItems={['left', null, 'center']}>
                <Text textStyle="lg">{cart?.items?.memberships?.[0]?.productName}</Text>
                <Tag
                  alignSelf="center"
                  backgroundColor="red.500"
                  color="white"
                  borderRadius="none"
                  size="sm"
                >
                  {t(`acg.checkout.orderPendingStep.pendingApproval`)}
                </Tag>
              </Stack>
              <Text fontWeight="bold" textStyle="lg">{`${
                rhythmOrderConfirmationData?.currencySymbol
              }${formatCheckoutPrice(rhythmOrderConfirmationData?.amountTotal)}`}</Text>
            </Stack>
            <Stack
              divider={<StackDivider />}
              alignSelf={['center', 'flex-end']}
              spacing={6}
              width={[null, null, 72]}
              id="totalContainer"
            >
              <Stack
                justify="space-between"
                direction={['column', 'row']}
                alignItems={['center']}
                id="subtotal"
              >
                <Text>{t(`acg.checkout.orderPendingStep.pendingSubtotal`)}</Text>
                <Text fontWeight="bold">{`${
                  rhythmOrderConfirmationData?.currencySymbol
                }${formatCheckoutPrice(rhythmOrderConfirmationData?.amountTotal)}`}</Text>
              </Stack>
              <Stack
                justify="space-between"
                direction={['column', 'row']}
                textAlign={['center']}
                alignItems={['center']}
                id="total"
              >
                <Heading size="hs-lg" width="min">
                  {t(`acg.checkout.orderPendingStep.pendingTotal`)}
                </Heading>
                <Heading size="hs-lg">{`${
                  rhythmOrderConfirmationData?.currencySymbol
                }${formatCheckoutPrice(rhythmOrderConfirmationData?.amountTotal)}`}</Heading>
              </Stack>
            </Stack>
            <Center mt={[6, 8]} textAlign="center" flexDir="column" width="full">
              {/* <Stack spacing={8}> */}
              {/* // TODO: For monthly installments wireup */}
              {/* <Text>
                  <Trans
                    i18nKey="acg.checkout.orderPendingStep.installmentPlan"
                    values={{ price: '$49.59', finalMonthPrice: '$49.51', total: '$595.00' }}
                  >
                    You have chosen 12 monthly installments. You will be charged
                    <Text fontWeight="bold">$49.59</Text>
                    the next 11 months and <Text fontWeight="bold">$49.51</Text> your final month.
                    You will be charged
                    <Text fontWeight="bold">$595.00</Text> in total.
                  </Trans>
                </Text> */}
              {/* </Stack> */}
              <Center id="goToMyACGButton" pt={4} width="full" mx="auto">
                <Button
                  size="lg"
                  onClick={
                    webCode === 'eventregfees' ||
                    webCode === 'acgmembershipdirectory' ||
                    webCode === 'acgmemberdirectory'
                      ? goToRedirect
                      : goToDashboard
                  }
                >
                  {webCode === 'eventregfees' ? 'GO TO EVENT REGISTRATION' : 'GO TO MY ACG'}
                </Button>
              </Center>
            </Center>
          </Stack>
        </Flex>
      </Box>
    </Center>
  );
};
