import {
  Box,
  Text,
  Icon,
  IconButton,
  useMediaQuery,
  Divider,
  Heading,
  Button,
  Center,
} from '@companydotcom/potion';
import { PageDivider } from '@companydotcom/ui';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { CcPaymentInfo } from '../../facecake-checkout/steps/payment-information';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  CheckoutAlertContent,
  ItemAndPrice,
  ItemRow,
  OrderConfirmationContentContainer,
  OrderConfirmationHeaderContainer,
  OrderInformationContainer,
  OrderInformationContent,
  OrderInformationHeader,
  OrderSubTotal,
  OrderTotal,
  OrderTotalContainer,
} from '../../../../components/layouts/checkout-layout/checkout-layout';
import { useLocalCartStorage } from '../../../../hooks/use-local-cart-storage';

export const CheckoutOrderConfirmation: React.FC<any> = ({ orderConfirmationData }) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const navigate = useNavigate();
  const { getLocalCart, clearLocalCart } = useLocalCartStorage();
  const selectedPlan = getLocalCart()?.selectedPlan;

  const goToDashboard = () => {
    clearLocalCart();
    navigate('/', { replace: true });
  };

  return (
    <Center
      className="checkout__checkoutLayoutContainer"
      flexDirection="column"
      bg={['transparent', 'white']}
      px={[0, 4]}
      layerStyle={{ base: undefined, sm: 'formContainer' }}
      py={[4, 12]}
    >
      <Box width="full" maxW={908}>
        <OrderConfirmationHeaderContainer>
          <Box className="checkout__checkoutHeader" width="full" textAlign="left">
            <Heading size={isMobile ? 'hs-xl' : 'hs-2xl'}>Order Confirmation</Heading>
            <PageDivider mt={[2, 6]} mx="inherit" />
          </Box>
          <IconButton
            bgColor="white"
            display="inherit"
            aria-label="print"
            icon={
              <Icon
                as={FontAwesomeIcon}
                icon={faPrint}
                className="fa-light fa-print"
                boxSize={6}
                color="pink.500"
              />
            }
            onClick={() => window.print()}
            id="printPageButton"
          />
        </OrderConfirmationHeaderContainer>
        <CheckoutAlertContent>
          <Box pb={6} width="full">
            <Text fontSize="3xl" id="thankyou" fontWeight="700">
              {`Thank you for your order, ${globalUser?.firstName} ${globalUser?.lastName}!`}
            </Text>
            <Text
              fontSize={['lg', '3xl']}
              id="confirmationNumber"
              fontWeight="700"
              whiteSpace={[null, null, null, 'nowrap']}
            >
              {`Your Confirmation Number is ${orderConfirmationData.orderConfirmation.subscription.id}.`}
            </Text>
          </Box>
          <Button id="goToDashboard" size="lg" onClick={goToDashboard} width={['full', 'auto']}>
            GO TO DASHBOARD
          </Button>
        </CheckoutAlertContent>
        <OrderConfirmationContentContainer>
          <OrderInformationContainer>
            <OrderInformationHeader fontWeight="500" opacity=".71" />
            <OrderInformationContent>
              {isMobile ? null : <ItemAndPrice />}
              <ItemRow>
                <Text textStyle="lg" fontWeight="300">
                  {selectedPlan?.name}
                </Text>
                <Text textStyle="lg" fontWeight="700">
                  {Number(selectedPlan?.price).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Text>
              </ItemRow>
              <OrderTotalContainer minWidth="292px">
                {isMobile ? null : (
                  <>
                    <OrderSubTotal>
                      <Text textStyle="lg" fontWeight="bold">
                        {Number(selectedPlan?.price).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </Text>
                    </OrderSubTotal>
                    <Divider my={[4, 6]} />
                  </>
                )}
                <OrderTotal>
                  <Heading size="hs-lg" fontWeight="700">
                    {Number(selectedPlan?.price).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </Heading>
                </OrderTotal>
              </OrderTotalContainer>
            </OrderInformationContent>
            <Text textStyle={['lg', 'xs']} fontWeight="400" textAlign={['left', 'right']} mt={1}>
              {selectedPlan?.detail}
            </Text>
          </OrderInformationContainer>
          {/* <Box id="paymentInfoContainer">
            <CcPaymentInfo
              {...{ goToDashboard, orderConfirmationData }}
              fontWeight="500"
              opacity=".71"
            />
          </Box> */}
        </OrderConfirmationContentContainer>
      </Box>
    </Center>
  );
};
