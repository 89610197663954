const productQuery = {
  getAllProducts: () => `query getAllProducts {
      getAllProducts () {
        productId
        description
        isPackage
        isLeadGen
        name
        slug
        purchaseRelUrl
        ssoUrl
        packageProducts {
          productId
        }
        ratePlans {
          eligibleProducts
          name
          description
          features
          includedInMembership
          zuoraProductRatePlanId
          price
        }
      }
    }`,
  getUserProduct:
    () => `query getUserProducts ($userId: String!, $productId: String, $locale: String) {
    getUserProducts (userId: $userId, productId: $productId, locale: $locale) {
      accountId
      zuoraPaymentMethodId
      zuoraAccountId
      zuoraSubscriptionId
      zuoraSubscriptionNumber
      monthlyPrice
      products {
        productId
        description
        isPackage
        isLeadGen
        isActive
        name
        slug
        ratePlans {
          description
          includedInMembership
          isActive
          name
          price
          features
          ratePlanId
          ratePlanChargeId
          tiers {
            description
            qty
            price
            isDisabled
            isActive
            isEnterprise
          }
        }
        paywallContentOptions {
          key
          value
        }
      }
    }
  }`,
  getUserProducts: () => `query getUserProducts ($userId: String!, $locale: String) {
    getUserProducts (userId: $userId, locale: $locale) {
      accountId
      zuoraPaymentMethodId
      zuoraAccountId
      zuoraSubscriptionId
      zuoraSubscriptionNumber
      monthlyPrice
      products {
        productId
        description
        isPackage
        isLeadGen
        isActive
        name
        slug
        ratePlans {
          description
          includedInMembership
          isActive
          name
          price
          features
          ratePlanId
          ratePlanChargeId
          tiers {
            description
            qty
            price
            isDisabled
            isActive
            isEnterprise
          }
        }
        paywallContentOptions {
          key
          value
        }
      }
    }
  }`,
  getLiveAgentAvailability: () => `query getLiveAgentAvailability{
    getLiveAgentAvailability
  }`,
  getProduct: (productId: string, locale: string) => `query getProduct {
    getProduct(productId: "${productId}" locale: "${locale}") {
      productId
      name
      description
      isPackage
      chargeTypeLabel
      maxPurchasableTier
      ratePlans
      {
        description
        includedInMembership
        isActive
        name
        price
        zuoraProductRatePlanId
        zuoraProductRatePlanChargeId
        partnerRatePlanId
        enterpriseQty
        dateCreatedNumber
        dateUpdatedNumber
        tiers {
          startingUnit
          endingUnit
          price
          partnerTierId
          chargeModel
        }
      }
    }
  }`,
  getEligibleChildProducts: (userId: string, sourceId: string, locale: string) =>
    `query getEligibleChildProducts {
      getEligibleChildProducts(userId: "${userId}" sourceId: "${sourceId}" locale: "${locale}") {
        products {
          productId
          description
          isActive
          name
          slug
          ratePlans {
            description
            includedInMembership
            isActive
            name
            price
            features
            ratePlanId
            ratePlanChargeId
            tiers {
              description
              qty
              price
              isDisabled
              isActive
              isEnterprise
            }
          }
        }
      }
    }`,
};

export default productQuery;
