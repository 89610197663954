var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RegionDropdown } from 'react-country-region-selector';
import { Controller } from 'react-hook-form';
import { FormControl, FormLabel, useMultiStyleConfig, FormHelperText, SelectIcon, potion, Skeleton, FormErrorMessage, } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
export const SelectRegionField = (props) => {
    const { country, control, name, label, helperText, formControlStyles, isLoading } = props, rest = __rest(props, ["country", "control", "name", "label", "helperText", "formControlStyles", "isLoading"]);
    const { t } = useTranslation();
    const styles = useMultiStyleConfig('Select', props);
    return (_jsx(Controller, { control: control, name: name, render: ({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error }, }) => {
            var _a;
            // @ts-ignore
            const errorStyles = error || invalid ? (_a = styles === null || styles === void 0 ? void 0 : styles.field) === null || _a === void 0 ? void 0 : _a._invalid : {};
            return (_jsxs(FormControl, Object.assign({ isInvalid: invalid, id: name, sx: Object.assign(Object.assign({}, formControlStyles), { '.select-region': Object.assign({}, errorStyles), select: Object.assign({}, styles.field) }) }, { children: [_jsx(FormLabel, Object.assign({ htmlFor: name }, { children: label !== null && label !== void 0 ? label : '' }), void 0), _jsx(Skeleton, Object.assign({ isLoaded: !isLoading }, { children: _jsxs(potion.div, Object.assign({ className: "potion-select__wrapper", __css: {
                                position: 'relative',
                            } }, { children: [_jsx(RegionDropdown, Object.assign({}, rest, { country: country, name: name, ref: ref, onChange: onChange, onBlur: onBlur, value: value, blankOptionLabel: "", defaultOptionLabel: "", classes: "select-region", valueType: "short" }), void 0), _jsx(SelectIcon, { __css: Object.assign({}, styles.icon) }, void 0)] }), void 0) }), void 0), helperText && !error && _jsx(FormHelperText, { children: helperText !== null && helperText !== void 0 ? helperText : '' }, void 0), error && _jsx(FormErrorMessage, { children: t(error === null || error === void 0 ? void 0 : error.message) }, void 0)] }), void 0));
        } }, void 0));
};
