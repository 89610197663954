import React from 'react';
import { Text, Box, Button, Heading, Center } from '@companydotcom/potion';
import { SubscriptionTier } from '@companydotcom/types';
import { RSelect } from '@companydotcom/ui';
import { companyConstants } from '@companydotcom/helpers';
import { useMitt } from '@companydotcom/providers';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { calculateEstimatedRatePlanPrice } from '../../../utils/helpers/purchasing-helpers';
import { useUserProfileContext } from '../../../providers/user-profile-context';
import { RatePlanWarningMessages } from '../../../components/elements/rate-plan-options/rate-plan-warning-messages';

interface AddAdditonalEmailUsersStepProps {
  goToStep?: (arg: string | number) => void;
}

export const AddAdditonalEmailUsersStep = ({ goToStep }: AddAdditonalEmailUsersStepProps) => {
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();
  const { emitter } = useMitt();
  const { t } = useTranslation();
  const emailRatePlan = userProfileState?.eligibleChildProducts
    ?.find?.(product => product.slug === 'email_rackspace')
    ?.ratePlans?.find(plan => plan?.productId);
  const hasTiers = emailRatePlan?.tiers && emailRatePlan?.tiers.length > 0;

  const onSubmit = () => {
    const seatQty =
      userProfileState?.selectedTier?.qty &&
      emailRatePlan?.tiers?.find(t => t?.isActive)?.qty &&
      userProfileState?.selectedTier?.qty -
        (emailRatePlan?.tiers?.find?.(t => t?.isActive)?.qty ?? 0);

    const eventData = {
      type: 'openPaywall',
      metadata: {
        eventSource: 'addNewUserFlow',
        products: [emailRatePlan],
        selectedTiers: [userProfileState?.selectedTier],
        // function to run on successful purchase of email seats
        onSuccessHandler: () => {
          dispatchUserProfileState?.({
            type: 'INCREASE_AVAILABLE_SEAT_COUNT',
            payload: { qty: seatQty ?? 0 },
          });

          goToStep?.(userProfileState?.onPaymentSuccessStep || 'new-user-basic-information');
        },
      },
    };
    emitter.emit(companyConstants.platformEvents.openPaywall, eventData);
  };
  return (
    <Center bg="white" flexDirection="column" pt={14} pb={16} px={4}>
      <Box as="form" maxWidth={[310, 455]} sx={{ textAlign: 'center' }}>
        <Heading size="hs-2xl">{t('miop.userProfile.addNewUser.addAddlUsers')}</Heading>
        <Text textStyle="2xl" mt={4}>
          {t('miop.userProfile.addNewUser.emailPaywall.header')}
        </Text>
        <Text textStyle="md" mt={2}>
          {t('miop.userProfile.addNewUser.emailPaywall.subheader')}
        </Text>
        <Text mt={8} mb={6} textStyle="md" color="pricing">
          {!emailRatePlan || isEmpty(userProfileState?.selectedTier)
            ? `+ $0.00/${t('miop.userProfile.addNewUser.emailPaywall.month')}`
            : `+$${calculateEstimatedRatePlanPrice(
                emailRatePlan,
                userProfileState?.selectedTier as SubscriptionTier | undefined,
              )?.toFixed(2)}/${t('miop.userProfile.addNewUser.emailPaywall.month')}`}
        </Text>
        {hasTiers && emailRatePlan?.tiers?.length && (
          <>
            <RSelect
              onChange={option => {
                if (option) {
                  dispatchUserProfileState?.({
                    type: 'UPDATE_STATE',
                    payload: { selectedTier: option },
                  });
                }
              }}
              placeholder={t('miop.userProfile.addNewUser.emailPaywall.placeholder')}
              label={t('miop.userProfile.addNewUser.emailPaywall.label')}
              // @ts-ignore
              options={emailRatePlan?.tiers! ?? []}
              getOptionLabel={(option: SubscriptionTier) => {
                return `${option.description.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')}${
                  option.isActive
                    ? ` (${t('miop.userProfile.addNewUser.emailPaywall.currentValueHighlight')})`
                    : ''
                }`;
              }}
              getOptionValue={option => option?.description ?? ''}
              isOptionDisabled={option => option?.isDisabled ?? false}
            />
            <RatePlanWarningMessages
              productName=""
              isEnterprise={userProfileState?.selectedTier?.isEnterprise}
            />
          </>
        )}
      </Box>

      <Box mt={10} sx={{ textAlign: 'center' }}>
        <Button
          size="lg"
          onClick={onSubmit}
          isDisabled={
            isEmpty(userProfileState?.selectedTier) ||
            (userProfileState?.selectedTier?.isEnterprise as boolean) ||
            userProfileState.selectedTier?.isActive === true
          }
        >
          {t('common.buttons.next')}
        </Button>
      </Box>
    </Center>
  );
};
