import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
export const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const switchLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    return (_jsx(_Fragment, { children: _jsx(IconButton, { onClick: () => switchLanguage(i18n.language === 'en' ? 'es-mx' : 'en'), icon: _jsx(FontAwesomeIcon, { icon: faLanguage }, void 0), "aria-label": "Open Dev Tools", colorScheme: "purple", cursor: "pointer", sx: { borderRadius: 'none' } }, void 0) }, void 0));
};
