import React from 'react';
import { platformHelpers } from '@companydotcom/helpers';
import { HStack, Box, Button, Text } from '@companydotcom/potion';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { CCIconSwitch } from '../../acg-auto-renew/utils/creditCardhelper';
import { FetchedPaymentProps } from './saved-payment-payment-tile';

interface SavedPaymentTileHeaderProps extends FetchedPaymentProps {
  isMobile?: boolean;
  onToggle?: () => void;
}

export const SavedPaymentTileHeader = ({
  cardNumber,
  cardType,
  isMobile,
  onToggle,
  paymentNickName,
  accountNumber,
}: Partial<SavedPaymentTileHeaderProps>) => {
  const { t } = useTranslation();
  const capitalNickName = platformHelpers.capitalizeFirstLetter(paymentNickName);

  if (cardNumber) {
    return (
      <HStack width="full" justifyContent="flex-start">
        <CCIconSwitch {...{ cardType }} />
        {isMobile ? (
          <Box>
            <Text fontWeight="bold">
              {t('acg.savedPaymentMethods.tiles.headings.ccHeadingMobile', { cardNumber })}
            </Text>
            <Button
              minWidth={4}
              cursor="pointer"
              variant="link"
              onClick={onToggle}
              position="absolute"
              top={2}
              right={2}
            >
              <FontAwesomeIcon icon={faTrash} boxSize={4} />
            </Button>
          </Box>
        ) : (
          <Text fontWeight="bold">
            {t('acg.savedPaymentMethods.tiles.headings.desktopHeading', {
              paymentNickName: capitalNickName,
              number: cardNumber,
            })}
          </Text>
        )}
      </HStack>
    );
  }
  return (
    <HStack width="full" justifyContent="flex-start">
      {isMobile ? (
        <Box>
          <Text fontWeight="bold">
            {t('acg.savedPaymentMethods.tiles.headings.achHeadingMobile', { accountNumber })}
          </Text>
          <Button
            minWidth={4}
            cursor="pointer"
            variant="link"
            position="absolute"
            top={2}
            right={2}
            onClick={onToggle}
          >
            <FontAwesomeIcon icon={faTrash} boxSize={4} />
          </Button>
        </Box>
      ) : (
        <Text fontWeight="bold">
          {t('acg.savedPaymentMethods.tiles.headings.desktopHeading', {
            paymentNickName: capitalNickName,
            number: accountNumber,
          })}
        </Text>
      )}
    </HStack>
  );
};
