import React from 'react';
import {
  StylesProvider,
  useMultiStyleConfig,
  potion,
  SystemStyleObject,
  useStyles,
  cx,
  Icon,
  __DEV__,
  ImageProps,
  forwardRef,
  Image,
  Box,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';

export interface TileProps {
  children: React.ReactNode;
  /**
   * Whether the tile should display a locked icon or not.
   */
  isLocked?: boolean;
  /**
   * Changes the default icon to one of your choice from the FontAwesome library.
   * @example iconName="faDollarSign"
   */
  iconName?: string;
}

export const Tile = forwardRef<TileProps, 'div'>((props, ref) => {
  const { className, children, ...rest } = props;

  const styles = useMultiStyleConfig('Tile', rest);
  const _className = cx('potion-tile', className);

  const rootStyles: SystemStyleObject = { position: 'relative', ...styles.tile };

  return (
    <potion.div className={_className} __css={rootStyles} ref={ref}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </potion.div>
  );
});

export const TileContent: React.FC<any> = props => {
  const { className, children, ...rest } = props;

  const styles = useStyles();
  const _className = cx('potion-tile__content', className);

  const contentStyles: SystemStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    outline: 0,
    ...styles.content,
  };
  return (
    <potion.div className={_className} {...rest} __css={contentStyles}>
      {children}
    </potion.div>
  );
};

export const TileHeader: React.FC<any> = props => {
  const styles = useStyles();

  const headerStyles: SystemStyleObject = {
    flex: 0,
    ...styles.header,
  };
  return <potion.header {...props} __css={headerStyles} />;
};

if (__DEV__) {
  TileHeader.displayName = 'TileHeader';
}

export const TileBody: React.FC<any> = props => {
  const { className, ...rest } = props;

  const _className = cx('potion-tile__body', className);
  const styles = useStyles();

  const bodyStyles: SystemStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...styles.body,
  };

  return <potion.div className={_className} {...rest} __css={bodyStyles} />;
};

if (__DEV__) {
  TileBody.displayName = 'TileBody';
}

export const TileFooter: React.FC<any> = props => {
  const { className, ...rest } = props;
  const _className = cx('potion-tile__footer', className);

  const styles = useStyles();
  const footerStyles: SystemStyleObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...styles.footer,
  };

  return <potion.footer {...rest} __css={footerStyles} className={_className} />;
};

if (__DEV__) {
  TileFooter.displayName = 'TileFooter';
}

export const TileLock: React.FC<any> = props => {
  const { className, ...rest } = props;
  const _className = cx('potion-tile__lock', className);
  const styles = useStyles();

  const lockWrapperStyles: SystemStyleObject = {
    position: 'absolute',
    width: styles?.lock?.['width'] ?? '56px',
    height: styles?.lock?.['height'] ?? '56px',
    borderTopLeftRadius: styles?.tile?.['borderRadius'] ?? 'md',
    overflow: 'hidden',
  };

  const lockStyles: SystemStyleObject = {
    position: 'absolute',
    borderStyle: 'solid',
    ...styles.lock,
  };

  const lockIconStyles: SystemStyleObject = {
    position: 'absolute',
    top: '-48px',
    left: 1,
    color: 'white',
  };

  return (
    <potion.div __css={lockWrapperStyles} className={_className}>
      <potion.div {...rest} __css={lockStyles}>
        <Icon as={FontAwesomeIcon} icon={faDollarSign} sx={lockIconStyles} boxSize={5} />
      </potion.div>
    </potion.div>
  );
};

if (__DEV__) {
  TileLock.displayName = 'TileLock';
}

export const TileProductIcon: React.FC<ImageProps> = props => {
  const { className, ...rest } = props;
  const styles = useStyles();
  const _className = cx('potion-tile__productIcon', className);

  const productIconStyles: SystemStyleObject = {
    position: 'absolute',
    ...styles.productIcon,
  };
  return (
    // @ts-ignore
    <Image
      {...rest}
      className={_className}
      fallback={<Box sx={productIconStyles} />}
      __css={productIconStyles}
    />
  );
};

if (__DEV__) {
  TileProductIcon.displayName = 'TileProductIcon';
}
