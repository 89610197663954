var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useRef, useState, useCallback } from 'react';
import { Text, Input, InputGroup, HStack, Image, InputLeftElement, Box, FormControl, FormHelperText, FormLabel, potion, Popover, PopoverTrigger, PopoverContent, PopoverBody, Divider, Flex, } from '@companydotcom/potion';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
import { TranslationErrorMessage } from './translation-error-message';
import { FontAwesomeIcon } from '../font-awesome-icon';
//Example Netherlands phone number +3197010299999
export const PhoneNumberInputField = (_a) => {
    var _b;
    var { name, control, size, errors, formControlStyles, helperText, label, onCountryChange, defaultCountry, country, defaultValue, placeholder, withCountryCallingCode, addInternationalOption, international } = _a, rest = __rest(_a, ["name", "control", "size", "errors", "formControlStyles", "helperText", "label", "onCountryChange", "defaultCountry", "country", "defaultValue", "placeholder", "withCountryCallingCode", "addInternationalOption", "international"]);
    return (_jsxs(FormControl, Object.assign({ id: name, isInvalid: errors && !!(errors === null || errors === void 0 ? void 0 : errors[name]), sx: formControlStyles }, { children: [_jsx(FormLabel, Object.assign({ htmlFor: name }, { children: label !== null && label !== void 0 ? label : '' }), void 0), _jsx(InputGroup, Object.assign({ size: size !== null && size !== void 0 ? size : 'md' }, { children: _jsx(PhoneInputWithCountry, Object.assign({ name: name, control: control, defaultValue: defaultValue, defaultCountry: defaultCountry !== null && defaultCountry !== void 0 ? defaultCountry : 'US', country: country !== null && country !== void 0 ? country : 'US', international: international !== null && international !== void 0 ? international : true, withCountryCallingCode: withCountryCallingCode !== null && withCountryCallingCode !== void 0 ? withCountryCallingCode : true, inputComponent: Input, countrySelectComponent: CountrySelect, onCountryChange: onCountryChange, flagComponent: FlagIcon, countryCallingCodeEditable: false, placeholder: placeholder || `+${getCountryCallingCode(country !== null && country !== void 0 ? country : 'US')}` || '', addInternationalOption: addInternationalOption !== null && addInternationalOption !== void 0 ? addInternationalOption : false, style: { width: '100%' }, pl: "4rem" }, rest), void 0) }), void 0), helperText && !(errors === null || errors === void 0 ? void 0 : errors[name]) && _jsx(FormHelperText, { children: helperText !== null && helperText !== void 0 ? helperText : '' }, void 0), errors && (_jsx(TranslationErrorMessage, Object.assign({ errors: errors, name: name }, { children: errors && (errors === null || errors === void 0 ? void 0 : errors[name]) && ((_b = errors === null || errors === void 0 ? void 0 : errors[name]) === null || _b === void 0 ? void 0 : _b.message) }), void 0))] }), void 0));
};
const CountrySelect = ({ options, value, onChange, iconComponent: Flag }) => {
    const [filteredList, setFilteredList] = useState(options);
    const handleSearch = (event) => {
        const evalue = event.target.value.toLowerCase();
        const result = (options === null || options === void 0 ? void 0 : options.filter(item => {
            var _a;
            return (_a = item === null || item === void 0 ? void 0 : item.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(evalue);
        })) || [];
        setFilteredList(result);
    };
    const initialFocusRef = useRef(null);
    return (_jsx(Popover, Object.assign({ initialFocusRef: initialFocusRef, placement: "bottom-start" }, { children: ({ isOpen }) => (_jsxs(_Fragment, { children: [_jsx(InputLeftElement, { height: "full", children: _jsx(PopoverTrigger, { children: _jsx(potion.button, Object.assign({ type: "button", pl: "4", h: "full" }, { children: _jsxs(HStack, Object.assign({ spacing: 1 }, { children: [_jsx(Flag, { label: value, country: value }, void 0), _jsx(FontAwesomeIcon, { icon: isOpen ? faAngleUp : faAngleDown }, void 0)] }), void 0) }), void 0) }, void 0) }, void 0), _jsx(PopoverContent, { children: _jsxs(PopoverBody, Object.assign({ p: "0" }, { children: [_jsx(Box, Object.assign({ py: "2", px: "4" }, { children: _jsxs(InputGroup, Object.assign({ size: "sm" }, { children: [_jsx(InputLeftElement, { pointerEvents: "none", children: _jsx(FontAwesomeIcon, { icon: faSearch }, void 0) }, void 0), _jsx(Input, { ref: initialFocusRef, type: "text", size: "sm", name: "search-input", autoComplete: "new-password", pl: "2rem", onChange: event => handleSearch(event) }, void 0)] }), void 0) }), void 0), _jsx(Divider, {}, void 0), _jsx(Flex, Object.assign({ flexDir: "column", align: "flex-start", textAlign: "left", justify: "flex-start", pt: "2", pb: "3", maxH: "xs", width: "full", height: "auto", overflowY: "scroll" }, { children: filteredList === null || filteredList === void 0 ? void 0 : filteredList.map((x, i) => (_jsx(MenuItemWithFlag, Object.assign({ onChange: onChange, iconComponent: Flag }, x), i))) }), void 0)] }), void 0) }, void 0)] }, void 0)) }), void 0));
};
const MenuItemWithFlag = memo(({ onChange, iconComponent: Flag, value, label }) => {
    return (_jsx(potion.button, Object.assign({ w: "full", _hover: { bg: 'gray.100' }, type: "button", onClick: () => onChange === null || onChange === void 0 ? void 0 : onChange(value) }, { children: _jsxs(HStack, Object.assign({ py: "2", px: "4", value: value }, { children: [_jsx(Flag, { label: label, country: value }, void 0), _jsxs(Text, Object.assign({ textStyle: "sm" }, { children: [label, value && (_jsx(potion.span, Object.assign({ color: "blackAlpha.500" }, { children: ` +${getCountryCallingCode(value)}` }), void 0))] }), void 0)] }), void 0) }), void 0));
});
const FlagIcon = ({ country, flagUrl, countryName }) => {
    return _jsx(Image, { label: countryName, src: flagUrl === null || flagUrl === void 0 ? void 0 : flagUrl.replace('{XX}', country), boxSize: "5" }, void 0);
};
/**
 * Exports a function to be fed into the PhoneNumberInput components onCountryChange prop
 * @param - optional defaultCountry.  Default is US
 * @returns - The currently selected country code
 */
export const useGetCurrentlySelectedCountry = (defaultCountry) => {
    const [country, setCountry] = useState(defaultCountry !== null && defaultCountry !== void 0 ? defaultCountry : 'US');
    const onCountryChange = useCallback((e) => {
        setCountry(e);
    }, []);
    return {
        country,
        onCountryChange,
    };
};
/**
 * If phone number starts with a +, its international (non-US)
 * Otherwise, its US.  We only ever store numbers with a plus sign or without
 * @param phoneno
 * @returns
 */
export const isPhoneNumberUS = (phoneno) => {
    if (phoneno.charAt(0) === '(' || phoneno.charAt(0) !== '+' || phoneno.charAt(0) === '1') {
        return true;
    }
    return false;
};
/**
 * This function ensures the correctly formatted phone number is returned for storage
 * @param phonenum
 * @param country
 */
export const getFormattedPhoneNumber = (phonenum, country) => {
    return country === 'US'
        ? parsePhoneNumber(phonenum, country).formatNational()
        : parsePhoneNumber(phonenum, country).formatInternational();
};
