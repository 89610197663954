import { Box, Heading, useBreakpointValue } from '@companydotcom/potion';
import { PageDivider } from '@companydotcom/ui';

export const EventsHeader = ({ text, ...rest }: any) => {
  const headingSize = useBreakpointValue({ base: 'md', sm: 'xl' });

  return (
    <Box mt={[2, 0]} mb={[3, 0]} width="full" textAlign={['center', 'left']} {...rest}>
      <Heading size={headingSize}>{text}</Heading>
      <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
    </Box>
  );
};
