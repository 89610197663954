import _get from 'lodash/get';
import { companyConstants, companyHelpers } from '@companydotcom/helpers';
// import { getUserCuis, updateUserCuis } from '../../../services/cui/old-api/cui-svc';

// TODO: make this work when no data exists
export const mapLandbotDataToQueryString = (data: any, object: any) => {
  if (data) {
    const chunks = data.reduce((current: any, item: any) => {
      const key = item.replace(/\./g, '_').toLowerCase();
      current[key] = _get(object, item);
      return current;
    }, {});

    return Object.keys(chunks)
      .map(key => `${key}=${chunks[key]}`)
      .join('&');
  }

  return null;
};

export const invokeDefaultTileAction = (user: any, tile: any, stateCurrent: any, emitter: any) => {
  stateCurrent = stateCurrent ?? 'inactive';
  const currentStateTileComponents = _get(
    tile.tileStates.find((state: any) => state.stateName === stateCurrent),
    'tileComponents',
  );
  const tileButtonComponent = currentStateTileComponents.find(
    (component: any) => component.type === 'TileButton',
  );
  if (tileButtonComponent && tileButtonComponent.events) {
    let userProducts = [];
    let productStatus = 'active';
    if (user.products) {
      if (typeof user.products === 'string') {
        try {
          userProducts = JSON.parse(user.products);
        } catch (err) {
          userProducts = companyHelpers.parseUserProducts(user.products);
        }
      } else {
        userProducts = user.products;
      }
      const userProduct = userProducts.find((p: any) => p.productId === tile.productId);
      productStatus = userProduct && userProduct.status ? userProduct.status : 'active';
    }

    const evtProps = {
      tileId: tile.tileId,
      productId: tile.productId,
      product: tile.product,
      sourceProductName: tile.sourceProductName,
      productStatus,
      statePrevious: stateCurrent,
      data: null,
    };
    const extendedEvt = { ...tileButtonComponent.events[0], ...evtProps };
    extendedEvt.actions = extendedEvt.actions.map((action: any) => {
      const extAction = { ...action };
      if (extAction.dataToValidate) {
        extAction.skipDataValidation = true;
      }
      return extAction;
    });

    emitter.emit(companyConstants.platformEvents.dataCollectorInitiated, extendedEvt);
  }
};

// const actions = {
//   get: (userId: string) => getUserCuis(userId) || {},
//   update: (options: any) => updateUserCuis(options),
// };

// export const cuiController = {
//   get: async (userId: string, dispatch: any) => {
//     // Get's CUIs and writes to state
//     const res = await actions.get(userId);
//     dispatch({ type: 'INIT', payload: { userCuis: res?.userCUIs || [] } });
//   },
//   set: async (options: any) => {
//     // Writes to database, DOES NOT update state
//     await actions.update(options);
//   },
// };
