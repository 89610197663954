import React, { useMemo, useEffect } from 'react';
import { Tabs, TabPanels, TabPanel, useTabStepper } from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from '@companydotcom/types';
import { AddPayment } from './auto-renew-add-payment';
import { PaymentOptions } from './auto-renew-payment-options';
import { BackButtonLayout } from '../../../../components/layouts';
import type { AutoRenewProps } from '../types/auto-renew.types';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { useGetSavedPaymentMethodsQuery } from '../../../../services/acg/acg-api';
import { useGetMembershipsV2Query, useGetAcgQueryArgs } from '../../../../services/acg/acg-api-v2';
import { useScript } from '../../../../hooks';

const autopaySteps = [
  {
    slug: 'payment-options',
    component: <PaymentOptions />,
  },
  {
    slug: 'add-payment',
    component: <AddPayment />,
  },
];

export const AutoRenew: React.FC<AutoRenewProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const chapterDataFromTile = location.state;
  const { data: globalUser } = useGetGlobalUserQuery();
  const { data: paymentData, isLoading } = useGetSavedPaymentMethodsQuery({
    user: globalUser as User,
  });
  const args = useGetAcgQueryArgs();
  const { data: membershipsList, isLoading: membershipDataLoading } = useGetMembershipsV2Query({
    ...args,
  });

  const paymentMethodKey = useMemo(
    () =>
      membershipsList?.memberships?.[0]?.renewalPaymentInfo?.merchantAccountTokens?.[0]
        ?.merchantToken,
    [membershipsList],
  );

  /** BLUEPAY LOGIC */
  const [, error] = useScript({
    src: 'https://secure.bluepay.com/v3/bluepay.js',
    checkForExisting: true,
  });
  const BluePay = window?.['BluePay'] ?? null;

  useEffect(() => {
    let mounted = true;
    if (BluePay && !error && mounted) {
      BluePay.setCredentials('200025930923', '1f76bb3d84ba20530f312254c2a5889c');
    }
    return () => {
      mounted = false;
    };
  }, [error, BluePay]);

  const { tabIndex, handleTabsChange, previousStep, nextStep, goToStep } = useTabStepper({
    steps: autopaySteps,
    initialStep: paymentData ? determineInitialAutoRenewStep(paymentData) : 0,
  });

  const sharedProps = {
    previousStep,
    nextStep,
    goToStep,
    membershipData: membershipsList,
    paymentMethodKey,
    paymentData,
    isLoading,
    BluePay,
    membershipDataLoading,
    chapterDataFromTile,
  };

  return (
    <BackButtonLayout onBackClick={() => navigate(-1)}>
      <Tabs variant="page" index={tabIndex} onChange={handleTabsChange}>
        <TabPanels>
          {isLoading ? (
            <AppSpinner
              containerStyles={{
                minHeight: '100vh',
              }}
            />
          ) : (
            autopaySteps.map(step => (
              <TabPanel key={step.slug}>{React.cloneElement(step.component, sharedProps)}</TabPanel>
            ))
          )}
        </TabPanels>
      </Tabs>
    </BackButtonLayout>
  );
};

const determineInitialAutoRenewStep = (data: { recordCount: number }) => {
  if (data && data?.recordCount >= 1) {
    return 'payment-options';
  }
  return 'add-payment';
};
