import React, { useEffect } from 'react';
import { Flex, Box, Progress, Heading } from '@companydotcom/potion';
import { User } from '@companydotcom/types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { allocateChildUserSeat } from '../../../pages/user-profile/utils';
import { useUserProfileContext } from '../../../providers/user-profile-context';
import { waitForSuccessfulEnrollmentStatus } from '../utils';
import { useToast } from '../../../hooks';
import {
  useUpdateUserMutation,
  useLazyGetUserTilesQuery,
  useLazyGetUserByEmailQuery,
  useCreateChildUserMutation,
} from '../../../services/user/user-api';
import { useLazyGetEnrollmentStatusQuery } from '../../../services/enrollment/enrollment-api';
import { useLazyPublishTileEventQuery } from '../../../services/event/event-api';

interface LoadingStepProps {
  user?: User;
  sourceId?: string;
  isEmailProductActiveForParent?: boolean;
  goToStep?: (arg: string | number) => void;
  locale?: string;
}

export const LoadingStep = ({
  user,
  sourceId,
  isEmailProductActiveForParent,
  goToStep,
  locale,
}: LoadingStepProps) => {
  const [publishTileEvent] = useLazyPublishTileEventQuery();
  const [getEnrollmentStatus] = useLazyGetEnrollmentStatusQuery();
  const [getUserTiles] = useLazyGetUserTilesQuery();
  const [updateUser] = useUpdateUserMutation();
  const [getUserByEmail] = useLazyGetUserByEmailQuery();
  const [createChildUser] = useCreateChildUserMutation();
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();
  const { t } = useTranslation();
  const toast = useToast();

  //get user for child user, looks for product user ->

  // TODO!!!
  const createUser = async () => {
    try {
      if (sourceId && user?.accountId && !isEmpty(userProfileState?.newUserInformation)) {
        const newChildUser = await createChildUser({
          childUser: {
            email: userProfileState?.newUserInformation?.personalEmail,
            userEmail: userProfileState?.newUserInformation?.personalEmail,
            externalIdentifier: `addNewUserFlow-${Math.round(
              new Date().getTime() / 1000,
            ).toString()}`,
            firstName: userProfileState?.newUserInformation?.firstName,
            lastName: userProfileState?.newUserInformation?.lastName,
            parentAccountId: user?.accountId,
            role: 'user',
          },
          source: sourceId,
        }).unwrap();

        if (newChildUser && newChildUser.status === 'In Progress - Still Processing') {
          //* Adds a timeout to wait for progress bar UI to complete

          setTimeout(() => {
            // Adds user to globalUser object to optimistically display the Additional Users tab
            // Only do this if its the first child user being added to an account

            dispatchUserProfileState?.({
              type: 'ADD_FILTERED_USER',
              payload: {
                firstName: userProfileState?.newUserInformation?.firstName,
                lastName: userProfileState?.newUserInformation?.lastName,
                email: userProfileState?.newUserInformation?.personalEmail,
                userStatus: 'pending',
                products: '[]',
                mailbox: userProfileState?.username,
                mailboxFQDN: user.mailboxFQDN,
              } as User,
            });

            if (userProfileState?.chosenProducts?.email_rackspace) {
              dispatchUserProfileState?.({
                type: 'DECREASE_AVAILABLE_SEAT_COUNT',
                payload: { qty: 1 },
              });
            }
            goToStep?.('new-user-added');
          }, 4500);
        }

        const successfulEnrollment = await waitForSuccessfulEnrollmentStatus(
          newChildUser.enrollmentId,
          sourceId,
          getEnrollmentStatus,
        );

        if (successfulEnrollment.status === 'fulfilled') {
          toast({
            description: t('miop.userProfile.addNewUser.loading.snackbar'),
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          const newUser = await getUserByEmail({
            email: userProfileState?.newUserInformation?.personalEmail ?? '',
          }).unwrap();

          if (newUser) {
            dispatchUserProfileState?.({
              type: 'UPDATE_FILTERED_USER',
              payload: {
                ...newUser,
                userStatus: 'pending',
                mailboxFQDN: user.mailboxFQDN,
                mailbox: userProfileState?.username !== '' ? userProfileState?.username : undefined,
              },
            });

            const childUser = {
              userId: newUser.userId,
              email: userProfileState?.newUserInformation?.personalEmail,
              firstName: userProfileState?.newUserInformation?.firstName,
              lastName: userProfileState?.newUserInformation?.lastName,
              mailboxFQDN: user.mailboxFQDN,
              source: user.source,
              mailbox: userProfileState?.chosenProducts?.email_rackspace
                ? userProfileState.username
                : undefined,
              products: [] as any,
              userStatus: 'pending',
            };

            // @ts-ignore doesnt like that products is empty array
            await updateUser(childUser);

            const { data: newUserTiles } = await getUserTiles({ userId: newUser.userId, locale });
            setTimeout(async () => {
              const emailTileIdFromProductId = newUserTiles?.find(
                tile => tile.productId === process.env.REACT_APP_EMAIL_PRODUCTID,
              )?.tileId;
              if (
                user.account &&
                emailTileIdFromProductId &&
                sourceId !== 'officedepot' &&
                isEmailProductActiveForParent &&
                userProfileState?.chosenProducts?.email_rackspace
              ) {
                await allocateChildUserSeat(
                  { ...newUser, ...childUser },
                  emailTileIdFromProductId,
                  user.account,
                  publishTileEvent,
                );
              }
            }, 500);
          }
        }
      }
    } catch (error) {
      console.error('Error creating and enrolling a child user! ', error);
      goToStep?.('error');
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      createUser();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      bg="white"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={104}
      px={4}
      width="full"
    >
      <Box mb={16} maxWidth={[310, 455]} sx={{ textAlign: 'center' }}>
        <Heading size="hs-2xl">{t('miop.userProfile.addNewUser.loading.header')}</Heading>
      </Box>
      <Progress width="full" maxW={680} size="lg" value={40} isIndeterminate />
    </Flex>
  );
};
