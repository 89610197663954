import * as React from 'react';
import {
  potion,
  forwardRef,
  omitThemingProps,
  ThemingProps,
  useStyleConfig,
  HTMLPotionProps,
} from '../../system';
import { SystemProps } from '../../styled-system';
import { cx, __DEV__, filterUndefined } from '../../utils';

export interface TextProps extends HTMLPotionProps<'p'>, ThemingProps<'Text'> {
  /**
   * The CSS `text-align` property
   * @type SystemProps["textAlign"]
   */
  align?: SystemProps['textAlign'];
  /**
   * The CSS `text-decoration` property
   * @type SystemProps["textDecoration"]
   */
  decoration?: SystemProps['textDecoration'];
  /**
   * The CSS `text-transform` property
   * @type SystemProps["textTransform"]
   */
  casing?: SystemProps['textTransform'];
}

/**
 * Used to render texts or paragraphs.
 */
export const Text = forwardRef<TextProps, 'p'>((props, ref) => {
  const styles = useStyleConfig('Text', props);
  const { className, align, decoration, casing, ...rest } = omitThemingProps(props);

  const aliasedProps = filterUndefined({
    textAlign: props.align,
    textDecoration: props.decoration,
    textTransform: props.casing,
  });

  return (
    <potion.p
      ref={ref}
      className={cx('potion-text', props.className)}
      {...aliasedProps}
      {...rest}
      __css={styles}
    />
  );
});

if (__DEV__) {
  Text.displayName = 'Text';
}
