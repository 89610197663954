import React from 'react';
import { Button, Flex } from '@companydotcom/potion';

import { ImageFeed } from '../components';
import { AcgRssFeedResponse } from '@companydotcom/types';

export interface TileAcgMediaMagazinesProps extends AcgRssFeedResponse {}

export const TileAcgMediaMagazines: React.FC<TileAcgMediaMagazinesProps> = props => {
  return (
    <Flex
      flexDirection="column"
      flexGrow={1}
      width="full"
      mt={1}
      justifyContent="center"
      alignItems="center"
    >
      <ImageFeed {...props} />
      <Button
        variant="tile"
        onClick={() => {
          window.open('https://middlemarketgrowth.org/category/magazines/');
        }}
        width="fit-content"
        size="md"
        type="submit"
      >
        GO TO MAGAZINES
      </Button>
    </Flex>
  );
};
