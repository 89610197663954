const notificationQuery = {
  getNotificationList: userId => `query getNotificationList {
    getNotificationList(userId: "${userId}") {
      id
      body
      handled
      notificationType
      deliveryType
      dateCreated
      dateExpires
      productId
    }
  }`,
  fetchLargePayload: respPayloadCacheId => `query fetchLargePayload {
    fetchLargePayload(respPayloadCacheId: "${respPayloadCacheId}")
  }`,
};

export default notificationQuery;
