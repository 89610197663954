import React from 'react';
import { Box, VStack, Heading, Text, Button, useBreakpointValue } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faFolderArrowUp } from '@fortawesome/pro-thin-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ContentPublishedProps {
  goToStep?: (arg: string) => void;
}

export const FaceCakeContentPublishedTile = ({ goToStep }: ContentPublishedProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const buttonSize = useBreakpointValue({ base: 'md', xl: 'lg' });

  const handleOnClick = () => {
    navigate('/', { state: { defaultStep: 'consult', isScheduled: undefined } });
    goToStep?.('consult');
  };

  return (
    <Box>
      <VStack spacing="2">
        <FontAwesomeIcon
          icon={faFolderArrowUp}
          color="pink.500"
          boxSize={{ base: '12', xl: '16' }}
        />
        <Heading size="lg" color="pink.500" fontWeight="bold">
          {t('icommerce.tileStatus.published.header')}
        </Heading>
        <Text>{t('icommerce.tileStatus.published.body')}</Text>
      </VStack>
      <Box mt="6">
        <Button size={buttonSize} onClick={handleOnClick}>
          {t('icommerce.tileStatus.published.buttonText')}
        </Button>
      </Box>
    </Box>
  );
};
