import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMitt } from '@companydotcom/providers';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction, useTranslation } from 'react-i18next';
import { Box, Button, Center, VStack, Divider } from '@companydotcom/potion';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  InputField,
  SelectRegionField,
  SelectCountryField,
  SelectField,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
} from '@companydotcom/ui';
import { companyConstants } from '@companydotcom/helpers';
import startCase from 'lodash/startCase';
import { useGetUserQuery } from '../../../../../services/user/user-api';
import { useAuth } from '../../../../../providers';
import { acgRefData, formatRhythmPhoneToUS, formatRhythmPhoneNumber } from '../../../shared';
import yup from '../../../../../lib/yup';
import {
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
  useUpdateAcgUserV2Mutation,
} from '../../../../../services/acg/acg-api-v2';
import { AcgUserAddress } from '@companydotcom/types';

const acgAddressInformationSchema = (t: TFunction) =>
  yup.object().shape({
    addressLine1: yup.string().required('common.inputs.address.error'),
    addressLine2: yup.string().notRequired(),
    city: yup.string().required('common.inputs.city.error'),
    country: yup.string().required(t('common.inputs.country.error')),
    extension: yup.string().notRequired(),
    phoneNumber: yup.string().phone().nullable().notRequired(),
    postalCode: yup.string().required('common.inputs.postalCode.error'),
    preferredAddressType: yup
      .string()
      .required(t('acg.firstTimeFlow.addressInformation.addressType.error')),
    preferredPhoneType: yup
      .string()
      .required(t('acg.firstTimeFlow.addressInformation.phoneType.error')),
    state: yup.string().required(t('common.inputs.state.error')),
  });
export const ExistingUserAddressInformation = () => {
  const { emitter } = useMitt();
  const { t } = useTranslation();
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();
  const authUser = useAuth();
  const { data: globalUser } = useGetUserQuery({ userId: authUser?.user?.userId as string });
  const [updateUser, { isLoading: isUpdating }] = useUpdateAcgUserV2Mutation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const args = useGetAcgQueryArgs();
  const updateUserArgs = useGetAcgQueryArgs(['email', 'source']);

  const { data: acgUser } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const preferredAddress = acgUser?.[acgUser?.preferredAddressType!] as AcgUserAddress;
  const preferredPhone = acgUser?.[acgUser?.preferredPhoneType!];

  const defaultValues: FieldValues = {
    addressLine1: preferredAddress?.addressLine1 || '',
    addressLine2: preferredAddress?.addressLine2 || '',
    preferredAddressType: acgUser?.preferredAddressType || '',
    preferredPhoneType: acgUser?.preferredPhoneType || '',
    city: preferredAddress?.city || '',
    state: preferredAddress?.state || '',
    country: startCase(preferredAddress?.country?.toLowerCase()) || 'United States',
    postalCode: preferredAddress?.postalCode || '',
    phoneNumber: formatRhythmPhoneToUS(localStorage.user_phoneno, country),
    extension: preferredPhone || '',
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(acgAddressInformationSchema(t)),
    defaultValues,
    context: { country },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (data: typeof defaultValues) => {
    if (data?.phoneNumber) {
      data.phoneNumber = formatRhythmPhoneNumber(data.phoneNumber, country);
    }
    try {
      if (globalUser) {
        await updateUser({
          ...updateUserArgs,
          payload: {
            preferredAddressType: data.preferredAddressType,
            preferredPhoneType: data.preferredPhoneType,
            lastProfileUpdateVersion: acgUser?.lastProfileUpdateVersion ?? 1,
            // Ensures that address and phone are always marked as primary on first time registration
            [data.preferredAddressType === 'homeAddress' ? 'homeAddress' : 'workAddress']: {
              addressLine1: data.addressLine1 ?? '',
              addressLine2: data.addressLine2 !== '' ? data.addressLine2 : undefined,
              city: data.city ?? '',
              state: data.state ?? '',
              country: data.country ?? '',
              postalCode: data.postalCode ?? '',
            },
            [data.preferredPhoneType === 'workPhone' ? 'workPhone' : 'mobilePhone']:
              data.phoneNumber ?? '',
            mobilePhoneExtension:
              data.preferredPhoneType === 'mobilePhone' ? data.extension : undefined,
            workPhoneExtension:
              data.preferredPhoneType === 'workPhone' ? data.extension : undefined,
          },
        })
          .unwrap()
          .then(async () => {
            authUser?.refreshUser();
            if (search) {
              navigate({ pathname: '/redirect', search });
            } else {
              emitter.emit(companyConstants.platformEvents.dataCollectorComplete, {
                path: '/',
              });
            }
          });
      }
    } catch (err) {
      console.log('Error!', err);
    }
  };

  return (
    <Center
      className="acg-registration-addressInformation__container"
      flexDirection="column"
      px={[0, 4]}
      textAlign="center"
    >
      <Box
        as="form"
        className="acg-registration-addressInformation__form"
        maxWidth={430}
        width="full"
      >
        <VStack spacing={6}>
          <SelectCountryField
            control={control}
            name="country"
            label={t('common.inputs.country.label')}
            helperText={t('common.misc.required')}
          />
          <InputField
            autoFocus
            className="acg-registration-addressInformation__addressLine1"
            data-test="acg-registration-addressInformation__addressLine1"
            register={register}
            name="addressLine1"
            placeholder={t('common.inputs.address.placeholder')}
            helperText={t('common.misc.required')}
            label={t('common.inputs.address.label')}
            errors={errors}
          />
          <InputField
            className="acg-registration-addressInformation__addressLine2"
            data-test="acg-registration-addressInformation__addressLine2"
            register={register}
            name="addressLine2"
            placeholder={t('common.inputs.addressLine2.placeholder')}
            errors={errors}
          />
          <InputField
            className="acg-registration-addressInformation__city"
            data-test="acg-registration-addressInformation__city"
            register={register}
            name="city"
            label={t('common.inputs.city.label')}
            helperText={t('common.misc.required')}
            errors={errors}
          />
          <SelectRegionField
            country={watch('country') ?? ''}
            control={control}
            helperText={t('common.misc.required')}
            name="state"
            label={t('common.inputs.state.label')}
          />
          <InputField
            className="acg-registration-addressInformation__postalCode"
            data-test="acg-registration-addressInformation__postalCode"
            register={register}
            name="postalCode"
            helperText={t('common.misc.required')}
            label={t('common.inputs.postalCode.label')}
            errors={errors}
          />
          <SelectField
            className="acg-registration-addressInformation__addressType"
            data-test="acg-registration-addressInformation__addressType"
            register={register}
            name="preferredAddressType"
            label={t('acg.firstTimeFlow.addressInformation.addressType.label')}
            helperText={t('common.misc.required')}
            formControlStyles={{ marginBottom: 6 }}
            errors={errors}
          >
            <option value=""> </option>
            {acgRefData?.addressType?.map(
              (opt, i) =>
                opt && (
                  <option key={i} value={opt.recordKey}>
                    {opt.recordName}
                  </option>
                ),
            )}
          </SelectField>

          <Divider borderWidth="1px" borderColor="#D1D1D1" />
          <PhoneNumberInputField
            name="phoneNumber"
            label={t('common.inputs.phoneNumber.label')}
            className="acg-registration-addressInformation__phoneField"
            data-test="acg-registration-addressInformation__phoneField"
            control={control}
            country={country}
            onCountryChange={onCountryChange}
            errors={errors}
          />

          <InputField
            className="acg-registration-addressInformation__extension"
            data-test="acg-registration-addressInformation__extension"
            register={register}
            name="extension"
            label={t('acg.firstTimeFlow.addressInformation.phoneNumberExt.label')}
            errors={errors}
          />
          <SelectField
            className="acg-registration-addressInformation__phoneType"
            data-test="acg-registration-addressInformation__phoneType"
            register={register}
            name="preferredPhoneType"
            helperText={t('acg.firstTimeFlow.addressInformation.phoneType.required')}
            label={t('acg.firstTimeFlow.addressInformation.phoneType.label')}
            formControlStyles={{ marginBottom: 6 }}
            errors={errors}
          >
            <option value=""> </option>
            {acgRefData?.phoneType?.map(
              (opt, i) =>
                opt && (
                  <option key={i} value={opt.recordKey}>
                    {opt.recordName}
                  </option>
                ),
            )}
          </SelectField>
        </VStack>

        <Box textAlign="center" mt={[8, 12]}>
          <Button
            className="acg-registration-addressInformation__submitButton"
            data-test="acg-registration-addressInformation__submitButton"
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid}
            isLoading={isSubmitting || isUpdating}
          >
            {searchParams.get('WebCode')?.toLowerCase() === 'eventregfees'
              ? 'GO TO EVENT REGISTRATION'
              : 'GO TO MY ACG'}
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
