import { PageLayout, PageLayoutContent } from '../../components/layouts';
import { EnterpriseContactUsForm } from '../../features/facecake/facecake-enterprise';

export const FaceCakeEnterPriseContactPage = () => {
  return (
    <PageLayout mx="auto">
      <PageLayoutContent>
        <EnterpriseContactUsForm />
      </PageLayoutContent>
    </PageLayout>
  );
};
