import { GENERATE_SIGNED_URL, DELETE_ITEM_FROM_BUCKET } from './graphql/management-mutation';
import { emptySplitApi } from '../../store/empty-split-api';

export const ManagementApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    generateSignedUrl: builder.mutation<
      { statusCode: number; body: { link: string } },
      { bucket: string; fileName: string }
    >({
      query: ({ bucket, fileName }) => ({
        method: 'mutation',
        document: GENERATE_SIGNED_URL,
        variables: {
          bucket,
          fileName,
        },
      }),
      transformResponse: (rawResult: { data: { generateSignedUrl: string } }) => {
        const parsed = JSON.parse(rawResult.data.generateSignedUrl);
        return {
          statusCode: parsed.statusCode,
          body: JSON.parse(parsed.body),
        };
      },
    }),
    uploadAccountAsset: builder.mutation<
      { statusCode: number; body: { link: string } },
      { bucket?: string; fileName: string }
    >({
      query: ({ bucket = 'dxp-account-assets', fileName }) => ({
        method: 'mutation',
        document: GENERATE_SIGNED_URL,
        variables: {
          bucket,
          fileName,
        },
      }),
      transformResponse: (rawResult: { data: { generateSignedUrl: string } }) => {
        const parsed = JSON.parse(rawResult.data.generateSignedUrl);
        return {
          statusCode: parsed.statusCode,
          body: JSON.parse(parsed.body),
        };
      },
    }),
    deleteItemFromBucket: builder.mutation<
      { statusCode: number; body: { deleted: boolean } },
      { bucket?: string; fileName: string }
    >({
      query: ({ bucket = 'dxp-account-assets', fileName }) => ({
        method: 'mutation',
        document: DELETE_ITEM_FROM_BUCKET,
        variables: {
          bucket,
          fileName,
        },
      }),
      transformResponse: (rawResult: { data: { deleteItemFromBucket: string } }) => {
        const parsed = JSON.parse(rawResult.data.deleteItemFromBucket);
        return {
          statusCode: parsed.statusCode,
          body: JSON.parse(parsed.body),
        };
      },
    }),
  }),
});

export const {
  useGenerateSignedUrlMutation,
  useDeleteItemFromBucketMutation,
  useUploadAccountAssetMutation,
} = ManagementApi;
