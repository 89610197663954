import React from 'react';
import { Text, Table, Thead, Tbody, Tr, Th, Td } from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import type { TileComponentProps } from '../../types';
import { TileSpinner } from '../tile-spinner';

export interface ThreatEducationProps {
  body?: any;
  payload: {
    title?: string;
    completion_percentage?: number;
    courses_completed?: string;
    total_courses?: number;
  }[];
}
export interface TileThreatAdviceEducationListProps extends TileComponentProps {}

export const TileThreatAdviceEducation: React.FC<TileThreatAdviceEducationListProps> = props => {
  const { variant, eventData } = props;
  const { loading } = useEventData<ThreatEducationProps>(eventData);

  const isVisible = variant;

  const response = eventData?.body?.payload;

  if (loading) {
    return <TileSpinner />;
  }

  return (
    <Table width={296} marginBottom="50px">
      <Thead>
        <Tr style={{ borderBottom: '1px solid #202020' }}>
          <Th pl={0} pb={0}>
            <Text
              textStyle="xs"
              fontWeight="extrabold"
              textTransform="capitalize"
              color="#333333"
              opacity="0.8"
            >
              Semester
            </Text>
          </Th>
          {isVisible === 'true' ? (
            <Th pl={0} pb={0}>
              <Text
                textStyle="xs"
                fontWeight="extrabold"
                color="#333333"
                textTransform="capitalize"
                opacity="0.8"
              >
                Completion
              </Text>
            </Th>
          ) : (
            ''
          )}
          <Th pl={0} pb={0}>
            <Text
              textStyle="xs"
              fontWeight="extrabold"
              color="#333333"
              textTransform="capitalize"
              opacity="0.8"
            >
              Courses
            </Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {response?.map((item: any, i: any) => (
          <Tr key={i}>
            <Td pl={0} py={2}>
              <Text textStyle="sm" fontWeight="normal" color="#333333">
                {item.title}
              </Text>
            </Td>
            {isVisible === 'true' ? (
              <Td pl={0} py={2}>
                <Text textStyle="sm" fontWeight="normal" color="#333333">
                  {item.completion_percentage}%
                </Text>
              </Td>
            ) : (
              ''
            )}
            <Td pl={0} py={2}>
              <Text textStyle="sm" fontWeight="normal" color="#333333">
                {item.courses_completed}/{item.total_courses}
              </Text>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
