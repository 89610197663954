"use strict";
exports.__esModule = true;
var theme_fragment_1 = require("./fragments/theme.fragment");
var sourceQuery = {
    getSourceURI: function (uri) { return "query getSourceURI {\n    getSourceURI(uri: \"".concat(uri, "\") {\n      sourceId\n    }\n  }"); },
    getSourceConfig: function (sourceId) { return "query getSourceConfig {\n    getSourceConfig(source: \"".concat(sourceId, "\"){\n      externalSignUpLink\n      captchaEnabled\n      passwordRequirements {\n        identicalCharsEnabled\n        max\n        min\n        numbersRequired\n        specialChars\n        uppercaseRequired\n      }\n      multiMidConfig{\n        midSelectionScreen{\n          externalIdentifier\n        }\n      }\n      redirectConfig {\n        nonMigratedRedirectUrl\n        routeMap {\n          param\n          path\n        }\n      }\n      cookieConfig {\n        name\n        path\n        domain\n        sameSite\n        secure\n        value\n        expires\n      }\n      gtmContainerIds\n    }\n  }"); },
    getSource: function (sourceId, locale) { return "query getSource {\n    getSource(\n      sourceId: \"".concat(sourceId, "\",\n      locale: \"").concat(locale, "\"\n      ) {\n      ").concat(theme_fragment_1.THEME_CONFIG_FRAGMENT, "\n      images {\n        logo\n        sidenav_logo\n        footer_logo\n        tile_icon\n        learn_more\n      }\n      createAccountOnSignup\n      sourceId\n      showGmbProfileFlow\n      supportPhoneNumber\n      supportEmail\n      invoiceDisabled\n      i18nConfig {\n        lng\n        supportedLngs\n      }\n      sourceTiles {\n        roles\n      }\n      byopConfig {\n        enabled\n      }\n      footerLinks {\n        home\n        aboutUs\n        contactUs\n        termsOfUse\n        privacyPolicy\n        learnMore\n      }\n      navConfig {\n        navGroups {\n          title\n          links {\n            path\n            iconVariant\n            icon\n            label\n            isExternal\n          }\n        }\n        navCopy {\n          title\n          links {\n            path\n            isExternal\n            label\n          }\n        }\n      }\n      forms {\n        label {\n          fontWeight\n          fontSize\n          color\n        }\n        type\n        buttonText\n        buttonType\n        termsText\n      }\n    }\n  }"); },
    getPaymentForm: function (sourceId, isLocal) {
        if (isLocal === void 0) { isLocal = false; }
        return "query getPaymentForm {\n    getPaymentForm(sourceId: \"".concat(sourceId, "\", isLocal: ").concat(isLocal, ") {\n      id\n      signature\n      token\n      tenantId\n      key\n      success\n      id\n      style\n      submitEnabled\n      locale\n      url\n    }\n  }");
    },
    getProductRatePlan: function (accountId, productId, locale) { return "query getProductRatePlan {\n    getProductRatePlan(productId: \"".concat(productId, "\" accountId: \"").concat(accountId, "\" locale: \"").concat(locale, "\") {\n      description\n      includedInMembership\n      isActive\n      name\n      price\n      zuoraProductRatePlanId\n      zuoraProductRatePlanChargeId\n      partnerRatePlanId\n      enterpriseQty\n      tiers {\n        startingUnit\n        endingUnit\n        price\n        partnerTierId\n        chargeModel\n      }\n    }\n  }"); },
    getAuth0Config: function (source, applicationSlug) {
        if (applicationSlug === void 0) { applicationSlug = 'dashboard'; }
        return "query getAuth0Config {\n    getAuth0Config(applicationSlug: \"".concat(applicationSlug, "\" source: \"").concat(source, "\") {\n      authDomain\n      clientAudience\n      clientId\n      clientSecret\n      clientUrl\n      connectionName\n      forgotPasswordUrl\n      redirectUrl\n      serverIssuer\n      tenant\n    }\n  }\n  ");
    },
    getContactInformation: function (sourceId) { return "query getContactInformation {\n    getContactInformation(source: \"".concat(sourceId, "\"){\n      heading\n      primaryAddress {\n        location\n        addressLine1\n        addressLine2\n        addressLine3\n      }\n      secondaryAddress {\n        location\n        addressLine1\n        addressLine2\n        addressLine3\n      }\n      customerSuccessBlock {\n        heading\n        showChatSupport\n        hours\n        copy\n        chatLink\n        contactPhone\n        contactEmail\n        imageUrl\n        liveChatAgentUserId\n        liveChatDeploymentId\n        liveChatEndpointUrl\n        liveChatOrganizationId\n        liveChatScriptUrl\n      }\n      salesBlock {\n        heading\n        copy\n        salesText\n        salesLinks\n        imageUrl\n        showSalesBlock\n      }\n    }\n  }"); }
};
exports["default"] = sourceQuery;
