import { useSource } from '@companydotcom/providers';
import { useState, useEffect } from 'react';
import {
  usePrefetch as useAcgApiPrefetchV2,
  useGetAcgQueryArgs,
} from '../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';

/** Component used to handle prefetching app-wide queries */
export const QueryPrefetcher = () => {
  const source = useSource();
  const args = useGetAcgQueryArgs();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [areAcgCallsPrefetching, setIsAcgPrefetching] = useState(true);

  // V2
  const getMembershipsV2Prefetch = useAcgApiPrefetchV2('getMembershipsV2');
  const getUserPurchasedEventsPrefetch = useAcgApiPrefetchV2('getUserPurchasedEventsV2');
  const getMemberCommitteesV2Prefetch = useAcgApiPrefetchV2('getMemberCommitteesV2');
  const getInvoicesV2Prefetch = useAcgApiPrefetchV2('getInvoicesByUserV2');
  const getGrowthTvFeedV2Prefetch = useAcgApiPrefetchV2('getGrowthTvFeedV2');
  const getMagazinesFeedV2Prefetch = useAcgApiPrefetchV2('getMagazinesFeedV2');
  const getNewsTrendsFeedV2Prefetch = useAcgApiPrefetchV2('getNewsTrendsFeedV2');
  const getAcgUserProfileV2Prefetch = useAcgApiPrefetchV2('getAcgUserProfileV2');

  useEffect(() => {
    if (globalUser && areAcgCallsPrefetching && source?.sourceId === 'acg') {
      setIsAcgPrefetching(false);

      // V2
      getMembershipsV2Prefetch({ ...args });
      getUserPurchasedEventsPrefetch({ ...args });
      getMemberCommitteesV2Prefetch({ ...args });
      getInvoicesV2Prefetch({ ...args });
      getNewsTrendsFeedV2Prefetch({ ...args });
      getMagazinesFeedV2Prefetch({ ...args });
      getGrowthTvFeedV2Prefetch({ ...args });
      getAcgUserProfileV2Prefetch({
        ...args,
        email: globalUser?.email as string,
        accountId: globalUser?.accountId as string,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
