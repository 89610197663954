import gql from 'graphql-tag';

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      userId
      firstName
      lastName
      phone
      mailbox
      userStatus
      organization
    }
  }
`;

export const SET_USER_TILE_STATE_MUTATION = gql`
  mutation SetUserTileState($input: UserTileStateInput!) {
    setUserTileState(input: $input) {
      tileId
      userId
      productId
      productSlug
      stateCurrent
      statePrevious
      isHidden
      seqNo
    }
  }
`;

export const SET_USER_TILE_STATE_LITE_MUTATION = gql`
  mutation SetUserTileStateLite($input: UserTileStateInputLite!) {
    setUserTileStateLite(input: $input) {
      tileId
      userId
      stateCurrent
      isHidden
      seqNo
    }
  }
`;

export const SET_USER_AVATAR_MUTATION = gql`
  mutation UpsertAvatar($userId: String!, $dataUri: String!) {
    upsertAvatar(dataUri: $dataUri, userId: $userId)
  }
`;
export const DELETE_USER_AVATAR_MUTATION = gql`
  mutation DeleteAvatar($userId: String!) {
    deleteAvatar(userId: $userId)
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($auth0UserID: String, $password: String) {
    updateUserPassword(input: { auth0UserID: $auth0UserID, password: $password })
  }
`;

export const UPDATE_USER_DATA_COLLECTOR = gql`
  mutation UpdateUserDataCollector(
    $userId: String!
    $dataCollectorSlug: String!
    $markDismissed: Boolean
    $markCompleted: Boolean
    $markSeen: Boolean
  ) {
    updateUserDataCollector(
      userId: $userId
      dataCollectorSlug: $dataCollectorSlug
      markDismissed: $markDismissed
      markCompleted: $markCompleted
      markSeen: $markSeen
    )
  }
`;

export const SET_USER_TILE_GROUP_MUTATION = gql`
  mutation SetUserTileGroup($input: UserTileGroupInput!) {
    setUserTileGroup(input: $input) {
      userId
      groupId
      seqNo
      isHidden
      color
      tileGroup {
        name
      }
    }
  }
`;

export const UPDATE_USER_ACCOUNT_DOMAIN = gql`
  mutation UpdateUserAccountDomain($options: UpdateAccountInput!) {
    updateAccount(input: $options) {
      accountId
    }
  }
`;

export const UPDATE_USER_ACCOUNT = gql`
  mutation UpdateUserAccount($options: UpdateAccountInput!) {
    updateAccount(input: $options) {
      accountId
    }
  }
`;

export const UPDATE_ACCOUNT_BUSINESS = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      accountStatus
      business {
        address {
          state
          city
          zipCode
          addressLine1
        }
        name
        numberOfEmployees
        website
        categories {
          id
          name
          fullName
          publisher
        }
      }
      dateCreated
      dateUpdated
      isEmailFQDNCustom
      isWebFQDNCustom
      fqdnCompany
      fqdnCustom
      hasFQDN
    }
  }
`;

export const UPDATE_ACCOUNT_BUSINESS_NO_CITY_STATE = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      accountStatus
      business {
        address {
          zipCode
          addressLine1
        }
        name
        categories {
          fullName
          name
          id
          publisher
        }
      }
      dateCreated
      dateUpdated
      isEmailFQDNCustom
      isWebFQDNCustom
      fqdnCompany
      fqdnCustom
      hasFQDN
    }
  }
`;

export const UPDATE_CHILD_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      userId
      firstName
      lastName
      phone
      userStatus
    }
  }
`;

export const UPDATE_MAILBOX_PASSWORD = gql`
  mutation UpdateMailboxPassword($input: UpdateMailboxPasswordInput!) {
    updateMailboxPassword(input: $input)
  }
`;

// export const UPDATE_USER_MAILBOX = gql`
// mutation UpdateUserMailbox($input: { $userId: String!, $mailbox: String }) {
//   updateUser(input: $input) {
//     account {
//       dateUpdated
//       users {
//         userId
//       }
//     }
//   }
// }`;

export const PROCESS_ORDER_SUMMARY = gql`
  mutation ProcessOrder($input: ProcessOrderInput!) {
    processOrder(input: $input) {
      requestSuccessful
      code
      errors
      payload
    }
  }
`;

export const CREATE_DOMAIN = gql`
  mutation CreateDomain($body: CreateDomainInput!) {
    createDomain(body: $body)
  }
`;

export const UPDATE_ALIAS = gql`
  mutation UpdateAlias($body: UpdateDomainInput!) {
    updateAlias(body: $body)
  }
`;

export const UPDATE_MX = gql`
  mutation UpdateMX($body: UpdateDomainInput!) {
    updateMX(body: $body)
  }
`;

export const UPDATE_LOAN = gql`
  mutation UpdateLoan($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      accountId
      loans {
        applicationId
        yearsInBusiness
        industry
        creditEstimate
        purpose
        annualRevenue
        amountRequired
        status
        monthFounded
        yearFounded
      }
      loanPrimary {
        applicationId
        yearsInBusiness
        industry
        creditEstimate
        purpose
        annualRevenue
        amountRequired
        status
        monthFounded
        yearFounded
      }
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation ActivateUser($userEmail: String!, $ownerEmail: String!, $enrollmentId: String!) {
    activateUser(userEmail: $userEmail, ownerEmail: $ownerEmail, enrollmentId: $enrollmentId)
  }
`;

export const CHANGE_ENROLLMENT_ID = gql`
  mutation ChangeEnrollmentIdForUser($enrollmentId: String!, $oldEnrollmentId: String!) {
    changeEnrollmentIdForUser(newEnrollmentId: $enrollmentId, oldEnrollmentId: $oldEnrollmentId)
  }
`;

export const UPDATE_ZUORA_PAYMENT_METHOD_ID = gql`
  mutation UpdateZuoraPaymentMethodId($accountId: String!, $zuoraPaymentMethodId: String!) {
    updateAccount(input: { accountId: $accountId, zuoraPaymentMethodId: $zuoraPaymentMethodId }) {
      dateUpdate
      zuoraPaymentMethodId
    }
  }
`;

export const CREATE_CALENDLY_APPOINTMENT = gql`
  mutation CreateCalendlyAppointment($userId: String!, $productId: String!, $url: String!) {
    createCalendlyAppointment(userId: $userId, productId: $productId, url: $url)
  }
`;

export const CREATE_CHILD_USER = gql`
  mutation CreateChildEmailUser($childUser: ChildUserInput!, $source: String!) {
    createChildEmailUser(childUser: $childUser, source: $source)
  }
`;

export const UPDATE_USER_PRODUCT = gql`
  mutation updateUserProduct($input: UserProductInput) {
    updateUserProduct(input: $input)
  }
`;
