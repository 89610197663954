import React from 'react';
import {
  Flex,
  Box,
  StylesProvider,
  useMultiStyleConfig,
  useDisclosure,
  __DEV__,
  useStyles,
  BoxProps,
  SimpleGrid,
  SimpleGridProps,
  IconButton,
  IconButtonProps,
  createContext,
  Collapse,
  DividerProps,
  Divider,
  forwardRef,
  Icon,
  useBreakpointValue,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faEllipsis } from '@fortawesome/pro-solid-svg-icons';

interface TileGroupContext {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const [TileGroupProvider, useTileGroupContext] = createContext<TileGroupContext>({
  name: 'TileGroupContext',
  errorMessage:
    'useTileGroupContext: `context` is undefined. Seems you forgot to wrap the tile group components in `<TileGroup />`',
});

export interface TileGroupProps extends BoxProps {}

export const TileGroup: React.FC<TileGroupProps> = props => {
  const { children, ...rest } = props;
  const styles = useMultiStyleConfig('TileGroup', props);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const ctx = React.useMemo(() => ({ isOpen, onOpen, onClose }), [isOpen, onOpen, onClose]);

  return (
    <TileGroupProvider value={ctx}>
      <StylesProvider value={styles}>
        {/* @ts-ignore */}
        <Box className="potion-tileGroup" {...rest} sx={styles.container}>
          {children}
        </Box>
      </StylesProvider>
    </TileGroupProvider>
  );
};

if (__DEV__) {
  TileGroup.displayName = 'TileGroup';
}

export interface TileGroupHeaderProps extends BoxProps {}

/**
 * TileGroupHeader
 *
 * React component that houses the tile group header content.
 *
 */
export const TileGroupHeader: React.FC<TileGroupHeaderProps> = props => {
  const styles = useStyles();
  const { children, ...rest } = props;

  return (
    // @ts-ignore
    <Flex sx={styles.header} direction="column" className="potion-tileGroup__header" {...rest}>
      {children}
    </Flex>
  );
};

if (__DEV__) {
  TileGroupHeader.displayName = 'TileGroupHeader';
}

export interface TileGroupItemProps extends SimpleGridProps {}

/**
 * TileGroupContainer
 *
 * React component that houses the tile group content.
 *
 */
export const TileGroupItem: React.FC<TileGroupItemProps> = props => {
  const styles = useStyles();
  const { isOpen } = useTileGroupContext();
  const autoColumns = useBreakpointValue({ base: 'calc(360px - 16px * 2)', md: 'inherit' });
  const overflow = useBreakpointValue({ base: 'auto', md: 'inherit' });
  const gridAutoFlow = useBreakpointValue({ base: 'inherit', md: 'inherit' });

  const child = (
    // @ts-ignore
    <SimpleGrid
      spacingX={10}
      spacingY={16}
      templateColumns="repeat(auto-fit, 360px)"
      direction="row"
      className="potion-tileGroup__item"
      gridAutoFlow={gridAutoFlow}
      gridAutoColumns={autoColumns}
      // @ts-ignore
      overflowX={overflow}
      sx={styles.item}
      {...props}
      justifyContent={['center', 'left']}
    />
  );

  return <Collapse in={isOpen}>{child}</Collapse>;
};

if (__DEV__) {
  TileGroupItem.displayName = 'TileGroupItem';
}

export interface TileGroupExpandButtonProps extends IconButtonProps {}

/**
 * TileGroupExpandButton is used to minimize or expand the TileGroup.
 */
export const TileGroupExpandButton: React.FC<TileGroupExpandButtonProps> = props => {
  const styles = useStyles();
  const { isOpen, onOpen, onClose } = useTileGroupContext();

  return (
    // @ts-ignore
    <IconButton
      variant="ghost"
      size="sm"
      icon={<Icon as={FontAwesomeIcon} icon={isOpen ? faCaretDown : faCaretUp} boxSize={4} />}
      className="potion-tileGroup__expand-btn"
      onClick={isOpen ? onClose : onOpen}
      {...props}
      sx={styles.icon}
    />
  );
};

if (__DEV__) {
  TileGroupExpandButton.displayName = 'TileGroupExpandButton';
}

export interface TileGroupSettingsButtonProps extends IconButtonProps {}

/**
 * TileGroupSettingsButton is used to contain tile group specific settings.
 */
export const TileGroupSettingsButton = forwardRef<TileGroupSettingsButtonProps, 'button'>(
  (props, ref) => {
    const styles = useStyles();
    const { isOpen, onOpen, onClose } = useTileGroupContext();

    return (
      // @ts-ignore
      <IconButton
        ref={ref}
        variant="ghost"
        size="sm"
        icon={<Icon as={FontAwesomeIcon} icon={faEllipsis} boxSize={5} />}
        className="potion-tileGroup__settings-btn"
        onClick={isOpen ? onClose : onOpen}
        {...props}
        sx={styles.icon}
      />
    );
  },
);

if (__DEV__) {
  TileGroupSettingsButton.displayName = 'TileGroupSettingsButton';
}

export interface TileGroupDividerProps extends DividerProps {}

/**
 * TileGroupDivider is used to divider the header from the group content.
 */
export const TileGroupDivider: React.FC<TileGroupDividerProps> = props => {
  const styles = useStyles();

  return (
    // @ts-ignore
    <Divider className="potion-tileGroup__divider" {...props} sx={styles.divider} />
  );
};

if (__DEV__) {
  TileGroupDivider.displayName = 'TileGroupDivider';
}
