import { useMemo, useCallback, useState } from 'react';
import { Box, Heading, useMediaQuery, Text, Link } from '@companydotcom/potion';
import { PageDivider } from '@companydotcom/ui';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAcgCartStorage } from '../../shared';
import { CheckoutLocationStateProps } from './checkout';
import {
  CheckoutSecureAndEncrypted,
  CheckoutLayout,
  CheckoutAlertContent,
} from '../../../../components/layouts/checkout-layout/checkout-layout';
import { useSource } from '@companydotcom/providers';
import { WaitlistAlert } from '../../shared/components/waitlist-alert';
import { EventFullAlert } from '../../shared/components/event-full-alert';
import { useAppDispatch } from '../../../../hooks';
import { resetGuestsToRegister } from '../../acg-add-guest';
import { setDiscountCodeString, setEventDiscount } from '../checkout-slice';

export const CheckoutOrderEventError = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const { getLocalCart } = useAcgCartStorage();
  const { t } = useTranslation();
  const source = useSource();
  const location = useLocation();
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const dispatch = useAppDispatch();

  const locationState = location.state as CheckoutLocationStateProps;
  const cart = useMemo(() => getLocalCart(), [getLocalCart]);
  const event = useMemo(() => cart?.items?.event, [cart]);

  const { clearLocalCart } = useAcgCartStorage();

  const cancelOrderHandler = useCallback(() => {
    clearLocalCart();
    dispatch(resetGuestsToRegister([]));
    dispatch(setDiscountCodeString(''));
    dispatch(setEventDiscount([]));
  }, [clearLocalCart, dispatch]);

  return (
    <CheckoutLayout>
      <Box className="checkout__checkoutHeader" width="full" textAlign={['center', 'left']}>
        <Heading size={isMobile ? 'hs-md' : 'hs-2xl'}>Checkout</Heading>
        <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
      </Box>
      <CheckoutAlertContent>
        <Box fontSize="sm">
          <Text textStyle="sm">{t('acg.checkout.orderStep.pleaseEnterPaymentInformation')}</Text>
          <Trans i18nKey="acg.checkout.orderStep.agreeToTerms">
            <Text textStyle="sm">
              By clicking Place Order, you are agreeing to the
              <Link
                sx={{ display: 'inline' }}
                href={source?.footerLinks?.termsOfUse ?? ''}
                isExternal
              >
                Terms and Conditions.
              </Link>
            </Text>
          </Trans>
        </Box>
        <CheckoutSecureAndEncrypted />
      </CheckoutAlertContent>
      {locationState?.shouldShouldWaitlistAlert &&
        event?.eventRecordKey &&
        locationState?.totalWaitList && (
          <WaitlistAlert
            eventKey={event?.eventRecordKey}
            waitListedRegistrants={locationState?.totalWaitList}
          />
        )}
      {(locationState?.shouldShowEventFullAlert ||
        locationState?.shouldShowEventOverCapacityAlert) && (
        <EventFullAlert
          variant="eventAlreadyFull"
          cancelOrderHandler={cancelOrderHandler}
          isCancelled={isCancelled}
          setIsCancelled={setIsCancelled}
        />
      )}
    </CheckoutLayout>
  );
};
