import React from 'react';
import {
  VStack,
  Stack,
  CheckboxGroup,
  Checkbox,
  useMediaQuery,
  // Icon,
  // Tooltip,
  // useMediaQuery,
} from '@companydotcom/potion';
import {
  InputField,
  // FontAwesomeIcon,
  SelectField,
} from '@companydotcom/ui';
// import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation, TFunction } from 'react-i18next';
import { BillingAddressForm } from './billing-address-form';

export interface ACGFormProps {
  register: any;
  control?: any;
  watch?: any;
  errors: unknown;
  pendingApproval?: boolean;
  isCheckout: boolean;
  isLoading?: boolean;
  displayBillingAddressForm?: boolean;
  isSavedPayment?: boolean;
  setValue?: any;
  isAutoRenewChecked?: boolean;
  addNewType?: boolean;
  getValues?: any;
  showAutoRenew?: boolean;
}

export const ACHForm: React.FC<ACGFormProps> = ({
  register,
  errors,
  control,
  watch,
  setValue,
  // pendingApproval,
  isCheckout = true, //defaults to true, required. set to false inorder to render auto-renewal option
  isLoading,
  displayBillingAddressForm = false,
  isSavedPayment = false,
  isAutoRenewChecked,
  addNewType = false,
  getValues,
  showAutoRenew,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 48rem)');
  const { t } = useTranslation();

  // value for checking and savings are C and S respectively, a netforum requirement
  const accountTypeOptions = (t: TFunction) => [
    { value: 'C', label: t('acg.checkout.orderStep.inputs.accountType.options.checking') },
    { value: 'S', label: t('acg.checkout.orderStep.inputs.accountType.options.savings') },
  ];

  return (
    <VStack mt={6} alignItems="flex-start" spacing={6}>
      <Stack direction={['column', null, null, 'row']} spacing={8} width="full" align="flex-start">
        <InputField
          isLoading={isLoading}
          register={register}
          errors={errors}
          name="achDetails.accountHolderName"
          label={t('acg.checkout.orderStep.inputs.accountholderName.label')}
        />

        <SelectField
          isLoading={isLoading}
          register={register}
          errors={errors}
          name="achDetails.accountType"
          label={t('acg.checkout.orderStep.inputs.accountType.label')}
        >
          {accountTypeOptions(t).map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </SelectField>
      </Stack>
      <Stack direction={['column', null, null, 'row']} spacing={8} width="full" align="flex-start">
        <InputField
          isLoading={isLoading}
          register={register}
          errors={errors}
          name="achDetails.routingNumber"
          label={t('acg.checkout.orderStep.inputs.routingNumber.label')}
          maxLength={9}
        />
        <InputField
          isLoading={isLoading}
          register={register}
          errors={errors}
          name="achDetails.accountNumber"
          label={t('acg.checkout.orderStep.inputs.accountNumber.label')}
        />
      </Stack>
      {isCheckout ? (
        <CheckboxGroup>
          <VStack alignItems="flex-start" spacing={3}>
            {/* // TODO */}
            {/* {pendingApproval && (
              <Stack direction="row" spacing={2}>
                <Checkbox {...register('monthlyInstallments')} width="max">
                  {t('acg.checkout.orderStep.inputs.monthlyInstallments.label')}
                </Checkbox>
                <Tooltip
                  shouldWrapChildren
                  hasArrow
                  placement="top"
                  label="This will change your membership to a more affordable monthly installment plan."
                  fontSize="sm"
                  textAlign="center"
                  defaultIsOpen={isMobile}
                  openDelay={isMobile ? 50 : undefined}
                  closeDelay={isMobile ? 300 : undefined}
                >
                  <Icon color="blue.500" as={FontAwesomeIcon} icon={faCircleInfo} boxSize={4} />
                </Tooltip>
              </Stack>
            )} */}
            {showAutoRenew ? (
              <Checkbox isDisabled={isLoading} {...register('autoRenew')}>
                {t('acg.checkout.orderStep.inputs.autopayRenewal.label')}
              </Checkbox>
            ) : null}
            <Checkbox
              isDisabled={isAutoRenewChecked || isSavedPayment || isLoading}
              isChecked={isAutoRenewChecked || displayBillingAddressForm || isSavedPayment}
              onChange={e => {
                setValue?.('savePaymentInformation', !!e.target.checked, { shouldValidate: true });
              }}
            >
              {t('acg.checkout.orderStep.inputs.savePaymentInformation.label')}
            </Checkbox>
          </VStack>
        </CheckboxGroup>
      ) : (
        !isMobile && (
          <CheckboxGroup>
            <VStack alignItems="flex-start" spacing={3}>
              <Checkbox isDisabled={isLoading} {...register('makeDefault')}>
                Make Default Payment Method
              </Checkbox>
            </VStack>
          </CheckboxGroup>
        )
      )}
      {isAutoRenewChecked || addNewType || getValues('savePaymentInformation') ? (
        <InputField
          register={register}
          errors={errors}
          name="cardNickname"
          label="Nickname"
          isRequired={isSavedPayment}
          helperText="Required"
        />
      ) : null}
      <BillingAddressForm
        displayBillingAddressForm={displayBillingAddressForm}
        isSavedPayment={isSavedPayment}
        isLoading={isLoading}
        errors={errors}
        register={register}
        control={control}
        watch={watch}
      />
      {isMobile && !isCheckout && (
        <CheckboxGroup>
          <VStack alignItems="flex-start" spacing={3}>
            <Checkbox isDisabled={isLoading} {...register('makeDefault')}>
              Make Default Payment Method
            </Checkbox>
          </VStack>
        </CheckboxGroup>
      )}
    </VStack>
  );
};
