import React from 'react';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { AutoRenew } from '../../../features/acg/acg-auto-renew';

export const AutoRenewPage: React.FC = () => {
  return (
    <PageLayout>
      <PageLayoutContent>
        <AutoRenew />
      </PageLayoutContent>
    </PageLayout>
  );
};
