export const acgRefData = {
  transactionType: [
    {
      recordName: 'Buyout',
    },
    {
      recordName: 'Challenged',
    },
    {
      recordName: 'Chapter 11',
    },
    {
      recordName: 'Control Investment',
    },
    {
      recordName: 'ESOP',
    },
    {
      recordName: 'Merger',
    },
    {
      recordName: 'Minority Investment',
    },
    {
      recordName: 'Other',
    },
    {
      recordName: 'Recap',
    },
  ],
  addressType: [
    {
      recordKey: 'homeAddress',
      recordName: 'Home Address',
    },
    {
      recordKey: 'workAddress',
      recordName: 'Work Address',
    },
  ],
  phoneType: [
    {
      recordKey: 'workPhone',
      recordName: 'Work Phone',
    },
    {
      recordKey: 'mobilePhone',
      recordName: 'Mobile Phone',
    },
  ],
  industry: [
    {
      recordKey: 'a8baf646-ddfa-4f94-8575-8c95d5a56283',
      recordName: 'Advertising',
    },
    {
      recordKey: '58f22588-c792-4fee-90df-02f0e908efc3',
      recordName: 'Aerospace/defense',
    },
    {
      recordKey: '4e26bb3a-3c18-4f9a-ad52-3820305a0c81',
      recordName: 'Agriculture',
    },
    {
      recordKey: '625fed3c-fda5-4fa5-a593-478c4e4e6764',
      recordName: 'Automotive',
    },
    {
      recordKey: '27cdd4b7-2db8-4565-aeff-71eec4236f4e',
      recordName: 'Banking & Financial Services',
    },
    {
      recordKey: '0a1a2c16-7b62-4650-8e0f-3bb0ca635956',
      recordName: 'Business services',
    },
    {
      recordKey: '73af6959-485b-41f6-9be1-936a72585564',
      recordName: 'Chemicals and allied products',
    },
    {
      recordKey: '3b368b87-7083-4bbb-ace8-2d73d65878cb',
      recordName: 'Construction, Engineering & Architectural',
    },
    {
      recordKey: '65724546-371e-43ff-b819-8c1ab1912d0f',
      recordName: 'Consumer Products',
    },
    {
      recordKey: 'f62cb503-4f89-4337-9eb3-451d31f102a0',
      recordName: 'Education',
    },
    {
      recordKey: '988ba174-d6dd-41fe-8a56-788059d3d545',
      recordName: 'Energy',
    },
    {
      recordKey: 'e109f850-e7bc-4900-9d17-ac81dc369ecf',
      recordName: 'Entertainment, Media & Publishing',
    },
    {
      recordKey: '2669f1dc-9207-4eac-b399-92d1cdf850ad',
      recordName: 'Food & Beverage',
    },
    {
      recordKey: '499c7a49-56d6-42ba-985a-67bf2c8f4d8f',
      recordName: 'Franchise',
    },
    {
      recordKey: '02bf38c2-e27a-4dba-83a8-dc928f0780c4',
      recordName: 'Health Care & Pharmaceuticals',
    },
    {
      recordKey: 'deefc7c2-7aee-4bf7-9305-16e68bb707c0',
      recordName: 'Insurance',
    },
    {
      recordKey: '748aef4c-a4bd-4ddd-99dd-93a49131aada',
      recordName: 'Legal services',
    },
    {
      recordKey: '8fa77184-bd99-4527-bed2-4590eb657f3c',
      recordName: 'Management consulting',
    },
    {
      recordKey: '7d7394c0-173d-4382-9d71-cce0d5a36244',
      recordName: 'Manufacturing',
    },
    {
      recordKey: 'cef8126d-75a9-4d42-b7c7-ab79031185f6',
      recordName: 'Medical devices',
    },
    {
      recordKey: 'ee4eaba9-fb25-426f-be1f-4565af26e1f0',
      recordName: 'Mining & Extraction',
    },
    {
      recordKey: '7697bc07-d830-4c86-bb24-3338c15d3a96',
      recordName: 'Miscellaneous services',
    },
    {
      recordKey: '32652baa-b885-4340-be18-7ceb734d8a00',
      recordName: 'Nano & Biotech',
    },
    {
      recordKey: '21875e0a-93cf-4edf-802d-e767c6df92e0',
      recordName: 'Packaging',
    },
    {
      recordKey: 'ee7f3c79-16a2-4f0c-b286-a7bb84d2ee99',
      recordName: 'Real estate',
    },
    {
      recordKey: '09959893-3c46-4989-98a8-4a8509a14a39',
      recordName: 'Retail',
    },
    {
      recordKey: '02fb513c-4860-4fae-a8cb-27d6d538666a',
      recordName: 'Technology',
    },
    {
      recordKey: '63e94ed4-60e4-4e29-a66a-da36db9f2d86',
      recordName: 'Transportation & Logistics',
    },
    {
      recordKey: '9e8fe2ae-ed84-48f2-9bb1-7cbeb06b2b28',
      recordName: 'Waste and Environmental Services',
    },
    {
      recordKey: '4ae34748-bfb1-44e3-902d-c5d2fdd8cc7b',
      recordName: 'Wholesale',
    },
  ],
  prefix: [
    {
      recordName: '',
    },
    {
      recordName: 'Dr.',
    },
    {
      recordName: 'Hon.',
    },
    {
      recordName: 'Miss',
    },
    {
      recordName: 'Mr.',
    },
    {
      recordName: 'Mrs.',
    },
    {
      recordName: 'Ms.',
    },
    {
      recordName: 'Rev.',
    },
  ],
  areasOfExpertise: [
    {
      recordKey: '3e1325f5-4eea-4ff5-a96f-63dfbfd5653a',
      recordName: 'Accounting & Tax - Audit',
    },
    {
      recordKey: '5e8302ee-ce2f-4fd5-98e7-b394ddb0234b',
      recordName: 'Accounting & Tax - M & A',
    },
    {
      recordKey: '01408f42-e430-4844-b68c-62b5c6fcb93e',
      recordName: 'Accounting & Tax - Tax Planning',
    },
    {
      recordKey: 'b5136590-cf71-4a99-a654-815fed609bf9',
      recordName: 'Accounting & Tax due diligence',
    },
    {
      recordKey: '2035c8d3-d318-43a0-9c4e-28ab9e5028df',
      recordName: 'Acquisition implementation after purchase',
    },
    {
      recordKey: 'c5dea156-2988-4f9e-890c-ba41630554bc',
      recordName: 'Asset appraisals/valuations',
    },
    {
      recordKey: '97e14754-19f0-4fe0-a8a1-e0e9919ac996',
      recordName: 'Commercial development',
    },
    {
      recordKey: 'b41f15db-8b64-46a9-aec2-74fff671590d',
      recordName: 'Debt placement',
    },
    {
      recordKey: 'fc2d107d-943c-4c1d-843a-10ce5d7bfd7d',
      recordName: 'Fairness Opinion',
    },
    {
      recordKey: '72ca9f1f-6224-4f9c-9408-a5b25c673916',
      recordName: 'Import/Export',
    },
    {
      recordKey: '7f388a0f-cb61-4850-ae39-f3618b43a63d',
      recordName: 'Intellectual Property',
    },
    {
      recordKey: '07a2f9f1-cc16-46af-a383-5299d6764f22',
      recordName: 'Joint Ventures',
    },
    {
      recordKey: '843ef928-420b-42bc-a17c-f2c94fde22e7',
      recordName: 'Legal-SEC',
    },
    {
      recordKey: '0b997c97-1cf8-42c0-ad1f-65bf570286cb',
      recordName: 'Leveraged buyouts',
    },
    {
      recordKey: '0dd779d1-cc39-457d-8fda-b02569a932f8',
      recordName: 'Litigation',
    },
    {
      recordKey: '583400e5-4bb2-4b30-99b8-951e89f921e5',
      recordName: 'Management audits',
    },
    {
      recordKey: '09b8776d-ce46-48e0-a4c0-24e3ad5bb013',
      recordName: 'Manufacturing evaluations',
    },
    {
      recordKey: 'b2fdb49a-afd5-4b3d-8a80-c8a586022462',
      recordName: 'Marketing',
    },
    {
      recordKey: 'ffcbd288-2766-441a-ba61-70610c8e17f4',
      recordName: 'Mergers & Acquisitions - Non-US',
    },
    {
      recordKey: '50cdee20-74c4-499b-b6f9-8e054812daf6',
      recordName: 'Mergers & Acquisitions - Spin off',
    },
    {
      recordKey: 'fbeda53f-ae3b-4f63-b6b9-be178eb5c407',
      recordName: 'Mergers & Acquisitions - US',
    },
    {
      recordKey: '0380dc8d-f00b-4616-a5a8-3b695f431423',
      recordName: 'Operational audits',
    },
    {
      recordKey: 'cc80b29d-4785-4859-b6c8-81506ce39d46',
      recordName: 'Project finance',
    },
    {
      recordKey: '2fa8d583-ec8d-45bb-ab25-69e97be3e7c2',
      recordName: 'Public/Investor Relations',
    },
    {
      recordKey: '0addf317-e42d-42be-a415-e2d7671fefde',
      recordName: 'Research',
    },
    {
      recordKey: 'ee8e8812-6786-45e8-aec0-b03c235a30e5',
      recordName: 'Turnaround',
    },
  ],
  country: [
    {
      recordName: 'ABKHAZIA',
      countryIsoNumber: '',
    },
    {
      recordName: 'AFGHANISTAN',
      countryIsoNumber: '004',
    },
    {
      recordName: 'AKROTIRI AND DHEKELIA',
      countryIsoNumber: '',
    },
    {
      recordName: 'ÅLAND',
      countryIsoNumber: '248',
    },
    {
      recordName: 'ALBANIA',
      countryIsoNumber: '008',
    },
    {
      recordName: 'ALGERIA',
      countryIsoNumber: '012',
    },
    {
      recordName: 'AMERICAN SAMOA',
      countryIsoNumber: '016',
    },
    {
      recordName: 'ANDORRA',
      countryIsoNumber: '020',
    },
    {
      recordName: 'ANGOLA',
      countryIsoNumber: '024',
    },
    {
      recordName: 'ANGUILLA',
      countryIsoNumber: '660',
    },
    {
      recordName: 'ANTIGUA',
      countryIsoNumber: '028',
    },
    {
      recordName: 'ARGENTINA',
      countryIsoNumber: '032',
    },
    {
      recordName: 'ARMENIA',
      countryIsoNumber: '051',
    },
    {
      recordName: 'ARUBA',
      countryIsoNumber: '533',
    },
    {
      recordName: 'ASCENSION',
      countryIsoNumber: '',
    },
    {
      recordName: 'AUSTRALIA',
      countryIsoNumber: '036',
    },
    {
      recordName: 'AUSTRIA',
      countryIsoNumber: '040',
    },
    {
      recordName: 'AZERBAIJAN',
      countryIsoNumber: '031',
    },
    {
      recordName: 'BAHAMAS',
      countryIsoNumber: '044',
    },
    {
      recordName: 'BAHRAIN',
      countryIsoNumber: '048',
    },
    {
      recordName: 'BANGLADESH',
      countryIsoNumber: '050',
    },
    {
      recordName: 'BARBADOS',
      countryIsoNumber: '052',
    },
    {
      recordName: 'BELARUS',
      countryIsoNumber: '112',
    },
    {
      recordName: 'BELGIUM',
      countryIsoNumber: '056',
    },
    {
      recordName: 'BELIZE',
      countryIsoNumber: '084',
    },
    {
      recordName: 'BENIN',
      countryIsoNumber: '204',
    },
    {
      recordName: 'BERMUDA',
      countryIsoNumber: '060',
    },
    {
      recordName: 'BHUTAN',
      countryIsoNumber: '064',
    },
    {
      recordName: 'BOLIVIA',
      countryIsoNumber: '068',
    },
    {
      recordName: 'BOSNIA AND HERZEGOVINA',
      countryIsoNumber: '070',
    },
    {
      recordName: 'BOTSWANA',
      countryIsoNumber: '072',
    },
    {
      recordName: 'BRAZIL',
      countryIsoNumber: '076',
    },
    {
      recordName: 'BRITISH VIRGIN ISLANDS',
      countryIsoNumber: '086',
    },
    {
      recordName: 'BRUNEI',
      countryIsoNumber: '096',
    },
    {
      recordName: 'BULGARIA',
      countryIsoNumber: '100',
    },
    {
      recordName: 'BURKINA FASO',
      countryIsoNumber: '854',
    },
    {
      recordName: 'BURUNDI',
      countryIsoNumber: '108',
    },
    {
      recordName: 'CAMBODIA',
      countryIsoNumber: '116',
    },
    {
      recordName: 'CAMEROON',
      countryIsoNumber: '120',
    },
    {
      recordName: 'CANADA',
      countryIsoNumber: '124',
    },
    {
      recordName: 'CAPE VERDE',
      countryIsoNumber: '132',
    },
    {
      recordName: 'CAYMAN ISLANDS',
      countryIsoNumber: '136',
    },
    {
      recordName: 'CENTRAL AFRICAN REPUBLIC',
      countryIsoNumber: '140',
    },
    {
      recordName: 'CHAD',
      countryIsoNumber: '148',
    },
    {
      recordName: 'CHILE',
      countryIsoNumber: '152',
    },
    {
      recordName: 'CHINA',
      countryIsoNumber: '156',
    },
    {
      recordName: 'CHRISTMAS ISLAND',
      countryIsoNumber: '162',
    },
    {
      recordName: 'COCOS (KEELING) ISLANDS',
      countryIsoNumber: '166',
    },
    {
      recordName: 'COLOMBIA',
      countryIsoNumber: '170',
    },
    {
      recordName: 'COMOROS',
      countryIsoNumber: '174',
    },
    {
      recordName: 'CONGO',
      countryIsoNumber: '178',
    },
    {
      recordName: 'COOK ISLANDS',
      countryIsoNumber: '184',
    },
    {
      recordName: 'COSTA RICA',
      countryIsoNumber: '188',
    },
    {
      recordName: "Côte d'Ivoire",
      countryIsoNumber: '384',
    },
    {
      recordName: 'CROATIA',
      countryIsoNumber: '191',
    },
    {
      recordName: 'CUBA',
      countryIsoNumber: '192',
    },
    {
      recordName: 'CYPRUS',
      countryIsoNumber: '196',
    },
    {
      recordName: 'CZECH REPUBLIC',
      countryIsoNumber: '203',
    },
    {
      recordName: 'DENMARK',
      countryIsoNumber: '208',
    },
    {
      recordName: 'DJIBOUTI',
      countryIsoNumber: '262',
    },
    {
      recordName: 'DOMINICA',
      countryIsoNumber: '212',
    },
    {
      recordName: 'DOMINICAN REPUBLIC',
      countryIsoNumber: '214',
    },
    {
      recordName: 'EAST TIMOR',
      countryIsoNumber: '626',
    },
    {
      recordName: 'ECUADOR',
      countryIsoNumber: '218',
    },
    {
      recordName: 'EGYPT',
      countryIsoNumber: '818',
    },
    {
      recordName: 'EL SALVADOR',
      countryIsoNumber: '222',
    },
    {
      recordName: 'EQUATORIAL GUINEA',
      countryIsoNumber: '226',
    },
    {
      recordName: 'ERITREA',
      countryIsoNumber: '232',
    },
    {
      recordName: 'ESTONIA',
      countryIsoNumber: '233',
    },
    {
      recordName: 'ETHIOPIA',
      countryIsoNumber: '231',
    },
    {
      recordName: 'FALKLAND ISLANDS',
      countryIsoNumber: '238',
    },
    {
      recordName: 'FAROE ISLANDS',
      countryIsoNumber: '234',
    },
    {
      recordName: 'FIJI',
      countryIsoNumber: '242',
    },
    {
      recordName: 'FINLAND',
      countryIsoNumber: '246',
    },
    {
      recordName: 'FRANCE',
      countryIsoNumber: '250',
    },
    {
      recordName: 'FRENCH POLYNESIA',
      countryIsoNumber: '258',
    },
    {
      recordName: 'GABON',
      countryIsoNumber: '266',
    },
    {
      recordName: 'GAMBIA',
      countryIsoNumber: '270',
    },
    {
      recordName: 'GEORGIA',
      countryIsoNumber: '268',
    },
    {
      recordName: 'GERMANY',
      countryIsoNumber: '276',
    },
    {
      recordName: 'GHANA',
      countryIsoNumber: '288',
    },
    {
      recordName: 'GIBRALTAR',
      countryIsoNumber: '292',
    },
    {
      recordName: 'GREECE',
      countryIsoNumber: '300',
    },
    {
      recordName: 'GREENLAND',
      countryIsoNumber: '304',
    },
    {
      recordName: 'GRENADA',
      countryIsoNumber: '308',
    },
    {
      recordName: 'GUAM',
      countryIsoNumber: '316',
    },
    {
      recordName: 'GUATEMALA',
      countryIsoNumber: '320',
    },
    {
      recordName: 'GUINEA',
      countryIsoNumber: '324',
    },
    {
      recordName: 'GUINEA-BISSAU',
      countryIsoNumber: '624',
    },
    {
      recordName: 'GUYANA',
      countryIsoNumber: '328',
    },
    {
      recordName: 'HAITI',
      countryIsoNumber: '332',
    },
    {
      recordName: 'HONDURAS',
      countryIsoNumber: '340',
    },
    {
      recordName: 'HONG KONG',
      countryIsoNumber: '344',
    },
    {
      recordName: 'HUNGARY',
      countryIsoNumber: '348',
    },
    {
      recordName: 'ICELAND',
      countryIsoNumber: '352',
    },
    {
      recordName: 'INDIA',
      countryIsoNumber: '356',
    },
    {
      recordName: 'INDONESIA',
      countryIsoNumber: '360',
    },
    {
      recordName: 'IRAN',
      countryIsoNumber: '364',
    },
    {
      recordName: 'IRAQ',
      countryIsoNumber: '368',
    },
    {
      recordName: 'IRELAND',
      countryIsoNumber: '372',
    },
    {
      recordName: 'ISLE OF MAN',
      countryIsoNumber: '833',
    },
    {
      recordName: 'ISRAEL',
      countryIsoNumber: '376',
    },
    {
      recordName: 'ITALY',
      countryIsoNumber: '380',
    },
    {
      recordName: 'JAMAICA',
      countryIsoNumber: '388',
    },
    {
      recordName: 'JAPAN',
      countryIsoNumber: '392',
    },
    {
      recordName: 'JERSEY',
      countryIsoNumber: '832',
    },
    {
      recordName: 'JORDAN',
      countryIsoNumber: '400',
    },
    {
      recordName: 'KAZAKHSTAN',
      countryIsoNumber: '398',
    },
    {
      recordName: 'KENYA',
      countryIsoNumber: '404',
    },
    {
      recordName: 'KIRIBATI',
      countryIsoNumber: '296',
    },
    {
      recordName: 'KOSOVO',
      countryIsoNumber: '',
    },
    {
      recordName: 'KUWAIT',
      countryIsoNumber: '414',
    },
    {
      recordName: 'KYRGYZSTAN',
      countryIsoNumber: '417',
    },
    {
      recordName: 'LAOS',
      countryIsoNumber: '',
    },
    {
      recordName: 'LATVIA',
      countryIsoNumber: '428',
    },
    {
      recordName: 'LEBANON',
      countryIsoNumber: '422',
    },
    {
      recordName: 'LESOTHO',
      countryIsoNumber: '426',
    },
    {
      recordName: 'LIBERIA',
      countryIsoNumber: '430',
    },
    {
      recordName: 'LIBYA',
      countryIsoNumber: '434',
    },
    {
      recordName: 'LIECHTENSTEIN',
      countryIsoNumber: '438',
    },
    {
      recordName: 'LITHUANIA',
      countryIsoNumber: '440',
    },
    {
      recordName: 'LUXEMBOURG',
      countryIsoNumber: '442',
    },
    {
      recordName: 'MACAO',
      countryIsoNumber: '',
    },
    {
      recordName: 'MACEDONIA',
      countryIsoNumber: '807',
    },
    {
      recordName: 'MADAGASCAR',
      countryIsoNumber: '450',
    },
    {
      recordName: 'MALAWI',
      countryIsoNumber: '454',
    },
    {
      recordName: 'MALAYSIA',
      countryIsoNumber: '458',
    },
    {
      recordName: 'MALDIVES',
      countryIsoNumber: '462',
    },
    {
      recordName: 'MALI',
      countryIsoNumber: '466',
    },
    {
      recordName: 'MALTA',
      countryIsoNumber: '470',
    },
    {
      recordName: 'MARSHALL ISLANDS',
      countryIsoNumber: '584',
    },
    {
      recordName: 'MAURITANIA',
      countryIsoNumber: '478',
    },
    {
      recordName: 'MAURITIUS',
      countryIsoNumber: '480',
    },
    {
      recordName: 'MAYOTTE',
      countryIsoNumber: '175',
    },
    {
      recordName: 'MEXICO',
      countryIsoNumber: '484',
    },
    {
      recordName: 'MICRONESIA',
      countryIsoNumber: '583',
    },
    {
      recordName: 'MOLDOVA',
      countryIsoNumber: '498',
    },
    {
      recordName: 'MONACO',
      countryIsoNumber: '492',
    },
    {
      recordName: 'MONGOLIA',
      countryIsoNumber: '496',
    },
    {
      recordName: 'MONTENEGRO',
      countryIsoNumber: '499',
    },
    {
      recordName: 'MONTSERRAT',
      countryIsoNumber: '500',
    },
    {
      recordName: 'MOROCCO',
      countryIsoNumber: '504',
    },
    {
      recordName: 'MOZAMBIQUE',
      countryIsoNumber: '508',
    },
    {
      recordName: 'MYANMAR',
      countryIsoNumber: '104',
    },
    {
      recordName: 'NAGORNO-KARABAKH',
      countryIsoNumber: '',
    },
    {
      recordName: 'NAMIBIA',
      countryIsoNumber: '516',
    },
    {
      recordName: 'NAURU',
      countryIsoNumber: '520',
    },
    {
      recordName: 'NEPAL',
      countryIsoNumber: '524',
    },
    {
      recordName: 'NETHERLANDS',
      countryIsoNumber: '528',
    },
    {
      recordName: 'NETHERLANDS ANTILLES',
      countryIsoNumber: '530',
    },
    {
      recordName: 'NEW CALEDONIA',
      countryIsoNumber: '540',
    },
    {
      recordName: 'NEW ZEALAND',
      countryIsoNumber: '554',
    },
    {
      recordName: 'NICARAGUA',
      countryIsoNumber: '558',
    },
    {
      recordName: 'NIGER',
      countryIsoNumber: '562',
    },
    {
      recordName: 'NIGERIA',
      countryIsoNumber: '566',
    },
    {
      recordName: 'NIUE',
      countryIsoNumber: '570',
    },
    {
      recordName: 'NORFOLK ISLAND',
      countryIsoNumber: '574',
    },
    {
      recordName: 'NORTH KOREA',
      countryIsoNumber: '408',
    },
    {
      recordName: 'NORTHERN CYPRUS',
      countryIsoNumber: '',
    },
    {
      recordName: 'NORTHERN MARIANA ISLANDS',
      countryIsoNumber: '580',
    },
    {
      recordName: 'NORWAY',
      countryIsoNumber: '578',
    },
    {
      recordName: 'OMAN',
      countryIsoNumber: '512',
    },
    {
      recordName: 'PAKISTAN',
      countryIsoNumber: '586',
    },
    {
      recordName: 'PALAU',
      countryIsoNumber: '585',
    },
    {
      recordName: 'PALESTINE',
      countryIsoNumber: '275',
    },
    {
      recordName: 'PANAMA',
      countryIsoNumber: '591',
    },
    {
      recordName: 'PAPUA NEW GUINEA',
      countryIsoNumber: '598',
    },
    {
      recordName: 'PARAGUAY',
      countryIsoNumber: '600',
    },
    {
      recordName: "PEOPLE'S REPUBLIC OF CONGO",
      countryIsoNumber: '180',
    },
    {
      recordName: 'PERU',
      countryIsoNumber: '604',
    },
    {
      recordName: 'PHILIPPINES',
      countryIsoNumber: '608',
    },
    {
      recordName: 'PITCAIRN ISLANDS',
      countryIsoNumber: '612',
    },
    {
      recordName: 'POLAND',
      countryIsoNumber: '616',
    },
    {
      recordName: 'PORTUGAL',
      countryIsoNumber: '620',
    },
    {
      recordName: 'PUERTO RICO',
      countryIsoNumber: '630',
    },
    {
      recordName: 'QATAR',
      countryIsoNumber: '634',
    },
    {
      recordName: 'ROMANIA',
      countryIsoNumber: '642',
    },
    {
      recordName: 'RUSSIA',
      countryIsoNumber: '643',
    },
    {
      recordName: 'RWANDA',
      countryIsoNumber: '646',
    },
    {
      recordName: 'SAINT BARTHéLEMY',
      countryIsoNumber: '652',
    },
    {
      recordName: 'SAINT HELENA',
      countryIsoNumber: '654',
    },
    {
      recordName: 'SAINT KITTS AND NEVIS',
      countryIsoNumber: '659',
    },
    {
      recordName: 'SAINT LUCIA',
      countryIsoNumber: '662',
    },
    {
      recordName: 'SAINT MARTIN',
      countryIsoNumber: '663',
    },
    {
      recordName: 'SAINT PIERRE AND MIQUELON',
      countryIsoNumber: '666',
    },
    {
      recordName: 'Saint Vincent and the Grenadines',
      countryIsoNumber: '670',
    },
    {
      recordName: 'SAMOA',
      countryIsoNumber: '882',
    },
    {
      recordName: 'SAN MARINO',
      countryIsoNumber: '674',
    },
    {
      recordName: 'Sao Tome and Principe',
      countryIsoNumber: '678',
    },
    {
      recordName: 'SAUDI ARABIA',
      countryIsoNumber: '682',
    },
    {
      recordName: 'SENEGAL',
      countryIsoNumber: '686',
    },
    {
      recordName: 'SERBIA',
      countryIsoNumber: '688',
    },
    {
      recordName: 'SEYCHELLES',
      countryIsoNumber: '690',
    },
    {
      recordName: 'SIERRA LEONE',
      countryIsoNumber: '694',
    },
    {
      recordName: 'SINGAPORE',
      countryIsoNumber: '702',
    },
    {
      recordName: 'SLOVAKIA',
      countryIsoNumber: '703',
    },
    {
      recordName: 'SLOVENIA',
      countryIsoNumber: '705',
    },
    {
      recordName: 'SOLOMON ISLANDS',
      countryIsoNumber: '090',
    },
    {
      recordName: 'SOMALIA',
      countryIsoNumber: '706',
    },
    {
      recordName: 'SOMALILAND',
      countryIsoNumber: '',
    },
    {
      recordName: 'SOUTH AFRICA',
      countryIsoNumber: '710',
    },
    {
      recordName: 'SOUTH KOREA',
      countryIsoNumber: '410',
    },
    {
      recordName: 'SOUTH OSSETIA',
      countryIsoNumber: '',
    },
    {
      recordName: 'SPAIN',
      countryIsoNumber: '724',
    },
    {
      recordName: 'SRI LANKA',
      countryIsoNumber: '144',
    },
    {
      recordName: 'SUDAN',
      countryIsoNumber: '736',
    },
    {
      recordName: 'SURINAME',
      countryIsoNumber: '740',
    },
    {
      recordName: 'SVALBARD',
      countryIsoNumber: '744',
    },
    {
      recordName: 'SWAZILAND',
      countryIsoNumber: '748',
    },
    {
      recordName: 'SWEDEN',
      countryIsoNumber: '752',
    },
    {
      recordName: 'SWITZERLAND',
      countryIsoNumber: '756',
    },
    {
      recordName: 'SYRIA',
      countryIsoNumber: '760',
    },
    {
      recordName: 'TAIWAN',
      countryIsoNumber: '158',
    },
    {
      recordName: 'TAJIKISTAN',
      countryIsoNumber: '762',
    },
    {
      recordName: 'TANZANIA',
      countryIsoNumber: '834',
    },
    {
      recordName: 'THAILAND',
      countryIsoNumber: '764',
    },
    {
      recordName: 'TOGO',
      countryIsoNumber: '768',
    },
    {
      recordName: 'TOKELAU',
      countryIsoNumber: '772',
    },
    {
      recordName: 'TONGA',
      countryIsoNumber: '776',
    },
    {
      recordName: 'TRANSNISTRIA',
      countryIsoNumber: '',
    },
    {
      recordName: 'TRINIDAD AND TOBAGO',
      countryIsoNumber: '780',
    },
    {
      recordName: 'TRISTAN DA CUNHA',
      countryIsoNumber: '',
    },
    {
      recordName: 'TUNISIA',
      countryIsoNumber: '788',
    },
    {
      recordName: 'TURKEY',
      countryIsoNumber: '792',
    },
    {
      recordName: 'TURKMENISTAN',
      countryIsoNumber: '795',
    },
    {
      recordName: 'TURKS and CAICOS ISLANDS',
      countryIsoNumber: '796',
    },
    {
      recordName: 'TUVALU',
      countryIsoNumber: '798',
    },
    {
      recordName: 'UGANDA',
      countryIsoNumber: '800',
    },
    {
      recordName: 'UKRAINE',
      countryIsoNumber: '804',
    },
    {
      recordName: 'UNITED ARAB EMIRATES',
      countryIsoNumber: '784',
    },
    {
      recordName: 'UNITED KINGDOM',
      countryIsoNumber: '826',
    },
    {
      recordName: 'UNITED STATES',
      countryIsoNumber: '840',
    },
    {
      recordName: 'UNITED STATES VIRGIN ISLANDS',
      countryIsoNumber: '',
    },
    {
      recordName: 'URUGUAY',
      countryIsoNumber: '858',
    },
    {
      recordName: 'UZBEKISTAN',
      countryIsoNumber: '860',
    },
    {
      recordName: 'VANUATU',
      countryIsoNumber: '548',
    },
    {
      recordName: 'VATICAN',
      countryIsoNumber: '',
    },
    {
      recordName: 'VENEZUELA',
      countryIsoNumber: '862',
    },
    {
      recordName: 'VIETNAM',
      countryIsoNumber: '704',
    },
    {
      recordName: 'WALLIS and FUTUNA',
      countryIsoNumber: '876',
    },
    {
      recordName: 'WESTERN SAHARA',
      countryIsoNumber: '732',
    },
    {
      recordName: 'YEMEN',
      countryIsoNumber: '887',
    },
    {
      recordName: 'ZAMBIA',
      countryIsoNumber: '894',
    },
    {
      recordName: 'ZIMBABWE',
      countryIsoNumber: '716',
    },
  ],
  referer: [
    {
      recordName: 'ACG News',
    },
    {
      recordName: 'Annual Conf Announcement',
    },
    {
      recordName: 'Event Announcement',
    },
    {
      recordName: 'Event Attendance',
    },
    {
      recordName: 'Magazine',
    },
    {
      recordName: 'Member',
    },
    {
      recordName: 'Newspaper',
    },
    {
      recordName: 'Online',
    },
    {
      recordName: 'Online – eNewsletter',
    },
    {
      recordName: 'Online – social network',
    },
    {
      recordName: 'Online – Website',
    },
    {
      recordName: 'Other',
    },
    {
      recordName: 'Other referral (please identify_______)',
    },
    {
      recordName: 'Television',
    },
  ],
  individualType: [
    {
      recordName: 'Advisor - Accountant',
    },
    {
      recordName: 'Advisor - Attorney',
    },
    {
      recordName: 'Advisor - Banking',
    },
    {
      recordName: 'Advisor - Consultant',
    },
    {
      recordName: 'Advisor - Other Services',
    },
    {
      recordName: 'Advisor - Recruiter',
    },
    {
      recordName: 'Advisor - Technology',
    },
    {
      recordName: 'Advisor - Valuator',
    },
    {
      recordName: 'Advisor - Wealth Manager',
    },
    {
      recordName: 'Capital Provider - Equity Group Managing Fund',
    },
    {
      recordName: 'Capital Provider - Equity Group Not Managing Fund',
    },
    {
      recordName: 'Capital Provider - Family Offices',
    },
    {
      recordName: 'Capital Provider - Hedge Fund',
    },
    {
      recordName: 'Capital Provider - Lender Jr Debt',
    },
    {
      recordName: 'Capital Provider - Lender Other',
    },
    {
      recordName: 'Capital Provider - Lender Sr Debt',
    },
    {
      recordName: 'Capital Provider - Limited Partner',
    },
    {
      recordName: 'Capital Provider - Mezzanine Lender',
    },
    {
      recordName: 'Capital Provider - Venture Capital',
    },
    {
      recordName: 'Corporate - Business Owner',
    },
    {
      recordName: 'Corporate Development',
    },
    {
      recordName: 'Corporate Finance',
    },
    {
      recordName: 'Corporate Management',
    },
    {
      recordName: 'Corporate Management - Entrepreneur',
    },
    {
      recordName: 'In-House Counsel',
    },
    {
      recordName: 'Intermediary - Business Brokers',
    },
    {
      recordName: 'Intermediary - Investment Banker',
    },
    {
      recordName: 'Intermediary - Underwriters',
    },
    {
      recordName: 'Other - Educators',
    },
    {
      recordName: 'Other - Government',
    },
    {
      recordName: 'Other - Media',
    },
    {
      recordName: 'Other - NGO Executive/Leaders',
    },
    {
      recordName: 'Other - Not Listed Above',
    },
  ],
  marketArea: [
    {
      recordKey: '733c8476-29ab-46d0-b946-b50e87544c60',
      recordName: 'Africa',
    },
    {
      recordKey: '23cbe507-b9c8-4235-9794-80ee61ebb93d',
      recordName: 'All Emerging Economies',
    },
    {
      recordKey: 'd47681a0-2362-4be1-8538-82da56a86052',
      recordName: 'Asia - China',
    },
    {
      recordKey: '16b140a0-6739-4ff1-9c02-b2f453780571',
      recordName: 'Asia - India',
    },
    {
      recordKey: '374eb41e-a010-4b6b-89ee-ef2439c27dfb',
      recordName: 'Asia - Other',
    },
    {
      recordKey: '4621501a-4a73-4cdd-854d-7ba36b730e95',
      recordName: 'Canada',
    },
    {
      recordKey: '81607c95-0833-4140-9567-4cd2af0bc749',
      recordName: 'Central America',
    },
    {
      recordKey: '484adcca-c956-4be9-9186-2d2e8f3080c8',
      recordName: 'Central Europe',
    },
    {
      recordKey: '62ff3251-6bdf-4d47-acba-8d4b360f7156',
      recordName: 'Eastern Europe',
    },
    {
      recordKey: '71a37208-6ec6-4117-8d4c-9200b00e7077',
      recordName: 'Mexico',
    },
    {
      recordKey: 'f83cb02f-8001-4108-9534-1d108b4799f0',
      recordName: 'Middle East',
    },
    {
      recordKey: 'acd11b3c-9ac8-42c7-b79a-6b256cc180d6',
      recordName: 'Midwest United States',
    },
    {
      recordKey: '3f2e5d5c-4f0e-453d-8168-32d055c6da01',
      recordName: 'Northeast United States',
    },
    {
      recordKey: '265c232f-ca6e-4c35-bf9f-67c8cddff9b9',
      recordName: 'Northwest United States',
    },
    {
      recordKey: '966e0f23-d00a-447a-b093-55ac0e5c07b3',
      recordName: 'South America - Brazil',
    },
    {
      recordKey: 'a0d65b5f-cd3f-40b6-8905-75d0a68e7c54',
      recordName: 'South America - Other',
    },
    {
      recordKey: 'b31bc2dd-1d81-4fd2-879c-78b0d9d14fb4',
      recordName: 'Southeast United States',
    },
    {
      recordKey: '65e8e0a8-c586-49b2-af21-dd8182401c32',
      recordName: 'Southwest United States',
    },
    {
      recordKey: '185f3588-e77c-46ae-bbdf-09f99f2115ae',
      recordName: 'Western Europe',
    },
  ],
  suffix: [
    { recordName: 'II' },
    { recordName: 'III' },
    { recordName: 'IV' },
    { recordName: 'Jr.' },
    { recordName: 'Sr.' },
  ],
  paymentMethod: [
    {
      recordKey: '84f55b7a-1097-4f66-a522-fabfdaeb99ae',
      apmType: 'ACH',
      apmMethod: 'ACH',
      cardFormat: '',
    },
    {
      recordKey: '17749668-5e92-416e-93ce-e61385b4ead4',
      apmType: 'credit card',
      apmMethod: 'Amex - USD - Vantiv',
      cardFormat: 'Amex',
    },
    {
      recordKey: '03b1d416-1873-4cab-b5f0-7b02b5c27bfe',
      apmType: 'other',
      apmMethod: 'International',
      cardFormat: '',
    },
    {
      recordKey: 'fe53a56f-bd24-42ce-807f-362948e146ec',
      apmType: 'credit card',
      apmMethod: 'MC - USD - Vantiv',
      cardFormat: 'MasterCard',
    },
    {
      recordKey: 'e3636c9e-f993-4c69-b63f-e8d385f2f668',
      apmType: 'credit card',
      apmMethod: 'VISA - USD - Vantiv',
      cardFormat: 'Visa',
    },
    {
      recordKey: 'ffbd04ac-f751-49db-b5a9-6111ac23e3c2',
      apmType: 'credit card',
      apmMethod: 'AMEX - CAD - Vantiv',
      cardFormat: 'Amex',
    },
    {
      recordKey: 'e969fadc-c5c8-41c6-98b0-ee8b7f5a16be',
      apmType: 'credit card',
      apmMethod: 'VISA - CAD - Vantiv',
      cardFormat: 'Visa',
    },
    {
      recordKey: 'b3b2ab78-3adc-45e0-b444-fec7efda46fa',
      apmType: 'credit card',
      apmMethod: 'MC - CAD - Vantiv',
      cardFormat: 'MasterCard',
    },
  ],
};
