import React from 'react';
import { EmptyObject, ExtendedSubscriptionStatus, UserTile, User } from '@companydotcom/types';

/**
 * All state managed by paywall and paywall child components.
 */
export interface PaywallStateProps {
  /** The fetched and processed object that contains a single product, and its rate plans */
  userProduct?: ExtendedSubscriptionStatus | EmptyObject;
  /** Triggers the internal paywall modal */
  paywallOpen?: boolean;
  /** Triggers the special LegalInc flow */
  legalIncOpen?: boolean;
  /** Sets paywall into a loading state, displaying a spinner */
  loading?: boolean;
  /** The tile that Paywall was triggered from, if applicable */
  tile?: UserTile | EmptyObject;
  /** The user object acquired from initialzing Paywall externally */
  externalUser?: User | EmptyObject;
  /** The source of the event that triggered the Paywall initilization */
  eventSource?: 'miop' | 'datacollector' | 'subscriptions' | 'cui' | 'addNewUserFlow';
  // TODO: Could probably improve this logic so quantity grabs the appropriate tier from selected tiers
  /** The quantity of additional licenses or seats to purchase, if available */
  quantity?: number;
  /** A handler thats passed to the Payment Success screen that fires when the success modal is closed */
  onSuccessHandler?: () => void;
}

export const initialPaywallState: PaywallStateProps = {
  userProduct: {},
  paywallOpen: false,
  legalIncOpen: false,
  loading: false,
  tile: {},
  externalUser: {},
  eventSource: undefined,
  quantity: undefined,
  onSuccessHandler: undefined,
};

export type PaywallActionType =
  | { type: 'SET_PAYWALL_STATE'; payload: typeof initialPaywallState }
  | { type: 'RESET_ALL_PAYWALL_STATE' }
  | { type: 'TOGGLE_PAYWALL_MODAL'; payload: boolean }
  | { type: 'TOGGLE_PAYWALL_LOADING'; payload: boolean };

export interface PaywallReducerProps {
  dispatchPaywallState?: React.Dispatch<PaywallActionType>;
  paywallState?: PaywallStateProps;
}

export const paywallReducer = (state: PaywallStateProps, action: PaywallActionType) => {
  switch (action.type) {
    case 'SET_PAYWALL_STATE':
      return { ...state, ...action.payload };
    case 'TOGGLE_PAYWALL_MODAL':
      return { ...state, paywallOpen: action.payload };
    case 'TOGGLE_PAYWALL_LOADING':
      return { ...state, loading: action.payload };
    case 'RESET_ALL_PAYWALL_STATE':
      return { ...initialPaywallState };
    default:
      throw new Error(`Unhandled action type`);
  }
};
