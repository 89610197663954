import { companyQL } from '@companydotcom/services';
import authQuery from './auth-query-old';
import authMutation from './auth-mutation-old';

/**
 * @deprecated
 * @param userId
 * @returns
 */
export const getAuthUser = async (userId: string) => {
  const res = await companyQL.query({
    query: authQuery.getAuthUser(userId),
  });

  const parsedResult = JSON.parse(res.data.getAuthUser);
  return parsedResult;
};

/**
 * @deprecated
 * @param email
 * @param input
 * @returns
 */
export const getWelcomeEmail = async (email: string, input: any) => {
  const res = await companyQL.query({
    query: authQuery.getWelcomeEmail(email, input),
  });
  const parsedResult = JSON.parse(res.data.getWelcomeEmail);
  return parsedResult;
};

/**
 * @deprecated
 * @param email
 * @returns
 */
export const resendEmailActivation = async (email: string) => {
  const res = await companyQL.mutate({
    mutation: authQuery.resendEmailActivation(email),
  });
  const parsedResult = JSON.parse(res.data.resendEmailActivation);
  return parsedResult;
};

/**
 * @deprecated
 * @param email
 * @param userId
 * @returns
 */
export const createAndSendEmailCode = async (email: string, userId: string) => {
  const res = await companyQL.mutate({
    mutation: authMutation.createAndSendEmailCode,
    variables: { email, userId },
  });
  const parsedResult = JSON.parse(res.data.createAndSendEmailCode);
  return parsedResult;
};

/**
 * @deprecated
 * @param email
 * @param userId
 * @returns
 */
export const updateVerifyEmailCode = async (email: string, userId: string) => {
  const res: { data: { updateVerifyEmailCode: string } } = await companyQL.mutate({
    mutation: authMutation.updateVerifyEmailCode,
    variables: { email, userId },
  });
  const parsedResult = JSON.parse(res.data.updateVerifyEmailCode);
  return parsedResult;
};

/**
 * Used to link an external account to a company.com account via SSO
 * @deprecated
 * @param sourceId
 * @param idpUserId
 * @param externalAuthUser
 * @returns
 */
export const linkAccounts = async (sourceId: string, idpUserId: string, externalAuthUser: any) => {
  const res = await companyQL.mutate({
    mutation: authMutation.linkAccounts,
    variables: {
      input: {
        sourceId,
        idpUserId,
        externalAuthUser: JSON.stringify(externalAuthUser),
      },
    },
  });
  const parsedResult = JSON.parse(res.data.linkAccounts);
  return parsedResult;
};
