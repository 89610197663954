import React, { useEffect } from 'react';
import { Tabs, TabPanels, TabPanel, useTabStepper } from '@companydotcom/potion';
import { LetsGetToKnowYouForm } from './get-to-know-you';
import { FaceCakePlanManagement } from '../facecake-plan-management/facecake-plan-management';
import { TermsAndConditions } from './terms-and-conditions';
import { useFacecakeLocation } from '../layouts/components/facecake-background-imager';

const qualifiedFlowSteps = [
  {
    slug: 'get-to-know-you',
    component: <LetsGetToKnowYouForm />,
  },
  {
    slug: 'terms-and-conditions',
    component: <TermsAndConditions />,
  },
  { slug: 'plan-selection', component: <FaceCakePlanManagement variant="component" /> },
];

export const FaceCakeQualifiedFlow = () => {
  const { tabIndex, handleTabsChange, nextStep } = useTabStepper({
    steps: qualifiedFlowSteps,
    initialStep:
      localStorage.getItem('facecake_termsChecked') === 'true'
        ? 'plan-selection'
        : 'get-to-know-you',
  });

  const { setFacecakeStep } = useFacecakeLocation();

  useEffect(() => {
    setFacecakeStep(qualifiedFlowSteps[tabIndex].slug);
  }, [setFacecakeStep, tabIndex]);

  return (
    <Tabs
      className="facecake-qualified-flow"
      isLazy
      index={tabIndex}
      onChange={handleTabsChange}
      width="full"
    >
      <TabPanels>
        {qualifiedFlowSteps.map(step => (
          <TabPanel className={step?.slug ?? ''} padding={0} key={step.slug}>
            {React.cloneElement(step.component, { nextStep })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
