import React from 'react';
import { Center } from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetMembershipsV2Query,
  useGetAcgUserProfileV2Query,
  useGetAcgQueryArgs,
} from '../../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { AcgPublicProfile } from '../components/public-profile';
import { useGetAcgProfileImage } from '../../shared';

export interface PublicProfileProps {
  goToStep?: (arg: number | string) => void;
}

export const PublicProfile: React.FC<PublicProfileProps> = () => {
  const args = useGetAcgQueryArgs();
  const { data: globalUser } = useGetGlobalUserQuery();
  const { acgAvatarUrl } = useGetAcgProfileImage();

  const { data: acgUserProfileData, isLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const { data: memberships, isLoading: isMembershipLoading } = useGetMembershipsV2Query({
    ...args,
  });

  return !isLoading && !isMembershipLoading && acgUserProfileData ? (
    <AcgPublicProfile
      userImage={acgAvatarUrl}
      profileData={acgUserProfileData}
      memberships={memberships?.memberships! || []}
      hiddenFields={memberships?.hiddenFieldsFromMembershipDirectory!}
    />
  ) : (
    <Center flexGrow={1} width="full">
      <AppSpinner my={12} />
    </Center>
  );
};
