import gql from 'graphql-tag';

export const GET_AUTOCOMPLETE_RESULTS_FOR_SEARCH = gql`
  query GetAutocompleteResults($searchTerm: String!) {
    getAutocompleteResults(queryString: $searchTerm) {
      ... on CategoryRecord {
        id
        name
        fullName
        publisher
      }
    }
  }
`;

export const GET_GMB_CATEGORY_AUTOCOMPLETE = gql`
  query GetGmbCategoryAutocomplete($searchTerm: String!) {
    getGmbCategoryAutocomplete(queryString: $searchTerm) {
      id
      name
      fullName
      publisher
    }
  }
`;
