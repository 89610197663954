import React from 'react';
import { Box, Text, Alert, AlertTitle, AlertDescription, Link } from '@companydotcom/potion';

export const PendingVerification = () => {
  return (
    <Box width="100%" mb={8}>
      <Alert status="warning" flexDirection="column">
        <AlertTitle mb={1} fontSize="lg">
          Waiting on Verification:
        </AlertTitle>
        <AlertDescription maxWidth="md">
          <Text textStyle="md" mt={2} textAlign="center">
            Looks like your business has not yet been verified with Google My Business.
          </Text>

          <Text textStyle="md" mt={3} textAlign="center">
            To check your status{' '}
            <Link isExternal href="https://grandio.company.com/contact-us">
              contact support.
            </Link>
          </Text>
        </AlertDescription>
      </Alert>
    </Box>
  );
};
