import { companyQL } from '@companydotcom/services';
import { companyQLSecure } from '../../secure-app-sync-client';
import enrollmentMutation from './enrollment-mutation-old';
import enrollmentQuery from './enrollment-query-old';

/**
 * @deprecated
 * @param data
 * @returns
 */
export const enrollmentSetValidEmail = async (data: any) => {
  const res = await companyQL.mutate({
    mutation: enrollmentMutation.enrollmentSetValidEmail,
    variables: { input: data },
  });

  if (typeof res.data.enrollmentSetValidEmail === 'string') {
    return JSON.parse(res.data.enrollmentSetValidEmail);
  }
  return res.data.enrollmentSetValidEmail;
};

/**
 * @deprecated
 * @param data
 * @returns
 */
export const optOutByExternalIdentifier = async (data: any) => {
  const res = await companyQL.mutate({
    mutation: enrollmentMutation.optOutByExternalIdentifier,
    variables: { input: data },
  });

  if (typeof res.data.optOutByExternalIdentifier === 'string') {
    return JSON.parse(res.data.optOutByExternalIdentifier);
  }
  return res.data.optOutByExternalIdentifier;
};

/**
 * @deprecated
 * @param email
 * @param source
 * @returns
 */
export const resetPasswordEmail = async (email: string, source: string) => {
  const res = await companyQL.mutate({
    mutation: enrollmentMutation.resetPasswordEmail(email, source),
  });
  const parsedResponse = JSON.parse(res.data.resetPasswordEmail);
  return parsedResponse;
};

/**
 * @deprecated
 * @param data
 * @returns
 */
export const verifyOtp = async (data: any) => {
  const res = await companyQLSecure().mutate({
    mutation: enrollmentMutation.verifyOtp,
    variables: data,
  });
  const parsedResponse = JSON.parse(res.data.verifyOtp);
  return parsedResponse;
};

/**
 * @deprecated
 * @param data
 * @returns
 */
export const generateAndSendOtp = async (data: any) => {
  const res = await companyQL.query({
    query: enrollmentQuery.generateAndSendOtp,
    variables: data,
  });
  const parsedResponse = JSON.parse(res.data.generateAndSendOtp);
  return parsedResponse;
};

/**
 * @deprecated
 * @param email
 * @returns
 */
export const getEnrollmentByUserEmail = async (email: string) => {
  const res = await companyQLSecure().query({
    query: enrollmentQuery.getEnrollmentByUserEmail(email),
  });
  const parsedResponse = JSON.parse(res.data.getEnrollmentByUserEmail);
  return parsedResponse;
};

/**
 * @deprecated
 * @param enrollmentId
 * @param source
 * @returns
 */
export const getEnrollmentStatus = async (
  enrollmentId: string,
  source: string,
): Promise<{
  externalIdentifier: string;
  email: string;
  status: string;
  enrollmentId: string;
}> => {
  const res = await companyQL.query({
    query: enrollmentQuery.getEnrollmentStatus(enrollmentId, source),
  });
  return JSON.parse(res.data.getEnrollMentIdStatus);
};
