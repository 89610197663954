import React from 'react';
import { Flex, Text, Radio, UnorderedList, ListItem } from '@companydotcom/potion';
import { SubscriptionRatePlan, SubscriptionTier } from '@companydotcom/types';
import { useTranslation } from 'react-i18next';

export interface PackageProps extends SubscriptionRatePlan {
  ratePlanClickHandler?: (ratePlan: SubscriptionRatePlan, tier?: SubscriptionTier) => void;
  selectedPlan?: SubscriptionRatePlan;
  disabled?: boolean;
  none?: boolean;
  add?: any;
  numberOfRatePlans?: number;
  estimatedPrice?: number;
  hasTiers?: boolean;
  calcprice?: number;
  checked?: boolean;
}

export const PaywallRatePlan: React.FC<PackageProps> = props => {
  const { isActive, name, features, hasTiers, selectedPlan, disabled, none, estimatedPrice } =
    props;
  const { t } = useTranslation();

  return (
    <Flex
      className="package"
      minWidth={['100%', null, '208px']}
      flexDirection="column"
      pb={[5, null, 0]}
    >
      <Radio
        value={name ?? ''}
        key={`${name}-checked-${selectedPlan?.name === name || (!selectedPlan && isActive)}`}
        isDisabled={disabled}
        alignItems="flex-start"
      >
        <Text ml={1} lineHeight={1} textStyle="md" opacity={disabled ? 0.5 : 1} minWidth={172}>
          {none ? t('paywall.none') : name}
        </Text>
        <UnorderedList maxWidth={['256px']} mt={1} listStyleType="'- '">
          {none ? null : (
            <>
              {features
                ? features.map((feature, i) => (
                    <ListItem key={`${feature}-${i}`}>
                      <Text opacity={disabled ? 0.5 : 1} textStyle="sm">
                        {`${feature}`}
                      </Text>
                    </ListItem>
                  ))
                : null}
            </>
          )}
          {none ? null : (
            <Text
              textStyle="sm"
              color={disabled ? 'inherit' : 'pricing'}
              opacity={disabled ? 0.5 : 1}
              mt={4}
            >
              {t(hasTiers ? 'paywall.pricingWithTiers' : 'paywall.pricing', {
                priceOrVoid:
                  estimatedPrice && estimatedPrice > 0 && !disabled
                    ? estimatedPrice?.toFixed(2)
                    : '--',
              })}
            </Text>
          )}
        </UnorderedList>
      </Radio>
    </Flex>
  );
};
