import { createContext } from '@companydotcom/potion';
import { UserGroup } from '@companydotcom/types';

const [GroupsProvider, useGroups] = createContext<UserGroup[]>({
  strict: false,
  name: 'GroupsContext',
  errorMessage:
    'useGroups: `groups` is undefined. Seems you forgot to wrap the app in `<GroupsProvider />` ',
});
export { GroupsProvider, useGroups };
