import React, { useMemo } from 'react';
import { useSearchParams, Navigate, useLocation } from 'react-router-dom';

export interface RedirectZoneProps {
  keyParam: string;
  routes: Record<string, any>;
}

export const RedirectZone = ({ keyParam, routes }: RedirectZoneProps) => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const foundParam = useMemo(() => {
    // Normalizes the query params and the keyParam to lowercase
    for (const entry of searchParams.entries()) {
      const [param] = entry;
      if (param.toLowerCase() === keyParam.toLowerCase()) {
        return searchParams.get(param);
      }
    }
  }, [keyParam, searchParams]);

  const redirect = useMemo(() => {
    return foundParam ? routes[foundParam] : '/';
  }, [foundParam, routes]);

  return <Navigate to={{ pathname: redirect, search }} state={{ referrer: redirect }} />;
};
