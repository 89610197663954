import { useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { parsePhoneNumber } from 'libphonenumber-js';
import ReCaptcha from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSource, useSourceConfig } from '@companydotcom/providers';
import { Text, Box, Button, Heading, Center, VStack, Link } from '@companydotcom/potion';
import {
  InputField,
  useGetCurrentlySelectedCountry,
  PhoneNumberInputField,
  //getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { companyHelpers } from '@companydotcom/helpers';
// import { EnrollmentSvc } from '@companydotcom/services';
import { enrollmentSetValidEmail } from '../../../services/enrollment/old-api/enrollment-svc';
import {
  useRecaptchaVerificationMutation,
  //useEnrollmentSetValidEmailMutation,
} from '../../../services/enrollment/enrollment-api';
import yup from '../../../lib/yup';

// const { recaptchaVerification } = EnrollmentSvc();

const getVerifyBusinessFormSchema = () =>
  yup.object().shape({
    businessName: yup.string().required('common.inputs.businessName.error'),
    email: yup.string().required('common.inputs.email.error'),
    mid: yup.string().required('common.inputs.mid.error'),
    phone: yup.string().phone().required('common.inputs.businessPhone.error').nullable(),

    zipCode: yup
      .string()
      .required('common.inputs.businessZipCode.error')
      .test('zipCode', 'common.inputs.businessZipCode.error', val =>
        companyHelpers.validateZipCode(val),
      ),
  });

export interface VerifyBusinessFormProps {
  formLabels?: { buttonType: any; buttonText: string };
  mid?: string;
  businessName?: string;
  zipCode?: string;
  phone?: string;
  email?: string;
}

export interface FormValuesProps {
  mid?: string;
  businessName?: string;
  zipCode?: string;
  phone?: string;
  email?: string;
}

export const VerifyBusiness = ({
  formLabels,
  mid,
  businessName,
  zipCode,
  phone,
  email,
}: VerifyBusinessFormProps) => {
  const sourceConfig = useSourceConfig();
  const captcha = useRef(null);
  const navigate = useNavigate();
  const source = useSource();
  const { t } = useTranslation();
  const captchaEnabled = sourceConfig?.captchaEnabled || false;
  const [captchaStatus, setCaptchaStatus] = useState<boolean>(!captchaEnabled);
  const [recaptchaVerification] = useRecaptchaVerificationMutation();
  //const [enrollmentSetValidEmail] = useEnrollmentSetValidEmailMutation();

  const { country, onCountryChange } = useGetCurrentlySelectedCountry();

  const defaultValues: FieldValues = {
    mid: mid || '',
    businessName: businessName || '',
    zipCode: zipCode || '',
    phone: phone ? parsePhoneNumber(phone, country ?? 'US').number : '',
    email: email || '',
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, isValid, errors },
  } = useForm<FormValuesProps>({
    mode: 'onBlur',
    resolver: yupResolver(getVerifyBusinessFormSchema()),
    defaultValues,
    context: { country },
  });

  const onSubmit = async (values: {
    email: string;
    mid: string;
    businessName: string;
    zipCode: string;
    phone: string;
  }) => {
    try {
      //changed the source paramater to sourceID
      const verifyStatus = await enrollmentSetValidEmail({
        email: values.email,
        source: source.sourceId,
        externalIdentifier: values.mid,
        businessName: values.businessName,
        zip: values.zipCode,
        phone: values.phone.replace(/\D/g, '').slice(-10),
        // phone: getFormattedPhoneNumber(values.phone, country ?? 'US'),
      });

      if (!verifyStatus.error) {
        const mailJson = JSON.stringify({ email: values.email });
        localStorage.setItem('welcomeemail', mailJson);
        navigate('/verifystd', { state: { email: values.email } });
      } else {
        throw new Error('Verify Business failed');
      }
    } catch (error) {
      navigate('/support');
    }
  };

  const verifyCaptcha = async (token: string) => {
    console.log('Verification Token: ', token);
    const verificationResponse = await recaptchaVerification({ token }).unwrap();
    if (verificationResponse.success) {
      console.log('Successful verification response: ', verificationResponse);
      setCaptchaStatus(true);
    } else {
      console.log('Unsuccessful verification response: ', verificationResponse);
      setCaptchaStatus(false);
    }
  };

  return (
    <Center
      className="verify-business"
      flexDirection="column"
      maxWidth={704}
      width="full"
      mx="auto"
      layerStyle="formContainer"
      px={4}
      py={[8, null, 12]}
    >
      <Heading size="hs-2xl" mb={4}>
        {t('subpage.verifyBusiness.heading')}
      </Heading>
      <Text textStyle="md" mb={8}>
        {t('subpage.verifyBusiness.subheading')}
      </Text>
      <Box as="form" width="full">
        <VStack mx="auto" spacing={6} width="full" maxW={326}>
          <InputField
            register={register}
            name="mid"
            autoFocus
            label={t('common.inputs.mid.label')}
            errors={errors}
          />
          <InputField
            register={register}
            name="businessName"
            label={t('common.inputs.businessName.label')}
            errors={errors}
          />
          <InputField
            register={register}
            name="zipCode"
            label={t('common.inputs.businessZipCode.label')}
            errors={errors}
          />
          <PhoneNumberInputField
            name="phone"
            label={t('common.inputs.businessPhone.label')}
            control={control}
            country={country}
            onCountryChange={onCountryChange}
            errors={errors}
          />
          <InputField
            register={register}
            name="email"
            label={t('common.inputs.email.label')}
            helperText={t('subpage.verifyBusiness.emailHelperText')}
            errors={errors}
          />
          {captchaEnabled && (
            <ReCaptcha
              ref={captcha}
              size="normal"
              sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
              render="explicit"
              onChange={verifyCaptcha}
            />
          )}
        </VStack>
        <Box mt={10} mb={4} sx={{ textAlign: 'center' }}>
          <Button
            size="lg"
            type="submit"
            // @ts-ignore
            onClick={handleSubmit(onSubmit)}
            variant="outline"
            isDisabled={!captchaStatus || !isValid}
            isLoading={isSubmitting}
          >
            {formLabels && formLabels.buttonText
              ? formLabels.buttonText
              : t('subpage.verifyBusiness.submitButton')}
          </Button>
        </Box>
      </Box>
      <Text textStyle="sm" textAlign="center">
        {t('subpage.verifyBusiness.signUpAgreement')}{' '}
        <Link href={`${process.env.REACT_APP_GRANDIO_URL}/terms-of-use`} target="_blank">
          {t('common.misc.termsOfUse')}
        </Link>
      </Text>
    </Center>
  );
};
