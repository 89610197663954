import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Text,
  Input,
  FormLabel,
  FormControl,
  VStack,
  Stack,
  Alert,
  AlertIcon,
  UseTabStepperReturn,
  AlertDescription,
  Spacer,
} from '@companydotcom/potion';
import { InputField, SelectField, TextareaField } from '@companydotcom/ui';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  useGetEventForRegistrationQuery,
  useLazyGetEventForRegistrationQuery,
} from '../../../../services/acg/acg-api';
import {
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
} from '../../../../services/acg/acg-api-v2';
import { EventForPurchase, ADAFields } from '../../../../services/acg/acg.types';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setActiveRegistrant } from '../event-registration-slice';
import { setEventForPurchase } from '../../acg-checkout/checkout-slice';
import {
  SubTotalList,
  SelectedFeesList,
  useAcgCartStorage,
  useShowAddGuestButton,
  checkEventStatus,
  filterOutAdaFields,
  eventsFormSliceMapper,
} from '../../shared';
import { selectGuestsToRegister } from '../../acg-add-guest/add-guest-slice';

export const getRegisterSelfSchema = (registrantTypes: string[]) =>
  yup.object().shape({
    fullName: yup.string().notRequired(),
    badgeName: yup.string().required('Please enter a Badge First Name'),
    organizationName: yup.string().required('Please enter an Organization'),
    registrantType: yup.string().oneOf(registrantTypes).required('Please select a Registrant Type'),
    title: yup.string().required('Please enter a Title'),
    selectedPackage: yup.string().required('Please select at least one fee'),
    fees: yup.array().of(yup.string()).notRequired().nullable(),
    customQuestions: yup
      .object()
      .shape({
        [ADAFields.ADA]: yup.string().notRequired(),
        [ADAFields.DIETARY]: yup.string().notRequired(),
      })
      .notRequired(),
  });

export interface RegisterSelfProps extends Pick<Partial<UseTabStepperReturn>, 'goToStep'> {
  showWaitListAlert?: boolean;
}

export const EventRegistrationRegisterSelf = ({
  goToStep,
  showWaitListAlert,
}: RegisterSelfProps) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const [searchParams] = useSearchParams();
  const regEventKey = searchParams.get('Reg_evt_key');
  const selectedValues: any = JSON.parse(window.localStorage.getItem('acg-cart') as string);
  const selectedPackageinCart =
    selectedValues?.items?.event?.registrant?.fees[0]?.eventFees?.priceKey;
  const { data: eventForRegistration } = useGetEventForRegistrationQuery(
    globalUser && regEventKey
      ? {
          user: globalUser,
          eventId: regEventKey,
        }
      : skipToken,
  );

  const { data: acgUserProfile, isLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  // State
  const [pageError, setPageError] = useState(false);

  const defaultValues = {
    fullName: `${acgUserProfile?.firstName} ${acgUserProfile?.lastName}` ?? '',
    badgeName: acgUserProfile?.firstName ?? '',
    registrantType:
      eventForRegistration?.registrantAndFees?.[0]?.registrantType?.registrantTypeId ?? '',
    organizationName: acgUserProfile?.organization ?? '',
    title: acgUserProfile?.title ?? '',
    selectedPackage:
      eventForRegistration?.registrantAndFees?.[0]?.packages.length === 1
        ? eventForRegistration?.registrantAndFees?.[0]?.packages[0].eventFees.priceKey
        : undefined,
    fees: [] as (string | undefined)[],
    customQuestions: {
      [ADAFields.ADA]: '',
      [ADAFields.DIETARY]: '',
    },
  };

  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    resetField,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      getRegisterSelfSchema(
        eventForRegistration?.registrantAndFees?.map(i => i.registrantType?.registrantTypeId) || [],
      ),
    ),
    defaultValues: defaultValues ?? {},
  });

  // Ensures defaultValues are always set
  useEffect(() => {
    if (!isLoading && eventForRegistration) {
      reset();
    }
  }, [eventForRegistration, isLoading, reset]);

  const [getEvent] = useLazyGetEventForRegistrationQuery();

  const { clearLocalCart, addEventToLocalCart } = useAcgCartStorage();

  const guestsToRegister = useAppSelector(selectGuestsToRegister);
  const watchedFees = useWatch({ control, name: 'fees' });
  const watchedRegistrantType = useWatch({ control, name: 'registrantType' });
  const watchedSelectedPackage = useWatch({ control, name: 'selectedPackage' });
  const selectedRegistrantType = eventForRegistration?.registrantAndFees?.find(
    fee => fee.registrantType.registrantTypeId === watchedRegistrantType,
  );

  const shouldShowGuestButton = useShowAddGuestButton(
    eventForRegistration,
    selectedRegistrantType,
    guestsToRegister.length,
  );

  const hasOnePackage = selectedRegistrantType?.packages?.length === 1;

  useEffect(() => {
    // if the back button was clicked then bind the selected values.
    if (selectedPackageinCart) {
      setValue('selectedPackage', selectedPackageinCart);
    }
    // If theres only one package, just automatically set the fee
    if (selectedRegistrantType && hasOnePackage) {
      setValue('selectedPackage', selectedRegistrantType?.packages?.[0]?.eventFees?.priceKey);
    } else {
      resetField('selectedPackage');
    }
  }, [hasOnePackage, resetField, selectedRegistrantType, setValue, trigger, selectedPackageinCart]);

  // Resets checkboxes when user chooses a different radio button
  useEffect(() => {
    if (watchedSelectedPackage) {
      resetField('fees');
    }
  }, [resetField, watchedSelectedPackage]);

  const calcTotalFees = () => {
    return selectedRegistrantType?.packages
      ?.reduce((total, { eventFees, sessions }) => {
        const packagePrice = eventFees.priceKey === watchedSelectedPackage ? eventFees.price : 0;

        watchedFees?.forEach(fee => {
          if (fee === eventFees?.priceKey) {
            total += eventFees?.price || 0;
          }

          if (sessions) {
            const flatSessFees = sessions?.flatMap(x => x?.sessionFees?.flatMap(y => y));

            if (
              flatSessFees &&
              fee === flatSessFees.find(x => x.sessionFeeId === fee)?.sessionFeeId
            ) {
              total += flatSessFees.find(x => x.sessionFeeId === fee)?.price || 0;
            }
          }
        });

        return total + packagePrice;
      }, 0)
      ?.toFixed(2);
  };

  const addGuestSubmitHandler = async (data: typeof defaultValues) => {
    try {
      if (selectedRegistrantType && eventForRegistration) {
        dispatch(
          // @ts-ignore
          setActiveRegistrant({
            ...data,
            firstName: acgUserProfile?.firstName,
            lastName: acgUserProfile?.lastName,
            individualType: acgUserProfile?.individualType,
            customQuestions:
              selectedRegistrantType?.customQuestions &&
              eventsFormSliceMapper(selectedRegistrantType?.customQuestions),
            fees: selectedRegistrantType?.packages?.filter(
              p => p.eventFees.priceKey === watchedSelectedPackage,
            ),
          }),
        );
      }
      goToStep?.('add-new-guest');
    } catch (err) {
      setPageError(true);
      console.log('👉 ~ onSubmit ~ err', err);
    }
  };

  const addToCartSubmitHandler = async (data: typeof defaultValues) => {
    try {
      const eventForPurchase = {
        showEventFullAlert:
          !eventForRegistration?.hasWaitList &&
          eventForRegistration?.eventCapacity &&
          guestsToRegister.length + eventForRegistration?.totalRegistered + 1 >
            eventForRegistration?.eventCapacity,
        showWaitListAlert,
        totalRegistered: eventForRegistration?.totalRegistered,
        eventCapacity: eventForRegistration?.eventCapacity,
        eventRecordKey: eventForRegistration?.eventKey,
        guests: guestsToRegister,
        hasWaitList: eventForRegistration?.hasWaitList,
        waitListedRegistrants: eventForRegistration?.totalWaitList,
        selectedRegistrantType: selectedRegistrantType,
        shouldShowGuestButton: shouldShowGuestButton,
        registrant: {
          badgeName: data?.badgeName,
          fullName: data?.fullName,
          organizationName: data?.organizationName,
          title: data?.title,
          selectedPackage: data?.selectedPackage,
          fees: selectedRegistrantType?.packages?.filter(
            p => p.eventFees.priceKey === watchedSelectedPackage,
          ),
          sessions: watchedFees,
          customQuestions: data.customQuestions,
        },
        registrantType: selectedRegistrantType?.registrantType,
      } as EventForPurchase;

      if (
        selectedRegistrantType?.customQuestions &&
        filterOutAdaFields(selectedRegistrantType?.customQuestions)?.length
      ) {
        dispatch(
          // @ts-ignore
          setActiveRegistrant({
            ...data,
            firstName: acgUserProfile?.firstName,
            lastName: acgUserProfile?.lastName,
            individualType: acgUserProfile?.individualType,
            customQuestions: eventsFormSliceMapper(selectedRegistrantType?.customQuestions),
            fees: !selectedRegistrantType
              ? undefined
              : [
                  selectedRegistrantType?.packages?.filter(
                    p => watchedSelectedPackage === p?.eventFees?.priceKey,
                  )?.[0],
                ],
          }),
        );
        dispatch(setEventForPurchase(eventForPurchase));
        goToStep?.('demographic-questions');
      } else {
        clearLocalCart();
        if (eventForPurchase.selectedRegistrantType) {
          delete eventForPurchase.selectedRegistrantType;
        }
        addEventToLocalCart(eventForPurchase);

        if (globalUser && eventForRegistration?.eventKey) {
          const latestEventData = await getEvent({
            user: globalUser,
            eventId: eventForRegistration?.eventKey,
          }).unwrap();
          const {
            shouldShouldWaitlistAlert,
            shouldShowEventFullAlert,
            shouldShowEventOverCapacityAlert,
          } = checkEventStatus(latestEventData, eventForPurchase?.guests?.length);

          navigate('/acg-shopping-cart', {
            state: {
              shouldShouldWaitlistAlert,
              shouldShowEventFullAlert,
              shouldShowEventOverCapacityAlert,
              totalWaitList: latestEventData?.totalWaitList,
            },
          });
        }
      }
    } catch (err) {
      setPageError(true);
      console.log('👉 ~ onSubmit ~ err', err);
    }
  };

  return (
    <Box className="eventRegistrations__registerSelfStep" width="full">
      {pageError && (
        <Alert status="error" variant="solid" mb={[8, 12]}>
          <AlertIcon />
          {/* // TODO: Add error message */}
          Error
        </Alert>
      )}
      {showWaitListAlert && (
        <Alert status="warning" my={10}>
          <AlertIcon />
          <AlertDescription fontSize="sm" lineHeight="5">
            <Text fontWeight="bold">There’s a Waitlist for this Event</Text>
            <Text>
              You can still register for this event and will have a pro forma invoice generated for
              your registration. If you’re moved off the waitlist, you will be notified of this
              change and charged your registration.
            </Text>
            {eventForRegistration?.hasWaitList && (
              <Text fontWeight="bold" fontStyle="italic">
                {eventForRegistration?.totalWaitList === 0
                  ? 'You will be the first person placed on the waitlist unless someone completes an order before you.'
                  : `Currently, there are ${eventForRegistration?.totalWaitList} people on the waitlist.`}
              </Text>
            )}
          </AlertDescription>
        </Alert>
      )}
      <Box as="form">
        <Stack
          direction={['column', null, null, 'row']}
          align="flex-start"
          width="full"
          spacing={[6, null, 8, 12]}
        >
          <VStack align="flex-start" width="full" spacing={6}>
            <Text textStyle={['lg', '3xl']} fontWeight="bold" opacity={0.7}>
              Registrant Information
            </Text>
            <FormControl>
              <FormLabel>Registrant Name</FormLabel>
              <Input
                isReadOnly
                height={6}
                {...register('fullName')}
                outline={0}
                pl={0}
                border={0}
                mb={6}
                fontWeight={600}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Function/Role:</FormLabel>
              <Input
                isReadOnly
                height={6}
                value={acgUserProfile?.individualType ?? undefined}
                outline={0}
                pl={0}
                border={0}
                fontWeight={600}
              />
            </FormControl>
            {selectedRegistrantType?.customQuestions?.some(q => q.questionId === ADAFields.ADA) && (
              <TextareaField
                register={register}
                name={`customQuestions.${ADAFields.ADA}`}
                label="ADA Requirements"
                errors={errors}
              />
            )}
            {selectedRegistrantType?.customQuestions?.some(
              q => q.questionId === ADAFields.DIETARY,
            ) && (
              <TextareaField
                register={register}
                name={`customQuestions.${ADAFields.DIETARY}`}
                label="Dietary Restrictions"
                errors={errors}
              />
            )}
          </VStack>
          <VStack align="flex-start" width="full" spacing={6}>
            <Text textStyle={['lg', '3xl']} fontWeight="bold" opacity={0.7}>
              Badge Information
            </Text>
            <InputField
              register={register}
              label="Badge First Name"
              name="badgeName"
              errors={errors}
              helperText="Required"
            />
            <InputField
              register={register}
              name="organizationName"
              label="Organization"
              helperText="Required"
              errors={errors}
            />
            <InputField
              register={register}
              label="Title"
              name="title"
              errors={errors}
              helperText="Required"
            />
          </VStack>
        </Stack>
        {/* REGISTRANT TYPE COLUMN */}
        <Stack
          direction={['column', null, 'row']}
          align="flex-start"
          width="full"
          spacing={[6, null, 8, 12]}
          mt="20"
        >
          <VStack width="full">
            <SelectField
              register={register}
              name="registrantType"
              label="Registrant Type"
              helperText="Required"
              errors={errors}
            >
              {eventForRegistration?.registrantAndFees?.map(opt => (
                <option
                  key={opt?.registrantType?.registrantTypeId}
                  value={opt?.registrantType?.registrantTypeId}
                >
                  {opt?.registrantType?.registrantTypeName}
                </option>
              ))}
            </SelectField>
          </VStack>
          <VStack width="full">
            <Spacer />
          </VStack>
        </Stack>
        <SelectedFeesList
          hasOneFee={hasOnePackage}
          data={selectedRegistrantType?.packages}
          mt={12}
          register={register}
          name="fees"
          setValue={setValue}
          fees={watchedFees}
          selectedPackage={watchedSelectedPackage}
        />
        <SubTotalList
          isSubmitting={isSubmitting}
          isValid={hasOnePackage && watchedSelectedPackage !== undefined ? true : isValid}
          total={calcTotalFees()}
          maxW={[null, null, 296]}
          ml="auto"
          demographicQuestionsExist={
            selectedRegistrantType?.customQuestions &&
            !!filterOutAdaFields(selectedRegistrantType?.customQuestions)?.length
          }
          addGuestSubmitHandler={handleSubmit(addGuestSubmitHandler)}
          addToCartSubmitHandler={handleSubmit(addToCartSubmitHandler)}
          showAddGuestButton={shouldShowGuestButton}
        />
      </Box>
    </Box>
  );
};
