import React from 'react';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faCalendarCheck } from '@fortawesome/pro-thin-svg-icons';
import { Heading, VStack, Text, Button, useBreakpointValue, Box } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const FaceCakeScheduleTile = ({ isScheduled }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const buttonSize = useBreakpointValue({ base: 'md', xl: 'lg' });

  const handleOnClick = () =>
    !isScheduled ? navigate('/facecake/schedule') : console.log('wire me up please :)');

  return (
    <Box>
      <VStack spacing="2">
        <FontAwesomeIcon
          icon={faCalendarCheck}
          color="pink.500"
          boxSize={{ base: '12', xl: '16' }}
        />
        <Heading size="lg" color="pink.500" fontWeight="bold">
          {!isScheduled
            ? t('icommerce.scheduleAConsultation.header')
            : t('icommerce.scheduleAConsultation.confirmationTileHeader')}
        </Heading>
        <Text>{t('icommerce.scheduleAConsultation.tileBody')}</Text>
      </VStack>
      <Box mt="6">
        <Button size={buttonSize} onClick={handleOnClick}>
          {!isScheduled
            ? t('icommerce.scheduleAConsultation.tileButton')
            : t('icommerce.scheduleAConsultation.confirmationTileButton')}
        </Button>
      </Box>
    </Box>
  );
};
