import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import _get from 'lodash/get';
import { useSource } from '@companydotcom/providers';
import { Text, Box, Flex, Image, Button, Heading } from '@companydotcom/potion';
import { platformHelpers } from '@companydotcom/helpers';
import { useEventData, AppSpinner } from '@companydotcom/ui';
import { useNavigate } from 'react-router-dom';

import type { TileComponentProps } from '../../types';

export interface TileImageWithDataProps extends TileComponentProps {}

export const TileImageWithData: React.FC<TileImageWithDataProps> = props => {
  const { eventData, dataConfig, params, variant, eventHandler, pubEvents } = props;
  const { t } = useTranslation();
  const source = useSource();
  const navigate = useNavigate();
  const hideError = platformHelpers.getComponentParamValue(params, 'hideError');

  const { loading, error, data } = useEventData(eventData);
  const showUpgradeCTA = platformHelpers.getComponentParamValue(params || [], 'showCTA', eventData);

  if (dataConfig && dataConfig?.length > 2) {
    // @ts-ignore
    return <Text>{t('tiles.imagewithData.cannotRender')}</Text>;
  }

  if (loading) {
    return <AppSpinner />;
  }

  if (!hideError && error) {
    <Box mb={4}>
      <Text color="red.500">
        <Trans i18nKey="tiles.imageWithData.contactSupport">
          Something went wrong, please try again or
          <Text
            onClick={() => {
              navigate('/help');
            }}
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Contact Support
          </Text>
        </Trans>
      </Text>
    </Box>;
  }

  if (data) {
    return (
      <>
        <Flex
          mt={[
            error || variant?.includes('single') || variant?.includes('shiftup') || showUpgradeCTA
              ? 0
              : 8,
          ]}
          mb={showUpgradeCTA && variant?.includes('single') ? [4] : [1]}
        >
          {dataConfig?.map(({ dataKey, imageUrl, label }: any) => {
            const value = _get(eventData, dataKey, 0);

            // if (value.error) {
            //   setHasError(true);
            // }

            const imageSrc = `${
              process.env.REACT_APP_IMG_URL
            }/icons/tile_icons/${source.sourceId.toLowerCase()}/${imageUrl}`;

            const shouldValueBeSmallFont =
              value.toString().length > 3 || variant?.includes('single');

            return (
              <Flex
                key={label}
                ml={[3]}
                mr={[3]}
                flexDirection={variant?.includes('single') ? 'column' : 'row'}
                justifyContent="center"
                alignItems="center"
              >
                {loading ? (
                  <AppSpinner />
                ) : (
                  <Image
                    src={imageSrc}
                    width={
                      variant?.includes('small') || (variant?.includes('single') && showUpgradeCTA)
                        ? ['88px']
                        : ['104px', '132px']
                    }
                    alt={label}
                  />
                )}
                {variant?.includes('single') && error && !hideError ? null : (
                  <Flex
                    flexDirection={variant?.includes('single') ? 'row' : 'column'}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      position: variant?.includes('single') ? 'relative' : 'absolute',
                    }}
                  >
                    {!loading && (
                      <>
                        <Heading
                          size={shouldValueBeSmallFont ? 'hs-lg' : 'hs-3xl'}
                          mt={[variant?.includes('single') ? 0 : 5]}
                          mr={[variant?.includes('single') ? 2 : 0]}
                          color={variant?.includes('single') ? '#064971' : '#566e7d'}
                        >
                          {!error ? platformHelpers.formatNumWithCommas(value) : '!'}
                        </Heading>
                        <Text
                          textStyle={variant?.includes('single') ? 'md' : 'sm'}
                          color="#506f7f"
                          sx={{
                            transform: shouldValueBeSmallFont ? 'none' : 'translateY(-5px)',
                          }}
                        >
                          {label}
                        </Text>
                      </>
                    )}
                  </Flex>
                )}
              </Flex>
            );
          })}
        </Flex>
        {showUpgradeCTA && (
          <UpgradePrompt
            message={platformHelpers.getComponentParamValue(params, 'ctaText')}
            messageStatistic={platformHelpers.getComponentParamValue(
              params,
              'ctaStatisticPath',
              eventData,
            )}
            onUpgradeClick={() => {
              eventHandler?.(
                pubEvents,
                { type: 'upgradeClick' },
                // @ts-ignore
                platformHelpers.getComponentParamValue(params, 'paywallPreselectPath', eventData),
              );
            }}
          />
        )}
      </>
    );
  }

  return null;
};

export const UpgradePrompt = ({ message, messageStatistic, onUpgradeClick }: any) => {
  const { t } = useTranslation();
  return (
    <Text textStyle="sm" maxWidth="280px">
      {message?.split?.('/statistic/').join(messageStatistic)}{' '}
      <Button fontSize="sm" variant="link" onClick={onUpgradeClick}>
        {t('components.upgradePrompt.upgradeNow')}
      </Button>
    </Text>
  );
};
