import { companyQL } from '@companydotcom/services';
import { withCache } from '@companydotcom/helpers';
import { ExtendedSubscriptionStatus } from '@companydotcom/types';
import { companyQLSecure } from '../../secure-app-sync-client';
import productQuery from './product-query-old';

const isEnabled = process.env.REACT_APP_CACHE_ENABLED === 'true' || false;

/**
 * @deprecated
 */
export const getAllProducts = withCache(
  async () => {
    const res = await companyQL.query({
      query: productQuery.getAllProducts(),
    });
    return res.data.getAllProducts;
  },
  'allProducts',
  undefined,
  isEnabled,
);

export const getUserProducts = withCache<ExtendedSubscriptionStatus>(
  async (userId: string, locale: string = 'en') => {
    const res = await companyQLSecure().query({
      query: productQuery.getUserProducts(),
      variables: { userId, locale },
    });

    return res.data.getUserProducts;
  },
  'userProducts',
  undefined,
  isEnabled,
);

/**
 * @deprecated
 * @param userId
 * @param productId
 * @param locale
 * @returns
 */
export const getUserProduct = async (userId: string, productId: string, locale: string = 'en') => {
  const res: {
    data: { getUserProducts: Promise<ExtendedSubscriptionStatus> };
  } = await companyQLSecure().query({
    query: productQuery.getUserProduct(),
    variables: { userId, productId, locale },
  });

  return res.data.getUserProducts;
};

/**
 * @deprecated
 * @param userId
 * @param sourceId
 * @param locale
 * @returns
 */
export const getEligibleChildProducts = async (
  userId: string,
  sourceId: string,
  locale: string = 'en',
) => {
  const res: {
    data: { getEligibleChildProducts: Promise<ExtendedSubscriptionStatus[]> };
  } = await companyQLSecure().query({
    query: productQuery.getEligibleChildProducts(userId, sourceId, locale),
  });
  return res.data.getEligibleChildProducts;
};

/**
 * @deprecated
 * @returns
 */
export const getLiveAgentAvailability = async () => {
  try {
    const res = await companyQL.query({
      query: productQuery.getLiveAgentAvailability(),
    });
    return res.data.getLiveAgentAvailability;
  } catch (err) {
    console.log('getLiveAgentAvailability Error: ', err);
  }
};

/**
 * @deprecated
 * @param productId
 * @param locale
 * @returns
 */
export const getProduct = async (productId: string, locale: string = 'en') => {
  const res = await companyQL.query({
    query: productQuery.getProduct(productId, locale),
  });
  return res.data.getProduct;
};

/* MOVED TO UTILS FOLDER */

// export const parseUserProducts = (productsInput: any) => {
//   if (typeof productsInput === 'string') {
//     try {
//       return JSON.parse(productsInput);
//     } catch (error) {
//       console.error(error);
//       return productsInput
//         .substring(2, productsInput.length - 2)
//         .replace(/=/g, ': ')
//         .split('}, {')
//         .map(p =>
//           p.split(', ').reduce((obj, str) => {
//             const [key, value] = str.split(': ');
//             return { ...obj, [key]: value };
//           }, {}),
//         );
//     }
//   }
//   return productsInput;
// };
