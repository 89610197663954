import React from 'react';
// import axios from 'axios';

import { useForm, Controller, FieldValues, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Center,
  Heading,
  Checkbox,
  FormLabel,
  Radio,
  RadioGroup,
  VStack,
  FormControl,
  CheckboxGroup,
} from '@companydotcom/potion';
import {
  InputField,
  PageDivider,
  TranslationErrorMessage,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { v4 as uuidv4 } from 'uuid';
import { useEnrollSingleUserV2Mutation } from '../../../../services/enrollment/enrollment-api';
import yup from '../../../../lib/yup';
import { useNavigate } from 'react-router-dom';

const LeadGenSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('common.inputs.firstName.error'),
    lastName: yup.string().required('common.inputs.lastName.error'),
    email: yup.string().required('common.inputs.email.error'),
    organization: yup.string().notRequired(),
    phone: yup.string().phone().nullable().required('Please enter a valid phone number'),
    website: yup.string().required(),
    productCategory: yup.array().min(1, 'At least one must be selected').nullable(),
    accessories: yup.string().when('productCategory', {
      is: (productCategory: string[]) => productCategory?.includes('Accessories'),
      then: yup.string().required('common.misc.required'),
    }),
    other: yup.string().when('productCategory', {
      is: (productCategory: string[]) => productCategory?.includes('Other'),
      then: yup.string().required('common.misc.required'),
    }),
    eyewear: yup.string().required('common.misc.required'),
    haveECommerce: yup.string().required('common.misc.required'),
    interestedECommerce: yup.string().when('haveECommerce', {
      is: 'No',
      then: yup.string().required('common.misc.required'),
    }),
  });

export const LeadGenForm: React.FC<any> = ({ setFormValues, goToStep }) => {
  const { t } = useTranslation();
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();
  const [enrollSingleUserV2] = useEnrollSingleUserV2Mutation();
  const navigate = useNavigate();

  const radioOptions = ['Yes', 'No'];

  const defaultValues: FieldValues = {
    firstName: '',
    lastName: '',
    organization: '',
    email: '',
    phone: '',
    website: '',
    productCategory: undefined,
    accessories: '',
    other: '',
    eyewear: '',
    haveECommerce: '',
    interestedECommerce: '',
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(LeadGenSchema()),
    defaultValues,
    context: { country },
  });
  const productCategory = useWatch({ name: 'productCategory', control });
  const haveECommerce = useWatch({ name: 'haveECommerce', control });
  const sellsEyewear = useWatch({ name: 'eyewear', control });

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      setFormValues?.((prevState: any) => ({ ...prevState, ...data }));
      if (haveECommerce === 'No' || sellsEyewear === 'No') {
        await enrollSingleUserV2({
          externalIdentifier: uuidv4(),
          email: data.email,
          phone: getFormattedPhoneNumber(data.phone, country ?? 'US'),
          firstName: data.firstName,
          lastName: data.lastName,
          website: data.website,
          businessName: data.organization,
        });
        goToStep?.(1);
      } else {
        navigate('/login', {
          state: {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            website: data?.website,
            organization: data?.organization,
            phone: getFormattedPhoneNumber(data?.phone, country ?? 'US'),
            productCategory,
            accessories: data?.accessories,
            other: data?.other,
            qualifiedLead: 'true',
            showCreateAccount: 'true',
          },
        });
      }
    } catch (err) {
      goToStep?.(1);
      console.log('Error!', err);
    }
  };

  return (
    <Center
      className="facecake-lead-gen__container"
      maxWidth={[null, '2xl']}
      py={[4, 12]}
      px={[0, 4]}
      flexDir="column"
      textAlign={['left', 'center']}
      alignItems={['left', 'center']}
      layerStyle={{ base: undefined, sm: 'formContainer' }}
    >
      <Heading size="hs-xl" width={['292px', '608px']}>
        Let’s Get to Know You
      </Heading>
      <PageDivider width="50px" mt="4" alignSelf="flex-start" display={['block', 'none']} />
      <VStack
        mt={['6', '12']}
        as="form"
        spacing="6"
        width="full"
        maxW={['auto', 'xs']}
        alignItems="left"
      >
        <InputField
          className="facecake-lead-gen__firstName"
          data-test="facecake-lead-gen__firstName"
          register={register}
          name="firstName"
          label={t('common.inputs.firstName.label')}
          errors={errors}
          width={['343px', '340px']}
        />
        <InputField
          className="facecake-lead-gen__lastName"
          data-test="facecake-lead-gen__lastName"
          register={register}
          name="lastName"
          label={t('common.inputs.lastName.label')}
          errors={errors}
          width={['343px', '340px']}
        />
        <InputField
          className="facecake-lead-gen__organization"
          data-test="facecake-lead-gen__organization"
          register={register}
          name="organization"
          label={t('common.inputs.organization.label')}
          errors={errors}
          width={['343px', '340px']}
        />
        <InputField
          className="facecake-lead-gen__email"
          data-test="facecake-lead-gen__email"
          register={register}
          name="email"
          label={t('common.inputs.email.label')}
          errors={errors}
          width={['343px', '340px']}
        />

        <PhoneNumberInputField
          name="phone"
          label={t('common.inputs.phoneNumber.label')}
          className="facecake-lead-gen__phoneField"
          data-test="facecake-lead-gen__phoneField"
          control={control}
          country={country}
          onCountryChange={onCountryChange}
          errors={errors}
          formControlStyles={{ width: ['343px', '340px'] }}
        />
        <InputField
          className="facecake-lead-gen__website"
          data-test="facecake-lead-gen__website"
          register={register}
          name="website"
          label="Website"
          errors={errors}
          width={['343px', '340px']}
        />
        <FormControl id="productCategory" isInvalid={errors && !!errors?.['productCategory']}>
          <FormLabel
            className="facecake-lead-gen__productCategory"
            name="productCategory"
            label="Product Category"
          >
            In addition to eyewear, I am interested in:
          </FormLabel>
          <Controller
            name="productCategory"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <CheckboxGroup {...rest}>
                <VStack spacing="3" align="flex-start" fontWeight="normal">
                  <Checkbox
                    value="Jewelry"
                    key={0}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Jewelry"
                  >
                    Jewelry
                  </Checkbox>
                  <Checkbox
                    value="Makeup"
                    key={1}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Make-Up"
                  >
                    Makeup
                  </Checkbox>
                  <Checkbox
                    value="Clothing"
                    key={2}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Clothing"
                  >
                    Clothing
                  </Checkbox>
                  <Checkbox
                    value="Handbags"
                    key={3}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Handbags"
                  >
                    Handbags
                  </Checkbox>
                  <Checkbox
                    value="Accessories"
                    key={4}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Accessories"
                  >
                    Accessories
                  </Checkbox>
                  {productCategory?.includes('Accessories') ? (
                    <InputField
                      className="facecake-lead-gen__accessories"
                      data-test="facecake-lead-gen__accessories"
                      register={register}
                      name="accessories"
                      errors={errors}
                      width={['343px', '340px']}
                    />
                  ) : null}
                  <Checkbox
                    value="Other"
                    key={5}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Other"
                  >
                    Other
                  </Checkbox>
                  {productCategory?.includes('Other') ? (
                    <InputField
                      className="facecake-lead-gen__other"
                      data-test="facecake-lead-gen__other"
                      register={register}
                      name="other"
                      errors={errors}
                      width={['343px', '340px']}
                    />
                  ) : null}
                </VStack>
              </CheckboxGroup>
            )}
          />
          {errors && (
            <TranslationErrorMessage errors={errors} name="productCategory">
              {/* @ts-ignore */}
              {errors?.['productCategory']?.message}
            </TranslationErrorMessage>
          )}
        </FormControl>

        <Controller
          name="eyewear"
          control={control}
          render={({ field }) => (
            <FormControl id="eyewear" isInvalid={errors && !!errors?.['eyewear']}>
              <FormLabel
                className="facecake-lead-gen__eyewear"
                data-test="facecake-lead-gen__eyewear"
                name="eyewear"
                value="Does your brand currently sell eyewear?"
              >
                Does your brand currently sell eyewear?
              </FormLabel>
              <RadioGroup {...field}>
                <VStack spacing="3" align="flex-start">
                  {radioOptions.map((option, i) => (
                    <Radio
                      key={i}
                      value={option}
                      className="facecake-lead-gen__radioField"
                      data-test={`facecake-lead-gen__radioField_${option}`}
                    >
                      {option}
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>

              {errors && (
                <TranslationErrorMessage errors={errors} name="eyewear">
                  {errors?.['eyewear']?.message}
                </TranslationErrorMessage>
              )}
            </FormControl>
          )}
        />
        <Controller
          name="haveECommerce"
          control={control}
          render={({ field }) => (
            <FormControl id="haveECommerce" isInvalid={errors && !!errors?.['haveECommerce']}>
              <FormLabel
                className="facecake-lead-gen__haveECommerce"
                data-test="facecake-lead-gen__haveECommerce"
                name="haveECommerce"
                value="Does your website currently have E-Commerce?"
              >
                Does your website currently have <br />
                E-Commerce?
              </FormLabel>
              <RadioGroup {...field}>
                <VStack spacing="3" align="flex-start">
                  {radioOptions.map((option, i) => (
                    <Radio
                      key={i}
                      value={option}
                      className="facecake-lead-gen__radioField"
                      data-test={`facecake-lead-gen__radioField_${option}`}
                    >
                      {option}
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
              {errors && (
                <TranslationErrorMessage errors={errors} name="haveECommerce">
                  {errors?.['haveECommerce']?.message}
                </TranslationErrorMessage>
              )}
            </FormControl>
          )}
        />

        {haveECommerce === 'No' ? (
          <Controller
            name="interestedECommerce"
            control={control}
            render={({ field }) => (
              <FormControl
                id="interestedECommerce"
                isInvalid={errors && !!errors?.['interestedECommerce']}
              >
                <FormLabel
                  className="facecake-lead-gen__interestedECommerce"
                  data-test="facecake-lead-gen__interestedECommerce"
                  name="interestedECommerce"
                  value="Are you interested in adding E-Commerce to your website?"
                  mt={6}
                >
                  Are you interested in adding <br />
                  E-Commerce to your website?
                </FormLabel>

                <RadioGroup {...field}>
                  <VStack spacing="3" align="flex-start">
                    {radioOptions.map((option, i) => (
                      <Radio
                        key={i}
                        value={option}
                        className="facecake-lead-gen__radioField"
                        data-test={`facecake-lead-gen__radioField_${option}`}
                      >
                        {option}
                      </Radio>
                    ))}
                  </VStack>
                </RadioGroup>
                {errors && (
                  <TranslationErrorMessage errors={errors} name="interestedECommerce">
                    {errors?.['interestedECommerce']?.message}
                  </TranslationErrorMessage>
                )}
              </FormControl>
            )}
          />
        ) : null}

        <Box textAlign="center" my={[8, 12]}>
          <Button
            className="facecake-lead-gen__submitButton"
            data-test="facecake-lead-gen__submitButton"
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isDisabled={!isValid}
            whiteSpace="normal"
          >
            {t('common.buttons.continue')}
          </Button>
        </Box>
      </VStack>
    </Center>
  );
};
