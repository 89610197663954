import React, { useMemo } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  FormLabel,
  Stack,
  useMediaQuery,
  VStack,
  // Icon,
  // Tooltip,
  // useMediaQuery,
} from '@companydotcom/potion';
import { FontAwesomeIcon, InputField, SelectField, CreditCardNumberInput } from '@companydotcom/ui';
import { Controller } from 'react-hook-form';
import {
  faCreditCardFront,
  faCreditCard,
  // faCircleInfo
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import valid from 'card-validator';
import { monthDropdownValues, generateFutureYears } from '../../../utils';
import { BillingAddressForm } from './billing-address-form';

export interface CreditCardFormProps {
  isCheckout: boolean;
  register: any;
  watch?: any;
  control?: any;
  errors: any;
  pendingApproval?: boolean;
  selectedCreditCard: any;
  setValue: any;
  setError: any;
  isValid?: any;
  isLoading?: boolean;
  displayBillingAddressForm: boolean;
  isAutoRenewChecked?: boolean;
  isSavedPayment?: boolean;
  addNewType?: boolean;
  getValues?: any;
  /** boolean set if we are editing an existing payment method */
  editPaymentMethod?: boolean;
  showAutoRenew?: boolean;
  showSavePaymentMethodRadio?: boolean;
}

export const CreditCardForm: React.FC<CreditCardFormProps> = ({
  isCheckout = true, //defaults to true, required. set to false inorder to render auto-renewal option
  register,
  errors,
  watch,
  control,
  // pendingApproval,
  selectedCreditCard,
  setValue,
  setError,
  isValid,
  isLoading,
  displayBillingAddressForm = false,
  isAutoRenewChecked = false,
  isSavedPayment = false,
  addNewType = false,
  getValues,
  showAutoRenew,
  showSavePaymentMethodRadio,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 48rem)');
  const { t } = useTranslation();

  const isAmex = useMemo(() => {
    return selectedCreditCard?.card?.type === 'american-express';
  }, [selectedCreditCard]);

  if (isSavedPayment) {
    return null;
  }
  if (
    !isValid &&
    selectedCreditCard.card === null &&
    (errors || !errors?.cardDetails || !errors?.cardDetails?.cardNumber)
  ) {
    errors = {};
  }
  // console.log('errors:', errors);
  // selectedCreditCard.isValid, addNewType = true
  if (isSavedPayment && isCheckout && showAutoRenew) {
    return (
      <Box mt={6}>
        <Checkbox isDisabled={isLoading} {...register('autoRenew')}>
          {t('acg.checkout.orderStep.inputs.autopayRenewal.label')}
        </Checkbox>
      </Box>
    );
  }

  return (
    <VStack mt={6} alignItems="flex-start" spacing={6}>
      <InputField
        isLoading={isLoading}
        register={register}
        errors={errors}
        name="cardDetails.cardHolderName"
        label={t('acg.checkout.orderStep.inputs.cardholderName.label')}
        formControlStyles={{ size: 'xl' }}
      />

      <CreditCardNumberInput
        isLoading={isLoading}
        control={control}
        errors={errors}
        name="cardDetails.cardNumber"
        label={t('acg.checkout.orderStep.inputs.cardholderNumber.label')}
      />
      <Stack direction={['column', null, 'row', 'row']} spacing={8} width="full" align="flex-start">
        <SelectField
          isLoading={isLoading}
          register={register}
          errors={errors}
          name="cardDetails.expirationMonth"
          label={t('acg.checkout.orderStep.inputs.expirationMonth.label')}
        >
          {monthDropdownValues(t).map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </SelectField>
        <SelectField
          isLoading={isLoading}
          register={register}
          errors={errors}
          name="cardDetails.expirationYear"
          label={t('acg.checkout.orderStep.inputs.expirationYear.label')}
        >
          {generateFutureYears(new Date().getFullYear(), 14).map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </SelectField>
      </Stack>
      <Stack spacing={8} align="flex-start" direction={['column', null, 'row', 'row']} width="full">
        <InputField
          isLoading={isLoading}
          maxLength={(isAmex && 4) || (selectedCreditCard?.card?.code?.size ?? 3)}
          register={register}
          errors={errors}
          name="cardDetails.cardCvv"
          label={t('acg.checkout.orderStep.inputs.securityCode.label')}
          helperText={t('acg.checkout.orderStep.inputs.securityCode.helperText', {
            codeSize: (isAmex && 4) || (selectedCreditCard?.card?.code?.size ?? 3),
            cardName: selectedCreditCard?.card?.niceType.toUpperCase() ?? '',
            cardSide: isAmex ? 'front' : 'back',
          })}
          onChange={e => {
            setValue('cardDetails.cardCvv', e.target.value.replace(/[^0-9]/g, ''), {
              shouldValidate: true,
            });
            if (!valid.cvv(e.target.value, selectedCreditCard?.card?.code?.size).isValid) {
              setError('cardDetails.cardCvv', {
                type: 'manual',
                message: t('acg.checkout.orderStep.inputs.securityCode.required'),
              });
            }
          }}
          inputRightElement={
            <FontAwesomeIcon
              icon={
                selectedCreditCard?.card?.type === 'american-express'
                  ? faCreditCardFront
                  : faCreditCard
              }
            />
          }
        />
        {isCheckout ? (
          <Box width="full">
            <FormLabel display={['none', 'inherit']} visibility="hidden" mb={1}>
              Checkboxes
            </FormLabel>
            <CheckboxGroup>
              <VStack alignItems="flex-start" spacing={3}>
                {/* // TODO: WIRE UP */}
                {/* {pendingApproval && (
                  <Stack direction="row" spacing={2}>
                    <Checkbox {...register('monthlyInstallments')} width="max">
                      {t('acg.checkout.orderStep.inputs.monthlyInstallments.label')}
                    </Checkbox>
                    <Tooltip
                      shouldWrapChildren
                      hasArrow
                      placement="top"
                      label="This will change your membership to a more affordable monthly installment plan."
                      fontSize="sm"
                      textAlign="center"
                      defaultIsOpen={isMobile}
                      openDelay={isMobile ? 50 : undefined}
                      closeDelay={isMobile ? 300 : undefined}
                    >
                      <Icon color="blue.500" as={FontAwesomeIcon} icon={faCircleInfo} boxSize={4} />
                    </Tooltip>
                  </Stack>
                )} */}
                {showAutoRenew ? (
                  <Checkbox isDisabled={isLoading} {...register('autoRenew')}>
                    {t('acg.checkout.orderStep.inputs.autopayRenewal.label')}
                  </Checkbox>
                ) : null}
                {showSavePaymentMethodRadio ? (
                  <Checkbox
                    isDisabled={isAutoRenewChecked || isSavedPayment || isLoading}
                    isChecked={isAutoRenewChecked || displayBillingAddressForm || isSavedPayment}
                    onChange={e => {
                      setValue('savePaymentInformation', !!e.target.checked, {
                        shouldValidate: true,
                      });
                    }}
                  >
                    {t('acg.checkout.orderStep.inputs.savePaymentInformation.label')}
                  </Checkbox>
                ) : null}
              </VStack>
            </CheckboxGroup>
          </Box>
        ) : (
          !isMobile && (
            <Box width="full">
              <FormLabel display={['none', 'inherit']} visibility="hidden" mb={1}>
                Checkboxes
              </FormLabel>
              <CheckboxGroup>
                <Controller
                  name="Checkboxes"
                  control={control}
                  render={({ field }) => {
                    return (
                      <VStack alignItems="flex-start" spacing={3}>
                        <Checkbox
                          value=""
                          isDisabled={isLoading}
                          {...register('makeDefault')}
                          onChange={e => field.onChange(e.target.checked)}
                          isChecked={field.value ?? false}
                        >
                          Make Default Payment Method
                        </Checkbox>
                      </VStack>
                    );
                  }}
                />
                {/* <VStack alignItems="flex-start" spacing={3}>
                  <Checkbox
                    isDisabled={isLoading}
                    {...register('makeDefault')}
                  >
                    Make Default Payment Method
                  </Checkbox>
                </VStack> */}
              </CheckboxGroup>
            </Box>
          )
        )}
      </Stack>
      {isAutoRenewChecked || addNewType || getValues('savePaymentInformation') ? (
        <InputField
          register={register}
          errors={errors}
          name="cardNickname"
          label="Nickname"
          isRequired={isSavedPayment}
          helperText="Required"
        />
      ) : null}
      <BillingAddressForm
        displayBillingAddressForm={displayBillingAddressForm}
        isSavedPayment={isSavedPayment}
        isLoading={isLoading}
        errors={errors}
        register={register}
        control={control}
        watch={watch}
      />
      {isMobile && !isCheckout && (
        <CheckboxGroup>
          <Controller
            name="Checkboxes"
            control={control}
            render={({ field }) => {
              return (
                <VStack alignItems="flex-start" spacing={3}>
                  <Checkbox
                    value=""
                    isDisabled={isLoading}
                    {...register('makeDefault')}
                    onChange={e => field.onChange(e.target.checked)}
                    isChecked={field.value ?? false}
                  >
                    Make Default Payment Method
                  </Checkbox>
                </VStack>
              );
            }}
          />
        </CheckboxGroup>
      )}
    </VStack>
  );
};
