import React from 'react';
import { Box, VStack, Heading, Text } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faSwatchbook } from '@fortawesome/pro-thin-svg-icons';
import { useTranslation } from 'react-i18next';

export const FaceCakeDesignTile = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <VStack spacing="2">
        <FontAwesomeIcon icon={faSwatchbook} color="pink.500" boxSize={{ base: '12', xl: '16' }} />
        <Heading size="lg" color="pink.500" fontWeight="bold">
          {t('icommerce.tileStatus.design.header')}
        </Heading>
        <Text>{t('icommerce.tileStatus.design.body')}</Text>
      </VStack>
    </Box>
  );
};
