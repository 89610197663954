import React, { useEffect, useState, useCallback } from 'react';
import { Center, Box, Text, Heading, Button } from '@companydotcom/potion';
import { useTranslation, Trans } from 'react-i18next';
import { CheckCircleIcon } from '@companydotcom/ui';
import { useNavigate } from 'react-router-dom';
import type { GmbSharedProps } from './gmb-profile-flow';

export const GmbProfileSuccess: React.FC<GmbSharedProps> = ({
  gmbClaimed,
  gmbVerified,
  gmbFailed,
  selectedVerification,
  handleClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const headers = {
    gmbClaimed: t('gmbProfileFlow.profileSuccess.headers.gmbClaimed'),
    optOut: t('gmbProfileFlow.profileSuccess.headers.optOut'),
    gmbFailed: t('gmbProfileFlow.profileSuccess.headers.gmbFailed'),
    gmbVerified: t('gmbProfileFlow.profileSuccess.headers.gmbVerified'),
    emailComing: t('gmbProfileFlow.profileSuccess.headers.emailComing'),
  };

  const body = {
    gmbClaimed: t('gmbProfileFlow.profileSuccess.body.gmbClaimed'),
    optOut: t('gmbProfileFlow.profileSuccess.body.optOut'),
    gmbFailed: (() => (
      <Trans i18nKey="gmbProfileFlow.profileSuccess.body.gmbFailed">
        Your Directory profile was created, but we could not create your Google My Business Profile
        at this time. Use your Directory tile to manage your Profile and try again later or
        <Button
          variant="link"
          sx={{
            fontSize: 'inherit',
          }}
          onClick={() => {
            navigate('/help');
            handleClose();
          }}
        >
          Contact Support
        </Button>
      </Trans>
    ))(),
    gmbVerified: t('gmbProfileFlow.profileSuccess.body.gmbVerified'),
    emailComing: t('gmbProfileFlow.profileSuccess.body.emailComing'),
  };

  const decideSuccessType = useCallback(
    (claimed, verified, failed) => {
      if (verified) {
        return 'gmbVerified';
      }
      if (selectedVerification === 'SUPPORT_EMAIL') {
        return 'emailComing';
      }
      if (claimed) {
        return 'gmbClaimed';
      }
      if (failed) {
        return 'gmbFailed';
      }
      return 'optOut';
    },
    [selectedVerification],
  );

  const [successType, setSuccessType] = useState(
    decideSuccessType(gmbClaimed, gmbVerified, gmbFailed),
  );

  useEffect(() => {
    setSuccessType(decideSuccessType(gmbClaimed, gmbVerified, gmbFailed));
  }, [gmbClaimed, gmbVerified, gmbFailed, decideSuccessType]);

  return (
    <Center
      className="gmb-profileSuccess__container"
      flexDirection="column"
      textAlign="center"
      py={[8, 12]}
      px={4}
    >
      <CheckCircleIcon mx="auto" />
      <Box maxW="lg" mt={[5, null, 8]}>
        <Heading as="h1" size="hs-xl">
          {/* @ts-ignore */}
          {headers[successType]}
        </Heading>
        <Text textStyle={['lg', 'xl']} mt={[5]}>
          {/* @ts-ignore */}
          {body[successType]}
        </Text>
      </Box>
      <Box textAlign="center" mt={[8, 12]}>
        <Button
          className="gmb-profileSuccess__submitButton"
          data-test="gmb-profileSuccess__submitButton"
          size="lg"
          onClick={() => handleClose()}
        >
          {t('gmbProfileFlow.profileSuccess.submitButton')}
        </Button>
      </Box>
    </Center>
  );
};
