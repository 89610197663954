import React from 'react';
import { useEventData } from '@companydotcom/ui';
import {
  Image,
  Box,
  Flex,
  Link,
  Button,
  LinkBox,
  LinkOverlay,
  Center,
} from '@companydotcom/potion';
import { TileSpinner } from '../tile-spinner';
import type { TileComponentProps } from '../../types';

export interface RSSData {
  creator: string;
  title: string;
  link: string;
  pubDate: string;
  'content:encoded': string;
  'content:encodedSnippet': string;
  'dc:creator': string;
  content: string;
  contentSnippet: string;
  guid: string;
  categories: string[];
  isoDate: Date;
}

interface TileImageWithTextPayload {
  data?: RSSData;
  link?: string;
  title?: string;
  imageUrl?: string;
  text?: string;
  href?: string;
}

export interface TileImageWithTextProps extends TileComponentProps {}

export const TileImageWithText: React.FC<TileImageWithTextProps> = ({
  eventData,
  variant = 'image',
}: TileImageWithTextProps) => {
  const { loading, data } = useEventData<TileImageWithTextPayload>(eventData);

  if (loading || !data) {
    return <TileSpinner />;
  }

  // video variant simply overlays a play button icon over the image,
  //   and adds opacity to the image
  if (variant === 'video') {
    return (
      <Flex
        className="tile__imageWithText_video"
        width="full"
        flex={1}
        flexDirection="column"
        px={4}
      >
        <Box
          as="a"
          href={data?.href}
          target="_blank"
          className="tile-image-with-text"
          width="full"
          backgroundColor="black"
          sx={{ position: 'relative', textAlign: 'center' }}
        >
          <Image src={data?.imageUrl} alt={data?.title} height={150} mx="auto" />
          <Box
            className="image-overlay"
            opacity={0.2}
            backgroundColor="black"
            sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          />
          <PlayCircleIcon
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </Box>

        <Link mt={2} isExternal href={data?.href} sx={{ lineClamp: 2, mx: 1, textAlign: 'center' }}>
          {data?.text ?? ''}
        </Link>
      </Flex>
    );
  }

  return (
    <LinkBox as={Center} className="tile__imageWithText" flex={1} mx="auto" width="full" px={4}>
      <LinkOverlay
        href={data?.href}
        isExternal
        display="flex"
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Image
          sx={{ objectFit: 'cover', objectPosition: '100% 0%' }}
          maxWidth={120}
          minHeight={90}
          mr={2}
          src={data?.imageUrl}
        />
        <Button variant="link" whiteSpace="normal" textAlign="left" maxWidth={172}>
          {data?.text}
        </Button>
      </LinkOverlay>
    </LinkBox>
  );
};

const PlayCircleIcon = (props: any) => (
  <Box {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="white" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
    </svg>
  </Box>
);
