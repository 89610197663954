import {
  EventFees,
  EventForRegistration,
  SearchIndividualResponse,
} from '../../../../services/acg/acg.types';

export const determineIfSingleFee = (acgEvent?: EventForRegistration) => {
  return acgEvent?.registrantAndFees?.[0]?.guests?.[0]?.packages?.length === 1;
};

export const getGuestEventFees = (acgEvent?: EventForRegistration) => {
  return acgEvent?.registrantAndFees?.[0]?.guests?.[0]?.packages
    ? acgEvent?.registrantAndFees?.[0]?.guests?.[0]?.packages.map(p => p?.eventFees)
    : [];
};

export const calculateTotalGuestFees = (
  hasOneFee: boolean,
  eventFees: EventFees[],
  currentFees: string[],
) => {
  if (hasOneFee) {
    return eventFees?.[0]?.price.toFixed(2);
  }

  return eventFees
    ?.reduce((acc, cur) => {
      if (currentFees?.find(f => f === cur?.priceKey)) {
        return (acc += cur?.price);
      }
      return acc;
    }, 0)
    ?.toFixed(2);
};

export const createGuestData = (
  formData?: Record<string, any>,
  acgEvent?: EventForRegistration,
  activeGuest?: Partial<SearchIndividualResponse>,
  // guestFees?: EventFees[],
) => {
  return {
    registrantType:
      acgEvent?.registrantAndFees?.[0]?.guests?.[0]?.registrant_type?.registrantTypeName || 'Guest',
    emailAddress: activeGuest ? activeGuest?.emailAddress : formData?.emailAddress,
    firstName: activeGuest ? activeGuest?.firstName : formData?.firstName,
    lastName: activeGuest ? activeGuest?.lastName : formData?.lastName,
    badgeName: formData?.badgeName,
    individualType: formData?.individualType,
    organizationName: formData?.organizationName,
    title: formData?.title,
    fees: formData?.fees,
    // guestFees
    //   ?.filter(f => !!(formData?.fees as EventFees[])?.find(f2 => f2[0] === f.productName))
    //   .map(fo => ({ ...fo, priceRecordKey: fo?.priceKey })),
  };
};
