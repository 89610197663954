class Zuora {
  static loadZuora(callback: any, env: any) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    if (env && env === 'prod') {
      s.src = 'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js';
    } else {
      s.src = 'https://apisandboxstatic.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js';
    }
    document.getElementsByTagName('head')[0].appendChild(s);
    s.onload = () => {
      if (callback) {
        callback();
      }
    };
  }

  static initialize(callback: any) {
    const hostname = window.origin;
    this.loadZuora(
      callback,
      hostname.includes('-corp') || hostname.includes('localhost') ? 'dev' : 'prod',
    );
  }
}

export default Zuora;
