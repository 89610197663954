const baseStyle = {
  fontFamily: 'heading',
  fontWeight: 'bold',
};

const sizes = {
  '4xl': {
    fontSize: ['6xl', null, '7xl'],
    lineHeight: 1,
  },
  '3xl': {
    fontSize: ['5xl', null, '6xl'],
    lineHeight: 1,
  },
  '2xl': {
    fontSize: ['4xl', null, '5xl'],
    lineHeight: [1.2, null, 1],
  },
  xl: {
    fontSize: ['3xl', null, '4xl'],
    lineHeight: [1.33, null, 1.2],
  },
  lg: {
    fontSize: ['2xl', null, '3xl'],
    lineHeight: [1.33, null, 1.2],
  },
  md: { fontSize: 'xl', lineHeight: 1.2 },
  sm: { fontSize: 'md', lineHeight: 1.2 },
  xs: { fontSize: 'sm', lineHeight: 1.2 },
  'hs-xs': {
    fontSize: 'sm',
    lineHeight: 1.2,
  },
  'hs-sm': {
    fontSize: 'md',
    lineHeight: 1.2,
  },
  'hs-md': {
    fontSize: 'xl',
    lineHeight: 1.2,
  },
  'hs-lg': {
    fontSize: '2xl',
    lineHeight: 1.33,
  },
  'hs-xl': {
    fontSize: '3xl',
    lineHeight: 1.33,
  },
  'hs-2xl': {
    fontSize: ['3xl', null, '4xl'],
    lineHeight: 1.2,
  },
  'hs-3xl': {
    fontSize: '5xl',
    lineHeight: 1,
  },
  'hs-4xl': {
    fontSize: '6xl',
    lineHeight: 1,
  },
  // Large heading styles
  'hl-xs': {
    fontSize: 'sm',
    lineHeight: 1.2,
  },
  'hl-sm': {
    fontSize: 'md',
    lineHeight: 1.2,
  },
  'hl-md': {
    fontSize: 'xl',
    lineHeight: 1.2,
  },
  'hl-lg': {
    fontSize: '3xl',
    lineHeight: 1.2,
  },
  'hl-xl': {
    fontSize: '4xl',
    lineHeight: 1.2,
  },
  'hl-2xl': {
    fontSize: '5xl',
    lineHeight: 1,
  },
  'hl-3xl': {
    fontSize: '6xl',
    lineHeight: 1,
  },
  'hl-4xl': {
    fontSize: '7xl',
    lineHeight: 1,
  },
};

const defaultProps = {
  size: 'xl',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
