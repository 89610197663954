import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAssets, useSource } from '@companydotcom/providers';
import IframeResizer from 'iframe-resizer-react';
import {
  useDisclosure,
  Button,
  Text,
  Image,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  HStack,
  //canUseDOM,
} from '@companydotcom/potion';
import type { TileComponentProps } from '../../types';
import { useTile } from '../../../../providers';

export interface TileFooterProps extends TileComponentProps {}

export const TileFooter: React.FC<TileFooterProps> = props => {
  const { variant, text, componentTheme } = props;
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tile = useTile();
  const learnMore = text || t('tiles.footer.learnMore');
  const source = useSource();
  const assets = useAssets();

  const computedLearnMoreUri = () => {
    if (tile?.learnMoreUri) {
      return tile?.learnMoreUri;
    }

    if (assets?.learn_more) {
      return `${assets?.learn_more}${tile?.tileSlug}/index.html`;
    }

    return `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/${source?.sourceId}/marketing_pages/${tile?.tileSlug}/index.html`;
  };
  const getHeight = (variant: any) => {
    switch (variant) {
      case 'condensed':
        return 5;
      case 'extended':
        return 7;
      default:
        return 4;
    }
  };
  return (
    <HStack
      className="tile__tileFooter"
      borderTop="1px solid"
      borderColor="gray.200"
      py={2}
      width="full"
      justifyContent="space-between"
    >
      <VStack
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={variant === 'condensed' ? 0 : 1}
        sx={componentTheme}
      >
        <Text fontSize="8px" textAlign="left">
          {t('tiles.footer.broughtToYouBy')}
        </Text>
        <Image
          src={tile?.vendorLogoUri ?? ''}
          height={getHeight(variant)}
          fallbackSrc={`${process.env.REACT_APP_IMG_URL}/vendor-logos/company-vendor-logo.svg`}
        />
      </VStack>
      {variant !== 'hidden' ? (
        <Button
          fontSize="sm"
          _hover={{
            textDecoration: 'none',
          }}
          variant="link"
          onClick={onOpen}
        >
          {learnMore}
        </Button>
      ) : null}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <IframeResizer
            log
            heightCalculationMethod="lowestElement"
            style={{ width: '1px', minWidth: '100%' }}
            src={computedLearnMoreUri()}
            title="learnMore"
            allowFullScreen
            frameBorder={0}
            scrolling={false}
          />
        </ModalContent>
      </Modal>
    </HStack>
  );
};
