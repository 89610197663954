import gql from 'graphql-tag';

export const GET_USER_CUIS = gql`
  query GetUserCUIs($userId: String!) {
    getUser(userId: $userId) {
      userId
      seoId
      accountId
      userCUIs {
        cuiId
        providerId
        name
        priority
        type
        tileName
        trigger
        isActive
        dataRequired
        dateUpdated
      }
    }
  }
`;
