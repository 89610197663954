import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['container', 'divider', 'form', 'formButton'];

const baseStyleContainer: SystemStyleObject = {
  paddingTop: 14,
  paddingBottom: '80px',
  maxWidth: 776,
  marginX: 'auto',
  paddingX: 5,
};

const baseStyleDivider: SystemStyleObject = {};

const baseStyleForm: SystemStyleObject = {
  width: 'full',
  backgroundColor: 'gray.50',
  borderRadius: 'md',
  paddingX: 7,
  paddingTop: 7,
  paddingBottom: 15,
};

const baseStyleFormButton: SystemStyleObject = {};

const baseStyle = () => ({
  container: baseStyleContainer,
  divider: baseStyleDivider,
  form: baseStyleForm,
  formButton: baseStyleFormButton,
});

export default {
  parts,
  baseStyle,
};
