import {
  Box,
  VStack,
  Container,
  Heading,
  Button,
  useBreakpointValue,
  Text,
  useMediaQuery,
} from '@companydotcom/potion';
import { InputField, PageDivider, TextareaField } from '@companydotcom/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import yup from '../../../lib/yup';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { useSendContactUsEmailMutation } from '../../../services/facecake/facecake-api';
import { useAwaitableFacade } from '../../../hooks';

export const EnterpriseContactUsForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const headingSize = useBreakpointValue({ base: 'hs-2xl', xl: 'hs-3xl' });
  const { data: globalUser } = useGetGlobalUserQuery();
  const snsInterface = useAwaitableFacade(globalUser);
  const [emailSent, setEmailSent] = useState(false);
  const [sendContactUsEmail] = useSendContactUsEmailMutation();
  const existingUser = !isEmpty(
    globalUser?.account?.Products?.filter(plan => plan?.isActive === true && !!plan?.ratePlanName),
  );

  const defaultValues: FieldValues = {
    email: globalUser?.email || '',
    message: '',
  };

  const contactFormSchema = () =>
    yup.object().shape({
      email: yup.string().required('Required'),
      message: yup.string().required('Required'),
    });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(contactFormSchema()),
    defaultValues,
  });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      const res = await sendContactUsEmail({
        snsInterface,
        payload: {
          email: values?.email,
          message: values?.message,
        },
      });
      //@ts-ignore
      if (res?.data?.status !== 'fail' || res.error.name !== 'Error') {
        setEmailSent(true);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Container
      maxW={['100rem']}
      centerContent
      px={['4', '8']}
      pt={['6', '12']}
      pb="5rem"
      bg={['transparent', 'white']}
    >
      <VStack width="full" maxW={908} p={4} rowGap={[6, 12]}>
        <Box width="full">
          <Heading size={headingSize} textAlign={['left', 'center']}>
            {t('icommerce.enterpriseContactUsPage.header')}
          </Heading>
          {isMobile ? <PageDivider mt={4} mx="inherit" /> : null}
        </Box>
        {emailSent ? (
          <Text textStyle="2xl" textAlign="center">
            {existingUser
              ? t('icommerce.enterpriseContactUsPage.upgradeBody')
              : t('icommerce.enterpriseContactUsPage.contactBody')}
          </Text>
        ) : (
          <>
            <InputField
              className="facecake-enterprise-contactUs__emailField"
              data-test="facecake-enterprise-contactUs__emailField"
              name="email"
              label="Your Email"
              register={register}
              errors={errors}
            />
            <TextareaField
              className="facecake-enterprise-contactUs__messageField"
              data-test="facecake-enterprise-contactUs__messageField"
              name="message"
              label="Let Us Know What You Need"
              register={register}
              errors={errors}
              height="207px"
            />
          </>
        )}
        <Box width={['full', 'auto']}>
          {emailSent ? (
            existingUser ? (
              <Button
                size="lg"
                isDisabled={!isValid}
                isLoading={isSubmitting}
                onClick={() => navigate('/')}
                width={['full', 'inherit']}
              >
                {t('common.buttons.toDashboard')}
              </Button>
            ) : (
              <Text textStyle="2xl" textAlign="center">
                {t('icommerce.unqualifiedLead.closeWindow')}
              </Text>
            )
          ) : (
            <Button
              size="lg"
              isDisabled={!isValid}
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              width={['full', 'inherit']}
            >
              {t('common.buttons.submit')}
            </Button>
          )}
        </Box>
      </VStack>
    </Container>
  );
};
