import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

export interface DataCollectorState {
  isUserDataCollectorsEmpty: boolean;
}

export const initialState: DataCollectorState = {
  isUserDataCollectorsEmpty: false,
};

export const dataCollectorSlice = createSlice({
  name: 'dataCollector',
  initialState,
  reducers: {
    updateIsUserDataCollectorsEmpty: (state, action: PayloadAction<Partial<boolean>>) => {
      state.isUserDataCollectorsEmpty = action.payload;
    },
  },
});

export const { updateIsUserDataCollectorsEmpty } = dataCollectorSlice.actions;

export const selectIsUserDataCollectorsEmpty = (state: RootState) =>
  state.dataCollector.isUserDataCollectorsEmpty;

export default dataCollectorSlice.reducer;
