import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Center,
  Box,
  Heading,
  Text,
  useBreakpointValue,
  VStack,
  potion,
  Button,
  OrderedList,
  UseTabStepperReturn,
} from '@companydotcom/potion';
import { PageDivider } from '@companydotcom/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CustomQuestion } from '../../../../services/acg/acg.types';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getEventForPurchase } from '../../acg-checkout/checkout-slice';
import { selectGuestsToRegister } from '../../acg-add-guest/add-guest-slice';
import {
  checkEventStatus,
  eventsFormSliceMapper,
  FormSliceZone,
  getCustomQuestionsSchema,
  useAcgCartStorage,
} from '../../shared';
import { updateActiveRegistrant } from '../event-registration-slice';
import { isEmpty } from 'lodash';
import { useLazyGetEventForRegistrationQuery } from '../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

const createYupSchema = (data?: CustomQuestion[]) => {
  const schema = getCustomQuestionsSchema(data);

  return yup.object().shape({
    customQuestions: yup.object().shape(schema),
  });
};
export interface EventRegistrationDemographicQuestionsProps
  extends Partial<Pick<UseTabStepperReturn, 'goToStep'>> {}

export const EventRegistrationDemographicQuestions = ({
  goToStep,
}: EventRegistrationDemographicQuestionsProps) => {
  const headingSize = useBreakpointValue({ base: 'md', sm: 'xl' });
  const eventForPurchase = useAppSelector(getEventForPurchase);
  const guestsToRegister = useAppSelector(selectGuestsToRegister);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { clearLocalCart, addEventToLocalCart } = useAcgCartStorage();
  const selectedValues: any = JSON.parse(window.localStorage.getItem('acg-cart') as string);
  const defaultValues = {
    customQuestions: selectedValues?.items?.event?.registrant?.customQuestions
      ? selectedValues?.items?.event?.registrant?.customQuestions
      : undefined,
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      createYupSchema(eventForPurchase?.selectedRegistrantType?.customQuestions),
    ),
    defaultValues,
  });
  const watchAllFields = watch();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [getEvent, { isFetching }] = useLazyGetEventForRegistrationQuery();
  const [searchParams] = useSearchParams();
  const regEventKey = searchParams.get('Reg_evt_key');

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      const update = {
        showEventFullAlert:
          !eventForPurchase?.hasWaitList &&
          guestsToRegister.length + eventForPurchase?.totalRegistered + 1 >
            eventForPurchase?.eventCapacity,
        showWaitListAlert: eventForPurchase?.showWaitListAlert,
        waitListedRegistrants: eventForPurchase?.waitListedRegistrants,
        waitListLimit: eventForPurchase?.waitListLimit,
        eventCapacity: eventForPurchase?.eventCapacity,
        totalRegistered: eventForPurchase?.totalRegistered,
        eventRecordKey: eventForPurchase?.eventRecordKey,
        registrant: { ...eventForPurchase?.registrant, customQuestions: data.customQuestions },
        registrantType: eventForPurchase?.registrantType,
        guests: guestsToRegister,
      };
      clearLocalCart();
      addEventToLocalCart(update);

      if (globalUser && regEventKey) {
        const latestEventData = await getEvent({ user: globalUser, eventId: regEventKey }).unwrap();
        const {
          shouldShouldWaitlistAlert,
          shouldShowEventFullAlert,
          shouldShowEventOverCapacityAlert,
        } = checkEventStatus(latestEventData, guestsToRegister.length);
        navigate('/acg-shopping-cart', {
          state: {
            shouldShouldWaitlistAlert,
            shouldShowEventFullAlert,
            shouldShowEventOverCapacityAlert,
            totalWaitList: latestEventData?.totalWaitList,
            lastStep: 'demographic-questions',
            flow: 'checkout',
          },
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const addGuestSubmitHandler = (data: typeof defaultValues) => {
    try {
      if (data.customQuestions) {
        dispatch(
          updateActiveRegistrant({
            customQuestions: data.customQuestions,
          }),
        );
      }
      goToStep?.('add-new-guest');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Center
      className="eventRegistrations__demographicsQuestionsStep"
      flexDirection="column"
      bg={['transparent', 'white']}
      pt={[0, 4]}
      px={[0, 4]}
    >
      <Box width="full" maxW={908}>
        <Box width="full" textAlign={['center', 'left']} mb={7}>
          <Heading size={headingSize}>Questions from the Event Organizers</Heading>
          <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
          <Text
            mt={[8, 12]}
            lineHeight={1.25}
            textStyle={['lg', '3xl']}
            fontWeight="bold"
            opacity={0.7}
          >
            Please answer these questions for the event organizers before continuing to checkout.
          </Text>
        </Box>
        <potion.form>
          <VStack align="flex-start">
            <OrderedList width="full" spacing={[6, 12]} stylePosition="outside" fontWeight="medium">
              {eventForPurchase?.selectedRegistrantType?.customQuestions &&
                eventForPurchase?.selectedRegistrantType?.customQuestions.length > 0 && (
                  <FormSliceZone
                    register={register}
                    errors={errors}
                    control={control}
                    watchAllFields={watchAllFields}
                    setValue={setValue}
                    data={eventsFormSliceMapper(
                      eventForPurchase?.selectedRegistrantType?.customQuestions,
                      'customQuestions',
                    )}
                  />
                )}
            </OrderedList>
          </VStack>
          <VStack mt={14} spacing={4}>
            <Button
              isLoading={isSubmitting || isFetching}
              isDisabled={!isEmpty(errors)}
              size="lg"
              minWidth="296px"
              onClick={handleSubmit(onSubmit)}
            >
              Add to Cart
            </Button>
            {eventForPurchase?.shouldShowGuestButton ? (
              <Button
                onClick={handleSubmit(addGuestSubmitHandler)}
                isDisabled={!isValid || isSubmitting || isFetching}
                variant="ghost"
                size="sm"
              >
                Add to cart and add guest
              </Button>
            ) : null}
          </VStack>
        </potion.form>
      </Box>
    </Center>
  );
};
