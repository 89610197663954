import React, { useState, useEffect } from 'react';
import { useTabStepper, Tabs, TabPanel, TabPanels, Flex } from '@companydotcom/potion';
import { Stepper } from '@companydotcom/ui';
import { HowItWorks } from './how-it-works';
import { BasicBusinessInformation } from './basic-business-information';
import { VerificationBusiness } from './verification-business';
import { VerificationCode } from './verification-code';
import { BLPCongrats } from './blp-congrats';
import { CallingYourPhone } from './calling-your-phone';
import { ScheduleWalkThrough } from './schedule-walk-through';
import { GmbDuplicate } from './gmb-duplicate';
import { BLPError } from './blp-error';

const fttfSteps = [
  { slug: 'how-it-works', component: <HowItWorks /> },
  {
    slug: 'basic-business-information',
    label: 'Basic Business Information',
    component: <BasicBusinessInformation />,
  },
  {
    label: 'Choose Verification Method',
    slug: 'business-verification',
    component: <VerificationBusiness />,
  },
  { slug: 'verification-code', label: 'Enter Verification Code', component: <VerificationCode /> },
  { slug: 'blp-congrats', label: 'Business Listings Profile', component: <BLPCongrats /> },
  { slug: 'calling-your-phone', component: <CallingYourPhone /> },
  { slug: 'schedule-walk-through', component: <ScheduleWalkThrough /> },
  { slug: 'gmb-duplicate', component: <GmbDuplicate /> },
  { slug: 'blp-error', component: <BLPError /> },
];

export interface FirstTimeTileFlowProps {
  initialStep?: number | string;
  sharedComponentState?: any;
}

export const FirstTimeTileFlow: React.FC<FirstTimeTileFlowProps> = props => {
  const { initialStep, sharedComponentState } = props;
  const { tabIndex, handleTabsChange, goToStep, nextStep, previousStep } = useTabStepper({
    steps: fttfSteps,
    initialStep,
  });

  return (
    <Tabs
      layerStyle="formContainer"
      variant="unstyled"
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <FTTFNav tabIndex={tabIndex} steps={fttfSteps.filter(s => s.label)} />
      <TabPanels mt={[0, null, null, 4]} width="full">
        {fttfSteps.map(step => (
          <TabPanel paddingTop={0} key={step.slug}>
            {React.cloneElement(step.component, {
              nextStep,
              previousStep,
              goToStep,
              ...sharedComponentState,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const FTTFNav = ({ steps, tabIndex }: any) => {
  const [fttfSteps, setFttfSteps] = useState([]);

  useEffect(() => {
    setFttfSteps(steps);
  }, [steps]);

  return tabIndex !== 0 ? (
    <>
      <Flex
        as="nav"
        className="fttf-nav"
        justifyContent="center"
        flexDirection={['column', null, 'row']}
        mt={[11]}
        mx="auto"
      >
        {fttfSteps && fttfSteps.length >= 1 && (
          <Stepper activeStep={tabIndex - 1} steps={fttfSteps} />
        )}
      </Flex>
    </>
  ) : null;
};
