import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['addNewUserButton'];

const baseStyleButton: SystemStyleObject = {
  backgroundColor: 'green.400',
  color: 'white',
  boxShadow: 'sm',
  _hover: {
    backgroundColor: 'green.500',
  },
};

const baseStyle = () => ({
  addNewUserButton: baseStyleButton,
});

export default {
  parts,
  baseStyle,
};
