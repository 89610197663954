import { TFunction } from 'react-i18next';

const accountForShortMonths = (day: any, month: any, year: any) => {
  return Math.min(
    parseInt(day, 10),
    new Date(parseInt(year, 10), parseInt(month, 10), 0).getDate(),
  ).toString();
};

export const calculateNextInvoiceDate = (
  invoices: any,
  billCycleDay: any,
  format?: string,
  t?: TFunction,
) => {
  const months: { [key: string]: string } = {
    '01': 'common.months.january',
    '02': 'common.months.february',
    '03': 'common.months.march',
    '04': 'common.months.april',
    '05': 'common.months.may',
    '06': 'common.months.june',
    '07': 'common.months.july',
    '08': 'common.months.august',
    '09': 'common.months.september',
    '10': 'common.months.october',
    '11': 'common.months.november',
    '12': 'common.months.december',
  };
  const now = new Date();
  let month;
  let year;
  // zuora date format is YYYY-MM-DD
  // we want MM/DD/YYYY
  // or for subscriptions page MMMMMM, D, YYYY
  const lastRegularInvoice = invoices.find(
    ({ invoiceDate }: any) => invoiceDate.split('-')[2] === billCycleDay,
  );

  if (lastRegularInvoice) {
    if (now.getDate() >= lastRegularInvoice.invoiceDate.split('-')[2]) {
      // there has already been an invoice this month (including today)
      const nextMonth = Number.parseInt(lastRegularInvoice.invoiceDate.split('-')[1], 10) + 1;
      month = nextMonth === 13 ? '01' : nextMonth.toString().padStart(2, '0');
      year = (
        nextMonth === 13
          ? parseInt(lastRegularInvoice.invoiceDate.split('-')[0], 10) + 1
          : lastRegularInvoice.invoiceDate.split('-')[0]
      ).toString();
    } else {
      // the next invoice will be in the current month
      month = Number.parseInt(lastRegularInvoice.invoiceDate.split('-')[1], 10)
        .toString()
        .padStart(2, '0');
      year = lastRegularInvoice.invoiceDate.split('-')[0];
    }
  } else if (now.getDate() >= billCycleDay) {
    // no existing invoices, this shouldn't happen if they have a zuora account;
    const nextMonth = now.getMonth() + 2; // 0-indexed;
    month = nextMonth === 13 ? '01' : nextMonth.toString().padStart(2, '0');
    //@ts-ignore
    year = parseInt(nextMonth === 13 ? now.getFullYear() + 1 : now.getFullYear(), 10);
  } else {
    month = (now.getMonth() + 1).toString().padStart(2, '0'); // 0-indexed
    year = now.getFullYear();
    // invoice will be this month
  }

  const day = (
    billCycleDay > 28 ? accountForShortMonths(billCycleDay, month, year) : billCycleDay
  ).padStart(2, '0');

  if (format === 'long-form') {
    // used on subscriptions page
    // @ts-ignore
    return `${t?.(months[month])} ${Number.parseInt(day, 10)}, ${year}`;
  }
  // @ts-ignore
  return `${t?.(month)}/${day}/${year}`;
};
