import { v4 as uuidv4 } from 'uuid';
import { platformHelpers } from '@companydotcom/helpers';

import { subscribeToNotifications } from '../../../services/notification/notification-svc';
// import { publishTileEvent } from '../../../services/event/old-api/event-svc';

export const fetchOrderSummary = async (
  user: any,
  orderId: any,
  productid: any,
  setExternalOrderSummary: any,
  setExternalLoading: any,
  publishTileEvent: any,
) => {
  // create a event data to publish get ordersummary
  const eventId = uuidv4();
  const eventToPublish = platformHelpers.fetchOrderSummary(user, orderId, eventId, productid);
  // subscribe event and publish above eventdata to get appropriate notification data
  await subscribeToNotifications(user.userId, async (notification: any) => {
    if (notification.body.triggerEventId === eventId) {
      // if notification data belongs to same user then check for existance of paymentMethod
      setExternalOrderSummary(notification.body.payload);
      setExternalLoading(false);
    }
  });
  // since subscription is taking time to give notification sleeping for some time.
  await new Promise(
    r =>
      setTimeout(() => {
        //@ts-ignore
        r();
      }, 5000), // not loading on stage increasing sleeping time
  );

  await publishTileEvent(eventToPublish);
};
