import React from 'react';
import { Tabs, TabPanels, TabPanel, useTabStepper } from '@companydotcom/potion';
import { useLocation } from 'react-router-dom';
import { PublicProfile } from './acg-my-profile-public-profile';
import { PrivateProfile } from './acg-my-profile-private-profile';
import { BackButtonLayout } from '../../../../components/layouts';

const myProfileSteps = [
  {
    slug: 'private-profile',
    component: <PrivateProfile />,
  },
  {
    slug: 'public-profile',
    component: <PublicProfile />,
  },
];

export interface LocationStateProps {
  defaultStep: 'private-profile' | 'public-profile';
}

export const MyAcgProfile = () => {
  const location = useLocation();
  const state = location.state as LocationStateProps;

  const { tabIndex, handleTabsChange, previousStep, goToStep } = useTabStepper({
    steps: myProfileSteps,
    initialStep: state?.defaultStep,
  });

  return (
    <BackButtonLayout isDisplayed={tabIndex > 0} onBackClick={() => previousStep?.()}>
      <Tabs variant="page" index={tabIndex} onChange={handleTabsChange}>
        <TabPanels>
          {myProfileSteps.map(step => (
            <TabPanel key={step.slug}>{React.cloneElement(step.component, { goToStep })}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </BackButtonLayout>
  );
};
