import { createContext } from '@companydotcom/potion';
import { UserTile } from '@companydotcom/types';

const [TileProvider, useTile] = createContext<UserTile>({
  name: 'TileContext',
  errorMessage:
    'useTile: `context` is undefined. Seems you forgot to wrap the tile component in `<Tile />`',
});

export { TileProvider, useTile };
