import type { ComponentStyleConfig } from '@companydotcom/potion';

type Dict = Record<string, any>;

function baseStyleButton(props: Dict) {
  const { colorScheme: c } = props;
  return {
    backgroundColor: `${c}.500`,
    boxShadow: 'sm',
    _hover: {
      backgroundColor: `${c}.600`,
    },
    _active: {
      backgroundColor: `${c}.700`,
    },
  };
}

const baseStyle = (props: Dict) => ({
  ...baseStyleButton(props),
});

const defaultProps = {
  colorScheme: 'green',
};

export default {
  baseStyle,
  defaultProps,
} as ComponentStyleConfig;
