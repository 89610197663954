import { useState } from 'react';
import {
  Box,
  Text,
  ListItem,
  UnorderedList,
  Button,
  HStack,
  Heading,
  Image,
  potion,
  CircularProgress,
} from '@companydotcom/potion';
import { FontAwesomeIcon, PageDivider, AppSpinner } from '@companydotcom/ui';
import Uppy, { UppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { useUppy } from '@uppy/react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { FaceCakePageLayout } from '../layouts';
import { FileInputButton } from '../../../components/elements/file-input-button/file-input-button';
import {
  useDeleteItemFromBucketMutation,
  useUploadAccountAssetMutation,
} from '../../../services/management/management-api';
import {
  useGetGlobalUserQuery,
  useUpdateUserAccountMutation,
} from '../../../services/user/user-api';
import { useToast } from '../../../hooks';

export const FaceCakeUploadLogo = () => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const { t } = useTranslation();

  return (
    <FaceCakePageLayout>
      <Box>
        <Heading size="hs-2xl">{t('icommerce.uploadLogoPage.heading')}</Heading>
        <PageDivider width="50px" mt={['4', null, '6']} alignSelf="flex-start" />
      </Box>
      <Box mt="12" fontSize="2xl">
        <Text>{t('icommerce.uploadLogoPage.list.description')}</Text>
        <UnorderedList>
          {/* <ListItem>{t('icommerce.uploadLogoPage.list.listItemOne')}</ListItem>
          <ListItem>{t('icommerce.uploadLogoPage.list.listItemTwo')}</ListItem> */}
          <ListItem>{t('icommerce.uploadLogoPage.list.listItemThree')}</ListItem>
          <ListItem>{t('icommerce.uploadLogoPage.list.listItemFour')}</ListItem>
        </UnorderedList>
      </Box>
      {globalUser?.accountId ? (
        <Uploader />
      ) : (
        <AppSpinner
          mt="12"
          containerStyles={{
            alignItems: 'flex-start',
          }}
        />
      )}
    </FaceCakePageLayout>
  );
};

export const Uploader = () => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const { t } = useTranslation();
  const [uploadAccountLogo] = useUploadAccountAssetMutation();
  const [updateAccount] = useUpdateUserAccountMutation();
  const [deleteLogo] = useDeleteItemFromBucketMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [file, setFile] = useState<UppyFile<Record<string, unknown>, Record<string, unknown>>>();
  const toast = useToast();

  const uppy = useUppy(() => {
    return new Uppy({
      id: 'facecake-logo',
      restrictions: {
        // 1mb max file size
        maxFileSize: 1048576,
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
      },
    })
      .use(AwsS3, {
        async getUploadParameters(file) {
          const fileName = `${globalUser?.accountId}/logo.${file.extension}`;
          const {
            body: { link },
          } = await uploadAccountLogo({
            fileName,
          }).unwrap();
          // Send a request to our PHP signing endpoint.
          return {
            method: 'PUT',
            url: link,
            headers: {
              'Content-Type': file.type ?? '',
            },
          };
        },
      })
      .use(ThumbnailGenerator, {
        // thumbnailWidth: 200,
        thumbnailHeight: 32,
        thumbnailType: 'image/png',
      });
  });

  uppy.on('restriction-failed', (_file, error) => {
    setErrorMessage(error?.message);
    console.error(error?.stack);
  });

  uppy.on('upload', () => {
    setIsUploading(true);
  });

  uppy.on('file-added', file => {
    setErrorMessage('');
    setFile(file);
  });

  uppy.on('thumbnail:generated', file => {
    setFile(file);
  });

  const removeChosenFile = async (file?: UppyFile) => {
    setIsDeleting(true);
    if (file) {
      uppy.removeFile(file?.id);
    }

    try {
      if (globalUser?.accountId && globalUser?.account?.logo) {
        const deleteResponse = await deleteLogo({
          fileName: `${globalUser?.accountId}/logo.png`,
        }).unwrap();

        if (deleteResponse?.body?.deleted) {
          await updateAccount({ accountId: globalUser?.accountId, logo: null });
        }
      }

      setErrorMessage('');
      setFile(undefined);
      setIsSuccessful(false);
      setIsDeleting(false);
      toast({
        title: 'Logo Successfully Deleted',
        status: 'success',
        isClosable: false,
      });
    } catch (err) {
      console.log('Error deleteing logo', err);
    }
  };

  const onSubmit = async () => {
    uppy.upload().then(async result => {
      setIsUploading(false);

      if (result.failed.length > 0) {
        setIsSuccessful(false);
        console.error('Errors:');
        result.failed.forEach(file => {
          console.error(file.error);
        });
      }

      setIsSuccessful(true);
      if (globalUser?.accountId) {
        await updateAccount({
          accountId: globalUser?.accountId,
          logo: `https://dxp-account-assets-${process.env.REACT_APP_S3_FOLDER}.s3.amazonaws.com/${globalUser?.accountId}/logo.${file?.extension}`,
        });

        toast({
          title: 'Logo Successfully Uploaded',
          status: 'success',
          isClosable: false,
        });
      }
    });
  };

  return (
    <>
      <HStack mt="12" spacing="2">
        {!file && !isUploading && !globalUser?.account?.logo && (
          <>
            <FileInputButton
              variant="outline"
              size="lg"
              colorScheme="blue"
              uppy={uppy}
              inputName="files[]"
              locale={{
                strings: {
                  chooseFiles: t('icommerce.uploadLogoPage.chooseFile'),
                },
              }}
            />
            <Text fontSize="lg">{t('icommerce.uploadLogoPage.noFileChosen')}</Text>
          </>
        )}
        {(isUploading || isDeleting) && (
          <>
            <CircularProgress size="80px" isIndeterminate color="pink.500" />
            <Text fontSize="lg">{isUploading ? 'Uploading...' : 'Deleting...'}</Text>
          </>
        )}
      </HStack>

      {!isUploading && !isDeleting && (
        <>
          <HStack spacing="4">
            {(file?.preview || globalUser?.account?.logo) && (
              <Box bg="blackAlpha.100" py="18px" px="10" borderRadius="lg">
                <Image
                  maxH="8"
                  src={file?.preview || (globalUser?.account?.logo as string | undefined)}
                  alt="Thumbnail image"
                />
              </Box>
            )}
            {(globalUser?.account?.logo || file) && (
              <potion.button onClick={() => removeChosenFile(file)}>
                <FontAwesomeIcon
                  boxSize="6"
                  color="pink.500"
                  _hover={{ color: 'pink.600' }}
                  _active={{ color: 'pink.700' }}
                  icon={faTrash}
                />
              </potion.button>
            )}
          </HStack>
          <Text mt="2" color="red.500">
            {errorMessage}
          </Text>
        </>
      )}
      {isSuccessful || isUploading || isDeleting || globalUser?.account?.logo ? null : (
        <Box mt="10">
          <Button onClick={onSubmit} size="lg" isDisabled={!file}>
            {t('common.buttons.upload')}
          </Button>
        </Box>
      )}
    </>
  );
};
