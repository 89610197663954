import { useLocalStorage } from '.';
import { RatePlan } from '@companydotcom/types';

interface CartStorageInterface {
  selectedPlan?: RatePlan;
  storedPayment?: {
    cardHolderName?: string;
    cardExpiry?: string;
    cardNumber?: string;
    cardType?: string;
  };
}

export const useLocalCartStorage = () => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_localShoppingCartIgnored, setLocalShoppingCart] = useLocalStorage<CartStorageInterface>({
    key: 'local-cart',
    defaultValue: {
      selectedPlan: {} as RatePlan,
      storedPayment: undefined,
    },
  });

  const addPlanToLocalCart = (plan: RatePlan | undefined) => {
    const parsed = JSON.parse(
      window.localStorage.getItem('local-cart') as string,
    ) as CartStorageInterface;

    setLocalShoppingCart({
      ...parsed,
      selectedPlan: plan,
    });
  };

  const getLocalCart = () => {
    const parsed = JSON.parse(
      window.localStorage.getItem('local-cart') as string,
    ) as CartStorageInterface;

    if (!parsed) {
      return null;
    }

    return parsed;
  };

  const clearLocalCart = () => {
    setLocalShoppingCart({
      selectedPlan: {} as RatePlan,
      storedPayment: undefined,
    });
  };

  const storePaymentInfo = (paymentInfo: any) => {
    const parsed = JSON.parse(
      window.localStorage.getItem('local-cart') as string,
    ) as CartStorageInterface;

    setLocalShoppingCart({ ...parsed, storedPayment: paymentInfo });
  };

  return {
    addPlanToLocalCart,
    getLocalCart,
    clearLocalCart,
    setLocalShoppingCart,
    storePaymentInfo,
  };
};
