import React, { useEffect, useState } from 'react';
import { Icon, IconProps, Stack, useStyleConfig } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfStroke } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faRegStar } from '@fortawesome/pro-regular-svg-icons';
// import _round from 'lodash/round';

export interface RatingProps {
  /**
   * The number of icons to display
   * @default 5
   */
  scale: number;
  /**
   * The color of the icon
   */
  iconColor?: string;
  /**
   * The current rating
   */
  rating?: number;
}

export const Rating: React.FC<RatingProps> = props => {
  const { scale = 5, rating = 0 } = props;
  const [currentRating, setCurrentRating] = useState(0);
  const styles = useStyleConfig('Rating', props);

  useEffect(() => {
    setCurrentRating(rating);
  }, [rating]);

  const StarIcon = (props: IconProps) => {
    // @ts-ignore
    return <Icon as={FontAwesomeIcon} icon={faStar} {...props} />;
  };

  const OutlineStarIcon = (props: IconProps) => {
    // @ts-ignore
    return <Icon as={FontAwesomeIcon} icon={faRegStar} {...props} />;
  };

  const HalfStarIcon = (props: IconProps) => {
    // @ts-ignore
    return <Icon as={FontAwesomeIcon} icon={faStarHalfStroke} {...props} />;
  };

  const RatingButton = ({ idx, currentRating, ...rest }: any) => {
    return (
      <>
        {idx < currentRating && idx + 1 > currentRating ? (
          <HalfStarIcon {...rest} />
        ) : idx < currentRating ? (
          <StarIcon {...rest} />
        ) : (
          <OutlineStarIcon {...rest} />
        )}
      </>
    );
  };

  return (
    <Stack isInline spacing={1}>
      {[...Array(scale)].map((_, idx) => (
        <RatingButton
          key={idx}
          idx={idx}
          currentRating={currentRating}
          // wholeStar={idx <= _round(currentRating, 1)}
          boxSize={4}
          {...styles}
        />
      ))}
      <input name="rating" type="hidden" value={currentRating} />
    </Stack>
  );
};
