import React from 'react';
import {
  StatNumber,
  Stat,
  VStack,
  Center,
  Text,
  StatLabel,
  HStack,
  StatArrow,
} from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { useEventData } from '@companydotcom/ui';
import type { TileComponentProps } from '../../types';

export interface TileWeeklyReviewProps extends TileComponentProps {}

export const TileWeeklyReview: React.FC<TileWeeklyReviewProps> = props => {
  const { eventData, componentTheme } = props;
  const { data } = useEventData<any>(eventData);

  const ratingInfoData = {
    change:
      data?.counts &&
      Math.round(
        ((data?.counts?.lastWeek - data?.counts?.lastTwoWeeks) / data?.counts?.lastTwoWeeks) * 100,
      ),
  };

  return (
    <Center className="tile__tileWeeklyReview" mb="auto" sx={componentTheme}>
      <Stat mr={8}>
        <StatNumber fontSize="5xl" sx={{ textAlign: 'center' }}>
          {!data ? 0 : data?.counts?.lastWeek}
        </StatNumber>
        <StatLabel fontSize="md" maxWidth={100} textAlign="center">
          New Reviews This Week
        </StatLabel>
      </Stat>
      <TileRatingInfo
        data={
          Number.isNaN(ratingInfoData.change) || !ratingInfoData.change
            ? { change: 0 }
            : ratingInfoData
        }
      />
    </Center>
  );
};

const TileRatingInfo = ({ data }: any) => {
  const { t } = useTranslation();

  return (
    <VStack className="tile_tileRatingInfo">
      <HStack>
        <StatArrow type={data?.change > 0 ? 'increase' : 'decrease'} />
        <Text textStyle="lg">{data?.change < 0 ? `${data?.change}%` : `+${data?.change}%`}</Text>
      </HStack>
      <Text textStyle="sm">{t('components.rating.fromLastWeek')}</Text>
    </VStack>
  );
};
