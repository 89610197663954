import React from 'react';
import { Center, Flex, Box, useMultiStyleConfig } from '@companydotcom/potion';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { TileSpinner } from '../tile-spinner';
import { usePrefetch } from '../../../../services/acg/acg-api';
import { useGetAcgQueryArgs, useGetMembershipsV2Query } from '../../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  TileAcgMembershipEnrollAutoRenewStep,
  TileAcgMembershipPurchaseStep,
  TileAcgMembershipRenewStep,
  TileAcgMembershipViewInvoiceStep,
  TileAcgMembershipManageAutoRenewStep,
} from './components';
import type { TileComponentProps } from '../../types';
import { getFriendlyACGDate } from '../../../acg';

export interface TileAcgMembershipStatusProps extends TileComponentProps {}

export const TileAcgMembershipStatus: React.FC<TileAcgMembershipStatusProps> = ({
  stateCurrent,
}) => {
  const args = useGetAcgQueryArgs();
  const { data: memberships, isLoading } = useGetMembershipsV2Query(
    stateCurrent?.stateName
      ? {
          stateCurrent: stateCurrent?.stateName,
          ...args,
        }
      : skipToken,
  );
  const styles = useMultiStyleConfig('TileSubheader', {});
  const { data: globalUser } = useGetGlobalUserQuery();
  const prefetchSavedPayments = usePrefetch('getSavedPaymentMethods');
  const handlePrefetchingSavedPayments = () => {
    if (globalUser) {
      prefetchSavedPayments({ user: globalUser });
    }
  };

  const children = memberships?.memberships?.map(membership => {
    const { status } = memberships;
    const newProps = {
      ...membership,
      expireDate: getFriendlyACGDate(membership?.expireDate || ''),
      renewDate: getFriendlyACGDate(membership?.renewDate || ''),
      chapterName: membership?.chapterName || '',
    };

    if (memberships?.status === 'Pending') {
      return (
        <TileAcgMembershipViewInvoiceStep
          {...newProps}
          invoiceRecordKey={membership?.invoiceRecordKey}
        />
      );
    }

    if (
      !membership?.autoRenew &&
      (status === 'Active' ||
        status === 'ACG Global Staff' ||
        status === 'Chapter Executive' ||
        status === 'Chapter Staff') &&
      Object.keys(membership?.openInvoices || [])?.length
    ) {
      return <TileAcgMembershipRenewStep {...newProps} />;
    }

    if (
      (!membership?.autoRenew &&
        status !== 'Active' &&
        status !== 'ACG Global Staff' &&
        status !== 'Chapter Executive' &&
        status !== 'Chapter Staff') ||
      (!membership?.autoRenew && Object.keys(membership?.openInvoices || []).length)
    ) {
      return <TileAcgMembershipPurchaseStep {...newProps} />;
    }

    if (membership?.autoRenew) {
      return (
        <TileAcgMembershipManageAutoRenewStep
          {...newProps}
          handlePrefetchingSavedPayments={handlePrefetchingSavedPayments}
        />
      );
    }

    return (
      <TileAcgMembershipEnrollAutoRenewStep
        {...newProps}
        handlePrefetchingSavedPayments={handlePrefetchingSavedPayments}
      />
    );
  });

  return !isLoading &&
    memberships &&
    memberships?.totalMembershipsFound &&
    memberships?.totalMembershipsFound > 0 &&
    children &&
    children?.length > 0 ? (
    <Center height="full">
      <Flex flexDirection="column" width="full" height="100%" padding={0} alignItems="center">
        <Box className="tile__subheaderDivider" sx={{ ...styles.divider }} />
        {React.cloneElement(children?.[0])}
      </Flex>
    </Center>
  ) : (
    <TileSpinner />
  );
};
