// Absolute
import React, { useEffect, useState, useCallback } from 'react';
import { useSource } from '@companydotcom/providers';
import { AppSpinner } from '@companydotcom/ui';
import { Mid } from '@companydotcom/types';
import { uniq, isEmpty, filter } from 'lodash';
import { Outlet, useNavigate } from 'react-router-dom';

// Relative
import { useAuth } from '../../providers';
import { DataCollectorController } from '../../features/data-collector';
import {
  useChangeEnrollmentIdMutation,
  useLazyFetchMidListQuery,
} from '../../services/user/user-api';
import { useStoreRhythmTokenAndContactIdV2Mutation } from '../../services/acg/acg-api-v2';

/**
 *
 * @returns
      <>
        <DataCollectorController />
        <Outlet />
      </> |
      <AppSpinner
        className="fetching_mid__spinner"
        containerStyles={{
          minHeight: '100vh',
          flexGrow: 1,
        }}
      />
  * @description This component handles all data fetching and
  * conditional logic that needs to happen BEFORE Dashboard renders.
 */
export const DashboardPreRenderer = () => {
  const auth = useAuth();
  const source = useSource();
  const [storeRhythmTokenAndContactId, { data, isLoading }] =
    useStoreRhythmTokenAndContactIdV2Mutation();
  const navigate = useNavigate();
  const [fetchMidList] = useLazyFetchMidListQuery();
  const [changeEnrollmentIdForUser] = useChangeEnrollmentIdMutation();
  const [fetchingMid, setFetchingMid] = useState(false);
  const [hasBeenFetched, setHasBeenFetched] = useState(false);
  const [hasRhythmTokenAndIdBeenStored, setHasRhythmTokenAndIdBeenStored] = useState(false);

  const fetchMidForGivenEmail = useCallback(async () => {
    try {
      if (!hasBeenFetched) {
        setFetchingMid(true);

        const midBodyString = await fetchMidList({
          email: auth?.user?.email as string,
          source: source?.sourceId,
        });

        const midBody = JSON.parse(midBodyString.data);
        const midList = midBody as Mid[];
        const allowedStatus = [
          'PASS - WAITING FOR USER ACCOUNT CREATION',
          'COMPLETE - MID AUTO-SELECTED',
        ];
        const availableSource: string[] = [];
        const eligibleMids = midList?.filter(unEnrolleduser => {
          availableSource.push(unEnrolleduser?.source);
          return allowedStatus.includes(unEnrolleduser?.status);
        });
        const completeStatusUser = filter(
          eligibleMids,
          val => val.status === 'COMPLETE - MID AUTO-SELECTED',
        );

        // If a user is trying to log in first time
        // and has more than one account with different mids, show this screen.
        if (eligibleMids && eligibleMids.length > 0 && !isEmpty(completeStatusUser)) {
          // If user has only one auto selected mid then
          // complete the status for that and redirect to dashboard, else show multi mid selection screen.
          if (eligibleMids.length === 1) {
            // as selected and processed enrolment ids are same, sending old and new enrolment id alike.
            await changeEnrollmentIdForUser({
              enrollmentId: completeStatusUser[0].enrolmentId,
              oldEnrollmentId: completeStatusUser[0].enrolmentId,
            });
            setHasBeenFetched(true);
            setFetchingMid(false);
          } else {
            setFetchingMid(false);
            setHasBeenFetched(true);
            navigate('/mid-list', {
              state: {
                midList: eligibleMids,
                availableSource: uniq(availableSource),
                selectedMid: completeStatusUser?.[0],
                completeStatusMid: completeStatusUser?.[0],
              },
            });
          }
        }
        setHasBeenFetched(true);
        setFetchingMid(false);
      }
    } catch (err) {
      setHasBeenFetched(true);
      setFetchingMid(false);
      console.log('Error in fetching mid for given email: ', err);
    }
  }, [
    auth?.user?.email,
    changeEnrollmentIdForUser,
    fetchMidList,
    hasBeenFetched,
    navigate,
    source?.sourceId,
  ]);

  useEffect(() => {
    fetchMidForGivenEmail();
  }, [fetchMidForGivenEmail]);

  useEffect(() => {
    if (source.sourceId === 'acg' && auth?.user?.token) {
      const invoke = async () => {
        await storeRhythmTokenAndContactId({
          userId: auth?.user?.userId,
          accessToken: auth?.user?.token,
          contactId: auth?.user?.['http://rhythmsoftware.com/contact_id'],
        });
      };

      invoke();
    }
  }, [auth?.user, source.sourceId, storeRhythmTokenAndContactId]);

  // We must store this evaluation in local state,
  // because storeRhythmTokenAndContactId causes "globalUser" to re-render,
  // which causes a small down-line memory leak.
  // If we evaluate the "result" in-line on the render conditional,
  // the user will see a result of the memory leak, in the form of a quick re-render.
  // In order to fix this broader issue as a whole, we need to completely re-architect the "SnsCachedAwaitableFacade" pattern
  // so that it does not depend on the "globalUser" object as input, which is also almost always mutates the "globalUser" object as an output effect.
  useEffect(() => {
    if (!isLoading && data?.status === 'pass') {
      setHasRhythmTokenAndIdBeenStored(true);
    }
  }, [data?.status, isLoading]);

  if (
    (source?.sourceId === 'acg' && hasRhythmTokenAndIdBeenStored) ||
    (source?.sourceId !== 'acg' && ((hasBeenFetched && !fetchingMid) || auth?.user?.userId))
  ) {
    return (
      <>
        <DataCollectorController />
        <Outlet />
      </>
    );
  }

  return (
    <AppSpinner
      className="fetching_mid__spinner"
      containerStyles={{
        minHeight: '100vh',
        flexGrow: 1,
      }}
    />
  );
};
