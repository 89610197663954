import { Box, Button, Flex, UseTabStepperReturn } from '@companydotcom/potion';
import { isEmpty } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEventPassed } from '@companydotcom/helpers';
import type { AcgPurchasedEvent, Maybe } from '@companydotcom/types';
import { selectActiveEvent } from '../my-events-slice';
import { useAppSelector } from '../../../../hooks';
import { MyEventsEditGuestListForm } from '../components/my-events-edit-guest-list-form';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  useGetEventForRegistrationQuery,
  useGetRegisteredEventQuery,
} from '../../../../services/acg/acg-api';
import { EventForRegistration, RegisteredEvent } from '../../../../services/acg/acg.types';
import { MyEventsViewGuestList } from '../components/my-events-view-guest-list';
import {
  useGetAcgUserProfileV2Query,
  useGetAcgQueryArgs,
  useGetMembershipsV2Query,
} from '../../../../services/acg/acg-api-v2';

export const MyEventsEditGuestList = ({
  goToStep,
  previousStep,
}: Partial<Pick<UseTabStepperReturn, 'goToStep' | 'previousStep'>>) => {
  const activeEvent = useAppSelector(selectActiveEvent);
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();

  const { data: acgUserProfile } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const { data: membershipData } = useGetMembershipsV2Query({
    ...args,
  });

  const {
    data: registeredEvent,
    isLoading,
    isError,
    isSuccess,
  } = useGetRegisteredEventQuery(
    activeEvent?.id && globalUser
      ? {
          user: globalUser,
          registrationId: activeEvent?.id,
        }
      : skipToken,
  );

  const { data: eventForRegistration, isLoading: isAcgEventLoading } =
    useGetEventForRegistrationQuery(
      registeredEvent?.eventId && globalUser
        ? { user: globalUser, eventId: registeredEvent?.eventId }
        : skipToken,
    );

  function isEventFull(e: EventForRegistration) {
    if (
      e?.eventCapacityEnabled &&
      (e?.totalRegistered >= e?.eventCapacity || e?.isEventClosed) &&
      !e?.hasWaitList
    ) {
      return true;
    }

    return false;
  }

  function shouldHideAddGuest(
    a?: Maybe<AcgPurchasedEvent>,
    e?: EventForRegistration,
    u?: boolean,
    d?: RegisteredEvent,
  ) {
    if (
      (a?.eventStartDate && isEventPassed(a?.eventStartDate)) ||
      (!u && e?.isEventMemberOnly) ||
      (e && isEventFull(e)) ||
      e?.registrantAndFees?.some(f => isEmpty(f?.guests)) ||
      d?.registrantType?.toLowerCase()?.includes('guest')
    ) {
      return true;
    }

    return false;
  }

  return (
    <>
      {registeredEvent?.guests && !isEmpty(registeredEvent?.guests) ? (
        <Box>
          {!shouldHideAddGuest(
            activeEvent,
            eventForRegistration,
            membershipData?.status?.toLowerCase() === 'active',
            registeredEvent,
          ) && (
            <Flex width="full" flexDirection="column" justifyContent="center" alignItems="center">
              <Button
                className="myEventsEditGuestList__addGuestButton"
                data-test="myEventsEditGuestList__addGuestButton"
                isDisabled={isLoading}
                isLoading={isAcgEventLoading}
                onClick={() => goToStep?.('my-events-add-guest')}
                size="sm"
                variant="ghost"
                maxW="196px"
              >
                Add Guest
              </Button>
            </Flex>
          )}
        </Box>
      ) : (
        <Box>
          {activeEvent?.eventEndDate && isEventPassed(activeEvent?.eventEndDate) ? (
            <MyEventsViewGuestList
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              registrantName={registeredEvent?.registrantName}
              individualType={acgUserProfile?.individualType}
              organizationName={registeredEvent?.organizationName}
              badgeName={registeredEvent?.badgeName}
              title={acgUserProfile?.title!}
              qAndA={registeredEvent?.qAndA}
              registrantType={registeredEvent?.registrantType}
              invoiceDetails={registeredEvent?.invoiceDetails}
            />
          ) : (
            <MyEventsEditGuestListForm
              isLoading={isLoading || isAcgEventLoading}
              isSuccess={isSuccess}
              isError={isError}
              previousStep={previousStep}
              goToStep={goToStep}
              variant="single"
              shouldShowGuestButton={
                !shouldHideAddGuest(
                  activeEvent,
                  eventForRegistration,
                  membershipData?.status?.toLowerCase() === 'active',
                  registeredEvent,
                )
              }
              isGuest
              registrantType={registeredEvent?.registrantType}
              registrantName={registeredEvent?.registrantName}
              registrationId={registeredEvent?.registrationId}
              individualType={acgUserProfile?.individualType}
              invoiceDetails={registeredEvent?.invoiceDetails}
              organizationName={registeredEvent?.organizationName}
              title={registeredEvent?.jobTitle}
              badgeName={registeredEvent?.badgeName}
              qAndA={registeredEvent?.qAndA}
              customQuestions={
                eventForRegistration?.registrantAndFees?.find(
                  fee => fee.registrantType?.registrantTypeId === registeredEvent?.registrantTypeId,
                )?.customQuestions
              }
            />
          )}
        </Box>
      )}
    </>
  );
};
