import { theme } from '@companydotcom/potion';
import textStyles from './text-styles';
import components from './components';
import layerStyles from './layer-styles';
import extensions from './components/extensions';
export const GlobalTheme = {
    fonts: {
        heading: 'Heebo, -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;',
        body: 'Heebo, -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;',
    },
    components: Object.assign(Object.assign({}, components), extensions),
    textStyles,
    layerStyles,
    // Turns off blue outline for all components
    shadows: {
        outline: 'none',
    },
    colors: {
        pricing: theme.colors.green[500],
        success: theme.colors.green[500],
        error: theme.colors.red[500],
        warning: theme.colors.yellow[500],
        info: theme.colors.blue[500],
    },
};
