import React from 'react';
import { Box, Heading, Center } from '@companydotcom/potion';

export const NoResults = () => (
  <Center maxW="full" py={10}>
    <Box borderWidth="1px" borderStyle="solid" padding={10}>
      <Heading fontWeight="semibold" size="hs-xl" textAlign="center">
        There are no members related to this search. Please try your search again.
      </Heading>
    </Box>
  </Center>
);
