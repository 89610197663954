import * as React from 'react';
import { FormControlOptions, useFormControl } from '../form-control';
import {
  potion,
  forwardRef,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
  HTMLPotionProps,
} from '../../system';
import { cx } from '../../utils';

interface InputOptions {
  /**
   * The border color when the input is focused. Use color keys in `theme?.colors?`
   * @example
   * focusBorderColor = "blue.500"
   */
  focusBorderColor?: string;
  /**
   * The border color when the input is invalid. Use color keys in `theme?.colors?`
   * @example
   * errorBorderColor = "red.500"
   */
  errorBorderColor?: string;
}

type Omitted = 'disabled' | 'required' | 'readOnly' | 'size';

export interface InputProps
  extends Omit<HTMLPotionProps<'input'>, Omitted>,
    InputOptions,
    ThemingProps<'Input'>,
    FormControlOptions {}

/**
 * Input
 *
 * Element that allows users enter single valued data.
 */
export const Input = forwardRef<InputProps, 'input'>((props, ref) => {
  const styles = useMultiStyleConfig('Input', props);
  const ownProps = omitThemingProps(props);
  const input = useFormControl<HTMLInputElement>(ownProps);
  const _className = cx('potion-input', props.className);

  return <potion.input {...input} __css={styles.field} ref={ref} className={_className} />;
});
