import React from 'react';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { useBoolean, Td, Text, Box, Flex, Button } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { DarkWebTag } from './dark-web-tag';
import { TagMappingInterface } from '../types';
import { tagMappings } from '../utils/dark-web-scan-helpers';
import { DarkScanResult, Tag } from '../../../services/dark-web-scan/dark-web-scan.types';

interface LeakAccordionProps {
  leak: DarkScanResult;
}

export const LeakAccordion: React.FC<LeakAccordionProps> = ({ leak }) => {
  const [isOpen, setIsOpen] = useBoolean();

  const { t } = useTranslation();

  const parseDate = (date: string) => {
    const numeralDate = date.split('T')[0];
    const slashDate = numeralDate.replaceAll('-', '/');
    return slashDate.split('/').reverse().join('/');
  };

  const createTags = (detectedTags: Tag[]) => {
    const uniqueTags = detectedTags.filter((v: any, i) => detectedTags.indexOf(v) === i);

    const foundTags: any = [];

    uniqueTags.forEach(uniqueTag => {
      Object.values(tagMappings).forEach((tagMapping: TagMappingInterface) => {
        tagMapping.keys.forEach((key: string) => {
          if (uniqueTag === key) {
            foundTags.push({ ...tagMapping, text: uniqueTag });
          }
        });
      });
    }, []);
    return foundTags;
  };

  return (
    <>
      <>
        <Td verticalAlign="baseline">
          {leak.publishedDate && <Text textStyle="md">{parseDate(leak.publishedDate)}</Text>}
        </Td>
        <Td verticalAlign="baseline">
          <Text textStyle="lg" mb={4}>
            {leak.leakName}
          </Text>
          <Box whiteSpace="normal">
            <Text textStyle="md">
              <Text as="span" fontWeight="bolder">
                {t('containers.darkWebScan.detailedResults.description')}{' '}
              </Text>
              {leak.description}
            </Text>
          </Box>
          <Text mt={7} mb={8} fontWeight="bolder">
            {t('containers.darkWebScan.detailedResults.dataTypes')}
          </Text>
          <Flex flexDirection="row" alignItems="center">
            {leak?.tags &&
              createTags(leak.tags).map((tag: any) => (
                <Box key={tag.text} mr={2} mt={2}>
                  <DarkWebTag
                    key={tag.text}
                    text={tag.text}
                    // size="small"
                    color={tag.color}
                    icon={tag.icon}
                  />
                </Box>
              ))}
          </Flex>
          {isOpen && (
            <Flex
              borderTopWidth="1px"
              borderTopStyle="solid"
              borderColor="gray.300"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={6}
            >
              <Text fontWeight="medium" mb={4} whiteSpace="normal">
                {t('containers.darkWebScan.detailedResults.leakDataNotice')}
              </Text>
              <Flex flexDirection="row" flexWrap="wrap">
                {leak?.compromisedValues?.map((value: string, index: number) => (
                  <Box key={index} mr={2} mb={2}>
                    <DarkWebTag text={value.toLowerCase()} textOnly bg="gray.50" />
                  </Box>
                ))}
              </Flex>
            </Flex>
          )}
        </Td>
        <Td verticalAlign="baseline">
          <Button
            variant="ghost"
            fontWeight="medium"
            rightIcon={<FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleUp} />}
            onClick={() => setIsOpen.toggle()}
          >
            {t('containers.darkWebScan.detailedResults.showDetails')}
          </Button>
        </Td>
      </>
    </>
  );
};
