import React from 'react';
import { useTranslation } from 'react-i18next';
import { Center, Heading, Progress } from '@companydotcom/potion';

export const DirectoryLoading = () => {
  const { t } = useTranslation();
  return (
    <Center width="full" flexDirection="column" px={4} pt={20} pb={28}>
      <Heading size="hs-xl" textAlign="center" mb={16}>
        {t('components.directory.scanningDirectories')}
      </Heading>
      <Progress width="full" maxW={680} size="lg" value={40} isIndeterminate />
    </Center>
  );
};
