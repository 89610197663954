import { FocusEventHandler } from 'react';
import { ariaAttr, callAllHandlers } from '../../utils';
import { FormControlOptions, useFormControlContext } from './form-control';

export interface UseFormControlProps<T extends HTMLElement> extends FormControlOptions {
  id?: string;
  onFocus?: FocusEventHandler<T>;
  onBlur?: FocusEventHandler<T>;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
}

/**
 * React hook that provides the props that should be spread on to
 * input fields (`input`, `select`, `textarea`, etc.).
 *
 * It provides a convenient way to control a form fields, validation
 * and helper text.
 */
export function useFormControl<T extends HTMLElement>(props: UseFormControlProps<T>) {
  const { isDisabled, isInvalid, isReadOnly, isRequired, ...rest } = useFormControlProps(props);

  return {
    ...rest,
    disabled: isDisabled,
    readOnly: isReadOnly,
    required: isRequired,
    'aria-invalid': ariaAttr(isInvalid),
    'aria-required': ariaAttr(isRequired),
    'aria-readonly': ariaAttr(isReadOnly),
  };
}

export function useFormControlProps<T extends HTMLElement>(props: UseFormControlProps<T>) {
  const field = useFormControlContext();

  const {
    id,
    disabled,
    readOnly,
    required,
    isRequired,
    isInvalid,
    isReadOnly,
    isDisabled,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const labelIds: string[] = [];

  // Error message must be described first in all scenarios.
  if (field?.hasFeedbackText && field?.isInvalid) {
    labelIds.push(field.feedbackId);
  }

  if (field?.hasHelpText) {
    labelIds.push(field.helpTextId);
  }

  return {
    ...rest,
    'aria-describedby': labelIds.join(' ') || undefined,
    id: id ?? field?.id,
    isDisabled: disabled ?? isDisabled ?? field?.isDisabled,
    isReadOnly: readOnly ?? isReadOnly ?? field?.isReadOnly,
    isRequired: required ?? isRequired ?? field?.isRequired,
    isInvalid: isInvalid ?? field?.isInvalid,
    onFocus: callAllHandlers(field?.onFocus, onFocus),
    onBlur: callAllHandlers(field?.onBlur, onBlur),
  };
}
