import { useState, useEffect } from 'react';
import { createContext } from '@companydotcom/potion';
import { useSource } from '@companydotcom/providers';

// Facecake wants specific background images on specific pages.

export const useGetFacecakeBackgroundImages = (
  sourceID: string,
  pathname: string,
  facecakeStep?: string,
) => {
  const images = [
    `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/icommerce/images/FC_eyewear_bg_01.png`,
    `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/icommerce/images/FC_eyewear_bg_02.png`,
    `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/icommerce/images/FC_eyewear_bg_03.png`,
  ];

  useImagePreloader(images, sourceID);

  if (sourceID === 'icommerce') {
    const getImage = () => {
      if (
        pathname === '/verifyphone' ||
        facecakeStep === 'terms-and-conditions' ||
        pathname === '/facecake/enterprise-contact-us' ||
        pathname === '/callback'
      ) {
        return images[1];
      }

      if (
        pathname === '/verifycode' ||
        pathname === '/verify' ||
        facecakeStep === 'plan-selection'
      ) {
        return images[2];
      }

      return images[0];
    };

    return {
      backgroundImage: [null, null, null, null, `url(${getImage()})`],
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundAttachment: 'fixed',
    };
  }

  return {};
};

export const useGetFacecakeColors = () => {
  const source = useSource();

  if (source?.sourceId === 'icommerce') {
    return {
      color: 'white',
      emptyColor: 'transparent',
      descriptionStyles: {
        color: 'white !important',
      },
    };
  }

  return {};
};

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export default function useImagePreloader(imageList: string[], sourceId: string) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList: Promise<any>[] = [];
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    if (sourceId === 'icommerce') {
      effect();
    }

    return () => {
      isCancelled = true;
    };
  }, [imageList, sourceId]);

  return { imagesPreloaded };
}

interface CProps {
  facecakeStep: string;
  setFacecakeStep: (step: string) => void;
}

const [FacecakeLocationProvider, useFacecakeLocation] = createContext<CProps>({
  strict: false,
  name: 'FacecakeContext',
  errorMessage:
    'useFacecakeLocation: `useFacecakeLocation` is undefined. Seems you forgot to wrap the app in `<GroupsProvider />` ',
});
export { FacecakeLocationProvider, useFacecakeLocation };
