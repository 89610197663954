import gql from 'graphql-tag';

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts {
    getAllProducts {
      productId
      description
      isPackage
      isLeadGen
      name
      slug
      purchaseRelUrl
      ssoUrl
      packageProducts {
        productId
      }
      ratePlans {
        id
        eligibleProducts
        name
        description
        features
        includedInMembership
        zuoraProductRatePlanId
        price
      }
    }
  }
`;

export const GET_USER_PRODUCT = gql`
  query GetUserProducts($userId: String!, $productId: String, $locale: String) {
    getUserProducts(userId: $userId, productId: $productId, locale: $locale) {
      accountId
      zuoraPaymentMethodId
      zuoraAccountId
      zuoraSubscriptionId
      zuoraSubscriptionNumber
      monthlyPrice
      products {
        productId
        description
        isPackage
        isLeadGen
        isActive
        name
        slug
        ratePlans {
          id
          description
          includedInMembership
          isActive
          name
          price
          features
          ratePlanId
          ratePlanChargeId
          id
          tiers {
            description
            qty
            price
            isDisabled
            isActive
            isEnterprise
          }
        }
        paywallContentOptions {
          key
          value
        }
      }
    }
  }
`;

export const GET_USER_PRODUCTS = gql`
  query GetUserProducts($userId: String!, $locale: String) {
    getUserProducts(userId: $userId, locale: $locale) {
      accountId
      zuoraPaymentMethodId
      zuoraAccountId
      zuoraSubscriptionId
      zuoraSubscriptionNumber
      monthlyPrice
      products {
        productId
        description
        isPackage
        isLeadGen
        isActive
        name
        slug
        ratePlans {
          id
          description
          includedInMembership
          isActive
          name
          price
          features
          ratePlanId
          ratePlanChargeId
          id
          tiers {
            description
            qty
            price
            isDisabled
            isActive
            isEnterprise
          }
        }
        paywallContentOptions {
          key
          value
        }
      }
    }
  }
`;

export const GET_LIVE_AGENT_AVAILABILITY = gql`
  query GetLiveAgentAvailability {
    getLiveAgentAvailability
  }
`;

export const GET_PRODUCT = gql`
  query GetProduct($productId: String, $locale: String) {
    getProduct(productId: $productId, locale: $locale) {
      productId
      description
      isPackage
      isLeadGen
      isActive
      name
      slug
      purchaseRelUrl
      ssoUrl
      dateCreatedNumber
      dateUpdatedNumber
      aLaCarte
      ratePlans {
        id
        description
        includedInMembership
        isActive
        name
        subheading
        detail
        detailList {
          name
          values
        }
        price
        features
        eligibleProducts
        zuoraProductRatePlanId
        zuoraProductRatePlanChargeId
        termType
        term
        partnerRatePlanId
        enterpriseQty
        tiers {
          startingUnit
          endingUnit
          price
          partnerTierId
          chargeModel
        }
      }
    }
  }
`;

export const GET_ELIGIBLE_CHILD_PRODUCTS = gql`
  query GetEligibleChildProducts($userId: String!, $sourceId: String!, $locale: String) {
    getEligibleChildProducts(userId: $userId, sourceId: $sourceId, locale: $locale) {
      products {
        productId
        description
        isActive
        name
        slug
        ratePlans {
          id
          description
          includedInMembership
          isActive
          name
          price
          features
          ratePlanId
          ratePlanChargeId
          tiers {
            description
            qty
            price
            isDisabled
            isActive
            isEnterprise
          }
        }
      }
    }
  }
`;
