import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import validator from 'validator';
import { getDirtyValues } from '@companydotcom/helpers';
import {
  Center,
  useBreakpointValue,
  VStack,
  Heading,
  SimpleGrid,
  Spacer,
  ButtonGroup,
  Box,
  Button,
  HStack,
  ButtonProps,
  Badge,
  BadgeProps,
  potion,
  Checkbox,
} from '@companydotcom/potion';
import {
  PageDivider,
  InputField,
  SelectCountryField,
  SelectRegionField,
  FontAwesomeIcon,
  useGetCurrentlySelectedCountry,
  PhoneNumberInputField,
} from '@companydotcom/ui';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import isEmpty from 'lodash/isEmpty';
import { faCheck, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useSource } from '@companydotcom/providers';
import { parsePhoneNumber, isPossiblePhoneNumber } from 'libphonenumber-js';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { formatRhythmPhoneToUS, formatRhythmPhoneNumber } from '../../shared';
import yup from '../../../../lib/yup';
import { determineLogoutDestination } from '../../../../providers/auth-provider/auth-context-helpers';
import { useAuth } from '../../../../providers';
import {
  useGetAcgUserProfileV2Query,
  useGetAcgQueryArgs,
  useUpdateAcgUserV2Mutation,
} from '../../../../services/acg/acg-api-v2';
import { removeNullFromObject } from '../../../../utils/helpers/object';

export interface AddNewContactInfoProps {
  onClose: () => void;
  isMobile: boolean;
}

function setHttp(link: string) {
  if (link.search(/^http[s]?:\/\//) == -1) {
    link = `http://${link}`;
  }
  return link;
}

let isEmailUpdate = false;

const getContactFormSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .test('is-valid-email', 'common.inputs.email.error', val => validator.isEmail(val ?? ''))
    .required('common.misc.required'),
  emailAddressSecondary: yup.string(),
  website: yup
    .string()
    .test('is-valid-url', 'common.inputs.businessWebsite.error', val =>
      val && val !== '' ? validator.isURL(val ?? '') : true,
    ),
  mobilePhone: yup.string().when(
    ['$isMobilePhoneRequired', '$mobileCountryCode'],
    // @ts-ignore
    (isMobilePhoneRequired, mobileCountryCode, schema) => {
      if (isMobilePhoneRequired) {
        return schema.phone(mobileCountryCode).required('common.misc.required').nullable();
      }
      return schema;
    },
  ),
  workPhone: yup.string().when(
    ['$isWorkPhoneRequired', '$workCountryCode'],
    // @ts-ignore
    (isWorkPhoneRequired, workCountryCode, schema) => {
      if (isWorkPhoneRequired) {
        return schema.phone(workCountryCode).required('common.misc.required').nullable();
      }
      return schema;
    },
  ),
  preferredPhoneType: yup.string().oneOf(['mobilePhone', 'workPhone']).required(),
  preferredAddressType: yup.string().oneOf(['homeAddress', 'workAddress']).required(),
  workAddress: yup.object().when('$isWorkAddressRequired', {
    is: true,
    then: yup.object().shape({
      addressLine1: yup.string().trim().required('common.misc.required'),
      addressLine2: yup.string(),
      addressLine3: yup.string(),
      city: yup.string().trim().required('common.misc.required'),
      state: yup.string().trim().required('common.misc.required'),
      country: yup.string().trim().required('common.misc.required'),
      postalCode: yup.string().trim().required('common.misc.required'),
    }),
  }),
  homeAddress: yup.object().when('$isHomeAddressRequired', {
    is: true,
    then: yup.object().shape({
      addressLine1: yup.string().trim().required('common.misc.required'),
      addressLine2: yup.string(),
      addressLine3: yup.string(),
      city: yup.string().trim().required('common.misc.required'),
      state: yup.string().trim().required('common.misc.required'),
      country: yup.string().trim().required('common.misc.required'),
      postalCode: yup.string().trim().required('common.misc.required'),
    }),
  }),
});

export const AddNewContactInfo = ({ onClose, isMobile }: AddNewContactInfoProps) => {
  const { t } = useTranslation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const authUser = useAuth();
  const source = useSource();
  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-2xl' });
  const [updateAcgUser] = useUpdateAcgUserV2Mutation();
  const updateUserArgs = useGetAcgQueryArgs(['email', 'source']);
  const args = useGetAcgQueryArgs();
  const { data: acgUserProfileData } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const { country: mobileCountry, onCountryChange: mobilePhoneHandleChange } =
    useGetCurrentlySelectedCountry(
      !acgUserProfileData?.mobilePhone ||
        acgUserProfileData?.mobilePhone?.charAt(0) === '(' ||
        !isPossiblePhoneNumber(acgUserProfileData?.mobilePhone)
        ? 'US'
        : parsePhoneNumber(acgUserProfileData?.mobilePhone).country,
    );
  const { country: workCountry, onCountryChange: workPhoneHandleChange } =
    useGetCurrentlySelectedCountry(
      !acgUserProfileData?.workPhone ||
        acgUserProfileData?.workPhone?.charAt(0) === '(' ||
        !isPossiblePhoneNumber(acgUserProfileData?.workPhone)
        ? 'US'
        : parsePhoneNumber(acgUserProfileData?.workPhone).country,
    );

  const [secondaryEmailVisible, setSecondaryEmailVisible] = useState(
    !!acgUserProfileData?.emailAddressSecondary || false,
  );
  const [workPhoneVisible, setWorkPhoneVisible] = useState(
    !!acgUserProfileData?.workPhone || false,
  );
  const [mobilePhoneVisible, setMobilePhoneVisible] = useState(
    !!acgUserProfileData?.mobilePhone || false,
  );
  const [workAddressVisible, setWorkAddressVisible] = useState(
    (typeof acgUserProfileData?.workAddress !== 'string' &&
      !!acgUserProfileData?.workAddress?.addressLine1) ||
      false,
  );
  const [homeAddressVisible, setHomeAddressVisible] = useState(
    (typeof acgUserProfileData?.homeAddress !== 'string' &&
      !!acgUserProfileData?.homeAddress?.addressLine1) ||
      false,
  );

  const [isHomeAddressRequired, setIsHomeAddressRequired] = useState(
    (typeof acgUserProfileData?.homeAddress !== 'string' &&
      !!acgUserProfileData?.homeAddress?.addressLine1) ||
      false,
  );
  const [isWorkAddressRequired, setIsWorkAddressRequired] = useState(
    (typeof acgUserProfileData?.workAddress !== 'string' &&
      !!acgUserProfileData?.workAddress?.addressLine1) ||
      false,
  );
  const [isWorkPhoneRequired, setIsWorkPhoneRequired] = useState(
    !!acgUserProfileData?.workPhone || false,
  );
  const [isMobilePhoneRequired, setIsMobilePhoneRequired] = useState(
    !!acgUserProfileData?.mobilePhone || false,
  );

  const defaultValues: FieldValues = {
    emailAddress: acgUserProfileData?.emailAddress || '',
    emailAddressSecondary: acgUserProfileData?.emailAddressSecondary || '',
    website: acgUserProfileData?.website || '',
    preferredPhoneType: acgUserProfileData?.preferredPhoneType ?? 'workPhone',
    mobilePhone:
      (acgUserProfileData?.mobilePhone &&
        formatRhythmPhoneToUS(acgUserProfileData?.mobilePhone, mobileCountry)) ??
      '',
    mobilePhoneExtension: acgUserProfileData?.mobilePhoneExtension ?? '',
    workPhone:
      (acgUserProfileData?.workPhone &&
        formatRhythmPhoneToUS(acgUserProfileData?.workPhone, workCountry)) ??
      '',
    workPhoneExtension: acgUserProfileData?.workPhoneExtension ?? '',
    preferredAddressType: acgUserProfileData?.preferredAddressType ?? 'workAddress',
    workAddress:
      typeof acgUserProfileData?.workAddress === 'string'
        ? ''
        : {
            addressLine1: acgUserProfileData?.workAddress?.addressLine1 ?? '',
            addressLine2: acgUserProfileData?.workAddress?.addressLine2 ?? '',
            addressLine3: acgUserProfileData?.workAddress?.addressLine3 ?? '',
            city: acgUserProfileData?.workAddress?.city ?? '',
            state: acgUserProfileData?.workAddress?.state ?? '',
            country: acgUserProfileData?.workAddress?.country ?? '',
            postalCode: acgUserProfileData?.workAddress?.postalCode ?? '',
          },
    homeAddress: {
      addressLine1: acgUserProfileData?.homeAddress?.addressLine1 ?? '',
      addressLine2: acgUserProfileData?.homeAddress?.addressLine2 ?? '',
      addressLine3: acgUserProfileData?.homeAddress?.addressLine3 ?? '',
      city: acgUserProfileData?.homeAddress?.city ?? '',
      state: acgUserProfileData?.homeAddress?.state ?? '',
      country: acgUserProfileData?.homeAddress?.country ?? '',
      postalCode: acgUserProfileData?.homeAddress?.postalCode ?? '',
    },
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    resetField,
    trigger,
    formState: { errors, isSubmitting, dirtyFields, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getContactFormSchema),
    context: {
      mobileCountryCode: mobileCountry ?? 'US',
      workCountryCode: workCountry ?? 'US',
      isHomeAddressRequired,
      isWorkAddressRequired,
      isWorkPhoneRequired,
      isMobilePhoneRequired,
    },
    defaultValues,
  });

  const onSubmit = async (data: typeof defaultValues) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as Partial<typeof defaultValues>;

    if (dirtyValues?.website) {
      dirtyValues.website = setHttp(dirtyValues.website);
    }

    if (dirtyValues?.mobilePhone) {
      dirtyValues.mobilePhone = formatRhythmPhoneNumber(dirtyValues?.mobilePhone, mobileCountry);

      if (acgUserProfileData?.mobilePhoneExtension && !dirtyValues?.mobilePhoneExtension) {
        dirtyValues!.mobilePhoneExtension = acgUserProfileData?.mobilePhoneExtension;
      }
    }

    if (dirtyValues?.workPhone) {
      dirtyValues.workPhone = formatRhythmPhoneNumber(dirtyValues?.workPhone, workCountry);
      if (acgUserProfileData?.workPhoneExtension && !dirtyValues?.workPhoneExtension) {
        dirtyValues!.workPhoneExtension = acgUserProfileData?.workPhoneExtension;
      }
    }

    // If phone extensions or phone numbers have changes, we must always
    // pass up the associated phone number/extension with it even if it hasn't been edited
    // This could probably be fixed on the service to not require this
    if (dirtyFields?.mobilePhoneExtension && !dirtyValues.mobilePhone) {
      dirtyValues.mobilePhone =
        acgUserProfileData?.mobilePhone &&
        formatRhythmPhoneNumber(acgUserProfileData?.mobilePhone, mobileCountry);
    }

    if (dirtyFields?.workPhoneExtension && !dirtyValues.workPhone) {
      dirtyValues.workPhone =
        acgUserProfileData?.workPhone &&
        formatRhythmPhoneNumber(acgUserProfileData?.workPhone, workCountry);
    }

    // If any field in the workAddress is changed, the entire set of address fields must go up with it
    if (
      dirtyValues?.workAddress &&
      typeof dirtyValues?.workAddress === 'object' &&
      typeof acgUserProfileData?.workAddress === 'object'
    ) {
      dirtyValues.workAddress = { ...acgUserProfileData?.workAddress, ...dirtyValues.workAddress };
    }

    if (
      dirtyValues?.homeAddress &&
      typeof dirtyValues?.homeAddress === 'object' &&
      typeof acgUserProfileData?.homeAddress === 'object'
    ) {
      dirtyValues.homeAddress = { ...acgUserProfileData?.homeAddress, ...dirtyValues.homeAddress };
    }

    if (dirtyValues.emailAddress) {
      isEmailUpdate = true;
    }

    try {
      const payload = {
        ...dirtyValues,
        lastProfileUpdateVersion: acgUserProfileData?.lastProfileUpdateVersion ?? 1,
      };

      if (globalUser) {
        await updateAcgUser({
          ...updateUserArgs,
          payload: removeNullFromObject(payload),
        });
        onClose();

        if (isEmailUpdate && source && source.sourceId && source.sourceId === 'acg') {
          const logoutHref = determineLogoutDestination(source.sourceId);
          authUser?.logout(logoutHref);
          const newRedirectionURl = window.location.origin;
          window.location.replace(`${newRedirectionURl}/login`);
        }
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  useEffect(() => {
    if (workAddressVisible || workPhoneVisible || mobilePhoneVisible || homeAddressVisible) {
      trigger();
    }
  }, [homeAddressVisible, mobilePhoneVisible, trigger, workAddressVisible, workPhoneVisible]);

  const watchedHomeAddress = watch('homeAddress.addressLine1');
  const watchedWorkAddress = watch('workAddress.addressLine1');
  const watchedWorkPhone = watch('workPhone');
  const watchedMobilePhone = watch('mobilePhone');
  const watchedPreferredPhoneType = watch('preferredPhoneType');
  const watchedPreferredAddressType = watch('preferredAddressType');

  return (
    <Center as="form" flexDirection="column">
      <Heading textAlign={['center', 'left']} size={headingSize} mt={[6, 12]} mb={[2, 2, 12]}>
        {t('acg.myProfile.forms.addNewContactInfo.addNewContactInfo')}
      </Heading>
      {isMobile && <PageDivider width={50} mb={[4, null, 12]} />}
      <VStack w="full" maxW="796" spacing={{ base: '6', md: '8' }}>
        {/* EMAIL */}
        <VStack align="flex-start" w="full" spacing="6">
          <SimpleGrid w="full" columns={{ base: 1, sm: 2 }} gap={['8', null, '12']}>
            <VStack align="flex-start" spacing="6">
              <HStack>
                <Heading fontSize="lg">Primary Email</Heading>
              </HStack>
              <InputField
                register={register}
                name="emailAddress"
                label="Email"
                errors={errors}
                helperText={t('common.misc.required')}
              />
            </VStack>
            <VStack align="flex-start" spacing="6">
              <HStack>
                <Heading opacity={secondaryEmailVisible ? 1 : 0.5} fontSize="lg">
                  Secondary Email
                </Heading>
                {!secondaryEmailVisible && (
                  <AddNewButton
                    onClick={() => {
                      setSecondaryEmailVisible(true);
                      resetField('emailAddressSecondary', { keepError: false });
                    }}
                  />
                )}
                {secondaryEmailVisible && (
                  <DeleteButton
                    onClick={() => {
                      setSecondaryEmailVisible(false);
                      setValue('emailAddressSecondary', '', {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              </HStack>
              <Box w="full" display={secondaryEmailVisible ? 'flex' : 'none'}>
                <InputField
                  register={register}
                  name="emailAddressSecondary"
                  label="Email"
                  errors={errors}
                  helperText="&#8203;"
                />
              </Box>
            </VStack>
          </SimpleGrid>
        </VStack>
        {/* PHONE */}
        <VStack align="flex-start" w="full" spacing="6">
          <SimpleGrid w="full" columns={{ base: 1, sm: 2 }} gap={['8', null, '12']}>
            <VStack align="flex-start" spacing="6">
              <HStack>
                <Heading opacity={workPhoneVisible ? 1 : 0.5} fontSize="lg">
                  Work Phone
                </Heading>
                {!workPhoneVisible && (
                  <AddNewButton
                    onClick={() => {
                      setWorkPhoneVisible(true);

                      resetField('workPhone', { keepError: false });

                      if (defaultValues?.preferredPhoneType === 'workPhone') {
                        setValue('preferredPhoneType', 'workPhone', {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }

                      if (!dirtyFields?.workPhone || !acgUserProfileData?.workPhone) {
                        setIsWorkPhoneRequired(true);
                      } else {
                        setIsWorkPhoneRequired(false);
                      }
                    }}
                  />
                )}
                {workPhoneVisible && acgUserProfileData?.mobilePhone && watchedMobilePhone !== '' && (
                  <DeleteButton
                    onClick={() => {
                      setWorkPhoneVisible(false);
                      setIsWorkPhoneRequired(false);

                      setValue('workPhone', '', { shouldDirty: true });
                      clearErrors('workPhone');

                      if (acgUserProfileData?.preferredPhoneType === 'workPhone') {
                        setValue('preferredPhoneType', 'mobilePhone', {
                          shouldDirty: true,
                        });
                      }
                    }}
                  />
                )}
              </HStack>
              <VStack
                align="flex-start"
                spacing="4"
                w="full"
                display={workPhoneVisible ? 'flex' : 'none'}
              >
                <PhoneNumberInputField
                  name="workPhone"
                  control={control}
                  label="Phone"
                  helperText={t('common.misc.required')}
                  onCountryChange={workPhoneHandleChange}
                  errors={errors}
                  country={workCountry}
                />
                <InputField
                  register={register}
                  name="workPhoneExtension"
                  label="Extension"
                  errors={errors}
                />
                {acgUserProfileData?.preferredPhoneType === 'workPhone' && <PrimaryBadge />}
              </VStack>

              {acgUserProfileData?.preferredPhoneType === 'mobilePhone' && workPhoneVisible && (
                <Checkbox
                  isDisabled={watch('mobilePhone') === ''}
                  onChange={() =>
                    setValue(
                      'preferredPhoneType',
                      watchedPreferredPhoneType === 'workPhone' ? 'mobilePhone' : 'workPhone',
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                      },
                    )
                  }
                >
                  Make this My Primary Phone Number
                </Checkbox>
              )}
            </VStack>
            <VStack align="flex-start" spacing="6">
              <HStack>
                <Heading fontSize="lg" opacity={mobilePhoneVisible ? 1 : 0.5}>
                  Mobile Phone
                </Heading>
                {!mobilePhoneVisible && (
                  <AddNewButton
                    onClick={() => {
                      setMobilePhoneVisible(true);

                      resetField('mobilePhone');

                      if (defaultValues?.preferredPhoneType === 'mobilePhone') {
                        setValue('preferredPhoneType', 'mobilePhone', {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }

                      if (!dirtyFields?.mobilePhone || !acgUserProfileData?.mobilePhone) {
                        setIsMobilePhoneRequired(true);
                      } else {
                        setIsMobilePhoneRequired(false);
                      }
                    }}
                  />
                )}
                {mobilePhoneVisible && acgUserProfileData?.workPhone && watchedWorkPhone !== '' && (
                  <DeleteButton
                    onClick={() => {
                      setMobilePhoneVisible(false);
                      setIsMobilePhoneRequired(false);

                      setValue('mobilePhone', '', { shouldDirty: true });
                      clearErrors('mobilePhone');

                      if (acgUserProfileData?.preferredPhoneType === 'mobilePhone') {
                        setValue('preferredPhoneType', 'workPhone', { shouldDirty: true });
                      }
                    }}
                  />
                )}
              </HStack>
              <VStack
                align="flex-start"
                spacing="4"
                w="full"
                display={mobilePhoneVisible ? 'flex' : 'none'}
              >
                <PhoneNumberInputField
                  name="mobilePhone"
                  control={control}
                  country={mobileCountry}
                  onCountryChange={mobilePhoneHandleChange}
                  label="Phone"
                  helperText={t('common.misc.required')}
                  errors={errors}
                />

                <InputField
                  register={register}
                  name="mobilePhoneExtension"
                  label="Extension"
                  errors={errors}
                />
                {acgUserProfileData?.preferredPhoneType === 'mobilePhone' && <PrimaryBadge />}
              </VStack>
              {acgUserProfileData?.preferredPhoneType === 'workPhone' && mobilePhoneVisible && (
                <Checkbox
                  isDisabled={watch('workPhone') === ''}
                  onChange={() =>
                    setValue(
                      'preferredPhoneType',
                      watchedPreferredPhoneType === 'mobilePhone' ? 'workPhone' : 'mobilePhone',
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                      },
                    )
                  }
                >
                  Make this My Primary Phone Number
                </Checkbox>
              )}
            </VStack>
          </SimpleGrid>
        </VStack>
        {/* WEBSITE */}
        <VStack align="flex-start" w="full" spacing="6">
          <Heading fontSize="lg">Website</Heading>

          <SimpleGrid w="full" columns={{ base: 1, sm: 2 }} gap={['8', null, '12']}>
            <InputField
              className="acg-contactInfo-website-url"
              data-test="acg-contactInfo-website-url"
              register={register}
              name="website"
              label="URL"
              errors={errors}
            />
            <Spacer />
          </SimpleGrid>
        </VStack>
        {/* ADDRESS */}

        <VStack align="flex-start" w="full" spacing="6">
          <SimpleGrid w="full" columns={{ base: 1, sm: 2 }} gap={['8', null, '12']}>
            <VStack align="flex-start" spacing="6">
              <HStack>
                <Heading opacity={workAddressVisible ? 1 : 0.5} fontSize="lg">
                  Work Address
                </Heading>
                {!workAddressVisible && (
                  <AddNewButton
                    onClick={() => {
                      setWorkAddressVisible(true);
                      setIsWorkAddressRequired(true);

                      if (typeof defaultValues?.workAddress !== 'string') {
                        resetField('workAddress', { keepError: false });
                      }

                      if (defaultValues?.preferredAddressType === 'workAddress') {
                        setValue('preferredAddressType', 'workAddress', {
                          shouldDirty: true,
                        });
                      }

                      setValue('workAddress.country', 'US', {
                        shouldDirty: true,
                      });
                    }}
                  />
                )}
                {workAddressVisible &&
                  typeof acgUserProfileData?.homeAddress !== 'string' &&
                  acgUserProfileData?.homeAddress?.addressLine1 &&
                  isHomeAddressRequired &&
                  watchedHomeAddress !== '' && (
                    <DeleteButton
                      onClick={() => {
                        setWorkAddressVisible(false);
                        setIsWorkAddressRequired(false);

                        Object.keys(defaultValues?.workAddress)?.forEach(item =>
                          setValue(`workAddress.${item}`, '', {
                            shouldDirty: true,
                          }),
                        );

                        if (acgUserProfileData?.preferredAddressType === 'workAddress') {
                          setValue('preferredAddressType', 'homeAddress', {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  )}
              </HStack>
              <VStack
                align="flex-start"
                spacing="4"
                w="full"
                display={workAddressVisible ? 'flex' : 'none'}
              >
                <SelectCountryField
                  control={control}
                  name="workAddress.country"
                  label={t('common.inputs.country.label')}
                  helperText={t('common.misc.required')}
                  valueType="short"
                  id="workAddress"
                />
                <InputField
                  register={register}
                  name="workAddress.addressLine1"
                  label="Address"
                  errors={errors}
                  helperText={t('common.misc.required')}
                />
                <InputField
                  register={register}
                  name="workAddress.addressLine2"
                  label=""
                  errors={errors}
                />
                <InputField
                  register={register}
                  name="workAddress.city"
                  label="City"
                  errors={errors}
                  helperText={t('common.misc.required')}
                />
                <SelectRegionField
                  country={watch('workAddress.country') ?? ''}
                  control={control}
                  helperText={t('common.misc.required')}
                  name="workAddress.state"
                  label={t('common.inputs.state.label')}
                  // @ts-ignore
                  countryValueType="short"
                  id="workAddress"
                />

                <InputField
                  register={register}
                  name="workAddress.postalCode"
                  label="Postal Code"
                  errors={errors}
                  helperText={t('common.misc.required')}
                />
                {acgUserProfileData?.preferredAddressType === 'workAddress' && <PrimaryBadge />}
                {acgUserProfileData?.preferredAddressType === 'homeAddress' && workAddressVisible && (
                  <Checkbox
                    isDisabled={watch('homeAddress.addressLine1') === ''}
                    onChange={() =>
                      setValue(
                        'preferredAddressType',
                        watchedPreferredAddressType === 'homeAddress'
                          ? 'workAddress'
                          : 'homeAddress',
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                        },
                      )
                    }
                  >
                    Make this My Primary Address
                  </Checkbox>
                )}
              </VStack>
            </VStack>
            <VStack align="flex-start" spacing="6">
              <HStack>
                <Heading opacity={homeAddressVisible ? 1 : 0.5} fontSize="lg">
                  Home Address
                </Heading>
                {!homeAddressVisible && (
                  <AddNewButton
                    onClick={() => {
                      setHomeAddressVisible(true);
                      setIsHomeAddressRequired(true);

                      if (typeof defaultValues?.homeAddress !== 'string') {
                        resetField('homeAddress', { keepError: false });
                      }

                      if (defaultValues?.preferredAddressType === 'homeAddress') {
                        setValue('preferredAddressType', 'homeAddress', {
                          shouldDirty: true,
                        });
                      }

                      setValue('homeAddress.country', 'US', {
                        shouldDirty: true,
                      });
                    }}
                  />
                )}
                {homeAddressVisible &&
                  typeof acgUserProfileData?.workAddress !== 'string' &&
                  acgUserProfileData?.workAddress?.addressLine1 &&
                  isWorkAddressRequired &&
                  watchedWorkAddress !== '' && (
                    <DeleteButton
                      onClick={() => {
                        setHomeAddressVisible(false);
                        setIsHomeAddressRequired(false);

                        Object.keys(defaultValues?.homeAddress)?.forEach(item =>
                          setValue(`homeAddress.${item}`, '', {
                            shouldDirty: true,
                          }),
                        );

                        if (acgUserProfileData?.preferredAddressType === 'homeAddress') {
                          setValue('preferredAddressType', 'workAddress', {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  )}
              </HStack>
              <VStack
                align="flex-start"
                spacing="4"
                w="full"
                display={homeAddressVisible ? 'flex' : 'none'}
              >
                <SelectCountryField
                  control={control}
                  name="homeAddress.country"
                  label={t('common.inputs.country.label')}
                  helperText={t('common.misc.required')}
                  valueType="short"
                  id="homeAddress"
                />
                <InputField
                  register={register}
                  name="homeAddress.addressLine1"
                  label="Address"
                  errors={errors}
                  helperText={t('common.misc.required')}
                />
                <InputField
                  register={register}
                  name="homeAddress.addressLine2"
                  label=""
                  errors={errors}
                />
                <InputField
                  register={register}
                  name="homeAddress.city"
                  label="City"
                  errors={errors}
                  helperText={t('common.misc.required')}
                />
                <SelectRegionField
                  country={watch('homeAddress.country') ?? ''}
                  control={control}
                  helperText={t('common.misc.required')}
                  name="homeAddress.state"
                  label={t('common.inputs.state.label')}
                  // @ts-ignore
                  countryValueType="short"
                  id="homeAddress"
                />
                <InputField
                  register={register}
                  name="homeAddress.postalCode"
                  label="Postal Code"
                  errors={errors}
                  helperText={t('common.misc.required')}
                />
                {acgUserProfileData?.preferredAddressType === 'homeAddress' && <PrimaryBadge />}
                {acgUserProfileData?.preferredAddressType === 'workAddress' && homeAddressVisible && (
                  <Checkbox
                    isDisabled={watch('workAddress.addressLine1') === ''}
                    onChange={() =>
                      setValue(
                        'preferredAddressType',
                        watchedPreferredAddressType === 'workAddress'
                          ? 'homeAddress'
                          : 'workAddress',
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                        },
                      )
                    }
                  >
                    Make this My Primary Address
                  </Checkbox>
                )}
              </VStack>
            </VStack>
          </SimpleGrid>
        </VStack>
      </VStack>
      <Box my="12">
        <ButtonGroup size="sm">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isDisabled={!isValid || isEmpty(dirtyFields)}
          >
            Save
          </Button>
        </ButtonGroup>
      </Box>
    </Center>
  );
};

const AddNewButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      type="button"
      variant="outline"
      size="xs"
      leftIcon={<FontAwesomeIcon color="black" icon={faCheck} />}
      {...props}
    >
      {t('common.buttons.addNew')}
    </Button>
  );
};

const DeleteButton = (props: ButtonProps) => {
  return (
    <potion.button type="button" {...props}>
      <FontAwesomeIcon color="blue.500" icon={faTrash} />
    </potion.button>
  );
};

const PrimaryBadge = (props: BadgeProps) => {
  return (
    <Badge variant="solid" colorScheme="green" {...props}>
      PRIMARY
    </Badge>
  );
};
