import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { useSource, useSourceConfig } from '@companydotcom/providers';
import { useMediaQuery } from '@companydotcom/potion';
import { useAuth } from './providers';
import { AppRoutes } from './routes';

import { hideFWWidget, showFWWidget } from './features/acg/shared';

export interface Auth0ConfigInterface {
  authDomain: string;
  clientId: string;
  clientAudience?: string;
}

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  const [isMobile] = useMediaQuery('(max-width: 40em)');
  const [sentryInit, setSentryInit] = useState(false);
  const source = useSource();
  const sourceConfig = useSourceConfig();
  const auth = useAuth();
  const email = auth?.user?.email;
  const location = useLocation();
  const locationState = location.state as Record<string, any>;
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (auth?.user && !sentryInit) {
    Sentry.setUser({
      id: auth.user?.userId,
      email: auth.user?.email,
    });

    setSentryInit(true);
  }

  useEffect(() => {
    const setAuthenticationStatus = async () => {
      try {
        const isAuthed = await auth?.isAuthenticated;
        setIsAuthenticated(isAuthed ?? false);
      } catch (err) {
        console.log('Error in setAuthenticationStatus: ', err);
      }
    };
    if (!isAuthenticated) {
      setAuthenticationStatus();
    }
  }, [auth?.isAuthenticated, isAuthenticated]);

  // GA4
  useEffect(() => {
    sourceConfig?.gtmContainerIds?.forEach((gtmId: any) => {
      TagManager.initialize({
        gtmId,
        events: {
          source: source?.sourceId,
          userId: auth?.user?.userId,
        },
      });
    });
  }, [auth?.user?.userId, source?.sourceId, sourceConfig?.gtmContainerIds]);

  useEffect(() => {
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: location,
        },
        source: source?.sourceId,
        userId: auth?.user?.userId,
      });
    }
  }, [auth?.user?.userId, location, source?.sourceId]);

  //UA
  // TODO: Remove this when we no longer need to support UA
  useEffect(() => {
    if (window?.dataLayer && window?.ga && source?.sourceId && auth?.user?.userId) {
      window.dataLayer.push({ pageCategory: location.pathname });
      window.ga('set', 'dimension3', auth?.user?.userId);
      window.ga('set', 'dimension4', source?.sourceId);
      window.ga('send', 'pageview');
    }
  }, [auth?.user?.userId, location?.pathname, source?.sourceId]);

  const sourceMap = (sourceId: string) => {
    switch (sourceId) {
      case 'ipayment':
        sourceId = 'paysafe';
        break;
      case 'priority2':
        sourceId = 'merchantprotectionplus';
        break;
      case 'priority3':
        sourceId = 'bizdash';
        break;
      case 'company':
        sourceId = '';
        break;
      default:
        break;
    }
    return sourceId;
  };

  /**
   * @name SourceRedirectionHook
   * @description Checks to see if there is a logged in user,
   *  and if the source of that user matches the host
   * IF NOT
   *    Redirect to the correct window location
   * @description Replaces the "whereami" component. This solves for two main issues:
   * 1. The incorrect white-labeled header and footer being rendered, prior to the main content being rendered.
   * 2. After being redirected from the deprecated "whereami" component, you would land on the root Dashboard (home screen)
   * and not the correct URL (pathname, and search parameters were excluded)
   */

  useEffect(() => {
    if (isAuthenticated === true && auth?.user) {
      const tokenSource = auth.getUserSource();

      if (tokenSource !== source?.sourceId && source?.sourceId) {
        const { protocol, pathname } = window.location;
        let { host, search } = window.location;
        const redirectingToGrandio = tokenSource === 'company';
        const redirectingFromGrandio = source?.sourceId === 'company';
        let subdomain = redirectingToGrandio ? '' : tokenSource;

        // TODO: The below mapping of source to URL is temp and
        // should go to Database table source, sourceConfig or sourceURI
        // calling function sourceMap to get correct source
        subdomain = sourceMap(tokenSource);
        const sourceName = sourceMap(source?.sourceId);

        if (!redirectingToGrandio && redirectingFromGrandio) {
          host = `${subdomain}.${host}`;
        }

        if (!redirectingToGrandio && !redirectingFromGrandio) {
          host = host.replace(sourceName, subdomain);
        }

        if (redirectingToGrandio) {
          host = host.replace(`${sourceName}.`, subdomain);
        }

        if (pathname === '/payment') {
          search = `${search}${
            locationState
              ? `&${Object.keys(locationState)
                  .map(key => `${key}=${locationState[key]}`)
                  .join('&')}`
              : ''
          }`;
        }

        window.location.replace(`${protocol}//${host}${pathname}${search}`);
      }
    }
  }, [auth, isAuthenticated, locationState, source?.sourceId]);

  // Checks the pathname and unmounts freshworks widget
  useEffect(() => {
    const fwWidget = document.getElementById('freshworks__widget');
    if (fwWidget && source.sourceId === 'acg') {
      if (location.pathname !== '/help') {
        hideFWWidget();
      } else if (location.pathname === '/help' && isMobile) {
        hideFWWidget();
      } else if (location.pathname === '/help') {
        showFWWidget();
      }
    }
  }, [isMobile, location.pathname, source.sourceId]);

  return <AppRoutes isAuthenticated={isAuthenticated} email={email} />;
};

export default App;
