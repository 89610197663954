import React, { useState, useEffect } from 'react';
import { Flex, Text, Box } from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useInView } from 'react-intersection-observer';
import { animate } from 'framer-motion';

import type { TileComponentProps } from '../../types';

export interface SentimentProps {
  negative: number;
  neutral: number;
  positive: number;
  average_sentiment: number;
}

export interface TileSocialMediaEngagementSentimentProps extends TileComponentProps {}

export const TileSocialMediaEngagementSentiment: React.FC<
  TileSocialMediaEngagementSentimentProps
> = props => {
  const { eventData, componentTheme } = props;
  const { data } = useEventData<SentimentProps>(eventData);
  const [from] = useState(-90);
  const [to] = useState(data?.average_sentiment ?? 0 * 90);

  const COLORS = ['#9FCBDD', '#6CAFCB', '#3C95BA'];

  const pieData = [
    { id: 'Negative', value: 300 },
    { id: 'Neutral', value: 350 },
    { id: 'Positive', value: 300 },
  ];

  return (
    <Flex
      className="tile__social-media-engagement-status"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="full"
      px={6}
      flexGrow={1}
      sx={componentTheme}
    >
      <Box position="relative">
        <ResponsiveContainer width={168} height={84}>
          <PieChart>
            <Pie
              data={pieData}
              cy={84}
              startAngle={180}
              endAngle={0}
              innerRadius={40}
              outerRadius={85}
              // fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <Needle from={from} to={to} />
      </Box>

      <Flex mt={7} className="legend" width="full" justifyContent="space-evenly">
        <Legend label="Negative" value={data?.negative} />
        <Legend label="Neutral" value={data?.neutral} />
        <Legend label="Positive" value={data?.positive} />
      </Flex>
    </Flex>
  );
};

function Legend({ label, value }: { label: string; value?: number }) {
  return (
    <Box sx={{ textAlign: 'center' }}>
      {value?.toString() ? (
        <Text textStyle="md" fontWeight="bold">
          {value}
        </Text>
      ) : (
        <Text textStyle="md" fontWeight="bold" color="red.500" mr={3}>
          !
        </Text>
      )}
      <Text textStyle="sm">{label}</Text>
    </Box>
  );
}

function Needle({ from = -90, to = 0 }: { from?: number; to?: number }) {
  const [position, setPosition] = useState(from);
  const { ref, inView } = useInView({
    threshold: 0.8,
  });

  useEffect(() => {
    const controls = animate(from, to, {
      type: 'spring',
      delay: 1,
      duration: 1,
      onUpdate(value) {
        setPosition(value);
      },
    });
    if (inView) {
      return () => controls.stop();
    }
  }, [from, to, inView]);

  return (
    <svg
      ref={ref}
      width={24}
      height={50}
      viewBox="0 0 24 50"
      style={{
        position: 'absolute',
        bottom: -10,
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        transformOrigin: '50% calc(100% - 12px)',
        transform: `rotate(${position}deg)`,
      }}
    >
      <g fill="#084066" fillRule="evenodd">
        <circle cx={12} cy={38} r={4} />
        <path stroke="#084066" strokeLinecap="square" d="M11 39l.67-31L13 39z" />
      </g>
    </svg>
  );
}
