import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { PageDivider } from '@companydotcom/ui';
import { InputMaybe } from '@companydotcom/types';
import {
  Center,
  Box,
  Button,
  useBreakpointValue,
  VStack,
  Heading,
  Checkbox,
} from '@companydotcom/potion';
import { HiddenField } from '../../../../services/acg/acg.types';
import {
  useGetAcgQueryArgs,
  useGetMembershipsV2Query,
  useUpdateMembershipV2Mutation,
} from '../../../../services/acg/acg-api-v2';

const EditDirectoryPreferencesSchema = () =>
  yup.object().shape({
    hiddenFields: yup.array().of(yup.string()),
  });

export interface EditDirectoryPreferencesProps {
  onClose: () => void;
  isMobile: boolean;
}

const checkBoxArray = ['emailAddress', 'preferredAddress', 'preferredPhoneNumber', 'website'];

export const EditDirectoryPreferences = ({ onClose, isMobile }: EditDirectoryPreferencesProps) => {
  const args = useGetAcgQueryArgs();
  const { data: membershipData } = useGetMembershipsV2Query({ ...args });
  const [updateMembership, { isLoading: isUpdating }] = useUpdateMembershipV2Mutation();

  const defaultValues = {
    emailAddress:
      membershipData?.hiddenFieldsFromMembershipDirectory?.includes(HiddenField.EMAIL_ADDRESS) ??
      false,
    preferredAddress:
      membershipData?.hiddenFieldsFromMembershipDirectory?.includes(
        HiddenField.PREFERRED_ADDRESS,
      ) ?? false,
    preferredPhoneNumber:
      membershipData?.hiddenFieldsFromMembershipDirectory?.includes(HiddenField.PREFERRED_PHONE) ??
      false,
    website:
      membershipData?.hiddenFieldsFromMembershipDirectory?.includes(HiddenField.WEBSITE) ?? false,
  };

  const { t } = useTranslation();
  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-2xl' });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(EditDirectoryPreferencesSchema()),
    defaultValues,
  });

  const onSubmit = async (data: typeof defaultValues) => {
    const dataToSubmit = Object.keys(data).filter(k => data[k]) as InputMaybe<string>[];
    try {
      await updateMembership({
        accessToken: args?.accessToken,
        contactId: args?.contactId,
        hiddenFieldsFromMembershipDirectory: dataToSubmit,
      });
      onClose();
    } catch (error) {
      console.log('EditDirectories onSubmit error: ', error);
    }
  };

  type TCheckBoxNames = 'emailAddress' | 'preferredAddress' | 'preferredPhoneNumber' | 'website';

  const labels = {
    emailAddress: t(
      'acg.myProfile.profilePages.memberDirectoryPreferences.excludePrimaryEmailFromDirectory',
    ),
    preferredAddress: t(
      'acg.myProfile.profilePages.memberDirectoryPreferences.excludePrimaryLocationFromDirectory',
    ),
    preferredPhoneNumber: t(
      'acg.myProfile.profilePages.memberDirectoryPreferences.excludePrimaryPhoneFromDirectory',
    ),
    website: t(
      'acg.myProfile.profilePages.memberDirectoryPreferences.excludePrimaryWebsiteFromDirectory',
    ),
  };

  return (
    <Center flexDirection="column">
      <Heading size={headingSize} fontWeight="700" mt={[6, 12]} mb={[2, 2, 12]}>
        {t(`acg.myProfile.profilePages.memberDirectoryPreferences.memberDirectoryPreferences`)}
      </Heading>
      {isMobile && <PageDivider width={50} mb={12} />}
      <VStack maxWidth="460px" width="100%" alignItems="start">
        {checkBoxArray.map(cbName => {
          return (
            <Controller
              control={control}
              name={cbName as TCheckBoxNames}
              key={cbName}
              defaultValue={false}
              render={({ field: { onChange, value, ref } }) => (
                <Checkbox onChange={onChange} ref={ref} isChecked={value}>
                  {labels[cbName]}
                </Checkbox>
              )}
            />
          );
        })}
      </VStack>
      <Box my={12}>
        <Button variant="outline" mr="2" onClick={onClose} size="sm">
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          size="sm"
          isLoading={isSubmitting || isUpdating}
          isDisabled={!isValid}
        >
          {t('common.buttons.save')}
        </Button>
      </Box>
    </Center>
  );
};
