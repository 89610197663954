import React, { useState } from 'react';
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  Tag,
  useMediaQuery,
  Button,
  useDisclosure,
  Collapse,
  VStack,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { SavedPaymentMethod } from '../../../../services/acg/acg.types';
import { DeleteConfirmationTile } from './saved-payments-delete-tile';
import { SavedPaymentTileHeader } from './saved-payment-tile-header';

export interface FetchedPaymentProps extends SavedPaymentMethod {
  isLoading?: boolean;
  isDeletingMethod?: boolean;
  onDelete?: (arg: any) => void;
  autoRenewMethod?: string;
}

export const SavedPaymentTile: React.FC<FetchedPaymentProps> = props => {
  const {
    cardNumber,
    cardExpiry,
    cardHolderName,
    city,
    postalCode,
    state,
    country,
    paymentNickName,
    accountNumber,
    addressLine1,
    addressLine2,
    customFields,
    accountHolderName,
    isLoading,
    onDelete,
    isDeletingMethod,
    cardType,
    recordKey,
    autoRenewMethod,
    paymentMethodType,
    merchantAccountTokens,
  } = props;

  const [isHover, setIsHover] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 48rem)');
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const isAutoRenew = autoRenewMethod === recordKey;

  return (
    <Box
      className="fetched-payment-tile"
      maxW="full"
      borderRadius="md"
      py={[1, 4]}
      px={[3, 4]}
      fontSize="sm"
      bg={isHover && !isLoading && !isOpen ? 'blackAlpha.50' : 'white'}
      position="relative"
      onMouseEnter={isLoading ? undefined : () => setIsHover(true)}
      onMouseLeave={isLoading ? undefined : () => setIsHover(false)}
      mb={[4, 0]}
      boxShadow={isMobile ? 'sm' : 'none'}
    >
      {!isMobile && !isOpen && isHover && (
        <Button
          minWidth={4}
          cursor="pointer"
          variant="link"
          position="absolute"
          onClick={onToggle}
          top={2}
          right={2}
        >
          <FontAwesomeIcon icon={faTrash} boxSize={4} />
        </Button>
      )}
      <Collapse in={isOpen}>
        <DeleteConfirmationTile
          {...{
            isDeletingMethod,
            isAutoRenew,
            paymentMethodType,
            cardNumber,
            accountNumber,
            cardType,
            merchantAccountTokens,
            recordKey,
            onDelete,
            onClose,
          }}
        />
      </Collapse>
      {!isOpen && (
        <VStack align="flex-start">
          <SavedPaymentTileHeader
            {...{ cardNumber, cardType, isMobile, onToggle, paymentNickName, accountNumber }}
          />
          <HStack width="full" justifyContent="flex-start" textTransform="uppercase">
            {customFields?.preferred === '1' && (
              <Tag
                variant="solid"
                colorScheme="green"
                borderRadius="sm"
                size="sm"
                width="fit-content"
              >
                <Text as="span" fontWeight="bold">
                  {t('acg.savedPaymentMethods.form.badge.default')}
                </Text>
              </Tag>
            )}
            {isAutoRenew && (
              <Tag variant="solid" borderRadius="sm" size="sm" width="fit-content">
                <Text as="span" fontWeight="bold">
                  {t('acg.savedPaymentMethods.form.badge.autoRenew')}
                </Text>
              </Tag>
            )}
          </HStack>
          <Grid
            templateColumns={[
              'repeat(2, 1fr)',
              'repeat(2, -1fr)',
              'repeat(2, -1fr)',
              'repeat(2, 1fr)',
            ]}
            columnGap={4}
            rowGap={1}
          >
            {cardExpiry ? (
              <GridItem>
                <Text fontWeight="medium">{t('common.paymentInfo.expiration')}</Text>
              </GridItem>
            ) : null}
            {cardExpiry && (
              <GridItem>
                <Text>{`${cardExpiry.slice(5)}/${cardExpiry.slice(0, 4)}`}</Text>
              </GridItem>
            )}
            {cardHolderName ? (
              <GridItem>
                <Text fontWeight="medium">{t('common.paymentInfo.name')}</Text>
              </GridItem>
            ) : null}
            {cardHolderName && (
              <GridItem>
                <Text wordBreak="break-all">{cardHolderName}</Text>
              </GridItem>
            )}
            {cardNumber && (
              <GridItem as={Stack} justifyContent="space-between">
                <Text fontWeight="medium">{t('common.paymentInfo.billingAddress')}</Text>
              </GridItem>
            )}
            {cardNumber && (
              <GridItem>
                <Text>{addressLine1 || ''}</Text>
                {addressLine2 && <Text>{addressLine2}</Text>}
                <Text>{city && state && `${city}, ${state}`}</Text>
                <Text>{city && !state && `${city}`}</Text>
                <Text>{!city && state && `${state}`}</Text>
                <Text>{postalCode || ''}</Text>
                <Text>{country || ''}</Text>
              </GridItem>
            )}
            {accountNumber && (
              <>
                <GridItem>
                  <Text fontWeight="medium">
                    {t('acg.checkout.orderStep.inputs.accountholderName.label')}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text>{accountHolderName || ''}</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="medium">
                    {t('acg.checkout.orderStep.inputs.accountType.label')}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text>{customFields?.account_type === 'S' ? 'Savings' : 'Checking'}</Text>
                </GridItem>
              </>
            )}
          </Grid>
        </VStack>
      )}
    </Box>
  );
};
