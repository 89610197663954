import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Box,
  Heading,
  Button,
  Alert,
  Center,
  VStack,
  StackDivider,
  AlertTitle,
  AlertDescription,
} from '@companydotcom/potion';
import { User } from '@companydotcom/types';
import { AppSpinner } from '@companydotcom/ui';
import { isEmpty } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { useUserProfileContext } from '../../../providers/user-profile-context';
import { getDefaultToggleState, formatProductName, formatChildProducts } from '../utils';
import { getChildProductsAndSeatCount } from '../../../pages/user-profile/utils';
import { ChooseUserProduct } from '../../../components/elements/choose-user-product';
import { useLazyGetEligibleChildProductsQuery } from '../../../services/product/product-api';
import { useLazyGetSeatCountQuery } from '../../../services/user/user-api';

interface IChooseProductsStep {
  isEmailProductActiveForParent?: boolean;
  user?: User;
  sourceId?: string;
  nextStep?: () => void;
  goToStep?: (arg: string | number) => void;
}

export const ChooseProductsStep = ({
  user,
  sourceId,
  nextStep,
  goToStep,
  isEmailProductActiveForParent,
}: IChooseProductsStep) => {
  const [loading, setLoading] = useState(true);
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();
  const [getSeatCount] = useLazyGetSeatCountQuery();
  const [childProductValues, setChildProductValues] = useState<{ [key: string]: boolean }>({});
  const { t } = useTranslation();
  const [getEligibleChildProducts] = useLazyGetEligibleChildProductsQuery();

  useEffect(() => {
    let isMounted = true;
    async function getChildProducts() {
      try {
        if (user?.userId && user.accountId && sourceId) {
          const productsAndSeatCount = await getChildProductsAndSeatCount(
            getEligibleChildProducts,
            getSeatCount,
            user.userId,
            user.accountId,
            sourceId,
            !!isEmailProductActiveForParent,
          );
          if (productsAndSeatCount) {
            if (
              productsAndSeatCount?.emailSeatCount &&
              !isEmpty(productsAndSeatCount?.emailSeatCount)
            ) {
              dispatchUserProfileState?.({
                type: 'UPDATE_STATE',
                payload: { emailSeatCount: productsAndSeatCount.emailSeatCount },
              });

              setChildProductValues(
                getDefaultToggleState(
                  productsAndSeatCount.processedChildProducts,
                  productsAndSeatCount.emailSeatCount.remainingEntitledSeats,
                  isEmailProductActiveForParent,
                ),
              );
            } else {
              setChildProductValues(
                getDefaultToggleState(
                  productsAndSeatCount.processedChildProducts,
                  undefined,
                  isEmailProductActiveForParent,
                ),
              );
            }

            dispatchUserProfileState?.({
              type: 'UPDATE_STATE',
              payload: {
                eligibleChildProducts: formatChildProducts(
                  productsAndSeatCount.processedChildProducts,
                ),
              },
            });

            setLoading(false);
          }
        }
      } catch (error) {
        console.log('\u{1F6A8} Error getting user products or seat count.', error);
      }
    }
    if (isMounted) {
      getChildProducts();
    }
    return () => {
      isMounted = false;
    };
  }, [
    user,
    user?.userId,
    sourceId,
    user?.accountId,
    dispatchUserProfileState,
    isEmailProductActiveForParent,
    getEligibleChildProducts,
    getSeatCount,
  ]);

  const handleToggleClick = (clickedValue: string) => {
    setChildProductValues(prevState => ({
      ...prevState,
      [clickedValue]: !childProductValues[clickedValue],
    }));
  };

  const handlePurchaseSeat = () => {
    if (goToStep) {
      dispatchUserProfileState?.({
        type: 'UPDATE_STATE',
        payload: {
          chosenProducts: {
            email_rackspace: true,
            teamup: true,
          },
        },
      });

      goToStep('add-additional-email-users');
    }
  };

  const handleSubmit = (data: { [key: string]: boolean }) => {
    // PARENT USER MUST HAVE EMAIL ACTIVATED AND SEATS AVAILABLE TO PROCEED IF data.email_rackspace IS TRUE
    // display form data on success
    dispatchUserProfileState?.({ type: 'UPDATE_STATE', payload: { chosenProducts: data } });
    nextStep?.();
  };

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" pt={14} pb={16} px={4}>
      <Box maxWidth={[310, 497]} sx={{ textAlign: 'center' }}>
        <Heading size="hs-2xl">{t('miop.userProfile.addNewUser.header')}</Heading>
        <Text textStyle="2xl" mt={4}>
          {t('miop.userProfile.addNewUser.chooseProducts.subheader')}
        </Text>
        <Text textStyle="md" mt={2}>
          {t('miop.userProfile.addNewUser.chooseProducts.copy')}
        </Text>
      </Box>
      <Flex flexDirection="column" mt={6}>
        {loading ? (
          <Center my={14}>
            <AppSpinner />
          </Center>
        ) : (
          <VStack divider={<StackDivider />}>
            {userProfileState?.eligibleChildProducts?.map(
              (product, i) =>
                product && (
                  <ChooseUserProduct
                    key={i}
                    productName={product?.name || ''}
                    productDescription={product?.description || ''}
                    productImage={`${
                      process.env.REACT_APP_IMG_URL
                    }/dashboard_nextgen/grandio/images/products/logos/svg/${formatProductName(
                      product?.name,
                    )}.svg`}
                    isSelected={childProductValues[product?.slug]}
                    includedInMembership={product.ratePlans?.some(
                      plan =>
                        plan?.name !== 'None' &&
                        plan?.includedInMembership &&
                        plan?.productId !== process.env.REACT_APP_EMAIL_PRODUCTID,
                    )}
                    toggleHandler={() => {
                      if (product?.slug) {
                        handleToggleClick(product?.slug);
                      }
                    }}
                    isDisabled={
                      !isEmailProductActiveForParent ||
                      (product.slug === 'email_rackspace' &&
                        (userProfileState?.emailSeatCount?.remainingEntitledSeats as number) <= 0)
                    }
                    warningMessage={
                      product.slug === 'email_rackspace' &&
                      isEmailProductActiveForParent &&
                      (userProfileState.emailSeatCount?.remainingEntitledSeats as number) <= 0 && (
                        <Alert status="warning" mt={4} textAlign="center" flexDirection="column">
                          <AlertTitle mb={2}>
                            {t('miop.userProfile.addNewUser.chooseProducts.emailWarningHeader')}
                          </AlertTitle>
                          <AlertDescription maxWidth={378}>
                            {t('miop.userProfile.addNewUser.chooseProducts.emailWarningSubheader')}
                          </AlertDescription>
                          <Trans i18nKey="miop.userProfile.addNewUser.chooseProducts.emailWarningCta">
                            <Text textStyle="md">
                              To add a user to email,
                              <Button variant="link" onClick={handlePurchaseSeat}>
                                purchase more seats.
                              </Button>
                            </Text>
                          </Trans>
                        </Alert>
                      )
                    }
                  />
                ),
            )}
          </VStack>
        )}
        <Box sx={{ textAlign: 'center' }} mt={4}>
          <Button
            size="lg"
            onClick={() => {
              handleSubmit(childProductValues);
            }}
            isLoading={loading}
            // isDisabled={Object.keys(childProductValues).every(i => !childProductValues[i])}
          >
            {t('common.buttons.next')}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
