import React from 'react';

export const DashboardIcon = () => (
  <g stroke="currentColor" strokeWidth="2" transform="translate(1.502 1)">
    <path
      fill="currentColor"
      strokeLinecap="round"
      d="M3.013 5.5H5.02M3.013 10.5H5.02M3.013 15.5H5.02"
    />
    <rect fill="transparent" width="16.069" height="24" x="8.034" rx="1" />
    <rect fill="transparent" width="8.034" height="24" rx="1" />
    <path
      fill="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.81 13.424l1.692 2.076 3.567-4.487 2.443 1.94 2.752-3.334"
    />
  </g>
);

export const HelpIcon = () => (
  <g fill="none">
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13.255 25H25V13.255C25 6.487 20.024 1 13.255 1 6.487 1 1 6.487 1 13.255 1 20.024 6.487 25 13.255 25z"
    />
    <text
      fill="currentColor"
      fontSize="18"
      fontWeight="bold"
      letterSpacing=".257"
      transform="translate(1)"
    >
      <tspan x="8" y="19.5">
        ?
      </tspan>
    </text>
  </g>
);

export const UserProfileIcon = () => (
  <g fill="none" fillRule="evenodd" stroke="currentColor" transform="translate(1 1)">
    <rect width="24" height="24" strokeWidth="2" rx="1" />
    <g strokeLinecap="round">
      <g strokeLinejoin="round" strokeWidth="1.61">
        <path d="M15 6.592c0 .779-.148 2.354-.781 2.88-.634.525-1.206 1.458-2.219 1.458-.93 0-1.258-.467-2.096-1.261C9.102 8.908 9 7.427 9 6.592c0-1.47 1.343-2.662 3-2.662s3 1.191 3 2.662z" />
        <path d="M10.89 10.93v.975c-.974.343-1.64.57-1.997.683-.662.21-1.084.208-1.435.487-.635.505-.954 1.124-.958 1.855h11c-.04-.724-.358-1.342-.956-1.855-.366-.314-1.066-.255-1.913-.487-.359-.098-.962-.339-1.81-.723v-.935l-.957.254-.974-.254z" />
      </g>
      <path strokeWidth="2" d="M4.563 19.5h15.625" />
    </g>
  </g>
);

export const AccountInformationIcon = () => (
  <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
    <g transform="translate(1 1)">
      <rect width="19.862" height="19.862" y="4" rx=".828" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M5.675.355h17.81v18.948" />
    </g>
    <path strokeLinecap="round" d="M6.455 10.6H16M6.455 16.6H16" />
  </g>
);

export const SubscriptionsIcon = () => (
  <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
    <path
      strokeLinejoin="round"
      d="M6.586 21H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h20.857a1 1 0 0 1 1 .984L24 11"
    />
    <path d="M18.5 13v12M12.5 19h12M7 6h11M7 10h11" />
  </g>
);

export const PasswordManagementIcon = () => (
  <g
    fill="none"
    fillRule="evenodd"
    stroke="currentColor"
    strokeWidth="2"
    transform="translate(1 1)"
  >
    <rect width="20" height="16" y="8" rx="2" />
    <path strokeLinecap="square" d="M4 8V1.693C4 .564 6 0 10 0s6 .564 6 1.693V8H4z" />
    <circle cx="10" cy="14" r="1" />
    <path strokeLinecap="round" d="M10 15v5" />
  </g>
);

export const LogOutIcon = () => (
  <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round">
    <path
      strokeWidth="2"
      d="M12 19.412V23c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h10c.552 0 1 .448 1 1v3.339h0"
      transform="translate(1 1)"
    />
    <g strokeWidth="2.25">
      <path
        d="M.818 5.369L15 5.369M.818 5.369L7 0M.818 5.369L7 11"
        transform="translate(1 1) translate(10 6)"
      />
    </g>
  </g>
);

export const GfDataIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    baseProfile="tiny"
    overflow="visible"
    version="1.1"
    viewBox="0 0 38.3 45.4"
    xmlSpace="preserve"
  >
    {' '}
    <path
      id="Path_15482"
      fill="#FFFFFF"
      d="M4.3,2.2c0,1.2-1,2.2-2.2,2.2C1,4.3,0,3.4,0,2.2c0,0,0,0,0,0C0,1,1,0,2.2,0S4.3,1,4.3,2.2"
    />
    <path
      id="Path_15483"
      fill="#FFFFFF"
      d="M4.3,8.9c0,1.2-1,2.2-2.2,2.2C1,11.1,0,10.1,0,8.9c0,0,0,0,0,0c0-1.2,1-2.2,2.2-2.2
	S4.3,7.7,4.3,8.9"
    />
    <path
      id="Path_15484"
      fill="#FFFFFF"
      d="M4.3,36.5c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0,0,0,0,0,0c0-1.2,1-2.2,2.2-2.2
	S4.3,35.3,4.3,36.5"
    />
    <path
      id="Path_15485"
      fill="#FFFFFF"
      d="M4.3,43.3c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2S4.3,42.1,4.3,43.3
	"
    />
    <path
      id="Path_15486"
      fill="#FFFFFF"
      d="M4.3,15.6c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0,0,0,0,0,0c0-1.2,1-2.2,2.2-2.2
	S4.3,14.4,4.3,15.6"
    />
    <path
      id="Path_15487"
      fill="#74BE44"
      d="M4.3,22.4c0,1.2-1,2.2-2.2,2.2c0,0,0,0,0,0c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
	c0,0,0,0,0,0C3.4,20.2,4.3,21.2,4.3,22.4"
    />
    <path
      id="Path_15488"
      fill="#FFFFFF"
      d="M4.3,29.1c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0,0,0,0,0,0C0,27.9,1,27,2.2,27
	C3.4,27,4.3,27.9,4.3,29.1"
    />
    <path
      id="Path_15489"
      fill="#FFFFFF"
      d="M11,2.2c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2S7.7,0,8.9,0S11,1,11,2.2"
    />
    <path
      id="Path_15490"
      fill="#FFFFFF"
      d="M11,8.9c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S11,7.7,11,8.9"
    />
    <path
      id="Path_15491"
      fill="#FFFFFF"
      d="M11,36.5c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S11,35.3,11,36.5"
    />
    <path
      id="Path_15492"
      fill="#FFFFFF"
      d="M11,43.3c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2S11,42.1,11,43.3"
    />
    <path
      id="Path_15493"
      fill="#FFFFFF"
      d="M11,15.6c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S11,14.4,11,15.6"
    />
    <path
      id="Path_15494"
      fill="#74BE44"
      d="M11,22.4c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c0,0,0,0,0,0
	C10.1,20.2,11,21.2,11,22.4"
    />
    <path
      id="Path_15495"
      fill="#FFFFFF"
      d="M11,29.1c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2C10.1,27,11,27.9,11,29.1"
    />
    <path
      id="Path_15496"
      fill="#FFFFFF"
      d="M17.7,2.2c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S17.7,1,17.7,2.2"
    />
    <path
      id="Path_15497"
      fill="#FFFFFF"
      d="M17.7,8.9c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S17.7,7.7,17.7,8.9"
    />
    <path
      id="Path_15498"
      fill="#FFFFFF"
      d="M17.7,36.5c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S17.7,35.3,17.7,36.5"
    />
    <path
      id="Path_15499"
      fill="#FFFFFF"
      d="M17.7,43.3c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
	S17.7,42.1,17.7,43.3"
    />
    <path
      id="Path_15500"
      fill="#FFFFFF"
      d="M17.7,15.6c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S17.7,14.4,17.7,15.6"
    />
    <path
      id="Path_15501"
      fill="#74BE44"
      d="M17.7,22.4c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c0,0,0,0,0,0
	C16.8,20.2,17.7,21.2,17.7,22.4"
    />
    <path
      id="Path_15502"
      fill="#FFFFFF"
      d="M24.6,8.9c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S24.6,7.7,24.6,8.9"
    />
    <path
      id="Path_15503"
      fill="#FFFFFF"
      d="M24.6,15.6c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S24.6,14.4,24.6,15.6"
    />
    <path
      id="Path_15504"
      fill="#74BE44"
      d="M24.6,22.3c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S24.6,21.1,24.6,22.3"
    />
    <path
      id="Path_15505"
      fill="#FFFFFF"
      d="M24.6,29.1c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c0,0,0,0,0,0
	C23.6,26.9,24.6,27.9,24.6,29.1"
    />
    <path
      id="Path_15506"
      fill="#FFFFFF"
      d="M31.6,15.6c0,1.2-1,2.2-2.2,2.2c0,0,0,0,0,0c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
	C30.7,13.5,31.6,14.4,31.6,15.6"
    />
    <path
      id="Path_15507"
      fill="#74BE44"
      d="M31.6,22.4c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
	C30.7,20.2,31.6,21.2,31.6,22.4"
    />
    <path
      id="Path_15508"
      fill="#FFFFFF"
      d="M31.6,29.1c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
	S31.6,27.9,31.6,29.1"
    />
    <path
      id="Path_15509"
      fill="#74BE44"
      d="M38.3,22.4c0,1.2-1,2.2-2.2,2.2c0,0,0,0,0,0c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
	C37.3,20.2,38.3,21.2,38.3,22.4C38.3,22.4,38.3,22.4,38.3,22.4"
    />
    <path
      id="Path_15510"
      fill="#FFFFFF"
      d="M24.6,35.9c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S24.6,34.7,24.6,35.9"
    />
    <path
      id="Path_15511"
      fill="#FFFFFF"
      d="M17.7,29.1c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
	C16.8,27,17.7,27.9,17.7,29.1"
    />
  </svg>
);
