import React, { useMemo } from 'react';
import {
  Center,
  Container,
  VStack,
  Text,
  Heading,
  useBreakpointValue,
  Box,
  Flex,
  Button,
  SimpleGrid,
  FormLabel,
  Switch,
  useBoolean,
  useMediaQuery,
} from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { PageDivider, AppSpinner } from '@companydotcom/ui';
import { RatePlan } from '@companydotcom/types';
import { useNavigate } from 'react-router-dom';
import { useMitt } from '@companydotcom/providers';
import { companyConstants } from '@companydotcom/helpers';
import { useGetProductQuery } from '../../../services/product/product-api';
import { useLocalCartStorage } from '../../../hooks/use-local-cart-storage';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';

export const FaceCakePlanManagement = ({ variant }: { variant?: 'page' | 'component' }) => {
  const { data: globalUser } = useGetGlobalUserQuery();

  const { t } = useTranslation();
  const headingSize = useBreakpointValue({ base: 'hs-2xl', xl: 'hs-3xl' });
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const navigate = useNavigate();
  const [monthlyPlans, setMonthlyPlans] = useBoolean(true);
  const { emitter } = useMitt();
  const { addPlanToLocalCart } = useLocalCartStorage();
  const faceCakeProductId = '238a1ecf-06fe-4ea9-8502-05bfbeeba612';

  const { data: product, isLoading } = useGetProductQuery({
    productId: faceCakeProductId,
  });

  const filteredRatePlans: RatePlan[] = useMemo(() => {
    if (product?.ratePlans) {
      return [
        ...product?.ratePlans?.filter((plan: RatePlan) =>
          monthlyPlans ? plan?.term === 'Month' : plan?.term === 'Year',
        ),
        {
          name: 'Enterprise',
          subheading: 'Unlimited Active Styles',
          detail: 'Contact Us to Discuss Pricing',
          description: 'Personalized to Your Needs',
          button: 'Contact Us',
          detailList: [
            {
              name: 'AR Try-On Experience with Signature Augmented Realism',
            },
            {
              name: '2-Way and 4-Way Compare',
            },
            {
              name: 'Photo and Video Share',
            },
            {
              name: 'Live or Photo Upload',
            },
            {
              name: 'AI Recognition:',
              values: ['Face Shape', 'Style', 'Frame Color'],
            },
          ],
        },
      ];
    }

    return [];
  }, [monthlyPlans, product?.ratePlans]);

  const faceCakeAccountProduct = useMemo(
    () =>
      globalUser?.account?.Products?.find(
        (product: any) => product?.productId === faceCakeProductId && product?.isActive,
      ),
    [globalUser?.account?.Products],
  );

  const currentUserProduct = useMemo(() => {
    return filteredRatePlans?.find(
      rp =>
        faceCakeAccountProduct &&
        rp?.zuoraProductRatePlanId === faceCakeAccountProduct?.zuoraRatePlanId,
    )?.name;
  }, [faceCakeAccountProduct, filteredRatePlans]);

  let completedPlans: any = Array();

  useMemo(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    completedPlans = filteredRatePlans.slice(
      0,
      filteredRatePlans.findIndex(
        rp =>
          faceCakeAccountProduct &&
          rp?.zuoraProductRatePlanId === faceCakeAccountProduct?.zuoraRatePlanId,
      ),
    );
  }, [faceCakeAccountProduct, filteredRatePlans, completedPlans]);

  const recommendedPlan = useMemo(() => {
    if (!faceCakeAccountProduct) {
      const availableForTryOnCount = +globalUser?.account?.mads?.['user-facecake']?.find(
        (data: any) => data?.key === 'availableForTryOnCount',
      )?.value;

      if (availableForTryOnCount >= 0 && availableForTryOnCount < 26) {
        return 'Plan 1';
      }

      if (availableForTryOnCount >= 26 && availableForTryOnCount < 51) {
        return 'Plan 2';
      }

      if (availableForTryOnCount >= 51 && availableForTryOnCount < 101) {
        return 'Plan 3';
      }

      return 'Enterprise';
    }

    return undefined;
  }, [faceCakeAccountProduct, globalUser?.account?.mads]);

  const onSubmit = async (selectedPlan: string) => {
    try {
      if (variant === 'component' && selectedPlan !== 'Enterprise') {
        await emitter.emit(companyConstants.platformEvents.dataCollectorComplete);
        localStorage.removeItem('facecake_termsChecked');
      }

      if (selectedPlan === 'Enterprise') {
        navigate('/facecake/enterprise-contact-us');
      } else {
        addPlanToLocalCart(filteredRatePlans?.find((rp: RatePlan) => rp?.name === selectedPlan));
        navigate('/facecake/checkout', { state: { firstTime: true } });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Center
      className="facecake_plan_management"
      flexDirection="column"
      bg="transparent"
      my={variant === 'component' ? [0, 8, 14] : [4, 8, 14]}
      mx={variant === 'component' ? [4, 8] : [6, 8]}
    >
      <Box
        width="full"
        maxW={1100}
        py={variant === 'component' ? [0, 12] : 0}
        px={variant === 'component' ? [0, 10, null, 24] : 0}
        bg={variant === 'component' && !isMobile ? 'white' : 'none'}
      >
        {!globalUser || isLoading ? (
          <Center flexGrow={1} width="full" my={24}>
            <AppSpinner description="Loading Account..." />
          </Center>
        ) : (
          <>
            <Box width="full" textAlign={variant === 'component' && !isMobile ? 'center' : 'left'}>
              <Heading size={headingSize} pb={variant === 'component' ? [2, 6] : 0}>
                {variant === 'component'
                  ? 'FaceCake Eyewear Plans'
                  : t('icommerce.accountManagement.planManagement')}
              </Heading>
              {(variant === 'page' || isMobile) && <PageDivider mt={[2, 6]} mx="inherit" />}
            </Box>

            <Container w="full" maxW="908">
              <Flex width="full" mt={8} mb={6} justifyContent={['flex-end', 'space-between']}>
                <FormLabel fontSize="lg" fontWeight="bold" display={['none', 'block']}>
                  {variant === 'component' ? 'Choose your plan:' : 'View or change your plan:'}
                </FormLabel>
                <Flex>
                  <Text mr={3} pt={1} fontSize="14px" fontWeight="normal">
                    Show annual plans
                  </Text>
                  <Switch pt={0.5} size="lg" onChange={() => setMonthlyPlans.toggle()} />
                </Flex>
              </Flex>

              <SimpleGrid
                spacing="4"
                columns={[
                  1,
                  2,
                  null,
                  filteredRatePlans?.length <= 4 ? filteredRatePlans?.length : 4,
                ]}
                className="plan-group"
              >
                {filteredRatePlans?.map(
                  (
                    { name, description, price, subheading, detail, detailList, button }: any,
                    i: string | number | undefined,
                  ) => {
                    const isCurrentProduct = currentUserProduct === name;
                    const isRecommendedProduct = recommendedPlan === name;
                    const isCompletedPlan = completedPlans.find(
                      (cp: any) => currentUserProduct && cp?.name === name,
                    );
                    return (
                      <Box
                        key={i}
                        className="plan"
                        w="full"
                        bg="white"
                        height="full"
                        borderRadius="md"
                        boxShadow="md"
                        border={
                          isCurrentProduct || isRecommendedProduct ? '2px solid' : '1px solid'
                        }
                        borderColor={
                          isCurrentProduct || isRecommendedProduct ? 'pink.500' : 'gray.200'
                        }
                        position="relative"
                      >
                        {(isCurrentProduct || isRecommendedProduct) && (
                          <Box bg="pink.500" position="absolute" width="inherit">
                            <Text
                              fontSize="11px"
                              fontWeight="bold"
                              color="white"
                              textAlign="center"
                            >
                              {isCurrentProduct ? 'YOUR CURRENT PLAN' : 'RECOMMENDED FOR YOU'}
                            </Text>
                          </Box>
                        )}

                        <VStack
                          align={['flex-start']}
                          justify="space-between"
                          height="full"
                          textAlign={['left']}
                          p="4"
                          mb={[2]}
                        >
                          <VStack align={['flex-start']} spacing="4">
                            <Heading size="hs-xl" fontWeight="bold">
                              {name}
                            </Heading>
                            <Box>
                              <Text fontWeight="bold">{subheading}</Text>
                              {description && (
                                <Text fontSize="xs" fontWeight="medium">
                                  {description}
                                </Text>
                              )}
                            </Box>
                            {name === 'Enterprise' && (
                              <Box>
                                <Text color="pink.500" fontWeight="bold" fontSize="sm">
                                  {detail}
                                </Text>
                              </Box>
                            )}
                            {price && (
                              <>
                                <Box color="pink.500">
                                  <Text fontSize="xl" fontWeight="extrabold">
                                    ${Number(price).toLocaleString('en-US')}
                                  </Text>
                                  {monthlyPlans ? (
                                    <Text fontSize="xs">Monthly Marketing Fee</Text>
                                  ) : (
                                    <>
                                      <Text fontSize="xs">Annual Marketing Fee</Text>
                                      <Text fontSize="xs" fontWeight="extrabold">
                                        INCLUDES 1 MONTH FREE
                                      </Text>
                                    </>
                                  )}
                                  <Text fontSize="11px">{detail}</Text>
                                </Box>
                              </>
                            )}
                            {detailList && (
                              <VStack align={['flex-start']} fontSize="sm">
                                {detailList.map(
                                  (
                                    {
                                      name: detailName,
                                      values,
                                    }: {
                                      name: string;
                                      values: [string];
                                    },
                                    i: string | number | undefined,
                                  ) => (
                                    <Box key={i}>
                                      <Text>{detailName}</Text>
                                      {values && values.map((value, j) => <li key={j}>{value}</li>)}
                                    </Box>
                                  ),
                                )}
                              </VStack>
                            )}
                          </VStack>
                          <Button
                            alignSelf="center"
                            width="full"
                            variant={isCurrentProduct || isRecommendedProduct ? 'solid' : 'outline'}
                            disabled={isCurrentProduct || isCompletedPlan}
                            onClick={() => onSubmit(name)}
                          >
                            {button || 'CHECKOUT'}
                          </Button>
                        </VStack>
                      </Box>
                    );
                  },
                )}
              </SimpleGrid>
            </Container>
          </>
        )}
      </Box>
    </Center>
  );
};
