import gql from 'graphql-tag';
import { COMPONENT_THEME_FRAGMENT } from './user-fragments';

export const GET_USER_QUERY = gql`
  query GetUser($userId: String!, $locale: String) {
    getUser(userId: $userId, locale: $locale) {
      userId
      seoId
      accountId
      userCUIs {
        cuiId
        name
        priority
        type
        tileName
        trigger
        isActive
        dataRequired
        dateUpdated
      }
      mads
      appointments {
        appointmentId
        userId
        productId
        dateCreated
        dateUpdated
        dateExpires
        appointmentTime
        fulfilled
        metadata
      }
      account {
        allowPartnerBilling
        defaultPartnerBilled
        accountId
        chargebeeCustomerId
        seoStatus {
          gmbConnectStatus
        }
        accountStatus
        business {
          businessId
          name
          address {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          categories {
            id
            name
            fullName
            publisher
          }
          website
        }
        businessPrimary {
          businessId
          name
          website
          address {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          numberOfEmployees
          categories {
            id
            name
            fullName
            publisher
          }
        }
        Products {
          isActive
          ratePlanName
          productId
          zuoraRatePlanId
        }
        listingId
        listing {
          id
          name
          address
          addressExtra
          listingUrl
          hours {
            closed
            periods {
              from
              to
            }
            day
          }
          phones {
            type
            number
          }
          specialOffer {
            description
            title
          }
          hideAddress
          zip
          city
          state
          country
          categories {
            name
            publisher
            id
            fullName
          }
          description
          socialProfiles {
            url
            description
            type
          }
          images {
            url
            description
            type
          }
          videos {
            url
            description
            type
          }
          keywords
          paymentOptions
          hideAddress
          emails {
            email
            description
          }
          gmbStatus
          gmbLocationId
          services
          languages
          serviceArea {
            businessType
            radius {
              latlng {
                latitude
                longitude
              }
              radiusKm
            }
            places {
              placeInfos {
                name
                placeId
              }
            }
          }
          urls {
            url
            description
            type
          }
          content {
            type
            name
            title
            timeStart
            currency
            price
            images {
              url
              description
              type
            }
            image {
              url
              description
              type
            }
            description
          }
        }
        loans {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          dateCreated
          dateUpdated
          yearFounded
          monthFounded
        }
        loanPrimary {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          dateCreated
          dateUpdated
          yearFounded
          monthFounded
        }
        zuoraPaymentMethodId
        zuoraAccountId
        dateCreated
        dateUpdated
        isWebFQDNCustom
        isEmailFQDNCustom
        fqdnCompany
        fqdnCustom
        hasFQDN
        users {
          isPrimary
          userId
        }
        vendorData
        mads
        logo
      }
      vendorData
      products
      mailboxFQDN
      mailbox
      marketoPersonId
      firstName
      lastName
      organization
      avatar
      email
      role
      phone
      source
      userStatus
      userSettings {
        showAllTiles
      }
      userTileStates {
        seqNo
        isHidden
        tileId
        stateCurrent
      }
      userTileGroups {
        seqNo
        isHidden
        groupId
        color
        tileGroup {
          name
        }
      }
      userSettings {
        showAllTiles
      }
      userDataCollectors {
        dataCollectorSlug
        priority
        dateCreated
        dateUpdated
        isActive
        provider
        providerId
        tileName
        trigger
        type
        isDismissible
        path
        timesSeen
        isCompleted
      }
    }
  }
`;

export const GET_USER_TILES_QUERY = gql`
  query GetUserTiles($userId: String!, $locale: String) {
    getUserTiles(userId: $userId, locale: $locale) {
      learnMoreUri
      vendorLogoUri
      targetTile
      tileId
      tileName
      tileSlug
      tileIcon
      tileVariant
      tileHasBadge
      tileHasLock
      tileLockIcon
      dateCreated
      productId
      dateCreated
      sourceProductName
      seqNo
      stateCurrent
      groupId
      isHidden
      dateUpdated
      dateExpires
      isNew
      roles
      tileLinks {
        targetTile
      }
      product {
        productId
        description
        isPackage
        isLeadGen
        isActive
        name
        slug
        purchaseRelUrl
        ssoUrl
        ratePlans {
          description
          includedInMembership
          isActive
          name
          price
          zuoraProductRatePlanId
          zuoraProductRatePlanChargeId
          partnerRatePlanId
          enterpriseQty
          eligibleProducts
          tiers {
            startingUnit
            endingUnit
            price
            partnerTierId
            chargeModel
          }
        }
        dateCreatedNumber
        dateUpdatedNumber
      }
      tileStates {
        stateName
        tileEvents {
          componentTypes
          eventType
          topic
          interactive
          triggerType
          pollInterval
          pollTimeout
        }
        tileComponents {
          href
          imageUrl
          gtmId
          target
          learnMoreUri
          errorText
          type
          text
          variant
          target
          statusType
          description
          dataConfig {
            type
            dataKey
            imageUrl
            label
          }
          operatingSystem
          componentTheme {
           ${COMPONENT_THEME_FRAGMENT}
          }
          disabled {
            disabledText
            sources
          }
          views {
            name
            type
            variant
          }
          params {
            key
            value
          }
          pubEvents {
            type
            actions {
              eventType
              responseField
              type
              dataRequired
              dataToValidate
              actionParams {
                key
                value
              }
            }
          }
          children {
            icon
            href
            imageUrl
            gtmId
            type
            target
            text
            variant
            statusType
            description
            dataConfig {
              type
              dataKey
              imageUrl
              label
            }
            operatingSystem
            componentTheme {
              ${COMPONENT_THEME_FRAGMENT}
            }
            params {
              key
              value
            }
            pubEvents {
              type
              actions {
                type
                dataRequired
                dataToValidate
                actionParams {
                  key
                  value
                }
              }
            }
            children {
              icon
              href
              imageUrl
              gtmId
              type
              target
              text
              variant
              statusType
              description
              dataConfig {
                type
                dataKey
                imageUrl
                label
              }
              operatingSystem
              componentTheme {
                ${COMPONENT_THEME_FRAGMENT}
              }
              params {
                key
                value
              }
              pubEvents {
                type
                actions {
                  eventType
                  responseField
                  type
                  dataRequired
                  dataToValidate
                  actionParams {
                    key
                    value
                  }
                }
              }
              children{
                icon
                href
                imageUrl
                description
                icon
                gtmId
                type
                target
                text
                description
                variant
                statusType
                dataConfig {
                  type
                  dataKey
                  imageUrl
                  label
                }
                pubEvents {
                  type
                  actions {
                    eventType
                    responseField
                    type
                    dataRequired
                    dataToValidate
                    actionParams {
                      key
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }}`;

export const GET_USER_GROUPS_QUERY = gql`
  query GetUserGroups($userId: String!, $locale: String) {
    getUserGroups(userId: $userId, locale: $locale) {
      groupId
      isHidden
      seqNo
      color
      roles
      name
    }
  }
`;

export const GET_USER_LITE_QUERY = gql`
  query GetUserLite($userId: String!, $locale: String) {
    getUser(userId: $userId, locale: $locale) {
      userId
      seoId
      accountId
      appointments {
        appointmentId
        userId
        productId
        dateCreated
        dateUpdated
        dateExpires
        appointmentTime
        metadata
      }
      account {
        allowPartnerBilling
        defaultPartnerBilled
        accountId
        seoStatus {
          gmbConnectStatus
        }
        accountStatus
        business {
          businessId
          name
          address {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          categories {
            id
            name
            fullName
            publisher
          }
          website
        }
        businessPrimary {
          businessId
          name
          website
          address {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          numberOfEmployees
          categories {
            id
            name
            fullName
            publisher
          }
        }
        Products {
          isActive
          ratePlanName
          productId
        }
        loans {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          dateCreated
          dateUpdated
          yearFounded
          monthFounded
        }
        loanPrimary {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          dateCreated
          dateUpdated
          yearFounded
          monthFounded
        }
        zuoraPaymentMethodId
        zuoraAccountId
        dateCreated
        dateUpdated
        isWebFQDNCustom
        isEmailFQDNCustom
        fqdnCompany
        fqdnCustom
        hasFQDN
        users {
          isPrimary
          userId
        }
        mads
      }
      products
      mailboxFQDN
      mailbox
      marketoPersonId
      firstName
      lastName
      avatar
      email
      role
      phone
      source
      userStatus
      mads
    }
  }
`;

export const GET_USER_TILE_STATES = gql`
  query GetUserTileStates($userId: String!) {
    getUserTileStates(userId: $userId) {
      tileId
      stateCurrent
      statePrevious
      isHidden
      seqNo
      groupId
    }
  }
`;

export const GET_PRODUCT_SSO = gql`
  query GetProductSSO($input: SsoUrlInput!) {
    getProductSSO(input: $input)
  }
`;

export const GET_DOMAIN = gql`
  query GetDomain($fqdn: String!) {
    getDomain(fqdn: $fqdn)
  }
`;

export const GET_SEAT_COUNT = gql`
  query GetSeatCount($accountId: String!, $productId: String!) {
    getSeatCount(productId: $productId, accountId: $accountId) {
      status
      totalEntitledSeats
      remainingEntitledSeats
      productSlug
      accountId
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      marketoPersonId
      dateCreated
      accountId
      role
      lastName
      userId
      source
      email
      userStatus
      firstName
      dateUpdated
      mailbox
      mailboxFQDN
    }
  }
`;

export const GET_USER_TILE_GROUPS = gql`
  query GetUserTileGroups($userId: String!) {
    getUserTileGroups(userId: $userId) {
      tileGroup {
        name
      }
      color
      isHidden
      seqNo
      groupId
    }
  }
`;

export const FETCH_MIDS_QUERY = gql`
  query GetMidList($email: String!, $source: String!) {
    getMidList(email: $email, source: $source)
  }
`;

export const GET_ENROLLED_USER = gql`
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      userId
      email
      accountId
    }
  }
`;

export const GET_USER_BY_EMAIL_AND_SOURCE = gql`
  query GetUserByEmailAndSource($email: String!, $source: String!) {
    getUserByEmailAndSource(email: $email, source: $source) {
      marketoPersonId
      dateCreated
      accountId
      role
      lastName
      userId
      source
      email
      userStatus
      firstName
      dateUpdated
      mailbox
      mailboxFQDN
    }
  }
`;
