import React, { useState, useEffect, useCallback } from 'react';
import { Center } from '@companydotcom/potion';
import { useSource } from '@companydotcom/providers';
import { useLocation } from 'react-router-dom';
import { VerifyEmailPage } from './verify-email-page';
import { useAuth } from '../../../providers';
import { useResendEmailActivationMutation } from '../../../services/auth/auth-api';

// import { resendEmailActivation } from '../../../services/auth/old-api/auth-svc';
// const sendVerification = async (email: string) => {
//   const res = await resendEmailActivation(email);

//   if (res && res.message === 'Email sent successfully') {
//     throw new Error('Verification email failed to send.');
//   }
//   return res;
// };

interface LocationStateProps {
  email?: string;
}

interface VerifyEmailProps {
  productName?: string;
  triggerImmediately?: boolean;
  hideShadow?: boolean;
}

export const VerifyEmail = ({ productName, triggerImmediately, hideShadow }: VerifyEmailProps) => {
  const source = useSource();
  const location = useLocation();
  const state = location?.state as LocationStateProps;
  const auth0 = useAuth();
  const [email, setEmail] = useState(state?.email ?? undefined);
  const [cpFirstTime, setCpFirstTime] = useState<number | string | undefined>(undefined);
  const [resendEmailActivation, { isLoading, isSuccess }] = useResendEmailActivationMutation();

  useEffect(() => {
    if (auth0?.user) {
      setEmail(auth0.user.email);
    } else {
      auth0?.refreshUser();
    }
  }, [auth0]);

  // const [isSending, setIsSending] = useState(false);
  // const [isResent, setIsResent] = useState(false);

  const resendVerification = useCallback(async () => {
    if (!isLoading && email) {
      // setIsSending(true);
      if (cpFirstTime === '1') {
        await resendEmailActivation({ email });
        // setIsResent(true);
      } else {
        setCpFirstTime(1);
        await resendEmailActivation({ email }).catch(err => console.error(err));
      }
      // setIsSending(false);
    }
  }, [cpFirstTime, email, isLoading, resendEmailActivation]);

  useEffect(() => {
    if (triggerImmediately && !cpFirstTime) {
      resendVerification();
    }
  }, [resendVerification, triggerImmediately, cpFirstTime]);

  return email ? (
    <Center
      className="verify-email"
      flexDirection="column"
      maxWidth={[null, '2xl']}
      px={[0, 4]}
      layerStyle={hideShadow ? undefined : { base: undefined, sm: 'formContainer' }}
    >
      <VerifyEmailPage
        isSending={isLoading}
        isResent={isSuccess}
        productName={productName}
        source={source}
        email={email}
        resendVerification={resendVerification}
      />
    </Center>
  ) : null;
};
