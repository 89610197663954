import gql from 'graphql-tag';

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($id: String, $handled: Int, $dateExpires: Int) {
    updateNotification(input: { id: $id, handled: $handled, dateExpires: $dateExpires }) {
      id
      body
      notificationType
      handled
      dateExpires
      dateCreated
    }
  }
`;

export const SUBSCRIBE_TO_NOTIFICATIONS_MUTATION = gql`
  mutation OnCreateNotification($userId: String!, $notificationType: NotificationType!) {
    createNotification(input: { userId: $userId, notificationType: $notificationType }) {
      id
      body
      productId
      notificationType
      deliveryType
      dateCreated
      handled
      userId
      requestId
    }
  }
`;
