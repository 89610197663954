const parts = ['container', 'header', 'title', 'day', 'navIcon'];

function baseStyleDay(props: Record<string, any>) {
  const { colorScheme: c } = props;
  return {
    _selected: {
      backgroundColor: `${c}.500`,
      _hover: {
        backgroundColor: `${c}.600`,
      },
    },
  };
}
const baseStyle = (props: Record<string, any>) => ({
  container: {
    border: '1px solid',
    borderColor: 'gray.100',
    shadow: 'md',
  },
  header: {
    backgroundColor: 'white',
    borderBottom: 'none',
  },
  title: {
    fontWeight: 'bold',
  },
  day: baseStyleDay(props),
  navIcon: { color: 'black' },
});

const sizes = {
  sm: {
    title: { fontSize: 'md' },
    day: { width: '1.7rem', lineHeight: '1.7rem' },
  },
  md: {
    title: { fontSize: 'lg' },
    day: { width: '2.625rem', lineHeight: '2.625rem', fontSize: 'lg' },
  },
};

const defaultProps = {
  size: 'sm',
  colorScheme: 'blue',
};

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
