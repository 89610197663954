import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Box, Text, Button, Heading, Center } from '@companydotcom/potion';
import {
  InputField,
  useGetCurrentlySelectedCountry,
  PhoneNumberInputField,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import type { GmbSharedProps } from './gmb-profile-flow';
import yup from '../../../lib/yup';

const getGmbCreateUserProfileSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('common.inputs.firstName.error'),
    lastName: yup.string().required('common.inputs.lastName.error'),
    phone: yup.string().phone().nullable(),
  });

export const GmbCreateUserProfile: React.FC<GmbSharedProps> = ({
  globalUser,
  setFormValues,
  formValues,
  nextStep,
}) => {
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();

  const defaultValues: FieldValues = {
    firstName: formValues?.firstName || globalUser?.firstName || '',
    lastName: formValues?.lastName || globalUser?.lastName || '',
    phone: formValues?.phone
      ? parsePhoneNumber(formValues?.phone, country).number
      : globalUser?.phone
      ? parsePhoneNumber(globalUser?.phone, country).number
      : '',
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getGmbCreateUserProfileSchema()),
    defaultValues,
  });
  useEffect(() => {
    if (globalUser) {
      reset({
        firstName: formValues?.firstName || globalUser?.firstName || '',
        lastName: formValues?.lastName || globalUser?.lastName || '',
        phone: formValues?.phone
          ? parsePhoneNumber(formValues?.phone, country).number
          : globalUser?.phone
          ? parsePhoneNumber(globalUser?.phone, country).number
          : '',
      });
    }
  }, [reset, globalUser, country, formValues]);
  const { t } = useTranslation();

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      if (country) {
        data.phone = getFormattedPhoneNumber(data.phone, country);
      }
      setFormValues?.(prevState => ({ ...prevState, ...data }));
      nextStep?.();
    } catch (err) {
      console.log('Error!', err);
    }
  };

  return (
    <Center
      className="gmb-createUserProfile__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Heading as="h1" size="hs-xl">
        {t('gmbProfileFlow.stepOne.heading')}
      </Heading>
      <Heading as="h2" size="hs-md" mt={[5]}>
        {t('gmbProfileFlow.stepOne.subheading')}
      </Heading>
      <Text textStyle="md" mt={4}>
        {t('common.misc.allFieldsRequired')}
      </Text>
      <Box
        as="form"
        className="gmb-createUserProfile__form"
        maxWidth={430}
        width="full"
        mt={[8, 10]}
      >
        <InputField
          className="gmb-createUserProfile__firstNameField"
          data-test="gmb-createUserProfile__firstNameField"
          register={register}
          name="firstName"
          label={t('common.inputs.firstName.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        />

        <InputField
          className="gmb-createUserProfile__lastNameField"
          data-test="gmb-createUserProfile__lastNameField"
          register={register}
          name="lastName"
          label={t('common.inputs.lastName.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        />
        <PhoneNumberInputField
          name="phone"
          label={t('common.inputs.phoneNumber.label')}
          className="gmb-createUserProfile__phoneField"
          data-test="form-createUserProfile__phoneField"
          control={control}
          country={country}
          onCountryChange={onCountryChange}
          errors={errors}
        />

        <Box textAlign="center" mt={[8, 12]}>
          <Button
            className="gmb-createUserProfile__submitButton"
            data-test="gmb-createUserProfile__submitButton"
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >
            {t('gmbProfileFlow.stepOne.submitButton')}
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
