import gql from 'graphql-tag';

export const SUBSCRIBE_TO_NOTIFICATIONS_DOCUMENT = gql`
  subscription OnCreateNotification($userId: String!, $requestId: String) {
    onCreateNotification(userId: $userId, requestId: $requestId) {
      id
      body
      productId
      notificationType
      deliveryType
      dateCreated
      handled
      userId
      requestId
    }
  }
`;

// this could also be a mutation that invalidates tags, as RTK query does not inherently support Graphql subscriptions
