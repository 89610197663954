import React from 'react';
import {
  useDisclosure,
  IconButton,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  FormControl,
  FormLabel,
  Switch,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { TileStateSwitcher } from './tile-state-switcher';
import { useTile, useGroups } from '../../../providers';

export const TileSettings = ({ refreshUserTileData }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tile = useTile();
  const groups = useGroups();
  const { t } = useTranslation();

  // Hide Tile settings for showing tiles based on number of groups.
  // If only one group, hide
  return groups.length > 1 || process.env.REACT_APP_DEBUG_MODE_ENABLED === 'true' ? (
    <Menu>
      <MenuButton
        as={IconButton}
        position="absolute"
        right={2}
        top={2}
        aria-label="Open tile settings"
        variant="ghost"
        size="sm"
        icon={<Icon as={FontAwesomeIcon} icon={faEllipsis} boxSize={5} />}
        className="potion-tileGroup__settings-btn"
        onClick={isOpen ? onClose : onOpen}
      />
      <MenuList>
        <MenuItem>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="showOnTile" mb="0">
              {t('tiles.showOnDashboard')}
            </FormLabel>
            <Switch defaultChecked={!tile?.isHidden} id="showOnTile" />
          </FormControl>
        </MenuItem>
        {process.env.REACT_APP_DEBUG_MODE_ENABLED === 'true' && (
          <TileStateSwitcher refreshUserTileData={refreshUserTileData} />
        )}
      </MenuList>
    </Menu>
  ) : null;
};
