import React, { useState } from 'react';
import {
  StylesProvider,
  IconButton,
  ButtonGroup,
  Center,
  Text,
  Flex,
  createContext,
  useMultiStyleConfig,
  Tabs,
  useTabStepper,
  TabPanels,
  Steps,
  CSSObject,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import type { TileComponentProps } from '../../types';

export function useCarousel() {
  const [disableCarousel, setDisableCarousel] = useState(false);

  return {
    /**
     * Disables the entire carousel
     */
    disableCarousel,
    /**
     * Set the state of the carousel disabled status
     */
    setDisableCarousel,
  };
}

export type CarouselReturn = ReturnType<typeof useCarousel>;

export const [CarouselProvider, useCarouselContext] = createContext<CarouselReturn>({
  name: 'CarouselContext',
  errorMessage:
    'useCarouselContext: `context` is undefined. Seems you forgot to wrap all Carousel components within <TileCarousel />',
});

export interface TileCarouselProps extends TileComponentProps {
  viewLabels?: string[];
}

export const TileCarousel: React.FC<TileCarouselProps> = props => {
  const { children = [], viewLabels } = props;
  const styles = useMultiStyleConfig('TileCarousel', props);

  const ctx = useCarousel();

  const context = React.useMemo(() => ctx, [ctx]);
  const { tabIndex, handleTabsChange, nextStep, previousStep, isFirstStep, isLastStep } =
    useTabStepper({ steps: children as Steps[], scrollOnStepChange: false });

  return (
    <CarouselProvider value={context}>
      <StylesProvider value={styles}>
        <Flex className="tile__carousel" flexDirection="column" flexGrow={1} sx={styles.container}>
          <Tabs
            index={tabIndex}
            onChange={handleTabsChange}
            width="full"
            display="flex"
            flexDirection="column"
            flex={1}
          >
            <CarouselControls
              nextStep={nextStep}
              previousStep={previousStep}
              isFirstStep={isFirstStep}
              isLastStep={isLastStep}
              labels={viewLabels}
              activeStep={tabIndex}
              styles={styles}
            />
            <TabPanels height="100%" width="full">
              {children}
            </TabPanels>
          </Tabs>
        </Flex>
      </StylesProvider>
    </CarouselProvider>
  );
};

interface CarouselControlsProps {
  nextStep: () => void;
  previousStep: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  activeStep: number;
  labels?: string[];
  styles?: Record<string, CSSObject>;
}

const CarouselControls: React.FC<CarouselControlsProps> = props => {
  const {
    labels = [],
    nextStep,
    previousStep,
    isFirstStep,
    isLastStep,
    activeStep,
    styles,
  } = props;

  return (
    <Center className="tile__carousel-controls" mb={4}>
      <ButtonGroup size="xs" variant="unstyled">
        <Center>
          <IconButton
            isDisabled={isFirstStep}
            onClick={previousStep}
            aria-label="Previous slide"
            icon={<FontAwesomeIcon icon={faAngleLeft} sx={styles?.icons} />}
            sx={styles?.icons}
          />
          <Text className="tile__carousel-label" textStyle="xs" mx={3} sx={styles?.label}>
            {labels[activeStep]}
          </Text>
          <IconButton
            isDisabled={isLastStep}
            onClick={nextStep}
            aria-label="Next slide"
            icon={<FontAwesomeIcon icon={faAngleRight} sx={styles?.icons} />}
            sx={styles?.icons}
          />
        </Center>
      </ButtonGroup>
    </Center>
  );
};
