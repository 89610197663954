import React from 'react';
import { Box, potion } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faClose } from '@fortawesome/pro-light-svg-icons';

export interface CloseButtonLayoutProps {
  /** Back button handler.  Usually paired with useNavigate or useTabStepper hook */
  onBackClick: () => void;
  /** Chooses when to display the entire layout */
  isDisplayed?: boolean;
  children: React.ReactNode;
}

export const CloseButtonLayout = ({
  onBackClick,
  isDisplayed = true,
  children,
}: CloseButtonLayoutProps) => {
  return isDisplayed ? (
    <Box position="relative">
      <potion.button
        alignSelf="flex-end"
        aria-label="Close page and go back"
        onClick={onBackClick}
        position="absolute"
        right={['4', null, '10']}
        top={['4', null, '10']}
      >
        <FontAwesomeIcon color="pink.500" boxSize={{ base: '32px', md: '64px' }} icon={faClose} />
      </potion.button>

      {children}
    </Box>
  ) : (
    <>{children}</>
  );
};
