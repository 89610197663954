import _get from 'lodash/get';
import { Source, SourceConfig } from '@companydotcom/types';
import { withCache } from '@companydotcom/helpers';
import { companyQL } from '@companydotcom/services';
import sourceQuery from './source-query-old';

const isEnabled = process.env.REACT_APP_CACHE_ENABLED === 'true' || false;

export const getSource = withCache<Source>(
  async (sourceId: string, locale: string = 'en') => {
    const res = await companyQL.query({
      query: sourceQuery.getSource(sourceId, locale),
    });
    const source = _get(res, 'data.getSource');
    return source;
  },
  'source',
  undefined,
  isEnabled,
);

export const getSourceURI = withCache<string>(
  async (uri: string) => {
    const res = await companyQL.query({
      query: sourceQuery.getSourceURI(uri),
    });
    return _get(res, 'data.getSourceURI.sourceId');
  },
  'sourceURI',
  undefined,
  isEnabled,
);

// export const getPaymentForm = async (sourceId: string, isLocal = false) => {
//   try {
//     const res = await companyQL.query({
//       query: sourceQuery.getPaymentForm(sourceId, isLocal),
//     });

//     return res.data.getPaymentForm || null;
//   } catch (err) {
//     console.error(err);
//     return err;
//   }
// };

// export const getProductRatePlan = async (accountId: string, productId: string, locale: string = 'en') => {
//   const res = await companyQL.query({
//     query: sourceQuery.getProductRatePlan(accountId, productId, locale),
//   });
//   return res.data.getProductRatePlan;
// };

export const getSourceConfig = withCache<SourceConfig>(
  async (sourceId: string) => {
    const res = await companyQL.query({
      query: sourceQuery.getSourceConfig(sourceId),
    });

    return res.data.getSourceConfig;
  },
  'externalIdentifier',
  undefined,
  isEnabled,
);

export interface Auth0ConfigInterface {
  authDomain: string;
  clientId: string;
  clientAudience?: string;
}

export const getAuth0Config = withCache<Auth0ConfigInterface>(
  async (source = 'company') => {
    const res = await companyQL.query({
      query: sourceQuery.getAuth0Config(source),
    });
    return res.data.getAuth0Config;
  },
  'externalIdentifier',
  undefined,
  isEnabled,
);
// export const getContactInformation = async (sourceId: string) => {
//   const res = await companyQL.query({
//     query: sourceQuery.getContactInformation(sourceId),
//   });
//   return res;
// };
