import * as React from 'react';
import { GlobalStyle, ThemeProvider, ThemeProviderProps } from '../system';
import { Dict } from '../utils';
import CSSReset from '../components/css-reset';
import OldTheme from '../theme';
import { IdProvider } from '../hooks';

export interface PotionProviderProps extends Pick<ThemeProviderProps, 'cssVarsRoot'> {
  /**
   * a theme. if omitted, uses the default theme provided by coco
   */
  theme?: Dict;

  /**
   * If `true`, `CSSReset` component will be mounted to help
   * you reset browser styles
   *
   * @default true
   */
  resetCSS?: boolean;
  /**
   * Your application content
   */
  children?: React.ReactNode;
}

/**
 * The global provider that must be added to make all Coco components
 * work correctly
 */
export const PotionProvider = (props: PotionProviderProps) => {
  const { children, resetCSS = true, theme = OldTheme, cssVarsRoot } = props;

  return (
    <IdProvider>
      <ThemeProvider theme={theme} cssVarsRoot={cssVarsRoot}>
        {resetCSS && <CSSReset />}
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </IdProvider>
  );
};
