import * as React from 'react';
import {
  Global,
  Interpolation,
  ThemeContext,
  ThemeProvider as EmotionThemeProvider,
  ThemeProviderProps as EmotionThemeProviderProps,
} from '@emotion/react';
import { css, SystemStyleObject, toCSSVar, WithCSSVar } from '../styled-system';
import { Dict, memoizedGet as get, runIfFn, createContext } from '../utils';

export interface ThemeProviderProps extends EmotionThemeProviderProps {
  /**
   * The element to attach the CSS custom properties to.
   * @default ":host, :root"
   */
  cssVarsRoot?: string;
}

export const ThemeProvider = (props: ThemeProviderProps) => {
  const { cssVarsRoot = ':host, :root', theme, children } = props;
  const computedTheme = React.useMemo(() => toCSSVar(theme), [theme]);
  return (
    <EmotionThemeProvider theme={computedTheme}>
      <Global styles={(theme: any) => ({ [cssVarsRoot]: theme.__cssVars })} />
      {children}
    </EmotionThemeProvider>
  );
};

export function useTheme<T extends object = Dict>() {
  const theme = React.useContext(ThemeContext as unknown as React.Context<T | undefined>);
  if (!theme) {
    throw Error(
      'useTheme: `theme` is undefined. Seems you forgot to wrap your app in `<PotionProvider />` or `<ThemeProvider />`',
    );
  }

  return theme as WithCSSVar<T>;
}

const [StylesProvider, useStyles] = createContext<Dict<SystemStyleObject>>({
  name: 'StylesContext',
  errorMessage:
    'useStyles: `styles` is undefined. Seems you forgot to wrap the components in `<StylesProvider />` ',
});
export { StylesProvider, useStyles };

/**
 * Applies styles defined in `theme.styles.global` globally
 * using emotion's `Global` component
 */
export const GlobalStyle = () => {
  return (
    <Global
      styles={(theme: any) => {
        const styleObjectOrFn = get(theme, 'styles.global');
        const globalStyles = runIfFn(styleObjectOrFn, { theme });
        if (!globalStyles) return undefined;
        const styles = css(globalStyles)(theme);
        // eslint-disable-next-line
        return styles as Interpolation<{}>;
      }}
    />
  );
};
