import React from 'react';
import { Text, Box, Link, Button, Icon, useStyleConfig } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faCalendarPlus as faCalendarPlusRegular } from '@fortawesome/pro-regular-svg-icons';
import type { TileComponentProps } from '../../types';

export interface TileTextProps extends TileComponentProps {}

export const TileText: React.FC<TileTextProps> = props => {
  const { children, variant, text, componentTheme } = props;
  const styles = useStyleConfig('TileText');

  const textStyles = {
    textAlign: 'center',
    paddingX: 4,
    ...styles,
    ...componentTheme,
  };
  const textLinkStyles = {
    textAlign: 'center',
    display: 'inline',
    padding: 0,
    ...styles,
  };

  return (
    <>
      {Array.isArray(children) ? (
        <Box mb="auto" className="tileComponent__textContainer" sx={textStyles}>
          {children.map((child: any, idx: number) => {
            return child.variant === 'anchor' ? (
              <Link key={idx} href={child.href} isExternal>
                {child.text}
              </Link>
            ) : (
              <Text className="tileComponent__text" textStyle="md" key={idx}>
                {child.text}
              </Text>
            );
          })}
        </Box>
      ) : variant && variant === 'TileTextDate' ? (
        <Box mb="auto" className="tileComponent__textContainer">
          <Text sx={textLinkStyles}>
            {text}
            <Button
              size="md"
              background="none"
              padding={0}
              _hover={{
                background: 'none',
              }}
              rightIcon={
                <Icon
                  as={FontAwesomeIcon}
                  color="blue.500"
                  boxSize={4}
                  icon={faCalendarPlusRegular}
                />
              }
            />
          </Text>
        </Box>
      ) : (
        <Text
          className="tileComponent__text"
          textStyle="md"
          sx={{ ...componentTheme, ...textLinkStyles }}
        >
          {text}
        </Text>
      )}
    </>
  );
};
