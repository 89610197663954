import { PaymentInfoPayload } from './../../../../services/acg/acg.types';
import valid from 'card-validator';

export const getSavePaymentPayload = (values: any): Partial<PaymentInfoPayload> => {
  const cardType = valid.number(values.cardDetails?.cardNumber)?.card?.type.split('-').join(' ');
  const cardExpiry = `${values?.cardDetails?.expirationYear}-${values?.cardDetails?.expirationMonth}`;

  const billingAddressData = {
    country: values?.billingDetails?.country,
    addressLine1: values?.billingDetails?.addressLine1,
    addressLine2: values?.billingDetails?.addressLine2,
    city: values?.billingDetails?.city,
    state: values?.billingDetails?.state,
    postalCode: values?.billingDetails?.postalCode,
    paymentNickName: values?.cardNickname,
  };

  if (values.paymentMethod === 'credit card') {
    return {
      paymentMethodType: 'credit card',
      cardNumber: values.cardDetails?.cardNumber?.slice(-4),
      cardHolderName: values?.cardDetails?.cardHolderName,
      cardType,
      cardExpiry,
      customFields: {
        preferred: values?.makeDefault === true && !values?.autoRenew ? '1' : '0',
      },
      ...billingAddressData,
    };
  }
  return {
    paymentMethodType: 'electronic check',
    accountHolderName: values?.achDetails?.accountHolderName,
    accountNumber: values?.achDetails?.accountNumber?.slice(-4),
    accountType: values?.achDetails?.accountType,
    customFields: {
      preferred: values?.makeDefault === true && !values?.autoRenew ? '1' : '0',
      account_type: values?.achDetails?.accountType,
    },
    ...billingAddressData,
  };
};
