import React from 'react';
import { Flex, Stat, StatNumber, StatLabel, HStack } from '@companydotcom/potion';
import { BarChart, Bar, XAxis, YAxis, Cell, Legend } from 'recharts';
import type { TileComponentProps } from '../../types';

export interface MainScore {
  label: string;
  value: number;
}

export interface Score {
  text: string;
  value: number;
}

export interface LinearProgressEventData {
  mainScore?: MainScore;
  scores?: Score[];
}

export interface LinearProgressProps extends TileComponentProps {
  chartData: LinearProgressEventData;
}

export const LinearProgress: React.FC<LinearProgressProps> = props => {
  const {
    chartData: { scores, mainScore },
  } = props;

  const getColor = (p: number) => {
    if (p >= 90) {
      return 'green';
    }
    if (p >= 60) {
      return 'orange';
    }
    if (p < 60) {
      return 'red';
    }
    return 'gray.500';
  };

  return (
    <Flex
      className="tile__chart-linear-progress"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex={1}
    >
      <Stat flex={0} mb={2}>
        <HStack>
          <StatNumber
            color={
              mainScore?.value || mainScore?.value === 0 ? getColor(mainScore?.value) : 'gray.500'
            }
          >
            {Number.isNaN(mainScore?.value) || mainScore?.value === 0 || !mainScore?.value
              ? `0%`
              : `${Math.trunc(mainScore?.value)}%`}
          </StatNumber>
          <StatLabel color="#4c6372">{mainScore?.label}</StatLabel>
        </HStack>
      </Stat>
      <BarChart
        width={268}
        height={72}
        data={scores}
        layout="vertical"
        maxBarSize={100}
        margin={{
          top: 0,
          right: 40,
          left: 4,
          bottom: -32,
        }}
      >
        <XAxis
          type="number"
          axisLine={false}
          domain={[0, 100]}
          min={0}
          max={100}
          strokeWidth={0}
          dataKey="value"
          tick={false}
        />
        <YAxis
          width={104}
          type="category"
          dataKey="text"
          textAnchor="end"
          interval={0}
          minTickGap={0}
          axisLine={false}
          strokeWidth={0}
          style={{
            fontSize: '13px',
          }}
        />
        <Bar
          barSize={8}
          dataKey="value"
          strokeWidth={0}
          radius={6}
          background={{ fill: '#d8d8d8', radius: 6 }}
        >
          {scores?.map((entry: any, index: number) => (
            <Cell key={index} fill={Number.isNaN(entry.value) ? 'gray' : getColor(entry.value)} />
          ))}
        </Bar>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          fontSize="14px"
          iconSize={0}
          wrapperStyle={{
            right: -6,
          }}
          payload={scores?.map(item => ({
            id: item.text,
            type: 'circle',
            value: `${Math.trunc(item.value)}%`,
            iconSize: 0,
            color: getColor(item.value),
          }))}
        />
      </BarChart>
    </Flex>
  );
};
