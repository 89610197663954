import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from '../../../../hooks';
import { useGetRegisteredEventQuery } from '../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { AddGuest } from '../../acg-add-guest';
import { selectActiveEvent } from '../my-events-slice';
import { EventForPurchaseGuest, EventForRegistration } from '../../../../services/acg/acg.types';

export const MyEventsAddGuest = () => {
  const activeEvent = useAppSelector(selectActiveEvent);
  const { data: globalUser } = useGetGlobalUserQuery();
  const {
    data: registeredEvent,
    isLoading,
    isError,
  } = useGetRegisteredEventQuery(
    activeEvent?.id && globalUser
      ? {
          user: globalUser,
          registrationId: activeEvent?.id,
        }
      : skipToken,
  );

  const createEventForPurchase = (
    _formData?: Record<string, any>,
    eventForRegistration?: EventForRegistration,
    guestsToRegister?: EventForPurchaseGuest[],
  ) => {
    const showWaitListAlert =
      eventForRegistration?.hasWaitList &&
      eventForRegistration?.totalRegistered &&
      guestsToRegister?.length
        ? eventForRegistration?.hasWaitList &&
          eventForRegistration.totalRegistered >= eventForRegistration.eventCapacity
        : false;

    return {
      isMyEventsFlow: true,
      showWaitListAlert,
      waitListedRegistrants: eventForRegistration?.totalWaitList,
      eventCapacity: eventForRegistration?.eventCapacity,
      totalRegistered: eventForRegistration?.totalRegistered,
      eventRecordKey: eventForRegistration?.eventKey,
      registrant: {
        registrationKey: registeredEvent?.registrationId,
      },
      guests: guestsToRegister,
      registrantType: eventForRegistration?.registrantAndFees?.[0]?.registrantType,
    };
  };

  if (!activeEvent || !registeredEvent?.eventId) {
    return null;
  }

  return (
    <AddGuest
      variant="my-events"
      eventId={registeredEvent?.eventId}
      wrapperStyles={{ bg: ['transparent', 'white'] }}
      isLoading={isLoading}
      isError={isError}
      createEventForPurchaseHandler={createEventForPurchase}
    />
  );
};
