import { Mid } from '@companydotcom/types';
import { NavigateFunction } from 'react-router-dom';
// import { changeEnrollmentIdForUser } from '../../../../services/user/old-api/user-svc';

export const changeExternalMid = async (
  selectedMid: Mid,
  completeStatusMid: Mid,
  auth: any,
  sourceId: string,
  changeEnrollmentIdForUser: any,
) => {
  await changeEnrollmentIdForUser({
    enrollmentId: selectedMid.enrolmentId,
    oldEnrollmentId: completeStatusMid.enrolmentId,
  });
  // if selected source is same with preselected mid source then send null as logic already exist in logout else send source
  auth.logout(selectedMid.source === sourceId ? null : selectedMid.source);
};

/**
 *  call changeEnrollmentIdForUser in every case.
 *  if selected mid is different from pre selected mid then send to logout screen.
 */

export const activateUserProcess = async (
  selectedMid: Mid,
  completeStatusMid: Mid,
  sourceId: string,
  navigate: NavigateFunction,
  changeEnrollmentIdForUser: any,
) => {
  if (
    selectedMid.externalIdentifier === completeStatusMid.externalIdentifier &&
    sourceId === selectedMid.source
  ) {
    await changeEnrollmentIdForUser({
      enrollmentId: selectedMid.enrolmentId,
      oldEnrollmentId: completeStatusMid.enrolmentId,
    });
    navigate('/');
  } else {
    navigate(1);
  }
};
