import React from 'react';
import { useTranslation } from 'react-i18next';
import { Center, Image, Text, Heading } from '@companydotcom/potion';
import { Error401, Error404, Error421 } from './ErrorTypes';

const { REACT_APP_IMG_URL } = process.env;

const Errors = {
  401: Error401,
  404: Error404,
  421: Error421,
};

interface GeneralErrorProps {
  statusCode: keyof typeof Errors;
}

export const GeneralError = ({ statusCode }: GeneralErrorProps) => {
  const { t } = useTranslation();
  return (
    <Center
      className={`subpage__generalError${statusCode}`}
      flexDirection="column"
      maxWidth={704}
      width="full"
      mx="auto"
      layerStyle="formContainer"
      py={[12, null, 14]}
    >
      <Image
        className="error__icon"
        alt="error icon"
        src={`${REACT_APP_IMG_URL}/dashboard_nextgen/company/images/error.svg`}
      />
      <Heading size="hs-2xl" my={8}>
        {t('subpage.generalError.oops')}
      </Heading>
      {{
        401: <Error401 />,
        404: <Error404 />,
        421: <Error421 />,
      }[statusCode] || <Text color="red.500">{t('subpage.generalError.somethingWentWrong')}</Text>}
    </Center>
  );
};
