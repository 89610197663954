import React from 'react';
import { Image } from '@companydotcom/potion';
import type { TileComponentProps } from '../../types';

export interface TileImageProps extends TileComponentProps {}

export const TileImage: React.FC<TileImageProps> = props => {
  const { text, componentTheme, imageUrl } = props;
  //@ts-ignore
  return <Image className="tile-image" src={text ?? imageUrl ?? ''} sx={componentTheme} />;
};
