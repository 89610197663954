import React from 'react';
import { WrapItem, VStack, Text, HStack, Flex, Wrap } from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import type { TileComponentProps } from '../../types';

export interface ContentProps {
  statuses: {
    label: string;
    quantity?: string | number;
    dataForNoOfDays?: number;
  }[];
}

export interface TileSocialMediaMarketingContentProps extends TileComponentProps {}

export const TileSocialMediaMarketingContent: React.FC<
  TileSocialMediaMarketingContentProps
> = props => {
  const { eventData } = props;
  const { data } = useEventData<ContentProps>(eventData);
  return (
    <Flex className="tile__socialMediaMarketingContent" flex={1} px={4}>
      <Wrap spacing={4} mx="auto" align="center" justify="center">
        {data?.statuses
          ?.filter(
            status => status?.label?.includes('Scheduled') || status?.label?.includes('Failed'),
          )
          .map(item => (
            <StatusCard
              key={item.label}
              label={item.label}
              quantity={item.quantity}
              dataForNoOfDays={item.dataForNoOfDays}
            />
          ))}
      </Wrap>
    </Flex>
  );
};

interface StatusCardProps {
  label?: string;
  quantity?: string | number;
  dataForNoOfDays?: number;
}

function StatusCard({ label, quantity, dataForNoOfDays }: StatusCardProps) {
  function renderIcon(data?: string) {
    switch (data) {
      case 'Scheduled':
        return <ScheduledIcon width={36} />;
      case 'Pending':
        return <PendingIcon width={36} />;
      case 'Rejected':
        return <RejectedIcon width={36} />;
      case 'Failed':
        return <FailedIcon width={36} />;
      default:
        return null;
    }
  }
  return (
    <WrapItem className="status-card" boxShadow="md" borderRadius="sm" py={2} px={4} width="full">
      <VStack align="flex-start">
        <Text textStyle="sm">
          {label === 'Scheduled'
            ? `${label} posts for next ${dataForNoOfDays} days:`
            : `${label} posts from last ${dataForNoOfDays} days:`}
        </Text>
        <HStack mt={1} justifyContent="center" alignItems="center">
          {renderIcon(label)}
          {quantity?.toString() ? (
            <Text textStyle="xl" fontWeight="bold" ml={2}>
              {quantity}
            </Text>
          ) : (
            <Text textStyle="md" fontWeight="bolder" color="red.500" ml={2}>
              !
            </Text>
          )}
        </HStack>
      </VStack>
    </WrapItem>
  );
}

function RejectedIcon({ width = 36, height = 36, color = '#084066' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36">
      <title>RejectedIcon_SMM</title>
      <g fill="none" fillRule="evenodd">
        <path
          fill={color}
          fillRule="nonzero"
          d="M18.758 20.25l.27-7.53H16.62l.27 7.53zM17.82 23.767c.385 0 .692-.114.922-.345.23-.23.345-.512.345-.847 0-.34-.114-.624-.345-.851-.23-.228-.537-.341-.922-.341s-.691.113-.919.34a1.155 1.155 0 00-.341.852c0 .335.114.617.341.848.228.23.534.345.919.345z"
        />
        <circle stroke={color} strokeWidth={2} cx={18} cy={18} r={11} />
      </g>
    </svg>
  );
}

function PendingIcon({ width = 36, height = 36, color = '#084066' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36">
      <title>PendingIcon_SMM</title>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" stroke={color} strokeWidth={2}>
        <path d="M25.051 10.36A10.89 10.89 0 0018 7.779c-6.075 0-11 4.974-11 11.11C7 25.026 11.925 30 18 30s11-4.975 11-11.111M21.52 11.778l3.52-1.334M24.676 6.665l.364 3.779" />
      </g>
    </svg>
  );
}

function FailedIcon({ width = 36, height = 36, color = '#084066' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36">
      <title>FailedIcon_SMM</title>
      <g fill="none" fillRule="evenodd" stroke={color} strokeWidth={2}>
        <circle cx={18} cy={18} r={11} />
        <path strokeLinecap="round" d="M14.16 14.16l7.68 7.68M21.84 14.16l-7.68 7.68" />
      </g>
    </svg>
  );
}

function ScheduledIcon({ width = 36, height = 36, color = '#084066' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36">
      <title>ScheduledIcon_SMM</title>
      <g fill="none" fillRule="evenodd" stroke={color} strokeWidth={2}>
        <circle cx={18} cy={18} r={11} />
        <path strokeLinecap="round" d="M21.36 18.48l-4.32 1.44M19.44 11.76l-2.4 8.16" />
      </g>
    </svg>
  );
}
