import React from 'react';
import { VisaIcon, MastercardIcon, AmexIcon } from '@companydotcom/ui';

export const CCIconSwitch: React.FC<any> = cardObject => {
  switch (cardObject.type || cardObject.cardType) {
    case 'visa':
      return <VisaIcon boxSize={9} />;
    case 'mastercard':
      return <MastercardIcon boxSize={9} />;
    case 'american express':
      return <AmexIcon boxSize={9} />;
    case 'american-express':
      return <AmexIcon boxSize={9} />;
    default:
      return null;
  }
};
