import { useMemo } from 'react';
import {
  Box,
  Text,
  Button,
  Divider,
  Skeleton,
  ButtonGroup,
  Alert,
  AlertDescription,
  AlertIcon,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faUsers, faFileInvoice } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import { Guest, InvoiceData } from '../../../../services/acg/acg.types';

import {
  useGetAcgQueryArgs,
  useGetInvoicesByUserV2Query,
} from '../../../../services/acg/acg-api-v2';
import { AcgInvoice, Maybe } from '@companydotcom/types';

export interface EventsTitleProps {
  eventName?: string;
  viewAttendeeListHandler?: () => void;
  showInvoiceButton?: boolean;
  showAttendeeList?: boolean;
  showDivider?: boolean;
  invoiceCode?: string;
  isLoading?: boolean;
  eventDate?: string;
  onWaitList?: boolean;
  guestData?: Guest[];
  isEventPassed?: boolean;
}

export const EventsTitle = ({
  showInvoiceButton,
  showAttendeeList,
  viewAttendeeListHandler,
  showDivider,
  eventName,
  eventDate,
  isLoading,
  invoiceCode,
  onWaitList,
  guestData,
  isEventPassed,
}: EventsTitleProps) => {
  const navigate = useNavigate();
  const args = useGetAcgQueryArgs();
  const { data: invoiceData } = useGetInvoicesByUserV2Query({ ...args });

  // TODO: RETYPE THIS
  const hashMapForMultiInvoice = (arr: (Maybe<AcgInvoice> | undefined)[] | undefined) => {
    if (!arr?.length) {
      return arr;
    }
    const hash = {};
    const dataCopy: any[] = [];
    arr?.forEach((inv, i: number) => {
      dataCopy[i] = { ...inv };
      if (hash[inv?.invoiceNumber!] >= 1) {
        dataCopy[i].invoiceNumber = `${inv?.invoiceNumber}-${hash[inv?.invoiceNumber!]}`;
        hash[inv?.invoiceNumber!]++;
      } else {
        hash[inv?.invoiceNumber!] = 1;
      }
    });
    return dataCopy;
  };

  const allEventInvoices = useMemo(() => {
    const mainRegistrantInvoice = invoiceData?.invoices?.filter?.(
      (i: any) => i.invoiceNumber === invoiceCode,
    );
    const guestInvoices = guestData?.flatMap(guest => {
      const result = invoiceData?.invoices?.filter?.(
        (i: any) => i.invoiceNumber === guest.fees?.[0]?.priceKey,
      );
      return result;
    });
    // Finds invoices with the same code and refigures them
    if (
      (guestInvoices && guestInvoices?.length > 1) ||
      (mainRegistrantInvoice && mainRegistrantInvoice?.length > 1)
    ) {
      return hashMapForMultiInvoice(mainRegistrantInvoice)?.concat(
        hashMapForMultiInvoice(guestInvoices) || [],
      );
    }

    if (guestInvoices?.length && mainRegistrantInvoice?.length) {
      const total = [...mainRegistrantInvoice, ...guestInvoices];
      return total;
    }
    return mainRegistrantInvoice;
  }, [guestData, invoiceData, invoiceCode]);

  const invoiceHandler = (selectedInv?: InvoiceData) => {
    navigate('/invoices', { state: { step: 'invoices-details', openInvoices: selectedInv } });
  };

  return (
    <>
      <Box width="full" textAlign={['center', 'left']}>
        <Text
          mt={[8]}
          lineHeight={1.25}
          textStyle={{ base: '2xl', md: '3xl' }}
          fontWeight="bold"
          opacity={0.7}
        >
          {eventName}
        </Text>
        <Skeleton isLoaded={!!eventDate}>
          <Text mt={3} fontWeight="semibold" textStyle="lg">
            {eventDate}
          </Text>
        </Skeleton>
        {(showInvoiceButton || showAttendeeList) && (
          <ButtonGroup spacing={6} mt={6} variant="link" size="sm">
            {showAttendeeList && (
              <Skeleton isLoaded={!isLoading}>
                <Button
                  textTransform="uppercase"
                  leftIcon={<FontAwesomeIcon boxSize={5} icon={faUsers} />}
                  onClick={viewAttendeeListHandler}
                >
                  View Attendee List
                </Button>
              </Skeleton>
            )}
            {showInvoiceButton && (
              <Skeleton isLoaded={!isLoading}>
                {allEventInvoices && allEventInvoices?.length > 1 ? (
                  allEventInvoices.map((inv: any, i: number) => {
                    return (
                      <Button
                        textTransform="uppercase"
                        leftIcon={<FontAwesomeIcon boxSize={5} icon={faFileInvoice} />}
                        onClick={() => invoiceHandler?.(inv)}
                        marginRight={6}
                        key={i}
                      >
                        {`Invoice ${inv?.invoiceNumber}`}
                      </Button>
                    );
                  })
                ) : (
                  <Button
                    textTransform="uppercase"
                    leftIcon={<FontAwesomeIcon boxSize={5} icon={faFileInvoice} />}
                    onClick={() => invoiceHandler?.(allEventInvoices?.[0])}
                  >
                    View Invoice
                  </Button>
                )}
              </Skeleton>
            )}
          </ButtonGroup>
        )}
        {onWaitList && !isEventPassed ? (
          <Alert status="warning" my={10}>
            <AlertIcon />
            <AlertDescription fontSize="sm" lineHeight="5">
              <Text fontWeight="bold">You’re on the Waitlist for this Event</Text>
              <Text>
                If you’re moved off the waitlist, you will be notified of this change and charged
                your registration.
              </Text>
            </AlertDescription>
          </Alert>
        ) : null}
      </Box>
      {showDivider && <Divider my={[8, 12]} />}
    </>
  );
};
