import React, { useState } from 'react';
import { Flex, Box, Text, Button, Heading, VStack, Center } from '@companydotcom/potion';
import * as yup from 'yup';
import { InputField, SelectField } from '@companydotcom/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useGroups } from '../../../providers';
// import { createByopTile } from '../../../services/tile/old-api/tile-service';
import { BuildingTileLoader } from './building-tile-loader';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { useCreateByopTileMutation } from '../../../services/tile/tile-api';

const AddAProductFormSchema = yup.object().shape({
  groupId: yup.string().required('forms.byop.inputs.groupId.error'),
  launchUrl: yup.string().required('forms.byop.inputs.launchUrl.error'),
  productDescription: yup.string().required('forms.byop.inputs.productDescription.error'),
  productName: yup.string().required('forms.byop.inputs.productName.error'),
});

const defaultValues = {
  launchUrl: '',
  productName: '',
  productDescription: '',
  groupId: '',
};

export interface AddAProductFormProps {
  onClose: () => void;
}

export const AddAProductForm = (props: AddAProductFormProps) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [createByopTile] = useCreateByopTileMutation();
  const { onClose } = props;
  const groups = useGroups();
  const [openLoader, setOpenLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit, watch, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddAProductFormSchema),
    defaultValues,
  });

  const groupOptions = groups.map((group: any) => {
    return {
      value: group.groupId,
      label: group.name,
    };
  });

  const { isSubmitting, isValid, errors } = formState;
  const watchProductName = watch('productName');

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      setIsLoading(true);
      setOpenLoader(true);

      if (globalUser) {
        await createByopTile({
          ...values,
          userId: globalUser?.userId,
          groupId: values.groupId,
        }).then(res => {
          const waitFor = (delay: number) => new Promise(resolve => setTimeout(resolve, delay));

          if (!res['error']) {
            waitFor(9000).then(() => {
              setIsLoading(false);
            });
          }
        });
      }
    } catch (err) {
      console.log('Error adding tile!', err);
    }
  };

  return (
    <Flex
      className="byop__addAProductForm"
      flexDirection="column"
      py={14}
      sx={{ textAlign: 'center' }}
    >
      {openLoader ? (
        <BuildingTileLoader
          onClose={onClose}
          productName={watchProductName}
          isLoading={isLoading}
        />
      ) : (
        <Box as="form">
          <Center mx="auto" flexDirection="column" textAlign="center" maxW="xl">
            <Heading size="hs-2xl" mb={4}>
              {t('forms.byop.heading')}
            </Heading>
            <Heading size="hs-md" mb={2}>
              {t('forms.byop.subheading')}
            </Heading>
            <Text textStyle="md">{t('forms.byop.caption')}</Text>
          </Center>
          <VStack
            my={14}
            spacing={6}
            flexDirection="column"
            width="full"
            maxWidth={430}
            justifyContent="center"
            alignItems="center"
            mx="auto"
          >
            <InputField
              register={register}
              name="launchUrl"
              label={t('forms.byop.inputs.launchUrl.label')}
              autoFocus
              errors={errors}
            />
            <InputField
              register={register}
              name="productName"
              label={t('forms.byop.inputs.productName.label')}
              errors={errors}
            />
            <InputField
              register={register}
              name="productDescription"
              label={t('forms.byop.inputs.productDescription.label')}
              errors={errors}
            />
            <SelectField
              register={register}
              name="groupId"
              label={t('forms.byop.inputs.groupId.label')}
              errors={errors}
            >
              {groupOptions.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </SelectField>
          </VStack>
          <Box>
            <Button
              type="submit"
              size="lg"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              variant="outline"
              isDisabled={!isValid || isSubmitting}
            >
              {t('forms.byop.submitButton')}
            </Button>
          </Box>
        </Box>
      )}
    </Flex>
  );
};
