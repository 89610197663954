import React from 'react';
import { Box, Alert, AlertTitle, AlertDescription, Link } from '@companydotcom/potion';
import { SubscriptionRatePlan, Maybe } from '@companydotcom/types';
import { useTranslation, Trans } from 'react-i18next';

interface RatePlanWarningMessagesProps {
  productName: string;
  freeUpgrade?: Maybe<boolean>;
  isEnterprise?: Maybe<boolean>;
  selectedPlan?: Maybe<SubscriptionRatePlan>;
  minTierPreselected?: Maybe<boolean>;
  quantityLabel?: Maybe<string>;
}

export const RatePlanWarningMessages = ({
  productName,
  freeUpgrade,
  isEnterprise,
  selectedPlan,
  minTierPreselected,
  quantityLabel,
}: RatePlanWarningMessagesProps) => {
  const { t } = useTranslation();
  return (
    <Box className="warning-message-container" maxWidth={728} width="full">
      {isEnterprise && (
        <Alert
          status="success"
          mt={1}
          mb={8}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertTitle mb={2}>{t('components.ratePlanOptions.enterprise.heading')}</AlertTitle>
          <AlertDescription textAlign="center" maxWidth={436}>
            <Trans
              i18nKey="components.ratePlanOptions.enterprise.message"
              values={{
                productName,
              }}
            >
              <Link href={`${process.env.REACT_APP_GRANDIO_URL}/contact-us`} isExternal>
                contact support
              </Link>
              to get set up for an enterprise plan of {productName}. We look forward to finding the
              right plan for you!
            </Trans>
          </AlertDescription>
        </Alert>
      )}
      {minTierPreselected && (
        <Alert
          status="warning"
          mt={1}
          mb={8}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertTitle mb={2}>
            {t('components.ratePlanOptions.minTierPreselected.heading')}
          </AlertTitle>
          <AlertDescription textAlign="center">
            {t('components.ratePlanOptions.minTierPreselected.message', {
              productName,
              qtyLabel: quantityLabel?.toLowerCase(),
            })}
          </AlertDescription>
        </Alert>
      )}
      {freeUpgrade && selectedPlan?.name && (
        <Alert
          status="success"
          mt={1}
          mb={8}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertTitle mb={2}>{t('components.ratePlanOptions.freeUpgrade.heading')}</AlertTitle>
          <AlertDescription textAlign="center">
            {t('components.ratePlanOptions.freeUpgrade.message', {
              productName,
              selectedPlan: selectedPlan?.name,
            })}
          </AlertDescription>
        </Alert>
      )}
    </Box>
  );
};
