import React, { useState, useEffect } from 'react';
import { Text } from '@companydotcom/potion';
import type { TileComponentProps } from '../../types';

export const PhoneNumberText = ({ text = '', componentTheme }: TileComponentProps) => {
  const [isFetching, setIsFetching] = useState(true);
  const [value, setValue] = useState<string[]>();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setValue(text?.split(':'));
      setIsFetching(false);
    }

    return () => {
      isMounted = false;
    };
  }, [text]);

  if (isFetching) return null;

  return (
    <Text className="tile__phoneNumberText" textStyle="md" sx={componentTheme}>
      {value?.[0]}:
      <Text as="span" textStyle="md" fontWeight="bolder" color="primary.300">
        {value?.[1]}
      </Text>
    </Text>
  );
};
