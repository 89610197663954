import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['profile', 'avatar', 'name', 'businessName'];

type Dict = Record<string, any>;

function baseStyleProfile(props: Dict) {
  const { isSideNavOpen } = props;
  return {
    color: 'white',
    mt: isSideNavOpen ? [4, null, 5] : 0,
    borderRadius: 'sm',
    padding: 2,
    _hover: {
      backgroundColor: 'blackAlpha.200',
    },
  };
}

const baseStyleAvatar: SystemStyleObject = {};

const baseStyleName: SystemStyleObject = {
  color: 'inherit',
};

const baseStyleBusinessName: SystemStyleObject = {
  color: 'inherit',
};

const baseStyle = (props: Dict) => ({
  profile: baseStyleProfile(props),
  avatar: baseStyleAvatar,
  name: baseStyleName,
  businessName: baseStyleBusinessName,
});

export default {
  parts,
  baseStyle,
};
