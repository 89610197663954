import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['container', 'header', 'content'];

const baseStyleContainer: SystemStyleObject = {
  width: 'full',
  maxWidth: 1098,
  marginBottom: ['80px', null, 144],
};

const baseStyleHeader: SystemStyleObject = {
  marginBottom: 10,
};

const baseStyleContent: SystemStyleObject = {};

const baseStyle = {
  container: baseStyleContainer,
  header: baseStyleHeader,
  content: baseStyleContent,
};

export default {
  parts,
  baseStyle,
};
