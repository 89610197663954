import { useState, useCallback } from 'react';
import {
  Box,
  Center,
  HStack,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
} from '@companydotcom/potion';
import { useDropzone } from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import Uppy, { UppyFile } from '@uppy/core';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faArrowRotateRight, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FileInputButton } from '../file-input-button';

interface UppyPhotoEditorProps {
  setFile: (file: UppyFile) => void;
  uppy: Uppy;
  editorRef: React.MutableRefObject<null>;
  uploadHandler: () => void;
  isLoading?: boolean;
  file?: UppyFile | string;
  onDeleteOpen: () => void;
}

export const UppyPhotoEditor = ({
  file,
  setFile,
  uppy,
  uploadHandler,
  isLoading,
  editorRef,
  onDeleteOpen,
}: UppyPhotoEditorProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [rotateIndex, setRotateIndex] = useState(0);
  const [scaleValue, setScaleValue] = useState(1.3);
  const [errorMessage, setErrorMessage] = useState('');

  const DEGREES = [0, 90, 180, 270];

  const rotateAvatar = () => {
    setRotateIndex((rotateIndex + 1) % DEGREES.length);
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      const uploadedFile = acceptedFiles?.[0] as File;

      if (editorRef) {
        uppy.addFile({
          name: uploadedFile?.name,
          type: uploadedFile?.type,
          data: uploadedFile,
          source: 'Local',
          isRemote: false,
          meta: {
            relativePath: uploadedFile?.webkitRelativePath,
          },
        });
      }
    },
    [editorRef, uppy],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noDrag: !!file || isUploading,
    noKeyboard: isUploading,
  });

  uppy.on('file-added', file => {
    setIsUploading(false);
    setErrorMessage('');
    setFile(file);
  });

  uppy.on('restriction-failed', (_file, error) => {
    setErrorMessage(error?.message);
    console.error(error?.stack);
  });

  uppy.on('upload', () => {
    setIsUploading(true);
  });

  uppy.on('upload-success', () => {
    setIsUploading(false);
  });

  uppy.on('upload-error', () => {
    setIsUploading(false);
  });

  return (
    <>
      <Center flexDir="column">
        {/* @ts-ignore */}
        <Box textAlign="center" position="relative" {...getRootProps({ className: 'dropzone' })}>
          <Text
            position="absolute"
            top="1"
            color="white"
            textTransform="uppercase"
            fontWeight="bold"
            textStyle="xs"
            left="0"
            right="0"
            mx="auto"
          >
            {!file ? 'Drag and drop a photo, or click add photo' : 'Drag to reposition photo'}
          </Text>
          <input {...getInputProps()} />
          <AvatarEditor
            ref={editorRef}
            color={[0, 0, 0, 0.6]}
            border={[188, 64]}
            borderRadius={999}
            width={136}
            height={128}
            crossOrigin="anonymous"
            image={
              typeof file === 'string'
                ? file
                : file?.data instanceof Blob
                ? new File([file.data], file.name)
                : file?.data ?? ''
            }
            scale={scaleValue}
            rotate={DEGREES[rotateIndex]}
          />
          <Text
            position="absolute"
            bottom="1"
            left="0"
            right="0"
            mx="auto"
            color={!file ? 'whiteAlpha.400' : 'white'}
            textTransform="uppercase"
            fontWeight="bold"
            textStyle="xs"
          >
            Zoom
          </Text>
        </Box>
        <UppyPhotoEditorControls
          setScaleValue={setScaleValue}
          rotateAvatar={rotateAvatar}
          onDeleteOpen={onDeleteOpen}
          isDisabled={!file}
        />

        <Box mt="6">
          {!file ? (
            <FileInputButton
              variant="outline"
              size="md"
              colorScheme="blue"
              uppy={uppy}
              inputName="files[]"
              locale={{
                strings: {
                  chooseFiles: 'Add Photo',
                },
              }}
            />
          ) : (
            <Button
              isLoading={isLoading || isUploading}
              isDisabled={!file}
              onClick={async () => await uploadHandler()}
            >
              Upload
            </Button>
          )}
          {errorMessage && (
            <Text mt="2" color="red.500">
              {errorMessage}
            </Text>
          )}
        </Box>
      </Center>
    </>
  );
};

export interface PhotoEditorControlsProps {
  setScaleValue: (e: number) => void;
  rotateAvatar: () => void;
  onDeleteOpen: () => void;
  isDisabled: boolean;
}

const UppyPhotoEditorControls = ({
  setScaleValue,
  rotateAvatar,
  onDeleteOpen,
  isDisabled,
}: PhotoEditorControlsProps) => {
  return (
    <HStack w="full" bg="black" justify="space-between" p="2">
      <Button
        minW="auto"
        h="auto"
        p={0}
        variant="unstyled"
        isDisabled={isDisabled}
        onClick={onDeleteOpen}
      >
        <FontAwesomeIcon icon={faTrash} boxSize="16px" color="white" />
      </Button>
      <Slider
        maxW="200"
        onChange={e => setScaleValue?.(e)}
        aria-label="zoom-slider"
        defaultValue={1.3}
        min={1}
        max={2}
        step={0.1}
        isDisabled={isDisabled}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <Button
        minW="auto"
        h="auto"
        p={0}
        variant="unstyled"
        isDisabled={isDisabled}
        onClick={rotateAvatar}
      >
        <FontAwesomeIcon icon={faArrowRotateRight} boxSize="16px" color="white" />
      </Button>
    </HStack>
  );
};
