import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Flex,
  Box,
  Button,
  Accordion,
  Center,
  Stack,
  Alert,
  AlertDescription,
  AlertTitle,
  useDisclosure,
  Text,
  Divider,
  Link,
  isEmptyObject,
} from '@companydotcom/potion';
import { AppSpinner, InputField, SelectField } from '@companydotcom/ui';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { MemberFilter } from '../components/index';
import { useLazySearchAcgMemberDirectoryV2Query } from '../../../../services/acg/acg-api-v2';

import { usStatesandProvinces } from '../utils';
import {
  updateSearchFilters,
  updateMembers,
  memberSearchQuery,
  setPageNumber,
  getSearchQuery,
} from '../member-search-slice';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { MemberSearchResults } from '../member-search-results';
import { GfDataLogo } from '../../shared';
import { PageHeading } from '../../../../components/elements';
import { AcgReferenceData } from '../../../../services/acg/acg.types';

const SearchSchema = (isMobile: boolean | undefined) =>
  yup
    .object()
    .shape({
      primaryChapter: isMobile ? yup.string() : yup.array().nullable(),
      organization: yup.string().nullable(),
      country: isMobile ? yup.string() : yup.array().nullable(),
      areaOfExpertise: isMobile ? yup.string() : yup.array().nullable(),
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      marketArea: isMobile ? yup.string() : yup.array().nullable(),
      individualType: isMobile ? yup.string() : yup.array().nullable(),
      state: isMobile ? yup.string() : yup.array().nullable(),
      transactionType: isMobile ? yup.string() : yup.array().nullable(),
      industry: isMobile ? yup.string() : yup.array().nullable(),
    })
    .test({
      name: 'at-least-one-required',
      test: values => {
        const anyFieldValid =
          values?.primaryChapter?.length ||
          values?.country?.length ||
          values?.areaOfExpertise?.length ||
          values?.marketArea?.length ||
          values?.state?.length ||
          values?.individualType?.length ||
          values?.transactionType?.length ||
          values?.industry?.length ||
          values?.firstName ||
          values?.organization ||
          values?.lastName;
        if (!anyFieldValid) {
          return false;
        }
        return true;
      },
      message: 'One field must be set',
    });

export interface SearchStepProps {
  isMobile?: boolean;
  memberDirectoryGoToStep?: (arg: number | string) => void;
  acgRefData?: AcgReferenceData;
}

export const SearchStep: React.FC<SearchStepProps> = props => {
  // STATE
  const query = useAppSelector(getSearchQuery);
  const { isMobile, memberDirectoryGoToStep, acgRefData } = props;
  const [
    searchMemberDirectory,
    { isFetching: memberFetching, isLoading: memberLoading, data: searchResults },
  ] = useLazySearchAcgMemberDirectoryV2Query();

  const defaultValues = {
    firstName: '',
    lastName: '',
    organization: '',
    state: isMobile ? '' : [],
    primaryChapter: isMobile ? '' : [],
    marketArea: isMobile ? '' : [],
    areaOfExpertise: isMobile ? '' : [],
    country: isMobile ? '' : [],
    individualType: isMobile ? '' : [],
    transactionType: isMobile ? '' : [],
    industry: isMobile ? '' : [],
    page: 0,
  };

  // HOOKS & HELPERS
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(SearchSchema(isMobile)),
    mode: 'onChange',
  });

  const cstKey = searchParams.get('cstKey');

  if (cstKey) {
    memberDirectoryGoToStep?.(1);
    navigate('../member-directory', {
      replace: true,
      state: { defaultStep: 'member-profile', recordKey: cstKey },
    });
  }

  const onSubmit = async (values: any) => {
    try {
      dispatch(setPageNumber(1));
      searchMemberDirectory({ searchParams: values }, true)
        .unwrap()
        .then(result => {
          dispatch(updateSearchFilters({ data: values, refData: acgRefData }));
          dispatch(updateMembers(result));
          dispatch(memberSearchQuery(values));
        });
    } catch (err) {
      console.log('ERROR in submit call', err);
    }
  };

  // Persists checkboxes
  useEffect(() => {
    if (!isDirty && !isEmptyObject(query)) {
      for (const [key, value] of Object.entries(query)) {
        // @ts-ignore
        setValue(key, value, {
          shouldValidate: true,
        });
      }
    }
  }, [isDirty, query, setValue]);

  return (
    <Center
      className="member-search"
      px={[0, 4]}
      borderRadius="sm"
      py={[0, 8, 12]}
      bg={['transparent', 'white']}
      flexDirection="column"
    >
      <Box maxW={908} width="full">
        {/**Header and Bar */}
        <PageHeading heading={t(`acg.memberSearch.search.header`)} />
        <Box p={[4, 0]} bg={['white', 'inherit']} mt={[4, 12]}>
          {/**ACG Terms Disclosure*/}
          <Center mb={6}>
            <Alert
              status="success"
              bg="rgba(70, 140, 35, 0.1)"
              borderRadius="md"
              px="25px"
              alignItems="start"
            >
              <Box mr="32px" mt="6px">
                <GfDataLogo />
              </Box>
              <Box>
                <AlertTitle lineHeight="24px">Meet the Right People. Get the Best Data.</AlertTitle>
                <AlertDescription fontSize="14px" lineHeight="20px">
                  ACG’s member directory allows you to search the entire member database across the
                  globe. Use this directory to search for the exact individuals you’re looking to
                  connect with – by company, industry, job function, local market and more. Then,
                  use GF data to ensure you’re working with the most reliable middle-market M&A
                  transaction data.
                  <Button
                    fontSize="xs"
                    fontWeight="600"
                    onClick={() => window.open('https://gfdata.com/')}
                    display="block"
                    colorScheme="green"
                    my="8px"
                    px="8px"
                    py="4px"
                    height="24px"
                  >
                    Explore GF Data
                  </Button>
                </AlertDescription>
              </Box>
            </Alert>
          </Center>

          <Box as="form" width="full">
            <Stack direction={['column', null, 'row']} mb={isMobile ? 2 : 4}>
              <InputField
                className="potion-myProfileStep__firstNameField"
                data-test="potion-myProfileStep__firstNameField"
                placeholder={t('common.inputs.firstName.label')}
                register={register}
                name="firstName"
                errors={errors}
              />
              <InputField
                className="memberSearch__Step__lastNameField"
                data-test="potion-myProfileStep__lastNameField"
                placeholder={t('common.inputs.lastName.label')}
                register={register}
                name="lastName"
                errors={errors}
              />
              <InputField
                className="potion-myProfileStep__firstNameField"
                data-test="potion-myProfileStep__firstNameField"
                placeholder="Company"
                register={register}
                name="organization"
                errors={errors}
              />
            </Stack>
            <Accordion allowMultiple allowToggle>
              <Center flexDirection="column" width="full">
                {!isMobile && (
                  <MemberFilter
                    label={t(`acg.memberSearch.search.filters.role`)}
                    name="individualType"
                    items={acgRefData?.individualType}
                    control={control}
                    borderBottom
                  />
                )}
                {isMobile && (
                  <SelectField
                    name="individualType"
                    placeholder="Function/Role"
                    errors={errors}
                    register={register}
                    mb={2}
                  >
                    {acgRefData?.individualType?.map((role, idx) => (
                      <option value={role.recordName} key={idx}>
                        {role.recordName}
                      </option>
                    ))}
                  </SelectField>
                )}
                <Button m={5} onClick={() => onToggle()} variant="link">
                  {isOpen
                    ? t(`acg.memberSearch.search.hideAdvancedOptions`)
                    : t(`acg.memberSearch.search.showAdvancedOptions`)}
                </Button>
                {isOpen && (
                  <Flex width="full" flexDirection="column">
                    {isMobile ? (
                      <>
                        <SelectField
                          name="primaryChapter"
                          placeholder={t(`acg.memberSearch.search.filters.chapter`)}
                          errors={errors}
                          register={register}
                          mb={2}
                        >
                          {acgRefData?.chapters?.map(role => (
                            <option value={role.chapterId} key={role.chapterId}>
                              {role.chapterName}
                            </option>
                          ))}
                        </SelectField>
                        <SelectField
                          name="marketArea"
                          placeholder={t(`acg.memberSearch.search.filters.market`)}
                          errors={errors}
                          register={register}
                          mb={2}
                        >
                          {acgRefData?.marketArea?.map((role, idx) => (
                            <option value={role.recordName} key={idx}>
                              {role.recordName}
                            </option>
                          ))}
                        </SelectField>
                        <SelectField
                          name="areaOfExpertise"
                          placeholder={t(`acg.memberSearch.search.filters.expertise`)}
                          errors={errors}
                          register={register}
                          mb={2}
                        >
                          {acgRefData?.areaOfExpertise?.map((role, idx) => (
                            <option value={role.recordName} key={idx}>
                              {role.recordName}
                            </option>
                          ))}
                        </SelectField>
                        <SelectField
                          name="state"
                          placeholder={t(`acg.memberSearch.search.filters.state`)}
                          errors={errors}
                          register={register}
                          mb={2}
                        >
                          {usStatesandProvinces?.map((role, idx) => (
                            <option value={role.abbreviation} key={idx}>
                              {role.recordName}
                            </option>
                          ))}
                        </SelectField>
                        <SelectField
                          name="country"
                          placeholder={t(`acg.memberSearch.search.filters.country`)}
                          errors={errors}
                          register={register}
                          mb={2}
                        >
                          {acgRefData?.country?.map((role, idx) => (
                            <option value={role.abbreviation} key={idx}>
                              {role.recordName}
                            </option>
                          ))}
                        </SelectField>
                        <SelectField
                          name="industry"
                          placeholder={t(`acg.memberSearch.search.filters.industry`)}
                          errors={errors}
                          register={register}
                          mb={2}
                        >
                          {acgRefData?.industry?.map((role, idx) => (
                            <option value={role.recordName} key={idx}>
                              {role.recordName}
                            </option>
                          ))}
                        </SelectField>
                        <SelectField
                          name="transactionType"
                          placeholder={t(
                            `acg.myProfile.profilePages.transactionType.transactionType`,
                          )}
                          errors={errors}
                          register={register}
                          mb={2}
                        >
                          {acgRefData?.transactionType?.map((role, idx) => (
                            <option value={role.recordName} key={idx}>
                              {role.recordName}
                            </option>
                          ))}
                        </SelectField>
                      </>
                    ) : (
                      <>
                        <MemberFilter
                          label={t(`acg.memberSearch.search.filters.chapter`)}
                          name="primaryChapter"
                          items={acgRefData?.chapters || []}
                          control={control}
                        />
                        <MemberFilter
                          label={t(`acg.memberSearch.search.filters.market`)}
                          name="marketArea"
                          items={acgRefData?.marketArea}
                          control={control}
                        />
                        <MemberFilter
                          label={t(`acg.memberSearch.search.filters.expertise`)}
                          name="areaOfExpertise"
                          items={acgRefData?.areaOfExpertise}
                          control={control}
                        />
                        <MemberFilter
                          label={t(`acg.memberSearch.search.filters.state`)}
                          name="state"
                          items={usStatesandProvinces}
                          control={control}
                        />
                        <MemberFilter
                          label={t(`acg.memberSearch.search.filters.country`)}
                          name="country"
                          items={acgRefData?.country}
                          control={control}
                        />
                        <MemberFilter
                          label={t(`acg.memberSearch.search.filters.industry`)}
                          name="industry"
                          items={acgRefData?.industry}
                          control={control}
                        />
                        <MemberFilter
                          label={t(`acg.myProfile.profilePages.transactionType.transactionType`)}
                          name="transactionType"
                          items={acgRefData?.transactionType}
                          control={control}
                        />
                      </>
                    )}
                  </Flex>
                )}
              </Center>
            </Accordion>
            <Box textAlign="center" my={4}>
              <Button
                className="potion-memberSearch__formButton"
                size="lg"
                isDisabled={memberFetching || !isValid}
                onClick={handleSubmit(onSubmit)}
                type="submit"
              >
                {t(`acg.memberSearch.search.searchCTA`)}
              </Button>
              {searchResults || memberFetching ? <Divider my={12} /> : null}
            </Box>
          </Box>
        </Box>
        {memberFetching && (
          <AppSpinner
            containerStyles={{ mx: 'auto', mb: 12, mt: 10 }}
            description={t(`acg.memberSearch.search.loadingTextForSearching`)}
          />
        )}
        {!memberLoading && !memberFetching && (
          <MemberSearchResults
            reset={reset}
            memberDirectoryGoToStep={memberDirectoryGoToStep}
            acgRefData={acgRefData}
            setValue={setValue}
          />
        )}
        <Text fontSize="xs" align="center" mb="48px" mx="auto">
          The Member Directory is governed by ACG’s{' '}
          <Link fontSize="xs" href="//www.acg.org/terms-conditions" isExternal>
            Terms and Conditions
          </Link>
          . Use of the data on this site to send unsolicited emails (Spam) is not permitted.
        </Text>
      </Box>
    </Center>
  );
};
