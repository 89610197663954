import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  InputField,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useForm, FieldValues } from 'react-hook-form';
import { Box, Text, Heading, Button, Center } from '@companydotcom/potion';
import type { DataCollectorFormProps } from '../../../features/data-collector';
import { gtm } from '../../../lib/google-tag-manager';
import { useUpdateChildUserProfileMutation } from '../../../services/user/user-api';
import yup from '../../../lib/yup';
// import { updateChildUserProfile } from '../../../services/user/old-api/user-svc';

const getChildUserProfileFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('common.inputs.firstName.error'),
    lastName: yup.string().required('common.inputs.lastName.error'),
    phone: yup.string().phone().nullable(),
  });

export interface ChildUserProfileFormProps extends DataCollectorFormProps {
  handleClose?: any;
}

export const ChildUserProfileForm: React.FC<ChildUserProfileFormProps> = ({
  globalUser,
  handleClose,
}) => {
  const { t } = useTranslation();
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();
  const [updateChildUserProfile] = useUpdateChildUserProfileMutation();

  const defaultValues: FieldValues = {
    firstName: globalUser?.firstName || '',
    lastName: globalUser?.lastName || '',
    phone: globalUser?.phone ? parsePhoneNumber(globalUser?.phone, country).number : '',
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getChildUserProfileFormSchema()),
    defaultValues,
    context: { country },
  });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      gtm('firstTimeChildUserProfileConfirm');
      await updateChildUserProfile({
        userId: globalUser?.userId,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: getFormattedPhoneNumber(values.phone, country ?? 'US'),
      })
        .then(() => handleClose())
        .catch(err => {
          console.log('Error!: ', err);
        });
    } catch (err) {
      console.log('Error!: ', err);
    }
  };

  return (
    <Center
      className="form-childUserProfileForm__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box textAlign="center">
        <Heading as="h1" size="hs-xl">
          {t('forms.childUserProfile.heading')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          <Trans i18nKey="forms.childUserProfile.subheading">
            Get started by creating your profile. <br /> This will be used to start setting up your
            products.
          </Trans>
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('common.misc.allFieldsRequired')}
        </Text>
      </Box>
      <Box
        as="form"
        className="form-childUserProfileForm__form"
        maxWidth={430}
        width="full"
        mt={[8, 10]}
      >
        <InputField
          className="form-childUserProfileForm__firstNameField"
          data-test="form-childUserProfileForm__firstNameField"
          register={register}
          label={t('common.inputs.firstName.label')}
          name="firstName"
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        />
        <InputField
          className="form-childUserProfileForm__lastNameField"
          data-test="form-childUserProfileForm__lastNameField"
          register={register}
          label={t('common.inputs.lastName.label')}
          name="lastName"
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        />

        <PhoneNumberInputField
          name="phone"
          label="Phone"
          className="form-childUserProfileForm__phoneField"
          data-test="form-childUserProfileForm__phoneField"
          control={control}
          country={country}
          onCountryChange={onCountryChange}
          errors={errors}
        />

        <Box textAlign="center" mt={[8, 12]}>
          <Button
            className="form-childUserProfileForm__submitButton"
            data-test="form-childUserProfileForm__submitButton"
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >
            {t('common.buttons.confirm')}
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
