import React, { useState, useEffect } from 'react';
import { useTabStepper, Tabs, TabPanels, TabPanel, useBoolean } from '@companydotcom/potion';
import { User, UserTile, CategoryRecord, LocationPlaceInfo, Maybe } from '@companydotcom/types';
import { GmbCreateUserProfile } from './gmb-create-user-profile';
import { GmbCompleteBusinessProfile } from './gmb-complete-business-profile';
import { GmbVerificationOptions } from './gmb-verification-options';
import { useLazyGetUserTilesQuery } from '../../../services/user/user-api';
import { CallingYourPhone } from './gmb-calling-your-phone';
import { GmbProfileSuccess } from './gmb-profile-success';
import { GmbVerificationCode } from './gmb-verification-code';
import { ResendingVerification } from './gmb-resending-verification';
import { ScheduleWalkThrough } from './gmb-schedule-walk-through';
// import ListingSvc from '../../../services/listings/listing-svc';

const gmbProfileSteps = [
  { slug: 'gmb-create-user-profile', component: <GmbCreateUserProfile /> },
  { slug: 'gmb-complete-business-profile', component: <GmbCompleteBusinessProfile /> },
  { slug: 'gmb-verification-options', component: <GmbVerificationOptions /> },
  { slug: 'gmb-verification-code', component: <GmbVerificationCode /> },
  { slug: 'gmb-success', component: <GmbProfileSuccess /> },
  { slug: 'gmb-calling-phone', component: <CallingYourPhone /> },
  { slug: 'gmb-schedule-walk-through', component: <ScheduleWalkThrough /> },
  { slug: 'gmb-resending-verification', component: <ResendingVerification /> },
];

export interface GmbProfileFlowProps {
  handleClose: any;
  globalUser?: User;
  handleAutocompleteLookup: (val: any, publisher?: string | undefined) => Promise<any>;
  locale?: string;
}

export interface UseBooleanReturn {
  readonly on: () => void;
  readonly off: () => void;
  readonly toggle: () => void;
}

export interface GmbFormValues {
  firstName?: string;
  lastName?: string;
  phone?: string;
  businessName?: string;
  businessPhone?: string;
  address?: string;
  zipCode?: string;
  visitable?: string;
  businessCategory?: CategoryRecord;
  gmbStatus?: string;
  serviceArea?: Maybe<LocationPlaceInfo>[];
  addressExtra?: string;
}

export interface GmbSharedProps {
  handleAutocompleteLookup?: any;
  globalUser?: User;
  setFormValues?: React.Dispatch<React.SetStateAction<GmbFormValues>>;
  formValues?: GmbFormValues;
  setSelectedVerification?: any;
  selectedVerification?: any;
  listingId?: string;
  setListingId?: (arg: string) => void;
  handleClose?: any;
  setGmbClaimed?: UseBooleanReturn;
  gmbClaimed?: boolean;
  setGmbFailed?: UseBooleanReturn;
  gmbFailed?: boolean;
  setGmbVerified?: UseBooleanReturn;
  gmbVerified?: boolean;
  BLPTile?: UserTile;
  listingSvc?: any;
  goToStep?: (step: number | string) => void;
  previousStep?: () => void;
  nextStep?: () => void;
}

export const GmbProfileFlow: React.FC<GmbProfileFlowProps> = ({
  handleClose,
  globalUser,
  handleAutocompleteLookup,
  locale,
}) => {
  const [getUserTiles] = useLazyGetUserTilesQuery();
  const { tabIndex, handleTabsChange, goToStep, nextStep, previousStep } = useTabStepper({
    steps: gmbProfileSteps,
    scrollOnStepChange: false,
  });
  const [formValues, setFormValues] = useState({});
  const [listingId, setListingId] = useState(globalUser?.account?.listingId ?? '');
  const [gmbClaimed, setGmbClaimed] = useBoolean(false);
  const [gmbFailed, setGmbFailed] = useBoolean(false);
  const [gmbVerified, setGmbVerified] = useBoolean(false);
  const [BLPTile, setBLPTile] = useState<UserTile | undefined>(undefined);
  const [selectedVerification, setSelectedVerification] = useState(undefined);
  // const listingSvc = ListingSvc(globalUser);

  useEffect(() => {
    const getTiles = async () => {
      const { data: result } = await getUserTiles({ userId: globalUser?.userId || '', locale });
      const tile = result?.find(t => t.tileSlug === 'business-listings-profile');
      setBLPTile(tile);
    };
    getTiles();
  }, [getUserTiles, locale, globalUser?.userId]);

  const sharedProps = {
    handleAutocompleteLookup,
    globalUser,
    setFormValues,
    formValues,
    setSelectedVerification,
    selectedVerification,
    listingId,
    setListingId,
    handleClose,
    setGmbClaimed,
    gmbClaimed,
    setGmbFailed,
    gmbFailed,
    setGmbVerified,
    gmbVerified,
    BLPTile,
    // listingSvc,
    goToStep,
    previousStep,
    nextStep,
  };

  return (
    <Tabs isLazy variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
      <TabPanels>
        {gmbProfileSteps.map(step => (
          <TabPanel key={step.slug}>{React.cloneElement(step.component, sharedProps)}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
