import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import type { RootState } from '../../../store';

// Define a type for the slice state
export interface AutoRenewState {
  // TODO: Properly type this with the proper types from the API
  savedPayments: any[];
  selectedPayment: {};
}

// Define the initial state using that type
export const initialState: AutoRenewState = {
  savedPayments: [],
  selectedPayment: {},
};

export const autoRenewSlice: Slice = createSlice({
  name: 'acgAutoRenew',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    savePayment: (state, action: PayloadAction<any>) => {
      if (action.payload.autoRenew) {
        state.selectedPayment = action.payload;
      }
      state.savedPayments = [...state.savedPayments, action.payload];
    },
    select: (state, action: PayloadAction<any>) => {
      state.selectedPayment = action.payload;
    },
  },
});

export const { savePayment, select } = autoRenewSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPayment = (state: RootState) => state.acgAutoRenew.savedPayments;

export default autoRenewSlice.reducer;
