import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Text,
  Box,
  HStack,
  VStack,
  UnorderedList,
  ListItem,
  SkeletonText,
} from '@companydotcom/potion';
import {
  SubscriptionTier,
  ExtendedSubscriptionRatePlan,
  ExtendedSubscriptionTier,
  Maybe,
} from '@companydotcom/types';
import { isEmpty } from 'lodash';
import {
  calculateEmailQuantity,
  calculateEstimatedRatePlanPrice,
  calculateOrderTotal,
} from '../../../utils/helpers/purchasing-helpers';

export interface OrderSummaryProps {
  selectedRatePlans?: ExtendedSubscriptionRatePlan[];
  selectedTiers?: ExtendedSubscriptionTier[] | [];
  orderId?: string;
  externalOrderSummary?: any[];
  externalLoading?: boolean;
}

export const OrderSummary = ({
  selectedRatePlans,
  selectedTiers,
  orderId,
  externalOrderSummary,
  externalLoading,
}: OrderSummaryProps) => {
  const { t } = useTranslation();
  const included = t('components.orderSummary.included');
  const currencySymbol = t('common.misc.currencySymbol');
  const perMonth = t('common.misc.perMonth');
  const yourOrder = t('components.orderSummary.yourOrder');
  const orderSummary = t('components.orderSummary.orderSummary');
  const disclaimerText = t('components.orderSummary.disclaimerText');

  const calculateCartItemPrice = (price?: Maybe<number>, selectedTier?: SubscriptionTier) => {
    if (!price && isEmpty(selectedTier)) {
      return included;
    }

    // tiered items
    if (
      (!price && !isEmpty(selectedTier) && !isEmpty(selectedRatePlans)) ||
      (price && selectedTier && selectedTier.price)
    ) {
      return `${currencySymbol}${calculateEstimatedRatePlanPrice(
        selectedRatePlans?.find(
          item =>
            // @ts-ignore
            item.ratePlanId === selectedTier?.ratePlanId ||
            // @ts-ignore
            item?.productName === selectedTier?.productName,
        ) as ExtendedSubscriptionRatePlan,
        selectedTier,
      )?.toFixed(2)}${perMonth}`;
    }

    if (price && isEmpty(selectedTier)) {
      return `${currencySymbol}${price?.toFixed(2)}${perMonth}`;
    }

    return price?.toString();
  };

  const calCartItemPrice = (price?: number) => {
    if (!price || price == 0) {
      return included;
    }
    if (price) {
      return `${currencySymbol}${price?.toFixed(2)}`;
    }
    return price?.toString();
  };

  const orderTotal = orderId
    ? (() =>
        typeof externalOrderSummary !== 'undefined' &&
        externalOrderSummary.reduce((sum: number, cartItem: any) => sum + cartItem.price, 0))()
    : selectedRatePlans && calculateOrderTotal(selectedRatePlans, selectedTiers);

  const getSelectedTier = (plan: ExtendedSubscriptionRatePlan) => {
    if (!isEmpty(selectedTiers)) {
      return selectedTiers?.find(tier => tier?.ratePlanId === plan?.ratePlanId);
    }
  };

  return (
    <Flex alignItems="start" alignSelf="start" flexDirection="column" mb={12} px={[4, null, 0]}>
      <Text fontWeight="medium" textStyle="xl" pb={[2]}>
        {yourOrder}
      </Text>
      <Box width="100%" borderTop="1px solid" borderColor="gray.300">
        {externalLoading ? (
          <VStack width="full" mt={6}>
            <SkeletonText width="full" noOfLines={6} spacing="4" />
          </VStack>
        ) : (
          <Flex flexDirection="column" p={6} backgroundColor="gray.100" mt={6}>
            {orderId
              ? externalOrderSummary &&
                externalOrderSummary.map((cartItem: any, i: number) => (
                  <CartItem
                    key={i}
                    name={cartItem.product}
                    price={calCartItemPrice(cartItem.price)}
                    productName={cartItem}
                  />
                ))
              : selectedRatePlans &&
                selectedRatePlans?.map((plan, i) => (
                  <CartItem
                    key={i}
                    productName={plan.productName}
                    name={plan?.productName}
                    subname={plan.name}
                    price={calculateCartItemPrice(
                      plan?.price,
                      selectedTiers?.find(t => t?.productName === plan.productName) ||
                        getSelectedTier(plan),
                    )}
                    selectedTier={
                      getSelectedTier(plan) ||
                      selectedTiers?.find(t => t?.productName === plan.productName)
                    }
                    quantity={
                      selectedRatePlans.some(p => p?.productName === 'Email')
                        ? calculateEmailQuantity(
                            //@ts-ignore
                            selectedRatePlans
                              ?.find(p => p?.productName === 'Email')
                              ?.tiers?.find(t => t?.isActive)?.qty,
                            selectedTiers?.find(t => t?.productName === plan?.productName)?.qty,
                          )
                        : selectedTiers?.find(t => t?.productName === plan.productName)?.qty
                    }
                  />
                ))}
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              sx={{
                borderTop: 'solid 1px #D8D8D8',
              }}
              pt={[4, 6]}
              mt={[4, 6]}
            >
              <Text textStyle="xl" fontWeight="medium">
                {orderSummary}:
              </Text>
              <Text textStyle="xl" fontWeight="medium" color="pricing">
                {`${currencySymbol}${orderTotal && orderTotal.toFixed(2)}${perMonth}*`}
              </Text>
            </Flex>
          </Flex>
        )}
      </Box>
      <Text textAlign="left" textStyle="xs" width="100%" opacity=".8" mt={2}>
        {disclaimerText}
      </Text>
    </Flex>
  );
};

interface CartItemProps {
  name?: Maybe<string>;
  subname?: Maybe<string>;
  price?: Maybe<string>;
  selectedTier?: ExtendedSubscriptionTier;
  productName?: Maybe<string>;
  quantity?: Maybe<number> | null;
}

const CartItem = ({ name, subname, price, selectedTier, quantity, productName }: CartItemProps) => {
  const { t } = useTranslation();
  return (
    <HStack
      className="cart-item"
      alignItems="flex-start"
      justifyContent="space-between"
      pb={[2, 3]}
    >
      <VStack textAlign="left">
        <Text className="product-name" textStyle="sm" width="full">
          {name}
        </Text>
        <UnorderedList textAlign="left" width="full" stylePosition="inside" listStyleType="'- '">
          {subname ? (
            <ListItem className="plan-name" textStyle="sm">
              {subname}
            </ListItem>
          ) : null}
          {!isEmpty(selectedTier) && selectedTier?.productName === name && (
            <ListItem className="tier-description" textStyle="sm">
              {productName === 'Email'
                ? t('components.orderSummary.additionalUsers', {
                    qty: quantity || selectedTier?.qty,
                  })
                : selectedTier?.description}
            </ListItem>
          )}
        </UnorderedList>
      </VStack>
      <Text className="price" textStyle="sm">
        {price}
      </Text>
    </HStack>
  );
};
