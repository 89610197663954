import React from 'react';
import { Flex, Button, Heading, Text, Center, Box } from '@companydotcom/potion';
import { CalendlyForm } from '../../../../components/forms/calendly/calendly';

export const ScheduleWalkThrough = ({ setFirstTimeTileFlow }: any) => {
  return (
    <Center className="fttf-calendly" flexDirection="column" pb={[8, 12]} px={4} textAlign="center">
      <Box maxW="xl">
        <Heading as="h1" size="hs-xl">
          Schedule a Free Walk Through{' '}
        </Heading>
        <Text textStyle={['lg', 'xl']} mt={[5]}>
          Schedule Your Appointment to Talk with a Company.com Customer Success Representative
        </Text>
      </Box>
      <CalendlyForm url={process.env.REACT_APP_CALENDLY_URL ?? ''} />
      <Flex textAlign="center" mt={[8, 12]} flexDirection="row" justifyContent="center">
        <Button
          size="lg"
          onClick={() => {
            setFirstTimeTileFlow(false);
          }}
        >
          Next
        </Button>
      </Flex>
    </Center>
  );
};
