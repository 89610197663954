import React, { useState, useEffect } from 'react';
import { platformHelpers, companyHelpers } from '@companydotcom/helpers';
import { Flex, Box } from '@companydotcom/potion';
import ContentLoader from 'react-content-loader';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import type { TileComponentProps } from '../../types';
import { TileConferencingCard } from '../tile-conferencing-card';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

export interface TileQuickLinksProps extends TileComponentProps {}

export const TileQuickLinks: React.FC<TileQuickLinksProps> = props => {
  const { eventData, params, eventHandler } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [buttonClicked, setIsButtonClicked] = useState(false);
  // eslint-disable-next-line
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState();
  const [disableConfig, setDisableConfig] = useState({});
  const { data: globalUser } = useGetGlobalUserQuery();

  const loaderTimeout = () => {
    setIsButtonClicked(true);
    setTimeout(() => {
      setIsButtonClicked(false);
    }, 8000);
  };

  useEffect(() => {
    if (!eventData || eventData?.fetching) {
      setIsLoading(true);
    }
    if (
      eventData?.body?.status === 'fail' ||
      (eventData?.body?.status === 'success' && !eventData?.body?.payload?.download)
    ) {
      setHasError(true);
    }

    if (eventData?.body?.payload?.download) {
      setData(eventData?.body?.payload?.download);
      setIsLoading(false);
    }
    const config = platformHelpers.getComponentParamValue(params || [], 'disable');

    setDisableConfig(companyHelpers.safeParse(config, {}));
  }, [eventData, params]);

  return (
    <Flex
      className="tile__quickLinks"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      flex={1}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box onClick={loaderTimeout} mb={[0, 3]}>
            {buttonClicked ? (
              <ButtonLoader />
            ) : (
              <TileConferencingCard
                events={[
                  {
                    type: 'click',
                    actions: [
                      {
                        type: 'navigate',
                        // @ts-ignore
                        actionParams: [{ key: 'target', value: data }],
                      },
                    ],
                  },
                ]}
                eventHandler={eventHandler}
                // @ts-ignore
                icon={faArrowDownToLine}
                text="Download Agent"
                description="Redownload agent to a device"
                useIcon
              />
            )}
          </Box>
          {/* @ts-ignore */}
          {globalUser?.source && !disableConfig?.sources?.includes(globalUser.source) && (
            <Box onClick={loaderTimeout} mb={[0, 3]}>
              {buttonClicked ? (
                <ButtonLoader />
              ) : (
                <TileConferencingCard
                  events={[
                    {
                      type: 'click',
                      actions: [
                        {
                          type: 'navigatePurchase',
                          actionParams: [{ key: 'target', value: 'self' }],
                        },
                      ],
                    },
                  ]}
                  eventHandler={eventHandler}
                  // @ts-ignore
                  icon={faArrowDownToLine}
                  text="+ Add License"
                  description="1 license includes 1 desktop device and up to 5 mobile devices."
                  useIcon
                />
              )}
            </Box>
          )}
        </>
      )}
    </Flex>
  );
};

const Loader = () => (
  <Flex flexDirection="row" sx={{ position: 'relative' }}>
    <ContentLoader
      speed={2}
      width={144}
      height={144}
      viewBox="0 0 144 144"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="226" y="64" rx="0" ry="0" width="40" height="8" />
      <rect x="226" y="40" rx="0" ry="0" width="40" height="8" />
      <circle cx="72" cy="72" r="72" />
    </ContentLoader>
  </Flex>
);
const ButtonLoader = () => (
  <ContentLoader
    speed={2}
    width={312}
    height={89}
    viewBox="0 0 312 89"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="312" height="89" />
  </ContentLoader>
);
