import React from 'react';
import {
  useMultiStyleConfig,
  StylesProvider,
  GridItem,
  useBreakpointValue,
} from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { Outlet } from 'react-router-dom';
import { useCycle } from 'framer-motion';
import { SideNav, MobileSideNav } from '../../elements/side-nav';
import { MotionGrid } from '../../elements';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { useAppSelector } from '../../../hooks';
import { selectIsUserDataCollectorsEmpty } from '../../../features/data-collector/data-collector-slice';

export const DashboardLayout: React.FC<{}> = props => {
  const isUserDataCollectorsEmpty = useAppSelector(selectIsUserDataCollectorsEmpty);
  const styles = useMultiStyleConfig('DashboardLayout', props);
  const [sidebarState, toggleSidebar] = useCycle('expand', 'collapse');
  const variants = useBreakpointValue({
    base: {
      collapse: { gridTemplateColumns: '1fr' },
      expand: { gridTemplateColumns: '1fr' },
    },
    md: {
      collapse: { gridTemplateColumns: '80px 1fr' },
      expand: { gridTemplateColumns: ['272px 1fr', '344px 1fr'] },
    },
  });
  const { data: globalUser, isLoading } = useGetGlobalUserQuery({
    refetchOnMountOrArgChange: true,
  });
  const firstTimeUser = !!globalUser?.userDataCollectors?.length && !isUserDataCollectorsEmpty;

  return !globalUser ? (
    <AppSpinner
      className="dashboard_layout_spinner"
      containerStyles={{
        minHeight: '100vh',
        flexGrow: 1,
      }}
    />
  ) : (
    <StylesProvider value={styles}>
      <MotionGrid
        gridTemplateRows="1fr"
        key="motionSidebar"
        animate={sidebarState}
        variants={variants}
      >
        <MobileSideNav display={['flex', null, 'none']}>
          <SideNav isSideNavOpen isLoading={isLoading || firstTimeUser} />
        </MobileSideNav>
        <GridItem
          className="sidenav-container"
          width={sidebarState === 'expand' ? ['272px', '344px'] : '80px'}
          sx={styles.sidenavContainer}
        >
          <SideNav
            isSideNavOpen={sidebarState === 'expand'}
            onSideNavToggle={toggleSidebar}
            isLoading={isLoading || firstTimeUser}
          />
        </GridItem>
        <GridItem className="main-container" sx={styles.mainContainer}>
          <Outlet />
        </GridItem>
      </MotionGrid>
    </StylesProvider>
  );
};
