"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
exports.__esModule = true;
exports.companyQL = exports.SourceSvc = void 0;
// Services
var index_1 = require("./services/source/index");
__createBinding(exports, index_1, "SourceSvc");
var app_sync_client_1 = require("./services/app-sync-client");
__createBinding(exports, app_sync_client_1, "companyQL");
