import React, { useState, useMemo, useReducer, useCallback } from 'react';
import { ExtendedSubscriptionProduct, User } from '@companydotcom/types';
import { isEmpty, sumBy } from 'lodash';
import {
  Flex,
  Box,
  Text,
  Button,
  Center,
  Accordion,
  AccordionItem,
  Link,
  useMediaQuery,
} from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { useMitt } from '@companydotcom/providers';
import { NavLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { companyConstants } from '@companydotcom/helpers';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SubscriptionAccordion } from '../components/subscription-accordion';
import { calculateNextInvoiceDate } from '../../account-information/utils';
// import { getZuoraInvoiceSummary } from '../../../services/payment/old-api/payment-svc';
import { SubscriptionProps } from '../../../state/reducers/subscriptions-reducer';
import { cartReducer, initialCartState } from '../../../state/reducers/cart-reducer';
import { OpenPaywallEvent } from '../../../features/paywall/types';
import { getUserProducts } from '../../../services/product/old-api/product-svc';
import { processAllRatePlanTiers } from '../../../utils/helpers/purchasing-helpers';
import { processProductRatePlans } from '../utils/subscription-helpers';
import { PageHeading } from '../../../components/elements';
import { useGetUserProductsQuery } from '../../../services/product/product-api';
import { useLazyGetZuoraInvoiceSummaryQuery } from '../../../services/payment/payment-api';

interface CurrentSubscriptionStepProps extends SubscriptionProps {
  user?: User;
  locale?: string;
}

export const CurrentSubscriptionStep = ({
  subscriptionState,
  dispatchSubscriptionState,
  user,
  locale = 'en',
}: CurrentSubscriptionStepProps) => {
  const { data: userProducts } = useGetUserProductsQuery(
    user
      ? {
          userId: user?.userId,
          locale,
        }
      : skipToken,
  );
  const [cartState, dispatchCartState] = useReducer(cartReducer, initialCartState);
  const [showBillDate, setShowBillDate] = useState(false);
  const [nextBillDate, setNextBillDate] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();
  const [getZuoraInvoiceSummary] = useLazyGetZuoraInvoiceSummaryQuery();

  const { emitter } = useMitt();

  useMemo(() => {
    const getBillDate = async () => {
      if (user && user.account) {
        if (!user.account.zuoraAccountId) {
          setShowBillDate(false);
          setNextBillDate('N/A');
        } else {
          const { invoices, billCycleDay } = await getZuoraInvoiceSummary({
            zuoraAccountId: user?.account?.zuoraAccountId,
          }).unwrap();
          if (invoices && billCycleDay) {
            setNextBillDate(calculateNextInvoiceDate(invoices, billCycleDay, 'long-form', t));
            setShowBillDate(true);
          }
          if (user && user?.account?.zuoraAccountId) {
            setNextBillDate(calculateNextInvoiceDate(invoices, billCycleDay, 'long-form', t));
            setShowBillDate(true);
          }
        }
      }
    };
    if (user && !nextBillDate) {
      getBillDate();
    }
    if (user && user?.account?.zuoraAccountId) {
      getBillDate();
    }
  }, [user, nextBillDate, getZuoraInvoiceSummary, t]);

  const onSuccessfulPurchase = useCallback(async () => {
    // This is temporary fix to get product updates. Should fix it with RTK
    const userProducts = await getUserProducts(user?.userId, locale);

    const processedUserProduct = {
      ...userProducts,
      products: userProducts?.products?.map(
        product => product && processAllRatePlanTiers<ExtendedSubscriptionProduct>(product),
      ),
    };

    const userProductsProcessedRatePlans = processedUserProduct?.products?.map(
      product => product && processProductRatePlans(product, user?.account?.Products),
    ) as ExtendedSubscriptionProduct[];

    dispatchSubscriptionState?.({
      type: 'UPDATE_CURRENT_SUBSCRIPTIONS',
      payload: {
        userProducts: userProductsProcessedRatePlans || [],
        purchasedRatePlans: cartState.selectedRatePlans || [],
        purchasedTiers: cartState.selectedTiers,
        estimatedNewMonthlyPrice:
          userProducts?.monthlyPrice || subscriptionState?.currentSubscriptionPrice,
      },
    });

    dispatchCartState({ type: 'RESET_ALL_CART_STATE' });
  }, [
    dispatchSubscriptionState,
    cartState.selectedRatePlans,
    cartState.selectedTiers,
    subscriptionState?.currentSubscriptionPrice,
    user?.account?.Products,
    user?.userId,
    locale,
  ]);

  const handleSubmit = () => {
    // Grab selectedRatePlans here and trigger paywall on submit
    const eventData: OpenPaywallEvent = {
      type: 'openPaywall',
      metadata: {
        eventSource: 'subscriptions',
        products: cartState.selectedRatePlans,
        selectedTiers: cartState.selectedTiers,
        onSuccessHandler: onSuccessfulPurchase,
      },
    };
    emitter.emit(companyConstants.platformEvents.openPaywall, eventData);
  };

  return (
    <Box
      className="current-subscription-step"
      paddingTop={14}
      paddingBottom="80px"
      maxWidth={776}
      marginX="auto"
      paddingX={5}
    >
      <Box mb={6}>
        <PageHeading heading={t('miop.subscriptions.currentSubscription.header')} mb={6} />

        <Flex
          width="full"
          flexDirection={['column-reverse', null, 'row']}
          justifyContent="space-between"
        >
          <Box>
            <Text textStyle="2xl" fontWeight="medium" mb={2}>
              {t('miop.subscriptions.currentSubscription.currentExpense')}
            </Text>
            {showBillDate ? (
              <Text textStyle="md">
                {t('miop.subscriptions.currentSubscription.nextBillingDate', { nextBillDate })}
              </Text>
            ) : (
              <Text textStyle="md" sx={{ visibility: 'hidden' }}>
                {t('common.buttons.loading')}...
              </Text>
            )}
          </Box>
          <Box mb={[2, null, 0]}>
            {subscriptionState?.currentSubscriptionProducts &&
              !isEmpty(subscriptionState?.currentSubscriptionProducts) && (
                <Text textStyle="2xl" fontWeight="medium" color="pricing">
                  $
                  {`${(
                    sumBy(cartState.selectedRatePlans, plan => plan?.estimatedPrice || 0) +
                    ((subscriptionState?.currentSubscriptionPrice as number) ||
                      (userProducts?.monthlyPrice as number))
                  ).toFixed(2)}`}
                  /{t('miop.subscriptions.month')}
                </Text>
              )}
          </Box>
        </Flex>
      </Box>
      <Center width="full" flexDirection="column" mt={8}>
        <Accordion width="full" allowMultiple allowToggle>
          {!subscriptionState?.currentSubscriptionProducts ||
          isEmpty(subscriptionState?.currentSubscriptionProducts) ? (
            <AppSpinner containerStyles={{ my: 14 }} />
          ) : (
            subscriptionState?.currentSubscriptionProducts?.map((product, i) => (
              <AccordionItem
                key={i}
                mb={4}
                borderWidth="1px"
                borderColor="gray.200"
                borderRadius="none"
                boxShadow="md"
              >
                {({ isExpanded }) => (
                  <SubscriptionAccordion
                    isExpanded={isExpanded}
                    product={product}
                    cartState={cartState}
                    dispatchCartState={dispatchCartState}
                    isMobile={isMobile}
                  />
                )}
              </AccordionItem>
            ))
          )}
        </Accordion>
        <Box sx={{ textAlign: 'center' }} mt={10}>
          <Button
            size="lg"
            onClick={handleSubmit}
            disabled={
              !cartState.selectedRatePlans ||
              isEmpty(cartState.selectedRatePlans) ||
              cartState.selectedTiers?.some?.(tier => tier?.isEnterprise || tier?.isActive)
            }
          >
            {t('miop.subscriptions.currentSubscription.cta')}
          </Button>
        </Box>
        <Trans i18nKey="miop.subscriptions.currentSubscription.contactSupport">
          <Text textStyle="sm" textAlign="center" mt={14}>
            If you have questions or you’d like to downgrade your plan, please
            <Link as={NavLink} to="/help">
              contact Customer Success.
            </Link>
          </Text>
        </Trans>
      </Center>
    </Box>
  );
};
