import React, { useEffect, useCallback } from 'react';
import { UserTile, User, Cui } from '@companydotcom/types';
import { Slide, Box, useMultiStyleConfig } from '@companydotcom/potion';
import { Landbot } from '../../landbot';

export interface CuiTileOverlayProps {
  tile: UserTile;
  user: User;
  userCui: Cui;
  isOpen: boolean;
  onToggle: () => void;
  onClose: () => void;
}

export const CuiTileOverlay: React.FC<CuiTileOverlayProps> = props => {
  const styles = useMultiStyleConfig('Tile', props);
  const {
    isOpen,
    tile,
    user,
    onToggle,
    onClose,
    userCui: { providerId, cuiId, name, dataRequired, trigger },
  } = props;

  const overlayStyles = {
    ...styles.tile,
    border: 'none',
    boxShadow: 'none',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'full',
    height: 'full',
  };

  const landbotStatusHandler = () => {
    onToggle();
  };

  const escFunction = useCallback(
    event => {
      if (isOpen && (event.keyCode === 27 || event.key === 'Escape')) {
        onClose();
      }
    },
    [isOpen, onClose],
  );

  useEffect(() => {
    if (trigger === 'auto') {
      setTimeout(() => {
        onToggle();
      }, 3000);
    }
    // eslint-disable-next-line
  }, [trigger]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <>
      <Box className="cui__tileOverlay" sx={overlayStyles}>
        <Slide
          direction="right"
          in={isOpen}
          style={{
            position: 'absolute',
            overflow: 'hidden',
            zIndex: isOpen ? 1 : -1,
            backgroundColor: 'rgba(226, 230, 232, 0.8)',
            borderRadius: '4px',
            backdropFilter: 'blur(4px)',
            width: '100%',
          }}
        >
          {providerId && name && isOpen && (
            <Landbot
              user={user}
              tile={tile}
              providerId={providerId}
              cuiId={cuiId}
              iframeId={name}
              dataRequired={dataRequired}
              landbotStatusHandler={landbotStatusHandler}
            />
          )}
        </Slide>
      </Box>
    </>
  );
};
