import {
  useToast as usePotionToast,
  UseToastOptions,
  useBreakpointValue,
  ToastPosition,
} from '@companydotcom/potion';
import { useSource } from '@companydotcom/providers';

export const useToast = (options?: UseToastOptions) => {
  const source = useSource();
  const toastPositions = useBreakpointValue({
    base: 'bottom',
    md: source?.themeConfig?.toastConfig?.position || 'top-right',
  });

  return usePotionToast({
    position: toastPositions as ToastPosition,
    duration: 6000,
    isClosable: true,
    ...options,
  });
};
