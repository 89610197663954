import React from 'react';
import {
  Box,
  Text,
  Button,
  Heading,
  Center,
  ButtonGroup,
  Link,
  useBreakpointValue,
} from '@companydotcom/potion';
import { useForm } from 'react-hook-form';
import { PinCodeField } from '@companydotcom/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Listing } from '@companydotcom/types';
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';
import isInt from 'validator/lib/isInt';
import type { GmbSharedProps } from './gmb-profile-flow';
import {
  useCompleteVerificationMutation,
  useStartVerificationMutation,
} from '../../../services/listings/listing-api';
import { useAwaitableFacade } from '../../../hooks';

const verificationTextMap = (selectedVerification: string) => {
  if (selectedVerification === 'EMAIL') {
    return 'email';
  }
  if (selectedVerification === 'SMS') {
    return 'text message';
  }

  return 'phone';
};

const CODE_LENGTH = 6;

const getGmbVerificationCodeSchema = () =>
  yup.object().shape({
    code: yup
      .string()
      .min(CODE_LENGTH, 'gmbProfileFlow.verificationCode.inputs.code.min')
      .test('code', 'gmbProfileFlow.verificationCode.inputs.code.test', val =>
        typeof val === 'string' ? isInt(val) : undefined,
      )
      .required('gmbProfileFlow.verificationCode.inputs.code.required'),
  });

export const GmbVerificationCode: React.FC<GmbSharedProps> = ({
  selectedVerification = 'SMS',
  listingId,
  setGmbVerified,
  setGmbFailed,
  // listingSvc,
  previousStep,
  goToStep,
  globalUser,
}) => {
  const [startVerification] = useStartVerificationMutation();
  const [completeVerification] = useCompleteVerificationMutation();
  const { t } = useTranslation();
  const snsInterface = useAwaitableFacade(globalUser, 'listing');
  const defaultValues = { code: '' };
  const {
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isValid },
    control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getGmbVerificationCodeSchema()),
    defaultValues,
  });

  const pinInputSize = useBreakpointValue({ base: 'md', sm: 'lg' });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      if (globalUser && listingId) {
        await completeVerification({ listingId, verificationCode: values.code, snsInterface })
          .unwrap()
          .then(({ success, error }: { success: boolean; error: any; data: Listing }) => {
            if (success) {
              setGmbVerified?.on();
              goToStep?.(4);
            } else if (error === 'Invalid PIN.') {
              setError('code', { message: t('gmbProfileFlow.verificationCode.inputs.code.test') });
            } else {
              console.error(error);
              setGmbFailed?.on();
              goToStep?.(4);
            }
          });
      }
      // listingSvc
      //   .completeVerification(listingId, values.code)
      //   .then(({ success, error, data }: { success: boolean; error: any; data: Listing }) => {
      //     if (success) {
      //       setGmbVerified?.on();
      //       goToStep?.(4);
      //     } else if (error === 'Invalid PIN.') {
      //       setError('code', { message: t('gmbProfileFlow.verificationCode.inputs.code.test') });
      //     } else {
      //       console.error(error);
      //       console.log(data);
      //       setGmbFailed?.on();
      //       goToStep?.(4);
      //     }
      //   });
    } catch (err) {
      console.log('Error!', err);
    }
  };

  return (
    <Center
      className="gmb-verificationCode__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box textAlign="center" maxWidth={480}>
        <Heading as="h1" size="hs-xl">
          {t('gmbProfileFlow.verificationCode.heading')}
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('gmbProfileFlow.verificationCode.subheading', {
            CODE_LENGTH,
            selectedVerification: verificationTextMap(selectedVerification),
          })}
        </Text>
      </Box>
      <>
        <Center>
          <PinCodeField
            autoFocus
            length={CODE_LENGTH}
            control={control}
            name="code"
            size={pinInputSize}
            formControlStyles={{ marginY: [14] }}
            errors={errors}
          />
        </Center>

        <ButtonGroup size="lg">
          <Button
            className="gmb-verificationCode__backButton"
            data-test="gmb-verificationCode__backButton"
            variant="outline"
            onClick={() => {
              previousStep?.();
            }}
          >
            {t('common.buttons.back')}
          </Button>

          <Button
            className="gmb-verificationCode__submitButton"
            data-test="gmb-verificationCode__submitButton"
            isDisabled={!isValid}
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('gmbProfileFlow.verificationCode.enterCode')}
          </Button>
        </ButtonGroup>
      </>
      <Box mt={14} textAlign="center">
        <Text textStyle="sm">
          {selectedVerification === 'CALL_ME'
            ? t('gmbProfileFlow.verificationCode.noCall')
            : t('gmbProfileFlow.verificationCode.noVerification', {
                selectedVerification: verificationTextMap(selectedVerification),
              })}{' '}
          <Button
            variant="link"
            fontSize="sm"
            onClick={() => {
              // listingSvc.startVerification(listingId, selectedVerification);
              if (globalUser && listingId) {
                startVerification({
                  snsInterface,
                  listingId,
                  verificationMethod: selectedVerification,
                });
              }
            }}
          >
            {selectedVerification === 'CALL_ME'
              ? t('gmbProfileFlow.verificationCode.callAgain')
              : t('gmbProfileFlow.verificationCode.resendVerification', {
                  selectedVerification: verificationTextMap(selectedVerification),
                })}
          </Button>
        </Text>
        <Trans i18nKey="gmbProfileFlow.verificationCode.needHelp">
          <Text textStyle="sm" mt={15}>
            Need Help?
            <Link isExternal href={`${process.env.REACT_APP_GRANDIO_URL}/contact-us`}>
              {' '}
              Contact Support
            </Link>
          </Text>
        </Trans>
      </Box>
    </Center>
  );
};
