import {
  ExtendedSubscriptionProduct,
  ExtendedSubscriptionRatePlan,
  ExtendedSubscriptionTier,
} from '@companydotcom/types';

export interface subscriptionStateProps {
  currentSubscriptionProducts?: ExtendedSubscriptionProduct[];
  currentSubscriptionPrice?: number | null;
  availableProducts?: ExtendedSubscriptionProduct[];
  allSubscriptionData?: ExtendedSubscriptionProduct[];
}

export const initialSubscriptionState: subscriptionStateProps = {
  currentSubscriptionProducts: [],
  currentSubscriptionPrice: undefined,
  availableProducts: [],
  allSubscriptionData: [],
};

export type SubscriptionActionType =
  | { type: 'UPDATE_STATE'; payload: typeof initialSubscriptionState }
  | {
      type: 'UPDATE_AVAILABLE_PRODUCTS';
      payload: ExtendedSubscriptionRatePlan[] | [];
    }
  | {
      type: 'UPDATE_CURRENT_SUBSCRIPTIONS';
      payload: {
        userProducts: ExtendedSubscriptionProduct[];
        purchasedRatePlans: ExtendedSubscriptionRatePlan[];
        purchasedTiers?: ExtendedSubscriptionTier[];
        estimatedNewMonthlyPrice?: number | null;
      };
    }
  | { type: 'RESET_ALL_SUBSCRIPTION_STATE' };

export interface SubscriptionProps {
  dispatchSubscriptionState?: React.Dispatch<SubscriptionActionType>;
  subscriptionState?: subscriptionStateProps;
}

export const subscriptionReducer = (
  state: typeof initialSubscriptionState,
  action: SubscriptionActionType,
) => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'UPDATE_AVAILABLE_PRODUCTS': {
      const productIds = (action.payload as ExtendedSubscriptionRatePlan[])?.map(o => o.productId);
      const productsPurchased = state.allSubscriptionData?.filter(item =>
        productIds?.includes(item.productId),
      );
      const updatedAvailableProducts = state.availableProducts?.filter(
        item => !productsPurchased?.includes(item),
      );

      return {
        ...state,
        availableProducts: updatedAvailableProducts,
        // currentSubscriptionProducts: updatedCurrentSubscriptionProducts,
      };
    }
    case 'UPDATE_CURRENT_SUBSCRIPTIONS': {
      const productIdsToUpdate = (
        action.payload.purchasedRatePlans as ExtendedSubscriptionRatePlan[]
      ).map(o => o.productId);

      const purchasedProducts = action.payload.userProducts.filter(p =>
        productIdsToUpdate.includes(p.productId),
      );

      const updatedCurrentSubscriptionProducts = state.currentSubscriptionProducts?.map(
        obj => purchasedProducts.find(o => o.productId === obj.productId) || obj,
      );

      return {
        ...state,
        currentSubscriptionProducts: updatedCurrentSubscriptionProducts,
        currentSubscriptionPrice: action.payload.estimatedNewMonthlyPrice,
      };
    }
    case 'RESET_ALL_SUBSCRIPTION_STATE':
      return { ...initialSubscriptionState };
    default:
      throw new Error('Need action type');
  }
};
