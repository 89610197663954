import sha1 from 'crypto-js/sha1';
import enc from 'crypto-js/enc-hex';

export const checkForPasswordBreach = async (password: string) => {
  const hash = sha1(password);
  const hex = hash.toString(enc).toUpperCase();
  const suffix = hex.slice(5);

  const response = await fetch(`https://api.pwnedpasswords.com/range/${hex.slice(0, 5)}`);
  const data = await response.text();

  const chunk = await data
    .split('\n')
    .map(row => ({
      suffix: row.split(':')[0],
      count: parseInt(row.split(':')[1], 10),
    }))
    .filter(row => row.suffix === suffix);

  if (chunk[0]) {
    return chunk[0].count;
  }

  return 0;
};
