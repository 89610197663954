import React from 'react';
import { Button } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faArrowLeftLong } from '@fortawesome/pro-solid-svg-icons';
import { Trans } from 'react-i18next';

export interface BackButtonLayoutProps {
  /** Back button handler.  Usually paired with useNavigate or useTabStepper hook */
  onBackClick: () => void;
  /** Chooses when to display the entire layout */
  isDisplayed?: boolean;
}

export const BackButtonLayout: React.FC<BackButtonLayoutProps> = ({
  onBackClick,
  isDisplayed = true,
  children,
}) =>
  isDisplayed ? (
    <>
      <Button
        size="sm"
        variant="link"
        onClick={onBackClick}
        leftIcon={<FontAwesomeIcon icon={faArrowLeftLong} />}
        mb={6}
      >
        <Trans i18nKey="common.buttons.back">Back</Trans>
      </Button>
      {children}
      <Button
        size="sm"
        variant="link"
        onClick={onBackClick}
        leftIcon={<FontAwesomeIcon icon={faArrowLeftLong} />}
        mt={6}
      >
        <Trans i18nKey="common.buttons.back">Back</Trans>
      </Button>
    </>
  ) : (
    <>{children}</>
  );
