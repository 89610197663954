import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, TabPanels, TabPanel, useTabStepper, BoxProps } from '@companydotcom/potion';
import { useAppSelector } from '../../../../hooks';
import { CheckoutOrder } from './checkout-order';
import { CheckoutOrderPending } from './checkout-order-pending';
import { CheckoutOrderConfirmation } from './checkout-order-confirmation';
import { CheckoutOrderInternational } from './checkout-order-international';
import { BackButtonLayout } from '../../../../components/layouts';
import { getSelectedChapter } from '../checkout-slice';
import { CheckoutOrderEventError } from './checkout-order-event-error';

const checkoutSteps = [
  {
    slug: 'checkout-order',
    component: <CheckoutOrder />,
  },
  {
    slug: 'checkout-order-pending',
    component: <CheckoutOrderPending />,
  },
  {
    slug: 'checkout-order-confirmation',
    component: <CheckoutOrderConfirmation />,
  },
  {
    slug: 'checkout-order-international',
    component: <CheckoutOrderInternational />,
  },
  {
    slug: 'checkout-order-event-error',
    component: <CheckoutOrderEventError />,
  },
];

export interface CheckoutSharedProps {
  goToStep?: (arg: number | string) => void;
  /** An additional function that fires on the order confirmation step once complete  */
  onOrderConfirmationComplete?: () => void;
  /** An additional function that fires once a purchase is successful  */
  onSuccessfulPurchase?: () => void;
  /** Style variant */
  variant?: 'unstyled' | 'page';
  containerProps?: BoxProps;
}

export interface CheckoutLocationStateProps {
  eventKey?: string;
  // These values come from the shopping-cart screen
  shouldShouldWaitlistAlert?: boolean;
  shouldShowEventFullAlert?: boolean;
  shouldShowEventOverCapacityAlert?: boolean;
  totalWaitList?: number;
}

export const Checkout: React.FC<Omit<CheckoutSharedProps, 'goToStep'>> = props => {
  const {
    onOrderConfirmationComplete,
    onSuccessfulPurchase,
    variant = 'page',
    containerProps,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as CheckoutLocationStateProps;

  const chapter = useAppSelector(getSelectedChapter);
  const { currentStep, tabIndex, handleTabsChange, goToStep } = useTabStepper({
    steps: checkoutSteps,
    initialStep:
      locationState?.shouldShouldWaitlistAlert ||
      locationState?.shouldShowEventFullAlert ||
      locationState?.shouldShowEventOverCapacityAlert
        ? 'checkout-order-event-error'
        : chapter
        ? determineInitialStep(chapter)
        : 0,
  });

  const determineIfBackButtonsDisplayed = (currentStepSlug?: string) => {
    return location.pathname.includes('checkout') && currentStepSlug === 'checkout-order';
  };

  return (
    <BackButtonLayout
      isDisplayed={determineIfBackButtonsDisplayed(currentStep?.slug)}
      onBackClick={() => navigate(-1)}
    >
      <Tabs
        className="acg-checkout"
        isLazy
        variant={variant}
        index={tabIndex}
        onChange={handleTabsChange}
        mt={determineIfBackButtonsDisplayed(currentStep?.slug) ? 0 : [0, 12]}
      >
        <TabPanels>
          {checkoutSteps.map(step => (
            <TabPanel sx={containerProps} className={step?.slug ?? ''} padding={0} key={step.slug}>
              {React.cloneElement(step.component, {
                goToStep,
                onOrderConfirmationComplete,
                onSuccessfulPurchase,
              })}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </BackButtonLayout>
  );
};

const determineInitialStep = (currentChapter: any) => {
  if (
    currentChapter?.duesProducts?.[0]?.chapterTypeName.includes('EU') ||
    currentChapter?.duesProducts?.[0]?.chapterTypeName.includes('UK')
  ) {
    return 'checkout-order-international';
  }
  return 0;
};
