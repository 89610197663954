import React from 'react';
import { ExtendedSubscriptionTier, ExtendedSubscriptionRatePlan } from '@companydotcom/types';

/**
 * Cart state managed for both Paywall and Subscription products.
 */
export interface CartStateProps {
  /** The selected rate plans to process. */
  selectedRatePlans?: ExtendedSubscriptionRatePlan[] | [];
  /** The selected tiers, if the product rate plan contains them (ex: Email, Email Marketing products) */
  selectedTiers?: ExtendedSubscriptionTier[] | [];
}

export const initialCartState: CartStateProps = {
  selectedRatePlans: [],
  selectedTiers: [],
};

export type CartActionType =
  | { type: 'SET_CART_STATE'; payload: typeof initialCartState }
  | { type: 'ADD_SUBSCRIPTION_TIER'; payload: ExtendedSubscriptionTier }
  | { type: 'UPDATE_SUBSCRIPTION_TIER'; payload: ExtendedSubscriptionTier }
  | { type: 'REMOVE_SUBSCRIPTION_TIER'; payload: ExtendedSubscriptionTier }
  | { type: 'ADD_RATE_PLAN'; payload: ExtendedSubscriptionRatePlan }
  | { type: 'UPDATE_RATE_PLAN'; payload: ExtendedSubscriptionRatePlan }
  | { type: 'REMOVE_RATE_PLAN'; payload: ExtendedSubscriptionRatePlan }
  | { type: 'RESET_ALL_CART_STATE' };

export interface CartReducerProps {
  dispatchCartState?: React.Dispatch<CartActionType>;
  cartState?: CartStateProps;
}

export const cartReducer = (state: CartStateProps, action: CartActionType) => {
  const ratePlans = state.selectedRatePlans || [];
  const tiers = state.selectedTiers || [];

  switch (action.type) {
    case 'SET_CART_STATE':
      return { ...state, ...action.payload };
    case 'ADD_SUBSCRIPTION_TIER':
      return { ...state, selectedTiers: [...tiers, action.payload] };
    case 'UPDATE_SUBSCRIPTION_TIER':
      return {
        ...state,
        selectedTiers: [
          ...tiers.filter(
            tier =>
              tier &&
              tier.description !== action.payload.description &&
              tier.productName !== action.payload.productName,
          ),
          action.payload,
        ],
      };
    case 'REMOVE_SUBSCRIPTION_TIER':
      return {
        ...state,
        selectedTiers: [
          ...tiers.filter(tier => tier && tier.description !== action.payload.description),
        ],
      };
    case 'ADD_RATE_PLAN':
      return { ...state, selectedRatePlans: [...ratePlans, action.payload] };
    case 'UPDATE_RATE_PLAN':
      return {
        ...state,
        selectedRatePlans: [
          ...ratePlans.filter(plan => plan && plan.productId !== action.payload.productId),
          action.payload,
        ],
      };
    case 'REMOVE_RATE_PLAN':
      return {
        ...state,
        selectedRatePlans: [
          ...ratePlans.filter(plan => plan && plan.productId !== action.payload.productId),
        ],
      };
    case 'RESET_ALL_CART_STATE':
      return { ...initialCartState };
    default:
      throw new Error(`Unhandled action type`);
  }
};
