import { isEmpty, zip, cloneDeep } from 'lodash';
import {
  EmptyObject,
  ExtendedSubscriptionStatus,
  ExtendedSubscriptionRatePlan,
  ExtendedSubscriptionTier,
  ExtendedSubscriptionProduct,
  Maybe,
} from '@companydotcom/types';

/**
 * Determines if a selected tier qualifies a user for a free upgrade
 * @param plan - The plan to check
 * @param index - The currently selected index of the plan
 * @param selectedTier - The currently selected tier
 * @param ratePlans - All available rate plans for a product
 * @param cart - Current state of cart
 * @returns boolean
 */
export const determineIfFreeUpgradeAvailable = (
  plan: ExtendedSubscriptionRatePlan,
  index: number,
  selectedTiers?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>> | [],
  ratePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
  cart?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
) => {
  const indexOfActivePlan = ratePlans?.findIndex(
    plan => plan?.ratePlanId === cart?.[0]?.ratePlanId,
  ) as number;
  if (
    !isEmpty(selectedTiers) &&
    !isEmpty(plan.tiers) &&
    selectedTiers !== undefined &&
    (plan?.tiers as Array<Maybe<ExtendedSubscriptionTier | undefined>>)?.includes(
      selectedTiers?.[0],
    ) &&
    index < indexOfActivePlan
  ) {
    return true;
  }
  return false;
};

/**
 * Calculates the estimated price for any rate plan.
 * For tiered products, price is derived by summing the price of each previous tier from currently selected
 * @param ratePlan - The selected rate plan
 * @param tier - The selected tier, if available
 */
export const calculateEstimatedRatePlanPrice = (
  ratePlan?: Maybe<ExtendedSubscriptionRatePlan>,
  tier?: Maybe<ExtendedSubscriptionTier> | EmptyObject,
) => {
  if (!isEmpty(tier) && ratePlan && tier?.ratePlanId === ratePlan.ratePlanId) {
    const indexOfSelectedTier = ratePlan.tiers?.findIndex(
      rTier => rTier?.description === tier?.description,
    );

    // If its the first item in a dropdown, dont bother summing prices
    if (indexOfSelectedTier === 0) {
      return ratePlan?.tiers?.[0]?.price;
    }

    return (ratePlan?.tiers as ExtendedSubscriptionTier[])?.reduce(
      (acc: number, curr: ExtendedSubscriptionTier, index: number) => {
        if (indexOfSelectedTier && curr && curr.price && index <= indexOfSelectedTier) {
          acc += curr?.price;
        }
        return acc;
      },
      0,
    );
  }
  if (
    //* Handles if tier is missing the ratePlanId, and ratePlan doesn't have a price
    !isEmpty(tier) &&
    ratePlan &&
    tier?.ratePlanId !== ratePlan.ratePlanId &&
    tier?.price &&
    tier?.qty
  ) {
    // This is a temporary fix, ad this condition is for Email Marketing product, for the 5000-10000 tier. No unique id is available for the same, as the ratePlan Ids are same for Starter & Pro
    if (tier?.ratePlanId !== ratePlan.ratePlanId && tier?.qty <= 10000) {
      const indexOfActiveTier = ratePlan.tiers?.findIndex(
        rTier => rTier?.description === tier?.description,
      );
      if (indexOfActiveTier === 0) {
        return ratePlan?.tiers?.[0]?.price;
      }
      return (ratePlan?.tiers as ExtendedSubscriptionTier[])?.reduce(
        (acc: number, curr: ExtendedSubscriptionTier, index: number) => {
          if (indexOfActiveTier && curr && curr.price && index <= indexOfActiveTier) {
            acc += curr?.price;
          }
          return acc;
        },
        0,
      );
    }
    return tier?.price * tier?.qty;
  }

  return ratePlan?.price;
};

/**
 * Takes an array of rate plans, an array of tiers, and calculates the total of them all
 * @param cart - An array of rate plans
 * @param selectedTiers - An array of tiers
 * @returns number
 */
export const calculateOrderTotal = (
  cart: ExtendedSubscriptionRatePlan[] | ExtendedSubscriptionRatePlan[],
  selectedTiers?: ExtendedSubscriptionTier[] | ExtendedSubscriptionTier[],
) => {
  //@ts-ignore
  return cart.reduce(
    (acc: number, curr: ExtendedSubscriptionRatePlan) =>
      (acc += calculateEstimatedRatePlanPrice(
        curr,
        selectedTiers?.find?.(tier => tier?.ratePlanId === curr?.ratePlanId) ||
          selectedTiers?.find?.(tier => tier?.productName === curr.productName),
      ) as number),
    0,
  );
};

/**
 * Checks whether or not a product is a tiered product
 * @param userProduct - The user product object
 */
export const checkProductForTiers = (userProduct: ExtendedSubscriptionStatus | EmptyObject) => {
  return userProduct?.products?.some(product =>
    product?.ratePlans?.some(plan => !isEmpty(plan?.tiers)),
  ) as boolean;
};

/**
 * Returns the values of paywallContentOptions from a product in a single object
 * @param product - The product
 */
export const getPaywallContentOptions = (product: ExtendedSubscriptionProduct) => {
  const options = product?.paywallContentOptions?.map(
    item =>
      item?.key && {
        [item.key]: item?.value,
      },
  );

  if (options) {
    return Object.assign({}, ...options);
  }
};

/**
 * Returns a copy of given rateplans with all available tiers across all rate plans into each available rate plan
 * @param product - An array of all a products active rate plans
 */
export const processAllRatePlanTiers = <T extends Maybe<ExtendedSubscriptionProduct>>(
  product: T,
) => {
  const productCopy = cloneDeep(product);

  const zippedTiers = zip(
    ...(productCopy?.ratePlans as ExtendedSubscriptionRatePlan[]).map(plan =>
      (plan?.tiers as ExtendedSubscriptionTier[]).map(tier => {
        return {
          ...tier,

          productName: product?.name,
          ratePlanId: plan.ratePlanId,
        };
      }),
    ),
  );

  return {
    ...productCopy,
    ratePlans: (productCopy?.ratePlans as ExtendedSubscriptionRatePlan[])?.map(plan => {
      return {
        ...plan,
        productName: productCopy?.name,
        productId: productCopy?.productId,
        tiers: zippedTiers.map(
          miniArray =>
            miniArray.find(tier => {
              return tier?.ratePlanId === plan.ratePlanId;
            }) || miniArray.find(tier => !!tier),
        ),
      };
    }),
  };
};

/**
 * For email product only: returns an amount of users to add to a users email product.
 * @param currentAvailableSeats - The amount of email seats a user currently has
 * @param seatsToPurchase - The amount of seats the user wishes to add to their plan
 */
export const calculateEmailQuantity = (currentAvailableSeats: number, seatsToPurchase: number) => {
  return seatsToPurchase - currentAvailableSeats;
};
