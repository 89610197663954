import React from 'react';
import { Button, Flex, Text } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { RhythmMembership } from '../../../../../services/acg/acg.types';
import {
  useGetAcgQueryArgs,
  useGetInvoicesByUserV2Query,
} from '../../../../../services/acg/acg-api-v2';
import { AcgInvoice, Maybe } from '@companydotcom/types';

export interface TileAcgMembershipViewInvoiceStepProps extends RhythmMembership {}

// TODO: retype this
export const TileAcgMembershipViewInvoiceStep: React.FC<any> = ({
  chapterName,
  invoiceRecordKey,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const args = useGetAcgQueryArgs();
  const { data: invoiceData } = useGetInvoicesByUserV2Query({ ...args });

  const openInvoice = invoiceData?.invoices?.find(
    (invoice: Maybe<AcgInvoice>) => invoice?.invoiceKey === invoiceRecordKey,
  );

  return (
    <Flex
      flexDirection="column"
      width="full"
      flexGrow={1}
      px={4}
      mt={2}
      justifyContent="space-between"
    >
      <Text>
        <Trans i18nKey="acg.membershipStatus.pending.body" values={{ chapterName }}>
          <Text as="span" fontWeight="700" color="error" display="inline">
            Your membership is pending.
          </Text>
          Your order has been submitted to the
          <Text as="span" fontWeight="700" display="inline">
            {chapterName}
          </Text>
        </Trans>
      </Text>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" mt="8.15rem">
        <Button
          variant="tile"
          onClick={() =>
            navigate('/invoices', {
              state: { openInvoices: openInvoice, step: 'invoices-details' },
            })
          }
          size="md"
          type="submit"
        >
          {t(`acg.membershipStatus.pending.cta`)}
        </Button>
      </Flex>
    </Flex>
  );
};
