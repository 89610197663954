import { Center, Box, Text, VStack, OrderedList } from '@companydotcom/potion';
import { Control, UseFormRegister } from 'react-hook-form';
import { EventsFormSliceMapperReturn, FormSliceZone } from '../../shared';

interface MyEventsDemographicQuestionsProps {
  register: UseFormRegister<any>;
  errors: { [x: string]: any };
  control: Control<any>;
  data: EventsFormSliceMapperReturn;
  variant?: 'single' | 'multiple';
  watchAllFields: any;
  setValue: any;
}

export const MyEventsDemographicQuestions = ({
  register,
  errors,
  control,
  data,
  variant,
  watchAllFields,
  setValue,
}: MyEventsDemographicQuestionsProps) => {
  return (
    <Center
      className="myEvents__demographicsQuestions"
      flexDirection="column"
      bg={['transparent', 'white']}
      pt={[0, 8, 12]}
    >
      <Box width="full" maxW={908}>
        <Box width="full" textAlign={['center', 'left']} mb={7}>
          <Text
            mb={6}
            lineHeight={1.25}
            textStyle={['lg', variant === 'single' ? '3xl' : 'xl']}
            fontWeight="bold"
            opacity={0.7}
          >
            Questions from the Event Organizers
          </Text>
        </Box>

        <VStack align="flex-start">
          <OrderedList width="full" spacing={[6, 12]} stylePosition="outside" fontWeight="medium">
            <FormSliceZone
              wrapInList
              register={register}
              errors={errors}
              control={control}
              data={data}
              watchAllFields={watchAllFields}
              setValue={setValue}
            />
          </OrderedList>
        </VStack>
      </Box>
    </Center>
  );
};
