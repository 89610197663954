import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Text,
  HStack,
  Divider,
  GridItem,
  Button,
  Link,
  Alert,
  AlertIcon,
  SkeletonText,
  Skeleton,
  Heading,
  useMediaQuery,
  Checkbox,
} from '@companydotcom/potion';
import { getSymbolFromCurrency } from '@companydotcom/helpers';
import { PageDivider, SelectField } from '@companydotcom/ui';
import { useSource } from '@companydotcom/providers';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import valid from 'card-validator';
import { User } from '@companydotcom/types';
import {
  CreditCardForm,
  ACHForm,
  getCheckoutOrderSchema,
} from '../../../../components/forms/checkout';
import { useAppDispatch, useAppSelector, useScript } from '../../../../hooks';
import {
  setOrderConfirmationData,
  getRenewInvoice,
  getEventForPurchase,
  getEventDiscount,
  setEventForPurchase,
  setEventDiscount,
  setDiscountCodeString,
} from '../checkout-slice';
import { Coupon, PurchaseTypes } from '../../../../services/acg/acg.types';
import {
  useDoPaymentMutation,
  useSetAutoRenewOnMembershipMutation,
  useDoPaymentForOpenInvoiceMutation,
  useAddOrUpdateSavedPaymentsMutation,
  useGetSavedPaymentMethodsQuery,
  useLazyGetEventForRegistrationQuery,
} from '../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  getCheckoutOrderData,
  getPaymentMethodTypeFromCardType,
  maskCreditCardNumber,
  savedPaymentIconMap,
  transformSavedPaymentLabels,
} from '../utils';
import type { CheckoutLocationStateProps, CheckoutSharedProps } from './checkout';
import { useAcgCartStorage, getEventFeeList, checkEventStatus } from '../../shared';
import { resetGuestsToRegister } from '../../acg-add-guest';
import {
  CheckoutLayout,
  CheckoutAlertContent,
  CheckoutSecureAndEncrypted,
  CheckoutContent,
  CheckoutPaymentOptionsHeader,
  CheckoutOrderStepOrderSummary,
  CheckoutDiscountAndPlaceOrder,
} from '../../../../components/layouts/checkout-layout/checkout-layout';
import { EventFullAlert } from '../../shared/components/event-full-alert';

export const CheckoutOrder: React.FC<CheckoutSharedProps> = props => {
  const { t } = useTranslation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const source = useSource();
  const dispatch = useAppDispatch();

  const renewalInvoice = useAppSelector(getRenewInvoice);
  const eventForPurchase = useAppSelector(getEventForPurchase);
  const [userErrorMsg, setUserErrorMsg] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const { goToStep, onSuccessfulPurchase } = props;
  const navigate = useNavigate();
  const { data: paymentData, isLoading: isPaymentDataLoading } = useGetSavedPaymentMethodsQuery(
    globalUser
      ? {
          user: globalUser,
        }
      : skipToken,
  );
  const [setAutoRenewOnMembership, { isLoading: isAutoRenewLoading }] =
    useSetAutoRenewOnMembershipMutation();
  const [savePaymentMethod, { isLoading: isSavingPayment }] = useAddOrUpdateSavedPaymentsMutation();
  const [doPayment, { isError, isLoading }] = useDoPaymentMutation();
  const [doRenewPayment, { isLoading: isRenewPaymentLoading }] =
    useDoPaymentForOpenInvoiceMutation();
  const isPaymentProcessing = useMemo(
    () => isLoading || isAutoRenewLoading || isSavingPayment || isRenewPaymentLoading,
    [isAutoRenewLoading, isLoading, isRenewPaymentLoading, isSavingPayment],
  );
  const [getEvent, { isFetching }] = useLazyGetEventForRegistrationQuery();
  const location = useLocation();
  const locationState = useLocation().state as CheckoutLocationStateProps;

  const { getLocalCart, storePaymentInfo, clearLocalCart } = useAcgCartStorage();
  const cart = getLocalCart();
  const chapterPackage = useMemo(() => cart?.items?.memberships?.[0], [cart]);
  const event = useMemo(() => cart?.items?.event, [cart]);
  const currencySymbol = useMemo(
    () =>
      !chapterPackage?.currencySymbol ||
      !event?.registrant?.fees?.[0].eventFees.currencySymbol ||
      !event?.guests?.[0]?.fees?.[0].currencySymbol
        ? getSymbolFromCurrency(
            chapterPackage?.currencyCode ||
              event?.registrant?.fees?.[0].eventFees.currencyCode ||
              event?.guests?.[0]?.fees?.[0].currencyCode,
          )
        : chapterPackage?.currencySymbol ||
          event?.registrant.fees?.[0].eventFees.currencySymbol ||
          event?.guests?.[0]?.fees?.[0].currencySymbol,
    [
      chapterPackage?.currencyCode,
      chapterPackage?.currencySymbol,
      event?.guests,
      event?.registrant?.fees,
    ],
  );

  const eventDiscount = useAppSelector(getEventDiscount);
  const membershipDiscount = useMemo(
    () => chapterPackage?.coupons ?? chapterPackage?.coupons,
    [chapterPackage?.coupons],
  );
  // console.log('chapterPackage:', chapterPackage);
  // console.log('membershipDiscount:', membershipDiscount);
  const discounts =
    eventDiscount && eventDiscount.length
      ? eventDiscount
      : membershipDiscount && membershipDiscount.length
      ? membershipDiscount
      : [];

  // console.log('discounts:', discounts);
  const [, error] = useScript({
    src: 'https://secure.bluepay.com/v3/bluepay.js',
    checkForExisting: true,
  });
  const BluePay = window?.['BluePay'] ?? null;

  useEffect(() => {
    let mounted = true;
    if (BluePay && !error && mounted) {
      BluePay.setCredentials('200025930923', '1f76bb3d84ba20530f312254c2a5889c');
    }
    return () => {
      mounted = false;
    };
  }, [error, BluePay]);

  const eventFeeList = useMemo(() => {
    if (eventForPurchase) {
      return getEventFeeList(eventForPurchase);
    }

    return undefined;
  }, [eventForPurchase]);

  const isCanadian =
    renewalInvoice?.some?.((i: any) => i?.currencyCode === 'CAD') ||
    eventFeeList?.some?.((i: any) => i?.currency === 'CAD') ||
    chapterPackage?.chapterId === 'CAL' ||
    chapterPackage?.chapterId === 'EDN';

  const defaultValues = {
    paymentMethod: isCanadian
      ? paymentData?.savedPaymentMethods?.find(i => i.paymentMethodType.includes('CAD'))
          ?.recordKey || 'credit card'
      : paymentData?.savedPaymentMethods?.[0]?.recordKey || 'credit card',
    savePaymentInformation: false,
    autoRenew: false,
    monthlyInstallments: false,
    cardNickname: paymentData?.savedPaymentMethods?.[0]?.paymentNickName || '',
    cardDetails: {
      cardHolderName: paymentData?.savedPaymentMethods?.[0]?.cardHolderName || '',
      cardNumber: paymentData?.savedPaymentMethods?.[0]?.cardNumber || '',
      expirationMonth:
        paymentData?.savedPaymentMethods?.[0]?.cardExpiry?.split('/')?.[1] ??
        `${new Date().getMonth() + 1}`,
      expirationYear:
        paymentData?.savedPaymentMethods?.[0]?.cardExpiry?.split('/')?.[0] ??
        new Date().getFullYear().toString(),
      cardCvv: '',
    },
    achDetails: {
      accountHolderName: '',
      accountNumber: '',
      accountType: '',
      routingNumber: '',
    },
    billingDetails: {
      country: 'United States',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
    },
  };

  const nonMemberFee = useMemo(() => {
    // todo: uncomment when NetForum supports this in production
    // if (eventFeeList) {
    //   return eventFeeList.reduce((acc, cur) => {
    //     const lowerCaseProduct = cur?.product.toLowerCase();
    //     if (
    //       lowerCaseProduct.includes('non-member') ||
    //       lowerCaseProduct.includes('non member') ||
    //       lowerCaseProduct.includes('nonmember')
    //     ) {
    //       acc = acc + 10;
    //     }

    //     return acc;
    //   }, 0);
    // }

    return 0;
  }, []);

  const subtotal = useMemo(() => {
    if (chapterPackage) {
      return chapterPackage?.nettToPay || chapterPackage?.total;
    }
    if (eventFeeList) {
      const result = eventFeeList?.reduce((acc: any, cur: any) => {
        acc = acc + (cur.price || 0);
        return acc;
      }, nonMemberFee);

      return result;
    }
    return 0;
  }, [chapterPackage, eventFeeList, nonMemberFee]);

  const total = useMemo(() => {
    let discountTotal = 0;
    if (nonMemberFee) {
      return (subtotal + nonMemberFee).toFixed(2);
    }
    if (eventDiscount.length) {
      discountTotal = eventDiscount.reduce((acc, cur: any) => {
        acc = acc + cur.discount;
        return acc;
      }, 0);
      return subtotal - discountTotal;
    }
    if (membershipDiscount && membershipDiscount.length) {
      discountTotal = membershipDiscount.reduce((acc: any, cur: any) => {
        acc = acc + cur.discount;
        return acc;
      }, 0);
      return subtotal - discountTotal;
    }
    return subtotal;
  }, [eventDiscount, membershipDiscount, nonMemberFee, subtotal]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    setError,
    reset,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: total === 0 ? undefined : yupResolver(getCheckoutOrderSchema()),
    defaultValues: defaultValues ?? {},
  });

  const paymentMethodOptions =
    // TODO uncomment when rhythm supports ach
    // isCanadian ?
    [{ label: t('acg.checkout.orderStep.creditCard'), value: 'credit card' }];
  // : [
  //     { label: t('acg.checkout.orderStep.creditCard'), value: 'credit card' },
  //     { label: t('acg.checkout.orderStep.ach'), value: 'ach' },
  //   ];

  const selectedPaymentMethod = useWatch({ name: 'paymentMethod', control });
  const selectedCreditCard = useWatch({ name: 'cardDetails.cardNumber', control });
  const autopayRenewalChecked = useWatch({ name: 'autoRenew', control });
  const savePaymentInformationChecked = useWatch({ name: 'savePaymentInformation', control });
  const selectedYear = useWatch({ name: 'cardDetails.expirationYear', control });
  const selectedMonth = useWatch({ name: 'cardDetails.expirationMonth', control });

  useEffect(() => {
    if (selectedYear || selectedMonth) {
      trigger(['cardDetails.expirationMonth', 'cardDetails.expirationYear']);
    }
  }, [selectedMonth, selectedYear, trigger]);

  // Automatically check and disable the "Save Payment Information" checkbox if the user is choosing to auto renew.
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (selectedPaymentMethod === 'credit card' || selectedPaymentMethod === 'ach') {
        if (autopayRenewalChecked) {
          setValue('savePaymentInformation', true, { shouldValidate: true });
        } else {
          setValue('savePaymentInformation', false, { shouldValidate: true });
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [autopayRenewalChecked, reset, selectedPaymentMethod, setValue]);

  // This useEffect ensures that when paymentData is done loading, if theres a savedPayment, its set as the default paymentMethod
  useEffect(() => {
    if (!isPaymentDataLoading) {
      reset({
        paymentMethod: paymentData?.savedPaymentMethods?.some(p => p?.preferred === '1')
          ? paymentData?.savedPaymentMethods?.find(p => p?.preferred === '1')?.recordKey
          : isCanadian
          ? paymentData?.savedPaymentMethods?.find(i => i.paymentMethodType.includes('CAD'))
              ?.recordKey || 'credit card'
          : paymentData?.savedPaymentMethods?.[0]?.recordKey || 'credit card',
      });
    }
  }, [isCanadian, isPaymentDataLoading, paymentData?.savedPaymentMethods, reset]);

  // We should also reset certain fields if the user selects a new credit card or ach
  useEffect(() => {
    if (selectedPaymentMethod === 'credit card' || selectedPaymentMethod === 'ach') {
      reset({
        paymentMethod: selectedPaymentMethod,
        cardNickname: '',
        cardDetails: {
          cardHolderName: '',
          cardNumber: '',
          cardCvv: '',
          expirationMonth: `0${new Date().getMonth() + 1}`,
          expirationYear: new Date().getFullYear().toString(),
        },
        achDetails: {
          accountHolderName: '',
          accountNumber: '',
          accountType: '',
          routingNumber: '',
        },
      });
    }
  }, [reset, selectedPaymentMethod]);

  // Will be rerouted if there is not cart or there is nothing to purchase
  useEffect(() => {
    if (
      !chapterPackage &&
      !eventForPurchase &&
      !cart?.items?.event &&
      !cart?.items?.memberships?.length
    ) {
      navigate('/join');
    }

    if (
      !chapterPackage &&
      !eventForPurchase &&
      (cart?.items?.event || cart?.items?.memberships?.length)
    ) {
      navigate('/acg-shopping-cart');
    }
  }, [cart, chapterPackage, eventForPurchase, navigate]);

  const onSubmit = async (values: typeof defaultValues) => {
    const isSavedPaymentMethod = !!values?.paymentMethod;
    let doPaymentStartTime: any;
    let doPaymentEndTime: any;

    let logErrorMsg: any = '';
    try {
      if (!globalUser) {
        throw new Error('global user info data missing');
      }

      // A user can only have 5 total saved payment methods.
      if (
        values?.savePaymentInformation &&
        paymentData?.savedPaymentMethods &&
        paymentData?.savedPaymentMethods?.length >= 5
      ) {
        setPaymentError(true);
        throw 'Error in saving payment method: You may only have 5 saved payment information records, you currently have 5. Please remove 1 or more before adding a new one.';
      }

      const selectedPaymentOption =
        isSavedPaymentMethod && total !== 0
          ? values?.paymentMethod
          : getPaymentMethodTypeFromCardType(
              selectedPaymentMethod === 'ach'
                ? 'ach'
                : (valid.number(selectedCreditCard).card?.type as PurchaseTypes),
              isCanadian ? 'CAD' : 'USD',
            );

      if (!selectedPaymentOption && total !== 0) {
        setPaymentError(true);
        throw new Error(`Selected payment data missing: ${selectedPaymentOption}`);
      }

      // check to see if event still has capacity before proceeding
      if (cart?.items?.event && cart?.items?.event?.eventRecordKey) {
        const latestEventData = await getEvent({
          user: globalUser,
          eventId: cart?.items?.event?.eventRecordKey,
        }).unwrap();

        const {
          shouldShouldWaitlistAlert,
          shouldShowEventFullAlert,
          shouldShowEventOverCapacityAlert,
        } = checkEventStatus(latestEventData, cart?.items?.event?.guests?.length);

        if (
          shouldShouldWaitlistAlert ||
          shouldShowEventFullAlert ||
          shouldShowEventOverCapacityAlert
        ) {
          location.state = {
            shouldShouldWaitlistAlert,
            shouldShowEventFullAlert,
            shouldShowEventOverCapacityAlert,
          };
          goToStep?.('checkout-order-event-error');
        }
      }

      const orderData = getCheckoutOrderData<typeof defaultValues>(
        values,
        selectedPaymentMethod,
        paymentData?.savedPaymentMethods,
      );

      if (total === 0) {
        await processZeroTotalOrders(values, globalUser);
      } else if (orderData?.merchantToken) {
        // If using a saved payment method, already contains tokens
        await processOrderWithSavedPayment({ ...orderData, autoRenew: values?.autoRenew });
      } else {
        const tokenData =
          // TODO uncomment when rhythm supports ach
          // values?.paymentMethod === 'ach'
          //   ? {
          //       PAYMENT_TYPE: 'ACH',
          //       ACH_ACCOUNT: values?.achDetails?.accountNumber,
          //       ACH_ROUTING: values?.achDetails?.routingNumber,
          //       ACH_ACCOUNT_TYPE: values?.achDetails?.accountType,
          //       NAME1: values?.achDetails?.accountHolderName,
          //     }
          //   :
          {
            CARD_ACCOUNT: values?.cardDetails?.cardNumber.split(' ').join(''),
            CARD_CVV2: values?.cardDetails?.cardCvv,
            EXPMO: values?.cardDetails?.expirationMonth,
            EXPYR: values?.cardDetails?.expirationYear,
          };

        doPaymentStartTime = Date.now();
        await BluePay?.createToken(tokenData, async (res: any) => {
          if (
            res?.MESSAGE === 'INFORMATION STORED' &&
            typeof res.TRANS_ID !== 'undefined' &&
            res.TRANS_ID.length > 0
          ) {
            let response;
            const tokens = {
              merchantAccountId: 'ACG-PRIMARY',
              merchantToken: res.TRANS_ID,
            };
            doPaymentStartTime = Date.now();
            if (chapterPackage && !renewalInvoice) {
              response = await doPayment({
                user: globalUser,
                payload: {
                  paymentInfo: {
                    ...orderData,
                    ...tokens,
                  },
                },
              }).unwrap();
              doPaymentEndTime = Date.now();
            }

            if (renewalInvoice) {
              response = await doRenewPayment({
                user: globalUser,
                payload: {
                  invoiceId: chapterPackage?.openInvoices?.invoiceRecordKey,
                  paymentInfo: {
                    ...orderData,
                    ...tokens,
                  },
                },
              }).unwrap();
              doPaymentEndTime = Date.now();
            }
            if (eventForPurchase) {
              setUserErrorMsg(false);
              response = await doPayment({
                user: globalUser,
                payload: { paymentInfo: total === 0 ? {} : { ...orderData, ...tokens } },
              }).unwrap();
              doPaymentEndTime = Date.now();
              if (response && !response.error && goToStep) {
                dispatch(setOrderConfirmationData(response));
                onSuccessfulPurchase?.();
                dispatch(resetGuestsToRegister([]));
                goToStep('checkout-order-confirmation');
              } else {
                setUserErrorMsg(true);
                setPaymentError(true);
                logErrorMsg =
                  response && response.error
                    ? response.error
                    : 'Failed at event purchase doPayment call';
                throw 'Unable to process the payment, User is Inelegible for Session';
                // throw  "Unable to process the payment, User is Inelegible for Session";
              }
            }

            if (response && (autopayRenewalChecked || values?.savePaymentInformation)) {
              await Promise.all([
                setAutoRenewOnMembership({
                  user: globalUser,
                  payload: {
                    autoRenew: values?.autoRenew,
                    paymentInfo: {
                      ...orderData,
                      ...tokens,
                    },
                  },
                }),
                savePaymentMethod({
                  user: globalUser,
                  payload: {
                    paymentInfo: {
                      cardExpiry:
                        values?.paymentMethod === 'ach'
                          ? undefined
                          : `${values?.cardDetails?.expirationYear}-${values?.cardDetails?.expirationMonth}`,
                      ...orderData,
                      ...tokens,
                    },
                  },
                }),
              ]);
            }
            dispatch(setOrderConfirmationData(response));
            // Stores payment info temporarily in localStorage for order confirmation
            storePaymentInfo({
              cardExpiry: `${values?.cardDetails?.expirationYear}/${values?.cardDetails?.expirationMonth}`,
              cardNumber: maskCreditCardNumber(values?.cardDetails?.cardNumber),
              cardHolderName: values?.cardDetails?.cardHolderName,
              accountNumber: maskCreditCardNumber(values?.achDetails?.accountNumber),
              accountHolderName: values?.achDetails?.accountHolderName,
              routingNumber: maskCreditCardNumber(values?.achDetails?.routingNumber),
              accountType: values?.achDetails?.accountType,
            });
            onSuccessfulPurchase?.();
            if (chapterPackage?.chapterTypeName?.includes('Approval Chapter')) {
              goToStep?.('checkout-order-pending');
            } else {
              goToStep?.('checkout-order-confirmation');
            }
          } else {
            setPaymentError(true);
            // added sentry exception if createToken fails
            Sentry.captureException(res, scope => {
              scope.setTransactionName('BluepayCheckoutOrder');
              scope.setTag('bluepayError', 'createTokenError');
              scope.setContext('Payment', {
                payment_method: getPaymentMethodTypeFromCardType(
                  valid.number(selectedCreditCard).card?.type as PurchaseTypes,
                  isCanadian ? 'CAD' : 'USD',
                ),
                currency: isCanadian ? 'CAD' : 'USD',
                card_number: maskCreditCardNumber(values?.cardDetails?.cardNumber),
                email: globalUser?.email,
                userId: globalUser?.userId,
                doPaymentStartTime,
                doPaymentEndTime,
                tokenCreationInput: JSON.stringify(tokenData),
                tokenResponse: JSON.stringify(res),
                error: logErrorMsg,
              });
              return scope;
            });
            if (res) {
              throw new Error(JSON.stringify(res));
            } else {
              throw 'Error occured: Unable to make payment';
            }
          }
        });
      }
      // local start and endtime do payment sentry
    } catch (err) {
      Sentry.captureException(err, scope => {
        scope.setTransactionName('BluepayCheckoutOrder');
        scope.setTag('api', 'bluepay');
        scope.setContext('Payment', {
          payment_method: getPaymentMethodTypeFromCardType(
            valid.number(selectedCreditCard).card?.type as PurchaseTypes,
            isCanadian ? 'CAD' : 'USD',
          ),
          currency: isCanadian ? 'CAD' : 'USD',
          card_number: maskCreditCardNumber(values?.cardDetails?.cardNumber),
          email: globalUser?.email,
          userId: globalUser?.userId,
          doPaymentStartTime,
          doPaymentEndTime: doPaymentEndTime ? doPaymentEndTime : Date.now(),
          error: err ? JSON.stringify(err) : logErrorMsg,
        });
        return scope;
      });
      setPaymentError(true);
      window.scrollTo(0, 0);
      console.error('Error completing purchase: ', err);
    }
  };

  const processOrderWithSavedPayment = async (data: any) => {
    let doPaymentStartTime: any;
    let doPaymentEndTime: any;
    try {
      if (globalUser) {
        let savedPaymentResonse;
        doPaymentStartTime = Date.now();
        if (chapterPackage && !renewalInvoice) {
          savedPaymentResonse = await doPayment({
            user: globalUser,
            payload: {
              paymentInfo: data,
            },
          }).unwrap();
          doPaymentEndTime = Date.now();
        }

        if (renewalInvoice) {
          savedPaymentResonse = await doRenewPayment({
            user: globalUser,
            payload: {
              invoiceId: chapterPackage?.openInvoices?.invoiceRecordKey,
              paymentInfo: data,
            },
          }).unwrap();
          doPaymentEndTime = Date.now();
        }

        if (eventForPurchase) {
          savedPaymentResonse = await doPayment({
            user: globalUser,
            payload: { paymentInfo: total === 0 ? {} : data },
          }).unwrap();
          doPaymentEndTime = Date.now();

          if (savedPaymentResonse && goToStep) {
            dispatch(setOrderConfirmationData(savedPaymentResonse));
            storePaymentInfo({
              cardExpiry: `${data?.cardExpiry?.split('-')?.join('/')}`,
              cardNumber: data?.cardNumber,
              cardHolderName: data?.cardHolderName,
              accountNumber: data?.accountNumber,
              accountHolderName: data?.accountHolderName,
              accountType: data?.accountType,
            });
            onSuccessfulPurchase?.();
            dispatch(resetGuestsToRegister([]));
            goToStep('checkout-order-confirmation');
            return;
          }
        }
        dispatch(setOrderConfirmationData(savedPaymentResonse));
        storePaymentInfo({
          cardExpiry: `${data?.cardExpiry?.split('-')?.join('/')}`,
          cardNumber: data?.cardNumber,
          cardHolderName: data?.cardHolderName,
          accountNumber: data?.accountNumber,
          accountHolderName: data?.accountHolderName,
          accountType: data?.accountType,
          autoRenew: data?.autoRenew,
        });
        onSuccessfulPurchase?.();
        if (chapterPackage?.chapterTypeName?.includes('Approval Chapter')) {
          goToStep?.('checkout-order-pending');
        } else {
          goToStep?.('checkout-order-confirmation');
        }
      }
    } catch (err) {
      setPaymentError(true);
      // added sentry error log
      Sentry.captureException(err, scope => {
        scope.setTransactionName('BluepayCheckoutOrder -> processOrderWithSavedPayment');
        scope.setTag('api', 'bluepay');
        scope.setContext('Payment', {
          payment_method: getPaymentMethodTypeFromCardType(
            valid.number(selectedCreditCard).card?.type as PurchaseTypes,
            isCanadian ? 'CAD' : 'USD',
          ),
          currency: isCanadian ? 'CAD' : 'USD',
          card_number: maskCreditCardNumber(data?.cardNumber),
          email: globalUser?.email,
          userId: globalUser?.userId,
          doPaymentStartTime,
          doPaymentEndTime,
          error: JSON.stringify(err),
        });
        return scope;
      });
      window.scrollTo(0, 0);
      console.error('Error completing purchase: ', err);
    }
  };

  const cancelOrderHandler = useCallback(() => {
    clearLocalCart();
    dispatch(resetGuestsToRegister([]));
    dispatch(setDiscountCodeString(''));
    dispatch(setEventDiscount([]));
    dispatch(setEventForPurchase({}));
    setIsCancelled(true);
  }, [clearLocalCart, dispatch]);

  const processZeroTotalOrders = async (values: { autoRenew?: boolean }, user: User) => {
    const response = await doPayment({
      user,
      payload: {
        paymentInfo: {
          autoRenew: values?.autoRenew,
        },
      },
    }).unwrap();

    dispatch(setOrderConfirmationData(response));
    onSuccessfulPurchase?.();
    goToStep?.('checkout-order-confirmation');
  };

  return (
    <CheckoutLayout>
      <Box className="checkout__checkoutHeader" width="full" textAlign={['center', 'left']}>
        <Heading size={isMobile ? 'hs-md' : 'hs-2xl'}>Checkout</Heading>
        <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
      </Box>
      <CheckoutAlertContent>
        {chapterPackage?.displayName && (
          <Alert status="warning" borderRadius="md" mb={6}>
            <AlertIcon />
            <Text>
              Thank you for your interest in renewing with the{' '}
              <Text as="span" fontWeight="bold">
                {chapterPackage?.displayName}{' '}
              </Text>
              chapter. Below is your renewal information.
            </Text>
          </Alert>
        )}
        {chapterPackage?.chapterTypeName?.includes('Approval Chapter') && (
          <Alert status="warning" textAlign="left" mb={4}>
            <AlertIcon />
            <Text textStyle="sm">
              <Text as="span" fontWeight="bold">
                {chapterPackage?.productName}{' '}
              </Text>
              requires final approval from the{' '}
              <Text as="span" fontWeight="bold">
                {chapterPackage?.chapterName}{' '}
              </Text>
              chapter.
            </Text>
          </Alert>
        )}
        <Box fontSize="sm">
          {total !== 0 && (
            <Text textStyle="sm">{t('acg.checkout.orderStep.pleaseEnterPaymentInformation')}</Text>
          )}
          <Trans i18nKey="acg.checkout.orderStep.agreeToTerms">
            <Text textStyle="sm">
              By clicking Place Order, you are agreeing to the
              <Link
                sx={{ display: 'inline' }}
                href={source?.footerLinks?.termsOfUse ?? ''}
                isExternal
              >
                Terms and Conditions.
              </Link>
            </Text>
          </Trans>
        </Box>
        <CheckoutSecureAndEncrypted />

        {(isError || paymentError) && (
          <Alert variant="solid" status="error" textAlign="left" mt={12}>
            <AlertIcon />
            <Text textStyle="sm">
              {userErrorMsg && userErrorMsg === true
                ? t('acg.checkout.orderStep.paymentDeclineError')
                : t('acg.checkout.orderStep.unableToCompleteOrder')}
            </Text>
          </Alert>
        )}

        {isCancelled && <EventFullAlert variant="eventOverCapacity" isCancelled />}
      </CheckoutAlertContent>
      <>
        {!isCancelled ? (
          <CheckoutContent>
            <GridItem as="form">
              {total === 0 ? (
                <Box>
                  <Text fontSize={{ base: '2xl', sm: '3xl' }} fontWeight="bold">
                    There is no cost associated with your event registration. Please Place Order to
                    complete your registration.
                  </Text>
                </Box>
              ) : (
                <>
                  <CheckoutPaymentOptionsHeader source="acg" />
                  {((isCanadian &&
                    paymentData?.savedPaymentMethods?.some(p =>
                      p.paymentMethodType.includes('CAD'),
                    )) ||
                    !isCanadian) &&
                    (paymentData?.savedPaymentMethods && paymentData?.savedPaymentMethods.length ? (
                      <>
                        <SelectField
                          isLoading={isPaymentDataLoading || !globalUser}
                          register={register}
                          name="paymentMethod"
                          label="Payment Method"
                          formControlStyles={{ maxW: ['100%'] }}
                        >
                          {transformSavedPaymentLabels(
                            paymentData?.savedPaymentMethods?.filter(p =>
                              isCanadian
                                ? p.paymentMethodType.includes('CAD')
                                : !p.paymentMethodType.includes('CAD'),
                            ),
                          )
                            .concat(
                              paymentData?.savedPaymentMethods &&
                                paymentData?.savedPaymentMethods?.length >= 5
                                ? []
                                : paymentMethodOptions,
                            )
                            .map(
                              (
                                opt: any, //TODO: retype
                              ) => (
                                <option key={opt.value} value={opt.value}>
                                  {opt.label}
                                </option>
                              ),
                            )}
                        </SelectField>
                        {selectedPaymentMethod !== 'ach' &&
                          selectedPaymentMethod !== 'credit card' &&
                          !event && (
                            <Checkbox isDisabled={isLoading} {...register('autoRenew')}>
                              {t('acg.checkout.orderStep.inputs.autopayRenewal.label')}
                            </Checkbox>
                          )}
                        {selectedPaymentMethod !== 'ach' &&
                          selectedPaymentMethod === 'credit card' && (
                            <HStack maxH={6} mt={2}>
                              {savedPaymentIconMap(
                                paymentData?.savedPaymentMethods?.some(
                                  pm => pm.recordKey === selectedPaymentMethod,
                                )
                                  ? valid.number(
                                      paymentData?.savedPaymentMethods?.find(
                                        pm => pm.recordKey === selectedPaymentMethod,
                                      )?.cardNumber,
                                    )?.card?.type
                                  : undefined,
                              )}
                            </HStack>
                          )}
                      </>
                    ) : null)}
                  {selectedPaymentMethod !== 'ach' ? (
                    <CreditCardForm
                      isCheckout
                      showAutoRenew={!eventFeeList}
                      isAutoRenewChecked={autopayRenewalChecked}
                      displayBillingAddressForm={savePaymentInformationChecked}
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      setError={setError}
                      selectedCreditCard={valid.number(selectedCreditCard)}
                      pendingApproval={chapterPackage?.chapterTypeName?.includes(
                        'Approval Chapter',
                      )}
                      register={register}
                      errors={errors}
                      isLoading={isPaymentDataLoading || !globalUser}
                      getValues={getValues}
                      isSavedPayment={
                        watch('paymentMethod') !== 'ach' && watch('paymentMethod') !== 'credit card'
                      }
                      showSavePaymentMethodRadio
                    />
                  ) : (
                    <ACHForm
                      isCheckout
                      showAutoRenew={!eventFeeList}
                      displayBillingAddressForm={savePaymentInformationChecked}
                      control={control}
                      watch={watch}
                      pendingApproval={chapterPackage?.chapterTypeName?.includes(
                        'Approval Chapter',
                      )}
                      register={register}
                      errors={errors}
                      isAutoRenewChecked={autopayRenewalChecked}
                      setValue={setValue}
                      getValues={getValues}
                      isSavedPayment={
                        watch('paymentMethod') !== 'ach' && watch('paymentMethod') !== 'credit card'
                      }
                    />
                  )}
                </>
              )}
            </GridItem>
            <CheckoutOrderStepOrderSummary bgColor="#F2F2F2">
              {!chapterPackage && !eventFeeList && <SkeletonText noOfLines={2} />}
              {(chapterPackage?.displayName || chapterPackage?.productName) && (
                <Box>
                  <Text textStyle="lg" mt={[4, 6]}>
                    {chapterPackage?.displayName || chapterPackage?.productName}
                  </Text>
                  {membershipDiscount ? (
                    <Text
                      textStyle="lg"
                      fontWeight="bold"
                      mt={1}
                    >{`${currencySymbol}${chapterPackage?.lineItems[0]?.unitPrice.toFixed(
                      2,
                    )}`}</Text>
                  ) : (
                    <Divider my={[4, 6]} borderColor="#D1D1D1" />
                  )}
                </Box>
              )}
              {eventFeeList && (
                <>
                  {eventFeeList.map((eventFee: any, i: number) => (
                    <Box key={i}>
                      <Text textStyle="lg" mt={[4, 6]}>
                        {eventFee?.productName || eventFee?.sessionName}
                      </Text>
                      <Text textStyle="lg" fontWeight="bold" mt={1}>
                        {`$${(eventFee?.price || 0.0).toFixed(2)}`}
                      </Text>
                    </Box>
                  ))}
                  <Divider my={[4, 6]} borderColor="#D1D1D1" />
                </>
              )}
              {nonMemberFee > 0 && (
                <Box>
                  <Text textStyle="lg" mt={[4, 6]}>
                    Non-Member Transaction Fee
                  </Text>
                  <Text textStyle="lg" fontWeight="bold" mt={1}>{`$${nonMemberFee.toFixed(
                    2,
                  )}`}</Text>
                </Box>
              )}
              {discounts?.length > 0 && (
                <Box>
                  <Text textStyle="lg" mt={[4, 6]}>
                    Subtotal
                  </Text>
                  <Text textStyle="lg" fontWeight="bold" mt={1}>
                    {cart && !event && !cart.items?.memberships?.length
                      ? '-'
                      : `${currencySymbol}${subtotal?.toFixed(2)}`}
                  </Text>
                  <Text textStyle="lg" mt={[4, 6]}>
                    Discounts Applied
                  </Text>
                  {discounts?.map((discountApplied: Coupon, i: number) => (
                    <Box key={i}>
                      <Text textStyle="lg" color="red.500" mt={1} textTransform="uppercase">
                        {discountApplied?.couponTypeId}
                      </Text>
                      <Text
                        textStyle="lg"
                        fontWeight="bold"
                        mt={1}
                      >{`-${currencySymbol}${discountApplied?.discount?.toFixed(2)}`}</Text>
                    </Box>
                  ))}
                  <Divider my={[4, 6]} borderColor="#D1D1D1" />
                </Box>
              )}
              <Text textStyle="2xl" fontWeight="bold">
                {t('acg.checkout.orderPendingStep.pendingTotal')}
              </Text>
              <Skeleton isLoaded={!!chapterPackage || !!eventForPurchase}>
                <Text mt={1} textStyle="2xl" fontWeight="bold">
                  {`${currencySymbol}${total.toFixed(2)}`}
                </Text>
              </Skeleton>
            </CheckoutOrderStepOrderSummary>
          </CheckoutContent>
        ) : null}
      </>
      {!isCancelled && (
        <CheckoutDiscountAndPlaceOrder>
          {locationState?.shouldShowEventOverCapacityAlert && (
            <Button variant="outline" size="lg" onClick={cancelOrderHandler}>
              Cancel Order
            </Button>
          )}
          <Button
            width={['full', '292px']}
            isLoading={isSubmitting || isPaymentProcessing || isFetching}
            isDisabled={(!isValid && total !== 0) || (!chapterPackage && !eventForPurchase)}
            size="lg"
            onClick={handleSubmit(onSubmit)}
          >
            {t('acg.checkout.orderStep.placeOrder')}
          </Button>
        </CheckoutDiscountAndPlaceOrder>
      )}
    </CheckoutLayout>
  );
};
