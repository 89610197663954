import type { User, AcgUserProfile } from '@companydotcom/types';
import { v4 as uuidv4 } from 'uuid';
import { platformHelpers } from '@companydotcom/helpers';
import {
  AddToCartInput,
  RecommendedEventsPayload,
  AddGuestsToRegisteredEvent,
  StoreRhythmTokenAndContactId,
  SubmitMembershipApplication,
  DoPayment,
  AddOrUpdateStoredPaymentPayload,
  HiddenField,
  // AcgReferenceDataPayload,
  UpdateRegisteredEventPayload,
  SubmitEventApplication,
} from './acg.types';
// import { useAwaitableFacade } from '../../hooks';

import { publishTileEvent } from '../event/old-api/event-svc';
import { fetchLargePayload, subscribeToNotifications } from '../notification/notification-svc';

/**
 * Refactored to awaitableFacade hook, removed after testing
 * @param user
 * @returns
 */

const SnsCachedAwaitableFacade = (user?: Partial<User> | undefined) => {
  const cache = {};
  let internalUser = user;
  const promiseResolvers = {};

  const updateUser = (user: Partial<User>) => {
    internalUser = user;
  };

  const emitEvent = async (
    tile: { productId: string; tileId: string },
    eventType: string,
    action: string,
    payload: any,
    metadata: any,
  ) => {
    const eventId = uuidv4();
    const event = platformHelpers.formatPublishTileEventInput(
      {
        eventType: action,
        component: null,
        topic: eventType,
        componentTypes: ['AcgSvc', action],
        ...metadata,
      },
      {
        user: internalUser,
        tile,
      },
      payload,
      eventType,
      eventId,
    );
    await publishTileEvent(event);
    return eventId;
  };

  // eventType, fetch/transition, payload
  const getNotifData = async (
    { productId, tileId }: { productId: string; tileId: string },
    eventType: string,
    action: string,
    payload: any,
    metadata: any,
    bypassCache: boolean,
  ) => {
    const stringifiedPayload = JSON.stringify(payload);
    const eventId = await emitEvent({ productId, tileId }, eventType, action, payload, metadata);
    if (
      !bypassCache &&
      cache[productId] &&
      cache[productId][action] &&
      cache[`${productId}-${action}`].status !== 'fail' &&
      cache[`${productId}-${action}`].payload === stringifiedPayload
    ) {
      return cache[`${productId}-${action}`].promise;
    }
    const promise = new Promise((resolver, rej) => {
      const timeout = setTimeout(() => {
        cache[`${productId}-${action}`].status = 'fail';
        rej(Error('Request Timed Out'));
      }, 300000);
      promiseResolvers[eventId] = (data: any) => {
        clearTimeout(timeout);
        resolver(data);
      };
    });
    cache[`${productId}-${action}`] = {
      status: 'pending',
      payload: stringifiedPayload,
      promise,
    };
    return promise;
  };

  const isRequestInProgress = (productId: string, action: string) => {
    if (cache[`${productId}-${action}`] && cache[`${productId}-${action}`] === 'pending') {
      return true;
    }
    return false;
  };

  const clearCache = (productId: string, action: string) => {
    delete cache[`${productId}-${action}`];
  };

  subscribeToNotifications(user?.userId as string, async (notification: any) => {
    if (promiseResolvers[notification.body.triggerEventId]) {
      if (notification?.body?.payload?.respPayloadCacheId) {
        const res = await fetchLargePayload(notification?.body?.payload?.respPayloadCacheId);
        notification.body.payload = JSON.parse(JSON.parse(res));
      }

      cache[`${notification.productId}-${notification.body.eventType}`] = 'complete';
      promiseResolvers[notification.body.triggerEventId](notification);
    }
  });

  return { getNotifData, updateUser, clearCache, isRequestInProgress };
};

const AcgSvc = (user: User) => {
  const snsInterface = SnsCachedAwaitableFacade(user);
  const tileData = {
    productId: 'user-acg',
    tileId: 'user-acg',
  };
  return {
    updateUser: async <T>(data: Partial<AcgUserProfile>): Promise<T> => {
      return snsInterface
        .getNotifData(tileData, 'transition', 'updateUserProfile', data, {}, true)
        .then(notif => notif.body.payload);
    },
    // searchMemberDirectory: async <T>(member: Partial<MemberSearchProps>): Promise<T> => {
    //   return snsInterface
    //     .getNotifData(
    //       {
    //         productId: 'b54ca3a5-2426-4e4f-8612-b912d504dbac',
    //         tileId: '6028b961-8578-4eac-82f0-c9ad6ac8d90b',
    //       },
    //       'fetch',
    //       'searchMemberDirectory',
    //       member,
    //       {},
    //       true,
    //     )
    //     .then(notif => {
    //       if (notif.body.payload) {
    //         return notif.body.payload;
    //       }
    //     })
    //     .catch(err => console.log(err));
    // },
    getMemberships: async <T>(stateCurrent?: string): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'b54ca3a5-2426-4e4f-8612-b912d504dbac',
            tileId: '6028b961-8578-4eac-82f0-c9ad6ac8d90b',
          },
          'fetch',
          'getMemberships',
          { stateCurrent },
          {},
          true,
        )
        .then(notif => {
          if (notif.body.payload) {
            return notif.body.payload;
          }

          if (notif.body.status === 'fail') {
            return { status: 'fail' };
          }
        })
        .catch(err => console.log(err));
    },
    updateMembership: async <T>(
      hiddenFieldsFromMembershipDirectory: Partial<HiddenField[]>,
    ): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'b54ca3a5-2426-4e4f-8612-b912d504dbac',
            tileId: '6028b961-8578-4eac-82f0-c9ad6ac8d90b',
          },
          'transition',
          'updateMembership',
          { hiddenFieldsFromMembershipDirectory },
          {},
          true,
        )
        .then(notif => {
          if (notif.body.payload) {
            return notif.body.payload;
          }

          if (notif.body.status === 'fail') {
            return { status: 'fail' };
          }
        })
        .catch(err => console.log(err));
    },
    // getMemberCommittees: async <T>(): Promise<T> => {
    //   return snsInterface
    //     .getNotifData(
    //       {
    //         productId: 'b54ca3a5-2426-4e4f-8612-b912d504dbac',
    //         tileId: '6028b961-8578-4eac-82f0-c9ad6ac8d90b',
    //       },
    //       'fetch',
    //       'getMemberCommittees',
    //       {},
    //       {},
    //       true,
    //     )
    //     .then(notif => {
    //       if (notif.body.payload) {
    //         return notif.body.payload;
    //       }
    //     })
    //     .catch(err => console.log(err));
    // },
    getUserProfile: async <T>(): Promise<T> => {
      return snsInterface
        .getNotifData(tileData, 'fetch', 'getUserProfile', {}, {}, true)
        .then(notif => notif.body.payload)
        .catch(err => console.log(err));
    },
    // getReferenceData: async <T>(payload: AcgReferenceDataPayload): Promise<T> => {
    //   return snsInterface
    //     .getNotifData(
    //       {
    //         productId: 'referencedata-acg',
    //         tileId: 'referencedata-acg',
    //       },
    //       'fetch',
    //       'getReferenceData',
    //       payload,
    //       {},
    //       true,
    //     )
    //     .then(notif => notif.body.payload);
    // },
    getCart: async <T>(): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'fetch',
          'getCart',
          { user },
          {},
          true,
        )
        .then(notif => notif.body.payload);
    },
    addToCart: async <T>(items: AddToCartInput): Promise<T> => {
      return snsInterface.getNotifData(
        {
          productId: 'shop-acg',
          tileId: 'shop-acg',
        },
        'transition',
        'addItemsToCart',
        { items },
        {},
        true,
      );
    },
    createCart: async <T>(items: AddToCartInput): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'createCart',
          { items },
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'pass') {
            return notif?.body?.payload;
          }
          throw new Error(notif?.body?.eventType);
        });
    },
    /** @deprecated */
    getPaymentOptionsForCart: async <T>(): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'fetch',
          'getSavedPaymentMethods',
          {},
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
        })
        .catch(err => console.log('Error getting payment options: ', err));
    },
    getPublicUserProfile: async <T>(recordKey: string, objectsToGet?: string[]): Promise<T> => {
      return snsInterface
        .getNotifData(
          tileData,
          'fetch',
          'getPublicUserProfile',
          { recordKey, objectsToGet: objectsToGet ?? ['*'] },
          {},
          true,
        )
        .then(notif => notif.body.payload);
    },
    // getChapters: async <T>(): Promise<T> => {
    //   return snsInterface
    //     .getNotifData(
    //       {
    //         productId: 'referencedata-acg',
    //         tileId: 'referencedata-acg',
    //       },
    //       'fetch',
    //       'getAllChaptersAndPackages',
    //       {},
    //       {},
    //       true,
    //     )
    //     .then(notif => {
    //       if (notif.body.payload) {
    //         return notif.body.payload;
    //       }
    //     })
    //     .catch(err => console.log('Error fetching chapters', err));
    // },
    doPayment: async <T>(payload: DoPayment): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'doPayment',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif.body.eventType);
          }
          if (notif?.body?.payload) {
            return notif.body.payload;
          }
        })
        .catch(err => console.log('Error running event type: doPayment - ', err));
    },
    // MISSPELLED EVENT TYPE
    doPaymentForOpenInvoice: async <T>(payload: DoPayment): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'doPaymentForOpenInvioce',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif.body.eventType);
          }
          if (notif?.body?.payload) {
            return notif.body.payload;
          }
        })
        .catch(err => console.log('Error running event type: doPaymentForOpenInvoice - ', err));
    },
    clearCart: async <T>(): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'clearCart',
          {},
          {},
          true,
        )
        .then(notif => notif.body.payload)
        .catch(err => console.log('Error clearing cart', err));
    },
    // getGrowthTvFeed: async <T>(): Promise<T> => {
    //   return snsInterface
    //     .getNotifData(
    //       {
    //         productId: '659cc59f-0557-4709-a6eb-22618f81792a',
    //         tileId: '685c173d-9c1f-4b16-b919-4746f632450f',
    //       },
    //       'fetch',
    //       'getGrowthTvFeed',
    //       { user },
    //       {},
    //       true,
    //     )
    //     .then(notif => notif.body.payload)
    //     .catch(err => console.log('Error clearing cart', err));
    // },
    // getMagazinesFeed: async <T>(): Promise<T> => {
    //   return snsInterface
    //     .getNotifData(
    //       {
    //         productId: '659cc59f-0557-4709-a6eb-22618f81792a',
    //         tileId: '685c173d-9c1f-4b16-b919-4746f632450f',
    //       },
    //       'fetch',
    //       'getMagazinesFeed',
    //       { user },
    //       {},
    //       true,
    //     )
    //     .then(notif => notif.body.payload)
    //     .catch(err => console.log('Error clearing cart', err));
    // },
    // getNewsTrendsFeed: async <T>(): Promise<T> => {
    //   return snsInterface
    //     .getNotifData(
    //       {
    //         productId: '659cc59f-0557-4709-a6eb-22618f81792a',
    //         tileId: '685c173d-9c1f-4b16-b919-4746f632450f',
    //       },
    //       'fetch',
    //       'getNewsTrendsFeed',
    //       { user },
    //       {},
    //       true,
    //     )
    //     .then(notif => notif.body.payload)
    //     .catch(err => console.log('Error clearing cart', err));
    // },
    // getInvoicesByUser: async <T>(): Promise<T> => {
    //   return snsInterface
    //     .getNotifData(
    //       {
    //         productId: 'shop-acg',
    //         tileId: 'user-acg',
    //       },
    //       'fetch',
    //       'getInvoicesByUser',
    //       {},
    //       {},
    //       true,
    //     )
    //     .then(notif => notif.body.payload)
    //     .catch(err => console.log('Error getting invoices', err));
    // },
    downloadInvoice: async <T>(invoiceId: string): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'user-acg',
          },
          'fetch',
          'downloadInvoice',
          { invoiceId },
          {},
          true,
        )
        .then(notif => notif.body.payload)
        .catch(err => console.log('Error getting invoices', err));
    },
    getPurchasedEvents: async <T>(): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'c2249007-08d5-4965-bbdb-9beb10773ec2',
            tileId: '',
          },
          'fetch',
          'getUserPurchasedEvents',
          { user },
          {},
          true,
        )
        .then(notif => {
          return notif.body.payload;
        })
        .catch(err => console.log('Error getting purchased events', err));
    },
    getEventForRegistration: async <T>(eventId: string): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'c2249007-08d5-4965-bbdb-9beb10773ec2',
            tileId: '',
          },
          'fetch',
          'getEventForRegistration',
          { user, eventId },
          {},
          true,
        )
        .then(notif => {
          return notif.body.payload;
        })
        .catch(err => console.log('Error getting event for registration: ', err));
    },
    getEventAttendees: async <T>(eventId: string): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'c2249007-08d5-4965-bbdb-9beb10773ec2',
            tileId: '',
          },
          'fetch',
          'getEventAttendees',
          { eventId },
          {},
          true,
        )
        .then(notif => {
          return notif.body.payload;
        })
        .catch(err => console.log('Error getting event attendees', err));
    },
    getSavedPaymentMethods: async <T>(): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'user-acg',
          },
          'fetch',
          'getSavedPaymentMethods',
          { user },
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
        })
        .catch(err => console.log('Error getting payment options: ', err));
    },
    addOrUpdateStoredPaymentMethod: async <T>(
      payload: AddOrUpdateStoredPaymentPayload,
    ): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'addOrUpdateStoredPaymentMethod',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
        })
        .catch(err => console.log('Error updating payment methods: ', err));
    },
    deleteStoredPaymentMethod: async <T>(payload: { recordId: string }): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'deleteStoredPaymentMethod',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
        })
        .catch(err => console.log('Error deleting payment methods: ', err));
    },
    setAutoRenewOnMembership: async <T>(payload: any): Promise<T> => {
      const { paymentInfo, membershipRecordKey, autoRenew } = payload;
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'setAutoRenewOnMembership',
          { paymentInfo, membershipRecordKey, autoRenew },
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
        })
        .catch(err => console.log('Error setting membership auto renew: ', err));
    },
    createCartForInvoice: async <T>(payload: {
      invoiceId: string;
      invoiceIdMember?: string;
    }): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'createCartForInvoice',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif.body.eventType);
          }
          if (notif?.body?.payload) {
            return notif.body.payload;
          }
        })
        .catch(err => console.log('Error running event type: payForInvoice - ', err));
    },
    getRegisteredEvent: async <T>(registrationId: string): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'c2249007-08d5-4965-bbdb-9beb10773ec2',
            tileId: 'events-acg',
          },
          'fetch',
          'getRegisteredEvent',
          { registrationId },
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif.body.eventType);
          }
          if (notif?.body?.payload) {
            return notif.body.payload;
          }
        })
        .catch(err => console.log('Error running event type: getRegisteredEvent - ', err));
    },
    updateRegisteredEvent: async <T>(payload: UpdateRegisteredEventPayload): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'c2249007-08d5-4965-bbdb-9beb10773ec2',
            tileId: 'events-acg',
          },
          'transition',
          'updateRegisteredEvent',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif.body.eventType);
          }
          if (notif?.body?.payload) {
            return notif.body.payload;
          }
        })
        .catch(err => console.log('Error updating Registered Event - ', err));
    },
    addContactToWaitlist: async <T>(payload: { eventId: string }): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'c2249007-08d5-4965-bbdb-9beb10773ec2',
            tileId: 'events-acg',
          },
          'transition',
          'addContactToWaitlist',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif.body.eventType);
          }
          if (notif?.body?.payload) {
            return notif.body.payload;
          }
        })
        .catch(err => console.log('Error adding contact to waitlist - ', err));
    },
    searchIndividual: async <T>(
      firstName?: string,
      lastName?: string,
      email?: string,
    ): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'user-acg',
            tileId: 'user-acg',
          },
          'fetch',
          'searchIndividual',
          { firstName, lastName, email },
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif.body.eventType);
          }
          if (notif?.body?.payload) {
            return notif.body.payload?.searchData;
          }
        })
        .catch(err => console.log('Error running event type: searchIndividual - ', err));
    },
    applyDiscount: async <T>(discountCodes: string[]): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'applyDiscountToCart',
          { discountCodes },
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif.body.eventType);
          }
          if (notif?.body?.payload) {
            return notif.body.payload;
          }
        })
        .catch(err => console.log('Error running event type: applyDiscountToCart - ', err));
    },
    addGuestsToRegisteredEventCart: async <T>(payload: AddGuestsToRegisteredEvent): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'shop-acg',
            tileId: 'shop-acg',
          },
          'transition',
          'addGuestsToRegisteredEventCart',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
        })
        .catch(err => console.log('Error adding guests to cart: ', err));
    },
    getRecommendedEvents: async <T>(payload: RecommendedEventsPayload): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'c2249007-08d5-4965-bbdb-9beb10773ec2',
            tileId: 'events-acg',
          },
          'fetch',
          'getRecommendedEvents',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
        })
        .catch(err => console.log('Error fetching recommended events: ', err));
    },
    storeRhythmTokenAndContactId: async <T>(payload: StoreRhythmTokenAndContactId): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'user-acg',
            tileId: 'user-acg',
          },
          'transition',
          'storeRhythmTokenAndContactId',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
          if (notif.body?.status === 'pass') {
            return notif.body;
          }
        })
        .catch(err => console.log('Error storing auth0 token: ', err));
    },
    submitMembershipApplication: async <T>(payload: SubmitMembershipApplication): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'b54ca3a5-2426-4e4f-8612-b912d504dbac',
            tileId: '6028b961-8578-4eac-82f0-c9ad6ac8d90b',
          },
          'transition',
          'submitMembershipApplication',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
        })
        .catch(err => console.log('Error fetching membership application id: ', err));
    },
    submitEventApplication: async <T>(payload: SubmitEventApplication): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'c2249007-08d5-4965-bbdb-9beb10773ec2',
            tileId: 'events-acg',
          },
          'transition',
          'submitEventApplication',
          payload,
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
        })
        .catch(err => console.log('Error fetching event application id: ', err));
    },
    deleteDraftMemberships: async <T>(): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'b54ca3a5-2426-4e4f-8612-b912d504dbac',
            tileId: '6028b961-8578-4eac-82f0-c9ad6ac8d90b',
          },
          'transition',
          'deleteDraftMemberships',
          {},
          {},
          true,
        )
        .then(notif => {
          if (notif?.body?.status === 'fail') {
            throw new Error(notif?.body?.eventType);
          }
          if (notif.body?.payload) {
            return notif.body?.payload;
          }
        })
        .catch(err => console.log('Error deleting drafts: ', err));
    },
    deleteDraftEventRegistrations: async <T>(payload: any): Promise<T> => {
      return snsInterface
        .getNotifData(
          {
            productId: 'c2249007-08d5-4965-bbdb-9beb10773ec2',
            tileId: 'events-acg',
          },
          'transition',
          'deleteDraftEventRegistrations',
          payload,
          {},
          true,
        )
        .then(notif => {
          return notif.body.payload;
        })
        .catch(err => console.log('Error deleting event drafts registrations: ', err));
    },
  };
};

export default AcgSvc;
