import React from 'react';
import { Box, VStack, Stack, Avatar, Button, Flex, Text } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { usePrefetch } from '../../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../../services/user/user-api';
import { useAppDispatch } from '../../../../../hooks';
import { setPublicProfileData } from '../../member-search-slice';
import { useGetAcgMemberProfileImage } from '../../../shared';

export interface MemberTileProps {
  fullName?: string;
  title?: string;
  company?: string;
  city?: string;
  state?: string;
  country?: string;
  imageUrl?: string;
  recordKey?: string;
  memberDirectoryGoToStep?: (arg: number | string) => void;
}

// TODO: retype this
export const MemberTile: React.FC<any> = props => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    firstName,
    lastName,
    title,
    organization,
    city,
    state,
    country,
    imageUrl,
    recordKey,
    memberDirectoryGoToStep,
    chapterIds,
  } = props;
  const { data: globalUser } = useGetGlobalUserQuery();
  const prefetchPublicUserProfile = usePrefetch('getPublicUserProfile');

  const handleProfileButtonClick = () => {
    const memberDataCopy = { ...props };
    delete memberDataCopy?.memberDirectoryGoToStep;
    dispatch(setPublicProfileData(memberDataCopy));
    memberDirectoryGoToStep?.(1);
    navigate('../member-directory', {
      replace: true,
      state: { defaultStep: 'member-profile', recordKey, imageUrl: memberAvatarUrl, chapterIds },
    });
  };

  const handlePrefetchingPublicProfile = (recordKey: string) => {
    if (globalUser && recordKey) {
      prefetchPublicUserProfile({ user: globalUser, recordKey });
    }
  };

  const memberAvatarUrl = useGetAcgMemberProfileImage(imageUrl);
  // old code
  // const accessToken = globalUser?.mads?.['user-acg']?.find?.(
  //   (i: any) => i?.key === 'rhythmAuth0Token',
  // )?.value;

  // const query = new URLSearchParams({
  //   access_token: accessToken,
  // }).toString();

  // let memberAvatarUrl = useMemo(() => {
  //   if (!imageUrl) {
  //     return undefined;
  //   }

  //   return `${imageUrl?.replace(
  //     'service://platform-file-uploads/v1',
  //     'https://platform.api.rhythmsoftware.com/fileUploads',
  //   )}/image?${query}`;
  // }, [imageUrl, query]);

  // console.log('acg Avatar URl:', memberAvatarUrl);

  return (
    <Box w="full" margin={6}>
      <Flex direction={['column', 'column', 'row']} justify="space-between" align="center">
        <Stack direction={['column', 'column', 'row']} align="center">
          <Avatar size="lg" src={memberAvatarUrl} backgroundColor="gray.500" />
          <VStack>
            <Box paddingLeft={[0, 0, 6]} textAlign={['center', 'center', 'start']}>
              <Text textStyle="md">{`${firstName} ${lastName}`}</Text>
              <Text textStyle="md" pr={[0, 0, 6]}>
                {title && organization && `${title}, ${organization}`}
                {title && !organization && `${title}`}
                {!title && organization && `${organization}`}
              </Text>
              <Text textStyle="md">
                {city && state && country && `${city}, ${state}, ${country}`}
                {!city && state && country && `${state}, ${country}`}
                {city && state && !country && `${city}, ${state}`}
                {city && !state && country && `${city}, ${country}`}
                {!city && state && country && `${state}, ${country}`}
                {!city && !state && country && `${country}`}
              </Text>
            </Box>
          </VStack>
        </Stack>
        <Stack pt={[5, 5, 0]}>
          <Button
            variant="outline"
            size="sm"
            fontWeight="600"
            onClick={handleProfileButtonClick}
            onMouseEnter={recordKey ? () => handlePrefetchingPublicProfile(recordKey) : undefined}
          >
            VIEW PUBLIC PROFILE
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
};
