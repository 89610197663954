import React from 'react';
import { Flex } from '@companydotcom/potion';
import { VerifyPhone } from '../../../pages/subpages/verify-phone/verify-phone';

export const VerifyPhoneWrapper = ({ goToStep }: { goToStep?: (arg: string | number) => void }) => {
  const onSubmit = () => {
    if (goToStep) {
      goToStep('enter-code');
    }
  }; // TODO figure out these on Submits
  return (
    <Flex className="verify-phone-wrapper" maxWidth={704} width="full" py={14} mx="auto" flex={[1]}>
      <VerifyPhone onSubmit={onSubmit} isBack secondVerification />
    </Flex>
  );
};
