import { Button, Flex, Text } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { useAddContactToWaitlistMutation } from '../../../../services/acg/acg-api';
import { EventForRegistration } from '../../../../services/acg/acg.types';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

export interface EventRegistrationWaitlistProps {
  eventForRegistration?: EventForRegistration;
}

export const EventRegistrationWaitlist = ({
  eventForRegistration,
}: EventRegistrationWaitlistProps) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [addContactToWaitlist, { isLoading: isUpdating, isSuccess }] =
    useAddContactToWaitlistMutation();

  const navigate = useNavigate();

  const isAlreadyOnWaitList = eventForRegistration?.onWaitList || isSuccess;

  return (
    <Flex
      justify="center"
      align="center"
      borderWidth="1px"
      flexDir="column"
      p={['4', null, '7']}
      mt="12"
      textAlign="center"
    >
      <Text
        textStyle={{ base: 'xl', md: '3xl' }}
        textAlign="center"
        fontWeight="bold"
        opacity="0.7"
      >
        {isAlreadyOnWaitList
          ? `You’re on the waitlist for this event. An ACG member will contact you if there’s an opening for this event.`
          : `There’s a waitlist for this event. You can add yourself to the waitlist and an ACG member
        will contact you if there’s an opening for this event.`}
      </Text>
      <Text textStyle={{ base: 'lg', md: 'xl' }} mt="4" opacity="0.7">
        {eventForRegistration && getWaitlistText(eventForRegistration, isAlreadyOnWaitList)}
      </Text>
      {isAlreadyOnWaitList ? (
        <Button size="lg" mt="12" onClick={() => navigate('/')}>
          Back To My ACG
        </Button>
      ) : (
        <Button
          size="lg"
          mt="12"
          isLoading={isUpdating}
          isDisabled={!globalUser || !eventForRegistration?.eventKey}
          onClick={
            globalUser && eventForRegistration?.eventKey
              ? () =>
                  addContactToWaitlist({
                    user: globalUser,
                    payload: {
                      eventId: eventForRegistration?.eventKey,
                    },
                  })
              : undefined
          }
        >
          Join Waitlist
        </Button>
      )}
      <Button
        variant="ghost"
        size="sm"
        mt="4"
        onClick={() => window.open(`${process.env.REACT_APP_ACG_BASE_URL}/events`)}
        isDisabled={isUpdating}
      >
        SEARCH FOR ANOTHER EVENT
      </Button>
    </Flex>
  );
};

function getWaitlistText(event: EventForRegistration, isAlreadyOnWaitList: boolean) {
  if (isAlreadyOnWaitList) {
    return `Including you, there are ${event?.totalWaitList} people on the waitlist.`;
  }

  if (event?.totalRegistered >= event?.eventCapacity && event?.totalWaitList === 0) {
    return `You will be the first person placed on the waitlist.`;
  }

  if (event?.totalWaitList && event?.totalWaitList === 1) {
    return `Currently, there is 1 person on the waitlist.`;
  }

  return `Currently, there are ${event?.totalWaitList} people on the waitlist.`;
}
