import React, { useState, useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { useLocation } from 'react-router';
import * as Sentry from '@sentry/react';
import { Source, SourceConfig } from '@companydotcom/types';
import { AppSpinner } from '@companydotcom/ui';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { createI18nConfig } from '@companydotcom/helpers';
import i18n from 'i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { GlobalProvider } from '@companydotcom/providers';
import { SourceSvc } from '@companydotcom/services';
import reportWebVitals from './report-web-vitals';
import { DashboardTheme } from './theme';
import App from './app';
import { MasterAuthInterface, Auth0Provider } from './providers';
import { store } from './store';
import { Amplify } from 'aws-amplify';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-search-params-polyfill';
import 'raf/polyfill';
import 'unfetch/polyfill';

const { getSource, getSourceConfig, getSourceURI, getAuth0Config } = SourceSvc?.();

export interface Auth0ConfigInterface {
  authDomain: string;
  clientId: string;
  clientAudience?: string;
}

Amplify.configure({
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_COMPANYQL_URL,
  aws_appsync_region: process.env.REACT_APP_REGION,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: process.env.REACT_APP_COMPANYQL_API_KEY,
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  environment: process.env.REACT_APP_MARKETPLACE_ENV || process.env.NODE_ENV,
  tracesSampleRate: process.env.REACT_APP_MARKETPLACE_ENV === 'production' ? 0.2 : 1.0,
  replaysSessionSampleRate: process.env.REACT_APP_MARKETPLACE_ENV === 'production' ? 0.1 : 1.0,
  replaysOnErrorSampleRate: process.env.REACT_APP_MARKETPLACE_ENV === 'production' ? 0.2 : 1.0,
  release: process.env.REACT_APP_SENTRY_RELEASE,
});

// Wraps the entire APP in GA to track routes
const AppWithGA = () => {
  const [isInit, setIsInit] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [source, setSource] = useState<Source | null>(null);
  const [sourceConfig, setSourceConfig] = useState<SourceConfig>();
  const [auth0Client, setAuth0Client] = useState<Auth0Client | null>(null);

  useEffect(() => {
    const fetchSourceConfig = async () => {
      try {
        const sourceURI = await getSourceURI(window.location.host);
        const sourceId = sourceURI || 'company';
        Sentry.setTag('source_id', sourceId);
        const sourceRes = await getSource(sourceId, 'en');
        const sourceConfigRes = await getSourceConfig(sourceId);

        setSource(sourceRes);
        setSourceConfig(sourceConfigRes);
        const auth0Config: Auth0ConfigInterface = await getAuth0Config(sourceId);

        MasterAuthInterface.initialize(
          auth0Config.authDomain,
          auth0Config.clientId,
          auth0Config.clientAudience,
        ).then(auth => {
          setAuth0Client(auth);
          setFetching(false);
        });
      } catch (err) {
        console.log('Error fetching source: ', err);
      }
    };

    fetchSourceConfig();
  }, []);

  useEffect(() => {
    if (!isInit && source) {
      createI18nConfig(source);
    }
  }, [isInit, source]);

  i18n.on('initialized', () => {
    setIsInit(true);
  });

  if (fetching || !auth0Client || !source || !sourceConfig) {
    return (
      <AppSpinner
        className="dashboard-app-spinner__fetching"
        containerStyles={{ minHeight: '100vh' }}
      />
    );
  }

  return (
    <Suspense
      fallback={
        <AppSpinner
          className="dashboard-app-spinner__suspense"
          containerStyles={{
            minHeight: '100vh',
            flexGrow: 1,
          }}
        />
      }
    >
      <BrowserRouter>
        <ScrollToTop>
          <ReduxProvider store={store}>
            <Auth0Provider auth0Client={auth0Client}>
              <GlobalProvider
                source={source}
                sourceConfig={sourceConfig}
                theme={DashboardTheme}
                debugMode={process.env.REACT_APP_DEBUG_MODE_ENABLED === 'true'}
              >
                <App />
              </GlobalProvider>
            </Auth0Provider>
          </ReduxProvider>
        </ScrollToTop>
      </BrowserRouter>
    </Suspense>
  );
};

const ScrollToTop: React.FC<{}> = props => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

ReactDOM.render(
  // <React.StrictMode>
  <AppWithGA />,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
