import gql from 'graphql-tag';

export const CREATE_BYOP_TILE = gql`
  mutation CreateByopTile($input: ByopTileInput!) {
    createByopTile(input: $input)
  }
`;

export const UPDATE_BYOP_TILE = gql`
  mutation UpdateByopTile(
    $byopTileId: String!
    $userId: String!
    $seqNo: Int
    $isHidden: Boolean
    $hasBeenLaunched: Boolean
  ) {
    updateByopTile(
      byopTileId: $byopTileId
      userId: $userId
      seqNo: $seqNo
      isHidden: $isHidden
      hasBeenLaunched: $hasBeenLaunched
    )
  }
`;
