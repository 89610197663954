import React from 'react';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { EventRegistration } from '../../../features/acg/acg-event-registration';

export const EventRegistrationPage: React.FC = () => {
  return (
    <PageLayout>
      <PageLayoutContent>
        <EventRegistration />
      </PageLayoutContent>
    </PageLayout>
  );
};
