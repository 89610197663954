import React from 'react';
import {
  Image,
  Text,
  Link,
  Box,
  Flex,
  HStack,
  VStack,
  StackDivider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Icon,
  UnorderedList,
  ListItem,
} from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

import type { TileComponentProps } from '../../types';
import { getLogos } from './utils';
import { TileSpinner } from '../tile-spinner';

export interface OverviewProps {
  overviews: {
    logo?: string;
    name?: string;
    status?: string;
    suffix?: string;
    quantity?: string | number;
    networks?: number;
    url?: string;
  }[];
}

export interface TileSocialMediaMarketingOverviewProps extends TileComponentProps {}

export const TileSocialMediaMarketingOverview: React.FC<
  TileSocialMediaMarketingOverviewProps
> = props => {
  const { eventData, componentTheme } = props;
  const { loading, error, data } = useEventData<OverviewProps>(eventData);

  return (
    <Flex
      className="tile__social-media-marketing-overview"
      flexDirection="column"
      flex={1}
      alignItems="center"
      height={202}
      width="full"
      pr={3}
      display="block"
      sx={{
        overflowY: 'scroll',
        height: '158px',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          backgroundColor: '#d8d8d8',
        },
        ...componentTheme,
      }}
    >
      {loading ? (
        <TileSpinner />
      ) : (
        <VStack divider={<StackDivider borderColor="gray.200" />} px={4}>
          {data?.overviews?.map(item =>
            item.name !== 'Google Plus' && item.name !== 'GMB' ? (
              <SocialMediaCard
                key={item.name}
                logo={getLogos(item.name)}
                name={item.name}
                url={item.url}
                status={item.status}
                suffix={item.suffix}
                quantity={item.quantity}
                networks={item.networks}
                error={error}
                loading={loading}
              />
            ) : null,
          )}
        </VStack>
      )}
      {data?.overviews && (
        <Box as="div" mt={[4]} mx="auto">
          <Popover trigger="hover">
            <PopoverTrigger>
              <HStack justifyContent="center">
                <Text textStyle="sm">Data update intervals vary by network</Text>
                <Icon
                  color="blue.500"
                  as={FontAwesomeIcon}
                  icon={faCircleExclamation}
                  boxSize={3}
                />
              </HStack>
            </PopoverTrigger>
            <PopoverContent bgColor="gray.500" color="white" fontSize="sm">
              <PopoverArrow bgColor="gray.500" />

              <PopoverHeader>Current refresh rates:</PopoverHeader>
              <PopoverBody>
                <UnorderedList>
                  <ListItem>Facebook - Real Time</ListItem>
                  <ListItem>Twitter - Real Time</ListItem>
                  <ListItem>Google My Business - Real Time</ListItem>
                  <ListItem>Instagram - Hourly</ListItem>
                  <ListItem>LinkedIn - Daily (3am PST)</ListItem>
                </UnorderedList>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      )}
    </Flex>
  );
};

interface CardDataProps {
  logo?: void | string;
  name?: string;
  status?: string;
  suffix?: string;
  quantity?: string | number;
  error?: boolean;
  loading?: boolean;
  networks?: number;
  url?: void | string;
}

function SocialMediaCard({
  logo,
  name,
  status,
  suffix,
  quantity,
  error,
  loading,
  networks,
  url,
}: CardDataProps) {
  function renderData(data: CardDataProps) {
    if (networks === 0) {
      return (
        <Link href={url ?? ''} fontSize="sm" isExternal color="#4EB6C9">
          Connect
        </Link>
      );
    }

    if (networks !== 0) {
      return (
        <HStack alignItems="center">
          {quantity?.toString ? (
            <Text textStyle="sm" color="#084066">
              {quantity}
            </Text>
          ) : (
            <Text textStyle="sm">0</Text>
          )}
          <Text textStyle="sm" color="#4c6372">{`${data.suffix}`}</Text>
        </HStack>
      );
    }
  }

  return (
    <HStack flexDirection="row" alignItems="center" justifyContent="space-between" width="full">
      <HStack>
        {!logo || logo === '' || loading || error ? (
          <Box boxSize={6} bg="gray.100" borderRadius="round" />
        ) : (
          <Image
            boxSize={6}
            src={logo}
            alt={name}
            fallback={<Box boxSize={6} bg="gray.100" borderRadius="round" />}
          />
        )}
        <Text textStyle="sm" color="#566e7d" fontWeight="bold" ml={2}>
          {name}
        </Text>
      </HStack>
      <Box mr={7}>{renderData({ logo, name, status, suffix, quantity })}</Box>
    </HStack>
  );
}
