import React from 'react';
import { Center, Heading, Button, VStack, Box } from '@companydotcom/potion';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../components/elements';

export interface PendingMembershipProps {
  isMobile?: boolean;
}

export const PendingMembership: React.FC<PendingMembershipProps> = props => {
  const { isMobile } = props;
  const { t } = useTranslation();

  return (
    <Center
      className="member-directory__pending-member"
      flexDirection="column"
      px={[0, 4]}
      borderRadius="sm"
      py={[0, 8, 12]}
      bg={['transparent', 'white']}
    >
      <Box maxW={908} width="full">
        <PageHeading heading={t(`acg.memberSearch.search.header`)} width="full" maxW={908} />
        <VStack py={[4]} px={[4]} my={[4]} spacing={[4, 12]} bg={['white']}>
          <Heading size={isMobile ? 'md' : 'hs-xl'} textAlign="center">
            {t(`acg.memberSearch.search.membershipStatus.pending`)}
          </Heading>
          <Button size="lg" as={NavLink} to="/">
            GO TO MY ACG DASHBOARD
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};
