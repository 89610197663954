import React from 'react';
import { Flex, Box, Text, Center, UnorderedList, ListItem } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';

export interface SubscriptionRatePlanProps {
  isSelected?: boolean;
  productSlug?: string;
  isActive?: boolean;
  name?: string;
  features?: string[];
  ratePlanClickHandler?: any;
  disabled?: boolean;
  estimatedPrice?: number;
  includedInMembership?: boolean;
  featurePrefix?: string;
  hasTiers?: boolean;
  isPackage?: boolean;
  availableProductStep?: boolean;
  noOfPlans?: number;
}

export const SubscriptionRatePlan = ({
  isSelected,
  productSlug,
  isActive,
  name,
  features,
  ratePlanClickHandler,
  disabled,
  estimatedPrice,
  includedInMembership,
  featurePrefix,
  hasTiers,
  isPackage,
  availableProductStep,
  noOfPlans,
}: SubscriptionRatePlanProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      className="subscription-rate-plan"
      flexDirection="column"
      maxW={noOfPlans && noOfPlans > 2 ? 216 : undefined}
    >
      {availableProductStep ? null : isActive ? <CurrentPlan /> : <Box height="26px" />}
      <Flex
        position="relative"
        height="100%"
        flex={1}
        onClick={e => !disabled && ratePlanClickHandler(e)}
        flexDirection="column"
        minWidth={216}
        p={4}
        pb={8}
        outline={isSelected ? '2px solid' : '1px solid'}
        outlineColor={isSelected ? 'pricing' : 'gray.200'}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        opacity={disabled ? 0.35 : 1}
        boxShadow="md"
        _hover={{
          boxShadow: isSelected || disabled ? 'md' : 'lg',
        }}
      >
        <Box>
          <Text className="plan-name" fontWeight="bolder">
            {name}
          </Text>
          <Text
            className="plan-price"
            textStyle="sm"
            fontWeight="medium"
            color="pricing"
            mt="2px"
            mb={4}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {includedInMembership
              ? t('miop.subscriptions.included')
              : estimatedPrice && estimatedPrice > 0 && !disabled
              ? `$${estimatedPrice?.toFixed(2)}/${t('miop.subscriptions.month')}${
                  productSlug === 'email_rackspace' ? ` ${t('miop.subscriptions.each')}` : ''
                }`
              : `--/${t('miop.subscriptions.month')}${hasTiers ? '*' : ''}`}
          </Text>
          {isPackage && (
            <Text textStyle="sm" fontWeight="bolder">
              {t('miop.subscriptions.whatIsIncluded')}
            </Text>
          )}
        </Box>
        {featurePrefix && !isPackage && (
          <Text className="feature-prefix" textStyle="sm" fontWeight="bolder" mb={2}>
            {featurePrefix}
          </Text>
        )}
        {features && (
          <UnorderedList className="feature-wrapper" stylePosition="outside" listStyleType="'- '">
            {features.map((feature, x) => (
              <ListItem textStyle="sm" className="feature" key={x}>
                {feature}
              </ListItem>
            ))}
          </UnorderedList>
        )}
        {disabled && (
          <Box
            bg="blackAlpha.100"
            width="full"
            height="100%"
            sx={{ position: 'absolute', top: 0, left: 0 }}
          />
        )}
      </Flex>
    </Flex>
  );
};

export const CurrentPlan = () => {
  const { t } = useTranslation();
  return (
    <Center
      bg="pricing"
      p={1}
      width="full"
      maxWidth={['100%', null, 235]}
      height="24px"
      mb="2px"
      sx={{
        outlineColor: 'pricing',
        outlineStyle: 'solid',
        outlineWidth: '2px',
      }}
    >
      <Text color="white" textAlign="center" sx={{ textTransform: 'uppercase' }}>
        {t('miop.subscriptions.currentPlan')}
      </Text>
    </Center>
  );
};
