var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { __DEV__, Input, FormControl, FormLabel, FormHelperText, InputGroup, Icon, useDisclosure, IconButton, InputRightElement, useMultiStyleConfig, } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { TranslationErrorMessage } from './translation-error-message';
export const PasswordInputField = props => {
    var _a, _b, _c, _d, _e;
    const { errors, register, name, label, helperText, formControlStyles } = props, rest = __rest(props, ["errors", "register", "name", "label", "helperText", "formControlStyles"]);
    const { isOpen, onToggle } = useDisclosure();
    const styles = useMultiStyleConfig('Input', props);
    const iconStyles = useMultiStyleConfig('PasswordInputField', props);
    const onClickReveal = () => {
        onToggle();
    };
    return (_jsxs(FormControl, Object.assign({ id: name, isInvalid: errors && !!(errors === null || errors === void 0 ? void 0 : errors[name]), sx: formControlStyles }, { children: [_jsx(FormLabel, Object.assign({ htmlFor: name }, { children: label !== null && label !== void 0 ? label : '' }), void 0), _jsxs(InputGroup, { children: [_jsx(Input, Object.assign({ autoComplete: "" }, register(name), { type: isOpen ? 'text' : 'password' }, rest), void 0), _jsx(InputRightElement, { padding: 0, color: "transparent", children: _jsx(IconButton, { bg: "transparent !important", _hover: { backgroundColor: 'none' }, variant: "unstyled", borderTopRightRadius: "md", borderBottomRightRadius: "md", borderTopLeftRadius: "none", borderBottomLeftRadius: "none", fontSize: "1.2em", "aria-label": isOpen ? 'Mask password' : 'Reveal password', icon: _jsx(Icon, { color: 
                                // @ts-ignore
                                (_d = (((_a = iconStyles === null || iconStyles === void 0 ? void 0 : iconStyles.icon) === null || _a === void 0 ? void 0 : _a.color) || ((_c = (_b = styles === null || styles === void 0 ? void 0 : styles.field) === null || _b === void 0 ? void 0 : _b._focus) === null || _c === void 0 ? void 0 : _c.borderColor))) !== null && _d !== void 0 ? _d : 'gray.100', as: FontAwesomeIcon, icon: isOpen ? faEye : faEyeSlash }, void 0), onClick: onClickReveal }, void 0) }, void 0)] }, void 0), helperText && !(errors === null || errors === void 0 ? void 0 : errors[name]) && _jsx(FormHelperText, { children: helperText !== null && helperText !== void 0 ? helperText : '' }, void 0), errors && (_jsx(TranslationErrorMessage, Object.assign({ errors: errors, name: name }, { children: errors && (errors === null || errors === void 0 ? void 0 : errors[name]) && ((_e = errors === null || errors === void 0 ? void 0 : errors[name]) === null || _e === void 0 ? void 0 : _e.message) }), void 0))] }), void 0));
};
if (__DEV__) {
    PasswordInputField.displayName = 'PasswordInputField';
}
