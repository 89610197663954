/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import {
  Tabs,
  TabPanels,
  TabPanel,
  useTabStepper,
  Box,
  useBoolean,
  useMediaQuery,
} from '@companydotcom/potion';
import { User } from '@companydotcom/types';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AppSpinner } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { AddSavedPaymentMethod } from './saved-payment-add-new';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { useGetSavedPaymentMethodsQuery } from '../../../../services/acg/acg-api';
import { useGetAcgQueryArgs, useGetMembershipsV2Query } from '../../../../services/acg/acg-api-v2';
import { BackButtonLayout } from '../../../../components/layouts';
import { useAppDispatch, useScript } from '../../../../hooks';
import { savedPaymentMethods } from '../saved-payments-slice';
import { useNavigate } from 'react-router-dom';

const SavedPaymentMethodsSteps = [
  {
    slug: 'add-method',
    component: <AddSavedPaymentMethod />,
  },
];

export const SavedPaymentMethods: React.FC<{}> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showForm, setShowForm] = useBoolean(true);
  const [isMobile] = useMediaQuery('(max-width: 48rem)');

  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const { data: paymentData, isLoading } = useGetSavedPaymentMethodsQuery(
    globalUser ? { user: globalUser as User } : skipToken,
  );
  const { data: membership, isLoading: membershipLoading } = useGetMembershipsV2Query({
    ...args,
  });

  const { tabIndex, handleTabsChange } = useTabStepper({
    steps: SavedPaymentMethodsSteps,
  });

  const autoRenewMethod = useMemo(() => {
    const autoRenewMethodToken =
      membership?.memberships?.[0]?.renewalPaymentInfo?.merchantAccountTokens?.[0]?.merchantToken;
    return paymentData?.savedPaymentMethods?.find(
      m => m?.merchantAccountTokens?.[0]?.merchantToken === autoRenewMethodToken,
    )?.recordKey;
  }, [membership]);

  /** BLUEPAY LOGIC */
  const [, error] = useScript({
    src: 'https://secure.bluepay.com/v3/bluepay.js',
    checkForExisting: true,
  });
  const BluePay = window?.['BluePay'] ?? null;

  useEffect(() => {
    let mounted = true;
    if (BluePay && !error && mounted) {
      BluePay.setCredentials('200025930923', '1f76bb3d84ba20530f312254c2a5889c');
    }
    return () => {
      mounted = false;
    };
  }, [error, BluePay]);

  // Mobile View/Form View
  useEffect(() => {
    if (paymentData?.recordCount === 5) {
      setShowForm.off();
    }
    if (isMobile && paymentData?.recordCount) {
      setShowForm.off();
    }
    if (!isMobile && paymentData?.recordCount !== 5) {
      setShowForm.on();
    }
  }, [isMobile, paymentData?.recordCount]);

  useEffect(() => {
    if (paymentData && paymentData?.recordCount) {
      dispatch(savedPaymentMethods(paymentData?.savedPaymentMethods));
    }
  }, [paymentData?.recordCount]);

  if (isLoading || membershipLoading) {
    return (
      <Box>
        <AppSpinner
          containerStyles={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
          }}
          description={t(`acg.savedPaymentMethods.loadingState.retreivingMethods`)}
        />
      </Box>
    );
  }

  return (
    <BackButtonLayout
      onBackClick={() => {
        if (isMobile && showForm) {
          setShowForm.off();
        }
        if (paymentData?.recordCount === 5) {
          setShowForm.off();
        }
        if ((isMobile && !paymentData?.recordCount) || !isMobile) {
          navigate('/');
        }
      }}
      isDisplayed={showForm || (isMobile && showForm)}
    >
      <Tabs variant="page" index={tabIndex} onChange={handleTabsChange}>
        <TabPanels>
          <TabPanel>
            {paymentData &&
              React.cloneElement(<AddSavedPaymentMethod />, {
                globalUser,
                paymentData,
                isLoading,
                showForm,
                setShowForm,
                isMobile,
                BluePay,
                autoRenewMethod,
              })}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </BackButtonLayout>
  );
};
