var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NumberFormat from 'react-number-format';
import { __DEV__, FormControl, FormLabel, FormHelperText, Input, Skeleton, } from '@companydotcom/potion';
import { Controller } from 'react-hook-form';
import { TranslationErrorMessage } from './translation-error-message';
export const ReactNumberFormatField = props => {
    const { name, label, helperText, control, formControlStyles, errors, isLoading, isReadOnly } = props, rest = __rest(props, ["name", "label", "helperText", "control", "formControlStyles", "errors", "isLoading", "isReadOnly"]);
    return (_jsx(Controller, { control: control, name: name, render: ({ field: { onChange, onBlur, value, name }, fieldState: { invalid, error } }) => {
            var _a;
            return (_jsxs(FormControl, Object.assign({ isInvalid: invalid, id: name, sx: formControlStyles }, { children: [_jsx(FormLabel, Object.assign({ htmlFor: name }, { children: label !== null && label !== void 0 ? label : '' }), void 0), _jsx(Skeleton, Object.assign({ isLoaded: !isLoading }, { children: _jsx(NumberFormat, Object.assign({ isReadOnly: isReadOnly, customInput: Input, name: name, value: value, onChange: onChange, onBlur: onBlur }, rest), void 0) }), void 0), helperText && !error && _jsx(FormHelperText, { children: helperText !== null && helperText !== void 0 ? helperText : '' }, void 0), errors && (_jsx(TranslationErrorMessage, Object.assign({ errors: errors, name: name }, { children: errors && (errors === null || errors === void 0 ? void 0 : errors[name]) && ((_a = errors === null || errors === void 0 ? void 0 : errors[name]) === null || _a === void 0 ? void 0 : _a.message) }), void 0))] }), void 0));
        } }, void 0));
};
if (__DEV__) {
    ReactNumberFormatField.displayName = 'ReactNumberFormatField';
}
