import React from 'react';
import { LinkBox, LinkOverlay, Image, Button, Box } from '@companydotcom/potion';
import { AcgRssFeedResponse } from '@companydotcom/types';

export const ImageFeed: React.FC<AcgRssFeedResponse> = ({
  href,
  imageUrl,
  text,
}: AcgRssFeedResponse) => {
  return (
    <LinkBox as={Box} flex={1} mx="auto" width="full" px={4} alignItems="flex-start">
      <LinkOverlay href={href!} isExternal display="flex" flexDirection="row" position="relative">
        <Image
          sx={{ objectFit: 'cover', objectPosition: 'center' }}
          maxWidth={120}
          minHeight={90}
          mr={2}
          src={imageUrl!}
        />
        <Button variant="link" whiteSpace="normal" textAlign="left" maxWidth={172}>
          {text}
        </Button>
      </LinkOverlay>
    </LinkBox>
  );
};
