export const mockScanData = {
  body: {
    payload: {
      results: {
        scan: [
          {
            count: 0,
            key: 'accountNumberOne',
          },
          {
            count: 0,
            key: 'accountNumberTwo',
          },
          {
            domain: 'leak.12-2019-combo',
            count: 3,
            description:
              'A credential combination list shared during December 2019 in the hacker community.  The original source of the data is unknown.',
            publishedDate: '2019-12-01T00:00:00.278Z',
            leakName: 'December 2019 Compilation Combo List',
            compromisedValues: [
              '122099***',
              'UADSXP0g***',
              '2019-02***',
              '<em>aid3nclark</em>@<em>gmail.com</***',
              'aid3ncl***',
              'Ai***',
              'Cl***',
              '121478***',
              'BADSXHFA***',
              '$2a$10$qqP.vRwLUlf1a0Rzk7Huv.cSJB5KMr6hfAz1NQsEQZOhk.9Waz***',
            ],
            key: 'email',
            tags: [
              'ID',
              'Metadata',
              'date',
              'Metadata',
              'Metadata',
              'Metadata',
              'email',
              'Metadata',
              'Website URL',
              'Password',
              'Metadata',
              'Metadata',
              'Metadata',
              'Metadata',
              'Metadata',
              'ID',
              'Metadata',
              'Metadata',
            ],
          },
          {
            count: 0,
            key: 'snn',
          },
        ],
      },
    },
  },
};

export const recommendations = [
  {
    tag: 'darkWebScan.tags.ssn',
    id: 'ssn',
    subheading: 'darkWebScan.recommendations.creditReports.subheading',
    text: ['darkWebScan.recommendations.creditReports.text'],
  },
  {
    tag: 'darkWebScan.tags.govId',
    id: 'govId',
    subheading: 'darkWebScan.recommendations.govId.subheading',
    text: ['darkWebScan.recommendations.govId.text', 'darkWebScan.recommendations.govId.text2'],
  },
  {
    tag: 'darkWebScan.tags.govId',
    id: 'password',
    subheading: 'darkWebScan.recommendations.password.subheading',
    text: ['darkWebScan.recommendations.password.text'],
  },
  {
    tag: 'darkWebScan.tags.bankAccount',
    id: 'financial',
    subheading: 'darkWebScan.recommendations.bankAccount.subheading',
    text: [
      'darkWebScan.recommendations.bankAccount.text',
      'darkWebScan.recommendations.bankAccount.text2',
    ],
  },
  {
    tag: 'darkWebScan.tags.ssn',
    id: 'ssn',
    subheading: 'darkWebScan.recommendations.ssnPin.subheading',
    text: ['darkWebScan.recommendations.ssnPin.text'],
  },
  {
    tag: 'darkWebScan.tags.password',
    id: 'password',
    subheading: 'darkWebScan.recommendations.exposedPassword.subheading',
    text: ['darkWebScan.recommendations.exposedPassword.text'],
  },
  {
    tag: 'darkWebScan.tags.ssn',
    id: 'ssn',
    subheading: 'darkWebScan.recommendations.identitiesWatchlist.subheading',
    text: ['darkWebScan.recommendations.identitiesWatchlist.text'],
  },
  {
    tag: 'darkWebScan.tags.ssn',
    id: 'ssn',
    subheading: 'darkWebScan.recommendations.earlyTaxes.subheading',
    text: ['darkWebScan.recommendations.earlyTaxes.text'],
  },
  {
    tag: 'darkWebScan.tags.bankAccount',
    id: 'financial',
    subheading: 'darkWebScan.recommendations.bankBreach.subheading',
    text: ['darkWebScan.recommendations.bankBreach.text'],
  },
  {
    tag: 'darkWebScan.tags.ssn',
    id: 'ssn',
    subheading: 'darkWebScan.recommendations.freezeCredit.subheading',
    text: [
      'darkWebScan.recommendations.freezeCredit.text',
      'darkWebScan.recommendations.freezeCredit.text2',
    ],
  },
  {
    tag: 'darkWebScan.tags.password',
    id: 'password',
    subheading: 'darkWebScan.recommendations.twoFactor.subheading',
    text: ['darkWebScan.recommendations.twoFactor.text'],
  },
  {
    tag: 'darkWebScan.tags.bankAccount',
    id: 'financial',
    subheading: 'darkWebScan.recommendations.bankTwoFactor.subheading',
    text: ['darkWebScan.recommendations.bankTwoFactor.text'],
  },
  {
    tag: 'darkWebScan.tags.email',
    id: 'email',
    subheading: 'darkWebScan.recommendations.emailPhishing.subheading',
    text: ['darkWebScan.recommendations.emailPhishing.text'],
  },
  {
    tag: 'darkWebScan.tags.password',
    id: 'password',
    subheading: 'darkWebScan.recommendations.privacyControls.subheading',
    text: ['darkWebScan.recommendations.privacyControls.text'],
  },
  {
    tag: 'darkWebScan.tags.creditCard',
    id: 'financial',
    subheading: 'darkWebScan.recommendations.creditCard.subheading',
    text: ['darkWebScan.recommendations.creditCard.text'],
  },
  {
    tag: 'darkWebScan.tags.password',
    id: 'password',
    subheading: 'darkWebScan.recommendations.uniquePassword.subheading',
    text: ['darkWebScan.recommendations.uniquePassword.text'],
  },
  {
    tag: 'darkWebScan.tags.email',
    id: 'email',
    subheading: 'darkWebScan.recommendations.uniqueEmail.subheading',
    text: [
      'darkWebScan.recommendations.uniqueEmail.text',
      'darkWebScan.recommendations.uniqueEmail.text2',
    ],
  },
  {
    tag: 'darkWebScan.tags.ipAddress',
    id: 'ip',
    subheading: 'darkWebScan.recommendations.ipAddress.subheading',
    text: [
      'darkWebScan.recommendations.uniqueEmail.text',
      'darkWebScan.recommendations.uniqueEmail.text2',
    ],
  },
  {
    tag: 'darkWebScan.tags.ssn',
    id: 'ssn',
    subheading: 'darkWebScan.recommendations.medicalAccounts.subheading',
    text: ['darkWebScan.recommendations.medicalAccounts.text'],
  },
];

export const tagMappings = {
  address: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/Address_Icon_HackNotice.png',
    color: '#bb4ee1',
    keys: ['darkWebScan.tags.address'],
  },
  email: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/EmailAddress_Icon_HackNotice.png',
    color: '#2f9fc5',
    keys: ['darkWebScan.tags.email', 'darkWebScan.tags.emailLower'],
  },
  'zip-code': {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/ZIPcode_Icon_HackNotice.png',
    color: '#bb4ee1',
    keys: ['darkWebScan.tags.zipCode'],
  },
  number: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/PhoneNumber_Icon_HackNotice.png',
    color: '#bb4ee1',
    keys: ['darkWebScan.tags.phoneNumber'],
  },
  'account-number': {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/AccountNumber_Icon_HackNotice.png',
    color: '#44c624',
    keys: ['darkWebScan.tags.accountNumber'],
  },
  financial: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/FinancialDetails_Icon_HackNotice.png',
    color: '#44c624',
    keys: ['darkWebScan.tags.financialInfo'],
  },
  passport: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/Passports_Icon_HackNotice.png',
    color: '#436ef1',
    keys: ['darkWebScan.tags.passport'],
  },
  govId: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/GovIssuedID_Icon_HackNotice.png',
    color: '#436ef1',
    keys: ['darkWebScan.tags.governmentId', 'darkWebScan.tags.id'],
  },
  identifier: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/Identifier_Icon_HackNotice.png',
    color: '#436ef1',
    keys: ['darkWebScan.tags.identifier'],
  },
  name: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/Name_Icon_HackNotice.png',
    color: '#436ef1',
    keys: ['darkWebScan.tags.name'],
  },
  'birth-date': {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/BirthDate_Icon_HackNotice.png',
    color: '#436ef1',
    keys: ['darkWebScan.tags.birthDate'],
  },
  ssn: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/SSNorEIN_Icon_HackNotice.png',
    color: '#436ef1',
    keys: ['darkWebScan.tags.ssnShort', 'darkWebScan.tags.ein'],
  },
  login: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/Login_Icon_HackNotice.png',
    color: '#2f9fc5',
    keys: ['darkWebScan.tags.login'],
  },
  ip: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/IPAddress_Icon_HackNotice.png',
    color: '#2f9fc5',
    keys: ['darkWebScan.tags.accountNumber'],
  },
  history: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/InternetHistory_Icon_HackNotice.png',
    color: '#2f9fc5',
    keys: ['darkWebScan.tags.browsingHistory', 'darkWebScan.tags.websiteUrl'],
  },
  password: {
    icon: 'https://assets-stage.company-corp.com/icons/hacknotice_icons/Password_Icon_HackNotice.png',
    color: '#2f9fc5',
    keys: ['darkWebScan.tags.password', 'darkWebScan.tags.passwordLower'],
  },
};
