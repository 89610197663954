import React from 'react';
import { useTabStepper, Tabs, TabPanels, TabPanel } from '@companydotcom/potion';
import { User } from '@companydotcom/types';
import { NewUserBasicInformationStep } from './new-user-basic-information-step';
import { CreateAUsernameStep } from './create-a-username-step';
import { NewUserAddedStep } from './new-user-added-step';
import { AddAdditonalEmailUsersStep } from './add-additional-email-users-step';
import { LoadingStep } from './loading-step';
import { ErrorStep } from './error-step';
import { isEmailProductActive } from '../../../pages/user-profile/utils';
import { useUserProfileContext } from '../../../providers/user-profile-context';
import { ChooseProductsStep } from './choose-products-step';

const addNewUserFlowSteps = [
  {
    slug: 'choose-products',
    component: <ChooseProductsStep />,
  },
  {
    slug: 'new-user-basic-information',
    component: <NewUserBasicInformationStep />,
  },
  {
    slug: 'add-additional-email-users',
    component: <AddAdditonalEmailUsersStep />,
  },
  {
    slug: 'create-a-username',
    component: <CreateAUsernameStep />,
  },
  {
    slug: 'new-user-added',
    component: <NewUserAddedStep />,
  },
  {
    slug: 'loading',
    component: <LoadingStep />,
  },
  {
    slug: 'error',
    component: <ErrorStep />,
  },
];

export interface AddNewUserFlowProps {
  user?: User;
  sourceId?: string;
  dispatchUser?: any;
  locale?: string;
}

export const AddNewUserFlow = ({ user, dispatchUser, sourceId, locale }: AddNewUserFlowProps) => {
  const { userProfileState } = useUserProfileContext();

  const { tabIndex, handleTabsChange, nextStep, goToStep } = useTabStepper({
    steps: addNewUserFlowSteps,
    initialStep: userProfileState?.initialNewUserStep,
  });

  const sharedProps = {
    user,
    dispatchUser,
    nextStep,
    sourceId,
    goToStep,
    isEmailProductActiveForParent: user ? isEmailProductActive(user) : false,
    locale,
  };

  return (
    <Tabs isLazy index={tabIndex} onChange={handleTabsChange} key={tabIndex}>
      <TabPanels>
        {addNewUserFlowSteps.map(step => (
          <TabPanel key={step.slug}>{React.cloneElement(step.component, sharedProps)}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
