/**
 * The CSS transform order following the upcoming spec from CSSWG
 * translate => rotate => scale => skew
 * @see https://drafts.csswg.org/css-transforms-2/#ctm
 * @see https://www.stefanjudis.com/blog/order-in-css-transformation-transform-functions-vs-individual-transforms/
 */
const transformTemplate = [
  'rotate(var(--potion-rotate, 0))',
  'scaleX(var(--potion-scale-x, 1))',
  'scaleY(var(--potion-scale-y, 1))',
  'skewX(var(--potion-skew-x, 0))',
  'skewY(var(--potion-skew-y, 0))',
];

export function getTransformTemplate() {
  return [
    'translateX(var(--potion-translate-x, 0))',
    'translateY(var(--potion-translate-y, 0))',
    ...transformTemplate,
  ].join(' ');
}

export function getTransformGpuTemplate() {
  return [
    'translate3d(var(--potion-translate-x, 0), var(--potion-translate-y, 0), 0)',
    ...transformTemplate,
  ].join(' ');
}

export const filterTemplate = {
  '--potion-blur': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-brightness': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-contrast': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-grayscale': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-hue-rotate': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-invert': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-saturate': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-sepia': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-drop-shadow': 'var(--potion-empty,/*!*/ /*!*/)',
  filter: [
    'var(--potion-blur)',
    'var(--potion-brightness)',
    'var(--potion-contrast)',
    'var(--potion-grayscale)',
    'var(--potion-hue-rotate)',
    'var(--potion-invert)',
    'var(--potion-saturate)',
    'var(--potion-sepia)',
    'var(--potion-drop-shadow)',
  ].join(' '),
};

export const backdropFilterTemplate = {
  backdropFilter: [
    'var(--potion-backdrop-blur)',
    'var(--potion-backdrop-brightness)',
    'var(--potion-backdrop-contrast)',
    'var(--potion-backdrop-grayscale)',
    'var(--potion-backdrop-hue-rotate)',
    'var(--potion-backdrop-invert)',
    'var(--potion-backdrop-opacity)',
    'var(--potion-backdrop-saturate)',
    'var(--potion-backdrop-sepia)',
  ].join(' '),
  '--potion-backdrop-blur': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-backdrop-brightness': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-backdrop-contrast': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-backdrop-grayscale': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-backdrop-hue-rotate': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-backdrop-invert': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-backdrop-opacity': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-backdrop-saturate': 'var(--potion-empty,/*!*/ /*!*/)',
  '--potion-backdrop-sepia': 'var(--potion-empty,/*!*/ /*!*/)',
};

export function getRingTemplate(value: any) {
  return {
    '--potion-ring-offset-shadow': `var(--potion-ring-inset) 0 0 0 var(--potion-ring-offset-width) var(--potion-ring-offset-color)`,
    '--potion-ring-shadow': `var(--potion-ring-inset) 0 0 0 calc(var(--potion-ring-width) + var(--potion-ring-offset-width)) var(--potion-ring-color)`,
    '--potion-ring-width': value,
    boxShadow: [
      `var(--potion-ring-offset-shadow)`,
      `var(--potion-ring-shadow)`,
      `var(--potion-shadow, 0 0 #0000)`,
    ].join(', '),
  };
}

export const flexDirectionTemplate = {
  'row-reverse': {
    space: '--potion-space-x-reverse',
    divide: '--potion-divide-x-reverse',
  },
  'column-reverse': {
    space: '--potion-space-y-reverse',
    divide: '--potion-divide-y-reverse',
  },
};

const owlSelector = '& > :not(style) ~ :not(style)';

export const spaceXTemplate = {
  [owlSelector]: {
    marginInlineStart: 'calc(var(--potion-space-x) * calc(1 - var(--potion-space-x-reverse)))',
    marginInlineEnd: 'calc(var(--potion-space-x) * var(--potion-space-x-reverse))',
  },
};

export const spaceYTemplate = {
  [owlSelector]: {
    marginTop: 'calc(var(--potion-space-y) * calc(1 - var(--potion-space-y-reverse)))',
    marginBottom: 'calc(var(--potion-space-y) * var(--potion-space-y-reverse))',
  },
};
