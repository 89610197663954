import React from 'react';
import { Flex, Center, Heading, Button, VStack } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { PageHeading } from '../../../../../../components/elements';

export interface BecomeAMemberPendingProps {
  isMobile?: boolean;
}

export const BecomeAMemberPending: React.FC<BecomeAMemberPendingProps> = props => {
  const { isMobile } = props;
  const navigate = useNavigate();

  return (
    <Center
      className="become-a-member__pending"
      px={[0, 4]}
      borderRadius="lg"
      flexDirection="column"
    >
      <PageHeading heading="Become A Member" width="full" maxW={908} />
      <Flex
        bg="white"
        mt={[4]}
        p={[4, 0]}
        width="full"
        maxW={908}
        flexDirection="column"
        alignItems="center"
      >
        <VStack mb={isMobile ? 6 : 0} mt={[0, 12]}>
          <Heading size={isMobile ? 'md' : 'hs-xl'} textAlign="center">
            Your membership is pending. You will soon be able to enjoy all of the benefits your
            membership includes.
          </Heading>
        </VStack>
        <Button size="lg" onClick={() => navigate('/')} my={[0, 12]}>
          GO TO MY ACG
        </Button>
      </Flex>
    </Center>
  );
};
