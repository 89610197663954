import * as React from 'react';
import { CloseButton, CloseButtonProps } from '../close-button';
import {
  potion,
  forwardRef,
  HTMLPotionProps,
  omitThemingProps,
  StylesProvider,
  ThemingProps,
  useMultiStyleConfig,
  useStyles,
} from '../../system';
import { SystemStyleObject } from '../../styled-system';
import { cx, runIfFn, __DEV__, MaybeRenderProp } from '../../utils';
import { PopoverProvider, usePopoverContext } from './popover-context';
import { PopoverTransition, PopoverTransitionProps } from './popover-transition';
import { usePopover, UsePopoverProps } from './use-popover';

export { usePopoverContext };

export interface PopoverProps extends UsePopoverProps, ThemingProps<'Popover'> {
  /**
   * The content of the popover. It is usually the `PopoverTrigger`,
   * and `PopoverContent`
   */
  children?: MaybeRenderProp<{
    isOpen: boolean;
    onClose: () => void;
    forceUpdate: (() => void) | undefined;
  }>;
}

/**
 * Popover is used to bring attention to specific user interface elements,
 * typically to suggest an action or to guide users through a new experience.
 */
export const Popover: React.FC<PopoverProps> = props => {
  const styles = useMultiStyleConfig('Popover', props);

  const { children, ...rest } = omitThemingProps(props);
  const context = usePopover({ ...rest });

  return (
    <PopoverProvider value={context}>
      <StylesProvider value={styles}>
        {runIfFn(children, {
          isOpen: context.isOpen,
          onClose: context.onClose,
          forceUpdate: context.forceUpdate,
        })}
      </StylesProvider>
    </PopoverProvider>
  );
};

if (__DEV__) {
  Popover.displayName = 'Popover';
}

/**
 * PopoverTrigger opens the popover's content. It must be an interactive element
 * such as `button` or `a`.
 */
export const PopoverTrigger: React.FC = props => {
  // enforce a single child
  const child: any = React.Children.only(props.children);
  const { getTriggerProps } = usePopoverContext();
  return React.cloneElement(child, getTriggerProps(child.props, child.ref));
};

if (__DEV__) {
  PopoverTrigger.displayName = 'PopoverTrigger';
}

export interface PopoverContentProps extends PopoverTransitionProps {
  rootProps?: HTMLPotionProps<'div'>;
}

export const PopoverContent = forwardRef<PopoverContentProps, 'section'>((props, ref) => {
  const { rootProps, ...contentProps } = props;

  const { getPopoverProps, getPopoverPositionerProps } = usePopoverContext();

  const styles = useStyles();
  const contentStyles: SystemStyleObject = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    ...styles.content,
  };

  return (
    <potion.div
      {...getPopoverPositionerProps(rootProps)}
      __css={styles.popper}
      className="potion-popover__popper"
    >
      <PopoverTransition
        {...getPopoverProps(contentProps, ref)}
        className={cx('potion-popover__content', props.className)}
        __css={contentStyles}
      />
    </potion.div>
  );
});

if (__DEV__) {
  PopoverContent.displayName = 'PopoverContent';
}

export interface PopoverHeaderProps extends HTMLPotionProps<'header'> {}

/**
 * PopoverHeader is the accessible header or label
 * for the popover's content and it is first announced by screenreaders.
 */
export const PopoverHeader = forwardRef<PopoverHeaderProps, 'header'>((props, ref) => {
  const { getHeaderProps } = usePopoverContext();

  const styles = useStyles();

  return (
    <potion.header
      {...getHeaderProps(props, ref)}
      className={cx('potion-popover__header', props.className)}
      __css={styles.header}
    />
  );
});

if (__DEV__) {
  PopoverHeader.displayName = 'PopoverHeader';
}

export interface PopoverBodyProps extends HTMLPotionProps<'div'> {}

/**
 * PopoverBody is the main content area for the popover. Should contain
 * at least one interactive element.
 */
export const PopoverBody = forwardRef<PopoverBodyProps, 'div'>((props, ref) => {
  const { getBodyProps } = usePopoverContext();

  const styles = useStyles();

  return (
    <potion.div
      {...getBodyProps(props, ref)}
      className={cx('potion-popover__body', props.className)}
      __css={styles.body}
    />
  );
});

if (__DEV__) {
  PopoverBody.displayName = 'PopoverBody';
}
export interface PopoverFooterProps extends HTMLPotionProps<'footer'> {}

export const PopoverFooter: React.FC<PopoverFooterProps> = props => {
  const styles = useStyles();
  return (
    <potion.footer
      {...props}
      className={cx('potion-popover__footer', props.className)}
      __css={styles.footer}
    />
  );
};

if (__DEV__) {
  PopoverFooter.displayName = 'PopoverFooter';
}

export type PopoverCloseButtonProps = CloseButtonProps;

export const PopoverCloseButton: React.FC<CloseButtonProps> = props => {
  const { onClose } = usePopoverContext();
  return (
    <CloseButton
      size="sm"
      onClick={onClose}
      position="absolute"
      borderRadius="md"
      top="0.25rem"
      insetEnd="0.5rem"
      padding="0.5rem"
      {...props}
    />
  );
};

if (__DEV__) {
  PopoverCloseButton.displayName = 'PopoverCloseButton';
}

export interface PopoverArrowProps extends HTMLPotionProps<'div'> {}

export const PopoverArrow: React.FC<PopoverArrowProps> = props => {
  const { bg, bgColor, backgroundColor } = props;
  const { getArrowProps, getArrowInnerProps } = usePopoverContext();
  const styles = useStyles();
  const arrowBg = bg ?? bgColor ?? backgroundColor;
  return (
    <potion.div {...getArrowProps()} className="potion-popover__arrow-positioner">
      <potion.div
        className={cx('potion-popover__arrow', props.className)}
        {...getArrowInnerProps(props)}
        __css={{
          ...styles.arrow,
          '--popper-arrow-bg': arrowBg ? `colors.${arrowBg}, ${arrowBg}` : undefined,
        }}
      />
    </potion.div>
  );
};

if (__DEV__) {
  PopoverArrow.displayName = 'PopoverArrow';
}
