import React, { useReducer, useCallback } from 'react';
import { Flex, Box, Text, Button, Center, Accordion, AccordionItem } from '@companydotcom/potion';
import { companyConstants } from '@companydotcom/helpers';
import { AppSpinner } from '@companydotcom/ui';
import { ExtendedSubscriptionRatePlan } from '@companydotcom/types';
import { useMitt } from '@companydotcom/providers';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SubscriptionAccordion } from '../components/subscription-accordion';
import { SubscriptionProps } from '../../../state/reducers/subscriptions-reducer';
import { calculateOrderTotal } from '../../../utils/helpers/purchasing-helpers';
import { cartReducer, initialCartState } from '../../../state/reducers/cart-reducer';
import { OpenPaywallEvent } from '../../../features/paywall/types';
import { PageHeading } from '../../../components/elements';

interface AvailableProductStepProps extends SubscriptionProps {}

export const AvailableProductStep = ({
  subscriptionState,
  dispatchSubscriptionState,
}: AvailableProductStepProps) => {
  const [cartState, dispatchCartState] = useReducer(cartReducer, initialCartState);
  const { t } = useTranslation();
  const { emitter } = useMitt();

  const optimisticallyUpdateProducts = useCallback(() => {
    dispatchSubscriptionState?.({
      type: 'UPDATE_AVAILABLE_PRODUCTS',
      payload: cartState.selectedRatePlans ?? [],
    });
    dispatchCartState({ type: 'RESET_ALL_CART_STATE' });
  }, [dispatchSubscriptionState, cartState.selectedRatePlans]);

  const handleSubmit = () => {
    // Grab selectedProducts here and trigger paywall on submit
    const eventData: OpenPaywallEvent = {
      type: 'openPaywall',
      metadata: {
        eventSource: 'subscriptions',
        products: cartState.selectedRatePlans,
        selectedTiers: cartState.selectedTiers,
        onSuccessHandler: optimisticallyUpdateProducts,
      },
    };

    emitter.emit(companyConstants.platformEvents.openPaywall, eventData);
  };

  return (
    <Box
      className="available-product-step"
      paddingTop={14}
      paddingBottom="80px"
      maxWidth={776}
      marginX="auto"
      paddingX={5}
    >
      <PageHeading heading={t('miop.subscriptions.availableProducts.header')} mb={6} />

      {subscriptionState?.availableProducts && subscriptionState?.availableProducts.length === 0 ? (
        <Box mb={6}>
          <Text textStyle="2xl" fontWeight="medium" pb={6}>
            {t('miop.subscriptions.availableProducts.noneAvailable')}
          </Text>
        </Box>
      ) : (
        <>
          <Flex flexDirection={['column', 'row']} width="full" justifyContent="space-between">
            <Text textStyle="2xl" fontWeight="medium" mb={2}>
              {t('miop.subscriptions.availableProducts.estimatedExpense')}
            </Text>
            <Text textStyle="2xl" fontWeight="medium" color="pricing" textAlign={['left', 'right']}>
              {calculateOrderTotal(
                cartState.selectedRatePlans as ExtendedSubscriptionRatePlan[],
                cartState.selectedTiers,
              ) === 0
                ? `$—/${t('miop.subscriptions.month')}`
                : `$${(
                    calculateOrderTotal(
                      cartState.selectedRatePlans as ExtendedSubscriptionRatePlan[],
                      cartState.selectedTiers,
                    ) + (subscriptionState?.currentSubscriptionPrice as number)
                  ).toFixed(2)}/${t('miop.subscriptions.month')}`}
            </Text>
          </Flex>
          <Flex flexDirection={['column', 'row']} width="full" justifyContent="space-between">
            <Text textStyle="md" mb={2}>
              {t('miop.subscriptions.availableProducts.currentExpense')}
            </Text>
            <Text
              fontWeight="medium"
              textStyle="md"
              color="pricing"
              mt={1}
              textAlign={['left', 'right']}
            >
              $
              {`${
                subscriptionState?.currentSubscriptionPrice === undefined
                  ? '—'
                  : (subscriptionState?.currentSubscriptionPrice as number).toFixed(2)
              }`}
              /{t('miop.subscriptions.month')}
            </Text>
          </Flex>

          <Center width="full" flexDirection="column" mt={8}>
            <Accordion width="full" allowMultiple allowToggle>
              {!subscriptionState?.availableProducts ||
              isEmpty(subscriptionState?.availableProducts) ? (
                <AppSpinner containerStyles={{ my: 14 }} />
              ) : (
                subscriptionState?.availableProducts.map((product, i) => (
                  <AccordionItem
                    key={i}
                    mb={4}
                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="none"
                    boxShadow="md"
                  >
                    {({ isExpanded }) => (
                      <SubscriptionAccordion
                        key={i}
                        isExpanded={isExpanded}
                        product={product}
                        productPrice={
                          product.minPrice || product?.ratePlans?.[0]?.tiers?.[0]?.price
                        }
                        cartState={cartState}
                        dispatchCartState={dispatchCartState}
                        availableProductStep
                      />
                    )}
                  </AccordionItem>
                ))
              )}
            </Accordion>

            <Text textStyle="sm" mt={4} maxWidth={570} textAlign="center" width="full">
              {t('miop.subscriptions.availableProducts.chargeNotice')}{' '}
            </Text>
            <Box mt={10} mb={15}>
              <Button
                size="lg"
                onClick={handleSubmit}
                // if cart is empty or if a tier has but selected but theres no item in cart that corresponds with it
                disabled={
                  !cartState.selectedRatePlans ||
                  isEmpty(cartState.selectedRatePlans) ||
                  (!isEmpty(cartState.selectedTiers) &&
                    cartState.selectedTiers?.some(
                      tier =>
                        tier?.productName !==
                        cartState.selectedRatePlans?.find(
                          cartItem => cartItem?.productName === tier?.productName,
                        )?.productName,
                    )) ||
                  cartState.selectedTiers?.some(t => t.isEnterprise)
                }
              >
                {t('miop.subscriptions.availableProducts.cta')}
              </Button>
            </Box>
          </Center>
        </>
      )}
    </Box>
  );
};
