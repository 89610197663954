import React from 'react';
import { Center, Heading, Button, Stack, Text, VStack, Box } from '@companydotcom/potion';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../components/elements';
import { MemberIcon } from '../components/memberIcon-asset';

export interface NoMembershipProps {
  isMobile?: boolean;
}

export const NonMembership: React.FC<NoMembershipProps> = props => {
  const { isMobile } = props;
  const { t } = useTranslation();

  return (
    <Center
      className="member-directory__become-a-member"
      flexDirection="column"
      px={[0, 4]}
      borderRadius="sm"
      py={[0, 8, 12]}
      bg={['transparent', 'white']}
    >
      <Box maxW={908} width="full">
        <PageHeading heading={t(`acg.memberSearch.search.header`)} />

        <VStack py={[4]} px={[4]} my={[4]} spacing={[4, 12]} bg={['white']}>
          <VStack>
            <MemberIcon width={[60, 120]} height={[65, 130]} />
            <Heading size={isMobile ? 'md' : 'hs-xl'} textAlign="center" color="#717174b5">
              {t(`acg.memberSearch.search.membershipStatus.nonMember`)}
            </Heading>
          </VStack>
          {!isMobile && (
            <Stack
              spacing={4}
              fontSize={18}
              fontWeight="bold"
              my={10}
              alignItems="center"
              textAlign="center"
            >
              <Text>Member-Exclusive Events and Programming</Text>
              <Text>Member-Only Discounts</Text>
              <Text>Access to the ACG Member Directory</Text>
              <Text>Middle Market Insights Webinars</Text>
            </Stack>
          )}
          <Button size="lg" as={NavLink} to="/join">
            BECOME A MEMBER
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};
