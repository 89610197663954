import React from 'react';
import { Box, BoxProps } from '@companydotcom/potion';

export const ProfileBox: React.FC<BoxProps> = props => {
  const { children, bg = 'white', ...rest } = props;
  return (
    <Box
      {...rest}
      backgroundColor={bg}
      borderRadius={4}
      width="100%"
      alignItems={['center', 'flex-start']}
      textAlign={['center', 'center', 'left', 'left']}
    >
      {children}
    </Box>
  );
};
