import React from 'react';
import { useMediaQuery } from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { useGetMembershipsV2Query, useGetAcgQueryArgs } from '../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import {
  BecomeAMember,
  BecomeAMemberActive,
  BecomeAMemberLapsed,
  BecomeAMemberPending,
} from '../../../features/acg/acg-registration';
import { useAcgCartStorage } from '../../../features/acg/shared';

export const BecomeAMemberPage: React.FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 48rem)');
  const args = useGetAcgQueryArgs();
  const { data: globalUser } = useGetGlobalUserQuery();
  const { data: memberData, isLoading } = useGetMembershipsV2Query({ ...args });
  const { getLocalCart } = useAcgCartStorage();
  const cart = getLocalCart();

  if (isLoading) {
    return <AppSpinner containerStyles={{ margin: 0, height: '100%', flexGrow: 1 }} />;
  }

  return (
    <PageLayout>
      <PageLayoutContent>
        {(memberData?.status === 'Inactive' ?? cart?.items?.memberships?.length) && (
          <BecomeAMember isSubpage />
        )}
        {memberData?.status === 'Lapsed' && (
          <BecomeAMemberLapsed {...{ isMobile, globalUser, memberData }} />
        )}
        {memberData?.status === 'Active' && !cart?.items?.memberships?.length && (
          <BecomeAMemberActive isMobile={isMobile} />
        )}
        {memberData?.status === 'Pending' && <BecomeAMemberPending isMobile={isMobile} />}
      </PageLayoutContent>
    </PageLayout>
  );
};
