import { TileHeader } from './tile-header';
import { TileFooter } from './tile-footer';
import { TileText } from './tile-text';
import { TileImage } from './tile-image';
import { TileSubheader } from './tile-subheader';
import { TileButtonGroup, TileButton, TilePollingButton } from './tile-button';
import { TileRating } from './tile-rating';
import { TileConditionText } from './tile-condition-text/tile-condition-text';
import { TileListingStatus } from './tile-listing-status';
import { TilePendingVerification } from './tile-pending-verification';
import { TileDate } from './tile-date';
import { TileUnderButtonText } from './tile-under-button-text/tile-under-button-text';
import { TileClickText } from './tile-click-text';
import { TileListingsReport } from './tile-listings-report';
import { TileChart } from './tile-chart';
import { TileSpinner } from './tile-spinner';
import { TileImageWithData } from './tile-image-with-data';
import { TileWithListItem } from './tile-with-listItem';
import { TileDynamicWarning } from './tile-dynamic-warning';
import { TileSelectTable } from './tile-select-table';
import { TileScheduleTable } from './tile-schedule-table';
import { TileStatusText } from './tile-status-text';
import { TileConferencingCard } from './tile-conferencing-card';
import { TileDocIcon } from './tile-doc-icon';
import { TileRecentReview } from './tile-recent-review';
import { TileWeeklyReview } from './tile-weekly-review';
import { TileStarRating } from './tile-star-rating';
import { TileAppointmentScheduler } from './tile-appointment-scheduler';
import { TileImageWithText } from './tile-image-with-text';
import { TilePhishingEligibility } from './tile-phishing-eligibility';
import { TileScheduledAppointment } from './tile-scheduled-appointment';
import { TileNextAvailableScan } from './tile-next-available-scan';
import { TileSwitch } from './tile-switch';
import { TileVulnerabilities } from './tile-vulnerabilities';
import { TileQuickLinks } from './tile-quick-links';
import { TileIncidentSummary } from './tile-incident-summary';
import { TileDeviceSummary } from './tile-device-summary';
import { TilePublisherSuggestions } from './tile-publisher-suggestions';
import { TileDisplayAppointment } from './tile-display-appointment';
import { TileSocialMediaMarketingOverview } from './tile-social-media-marketing-overview';
import { TileSocialMediaMarketingContent } from './tile-social-media-marketing-content';
import { TileSocialMediaEngagementStatus } from './tile-social-media-engagement-status';
import { TileSocialMediaEngagementSentiment } from './tile-social-media-engagement-sentiment';
import { TileAcgMembershipChecklist } from './tile-acg-membership-checklist';
import { TileAcgMembershipStatus } from './tile-acg-membership-status';
import { TileAcgMediaFeed } from './tile-acg-media-feed';
import { TileAcgCommittees } from './tile-acg-committees';
import { TileAcgMyEvents } from './tile-acg-my-events';
import { TileAcgRecommendedEvents } from './tile-acg-recommended-events';
import { TileThreatAdviceEducation } from './tile-threatadvice-education';

export const TileComponents = {
  // Done
  TileHeader,
  TileFooter,
  TileText,
  TileImage,
  TileSubheader,
  TileRating,
  TileConditionText,
  TileListingStatus,
  TilePendingVerification,
  TileDate,
  TileUnderButtonText, // Formally CCUnderButtonText
  TileClickText,
  TileSpinner, // Formally CCLoader
  TileImageWithData,
  TileDynamicWarning,
  TileSelectTable,
  TileConferencingCard, // Formally CCCard
  TileButtonGroup, // Formally CCButtonGroup
  TileDocIcon, // Formally CCDocIcon
  TileRecentReview,
  TileWeeklyReview,
  TileStarRating,
  TilePollingButton, // Formally PollingAwareButton
  TileAppointmentScheduler,
  TileImageWithText,
  TileWithListItem,
  TilePhishingEligibility, // Formally PhishingElgibility
  TileScheduledAppointment, // Formally ScheduledAppointment
  TileNextAvailableScan, // Formally NextAvailableScan
  TileSwitch,
  TileVulnerabilities,
  TileDeviceSummary,
  TileIncidentSummary,
  TileQuickLinks,
  TilePublisherSuggestions,
  TileDisplayAppointment,
  TileSocialMediaMarketingOverview,
  TileSocialMediaMarketingContent,
  TileSocialMediaEngagementStatus,
  TileSocialMediaEngagementSentiment,
  TileChart, // Formally CCChart
  TileStatusText, // Formally CCStatusText
  TileListingsReport, // Formally CCReport
  TileScheduleTable,
  TileImageWithVideo: TileImageWithText,
  TileImageWithLink: TileImageWithText,
  TileAcgMembershipChecklist,
  TileAcgMembershipStatus,
  TileAcgMediaFeed,
  TileAcgCommittees,
  TileAcgMyEvents,
  TileAcgRecommendedEvents,
  TileThreatAdviceEducation,
  // Not Done
  TileButton, // Needs badges done
  // CCDocList
};

// Notes

// - TileMeetingRoomList was for Conferencing Tile, which is now defunct
// - CCPhoneNumber was redundant with TileStatusText (phonenumber variant), so it was replaced
// - CCLabel - not used anywhere
// - TileCreditScore - not used anywhere
// - TileCreditGauge - not used anywhere
// - TileDuplicateSuppression - not used anywhere
