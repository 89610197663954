import React from 'react';
import { Image, Tag, TagLabel, Avatar } from '@companydotcom/potion';

interface TagProps {
  icon?: string;
  text: string;
  color?: string;
  textOnly?: boolean;
  bg?: string;
}

//Wanted to keep this generic but I realized we have quite a bit of logic to get through here.
export const DarkWebTag = ({ text, color, icon, textOnly = false, bg }: TagProps): JSX.Element => {
  return (
    <Tag
      borderRadius="full"
      backgroundColor={bg || 'white'}
      borderStyle="solid"
      borderWidth="2px"
      borderColor="gray.400"
    >
      {!textOnly && (
        <Avatar
          ml={-1}
          my={1}
          mr={5}
          icon={<Image src={icon} width="24px" />}
          bg={color}
          size="md"
        />
      )}
      <TagLabel>{text}</TagLabel>
    </Tag>
  );
};
