import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Registrant } from '../../../services/acg/acg.types';
import type { RootState } from '../../../store';

export interface ACGEventRegistrationState {
  activeRegistrant?: Registrant;
}

export const initialState: ACGEventRegistrationState = {
  activeRegistrant: undefined,
};

export const acgEventRegistrationSlice = createSlice({
  name: 'acgEventRegistration',
  initialState,
  reducers: {
    setActiveRegistrant: (state, action: PayloadAction<Partial<Registrant>> | undefined) => {
      state.activeRegistrant = action?.payload;
    },

    updateActiveRegistrant: (state, action: PayloadAction<Partial<Registrant>>) => {
      state.activeRegistrant = { ...state.activeRegistrant, ...action.payload };
    },
  },
});

export const { setActiveRegistrant, updateActiveRegistrant } = acgEventRegistrationSlice.actions;

export const selectActiveRegistrant = (state: RootState) =>
  state.acgEventRegistration.activeRegistrant;

export default acgEventRegistrationSlice.reducer;
