import { User, Auth0Client } from '@auth0/auth0-spa-js';
import { getSourceURI } from '../../services/source/old-api/source-srv-old';
import { linkAccounts } from '../../services/auth/old-api/auth-svc';
// import { SourceSvc } from '@companydotcom/services';

export const determineLogoutDestination = (source?: string) => {
  let newReturnUrl = `${window.location.origin}${process.env.REACT_APP_UNAUTH_REDIRECT}`;

  var facecakeTermsChecked = localStorage.getItem('facecake_termsChecked');
  localStorage.clear();
  localStorage.setItem('facecake_termsChecked', facecakeTermsChecked || '');

  if (source) {
    let newSource = source;
    const { origin } = window.location;
    const originArray = origin.split('//');
    const urlSplit = originArray[1].split('.');
    if (urlSplit[0].indexOf('dashboard') !== 0) {
      urlSplit.shift();
    }
    switch (source) {
      case 'ipayment':
        newSource = 'paysafe';
        break;
      case 'priority2':
        newSource = 'merchantprotectionplus';
        break;
      case 'priority3':
        newSource = 'bizdash';
        break;
      default:
        break;
    }

    newReturnUrl = `${originArray[0]}//${
      newSource !== 'company' ? `${newSource}.` : ''
    }${urlSplit.join('.')}`;
  }
  return newReturnUrl;
};

// const { getSourceURI } = SourceSvc?.();

export const handleExternalConnections = async (
  authUser: User,
  handleCallback: (redirect: string) => Promise<void>,
  // linkAccounts: any,
) => {
  const isSAMLConnection = authUser?.sub?.includes('samlp');
  const isOIDCConnection = authUser?.sub?.includes('oidc');
  const isPasswordless = authUser?.sub?.includes('email');

  if (isSAMLConnection || isOIDCConnection || isPasswordless) {
    console.log('Unlinked external account detected.');
    const sourceURI = await getSourceURI(window.location.host);
    const sourceId = sourceURI || 'company';

    const accountLinkResponse = await linkAccounts(
      sourceId,
      //* Making an assumption on what external identifier may look like here
      authUser?.externalIdentifier?.includes('mid')
        ? authUser?.externalIdentifier
        : authUser?.email,
      authUser,
    );

    /** RTK attempt, getting a dependency cycle error */
    // const accountLinkResponse = await linkAccounts({
    //   sourceId,
    //   idpUserId: authUser?.externalIdentifier?.includes('mid')
    //     ? authUser?.externalIdentifier
    //     : authUser?.email,
    //   externalAuthUser: authUser,
    // }).unwrap();

    if (accountLinkResponse.status >= 200 && accountLinkResponse.status <= 210) {
      handleCallback('/');
    }
  }
};

export const AUTH0_CUSTOM_RULE_KEY = 'https://company.com/user_authorization';

export interface Auth0User extends User {
  userId: string;
  email: string;
  isAuthenticated: boolean | undefined;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  acquisitionProduct?: string;
  [AUTH0_CUSTOM_RULE_KEY]: {
    email_code_verified: boolean;
    email_code_verified_ts: number;
    groups: string[];
    loginsCount: number;
    permissions: any[];
    phone_verified: boolean;
    phone_verified_ts: number;
    roles: string[];
    individualToken?: string | null;
  } | null;
}

export const localStorageDevtool = (user: Auth0User) => {
  localStorage.setItem('user_info', JSON.stringify(user));
};

export const getSourceIdFromGroups = (groups?: string[]) => {
  if (!groups) {
    return undefined;
  }
  const sourceGroup = groups.find(group => group.indexOf('Source:') === 0);
  return sourceGroup && sourceGroup.split(':')[1].toLocaleLowerCase();
};

export const AuthFactory = () => {
  let auth0Client: Auth0Client | null = null;
  const initialize = async (authDomain: string, clientId: string, clientAudience?: string) => {
    if (!auth0Client) {
      const client = new Auth0Client({
        domain: authDomain,
        client_id: clientId,
        redirect_uri: `${window.location.origin}/callback`,
        audience: clientAudience,
        cacheLocation: 'memory',
        useRefreshTokens: true,
      });
      auth0Client = client;
    }
    return auth0Client;
  };

  const getCore = (): Promise<Auth0Client> => {
    return new Promise(res => {
      setTimeout(() => {
        res(auth0Client || getCore());
      }, 250);
    });
  };

  return {
    initialize,
    getCore,
  };
};

export const getQueryParamsFromSessionStorage = () => {
  const params = sessionStorage.getItem('queryParams');
  if (params && params !== null && params !== '') {
    // @ts-ignore
    return JSON.parse(sessionStorage.getItem('queryParams'));
  }

  return undefined;
};
