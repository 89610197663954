var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export const cleanCommonProps = (props) => {
    // className
    const { className, // not listed in commonProps documentation, needs to be removed to allow Emotion to generate classNames
    clearValue, cx, getStyles, getValue, hasValue, isMulti, isRtl, options, // not listed in commonProps documentation
    selectOption, selectProps, setValue, theme } = props, // not listed in commonProps documentation
    innerProps = __rest(props, ["className", "clearValue", "cx", "getStyles", "getValue", "hasValue", "isMulti", "isRtl", "options", "selectOption", "selectProps", "setValue", "theme"]);
    return Object.assign({}, innerProps);
};
