import React from 'react';
import { Flex } from '@companydotcom/potion';
import { FaceCakeAccountManagement } from '../../features/facecake/facecake-account-management';

export const FaceCakeAccountManagementPage = () => {
  return (
    <Flex justify="center">
      <FaceCakeAccountManagement />
    </Flex>
  );
};
