import type { SystemStyleObject } from '../../styled-system';
import Input from './input';

const baseStyle: SystemStyleObject = {
  ...Input.baseStyle.field,
  textAlign: 'center',
};

const sizes: Record<string, SystemStyleObject> = {
  lg: {
    fontSize: 'lg',
    w: 12,
    h: 12,
    borderRadius: 'md',
  },
  md: {
    fontSize: 'md',
    w: 10,
    h: 10,
    borderRadius: 'md',
  },
  sm: {
    fontSize: 'sm',
    w: 8,
    h: 8,
    borderRadius: 'sm',
  },
  xs: {
    fontSize: 'xs',
    w: 6,
    h: 6,
    borderRadius: 'sm',
  },
};

const variants: Record<string, any> = {
  outline: (props: any) => Input.variants.outline(props).field ?? {},
  flushed: (props: any) => Input.variants.flushed(props).field ?? {},
  filled: (props: any) => Input.variants.filled(props).field ?? {},
  unstyled: Input.variants.unstyled.field ?? {},
};

const defaultProps = Input.defaultProps;

export default {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
