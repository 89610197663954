import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer, useContext, useMemo } from 'react';
// Code made with help from https://gist.github.com/sw-yx/f18fe6dd4c43fddb3a4971e80114a052
export function createCuiCtx(reducer, initialState) {
    const defaultDispatch = () => initialState;
    const ctx = createContext({
        userCuis: initialState,
        dispatchUserCuis: defaultDispatch,
    });
    function Provider(props) {
        const [userCuis, dispatchUserCuis] = useReducer(reducer, initialState);
        const contextValue = useMemo(() => {
            return { userCuis, dispatchUserCuis };
        }, [userCuis, dispatchUserCuis]);
        return _jsx(ctx.Provider, Object.assign({ value: contextValue }, props), void 0);
    }
    return [ctx, Provider];
}
const initialState = {};
function reducer(userCuis, action) {
    var _a;
    switch (action.type) {
        case 'INIT': {
            const newCUIs = { userCuis: (_a = action.payload) === null || _a === void 0 ? void 0 : _a.userCuis };
            return newCUIs;
        }
        case 'UPDATE':
            return { userCuis: action.payload.userCuis };
        default:
            // @ts-ignore
            return { userCuis };
    }
}
export const [CuiContext, CuiProvider] = createCuiCtx(reducer, initialState);
export const useCuiContext = () => useContext(CuiContext);
