import { Maybe } from '@companydotcom/types';

export interface RegistrationFormValues {
  firstName: string;
  lastName: string;
  organization: string;
  title: string;
  individualType: string;
  reference: string;
  addressLine1: string;
  preferredAddressType?: 'homeAddress' | 'workAddress' | '';
  preferredPhoneType?: 'mobilePhone' | 'workPhone' | '';
  city: string;
  state: string;
  postalCode: string;
  country: string;
  prefix?: string;
  middleName?: string;
  addressLine2?: string;
  phoneNumber?: string;
  extension?: string;
  email?: Email[];
  phone?: Phone[];
  address?: Address[];
  website?: string;
  bio: string;
  designation: string;
  dob: string;
  ethnicity: string;
  gender: 'male' | 'female';
  isMember: '0' | '1';
  linkedInName: string;
  suffix: string;
  transactionType: string;
  twitterName: string;
}

export interface AcgUserProfile {
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  emailAddressSecondary: string;
  organization: string;
  title: string;
  bio: string;
  reference: string;
  lastProfileUpdateVersion: number;
  industry: string[];
  individualType: string;
  transactionType: string[] | string | undefined;
  ethnicity: string;
  areaOfExpertise: string[];
  marketArea: string[];
  preferredPhoneType: 'mobilePhone' | 'workPhone' | '';
  mobilePhone: string;
  mobilePhoneExtension?: string;
  workPhone: string;
  workPhoneExtension?: string;
  dob: string;
  gender: 'male' | 'female' | string;
  preferredAddressType: 'homeAddress' | 'workAddress' | '';
  linkedInName: string;
  suffix: string;
  twitterName: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  state?: string;
  country?: string;
  /**
   * Send empty string instead of object to delete all fields
   */
  workAddress: Address | string;
  /**
   * Send empty string instead of object to delete all fields
   */
  homeAddress: Address | string;
  website: string;
  profilePicture?: string;
  isRegistered: boolean;
  designation: string;
  isMember: '0' | '1';
  syncProfilePic: boolean;
  memberships: { chapterName: string }[];
  contactId: string;
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export interface Email {
  emailAddress: string;
  type: string;
  isPrimary: boolean;
  recordKey?: string;
  delete?: 0 | 1;
}

export interface Industry {
  industryRecordKey: string;
  name?: string;
  recordKey?: string;
  delete?: 0 | 1;
}

export interface MarketArea {
  marketAreaRecordKey: string;
  name?: string;
  recordKey?: string;
  delete?: 0 | 1;
}

export interface Phone {
  phoneNumber: string;
  extension: string;
  isPrimary: boolean;
  recordKey?: string;
  phoneType?: string;
  delete?: 0 | 1;
}

export type FormData = Email | Phone | Address | MarketArea | Industry;

export type ReferenceDataOptions =
  | 'areaOfExpertise'
  | 'marketArea'
  | 'industry'
  | 'reference'
  | 'transactionType'
  | 'individualType'
  | 'suffix'
  | 'prefix'
  | 'country'
  | 'referer'
  | 'phoneType'
  | 'addressType'
  | 'membershipCustomFields'
  | 'eventsCustomFields'
  | 'hiddenFieldsFromMembershipDirectory';

export interface AcgReferenceDataPayload {
  referenceData: ReferenceDataOptions[];
}

export interface AcgReferenceData {
  transactionType?: RefRecord[];
  areaOfExpertise?: RefRecord[];
  marketArea?: RefRecord[];
  industry?: RefRecord[];
  prefix?: RefRecord[];
  country?: CountryRefRecord[];
  referer?: RefRecord[];
  individualType?: RefRecord[];
  phoneType?: RefRecord[];
  addressType?: RefRecord[];
  chapters?: Chapter[];
  suffix?: RefRecord[];
}

export interface RefRecord {
  recordName: string;
}

export interface CountryRefRecord {
  recordName: string;
  abbreviation: string;
}
export enum HiddenField {
  EMAIL_ADDRESS = 'emailAddress',
  PREFERRED_ADDRESS = 'preferredAddress',
  PREFERRED_PHONE = 'preferredPhoneNumber',
  WEBSITE = 'website',
}
export interface AcgMemberships {
  totalMembershipsFound: number;
  memberships: RhythmMembership[];
  status:
    | 'Active'
    | 'Pending'
    | 'Lapsed'
    | 'Inactive'
    | 'ACG Global Staff'
    | 'Chapter Executive'
    | 'Chapter Staff';
  hiddenFieldsFromMembershipDirectory: HiddenField[];
}

export interface Membership {
  recordKey: string;
  autoRenew: string;
  chapterName: string;
  chapterRecordKey?: string;
  joinDate: Date | string;
  renewDate: Date | string;
  expireDate: string;
  delete?: string;
  status: string;
  openInvoices: OpenInvoices[];
  savedPaymentRecordKey?: string;
}

export interface RhythmMembership {
  recordKey: string;
  autoRenew: string;
  chapterName: string;
  joinDate: string;
  renewDate: string;
  expireDate: string;
  status: string;
  openInvoices?: OpenInvoices;
  renewalPaymentInfo?: any;
  invoiceRecordKey?: string;
}

export interface OpenInvoices {
  mbrRecordKey?: string;
  invoiceRecordKey: string;
  chapterPriceRecordKey?: string;
}

export interface AcgCommittees {
  totalCommitteesFound: number;
  committees: Committee[];
}

export interface Committee {
  recordKey: string;
  committeeRecordKey: string;
  name: string;
  position: string;
  startDate: Date;
  endDate: Date;
  hasWorkspace: boolean;
}

/** @deprecated */
export interface GetPaymentOptionsRes {
  paymentOptions?: PaymentOption[];
  savedPayments: SavedPayment[];
}

export interface PaymentOption {
  recordKey: string;
  apmMethod:
    | 'MC - USD - Vantiv'
    | 'Amex - USD - Vantiv'
    | 'VISA - USD - Vantiv'
    | 'ACH'
    | 'International'
    | 'AMEX - CAD - Vantiv'
    | 'VISA - CAD - Vantiv'
    | 'MC - CAD - Vantiv';
  apmType: 'credit card' | 'ACH';
  cardFormat: string;
}

export type PurchaseTypes = 'visa' | 'mastercard' | 'american-express' | 'ach';

/**  TODO: remove after rhythm wire-up */
export interface SavedPayment {
  recordKey: string;
  paymentMethodRecordKey: string;
  cardNumber?: string;
  accountNumber?: string;
  cardExpiry?: string;
  cardHolderName?: string;
  preferred?: '0' | '1';
  routingNumber?: string;
  accountType?: 'Checking' | 'Savings';
  paymentNickName?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  paymentMethodType: string;
  paymentMethod: string;
  cardType: string;
  accountHolderName?: string;
}
/** ---------------------------------- */

export interface GetSavedPaymentMethodsRhythm {
  recordCount: number;
  savedPaymentMethods: SavedPaymentMethod[];
}
export interface SavedPaymentMethod {
  recordKey: string;
  paymentMethodType: string;
  cardNumber?: string;
  cardExpiry?: string;
  cardHolderName?: string;
  cardType?: string;
  paymentNickName: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  accountNumber?: string;
  accountType?: string;
  accountHolderName?: string;
  routingNumber?: string;
  preferred?: string;
  customFields?: any;
  merchantAccountTokens: MerchantAccountToken[];
}

export type AddToCartInput =
  | CartInputWithMemberships
  | CartInputWithEvents
  | CartInputWithMembershipsRhythm
  | (CartInputWithMemberships & CartInputWithEvents);

export interface CartInputWithMemberships {
  memberships: AcgMembership[];
}

export interface CartInputWithMembershipsRhythm {
  memberships: AcgMembershipRhythm[];
}

export interface CartInputWithEvents {
  events: EventForPurchase[];
}

export interface EventForPurchase {
  showEventFullAlert?: boolean;
  showWaitListAlert?: boolean;
  eventCapacity?: number;
  eventRecordKey?: string;
  guests?: EventForPurchaseGuest[] | undefined;
  hasWaitList?: boolean;
  waitListedRegistrants?: number;
  totalRegistered?: number;
  selectedRegistrantType?: RegistrantAndFee;
  registrant?: {
    registrationKey?: string;
    badgeName?: string;
    fullName?: string;
    organizationName?: string;
    title?: string;
    fees?: RegistrantAndFeePackage[];
    customQuestions?: Record<string, any>;
    selectedPackage?: string;
    sessions?: string[];
  };
  isMyEventsFlow?: boolean;
  registrantType?: RegistrantType;
  applicationId?: string;
}
export interface Registrant {
  fullName?: Maybe<string>;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  badgeName?: string;
  isExistingMember?: boolean;
  registrantId?: string;
  individualType?: Maybe<string>;
  registrantType?: string;
  functionRole?: string;
  organizationName?: string;
  title?: string;
  email?: string;
  guestRegistrationType?: string;
  customQuestions?: Record<string, any>;
  fees?: RegistrantAndFeePackage[];
}

export interface AcgMembership {
  chapterProductRecordKey: string;
  chapterPriceRecordKey: string;
  chapterRecordKey: string;
  autoRenew?: boolean;
  membershipTypeId?: string;
}

export interface AcgMembershipRhythm {
  applicationId: string;
  packageId: string;
  chapterId: string;
  processId: string;
  membershipTypeId?: string;
  customFields?: CustomField[];
}
export interface PriceComponent {
  price: string;
  priceKey: string;
  recordKey: string;
}

// export interface AcgPackages {
//   packageMembershipType: string;
//   chapters: Chapter[];
// }

export interface Chapter {
  chapterId: string;
  chapterName: string;
  chapterDescription: string;
  currencyCode: string;
  updatesWhen: string;
  duesProducts: DuesProduct[];
}

export interface DuesProduct {
  chapterTypeId: string;
  chapterTypeName: string;
  membershipTypeId: string;
  joinProcessId: string;
  packageBusinessUnitId: string;
  packageId: string;
  packageName: string;
  price: number;
  productId: string;
  productName: string;
}

export interface InvoiceLineItems {
  lineItemAmount: string;
  lineItemBalance: string;
  lineItemDiscount: string;
  lineItemDisplayName: string;
  lineItemKey: string;
  lineItemPaidAmount: string;
  lineItemPaidFlag: '0' | '1';
  lineItemQuantity: string;
  lineItemTotalWithoutDiscount: string;
  lineItemType: string;
  lineItemUnitPrice: string;
}

export interface InvoicePayments {
  accountNumberDisplay: string | null;
  creditCardFormat: string;
  creditCardNumberDisplay: string | null;
  paidAmount: string;
  paidDate: string;
  paymentKey: string;
  paymentMethod: string;
  paymentType: string;
}

export interface InvoiceTaxes {
  amount: number;
}

export interface InvoiceData {
  downloadUrl: string;
  invoiceDate: string;
  invoiceDues: string;
  invoiceKey: string;
  invoiceNumber: string;
  invoicePaid: string;
  invoiceTotal: string;
  lineItem: InvoiceLineItems[];
  payments: InvoicePayments[];
  taxes: InvoiceTaxes;
}

export interface InvoiceDataRhythm {
  invoices: InvoiceRhythm[];
}

export interface InvoiceRhythm {
  invoiceKey: string;
  invoiceNumber: number;
  invoiceDate: Date;
  invoiceTotal: number;
  invoiceDues: number;
  invoicePaid: number;
  lineItem: InvoiceLineItemRhythm[];
  taxes: TaxesRhythm;
  payments: PaymentRhythm[];
}

export interface InvoiceLineItemRhythm {
  lineItemKey: string;
  lineItemType: string;
  lineItemDisplayName: string;
  lineItemUnitPrice: number;
  lineItemQuantity: number;
  lineItemTotalWithoutDiscount: number;
  lineItemDiscount: number;
  lineItemAmount: number;
  lineItemPaidAmount: number;
  lineItemBalance: number;
  lineItemPaidFlag: number;
}

export interface PaymentRhythm {
  paidAmount: number;
  paidDate: Date;
  paymentKey: string;
  creditCardNumberDisplay: string;
  paymentType: string;
  paymentMethod: string;
  creditCardFormat: string;
}

export interface TaxesRhythm {
  amount: number;
}

export interface DoPayment {
  invoiceId?: string;
  autoRenew?: boolean;
  paymentInfo?: PaymentInfoRhythmPayload | {};
}

export type PaymentInfo = NewCreditCardPaymentInfo | NewACHPaymentInfo | SavedPaymentInfo;

export interface SavedPaymentInfo {
  savedPaymentMethodId: string;
  // cardCvv: string;
}
export interface NewCreditCardPaymentInfo {
  paymentMethodRecordKey: string;
  cardExpiry: string;
  cardNumber: string;
  cardHolderName: string;
  cardCvv: string;
  /** If true, saves the payment method */
  save: boolean;
  preferred?: boolean;
}

export interface NewACHPaymentInfo {
  paymentMethodRecordKey: string;
  accountNumber: string;
  routingNumber: string;
  accountType: 'checking' | 'savings';
  accountHolderName: string;
  /** If true, saves the payment method */
  save: boolean;
  preferred?: boolean;
}

export interface Feed {
  imageUrl?: string;
  href?: string;
  text?: string;
  data?: FeedData;
}

export interface FeedData {
  categories: [string];
  content: string;
  'content:encoded': string;
  'content:encodedSnippet': string;
  contentSnippet: string;
  creator: string;
  'dc:creator': string;
  guid: string;
  isoDate: string;
  link: string;
  pubDate: string;
  title: string;
}

export interface SuccessfulPaymentResponse {
  currencyCode?: string;
  currencyRecordKey?: string;
  pinCheckAmount?: string;
  savedPaymentRecordKey?: string;
  cardHolderName?: string;
  cardExpiry?: string;
  creditCardNum?: string;
  paymentMethodRecordKey?: string;
  paymentMethod?: string;
  paymentMethodType?: string;
  confirmationNumber?: string;
  routingNumber?: string;
  accountHolderName?: string;
  accountNumber?: string;
  accountType?: 'Savings' | 'Checking';
  invoiceAmount?: string;
  invoiceBalance?: string;
  invoiceType?: string;
  invoiceAmountApplied?: string;
}

export interface Memberships {
  globalMembership: PurchasedMembership[];
  chapterMemberships: PurchasedMembership[];
}

export interface PurchasedMembership {
  recordKey: string;
  membershipType: string;
  chapterRecordKey?: string;
  productName: string;
  paymentInfo: PurchasedPaymentInfo;
}

export interface PurchasedPaymentInfo {
  invoiceDetailAmount: string;
  invoiceDetailBalance: string;
  invoiceDetailAmountApplied: string;
}

export interface PurchasedEventInfo {
  recordKey: string;
  eventRecordKey: string;
  registrant: PurchasedEventRegistrant;
  paymentInfo: PurchasedPaymentInfo;
  productName: string;
}

export interface PurchasedEventRegistrant {
  registrantBadgeName: string;
  onWaitList: string;
  isGuest?: string;
}

export interface DoPaymentResponse {
  events: PurchasedEventInfo[];
  memberships: Memberships[];
  payment: Partial<SuccessfulPaymentResponse>;
}

export interface DoPaymentResponseRhythm {
  recordKey: string;
  type: string;
  date: Date;
  processId: string;
  submissionDate: Date;
  amountTotal: number;
  amountDiscount: number;
  amountTax: number;
  amountPaid: number;
  amountPendingCapture: number;
  amountDue: number;
  amountRefunded: number;
  paymentMethod: string;
  status: string;
  error?: string;
  currencyCode: string;
  currencySymbol: string;
  billingAddress: IngAddress;
  shippingAddress: IngAddress;
  paymentInfo: PaymentInfoRhythm;
  orderItems: OrderItem[];
}

export interface DoPaymentForOpenInvoice {
  date: Date;
  amount_pending_capture: number;
  electronic_payment_info: ElectronicPaymentInfo;
  batch_id: string;
  line_items: LineItemOpenInvoice[];
  card_last_digits: string;
  contact_id: string;
  type: string;
  base_currency_symbol: string;
  currency_code: string;
  number: number;
  total: number;
  cash_account_type: string;
  merchant_account_id: string;
  id: string;
  base_currency_code: string;
  transaction_id: string;
  captures: Capture[];
  currency_symbol: string;
  send_email_confirmation: boolean;
  card_expiration: Date;
  card_type: string;
  total_in_base_currency: number;
  business_unit_id: string;
  organization_id: string;
  order_id: string;
  owner_type: string;
  status: string;
  sys_version: number;
  sys_last_modified_at: Date;
  sys_created_at: Date;
  sys_last_modified_by_id: string;
  sys_created_by_id: string;
}

export interface Capture {
  date: Date;
  transaction_id: string;
  amount_captured: number;
  card_expiration: Date;
  raw_capture_info: string;
  card_last_digits: string;
  card_type: string;
}

export interface ElectronicPaymentInfo {
  payment_account: string;
  card_type: string;
  payment_origin: string;
  token: string;
}

export interface LineItemOpenInvoice {
  payment_line_item_id: string;
  invoice_line_item_id: string;
  invoice_line_item_balance_due_at_the_time_of_payment: number;
  total_in_invoice_currency: number;
  invoice_line_item_balance_due_after_payment: number;
  total_in_base_currency: number;
  type: string;
  amount_refunded: number;
  total: number;
  invoice_currency_code: string;
  product_type: string;
  product_id: string;
  invoice_line_item_total_at_the_time_of_payment: number;
  invoice_id: string;
  invoice_version_at_the_time_of_payment: number;
}

export interface IngAddress {
  addressLine1: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export interface OrderItem {
  itemType: string;
  productId: string;
  id: string;
  payloadId?: string;
  isItemOptional: boolean;
  productType: string;
  relatedRecordType?: string;
  status: string;
  relatedTransactions: RelatedTransaction[];
  unitPrice: number;
  quantity: number;
  amountTax: number;
  amountToPayOnCreation: number;
  amountTotal: number;
  amountDiscount: number;
  amountPaid: number;
  amountPendingCapture: number;
  amountDue: number;
  amountRefunded: number;
}

export interface RelatedTransaction {
  date: Date;
  transactionId: string;
  amount: number;
  transactionType: string;
  orderItemId: string;
  transactionNumber: number;
}

export interface PaymentInfoRhythm {
  paymentMethodType: string;
  merchantAccountId: string;
  paymentOrigin: string;
  merchantAccountTokens: MerchantAccountToken[];
}

export interface PaymentInfoRhythmPayload {
  paymentMethodType: string;
  cardNumber?: string;
  cardType?: string;
  cardExpiry?: string;
  cardHolderName?: string;
  autoRenew?: boolean;
  accountHolderName?: string;
  accountNumber?: string;
  accountType?: string;
  merchantAccountId: string;
  merchantToken: string;
}

export interface MerchantAccountToken {
  merchantAccountId: string;
  merchantToken: string;
}

export interface EventAttendee {
  registrationId: string;
  name: string;
  type: string;
  organizationName: string;
  title: string;
}

export interface PurchasedEvent {
  id: string;
  eventId: string;
  eventStartDate: Date;
  eventEndDate: Date;
  eventName: string;
  eventTypeKey: string;
  eventTypeName: string;
  eventLocation: string;
  eventLocationPlace: string;
  registrationInvoice: string;
  onWaitList: boolean;
  listRegistrantsOnWeb: boolean;
  eventTimezone: string;
  eventTimezoneAbbreviation: string;
  eventTimezoneMode: TimezoneMode;
  eventTimezoneOffset: number;
}
export interface AddOrUpdateStoredPaymentPayload {
  autoRenew?: boolean;
  paymentInfo: PaymentInfoPayload;
}

export interface PaymentInfoPayload {
  paymentMethodType?: 'credit card' | 'electronic check';
  cardType?: string;
  cardExpiry?: string;
  cardNumber?: string;
  cardHolderName?: string;
  paymentNickName?: string;
  accountHolderName?: string;
  accountNumber?: string;
  routingNumber?: string;
  accountType?: string;
  autoRenew?: boolean;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  merchantAccountId: string;
  merchantToken: string;
  customFields?: any;
}

export interface CartForInvoice extends DuesProduct {
  currencyCode?: string;
  discountAmountApplied?: string;
  displayName?: string;
  display_name?: string;
  nettToPay?: string;
}

export interface EventGuestContactInfo {
  name?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
}
export interface EventForPurchaseGuest {
  badgeName?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  fees?: EventFees[];
  packageId?: string;
  contactInfo?: EventGuestContactInfo;
}

export interface RegisteredEvent {
  eventId: string;
  registrationId: string;
  eventName: string;
  eventTypeKey: string;
  eventTypeName: string;
  eventLocation: string;
  onWaitList: boolean;
  eventCapacity: number;
  eventRegistrationsCount: number;
  registrantName: string;
  badgeName: string;
  organizationName: string;
  packageId: string;
  processId: string;
  qAndA: QAndA[];
  guests: Guest[];
  invoiceDetails: InvoiceDetails;
  hasWaitList: boolean;
  registrantType: string;
  listRegistrantsOnWeb: boolean;
  individualType: string;
  jobTitle: string;
  registrantTypeId: string;
  eventTimezone: string;
  eventTimezoneAbbreviation: string;
  eventTimezoneMode: TimezoneMode;
  eventTimezoneOffset: number;
}

export interface Guest {
  registrationId: string;
  customerId: string;
  name: string;
  badgeName: string;
  packageId: string;
  processId: string;
  registrantType: string;
  onWaitList: boolean;
  invoiceKey: string;
  productName: string;
  price: number;
  quantity: number;
  type: string;
  registrationDate: Date;
  title: string;
  organizationName: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  individualType: string;
  fees?: EventFees[];
}

export interface QAndA {
  qAndAKey: string;
  question: string;
  answer: string;
  isRequired: boolean;
  questionType: string;
}

export interface InvoiceDetails {
  invoiceKey: string;
  productName: string;
  price: number;
  quantity: number;
  type: string;
  registrationDate: Date;
}

export interface InvoiceDetail {
  invoiceCode: string;
  invoiceKey: string;
  productName: string;
  price: string;
  quantity: number;
  type: string;
}

export interface SearchIndividualResponse {
  contactId: string;
  firstName: string;
  lastName: string;
  organization?: string;
  title?: string;
  middleName?: string;
  prefix?: string;
  gender?: 'male' | 'female';
  dob?: Date;
  emailAddress?: string;
  twitterName?: string;
  individualType?: string;
}
export interface CreateCartResponse {
  id: string;
  cartType: string;
  subTotal: number;
  total: number;
  tax: number;
  discount: number;
  currencyCode: string;
  currencySymbol: string;
  coupons: Coupon[];
  lineItems: LineItem[];
}
export interface ApplyDiscountPayload {
  discountCodes: string[];
}
export interface ApplyDiscountResponse {
  id: string;
  cartType: string;
  subTotal: number;
  total: number;
  tax: number;
  discount: number;
  currencyCode: string;
  currencySymbol: string;
  coupons: Coupon[];
  lineItems: LineItem[];
}

export interface Coupon {
  couponTypeId: string;
  couponId: string;
  couponType: string;
  discount: number;
}

export interface LineItem {
  name: string;
  type: string;
  quantity: number;
  unitPrice: number;
  discount: number;
  tax: number;
  total: number;
}

export interface RecommendedEventsPayload {
  residentState: string;
  chapterNames: (Maybe<string> | undefined)[] | [];
}

export interface RecommendedEventsResponse {
  chapter?: { [key: string]: RecommendedEventsDetail[] }[];
  region?: { [key: string]: RecommendedEventsDetail[] }[];
  featured?: RecommendedEventsDetail[];
}

export interface RecommendedEventsDetail {
  name?: string;
  eventId?: string;
  netForumEventId?: string;
  chapterId?: string;
  chapterName?: string;
  isFeatured?: string;
  startDate?: string;
  endDate?: string;
  timezone?: string;
  url?: string;
}

export interface AddGuestsToRegisteredEvent {
  items: {
    events?: EventForPurchase[];
    memberships?: [];
  };
}

export interface SubmitMembershipApplication {
  name: string;
  autoRenew: boolean;
  packageId: string;
  chapterId: string;
  processId: string;
  membershipTypeId?: string;
  customFields?: CustomField[] | [];
}

export interface SubmitEventApplication {
  items: SubmitEventApplicationItems;
}
export interface SubmitEventApplicationItems {
  memberships: [];
  events: SubmitEventApplicationEvent[];
}
export interface SubmitEventApplicationEvent {
  eventId?: string;
  processId?: string;
  organizationId?: string;
  typeId?: string;
  productId?: string;
  badgeInfo?: Badge;
  guestsInfo?: EventForPurchaseGuest[];
  customFields?: Record<string, any>;
  sessions?: string[];
}

export interface CustomField {
  custom_field_id: string;
  string_value?: string;
}

export interface AddGuestsToRegisteredEventResponse extends CartForInvoice {}

export interface StoreRhythmTokenAndContactId {
  contactId?: string;
  auth0Token?: string;
}

export interface MemberSearchProps {
  firstName: string;
  lastName: string;
  organization: string;
  state: string[];
  primaryChapter: string[];
  marketArea: string[];
  areaOfExpertise: string[];
  country: string[];
  individualType: string[];
  transactionType: string[];
  page?: number;
}

export interface MemberSearchResults {
  members: Member[];
  totalNumberOfRows: number;
}

export interface Member extends AcgUserProfile {
  recordKey?: string;
  chapterIds?: string;
  phone?: string;
}

export enum TimezoneMode {
  SPECIFIC = 'specific timezone',
  LOCAL = 'local',
}

export interface EventForRegistration {
  eventKey: string;
  eventName: string;
  eventTypeKey: string;
  eventTypeName: string;
  eventStartDate: Date;
  eventEndDate: Date;
  isEventClosed: boolean;
  isEventFree: boolean;
  isEventFreeGuest: boolean;
  eventCapacity: number;
  eventCapacityEnabled: boolean;
  listRegistrantsOnWeb: boolean;
  registeredGuestCount: number;
  hasWaitList: boolean;
  onWaitList: boolean;
  isDeleted: boolean;
  hasSessions: boolean;
  hasTracks: boolean;
  isEventMemberOnly: boolean;
  hasFees: boolean;
  totalRegistered: number;
  totalCancelled: number;
  totalWaitList: number;
  isAlreadyRegistered: boolean;
  businessUnitId: string;
  merchantAccountId: string;
  chapterId: string;
  eventDescription?: string;
  maxGuestsPerRegistration?: number;
  enableACHPayment?: boolean;
  registrantAndFees: RegistrantAndFee[];
  eventTimezone: string;
  eventTimezoneAbbreviation: string;
  eventTimezoneMode: TimezoneMode;
  eventTimezoneOffset: number;
}

export enum QuestionType {
  TEXT = 'text',
  DROPDOWN = 'picklist',
  FLAG = 'yes-no',
  NUMBER = 'number',
  CHECKBOXGROUP = 'multiselect picklist',
  CHECKBOXES = 'checkboxes',
  DATEPICKER = 'date',
  LARGETEXT = 'large text',
}

export interface CustomQuestion {
  questionId: string;
  questionName: string;
  isRequired: boolean;
  questionType: QuestionType;
  numberOfDecimalPlaces?: number;
  maximumNumberOfWords?: number;
  selectionOptions?: string[];
}

export interface RegistrantAndFee {
  registrantType: RegistrantType;
  packages: RegistrantAndFeePackage[];
  guests: GuestWithPackages[];
  customQuestions?: CustomQuestion[];
}

export interface GuestWithPackages {
  registrant_type: RegistrantType;
  packages: GuestPackage[];
}

export interface GuestPackage {
  eventFees: EventFees;
}

export interface EventFees {
  price: number;
  currencyCode: string;
  currencySymbol: string;
  sessionFeeId: string;
  productName: string;
  priceKey?: string;
  sessionId: string;
}

export interface RegistrantAndFeePackage {
  eventFees: EventFees;
  sessions: Session[];
}

export interface Session {
  sessionId: string;
  sessionName: string;
  sessionTypeId: string;
  sessionAccessType: string;
  sessionStartDate: Date;
  sessionEndDate: Date;
  isSessionClosed: boolean;
  tracks: Track[];
  sessionFees: EventFees[];
  sessionDescription?: string;
}

export interface Track {
  trackName: string;
  trackId: string;
}

export interface RegistrantType {
  registrantProcessId: string;
  registrantTypeName: string;
  registrantTypeId: string;
  registrantType: string;
  restrictedToGuests: boolean;
  isPortalRegistrantType: boolean;
  registrationOpenDate?: Date;
  maxGuestsPerRegistration?: number;
}

export interface UpdateRegisteredEventPayload {
  registrationId: string;
  badge?: Badge;
  customQuestions?: Record<string, any>;
}

export interface Badge {
  location?: string;
  fullName?: string;
  organizationName?: string;
  firstName?: string;
  title?: string;
  jobTitle?: string;
}

export interface ContactInfo {
  name?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  preferredPhoneNumberType?: string;
}

export enum ADAFields {
  ADA = 'events-registrations__ada_requirementsinformation__c',
  DIETARY = 'events-registrations__ada_requirements__c',
}
