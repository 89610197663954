import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Radio, Button } from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';

export const PaymentMethodsList = ({
  handleRadioSelect,
  handleUpdatePaymentMethod,
  paymentMethod,
  processOrderSummary,
  goToStep,
  user,
  selectedRatePlans,
  handlePayment,
  selectedTiers,
}: any) => {
  const { t } = useTranslation();
  const [checkoutState, setCheckoutState] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { id, cardNumber } = paymentMethod;

  const createOrders = () => {
    const orders: any[] = [];
    selectedRatePlans?.map((plan: any) => {
      orders.push({
        productId: plan.productId,
        ratePlanId: plan.ratePlanId,
        dxpPlanId: plan.id,
        // @ts-ignore
        quantity: plan.qty
          ? plan.qty
          : selectedTiers && selectedTiers?.length > 0
          ? selectedTiers.find((tier: any) => tier?.productName === plan?.productName)?.qty || 1
          : 1,
      });
    });
    return orders;
  };

  const processOrder = async (user?: any, orders?: any) => {
    const processOrderResponse = await processOrderSummary({
      userId: user.userId,
      partnerBillingEnabled: user.account?.allowPartnerBilling,
      //@ts-ignore
      orderSummary: orders,
      paymentMethodId: id,
    }).unwrap();
    return processOrderResponse;
  };

  const onSubmit = () => {
    setIsLoading(true);
    const orders = createOrders();
    try {
      processOrder(user, orders).then(resp => {
        setIsLoading(false);
        if (resp) {
          const paymentData: any = resp[Object.keys(resp)[0]];
          handlePayment(true, cardNumber, paymentData);
          goToStep?.('payment-success');
        } else {
          console.log('Payment failed!');
          throw 'Payment failed!';
        }
      });
    } catch (error) {
      console.log(error);
      handlePayment(false, '');
    }
  };

  useEffect(() => {
    setCheckoutState(false);
  }, [cardNumber]);

  return isLoading ? (
    <AppSpinner marginTop="100px" />
  ) : (
    <Flex flexDirection="column" alignItems={['center', 'flex-start']} mt={[6]} mb={[13, 16]}>
      {/* Eventually this will iterate over a list of payment methods, but this has to be implemented on the backend -- 2.8.20 */}
      <Radio name="paymentmethod" defaultChecked onChange={handleRadioSelect}>
        {t('components.paymentMethod.list.useCard', { cardNumber })}
      </Radio>
      <Button variant="link" mt={[10]} fontSize="sm" onClick={handleUpdatePaymentMethod}>
        {t('components.paymentMethod.list.replaceThisCard')}
      </Button>
      <Button
        size="lg"
        position="absolute"
        bottom="-66px"
        left="0"
        right="0"
        width="fit-content"
        marginLeft="auto"
        marginRight="auto"
        onClick={onSubmit}
        isDisabled={checkoutState}
      >
        Confirm Purchase
      </Button>
    </Flex>
  );
};
