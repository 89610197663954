import { useMemo } from 'react';
import {
  Center,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Box,
  VStack,
  Button,
} from '@companydotcom/potion';
import {
  InputField,
  TranslationErrorMessage,
  PageDivider,
  AppSpinner,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import {
  useGetGlobalUserQuery,
  useUpdateAccountBusinessMutation,
  useUpdateUserMutation,
} from '../../../services/user/user-api';
import { useSubmitProfileDataMutation } from '../../../services/facecake/facecake-api';
import { useAwaitableFacade } from '../../../hooks';
import yup from '../../../lib/yup';
import { useGetFacecakeColors } from '../layouts/components/facecake-background-imager';

const radioOptions = [
  { label: '1-50', value: '0' },
  { label: '51-200', value: '1' },
  { label: '201-499', value: '2' },
  { label: '500+', value: '3' },
];

const getLetsGetToKnowYouFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('common.inputs.firstName.error'),
    lastName: yup.string().required('common.inputs.lastName.error'),
    phone: yup.string().phone().nullable(),
    website: yup.string().required('Please enter a valid website'),
    additionalInterest: yup.array().nullable(),
    productCategory: yup.array().min(1, 'At least one must be selected').nullable(),
    accessories: yup.string().when('productCategory', {
      is: (productCategory: string[]) => productCategory?.includes('Accessories'),
      then: yup.string().required('common.misc.required'),
    }),
    other: yup.string().when('productCategory', {
      is: (productCategory: string[]) => productCategory?.includes('Other'),
      then: yup.string().required('common.misc.required'),
    }),
    numberOfSkus: yup.string().required('Required'),
    tryOn: yup.string().required('Required'),
  });

export const LetsGetToKnowYouForm = (props: any) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const [submitProfileData] = useSubmitProfileDataMutation();
  const [updateAccountBusiness] = useUpdateAccountBusinessMutation();
  const snsInterface = useAwaitableFacade(globalUser);
  const prefillValues = localStorage?.pre_user_reg ? JSON.parse(localStorage.pre_user_reg) : null;
  const facecakeSpinnerStyles = useGetFacecakeColors();

  const { nextStep } = props;
  const { t } = useTranslation();
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();
  const phoneNumber = useMemo(() => {
    if (localStorage.user_phoneno) {
      return parsePhoneNumber(localStorage?.user_phoneno, country)?.number;
    }
    if (prefillValues?.phone) {
      return parsePhoneNumber(prefillValues?.phone, country)?.number;
    }
    if (globalUser?.phone) {
      return parsePhoneNumber(globalUser?.phone, country)?.number;
    }
    return '';
  }, [country, globalUser?.phone, prefillValues?.phone]);

  const defaultValues: FieldValues = useMemo(
    () => ({
      firstName: prefillValues?.firstName || globalUser?.firstName || '',
      lastName: prefillValues?.lastName || globalUser?.lastName || '',
      organization: prefillValues?.organization || '',
      phone: phoneNumber,
      email: prefillValues?.email || globalUser?.email || '',
      additionalInterest: undefined,
      website: prefillValues?.website || '',
      productCategory: prefillValues?.productCategory ? prefillValues?.productCategory : undefined,
      accessories: prefillValues?.accessories ? prefillValues?.accessories : '',
      other: prefillValues?.other ? prefillValues?.other : '',
      numberOfSkus: '',
      tryOn: '',
    }),
    [
      globalUser?.email,
      globalUser?.firstName,
      globalUser?.lastName,
      phoneNumber,
      prefillValues?.accessories,
      prefillValues?.email,
      prefillValues?.firstName,
      prefillValues?.lastName,
      prefillValues?.organization,
      prefillValues?.other,
      prefillValues?.productCategory,
      prefillValues?.website,
    ],
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getLetsGetToKnowYouFormSchema()),
    defaultValues,
    context: { country },
  });
  const productCategory = useWatch({ name: 'productCategory', control });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      const userRes = await updateUser({
        userId: globalUser?.userId as string,
        firstName: values?.firstName,
        lastName: values?.lastName,
        // @ts-ignore
        organization: values?.organization,
        phone: getFormattedPhoneNumber(values.phone, country ?? 'US'),
      });
      const accountRes = await updateAccountBusiness({
        userId: globalUser?.userId,
        accountId: globalUser?.accountId,
        website: values?.website,
      });
      const profileRes = await submitProfileData({
        snsInterface,
        payload: {
          productCategory: values?.productCategory,
          accessories: values?.accessories,
          other: values?.other,
          numberOfSkus: values?.numberOfSkus,
          tryOn: values?.tryOn,
          additionalInterest: values?.additionalInterest,
        },
      });
      if (userRes && accountRes && profileRes) {
        localStorage.removeItem('pre_user_reg');
        nextStep?.();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return globalUser ? (
    <Center
      className="getToKnowYouForm"
      maxWidth={[null, '2xl']}
      py={[4, 12]}
      px={[0, 4]}
      flexDir="column"
      layerStyle={{ base: undefined, sm: 'formContainer' }}
      mb="14"
      textAlign={['left', 'center']}
    >
      <Heading size="hs-2xl" width="full">
        Complete Your Profile
      </Heading>
      <PageDivider width="50px" mt="4" alignSelf="flex-start" display={['block', 'none']} />

      <VStack mt={['6', '12']} as="form" spacing="6" width="full" maxW={['auto', 'xs']}>
        <InputField
          className="facecake-form-getToKnowYou__firstNameField"
          data-test="facecake-form-getToKnowYou__firstNameField"
          register={register}
          name="firstName"
          label={t('common.inputs.firstName.label')}
          errors={errors}
        />
        <InputField
          className="facecake-form-getToKnowYou__lastNameField"
          data-test="facecake-form-getToKnowYou__lastNameField"
          register={register}
          name="lastName"
          label={t('common.inputs.lastName.label')}
          errors={errors}
        />
        <InputField
          className="facecake-form-getToKnowYou__organizationField"
          data-test="facecake-form-getToKnowYou__organizationField"
          register={register}
          name="organization"
          label={t('common.inputs.organization.label')}
          errors={errors}
        />
        <InputField
          className="facecake-form-getToKnowYou__emailField"
          data-test="facecake-form-getToKnowYou__emailField"
          register={register}
          name="email"
          label={t('common.inputs.email.label')}
          errors={errors}
        />
        <PhoneNumberInputField
          name="phone"
          label="Phone"
          className="facecake-form-getToKnowYou__phoneField"
          data-test="facecake-form-getToKnowYou__phoneField"
          control={control}
          country={country}
          onCountryChange={onCountryChange}
          errors={errors}
        />
        <InputField
          className="facecake-form-getToKnowYou__websiteField"
          data-test="facecake-form-getToKnowYou__websiteField"
          register={register}
          name="website"
          label="Website"
          errors={errors}
        />
        <FormControl id="additionalInterest" isInvalid={errors && !!errors?.['additionalInterest']}>
          <FormLabel htmlFor="additionalInterest">Additional areas of interest:</FormLabel>
          <Controller
            name="additionalInterest"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <CheckboxGroup {...rest}>
                <VStack spacing="3" align="flex-start">
                  <Checkbox
                    key={0}
                    value="Homepage Product Promotion"
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Homepage-Product-Promotion"
                  >
                    Homepage Product Promotion
                  </Checkbox>
                  <Checkbox
                    value="AR Ads"
                    key={1}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_AR-ADs"
                  >
                    AR Ads
                  </Checkbox>
                  <Checkbox
                    value="Mobile App: iOS"
                    key={2}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Mobile-App:-iOS"
                  >
                    Mobile App: iOS
                  </Checkbox>
                  <Checkbox
                    value="Mobile App: Android"
                    key={3}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Mobile-App:-Android"
                  >
                    Mobile App: Android
                  </Checkbox>
                </VStack>
              </CheckboxGroup>
            )}
          />
        </FormControl>
        <FormControl id="productCategory" isInvalid={errors && !!errors?.['productCategory']}>
          <FormLabel htmlFor="productCategory">
            In addition to eyewear, I am interested in:
          </FormLabel>
          <Controller
            name="productCategory"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <CheckboxGroup {...rest}>
                <VStack spacing="3" align="flex-start">
                  <Checkbox
                    value="Jewelry"
                    key={1}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Jewelry"
                  >
                    Jewelry
                  </Checkbox>
                  <Checkbox
                    value="Makeup"
                    key={2}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Make-Up"
                  >
                    Makeup
                  </Checkbox>
                  <Checkbox
                    value="Clothing"
                    key={3}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Clothing"
                  >
                    Clothing
                  </Checkbox>
                  <Checkbox
                    value="Handbags"
                    key={4}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Handbags"
                  >
                    Handbags
                  </Checkbox>
                  <Checkbox
                    value="Accessories"
                    key={5}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Accessories"
                  >
                    Accessories
                  </Checkbox>
                  {/* @ts-ignore */}
                  {productCategory?.includes('Accessories') ? (
                    <InputField
                      className="facecake-lead-gen__accessories"
                      data-test="facecake-lead-gen__accessories"
                      register={register}
                      name="accessories"
                      errors={errors}
                      width={['343px', '340px']}
                    />
                  ) : null}
                  <Checkbox
                    value="Other"
                    key={6}
                    ref={ref}
                    size="lg"
                    className="facecake-form-getToKnowYou__checkboxField"
                    data-test="facecake-form-getToKnowYou__checkboxField_Other"
                  >
                    Other
                  </Checkbox>
                  {/* @ts-ignore */}
                  {productCategory?.includes('Other') ? (
                    <InputField
                      className="facecake-lead-gen__other"
                      data-test="facecake-lead-gen__other"
                      register={register}
                      name="other"
                      errors={errors}
                      width={['343px', '340px']}
                    />
                  ) : null}
                </VStack>
              </CheckboxGroup>
            )}
          />
          {errors && (
            <TranslationErrorMessage errors={errors} name="productCategory">
              {/* @ts-ignore */}
              {errors?.['productCategory']?.message}
            </TranslationErrorMessage>
          )}
        </FormControl>
        <Controller
          name="numberOfSkus"
          control={control}
          render={({ field }) => (
            <FormControl id="numberOfSkus" isInvalid={errors && !!errors?.['numberOfSkus']}>
              <FormLabel htmlFor="numberOfSkus">Current number of eyewear styles?</FormLabel>
              <RadioGroup {...field}>
                <VStack spacing="3" align="flex-start">
                  {radioOptions.map((option, i) => (
                    <Radio
                      key={i}
                      value={option.value}
                      className="facecake-form-getToKnowYou__radioField"
                      data-test={`facecake-form-getToKnowYou__radioField_${option?.value}`}
                    >
                      {option.label}
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
              {errors && (
                <TranslationErrorMessage errors={errors} name="numberOfSkus">
                  {errors?.['numberOfSkus']?.message}
                </TranslationErrorMessage>
              )}
            </FormControl>
          )}
        />
        <InputField
          className="facecake-form-getToKnowYou__tryOnField"
          data-test="facecake-form-getToKnowYou__tryOnField"
          register={register}
          name="tryOn"
          label="How many eyewear styles are available for Try-On?"
          errors={errors}
        />
        <Box width={['full', 'auto']}>
          <Button
            onClick={handleSubmit(onSubmit)}
            mt={['6']}
            size="lg"
            isDisabled={!isValid}
            isLoading={isSubmitting}
            width={['full', 'inherit']}
          >
            {t('common.buttons.continue')}
          </Button>
        </Box>
      </VStack>
    </Center>
  ) : (
    <AppSpinner
      containerStyles={{
        mt: 12,
        mx: 'auto',
      }}
      {...facecakeSpinnerStyles}
    />
  );
};
