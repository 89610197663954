import React, { useState, useEffect, useCallback } from 'react';
import _get from 'lodash/get';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Outlet } from 'react-router-dom';

import { UserTile, Notification, Product, Appointment, UserGroup } from '@companydotcom/types';
import { companyConstants, companyHelpers as utils, platformHelpers } from '@companydotcom/helpers';
import { useCuiContext, useSource, useMitt } from '@companydotcom/providers';

import { Paywall } from '../../../features/paywall';
import { DataCollector } from '../../../features/data-collector/components/data-collector';
import { NavController } from '../../../components/elements/nav-controller';
import { handleNavEvent } from '../../../components/elements/nav-controller/nav-helper';
import { useSubscribeToAppointments } from '../../../services/user/old-api/user-svc';
import { gtm } from '../../../lib/google-tag-manager';
import { useSubscribeToNotifications } from '../../../services/notification/notification-svc';
import { getNotifications } from '../api';
import {
  GroupsProvider,
  TilesProvider,
  NotificationsProvider,
  UserProfileProvider,
  useAuth,
} from '../../../providers';
import { NotificationsController } from '../../../components/elements/notifications';
import { useAppDispatch } from '../../../hooks';
import { addNewAppointment } from '../../../features/users';
import {
  useGetGlobalUserQuery,
  useLazyGetUserTilesQuery,
  useLazyGetUserGroupsQuery,
  useSetUserTileStateMutation,
} from '../../../services/user/user-api';
import {
  useGetAcgQueryArgs,
  useGetAcgReferenceDataV2Query,
  useGetAllChaptersAndPackagesV2Query,
  useGetAcgUserProfileV2Query,
  usePrefetch,
} from '../../../services/acg/acg-api-v2';
import { QueryPrefetcher } from '../../../components/elements';
import { useLazyPublishTileEventQuery } from '../../../services/event/event-api';
import {
  useLazyGetNotificationListQuery,
  useUpdateNotificationHandledMutation,
} from '../../../services/notification/notification-api';
import { useLazyGetUserCuisQuery } from '../../../services/cui/cui-api';

const getTilesFromProductId = (userTiles: UserTile[], productId: string) => {
  const tilesToActivate = userTiles.filter(tile => tile.productId === productId);
  if (tilesToActivate.length < 1) {
    throw new Error(`No tile matching the given product id: ${productId}`);
  }
  return tilesToActivate;
};

export const Dashboard = () => {
  const { emitter } = useMitt();
  const auth0 = useAuth();
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const source = useSource();
  const dispatch = useAppDispatch();
  const { dispatchUserCuis } = useCuiContext();
  const [getUserGroups] = useLazyGetUserGroupsQuery();
  const [getUserTiles] = useLazyGetUserTilesQuery();
  const [publishTileEvent] = useLazyPublishTileEventQuery();
  const prefetchAcgProfile = usePrefetch('getAcgUserProfileV2');
  const [setUserTileState] = useSetUserTileStateMutation();
  const [updateNotificationHandled] = useUpdateNotificationHandledMutation();
  const [getNotificationList] = useLazyGetNotificationListQuery();
  const [getUserCuis] = useLazyGetUserCuisQuery();

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [errors, setErrors] = useState(undefined);
  const [userTiles, setUserTiles] = useState<UserTile[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [navEvent, setNavEvent] = useState<any>(null);
  const [isPrefetched, setIsPrefetched] = useState(false);

  useGetAcgUserProfileV2Query(
    source.sourceId === 'acg' && globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  useGetAcgReferenceDataV2Query(
    source.sourceId === 'acg'
      ? {
          ...args,
          referenceData: [
            'areaOfExpertise',
            'marketArea',
            'transactionType',
            'individualType',
            'country',
            'industry',
          ],
        }
      : skipToken,
  );

  useGetAllChaptersAndPackagesV2Query(source.sourceId === 'acg' ? {} : skipToken);

  useEffect(() => {
    if (globalUser && !isPrefetched) {
      prefetchAcgProfile({ ...args, email: globalUser?.email, accountId: globalUser?.accountId });
      setIsPrefetched(true);
    }
  }, [args, globalUser, isPrefetched, prefetchAcgProfile]);

  const activateTileFromProductId = useCallback(
    productId => {
      const triggerTileActivation = (tileToActivate: UserTile) => {
        let currentTileStateComponents = null;
        const currentTileState = userTiles.filter(tile => tile.tileId === tileToActivate.tileId);

        if (currentTileState.length > 0) {
          currentTileStateComponents = tileToActivate?.tileStates?.filter(
            tileState => tileState?.stateName === currentTileState[0].stateCurrent,
          );
        } else {
          currentTileStateComponents = [tileToActivate?.tileStates?.[0]];
        }

        if (currentTileStateComponents?.[0]?.stateName?.toLowerCase() === 'active') {
          setErrors((prevErrors: any) => {
            return {
              ...prevErrors,
              ...{ tileTransitionError: true },
            };
          });
        }

        const activateTileComponent = currentTileStateComponents?.[0]?.tileComponents?.filter(
          component =>
            component?.type === 'CCButton' ||
            component?.type === 'CCClickText' ||
            component?.type === 'TileButton',
        );

        const currentPubEvent = activateTileComponent?.[0]?.pubEvents?.filter(
          pubEvent => pubEvent?.type === 'click',
        );

        const currentActions = currentPubEvent?.[0]?.actions;

        emitter.emit(companyConstants.platformEvents.dataCollectorInitiated, {
          tileId: tileToActivate.tileId,
          productId: tileToActivate.productId,
          product: tileToActivate.product,
          stateCurrent: 'active',
          actions: currentActions,
          type: 'click',
        });
      };

      const getParams = !productId
        ? utils.getQueryStringParams(window.location.search).activate
        : productId;

      if (getParams) {
        const tilesMatchingProductId = getTilesFromProductId(userTiles, getParams);
        const tileMatchingRole = tilesMatchingProductId.filter(tile => {
          if (
            tile &&
            tile.roles &&
            tile.roles.filter(
              role =>
                role ===
                auth0?.user?.['https://company.com/user_authorization']?.roles[0].toLowerCase(),
            ).length > 0
          ) {
            return true;
          }
          return false;
        });
        return triggerTileActivation(tileMatchingRole[0]);
      }

      return undefined;
    },
    [auth0?.user, emitter, userTiles],
  );

  const handleNotification = async (notificationId: string) => {
    const targetProductId = notifications.find(item => item.id === notificationId)?.productId;

    const relatedNotificationIds = notifications.reduce(
      (results, item) => (item.productId === targetProductId ? [...results, item.id] : results),
      [] as string[],
    );

    await Promise.all(
      relatedNotificationIds.map((id: string) =>
        updateNotificationHandled({ notificationId: id, secondsToExpiration: 86400 /* one day */ }),
      ),
    );

    setNotifications(prevNotifications =>
      relatedNotificationIds.reduce(
        (newNotifications: Notification[], id: string) => {
          const targetIndex = newNotifications.findIndex(item => item.id === id);
          return [
            ...newNotifications.slice(0, targetIndex),
            ...newNotifications.slice(targetIndex + 1, newNotifications.length),
          ];
        },
        [...prevNotifications],
      ),
    );
  };

  // DEPRECATED
  const mapUserTileStates = useCallback(
    async ({
      tileId,
      productId,
      stateCurrent,
      statePrevious,
      isHidden,
      seqNo,
      product,
    }: {
      tileId: string;
      productId: string;
      stateCurrent: string;
      statePrevious: string;
      isHidden: boolean;
      seqNo: number;
      product: Product;
    }) => {
      try {
        gtm(`tileStateChangeCall , ${product.slug}, ${stateCurrent}`);
        const tileIdx = userTiles.findIndex(tile => tile.tileId === tileId);

        if (tileIdx > -1) {
          setUserTiles(currentUserTiles => {
            const newUserTiles = currentUserTiles.slice();
            newUserTiles[tileIdx] = {
              ...newUserTiles[tileIdx],
              stateCurrent: 'pending',
            };
            return newUserTiles;
          });
        }

        const input = {
          userId: globalUser?.userId,
          tileId,
          productId,
          productSlug: product.slug,
          stateCurrent: stateCurrent || null,
          statePrevious: statePrevious || null,
          isHidden: isHidden || null,
          seqNo,
          context: JSON.stringify({
            user: globalUser,
            account: globalUser?.account,
            product: {
              ...product,
              productId,
            },
          }),
        };
        const res = await setUserTileState({ input });

        if (res) {
          if (product.slug === 'bizifiFinExchange') {
            setTimeout(() => {
              setUserTiles(currentUserTiles => {
                const newUserTiles = currentUserTiles.slice();
                newUserTiles[tileIdx] = {
                  ...newUserTiles[tileIdx],
                  stateCurrent,
                };
                return newUserTiles;
              });
            }, 2000);
          } else {
            setUserTiles(currentUserTiles => {
              const newUserTiles = currentUserTiles.slice();
              newUserTiles[tileIdx] = {
                ...newUserTiles[tileIdx],
                stateCurrent,
              };
              return newUserTiles;
            });
          }
        }
      } catch (setUserTileStateError) {
        setErrors((prevErrors: any) => {
          return {
            ...prevErrors,
            ...{ setUserTileStateError },
          };
        });
        if (product.slug === 'directory_uberall') {
          emitter.emit(companyConstants.platformEvents.dataCollectorInitiated, {
            tileId,
            productId,
            stateCurrent,
            statePrevious,
            isHidden,
            seqNo,
            product,
          });
        }
      }
    },
    [emitter, globalUser, setUserTileState, userTiles],
  );

  const mapUserTileStatesV2 = useCallback(
    async ({
      tileId,
      productId,
      stateCurrent,
      product,
      payload,
      eventType,
    }: {
      tileId: string;
      productId: string;
      stateCurrent: string;
      product: Product;
      payload: any;
      eventType: string;
    }) => {
      try {
        // update tile to pending
        const tileIdx = userTiles.findIndex(tile => tile.tileId === tileId);
        const tile = userTiles.find(tile => tile.tileId === tileId);

        const previousState = userTiles[tileIdx].stateCurrent;

        setTimeout(() => {
          if (tileIdx > -1) {
            setUserTiles(currentUserTiles => {
              const newUserTiles = currentUserTiles.slice();
              newUserTiles[tileIdx] = {
                ...newUserTiles[tileIdx],
                stateCurrent: 'pending',
              };
              return newUserTiles;
            });
          }
          gtm(`tileStateChangeCall , ${product.slug}, ${stateCurrent}`);
          publishTileEvent(
            platformHelpers.formatPublishTileEventInput(
              {
                eventType,
                componentTypes: null,
                statePrevious: previousState,
                stateCurrent,
              },
              {
                user: globalUser,
                account: globalUser?.account,
                tile,
                product: { ...product, productId },
              },
              payload,
              'transition',
            ),
          );
        }, 3000);
      } catch (setUserTileStateError) {
        setErrors((prevErrors: any) => {
          return {
            ...prevErrors,
            ...{ setUserTileStateError },
          };
        });
      }
    },
    [globalUser, publishTileEvent, userTiles],
  );

  const getUpdatedUserTileData = useCallback(
    async notifData => {
      const optimisticTileUpdate = ({ tileId }: { tileId: string }) => {
        // tile data is actually notif data, need to make sure to not apply values to tile that shouldn't be there or shouldn't have changed.  e.g. error, message, product  = ''
        setUserTiles(currentTiles => {
          const affectedTileIdx = currentTiles.findIndex(tile => tile.tileId === tileId);
          if (affectedTileIdx > -1) {
            const newTiles = currentTiles.slice();
            newTiles[affectedTileIdx] = {
              ...currentTiles[affectedTileIdx],
              stateCurrent: notifData.stateCurrent,
            };
            return newTiles;
          }
          return currentTiles;
        });
      };
      if (notifData) {
        optimisticTileUpdate(notifData);
      }
      try {
        if (globalUser?.userId) {
          const [{ data: newUserTiles }, { data: newUserGroups }] = await Promise.all([
            getUserTiles({ userId: globalUser?.userId, locale: source?.i18nConfig?.lng }),
            getUserGroups({ userId: globalUser?.userId, locale: source?.i18nConfig?.lng }),
          ]);

          setUserTiles(newUserTiles?.filter(tile => !!tile) ?? []);
          setUserGroups(newUserGroups ?? []);
        }
      } catch (updateUserTileDataError) {
        setErrors((prevErrors: any) => {
          return {
            ...prevErrors,
            ...{ updateUserTileDataError },
          };
        });
      }
    },
    [getUserGroups, getUserTiles, source?.i18nConfig?.lng, globalUser?.userId],
  );

  const subNotificationCallback = useCallback(
    async (notification: Notification | Error) => {
      if (notification instanceof Error) {
        return;
      } else {
        setNotifications(prevNotifications => {
          if (
            prevNotifications?.find(
              prevNotification =>
                prevNotification.notificationType === notification.notificationType &&
                // @ts-ignore
                prevNotification?.body?.tileId === notification?.body?.tileId &&
                // @ts-ignore
                prevNotification?.body?.dateCreated === notification?.body?.dateCreated,
            )
          ) {
            return [...prevNotifications];
          }
          return [...prevNotifications, notification];
        });

        if (notification.notificationType === 'tileStateUpdated') {
          const updatedTileId = _get(notification, 'body.tileId');
          const updatedBody = _get(notification, 'body', {}) as {} | Record<string, any>;
          getUpdatedUserTileData({
            tileId: updatedTileId,
            ...updatedBody,
          });
          emitter.emit(companyConstants.tileActions.tileUpdated, {});
        }
      }
    },
    [emitter, getUpdatedUserTileData],
  );

  const subAppointmentCallback = useCallback(
    async (appointment: Appointment) => {
      dispatch(addNewAppointment(appointment));
    },
    [dispatch],
  );

  useSubscribeToNotifications(globalUser?.userId as string, subNotificationCallback);
  useSubscribeToAppointments(
    globalUser?.userId as string,
    source?.sourceId,
    subAppointmentCallback,
  );

  useEffect(() => {
    getUpdatedUserTileData(null);
  }, [getUpdatedUserTileData]);

  useEffect(() => {
    const getCuisAndWriteToState = async () => {
      if (globalUser?.userId) {
        const res = await getUserCuis({ userId: globalUser?.userId }).unwrap();
        dispatchUserCuis({ type: 'INIT', payload: { userCuis: res?.userCUIs || [] } });
      }
    };
    getCuisAndWriteToState();
  }, [dispatchUserCuis, getUserCuis, globalUser?.userId]);

  useEffect(() => {
    emitter.on(companyConstants.tileActions.remoteActivate, (id: any) => {
      activateTileFromProductId(id);
    });

    return () => {
      emitter.off(companyConstants.tileActions.remoteActivate);
    };
  }, [activateTileFromProductId, emitter]);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.tileNextAction, (evt: any) => {
      mapUserTileStates(evt);
    });

    return () => {
      emitter.off(companyConstants.platformEvents.tileNextAction);
    };
  }, [emitter, mapUserTileStates]);

  useEffect(() => {
    emitter.on(companyConstants.tileActions.transitionState, (evt: any) => {
      mapUserTileStates(evt);
    });

    return () => {
      emitter.off(companyConstants.tileActions.transitionState);
    };
  }, [emitter, mapUserTileStates]);

  useEffect(() => {
    emitter.on(companyConstants.tileActions.transitionStateV2, (evt: any) => {
      mapUserTileStatesV2(evt);
    });

    return () => {
      emitter.off(companyConstants.tileActions.transitionStateV2);
    };
  }, [emitter, mapUserTileStatesV2]);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.navEvent, (evt: any) => {
      setNavEvent(handleNavEvent(evt));
    });

    return () => {
      emitter.off(companyConstants.platformEvents.navEvent);
    };
  }, [emitter]);

  const sharedProps = {
    getAndSubNotifications: () => {
      getNotifications(
        getNotificationList,
        globalUser?.userId as string,
        setNotifications,
        setErrors,
      );
    },
  };

  if (errors) {
    console.error('Dashboard.tsx render logic errors: ', errors);
  }

  // const renderCounter = useRef(0);
  // renderCounter.current = renderCounter.current + 1;
  // console.log(`dashboard.tsx HAS RENDERED ${renderCounter.current} TIMES!`);
  return (
    <NotificationsProvider value={notifications}>
      <GroupsProvider value={userGroups}>
        <TilesProvider value={{ tiles: userTiles, getUpdatedUserTileData }}>
          <UserProfileProvider>
            <NotificationsController
              handleClose={handleNotification}
              activateProduct={activateTileFromProductId}
            />
            <DataCollector {...sharedProps} />
            {navEvent && <NavController {...navEvent} user={globalUser} source={source} />}
            <Paywall />
            <QueryPrefetcher />
            <Outlet />
          </UserProfileProvider>
        </TilesProvider>
      </GroupsProvider>
    </NotificationsProvider>
  );
};
