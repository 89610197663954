import {
  SeatCountSummary,
  EmptyObject,
  ExtendedSubscriptionProduct,
  ExtendedSubscriptionTier,
  User,
  Maybe,
} from '@companydotcom/types';

export interface NewUserInformationProps {
  firstName: string;
  lastName: string;
  personalEmail: string;
}

export type ChildProducts = 'email_rackspace' | 'teamup' | 'conferencing';
export interface userProfileStateProps {
  /** An array of eligible child products the parent can activate or is included for their child users */
  eligibleChildProducts?: ExtendedSubscriptionProduct[];
  /** Toggled state of products activated for a child */
  chosenProducts?: { [k in ChildProducts]: boolean } | { [key: string]: boolean } | EmptyObject;
  /** The parent users currently available number of email seats to assign */
  emailSeatCount?: SeatCountSummary | EmptyObject | Partial<SeatCountSummary>;
  /** Information gathered for a new child user */
  newUserInformation?: NewUserInformationProps | EmptyObject;
  /** The new users username for their mailbox email product, if available */
  username?: string;
  /** Toggles the new user flow modal open or closed */
  newUserFlowOpen?: boolean;
  /** The selected tier of a tiered product.  Email only for now. */
  selectedTier?: Partial<ExtendedSubscriptionTier> | EmptyObject;
  /** Sets the initial step of the add new user flow on opening. */
  initialNewUserStep?: string | number;
  /** The step of user profile flow that paywall should navigate to upon successful purchase */
  onPaymentSuccessStep?: string;
  /** The exisiting child user object, if it exists */
  existingChildUser?: User | EmptyObject;
  /** A flag passed to loadingStep.tsx to determine the activation intention for a product */
  productIntention?: {
    intention: 'grant_access' | 'suspend_access';
    product: ExtendedSubscriptionProduct;
  };
  /** The fetched list of child users with partial user info */
  childUsers?: User[];
  /** The filtered list of shown users on the additional users tab */
  filteredUsers?: Maybe<Array<User>>;
  /** An optional function to fire when the Add New User modal closes */
  onModalClose?: () => void;
  /** An optional function thats fired when a seat is successfully managed */
  onManageSeatSuccess?: () => void;
}

export const initialUserProfileState: userProfileStateProps = {
  eligibleChildProducts: [],
  emailSeatCount: {},
  newUserInformation: {},
  username: '',
  chosenProducts: {},
  newUserFlowOpen: false,
  selectedTier: {},
  initialNewUserStep: undefined,
  onPaymentSuccessStep: undefined,
  existingChildUser: {},
  productIntention: undefined,
  onModalClose: undefined,
  onManageSeatSuccess: undefined,
  childUsers: [],
  filteredUsers: [],
};

export type UserProfileActionType =
  | { type: 'UPDATE_STATE'; payload: typeof initialUserProfileState }
  | { type: 'RESET_ALL_USER_PROFILE_STATE' }
  | {
      type: 'TOGGLE_NEW_USER_MODAL';
      payload?: {
        initialNewUserStep?: string | number;
        onPaymentSuccessStep?: string;
        onModalClose?: () => void;
        onManageSeatSuccess?: () => void;
      };
    }
  | {
      type: 'INCREASE_AVAILABLE_SEAT_COUNT';
      payload: { qty: number; options?: 'add-remaining-only' };
    }
  | { type: 'DECREASE_AVAILABLE_SEAT_COUNT'; payload: { qty: number } }
  | { type: 'ADD_FILTERED_USER'; payload: User }
  | { type: 'UPDATE_FILTERED_USER'; payload: User }
  | {
      type: 'UPDATE_PRODUCT_STATUS';
      payload: { productId: string; userId: string; status: 'inactive' | 'active' | 'disabled' };
    };

type AppState = typeof initialUserProfileState;

export const userProfileReducer = (state: AppState, action: UserProfileActionType): AppState => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'TOGGLE_NEW_USER_MODAL':
      //* Toggling modal can also be used to set initial step of the new user flow as well as a handler thats
      //* passed to paywall via the openPaywall evt to handling differing flows between addNewUserFlow and additonalUsers purchasing situations
      return {
        ...state,
        newUserFlowOpen: !state.newUserFlowOpen,
        initialNewUserStep: action.payload?.initialNewUserStep,
        onPaymentSuccessStep: action.payload?.onPaymentSuccessStep,
        onModalClose: action.payload?.onModalClose,
        onManageSeatSuccess: action.payload?.onManageSeatSuccess,
      };
    case 'RESET_ALL_USER_PROFILE_STATE':
      //* Exclude childproducts, email seat count, and filtered users from resetting on state clear
      return {
        ...initialUserProfileState,
        eligibleChildProducts: state.eligibleChildProducts,
        emailSeatCount: state.emailSeatCount,
        filteredUsers: state.filteredUsers,
        childUsers: state.childUsers,
      };
    case 'INCREASE_AVAILABLE_SEAT_COUNT': {
      //* Calculates the new seat count in state upon email seat purchase
      const remainingSeatsToIncrease =
        (state.emailSeatCount?.remainingEntitledSeats || 0) + (action.payload?.qty || 0);
      const entitledSeatsToIncrease =
        (state.emailSeatCount?.totalEntitledSeats || 0) + (action.payload?.qty || 0);

      return {
        ...state,
        emailSeatCount: {
          ...state.emailSeatCount,
          remainingEntitledSeats:
            remainingSeatsToIncrease || state.emailSeatCount?.remainingEntitledSeats,
          totalEntitledSeats:
            action.payload.options === 'add-remaining-only'
              ? state.emailSeatCount?.totalEntitledSeats
              : entitledSeatsToIncrease || state.emailSeatCount?.totalEntitledSeats,
        },
      };
    }

    case 'DECREASE_AVAILABLE_SEAT_COUNT': {
      //* Calculates the new seat count in state upon email assigning email to a child user
      const remainingSeatsToDecrease =
        (state.emailSeatCount?.remainingEntitledSeats || 0) - (action.payload?.qty || 0);

      return {
        ...state,
        emailSeatCount: {
          ...state.emailSeatCount,
          remainingEntitledSeats: remainingSeatsToDecrease,
        },
      };
    }
    case 'ADD_FILTERED_USER':
      return {
        ...state,
        filteredUsers: [...(state.filteredUsers || []), action.payload],
        childUsers: [...(state.childUsers || []), action.payload],
      };
    case 'UPDATE_FILTERED_USER':
      return {
        ...state,
        filteredUsers: [
          action.payload,
          ...(state.filteredUsers?.filter(user => user.email !== action.payload.email) || []),
        ],
        childUsers: [
          action.payload,
          ...(state.childUsers?.filter(user => user.email !== action.payload.email) || []),
        ],
      };
    case 'UPDATE_PRODUCT_STATUS': {
      const userToUpdate: User | EmptyObject =
        state.filteredUsers?.find(u => u.userId === action.payload.userId) || {};
      const parsedProducts = userToUpdate.products ?
        userToUpdate.products && JSON.parse(userToUpdate.products) : [];

      const updatedUserProduct = {
        ...userToUpdate,
        userId: userToUpdate.userId || '',
        products: JSON.stringify([
          ...parsedProducts.map((prod: any) =>
            prod.productId === action.payload.productId
              ? { ...prod, status: action.payload.status }
              : prod,
          ),
        ]),
      } as User;

      return {
        ...state,
        filteredUsers: [
          updatedUserProduct,
          ...(state.filteredUsers?.filter(user => user.userId !== action.payload.userId) || []),
        ],
      };
    }

    default:
      throw new Error('Need action type');
  }
};
