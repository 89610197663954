import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Appointment, User, Account } from '@companydotcom/types';
import type { RootState } from '../../store';

export interface UserState {
  globalUser: User;
}

export const initialState: UserState = { globalUser: {} as User };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.globalUser = action.payload;
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      state.globalUser = { ...state.globalUser, ...action.payload };
    },
    updateUserAccount: (state, action: PayloadAction<Partial<Account>>) => {
      state.globalUser.account = {
        accountId: state.globalUser.account?.accountId as string,
        ...state.globalUser.account,
        ...action.payload,
      };
    },
    addNewAppointment: (state, action: PayloadAction<Appointment>) => {
      const prevAppts = state.globalUser?.appointments || [];

      if (!prevAppts?.find?.(appt => appt?.appointmentId === action.payload?.appointmentId)) {
        state.globalUser = { ...state.globalUser, appointments: [...prevAppts, action.payload] };
      }
    },
  },
});

export const { setUser, updateUser, addNewAppointment, updateUserAccount } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.globalUser;

export default userSlice.reducer;
