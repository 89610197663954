import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EventForPurchase,
  CartForInvoice,
  CreateCartResponse,
  Coupon,
  // Chapter,
  // ChapterPackage,
  // Membership,
  // DoPaymentResponse,
} from '../../../services/acg/acg.types';
import type { RootState } from '../../../store';

// TODO: retype this
export interface ACGCheckoutState {
  selectedChapter?: any;
  selectedPackage?: SelectedPackage;
  orderConfirmationData?: any;
  renewInvoice?: CartForInvoice[];
  eventForPurchase?: any;
  discountCodeStrings?: string[];
  eventDiscount: Coupon[];
  membershipDiscount: Coupon[];
}

export type SelectedPackage = CreateCartResponse | CartForInvoice;

export const initialState: ACGCheckoutState = {
  selectedChapter: undefined,
  selectedPackage: undefined,
  orderConfirmationData: undefined,
  renewInvoice: undefined,
  eventForPurchase: undefined,
  eventDiscount: [],
  membershipDiscount: [],
  discountCodeStrings: [],
};

export const acgCheckoutSlice = createSlice({
  name: 'acgCheckout',
  initialState,
  reducers: {
    setSelectedChapter: (state: ACGCheckoutState, action: PayloadAction<any>) => {
      state.selectedChapter = action.payload;
    },
    setSelectedPackage: (state: ACGCheckoutState, action: PayloadAction<any>) => {
      state.selectedPackage = action.payload;
    },
    // TODO: RETYPE THIS
    setOrderConfirmationData: (state: ACGCheckoutState, action: PayloadAction<any>) => {
      state.orderConfirmationData = action.payload;
    },
    setRenewInvoice: (state: ACGCheckoutState, action: PayloadAction<CartForInvoice[]>) => {
      state.renewInvoice = action.payload;
    },
    setEventForPurchase: (state: ACGCheckoutState, action: PayloadAction<EventForPurchase>) => {
      state.eventForPurchase = action.payload;
    },
    setEventDiscount: (state: ACGCheckoutState, action: PayloadAction<Coupon[]>) => {
      if (!action.payload) {
        state.eventDiscount = initialState.eventDiscount;
      } else {
        state.eventDiscount = action.payload;
      }
    },
    setMembershipDiscount: (state: ACGCheckoutState, action: PayloadAction<Coupon[]>) => {
      if (!action.payload) {
        state.membershipDiscount = initialState.membershipDiscount;
      } else {
        state.membershipDiscount = action.payload;
      }
    },
    setDiscountCodeString: (state: ACGCheckoutState, action: PayloadAction<string>) => {
      if (!action.payload.length) {
        state.discountCodeStrings = initialState.discountCodeStrings;
      } else {
        state.discountCodeStrings?.push(action.payload);
      }
    },
  },
});

export const {
  setSelectedChapter,
  setSelectedPackage,
  setOrderConfirmationData,
  setRenewInvoice,
  setEventForPurchase,
  setEventDiscount,
  setMembershipDiscount,
  setDiscountCodeString,
} = acgCheckoutSlice.actions;

export const getSelectedChapter = (state: RootState) => state.acgCheckout.selectedChapter;
export const getRenewInvoice = (state: RootState) => state.acgCheckout.renewInvoice;
export const getSelectedPackage = (state: RootState) => state.acgCheckout.selectedPackage;
export const getOrderConfirmationData = (state: RootState) =>
  state.acgCheckout.orderConfirmationData;
export const getEventForPurchase = (state: RootState) => state.acgCheckout.eventForPurchase;
export const getEventDiscount = (state: RootState) => state.acgCheckout.eventDiscount;
export const getMembershipDiscount = (state: RootState) => state.acgCheckout.membershipDiscount;
export const getDiscountCodes = (state: RootState) => state.acgCheckout.discountCodeStrings;

export default acgCheckoutSlice.reducer;
