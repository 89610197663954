import React from 'react';
import { SubpageLayout, SubpageHeader, SubpageFooter, ConditionalWrapper } from '@companydotcom/ui';
import { Outlet, useLocation } from 'react-router-dom';
import { useAssets, useSource } from '@companydotcom/providers';
import { Flex } from '@companydotcom/potion';
import {
  FacecakeLocationProvider,
  useGetFacecakeBackgroundImages,
} from '../../../features/facecake/layouts/components/facecake-background-imager';

export interface OneColumnLayoutProps {
  hideFooter?: boolean;
}

export const OneColumnLayout: React.FC<OneColumnLayoutProps> = ({ hideFooter }) => {
  const [facecakeStep, setFacecakeStep] = React.useState('');
  const assets = useAssets();
  const source = useSource();
  const location = useLocation();

  const faceCakeBackgroundImageData = useGetFacecakeBackgroundImages(
    source?.sourceId,
    location.pathname,
    facecakeStep,
  );

  return (
    <ConditionalWrapper
      condition={source?.sourceId === 'icommerce'}
      wrapper={children => (
        <FacecakeLocationProvider value={{ facecakeStep, setFacecakeStep }}>
          {children}
        </FacecakeLocationProvider>
      )}
    >
      <SubpageLayout {...faceCakeBackgroundImageData}>
        <SubpageHeader logo={assets?.logo} />
        <Flex as="main" className="one-column-layout" px={4} my={8} flexGrow={1}>
          <Outlet />
        </Flex>
        {!hideFooter && (
          <SubpageFooter footerLogo={assets?.footer_logo} footerLinks={source?.footerLinks} />
        )}
      </SubpageLayout>
    </ConditionalWrapper>
  );
};
