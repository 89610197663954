import React from 'react';
import {
  Center,
  Heading,
  Text,
  Link,
  Icon,
  Stack,
  Flex,
  useBreakpointValue,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-thin-svg-icons';
import { useTranslation, Trans } from 'react-i18next';

export const LeadGenSuccess: React.FC<any> = () => {
  const { t } = useTranslation();
  const headingSize = useBreakpointValue({ base: 'hs-md', sm: 'hs-lg' });

  return (
    <Center
      className="facecake-lead-gen__success"
      flexDirection="column"
      maxW="704px"
      backgroundColor={['transparent', 'white']}
      py={[0, 12]}
      px={12}
      layerStyle={{ base: undefined, sm: 'formContainer' }}
    >
      <Icon as={FontAwesomeIcon} icon={faCheckCircle} boxSize="96px" color="success" />
      <Flex flexDirection="column" align="center" mt={6}>
        <Stack spacing={10} textAlign="center" fontWeight="normal" maxW="608px">
          <Heading size={headingSize} fontWeight="normal">
            {t('icommerce.unqualifiedLead.successScreenParagraphOne')}
          </Heading>
          <Heading size={headingSize} fontWeight="normal" textAlign="center">
            <Trans i18nKey="icommerce.unqualifiedLead.successScreenParagraphTwo">
              <Text />
              <Link
                href={`mailto:${t('icommerce.supportPage.contactEmail')}`}
                whiteSpace="nowrap"
              />
            </Trans>
          </Heading>
          <Heading size={headingSize} fontWeight="normal">
            {t('icommerce.unqualifiedLead.closeWindow')}
          </Heading>
        </Stack>
        <Text mt={12} fontSize="12px">
          <Trans i18nKey="icommerce.unqualifiedLead.haveQuestions">
            <Text as="span" />
            <Link href={`mailto:${t('icommerce.supportPage.contactEmail')}`} />
          </Trans>
        </Text>
      </Flex>
    </Center>
  );
};
