import React from 'react';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  TableProps,
  isEmpty,
  Radio,
} from '@companydotcom/potion';
import { EventFees, RegistrantAndFeePackage } from '../../../../services/acg/acg.types';
import { formatCheckoutPrice } from '../utils';
import { UseFormSetValue } from 'react-hook-form';
import _orderBy from 'lodash/orderBy';

export interface SelectedFeesListProps extends TableProps {
  name: string;
  register: any;
  hasOneFee: boolean;
  data?: Partial<RegistrantAndFeePackage>[];
  purchased?: boolean;
  passedEvent?: boolean;
  setValue?: UseFormSetValue<any>;
  fees?: (string | EventFees | undefined)[];
  selectedPackage?: string;
  isAddGuestReg?: boolean;
}

export const SelectedFeesList = (props: SelectedFeesListProps) => {
  const {
    data,
    name = 'fees',
    register,
    hasOneFee,
    purchased,
    passedEvent,
    fees,
    setValue,
    selectedPackage,
    isAddGuestReg,
    ...rest
  } = props;

  // logic to sort sessionsFees by sessionStartDate asc
  let dataSorted: Partial<RegistrantAndFeePackage>[];
  if (data && Array.isArray(data) && data.length > 0) {
    dataSorted = [...(data as Partial<RegistrantAndFeePackage>[])];
    dataSorted = [...dataSorted].map(obj => {
      return {
        sessions: _orderBy(
          obj?.sessions,
          [
            item => {
              return new Date(item?.sessionStartDate);
            },
          ],
          ['asc'],
        ),
        eventFees: obj?.eventFees,
      };
    });
  } else {
    dataSorted = [];
  }

  function hasSessions(fee: Partial<RegistrantAndFeePackage>) {
    return !isEmpty(fee.sessions);
  }

  const checkHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue?.(
      name as 'fees',
      fees?.includes(value)
        ? fees?.filter(cur => cur !== value)
        : [...(fees ?? []), e.target.value],
    );
  };

  return (
    <Table {...rest}>
      <Thead>
        <Tr>
          <Th pl={0}>
            <Text textStyle="xl" fontWeight="bold">
              {purchased ? (passedEvent ? 'ATTENDED ' : 'ATTENDING') : 'SELECT ITEMS'}
            </Text>
          </Th>
          <Th pr={0} isNumeric>
            <Text textStyle="xl" fontWeight="bold">
              PRICE
            </Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {dataSorted?.map((item, i) => (
          <React.Fragment key={i}>
            <Tr>
              <Td
                pl={0}
                pt={8}
                pb={hasSessions(item) ? 6 : 8}
                borderBottomWidth={
                  (dataSorted.length - 1 === i && purchased) || hasSessions(item) ? '0px' : '1px'
                }
                sx={{
                  '.potion-radio__control': {
                    // Need to remove the radio, but keep the label, when one fee is selected
                    display: hasOneFee ? 'none' : 'inline-flex',
                  },
                }}
              >
                {!isAddGuestReg ? (
                  <Radio
                    size="lg"
                    value={item?.eventFees?.priceKey}
                    isReadOnly={hasOneFee}
                    pointerEvents={hasOneFee ? 'none' : 'inherit'}
                    isChecked={selectedPackage === item?.eventFees?.priceKey}
                    {...register('selectedPackage')}
                    sx={{
                      cursor: hasOneFee ? 'inherit' : 'pointer',
                    }}
                  >
                    {item?.eventFees?.productName}
                  </Radio>
                ) : (
                  <Radio
                    size="lg"
                    value={item?.eventFees?.priceKey}
                    isReadOnly={hasOneFee}
                    pointerEvents={hasOneFee ? 'none' : 'inherit'}
                    isChecked={fees?.includes(item?.eventFees?.priceKey)}
                    {...register('fees.0')}
                    sx={{
                      cursor: hasOneFee ? 'inherit' : 'pointer',
                    }}
                  >
                    {item?.eventFees?.productName}
                  </Radio>
                )}
              </Td>
              <Td
                borderBottomWidth={
                  (dataSorted.length - 1 === i && purchased) || hasSessions(item) ? '0px' : '1px'
                }
                px={0}
                pt={8}
                pb={hasSessions(item) ? 6 : 8}
                isNumeric
              >
                <Text textStyle="lg" fontWeight="bold">
                  {`${item?.eventFees?.currencySymbol || '$'}${formatCheckoutPrice(
                    item?.eventFees?.price,
                  )}`}
                </Text>
              </Td>
            </Tr>
            {/* <VStack w="full" spacing="6" justify="space-between"> */}
            {item?.sessions &&
              item?.sessions?.map(session =>
                // Empty session frees show as free in the UI, still checkable
                isEmpty(session?.sessionFees) ? (
                  <Tr key={session.sessionId}>
                    <Td borderBottomWidth="0" pt="0" pl="6" pb="6" pr="0">
                      <Checkbox
                        {...register(name)}
                        isDisabled={selectedPackage !== item?.eventFees?.priceKey}
                        isChecked={fees?.includes(session?.sessionId)}
                        onChange={checkHandler}
                        key={session?.sessionId}
                        size="lg"
                        value={session?.sessionId}
                      >
                        {session?.sessionName}
                      </Checkbox>
                    </Td>
                    <Td isNumeric borderBottomWidth="0" pt="0" pl="6" pb="6" pr="0">
                      <Text textStyle="lg" fontWeight="bold">
                        {`${item.eventFees?.currencySymbol}0.00`}
                      </Text>
                    </Td>
                  </Tr>
                ) : (
                  session.sessionFees?.map(sessionFee => (
                    <Tr key={sessionFee.productName}>
                      <Td borderBottomWidth="0" pt="0" pl="6" pb="6" pr="0">
                        <Checkbox
                          {...register(name)}
                          isDisabled={selectedPackage !== item?.eventFees?.priceKey}
                          isChecked={fees?.includes(sessionFee.sessionFeeId)}
                          onChange={checkHandler}
                          size="lg"
                          value={sessionFee.sessionFeeId}
                        >
                          {sessionFee.productName}
                        </Checkbox>
                      </Td>
                      <Td isNumeric borderBottomWidth="0" pt="0" pl="6" pb="6" pr="0">
                        <Text textStyle="lg" fontWeight="bold">
                          {`${sessionFee?.currencySymbol || '$'}${formatCheckoutPrice(
                            sessionFee?.price || 0,
                          )}`}
                        </Text>
                      </Td>
                    </Tr>
                  ))
                ),
              )}
            {/* </VStack> */}
          </React.Fragment>
        ))}
      </Tbody>
    </Table>
  );
};
