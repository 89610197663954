import React from 'react';
import { ButtonGroup } from '@companydotcom/potion';
import { TileButton } from './tile-button';
import type { TileComponentProps } from '../../types';

export interface TileButtonGroupProps extends TileComponentProps {}

export const TileButtonGroup: React.FC<TileButtonGroupProps> = props => {
  const { children } = props;

  // @ts-ignore
  const _children = children || children?.children;

  return (
    <ButtonGroup size="md">
      {_children.map((button: any, idx: number) => {
        const sharedProps = { ...props, ...button };
        return (
          <TileButton
            key={idx}
            events={button?.events}
            id={button?.gtmId}
            variant={button?.variant ?? 'solid'}
            {...sharedProps}
          >
            {button?.text}
          </TileButton>
        );
      })}
    </ButtonGroup>
  );
};
