import React, { useEffect } from 'react';
import valid from 'card-validator';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Stack,
  Text,
  useToast,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { User } from '@companydotcom/types';
import { faCheckCircle, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { PageHeading } from '../../../../components/elements';
import {
  CreditCardForm,
  ACHForm,
  getCheckoutOrderSchema,
} from '../../../../components/forms/checkout';
import { SavedPaymentTile } from '../components/saved-payment-payment-tile';
import {
  useAddOrUpdateSavedPaymentsMutation,
  useDeleteStoredPaymentMethodMutation,
  useSetAutoRenewOnMembershipMutation,
} from '../../../../services/acg/acg-api';
import { MaxSavedPaymentTypes } from '../components/saved-payment-max-methods';
import { getSavePaymentPayload } from '../utils';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getSavedPaymentMethods, removePaymentMethod } from '../saved-payments-slice';
import { maskCreditCardNumber } from '../../acg-checkout';

export interface AddPaymentMethodProps {
  globalUser?: User;
  paymentData?: any;
  showForm?: boolean;
  setShowForm?: any;
  isLoading?: boolean;
  isMobile?: boolean;
  BluePay?: any;
  autoRenewMethod?: string;
}

export const AddSavedPaymentMethod: React.FC<AddPaymentMethodProps> = props => {
  const {
    paymentData,
    globalUser,
    isLoading,
    showForm,
    setShowForm,
    isMobile,
    BluePay,
    autoRenewMethod,
  } = props;

  const toast = useToast();
  const dispatch = useAppDispatch();
  const savedPaymentMethods = useAppSelector(getSavedPaymentMethods);
  const [addMethod, { isLoading: addMethodLoading }] = useAddOrUpdateSavedPaymentsMutation();
  const [deleteMethod, { isLoading: isDeletingMethod }] = useDeleteStoredPaymentMethodMutation();
  const [removeAutoRenew, { isLoading: autoRenewLoading }] = useSetAutoRenewOnMembershipMutation();
  const { t } = useTranslation();

  /* PAYMENT FORM STATE AND HELPERS */
  // TODO: uncomment when rhythm supports ACH
  // const paymentMethodOptions = [
  //   { label: t('acg.checkout.orderStep.creditCard'), value: 'credit card' },
  //   { label: t('acg.checkout.orderStep.ach'), value: 'ach' },
  // ];

  const defaultValues = {
    recordKey: '',
    paymentMethod: 'credit card',
    makeDefault: false,
    savePaymentInformation: true,
    cardNickname: '',
    cardDetails: {
      cardHolderName: '',
      cardNumber: '',
      expirationMonth: '',
      expirationYear: '',
      cardCvv: '',
    },
    achDetails: {
      accountHolderName: '',
      accountNumber: '',
      accountType: '',
      routingNumber: '',
    },
    billingDetails: {
      country: 'United States',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
    },
  };

  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getCheckoutOrderSchema(true)),
    defaultValues: defaultValues ?? {},
  });

  const selectedPaymentMethod = useWatch({ name: 'paymentMethod', control });
  const selectedCreditCard = useWatch({ name: 'cardDetails.cardNumber', control });
  const card = valid.number(selectedCreditCard);
  const selectedYear = useWatch({ name: 'cardDetails.expirationYear', control });
  const selectedMonth = useWatch({ name: 'cardDetails.expirationMonth', control });

  useEffect(() => {
    if (selectedYear || selectedMonth) {
      trigger(['cardDetails.expirationMonth', 'cardDetails.expirationYear']);
    }
  }, [selectedMonth, selectedYear, trigger]);

  /* HANDLERS */
  const onSubmit = async (values: typeof defaultValues) => {
    try {
      reset(defaultValues);
      const savePaymentPayload = getSavePaymentPayload(values);

      /** BLUEPAY LOGIC -----START----- */
      const tokenData =
        values?.paymentMethod === 'ach'
          ? {
              PAYMENT_TYPE: 'ACH',
              ACH_ACCOUNT: values?.achDetails?.accountNumber,
              ACH_ROUTING: values?.achDetails?.routingNumber,
              ACH_ACCOUNT_TYPE: values?.achDetails?.accountType,
              NAME1: values?.achDetails?.accountHolderName,
            }
          : {
              CARD_ACCOUNT: values?.cardDetails?.cardNumber,
              CARD_CVV2: values?.cardDetails?.cardCvv,
              EXPMO: values?.cardDetails?.expirationMonth,
              EXPYR: values?.cardDetails?.expirationYear,
            };

      await BluePay?.createToken(tokenData, async (res: any) => {
        if (res.MESSAGE === 'INFORMATION STORED') {
          const tokens = {
            merchantAccountId: 'ACG-PRIMARY',
            merchantToken: res.TRANS_ID,
          };

          const response = await addMethod({
            user: globalUser as User,
            payload: {
              paymentInfo: {
                ...savePaymentPayload,
                ...tokens,
              },
            },
          }).unwrap();

          if (response) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            mobileView('off');
          } else {
            mobileView('off');
            throw new Error();
          }
        } else {
          throw new Error('could not encrypt payment');
        }
      });
      /** BLUEPAY LOGIC -----END----- */
    } catch (err) {
      console.log('Error submitting saved payment', err);
      Sentry.captureException(err, scope => {
        scope.setTransactionName('BluepaySavedPaymentAddNew');
        scope.setTag('api', 'bluepay');
        scope.setContext('Payment', {
          payment_method: card?.card?.type,
          card_number: maskCreditCardNumber(values?.cardDetails?.cardNumber),
        });

        return scope;
      });
    }
  };

  const onDelete = async (arg: any) => {
    try {
      reset();
      if (arg?.paymentMethodType) {
        await removeAutoRenew({
          user: globalUser as User,
          payload: {
            autoRenew: false,
            paymentInfo: {
              paymentMethodType: arg?.paymentMethodType,
              cardType: arg?.cardType,
              cardNumber: arg?.cardNumber,
              accountNumber: arg?.accountNumber,
              ...arg?.merchantAccountTokens,
            },
          },
        });
      }
      const res = await deleteMethod({
        user: globalUser as User,
        payload: {
          recordId: arg?.recordKey,
        },
      }).unwrap();
      if (paymentData?.recordCount === 5 && !isMobile) {
        setShowForm?.on();
      }
      dispatch(removePaymentMethod(arg?.recordKey ?? ''));

      if (res) {
        arg?.onClose?.();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        toast({
          render: () => (
            <Flex flexDirection="row" color="white" p={3} bg="green.500" borderRadius="6px">
              <Icon
                p={1}
                pr={2}
                className="fa-solid"
                as={FontAwesomeIcon}
                icon={faCheckCircle}
                boxSize="16px"
              />
              <Flex flexDirection="column" pr={2}>
                <Text fontWeight={700}>Payment method successfully deleted.</Text>
              </Flex>
            </Flex>
          ),
          duration: 5000,
          status: 'success',
          isClosable: true,
          position: isMobile ? 'top' : 'top-right',
        });
      } else {
        mobileView('off');
        throw new Error();
      }
    } catch (err) {
      console.log('Error deleting payment method', err);
    }
  };

  const mobileView = (arg: 'off' | 'on') => {
    if (arg === 'off' && isMobile) {
      setShowForm.off();
    }
  };

  return (
    <Center
      className="auto-renew"
      flexDirection="column"
      bg={['transparent', 'white']}
      py={[0, 8, 14]}
      px={[0, 4]}
    >
      <Box width="full" maxW={908}>
        <PageHeading heading="Saved Payment Methods" />
        {showForm && (
          <Heading size="hs-xl" fontWeight="semibold" mt={12} textAlign={['center', 'start']}>
            {t('acg.savedPaymentMethods.form.addNewMethod')}
          </Heading>
        )}
        <Grid gridTemplateColumns={['1fr', null, '1fr 292px']} gap={[6, 8]} mb={[4, 8]} mt={6}>
          {paymentData?.recordCount === 5 && !showForm && (
            <GridItem>
              <MaxSavedPaymentTypes
                isMobile={isMobile}
                isLoading={isDeletingMethod || autoRenewLoading}
              />
            </GridItem>
          )}
          {showForm && (
            <GridItem as="form" maxW="xl">
              {/* TODO uncomment when rhythm supports ACH */}
              {/* <Stack direction={['column', 'column', 'column', 'column', 'row']} spacing={8}>
                <Box>
                  <SelectField
                    register={register}
                    name="paymentMethod"
                    label={t('acg.savedPaymentMethods.form.paymentMethod')}
                    formControlStyles={{ width: ['100%', 276] }}
                  >
                    {paymentMethodOptions.map(opt => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </SelectField>
                  {selectedPaymentMethod === 'credit card' && (
                    <HStack maxH={6} mt={2}>
                      <VisaIcon boxSize={9} />
                      <MastercardIcon boxSize={9} />
                      <AmexIcon boxSize={9} />
                    </HStack>
                  )} 
                </Box>
              </Stack> */}
              {selectedPaymentMethod === 'credit card' ? (
                <CreditCardForm
                  displayBillingAddressForm
                  isCheckout={false}
                  watch={watch}
                  control={control}
                  setValue={setValue}
                  setError={setError}
                  selectedCreditCard={card}
                  register={register}
                  errors={errors}
                  isValid={isValid}
                  addNewType
                />
              ) : (
                <ACHForm
                  displayBillingAddressForm
                  isCheckout={false}
                  control={control}
                  watch={watch}
                  register={register}
                  errors={errors}
                  addNewType
                />
              )}
            </GridItem>
          )}
          {((isMobile && !showForm) || !isMobile) && (
            <GridItem maxW={['100%', 292]} width="full" borderRadius="md">
              {savedPaymentMethods?.map((method: any, i: any) => {
                return (
                  <SavedPaymentTile
                    {...{
                      ...method,
                      isLoading,
                      onDelete,
                      isDeletingMethod: autoRenewLoading || isDeletingMethod,
                      autoRenewMethod,
                    }}
                    key={i}
                  />
                );
              })}
            </GridItem>
          )}
        </Grid>
        <Divider mb={5} />
        <Stack
          justify="space-between"
          align={isMobile ? 'center' : 'flex-end'}
          pt={[4, 8]}
          spacing={[4, null, null, 0]}
        >
          {paymentData?.recordCount !== 5 && !showForm && (
            <Button
              size="sm"
              variant="link"
              onClick={() => {
                setShowForm.on();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              fontWeight="semibold"
            >
              {t('acg.autoRenew.addPaymentMethodButton')}
            </Button>
          )}
          {showForm && (
            <Button
              width={['full', 'xs']}
              isLoading={
                addMethodLoading ||
                isSubmitting ||
                isLoading ||
                isDeletingMethod ||
                autoRenewLoading
              }
              isDisabled={!isValid}
              size="lg"
              onClick={handleSubmit(onSubmit)}
            >
              {t('common.buttons.save')}
            </Button>
          )}
        </Stack>
      </Box>
    </Center>
  );
};
