import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Heading,
  Center,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { DarkWebStepProps } from '../types';
import { useAppSelector } from '../../../hooks';
import { selectDarkWebScanResults } from '../dark-web-scan-slice';
import { DarkScanResult } from '../../../services/dark-web-scan/dark-web-scan.types';
import { LeakAccordion } from '../components/dark-web-accordion';

export const DarkWebDetailedResults: React.FC<DarkWebStepProps> = ({ detailedTag }) => {
  const currentDarkWebResults = useAppSelector(selectDarkWebScanResults);
  const [leaks, setLeaks] = useState<DarkScanResult[]>();
  const tag = detailedTag;
  const { t } = useTranslation();

  useEffect(() => {
    const filteredLeaks = currentDarkWebResults?.filter((leak: DarkScanResult) => leak.count > 0);
    setLeaks(filteredLeaks);
  }, [currentDarkWebResults]);

  return (
    <Center
      className="dws-fullResults"
      flexDirection="column"
      pt={[12, null, 10]}
      pb={[12, null, 16]}
      width="full"
      px={4}
    >
      <Box textAlign="center" maxWidth={732} mb={12}>
        <Heading as="h1" size="hs-xl">
          {t('containers.darkWebScan.detailedResults.header')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          {tag?.['text']}
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('containers.darkWebScan.detailedResults.subheader')}
        </Text>
      </Box>
      <Flex flexDirection="column" width="full" maxWidth={944} className="Results">
        <TableContainer>
          <Table
            variant="simple"
            borderRadius="md"
            borderColor="gray.200"
            borderWidth="1px"
            borderStyle="solid"
          >
            <Thead backgroundColor="gray.100">
              <Tr>
                <Th>{t('containers.darkWebScan.detailedResults.date')}</Th>
                <Th>{t('containers.darkWebScan.detailedResults.fileName')}</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {leaks?.map?.((leak: DarkScanResult, index) => (
                <Tr>
                  <LeakAccordion key={index} leak={leak} />
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <Flex flexDirection={['column', null, 'row']} mt={14}>
        <Button size="lg" as={NavLink} to="/">
          {t('common.buttons.toDashboard')}
        </Button>
      </Flex>
    </Center>
  );
};
