import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Box, Text, useMultiStyleConfig } from '@companydotcom/potion';
export const Stepper = props => {
    const { steps, activeStep } = props;
    const styles = useMultiStyleConfig('Stepper', props);
    const stepperSteps = steps.filter(s => s === null || s === void 0 ? void 0 : s.label);
    if (!Array.isArray(steps) || !steps.length) {
        console.warn('🚨 ~ Stepper ~ steps is not an array or is empty');
    }
    return (_jsx(Flex, Object.assign({ className: "stepper", flexDirection: "row", alignItems: "flex-end", justifyContent: "center", py: [12], sx: styles.container }, { children: stepperSteps &&
            (stepperSteps === null || stepperSteps === void 0 ? void 0 : stepperSteps.map(({ label, slug }, i) => {
                var _a, _b, _c, _d, _e;
                return (_jsxs(Box, Object.assign({ pr: [1], width: ['144px'] }, { children: [_jsx(Text, Object.assign({ fontSize: "sm", fontWeight: "medium", textAlign: "center", 
                            // @ts-ignore
                            color: i <= activeStep
                                ? (_b = (_a = styles.activeStep) === null || _a === void 0 ? void 0 : _a['color']) !== null && _b !== void 0 ? _b : 'inherit'
                                : ((_c = styles.inactiveStep) === null || _c === void 0 ? void 0 : _c['color'])
                                    ? (_d = styles.inactiveStep) === null || _d === void 0 ? void 0 : _d['color']
                                    : (_e = styles.label) !== null && _e !== void 0 ? _e : 'inherit' }, { children: label }), void 0), _jsx(Box, { className: "stepper-step", sx: i <= activeStep ? styles.activeStep : styles.inactiveStep, height: "10px", borderTopLeftRadius: i === 0 ? 'md' : 'none', borderBottomLeftRadius: i === 0 ? 'md' : 'none', borderBottomRightRadius: i === (stepperSteps === null || stepperSteps === void 0 ? void 0 : stepperSteps.length) - 1 ? 'md' : 'none', borderTopRightRadius: i === (stepperSteps === null || stepperSteps === void 0 ? void 0 : stepperSteps.length) - 1 ? 'md' : 'none' }, void 0)] }), slug));
            })) }), void 0));
};
