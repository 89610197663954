import React from 'react';
import {
  Text,
  Select,
  Button,
  Flex,
  List,
  ListItem,
  CloseButton,
  FormLabel,
  FormControl,
  VStack,
  StackDivider,
  Skeleton,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { isEmpty } from 'lodash';
import { AnimatePresence } from 'framer-motion';
import { MotionFlex } from '../../motion';

export interface AdditiveListProps {
  children: React.ReactNode;
  fields: any[];
  addHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  removeHandler: (index: number, field?: any) => void;
  showOptions: boolean;
  label: string;
  setShowOptions: (showOptions: boolean) => void;
  isLoading?: boolean;
}

export const AdditiveList = (props: AdditiveListProps) => {
  const {
    children,
    fields = [],
    removeHandler,
    addHandler,
    setShowOptions,
    showOptions = true,
    label = '',
    isLoading,
  } = props;

  return (
    <Flex
      align="flex-start"
      flexDirection="column"
      sx={{
        '.additive-list::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          width: '12px',
        },
        '.additive-list::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
          borderWidth: '1px',
          borderColor: '#C4C4C4',
          borderRadius: '0 4px 4px 0',
        },
        '.additive-list::-webkit-scrollbar-thumb': {
          backgroundColor: '#C4C4C4',
          borderRadius: '8px',
        },
        '.additive-list::-webkit-scrollbar-button': {
          display: 'none',
        },
      }}
    >
      {!isEmpty(fields) && (
        <List
          className="additive-list"
          maxHeight={236}
          overflowY="auto"
          width="full"
          borderRadius="xl"
          borderWidth="1px"
          borderColor="gray.300"
        >
          <AnimatePresence initial={false}>
            <VStack width="full" spacing={0} divider={<StackDivider borderColor="gray.300" />}>
              {fields.map((field, index) => (
                <ListItem width="full" key={field.id}>
                  <MotionFlex
                    px={4}
                    py={3}
                    backgroundColor="yellow"
                    initial={{ backgroundColor: '#FBEFC9' }}
                    animate={{ backgroundColor: '#fff', transition: { duration: 4 } }}
                    justify="space-between"
                  >
                    <Text textStyle="md" fontWeight="medium">
                      {field?.label || field?.name}
                    </Text>
                    <CloseButton
                      isDisabled={isLoading}
                      size="sm"
                      onClick={() => removeHandler(index, field)}
                    />
                  </MotionFlex>
                </ListItem>
              ))}
            </VStack>
          </AnimatePresence>
        </List>
      )}

      {showOptions ? (
        <FormControl mt={fields.length >= 1 ? 4 : 0}>
          <FormLabel>{label}</FormLabel>
          <Skeleton isLoaded={!isLoading}>
            <Select onChange={addHandler} defaultValue="">
              <option value=""> </option>
              {children}
            </Select>
          </Skeleton>
        </FormControl>
      ) : (
        <Button
          onClick={() => setShowOptions(true)}
          mt={4}
          size="sm"
          variant="outline"
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          isLoading={isLoading}
        >
          {label}
        </Button>
      )}
    </Flex>
  );
};
