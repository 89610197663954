import { PaymentForm, RatePlan, CreatePaymentMethodInput } from '@companydotcom/types';
import {
  SET_NEW_ZUORA_DEFAULT_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
} from './graphql/payment-mutation';
import {
  GET_ACCOUNT_PAYMENT_DETAILS,
  GET_PAYMENT_FORM,
  GET_PAYMENT_METHOD_DATA_BY_ID,
  GET_PRODUCT_RATE_PLAN,
  GET_ZUORA_INVOICE_PDF,
  GET_ZUORA_INVOICE_SUMMARY,
  GET_ALL_PAYMENT_SOURCE_BY_CUSTOMER,
  GET_CUSTOMER_CB_INVOICES,
  GET_CB_INVOICE_BY_ID,
  GET_CB_INVOICE_PDF_BY_ID,
} from './graphql/payment-query';
import { emptySplitApi } from '../../store/empty-split-api';

export const PaymentApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getAccountPaymentDetails: builder.query<any, { zuoraAccountId: string }>({
      query: ({ zuoraAccountId }) => ({
        method: 'query',
        document: GET_ACCOUNT_PAYMENT_DETAILS,
        variables: {
          zuoraAccountId,
        },
      }),
      transformResponse: (rawResult: { data: { getPaymentDetails: any } }) =>
        JSON.parse(rawResult.data.getPaymentDetails),
    }),
    getPaymentMethodDataById: builder.query<any, { zuoraPaymentMethodId: string }>({
      query: ({ zuoraPaymentMethodId }) => ({
        method: 'query',
        document: GET_PAYMENT_METHOD_DATA_BY_ID,
        variables: {
          zuoraPaymentMethodId,
        },
      }),
      transformResponse: (rawResult: { data: { getPaymentMethodDataById: any } }) =>
        rawResult.data.getPaymentMethodDataById,
    }),
    getZuoraInvoiceSummary: builder.query<any, { zuoraAccountId: string }>({
      query: ({ zuoraAccountId }) => ({
        method: 'query',
        document: GET_ZUORA_INVOICE_SUMMARY,
        variables: {
          zuoraAccountId,
        },
      }),
      transformResponse: (rawResult: { data: { getZuoraInvoiceSummary: any } }) =>
        rawResult.data.getZuoraInvoiceSummary,
    }),
    getZuoraInvoicePdf: builder.query<any, { zuoraInvoiceId: string }>({
      query: ({ zuoraInvoiceId }) => ({
        method: 'query',
        document: GET_ZUORA_INVOICE_PDF,
        variables: {
          zuoraInvoiceId,
        },
      }),
      transformResponse: async (rawResult: { data: { getZuoraInvoicePdf: any } }) => {
        try {
          // res should be a base64 string, if it is parsable, an error was returned
          // usually 400 because files are not yet available
          JSON.parse(rawResult.data.getZuoraInvoicePdf);
        } catch (err) {
          // actually a good thing, parse failed
          return rawResult.data.getZuoraInvoicePdf;
        }
        return rawResult.data.getZuoraInvoicePdf;
      },
    }),
    setNewZuoraDefaultPaymentMethod: builder.mutation<
      any,
      { zuoraAccountId: string; zuoraPaymentMethodId: string }
    >({
      query: ({ zuoraAccountId, zuoraPaymentMethodId }) => ({
        method: 'mutation',
        document: SET_NEW_ZUORA_DEFAULT_PAYMENT_METHOD,
        variables: {
          body: {
            zuoraAccountId,
            zuoraPaymentMethodId,
          },
        },
      }),
      transformResponse: (rawResult: { data: { setNewZuoraDefaultPaymentMethod: any } }) =>
        rawResult.data.setNewZuoraDefaultPaymentMethod,
    }),
    getPaymentForm: builder.query<PaymentForm, { sourceId: string; isLocal: boolean }>({
      query: ({ sourceId, isLocal = false }) => ({
        method: 'query',
        document: GET_PAYMENT_FORM,
        variables: {
          sourceId,
          isLocal,
        },
      }),
      transformResponse: (rawResult: { data: { getPaymentForm: PaymentForm } }) =>
        rawResult.data.getPaymentForm,
    }),
    getProductRatePlan: builder.query<
      RatePlan,
      { accountId: string; locale?: string; productId: string }
    >({
      query: ({ accountId, locale = 'en', productId }) => ({
        method: 'query',
        document: GET_PRODUCT_RATE_PLAN,
        variables: {
          accountId,
          locale,
          productId,
        },
      }),
      transformResponse: (rawResult: { data: { getProductRatePlan: RatePlan } }) =>
        rawResult.data.getProductRatePlan,
    }),
    createPaymentMethod: builder.mutation<any, CreatePaymentMethodInput>({
      query: options => ({
        method: 'mutation',
        document: CREATE_PAYMENT_METHOD,
        variables: {
          input: options,
        },
      }),
      transformResponse: (rawResult: { data: { createPaymentMethod: any } }) =>
        JSON.parse(rawResult.data.createPaymentMethod),
    }),
    getInvoiceListByCustomer: builder.query<any, { customerId: any }>({
      query: ({ customerId }) => ({
        method: 'query',
        document: GET_CUSTOMER_CB_INVOICES,
        variables: {
          customerId,
        },
      }),
      transformResponse: (rawResult: { data: { getInvoiceListByCustomer: any } }) =>
        rawResult.data.getInvoiceListByCustomer,
    }),
    getInvoiceByInvoiceId: builder.query<any, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        method: 'query',
        document: GET_CB_INVOICE_BY_ID,
        variables: {
          invoiceId,
        },
      }),
      transformResponse: (rawResult: { data: { getInvoiceByInvoiceId: any } }) =>
        rawResult.data.getInvoiceByInvoiceId,
    }), // GET_CB_INVOICE_PDF_BY_ID
    getInvoicePdfByInvoiceId: builder.query<any, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        method: 'query',
        document: GET_CB_INVOICE_PDF_BY_ID,
        variables: {
          invoiceId,
        },
      }),
      transformResponse: (rawResult: { data: { getInvoicePdfByInvoiceId: any } }) =>
        rawResult.data.getInvoicePdfByInvoiceId,
    }),
    getAllPaymentSourceByCustomer: builder.query<any, { customerId: string }>({
      query: ({ customerId }) => ({
        method: 'query',
        document: GET_ALL_PAYMENT_SOURCE_BY_CUSTOMER,
        variables: {
          customerId,
        },
      }),
      transformResponse: (rawResult: { data: { getAllPaymentSourceByCustomer: any } }) =>
        rawResult.data.getAllPaymentSourceByCustomer,
    }),
  }),
});

export const {
  useLazyGetPaymentFormQuery,
  useLazyGetProductRatePlanQuery,
  useLazyGetAccountPaymentDetailsQuery,
  useLazyGetPaymentMethodDataByIdQuery,
  useSetNewZuoraDefaultPaymentMethodMutation,
  useCreatePaymentMethodMutation,
  useLazyGetZuoraInvoiceSummaryQuery,
  useGetZuoraInvoiceSummaryQuery,
  useGetInvoiceListByCustomerQuery,
  useLazyGetZuoraInvoicePdfQuery,
  useLazyGetAllPaymentSourceByCustomerQuery,
  useLazyGetInvoiceListByCustomerQuery,
  useLazyGetInvoiceByInvoiceIdQuery,
  useLazyGetInvoicePdfByInvoiceIdQuery,
} = PaymentApi;
