import * as yup from 'yup';
import { isValidPhoneNumber, CountryCode } from 'libphonenumber-js';

declare module 'yup' {
  export interface StringSchema {
    /**
     * Check for phone number validity.
     */
    phone(countryCode?: string, errorMessage?: string): StringSchema;
  }
}

yup.addMethod(
  yup.string,
  'phone',
  function yupPhone(countryCode?: CountryCode, errorMessage: string = '') {
    return this.test(
      'phone',
      errorMessage || 'common.inputs.phoneNumber.validation',
      (value: string | undefined, { options: { context } }) => {
        try {
          if (!countryCode && !context?.['country']) {
            console.warn(
              'No country found to properly validate the phone schema.  Did you pass context into react-hook-form?',
            );
          }
          return isValidPhoneNumber(value || '', (countryCode || context?.['country']) ?? 'US');
        } catch {
          return false;
        }
      },
    );
  },
);

export default yup;
