import React, { useEffect } from 'react';
import { Button, Flex, Text } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { AcgMembership, Maybe } from '@companydotcom/types';
import { useTranslation } from 'react-i18next';

export interface TileAcgMembershipManageAutoRenewStepProps extends AcgMembership {
  handlePrefetchingSavedPayments?: any;
  chapterName: Maybe<string>;
}

export const TileAcgMembershipManageAutoRenewStep: React.FC<
  TileAcgMembershipManageAutoRenewStepProps
> = ({ chapterName, expireDate, joinDate, renewDate, handlePrefetchingSavedPayments }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const autoRenewProps = {
    chapterName,
    expireDate,
    joinDate,
  };

  useEffect(() => {
    handlePrefetchingSavedPayments();
  }, [handlePrefetchingSavedPayments]);

  return (
    <Flex
      flexDirection="column"
      width="full"
      flexGrow={1}
      px={4}
      mt={2}
      justifyContent="space-between"
    >
      <Text>{t(`acg.membershipStatus.manageAutoRenew.body`)}</Text>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Text fontWeight="700" mb={2} textAlign="center">
            Chapter(s): {chapterName}
          </Text>
          <Text color="success" fontWeight="700" mb={4} textAlign="center">
            Membership Will Auto-Renew: {renewDate}
          </Text>
        </Flex>

        <Button
          variant="tile"
          onClick={() => navigate('/auto-renew', { state: autoRenewProps })}
          size="md"
          type="submit"
        >
          {t(`acg.membershipStatus.manageAutoRenew.cta`)}
        </Button>
      </Flex>
    </Flex>
  );
};
