import { companyQL } from '@companydotcom/services';
import { withCache, clearCache } from '@companydotcom/helpers';
import {
  ProcessOrderInput,
  UserTile,
  User,
  SeatCountSummary,
  ChildUserInput,
  UpdateUserInput,
  Appointment,
} from '@companydotcom/types';
import { companyQLSecure } from '../../secure-app-sync-client';
import userQuery from './user-query-old';
import userMutation from './user-mutation-old';
import userSubscription from './user-subscriptions-old';
import { FetchEvent } from '../../../utils/skynet';
import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';

const isEnabled = process.env.REACT_APP_CACHE_ENABLED === 'true' || false;

/**
 * @deprecated
 */
export const getUserTiles = async (userId: string, locale: string = 'en') => {
  try {
    const res: { data: { getUserTiles: UserTile[] } } = await companyQLSecure().query({
      query: userQuery.getUserTiles(userId, locale),
    });
    return res.data.getUserTiles;
  } catch (err) {
    console.log('getUserTiles Error: ', err);
    return [];
  }
};
/**
 * @deprecated
 */
export const getUserGroups = async (userId: any, locale: string = 'en') => {
  try {
    const res = await companyQLSecure().query({
      query: userQuery.getUserGroups(userId, locale),
    });
    return res.data.getUserGroups;
  } catch (err) {
    console.log('getUser Error: ', err);
    return [];
  }
};
/**
 * @deprecated
 */
export const getUserLite = withCache<User>(
  async (userId: string, locale: string = 'en') => {
    try {
      const res = await companyQLSecure().query({
        query: userQuery.getUserLite(userId, locale),
      });
      return res.data.getUser;
    } catch (err) {
      console.log('getUser Error: ', err);
    }
  },
  'userLite',
  undefined,
  isEnabled,
);
/**
 * @deprecated
 */
export const setUserTileState = async (tileEvent: any) => {
  try {
    const res = await companyQLSecure().mutate({
      mutation: userMutation.setUserTileState,
      variables: { input: tileEvent },
    });
    clearCache('user', tileEvent.userId);
    return res;
  } catch (err) {
    console.log('setUserTileState Error: ', err);
  }
};
/**
 * @deprecated
 */
export const setUserTileStateLite = async (tileEvent: any) => {
  try {
    if (tileEvent.statePrevious && tileEvent.stateCurrent !== tileEvent.statePrevious) {
      throw new Error('Use full setUserTileState for state changes');
    }
    const res = await companyQLSecure().mutate({
      mutation: userMutation.setUserTileStateLite,
      variables: { input: tileEvent },
    });
    return res;
  } catch (err) {
    return err;
    console.log('setUserTileStateLite Error: ', err);
  }
};
/**
 * @deprecated
 */
export const getUserTileStates = async (userId: any) => {
  try {
    const res = await companyQLSecure().query({
      query: userQuery.getUserTileStates(userId),
    });
    return res.data.getUserTileStates;
  } catch (err) {
    console.log('getUserTileStates Error: ', err);
  }
};
/**
 * @deprecated
 */
export const setUserTileGroup = async (input: any) => {
  try {
    const res = await companyQLSecure().mutate({
      mutation: userMutation.setUserTileGroup,
      variables: { input },
    });
    clearCache('user', input.userId);
    return res;
  } catch (err) {
    console.log('setUserTileGroup Error: ', err);
  }
};
/**
 * @deprecated
 */
export const getUserTileGroups = async (userId: any) => {
  try {
    const res = await companyQLSecure().query({
      query: userQuery.getUserTileGroups(userId),
    });
    return res;
  } catch (err) {
    console.log('getUserTileGroups Error: ', err);
  }
};
/**
 * @deprecated
 */
export const getUserByEmail = async (email: string) => {
  const res = await companyQLSecure().query({
    query: userQuery.getUserByEmail(email),
  });
  return res.data.getUserByEmail;
};

/**
 * @deprecated
 */
export const updateUserAccountDomain = async (options: any) => {
  const res = await companyQLSecure().mutate({
    mutation: userMutation.updateUserAccountDomain(options),
  });
  clearCache('user', options.userId);
  return res;
};

/**
 * @deprecated
 */
export const updateAccountBusiness = async (options: any) => {
  const res = await companyQLSecure().mutate({
    mutation: userMutation.updateAccountBusiness,
    variables: {
      input: {
        accountId: options.accountId,
        business: [
          {
            businessId: options.businessId,
            physicalLocation: options.physicalLocation,
            address: {
              state: options.state,
              city: options.city,
              zipCode: options.zipCode,
              addressLine1: options.address,
              addressLine2: options.address2,
            },
            name: options.businessName,
            numberOfEmployees: options.numberOfEmployees,
            website: options.website,
            ...(options.categories ? { categories: options.categories } : {}),
          },
        ],
      },
    },
  });
  clearCache('user', options.userId);
  return res.data.updateAccount;
};
/**
 * @deprecated
 */
export const updateAccountBusinessNoCityState = async (options: any) => {
  const res = await companyQLSecure().mutate({
    mutation: userMutation.updateAccountBusinessNoCityState,
    variables: {
      input: {
        accountId: options.accountId,
        business: [
          {
            businessId: options.businessId,
            address: {
              city: options.city,
              state: options.state,
              zipCode: options.zipCode,
              addressLine1: options.address,
              addressLine2: options.address2,
            },
            name: options.businessName,
            ...(options.categories ? { categories: options.categories } : {}),
          },
        ],
      },
    },
  });
  clearCache('user', options.userId);
  return res.data.updateAccount;
};

/**
 * @deprecated
 */
export const updateUserProfile = async (options: UpdateUserInput) => {
  if (options.phone && options.firstName && options.lastName) {
    options.phone = options.phone.replace(/([-() ])/g, '');
    options.firstName = options.firstName.replace(/\\/g, '').replace(/"/g, '\\"');
    options.lastName = options.lastName.replace(/\\/g, '').replace(/"/g, '\\"');
  }
  const res = await companyQLSecure().mutate({
    mutation: userMutation.updateUserProfile,
    variables: { input: options },
  });
  clearCache('user', options.userId);
  return res;
};

/**
 * @deprecated
 */
export const updateChildUserProfile = async (options: any) => {
  const phone = options.phone.replace(/([-() ])/g, '');
  const firstName = options.firstName.replace(/\\/g, '').replace(/"/g, '\\"');
  const lastName = options.lastName.replace(/\\/g, '').replace(/"/g, '\\"');
  const status = options.status && options.status === 'pending' ? 'active' : options.status;
  const res = await companyQLSecure().mutate({
    mutation: userMutation.updateChildUserProfile({
      ...options,
      phone,
      status,
      firstName,
      lastName,
    }),
  });
  clearCache('user', options.userId);
  return res;
};

/**
 * @deprecated
 */
export const updateMailboxPassword = async (globalUser: User, options: any) => {
  try {
    const res = await FetchEvent(
      'updateMailbox',
      globalUser,
      {
        productId: 'f5327182-4604-11e8-842f-0ed5f89f718b',
        tileId: '190e526f-b197-448a-bf14-2e60a332099e',
        stateCurrent: 'active',
      }, //TODO - replace productId with getProductIdBySlug helper function
      options,
    );
    clearCache('user', globalUser.userId);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};
/**
 * @deprecated
 */
export const updateMailboxUsername = async (globalUser: User, options: any) => {
  try {
    const res = await FetchEvent(
      'updateMailbox',
      globalUser,
      {
        productId: 'f5327182-4604-11e8-842f-0ed5f89f718b',
        tileId: '190e526f-b197-448a-bf14-2e60a332099e',
        stateCurrent: 'active',
      }, //TODO - replace productId with getProductIdBySlug helper function
      options,
    );
    await updateUserMailboxField(globalUser.userId, options.mailbox);

    clearCache('user', globalUser.userId);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};
/**
 * @deprecated
 */
export const updateUserMailboxField = async (userId: string, mailbox: any) => {
  try {
    const res = await companyQLSecure().mutate({
      mutation: userMutation.updateUserMailbox(userId, mailbox),
    });
    clearCache('user', userId);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Update User Product
// export const updateUserProduct = async () => { };
/**
 * @deprecated
 */
export const processOrderSummary = async (options: ProcessOrderInput) => {
  try {
    const res = await companyQLSecure().mutate({
      mutation: userMutation.processOrderSummary,
      variables: { input: options },
    });
    clearCache('user', options.userId);
    res.data.processOrder.payload = JSON.parse(res.data.processOrder.payload);
    return res.data.processOrder;
  } catch (err) {
    console.log('processOrderSummary Error: ', err);
  }
};
/**
 * @deprecated
 */
export const getProductSSO = async (options: any) => {
  const res = await companyQLSecure().query({
    query: userQuery.getProductSSO(options),
  });
  return res.data.getProductSSO;
};

/**
 * @deprecated
 * MOVED TO UTILS
 */
// export const getPurchaseUrl = async (productParams: any, source: Source, purchaseRelUrl: any) => {
//   const redirectTo = companyHelpers.routeBuilder(source, companyConstants.hosts.account);
//   const overridePurchaseRelUrl = purchaseRelUrl ? purchaseRelUrl.value : null;
//   return `${redirectTo}${overridePurchaseRelUrl || productParams.product.purchaseRelUrl}`;
// };

/**
 * @deprecated
 */
export const getDomain = async (fqdn: any) => {
  const res = await companyQLSecure().query({ query: userQuery.getDomain(fqdn) });
  const result = JSON.parse(res.data.getDomain).resultObj;
  return result;
};

/**
 * @deprecated
 * Gets the available seats for a product
 * @param accountId - A users account id
 * @param productId - The product id
 * @returns
 */
export const getSeatCount = async (accountId: string, productId: string) => {
  const res: {
    data: {
      getSeatCount: SeatCountSummary;
    };
  } = await companyQLSecure().query({
    query: userQuery.getSeatCount(accountId, productId),
  });
  return res.data.getSeatCount;
};
/**
 * @deprecated
 */
export const createDomain = async (options: any) => {
  const res = await companyQLSecure().mutate({
    mutation: userMutation.createDomain(options),
  });
  return res.data.createDomain;
};
/**
 * @deprecated
 */
export const updateDomain = async (options: any) => {
  const res = await companyQLSecure().mutate({
    mutation: options.isRequiredAlias
      ? userMutation.updateAlias(options)
      : userMutation.updateMX(options),
  });
  return res.data.updateDomain;
};

/**
 * @deprecated
 */
export const updateLoanData = async (input: any) => {
  const {
    applicationId,
    yearsInBusiness,
    industry,
    creditEstimate,
    purpose,
    annualRevenue,
    amountRequired,
    status,
    yearFounded,
    monthFounded,
  } = input.loans[0];
  const res = await companyQLSecure().mutate({
    mutation: userMutation.updateLoan(),
    variables: {
      input: {
        accountId: input.accountId,
        loans: [
          {
            applicationId,
            yearsInBusiness,
            industry,
            creditEstimate,
            purpose,
            annualRevenue,
            amountRequired,
            status,
            yearFounded,
            monthFounded: monthFounded ? Math.round(monthFounded) : 0,
          },
        ],
        loanPrimary: {
          applicationId,
          yearsInBusiness,
          industry,
          creditEstimate,
          purpose,
          annualRevenue,
          amountRequired,
          status,
          yearFounded,
          monthFounded: monthFounded ? Math.round(monthFounded) : 0,
        },
      },
    },
  });
  return res.data.updateAccount;
};
/**
 * @deprecated
 */
export const fetchMidList = async (email: string, source: string) => {
  const res = await companyQLSecure().query({
    query: userQuery.fetchMids(email, source),
  });
  return res.data.getMidList;
};
/**
 * @deprecated
 */
export const activateUser = async (userEmail: any, ownerEmail: any, enrolmentId: any) => {
  const res = await companyQL.mutate({
    mutation: userMutation.activateUser(userEmail, ownerEmail, enrolmentId),
  });
  return res.data.enrollUser;
};
/**
 * @deprecated
 */
export const getEnrolledUser = async (userId: string) => {
  const res = await companyQLSecure().query({
    query: userQuery.getEnrolledUser(userId),
  });
  return res.data.getUser;
};

/**
 * @deprecated
 */
export const uploadAvatar = async (dataUri: string, userId: string) => {
  const res = await companyQLSecure().mutate({
    mutation: userMutation.upsertAvatar(dataUri, userId),
  });
  return res.data.upsertAvatar;
};

/**
 * @deprecated
 */
export const deleteAvatar = async (userId: string) => {
  const res = await companyQLSecure().mutate({
    mutation: userMutation.deleteAvatar(userId),
  });
  return res.data.deleteAvatar;
};

export const useSubscribeToAppointments = (
  userId: string,
  sourceId: string,
  callback?: (appointment: Appointment) => void,
) => {
  useEffect(() => {
    if (userId && sourceId !== 'acg') {
      const sub = API.graphql<GraphQLSubscription<{ onCreateAppointment: Appointment }>>(
        graphqlOperation(
          `subscription onCreateUserAppointment {
            onCreateUserAppointment(userId:"${userId}") {
                appointmentId
                userId
                productId
                dateCreated
                dateUpdated
                dateExpires
                appointmentTime
                fulfilled
                url
                metadata
              }
          }`,
          { userId },
        ),
      ).subscribe({
        next: async (eventData: any) => {
          const appointment = eventData.data.onCreateUserAppointment;
          if (typeof appointment.body === 'string') {
            appointment.body = JSON.parse(decodeURIComponent(appointment.body));
          }
          callback?.(appointment);
        },
        error: (err: any) => {
          console.error(err);
        },
      });

      return () => {
        sub.unsubscribe();
      };
    }
  }, [callback, sourceId, userId]);
};

/**
 * @deprecated
 */
export const subscribeToAppointments = async (userId: string, callback: any) => {
  return companyQL
    .subscribe({
      query: userSubscription.subscribeToAppointments(userId),
    })
    .subscribe({
      next: async (eventData: any) => {
        const appointment = eventData.data.onCreateUserAppointment;
        if (typeof appointment.body === 'string') {
          appointment.body = JSON.parse(decodeURIComponent(appointment.body));
        }
        callback(appointment);
      },
      error: (err: any) => {
        console.error(err);
      },
    });
};
// export const changeMid = async (externalId, enrollmentId, oldEnrollmentId) => {
//   return await axios.put(`https://platformapi.company-corp.com/enroll/v2/enrollmentId/${enrollmentId}/externalId/${externalId}`, {
//     oldEnrollmentId: "123lasd-2123-sdsd-123122"
//   });
// }
/**
 * @deprecated
 */
export const changeEnrollmentIdForUser = async (
  externalId: string,
  enrollmentId: string,
  oldEnrollmentId?: string,
) => {
  const res = await companyQLSecure().mutate({
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
    mutation: userMutation.changeEnrollmentIdForUser(externalId, enrollmentId, oldEnrollmentId),
  });
  return res.data.changeEnrollmentIdForUser;
};
/**
 * @deprecated
 */
export const updateZuoraPaymentMethodId = async (
  accountId: string,
  zuoraPaymentMethodId: string,
) => {
  const res = await companyQLSecure().mutate({
    mutation: userMutation.updateLoan(), // update loan really uses a generic update account
    variables: {
      input: {
        accountId,
        zuoraPaymentMethodId,
      },
    },
  });
  return res.data.updateAccount;
};

/**
 * @deprecated
 */
export const updateUserPassword = async (userId: any, password: any) => {
  const res = await companyQLSecure().mutate({
    mutation: userMutation.updateUserPassword(userId, password),
  });
  return res;
};
/**
 * @deprecated
 */
export const deleteMailbox = async (globalUser: User, mailbox: string) => {
  try {
    const res = await FetchEvent(
      'deleteMailbox',
      globalUser,
      {
        productId: 'f5327182-4604-11e8-842f-0ed5f89f718b',
        tileId: 'f5327182-4604-11e8-842f-0ed5f89f718b',
        stateCurrent: 'active',
      }, //TODO - replace productId with getProductIdBySlug helper function
      { mailbox },
    );
    clearCache('user', globalUser.userId);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};
/**
 * @deprecated
 */
export const createCalendlyAppointment = async (userId: string, productId: string, url: string) => {
  try {
    const res = await companyQLSecure().mutate({
      mutation: userMutation.createCalendlyAppointment,
      variables: { userId, productId, url },
    });
    return JSON.parse(res.data.createCalendlyAppointment);
  } catch (err) {
    console.log('createCalendlyAppointment Error: ', err);
  }
};

/**
 * @deprecated
 * Creates a child email account with parent as the admin
 * @param childUser - The child user oject
 * @param childUser.email - User email
 * @param childUser.userEmail - User email
 * @param childUser.externalIdentifier - User email
 * @param childUser.firstName - User email
 * @param childUser.lastName - User email
 * @param childUser.parentAccountId - User email
 * @param childUser.role - User role.  Either 'user' or 'admin'
 * @param source - The current source
 * @returns
 */
export const createChildUser = async (
  childUser: ChildUserInput,
  sourceId: string,
): Promise<{ processId: string; status: string; enrollmentId: string }> => {
  const res = await companyQL.mutate({
    mutation: userMutation.createChildUser(childUser, sourceId),
  });
  return JSON.parse(res.data.createChildEmailUser);
};
/**
 * @deprecated
 */
export const getUserByEmailAndSource = async (email: string, sourceId: string) => {
  const res = await companyQLSecure().query({
    query: userQuery.getUserByEmailAndSource(email, sourceId),
  });
  return res.data.getUserByEmailAndSource;
};
