import React from 'react';
import { Heading, HStack, Icon, Button, VStack, Stack, useMediaQuery } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faShoppingCart,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { PageDivider } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { useAcgCartStorage } from '../hooks/use-acg-cart-storage';

export const ACGTopRail = () => {
  const { getLocalCart } = useAcgCartStorage();
  const cart = getLocalCart();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 40em)');

  return (
    <VStack width="full" align={['center', null, 'flex-start']} rowGap="48px">
      <Stack
        width="100%"
        direction={['column', null, 'row']}
        justify="space-between"
        align="center"
      >
        <VStack align={['center', null, 'flex-start']} spacing={4}>
          <Heading size="hs-2xl" fontWeight="medium">
            My ACG
          </Heading>
          <PageDivider width={50} />
        </VStack>
        <HStack align="flex-end" spacing={8}>
          <Button
            variant="link"
            as={NavLink}
            to="/acg-shopping-cart"
            leftIcon={
              <Icon
                as={FontAwesomeIcon}
                icon={faShoppingCart}
                color="secondary.500"
                width={['4', '6']}
                height={['4', '6']}
                sx={{ position: 'relative' }}
              />
            }
          >
            {cart?.items?.event ||
            (cart?.items?.memberships && cart?.items?.memberships?.length > 0) ? (
              <Icon
                as={FontAwesomeIcon}
                icon={faCircle}
                color="success"
                sx={{ position: 'absolute', width: '8px', right: '10px', top: '-5px' }}
              />
            ) : (
              ''
            )}
          </Button>
          <Button
            variant="link"
            size="sm"
            onClick={() => window.open(`${process.env.REACT_APP_ACG_BASE_URL || ''}/events`)}
            fontWeight="semibold"
            leftIcon={
              <Icon
                as={FontAwesomeIcon}
                icon={faSearch}
                color="secondary.500"
                height={['4', '6']}
                width={['4', '6']}
              />
            }
          >
            Search Events{' '}
          </Button>
          <Button
            variant="link"
            size="sm"
            as={NavLink}
            to="/member-directory"
            fontWeight="semibold"
            leftIcon={
              <Icon
                as={FontAwesomeIcon}
                icon={faSearch}
                color="secondary.500"
                height={['4', '6']}
                width={['4', '6']}
              />
            }
          >
            {t(`acg.memberSearch.search.searchNavText`)}
          </Button>
          {!isMobile ? (
            <Button
              variant="link"
              size="sm"
              onClick={() => window.open('https://www.acg.org/')}
              fontWeight="semibold"
              leftIcon={
                <Icon
                  as={FontAwesomeIcon}
                  icon={faArrowUpRightFromSquare}
                  color="secondary.500"
                  height={['4', '6']}
                  width={['4', '6']}
                />
              }
            >
              Go to ACG.org
            </Button>
          ) : null}
        </HStack>
      </Stack>
    </VStack>
  );
};
