import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  CheckboxGroup,
  Checkbox,
  TableProps,
} from '@companydotcom/potion';
import { InvoiceDetails } from '../../../../services/acg/acg.types';
import { formatCheckoutPrice } from '../../shared';

export interface SelectedItemsListProps extends TableProps {
  data: InvoiceDetails;
  name?: string;
  register?: any;
  hasOneFee?: boolean;
  purchased?: boolean;
  passedEvent?: boolean;
}

export const MyEventsSelectedItemsList = (props: SelectedItemsListProps) => {
  const { data, name, register, hasOneFee, purchased, passedEvent, ...rest } = props;

  return (
    <Table className="selected-items-list" {...rest}>
      <Thead>
        <Tr>
          <Th pl={0}>
            <Text textStyle="xl" fontWeight="bold">
              {purchased ? (passedEvent ? 'ATTENDED ' : 'ATTENDING') : 'SELECT ITEMS'}
            </Text>
          </Th>
          <Th pr={0} isNumeric>
            <Text textStyle="xl" fontWeight="bold">
              PRICE
            </Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        <CheckboxGroup>
          {data && (
            <Tr>
              <Td
                pl={0}
                py={8}
                // borderBottomWidth={data.length - 1 === i && purchased ? '0px' : '1px'}
                sx={{
                  '.potion-checkbox__control': {
                    // Need to remove the checkbox, but keep the label, when one fee is selected
                    display: hasOneFee ? 'none' : 'inline-flex',
                  },
                }}
              >
                {purchased ? (
                  <Text>{data?.productName}</Text>
                ) : (
                  <Checkbox
                    size="lg"
                    value={data.productName}
                    isReadOnly={hasOneFee}
                    {...register(name)}
                  >
                    {data.productName}
                  </Checkbox>
                )}
              </Td>
              <Td
                px={0}
                py={8}
                isNumeric
                // borderBottomWidth={data.length - 1 === i && purchased ? '0px' : '1px'}
              >
                <Text textStyle="lg" fontWeight="bold">
                  {`$${formatCheckoutPrice(JSON.stringify(data.price))}`}
                </Text>
              </Td>
            </Tr>
          )}
        </CheckboxGroup>
      </Tbody>
    </Table>
  );
};
