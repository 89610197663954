import type { SystemStyleObject } from '@companydotcom/potion';

const parts = [
  'container',
  'header',
  'body',
  'footer',
  'closeButton',
  'logo',
  'blob',
  'groupHeader',
  'navLink',
  'copyWrapper',
  'copyTitle',
  'copyLink',
];

type Dict = Record<string, any>;

function baseStyleContainer(props: Dict) {
  const { isSideNavOpen } = props;

  return {
    bg: 'blue.800',
    boxShadow: 'lg',
    width: isSideNavOpen ? ['272px', '344px'] : ['100%', null, '80px'],
    overflowY: 'auto',
    overflowX: 'hidden',
  } as SystemStyleObject;
}

function baseStyleHeader(props: Dict) {
  const { isSideNavOpen } = props;
  return {
    zIndex: 1,
    minHeight: isSideNavOpen ? ['112px', null, '230px'] : '190px',
  } as SystemStyleObject;
}

function baseStyleBody(props: Dict) {
  const { isSideNavOpen } = props;
  return {
    zIndex: 2,
    marginTop: isSideNavOpen ? [6, null, 14] : 0,
    flex: 1,
    '.sidenav__navGroup + .sidenav__navGroup': {
      mt: isSideNavOpen ? [8] : 0,
    },
  } as SystemStyleObject;
}

const baseStyleFooter: SystemStyleObject = {};

const baseStyleCloseButton: SystemStyleObject = {
  borderRadius: 'md',
  borderTopRightRadius: 'none',
  borderBottomRightRadius: 'none',
  bg: 'blackAlpha.100',
  color: 'white',
  zIndex: 2,
  _hover: {
    bg: 'blackAlpha.200',
  },
  _active: {
    bg: 'blackAlpha.300',
  },
};

const baseStyleLogo: SystemStyleObject = {
  maxWidth: '200px',
  maxHeight: '64px',
  zIndex: 2,
  mt: 6,
};

function baseStyleBlob(props: Dict) {
  const { isSideNavOpen } = props;

  return {
    width: '352px',
    height: isSideNavOpen ? ['112px', null, '230px'] : '200px',
    borderRadius: '0% 0% 90% 90% / 100% 100% 55% 55%',
    backgroundColor: 'white',
    boxShadow: '0 0 10px rgb(0 0 0 / 30%)',
  };
}

function baseStyleGroupHeader(props: Dict) {
  const { isSideNavOpen } = props;

  return {
    fontSize: 'md',
    color: 'white',
    textTransform: 'uppercase',
    mb: isSideNavOpen ? 3 : 0,
    ml: isSideNavOpen ? [6, 7] : 0,
  } as SystemStyleObject;
}

function baseStyleNavLink(props: Dict) {
  const { isSideNavOpen } = props;
  return {
    color: 'white',
    width: '100%',
    pl: isSideNavOpen ? 7 : 4,
    fontWeight: 'medium',
    _hover: {
      backgroundColor: 'blackAlpha.300',
    },
    _active: {
      backgroundColor: 'blackAlpha.300',
    },
  } as SystemStyleObject;
}

const baseCopyWrapper = {
  alignItems: 'flex-start',
  textAlign: 'left',
  color: 'white',
  fontSize: '14px',
  mt: 16,
  spacing: 8,
};

const baseCopyTitle: SystemStyleObject = {
  color: 'whiteAlpha.500',
};

const baseCopyLink: SystemStyleObject = {
  color: 'white',
};

const baseStyle = (props: Dict) => ({
  header: baseStyleHeader(props),
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(props),
  container: baseStyleContainer(props),
  footer: baseStyleFooter,
  logo: baseStyleLogo,
  blob: baseStyleBlob(props),
  groupHeader: baseStyleGroupHeader(props),
  navLink: baseStyleNavLink(props),
  copyWrapper: baseCopyWrapper,
  copyTitle: baseCopyTitle,
  copyLink: baseCopyLink,
});

export default {
  parts,
  baseStyle,
};
