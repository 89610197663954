import gql from 'graphql-tag';

export const CREATE_AND_SEND_EMAIL_CODE = gql`
  mutation CreateAndSendEmailCode($userId: String!, $email: String) {
    createAndSendEmailCode(userId: $userId, email: $email)
  }
`;

export const UPDATE_VERIFY_EMAIL_CODE = gql`
  mutation UpdateVerifyEmailCode($userId: String!, $email: String) {
    updateVerifyEmailCode(userId: $userId, email: $email)
  }
`;

export const LINK_ACCOUNTS = gql`
  mutation LinkAccounts($input: LinkAccountInput!) {
    linkAccounts(input: $input)
  }
`;

export const RESEND_EMAIL_ACTIVATION = gql`
  mutation ResendEmailActivation($email: String!) {
    resendEmailActivation(email: $email)
  }
`;
