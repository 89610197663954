const authQuery = {
  getAuthUser: (userId: string) => `query getAuthUser {
    getAuthUser(userId: "${userId}")
  }`,
  getWelcomeEmail: (email: string, input: any) => `query getWelcomeEmail {
    getWelcomeEmail(email: "${email}" , input: {
         source: "${input.source}", userId: "${input.userId}", role:"${input.role}"
    }
    )
  }`,
  resendEmailActivation: (email: string) => `mutation resendEmailActivation{ resendEmailActivation(
    email:"${email}",
 )
}`,
};

export default authQuery;
