import * as React from 'react';
import {
  potion,
  forwardRef,
  HTMLPotionProps,
  omitThemingProps,
  ThemingProps,
  useStyleConfig,
  useStyles,
} from '../../system';
import { cx, __DEV__ } from '../../utils';
import { useFormControlContext } from './form-control';

export interface FormLabelProps extends HTMLPotionProps<'label'>, ThemingProps<'FormLabel'> {
  /**
   * @type React.ReactElement
   */
  requiredIndicator?: React.ReactElement;
}

/**
 * Used to enhance the usability of form controls.
 *
 * It is used to inform users as to what information
 * is requested for a form field.
 *
 * ♿️ Accessibility: Every form field should have a form label.
 */
export const FormLabel = forwardRef<FormLabelProps, 'label'>((passedProps, ref) => {
  const styles = useStyleConfig('FormLabel', passedProps);
  const props = omitThemingProps(passedProps);

  const { className, children, requiredIndicator = <RequiredIndicator />, ...rest } = props;

  const field = useFormControlContext();
  const ownProps = field?.getLabelProps(rest, ref) ?? { ref, ...rest };

  return (
    <potion.label
      {...ownProps}
      className={cx('potion-form__label', props.className)}
      __css={{
        display: 'block',
        textAlign: 'start',
        ...styles,
      }}
    >
      {children}
      {field?.isRequired ? requiredIndicator : null}
    </potion.label>
  );
});

if (__DEV__) {
  FormLabel.displayName = 'FormLabel';
}

export interface RequiredIndicatorProps extends HTMLPotionProps<'span'> {}

/**
 * Used to show a "required" text or an asterisks (*) to indicate that
 * a field is required.
 */
export const RequiredIndicator = forwardRef<RequiredIndicatorProps, 'span'>((props, ref) => {
  const field = useFormControlContext();
  const styles = useStyles();

  if (!field?.isRequired) return null;

  const className = cx('potion-form__required-indicator', props.className);

  return (
    <potion.span
      {...field?.getRequiredIndicatorProps(props, ref)}
      __css={styles.requiredIndicator}
      className={className}
    />
  );
});

if (__DEV__) {
  RequiredIndicator.displayName = 'RequiredIndicator';
}
