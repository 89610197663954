import React, { useState, useEffect, useCallback } from 'react';
import {
  Text,
  Flex,
  Button,
  useTabStepper,
  Tabs,
  TabPanels,
  TabPanel,
  Center,
  Box,
  Heading,
  Radio,
  VStack,
  HStack,
  Link,
} from '@companydotcom/potion';
import { AppSpinner, CheckCircleIcon } from '@companydotcom/ui';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { SourceSvc } from '@companydotcom/services';
import { Mid, Source } from '@companydotcom/types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation, Trans } from 'react-i18next';
import { useSource } from '@companydotcom/providers';
import { changeExternalMid, activateUserProcess } from './utils';
import { useAuth } from '../../../providers';
import { useChangeEnrollmentIdMutation } from '../../../services/user/user-api';

const steps = [
  {
    slug: 'mid-selector',
    component: <MidSelector />,
  },
  {
    slug: 'mid-success',
    component: <MidSuccess />,
  },
];

export interface MidState {
  midList: Mid[];
  availableSource: string[];
  selectedMid: Mid;
  completeStatusMid: Mid;
}

export const MidList = () => {
  const location = useLocation();
  const source = useSource();
  const midState = (location.state as MidState) || {};
  const [selectedMid, setSelectedMid] = useState<Mid>(midState.selectedMid);

  const { tabIndex, handleTabsChange, nextStep } = useTabStepper({
    steps,
  });

  if (isEmpty(location.state)) {
    return <Navigate to="/" />;
  }

  return (
    <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
      <TabPanels>
        {steps.map(step => (
          <TabPanel key={step.slug}>
            {React.cloneElement(step.component, {
              midState,
              source,
              selectedMid,
              setSelectedMid,
              nextStep,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

const { getSourceConfig } = SourceSvc?.();

function MidSelector({
  midState,
  source,
  selectedMid,
  setSelectedMid,
  nextStep,
}: {
  midState?: MidState;
  source?: Source;
  selectedMid?: Mid;
  setSelectedMid?: (arg: Mid) => void;
  nextStep?: () => void;
}) {
  const [sourceMap, setSourceMap] = useState({});
  const [changeEnrollmentIdForUser] = useChangeEnrollmentIdMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setExternalIdentifierName = useCallback(async (availableSource: string[]) => {
    const sourceConfig = {};
    const sourceConfigList = await Promise.all(
      availableSource.map(async sourceId =>
        getSourceConfig(sourceId).then(r => r?.multiMidConfig?.midSelectionScreen),
      ),
    );

    for (const i in sourceConfigList) {
      sourceConfig[availableSource?.[i]] = sourceConfigList?.[i]?.externalIdentifier;
    }

    setSourceMap(sourceConfig);
  }, []);

  useEffect(() => {
    if (midState?.availableSource && !isEmpty(midState?.availableSource)) {
      setExternalIdentifierName(midState?.availableSource);
    }
  }, [midState?.availableSource, setExternalIdentifierName]);

  const confirmMid = async () => {
    if (selectedMid && midState?.completeStatusMid && source) {
      await activateUserProcess(
        selectedMid,
        midState?.completeStatusMid,
        source?.sourceId,
        navigate,
        changeEnrollmentIdForUser,
      );
      nextStep?.();
    }
  };

  return (
    <Center
      mx="auto"
      maxWidth="2xl"
      flexDirection="column"
      layerStyle="formContainer"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      {isEmpty(sourceMap) ? (
        <AppSpinner />
      ) : (
        <>
          <Box>
            <Heading size="hs-2xl">{t('subpage.accountList.list.header')}</Heading>
            <Text textStyle="md" mx="auto" maxW="xl" mt={2}>
              {t('subpage.accountList.list.subheader')}
            </Text>
          </Box>
          <VStack maxW={584} width="full" mt={14} spacing={2}>
            {midState?.midList.map(mid => (
              <Flex
                width="full"
                py={4}
                px={6}
                key={mid.enrolmentId}
                borderWidth="1px"
                borderColor="gray.200"
                justify="space-between"
              >
                <HStack spacing={4}>
                  <Radio
                    onChange={() => setSelectedMid?.(mid)}
                    isChecked={selectedMid?.externalIdentifier === mid.externalIdentifier}
                  />
                  <VStack textAlign="left" justify="flex-start">
                    <div>
                      {/* @ts-ignore */}
                      <Text fontWeight="medium">{mid.businessName}</Text>
                      {/* @ts-ignore */}
                      <Text>{mid.addressLine1}</Text>
                      {/* @ts-ignore */}
                      <Text>{`${mid.city}, ${mid.stateCode} ${mid.zip}`}</Text>
                    </div>
                  </VStack>
                </HStack>
                <VStack textAlign="right" justify="flex-end">
                  <Text>
                    {sourceMap[mid.source]}: {mid.externalIdentifier}
                  </Text>
                </VStack>
              </Flex>
            ))}
          </VStack>
          <Button onClick={confirmMid} variant="outline" size="lg" mt={14}>
            {t('subpage.accountList.list.button', {
              source: sourceMap?.[midState?.selectedMid?.source as string],
            })}
          </Button>
          <Trans i18nKey="subpage.accountList.list.support">
            <Text mt={16} maxW={584} mx="auto">
              If you would like to set up accounts for your additional Merchant Identification
              Numbers (MIDs) please
              <Link href={`mailto:${source?.supportEmail}`}>email support</Link>
              or call 888-486-8156.
            </Text>
          </Trans>
        </>
      )}
    </Center>
  );
}

function MidSuccess({
  midState,
  source,
  selectedMid,
}: {
  midState?: MidState;
  source?: Source;
  selectedMid?: Mid;
}) {
  const auth0 = useAuth();
  const { t } = useTranslation();
  const [changeEnrollmentIdForUser] = useChangeEnrollmentIdMutation();

  const handleContinue = () => {
    if (midState && source) {
      changeExternalMid(
        selectedMid ?? midState.selectedMid,
        midState?.completeStatusMid,
        auth0,
        source?.sourceId,
        changeEnrollmentIdForUser,
      );
    }
  };

  return (
    <Center
      mx="auto"
      maxWidth="2xl"
      flexDirection="column"
      layerStyle="formContainer"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Flex flexDirection="column" alignItems="center" flexBasis="100%">
        <CheckCircleIcon />
        <Box>
          <Heading className="title-class" mt={14} size="hs-2xl">
            {t('subpage.accountList.complete.header')}
          </Heading>
          <Text className="youll-be-logged-out" textStyle="md" mx="auto" maxW="xl" mt={10}>
            {t('subpage.accountList.complete.copy')}
          </Text>
        </Box>
        <Button variant="outline" size="lg" mt={14} onClick={handleContinue}>
          {t('common.buttons.continue')}
        </Button>
      </Flex>
    </Center>
  );
}
