import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  DatePicker,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  CheckboxGroup,
  SimpleGrid,
  IconButton,
  ButtonGroup,
  Text,
  Icon,
  IconProps,
  ListItem,
} from '@companydotcom/potion';

import { Controller, UseFormRegister } from 'react-hook-form';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import {
  InputField,
  TranslationErrorMessage,
  TextareaField,
  SelectField,
  NumberInputField,
  ConditionalWrapper,
} from '@companydotcom/ui';
import { get } from 'lodash';
import { SliceZone } from '../../../slice-zone';
import { EventsFormSliceMapperReturn } from '../utils';

export type SharedProps<Name extends string = string> = {
  slice: {
    slice_type: Name | string;
    fields: {
      name: string;
      is_required?: boolean;
    };
  };
  context: {
    register: UseFormRegister<any>;
    errors: { [x: string]: any };
    control: any;
    wrapInList?: boolean;
    watchAllFields?: any;
    setValue?: any;
  };
};

export type InputSliceProps = SharedProps<'input'> & {
  slice: {
    fields: {
      label?: string;
      placeholder?: string;
      helper_text?: string;
    };
  };
};

export function InputSlice({ slice, context }: InputSliceProps) {
  const { register, errors, wrapInList } = context;
  const { name, label, placeholder, helper_text, is_required } = slice.fields;

  const _helperText = helper_text || (is_required ? 'Required' : undefined);

  return (
    <ConditionalWrapper
      condition={!!wrapInList}
      wrapper={children => <ListItem pl={4}>{children}</ListItem>}
    >
      <InputField
        register={register}
        placeholder={placeholder}
        name={name}
        label={label}
        helperText={_helperText}
        errors={errors}
        maxWidth={['full', 402]}
      />
    </ConditionalWrapper>
  );
}

export type TextareaSliceProps = SharedProps<'textarea'> & {
  slice: {
    fields: {
      label?: string;
      placeholder?: string;
      helper_text?: string;
    };
  };
};

export function TextareaSlice({ slice, context }: TextareaSliceProps) {
  const { register, errors, wrapInList } = context;
  const { name, label, placeholder, is_required, helper_text } = slice.fields;

  const _helperText = helper_text || (is_required ? 'Required' : undefined);

  return (
    <ConditionalWrapper
      condition={!!wrapInList}
      wrapper={children => <ListItem pl={4}>{children}</ListItem>}
    >
      <TextareaField
        register={register}
        placeholder={placeholder}
        name={name}
        label={label}
        helperText={_helperText}
        errors={errors}
        maxWidth={['full', 402]}
      />
    </ConditionalWrapper>
  );
}

export type DropdownSliceProps = SharedProps<'dropdown'> & {
  slice: {
    fields: {
      label?: string;
      placeholder?: string;
      helper_text?: string;
      options?: string[];
      hasParent?: boolean;
      questionId?: string;
      parentQuestionId?: string;
      fieldMappings?: { parentFieldValue: string; childFieldValues: string[] }[];
    };
  };
};

export function DropdownSlice({ slice, context }: DropdownSliceProps) {
  const { register, errors, wrapInList, watchAllFields, setValue } = context;
  const {
    name,
    label,
    placeholder,
    helper_text,
    is_required,
    options,
    questionId,
    hasParent,
    parentQuestionId,
    fieldMappings,
  } = slice.fields;

  const parentQuestionValue =
    parentQuestionId && watchAllFields.customQuestions?.[parentQuestionId];
  const [filteredOptions, setFilteredOptions] = useState(options || []);

  useEffect(() => {
    if (hasParent && parentQuestionValue) {
      const mapping = fieldMappings?.find(
        mapping => mapping.parentFieldValue === parentQuestionValue,
      );
      setFilteredOptions(mapping?.childFieldValues || []);
    } else if (hasParent && !parentQuestionValue) {
      setFilteredOptions([]);
      setValue?.(`customQuestions.${questionId}`, '');
    } else {
      setFilteredOptions(options || []);
    }
  }, [hasParent, parentQuestionValue, fieldMappings, options, questionId, setValue]);
  const _helperText = helper_text || (is_required ? 'Required' : undefined);

  return (
    <ConditionalWrapper
      condition={!!wrapInList}
      wrapper={children => <ListItem pl={4}>{children}</ListItem>}
    >
      <SelectField
        register={register}
        placeholder={placeholder}
        name={name}
        label={label}
        helperText={_helperText}
        errors={errors}
        isDisabled={hasParent && !parentQuestionValue}
        maxWidth={['full', 402]}
      >
        <option value=""> </option>
        {filteredOptions?.map(opt => (
          <option key={opt} value={opt.trim()}>
            {opt}
          </option>
        ))}
      </SelectField>
    </ConditionalWrapper>
  );
}

export type NumberSliceProps = SharedProps<'number'> & {
  slice: {
    fields: {
      label?: string;
      placeholder?: string;
      helper_text?: string;
    };
  };
};

export function NumberSlice({ slice, context }: NumberSliceProps) {
  const { control, errors, wrapInList } = context;
  const { name, label, placeholder, helper_text, is_required } = slice.fields;

  const _helperText = helper_text || (is_required ? 'Required' : undefined);

  return (
    <ConditionalWrapper
      condition={!!wrapInList}
      wrapper={children => <ListItem pl={4}>{children}</ListItem>}
    >
      <NumberInputField
        control={control}
        placeholder={placeholder}
        name={name}
        label={label}
        helperText={_helperText}
        errors={errors}
        hideNumberStepper
        inputStyles={{
          maxWidth: ['full', 200],
        }}
      />
    </ConditionalWrapper>
  );
}

export type CurrencySliceProps = SharedProps<'currency'> & {
  slice: {
    fields: {
      label?: string;
      placeholder?: string;
      helper_text?: string;
      decimal_places?: number;
    };
  };
};

export function CurrencySlice({ slice, context }: CurrencySliceProps) {
  const { control, errors, wrapInList } = context;
  const { name, label, placeholder, helper_text, is_required, decimal_places } = slice.fields;

  const _helperText = helper_text || (is_required ? 'Required' : undefined);

  return (
    <ConditionalWrapper
      condition={!!wrapInList}
      wrapper={children => <ListItem pl={4}>{children}</ListItem>}
    >
      <NumberInputField
        control={control}
        placeholder={placeholder}
        name={name}
        label={label}
        helperText={_helperText}
        errors={errors}
        hideNumberStepper
        precision={decimal_places || 0}
        inputStyles={{
          maxWidth: ['full', 200],
        }}
      />
    </ConditionalWrapper>
  );
}

export type DatePickerSliceProps = SharedProps<'datepicker'> & {
  slice: {
    fields: {
      label?: string;
      placeholder?: string;
      helper_text?: string;
    };
  };
};

export function DatePickerSlice({ slice, context }: DatePickerSliceProps) {
  const { errors, control, wrapInList } = context;
  const { name, label, placeholder, helper_text } = slice.fields;

  const CustomHeader = ({
    date,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    function getMonth(date: Date) {
      return date?.getMonth();
    }

    return (
      <Flex align="center" justify="space-between" py={1} pl={4} pr={2}>
        <Menu>
          <MenuButton type="button">
            <Flex direction="row" align="center">
              <Text textStyle="sm" fontWeight="bold" mr={1}>
                {months[getMonth(date)]}
              </Text>
              <CaretDown />
            </Flex>
          </MenuButton>
          <MenuList>
            {months.map(opt => (
              <MenuItem
                // @ts-ignore
                onClick={e => changeMonth(months.indexOf(e.target.value))}
                key={opt}
                value={opt}
              >
                {opt}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <ButtonGroup size="xs">
          <IconButton
            variant="ghost"
            size="sm"
            aria-label="Previous month"
            isDisabled={prevMonthButtonDisabled}
            onClick={decreaseMonth}
            icon={<AngleLeft color="black" />}
          />
          <IconButton
            variant="ghost"
            size="sm"
            aria-label="Next month"
            isDisabled={nextMonthButtonDisabled}
            onClick={increaseMonth}
            icon={<AngleRight color="black" />}
          />
        </ButtonGroup>
      </Flex>
    );
  };

  return (
    <ConditionalWrapper
      condition={!!wrapInList}
      wrapper={children => <ListItem pl={4}>{children}</ListItem>}
    >
      <FormControl id={name} isInvalid={errors && !!get(errors, name)}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <DatePicker
              dateFormat="MM-dd-yyyy"
              renderCustomHeader={CustomHeader}
              placeholderText={placeholder}
              onChange={date => field.onChange(date)}
              selected={field.value}
              inputStyles={{ maxWidth: ['full', 200] }}
            />
          )}
        />
        {helper_text && !get(errors, name) && <FormHelperText>{helper_text ?? ''}</FormHelperText>}
        {errors && (
          <TranslationErrorMessage errors={errors} name={name}>
            {errors && get(errors, name) && get(errors, name)?.message}
          </TranslationErrorMessage>
        )}
      </FormControl>
    </ConditionalWrapper>
  );
}

export type CheckboxSliceProps = SharedProps<'checkbox'> & {
  slice: {
    fields: {
      label?: string;
      helper_text?: string;
    };
  };
};

export function CheckboxSlice({ slice, context }: CheckboxSliceProps) {
  const { register, errors, wrapInList } = context;
  const { name, label, helper_text, is_required } = slice.fields;

  const _helperText = helper_text || (is_required ? 'Required' : undefined);

  return (
    <ConditionalWrapper
      condition={!!wrapInList}
      wrapper={children => <ListItem pl={4}>{children}</ListItem>}
    >
      <FormControl id={name} isInvalid={errors && !!get(errors, name)}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Checkbox size="lg" {...register(name)} />
        {_helperText && !get(errors, name) && <FormHelperText>{_helperText ?? ''}</FormHelperText>}
        {errors && (
          <TranslationErrorMessage errors={errors} name={name}>
            {errors && get(errors, name) && get(errors, name)?.message}
          </TranslationErrorMessage>
        )}
      </FormControl>
    </ConditionalWrapper>
  );
}

export type CheckboxGroupSliceProps = SharedProps<'checkboxgroup'> & {
  slice: {
    fields: {
      label?: string;
      helper_text?: string;
      options?: string[];
    };
  };
};

export function CheckboxGroupSlice({ slice, context }: CheckboxGroupSliceProps) {
  const { control, errors, wrapInList } = context;
  const { name, label, helper_text, options, is_required } = slice.fields;

  const _helperText = helper_text || (is_required ? 'Required' : undefined);

  return (
    <ConditionalWrapper
      condition={!!wrapInList}
      wrapper={children => <ListItem pl={4}>{children}</ListItem>}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, ...rest } }) => (
          <FormControl ref={ref} id={name} isInvalid={errors && !!get(errors, name)}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <CheckboxGroup {...rest}>
              <SimpleGrid columns={[1, 2]} spacing={4}>
                {options?.map((opt, i) => (
                  <Checkbox key={i} value={opt.trim()}>
                    {opt}
                  </Checkbox>
                ))}
              </SimpleGrid>
            </CheckboxGroup>
            {_helperText && !get(errors, name) && (
              <FormHelperText>{_helperText ?? ''}</FormHelperText>
            )}
            {errors && (
              <TranslationErrorMessage errors={errors} name={name}>
                {errors && get(errors, name) && get(errors, name)?.message}
              </TranslationErrorMessage>
            )}
          </FormControl>
        )}
      />
    </ConditionalWrapper>
  );
}

export function FormSliceZone({
  register,
  errors,
  control,
  data,
  wrapInList,
  watchAllFields,
  setValue,
}: {
  register: UseFormRegister<any>;
  errors: { [x: string]: any };
  control: any;
  data: EventsFormSliceMapperReturn;
  wrapInList?: boolean;
  watchAllFields?: any;
  setValue?: any;
}) {
  const components = {
    input: InputSlice,
    textarea: TextareaSlice,
    dropdown: DropdownSlice,
    number: NumberSlice,
    currency: CurrencySlice,
    datepicker: DatePickerSlice,
    checkbox: CheckboxSlice,
    checkboxgroup: CheckboxGroupSlice,
  };

  return (
    <SliceZone
      slices={data}
      // @ts-ignore
      components={components}
      context={{ register, errors, control, wrapInList, watchAllFields, setValue }}
    />
  );
}

const AngleLeft = (props: IconProps) => (
  <Icon viewBox="0 0 256 512" {...props}>
    <path
      fill="currentColor"
      d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"
    />
  </Icon>
);

const AngleRight = (props: IconProps) => (
  <Icon viewBox="0 0 256 512" {...props}>
    <path
      fill="currentColor"
      d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"
    />
  </Icon>
);

const CaretDown = (props: IconProps) => (
  <Icon viewBox="0 0 320 512" {...props}>
    <path
      fill="currentColor"
      d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z"
    />
  </Icon>
);
