import React from 'react';
import { Flex } from '@companydotcom/potion';
import { PieChart, Pie, Cell, Label } from 'recharts';

import type { TileComponentProps } from '../../types';

export interface ChartData {
  text: string;
  value: number;
  key: number;
}

export interface CircleProgressProps extends TileComponentProps {
  chartData?: ChartData[];
  dataColor?: any;
}

export const CircleProgress: React.FC<CircleProgressProps> = props => {
  const { chartData, dataColor } = props;

  return (
    <Flex
      className="tile__chart-circle-progress"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex={1}
    >
      <PieChart width={150} height={150}>
        <Pie
          data={chartData}
          cx={70}
          innerRadius={40}
          outerRadius={70}
          paddingAngle={0}
          dataKey="value"
        >
          {chartData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={dataColor?.[index % dataColor?.length]} />
          ))}
          <Label
            fontSize="24px"
            fontWeight={500}
            value={
              !chartData
                ? 'Loading...'
                : `${chartData?.find?.(i => i?.text === 'complete')?.value ?? ''}%`
            }
            position="center"
          />
        </Pie>
      </PieChart>
    </Flex>
  );
};
