import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  // EventForPurchaseGuest,
  Guest,
  SearchIndividualResponse,
} from '../../../services/acg/acg.types';
import type { RootState } from '../../../store';

export interface AddGuestState {
  guestsToRegister: Partial<Guest>[];
  activeGuest?: Partial<SearchIndividualResponse>;
}

export const initialState: AddGuestState = {
  activeGuest: undefined,
  guestsToRegister: [],
};

export const acgAddGuestSlice = createSlice({
  name: 'acgAddGuest',
  initialState,
  reducers: {
    updateActiveGuest: (state, action: PayloadAction<Partial<SearchIndividualResponse>>) => {
      state.activeGuest = action.payload;
    },
    addGuestToRegister: (state, action: PayloadAction<Guest>) => {
      state.guestsToRegister?.push(action.payload);
    },
    updateGuestToRegister: (
      state,
      action: PayloadAction<{
        emailAddress: string;
        badge: {
          firstName?: string;
          organizationName?: string;
          title?: string;
        };
      }>,
    ) => {
      const guest = state.guestsToRegister.findIndex(
        guest => guest.emailAddress === action.payload.emailAddress,
      );

      if (guest) {
        state.guestsToRegister[guest] = {
          ...state.guestsToRegister[guest],
          ...action.payload.badge,
        };
      }
    },
    // addQuestionsToGuest: (state, action: PayloadAction<Record<string, any>>) => {
    //   const mostRecentGuest = state.guestsToRegister[state.guestsToRegister.length - 1];
    //   mostRecentGuest.questions = action.payload;
    // },
    resetGuestsToRegister: (state, action: PayloadAction<any[]>) => {
      state.guestsToRegister = action.payload;
    },
    removeGuestToRegister: (state, action: PayloadAction<number | undefined>) => {
      state.guestsToRegister = state.guestsToRegister.filter(
        (guest, index) => action.payload !== index,
      );
    },
  },
});

export const {
  updateActiveGuest,
  addGuestToRegister,
  // addQuestionsToGuest,
  resetGuestsToRegister,
  updateGuestToRegister,
  removeGuestToRegister,
} = acgAddGuestSlice.actions;

export const selectActiveGuest = (state: RootState) => state.acgAddGuest.activeGuest;
export const selectGuestsToRegister = (state: RootState) => state.acgAddGuest.guestsToRegister;

export default acgAddGuestSlice.reducer;
