import React, { createContext, useReducer, useContext, useMemo } from 'react';
import {
  userProfileReducer,
  initialUserProfileState,
} from '../state/reducers/user-profile-reducer';

// Code made with help from https://gist.github.com/sw-yx/f18fe6dd4c43fddb3a4971e80114a052
export function createCtx<StateType, ActionType>(
  reducer: React.Reducer<StateType, ActionType>,
  initialState: StateType,
) {
  const defaultDispatch: React.Dispatch<ActionType> = () => initialState;
  const ctx = createContext({
    userProfileState: initialState,
    dispatchUserProfileState: defaultDispatch,
  });
  // eslint-disable-next-line
  function Provider(props: React.PropsWithChildren<{}>) {
    const [userProfileState, dispatchUserProfileState] = useReducer<
      React.Reducer<StateType, ActionType>
    >(reducer, initialState);

    const contextValue = useMemo(() => {
      return { userProfileState, dispatchUserProfileState };
    }, [userProfileState, dispatchUserProfileState]);

    return <ctx.Provider value={contextValue} {...props} />;
  }

  return [ctx, Provider] as const;
}

const initialState = initialUserProfileState;

export const [ctx, UserProfileProvider] = createCtx(userProfileReducer, initialState);
export const UserProfileContext = ctx;
export const useUserProfileContext = () => useContext(UserProfileContext);
