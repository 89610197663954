import React from 'react';
import { Image, Icon, Text, Heading, HStack, VStack } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { TileComponentProps } from '../../types';

export interface TileConferencingCardProps extends TileComponentProps {
  useIcon?: boolean;
}

export const TileConferencingCard: React.FC<TileConferencingCardProps> = props => {
  const { pubEvents, eventHandler, icon, text, description, useIcon, componentTheme } = props;
  return (
    <HStack
      role="button"
      flexDirection="row"
      mb={2}
      boxShadow="md"
      width="full"
      borderRadius="md"
      px={8}
      py={4}
      border="1px solid"
      borderColor="gray.100"
      onClick={(e: any) => eventHandler?.(pubEvents, e)}
      _hover={{
        boxShadow: 'lg',
      }}
      sx={componentTheme}
    >
      {useIcon ? (
        // @ts-ignore
        <Icon as={FontAwesomeIcon} icon={icon} width="40px" height="40px" />
      ) : (
        <Image src={icon ?? ''} width="40px" height="40px" alt="" />
      )}
      <VStack alignItems="flex-start">
        <Heading size="hs-md">{text}</Heading>
        <Text textStyle="sm">{description}</Text>
      </VStack>
    </HStack>
  );
};
