import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['topRail'];

const baseStyleTopRail: SystemStyleObject = {
  width: 'full',
  // justifyContent: 'space-between',
  flexDirection: 'row',
  mb: 8,
};

const baseStyle = () => ({
  topRail: baseStyleTopRail,
});

export default {
  parts,
  baseStyle,
};
