import React, { useState, useEffect } from 'react';
import { platformHelpers } from '@companydotcom/helpers';
import {
  UserTile,
  TileComponent as TTileComponent,
  Notification,
  TileState,
  Account,
  ActionParam,
} from '@companydotcom/types';
import { CSSObject, TabPanel, Text } from '@companydotcom/potion';
import { isEmpty } from 'lodash';
import { TileComponents } from '.';
import { TileCarousel } from './tile-carousel';
import { TileSwitch } from './tile-switch';
import { findChildConditionMatch } from '../utils';

export interface TileComponentProps extends Omit<TTileComponent, 'componentTheme'> {
  tile?: UserTile;
  notifications?: Notification[];
  eventData?: any;
  stateCurrent?: TileState;
  eventHandler?: (events: any, event: React.MouseEvent<HTMLButtonElement>) => void;
  componentTheme?: CSSObject;
  account?: Account;
}

export const TileComponent: React.FC<any> = props => {
  const {
    type,
    notifications,
    children,
    params,
    account,
    eventHandler,
    product,
    source,
    stateCurrent,
    tile,
    user,
    userId,
  } = props;

  const sharedChildProps = {
    account,
    eventHandler,
    product,
    source,
    stateCurrent,
    tile,
    user,
    userId,
  };

  const eventData = notifications
    ? platformHelpers.mapEventDataToComponent(notifications, type)
    : undefined;

  const [activeChildIdx, setChildPageIdx] = useState(
    type === 'TileSwitch' && findChildConditionMatch(params as ActionParam[], children, eventData),
  );

  useEffect(() => {
    if (type === 'TileSwitch') {
      setChildPageIdx(findChildConditionMatch(params as ActionParam[], children, eventData));
    }
  }, [children, eventData, params, type]);

  const SelectedComponent = type ? TileComponents[type] : undefined;

  // Since TileCarousel recursively renders other Tile Components,
  // we must render it here to avoid dependency cycles
  if (type === 'TileCarousel') {
    return (
      <TileCarousel
        eventData={eventData}
        viewLabels={props.children?.map((child: any) => child?.text)}
        {...props}
      >
        {(children as TileComponentProps[])?.map((c, k) => (
          <TabPanel
            key={k}
            display="flex"
            flexDirection="column"
            width="full"
            height="100%"
            padding={0}
            alignItems="center"
          >
            {c?.children?.map((i, n) => (
              // @ts-ignore
              <RenderedComponent
                key={n}
                component={i?.type ? TileComponents[i?.type as string] : undefined}
                type={i?.type}
                eventData={platformHelpers.mapEventDataToComponent(notifications, i?.type)}
                notifications={notifications}
                {...i}
                {...sharedChildProps}
              />
            ))}
          </TabPanel>
        ))}
      </TileCarousel>
    );
  }

  if (type === 'TileSwitch') {
    return (
      <TileSwitch>
        {(children[activeChildIdx]?.children as TileComponentProps[])?.map((c, k) => (
          <React.Fragment key={k}>
            <RenderedComponent
              key={k}
              component={c?.type ? TileComponents[c?.type as string] : undefined}
              type={c?.type}
              eventData={eventData}
              {...c}
            />
          </React.Fragment>
        ))}
      </TileSwitch>
    );
  }

  return (
    <RenderedComponent component={SelectedComponent} type={type} eventData={eventData} {...props} />
  );
};

export interface RenderedComponentProps extends TileComponentProps {
  component?: React.ReactNode;
}

export const RenderedComponent: React.FC<RenderedComponentProps> = (props: any) => {
  const { component: Component, type, eventData, notifications, stateCurrent, ...rest } = props;
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (
      !isEmpty(stateCurrent?.tileEvents) &&
      isEmpty(notifications) &&
      stateCurrent?.tileEvents.some((event: any) => event.componentTypes.includes(type))
    ) {
      setIsFetching(true);
    }

    return () => setIsFetching(false);
  }, [notifications, stateCurrent?.tileEvents, type]);

  if (!Component) {
    return (
      <Text textStyle="md" color="red.500">
        {type} is not a valid component
      </Text>
    );
  }

  return (
    <Component
      type={type}
      stateCurrent={stateCurrent}
      notifications={notifications}
      {...rest}
      eventData={{
        ...eventData,
        fetching: isFetching,
      }}
    />
  );
};
