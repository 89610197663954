import dayjs from 'dayjs';
import { Maybe, AcgMembership } from '@companydotcom/types';
import { RecommendedEventsPayload } from '../../../../../services/acg/acg.types';

export const dataMap = (
  state: string,
  memberships: Maybe<AcgMembership>[],
): RecommendedEventsPayload => {
  const chapters = memberships?.map(m => m?.chapterName);
  const payload = {
    residentState: state,
    chapterNames: chapters,
  };
  return payload;
};

export const unixTimeStampToReadableDate = (timeStamp: string) => {
  return dayjs(Number(timeStamp) * 1000).format('MMMM D, YYYY');
};
