import gql from 'graphql-tag';

export const GET_ENROLLMENT_STATUS_QUERY = gql`
  query GetEnrollMentStatus($enrollmentId: String!, $source: String) {
    getEnrollMentIdStatus(enrollmentId: $enrollmentId, source: $source)
  }
`;

export const GET_ENROLLMENT_BY_EMAIL_QUERY = gql`
  query GetEnrollmentByUserEmail($email: String!) {
    getEnrollmentByUserEmail(email: $email)
  }
`;

export const GENERATE_AND_SEND_OTP = gql`
  query GenerateAndSendOtp(
    $userId: String!
    $phoneNumber: String
    $email: String
    $useEmail: Boolean
  ) {
    generateAndSendOtp(
      userId: $userId
      phoneNumber: $phoneNumber
      email: $email
      useEmail: $useEmail
    )
  }
`;
