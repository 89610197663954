import SideNav from './side-nav';
import ShoppingCart from './shopping-cart';
import MobileSideNav from './mobile-side-nav';
import Profile from './profile';
import DashboardLayout from './dashboard-layout';
import TileGroupFilter from './tile-group-filter';
import BYOP from './byop';
import Tiles from './tiles';
import TileGroup from './tile-group';
import UserProfile from './user-profile';
import PageLayout from './page-layout';
import MyProfileStep from './my-profile-step';
import AdditionalUsersStep from './additional-users-step';
import BusinessProfileStep from './business-profile-step';
import PaymentInformationStep from './payment-information-step';
import InvoiceStep from './invoice-step';
import ChangeAccountPasswordStep from './change-account-password-step';
import EmailPasswordSettingsStep from './email-password-settings-step';
import Tile from './tile';
import Rating from './rating';
import TileText from './tile-text';
import TileCarousel from './tile-carousel';
import TileSubheader from './tile-subheader';

export default {
  SideNav,
  ShoppingCart,
  Profile,
  MobileSideNav,
  DashboardLayout,
  TileGroupFilter,
  BYOP,
  Tiles,
  TileGroup,
  UserProfile,
  PageLayout,
  MyProfileStep,
  AdditionalUsersStep,
  BusinessProfileStep,
  PaymentInformationStep,
  InvoiceStep,
  ChangeAccountPasswordStep,
  EmailPasswordSettingsStep,
  Tile,
  Rating,
  TileText,
  TileCarousel,
  TileSubheader,
};
