import { useMitt } from '@companydotcom/providers';
import { companyConstants } from '@companydotcom/helpers';
import { useEventListener } from '@companydotcom/ui';
import { useNavigate } from 'react-router-dom';

interface MetadataProps {
  url?: string;
  link?: string;
  label?: string;
  height?: string;
  props?: any;
}

interface NavControllerProps {
  metadata: MetadataProps;
  type?: string;
}

export const NavController: any = ({ type, metadata }: NavControllerProps) => {
  const navigate = useNavigate();
  const { emitter } = useMitt();

  useEventListener('message', (event: any) => {
    if (event.data.reload) {
      window.location.reload();
    }
    if (event.data.logout) {
      window.location.assign('/logout');
    }
    if (event.data.launchPaywall) {
      const data = event.data.paywallData;
      data.metadata.products = [data.metadata.product];
      emitter.emit(companyConstants.platformEvents.openPaywall, event.data.paywallData);
    }
  });

  // TODO: Unsure if any of this is necessary, but may require tile data refactor
  switch (type) {
    case 'openSubpage': {
      if (metadata?.url) {
        navigate(metadata.url);
      }
      console.log('Error: openSubpage navType metadata is missing url');
      return null;
    }
    case 'link':
      if (metadata.url) {
        navigate(metadata.url);
      }
      console.log('Error: link navType metadata is missing url');

      return null;
    default:
  }
};
