import { ExtendedSubscriptionStatus } from '@companydotcom/types';
import { emptySplitApi } from '../../store/empty-split-api';
import {
  GET_ALL_PRODUCTS,
  GET_ELIGIBLE_CHILD_PRODUCTS,
  GET_LIVE_AGENT_AVAILABILITY,
  GET_PRODUCT,
  GET_USER_PRODUCT,
  GET_USER_PRODUCTS,
} from './graphql/product-query';

export const ProductApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getAllProducts: builder.query<any, any>({
      query: () => ({
        method: 'query',
        document: GET_ALL_PRODUCTS,
      }),
      transformResponse: (rawResult: { data: { getAllProducts: any } }) =>
        rawResult.data.getAllProducts,
    }),
    getUserProducts: builder.query<ExtendedSubscriptionStatus, { userId: string; locale?: string }>(
      {
        providesTags: ['UserProducts'],
        query: ({ userId, locale = 'en' }) => ({
          method: 'query',
          document: GET_USER_PRODUCTS,
          variables: {
            userId,
            locale,
          },
        }),
        transformResponse: (rawResult: { data: { getUserProducts: ExtendedSubscriptionStatus } }) =>
          rawResult.data.getUserProducts,
      },
    ),
    getUserProduct: builder.query<
      ExtendedSubscriptionStatus,
      { userId: string; productId: string; locale?: string }
    >({
      query: ({ userId, locale = 'en', productId }) => ({
        method: 'query',
        document: GET_USER_PRODUCT,
        variables: {
          userId,
          productId,
          locale,
        },
      }),
      transformResponse: (rawResult: { data: { getUserProducts: ExtendedSubscriptionStatus } }) =>
        rawResult.data.getUserProducts,
    }),
    getEligibleChildProducts: builder.query<
      ExtendedSubscriptionStatus[],
      { userId: string; sourceId: string; locale?: string }
    >({
      query: ({ userId, sourceId, locale = 'en' }) => ({
        method: 'query',
        document: GET_ELIGIBLE_CHILD_PRODUCTS,
        variables: {
          userId,
          sourceId,
          locale,
        },
      }),
      transformResponse: (rawResult: {
        data: { getEligibleChildProducts: ExtendedSubscriptionStatus[] };
      }) => rawResult.data.getEligibleChildProducts,
    }),
    getLiveAgentAvailability: builder.query<any, void>({
      query: () => ({
        method: 'query',
        document: GET_LIVE_AGENT_AVAILABILITY,
      }),
      transformResponse: (rawResult: { data: { getLiveAgentAvailability: any } }) =>
        rawResult.data.getLiveAgentAvailability,
    }),
    getProduct: builder.query<any, { productId: string; locale?: string }>({
      query: ({ productId, locale = 'en' }) => ({
        method: 'query',
        document: GET_PRODUCT,
        variables: {
          productId,
          locale,
        },
      }),
      transformResponse: (rawResult: { data: { getProduct: any } }) => rawResult.data.getProduct,
    }),
  }),
});

export const {
  useGetUserProductsQuery,
  useGetUserProductQuery,
  useGetProductQuery,
  useLazyGetUserProductQuery,
  useLazyGetEligibleChildProductsQuery,
  useLazyGetLiveAgentAvailabilityQuery,
} = ProductApi;
