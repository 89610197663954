import AppSyncClient from 'aws-appsync';
import gql from 'graphql-tag';

import { MasterAuthInterface } from '../providers';

const { REACT_APP_REGION, REACT_APP_COMPANYQL_URL } = process.env;
class CustomAppSyncClient extends AppSyncClient {
  constructor(options) {
    super({ ...options, disableOffline: true });
  }

  query(options) {
    return super.query({
      ...options,
      fetchPolicy: 'no-cache',
      query: gql(options.query),
    });
  }

  mutate(options) {
    return super.mutate({
      ...options,
      fetchPolicy: 'no-cache',
      mutation: gql(options.mutation),
    });
  }

  subscribe(options) {
    return super.subscribe({
      ...options,
      fetchPolicy: 'no-cache',
      query: gql(options.query),
    });
  }
}

export const companyQLSecure = () => {
  return new CustomAppSyncClient({
    url: REACT_APP_COMPANYQL_URL,
    region: REACT_APP_REGION,
    auth: {
      type: 'OPENID_CONNECT',
      jwtToken: MasterAuthInterface.getCore().then(auth => auth.getTokenSilently()),
    },
  });
};
