import { THEME_CONFIG_FRAGMENT } from '../graphql/source-fragments';

const sourceQuery = {
  getSourceURI: (uri: string) => `query getSourceURI {
    getSourceURI(uri: "${uri}") {
      sourceId
    }
  }`,
  getSourceConfig: (sourceId: string) => `query getSourceConfig {
    getSourceConfig(source: "${sourceId}"){
      captchaEnabled
      passwordRequirements {
        identicalCharsEnabled
        max
        min
        numbersRequired
        specialChars
        uppercaseRequired
      }
      multiMidConfig {
        midSelectionScreen{
          externalIdentifier
        }
      }
      redirectConfig {
        nonMigratedRedirectUrl
        routeMap {
          param
          path
        }
      }
      cookieConfig {
        name
        path
        domain
        sameSite
        secure
      }
    }
  }`,
  getSource: (sourceId: string, locale: string) => `query getSource {
    getSource(
      sourceId: "${sourceId}",
      locale: "${locale}"
      ) {
      ${THEME_CONFIG_FRAGMENT}
      images {
        logo
        sidenav_logo
        footer_logo
        tile_icon
        learn_more
      }
      createAccountOnSignup
      sourceId
      showGmbProfileFlow
      supportPhoneNumber
      supportEmail
      i18nConfig {
        lng
        supportedLngs
      }
      sourceTiles {
        roles
      }
      byopConfig {
        enabled
      }
      footerLinks {
        home
        aboutUs
        contactUs
        termsOfUse
        privacyPolicy
        learnMore
      }
      navLinks {
        title
        links {
          path
          iconVariant
          icon
          label
          isExternal
        }
      }
      forms {
        label {
          fontWeight
          fontSize
          color
        }
        type
        buttonText
        buttonType
        termsText
      }
    }
  }`,
  getPaymentForm: (sourceId: any, isLocal = false) => `query getPaymentForm {
    getPaymentForm(sourceId: "${sourceId}", isLocal: ${isLocal}) {
      id
      signature
      token
      tenantId
      key
      success
      id
      style
      submitEnabled
      locale
      url
    }
  }`,
  getProductRatePlan: (
    accountId: any,
    productId: any,
    locale: string,
  ) => `query getProductRatePlan {
    getProductRatePlan(productId: "${productId}" accountId: "${accountId}" locale: "${locale}") {
      description
      includedInMembership
      isActive
      name
      price
      zuoraProductRatePlanId
      zuoraProductRatePlanChargeId
      partnerRatePlanId
      enterpriseQty
      tiers {
        startingUnit
        endingUnit
        price
        partnerTierId
        chargeModel
      }
    }
  }`,
  getAuth0Config: (source: string, applicationSlug = 'dashboard') => `query getAuth0Config {
    getAuth0Config(applicationSlug: "${applicationSlug}" source: "${source}") {
      authDomain
      clientAudience
      clientId
      clientSecret
      clientUrl
      connectionName
      forgotPasswordUrl
      redirectUrl
      serverIssuer
      tenant
    }
  }
  `,
  getContactInformation: (sourceId: string) => `query getContactInformation {
    getContactInformation(source: "${sourceId}"){
      heading
      primaryAddress {
        location
        addressLine1
        addressLine2
        addressLine3
      }
      secondaryAddress {
        location
        addressLine1
        addressLine2
        addressLine3
      }
      customerSuccessBlock {
        heading
        showChatSupport
        hours
        copy
        chatLink
        contactPhone
        contactEmail
        imageUrl
      }
      salesBlock {
        heading
        copy
        salesText
        salesLinks
        imageUrl
        showSalesBlock
      }
    }
  }`,
};
export default sourceQuery;
