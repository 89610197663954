import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['container', 'divider', 'form'];

const baseStyleContainer: SystemStyleObject = {
  paddingTop: 14,
  paddingBottom: '80px',
  maxWidth: 776,
  marginX: 'auto',
  paddingX: 5,
};

const baseStyleDivider: SystemStyleObject = {};

const baseStyle = () => ({
  container: baseStyleContainer,
  divider: baseStyleDivider,
});

export default {
  parts,
  baseStyle,
};
