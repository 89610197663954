import { Flex, Text, Button } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { useAddContactToWaitlistMutation } from '../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

export const WaitlistAlert = ({
  eventKey,
  waitListedRegistrants,
}: {
  eventKey: string;
  waitListedRegistrants: number;
}) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [addContactToWaitlist, { isLoading: isUpdating }] = useAddContactToWaitlistMutation();

  const navigate = useNavigate();

  const handleAddContactToWaitlist = async () => {
    try {
      if (globalUser && eventKey) {
        const res = await addContactToWaitlist({
          user: globalUser,
          payload: {
            eventId: eventKey,
          },
        }).unwrap();

        if (res) {
          navigate(`/event-registration/?Reg_evt_key=${eventKey}`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Flex
      justify="center"
      align="center"
      borderWidth="1px"
      flexDir="column"
      p={['4', null, '7']}
      mt="12"
      textAlign="center"
    >
      <Text
        textStyle={{ base: 'xl', md: '3xl' }}
        textAlign="center"
        fontWeight="bold"
        opacity="0.7"
      >
        We’re sorry. There’s now a waitlist for this event. You can add yourself to the waitlist and
        an ACG member will contact you if there’s an opening for this event. Unfortunately, you
        can’t add guests to the waitlist.
      </Text>
      <Text textStyle={{ base: 'lg', md: 'xl' }} mt="4" opacity="0.7">
        {waitListedRegistrants === 1
          ? `Currently, there is 1 person on the waitlist.`
          : `Currently, there are ${waitListedRegistrants} people on the waitlist.`}
      </Text>

      <Button
        size="lg"
        mt="12"
        isLoading={isUpdating}
        isDisabled={!globalUser || !eventKey}
        onClick={handleAddContactToWaitlist}
      >
        Join Waitlist
      </Button>

      <Button
        variant="ghost"
        size="sm"
        mt="4"
        onClick={() => window.open(`${process.env.REACT_APP_ACG_BASE_URL}/events`)}
        isDisabled={isUpdating}
      >
        SEARCH FOR ANOTHER EVENT
      </Button>
    </Flex>
  );
};
