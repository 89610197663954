import React from 'react';
import {
  Box,
  Center,
  Text,
  Pagination,
  PaginationContainer,
  PaginationItem,
  useBreakpointValue,
  usePagination as useTabPagination,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  TableContainer,
} from '@companydotcom/potion';
import { useTable, useSortBy, Column, usePagination, TableState } from 'react-table';

export interface SortableTableProps {
  data: object[];
  columns: Column<any>[];
  isMobile?: boolean;
  initialState?: Partial<TableState<object>>;
  autoResetHiddenColumns?: boolean;
  autoResetPage?: boolean;
}

export const SortableTable = (props: SortableTableProps) => {
  const {
    isMobile,
    data,
    columns,
    initialState,
    autoResetHiddenColumns = false,
    autoResetPage = true,
  } = props;

  const memoizedData = React.useMemo(() => data, [data]);
  const memoizedColumns = React.useMemo(() => columns, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state,
    pageCount,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState,
      autoResetHiddenColumns,
      autoResetPage,
    },
    useSortBy,
    usePagination,
  );
  const paginationBreakPoint = useBreakpointValue({ base: 0, md: 1 });

  const handlePaginationClick = (e: any, value: number) => {
    gotoPage(value - 1);
  };

  const { items } = useTabPagination({
    page: state.pageIndex + 1,
    count: pageCount,
    onChange: handlePaginationClick,
    siblingCount: paginationBreakPoint,
  });

  return (
    <>
      {data && data.length > 0 && (
        <Box as="span" fontSize="sm" alignSelf={['center', 'center', 'flex-start']}>
          {`Showing ${state?.pageIndex * state?.pageSize + 1} - ${
            data?.length < state?.pageSize
              ? data?.length
              : (state?.pageIndex + 1) * state?.pageSize > data?.length
              ? data?.length
              : (state?.pageIndex + 1) * state?.pageSize
          } of ${data?.length}`}
        </Box>
      )}
      <TableContainer width="full">
        <Table {...getTableProps()}>
          <Thead>
            {!data || data.length === 0 ? (
              <Tr>
                <Td>
                  <Heading
                    size="hs-xl"
                    padding={5}
                    py={7}
                    whiteSpace="normal"
                    textAlign="center"
                    color="#717174"
                    opacity={0.8}
                  >
                    There are no members related to this search. Please try your search again.
                  </Heading>
                </Td>
              </Tr>
            ) : (
              headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Th
                      fontSize="sm"
                      color="green.500"
                      fontWeight="bold"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.Header !== '' ? <Text>{column.render('Header')}</Text> : null}
                    </Th>
                  ))}
                </Tr>
              ))
            )}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return !isMobile ? (
                <Tr borderBottomWidth="1px" {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <Td
                      px={0}
                      fontSize="sm"
                      sx={{ borderBottomWidth: '0' }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              ) : (
                <Tr
                  sx={{ display: 'flex', flexDirection: 'column' }}
                  borderBottomWidth="1px"
                  {...row.getRowProps()}
                >
                  {row.cells.map(cell => (
                    <Td
                      role="row"
                      fontSize="sm"
                      py={1}
                      sx={{ borderBottomWidth: '0' }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        {!data ||
        data.length === 0 ||
        data.length <= 10 ||
        (isMobile && data.length <= 6) ? null : (
          <Center mt={6}>
            <Pagination variant="outline" size="sm">
              <PaginationContainer>
                {items?.map((item, i) => (
                  <PaginationItem key={i} {...item} />
                ))}
              </PaginationContainer>
            </Pagination>
          </Center>
        )}
      </TableContainer>
    </>
  );
};
