import { useSourceConfig } from '@companydotcom/providers';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { mapRedirectConfigToRouteMap } from '../components/acg-redirect-zone';

export const useAcgRedirect = () => {
  const sourceConfig = useSourceConfig();
  const [searchParams] = useSearchParams();
  const map = mapRedirectConfigToRouteMap(sourceConfig?.redirectConfig?.routeMap);
  const keyParam = 'WebCode';

  const foundParam = useMemo(() => {
    // Normalizes the query params and the keyParam to lowercase
    for (const entry of searchParams.entries()) {
      const [param] = entry;
      if (param.toLowerCase() === keyParam.toLowerCase()) {
        return searchParams.get(param);
      }
    }
  }, [keyParam, searchParams]);

  const redirect = useMemo(() => {
    return foundParam ? map[foundParam] : '/';
  }, [foundParam, map]);

  return { redirect };
};
