import React from 'react';
import { Tabs, TabPanels, TabPanel, useTabStepper } from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { MemberPublicProfile } from './acg-member-directory-public-profile';

import { MemberSearch } from '../../../features/acg/acg-member-search';
import { PageLayout, BackButtonLayout, PageLayoutContent } from '../../../components/layouts';
import {
  useGetAcgQueryArgs,
  useGetAcgReferenceDataV2Query,
} from '../../../services/acg/acg-api-v2';
import { useGetAllChapters } from '../../../features/acg/acg-checkout';

const memberDirectorySteps = [
  {
    slug: 'member-search',
    component: <MemberSearch />,
  },
  {
    slug: 'member-profile',
    component: <MemberPublicProfile />,
  },
];

export interface MemberDirectorySharedProps {
  memberDirectoryGoToStep?: (arg: number | string) => void;
}

export interface LocationStateProps {
  defaultStep: 'member-search' | 'member-profile';
}

export const AcgMemberDirectory: React.FC<MemberDirectorySharedProps> = () => {
  const location = useLocation();
  const state = location.state as LocationStateProps;
  const navigate = useNavigate();
  const args = useGetAcgQueryArgs();

  const { chapters, isLoading: chaptersLoading } = useGetAllChapters();
  const { data: acgRefData, isLoading: isRefDataLoading } = useGetAcgReferenceDataV2Query({
    ...args,
    referenceData: [
      'areaOfExpertise',
      'marketArea',
      'transactionType',
      'individualType',
      'country',
      'industry',
    ],
  });

  const {
    tabIndex,
    handleTabsChange,
    previousStep,
    goToStep: memberDirectoryGoToStep,
  } = useTabStepper({
    steps: memberDirectorySteps,
    initialStep: state?.defaultStep,
  });

  if (isRefDataLoading || chaptersLoading) {
    return (
      <AppSpinner
        description="Validating your membership..."
        containerStyles={{ margin: 0, height: '100%', flexGrow: 1 }}
      />
    );
  }

  return (
    <PageLayout>
      <PageLayoutContent maxWidth={1100}>
        <BackButtonLayout
          isDisplayed={tabIndex > 0}
          onBackClick={() => {
            if (location?.search) {
              navigate('/');
            } else {
              previousStep?.();
            }
          }}
        >
          <Tabs variant="page" isLazy index={tabIndex} onChange={handleTabsChange}>
            <TabPanels>
              {memberDirectorySteps?.map(step => (
                <TabPanel key={step.slug}>
                  {React.cloneElement(step.component, {
                    memberDirectoryGoToStep,
                    acgRefData: { ...acgRefData, chapters },
                  })}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </BackButtonLayout>
      </PageLayoutContent>
    </PageLayout>
  );
};
