import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { graphqlBaseQuery } from '../services';
import appSyncClient from '../lib/appsync';
// import { API } from 'aws-amplify';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  reducerPath: 'api',
  tagTypes: [
    'User',
    'UserTile',
    'UserGroup',
    'UserProducts',
    'UserTileState',
    'DataCollector',
    'AcgUser',
    'AcgCart',
    'AcgPaymentOptions',
    'AcgChapters',
    'AcgInvoices',
    'AcgMembership',
    'AcgPurchasedEvents',
    'AcgSavedPayments',
    'AcgRegisteredEvent',
    'DarkWebScan',
    'Notifications',
    'Appointments',
    'AcgReferenceData',
    'AcgChapterData',
  ],
  baseQuery: retry(graphqlBaseQuery({ client: appSyncClient })),
  endpoints: () => ({}),
  // Keep cache subscriptions open for an hour unless in production.  Will probably need to adjust once past ACG show
  keepUnusedDataFor: process.env.NODE_ENV === 'production' ? 1200 : 3600,
});
