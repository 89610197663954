import { GET_AUTH_USER, GET_WELCOME_EMAIL } from './graphql/auth-query';
import { emptySplitApi } from '../../store/empty-split-api';
import {
  LINK_ACCOUNTS,
  RESEND_EMAIL_ACTIVATION,
  UPDATE_VERIFY_EMAIL_CODE,
} from './graphql/auth-mutation';

export const AuthApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    // Not utilized, used in one place password-reset.tsx Could not get to function properly, reverted to old api call
    getAuthUser: builder.query<any, { userId: string }>({
      query: ({ userId }) => ({
        method: 'query',
        document: GET_AUTH_USER,
        variables: {
          userId,
        },
      }),
      transformResponse: (rawResult: { data: { getAuthUser: string } }) =>
        JSON.parse(rawResult.data.getAuthUser),
    }),
    getWelcomeEmail: builder.query<any, { email: string; input: any }>({
      query: ({ email, input }) => ({
        method: 'query',
        document: GET_WELCOME_EMAIL,
        variables: {
          email,
          input,
        },
      }),
      transformResponse: (rawResult: { data: { getWelcomeEmail: string } }) =>
        JSON.parse(rawResult.data.getWelcomeEmail),
    }),
    resendEmailActivation: builder.mutation<any, { email: string }>({
      query: ({ email }) => ({
        method: 'mutation',
        document: RESEND_EMAIL_ACTIVATION,
        variables: {
          email,
        },
      }),
      transformResponse: (rawResult: { data: { resendEmailActivation: string } }) =>
        JSON.parse(rawResult.data.resendEmailActivation),
    }),
    createAndSendEmailCode: builder.mutation<any, { email: string; userId: string }>({
      query: ({ email, userId }) => ({
        method: 'mutation',
        document: RESEND_EMAIL_ACTIVATION,
        variables: {
          email,
          userId,
        },
      }),
      transformResponse: (rawResult: { data: { createAndSendEmailCode: string } }) =>
        JSON.parse(rawResult.data.createAndSendEmailCode),
    }),
    updateVerifyEmailCode: builder.mutation<any, { email: string; userId: string }>({
      query: ({ email, userId }) => ({
        method: 'mutation',
        document: UPDATE_VERIFY_EMAIL_CODE,
        variables: {
          email,
          userId,
        },
      }),
      transformResponse: (rawResult: { data: { updateVerifyEmailCode: string } }) =>
        JSON.parse(rawResult.data.updateVerifyEmailCode),
    }),
    // Unable to implement due to context outside of AuthProvider. Will need to refactor App.tsx
    linkAccounts: builder.mutation<
      any,
      { sourceId: string; idpUserId: string; externalAuthUser: any }
    >({
      query: ({ sourceId, idpUserId, externalAuthUser }) => ({
        method: 'mutation',
        document: LINK_ACCOUNTS,
        variables: {
          input: {
            sourceId,
            idpUserId,
            externalAuthUser: JSON.stringify(externalAuthUser),
          },
        },
      }),
      transformResponse: (rawResult: { data: { linkAccounts: string } }) =>
        JSON.parse(rawResult.data.linkAccounts),
    }),
  }),
});

export const {
  useGetAuthUserQuery,
  useLazyGetAuthUserQuery,
  useLazyGetWelcomeEmailQuery,
  useResendEmailActivationMutation,
  useLinkAccountsMutation,
} = AuthApi;
