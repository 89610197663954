import { configureStore, AnyAction, ThunkAction } from '@reduxjs/toolkit';
import userReducer from '../features/users';
import { emptySplitApi } from './empty-split-api';

// ACG specific reducers
import acgEventRegistrationReducer from '../features/acg/acg-event-registration/event-registration-slice';
import acgMemberSearchReducer from '../features/acg/acg-member-search/member-search-slice';
import acgCheckoutReducer from '../features/acg/acg-checkout/checkout-slice';
import acgAutoRenewReducer from '../features/acg/acg-auto-renew/auto-renew-slice';
import acgMyEventsReducer from '../features/acg/acg-my-events/my-events-slice';
import acgAddGuestReducer from '../features/acg/acg-add-guest/add-guest-slice';
import darkWebScanReducer from '../features/dark-web-scan/dark-web-scan-slice';
import acgSavedPaymentMethodsReducer from '../features/acg/acg-saved-payment-methods/saved-payments-slice';
import dataCollectorReducer from '../features/data-collector/data-collector-slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    darkWebScan: darkWebScanReducer,
    dataCollector: dataCollectorReducer,
    // ACG specific state management
    acgMemberSearch: acgMemberSearchReducer,
    acgEventRegistration: acgEventRegistrationReducer,
    acgMyEvents: acgMyEventsReducer,
    acgCheckout: acgCheckoutReducer,
    acgAutoRenew: acgAutoRenewReducer,
    acgAddGuest: acgAddGuestReducer,
    acgSavedPaymentMethods: acgSavedPaymentMethodsReducer,
    // APIs
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(emptySplitApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
