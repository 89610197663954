import { TFunction } from 'react-i18next';

export const prefixOptions = (t: TFunction) => [
  {
    label: t('acg.firstTimeFlow.personalInformation.inputs.prefixOptionLabels.dr'),
    value: 'Dr.',
  },
  {
    label: t('acg.firstTimeFlow.personalInformation.inputs.prefixOptionLabels.hon'),
    value: 'Hon.',
  },
  {
    label: t('acg.firstTimeFlow.personalInformation.inputs.prefixOptionLabels.miss'),
    value: 'Miss',
  },
  {
    label: t('acg.firstTimeFlow.personalInformation.inputs.prefixOptionLabels.mr'),
    value: 'Mr.',
  },
  {
    label: t('acg.firstTimeFlow.personalInformation.inputs.prefixOptionLabels.mrs'),
    value: 'Mrs.',
  },
  {
    label: t('acg.firstTimeFlow.personalInformation.inputs.prefixOptionLabels.ms'),
    value: 'Ms.',
  },
  {
    label: t('acg.firstTimeFlow.personalInformation.inputs.prefixOptionLabels.rev'),
    value: 'Rev',
  },
];

export const referenceOptions = [
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.acgNews',
    value: 'ACG News',
  },
  {
    label:
      'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.annualConfAnnouncement',
    value: 'Annual Conf Announcement',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.eventAnnouncement',
    value: 'Event Announcement',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.eventAttendance',
    value: 'Event Attendance',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.magazine',
    value: 'Magazine',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.member',
    value: 'Member',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.newspaper',
    value: 'Newspaper',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.online',
    value: 'Online',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.eNewsletter',
    value: 'Online -- eNewsletter',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.socialNetwork',
    value: 'Online -- social network',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.website',
    value: 'Online -- Website',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.other',
    value: 'Other',
  },
  {
    label: 'acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.tv',
    value: 'Television',
  },
];

export const suffixOptions = (t: TFunction) => [
  {
    label: t('common.inputs.suffix.options.md'),
    value: 'MD',
  },
  {
    label: t('common.inputs.suffix.options.mba'),
    value: 'MBA',
  },
  {
    label: t('common.inputs.suffix.options.phd'),
    value: 'PhD',
  },
];

export const genderOptions = (t: TFunction) => [
  {
    label: t('common.inputs.gender.options.male'),
    value: 'male',
  },
  {
    label: t('common.inputs.gender.options.female'),
    value: 'female',
  },
];

export const ethnicityOptions = (t: TFunction) => [
  {
    label: t('common.inputs.ethnicity.options.caucasian'),
    value: 'caucasian',
  },
  {
    label: t('common.inputs.ethnicity.options.asianAmerican'),
    value: 'asian american',
  },
  {
    label: t('common.inputs.ethnicity.options.africanAmerican'),
    value: 'african american',
  },
  {
    label: t('common.inputs.ethnicity.options.nativeAmerican'),
    value: 'native american',
  },
  {
    label: t('common.inputs.ethnicity.options.hispanicLatino'),
    value: 'hispanic/latino(a)',
  },
  {
    label: t('common.inputs.ethnicity.options.moreThanOneRace'),
    value: 'more than one race/ethnicity',
  },
  {
    label: t('common.inputs.ethnicity.options.other'),
    value: 'other',
  },
];
