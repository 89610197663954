import React, { useState } from 'react';
import {
  Avatar,
  Heading,
  Text,
  useMultiStyleConfig,
  Skeleton,
  SkeletonCircle,
  StackProps,
  __DEV__,
  useStyles,
  AvatarProps,
  StylesProvider,
  HeadingProps,
  TextProps,
  potion,
  ButtonProps,
  Box,
} from '@companydotcom/potion';
import { Maybe } from '@companydotcom/types';
import { NavLink } from 'react-router-dom';

export interface ProfileProps extends StackProps {
  isSideNavOpen?: boolean;
  name?: string;
  businessName?: string;
  avatarUrl?: Maybe<string>;
  isLoading?: boolean;
}

export const Profile: React.FC<ProfileProps> = props => {
  const styles = useMultiStyleConfig('Profile', props);
  const { name, avatarUrl, businessName, isSideNavOpen, isLoading } = props;
  const [isError, setIsError] = useState(false);

  return (
    <StylesProvider value={styles}>
      <ProfileContainer marginLeft={isSideNavOpen ? [7, 10] : 2}>
        <SkeletonCircle
          isLoaded={!isLoading}
          width={isSideNavOpen ? '64px' : '48px'}
          height={isSideNavOpen ? '64px' : '48px'}
        >
          <ProfileAvatar
            name={name}
            size={isSideNavOpen ? 'lg' : 'md'}
            src={avatarUrl ?? undefined}
            onError={() => setIsError(true)}
            loading="eager"
            backgroundColor={avatarUrl && !isError ? 'transparent' : undefined}
          />
        </SkeletonCircle>

        {isSideNavOpen && (
          <>
            {isLoading ? (
              <Skeleton ml={2} width={180} height={4} />
            ) : (
              <Box>
                <ProfileName ml={2}>{name ?? ''}</ProfileName>
                <ProfileBusinessName ml={2}>{businessName ?? ''}</ProfileBusinessName>
              </Box>
            )}
          </>
        )}
      </ProfileContainer>
    </StylesProvider>
  );
};

export interface ProfileContainerProps extends ButtonProps {}

/**
 * ProfileContainer
 *
 * React component that houses the profile content.
 *
 */
export const ProfileContainer: React.FC<ProfileContainerProps> = props => {
  const styles = useStyles();

  const containerStyles = {
    display: 'inline-flex',
    height: 'inherit',
    alignItems: 'center',
    ...styles.profile,
  };

  return (
    <potion.button
      as={NavLink}
      to="/my-profile"
      textTransform="none"
      variant="unstyled"
      sx={containerStyles}
      className="profile__container"
      mr={4}
      {...props}
    />
  );
};

if (__DEV__) {
  ProfileContainer.displayName = 'ProfileContainer';
}

export interface ProfileAvatarProps extends AvatarProps {}

/**
 * ProfileAvatar
 *
 * React component that houses the profile avatar.
 *
 */
export const ProfileAvatar: React.FC<ProfileAvatarProps> = React.memo(props => {
  const styles = useStyles();

  return <Avatar className="profile__avatar" sx={styles.avatar} {...props} />;
});

if (__DEV__) {
  ProfileAvatar.displayName = 'ProfileAvatar';
}

export interface ProfileNameProps extends HeadingProps {}

/**
 * ProfileName
 *
 * React component that houses the profile name component.
 *
 */
export const ProfileName: React.FC<ProfileNameProps> = props => {
  const { children, ...rest } = props;
  const styles = useStyles();

  return (
    <Heading
      maxW={[140, 200]}
      noOfLines={[3, 2]}
      size="hs-md"
      sx={styles.name}
      className="profile__name"
      {...rest}
    >
      {children}
    </Heading>
  );
};

if (__DEV__) {
  ProfileName.displayName = 'ProfileName';
}

export interface ProfileBusinessNameProps extends TextProps {}

/**
 * ProfileBusinessName
 *
 * React component that houses the profile business name component.
 *
 */
export const ProfileBusinessName: React.FC<ProfileBusinessNameProps> = props => {
  const { children, ...rest } = props;
  const styles = useStyles();

  return (
    <Text
      noOfLines={2}
      sx={styles.businessName}
      marginTop="auto !important"
      className="profile__businessName"
      {...rest}
    >
      {children}
    </Text>
  );
};

if (__DEV__) {
  ProfileBusinessName.displayName = 'ProfileBusinessName';
}
