import React from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  Button,
  useBreakpointValue,
  VStack,
  Heading,
  Divider,
  UseTabStepperReturn,
  Alert,
  AlertDescription,
  AlertIcon,
} from '@companydotcom/potion';
import { InputField, AppSpinner } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch } from '../../../../hooks';
import { clearSearch } from '../../acg-member-search/member-search-slice';
import { useLazySearchIndividualQuery } from '../../../../services/acg/acg-api';
import { SortableTable } from '../../shared';
import { SearchIndividualResponse, EventForRegistration } from '../../../../services/acg/acg.types';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { updateActiveGuest } from '../add-guest-slice';
import { Column } from 'react-table';
import { getDirtyValues } from '@companydotcom/helpers';

const SearchSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string().email(),
});

export interface AddGuestSearchProps extends Pick<Partial<UseTabStepperReturn>, 'goToStep'> {
  showWaitListAlert?: boolean;
  waitListedRegistrants?: string;
  eventForRegistration?: EventForRegistration;
}

export const AddGuestSearch = (props: AddGuestSearchProps) => {
  const { goToStep, showWaitListAlert, waitListedRegistrants, eventForRegistration } = props;
  // Hooks
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const dispatch = useAppDispatch();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [searchIndividual, { isLoading, isFetching, isError, data: searchResults }] =
    useLazySearchIndividualQuery();

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors, isValid, isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(SearchSchema),
    mode: 'onChange',
  });

  const onSubmit = async (values: typeof defaultValues) => {
    const dirtyValues: Partial<typeof defaultValues> = getDirtyValues(dirtyFields, values);

    try {
      if (globalUser) {
        await searchIndividual({ user: globalUser, ...dirtyValues })
          .unwrap()
          .then(() => {
            dispatch(clearSearch([]));
            reset();
          });
      }
    } catch (err) {
      console.log('ERROR in submit call', err);
    }
  };

  const columns: Column<SearchIndividualResponse>[] = [
    {
      id: 'individual',
      Header: '',
      accessor: 'firstName',
      Cell: ({ row }) => <IndividualResult {...row?.original} goToStep={goToStep} />,
    },
  ];

  const createNewGuestHandler = async () => {
    try {
      goToStep?.('add-guest-new');
    } catch (err) {
      console.log('ERROR in submit call', err);
    }
  };

  return (
    <Box maxW={908} width="full" as="form">
      {showWaitListAlert && (
        <Alert status="warning" my={10}>
          <AlertIcon />
          <AlertDescription fontSize="sm" lineHeight="5">
            <Text fontWeight="bold">There’s a Waitlist for this Event</Text>
            <Text>
              You can still register for this event and will have a pro forma invoice generated for
              your registration. If you’re moved off the waitlist, you will be notified of this
              change and charged your registration.
            </Text>
            {eventForRegistration?.hasWaitList && (
              <Text fontWeight="bold" fontStyle="italic">
                {waitListedRegistrants === '0'
                  ? 'You will be the first person placed on the waitlist unless someone completes an order before you.'
                  : `Currently, there are ${waitListedRegistrants} people on the waitlist.`}
              </Text>
            )}
          </AlertDescription>
        </Alert>
      )}
      <Text
        lineHeight={1.25}
        textStyle="3xl"
        fontWeight="bold"
        opacity={0.7}
        textAlign={['center', 'left']}
      >
        Add Guest Attendee
      </Text>
      <Text my={[2, 6]} textAlign="center">
        Use any combination of first name, last name, and email address to search for guests.
      </Text>
      <Stack direction={['column', null, 'row']} mb={4}>
        <InputField
          className="addGuestSearch__firstNameField"
          data-test="addGuestSearch__firstNameField"
          placeholder={t('common.inputs.firstName.label')}
          register={register}
          name="firstName"
          errors={errors}
        />
        <InputField
          className="addGuestSearch__lastNameField"
          data-test="addGuestSearch__lastNameField"
          placeholder={t('common.inputs.lastName.label')}
          register={register}
          name="lastName"
          errors={errors}
        />
        <InputField
          className="addGuestSearch__email"
          data-test="addGuestSearch__email"
          placeholder={t('common.inputs.email.label')}
          register={register}
          name="email"
          errors={errors}
        />
      </Stack>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" mt={[6]}>
        <Button
          size="lg"
          isFullWidth={isMobile}
          type="submit"
          isDisabled={!isValid || isFetching || !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Search Guests
        </Button>

        <Button
          onClick={handleSubmit(createNewGuestHandler)}
          size="sm"
          mt={4}
          type="submit"
          variant="ghost"
          isDisabled={!isValid || isFetching}
        >
          CREATE NEW GUEST ATTENDEE
        </Button>
        {isLoading && !isError && (
          <AppSpinner
            description="Searching the guest directory…"
            containerStyles={{
              my: 12,
              mx: 'auto',
            }}
          />
        )}
        {searchResults && !isFetching && (
          <>
            <Divider my={12} borderColor="gray.200" />
            <Heading opacity={0.7} size="hs-xl" pb={6} alignSelf={['center', 'center', 'start']}>
              Guest Attendee Results
            </Heading>
            <SortableTable data={searchResults} columns={columns} isMobile={isMobile} />
          </>
        )}
      </Flex>
    </Box>
  );
};

export interface IndividualResultProps
  extends SearchIndividualResponse,
    Pick<Partial<UseTabStepperReturn>, 'goToStep'> {}

export const IndividualResult = (props: IndividualResultProps) => {
  const { firstName, lastName, emailAddress, organization, goToStep, ...rest } = props;
  const dispatch = useAppDispatch();

  const addButtonHandler = () => {
    dispatch(updateActiveGuest({ firstName, lastName, emailAddress, organization, ...rest }));
    goToStep?.('add-guest-existing');
  };

  const maskEmail = (email: string) => {
    return email?.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
  };

  return (
    <Stack
      className="individual-result"
      direction={['column', null, null, 'row']}
      justifyContent="space-between"
      alignItems="center"
    >
      <VStack alignItems="flex-start" spacing={0}>
        <Text textStyle="sm">{`${firstName} ${lastName}`}</Text>
        <Text textStyle="sm">{organization}</Text>
        {emailAddress && <Text textStyle="sm">{maskEmail(emailAddress)}</Text>}
      </VStack>
      <Button variant="outline" size="sm" onClick={addButtonHandler}>
        ADD AS GUEST
      </Button>
    </Stack>
  );
};
