const parts = ['container', 'control', 'label', 'icon'];

function baseStyleControl(props: Record<string, any>) {
  const { colorScheme: c } = props;

  return {
    w: '100%',
    transitionProperty: 'box-shadow',
    transitionDuration: 'normal',
    border: '2px solid',
    borderRadius: 'sm',
    borderColor: 'inherit',
    color: 'white',

    _checked: {
      bg: `${c}.500`,
      borderColor: `${c}.500`,
      color: 'white',

      _hover: {
        bg: `${c}.600`,
        borderColor: `${c}.600`,
      },

      _disabled: {
        borderColor: 'gray.200',
        bg: 'gray.200',
        color: 'gray.500',
      },
    },

    _indeterminate: {
      bg: `${c}.500`,
      borderColor: `${c}.500`,
      color: 'white',
    },

    _disabled: {
      bg: 'gray.100',
      borderColor: 'gray.100',
    },

    _focus: {
      boxShadow: 'outline',
    },

    _invalid: {
      borderColor: 'red.500',
    },
  };
}

const baseStyleLabel = {
  userSelect: 'none',
  _disabled: { opacity: 0.4 },
};

const baseStyleIcon = {
  transitionProperty: 'transform',
  transitionDuration: 'normal',
};

const baseStyle = (props: Record<string, any>) => ({
  icon: baseStyleIcon,
  control: baseStyleControl(props),
  label: baseStyleLabel,
});

const sizes = {
  sm: {
    control: { h: 3, w: 3 },
    label: { fontSize: 'sm' },
    icon: { fontSize: '0.45rem' },
  },
  md: {
    control: { w: 4, h: 4 },
    label: { fontSize: 'md' },
    icon: { fontSize: '0.625rem' },
  },
  lg: {
    control: { w: 5, h: 5 },
    label: { fontSize: 'lg' },
    icon: { fontSize: '0.625rem' },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'blue',
};

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
