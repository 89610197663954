import { useState, useEffect } from 'react';
/**
 * Hook that helps with fetching data from tile events.  Returns loading, error, and retrieved data
 * @param eventData
 * @example const { loading, error, data } = useEventData<SeatCountTextPayload>(eventData);
 */
export const useEventData = (eventData) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState();
    useEffect(() => {
        var _a, _b, _c;
        let mounted = true;
        if (mounted) {
            if (!eventData) {
                setLoading(true);
            }
            if (eventData && ((_a = eventData === null || eventData === void 0 ? void 0 : eventData.body) === null || _a === void 0 ? void 0 : _a.status) === 'fail') {
                setError(true);
            }
            if ((_b = eventData === null || eventData === void 0 ? void 0 : eventData.body) === null || _b === void 0 ? void 0 : _b.payload) {
                setData((_c = eventData === null || eventData === void 0 ? void 0 : eventData.body) === null || _c === void 0 ? void 0 : _c.payload);
                setLoading(false);
            }
        }
        return () => {
            mounted = false;
        };
    }, [eventData]);
    return {
        loading,
        error,
        data,
    };
};
