import { useState } from 'react';
import {
  Avatar,
  AvatarBadge,
  AvatarProps,
  Box,
  Center,
  Icon,
  SkeletonCircle,
  useBreakpointValue,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPencil } from '@fortawesome/pro-solid-svg-icons';

export interface AppAvatarProps extends AvatarProps {}

type ProfileAvatarProps = AppAvatarProps & {
  name?: string;
  bg?: string;
  size?: string;
  isLoading?: boolean;
  src?: string;
  isEditorDisabled?: boolean;
  onClick?: () => void;
};

export const ProfileAvatar = (props: ProfileAvatarProps) => {
  const { isLoading, src, isEditorDisabled, onClick, ...rest } = props;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [edit, setEdit] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <>
      <Box position="relative">
        {isLoading ? (
          <SkeletonCircle size="32" />
        ) : (
          <Avatar
            src={src}
            className="profile__avatar"
            sx={{ cursor: isEditorDisabled ? 'inherit' : 'pointer' }}
            onError={() => setIsError(true)}
            {...rest}
            backgroundColor={src && !isError ? 'transparent' : undefined}
            onMouseEnter={isMobile || isEditorDisabled ? undefined : () => setEdit(true)}
            onMouseLeave={isMobile || isEditorDisabled ? undefined : () => setEdit(false)}
          >
            {isMobile && !isEditorDisabled && (
              <AvatarBadge
                display={['flex', 'none']}
                boxSize="3rem"
                bg="#0099CC"
                borderWidth={5}
                translateY="-100px"
                onClick={() => onClick?.()}
              >
                <Icon
                  width="0.32em"
                  height="0.32em"
                  color="white"
                  as={FontAwesomeIcon}
                  icon={faPencil}
                />
              </AvatarBadge>
            )}
            {edit && !isEditorDisabled && (
              <AvatarOverlay onClick={isMobile ? undefined : () => onClick?.()} />
            )}
          </Avatar>
        )}
      </Box>
    </>
  );
};

export interface AvatarOverlayProps {
  onClick?: () => void;
}

const AvatarOverlay = ({ onClick }: AvatarOverlayProps) => {
  return (
    <Center
      position="absolute"
      backgroundColor="blackAlpha.600"
      width="100%"
      height="100%"
      borderRadius="50%"
      onClick={onClick}
    >
      <Icon as={FontAwesomeIcon} icon={faCamera} color="white" size="xs" />
    </Center>
  );
};
