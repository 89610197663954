import { StyleFunctionProps } from '@companydotcom/potion';

export default {
  Drawer: {
    sizes: {
      sidenav: { dialog: { maxW: ['272px', '344px'] } },
    },
  },
  Tabs: {
    variants: {
      page: (props: any) => {
        const { colorScheme: c } = props;
        return {
          tab: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: '1px',
            borderColor: `${c}.500`,
            color: `${c}.500`,
            _selected: { color: 'white', backgroundColor: `${c}.500` },
            _hover: {
              backgroundColor: `${c}.500`,
              color: 'white',
            },
            _first: {
              borderTopLeftRadius: 'md',
              borderBottomLeftRadius: 'md',
            },
            _last: {
              borderTopRightRadius: 'md',
              borderBottomRightRadius: 'md',
            },
          },
          tabpanel: {
            backgroundColor: ['gray.50', 'white'],
            width: 'full',
            mx: 'auto',
            boxShadow: ['none', 'lg'],
            borderRadius: ['none', 'md'],
            border: '1px solid',
            borderColor: 'gray.100',
            padding: 0,
          },
        };
      },
    },
  },
  Avatar: {
    baseStyle: {
      container: {
        backgroundColor: 'teal.400',
      },
      label: {
        color: 'white',
      },
    },
  },
  Button: {
    variants: {
      tile: (props: StyleFunctionProps) => {
        return {
          ...props?.theme?.components?.Button?.variants?.solid?.(props),
        };
      },
    },
  },
};
