import React, { useState, useEffect } from 'react';
import { Text, Button } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import type { TileComponentProps } from '../../types';

//? NOTE: This component is pretty much the same as TileConditionText, and TileUnderButtonText

export interface TileClickTextProps extends TileComponentProps {}

export const TileClickText: React.FC<TileClickTextProps> = props => {
  const [error, setError] = useState(false);

  const { pubEvents, eventHandler, eventData, componentTheme, errorText, stateCurrent, text } =
    props;
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    eventHandler?.(pubEvents, e);
  };

  useEffect(() => {
    if (
      eventData &&
      eventData.body?.status === 'fail' &&
      eventData.body?.payload &&
      eventData.body?.payload.error
    ) {
      setError(true);
    }
  }, [stateCurrent?.stateName, eventData]);

  if (!eventData || stateCurrent?.stateName === 'pending') {
    return (
      <Text textStyle="sm" sx={componentTheme}>
        {t('tiles.clickText.loading')}
      </Text>
    );
  }

  if (eventData && error) {
    return (
      <Text textStyle="sm" color="red.500" sx={componentTheme}>
        {errorText || t('tiles.clickText.somethingWrong')}
      </Text>
    );
  }

  if (eventData && eventData?.body?.status === 'fail') {
    return (
      <Button fontSize="sm" variant="link" onClick={handleClick} sx={componentTheme}>
        {text || t('tiles.clickText.pleaseUpdate')}
      </Button>
    );
  }

  return null;
};
