import React from 'react';
import { Tabs, TabPanels, TabPanel, useTabStepper } from '@companydotcom/potion';
import { LeadGenForm } from './lead-gen-form';
import { LeadGenSuccess } from './lead-gen-success';

const steps = [
  {
    slug: 'lead-gen-form',
    component: <LeadGenForm />,
  },
  {
    slug: 'lead-gen-confirmation',
    component: <LeadGenSuccess />,
  },
];

export const LeadGen = () => {
  const { tabIndex, handleTabsChange, nextStep, previousStep, goToStep } = useTabStepper({
    steps,
  });

  return (
    <Tabs
      isLazy
      variant="unstyled"
      index={tabIndex}
      onChange={handleTabsChange}
      boxShadow={['none', 'none']}
    >
      <TabPanels>
        {steps.map(step => (
          <TabPanel key={step.slug}>
            {React.cloneElement(step.component, {
              nextStep,
              previousStep,
              goToStep,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
