import React from 'react';
import { Box, Text, Button, Heading, Center, ButtonGroup } from '@companydotcom/potion';
import { CircleExclamationIcon } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';

interface SDProps {
  firstName: string;
  loading: boolean;
  suspendHandler: () => void;
  deleteHandler: () => void;
  suspendDisabled?: boolean;
}

export const SuspendDeleteModal = ({
  firstName,
  loading,
  suspendHandler,
  deleteHandler,
  suspendDisabled,
}: SDProps) => {
  const { t } = useTranslation();
  return (
    <Center bg="white" flexDirection="column" px={4} py={16}>
      <Box maxWidth={[310, 455]} sx={{ textAlign: 'center' }}>
        <CircleExclamationIcon mx="auto" mb={6} />
        <Heading size="hs-2xl">
          {t('miop.userProfile.modals.confirmDelete.header', { firstName })}
        </Heading>

        <Text textStyle="2xl" mx="auto" mt={4}>
          {t('miop.userProfile.modals.confirmDelete.subheader', { firstName })}
        </Text>
      </Box>
      <ButtonGroup
        size="lg"
        flexDirection={['column', null, 'row']}
        mt={10}
        sx={{ textAlign: 'center' }}
        spacing={[0, null, 8]}
      >
        <Button
          variant="outline"
          isLoading={loading}
          isDisabled={loading || suspendDisabled}
          onClick={suspendHandler}
        >
          {t('miop.userProfile.modals.confirmDelete.button1')}
        </Button>

        <Button mt={[8, null, 0]} isLoading={loading} isDisabled={loading} onClick={deleteHandler}>
          {t('miop.userProfile.modals.confirmDelete.button2')}
        </Button>
      </ButtonGroup>
    </Center>
  );
};
