import React from 'react';
import { Box, Text, Button, Heading, Center, Divider } from '@companydotcom/potion';
import { CircleEnvelopeIcon } from '@companydotcom/ui';
import { useTranslation, Trans } from 'react-i18next';

interface AMProps {
  email: string;
  clickHandler: () => void;
}

export const ActivationModal = ({ email, clickHandler }: AMProps) => {
  const { t } = useTranslation();

  return (
    <Center bg="white" flexDirection="column" px={4} py={16}>
      <Box maxWidth={[310, 455]} sx={{ textAlign: 'center' }}>
        <CircleEnvelopeIcon mb={6} mx="auto" />
        <Heading size="hs-2xl">{t('miop.userProfile.modals.activationResent.header')}</Heading>
        <Trans i18nKey="miop.userProfile.modals.activationResent.subheader">
          <Text textStyle="2xl" mt={4} sx={{ wordWrap: 'break-word' }}>
            We sent an email to
            <Button variant="link" userSelect="none" as="span">
              {{ email }}
            </Button>
          </Text>
        </Trans>

        <Divider width={322} sx={{ mx: 'auto', mt: 10, mb: 5 }} />
        <Text textStyle="md" maxWidth={378} mx="auto">
          {t('miop.userProfile.modals.activationResent.copy')}
        </Text>
      </Box>
      <Box mt={10} sx={{ textAlign: 'center' }}>
        <Button size="lg" onClick={clickHandler}>
          {t('common.buttons.gotIt')}
        </Button>
      </Box>
    </Center>
  );
};
