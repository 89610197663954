import React, { useEffect, useState } from 'react';
import { AppSpinner } from '@companydotcom/ui';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../providers';
import { useGetFacecakeColors } from '../../features/facecake/layouts/components/facecake-background-imager';

export interface LocationState {
  referrer?: string;
}

const Callback = () => {
  const location = useLocation();
  const state = (location.state || {}) as LocationState;
  const auth0 = useAuth();
  const [called, setCalled] = useState(false);
  const facecakeSpinnerStyles = useGetFacecakeColors();

  useEffect(() => {
    try {
      if (!called && auth0) {
        setCalled(true);
        auth0?.handleCallback(state?.referrer ?? '/');
      }
    } catch (err) {
      console.log('Error handling callback: ', err);
    }
  }, [auth0, called, location.search, state?.referrer]);

  return (
    <AppSpinner
      className="dashboard-callback__spinner"
      containerStyles={{
        minHeight: '100%',
        flexGrow: 1,
        mt: 3,
      }}
      {...facecakeSpinnerStyles}
    />
  );
};

export default Callback;
