import React, { useCallback, useState } from 'react';
import {
  Center,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  PaginationContainer,
  Pagination,
  PaginationItem,
  HStack,
  usePagination as useTabPagination,
  useBreakpointValue,
  VStack,
  useMediaQuery,
  TableContainer,
  ButtonGroup,
  Tabs,
  useTabStepper,
  Tab,
  TabList,
  Stack,
  TabPanels,
  TabPanel,
  Skeleton,
  Tag,
  Link,
  UseTabStepperReturn,
} from '@companydotcom/potion';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon, AppSpinner } from '@companydotcom/ui';
import type { AcgPurchasedEvent, Maybe } from '@companydotcom/types';
import { isSameMonth, isEventPassed, isSameYear } from '@companydotcom/helpers';
import { useTable, useSortBy, Column, Cell, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { faCaretDown, faUsers, faCaretUp, faCalendarPlus } from '@fortawesome/pro-solid-svg-icons';
import { faCaretDown as faCaretDownLight } from '@fortawesome/pro-light-svg-icons';
import {
  useGetAcgQueryArgs,
  useGetUserPurchasedEventsV2Query,
} from '../../../../services/acg/acg-api-v2';
import { useAppDispatch } from '../../../../hooks';
import { updateActiveEvent } from '../my-events-slice';
import { getFriendlyACGDate, useFormatAcgEventDate, generateIcsUrl } from '../../shared';
import { resetGuestsToRegister } from '../../acg-add-guest/add-guest-slice';

export const MyEventsList = ({ goToStep }: Partial<Pick<UseTabStepperReturn, 'goToStep'>>) => {
  const [isMobile] = useMediaQuery('(max-width: 40em)');
  const args = useGetAcgQueryArgs();

  const { data: purchasedEvents = [], isLoading } = useGetUserPurchasedEventsV2Query(args);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const events = React.useMemo(() => {
    return purchasedEvents;
  }, [purchasedEvents]);

  const handleClick = useCallback(
    (e: any, { row }: Cell<AcgPurchasedEvent, Maybe<string> | undefined>) => {
      e.preventDefault();
      dispatch(resetGuestsToRegister([]));
      dispatch(updateActiveEvent(row.original));
      if (e.target.value === 'attendee') {
        goToStep?.('my-events-attendee-list');
      } else {
        goToStep?.('my-events-edit');
      }
    },
    [dispatch, goToStep],
  );

  const formatDateColumn = (eventStartDate: Date, eventEndDate: Date) => {
    const startDate = getFriendlyACGDate(eventStartDate, 'DD MMM YYYY');
    const endDate = getFriendlyACGDate(eventEndDate, 'DD MMM YYYY');

    if ((startDate && endDate && startDate === endDate) || (startDate && !endDate)) {
      return getFriendlyACGDate(eventStartDate, 'DD MMM YYYY').toUpperCase();
    }

    // If same month
    if (isSameMonth(eventStartDate, eventEndDate)) {
      return `${getFriendlyACGDate(eventStartDate, 'DD')} - ${endDate}`.toUpperCase();
    }

    // If same year
    if (isSameYear(eventStartDate, eventEndDate)) {
      return `${getFriendlyACGDate(eventStartDate, 'DD MMM')} - ${endDate}`.toUpperCase();
    }

    return `${startDate} - ${endDate}`.toUpperCase();
  };

  // Desktop logic
  const columns: Column<AcgPurchasedEvent>[] = React.useMemo(
    () => [
      {
        Header: 'DATE',
        accessor: 'eventStartDate',
        Cell: ({ cell }) =>
          isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Text fontWeight="bold" textAlign="center" textStyle="sm" maxW={75}>
              {cell.row.original.eventStartDate &&
                cell.row.original.eventEndDate &&
                formatDateColumn(
                  cell.row.original.eventStartDate as unknown as Date,
                  cell.row.original.eventEndDate as unknown as Date,
                )}
            </Text>
          ),
      },
      {
        Header: 'EVENT NAME AND LOCATION',
        accessor: 'eventName',
        sortType: 'basic',
        Cell: ({ cell }) => {
          const date = useFormatAcgEventDate({
            eventStartDate: cell.row.original?.eventStartDate as unknown as Date | undefined,
            eventEndDate: cell.row.original?.eventEndDate as unknown as Date | undefined,
            eventTimezone: cell.row.original?.eventTimezone as string | undefined,
            eventTimezoneMode: cell.row.original?.eventTimezoneMode as any,
            eventTimezoneAbbreviation: cell.row.original?.eventTimezoneAbbreviation as
              | string
              | undefined,
          });

          if (isLoading) {
            return <Skeleton height={5} />;
          }
          return (
            <VStack maxW={540} align="flex-start" spacing={2}>
              <Stack direction={['column', 'column', 'row']} alignItems={['left', null, 'center']}>
                <Button
                  value="event"
                  variant="link"
                  onClick={e =>
                    cell.row.original?.onWaitList
                      ? navigate(`/event-registration/?Reg_evt_key=${cell.row.original?.eventId}`)
                      : handleClick(e, cell)
                  }
                  whiteSpace="normal"
                  textAlign="left"
                  fontWeight="bold"
                >
                  <Text lineHeight={5}>{cell?.value ?? ''} </Text>
                </Button>
                {cell.row.original?.onWaitList &&
                  !isEventPassed(cell.row.original?.eventStartDate as unknown as Date) && (
                    <Tag
                      backgroundColor="orange.500"
                      size="sm"
                      color="white"
                      fontWeight="bold"
                      textTransform="uppercase"
                      borderRadius="sm"
                      alignSelf="baseline"
                      minWidth="none"
                      ml={1}
                    >
                      Waitlist
                    </Tag>
                  )}
              </Stack>
              {/* Times can be all null, just start time, or a range */}
              <Box>
                <Text textStyle="sm" className="my-events__event-time">
                  {date}
                </Text>
                {cell.row.original?.eventLocationPlace ? (
                  <Text align="justify">{cell.row.original?.eventLocationPlace}</Text>
                ) : null}
                <Text textStyle="sm" className="my-events__event-location">
                  {cell.row.original?.eventLocation}
                </Text>
              </Box>
              <ButtonGroup spacing={6}>
                {!isEventPassed(cell.row.original?.eventStartDate as unknown as Date) && (
                  <Button
                    variant="link"
                    as={Link}
                    href={generateIcsUrl(cell.row.original as AcgPurchasedEvent)}
                    download={cell.row.original['eventName']}
                    textTransform="uppercase"
                    size="xs"
                    leftIcon={<FontAwesomeIcon boxSize={5} icon={faCalendarPlus} />}
                  >
                    Add to Calendar
                  </Button>
                )}
                {cell.row.original?.listRegistrantsOnWeb && (
                  <Button
                    value="attendee"
                    variant="link"
                    textTransform="uppercase"
                    onClick={e => handleClick(e, cell)}
                    size="xs"
                    leftIcon={<FontAwesomeIcon boxSize={5} icon={faUsers} />}
                  >
                    View Attendee List
                  </Button>
                )}
              </ButtonGroup>
            </VStack>
          );
        },
      },
      {
        Header: 'EVENT TYPE',
        accessor: 'eventTypeName',
        Cell: ({ cell }: { cell: Cell }) =>
          isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Text textStyle="xs" textTransform="uppercase">
              {cell?.value ?? ''}
            </Text>
          ),
      },
    ],
    [handleClick, isLoading, navigate],
  );

  return (
    <>
      {isMobile ? (
        <EventsListMobile data={events ?? []} parentGoToStep={goToStep} isLoading={isLoading} />
      ) : (
        <EventsListTable data={events ?? []} columns={columns} isLoading={isLoading} />
      )}
    </>
  );
};

// Mobile Table View & Logic
export function EventsListMobile({
  data,
  parentGoToStep,
  isLoading,
}: {
  data?: Maybe<AcgPurchasedEvent>[];
  parentGoToStep?: (index: string | number) => void;
  isLoading: boolean;
}) {
  const dispatch = useAppDispatch();
  const steps = [{ slug: 'Upcoming' }, { slug: 'Past' }, { slug: 'Waitlist' }];
  const { tabIndex, handleTabsChange } = useTabStepper({
    steps,
  });

  // Tab data buckets
  const pastEvents = data?.filter(
    event => event?.eventStartDate && isEventPassed(event?.eventStartDate),
  );
  const upComing = data?.filter(
    event => event?.eventStartDate && !isEventPassed(event?.eventStartDate),
  );
  const waitlist = data?.filter(event => event?.onWaitList);

  const selectedStyle = {
    color: 'blue.500',
    bg: 'blackAlpha.50',
    fontWeight: 'bold',
    transition: 'none',
  };

  const handleClick = useCallback(
    (e: any, row: AcgPurchasedEvent) => {
      e.preventDefault();
      dispatch(
        updateActiveEvent({
          eventName: row?.eventName,
          // eventKey: row['eventKey'],
          eventStartDate: row?.eventStartDate,
          eventEndDate: row?.eventEndDate,
        } as AcgPurchasedEvent),
      );
      if (e.target.value === 'event') {
        parentGoToStep?.('my-events-edit');
      } else {
        parentGoToStep?.('my-events-attendee-list');
      }
    },
    [dispatch, parentGoToStep],
  );

  const formatEventDate = (event: AcgPurchasedEvent) => {
    const startDate = event?.eventStartDate && getFriendlyACGDate(event.eventStartDate);
    const endDate = event?.eventEndDate && getFriendlyACGDate(event.eventEndDate);
    const startTime = event?.eventStartDate && getFriendlyACGDate(event.eventStartDate, 'ha');
    const endTime = event?.eventEndDate && getFriendlyACGDate(event.eventEndDate, 'ha');

    // START DATE === END DATE
    if (startDate === endDate) {
      if (startTime && endTime) {
        return `${startDate} ${startTime} - ${endTime}`;
      }

      if (startTime && !endTime) {
        return `${startDate} ${startTime}`;
      }

      return `${startDate}`;
    }

    //START DATE !== END DATE
    if (startDate !== endDate) {
      if (startTime && endTime) {
        return `${startDate} - ${endDate} ${startTime} - ${endTime}`;
      }

      if (startTime && !endTime) {
        return `${startDate} - ${endDate} ${startTime}`;
      }

      return `${startDate} - ${endDate}`;
    }

    if (startDate && !endDate) {
      if (startTime && !endTime) {
        return `${startDate} ${startTime}`;
      }

      if (!startTime && !endTime) {
        return `${startDate}`;
      }
    }

    return `${startDate}`;
  };

  return (
    <>
      {isLoading ? (
        <AppSpinner
          description="Retrieving your registrations..."
          containerStyles={{
            mt: 12,
            mx: 'auto',
          }}
        />
      ) : (
        <Tabs isFitted width="full" variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
          <TabList
            width="full"
            borderRadius="base"
            boxShadow="base"
            className="my-events__tabs-list"
            backgroundColor="white"
            mb={4}
            color="blue.500"
          >
            {steps.map((tab, i) => (
              <Tab key={i} _selected={selectedStyle} fontSize={14} panelid={tab.slug}>
                {tab.slug}
              </Tab>
            ))}
          </TabList>
          <TabPanels textAlign="center">
            <TabPanel>
              {data && upComing?.length ? (
                upComing?.map((event, i) => (
                  <Box
                    width="full"
                    borderRadius="base"
                    boxShadow={['base', 'none']}
                    backgroundColor="white"
                    padding={4}
                    mb={4}
                    key={i}
                  >
                    {isLoading ? (
                      <Skeleton height={5} />
                    ) : (
                      <Stack spacing={2} alignItems="start" textStyle="sm">
                        <Text fontWeight="semibold">{event && formatEventDate(event)}</Text>
                        <Button
                          variant="link"
                          onClick={e => event && handleClick(e, event)}
                          whiteSpace="normal"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize={14}
                        >
                          {event?.eventName}
                        </Button>
                        <Text fontSize="12px" textTransform="uppercase">
                          {event?.eventTypeName}
                        </Text>
                        {event?.eventLocationPlace ? (
                          <Text align="justify">{event?.eventLocationPlace}</Text>
                        ) : null}
                        <Text align="justify">{event?.eventLocation}</Text>
                        <HStack width="full" spacing={7} mt={6}>
                          <Button
                            variant="link"
                            textTransform="uppercase"
                            as={Link}
                            href={event && generateIcsUrl(event)}
                            download={event?.['eventName']}
                            size="xs"
                            leftIcon={<FontAwesomeIcon boxSize={5} icon={faCalendarPlus} />}
                          >
                            Add to Calendar
                          </Button>
                          {event?.listRegistrantsOnWeb && (
                            <Button
                              value="attendee"
                              variant="link"
                              textTransform="uppercase"
                              onClick={e => handleClick(e, event)}
                              size="xs"
                              leftIcon={<FontAwesomeIcon boxSize={5} icon={faUsers} />}
                            >
                              View Attendee List
                            </Button>
                          )}
                        </HStack>
                      </Stack>
                    )}
                  </Box>
                ))
              ) : isLoading ? (
                <Skeleton height={5} />
              ) : (
                <Text>There are no events to display</Text>
              )}
            </TabPanel>
            <TabPanel>
              {data && pastEvents?.length ? (
                pastEvents?.map((event, i) => (
                  <Box
                    width="full"
                    borderRadius="base"
                    boxShadow={['base', 'none']}
                    backgroundColor="white"
                    padding={4}
                    mb={4}
                    key={i}
                  >
                    {isLoading ? (
                      <Skeleton height={5} />
                    ) : (
                      <Stack spacing={2} alignItems="start" textStyle="sm">
                        <Text fontWeight="semibold">{event && formatEventDate(event)}</Text>
                        <Button
                          variant="link"
                          onClick={e => event && handleClick(e, event)}
                          whiteSpace="normal"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize={14}
                        >
                          {event?.eventName}
                        </Button>
                        <Text fontSize="12px" textTransform="uppercase">
                          {event?.eventTypeName}
                        </Text>
                        {event?.eventLocationPlace ? (
                          <Text align="justify">{event?.eventLocationPlace}</Text>
                        ) : null}
                        <Text align="justify">{event?.eventLocation}</Text>
                        <HStack width="full" spacing={7} mt={6}>
                          {event?.listRegistrantsOnWeb && (
                            <Button
                              variant="link"
                              textTransform="uppercase"
                              onClick={e => handleClick(e, event)}
                              size="xs"
                              leftIcon={<FontAwesomeIcon boxSize={5} icon={faUsers} />}
                            >
                              View Attendee List
                            </Button>
                          )}
                        </HStack>
                      </Stack>
                    )}
                  </Box>
                ))
              ) : isLoading ? (
                <Skeleton height={5} />
              ) : (
                <Text>There are no events to display</Text>
              )}
            </TabPanel>
            <TabPanel>
              {data && waitlist?.length ? (
                waitlist?.map((event, i) => (
                  <Box
                    width="full"
                    borderRadius="base"
                    boxShadow={['base', 'none']}
                    backgroundColor="white"
                    padding={4}
                    mb={4}
                    key={i}
                  >
                    {isLoading ? (
                      <Skeleton height={5} />
                    ) : (
                      <Stack spacing={2} alignItems="start" textStyle="sm">
                        <Text fontWeight="semibold">{event && formatEventDate(event)}</Text>
                        <Button
                          variant="link"
                          onClick={e => event && handleClick(e, event)}
                          whiteSpace="normal"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize={14}
                        >
                          {event?.eventName}
                        </Button>
                        <Text fontSize="12px" textTransform="uppercase">
                          {event?.eventTypeName}
                        </Text>
                        {event?.eventLocationPlace ? (
                          <Text align="justify">{event?.eventLocationPlace}</Text>
                        ) : null}
                        <Text align="justify">{event?.eventLocation}</Text>
                        <HStack width="full" spacing={7} mt={6}>
                          <Button
                            variant="link"
                            textTransform="uppercase"
                            to={event && `${generateIcsUrl(event)}`}
                            size="xs"
                            leftIcon={<FontAwesomeIcon boxSize={5} icon={faCalendarPlus} />}
                          >
                            Add to Calendar
                          </Button>
                          {event?.listRegistrantsOnWeb && (
                            <Button
                              variant="link"
                              textTransform="uppercase"
                              onClick={e => handleClick(e, event)}
                              size="xs"
                              leftIcon={<FontAwesomeIcon boxSize={5} icon={faUsers} />}
                            >
                              View Attendee List
                            </Button>
                          )}
                        </HStack>
                      </Stack>
                    )}
                  </Box>
                ))
              ) : isLoading ? (
                <Skeleton height={5} />
              ) : (
                <Text>There are no events to display</Text>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
}

// Desktop Table View & Logic
export function EventsListTable({
  data,
  columns,
  isLoading,
}: {
  columns: Column<AcgPurchasedEvent>[];
  data: Maybe<AcgPurchasedEvent>[];
  isLoading: boolean;
}) {
  const [pageNumber, setPageNumber] = useState(1);
  const showingCount = pageNumber * 10 - 9;
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, pageCount, page, gotoPage } =
    useTable(
      {
        columns,
        data: (data as AcgPurchasedEvent[]) || [],
        initialState: {
          pageSize: 10,
          sortBy: [
            {
              id: 'eventStartDate',
              desc: true,
            },
          ],
        },
      },
      useSortBy,
      usePagination,
    );

  const paginationBreakPoint = useBreakpointValue({ base: 0, md: 1 });

  const { items } = useTabPagination({
    count: pageCount,
    boundaryCount: paginationBreakPoint,
    onChange: (e: any, value: number) => {
      e.preventDefault();
      gotoPage(value - 1);
      setPageNumber(value);
    },
  });

  return (
    <>
      {isLoading ? (
        <Box height="21px" mt={7} mb={4} />
      ) : (
        <Text textStyle="sm" mt={7} mb={4}>
          {`Showing ${!isEmpty(page) ? showingCount : 0} - ${
            !isEmpty(page) ? page.length + showingCount - 1 : 0
          } of ${data?.length}`}
        </Text>
      )}
      <TableContainer>
        <Table {...getTableProps()}>
          <Thead borderWidth="1px" opacity={isLoading ? 0.5 : 1}>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <Th fontSize="sm" {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.Header !== '' ? (
                        <HStack spacing={1}>
                          <Text fontWeight="normal">{column.render('Header')}</Text>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                icon={faCaretDown}
                                boxSize={5}
                                aria-label="sorted descending"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCaretUp}
                                boxSize={5}
                                aria-label="sorted ascending"
                              />
                            )
                          ) : (
                            <FontAwesomeIcon
                              icon={faCaretDownLight}
                              boxSize={5}
                              aria-label="sorted ascending"
                            />
                          )}
                        </HStack>
                      ) : null}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          {isLoading ? (
            <Tbody>
              <Tr>
                <Td border="0" colSpan={3}>
                  <AppSpinner
                    description="Retrieving your registrations..."
                    containerStyles={{
                      mt: 12,
                      mx: 'auto',
                    }}
                  />
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {!data || data.length === 0 ? (
                <Tr borderBottomWidth="1px">
                  <Td
                    whiteSpace="normal"
                    textStyle="md"
                    textAlign="center"
                    py={8}
                    colSpan={columns?.length ?? 5}
                  >
                    There are no events to display
                  </Td>
                </Tr>
              ) : (
                page.map(row => {
                  prepareRow(row);
                  return (
                    <Tr borderBottomWidth="1px" {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <Td whiteSpace="normal" textStyle="sm" py={8} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Td>
                      ))}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!data || data.length <= 10 ? null : (
        <Center mt={6}>
          <Pagination variant="outline" size="sm">
            <PaginationContainer>
              {items.map((item, i) => (
                <PaginationItem key={i} {...item} />
              ))}
            </PaginationContainer>
          </Pagination>
        </Center>
      )}
    </>
  );
}
