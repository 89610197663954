import gql from 'graphql-tag';

export const GENERATE_SIGNED_URL = gql`
  mutation GenerateSignedUrl($bucket: String!, $fileName: String!) {
    generateSignedUrl(bucket: $bucket, fileName: $fileName)
  }
`;

export const DELETE_ITEM_FROM_BUCKET = gql`
  mutation DeleteItemFromBucket($bucket: String!, $fileName: String!) {
    deleteItemFromBucket(bucket: $bucket, fileName: $fileName)
  }
`;
