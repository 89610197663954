export const sortInvoiceLineItems = (invoice: any, onlyLineItemNames?: boolean) => {
  const invoiceCopy = { ...invoice };

  let lineItems = invoiceCopy.lineItem?.slice();

  // sort line items by display name
  if (lineItems && lineItems?.length > 1) {
    lineItems = lineItems?.sort((a: any, b: any) => {
      if (a.lineItemDisplayName < b.lineItemDisplayName) {
        return -1;
      }
      if (a.lineItemDisplayName > b.lineItemDisplayName) {
        return 1;
      }
      return 0;
    });

    //remove transaction fee line item and place it last
    const transactionFee = lineItems?.find((a: any) => a.lineItemDisplayName === 'Transaction Fee');
    const transactionFeeIndex = lineItems?.indexOf(transactionFee);
    if (transactionFee && transactionFeeIndex >= 0) {
      lineItems?.splice(transactionFeeIndex, 1);
      lineItems?.push(transactionFee);
    }
    invoiceCopy.lineItem = lineItems;
  }

  const lineItemNames = lineItems?.map((i: any) => i?.lineItemDisplayName);

  //return sorted list of line item names
  if (onlyLineItemNames) {
    return lineItemNames.join(', ');
  }

  //return invoice with sorted line items
  return invoiceCopy;
};
