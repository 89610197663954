import Input from './input';

const { sizes, defaultProps, variants } = Input;

const parts = ['field', 'icon'];

function baseStyleField() {
  return {
    ...Input.baseStyle.field,
    appearance: 'none',
    paddingBottom: '1px',
    lineHeight: 'normal',

    '> option': {
      bg: 'white',
    },
  };
}

const baseStyleInput = {
  color: 'currentColor',
  fontSize: '1.25rem',
  right: '.5rem',
  _disabled: { opacity: 0.5 },
};

const baseStyle = () => ({
  field: baseStyleField(),
  icon: baseStyleInput,
});

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
