import React, { useEffect } from 'react';
import { useEventData } from '@companydotcom/ui';
import { Flex } from '@companydotcom/potion';
import { PieChart, Pie, Cell, Legend, Label } from 'recharts';
import _sumBy from 'lodash/sumBy';
import ContentLoader from 'react-content-loader';
import type { TileComponentProps } from '../../types';
import { useCarouselContext } from '../tile-carousel';
import { TileText } from '../tile-text';

interface Vulnerability {
  id: string;
  value: number;
  label: string;
  color: string;
}

export interface VulnerabilitiesProps {
  vulnerabilities: Vulnerability[];
}

export interface TileVulnerabilitiesProps extends TileComponentProps {}

export const TileVulnerabilities: React.FC<TileVulnerabilitiesProps> = props => {
  const { eventData } = props;
  const { loading, error, data } = useEventData<VulnerabilitiesProps>(eventData);
  const { disableCarousel, setDisableCarousel } = useCarouselContext();

  const COLORS = ['#cd0909', '#f5843c', '#ffd243', '#24be51'];

  useEffect(() => {
    if (error) {
      setDisableCarousel(true);
    }

    if (data?.vulnerabilities) {
      if (data.vulnerabilities.length === 0) {
        setDisableCarousel(true);
      }
    }
  }, [data?.vulnerabilities, error, setDisableCarousel]);

  if (error || disableCarousel) {
    return (
      <TileText text="This product is currently under construction to provide you with the best experience.  It will be available soon!" />
    );
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Flex
      className="tile__vulnerabilities"
      flexDirection="column"
      flex={1}
      sx={{
        '.recharts-legend-item': {
          fontSize: '14px',
        },
      }}
    >
      <PieChart width={265} height={144}>
        <Pie
          data={data?.vulnerabilities}
          cx={70}
          innerRadius={40}
          outerRadius={70}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data?.vulnerabilities.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label value={_sumBy(data?.vulnerabilities, 'value')} position="center" />
        </Pie>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          fontSize="14px"
          payload={data?.vulnerabilities?.map(item => ({
            id: item.id,
            type: 'circle',
            value: `${item.label}`,
            color: item.color,
          }))}
        />
      </PieChart>
    </Flex>
  );
};

const LegendLoader = () => (
  <ContentLoader
    style={{ marginLeft: 28, marginTop: 24 }}
    speed={2}
    width={82}
    height={98}
    viewBox="0 0 82 88"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="8" cy="8" r="8" />
    <circle cx="8" cy="32" r="8" />
    <circle cx="8" cy="56" r="8" />
    <circle cx="8" cy="80" r="8" />
    <rect x="24" y="0" rx="0" ry="0" width="58" height="16" />
    <rect x="24" y="24" rx="0" ry="0" width="58" height="16" />
    <rect x="24" y="48" rx="0" ry="0" width="58" height="16" />
    <rect x="24" y="72" rx="0" ry="0" width="58" height="16" />
  </ContentLoader>
);

const Loader = () => (
  <Flex flexDirection="row" sx={{ position: 'relative' }}>
    <ContentLoader
      speed={2}
      width={144}
      height={144}
      viewBox="0 0 144 144"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="226" y="64" rx="0" ry="0" width="40" height="8" />
      <rect x="226" y="40" rx="0" ry="0" width="40" height="8" />
      <circle cx="72" cy="72" r="72" />
    </ContentLoader>
    <LegendLoader />
    <svg
      width={75}
      height={75}
      style={{
        position: 'absolute',
        margin: 'auto',
        left: -108,
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        textAlign: 'center',
        zIndex: 1,
      }}
    >
      <circle cx="37.5" cy="37.5" r="37.5" style={{ fill: 'white' }} />
    </svg>
  </Flex>
);
