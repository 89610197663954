import React from 'react';
import { NavigateFunction, RouteObject } from 'react-router-dom';
import { DashboardPreRenderer } from './dashboard-pre-renderer/dashboard-pre-renderer';
import { AccountInformation } from '../pages/account-information';
import { AcgMemberDirectory } from '../pages/acg-member-directory';
import { BusinessListingsProfile } from '../pages/business-listings-profile';
import { ContactPage } from '../pages/contact-page';
import { DarkWebScanPage } from '../pages/dark-web-scan';
import { PasswordManagement } from '../pages/password-management';
import { VerifyCode, VerifyEmail, VerifyPhone } from '../pages/subpages';
import Subpage from '../pages/subpages/subpage';
import { Subscriptions } from '../pages/subscriptions';
import { Tiles } from '../pages/tiles';
import { UserProfile } from '../pages/user-profile';
import { CheckoutPage } from '../pages/acg-checkout';
import { ACGShoppingCartPage } from '../pages/acg-checkout/components/shopping-cart';
import SecuredRoute from './secured-route/secured-route';
import WaitForEnrollment from './wait-for-enrollment';
import { InvoicesPage } from '../pages/acg-invoices';
import { Dashboard } from '../pages/dashboard';
import { MidList } from '../pages/subpages/mid-list';
import { EventRegistrationPage } from '../pages/acg-event-registration';
import { AutoRenewPage } from '../pages/acg-auto-renew';
import { MyEventsPage } from '../pages/acg-my-events';
import { AcgMyProfile } from '../pages/acg-my-profile';
import { SavedPaymentMethodsPage } from '../pages/acg-saved-payment-types';
import { DashboardLayout, OneColumnLayout } from '../components/layouts';
import { AcgRegistration } from '../features/acg/acg-registration';
import { Payment } from '../pages/payment';
import { Redirect } from './redirect/redirect';
import { faceCakeRoutes } from '../features/facecake/routes/facecake-routes';
import { BecomeAMemberPage } from '../pages/acg-become-a-member';

export const protectedRoutes = ({
  setIsResend,
  isResend,
  navigate,
  sourceId,
}: {
  setIsResend: (arg: boolean) => void;
  isResend: boolean;
  navigate: NavigateFunction;
  sourceId: string;
}): RouteObject[] => [
  {
    element: <SecuredRoute />,
    children: [
      {
        path: '/redirect',
        element: <Redirect />,
      },
      {
        // Protected subpage routes
        element: <Subpage />,
        children: [
          {
            element: <WaitForEnrollment />,
            children: [
              {
                path: 'verify',
                element: <VerifyEmail />,
              },
              { path: 'payment', element: <Payment /> },
              {
                path: 'verifycode',
                element: (
                  <VerifyCode
                    boxShadow
                    handleResend={() => {
                      setIsResend(true);
                      navigate('/verifyphone');
                    }}
                  />
                ),
              },
              {
                path: 'verifyphone',
                element: <VerifyPhone isResend={isResend} />,
              },
            ],
          },
          {
            path: 'mid-list',
            element: <MidList />,
          },
        ],
      },
      // Protected dashboard routes
      {
        element: <DashboardPreRenderer />,
        children:
          sourceId === 'icommerce'
            ? faceCakeRoutes
            : [
                {
                  element: <DashboardLayout />,
                  children: [
                    {
                      path: '/',
                      element: <Dashboard />,
                      children: [
                        {
                          index: true,
                          element: <Tiles />,
                        },
                        {
                          path: 'help',
                          element: <ContactPage />,
                        },
                        {
                          path: 'account-information',
                          element: <AccountInformation />,
                        },
                        {
                          path: 'password-management',
                          element: <PasswordManagement />,
                        },
                        {
                          path: 'subscriptions',
                          element: <Subscriptions />,
                        },
                        {
                          path: 'dark-web-scan',
                          element: <DarkWebScanPage />,
                        },
                        {
                          path: 'business-listings-profile',
                          element: <BusinessListingsProfile />,
                        },
                        {
                          path: 'member-directory',
                          element: <AcgMemberDirectory />,
                        },
                        {
                          path: 'join',
                          element: <BecomeAMemberPage />,
                        },
                        {
                          path: 'my-profile',
                          element: sourceId === 'acg' ? <AcgMyProfile /> : <UserProfile />,
                        },

                        {
                          path: 'acg-shopping-cart',
                          element: <ACGShoppingCartPage />,
                        },
                        {
                          path: 'auto-renew',

                          element: <AutoRenewPage />,
                        },
                        {
                          path: 'invoices',
                          element: <InvoicesPage />,
                        },
                        {
                          path: 'event-registration',
                          element: <EventRegistrationPage />,
                        },
                        {
                          path: 'my-events',
                          element: <MyEventsPage />,
                        },
                        {
                          path: 'payment-methods',
                          element: <SavedPaymentMethodsPage />,
                        },
                      ],
                    },
                  ],
                },
                {
                  element: <OneColumnLayout />,
                  children: [
                    {
                      path: 'registration',
                      element: <AcgRegistration />,
                    },
                  ],
                },
                {
                  element: <OneColumnLayout hideFooter />,
                  children: [
                    {
                      path: 'checkout',
                      // ACG checkout page
                      element: <CheckoutPage />,
                    },
                  ],
                },
              ],
      },
    ],
  },
];

// Remove renderModal prop from data collectors
// add path variable to data collectors to companyql and acg-data-collector
// ensure if a user is acg-registered, they cant access that path (should route to "/")
