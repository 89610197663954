import React, { useEffect } from 'react';
import {
  Text,
  Box,
  Button,
  Heading,
  Center,
  Radio,
  RadioGroup,
  Stack,
  ButtonGroup,
  Icon,
  useBoolean,
} from '@companydotcom/potion';
import { useMitt } from '@companydotcom/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { companyConstants, companyHelpers } from '@companydotcom/helpers';
import { Listing } from '@companydotcom/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import {
  InputField,
  ReactSelectFieldAsync,
  useGetCurrentlySelectedCountry,
  PhoneNumberInputField,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import unset from 'lodash/unset';
import { parsePhoneNumber } from 'libphonenumber-js';
import type { GmbSharedProps } from './gmb-profile-flow';
import {
  useUpdateUserMutation,
  useUpdateAccountBusinessNoCityStateMutation,
} from '../../../services/user/user-api';
import {
  useLazyGetLocationAutocompleteResultsQuery,
  useUpdateListingMutation,
  useCreateListingMutation,
  useLazyGetCityStateFromZipCodeQuery,
} from '../../../services/listings/listing-api';
import { useAwaitableFacade } from '../../../hooks';
import yup from '../../../lib/yup';

const getGmbCompleteBusinessProfileSchema = () =>
  yup.object().shape({
    address: yup.string().required('common.inputs.address.error'),
    addressLine2: yup.string(),
    businessCategory: yup.object().shape({
      fullName: yup.string(),
      id: yup.string(),
      name: yup.string(),
      publisher: yup.string().oneOf(['GMB', 'YEXT']),
      __typename: yup.string().notRequired(),
    }),
    businessName: yup.string().required('common.inputs.businessName.error'),
    businessPhone: yup.string().phone().nullable(),

    gmbStatus: yup
      .string()
      .oneOf(['optIn', 'optOut', 'suppress'])
      .required('gmbProfileFlow.stepTwo.inputs.gmbStatus.error'),
    serviceArea: yup
      .array()
      .notRequired()
      .when('visitable', {
        is: (arg: any) => {
          return arg === 'visitable';
        },
        otherwise: yup
          .array()
          .required('gmbProfileFlow.stepTwo.inputs.serviceArea.error')
          .min(1, 'gmbProfileFlow.stepTwo.inputs.serviceArea.error'),
      }),
    visitable: yup
      .string()
      .oneOf(['visitable', 'notVisitable'])
      .required('gmbProfileFlow.stepTwo.inputs.visitableRadioOptions.error'),
    zipCode: yup
      .string()
      .required('common.inputs.postalCode.error')
      .test('zip code', 'common.inputs.postalCode.error', val =>
        companyHelpers.validateZipCode(val),
      ),
  });

export const GmbCompleteBusinessProfile: React.FC<GmbSharedProps> = props => {
  const {
    handleAutocompleteLookup,
    globalUser,
    formValues,
    listingId,
    setListingId,
    setGmbClaimed,
    setGmbFailed,
    setFormValues,
    goToStep,
    previousStep,
  } = props;
  const [updateUser] = useUpdateUserMutation();
  const [updateListing] = useUpdateListingMutation();
  const [createListing] = useCreateListingMutation();
  const [getCityStateFromZipCode] = useLazyGetCityStateFromZipCodeQuery();
  const [getLocationAutocompleteResults] = useLazyGetLocationAutocompleteResultsQuery();
  const [updateAccountBusinessNoCityState] = useUpdateAccountBusinessNoCityStateMutation();
  const [addAreas, setAddAreas] = useBoolean(false);
  const snsInterface = useAwaitableFacade(globalUser, 'listing');
  const { emitter } = useMitt();

  const { country, onCountryChange } = useGetCurrentlySelectedCountry();

  const defaultValues: FieldValues = {
    businessName: globalUser?.account?.businessPrimary?.name || '',
    businessPhone: formValues?.phone
      ? parsePhoneNumber(formValues?.phone, country).number
      : globalUser?.phone
      ? parsePhoneNumber(globalUser?.phone, country).number
      : '',
    address: globalUser?.account?.businessPrimary?.address?.addressLine1 || '',
    zipCode: globalUser?.account?.businessPrimary?.address?.zipCode || '',
    visitable: 'visitable',
    businessCategory:
      globalUser?.account?.businessPrimary?.categories?.filter(
        cat => cat?.publisher === 'GMB',
      )?.[0] ||
      globalUser?.account?.listing?.categories?.[0] ||
      undefined,
    gmbStatus: globalUser?.account?.listing?.gmbStatus || 'optIn',
    serviceArea: globalUser?.account?.listing?.serviceArea?.places?.placeInfos || [],
    addressLine2: '',
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    trigger,
    formState: { isValid, errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getGmbCompleteBusinessProfileSchema()),
    defaultValues: defaultValues,
    context: {
      country,
    },
  });
  const { t } = useTranslation();
  const watchVisitable = watch('visitable');

  useEffect(() => {
    // Revalidate the serviceArea field every time the visitable radio button is pressed
    if (watchVisitable) {
      trigger('serviceArea');
    }
  }, [trigger, watchVisitable]);

  const visitableRadioOptions = [
    {
      label: t('gmbProfileFlow.stepTwo.inputs.visitableRadioOptions.visitable'),
      value: 'visitable',
    },
    {
      label: t('gmbProfileFlow.stepTwo.inputs.visitableRadioOptions.notVisitable'),
      value: 'notVisitable',
    },
  ];

  const gmbStatusOptions = [
    {
      label: t('gmbProfileFlow.stepTwo.inputs.gmbStatus.both'),
      value: 'optIn',
    },
    {
      label: t('gmbProfileFlow.stepTwo.inputs.gmbStatus.directoryOnly'),
      value: 'optOut',
    },
    {
      label: t('gmbProfileFlow.stepTwo.inputs.gmbStatus.none'),
      value: 'suppress',
    },
  ];

  useEffect(() => {
    const areas = globalUser?.account?.listing?.serviceArea?.places?.placeInfos || [];
    if (areas.length) {
      setAddAreas.on();
    }
  }, [setAddAreas, globalUser?.account?.listing?.serviceArea?.places?.placeInfos]);

  const locationOptions = async (value: any) => {
    // const locations = await listingSvc.getLocationAutocompleteResults(value);
    const locations = await getLocationAutocompleteResults({ searchTerm: value })
      .unwrap()
      .catch(error => console.error('Rejected! ', error));
    return locations;
  };
  const categoryOptions = (value: string) => {
    const categories = handleAutocompleteLookup(value, 'GMB');
    return categories;
  };

  const onSubmit = async (values: typeof defaultValues) => {
    if (globalUser) {
      try {
        values.businessPhone = values.businessPhone.replace(/[^0-9]/g, '');
        setFormValues?.(prevState => ({ ...prevState, ...values }));
        const cityStateRes = await getCityStateFromZipCode({ zipCode: values.zipCode }).unwrap();
        const city = cityStateRes?.data?.city?.longName;
        const state = cityStateRes?.data?.state?.shortName;

        /** OLD API */
        // const cityStateRes = await listingSvc.getCityStateFromZipCode(values.zipCode);

        await Promise.all([
          updateUser({
            userId: globalUser?.userId as string,
            firstName: formValues?.firstName,
            lastName: formValues?.lastName,
            phone: getFormattedPhoneNumber(formValues?.phone as string, country ?? 'US'),
          }),
          updateAccountBusinessNoCityState({
            userId: globalUser?.userId,
            accountId: globalUser?.account?.accountId,
            businessName: values.businessName,
            zipCode: values.zipCode,
            city,
            state,
            address: values.address,
            businessId: globalUser?.account?.businessPrimary?.businessId,
            categories: [
              ...(globalUser?.account?.businessPrimary?.categories || [{}])
                .map(cat => {
                  if (cat?.__typename) {
                    // @ts-ignore
                    cat = unset(cat, '__typename');
                  }
                  return cat;
                })
                .filter(({ publisher }: any) => publisher === 'GMB'),
              {
                fullName: values?.businessCategory?.fullName,
                id: values?.businessCategory?.id,
                name: values?.businessCategory?.name,
                publisher: 'GMB',
              },
            ], // having to drop _typename from this],
          }),
        ]);
        const listingInput = {
          accountId: globalUser?.accountId,
          hideAddress: values.visitable === 'notVisitable',
          name: values.businessName,
          address: values.address,
          zip: values.zipCode,
          city,
          state,
          country: 'US',
          categories: [
            ...(globalUser?.account?.businessPrimary?.categories || [{}]).filter(
              ({ publisher }: any) => publisher === 'GMB',
            ),
            {
              fullName: values?.businessCategory?.fullName,
              id: values?.businessCategory?.id,
              name: values?.businessCategory?.name,
              publisher: 'GMB',
            },
          ],
          status: 'CLAIMED',
          phones: [
            {
              type: 'MAIN',
              number:
                values.businessPhone && country
                  ? getFormattedPhoneNumber(values.businessPhone, country)
                  : values.businessPhone ?? '',
            },
          ],
          gmbStatus: values?.gmbStatus === 'suppress' ? 'optOut' : values?.gmbStatus,
          suppress: values?.gmbStatus === 'suppress',
          source: 'companydotcom',
        };
        if (values.addressLine2) {
          listingInput['addressExtra'] = values.addressLine2;
        }
        if (values.serviceArea && values.serviceArea.length) {
          listingInput['serviceArea'] = {
            businessType:
              values.visitable === 'notVisitable'
                ? 'CUSTOMER_LOCATION_ONLY'
                : 'CUSTOMER_AND_BUSINESS_LOCATION',
            places: {
              placeInfos: values.serviceArea.map(({ name, placeId }: any) => ({
                name,
                placeId,
              })),
            },
          };
        }
        if (snsInterface) {
          if (listingId) {
            // @ts-ignore
            delete listingInput.source;
            // @ts-ignore
            delete listingInput.gmbStatus;
            // @ts-ignore
            delete listingInput.suppress;
            // @ts-ignore
            delete listingInput.status;
            // @ts-ignore
            listingInput.id = listingId;

            await updateListing({ listingInput, snsInterface })
              .unwrap()
              .then(listing => {
                switch (listing?.gmbStatus) {
                  case 'optOut':
                    goToStep?.('gmb-success');
                    break;
                  case 'optIn':
                    setGmbFailed?.off();
                    goToStep?.('gmb-verification-options');
                    break;
                  case 'externalClaim':
                    setGmbClaimed?.on();
                    goToStep?.('gmb-success');
                    break;
                  case 'locationCreationFailed':
                    setGmbFailed?.on();
                    goToStep?.('gmb-success');
                    break;
                  default:
                    goToStep?.('gmb-success');
                    break;
                }
                emitter.emit(companyConstants.platformEvents.dataCollectorComplete);
              })
              .catch((error: any) => {
                console.log('Error updating Listing', error);
              });
          } else {
            //@ts-ignore
            await createListing({ listingInput, snsInterface })
              .unwrap()
              .then(({ success, error, data }: { success: boolean; error: any; data: Listing }) => {
                if (success) {
                  setListingId?.(data.id);
                  if (
                    data?.gmbStatus === 'optOut' ||
                    values?.gmbStatus === 'suppress' ||
                    values?.gmbStatus === 'optOut'
                  ) {
                    goToStep?.('gmb-success');
                  } else if (data?.gmbStatus === 'optIn') {
                    if (data.gmbLocationId) {
                      setGmbFailed?.off();
                      goToStep?.('gmb-verification-options');
                    } else {
                      setGmbFailed?.on();
                      goToStep?.('gmb-success');
                    }
                  } else if (data?.gmbStatus === 'externalClaim') {
                    setGmbClaimed?.on();
                    goToStep?.('gmb-success');
                  }
                }
                if (error === 'Directory Duplicate') {
                  console.log('Directory Duplicate');
                  setGmbFailed?.on();
                  goToStep?.('gmb-success');
                }
                emitter.emit(companyConstants.platformEvents.dataCollectorComplete);
              })
              .catch((error: any) => {
                console.log('Error creating listing', error);
              });
          }
        }
      } catch (err) {
        console.warn('Error completeing GMB business profile!: ', err);
      }
    }
  };

  return (
    <Center
      className="gmb-completeBusinessProfile__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Heading as="h1" size="hs-lg">
        {t('gmbProfileFlow.stepTwo.heading')}
      </Heading>
      <Heading as="h2" size="hs-md" mt={[5]}>
        {t('gmbProfileFlow.stepTwo.subheading')}
      </Heading>
      <Text textStyle="md" mt={4}>
        {t('common.misc.allFieldsRequired')}
      </Text>
      <Box
        as="form"
        className="gmb-completeBusinessProfile__form"
        maxWidth={430}
        width="full"
        textAlign="left"
        mt={[8, 10]}
      >
        <InputField
          className="gmb-completeBusinessProfile__businessNameField"
          data-test="gmb-completeBusinessProfile__businessNameField"
          register={register}
          name="businessName"
          label={t('common.inputs.businessName.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <PhoneNumberInputField
          name="businessPhone"
          label={t('common.inputs.businessPhone.label')}
          className="gmb-completeBusinessProfile__businessPhoneField"
          data-test="gmb-completeBusinessProfile__businessPhoneField"
          control={control}
          country={country}
          onCountryChange={onCountryChange}
          errors={errors}
          formControlStyles={{ marginBottom: 6 }}
        />
        <InputField
          className="gmb-completeBusinessProfile__businessStreetAddressField"
          data-test="gmb-completeBusinessProfile__businessStreetAddressField"
          register={register}
          name="address"
          label={t('common.inputs.businessStreetAddress.label')}
          helperText={t('common.inputs.businessStreetAddress.helperText')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="gmb-completeBusinessProfile__addressLine2Field"
          data-test="gmb-completeBusinessProfile__addressLine2Field"
          register={register}
          name="addressLine2"
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="gmb-completeBusinessProfile__businessZipCodeField"
          data-test="gmb-completeBusinessProfile__businessZipCodeField"
          register={register}
          name="zipCode"
          type="number"
          min="0"
          label={t('common.inputs.businessZipCode.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <Heading size="hs-sm" textAlign="left" mb={4}>
          {t('gmbProfileFlow.stepTwo.canCustomersVisit')}
        </Heading>
        <Controller
          name="visitable"
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              className="gmb-completeBusinessProfile__visitableRadio"
              data-test="gmb-completeBusinessProfile__visitableRadio"
            >
              <Stack spacing={2} direction="column">
                {visitableRadioOptions.map(option => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}
        />
        <Button
          className="gmb-completeBusinessProfile__setAddAreasButton"
          data-test="gmb-completeBusinessProfile__setAddAreasButton"
          onClick={setAddAreas.toggle}
          display="inline-flex"
          alignItems="center"
          variant="unstyled"
          borderRadius="none"
          size="sm"
          fontSize={['sm', 'md']}
          py={0}
          mt={7}
          mb={addAreas || watchVisitable === 'notVisitable' ? 1 : 6}
          leftIcon={<Icon as={FontAwesomeIcon} icon={addAreas ? faCircleMinus : faCirclePlus} />}
        >
          {t('gmbProfileFlow.stepTwo.areasServed', {
            watchVisitable: watchVisitable === 'notVisitable' ? '' : ' (optional)',
          })}
        </Button>
        {addAreas || watchVisitable === 'notVisitable' ? (
          <ReactSelectFieldAsync
            isMulti
            className="gmb-completeBusinessProfile__serviceAreaField"
            data-test="gmb-completeBusinessProfile__serviceAreaField"
            control={control}
            name="serviceArea"
            loadOptions={locationOptions}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.placeId}
            errors={errors}
            formControlStyles={{ marginLeft: 5, marginBottom: 6, width: 'auto' }}
          />
        ) : null}
        <ReactSelectFieldAsync
          className="gmb-completeBusinessProfile__businessCategoryField"
          data-test="gmb-completeBusinessProfile__businessCategoryField"
          control={control}
          errors={errors}
          name="businessCategory"
          label={t('common.inputs.businessCategory.label')}
          loadOptions={categoryOptions}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          formControlStyles={{ marginBottom: 6 }}
        />
        <Heading size="hs-sm" textAlign="left" mb={4}>
          {t('gmbProfileFlow.stepTwo.sentWhere')}
        </Heading>
        <Controller
          name="gmbStatus"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <RadioGroup
              {...field}
              className="gmb-completeBusinessProfile__gmbStatusRadio"
              data-test="gmb-completeBusinessProfile__gmbStatusRadio"
            >
              <Stack spacing={2} direction="column">
                {gmbStatusOptions.map(option => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}
        />
      </Box>
      <Box textAlign="center" mt={[8, 12]}>
        <ButtonGroup size="lg">
          <Button
            onClick={() => {
              previousStep?.();
            }}
            className="gmb-completeBusinessProfile__backButton"
            data-test="gmb-completeBusinessProfile__backButton"
            variant="outline"
          >
            {t('common.buttons.back')}
          </Button>

          <Button
            onClick={handleSubmit(onSubmit)}
            className="gmb-completeBusinessProfile__submitButton"
            data-test="gmb-completeBusinessProfile__submitButton"
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >
            {t('gmbProfileFlow.stepTwo.submitButton')}
          </Button>
        </ButtonGroup>
      </Box>
    </Center>
  );
};
