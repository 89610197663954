import React from 'react';
import {
  Box,
  HStack,
  Text,
  Skeleton,
  VStack,
  TextProps,
  BoxProps,
  useStyles,
} from '@companydotcom/potion';
import { useSideNavContext } from './use-side-nav';

export const NavGroup: React.FC<BoxProps> = props => {
  const { children, ...rest } = props;

  return (
    <Box className="sidenav__navGroup" {...rest}>
      {children}
    </Box>
  );
};

export interface NavGroupHeaderProps extends TextProps {
  isLoading?: boolean;
  isSideNavOpen?: boolean;
}

export const NavGroupHeader: React.FC<NavGroupHeaderProps> = props => {
  const styles = useStyles();
  const { children, isLoading, ...rest } = props;
  const { isSideNavOpen } = useSideNavContext();

  return isLoading ? (
    <Skeleton mb={3} width={90} height={6} ml={isSideNavOpen ? [6, 7] : 0} />
  ) : (
    <Text className="sidenav__navGroupHeader" sx={styles.groupHeader} {...rest}>
      {isSideNavOpen ? children ?? '' : null}
    </Text>
  );
};

export const NavGroupLoader: React.FC<any> = props => {
  return (
    // @ts-ignore
    <Box className="potion-sidenav__navGroupLoader" {...props}>
      <VStack spacing={2} align="flex-start">
        <Skeleton>
          <Text>MAIN MENU</Text>
        </Skeleton>
        <VStack align="flex-start" pl={6}>
          <NavLinkSkeleton>Dashboard</NavLinkSkeleton>
          <NavLinkSkeleton>Help</NavLinkSkeleton>
        </VStack>
      </VStack>
      <VStack spacing={2} align="flex-start" mt={12}>
        <Skeleton>
          <Text>SETTINGS</Text>
        </Skeleton>
        <VStack align="flex-start" pl={6}>
          <NavLinkSkeleton>User Profile</NavLinkSkeleton>
          <NavLinkSkeleton>Account Information</NavLinkSkeleton>
          <NavLinkSkeleton>Subscriptions</NavLinkSkeleton>
          <NavLinkSkeleton>Passowrd Management</NavLinkSkeleton>
          <NavLinkSkeleton>Log Out</NavLinkSkeleton>
        </VStack>
      </VStack>
    </Box>
  );
};

function NavLinkSkeleton({ children }: any) {
  return (
    <HStack height="48px">
      <Skeleton>Icon</Skeleton>
      <Skeleton>
        <Text>{children}</Text>
      </Skeleton>
    </HStack>
  );
}
