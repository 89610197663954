import React, { useRef, useMemo, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  IconButton,
  useMediaQuery,
  Divider,
  Tag,
  VStack,
} from '@companydotcom/potion';
import { FontAwesomeIcon, PageDivider } from '@companydotcom/ui';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import type { CheckoutSharedProps } from '..';
import { useAppSelector } from '../../../../hooks';
import {
  getOrderConfirmationData,
  getRenewInvoice,
  // getSelectedPackage,
  getEventForPurchase,
  // getEventDiscount,
} from '../checkout-slice';
import { getCurrency } from '../utils';
import {
  AchPaymentInfo,
  CcPaymentInfo,
} from '../../../../components/forms/checkout/payment-information';
import { formatCheckoutPrice, getEventFeeList, useAcgCartStorage } from '../../shared';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  CheckoutAlertContent,
  CheckoutLayout,
  ItemAndPrice,
  ItemRow,
  OrderConfirmationContentContainer,
  OrderConfirmationHeaderContainer,
  OrderInformationContainer,
  OrderInformationContent,
  OrderInformationHeader,
  OrderSubTotal,
  OrderTotal,
  OrderTotalContainer,
} from '../../../../components/layouts/checkout-layout/checkout-layout';

export const CheckoutOrderConfirmation: React.FC<CheckoutSharedProps> = props => {
  const { onOrderConfirmationComplete } = props;
  const { t } = useTranslation();
  const { data: globalUser } = useGetGlobalUserQuery();

  // const chapterPackage = useAppSelector(getSelectedPackage);
  const renewRejoinInvoice = useAppSelector(getRenewInvoice)?.[0];
  const eventForPurchase = useAppSelector(getEventForPurchase);

  // TODO: phase out these
  const eventOrderConfirmationData = useAppSelector(getOrderConfirmationData)?.events;
  const rhythmOrderConfirmationData = useAppSelector(getOrderConfirmationData);
  // console.log('rhythmOrderConfirmationData :', rhythmOrderConfirmationData);
  const { clearLocalCart, getLocalCart } = useAcgCartStorage();
  const cart = getLocalCart();
  // const eventDiscount = useAppSelector(getEventDiscount);

  const navigate = useNavigate();
  const componentRef = useRef(null);
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // Creates params dynamically (SuperPixel/Feathr)
  useEffect(() => {
    let params;
    if (eventOrderConfirmationData?.length || rhythmOrderConfirmationData?.type !== 'membership') {
      params = { confirmation: 'event' };
    } else {
      params = { confirmation: 'membership' };
    }
    if (search && params) {
      setSearchParams({ ...Object.fromEntries([...searchParams]), ...params });
    } else {
      setSearchParams(params);
    }
  }, [
    eventOrderConfirmationData?.length,
    rhythmOrderConfirmationData?.type,
    search,
    searchParams,
    setSearchParams,
  ]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print {
      #printPageButton, #goToMyACGButton, #accessYourMembership, #headingBorder {
        display: none;
      }
      #mainContentBoxShadow {
        box-shadow: none;
      }
      #itemAndPrice {
        display: inherit;
      }
      #order-content, #paymentInfo, #orderInfoHeader {
        max-width: 600px;
        width: 100%;
        margin: 0.3rem auto;
      }
      .order-content {
        display:flex;
        flex-direction: column;
      }
      #orderConfirmationHeading {
        margin-top: 2rem;
        font-size: 40px;
      }
      #thankyou, #thankyouPart2 {
        font-size: 16px;
      }
      #thankyouPart2 {
        margin: 0;
      }
      #totalContainer {
        align-self: flex-end;
        width: auto;
      }
      #total, #subtotal {
        flex-direction: row;
        width: auto;
        align-items: flex-end;
      }
      #totalText {
        padding-right: 20px;
      }
      #paymentInfoContainer {
        display: flex;
        flex-direction: row;
        width: full;
        justify-content: space-between;
      }
      #methodNameDateContainer, #numberCurrencyContainer {
        width: 50%;
      }
      #method, #name, #date, #methodNumber, #currency, #achRoutingNumber, #achAccountType, #achAccountNumber {
        flex-direction: row;
        white-space: normal;
      }
      p.text {
        padding-left: 3px;
      }
    }`,
  });

  const getPageMargins = () => {
    return `@page { margin: 3rem !important; }`;
  };

  const goToDashboard = () => {
    onOrderConfirmationComplete?.();
    clearLocalCart();
    if (search && !search.includes('confirmation')) {
      navigate({ pathname: '/redirect', search });
    } else {
      navigate('/', { replace: true });
    }
  };

  const goToRedirect = () => {
    onOrderConfirmationComplete?.();
    clearLocalCart();
    const webCode =
      search.toLowerCase().includes('eventregfees') ||
      search.toLowerCase().includes('acgmembershipdirectory') ||
      search.toLowerCase().includes('acgmemberdirectory');
    if (webCode) {
      navigate({ pathname: '/redirect', search });
    } else {
      navigate('/', { replace: true });
    }
  };

  const eventFeeList = useMemo(() => {
    if (eventForPurchase) {
      return getEventFeeList(eventForPurchase);
    }

    return undefined;
  }, [eventForPurchase]);

  const nonMemberFee = useMemo(() => {
    // todo: uncomment this when NetForum is ready for this in production
    // if (eventFeeList) {
    //   return eventFeeList.reduce((acc, cur) => {
    //     const lowerCaseProduct = cur?.product.toLowerCase();
    //     if (
    //       lowerCaseProduct.includes('non-member') ||
    //       lowerCaseProduct.includes('non member') ||
    //       lowerCaseProduct.includes('nonmember')
    //     ) {
    //       acc = acc + 10;
    //     }
    //     return acc;
    //   }, 0);
    // }
    return 0;
  }, []);

  const subtotal = useMemo(() => {
    if (renewRejoinInvoice?.nettToPay) {
      return parseInt(renewRejoinInvoice?.nettToPay, 10);
    }
    if (eventFeeList) {
      return eventFeeList?.reduce((acc: any, cur: any) => {
        acc = acc + cur.price;
        return acc;
      }, 0);
    }
    if (rhythmOrderConfirmationData) {
      return rhythmOrderConfirmationData?.amountTotal;
    }
    return 0;
  }, [eventFeeList, renewRejoinInvoice, rhythmOrderConfirmationData]);

  const paidAndBalance = useMemo(() => {
    if (eventFeeList && eventOrderConfirmationData?.length) {
      const total = eventOrderConfirmationData?.reduce((acc: any, curr: any) => {
        acc = acc + parseInt(curr.paymentInfo.invoiceDetailAmountApplied, 10);
        return acc;
      }, 0);
      return total;
    }

    if (rhythmOrderConfirmationData?.type === 'membership') {
      return rhythmOrderConfirmationData?.amountPaid;
    }
  }, [eventFeeList, eventOrderConfirmationData, rhythmOrderConfirmationData]);

  return (
    <CheckoutLayout id="orderConfirmationLayout">
      <Box width="full" maxW={908} ref={componentRef}>
        <style>{getPageMargins()}</style>
        <OrderConfirmationHeaderContainer>
          <Box className="checkout__checkoutHeader" width="full" textAlign={['center', 'left']}>
            <Heading size={isMobile ? 'hs-md' : 'hs-2xl'}>
              {t(`acg.checkout.orderConfirmationStep.orderConfirmation`)}
            </Heading>
            <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
          </Box>
          <IconButton
            bgColor="white"
            display={['none', 'inherit']}
            aria-label="print"
            icon={<Icon as={FontAwesomeIcon} icon={faPrint} boxSize={6} color="blue.500" />}
            onClick={handlePrint}
            id="printPageButton"
          />
        </OrderConfirmationHeaderContainer>
        <CheckoutAlertContent>
          <Box pb={6}>
            <Heading size="hs-xl" id="thankyou">
              {t(`acg.checkout.orderConfirmationStep.thankYou`, {
                name: `${globalUser?.firstName} ${globalUser?.lastName}`,
              })}
            </Heading>
            {rhythmOrderConfirmationData?.amountTotal !== 0 && (
              <Heading id="thankyouPart2" mt={[4, 0]} size={isMobile ? 'hs-md' : 'hs-lg'}>
                {t(`acg.checkout.orderConfirmationStep.confirmationNumber`, {
                  number: rhythmOrderConfirmationData?.recordKey,
                })}
              </Heading>
            )}
          </Box>
          {rhythmOrderConfirmationData?.amountTotal !== 0 && (
            <Text id="accessYourMembership" textStyle="lg" fontWeight="semibold">
              <Trans i18nKey="acg.checkout.orderConfirmationStep.goToDashboad">
                You can go to your
                <Button
                  fontWeight="semibold"
                  size="lg"
                  whiteSpace="normal"
                  variant="link"
                  onClick={goToDashboard}
                >
                  My ACG Dashboard
                </Button>
                and access your membership.
              </Trans>
            </Text>
          )}
        </CheckoutAlertContent>

        <OrderConfirmationContentContainer>
          <OrderInformationContainer>
            <OrderInformationHeader fontWeight="700" opacity="0.71" />
            <OrderInformationContent>
              {isMobile ? null : <ItemAndPrice />}
              {(cart?.items?.memberships?.[0]?.lineItems?.[0]?.name ||
                renewRejoinInvoice?.displayName) && (
                <ItemRow>
                  <Text textStyle="lg">
                    {cart?.items?.memberships?.[0]?.lineItems?.[0]?.name ||
                      renewRejoinInvoice?.displayName}
                  </Text>
                  <Text textStyle="lg" fontWeight="bold">
                    {`${rhythmOrderConfirmationData?.currencySymbol}${formatCheckoutPrice(
                      cart?.items?.memberships?.[0]?.lineItems?.[0]?.total,
                    )}`}
                  </Text>
                </ItemRow>
              )}
              {eventForPurchase &&
                getEventFeeList(eventForPurchase).map((eventFee: any, i: number) => (
                  <Stack
                    key={i}
                    direction={['column', 'row']}
                    justify="space-between"
                    spacing={[3, 10]}
                  >
                    <Stack
                      direction={['column', 'column', 'row']}
                      alignItems={['left', null, 'center']}
                    >
                      <Text textStyle="lg">{eventFee.productName}</Text>
                      {eventOrderConfirmationData?.[i]?.registrant?.onWaitList && (
                        <Tag
                          backgroundColor="orange.500"
                          size="sm"
                          color="white"
                          fontWeight="bold"
                          textTransform="uppercase"
                          borderRadius="sm"
                        >
                          Waitlist
                        </Tag>
                      )}
                    </Stack>
                    <Text textStyle="lg" fontWeight="bold">{`${getCurrency(
                      rhythmOrderConfirmationData?.currencyCode,
                    )}${formatCheckoutPrice(eventFee.price)}`}</Text>
                  </Stack>
                ))}
              <OrderTotalContainer>
                {isMobile ? null : (
                  <OrderSubTotal>
                    {/* nonMemberFee needs to be added here but it is not in data at the moment EX: nonMemberFee && orderConfirmationData?.invoiceAmount */}
                    <Text textStyle="lg" fontWeight="bold">{`${
                      rhythmOrderConfirmationData?.currencySymbol
                    }${rhythmOrderConfirmationData?.amountTotal.toFixed(2) || subtotal}`}</Text>
                  </OrderSubTotal>
                )}
                {nonMemberFee > 0 && (
                  <Stack spacing={[0, 16]} justify="space-between" direction={['column', 'row']}>
                    <Text textStyle="lg" maxWidth={186} textAlign="left">
                      Non-Member Transaction Fee
                    </Text>
                    <Text textStyle="lg" fontWeight="bold">{`$${nonMemberFee.toFixed(2)}`}</Text>
                  </Stack>
                )}
                {rhythmOrderConfirmationData?.amountDiscount > 0 && (
                  <Stack spacing={[0, 16]} justify="space-between" direction={['column', 'row']}>
                    <Text textStyle="lg" maxWidth={186} textAlign="left">
                      Discounts Applied
                    </Text>
                    <Text textStyle="lg" fontWeight="bold">{`-${
                      rhythmOrderConfirmationData?.currencySymbol
                    }${rhythmOrderConfirmationData?.amountDiscount.toFixed(2)}`}</Text>
                  </Stack>
                )}
                {isMobile ? null : <Divider my={[4, 6]} />}
                <Stack spacing={3}>
                  <OrderTotal>
                    <Heading size="hs-lg">{`${
                      rhythmOrderConfirmationData?.currencySymbol
                    }${rhythmOrderConfirmationData?.amountPaid.toFixed(2)}`}</Heading>
                  </OrderTotal>
                  {eventOrderConfirmationData?.length &&
                    eventOrderConfirmationData?.some(
                      (order: any) => order?.registrant?.onWaitList === '1',
                    ) && (
                      <Stack
                        direction={['column', 'row']}
                        width={['full', 'auto']}
                        justify="space-between"
                        marginTop={3}
                      >
                        <Heading size="hs-lg">PAID</Heading>
                        <Heading size="hs-lg">
                          {`${rhythmOrderConfirmationData?.currencySymbol}${
                            paidAndBalance !== undefined && paidAndBalance.toFixed(2)
                          }`}
                        </Heading>
                      </Stack>
                    )}
                </Stack>
              </OrderTotalContainer>
            </OrderInformationContent>
          </OrderInformationContainer>
          {rhythmOrderConfirmationData?.amountTotal !== 0 || !eventForPurchase ? (
            rhythmOrderConfirmationData?.paymentMethod === 'credit card' ||
            cart?.storedPayment?.cardNumber ? (
              <Box id="paymentInfo">
                <CcPaymentInfo
                  {...{
                    orderConfirmationData: rhythmOrderConfirmationData,
                    goToDashboard,
                    goToRedirect,
                  }}
                  fontWeight="600"
                  opacity="0.71"
                />
              </Box>
            ) : (
              <Box id="paymentInfo">
                <AchPaymentInfo
                  {...{
                    goToDashboard,
                    goToRedirect,
                  }}
                  fontWeight="600"
                  opacity="0.71"
                />
              </Box>
            )
          ) : (
            <VStack spacing={12} mt={12}>
              <Divider display={['none', 'block']} />
              <Button size="lg" onClick={goToDashboard}>
                GO TO MY ACG
              </Button>
            </VStack>
          )}
        </OrderConfirmationContentContainer>
      </Box>
    </CheckoutLayout>
  );
};
