import React from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import {
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  useMediaQuery,
  useTabStepper,
} from '@companydotcom/potion';
import { useSource } from '@companydotcom/providers';
import { useLocation } from 'react-router-dom';
import { ChangeAccountPasswordStep } from './change-account-password-step';
import { EmailPasswordSettingsStep } from './email-password-settings-step';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { MobilePageTabs } from '../../../components/elements';

const passwordManagementSteps = (t: TFunction<'translations', undefined>) => [
  {
    slug: 'change-account-password',
    label: t('miop.passwordManagement.header'),
    component: <ChangeAccountPasswordStep />,
  },
  {
    slug: 'email-password-settings',
    label: t('miop.passwordManagement.email.label'),
    component: <EmailPasswordSettingsStep />,
  },
];

type LocationState = {
  toStep?: string;
};

export const PasswordManagement = () => {
  const source = useSource();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const location = useLocation();

  const steps =
    source.sourceId === 'fis' ||
    source.sourceId === 'officedepot' ||
    source.sourceId === 'pineapple' ||
    source.sourceId === 'paysafe2' ||
    source.sourceId === 'priority2' ||
    source.sourceId === 'priority3' ||
    source.sourceId === 'metabaseq' ||
    source.sourceId === 'acg' ||
    source.sourceId === 'icommerce'
      ? [passwordManagementSteps(t)[0]]
      : passwordManagementSteps(t);

  const state = location.state as LocationState;
  const { tabIndex, handleTabsChange, goToStep } = useTabStepper({
    steps,
    initialStep: state?.toStep,
  });

  return (
    <PageLayout>
      <PageLayoutContent>
        <Tabs variant="page" index={tabIndex} onChange={handleTabsChange}>
          {steps.length > 1 && isMobile ? (
            <MobilePageTabs steps={steps} tabIndex={tabIndex} goToStep={goToStep} />
          ) : (
            steps.length > 1 && (
              <TabList>
                {steps.map(step => (
                  <Tab key={step.slug}>{step.label}</Tab>
                ))}
              </TabList>
            )
          )}
          <TabPanels mt={[0, null, null, 4]} width="full">
            {steps.map(step => (
              <TabPanel key={step.slug}>{React.cloneElement(step.component)}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </PageLayoutContent>
    </PageLayout>
  );
};
