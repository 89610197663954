import React, { useState, useEffect, useCallback } from 'react';
import Script from 'react-load-script';
import {
  Flex,
  Box,
  Text,
  Image,
  Heading,
  VStack,
  Stack,
  Link,
  Button,
  Icon,
  HStack,
} from '@companydotcom/potion';
import { FontAwesomeIcon, AppSpinner } from '@companydotcom/ui';
import { faCircle, faLocationPin } from '@fortawesome/pro-solid-svg-icons';
import { useSource } from '@companydotcom/providers';
import { platformHelpers } from '@companydotcom/helpers';
import { useTranslation } from 'react-i18next';
import { SourceSvc } from '@companydotcom/services';
import _merge from 'lodash/merge';
import { EmptyObject } from '@companydotcom/types';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { openFWWidget, loadFreshworks } from '../../../features/acg/shared';
import { useLazyGetLiveAgentAvailabilityQuery } from '../../../services/product/product-api';
// import { getLiveAgentAvailability } from '../../../services/product/old-api/product-svc';
// import { useGetContactInformationQuery } from '../../../services/source/source-api';

const { getContactInformation } = SourceSvc?.();

// used to merge with data from source, similar to how we do Theme in App.jsx
const defaultContactData = () => ({
  customerSuccessBlock: {
    showChatSupport: true,
    imageUrl: '/dashboard_nextgen/global/images/contact_success_icon.svg',
  },
});

declare global {
  interface Window {
    isLiveagentInitialized: any;
    liveagent: any;
  }
}

export const ContactPage = () => {
  const source = useSource();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [contactData, setContactData] = useState<any | EmptyObject>({});
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [scriptError, setScriptError] = useState(false);
  const [online, setOnlineStatus] = useState<boolean | undefined>(undefined);
  const [isCompanySource, setIsCompanySource] = useState(true);
  const { t } = useTranslation();
  const [getLiveAgentAvailability] = useLazyGetLiveAgentAvailabilityQuery();
  // const { data: contactInfo } = useGetContactInformationQuery(source.sourceId);

  const fetchData = useCallback(async () => {
    const result = await getContactInformation(source.sourceId);

    setContactData(
      _merge(
        defaultContactData(),
        result.data.getContactInformation === null
          ? {}
          : platformHelpers.removeAllNullKeys(result.data.getContactInformation),
      ),
    );
  }, [source.sourceId]);

  let sourceID = source.sourceId;
  switch (sourceID) {
    case 'ipayment':
      sourceID = 'paysafe';
      break;
    case 'company':
      sourceID = 'grandio';
      break;
    case 'priority2':
      sourceID = 'mpp';
      break;
    case 'priority3':
      sourceID = 'bizdash';
      break;
    case 'icommerce':
      sourceID = 'icommerce';
      break;
    default:
  }

  let helpAnchorTag = null;
  if (sourceID === 'paysafe2' || sourceID === 'officedepot') {
    helpAnchorTag = (
      <Link
        as="a"
        mt={[2]}
        href={`mailto:${t('containers.contactPage.customerSuccess.contactEmail')}`}
        sx={{
          textAlign: ['center', null, 'left'],
        }}
      >
        {t('containers.contactPage.customerSuccess.contactEmail')}
      </Link>
    );
  } else if (sourceID === 'dd') {
    helpAnchorTag = (
      <Link
        as="a"
        mt={[2]}
        href={`mailto:'support@company.com`}
        sx={{
          textAlign: ['center', null, 'left'],
        }}
      >
        support@company.com
      </Link>
    );
  } else if (sourceID === 'acg') {
    helpAnchorTag = (
      <Button
        sx={{ cursor: 'pointer' }}
        onClick={() => openFWWidget()}
        className="contact-page__support-button"
      >
        {t('acg.contactPage.getHelpButton')}
      </Button>
    );
  } else {
    helpAnchorTag = (
      <Link
        as="a"
        mt={[2]}
        href={`mailto:${sourceID}-${t('containers.contactPage.customerSuccess.contactEmail')}`}
        sx={{
          textAlign: ['center', null, 'left'],
        }}
      >
        {sourceID}-{t('containers.contactPage.customerSuccess.contactEmail')}
      </Link>
    );
  }

  const handleScriptLoad = () => {
    setScriptLoaded(true);
  };

  const handleScriptError = () => {
    setScriptError(true);
  };

  // Mounts the Freshworks widget for ACG
  useEffect(() => {
    if (sourceID === 'acg') {
      loadFreshworks();
    }
  }, [sourceID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (scriptLoaded && !window.isLiveagentInitialized && globalUser?.userId) {
      const initLiveagent = async () => {
        await window.liveagent.init(
          'https://d.la3-c2-ia4.salesforceliveagent.com/chat',
          '5720a000000XZFl',
          '00Dj0000001u6wy',
        );
        window.liveagent.showWhenOnline('5730a000000XZGS');
        window.liveagent.showWhenOffline('5730a000000XZGS');
        window.liveagent.addCustomDetail('platformUserId', globalUser?.userId);
        window.isLiveagentInitialized = true;
      };

      initLiveagent();
    }
  }, [scriptLoaded, globalUser?.userId]);

  useEffect(() => {
    const liveAgentAvailability = async () => {
      const res = await getLiveAgentAvailability().unwrap();
      if (res === 'true') {
        setOnlineStatus(true);
      } else {
        setOnlineStatus(false);
      }
    };
    liveAgentAvailability();
  }, [getLiveAgentAvailability]);

  useEffect(() => {
    if (source.sourceId === 'company') {
      setIsCompanySource(true);
    } else {
      setIsCompanySource(false);
    }
  }, [source.sourceId]);

  return (
    <Box className="contact-page" layerStyle="formContainer" maxW={1100} mx={0}>
      <Script
        url="https://d.la3-c2-ia4.salesforceliveagent.com/content/g/js/46.0/deployment.js"
        crossorigin
        onLoad={handleScriptLoad}
        onError={handleScriptError}
      />
      <Box pt={[6, 14]} pb={[20]} px={[5]} maxWidth={1176}>
        {source.sourceId ? (
          <Box>
            <VStack spacing={3} flexDirection="column" sx={{ textAlign: 'center' }}>
              <Text
                color={sourceID === 'acg' ? 'orange.500' : 'pricing'}
                textTransform="uppercase"
                textStyle="xs"
                letterSpacing="2.47px"
              >
                {sourceID === 'acg'
                  ? t('acg.contactPage.support')
                  : t('containers.contactPage.caption')}
              </Text>
              <Heading size="hs-2xl">{t('containers.contactPage.heading')}</Heading>
              <Text textStyle="md">{t('containers.contactPage.subheading')}</Text>
            </VStack>
            <Stack
              justifyContent="space-around"
              direction={['column', 'column', 'column', 'row']}
              mt={14}
            >
              <Stack
                direction={['column', null, null, 'row']}
                alignItems={['center', 'center', null, 'flex-start']}
                spacing={6}
              >
                <Image
                  src={`${process.env.REACT_APP_IMG_URL}${contactData?.customerSuccessBlock?.imageUrl}`}
                  width={['56px', null, '96px']}
                />

                <VStack
                  textAlign="left"
                  alignItems={['center', 'center', null, 'flex-start']}
                  spacing={4}
                >
                  <Text fontSize="26px" fontWeight="bold">
                    {sourceID === 'acg'
                      ? t('acg.contactPage.contactUs')
                      : t('containers.contactPage.customerSuccess.heading')}
                  </Text>
                  {!isCompanySource && (
                    <Stack spacing={0}>
                      <Text textStyle="md" mt={[2]} textAlign={['center', null, 'left']}>
                        {t('containers.contactPage.customerSuccess.copy')}
                      </Text>
                      <Text textStyle="md" mt={[2]} textAlign={['center', null, 'left']}>
                        {t('containers.contactPage.customerSuccess.hours')}
                      </Text>
                    </Stack>
                  )}

                  {isCompanySource && (
                    <Box>
                      <Text textStyle="md" mt={[2]} textAlign={['center', null, 'left']}>
                        {t('containers.contactPage.customerSuccess.copy')}
                      </Text>
                      <Text textStyle="md" mt={[2]} textAlign={['center', null, 'left']}>
                        {t('containers.contactPage.customerSuccess.hours')}
                      </Text>

                      {contactData?.customerSuccessBlock?.showChatSupport || !scriptError ? (
                        <ChatSupport online={online} />
                      ) : (
                        ''
                      )}
                    </Box>
                  )}
                  {helpAnchorTag}
                  {sourceID === 'acg' ? (
                    <Link href="https://www.acg.org/membership-tools/find-chapter" target="_blank">
                      <HStack>
                        <Text>Chapter Locator</Text>
                        <Icon as={FontAwesomeIcon} icon={faLocationPin} />
                      </HStack>
                    </Link>
                  ) : !isCompanySource &&
                    t('containers.contactPage.customerSuccess.contactPhone') ? (
                    <Link
                      href={`tel:${t('containers.contactPage.customerSuccess.contactPhone')}`}
                      target=""
                    >
                      {t('containers.contactPage.customerSuccess.contactPhone')}
                    </Link>
                  ) : null}
                </VStack>
              </Stack>
              <VStack
                pl={[0, null, null, 10]}
                spacing={4}
                flexDirection="column"
                alignItems={['center', 'center', null, 'flex-start']}
                borderLeftColor="gray.200"
                borderLeftWidth={[0, null, null, '1px']}
                borderleftstyle="solid"
                pt={[8, null, null, 0]}
              >
                {sourceID === 'acg' ? (
                  <Box textAlign={['center', 'center', null, 'left']}>
                    <Heading size="hs-xl" mb={6}>
                      {t('acg.contactPage.primaryAddress.heading')}
                    </Heading>
                    <Text color="orange.500" textTransform="uppercase" letterSpacing="2.47px">
                      {t('acg.contactPage.primaryAddress.location')}
                    </Text>
                    <Text>{t('acg.contactPage.primaryAddress.addressLine1')}</Text>
                    <Text>{t('acg.contactPage.primaryAddress.addressLine2')}</Text>
                    <Text>{t('acg.contactPage.primaryAddress.addressLine3')}</Text>
                  </Box>
                ) : (
                  <Box>
                    <Heading size="hs-lg">
                      {t('containers.contactPage.primaryAddress.heading')}
                    </Heading>
                    <Text color="pricing" textTransform="uppercase">
                      {t('containers.contactPage.primaryAddress.location')}
                    </Text>
                    <Text>{t('containers.contactPage.primaryAddress.addressLine1')}</Text>
                    <Text>{t('containers.contactPage.primaryAddress.addressLine2')}</Text>
                    <Text>{t('containers.contactPage.primaryAddress.addressLine3')}</Text>
                  </Box>
                )}
                {contactData?.secondaryAddress?.location && (
                  <Box>
                    <Text color="pricing" textTransform="uppercase">
                      {t('containers.contactPage.secondaryAddress.location')}
                    </Text>
                    <Text>{t('containers.contactPage.secondaryAddress.addressLine1')}</Text>
                    <Text>{t('containers.contactPage.secondaryAddress.addressLine2')}</Text>
                    <Text>{t('containers.contactPage.secondaryAddress.addressLine3')}</Text>
                  </Box>
                )}
              </VStack>
            </Stack>
          </Box>
        ) : (
          <AppSpinner my={14} containerStyles={{ width: 'full' }} />
        )}
      </Box>
    </Box>
  );
};

export const ChatSupport = ({ online }: any) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection={['column']}>
      <Flex flexDirection="row" alignItems="center">
        <FontAwesomeIcon
          mr={[2]}
          color={online ? 'green.500' : 'red.500'}
          icon={faCircle}
          boxSize={[2, 3]}
        />
        <Text as="span">
          {t('components.chatSupport.message')}
          {online ? t('components.chatSupport.online') : t('components.chatSupport.offline')}
        </Text>
      </Flex>
    </Flex>
  );
};
