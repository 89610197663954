import React from 'react';
import { Box, Flex, Image, Text, Button, Heading, Stack, Center } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';

const ImageStep = ({
  imageSrc,
  heading,
  subheading,
}: {
  imageSrc: string;
  heading: string;
  subheading: string;
}) => (
  <Box maxWidth={320} px={[4]} py={[5, null, 0]}>
    <Image
      fallback={<Box bg="white" width={320} height={165} />}
      src={imageSrc}
      alt={heading}
      sx={{ textAlign: 'center' }}
    />
    <Heading size="hs-md" mt={2}>
      {heading || ''}
    </Heading>
    <Text textStyle="md" mt={3}>
      {subheading || ''}
    </Text>
  </Box>
);

const gmbSecondChanceSteps = [
  {
    imageSrc:
      'https://assets-stage.company-corp.com/dashboard_nextgen/company/images/illustrations/how-it-works-1.svg',
    heading: 'Step 1: Complete Basic Info',
    subheading:
      'Provide key information about your business to get your business listed in Google My Business. ',
  },
  {
    imageSrc:
      'https://assets-stage.company-corp.com/dashboard_nextgen/company/images/illustrations/how-it-works-2.svg',
    heading: 'Step 2: Verify Your Business',
    subheading:
      'Verify your business by choosing the verification option that your business qualifies for.',
  },
  {
    imageSrc:
      'https://assets-stage.company-corp.com/dashboard_nextgen/company/images/illustrations/how-it-works-3.svg',
    heading: 'Step 3: Keep Everything in Sync',
    subheading:
      'Update your listing here and it will automatically keep in sync with all of your listings.',
  },
];

const firstTimeSteps = [
  {
    imageSrc:
      'https://assets-stage.company-corp.com/dashboard_nextgen/company/images/illustrations/how-it-works-1.svg',
    heading: 'Step 1: Complete Basic Info',
    subheading:
      'Provide key information about your business to get your business online in the Company.com Directory and Google My Business. ',
  },
  {
    imageSrc:
      'https://assets-stage.company-corp.com/dashboard_nextgen/company/images/illustrations/how-it-works-2.svg',
    heading: 'Step 2: Verify Your Business',
    subheading:
      'To publish and verify your listing, choose to verify through phone, text, email, or with help from our Customer Success Team.',
  },
  {
    imageSrc:
      'https://assets-stage.company-corp.com/dashboard_nextgen/company/images/illustrations/how-it-works-3.svg',
    heading: 'Step 3: Keep Everything in Sync',
    subheading:
      'Update your listing here and it will automatically keep in sync with your Google My Business listing. ',
  },
];

interface HowItWorksProps {
  isSecondChanceGmb?: boolean;
  setFirstTimeTileFlow?: (arg: boolean) => void;
  nextStep?: () => void;
}

export const HowItWorks: React.FC<HowItWorksProps> = ({
  isSecondChanceGmb,
  setFirstTimeTileFlow,
  nextStep,
}) => {
  const { t } = useTranslation();
  const steps = isSecondChanceGmb ? gmbSecondChanceSteps : firstTimeSteps;
  return (
    <Center
      className="fttf-how-it-works"
      flexDirection="column"
      pt={[12, null, 10]}
      pb={[12, null, 16]}
      width="full"
      px={4}
    >
      <Box textAlign="center" mb={14}>
        <Heading as="h1" size="hs-xl">
          How It Works
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          Learn how to keep all your listings in sync from the Business Listings Profile.
        </Heading>
      </Box>
      <Flex flexDirection={['column', null, 'row']} flexWrap="wrap" mb={[11]} mt={[13]}>
        {steps.map((step, i) => (
          <ImageStep {...step} key={i} />
        ))}
      </Flex>
      <Stack direction={['column', null, 'row']} mt={12}>
        {isSecondChanceGmb && (
          <>
            <Button
              size="lg"
              variant="outline"
              onClick={() => {
                setFirstTimeTileFlow?.(false);
              }}
            >
              {t('common.buttons.back')}
            </Button>
            <Box my={[2, null, 0]} mx={[0, null, 2]} />
          </>
        )}
        <Button
          size="lg"
          onClick={() => {
            nextStep?.();
          }}
        >
          {isSecondChanceGmb ? 'Continue' : 'Next: Basic Info'}
        </Button>
      </Stack>
    </Center>
  );
};
