import React from 'react';
import {
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  UnorderedList,
  ListItem,
} from '@companydotcom/potion';
import { InputField, CheckboxField } from '@companydotcom/ui';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _isEmpty from 'lodash/isEmpty';

const getEmailProviderSchema = () =>
  yup.object().shape({
    emailDomain: yup
      .string()
      .test(
        'domainOrNoneChecked',
        'forms.emailEligibility.emailProvider.inputs.emailDomain.validation',
        function testEmail(value) {
          return this.parent.noDomainChecked || yup.string().email().isValid(`abc@${value}`);
        },
      ),
    emailProvider: yup.string().required('common.misc.allFieldsRequired'),
    noDomainChecked: yup.boolean(),
  });

interface EmailProviderProps {
  onComplete?: (formData: any, submit: boolean) => void;
  nextStep?: () => void;
}

export const EmailProvider = ({ onComplete, nextStep }: EmailProviderProps) => {
  const defaultValues = {
    emailDomain: '',
    emailProvider: '',
    noDomainChecked: false,
  };
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getEmailProviderSchema()),
    defaultValues,
  });

  const radioOptions = [
    { label: t('forms.emailEligibility.emailProvider.office365'), value: 'office365' },
    { label: t('forms.emailEligibility.emailProvider.gSuite'), value: 'gsuite' },
    { label: t('forms.emailEligibility.emailProvider.other'), value: 'other' },
  ];

  const onSubmit = (values: typeof defaultValues) => {
    onComplete?.(values, false);
    nextStep?.();
  };

  return (
    <Flex
      className="email-provider-form"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      maxWidth="2xl"
      py={[8, 12]}
      px={4}
    >
      <Box sx={{ textAlign: 'center' }} maxWidth={498} width="full">
        <Heading size="hs-xl" mb={3}>
          {t('forms.emailEligibility.emailProvider.heading')}
        </Heading>
        <Heading size="hs-lg" mt={10}>
          {t('forms.emailEligibility.emailProvider.subheading')}
        </Heading>
        <Box
          width="full"
          p={[4, 10]}
          my={6}
          bg="gray.50"
          border="1px solid"
          borderColor="gray.100"
          borderRadius="md"
          textAlign="left"
        >
          <Heading size="hs-md" mb={6}>
            {t('forms.emailEligibility.emailProvider.criteria.heading')}
          </Heading>
          <UnorderedList>
            <ListItem>
              <Text textStyle="md">
                {t('forms.emailEligibility.emailProvider.criteria.listItemOne')}
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="md">
                {t('forms.emailEligibility.emailProvider.criteria.listItemTwo')}
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="md">
                {t('forms.emailEligibility.emailProvider.criteria.listItemThree')}
              </Text>
            </ListItem>
            <ListItem listStyleType="none">
              <Text textStyle="sm" sx={{ opacity: 0.8 }}>
                {t('forms.emailEligibility.emailProvider.criteria.listItemFour')}
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
        <Text textStyle="md" color={!_isEmpty(errors) ? 'red.500' : 'inherit'}>
          {t('common.misc.allFieldsRequired')}
        </Text>
        <Flex
          mx="auto"
          as="form"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt={10}
          maxWidth={328}
          width="full"
          sx={{
            textAlign: 'left',
          }}
        >
          <Text textStyle="lg" mb={5} width="full">
            {t('forms.emailEligibility.emailProvider.emailProvider')}
          </Text>
          <Controller
            name="emailProvider"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                width="full"
                className="inky-emailProviderStep__selectedOptionRadio"
                data-test="inky-emailProviderStep__selectedOptionRadio"
              >
                <Stack spacing={3} direction="column" textAlign="left">
                  {radioOptions.map(option => {
                    return (
                      <Radio key={option.value} value={option.value}>
                        {option.label}
                      </Radio>
                    );
                  })}
                </Stack>
              </RadioGroup>
            )}
          />

          <Text textStyle="lg" width="full" mt={12} mb={4}>
            {t('forms.emailEligibility.emailProvider.emailDomain')}
          </Text>
          <InputField
            register={register}
            name="emailDomain"
            label={t('forms.emailEligibility.emailProvider.inputs.emailDomain.label')}
            errors={errors}
          />
          <CheckboxField
            mt={7}
            className="auth-signUpPage__termsCheckedCheckbox"
            data-test="auth-signUpPage__termsCheckedCheckbox"
            register={register}
            errors={errors}
            name="noDomainChecked"
          >
            {t('forms.emailEligibility.emailProvider.inputs.emailDomain.error')}
          </CheckboxField>
          <Button
            mt={8}
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid || isSubmitting}
          >
            {t('common.buttons.next')}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
