import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Image, Button, Skeleton, Tr, Td, Th, Link } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { useSource } from '@companydotcom/providers';

export const NoInvoices = () => {
  const { t } = useTranslation();
  const source = useSource();
  let sourceID = source.sourceId;
  switch (sourceID) {
    case 'ipayment':
      sourceID = 'paysafe';
      break;
    case 'company':
      sourceID = 'grandio';
      break;
    case 'priority2':
      sourceID = 'mpp';
      break;
    case 'priority3':
      sourceID = 'bizdash';
      break;
    case 'icommerce':
      sourceID = 'icommerce';
      break;
    default:
  }
  let helpAnchorTag = null;
  if (sourceID === 'paysafe2' || sourceID === 'officedepot' || sourceID === 'grandio') {
    helpAnchorTag = (
      <Link
        as="a"
        mt={[2]}
        href={`mailto:${t('containers.contactPage.customerSuccess.contactEmail')}`}
        sx={{
          textAlign: ['center', null, 'left'],
          color: '#0067C5',
          display: 'inline !important',
        }}
      >
        {`${t('containers.contactPage.customerSuccess.contactEmail')}.`}
      </Link>
    );
  } else if (sourceID === 'dd') {
    helpAnchorTag = (
      <Link
        as="a"
        mt={[2]}
        href={`mailto:'support@company.com`}
        sx={{
          textAlign: ['center', null, 'left'],
          color: '#0067C5',
          display: 'inline !important',
        }}
      >
        support@company.com.
      </Link>
    );
  } else {
    helpAnchorTag = (
      <Link
        as="a"
        mt={[2]}
        href={`mailto:${sourceID}-${t('containers.contactPage.customerSuccess.contactEmail')}.`}
        sx={{
          textAlign: ['center', null, 'left'],
          color: '#0067C5',
          display: 'inline !important',
        }}
      >
        {sourceID}-{`${t('containers.contactPage.customerSuccess.contactEmail')}.`}
      </Link>
    );
  }
  return (
    <Flex flexDirection="column" alignItems="center">
      <Image
        src={`${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/invoice.png`}
        width="120px"
        height="160px"
      />
      <Text textStyle="xl" mt={6}>
        {t('miop.invoices.noInvoices.header')}
      </Text>
      <Text maxWidth="575px" mt={[2, null, 3]} textStyle="md" textAlign="center">
        {/*Commented zuora payment form temporarily until a new payment method is introduced */}
        {/* {t('miop.invoices.noInvoices.subheader')} */}
        If you purchase a subscription to any product, your invoices will appear here. Think you’re
        seeing this by mistake?
      </Text>
      <Text display="inline !important" mt={[6, null, 8]}>
        Contact support,{' '}
        <Text color="#0067C5" display="inline !important">
          {helpAnchorTag}
        </Text>
      </Text>
    </Flex>
  );
};

export const Headers = () => {
  const { t } = useTranslation();

  return (
    <Tr>
      <Th>
        <Text textStyle="md" fontWeight="medium">
          {t('miop.invoices.table.invoiceNumber')}
        </Text>
      </Th>

      <Th>
        <Text textStyle="md" fontWeight="medium">
          {t('miop.invoices.table.date')}
        </Text>
      </Th>

      <Th>
        <Text textStyle="md" fontWeight="medium">
          {t('miop.invoices.table.amountBilled')}
        </Text>
      </Th>

      <Th>
        <Text textStyle="md" fontWeight="medium"></Text>
      </Th>
    </Tr>
  );
};

export const HeadersMobile = () => {
  const { t } = useTranslation();

  return (
    <Tr>
      <Th>
        <Text textStyle="sm" fontWeight="medium">
          {t('miop.invoices.table.invoiceNumber')}
        </Text>
      </Th>

      <Th>
        <Text textStyle="sm" textAlign="center" fontWeight="medium">
          {t('miop.invoices.table.amountBilled')}
        </Text>
      </Th>
    </Tr>
  );
};

export const LoadingInvoiceRowMobile = () => (
  <Tr>
    <Td>
      <Skeleton height="24px" width="100%" />
    </Td>
    <Td>
      <Skeleton height="24px" width="100%" />
    </Td>

    <Td>
      <Skeleton height="24px" width="100%" />
    </Td>
  </Tr>
);

export const LoadingInvoiceRow = () => (
  <Tr>
    <Td>
      <Skeleton height="24px" width="100%" />
    </Td>
    <Td>
      <Skeleton height="24px" width="100%" />
    </Td>
    <Td>
      <Skeleton height="24px" width="100%" />
    </Td>
    <Td>
      <Skeleton height="24px" width="100%" />
    </Td>
  </Tr>
);

export interface InvoiceType {
  id: string;
  invoiceNumber: string;
  invoiceDate: string;
  formattedDate?: string;
  amount: string;
  formattedAmount?: string;
  customer_id: string;
  subscription_id: string;
  total: number;
  amount_paid: number;
  first_invoice: Boolean;
  date: number;
  download_url: string;
}

interface InvoiceRowProps {
  invoice: InvoiceType;
  openPdf: (invoice: InvoiceType) => void;
  displayPdf: (invoiceId: string) => void;
  downloadPdf: (invoice: InvoiceType) => void;
  // previewPdf: (invoice: InvoiceType) => void;
  // downloading?: string | undefined;
}

export const InvoiceRowMobile = ({
  invoice,
  openPdf,
}: Omit<InvoiceRowProps, 'downloadPdf' | 'previewPdf' | 'downloading'>) => {
  const { t } = useTranslation();

  return (
    <Tr>
      <Td>
        <Button
          variant="link"
          fontSize="sm"
          onClick={() => {
            openPdf(invoice);
          }}
        >
          {invoice.invoiceNumber}
        </Button>
        <Text textStyle="xs" opacity={0.5}>
          {t('miop.invoices.table.date')}: {invoice.formattedDate}
        </Text>
      </Td>
      <Td>
        <Text textStyle="sm" textAlign="center">
          {invoice.formattedAmount}
        </Text>
      </Td>
    </Tr>
  );
};

export const InvoiceRow = ({
  invoice,
  displayPdf,
  downloadPdf,
}: Omit<InvoiceRowProps, 'openPdf'>) => {
  const { t } = useTranslation();
  function formatNumber(number: any) {
    return (number / 100).toFixed(2);
  }
  const total = formatNumber(invoice.total);
  return (
    <Tr>
      <Td>
        <Button variant="link" onClick={() => displayPdf(invoice.id)}>
          {invoice.id}
        </Button>
      </Td>
      <Td>
        <Text textStyle="md">{dayjs.unix(invoice.date).format('MM/DD/YYYY')}</Text>
      </Td>
      <Td>
        <Text textStyle="md">${total}</Text>
      </Td>
      <Td>
        <Button
          variant="link"
          rightIcon={<FontAwesomeIcon icon={faArrowDownToLine} boxSize={4} />}
          onClick={() => {
            downloadPdf(invoice);
          }}
        >
          {t('common.buttons.download')}
        </Button>
      </Td>
    </Tr>
  );
};
