import Input from './input';
import typography from '../foundations/typography';

const parts = ['root', 'field', 'stepper', 'stepperGroup'];

const { variants, defaultProps } = Input;

const baseStyleRoot = {
  '--number-input-stepper-width': '24px',
  '--number-input-field-padding': 'calc(var(--number-input-stepper-width) + 0.5rem)',
};

const baseStyleField = Input.baseStyle?.field;

const baseStyleStepperGroup = {
  width: 'var(--number-input-stepper-width)',
};

const baseStyleStepper = {
  borderStart: '1px solid',
  borderStartColor: 'inherit',
  color: 'inherit',
  _active: {
    bg: 'gray.200',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
};

const baseStyle = () => ({
  root: baseStyleRoot,
  field: baseStyleField,
  stepperGroup: baseStyleStepperGroup,
  stepper: baseStyleStepper,
});

function getSize(size: 'xs' | 'sm' | 'md' | 'lg') {
  const sizeStyle = Input.sizes[size];

  const radius = {
    lg: 'md',
    md: 'md',
    sm: 'sm',
    xs: 'sm',
  };

  const resolvedFontSize = typography.fontSizes[sizeStyle.field.fontSize];

  return {
    field: {
      ...sizeStyle.field,
      paddingInlineEnd: 'var(--number-input-field-padding)',
      verticalAlign: 'top',
    },
    stepper: {
      fontSize: `calc(${resolvedFontSize} * 0.75)`,
      _first: {
        borderTopEndRadius: radius[size],
      },
      _last: {
        borderBottomEndRadius: radius[size],
        mt: '-1px',
        borderTopWidth: 1,
      },
    },
  };
}

const sizes = {
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg'),
};

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
