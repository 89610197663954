import React from 'react';
import { Flex, Text, Box, Button, Heading, VStack } from '@companydotcom/potion';
import { InputField } from '@companydotcom/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NewUserInformationProps } from '../../../state/reducers/user-profile-reducer';
import { useUserProfileContext } from '../../../providers/user-profile-context';

const NewUserBasicInformationStepSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('common.misc.allFieldsRequired'),
    lastName: yup.string().required('common.misc.allFieldsRequired'),
    personalEmail: yup.string().required('common.misc.allFieldsRequired'),
  });

interface NewUserBasicInformationStepProps {
  goToStep?: (arg: string | number) => void;
}

export const NewUserBasicInformationStep = ({ goToStep }: NewUserBasicInformationStepProps) => {
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(NewUserBasicInformationStepSchema()),
  });
  const { t } = useTranslation();
  const { isSubmitting, isValid, errors } = formState;

  const onSubmit = (data: NewUserInformationProps) => {
    dispatchUserProfileState?.({ type: 'UPDATE_STATE', payload: { newUserInformation: data } });
    // Skip createAUsername step if email is NOT being activated
    if (
      userProfileState?.chosenProducts?.['email_rackspace'] === false ||
      userProfileState?.chosenProducts?.['email_rackspace'] === undefined
    ) {
      goToStep?.('loading');
    } else {
      goToStep?.('create-a-username');
    }
  };

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" pt={14} pb={16} px={4}>
      <Box maxWidth={[310, 455]} sx={{ textAlign: 'center' }}>
        <Heading size="hs-2xl">{t('miop.userProfile.addNewUser.header')}</Heading>
        <Text textStyle="2xl" mt={4}>
          {t('miop.userProfile.addNewUser.basicInformation.subheader')}
        </Text>
        <Text textStyle="md" mt={2} maxWidth={430}>
          {t('miop.userProfile.addNewUser.basicInformation.copy')}
        </Text>
        <Text textStyle="md" mt={4}>
          {t('common.misc.allFieldsRequiredUnless')}
        </Text>
      </Box>
      <Flex as="form" flexDirection="column" width="full" maxWidth={390} mt={6}>
        <VStack spacing={6}>
          <InputField
            register={register}
            name="firstName"
            autoFocus
            label={t('common.inputs.firstName.label')}
            errors={errors}
          />
          <InputField
            register={register}
            name="lastName"
            label={t('common.inputs.lastName.label')}
            errors={errors}
          />
          <InputField
            register={register}
            name="personalEmail"
            label={t('common.inputs.email.altLabel')}
            errors={errors}
          />
        </VStack>
        <Box mt={10} sx={{ textAlign: 'center' }}>
          <Button
            type="submit"
            size="lg"
            // @ts-ignore
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isDisabled={!isValid || isSubmitting || !isEmpty(errors)}
          >
            {t('common.buttons.next')}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
