import * as React from 'react';
import { useCheckbox, UseCheckboxProps } from '../checkbox';
import {
  potion,
  forwardRef,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
  HTMLPotionProps,
} from '../../system';
import { SystemProps, SystemStyleObject } from '../../styled-system';
import { cx, dataAttr, __DEV__ } from '../../utils';

export interface SwitchProps
  extends Omit<UseCheckboxProps, 'isIndeterminate'>,
    Omit<HTMLPotionProps<'label'>, keyof UseCheckboxProps>,
    ThemingProps<'Switch'> {
  /**
   * The spacing between the switch and its label text
   * @default 0.5rem
   * @type SystemProps["marginLeft"]
   */
  spacing?: SystemProps['marginLeft'];
}

export const Switch = forwardRef<SwitchProps, 'input'>((props, ref) => {
  const styles = useMultiStyleConfig('Switch', props);

  const { spacing = '0.5rem', children, ...ownProps } = omitThemingProps(props);

  const { state, getInputProps, getCheckboxProps, getRootProps, getLabelProps } =
    useCheckbox(ownProps);

  const containerStyles: SystemStyleObject = React.useMemo(
    () => ({
      display: 'inline-block',
      verticalAlign: 'middle',
      lineHeight: 0,
      ...styles.container,
    }),
    [styles.container],
  );

  const trackStyles: SystemStyleObject = React.useMemo(
    () => ({
      display: 'inline-flex',
      flexShrink: 0,
      justifyContent: 'flex-start',
      boxSizing: 'content-box',
      cursor: 'pointer',
      ...styles.track,
    }),
    [styles.track],
  );

  const labelStyles: SystemStyleObject = React.useMemo(
    () => ({
      userSelect: 'none',
      marginStart: spacing,
      ...styles.label,
    }),
    [spacing, styles.label],
  );

  return (
    <potion.label
      {...getRootProps()}
      className={cx('potion-switch', props.className)}
      __css={containerStyles}
    >
      <input className="potion-switch__input" {...getInputProps({}, ref)} />
      <potion.span {...getCheckboxProps()} className="potion-switch__track" __css={trackStyles}>
        <potion.span
          __css={styles.thumb}
          className="potion-switch__thumb"
          data-checked={dataAttr(state.isChecked)}
          data-hover={dataAttr(state.isHovered)}
        />
      </potion.span>
      {children && (
        <potion.span className="potion-switch__label" {...getLabelProps()} __css={labelStyles}>
          {children}
        </potion.span>
      )}
    </potion.label>
  );
});

if (__DEV__) {
  Switch.displayName = 'Switch';
}
