import * as React from 'react';
import {
  potion,
  forwardRef,
  HTMLPotionProps,
  omitThemingProps,
  StylesProvider,
  ThemingProps,
  useMultiStyleConfig,
  useStyles,
} from '../../system';
import { cx, __DEV__ } from '../../utils';

export interface TableContainerProps extends HTMLPotionProps<'div'> {}

export const TableContainer = forwardRef<TableContainerProps, 'div'>(
  (props: HTMLPotionProps<'div'>, ref) => {
    const { overflow, overflowX, className, ...rest } = props;
    return (
      <potion.div
        ref={ref}
        className={cx('potion-table__container', className)}
        {...rest}
        __css={{
          display: 'block',
          whiteSpace: 'nowrap',
          WebkitOverflowScrolling: 'touch',
          overflowX: overflow ?? overflowX ?? 'auto',
          overflowY: 'hidden',
          maxWidth: '100%',
        }}
      />
    );
  },
);

export interface TableProps extends HTMLPotionProps<'table'>, ThemingProps<'Table'> {}

export const Table = forwardRef<TableProps, 'table'>((props, ref) => {
  const styles = useMultiStyleConfig('Table', props);
  const { className, ...tableProps } = omitThemingProps(props);

  return (
    <StylesProvider value={styles}>
      <potion.table
        role="table"
        ref={ref}
        __css={styles.table}
        className={cx('potion-table', className)}
        {...tableProps}
      />
    </StylesProvider>
  );
});

if (__DEV__) {
  Table.displayName = 'Table';
}

export interface TableCaptionProps extends HTMLPotionProps<'caption'> {
  /**
   * The placement of the table caption. This sets the `caption-side` CSS attribute.
   * @default "bottom"
   */
  placement?: 'top' | 'bottom';
}

export const TableCaption = forwardRef<TableCaptionProps, 'caption'>((props, ref) => {
  const { placement = 'bottom', ...rest } = props;
  const styles = useStyles();
  return (
    <potion.caption
      {...rest}
      ref={ref}
      __css={{
        ...styles.caption,
        captionSide: placement,
      }}
    />
  );
});

if (__DEV__) {
  TableCaption.displayName = 'TableCaption';
}

export interface TableHeadProps extends HTMLPotionProps<'thead'> {}

export const Thead = forwardRef<TableHeadProps, 'thead'>((props, ref) => {
  const styles = useStyles();
  return <potion.thead {...props} ref={ref} __css={styles.thead} />;
});

export interface TableBodyProps extends HTMLPotionProps<'tbody'> {}

export const Tbody = forwardRef<TableBodyProps, 'tbody'>((props, ref) => {
  const styles = useStyles();
  return <potion.tbody {...props} ref={ref} __css={styles.tbody} />;
});

export interface TableFooterProps extends HTMLPotionProps<'tfoot'> {}

export const Tfoot = forwardRef<TableFooterProps, 'tfoot'>((props, ref) => {
  const styles = useStyles();
  return <potion.tfoot {...props} ref={ref} __css={styles.tfoot} />;
});

export interface TableColumnHeaderProps extends HTMLPotionProps<'th'> {
  /**
   * Aligns the cell content to the right
   */
  isNumeric?: boolean;
}
export const Th = forwardRef<TableColumnHeaderProps, 'th'>(({ isNumeric, ...rest }, ref) => {
  const styles = useStyles();
  return <potion.th {...rest} ref={ref} __css={styles.th} data-is-numeric={isNumeric} />;
});

export interface TableRowProps extends HTMLPotionProps<'tr'> {}
export const Tr = forwardRef<TableRowProps, 'tr'>((props, ref) => {
  const styles = useStyles();

  return <potion.tr role="row" {...props} ref={ref} __css={styles.tr} />;
});

export interface TableCellProps extends HTMLPotionProps<'td'> {
  /**
   * Aligns the cell content to the right
   */
  isNumeric?: boolean;
}
export const Td = forwardRef<TableCellProps, 'td'>(({ isNumeric, ...rest }, ref) => {
  const styles = useStyles();

  return (
    <potion.td role="gridcell" {...rest} ref={ref} __css={styles.td} data-is-numeric={isNumeric} />
  );
});
