import React from 'react';
import { useSource } from '@companydotcom/providers';
import { useAuth } from '../../../../providers';
import { ConfirmationNotification } from './confirmation-notification';
import { AlertNotification } from './alert-notification';
import { useNotifications } from '../../../../providers/notifications-provider';

interface NotificationsControllerProps {
  activateProduct?: any;
  handleClose?: any;
}

export const NotificationsController = (props: NotificationsControllerProps) => {
  const { activateProduct, handleClose } = props;
  const notifications = useNotifications();
  const source = useSource();
  const auth0 = useAuth();
  const confirmations = notifications.filter(item => item.deliveryType === 'confirmation');
  const alerts = notifications.filter(item => item.deliveryType === 'alert');
  const userInfo = auth0?.user;
  const userId = userInfo?.sub?.split('|')[1];
  const role = userInfo?.['https://company.com/user_authorization']?.roles?.[0];

  if (confirmations.length) {
    const target = confirmations[confirmations.length - 1];
    return (
      <ConfirmationNotification
        activateProduct={activateProduct}
        handleClose={handleClose}
        notification={target}
      />
    );
  }

  if (alerts.length) {
    const target = alerts[alerts.length - 1];
    return (
      <AlertNotification
        handleClose={handleClose}
        notification={target}
        userId={userId}
        role={role}
        source={source}
      />
    );
  }

  return null;
};
