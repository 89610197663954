import { keyframes } from '@emotion/react';
import { getColor } from '../../theme-tools';
import type { SystemStyleFunction } from '../../theme-tools';

const fade = (startColor: string, endColor: string) =>
  keyframes({
    from: { borderColor: startColor, background: startColor },
    to: { borderColor: endColor, background: endColor },
  });

const baseStyle: SystemStyleFunction = props => {
  const defaultStartColor = 'gray.100';
  const defaultEndColor = 'gray.400';

  const { startColor = defaultStartColor, endColor = defaultEndColor, speed, theme } = props;

  const start = getColor(theme, startColor);
  const end = getColor(theme, endColor);

  return {
    opacity: 0.7,
    borderRadius: '2px',
    borderColor: start,
    background: end,
    animation: `${speed}s linear infinite alternate ${fade(start, end)}`,
  };
};

export default {
  baseStyle,
};
