// import { useState, useEffect, useCallback } from 'react';
import { Heading, Box, Text, Stack, Flex, StackDivider, Link } from '@companydotcom/potion';
import { FontAwesomeIcon, PageDivider } from '@companydotcom/ui';
import { faMessages, faEnvelope } from '@fortawesome/pro-thin-svg-icons';
// import { platformHelpers } from '@companydotcom/helpers';
// import { EmptyObject } from '@companydotcom/types';
// import { SourceSvc } from '@companydotcom/services';
import { useTranslation } from 'react-i18next';
// import _merge from 'lodash/merge';
import { FaceCakePageLayout } from '../layouts';
// import { useLazyGetLiveAgentAvailabilityQuery } from '../../../services/product/product-api';
// import { useGetGlobalUserQuery } from '../../../services/user/user-api';
// import { ChatSupport } from '../../../pages/contact-page';
// import { useSource } from '@companydotcom/providers';
// import { useScript } from '../../../hooks';

// const { getContactInformation } = SourceSvc?.();

// const defaultContactData = () => ({
//   customerSuccessBlock: {
//     showChatSupport: true,
//     imageUrl: '/dashboard_nextgen/global/images/contact_success_icon.svg',
//   },
// });
// declare global {
//   interface Window {
//     isLiveagentInitialized: any;
//     liveagent: any;
//   }
// }

export const FaceCakeSupport = () => {
  // const source = useSource();
  // const [online, setOnlineStatus] = useState<boolean | undefined>(undefined);
  // const [getLiveAgentAvailability] = useLazyGetLiveAgentAvailabilityQuery();
  // const [contactData, setContactData] = useState<any | EmptyObject>({});
  // const { data: globalUser } = useGetGlobalUserQuery();
  const { t } = useTranslation();

  // const fetchData = useCallback(async () => {
  //   const result = await getContactInformation(source.sourceId);
  //   setContactData(
  //     _merge(
  //       defaultContactData(),
  //       result.data.getContactInformation === null
  //         ? {}
  //         : platformHelpers.removeAllNullKeys(result.data.getContactInformation),
  //     ),
  //   );
  // }, [source.sourceId]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  // const [loading, error] = useScript({
  //   src: 'https://c.la2-c1cs-ia5.salesforceliveagent.com/content/g/js/56.0/deployment.js',
  //   checkForExisting: true,
  // });

  // useEffect(() => {
  //   if (
  //     !loading &&
  //     !window.isLiveagentInitialized &&
  //     globalUser?.userId &&
  //     contactData?.customerSuccessBlock
  //   ) {
  //     const initLiveagent = async () => {
  //       await window.liveagent.init(
  //         contactData?.customerSuccessBlock?.liveChatEndpointUrl,
  //         contactData?.customerSuccessBlock?.liveChatDeploymentId,
  //         contactData?.customerSuccessBlock?.liveChatOrganizationId,
  //       );
  //       window.liveagent.showWhenOnline(contactData?.customerSuccessBlock?.liveChatAgentUserId);
  //       window.liveagent.showWhenOffline(contactData?.customerSuccessBlock?.liveChatAgentUserId);
  //       window.liveagent.addCustomDetail('platformUserId', globalUser?.userId);
  //       window.isLiveagentInitialized = true;
  //     };

  //     initLiveagent();
  //   }
  // }, [
  //   loading,
  //   globalUser?.userId,
  //   contactData?.customerSuccessBlock?.liveChatDeploymentId,
  //   contactData?.customerSuccessBlock?.liveChatOrganizationId,
  //   contactData?.customerSuccessBlock?.liveChatAgentUserId,
  //   contactData?.customerSuccessBlock?.liveChatEndpointUrl,
  //   contactData?.customerSuccessBlock,
  // ]);

  // useEffect(() => {
  //   const liveAgentAvailability = async () => {
  //     const res = await getLiveAgentAvailability().unwrap();
  //     if (res === 'true') {
  //       setOnlineStatus(true);
  //     } else {
  //       setOnlineStatus(false);
  //     }
  //   };
  //   liveAgentAvailability();
  // }, [getLiveAgentAvailability]);

  return (
    <FaceCakePageLayout>
      <Heading size="hs-2xl">{t('icommerce.supportPage.heading')}</Heading>
      <PageDivider width="50px" mt={['4', null, '6']} alignSelf="flex-start" />
      <Stack
        textAlign={['center', null, 'left']}
        mt={['6', null, '12']}
        direction="column"
        spacing={{ base: '6', md: '8' }}
      >
        <Box>
          <Heading fontWeight="bold">{t('icommerce.supportPage.subheading')}</Heading>
          <Text mt={['4', null, '6']}>{t('icommerce.supportPage.description')}</Text>
        </Box>
        <Stack
          direction={['column', null, 'row']}
          divider={<StackDivider orientation="vertical" borderColor="gray.300" />}
          w="full"
        >
          <Stack
            direction={['column', null, 'row']}
            align={['center', null, 'flex-start']}
            spacing={{ base: '2', md: '3' }}
            mr={['0', null, '2']}
            mb={['4', null, '0']}
          >
            <FontAwesomeIcon
              alignSelf={['center', null, 'flex-start']}
              boxSize="8"
              color="pink.500"
              icon={faEnvelope}
            />
            <Flex direction="column">
              <Text fontWeight="bold" fontSize="2xl">
                Contact FaceCake
              </Text>
              <Text>For support with assets and product embeds.</Text>
              <Link
                color="pink.500"
                isExternal
                href={`mailto:${t('icommerce.supportPage.contactEmail')}`}
                mt="3"
              >
                {t('icommerce.supportPage.contactEmail')}
              </Link>
            </Flex>
          </Stack>
          <Stack
            direction={['column', null, 'row']}
            align={['center', null, 'flex-start']}
            spacing={{ base: '2', md: '3' }}
            ml={['0', null, '2']}
            mt={['4', null, '0']}
          >
            <FontAwesomeIcon
              alignSelf={['center', null, 'flex-start']}
              boxSize="10"
              color="pink.500"
              icon={faMessages}
            />
            <Flex direction="column" mt="8">
              <Text fontWeight="bold" fontSize="2xl">
                {t('icommerce.supportPage.leftColumn.heading')}
              </Text>
              <Text mt="2" maxW="388">
                {t('icommerce.supportPage.leftColumn.subheading')}
              </Text>
              <Text>{t('icommerce.supportPage.leftColumn.hours')}</Text>
              {/* <Box mt="6">
                <Button
                  size="lg"
                  width={['full', 'inherit']}
                  mb={2}
                  onClick={() => {
                    if (online) {
                      window.liveagent.startChat(
                        contactData?.customerSuccessBlock?.liveChatAgentUserId,
                      );
                    } else {
                      platformHelpers.centerNewWindow(
                        'https://company.secure.force.com/PreChatForm/apex/GenericOfflineChatForm?Source=facecake',
                        'Support',
                        600,
                        400,
                      );
                    }
                  }}
                >
                  CHAT WITH A MEMBER
                </Button>
              </Box> */}

              {/* {contactData?.customerSuccessBlock?.showChatSupport || !error ? (
                <ChatSupport online={online} />
              ) : (
                ''
              )} */}
              <Link
                color="pink.500"
                isExternal
                href={'mailto:facecake-support@company-corp.com'}
                mt="3"
              >
                facecake-support@company-corp.com
              </Link>
            </Flex>
          </Stack>
        </Stack>
      </Stack>
    </FaceCakePageLayout>
  );
};
