import {
  ExtendedSubscriptionProduct,
  User,
  AccountProduct,
  ExtendedSubscriptionRatePlan,
  ExtendedSubscriptionTier,
  Maybe,
  ExtendedSubscriptionStatus,
} from '@companydotcom/types';
import { sumBy, isEmpty, isNumber } from 'lodash';
import {
  calculateEstimatedRatePlanPrice,
  processAllRatePlanTiers,
} from '../../../utils/helpers/purchasing-helpers';
// import { getUserProducts } from '../../../services/product/old-api/product-svc';

/**
 *
 * @param product - The product to process
 * @param userAccountProducts - The list of products from the User object
 * @returns - Returns a processed product
 */
export const processProductRatePlans = (
  product: ExtendedSubscriptionProduct,
  userAccountProducts?: Maybe<Array<Maybe<AccountProduct>>>,
) => {
  product.ratePlans = product?.ratePlans?.filter(plan => plan?.name !== 'None');

  if (product?.ratePlans?.every(plan => !plan?.isActive)) {
    const relevantAccountProduct = userAccountProducts?.find(
      prod => prod?.productId === product.productId,
    );

    if (relevantAccountProduct) {
      const activeRatePlan = product.ratePlans.find(
        plan => plan?.name === relevantAccountProduct.ratePlanName,
      );
      if (activeRatePlan) {
        activeRatePlan.isActive = true;
      }
    } else if (product.ratePlans) {
      const includedRatePlan = product.ratePlans.find(
        plan => plan?.includedInMembership || plan?.price === 0,
      );
      if (includedRatePlan) {
        includedRatePlan.isActive = true;
      }
    }
  }

  // need to digest ratePlan features unless its a package

  if (product.productId !== 'PACKAGE_OPTIONS_SUMMARY') {
    product?.ratePlans?.reduce(
      (acc, ratePlan) => {
        if (acc.ratePlanNames.length && ratePlan) {
          ratePlan.featurePrefix = `Everything in ${acc.ratePlanNames.pop()}:`;
          ratePlan.features = ratePlan?.features?.filter(
            feature => feature && !acc.uniqueFeatures?.includes(feature),
          );
        }

        // Find the lowest price across all rate plans, assign it to the product to display
        product.minPrice =
          product.minPrice && ratePlan?.price && ratePlan?.price > product.minPrice
            ? product.minPrice
            : ratePlan?.price;

        if (ratePlan?.features && ratePlan.name && acc) {
          acc.uniqueFeatures = [...acc.uniqueFeatures, ...ratePlan.features];
          acc.ratePlanNames.push(ratePlan.name);
        }
        return acc;
      },
      {
        uniqueFeatures: [] as Array<Maybe<string>>,
        ratePlanNames: [] as string[],
      },
    );
  }

  return product;
};

/**
 * Processes data specific to the Current Subscriptions tab in MIOP
 * @param userProducts
 * @returns - Returns an array of formatted products
 */
const processDataForCurrentSubscription = (userProducts: ExtendedSubscriptionProduct[]) => {
  const formattedProducts = userProducts
    .filter(({ ratePlans }) => ratePlans?.find(plan => plan?.isActive && plan?.name !== 'None'))
    .map(product => {
      if (product.ratePlans && product?.ratePlans?.length === 1 && product.ratePlans[0]) {
        product.ratePlans[0].isActive = true;
      }

      return product;
    });

  return formattedProducts;
};

/**
 * Processes data specific to the Available Subscriptions tab in MIOP
 * @param userProducts
 * @returns - Returns an array of formatted products
 */
const processDataForAllAvailable = (userProducts: ExtendedSubscriptionProduct[]) => {
  const formattedProducts = userProducts.filter(
    ({ stateCurrent, ratePlans, tileId }: any) => {
      return (
        ((stateCurrent !== 'active' && stateCurrent !== 'inactive') || !tileId) && // not tile id catches premium package
        ratePlans.every(
          ({ isActive, includedInMembership, name }: any) =>
            !isActive || (includedInMembership && name === 'None'),
        )
      );
    },
    // products with multiple tiles (e.g. blm) can return "buy" state current for one tile while the product itself is active
  );
  return formattedProducts;
};

/**
 * Gets all products that a user has, and processes them into two categories:
 * products the user has, and products that the user can purchase
 * @param user - The current user object
 * @returns - Returns an array of formatted products and the users current monthly subscription price
 */
export const getAllSubscriptionData = async (
  user: User,
  userProducts?: ExtendedSubscriptionStatus,
) => {
  // const userProducts = await getUserProducts(user?.userId, locale);

  const processedUserProduct = {
    ...userProducts,
    products: userProducts?.products?.map(
      product => product && processAllRatePlanTiers<ExtendedSubscriptionProduct>(product),
    ),
  };

  const userProductsProcessedRatePlans = processedUserProduct?.products?.map(
    product => product && processProductRatePlans(product, user.account?.Products),
  ) as ExtendedSubscriptionProduct[];

  return {
    currentSubscriptionProducts: processDataForCurrentSubscription(userProductsProcessedRatePlans),
    availableProducts: processDataForAllAvailable(userProductsProcessedRatePlans),
    currentSubscriptionPrice: userProducts?.monthlyPrice,
  };
};

export const getInitialPlanPrice = (product: ExtendedSubscriptionProduct) => {
  // if its a tiered product
  if (
    !isEmpty(product.ratePlans) &&
    product?.ratePlans?.find(ratePlan => ratePlan?.isActive)?.tiers?.find(tier => tier?.isActive)
  ) {
    return calculateEstimatedRatePlanPrice(
      product?.ratePlans?.find(ratePlan => ratePlan?.isActive) as ExtendedSubscriptionRatePlan,
      product?.ratePlans
        ?.find(ratePlan => ratePlan?.isActive)
        ?.tiers?.find(tier => tier?.isActive) as ExtendedSubscriptionTier,
    );
  }

  if (!isEmpty(product.ratePlans) && product?.ratePlans?.find(ratePlan => ratePlan?.isActive)) {
    return product?.ratePlans.find(ratePlan => ratePlan?.isActive)?.price;
  }

  return 0;
};

export const getEstimatedPremiumProductPrice = (
  userProducts: any,
  product: any,
  allProducts: any,
) => {
  const foundProducts = product.packageProducts.filter((p: any) => {
    return userProducts.find((x: any) => {
      return x.productId === p.productId;
    });
  });

  const foundPrice = allProducts.filter((y: any) => {
    return foundProducts.find((j: any) => {
      return j.productId === y.productId;
    });
  });
  //@ts-ignore
  return sumBy(foundPrice, o => o.minPrice);
};

export const determineIfRatePlanDisabled = (
  plan: ExtendedSubscriptionRatePlan,
  index: number,
  selectedTiers?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>> | [],
  ratePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
  product?: Maybe<ExtendedSubscriptionProduct>,
) => {
  const hasTiers = ratePlans?.some(plan => plan?.tiers && plan.tiers.length > 0);
  const totalRatePlans = ratePlans?.length || 1;
  const nextRatePlan = ratePlans?.[index + 1];
  // disabled handling for tiered products
  if (hasTiers) {
    // Disable all rate plans until a tier is selected
    const hasMissingTier =
      isEmpty(selectedTiers?.find(tier => tier?.productName === product?.name)) && !plan.isActive;

    const isEnterpriseTier = selectedTiers?.some(
      tier => tier?.isEnterprise && tier.productName === product?.name,
    );
    const hasFreeUpgrade =
      !selectedTiers?.some(tier => tier?.isEnterprise) &&
      selectedTiers?.some(
        tier =>
          tier?.ratePlanId !== plan.ratePlanId &&
          tier?.productName === product?.name &&
          isNumber(tier?.qty) &&
          tier?.qty &&
          tier?.qty > 10000,
      );

    // Disable handling for products with multiple rate plans and tiers - example, Email

    if (totalRatePlans <= 1) {
      if (isEnterpriseTier || hasFreeUpgrade || hasMissingTier) {
        return true;
      }

      return false;
    }

    // Disable handling for products with multiple rate plans and tiers - example, Email Marketing
    if (totalRatePlans > 1 && ratePlans) {
      if (isEnterpriseTier || hasFreeUpgrade || hasMissingTier || nextRatePlan?.isActive) {
        return true;
      }

      return false;
    }
  }

  // every other product that isnt tiered
  if (
    // if a plan is active, disable all plans below because users cannot downgrade without contacting customer service
    (ratePlans &&
      totalRatePlans > 1 &&
      nextRatePlan &&
      index < ratePlans.findIndex(plan => plan?.isActive)) ||
    (ratePlans && totalRatePlans > 1 && nextRatePlan?.isActive)
  ) {
    return true;
  }

  return false;
};
