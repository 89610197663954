import * as React from 'react';
import {
  potion,
  forwardRef,
  omitThemingProps,
  ThemingProps,
  useStyleConfig,
  HTMLPotionProps,
} from '../../system';
import { cx, __DEV__ } from '../../utils';

export interface LinkProps extends HTMLPotionProps<'a'>, ThemingProps<'Link'> {
  /**
   *  If `true`, the link will open in new tab
   */
  isExternal?: boolean;
}

/**
 * Links are accessible elements used primarily for navigation.
 *
 * It integrates well with other routing libraries like
 * React Router, Reach Router and Next.js Link.
 *
 * @example
 *
 * ```jsx
 * <Link as={ReactRouterLink} to="/home">Home</Link>
 * ```
 *
 * @see Docs https://chakra-ui.com/link
 */
export const Link = forwardRef<LinkProps, 'a'>((props, ref) => {
  const styles = useStyleConfig('Link', props);
  const { className, isExternal, ...rest } = omitThemingProps(props);

  return (
    <potion.a
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      ref={ref}
      className={cx('potion-link', className)}
      {...rest}
      __css={styles}
    />
  );
});

if (__DEV__) {
  Link.displayName = 'Link';
}
