export default {
    formContainer: {
        backgroundColor: 'white',
        width: 'full',
        mx: 'auto',
        boxShadow: 'lg',
        borderRadius: 'md',
        border: '1px solid',
        borderColor: 'gray.100',
    },
};
