import { isObject } from '../utils';
import { CocoTheme } from './theme.types';

export const requiredCocoThemeKeys: (keyof CocoTheme)[] = [
  'breakpoints',
  'colors',
  'components',
  'fonts',
  'fontSizes',
  'fontWeights',
  'letterSpacings',
  'lineHeights',
  'radii',
  'shadows',
  'sizes',
  'space',
  'transition',
  'zIndices',
];

export function isCocoTheme(unit: unknown): unit is CocoTheme {
  if (!isObject(unit)) {
    return false;
  }

  return requiredCocoThemeKeys.every(propertyName =>
    Object.prototype.hasOwnProperty.call(unit, propertyName),
  );
}
