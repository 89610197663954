import React from 'react';
import { Box, Flex } from '@companydotcom/potion';
import { Outlet } from 'react-router-dom';
import { FaceCakeTopRail } from './components/facecake-top-rail';
import { AppSpinner } from '@companydotcom/ui';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';

export const FaceCakeLayout = () => {
  const { data: globalUser } = useGetGlobalUserQuery({
    refetchOnMountOrArgChange: true,
  });

  return !globalUser || globalUser?.userDataCollectors?.some?.(dc => dc?.path) ? (
    <AppSpinner
      className="facecake_layout_spinner"
      containerStyles={{
        minHeight: '100vh',
        flexGrow: 1,
      }}
    />
  ) : (
    <Box minH="100vh">
      <Flex flexDirection="column">
        <FaceCakeTopRail />
        <Outlet />
      </Flex>
    </Box>
  );
};
