import { createBreakpoints } from '../../theme-tools';

/**
 * Breakpoints for responsive design
 */
const breakpoints = createBreakpoints({
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '2xl': '96em', // 1536px
});

export default breakpoints;
