import React from 'react';
import { ButtonGroup, Box, Text, Button, Heading, Center, Link, Icon } from '@companydotcom/potion';
import { faPhoneVolume } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import type { GmbSharedProps } from './gmb-profile-flow';

export const CallingYourPhone: React.FC<GmbSharedProps> = ({ selectedVerification, goToStep }) => {
  const { t } = useTranslation();

  const resendCall = () => {
    console.log('NEEDS HOOKED UP');
  }; // need backend hookup

  return (
    <Center
      className="gmb-callingYourPhone__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Icon
        mx="auto"
        color="green.500"
        as={FontAwesomeIcon}
        boxSize={[14, 28]}
        icon={faPhoneVolume}
        mb={[5, null, 8]}
      />
      <Box maxW="lg">
        <Heading as="h1" size="hs-2xl">
          {t('gmbProfileFlow.callingYourPhone.heading')}
        </Heading>
        <Trans
          i18nKey="gmbProfileFlow.callingYourPhone.subheading"
          values={{
            selectedVerification:
              selectedVerification === 'CALL_ME'
                ? 'schedule a time to talk with a Customer Success Representative.'
                : 'resend the phone call.',
          }}
        >
          <Text textStyle={['lg', 'xl']} mt={[5]}>
            You should receive a phone call to the number listed in your business profile in the
            next business day. If you do not receive a phone call, check your phone number in the
            last step or
            <Button
              variant="link"
              fontSize={['md', 'lg']}
              onClick={() => {
                return selectedVerification === 'CALL_ME' ? goToStep?.(6) : resendCall;
              }}
            >
              {selectedVerification}
            </Button>
          </Text>
        </Trans>
      </Box>
      <ButtonGroup size="lg" mt={12} mb={['3rem', '5rem']}>
        <Button
          variant="outline"
          onClick={() => {
            goToStep?.(2);
          }}
        >
          {t('common.buttons.back')}
        </Button>

        <Button
          onClick={() => {
            return selectedVerification === 'CALL_ME' ? goToStep?.(4) : goToStep?.(2);
          }}
        >
          {t('gmbProfileFlow.callingYourPhone.successButton')}
        </Button>
      </ButtonGroup>

      <Trans i18nKey="gmbProfileFlow.callingYourPhone.needHelp">
        <Text textStyle="sm">
          Need Help?
          <Link as={NavLink} to="/help">
            Contact Support
          </Link>
        </Text>
      </Trans>
    </Center>
  );
};
