import React from 'react';
import {
  Image,
  Text,
  Center,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
} from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';

export interface ConfirmationNotificationProps {
  activateProduct: any;
  handleClose: any;
  notification: any;
}

export const ConfirmationNotification: React.FC<ConfirmationNotificationProps> = props => {
  const { notificationType, id: notificationId, productId } = props.notification;
  const { productIcon, title, lines } = props.notification.body;
  const { t } = useTranslation();

  return notificationType === 'invitation' ? (
    <Modal size="3xl" isOpen onClose={() => props.handleClose(notificationId)}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Center flexDirection="column" width={708} mt="56px" mx={4} mb="40px">
          <Image src={productIcon || '/images/directory.svg'} alt="product icon" width={75} />
          <Heading size="hs-2xl" m={4}>
            {title}
          </Heading>
          {lines
            ? lines.map((line: string, i: number) => (
                <Text textStyle="md" maxWidth={520} my={4} key={i}>
                  {line}
                </Text>
              ))
            : null}
          <br />
          <Button
            mb={4}
            mt={8}
            size="lg"
            variant="outline"
            className="confirmation__button"
            onClick={() => {
              props.handleClose(notificationId);
              props.activateProduct(productId);
            }}
          >
            {t('components.notifications.confirmation.confirmationButton')}
          </Button>
        </Center>
      </ModalContent>
    </Modal>
  ) : null;
};
