import React, { useEffect } from 'react';
import ConfettiGenerator from 'confetti-js';

export const Confetti = ({ confetti = false }) => {
  useEffect(() => {
    const confettiSettings = { target: 'landbot-confetti', size: 4 };
    const myConfetti = new ConfettiGenerator(confettiSettings);
    if (confetti) {
      myConfetti.render();
    }

    return () => myConfetti.clear();
  }, [confetti]);

  return (
    <canvas
      id="landbot-confetti"
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -10,
      }}
    />
  );
};
