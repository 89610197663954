import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useTheme, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text, SimpleGrid, Heading, Flex, VStack, Center, darken, lighten, } from '@companydotcom/potion';
export const ColorEditor = () => {
    const excludedTokens = ['transparent', 'current', 'black', 'white'];
    const theme = useTheme();
    const items = Object.entries(theme.colors).filter(([key]) => !excludedTokens.includes(key));
    const alphas = items.filter(([o]) => o.toLowerCase().includes('alpha'));
    const grayScale = items.filter(([o]) => o.toLowerCase().includes('gray'));
    const palettes = items.filter(([o]) => !o.toLowerCase().includes('alpha') && !o.toLowerCase().includes('gray'));
    return (_jsx(VStack, Object.assign({ spacing: 4 }, { children: [
            { label: 'Palettes', items: palettes },
            { label: 'Grayscale', items: grayScale },
            { label: 'Alphas', items: alphas },
        ].map(o => {
            return (_jsx(Accordion, Object.assign({ allowMultiple: true, allowToggle: true, width: "full" }, { children: _jsxs(AccordionItem, Object.assign({ backgroundColor: "white", borderColor: "gray.100", borderWidth: "1px" }, { children: [_jsxs(AccordionButton, Object.assign({ borderRadius: "md" }, { children: [_jsx(Box, Object.assign({ flex: "1", textAlign: "left", py: 3 }, { children: _jsx(Heading, Object.assign({ size: "hs-sm" }, { children: o.label }), void 0) }), void 0), _jsx(AccordionIcon, {}, void 0)] }), void 0), _jsx(AccordionPanel, { children: o.items.map(([key, value], n) => {
                                return (_jsxs(Flex, Object.assign({ flexDirection: "column" }, { children: [_jsx(Text, Object.assign({ fontSize: "lg", mb: 2 }, { children: key }), void 0), _jsx(SimpleGrid, Object.assign({ columns: 5, gap: 0, spacingX: 0, spacingY: 0, mb: 2 }, { children: Object.entries(value).map(([k, v], i) => (_jsx(Center, Object.assign({ bgColor: v }, { children: _jsx(Text
                                                // @ts-ignore
                                                , Object.assign({ 
                                                    // @ts-ignore
                                                    color: key === 'whiteAlpha' || Number(k) < 500
                                                        ? darken(v, 60)
                                                        : lighten(v, 60), textStyle: "sm", fontWeight: "semibold" }, { children: k }), void 0) }), i))) }), void 0)] }), n));
                            }) }, void 0)] }), void 0) }), o.label));
        }) }), void 0));
};
