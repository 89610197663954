import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

import {
  Center,
  Heading,
  useBreakpointValue,
  Flex,
  VStack,
  Box,
  Button,
  Text,
  ButtonGroup,
} from '@companydotcom/potion';
import { AppSpinner, InputField, SelectField, PageDivider } from '@companydotcom/ui';
import { genderOptions, ethnicityOptions } from '../../shared';
import { DateSelect } from '../../../../components/elements/date-select';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
  useGetAcgReferenceDataV2Query,
  useUpdateAcgUserV2Mutation,
} from '../../../../services/acg/acg-api-v2';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { getDirtyValues } from '@companydotcom/helpers';
import { removeNullFromObject } from '../../../../utils/helpers/object';

const EditPersonalInformationSchema = () =>
  yup.object().shape({
    title: yup.string().trim().required('acg.firstTimeFlow.personalInformation.inputs.title.error'),
    prefix: yup.string().notRequired(),
    firstName: yup.string().trim().notRequired(),
    lastName: yup.string().trim().notRequired(),
    organization: yup.string().trim().required(),
    middleName: yup.string().trim().notRequired(),
    suffix: yup.string().notRequired(),
    twitterName: yup.string().trim().notRequired(),
    linkedInName: yup.string().trim().notRequired(),
    individualType: yup.string().notRequired(),
    designation: yup.string().trim().notRequired(),
    gender: yup.string().oneOf(['male', 'female', '']).notRequired(),
    ethnicity: yup.string().notRequired(),
    month: yup.string().notRequired(),
    day: yup.string().notRequired(),
    year: yup.string().notRequired(),
  });
interface EditPersonalInformationProps {
  onClose: () => void;
  isMobile: boolean;
}

export const EditPersonalInformation = ({ onClose, isMobile }: EditPersonalInformationProps) => {
  const { t } = useTranslation();
  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-2xl' });
  const { data: globalUser, isLoading: isUserLoading } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const updateUserArgs = useGetAcgQueryArgs(['email', 'source']);
  const { data: acgUserProfileData, isLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );
  const [updateAcgUser, { isLoading: isUpdating }] = useUpdateAcgUserV2Mutation();

  const { data: acgRefData, isLoading: isRefDataLoading } = useGetAcgReferenceDataV2Query({
    ...args,
    referenceData: ['prefix', 'suffix', 'individualType'],
  });

  const defaultValues = {
    prefix: acgUserProfileData?.prefix || '',
    title: acgUserProfileData?.title || '',
    firstName: acgUserProfileData?.firstName || '',
    lastName: acgUserProfileData?.lastName || '',
    organization: acgUserProfileData?.organization || '',
    middleName: acgUserProfileData?.middleName || '',
    suffix: acgUserProfileData?.suffix || '',
    twitterName: acgUserProfileData?.twitterName || '',
    linkedInName: acgUserProfileData?.linkedInName || '',
    individualType: acgUserProfileData?.individualType || '',
    designation: acgUserProfileData?.designation || '',
    gender: acgUserProfileData?.gender || '',
    ethnicity: acgUserProfileData?.ethnicity || '',
    year: acgUserProfileData?.dob?.split?.('-')?.[0] || '',
    month: acgUserProfileData?.dob?.split?.('-')?.[1] || '',
    day: acgUserProfileData?.dob?.split?.('-')?.[2]?.replace(/^0+/, '') || '',
  };

  const makeDOB = (year: string, month: string, day: string) => {
    if (!year || year === '' || !month || month === '' || !day || day === '') {
      return '';
    }

    return `${year}-${month}-${day}`;
  };

  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors, isSubmitting, isValid, isDirty, dirtyFields },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(EditPersonalInformationSchema()),
    defaultValues,
  });

  useEffect(() => {
    if (!isRefDataLoading) {
      resetField('prefix');
      resetField('suffix');
      resetField('individualType');
    }
  }, [isRefDataLoading, resetField]);

  function formatUrls(url: string, prefix: string) {
    if (validator.isURL(url)) {
      return `${prefix}${url.split('/').pop()}`;
    }

    if (!validator.isEmpty(url)) {
      return `${prefix}${url}`;
    }

    return '';
  }

  const onSubmit = async (data: typeof defaultValues) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as Partial<typeof defaultValues>;

    try {
      const payload = {
        ...dirtyValues,
        dob:
          dirtyValues?.year || dirtyValues?.month || dirtyValues?.day
            ? makeDOB(
                dirtyValues?.year || data.year,
                dirtyValues?.month || data.month,
                dirtyValues?.day || data.day,
              )
            : undefined,
        linkedInName:
          dirtyValues?.linkedInName === ''
            ? ''
            : dirtyValues?.linkedInName
            ? formatUrls(dirtyValues?.linkedInName, 'https://www.linkedin.com/in/')
            : acgUserProfileData?.linkedInName,
        twitterName:
          dirtyValues?.twitterName === ''
            ? ''
            : dirtyValues?.twitterName
            ? formatUrls(dirtyValues?.twitterName, 'https://twitter.com/')
            : acgUserProfileData?.twitterName,
        lastProfileUpdateVersion: acgUserProfileData?.lastProfileUpdateVersion ?? 1,
      };

      // Don't send unnecessary values to the call
      delete payload?.month;
      delete payload?.day;
      delete payload?.year;

      updateAcgUser({
        ...updateUserArgs,
        payload: removeNullFromObject(payload),
      });
      onClose();
    } catch (error) {
      console.log('EditPersonalInformation onSubmit error: ', error);
    }
  };

  return (
    <Center flexDirection="column">
      {isLoading || isUserLoading ? (
        <AppSpinner />
      ) : (
        <>
          <Heading size={headingSize} fontWeight="700" mt={[6, 12]} mb={[2, 2, 12]}>
            {t('acg.myProfile.forms.editPersonalInformation.personalInformation')}
          </Heading>
          {isMobile && <PageDivider width={50} mb={12} />}
          <VStack>
            <Flex
              flexDirection={['column', 'column', 'row']}
              width="100%"
              justifyContent="space-between"
              alignItems={['center', 'center', 'start']}
            >
              <VStack maxWidth={[null, '374px']} width="100%">
                <SelectField
                  isLoading={isRefDataLoading}
                  className="acg-myProfile-editPersonalInfo__prefix"
                  data-test="acg-myProfile-editPersonalInfo__prefix"
                  register={register}
                  name="prefix"
                  label={t('common.inputs.prefix.label')}
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 4,
                  }}
                >
                  <option value=""> </option>
                  {acgRefData?.prefix?.map(
                    (opt, i) =>
                      opt?.recordName && (
                        <option key={i} value={opt.recordName}>
                          {opt.recordName}
                        </option>
                      ),
                  )}
                </SelectField>
                <InputField
                  className="acg-myProfile-editPersonalInfo__firstName"
                  data-test="acg-myProfile-editPersonalInfo__firstName"
                  register={register}
                  name="firstName"
                  helperText="This is no longer editable"
                  label={t('common.inputs.firstName.label')}
                  formControlStyles={{
                    paddingBottom: 6,
                  }}
                  errors={errors}
                  isDisabled
                />
                <InputField
                  className="acg-myProfile-editPersonalInfo__middleName"
                  data-test="acg-myProfile-editPersonalInfo__middleName"
                  register={register}
                  name="middleName"
                  label={t('common.inputs.middleName.label')}
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 4,
                  }}
                />
                <InputField
                  className="acg-myProfile-editPersonalInfo__lastName"
                  data-test="acg-myProfile-editPersonalInfo__lastName"
                  register={register}
                  name="lastName"
                  helperText="This is no longer editable"
                  label={t('common.inputs.lastName.label')}
                  errors={errors}
                  isDisabled
                  formControlStyles={{
                    paddingBottom: 6,
                  }}
                />
                <SelectField
                  isLoading={isRefDataLoading}
                  className="acg-myProfile-editPersonalInfo__suffix"
                  data-test="acg-myProfile-editPersonalInfo__suffix"
                  register={register}
                  name="suffix"
                  label={t('common.inputs.suffix.label')}
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 4,
                  }}
                >
                  <option value=""> </option>
                  {acgRefData?.suffix?.map(opt => (
                    <option key={opt?.recordName} value={opt?.recordName!}>
                      {opt?.recordName}
                    </option>
                  ))}
                </SelectField>
                <InputField
                  className="acg-myProfile-editPersonalInfo__designation"
                  data-test="acg-myProfile-editPersonalInfo__designation"
                  register={register}
                  name="designation"
                  label={t('common.inputs.designation.label')}
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 6,
                  }}
                />
              </VStack>
              <VStack maxWidth={[null, '374px']} width="100%">
                <InputField
                  className="acg-myProfile-editPersonalInfo__title"
                  data-test="acg-myProfile-editPersonalInfo__title"
                  register={register}
                  name="title"
                  label={t('common.inputs.title.label')}
                  helperText={t('common.misc.required')}
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 6,
                  }}
                />
                <InputField
                  className="acg-myProfile-editPersonalInfo__organization"
                  data-test="acg-myProfile-editPersonalInfo__organization"
                  register={register}
                  name="organization"
                  helperText={t('common.misc.required')}
                  label={t('common.inputs.organization.label')}
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 6,
                  }}
                />
                <InputField
                  className="acg-myProfile-editPersonalInfo__twitterName"
                  data-test="acg-myProfile-editPersonalInfo__twitterName"
                  register={register}
                  name="twitterName"
                  label="Twitter URL"
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 4,
                  }}
                />
                <InputField
                  className="acg-myProfile-editPersonalInfo__linkedInProfileUr"
                  data-test="acg-myProfile-editPersonalInfo__linkedInProfileUr"
                  register={register}
                  name="linkedInName"
                  label="LinkedIn Profile URL or Name"
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 4,
                  }}
                />
                <SelectField
                  isLoading={isRefDataLoading}
                  className="acg-myProfile-editPersonalInfo__individualType"
                  data-test="acg-myProfile-editPersonalInfo__individualType"
                  register={register}
                  name="individualType"
                  label="Individual Type"
                  errors={errors}
                  helperText="This is no longer editable"
                  isDisabled
                >
                  {acgRefData?.individualType?.map((opt, i) => (
                    <option key={i} value={opt?.recordName!}>
                      {opt?.recordName}
                    </option>
                  ))}
                </SelectField>
              </VStack>
            </Flex>
            <Text
              fontWeight={700}
              fontSize="sm"
              alignSelf="center"
              textAlign="center"
              color="red.500"
            >
              {t('acg.myProfile.forms.editPersonalInformation.optionalFieldsWarning')}
            </Text>
            <Flex
              flexDirection={['column', 'column', 'row']}
              width="100%"
              justifyContent="space-between"
              alignItems={['center', 'center', 'end']}
              mb={[6, null]}
            >
              <VStack maxWidth={[null, '374px']} width="100%">
                <DateSelect
                  register={register}
                  setValue={setValue}
                  monthStateKey="month"
                  dayStateKey="day"
                  yearStateKey="year"
                  formValues={defaultValues}
                  label={t('common.inputs.dateOfBirth.label')}
                  formControlStyles={{
                    paddingBottom: 2,
                    width: '100%',
                  }}
                />
                <SelectField
                  className="acg-myProfile-editPersonalInfo__gender"
                  data-test="acg-myProfile-editPersonalInfo__gender"
                  register={register}
                  name="gender"
                  label={t('common.inputs.gender.label')}
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 6,
                  }}
                >
                  <option value=""> </option>
                  {genderOptions(t).map(opt => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </SelectField>
              </VStack>
              <Flex maxWidth={[null, '374px']} width="100%">
                <SelectField
                  className="acg-myProfile-editPersonalInfo__ethnicity"
                  data-test="acg-myProfile-editPersonalInfo__ethnicity"
                  register={register}
                  name="ethnicity"
                  label={t('common.inputs.ethnicity.label')}
                  errors={errors}
                  formControlStyles={{
                    paddingBottom: 6,
                  }}
                >
                  <option value=""> </option>
                  {ethnicityOptions(t).map(opt => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </SelectField>
              </Flex>
            </Flex>
            <Box pt={[8, 8, 2]} pb={12}>
              <ButtonGroup size="sm">
                <Button variant="outline" onClick={onClose} isDisabled={isSubmitting || isUpdating}>
                  {t('common.buttons.cancel')}
                </Button>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  isLoading={isSubmitting || isUpdating}
                  isDisabled={!isValid || !isDirty}
                >
                  {t('common.buttons.save')}
                </Button>
              </ButtonGroup>
            </Box>
          </VStack>
        </>
      )}
    </Center>
  );
};
