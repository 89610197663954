import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack, Heading, Box, Text, Button, VStack } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { SuccessfulPaymentResponse } from '../../../services/acg/acg.types';
import { useAcgCartStorage } from '../../../features/acg';
import { useLocalCartStorage } from '../../../hooks/use-local-cart-storage';

export interface AchAndCCInfo {
  orderConfirmationData?: SuccessfulPaymentResponse;
  goToDashboard: () => void;
  goToRedirect?: () => void;
  fontWeight?: string;
  opacity?: string;
}

export const CcPaymentInfo: React.FC<AchAndCCInfo> = ({
  orderConfirmationData,
  goToDashboard,
  goToRedirect,
  fontWeight,
  opacity,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const webCode = searchParams.get('WebCode')?.toLowerCase();
  const { getLocalCart: getLocalAcgCart } = useAcgCartStorage();
  const { getLocalCart } = useLocalCartStorage();
  const storedLocalPayment = getLocalCart()?.storedPayment;
  const storedAcgPayment = getLocalAcgCart()?.storedPayment;
  const storedPayment = storedLocalPayment ? storedLocalPayment : storedAcgPayment;

  const CURRENCY_MAP = {
    USD: 'USD - United States Dollar',
    CAD: 'CAD - Canadian Dollar',
  };

  return (
    <Stack textAlign="left" mt={14} spacing={[6]} width="full">
      <Heading size="hs-xl" fontWeight={fontWeight} opacity={opacity}>
        {t(`miop.paymentInformation.header`)}
      </Heading>
      <Stack
        width="full"
        spacing={[1, null, 4]}
        direction={['column', null, 'row']}
        id="paymentInfoContainer"
      >
        <VStack
          justify="flex-start"
          spacing={4}
          width={['full', '50%']}
          id="methodNameDateContainer"
        >
          <Stack width="full" spacing={[0, 1]} direction={['column', null, 'row']} id="method">
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.paymentMethod')}
            </Text>
            <Text className="text">{` ${storedPayment?.cardNumber ? 'Credit Card' : 'ACH'}`}</Text>
          </Stack>
          <Stack width="full" spacing={[0, 1]} direction={['column', null, 'row']} id="name">
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.cardholderName')}
            </Text>
            <Text className="text">{` ${storedPayment?.cardHolderName}`}</Text>
          </Stack>
          <Stack width="full" spacing={[0, 1]} direction={['column', null, 'row']} id="date">
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.expiration')}
            </Text>
            <Text className="text">{` ${storedPayment?.cardExpiry}`}</Text>
          </Stack>
        </VStack>
        <VStack
          justify="flex-start"
          spacing={4}
          width={['full', '50%']}
          id="numberCurrencyContainer"
        >
          <Box width="full" height={[0, null, 6]} />
          <Stack
            width="full"
            spacing={[0, 1]}
            direction={['column', null, 'row']}
            id="methodNumber"
          >
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.ccNumber')}
            </Text>
            <Text className="text">{`XXXX-XXXX-XXXX-${storedPayment?.cardNumber?.substr(
              -4,
            )}`}</Text>
          </Stack>
          <Stack width="full" spacing={[0, 1]} direction={['column', null, 'row']} id="currency">
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.currency')}
            </Text>
            <Text className="text">{` ${
              CURRENCY_MAP[orderConfirmationData?.currencyCode ?? 'USD']
            }`}</Text>
          </Stack>
        </VStack>
      </Stack>
      <Box align="center" pt={4} width="full">
        {goToRedirect ? (
          <Button
            id="goToMyACGButton"
            size="lg"
            onClick={
              webCode === 'eventregfees' ||
              webCode === 'acgmembershipdirectory' ||
              webCode === 'acgmemberdirectory'
                ? goToRedirect
                : goToDashboard
            }
          >
            {webCode === 'eventregfees' ? 'GO TO EVENT REGISTRATION' : 'GO TO MY ACG'}
          </Button>
        ) : (
          <Button id="goToDashboard" size="lg" onClick={goToDashboard} width={['full', 'auto']}>
            GO TO DASHBOARD
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export const AchPaymentInfo: React.FC<AchAndCCInfo> = ({ goToDashboard, goToRedirect }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const webCode = searchParams.get('WebCode')?.toLowerCase();
  const { getLocalCart } = useAcgCartStorage();
  const storedPayment = getLocalCart()?.storedPayment;

  return (
    <Stack textAlign="left" mt={14} spacing={[6]} width="full">
      <Heading size="hs-lg" fontWeight="700">
        {t(`miop.paymentInformation.header`)}
      </Heading>
      <Stack
        width="full"
        spacing={[1, null, 4]}
        direction={['column', null, 'row']}
        id="paymentInfoContainer"
      >
        <VStack justify="flex-start" spacing={4} width={['full', '50%']}>
          <Stack width="full" spacing={[0, 1]} direction={['column', null, 'row']} id="method">
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.paymentMethod')}
            </Text>
            <Text className="text">ACH</Text>
          </Stack>
          <Stack width="full" spacing={[0, 1]} direction={['column', null, 'row']} id="name">
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.accountholderName')}
            </Text>
            <Text className="text">{` ${storedPayment?.accountHolderName}`}</Text>
          </Stack>
          <Stack
            width="full"
            spacing={[0, 1]}
            direction={['column', null, 'row']}
            id="achRoutingNumber"
          >
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.routingNumber')}
            </Text>
            <Text className="text">{` ${storedPayment?.routingNumber}`}</Text>
          </Stack>
        </VStack>
        <VStack justify="flex-start" spacing={4} width={['full', '50%']}>
          <Box width="full" height={[0, null, 6]} />
          <Stack
            width="full"
            spacing={[0, 1]}
            direction={['column', null, 'row']}
            id="achAccountType"
          >
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.accountType')}
            </Text>
            <Text className="text">
              {storedPayment?.accountType === 'S' ? 'Savings' : 'Checking'}
            </Text>
          </Stack>
          <Stack
            width="full"
            spacing={[0, 1]}
            direction={['column', null, 'row']}
            id="achAccountNumber"
          >
            <Text fontWeight="bold">
              {t('acg.checkout.orderConfirmationStep.orderInfo.accountNumber')}
            </Text>
            <Text className="text">{` ${storedPayment?.accountNumber}`}</Text>
          </Stack>
        </VStack>
      </Stack>
      <Box alignSelf="center" pt={4}>
        <Button
          id="goToMyACGButton"
          size="lg"
          onClick={
            webCode === 'eventregfees' ||
            webCode === 'acgmembershipdirectory' ||
            webCode === 'acgmemberdirectory'
              ? goToRedirect
              : goToDashboard
          }
        >
          {webCode === 'eventregfees' ? 'GO TO EVENT REGISTRATION' : 'GO TO MY ACG'}
        </Button>
      </Box>
    </Stack>
  );
};
