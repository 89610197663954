import React, { useMemo } from 'react';
import { InlineWidget } from 'react-calendly';
import { Heading, Box, Center } from '@companydotcom/potion';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';

export interface CalendlyProps {
  url: string;
  header?: string;
  subheader?: string;
  productId?: string;
  tileId?: string;
  handleDataFormFill?: (arg: any) => void;
}

export const CalendlyForm = ({
  header,
  subheader,
  url,
  productId,
  tileId,
  handleDataFormFill,
}: CalendlyProps) => {
  const { data: globalUser } = useGetGlobalUserQuery();

  function isCalendlyEvent(e: any) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  }

  const debounceCreateAppointment = useMemo(() => {
    let calls = 0;
    return async () => {
      calls++;
      if (calls === 1 && productId) {
        handleDataFormFill?.({});
      }
    };
  }, [handleDataFormFill, productId]);

  window.addEventListener('message', async e => {
    if (isCalendlyEvent(e)) {
      if (e.data.event === 'calendly.event_scheduled') {
        // send data down to schedule appointment
        // example return: https://api.calendly.com/scheduled_events/BCUVGTSM46UEW3CV
        debounceCreateAppointment();
      }
    }
  });

  const metaData = JSON.stringify({
    productId,
    userId: globalUser?.userId,
    tileId,
  });

  return (
    <Center
      className="form-calendlyForm__container"
      flexDirection="column"
      py={[8, 12]}
      textAlign="center"
      width="full"
    >
      <Box textAlign="center" px={4}>
        {header ? (
          <Heading as="h1" size="hs-xl">
            {header ?? ''}
          </Heading>
        ) : null}
        {subheader ? (
          <Heading as="h2" size="hs-md" mt={[5]}>
            {subheader ?? ''}
          </Heading>
        ) : null}
      </Box>
      {url ? (
        <InlineWidget
          url={url}
          utm={{ utmSource: metaData }}
          prefill={{
            name: `${globalUser?.firstName} ${globalUser?.lastName}`,
            firstName: globalUser?.firstName ?? '',
            lastName: globalUser?.lastName ?? '',
            email: globalUser?.email ?? '',
            customAnswers: {
              a1: `1${globalUser?.phone ?? ''}`,
            },
          }}
          styles={{
            width: '100%',
            height: '750px',
          }}
        />
      ) : null}
    </Center>
  );
};
