import React from 'react';
import { Center } from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';

import type { TileComponentProps } from '../../types';

export interface TileSpinnerProps extends TileComponentProps {}

export const TileSpinner: React.FC<TileSpinnerProps> = props => {
  const { componentTheme } = props;
  return (
    <Center className="tile__spinner" flex={1} sx={componentTheme}>
      <AppSpinner size="md" />
    </Center>
  );
};
