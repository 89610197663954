import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { SubpageHeader, SubpageContent, SubpageFooter, SubpageLayout } from '@companydotcom/ui';
import { useAssets, useSource } from '@companydotcom/providers';
import { useGetFacecakeBackgroundImages } from '../../features/facecake/layouts/components/facecake-background-imager';

export interface SubpageProps {
  hideFooter?: boolean;
}

const Subpage: React.FC<SubpageProps> = ({ hideFooter }) => {
  const source = useSource();
  const location = useLocation();
  const assets = useAssets();
  const faceCakeBackgroundImageData = useGetFacecakeBackgroundImages(
    source?.sourceId,
    location.pathname,
  );

  return (
    <SubpageLayout {...faceCakeBackgroundImageData}>
      {location.pathname !== '/callback' && <SubpageHeader logo={assets?.logo} />}
      <SubpageContent>
        <Outlet />
      </SubpageContent>
      {!['/callback', '/payment'].includes(location.pathname) && !hideFooter && (
        <SubpageFooter
          // isSubpageFooter
          footerLogo={assets?.footer_logo}
          footerLinks={source?.footerLinks}
        />
      )}
    </SubpageLayout>
  );
};

export default Subpage;
