import gql from 'graphql-tag';

export const GET_NOTIFICATION_LIST = gql`
  query GetNotificationList($userId: String!) {
    getNotificationList(userId: $userId) {
      id
      body
      handled
      notificationType
      deliveryType
      dateCreated
      dateExpires
      productId
    }
  }
`;

export const FETCH_LARGE_PAYLOAD = gql`
  query FetchLargePayload($respPayloadCacheId: String!) {
    fetchLargePayload(respPayloadCacheId: $respPayloadCacheId)
  }
`;
