import React, { useReducer, useEffect, useCallback, useState } from 'react';
import { useMitt, useSource } from '@companydotcom/providers';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useTabStepper,
  Tabs,
  TabPanels,
  Center,
  TabPanel,
  ModalCloseButton,
  IconButton,
  Icon,
} from '@companydotcom/potion';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate } from 'react-router-dom';
import { companyConstants } from '@companydotcom/helpers';
import { AppSpinner } from '@companydotcom/ui';
import { ExtendedSubscriptionTier, ExtendedSubscriptionRatePlan, User } from '@companydotcom/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/pro-solid-svg-icons';
import { Checkout } from './checkout';
import { LegalIncRedirect } from '../../../pages/subpages/legal-inc-redirect';
import { ProductSelect } from './product-select';
import { PaymentSuccess } from './payment-success';
import { CheckVerification } from '../../../components/forms/check-verification';
// import { OpenPaywallEvent } from '../types';
// import { useAuth } from '../../../providers';
// import { updateMarketoProductActivity, initExternalFlow, initInternalFlow } from '../utils';
import { initExternalFlow, initInternalFlow } from '../utils';
import { paywallReducer, initialPaywallState } from '../../../state/reducers/paywall-reducer';
import { cartReducer, initialCartState } from '../../../state/reducers/cart-reducer';
import { useGetGlobalUserQuery, useGetUserLiteQuery } from '../../../services/user/user-api';
import {
  useGetUserProductQuery,
  useLazyGetUserProductQuery,
} from '../../../services/product/product-api';

const { platformEvents } = companyConstants;

const paywallSteps = [
  { slug: 'check-verification', component: <CheckVerification /> },
  { slug: 'product-select', component: <ProductSelect /> },
  { slug: 'checkout', component: <Checkout /> },
  { slug: 'payment-success', component: <PaymentSuccess /> },
];

export interface PaywallProps {
  //* All below props are specifically for the external flow
  productId?: string | null;
  planId?: string | null;
  userId?: string | null;
  email?: string | null;
  orderid?: string | null;
  referrer?: string | null;
  directFlow?: boolean;
  externalFlow?: boolean;
  redirectUrl?: string;
}

export const Paywall = ({
  userId,
  email,
  externalFlow,
  directFlow,
  productId,
  planId,
  orderid,
  referrer,
  redirectUrl,
}: PaywallProps) => {
  const { emitter } = useMitt();
  const { data: globalUser } = useGetGlobalUserQuery();
  const source = useSource();
  const { sourceId } = source;
  const { data: userProduct } = useGetUserProductQuery(
    globalUser && productId
      ? {
          userId: globalUser.userId,
          productId,
          locale: source.i18nConfig?.lng,
        }
      : skipToken,
  );
  const [getUserProduct] = useLazyGetUserProductQuery();
  const navigate = useNavigate();
  const paywallUserId = userId || globalUser?.userId;
  const { data: userLite, refetch } = useGetUserLiteQuery(
    paywallUserId ? { userId: paywallUserId } : skipToken,
  );

  const { tabIndex, handleTabsChange, nextStep, goToStep } = useTabStepper({
    steps: paywallSteps,
    scrollOnStepChange: false,
  });
  const [paywallState, dispatchPaywallState] = useReducer(paywallReducer, initialPaywallState);
  const [cartState, dispatchCartState] = useReducer(cartReducer, initialCartState);
  // Used in paymentMethodHelpers to pass data from the zuora callback to the payment success page
  const [paymentData, setPaymentData] = useState({});

  // All logic performed when a user selects a new rateplan
  const addToCart = useCallback(
    (ratePlan: ExtendedSubscriptionRatePlan, currentTier?: ExtendedSubscriptionTier) => {
      const foundRp = paywallState?.userProduct?.products?.[0]?.ratePlans?.find(
        p => p?.name === ratePlan,
      ) as ExtendedSubscriptionRatePlan;
      // if the tier currently selected exists on the ratePlan attempted to be selected,
      // automatically select that appropriate tier for them
      if (
        foundRp?.tiers &&
        foundRp?.tiers.length > 0 &&
        currentTier?.ratePlanId !== foundRp?.ratePlanId
      ) {
        const foundTier = foundRp?.tiers.find(
          newTier => newTier?.description === currentTier?.description,
        );

        if (foundTier) {
          dispatchCartState({
            type: 'ADD_SUBSCRIPTION_TIER',
            payload: foundTier,
          });
        }
      }

      dispatchCartState({
        type: 'UPDATE_RATE_PLAN',
        payload: foundRp,
      });
    },
    [paywallState?.userProduct?.products],
  );

  // All logic that happens when a tier is selected, if tiers exist
  const addSelectedTier = useCallback(
    (
      tier: ExtendedSubscriptionTier,
      cart?: ExtendedSubscriptionRatePlan[],
      ratePlans?: ExtendedSubscriptionRatePlan[],
    ) => {
      // Changes selected rate plan based on whether a selected tier only exists on one rate plan
      if (tier.ratePlanId && tier.ratePlanId !== cart?.[0].ratePlanId) {
        const foundRatePlan = ratePlans?.find(
          plan => plan.ratePlanId === tier.ratePlanId,
        ) as ExtendedSubscriptionRatePlan;

        dispatchCartState({
          type: 'UPDATE_RATE_PLAN',
          payload: foundRatePlan,
        });
      }

      if (cart?.[0].productId === process.env.REACT_APP_EMAIL_PRODUCTID && tier && cart) {
        dispatchPaywallState({
          type: 'SET_PAYWALL_STATE',
          payload: {
            // @ts-ignore
            quantity: tier?.qty - cart?.[0].tiers?.find(tier => tier.isActive)?.qty,
          },
        });
      }

      dispatchCartState({
        type: 'UPDATE_SUBSCRIPTION_TIER',
        payload: tier,
      });
    },
    [],
  );

  const cancelPayment = useCallback(() => {
    goToStep(0);
    dispatchPaywallState({ type: 'RESET_ALL_PAYWALL_STATE' });
    dispatchCartState({ type: 'RESET_ALL_CART_STATE' });
  }, [goToStep]);

  const onVerificationComplete = useCallback(() => {
    if (
      // always show checkout when coming from miop subscriptions tab or checkout prop exists
      // @ts-ignore no idea about this checkout prop
      cartState.selectedRatePlans?.[0]?.checkout ||
      (paywallState?.userProduct?.products?.[0]?.ratePlans &&
        paywallState?.userProduct?.products?.[0].ratePlans?.length <= 1)
    ) {
      goToStep('checkout');
    } else if (!externalFlow) {
      goToStep('product-select');
    }
  }, [cartState.selectedRatePlans, externalFlow, goToStep, paywallState?.userProduct?.products]);

  // External Init
  useEffect(() => {
    if (externalFlow && productId && userId && userProduct) {
      initExternalFlow(
        productId,
        // userId,
        navigate,
        goToStep,
        dispatchPaywallState,
        dispatchCartState,
        userProduct,
        orderid,
        planId,
        referrer,
        userLite,
        refetch,
      );
    }
  }, [
    externalFlow,
    goToStep,
    navigate,
    orderid,
    planId,
    productId,
    referrer,
    refetch,
    source?.i18nConfig?.lng,
    userId,
    userLite,
    userProduct,
  ]);

  useEffect(() => {
    // Internal Init
    emitter.on(platformEvents.openPaywall, async (evt: any) => {
      // Reset state
      cancelPayment();
      dispatchPaywallState({ type: 'TOGGLE_PAYWALL_MODAL', payload: true });
      // dispatchPaywallState({ type: 'TOGGLE_PAYWALL_LOADING' });
      // Immediately send user to checkout screen if paywall is popped from subscriptions tab
      if (
        evt.metadata?.eventSource === 'subscriptions' ||
        evt.metadata?.eventSource === 'addNewUserFlow'
      ) {
        dispatchPaywallState({
          type: 'SET_PAYWALL_STATE',
          payload: {
            eventSource: evt.metadata.eventSource,
            onSuccessHandler: evt.metadata.onSuccessHandler,
          },
        });

        dispatchCartState({
          type: 'SET_CART_STATE',
          payload: {
            selectedRatePlans: evt.metadata.products,
            selectedTiers: evt.metadata.selectedTiers,
          },
        });

        goToStep('checkout');
      } else {
        await initInternalFlow(
          evt,
          getUserProduct,
          globalUser as User,
          dispatchPaywallState,
          dispatchCartState,
          source.i18nConfig?.lng,
        );
      }
    });

    return () => {
      emitter.off(platformEvents.openPaywall);
    };
  }, [cancelPayment, emitter, getUserProduct, globalUser, goToStep, source.i18nConfig?.lng]);

  const sharedPaywallProps = {
    cartState,
    paywallState,
    sourceId,
    source,
    toggleModal: () => dispatchPaywallState({ type: 'TOGGLE_PAYWALL_MODAL', payload: false }),
    cancelPayment,
    externalFlow,
    email: email || globalUser?.email,
    productId,
    orderId: orderid,
    paymentData,
    setPaymentData,
    goToStep,
    nextStep,
    planId,
    onVerificationComplete,
    addSelectedTier,
    addToCart,
    additionalVerificationCondition:
      paywallState?.userProduct?.products?.[0]?.slug === 'weebly' ||
      paywallState?.userProduct?.products?.[0]?.productId ===
        'af62e060-06e4-11e8-9306-120b17a64360' ||
      paywallState?.userProduct?.products?.[0]?.slug === 'email_rackspace' ||
      paywallState?.userProduct?.products?.[0]?.productId ===
        'f5327182-4604-11e8-842f-0ed5f89f718b' ||
      paywallState?.userProduct?.products?.[0]?.slug === 'email_marketing' ||
      paywallState?.userProduct?.products?.[0]?.productId ===
        'b718fbf6-e3f9-4770-a008-0d69a967ea2e',
  };

  if (externalFlow) {
    const externalFlowProps = {
      user: paywallState?.externalUser,
      directFlow,
      redirectUrl,
      reason: 'purchase',
    };

    return (
      <Center
        className="paywall__externalFlow"
        px={4}
        maxW={paywallState.loading || !paywallState.externalUser ? '2xl' : '6xl'}
        width="full"
        boxShadow="md"
        bg="white"
        mx="auto"
      >
        {paywallState.loading || !paywallState.externalUser ? (
          <Center height={600} className="paywall__externalLoader">
            <AppSpinner />
          </Center>
        ) : paywallState.legalIncOpen ? (
          <LegalIncRedirect userId={userId || globalUser?.userId} />
        ) : (
          <Tabs
            isLazy
            key={tabIndex}
            variant="unstyled"
            index={tabIndex}
            onChange={handleTabsChange}
            marginBottom="71px"
          >
            <TabPanels>
              {paywallSteps.map(step => (
                <TabPanel key={step.slug}>
                  {React.cloneElement(step.component, {
                    ...externalFlowProps,
                    ...sharedPaywallProps,
                  })}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </Center>
    );
  }

  return (
    <Modal
      isOpen={paywallState?.paywallOpen ?? false}
      onClose={() => {
        // If onSuccessHandler has been set, fire it on any toggle if the payment is successful
        if (paywallSteps?.[tabIndex]?.slug === 'payment-success') {
          paywallState?.onSuccessHandler?.();
        }
        // if you are on miop, and you are on the success screen, make clicking the "x" have the same behavior has clicking "got it", this way you never see miop in an "unrefreshed" state
        if (
          paywallState.paywallOpen &&
          paywallState.eventSource === 'miop' &&
          paywallSteps?.[tabIndex]?.slug === 'payment-success'
        ) {
          navigate('/');
        }
        dispatchPaywallState({ type: 'TOGGLE_PAYWALL_MODAL', payload: false });
        dispatchCartState({ type: 'RESET_ALL_CART_STATE' });
      }}
      size={
        paywallState.loading || !globalUser
          ? '2xl'
          : paywallSteps?.[tabIndex]?.slug === 'check-verification' ||
            paywallSteps?.[tabIndex]?.slug === 'payment-success'
          ? '3xl'
          : '6xl'
      }
    >
      <ModalOverlay />
      <ModalContent>
        {tabIndex === 2 &&
          paywallState.eventSource !== 'miop' &&
          paywallState.eventSource !== 'subscriptions' &&
          paywallState.eventSource !== 'addNewUserFlow' && (
            <PaywallBackButton onClick={() => goToStep('product-select')} />
          )}
        <ModalCloseButton />
        <ModalBody>
          {paywallState.loading || !globalUser ? (
            <Center height={600} className="paywall__internalLoader">
              <AppSpinner />
            </Center>
          ) : (
            <>
              {paywallState.legalIncOpen ? (
                <LegalIncRedirect />
              ) : (
                <Tabs
                  className="paywall__internalFlow"
                  isLazy
                  id="paywall"
                  key={tabIndex}
                  variant="unstyled"
                  index={tabIndex}
                  onChange={handleTabsChange}
                >
                  <TabPanels>
                    {paywallSteps.map(step => (
                      <TabPanel key={step.slug}>
                        {React.cloneElement(step.component, {
                          user: globalUser || paywallState.externalUser,
                          ...sharedPaywallProps,
                        })}
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const PaywallBackButton = ({ onClick }: any) => (
  <IconButton
    onClick={onClick}
    aria-label="Go Back"
    className="potion-mobilesidenav__hamburger"
    variant="ghost"
    icon={<Icon as={FontAwesomeIcon} borderRadius="md" icon={faArrowLeftLong} />}
    position="absolute"
    top={2}
    left={3}
    boxSize="32px"
  />
);
