import gql from 'graphql-tag';

const UPDATE_ACG_USER_PROFILE_MUTATION = gql`
  mutation UpdateAcgUserProfile($input: UpdateAcgUserInput) {
    updateAcgUserProfile(input: $input) {
      profilePicture
    }
  }
`;

const STORE_RHYTHM_TOKEN_AND_CONTACT_MUTATION = gql`
  mutation StoreRhythmTokenAndContactId($input: StoreRhythmTokenAndContactIdInput) {
    storeRhythmTokenAndContactId(input: $input) {
      status
    }
  }
`;

const UPDATE_ACG_MEMBERSHIP = gql`
  mutation UpdateAcgMembership($input: UpdateAcgMembershipInput) {
    updateMembership(input: $input) {
      memberships {
        recordKey
        autoRenew
        expireDate
        renewDate
        chapterName
        joinDate
        status
        invoiceRecordKey
        openInvoices {
          invoiceRecordKey
        }
        renewalPaymentInfo {
          cardType
          paymentOrigin
          merchantAccountTokens {
            merchantAccountId
            merchantToken
          }
        }
      }
      status
      totalMembershipsFound
      hiddenFieldsFromMembershipDirectory
    }
  }
`;

export const mutations = {
  UPDATE_ACG_USER_PROFILE_MUTATION,
  STORE_RHYTHM_TOKEN_AND_CONTACT_MUTATION,
  UPDATE_ACG_MEMBERSHIP,
};
