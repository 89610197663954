import {
  Box,
  Divider,
  GridItem,
  Stack,
  Text,
  useMediaQuery,
  Heading,
  Center,
} from '@companydotcom/potion';
import { PageDivider } from '@companydotcom/ui';
import {
  useProcessOrderSummaryMutation,
  useGetGlobalUserQuery,
} from '../../../../../../dashboard/src/services/user/user-api';
import {
  CheckoutAlertContent,
  CheckoutContent,
  CheckoutDiscountAndPlaceOrder,
  CheckoutOrderStepOrderSummary,
  CheckoutPaymentOptionsHeader,
  CheckoutSecureAndEncrypted,
} from '../../../../components/layouts/checkout-layout/checkout-layout';
import { useLocalCartStorage } from '../../../../hooks/use-local-cart-storage';
import ChargebeePaymentFormfc from '../chargeBeeForm';

export const CheckoutOrder: React.FC<any> = props => {
  const { goToStep, setOrderConfirmationData } = props;
  const { data: globalUser } = useGetGlobalUserQuery();
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const { getLocalCart } = useLocalCartStorage();
  const selectedPlan = getLocalCart()?.selectedPlan;
  const [processOrderSummary] = useProcessOrderSummaryMutation();

  return (
    <Center
      className="checkout__checkoutLayoutContainer"
      flexDirection="column"
      bg={['transparent', 'white']}
      px={[0, 4]}
      layerStyle={{ base: undefined, sm: 'formContainer' }}
      py={[4, 12]}
    >
      <Box width="full" maxW={908} paddingBottom="46px">
        <Box className="checkout__checkoutHeader" width="full" alignItems="left">
          <Heading size={isMobile ? 'hs-xl' : 'hs-2xl'}>Checkout</Heading>
          <PageDivider mt={[4, 6]} mb={[6, 0]} mx="inherit" />
        </Box>
        {isMobile ? null : (
          <CheckoutAlertContent>
            <CheckoutSecureAndEncrypted />
          </CheckoutAlertContent>
        )}
        <CheckoutContent>
          <GridItem as="form">
            <CheckoutPaymentOptionsHeader source="facecake" />
            <ChargebeePaymentFormfc
              globalUser={globalUser}
              processOrderSummary={processOrderSummary}
              goToStep={goToStep}
              setOrderConfirmationData={setOrderConfirmationData}
            />
          </GridItem>
          <CheckoutOrderStepOrderSummary bgColor="#F2F2F2">
            <Box>
              <Text textStyle="lg" mt={[4, 6]} pb={isMobile ? '31px' : '0px'}>
                {selectedPlan?.name}
              </Text>
              <Text textStyle={['lg', 'xs']} mt={1} fontWeight="400">
                {selectedPlan?.subheading}
              </Text>
              <Text textStyle={['lg', 'xs']} mt={1} fontWeight="400">
                {selectedPlan?.description}
              </Text>
            </Box>
            <Divider my={[4, 6]} borderColor="black" />
            <Text textStyle="2xl" fontWeight="700">
              TOTAL
            </Text>
            <Text mt={1} textStyle="2xl" fontWeight="700">
              {Number(selectedPlan?.price).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </Text>
            <Text textStyle={['lg', 'xs']} mt={1} fontWeight="400">
              {selectedPlan?.detail}
            </Text>
          </CheckoutOrderStepOrderSummary>
        </CheckoutContent>
        <CheckoutDiscountAndPlaceOrder borderColor="black" position="relative">
          <Stack flexDir="row" width={['100%', '370px']} justifyContent="end"></Stack>
        </CheckoutDiscountAndPlaceOrder>
      </Box>
    </Center>
  );
};
