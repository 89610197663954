import * as React from 'react';
import {
  potion,
  forwardRef,
  HTMLPotionProps,
  omitThemingProps,
  StylesProvider,
  ThemingProps,
  useMultiStyleConfig,
} from '../../system';
import { cx, __DEV__ } from '../../utils';
import { useFormControlContext } from './form-control';

export interface FormErrorMessageProps
  extends HTMLPotionProps<'div'>,
    ThemingProps<'FormErrorMessage'> {}

/**
 * Used to provide feedback about an invalid input,
 * and suggest clear instructions on how to fix it.
 */
export const FormErrorMessage = forwardRef<FormErrorMessageProps, 'div'>((props, ref) => {
  const styles = useMultiStyleConfig('FormError', props);
  const ownProps = omitThemingProps(props);
  const field = useFormControlContext();

  if (!field?.isInvalid) return null;

  return (
    <StylesProvider value={styles}>
      <potion.div
        {...field?.getErrorMessageProps(ownProps, ref)}
        className={cx('potion-form__error-message', props.className)}
        __css={{
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
          ...styles.text,
        }}
      />
    </StylesProvider>
  );
});

if (__DEV__) {
  FormErrorMessage.displayName = 'FormErrorMessage';
}
