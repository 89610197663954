import React, { useState, useEffect, useRef } from 'react';
import {
  Center,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  PaginationContainer,
  Pagination,
  PaginationItem,
  HStack,
  usePagination as useTabPagination,
  useBreakpointValue,
  VStack,
  useMediaQuery,
  TableContainer,
  Skeleton,
} from '@companydotcom/potion';
import { PageDivider, FontAwesomeIcon, AppSpinner } from '@companydotcom/ui';
import { useTable, useSortBy, Column, Cell, usePagination } from 'react-table';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { faCaretUp as faCaretUpLight } from '@fortawesome/pro-light-svg-icons';
import type { InvoicesSharedProps } from './invoices';
import type { InvoiceData } from '../../../../services/acg/acg.types';
import { formatCheckoutPrice, getFriendlyACGDate } from '../../shared';
import { sortInvoiceLineItems } from '../utils/index';

export const InvoicesList: React.FC<InvoicesSharedProps> = props => {
  const {
    nextStep,
    invoiceData: data = [],
    isLoading,
    currentInvoice,
    setCurrentInvoice,
    currentDownloadUrl,
    downloadUrlLoading,
  } = props;

  const isPendingDownload = useRef('');

  const columns = React.useMemo(
    () => [
      {
        Header: 'Invoice #',
        accessor: 'invoiceNumber',
        Cell: ({ cell }: { cell: Cell }) =>
          isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Text textStyle="sm" fontWeight="bold">
              {cell?.value ?? ''}
            </Text>
          ),
      },
      {
        Header: 'Product',
        accessor: 'lineItem[0].lineItemDisplayName',
        sortType: 'basic',
        Cell: ({ cell }: { cell: Cell }) =>
          isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Button
              onClick={() => {
                setCurrentInvoice?.(cell.row.original);
                nextStep?.();
              }}
              variant="link"
              textAlign="left"
              fontSize="md"
            >
              {sortInvoiceLineItems(cell.row.original, true)}
            </Button>
          ),
      },
      {
        Header: 'Invoice Total',
        accessor: 'invoiceTotal',
        Cell: ({ cell }: { cell: Cell }) =>
          isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Text fontSize="md">{`$${formatCheckoutPrice(cell?.value)}` ?? ''}</Text>
          ),
      },
      {
        Header: 'Invoice Date',
        accessor: 'invoiceDate',
        Cell: ({ cell }: { cell: Cell }) =>
          isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Text fontSize="md">{getFriendlyACGDate(cell?.value) ?? ''}</Text>
          ),
      },
      {
        Header: '',
        accessor: 'downloadUrl',
        Cell: ({ cell }: { cell: Cell<any> }) => {
          const onRequestDownload = () => {
            if (currentInvoice?.invoiceKey !== cell.row.original.invoiceKey) {
              isPendingDownload.current = cell.row.original.invoiceKey;
              setCurrentInvoice?.(cell.row.original);
            } else {
              window.open(currentDownloadUrl?.downloadUrl);
            }
          };

          useEffect(() => {
            if (
              isPendingDownload.current === cell.row.original.invoiceKey &&
              currentInvoice?.invoiceKey === cell.row.original.invoiceKey &&
              !downloadUrlLoading
            ) {
              window.open(currentDownloadUrl?.downloadUrl);
              isPendingDownload.current = '';
            }
          }, [cell.row.original.invoiceKey]);

          return isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Button
              variant="outline"
              size="xs"
              value={cell.row.values.name}
              isLoading={downloadUrlLoading}
              onClick={onRequestDownload}
            >
              Print
            </Button>
          );
        },
      },
    ],
    [
      currentDownloadUrl?.downloadUrl,
      downloadUrlLoading,
      isLoading,
      nextStep,
      setCurrentInvoice,
      currentInvoice,
    ],
  );

  const headingSize = useBreakpointValue({ base: 'md', sm: 'xl' });

  return (
    <Center
      className="invoices__invoicesListStep"
      flexDirection="column"
      bg={['transparent', 'white']}
      py={[0, 8, 12]}
      px={[0, 4]}
    >
      <Box width="full" maxW={908}>
        <Box width="full" textAlign={['center', 'left']} mb={7}>
          <Heading size={headingSize}>Invoices</Heading>
          <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
        </Box>

        <SortableTable
          nextStep={nextStep}
          setCurrentInvoice={setCurrentInvoice}
          isLoading={isLoading}
          columns={columns}
          data={data}
        />
      </Box>
    </Center>
  );
};
interface SortableTableProps {
  columns: Column<object>[];
  data: readonly object[];
  nextStep?: () => void;
  setCurrentInvoice?: (arg: InvoiceData | {}) => void;
  isLoading: boolean | undefined;
}

export function SortableTable({
  data,
  columns,
  nextStep,
  setCurrentInvoice,
  isLoading,
}: SortableTableProps) {
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const [pages, setPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageCount = pageNumber * 10 - 9;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, gotoPage } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: isMobile ? 6 : 10,
        sortBy: [
          {
            id: 'invoiceNumber',
            desc: true,
          },
        ],
      },
      autoResetHiddenColumns: false,
    },
    useSortBy,
    usePagination,
  );
  const paginationBreakPoint = useBreakpointValue({ base: 0, md: 1 });

  const { items } = useTabPagination({
    count: pages,
    onChange: (e: any, value: number) => {
      gotoPage(value - 1);
      setPageNumber(value);
    },
    siblingCount: paginationBreakPoint,
  });

  useEffect(() => {
    if (data && data?.length) {
      setPages(Math.ceil(data?.length / 10));
    }
  }, [data]);

  return (
    <>
      {!isMobile &&
        data?.length > 0 &&
        (isLoading ? (
          <Box height="21px" mb={4} />
        ) : (
          <Text textStyle="sm" mb={4}>
            {`Showing ${data?.length ? pageCount : '0'} -
            ${
              data?.length <= 10
                ? data?.length
                : pageNumber * 10 > data?.length
                ? data?.length
                : pageNumber * 10
            }
            of ${data?.length}`}
          </Text>
        ))}
      <TableContainer>
        <Table {...getTableProps()}>
          {!isMobile && (
            <Thead opacity={isLoading ? 0.5 : 1} borderWidth="1px">
              {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Th fontSize="sm" {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.Header !== '' ? (
                        <HStack spacing={1}>
                          <Text>{column.render('Header')}</Text>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                icon={faCaretDown}
                                boxSize={5}
                                aria-label="sorted descending"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCaretUp}
                                boxSize={5}
                                aria-label="sorted ascending"
                              />
                            )
                          ) : (
                            <FontAwesomeIcon
                              icon={faCaretUpLight}
                              boxSize={5}
                              aria-label="default unsorted"
                            />
                          )}
                        </HStack>
                      ) : null}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
          )}
          {isLoading ? (
            <Tbody>
              <Tr>
                <Td border="0" colSpan={5}>
                  <AppSpinner
                    description="Retrieving your invoices..."
                    containerStyles={{
                      mt: 12,
                      mx: 'auto',
                    }}
                  />
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {!data || data?.length === 0 ? (
                <Tr borderBottomWidth="1px">
                  <Td textStyle="md" textAlign="center" py={8} colSpan={columns?.length ?? 5}>
                    There are no invoices to display
                  </Td>
                </Tr>
              ) : (
                page.map((row, i) => {
                  prepareRow(row);
                  return isMobile ? (
                    isLoading ? (
                      <Skeleton key={i} height={5} />
                    ) : (
                      <Box
                        key={i}
                        borderRadius="sm"
                        shadow="md"
                        mb={2}
                        className="invoice"
                        bg="white"
                        p={4}
                      >
                        <VStack width="full" align="flex-start">
                          <Text fontWeight="bold" textStyle="sm">
                            {/* @ts-ignore */}
                            {data && data?.[i]?.invoiceNumber}
                          </Text>
                          <VStack spacing={0} align="flex-start">
                            <Text textStyle="sm">Product Name:</Text>
                            <Button
                              onClick={() => {
                                //@ts-ignore
                                setCurrentInvoice?.(data?.[i]);
                                nextStep?.();
                              }}
                              variant="link"
                              fontSize="sm"
                              textAlign="left"
                            >
                              {sortInvoiceLineItems(data[i], true)}
                            </Button>
                          </VStack>
                          <HStack width="full" align="flex-end" justify="space-between">
                            <VStack align="flex-start">
                              <Text textStyle="sm">
                                {/* @ts-ignore */}
                                {getFriendlyACGDate(data?.[i].invoiceDate)}
                              </Text>
                              <Text textStyle="sm">
                                {/* @ts-ignore */}
                                {`$${formatCheckoutPrice(data?.[i]?.invoiceTotal)}`}
                              </Text>
                            </VStack>
                            <Button
                              variant="outline"
                              size="sm"
                              // @ts-ignore
                              onClick={() => window.open(data?.[i].downloadUrl)}
                            >
                              Print
                            </Button>
                          </HStack>
                        </VStack>
                      </Box>
                    )
                  ) : (
                    <Tr borderBottomWidth="1px" {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <Td py={8} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Td>
                      ))}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!data ||
      data?.length === 0 ||
      isLoading ||
      data?.length <= 10 ||
      (isMobile && data?.length <= 6) ? null : (
        <Center mt={6}>
          <Pagination variant="outline" size="sm">
            <PaginationContainer>
              {/* @ts-ignore */}
              {items.map((item, i) => (
                <PaginationItem key={i} {...item} />
              ))}
            </PaginationContainer>
          </Pagination>
        </Center>
      )}
    </>
  );
}
