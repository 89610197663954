import { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  Stack,
  Alert,
  AlertIcon,
  AlertDescription,
  FormControl,
  FormLabel,
  Skeleton,
} from '@companydotcom/potion';
import { InputField, SelectField } from '@companydotcom/ui';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  useGetEventForRegistrationQuery,
  useLazyGetEventForRegistrationQuery,
} from '../../../../services/acg/acg-api';
import {
  useGetAcgQueryArgs,
  useGetAcgReferenceDataV2Query,
} from '../../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  SubTotalList,
  SelectedFeesList,
  useAcgCartStorage,
  useShowAddGuestButton,
  checkEventStatus,
} from '../../shared';
import { EventFees, EventForPurchase, Guest } from '../../../../services/acg/acg.types';
import {
  calculateTotalGuestFees,
  createGuestData,
  determineIfSingleFee,
  getGuestEventFees,
} from '../utils';
import { addGuestToRegister, selectGuestsToRegister } from '../add-guest-slice';
import { AddGuestProps } from '../types';
import { AppThunk } from '../../../../store';

export const getMyEventsNewGuestSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('Please enter a First Name'),
    lastName: yup.string().required('Please enter a Last Name'),
    individualType: yup.string().required('Please enter a Function/Role'),
    emailAddress: yup.string().required('Please enter an Email'),
    badgeName: yup.string().required('Please enter a Badge First Name'),
    organizationName: yup.string().required('Please enter an Organization'),
    title: yup.string().required('Please enter a Title'),
    guestRegistrationType: yup.string().notRequired(),
    fees: yup
      .array()
      .min(1, 'Please select at least one fee')
      .required('Please select at least one fee')
      .nullable(),
  });

export const AddNewGuest = ({
  goToStep,
  eventId,
  isLoading,
  createEventForPurchaseHandler,
  showWaitListAlert,
}: Partial<AddGuestProps>) => {
  const [pageError, setPageError] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { clearLocalCart, addEventToLocalCart } = useAcgCartStorage();
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const guestsToRegister = useAppSelector(selectGuestsToRegister);
  const [getEvent] = useLazyGetEventForRegistrationQuery();

  const { data: eventForRegistration } = useGetEventForRegistrationQuery(
    eventId && globalUser ? { user: globalUser, eventId } : skipToken,
  );

  const { data: acgRefData, isLoading: isAcgRefDataLoading } = useGetAcgReferenceDataV2Query({
    ...args,
    referenceData: ['individualType'],
  });

  const hasOneFee = !!determineIfSingleFee(eventForRegistration);

  const defaultValues = {
    firstName: '',
    lastName: '',
    badgeName: '',
    emailAddress: '',
    organizationName: '',
    individualType: '',
    title: '',
    guestRegistrationType: '',
    fees: [] as EventFees[],
  };

  // Hooks
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getMyEventsNewGuestSchema()),
    defaultValues,
  });
  const watchedFees = useWatch({ control, name: 'fees' });

  const guestEventFees = useMemo(() => {
    return getGuestEventFees(eventForRegistration);
  }, [eventForRegistration]);

  useEffect(() => {
    if (eventForRegistration) {
      reset({
        fees: hasOneFee ? guestEventFees : ([] as EventFees[]),
        guestRegistrationType:
          eventForRegistration?.registrantAndFees?.[0]?.guests?.[0]?.registrant_type
            .registrantTypeId,
      });
    }
  }, [eventForRegistration, guestEventFees, hasOneFee, reset]);

  const shouldShowGuestButton = useShowAddGuestButton(
    eventForRegistration,
    eventForRegistration?.registrantAndFees?.[0]?.guests?.[0],
    guestsToRegister.length + 1,
  );
  const addGuestSubmitHandler = async (data: typeof defaultValues) => {
    try {
      if (typeof data.fees[0] === 'string') {
        data.fees = guestEventFees?.filter(i => i?.priceKey === (watchedFees?.[0] as any));
      }
      const guestData = createGuestData(data, eventForRegistration, undefined) as Guest;
      dispatch(addGuestToRegister(guestData));
      goToStep?.('add-guest-search');
    } catch (err) {
      console.log('👉 ~ onSubmit ~ err', err);
      setPageError(true);
    }
  };

  function addGuestToRegisterThunk(data?: typeof defaultValues): AppThunk<EventForPurchase> {
    return (dispatch, getState) => {
      const guestData = createGuestData(data, eventForRegistration, undefined) as Guest;

      dispatch(addGuestToRegister(guestData));

      const state = getState();

      const eventForPurchase = createEventForPurchaseHandler?.(
        data,
        eventForRegistration,
        state.acgAddGuest.guestsToRegister,
      ) as EventForPurchase;

      return eventForPurchase;
    };
  }

  const addToCartSubmitHandler = async (data: typeof defaultValues) => {
    try {
      if (typeof data.fees[0] === 'string') {
        data.fees = guestEventFees?.filter(i => i?.priceKey === (watchedFees?.[0] as any));
      }
      const eventForPurchase = dispatch(addGuestToRegisterThunk(data));

      clearLocalCart();
      addEventToLocalCart(eventForPurchase);

      if (globalUser && eventId) {
        const latestEventData = await getEvent({ user: globalUser, eventId }).unwrap();
        const {
          shouldShouldWaitlistAlert,
          shouldShowEventFullAlert,
          shouldShowEventOverCapacityAlert,
        } = checkEventStatus(latestEventData, eventForPurchase.guests?.length);
        navigate('/acg-shopping-cart', {
          state: {
            shouldShouldWaitlistAlert,
            shouldShowEventFullAlert,
            shouldShowEventOverCapacityAlert,
            totalWaitList: latestEventData?.totalWaitList,
          },
        });
      }
    } catch (err) {
      console.log('👉 ~ onSubmit ~ err', err);
      setPageError(true);
    }
  };

  return (
    <Box width="full" maxW={908}>
      {pageError && (
        <Alert status="error" variant="solid" mb={[8, 12]}>
          <AlertIcon />
          {/* // TODO: Add error message */}
          Error
        </Alert>
      )}
      {showWaitListAlert && (
        <Alert status="warning" my={10}>
          <AlertIcon />
          <AlertDescription fontSize="sm" lineHeight="5">
            <Text fontWeight="bold">There’s a Waitlist for this Event</Text>
            <Text>
              You can still register for this event and will have a pro forma invoice generated for
              your registration. If you’re moved off the waitlist, you will be notified of this
              change and charged your registration.
            </Text>
            {eventForRegistration?.hasWaitList && (
              <Text fontWeight="bold" fontStyle="italic">
                {eventForRegistration?.totalWaitList === 0
                  ? 'You will be the first person placed on the waitlist unless someone completes an order before you.'
                  : `Currently, there are ${eventForRegistration?.totalWaitList} people on the waitlist.`}
              </Text>
            )}
          </AlertDescription>
        </Alert>
      )}
      <Box as="form">
        <Stack
          direction={['column', null, 'row']}
          align="flex-start"
          width="full"
          spacing={[6, null, 8, 12]}
        >
          <VStack width="full" spacing={6} alignItems="flex-start">
            <Text lineHeight={1.25} textStyle={['lg', '3xl']} fontWeight="bold" opacity={0.7}>
              Guest Information
            </Text>
            <InputField
              register={register}
              isLoading={isLoading}
              name="firstName"
              label="First Name"
              helperText="Required"
              errors={errors}
            />
            <InputField
              register={register}
              isLoading={isLoading}
              name="lastName"
              label="Last Name"
              helperText="Required"
              errors={errors}
            />
            <SelectField
              register={register}
              isLoading={isLoading || isAcgRefDataLoading}
              name="individualType"
              label="Function/Role"
              errors={errors}
              helperText="Required"
            >
              <option value="" />
              {acgRefData?.individualType?.map(o => (
                <option key={o?.recordName} value={o?.recordName!}>
                  {o?.recordName}
                </option>
              ))}
            </SelectField>
            <InputField
              register={register}
              isLoading={isLoading}
              name="emailAddress"
              label="Email"
              errors={errors}
              helperText="Required"
            />
            <FormControl>
              <FormLabel>Registration Type</FormLabel>
              <Skeleton isLoaded={!isLoading}>
                <Text fontWeight={600}>
                  {
                    eventForRegistration?.registrantAndFees?.[0]?.guests?.[0]?.registrant_type
                      .registrantTypeName
                  }
                </Text>
              </Skeleton>
            </FormControl>
          </VStack>
          <VStack width="full" spacing={6} alignItems="flex-start">
            <Text lineHeight={1.25} textStyle={['lg', '3xl']} fontWeight="bold" opacity={0.7}>
              Badge Information
            </Text>
            <InputField
              isLoading={isLoading}
              register={register}
              label="Badge First Name"
              name="badgeName"
              errors={errors}
              helperText="Required"
            />
            <InputField
              isLoading={isLoading}
              register={register}
              name="organizationName"
              label="Organization"
              helperText="Required"
              errors={errors}
            />
            <InputField
              isLoading={isLoading}
              register={register}
              label="Title"
              name="title"
              errors={errors}
              helperText="Required"
            />
          </VStack>
        </Stack>

        <SelectedFeesList
          hasOneFee={hasOneFee}
          data={eventForRegistration?.registrantAndFees?.[0]?.guests?.[0]?.packages}
          mt={20}
          register={register}
          name="fees"
          fees={watchedFees}
          isAddGuestReg={true}
        />

        <SubTotalList
          isSubmitting={isSubmitting}
          isValid={isValid}
          total={calculateTotalGuestFees(hasOneFee, guestEventFees, watchedFees as any[])}
          maxW={[null, null, 296]}
          ml="auto"
          addGuestSubmitHandler={handleSubmit(addGuestSubmitHandler)}
          addToCartSubmitHandler={handleSubmit(addToCartSubmitHandler)}
          showAddGuestButton={shouldShowGuestButton}
        />
      </Box>
    </Box>
  );
};
