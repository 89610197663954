import React, { useMemo } from 'react';
import * as yup from 'yup';
import { InputField } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { Box, Text, Button, Heading, Center } from '@companydotcom/potion';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { gtm } from '../../../lib/google-tag-manager';
import type { DataCollectorFormProps } from '../../../features/data-collector';
import {
  useGetGlobalUserQuery,
  useUpdateMailboxMutation,
  useUpdateUserMutation,
} from '../../../services/user/user-api';
import { useAwaitableFacade } from '../../../hooks';
// import { updateMailboxUsername } from '../../../services/user/old-api/user-svc';

const getEmailFormSchema = () =>
  yup.object().shape({
    username: yup
      .string()
      .min(3)
      .required()
      .matches(/^[a-zA-Z0-9_-]*$/, {
        message: 'forms.email.inputs.username.error',
        excludeEmptyString: true,
      })
      .test('Email Address', 'forms.email.inputs.username.validation', val =>
        yup.string().email().isValid(`${val}@anydomain.com`),
      ),
  });

export const EmailForm: React.FC<DataCollectorFormProps> = ({ handleDataFormFill }) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [updateMailboxUsername] = useUpdateMailboxMutation();
  const [updateUser] = useUpdateUserMutation();
  const snsInterface = useAwaitableFacade(globalUser, 'mailBox');
  const { t } = useTranslation();

  const defaultValues = {
    username: globalUser?.mailbox || globalUser?.firstName || '',
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(getEmailFormSchema()),
    defaultValues,
  });

  const watchUsername = watch('username');

  const fqdn = useMemo(() => {
    return !globalUser?.account?.isEmailFQDNCustom
      ? globalUser?.account?.fqdnCompany
      : globalUser?.account?.fqdnCustom;
  }, [
    globalUser?.account?.fqdnCompany,
    globalUser?.account?.fqdnCustom,
    globalUser?.account?.isEmailFQDNCustom,
  ]);

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      if (globalUser) {
        gtm('editUsernameConfirm');
        await updateUser({
          userId: globalUser.userId,
          mailbox: values.username,
        });
        updateMailboxUsername({ mailbox: values.username, snsInterface })
          .then(() => {
            handleDataFormFill();
          })
          .catch(err => {
            console.log('Error!: ', err);
          });
      }
    } catch (err) {
      console.log('Error submitting email form: ', err);
    }
  };

  return (
    <Center
      className="form-emailForm__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box textAlign="center">
        <Heading as="h1" size="hs-xl">
          {t('forms.email.heading')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          {t('forms.email.subheading')}
        </Heading>
      </Box>
      <Box as="form" className="form-emailForm__form" maxWidth={430} width="full" mt={[8, 10]}>
        <Box
          textAlign="center"
          width="full"
          maxW={564}
          border="1px solid"
          borderColor="gray.100"
          p={6}
        >
          <Text textStyle="md" mb={2}>{`${
            watchUsername ? watchUsername.toLowerCase() : ''
          }@${fqdn}`}</Text>
          <InputField
            className="form-emailForm__usernameField"
            data-test="form-emailForm__usernameField"
            register={register}
            name="username"
            label={t('forms.email.inputs.username.label')}
            errors={errors}
          />
        </Box>
        <Box textAlign="center" mt={[8, 12]}>
          <Button
            className="form-emailForm__submitButton"
            data-test="form-emailForm__submitButton"
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >
            {t('forms.email.submitButton')}
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
