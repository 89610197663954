import React from 'react';
import { Box, Text } from '@companydotcom/potion';

export const NewFlag = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 6000,
    }}
  >
    <Box sx={{ position: 'relative' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="68" height="24" viewBox="0 0 68 24">
        <path
          fill="#3c95ba"
          fillRule="evenodd"
          d="M4 0h64l-8.974 21.538A4 4 0 0 1 55.333 24H0V4a4 4 0 0 1 4-4z"
        />
      </svg>
      <Text
        color="white"
        fontSize="14px"
        fontWeight="500"
        sx={{ position: 'absolute', top: 1, left: '15px' }}
      >
        NEW
      </Text>
    </Box>
  </Box>
);
