var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, __DEV__, PinInput, PinInputField, FormLabel, HStack, } from '@companydotcom/potion';
import { Controller } from 'react-hook-form';
import { TranslationErrorMessage } from './translation-error-message';
export const PinCodeField = props => {
    const { name, length, control, helperText, label, formControlStyles, placeholder = '', errors } = props, rest = __rest(props, ["name", "length", "control", "helperText", "label", "formControlStyles", "placeholder", "errors"]);
    return (_jsx(Controller, { control: control, name: name, render: ({ field: { onChange, value, name, ref }, fieldState: { invalid, error } }) => {
            var _a;
            return (_jsxs(FormControl, Object.assign({ id: name, isInvalid: invalid, ref: ref, sx: formControlStyles }, { children: [_jsx(FormLabel, { children: label !== null && label !== void 0 ? label : '' }, void 0), _jsx(HStack, { children: _jsx(PinInput, Object.assign({ value: value, onChange: onChange, placeholder: placeholder }, rest, { children: [...Array(length).keys()].map((_, i) => (_jsx(PinInputField, {}, i))) }), void 0) }, void 0), helperText && !error && _jsx(FormHelperText, { children: helperText !== null && helperText !== void 0 ? helperText : '' }, void 0), errors && (_jsx(TranslationErrorMessage, Object.assign({ errors: errors, name: name }, { children: errors && (errors === null || errors === void 0 ? void 0 : errors[name]) && ((_a = errors === null || errors === void 0 ? void 0 : errors[name]) === null || _a === void 0 ? void 0 : _a.message) }), void 0))] }), void 0));
        } }, void 0));
};
if (__DEV__) {
    PinCodeField.displayName = 'PinCodeField';
}
