import React, { useEffect, useState, useCallback, useReducer, useRef } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { companyConstants, companyHelpers } from '@companydotcom/helpers';
import { useCuiContext, useSource, useMitt } from '@companydotcom/providers';
import {
  Flex,
  useMultiStyleConfig,
  useStyles,
  StylesProvider,
  FlexProps,
  Heading,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  FormLabel,
  Switch,
  potion,
  Box,
  HStack,
  Button,
  Text,
  useBreakpointValue,
  VStack,
  Stack,
} from '@companydotcom/potion';
import { AppSpinner, PageDivider } from '@companydotcom/ui';
import { UserGroup, UserTile } from '@companydotcom/types';
import { useTranslation } from 'react-i18next';
import { useGroups } from '../../../providers';
import { filterSettingReducer } from '../utils/filter-settings-reducer';
import { TileGroupFilter } from '../../../features/tile-group-filter';
import { useTiles } from '../../../providers/tiles-provider';
import { BYOP } from '../../../features/byop';
import {
  TileGroup,
  TileGroupHeader,
  TileGroupItem,
  TileGroupExpandButton,
  TileGroupDivider,
  TileGroupSettingsButton,
} from '../../../components/elements/tile-group';
import { DashboardTile } from '../../../features/dashboard-tile';
import { ACGTopRail } from '../../../features/acg';
import { useToast, useAppSelector } from '../../../hooks';
import {
  useGetGlobalUserQuery,
  useUpdateUserMutation,
  useSetUserTileStateLiteMutation,
  useSetUserTileGroupMutation,
} from '../../../services/user/user-api';
import {
  useGetMembershipsV2Query,
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
} from '../../../services/acg/acg-api-v2';
import { useUpdateByopTileMutation } from '../../../services/tile/tile-api';
import { selectIsUserDataCollectorsEmpty } from '../../../features/data-collector/data-collector-slice';

export interface TileProps {}

export const Tiles: React.FC<TileProps> = props => {
  const styles = useMultiStyleConfig('Tiles', props);
  const topRailBreakpoint = useBreakpointValue({ base: null, md: '908px', sm: null });
  const { tiles, getUpdatedUserTileData } = useTiles();
  const source = useSource();
  const groups = useGroups();
  let initialFocusRef = useRef<HTMLInputElement | null>(null);
  const toast = useToast();
  const { emitter } = useMitt();
  const { t } = useTranslation();
  const { data: globalUser } = useGetGlobalUserQuery();

  const args = useGetAcgQueryArgs();
  const isUserDataCollectorsEmpty = useAppSelector(selectIsUserDataCollectorsEmpty);
  const [updateByopTile] = useUpdateByopTileMutation();
  // todo: figure out to remove acg hard coding from this file
  const { isLoading: isAcgProfileLoading } = useGetAcgUserProfileV2Query(
    source.sourceId === 'acg' && globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const { data: membershipData, isLoading: isMembershipLoading } = useGetMembershipsV2Query(
    source.sourceId === 'acg'
      ? {
          ...args,
          stateCurrent: 'inactive',
        }
      : skipToken,
  );

  const [setUserTileStateLite] = useSetUserTileStateLiteMutation();
  const [setUserTileGroup] = useSetUserTileGroupMutation();
  const [updateUser] = useUpdateUserMutation();

  const firstTimeUser = !!globalUser?.userDataCollectors?.length && !isUserDataCollectorsEmpty;

  const defaultFilterState = {
    showAllTiles: useState(globalUser?.userSettings?.showAllTiles),
    setUserTileStateLite,
    setUserTileGroup,
    updateUser,
    visibleGroups: [] as UserGroup[],
    visibleTiles: [] as UserTile[],
    allGroups: [] as UserGroup[],
    allTiles: [] as UserTile[],
  };

  const [{ visibleGroups, visibleTiles, allGroups, showAllTiles }, dispatchFilter] = useReducer(
    filterSettingReducer,
    defaultFilterState,
  );

  const { userCuis } = useCuiContext();

  const hideGroup = useCallback(
    ({ groupId, isHidden }) => {
      dispatchFilter({
        type: 'GROUP_FILTER_CHANGED',
        payload: {
          userId: globalUser?.userId,
          allGroups,
          groupId,
          isHidden,
          showAllTiles,
          updateByopTile,
        },
      });
    },
    // eslint-disable-next-line
    [dispatchFilter],
  );

  const hideTile = useCallback(
    e => {
      dispatchFilter({ type: 'TILE_FILTER_CHANGED', payload: e });
    },
    [dispatchFilter],
  );

  useEffect(() => {
    if (globalUser?.userId) {
      if (
        membershipData?.status === 'Pending' ||
        membershipData?.status === 'Lapsed' ||
        (membershipData?.status === 'Inactive' && source.sourceId === 'acg')
      ) {
        setUserTileStateLite({
          input: {
            userId: globalUser.userId,
            tileId: '03e080c5-d5ce-4e32-a76d-6f2e936b9564',
            isHidden: true,
            stateCurrent: 'active',
          },
        });
      }
      if (membershipData?.status === 'Active' && source.sourceId === 'acg') {
        setUserTileStateLite({
          input: {
            userId: globalUser.userId,
            tileId: '03e080c5-d5ce-4e32-a76d-6f2e936b9564',
            isHidden: false,
            stateCurrent: 'active',
          },
        });
      }
      return () => {
        initialFocusRef.current = null;
      };
    }
  }, [globalUser?.userId, membershipData?.status, setUserTileStateLite, source.sourceId]);

  useEffect(() => {
    dispatchFilter({
      type: 'TILE_INPUT_CHANGED',
      payload: { tiles, groups, showAllTiles, allGroups, allTiles: tiles },
    });
    // eslint-disable-next-line
  }, [dispatchFilter, tiles]);

  useEffect(() => {
    dispatchFilter({ type: 'GROUP_INPUT_CHANGED', payload: { groups, showAllTiles } });
    // eslint-disable-next-line
  }, [dispatchFilter, groups]);

  useEffect(() => {
    dispatchFilter({
      type: 'SET_SHOW_ALL',
      payload: {
        userId: globalUser?.userId,
        showAllTiles: globalUser?.userSettings?.showAllTiles,
        updateUser: false,
        groups,
        tiles,
      },
    });
    // eslint-disable-next-line
  }, [dispatchFilter, globalUser]);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.hideGroupFromFilter, (e: any) =>
      dispatchFilter({
        type: 'GROUP_FILTER_CHANGED_MULTI',
        payload: { groupSettings: e, allGroups, userId: globalUser?.userId, showAllTiles },
      }),
    );

    return () => {
      emitter.off(companyConstants.platformEvents.hideGroupFromFilter);
    };
  }, [allGroups, emitter, globalUser?.userId, showAllTiles]);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.hideTile, hideTile);

    return () => {
      emitter.off(companyConstants.platformEvents.hideTile);
    };
  }, [emitter, hideTile]);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.showAllTiles, (e: any) => {
      if (globalUser?.userId) {
        dispatchFilter({
          type: 'SET_SHOW_ALL',
          payload: {
            showAllTiles: e.showAllTiles,
            updateUser: true,
            userId: globalUser?.userId,
            groups,
            tiles,
          },
        });
      }
    });

    return () => {
      emitter.off(companyConstants.platformEvents.showAllTiles);
    };
  }, [emitter, globalUser?.userId, groups, tiles]);

  const handleHideGroup = (groupId: string, isHidden: boolean) => {
    const label = visibleGroups.find((g: any) => g.groupId === groupId)?.label;

    const undoHideGroup = (oldIsHidden: boolean) => {
      emitter.emit(companyConstants.platformEvents.hideGroupFromTile, {
        groupId,
        isHidden: oldIsHidden,
      });
    };
    if (visibleGroups.length === 1 && visibleGroups[0].groupId === groupId) {
      toast({
        description: `Please unhide another group before hiding ${label}`,
        status: 'error',
        isClosable: true,
      });
    }
    toast({
      description: `${label} Hidden`,
      status: 'info',
      isClosable: true,
      render: () => (
        <Flex flexDir="row" color="white" p={3} bg="blue.500">
          <Text>{`${label} Hidden`}</Text>{' '}
          <Button variant="link" color="white" onClick={() => undoHideGroup(isHidden)}>
            Undo
          </Button>
        </Flex>
      ),
    });

    if (globalUser?.userId) {
      setTimeout(() => {
        hideGroup({ groupId, isHidden: !isHidden });
      }, 250);
    }
  };

  if (
    !visibleGroups?.length ||
    !tiles?.length ||
    firstTimeUser ||
    (source.sourceId === 'acg' && (isAcgProfileLoading || isMembershipLoading))
  ) {
    return (
      <AppSpinner
        className="loading-tiles-spinner"
        description={t('containers.tilesPage.loadingText')}
        containerStyles={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }

  return (
    <potion.div className="page__tiles" width="full" height="full">
      {source?.sourceId === 'acg' && (
        <VStack display={['flex', null, 'none']} spacing={5} bg="#F2F2F2">
          <Stack alignItems="center" justifyContent="center">
            <Heading fontSize="18px">My ACG</Heading>
            <PageDivider width="51px" mb={5} />
          </Stack>
        </VStack>
      )}
      <StylesProvider value={styles}>
        {topRailBreakpoint && (
          <TilesTopRail justifyContent={source?.byopConfig?.enabled ? 'space-between' : 'flex-end'}>
            {source?.sourceId === 'acg' ? (
              <ACGTopRail />
            ) : (
              <>
                {source?.byopConfig?.enabled && <BYOP />}
                {allGroups.length > 1 && (
                  <TileGroupFilter userGroups={allGroups} showAllTiles={showAllTiles} />
                )}
              </>
            )}
          </TilesTopRail>
        )}
        {visibleGroups?.map((group: UserGroup) => {
          return (
            <TileGroup key={group.groupId}>
              <TileGroupHeader>
                <HStack justifyContent="space-between">
                  <Heading size="hs-md">{group.name}</Heading>
                  <Box>
                    <TileGroupExpandButton aria-label="Expand tile group" marginRight={3} />
                    <Popover placement="bottom-end" initialFocusRef={initialFocusRef}>
                      <PopoverTrigger>
                        <TileGroupSettingsButton aria-label="Expand tile settings" />
                      </PopoverTrigger>
                      <PopoverContent width="inherit">
                        <PopoverBody>
                          <Flex alignItems="center" justifyContent="flex-end">
                            <FormLabel htmlFor="hide-tile-group" mb="0" fontSize="sm">
                              Show on Dashboard?
                            </FormLabel>
                            <Switch
                              ref={initialFocusRef}
                              id="hide-tile-group"
                              defaultChecked={!group.isHidden}
                              onChange={() => {
                                return handleHideGroup(
                                  group.groupId as string,
                                  group.isHidden as boolean,
                                );
                              }}
                            />
                          </Flex>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>
                </HStack>
                <TileGroupDivider borderColor={group.color ?? 'inherit'} />
              </TileGroupHeader>
              <TileGroupItem>
                {visibleTiles
                  .filter((tile: UserTile) => group.groupId === tile.groupId)
                  .map((tile: UserTile) => {
                    if (tile.isHidden !== true) {
                      return (
                        <DashboardTile
                          key={tile.tileSlug}
                          tile={tile}
                          tiles={tiles}
                          refreshUserTileData={getUpdatedUserTileData}
                          userCui={
                            userCuis?.userCuis?.find(
                              cui => cui?.tileName === companyHelpers.formatTileName(tile.tileName),
                            )!
                          }
                        />
                      );
                    }
                  })}
              </TileGroupItem>
            </TileGroup>
          );
        })}
      </StylesProvider>
    </potion.div>
  );
};

export const TilesTopRail: React.FC<FlexProps> = props => {
  const styles = useStyles();

  return <Flex sx={styles.topRail} className="potion-tiles__toprail" {...props} />;
};
