import React from 'react';
import { useTranslation } from 'react-i18next';
import { Center, Heading, Button, Stack, Text, VStack, Box } from '@companydotcom/potion';
import { User } from '@companydotcom/types';
import { useNavigate } from 'react-router-dom';
import { PageHeading } from '../../../../components/elements';
import { AcgMemberships } from '../../../../services/acg/acg.types';
import { useCreateCartForInvoiceMutation } from '../../../../services/acg/acg-api';
import { useAppDispatch } from '../../../../hooks';
import { setRenewInvoice, setSelectedPackage } from '../../acg-checkout/checkout-slice';
import { MemberIcon } from '../components/memberIcon-asset';
import { useAcgCartStorage } from '../../shared';

export interface LapsedMemberProps {
  isMobile?: boolean;
  globalUser?: User;
  membership?: AcgMemberships | any;
}

export const LapsedMember: React.FC<LapsedMemberProps> = props => {
  const { isMobile, globalUser, membership } = props;
  const { memberships } = membership;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createCart, { isLoading }] = useCreateCartForInvoiceMutation();
  const { addMembershipToLocalCart } = useAcgCartStorage();
  const { t } = useTranslation();

  const handleOnClick = async () => {
    try {
      dispatch(setSelectedPackage(memberships?.[0]));

      const res = await createCart({
        user: globalUser as User,
        payload: {
          invoiceId: memberships?.[0]?.openInvoices?.invoiceRecordKey,
        },
      }).unwrap();

      if (res?.length) {
        dispatch(setRenewInvoice(res));
        addMembershipToLocalCart({ ...res[0], ...memberships?.[0] });
        navigate('/acg-shopping-cart', { state: { flow: 'checkout' } });
      }
    } catch (err) {
      throw new Error();
    }
  };

  return (
    <Center
      className="member-directory__become-a-member"
      flexDirection="column"
      px={[0, 4]}
      borderRadius="sm"
      py={[0, 8, 12]}
      bg={['transparent', 'white']}
    >
      <Box maxW={908} width="full">
        <PageHeading heading={t(`acg.memberSearch.search.header`)} />
        <VStack py={[4]} px={[4]} my={[4]} spacing={[4, 12]} bg={['white']}>
          <VStack>
            <MemberIcon width={[60, 120]} height={[65, 130]} />
            <Heading size={isMobile ? 'md' : 'hs-xl'} textAlign="center" color="#717174b5">
              {t(`acg.memberSearch.search.membershipStatus.lapsed`)}
            </Heading>
          </VStack>
          {!isMobile && (
            <Stack
              spacing={4}
              fontSize={18}
              fontWeight="bold"
              my={10}
              alignItems="center"
              textAlign="center"
            >
              <Text>Member-Exclusive Events and Programming</Text>
              <Text>Member-Only Discounts</Text>
              <Text>Middle Market Insights Webinars</Text>
            </Stack>
          )}
          <Button size="lg" onClick={handleOnClick} isLoading={isLoading}>
            CONTINUE
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};
