import React from 'react';
import { VStack, HStack, Text } from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import type { TileComponentProps } from '../../types';
import { Rating } from '../../../../components/elements/rating';

export interface TileStarRatingProps extends TileComponentProps {}

export const TileStarRating: React.FC<TileStarRatingProps> = props => {
  const { eventData, componentTheme } = props;
  const { data } = useEventData<any>(eventData);
  const { t } = useTranslation();
  const reviews = t('tiles.starRating.reviews');

  const dataArray = [
    { value: 'fiveStar', rating: 5 },
    { value: 'fourStar', rating: 4 },
    { value: 'threeStar', rating: 3 },
    { value: 'twoStar', rating: 2 },
    { value: 'oneStar', rating: 1 },
  ];

  return (
    <VStack className="tile-tileStarRating" alignItems="baseline" spacing={3} sx={componentTheme}>
      {dataArray.map(({ value, rating }) => (
        <HStack key={rating} spacing={8} justifyContent="space-between">
          <Rating scale={5} rating={rating} />
          <Text fontWeight="bold" textStyle="sm">{`${
            data?.counts ? data?.counts?.[value] : 0
          } ${reviews}`}</Text>
        </HStack>
      ))}
    </VStack>
  );
};
