import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { Text, Center, Heading } from '@companydotcom/potion';
import { faTrafficCone } from '@fortawesome/pro-regular-svg-icons';

export const CountryFilter = () => {
  const { t } = useTranslation();
  return (
    <Center
      textAlign="center"
      flexDirection="column"
      layerStyle="formContainer"
      maxWidth={704}
      width="full"
      mx="auto"
      px={4}
      py={[8, null, 12]}
    >
      <FontAwesomeIcon mb={[8]} color="orange.500" icon={faTrafficCone} boxSize={[14, 28]} />

      <Heading maxW="md" size="hs-2xl">
        {t('subpage.countryFilter.header')}
      </Heading>
      <Text textStyle="md" maxW="md" mt={8}>
        {t('subpage.countryFilter.subheader')} {t('subpage.countryFilter.span')}
      </Text>
    </Center>
  );
};
