import { GetAllAcgChaptersAndPackagesResponse } from '@companydotcom/types';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useLocalStorage } from '../../../../hooks';
import {
  // ChapterPackage,
  Chapter,
  EventForPurchase,
} from '../../../../services/acg/acg.types';

dayjs.extend(duration);

interface CartStorageInterface {
  expiry?: Dayjs | Date;
  selectedChapter?: Chapter | any;
  selectedPackage?: GetAllAcgChaptersAndPackagesResponse | any;
  items?: { memberships?: any; event?: EventForPurchase };
  storedPayment?: {
    cardHolderName?: string;
    cardExpiry?: string;
    cardNumber?: string;
    accountNumber?: string;
    accountHolderName?: string;
    routingNumber?: string;
    accountType?: string;
  };
}

export function useAcgCartStorage() {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_localShoppingCartIgnored, setLocalShoppingCart] = useLocalStorage<CartStorageInterface>({
    key: 'acg-cart',
    defaultValue: {
      expiry: undefined,
      selectedChapter: {} as Chapter,
      selectedPackage: {} as GetAllAcgChaptersAndPackagesResponse,
      items: {
        memberships: [],
        event: undefined,
      },
      storedPayment: undefined,
    },
  });

  // TODO: retype this
  const addMembershipToLocalCart = (membership: any) => {
    const parsed = JSON.parse(
      window.localStorage.getItem('acg-cart') as string,
    ) as CartStorageInterface;

    setLocalShoppingCart({
      ...parsed,
      expiry: dayjs().add(dayjs.duration({ days: 1 })),
      items: {
        ...parsed?.items,
        memberships: [membership],
      },
    });
  };

  const removeMembershipFromLocalCart = (recordKey: string) => {
    const parsed = JSON.parse(
      window.localStorage.getItem('acg-cart') as string,
    ) as CartStorageInterface;

    if (parsed?.items && parsed?.items?.memberships) {
      const newItems = parsed?.items?.memberships?.filter(
        (m: any) => m.recordKey !== recordKey && m.id !== recordKey,
      );
      setLocalShoppingCart({
        ...parsed,
        items: { ...parsed.items, memberships: newItems },
      });
    }
  };

  const addEventToLocalCart = (event: EventForPurchase) => {
    const parsed = JSON.parse(
      window.localStorage.getItem('acg-cart') as string,
    ) as CartStorageInterface;

    setLocalShoppingCart({
      ...parsed,
      expiry: dayjs().add(dayjs.duration({ days: 1 })),
      selectedChapter: {} as Chapter,
      items: {
        ...parsed?.items,
        event,
      },
    });
  };

  const removeEventFeeFromLocalCart = (
    priceKey: string,
    product: string,
    guestIndex?: number,
    recordKey?: string,
  ) => {
    const parsed = JSON.parse(
      window.localStorage.getItem('acg-cart') as string,
    ) as CartStorageInterface;
    // Removes guest fees
    if (parsed?.items?.event?.guests && guestIndex !== undefined) {
      const newGuestFeeArray = parsed?.items.event.guests[guestIndex].fees?.filter(
        f => f.priceKey !== priceKey && f.productName !== product,
      );

      if (parsed.items.event.guests[guestIndex].fees) {
        parsed.items.event.guests[guestIndex].fees = newGuestFeeArray;
      }

      setLocalShoppingCart({
        ...parsed,
        items: { ...parsed?.items, event: parsed.items.event },
      });
    }

    // Remove session fees
    if (parsed?.items?.event?.registrant?.sessions) {
      const newSessionsArray = parsed?.items.event.registrant.sessions.filter(s => s !== product);

      if (parsed?.items?.event?.registrant?.sessions !== undefined) {
        parsed.items.event.registrant.sessions = newSessionsArray;
      }
    }

    // Removes registrant fees
    if (parsed?.items?.event?.registrant && !guestIndex) {
      const newRegistrantFeeArray = parsed?.items.event.registrant.fees?.filter(
        f => f.eventFees.priceKey !== priceKey && f.eventFees.productName !== product,
      );

      if (parsed.items.event.registrant.fees !== undefined) {
        parsed.items.event.registrant.fees = newRegistrantFeeArray;
      }

      if (newRegistrantFeeArray?.length) {
        setLocalShoppingCart({
          ...parsed,
          items: { ...parsed?.items, event: parsed.items.event },
        });
        // Deletes event from cart if all fees have been removed
      } else if (parsed?.items?.event?.eventRecordKey === recordKey) {
        delete parsed?.items?.event;
        setLocalShoppingCart({
          ...parsed,
          items: { ...parsed?.items, event: parsed.items.event },
        });
      }
    }
  };

  // TODO: retype this
  const addChapterToLocalCart = (selectedChapter: Chapter) => {
    const parsed = JSON.parse(
      window.localStorage.getItem('acg-cart') as string,
    ) as CartStorageInterface;

    setLocalShoppingCart({ ...parsed, selectedChapter });
  };

  const addPackageToLocalCart = (selectedPackage: GetAllAcgChaptersAndPackagesResponse) => {
    const parsed = JSON.parse(
      window.localStorage.getItem('acg-cart') as string,
    ) as CartStorageInterface;

    setLocalShoppingCart({ ...parsed, selectedPackage });
  };

  const clearLocalCart = () => {
    setLocalShoppingCart({
      expiry: undefined,
      selectedChapter: {} as Chapter,
      selectedPackage: {} as GetAllAcgChaptersAndPackagesResponse,
      items: { memberships: [], event: undefined },
      storedPayment: undefined,
    });
  };

  const getLocalCart = () => {
    const parsed = JSON.parse(
      window.localStorage.getItem('acg-cart') as string,
    ) as CartStorageInterface;

    if (!parsed) {
      return null;
    }

    if (parsed?.expiry && dayjs().isAfter(parsed?.expiry)) {
      clearLocalCart();
    }

    return parsed;
  };

  const storePaymentInfo = (paymentInfo: any) => {
    const parsed = JSON.parse(
      window.localStorage.getItem('acg-cart') as string,
    ) as CartStorageInterface;

    setLocalShoppingCart({ ...parsed, storedPayment: paymentInfo });
  };

  const removeGuestsFromCart = () => {
    const parsed = JSON.parse(
      window.localStorage.getItem('acg-cart') as string,
    ) as CartStorageInterface;

    if (!parsed) {
      return null;
    }

    setLocalShoppingCart({
      ...parsed,
      items: { ...parsed?.items, event: { ...parsed.items?.event, guests: undefined } },
    });
  };

  return {
    addChapterToLocalCart,
    addMembershipToLocalCart,
    addEventToLocalCart,
    getLocalCart,
    removeEventFeeFromLocalCart,
    setLocalShoppingCart,
    clearLocalCart,
    removeMembershipFromLocalCart,
    storePaymentInfo,
    addPackageToLocalCart,
    removeGuestsFromCart,
  };
}
