import { v4 as uuidv4 } from 'uuid';
import { companyConstants, platformHelpers } from '@companydotcom/helpers';
import { User, ExtendedSubscriptionTier, ExtendedSubscriptionRatePlan } from '@companydotcom/types';
import { isEmpty } from 'lodash';
import { NavigateFunction } from 'react-router-dom';
import { subscribeToNotifications } from '../../../services/notification/notification-svc';
// import { processOrderSummary } from '../../../services/user/old-api/user-svc';
// import { publishTileEvent } from '../../../services/event/old-api/event-svc';
// import { getAccountPaymentDetails } from '../../../services/payment/old-api/payment-svc';

export const savePaymentMethod = async (
  processOrderSummary: any,
  res: any,
  planId: string | undefined | null,
  user: User,
  productId: string,
  qty: any,
  immediateActivate: any,
  paymentMethodId: string,
  goToStep: any,
  setSubmitting: any,
  setError: any,
  tile: any,
  emitter: any,
  externalFlow: boolean,
  orderId: string,
  eventSource: string,
  zuoraProductRatePlanChargeId: string,
  cart: ExtendedSubscriptionRatePlan[],
  paymentMethod: any,
  publishTileEvent: any,
  getAccountPaymentDetails: any,
  selectedTiers?: ExtendedSubscriptionTier[],
  setPaymentData?: (arg: any) => void,
  navigate?: NavigateFunction,
) => {
  try {
    setSubmitting(true);
    let processOrderResponse: any;

    // legal inc doesn't go through provision-svc since what is being purchased isn't actually one of our products
    // transition the tile to active through a direct call.
    if (productId === process.env.REACT_APP_LEGALINC_PRODUCTID) {
      const eventId = uuidv4();

      const paymentInfo = {
        orderId,
        hpmCreditCardPaymentMethodId: res ? res.refId : paymentMethodId,
      };
      const eventToPublish = platformHelpers.publishPaymentEvent(
        paymentInfo,
        user,
        eventId,
        productId,
      );

      const subscription = await subscribeToNotifications(
        user.userId,
        async (notification: any) => {
          if (notification.userId === user.userId) {
            navigate?.('/');
          } else {
            navigate?.('/paymenterror', {
              replace: true,
              state: {
                productId,
                planid: planId,
              },
            });
          }
        },
      );

      await new Promise(r =>
        setTimeout(() => {
          //@ts-ignore give time for subscription to set up; I don't know why this is necessary
          r();
        }, 3000),
      );

      await publishTileEvent(eventToPublish);

      await new Promise(r =>
        setTimeout(() => {
          subscription.unsubscribe();
          //@ts-ignore plenty of time for the relevant notification to return but we want to cancel because the history.replace doesn't refresh dashboard fully
          r();
        }, 15000),
      );
    } else {
      if (eventSource === 'subscriptions' || eventSource === 'miop') {
        const orders = cart.map(plan => {
          return {
            productId: plan.productId || productId,
            ratePlanId: plan.ratePlanId,
            quantity:
              !isEmpty(selectedTiers) &&
              selectedTiers?.some(tier => tier.ratePlanId === plan.ratePlanId)
                ? selectedTiers?.find(tier => tier.ratePlanId === plan.ratePlanId)?.qty
                : qty || 1,
            ratePlanChargeId: plan.ratePlanChargeId,
            //@ts-ignore
            ratePlanId: plan.ratePlanId,
            immediateActivate: plan.immediateActivate,
            dateActivated: Date.now().toString(),
          };
        });

        processOrderResponse = await processOrderSummary({
          userId: user.userId,
          paymentMethodId: res ? res.refId : paymentMethodId || '',
          partnerBillingEnabled: user.account?.allowPartnerBilling,
          initiatedBy: 'user',
          //@ts-ignore
          orderSummary: orders,
        }).unwrap();
      } else {
        processOrderResponse = await processOrderSummary({
          userId: user.userId,
          paymentMethodId: res ? res.refId : paymentMethodId,
          partnerBillingEnabled: user.account?.allowPartnerBilling,
          orderSummary: [
            {
              productId,
              ratePlanId: planId as string,
              quantity: qty || 1,
              ratePlanChargeId: zuoraProductRatePlanChargeId,
              immediateActivate,
            },
          ],
        }).unwrap();
      }

      // If product is conferencing then emit this event too
      if (productId === 'b56b1889-fc6a-4d90-b222-1f05453d77d8') {
        await publishTileEvent(
          platformHelpers.formatPublishTileEventInput(
            {
              eventType: 'allocateSeat',
              componentTypes: null,
              topic: 'transition',
            },
            {
              user,
              account: user.account,
              tile,
            },
            null,
            'transition',
          ),
        );
        setSubmitting(false);
        goToStep('payment-success');
      }

      if (!paymentMethod && !user.account?.allowPartnerBilling && processOrderResponse) {
        paymentMethod = await getAccountPaymentDetails({
          zuoraAccountId: processOrderResponse.zuoraAccountId,
        }).unwrap();
      }

      if (externalFlow || eventSource === 'miop' || eventSource === 'subscriptions') {
        setSubmitting(false);
        setPaymentData?.({ paymentMethod, processOrderResponse });
        goToStep('payment-success');
      }
      emitter.on(companyConstants.tileActions.tileUpdated, () => {
        emitter.emit(companyConstants.platformEvents.paymentSucceeded, {});

        setSubmitting(false);
        setPaymentData?.({ paymentMethod, processOrderResponse });
        goToStep('payment-success');
      });
    }
  } catch (error) {
    console.log('Error saving payment method:', error);
    emitter.emit(companyConstants.tileActions.paymentFailed, {});
    setError('Something has gone wrong on our side. Please refresh or try again later.');
  }
};
