import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  Heading,
  VStack,
  Radio,
  RadioGroup,
  Stack,
  useBoolean,
  Icon,
  Center,
  HStack,
  Alert,
  AlertIcon,
  Flex,
} from '@companydotcom/potion';
import * as yup from 'yup';
import { User, Listing } from '@companydotcom/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/pro-solid-svg-icons';
import { useForm, Controller } from 'react-hook-form';
import { InputField, SelectField, ReactSelectFieldAsync } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { companyHelpers } from '@companydotcom/helpers';
import stateOptions from '../../utils/state-options';
import { PendingVerification } from './shared-blpcomponents';
import { useAwaitableFacade, useToast } from '../../../../hooks';
import { useLazyGetGmbCategoryAutocompleteQuery } from '../../../../services/search/search-api';
import {
  useLazyGetLocationAutocompleteResultsQuery,
  useUpdateListingMutation,
} from '../../../../services/listings/listing-api';
// import { getGmbCategoryAutocomplete } from '../../../../services/search/old-api/search-svc';

const getBasicInformationSchema = () =>
  yup.object().shape({
    address: yup.string().required('Please enter an address'),
    businessCategory: yup
      .mixed()
      .required('A business description is required')
      .test(
        'Category selected',
        'Please select a business category',
        val => val && val.name && val.id,
      )
      .nullable(),
    businessName: yup.string().required('Please enter a business name'),
    city: yup.string().required('Please enter a city'),
    serviceArea: yup.array().when('visitable', {
      is: (val: string) => {
        return val === 'notVisitable';
      },
      then: yup.array().required('Must select service area'),
    }),
    state: yup.string().required('Please enter a state'),
    visitable: yup
      .string()
      .oneOf(['visitable', 'notVisitable'])
      .required('Please select whether your business is visitable or not'),
    zipCode: yup
      .string()
      .required('Please enter a zip code')
      .test('zip code', 'Please enter a valid zip code', val =>
        companyHelpers.validateZipCode(val),
      ),
  });

export interface BasicInformationProps {
  user?: User;
  listing?: Listing;
  setListing?: (arg: Listing) => void;
  showPendingVerification?: boolean;
  // listingSvc?: any;
}

export const BasicInformation: React.FC<BasicInformationProps> = props => {
  const {
    user,
    listing,
    setListing,
    showPendingVerification,
    // listingSvc
  } = props;
  const [addAreas, setAddAreas] = useBoolean(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [getGmbCategoryAutocomplete] = useLazyGetGmbCategoryAutocompleteQuery();
  const [getLocationAutocompleteResults] = useLazyGetLocationAutocompleteResultsQuery();
  const [updateListing] = useUpdateListingMutation();
  const snsInterface = useAwaitableFacade(user, 'listing');

  const defaultValues = {
    businessName: listing?.name || '',
    address: listing?.address || '',
    addressLine2: listing?.addressExtra || '',
    zipCode: listing?.zip || '',
    city: listing?.city || '',
    state: listing?.state || user?.account?.businessPrimary?.address?.state || '',
    visitable: listing?.hideAddress ? 'notVisitable' : 'visitable',
    serviceArea: listing?.serviceArea?.places?.placeInfos || [],
    businessCategory:
      (listing?.categories || user?.account?.businessPrimary?.categories || []).filter(
        cat => cat?.publisher === 'GMB',
      )?.[0] || undefined,
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getBasicInformationSchema()),
    defaultValues,
  });
  const { t } = useTranslation();

  const watchVisitable = watch('visitable');

  const locationOptions = async (value: any) => {
    // const locations = await listingSvc.getLocationAutocompleteResults(value);
    const locations = await getLocationAutocompleteResults({ searchTerm: value }).unwrap();
    return locations;
  };

  const categoryOptions = async (value: any) => {
    const categories = await getGmbCategoryAutocomplete({ searchTerm: value })
      .unwrap()
      .catch(error => console.error('Rejected! ', error));
    return categories;
  };

  const visitableRadioOptions = [
    {
      label: 'Yes, they can',
      value: 'visitable',
    },
    {
      label: 'No, they cannot',
      value: 'notVisitable',
    },
  ];

  const onSubmit = async (values: typeof defaultValues) => {
    setIsLoading(true);
    try {
      const listingUpdates: any = {
        id: user?.account?.listingId,
        name: values.businessName,
        address: values.address,
        zip: values.zipCode,
        city: values.city,
        state: values.state,
        hideAddress: values.visitable === 'notVisitable',
        categories: [
          {
            fullName: values.businessCategory?.fullName,
            id: values.businessCategory?.id,
            name: values.businessCategory?.name,
            publisher: values.businessCategory?.publisher,
          },
        ],
        addressLine2: undefined,
      };

      if (
        (values.serviceArea && values.serviceArea.length) ||
        (listing?.serviceArea && (!values.serviceArea || !values.serviceArea.length))
      ) {
        listingUpdates.serviceArea = {
          businessType:
            values.visitable === 'notVisitable'
              ? 'CUSTOMER_LOCATION_ONLY'
              : 'CUSTOMER_AND_BUSINESS_LOCATION',
          places: values.serviceArea
            ? {
                placeInfos: values.serviceArea.map(({ name, placeId }: any) => ({
                  name,
                  placeId,
                })),
              }
            : {},
        };
        if (values.addressLine2) {
          listingUpdates.addressLine2 = values.addressLine2;
        }
      }

      // listingSvc.updateListing(listingUpdates).then((updatedListing: Listing) => {
      //   setListing?.(updatedListing);
      //   toast({
      //     description: 'Your changes have been synced!',
      //     status: 'success',
      //     duration: 9000,
      //     isClosable: true,
      //     position: 'bottom-right',
      //   });
      //   setIsLoading(false);
      // });

      if (user) {
        await updateListing({ listingInput: listingUpdates, snsInterface, source: user?.source })
          .unwrap()
          .then(updatedListing => {
            setListing?.(updatedListing);
            toast({
              description: 'Your changes have been synced!',
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
            setIsLoading(false);
          });
      }
    } catch (err) {
      toast({
        description: 'Error saving changes',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      });
      setIsLoading(false);
      console.log('Error!', err);
    }
  };

  const isGmbListingSynced = () => {
    return !!(
      (listing?.gmbLocationId && listing?.gmbStatus === 'pendingDirectVerification') ||
      listing?.gmbStatus === 'pendingExternalVerification' ||
      listing?.gmbStatus === 'verified'
    );
  };

  return (
    <Center
      className="blp-basic-information"
      flexDirection="column"
      pt={[12, null, 3]}
      pb={[12, null, 15]}
      width="full"
      px={4}
      textAlign="center"
      justifyContent="flex-start"
    >
      {showPendingVerification && <PendingVerification />}
      <Box maxW="xl">
        <Heading as="h1" size="hs-lg">
          Basic Business Information
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          Add your basic business information that customers can find when they look for your
          business in local searches.
        </Heading>
        <Text textStyle="md" mt={4}>
          All fields are required unless marked optional
        </Text>
      </Box>
      <Center textAlign="left" width="full" flexDirection="column" maxWidth={475} mt={14}>
        <Box width="full" textAlign="left">
          <Heading size="hs-md" mb={5}>
            1. Basic Business Information
          </Heading>
        </Box>
        <VStack width="full" spacing={6}>
          <InputField
            register={register}
            name="businessName"
            autoFocus
            label="Business Name"
            errors={errors}
          />
          <InputField
            className="blp-basicInformation__businessStreetAddressField"
            data-test="blp-basicInformation__businessStreetAddressField"
            register={register}
            name="address"
            label={t('common.inputs.businessStreetAddress.label')}
            helperText={t('common.inputs.businessStreetAddress.helperText')}
            formControlStyles={{ marginBottom: 6 }}
            errors={errors}
          />
          <InputField
            className="blp-basicInformation__addressLine2Field"
            data-test="blp-basicInformation__addressLine2Field"
            register={register}
            name="addressLine2"
            formControlStyles={{ marginBottom: 6 }}
            errors={errors}
          />

          <InputField
            className="blp-basicInformation__businessZipCodeField"
            data-test="blp-basicInformation__businessZipCodeField"
            register={register}
            name="zipCode"
            type="number"
            min="0"
            label="Business ZIP Code"
            errors={errors}
          />
          <HStack alignItems="flex-start" mb={12}>
            <InputField register={register} name="city" label="City" errors={errors} />
            <SelectField
              register={register}
              name="state"
              label={t('common.inputs.state.label')}
              errors={errors}
            >
              {stateOptions.map(opt => (
                <option value={opt.value} key={opt.value}>
                  {opt.label}
                </option>
              ))}
            </SelectField>
          </HStack>
        </VStack>
        <Box width="full" mt={12}>
          <Text textStyle="lg" fontWeight="medium" mb={4}>
            Can customers visit this location?
          </Text>
          <Controller
            name="visitable"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                className="blp-basicBusinessInformation__visitableRadio"
                data-test="blp-basicBusinessInformation__visitableRadio"
              >
                <Stack spacing={2} direction="column">
                  {visitableRadioOptions.map(option => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
          <Button
            className="blp-basicInformation__setAddAreasButton"
            data-test="blp-basicInformation__setAddAreasButton"
            onClick={setAddAreas.toggle}
            display="inline-flex"
            alignItems="center"
            variant="unstyled"
            borderRadius="none"
            size="sm"
            fontSize={['sm', 'md']}
            py={0}
            mt={7}
            mb={addAreas || watchVisitable === 'notVisitable' ? 1 : 6}
            leftIcon={<Icon as={FontAwesomeIcon} icon={addAreas ? faCircleMinus : faCirclePlus} />}
          >
            {t('gmbProfileFlow.stepTwo.areasServed', {
              watchVisitable: watchVisitable === 'notVisitable' ? '' : ' (optional)',
            })}
          </Button>
          {addAreas || watchVisitable === 'notVisitable' ? (
            <ReactSelectFieldAsync
              // @ts-ignore For some reason isMulti is typed wrong, cant figure it out
              isMulti
              className="blp-basicInformation__serviceAreaField"
              data-test="blp-basicInformation__serviceAreaField"
              control={control}
              name="serviceArea"
              loadOptions={locationOptions}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.placeId}
              errors={errors}
              formControlStyles={{ marginLeft: 5, marginBottom: 6, width: 'auto' }}
            />
          ) : null}
        </Box>

        {isGmbListingSynced() ? (
          <>
            <Flex direction="column" align="flex-start" justifyContent="flex-start" width="100%">
              <Text py={2} fontWeight="bold">
                Business Category
              </Text>
              <Text>{listing?.categories?.[0].fullName}</Text>
            </Flex>
            <Alert status="warning" borderRadius="md" mt={3} backgroundColor="#FCEBE3">
              <AlertIcon color="#C94C14" />
              <Text>
                Once a business category is selected and synced with Google My Business, it can not
                be changed.
              </Text>
            </Alert>
          </>
        ) : (
          <ReactSelectFieldAsync
            className="blp-basicInformation__businessCategoryField"
            data-test="blp-basicInformation__businessCategoryField"
            control={control}
            errors={errors}
            name="businessCategory"
            label={t('common.inputs.businessCategory.label')}
            loadOptions={categoryOptions}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
          />
        )}
        <Box mt={[14]}>
          <Button
            size="lg"
            onClick={handleSubmit(onSubmit)}
            variant="outline"
            isDisabled={!isValid || isLoading || showPendingVerification}
            isLoading={isLoading}
          >
            Save
          </Button>
        </Box>
      </Center>
    </Center>
  );
};
