import { CategoryRecord } from '@companydotcom/types';
import { emptySplitApi } from '../../store/empty-split-api';
import {
  GET_AUTOCOMPLETE_RESULTS_FOR_SEARCH,
  GET_GMB_CATEGORY_AUTOCOMPLETE,
} from './graphql/search-query';

export const SearchApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    // these are yext categories
    getAutocompleteResultsSearch: builder.query<[], { searchTerm: string }>({
      query: ({ searchTerm }) => ({
        method: 'query',
        document: GET_AUTOCOMPLETE_RESULTS_FOR_SEARCH,
        variables: {
          searchTerm: searchTerm.split(' ').join(' + ').concat('*'),
        },
      }),
      transformResponse: (rawResult: { data: { getAutocompleteResults: [] } }) =>
        rawResult.data.getAutocompleteResults,
    }),
    getGmbCategoryAutocomplete: builder.query<CategoryRecord[], { searchTerm: string }>({
      query: ({ searchTerm }) => ({
        method: 'query',
        document: GET_GMB_CATEGORY_AUTOCOMPLETE,
        variables: {
          searchTerm,
        },
      }),
      transformResponse: (rawResult: { data: { getGmbCategoryAutocomplete: CategoryRecord[] } }) =>
        rawResult.data.getGmbCategoryAutocomplete,
    }),
  }),
});

export const { useLazyGetAutocompleteResultsSearchQuery, useLazyGetGmbCategoryAutocompleteQuery } =
  SearchApi;
