import React from 'react';
import {
  Avatar,
  Flex,
  Box,
  Text,
  Button,
  Heading,
  useMultiStyleConfig,
  StylesProvider,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@companydotcom/potion';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  InputField,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { parsePhoneNumber } from 'libphonenumber-js';
import { PhotoEditor } from '../../../components/elements/photo-editor';
import { useToast } from '../../../hooks';
import { useGetGlobalUserQuery, useUpdateUserMutation } from '../../../services/user/user-api';
import { PageHeading } from '../../../components/elements';
import yup from '../../../lib/yup';

const getMyProfileStepSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('common.inputs.firstName.error'),
    lastName: yup.string().required('common.inputs.lastName.error'),
    phone: yup.string().phone().required('common.inputs.phoneNumber.error').nullable(),
  });

export interface MyProfileStepProps {}

export const MyProfileStep: React.FC<MyProfileStepProps> = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: globalUser } = useGetGlobalUserQuery();
  const styles = useMultiStyleConfig('MyProfileStep', props);
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();

  const defaultValues: FieldValues = {
    firstName: globalUser?.firstName || '',
    lastName: globalUser?.lastName || '',
    phone: globalUser?.phone ? parsePhoneNumber(globalUser?.phone, country).number : '',
    personalEmail: globalUser?.email || '',
    companyEmail: `${globalUser?.mailbox}@${globalUser?.mailboxFQDN}` || '',
  };

  const [updateUser] = useUpdateUserMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getMyProfileStepSchema()),
    defaultValues,
    context: {
      country,
    },
  });
  const toast = useToast();
  const { t } = useTranslation();

  const onSubmit = async (values: typeof defaultValues) => {
    if (globalUser?.userId) {
      const inputData = {
        userId: globalUser?.userId,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: getFormattedPhoneNumber(values.phone, country ?? 'US'),
      };
      return updateUser(inputData)
        .then(() => {
          toast({
            description: t('miop.userProfile.myProfileStep.snackbar.success'),
            status: 'success',
            duration: 7000,
            isClosable: true,
            position: 'bottom-right',
          });
        })
        .catch(err => {
          console.log('Error: ', err);
          toast({
            description: t('miop.userProfile.myProfileStep.snackbar.error'),
            status: 'error',
            duration: 7000,
            isClosable: true,
            position: 'bottom-right',
          });
        });
    }
  };

  return (
    <>
      <StylesProvider value={styles}>
        <Box className="potion-myProfileStep__container" sx={styles.container}>
          <Flex
            flexDirection={['column', null, 'row']}
            width="full"
            sx={{ textAlign: 'left' }}
            justifyContent="space-between"
          >
            <PageHeading heading={t('miop.userProfile.myProfileStep.header')} mb={6} />

            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Avatar
                className="potion-myProfileStep__avatar"
                sx={styles.avatar}
                size="lg"
                src={globalUser?.avatar ?? undefined}
                name={`${globalUser?.firstName} ${globalUser?.lastName}`}
              />
              <Button
                variant="link"
                onClick={onOpen}
                _hover={{
                  textDecoration: 'none',
                }}
              >
                {!globalUser?.avatar || globalUser.avatar === ''
                  ? t('miop.userProfile.myProfileStep.addAvatar')
                  : t('miop.userProfile.myProfileStep.updateAvatar')}
              </Button>
            </Flex>
          </Flex>
          <Box mb={6}>
            <Heading as="h3" size="hs-md" mb={2}>
              {t('miop.userProfile.myProfileStep.basicInformation')}
            </Heading>
            <Text textStyle="md">{t('common.misc.allFieldsRequiredUnless')}</Text>
          </Box>
          <Box
            as="form"
            className="potion-myProfileStep__form"
            data-test="potion-myProfileStep__form"
            sx={styles.form}
          >
            <VStack className="potion-myProfileStep__fields" sx={styles.fields} spacing={6}>
              <InputField
                className="potion-myProfileStep__firstNameField"
                data-test="potion-myProfileStep__firstNameField"
                register={register}
                name="firstName"
                label={t('common.inputs.firstName.label')}
                errors={errors}
              />
              <InputField
                className="potion-myProfileStep__lastNameField"
                data-test="potion-myProfileStep__lastNameField"
                register={register}
                name="lastName"
                label={t('common.inputs.lastName.label')}
                errors={errors}
              />

              <PhoneNumberInputField
                name="phone"
                label={t('common.inputs.phoneNumber.label')}
                className="potion-myProfileStep__phoneField"
                data-test="potion-myProfileStep__phoneField"
                control={control}
                country={country}
                onCountryChange={onCountryChange}
                errors={errors}
              />

              <InputField
                className="potion-myProfileStep__personalEmailField"
                data-test="potion-myProfileStep__personalEmailField"
                isDisabled
                register={register}
                name="personalEmail"
                label={t('common.inputs.email.altLabel')}
                errors={errors}
              />

              {globalUser?.mailbox && globalUser.mailboxFQDN && (
                <InputField
                  className="potion-myProfileStep__companyEmailField"
                  data-test="potion-myProfileStep__companyEmailField"
                  isDisabled
                  register={register}
                  name="companyEmail"
                  label={t('common.inputs.email.brandedLabel')}
                  errors={errors}
                />
              )}
            </VStack>
          </Box>
          <Box sx={{ textAlign: 'center' }} mt={10}>
            <Button
              onClick={handleSubmit(onSubmit)}
              className="potion-myProfileStep__formButton"
              data-test="potion-myProfileStep__formButton"
              sx={styles.formButton}
              type="submit"
              size="lg"
              isLoading={isSubmitting}
              isDisabled={!isValid}
            >
              {t('common.buttons.save')}
            </Button>
          </Box>
        </Box>
      </StylesProvider>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Avatar Photo</ModalHeader>
          <ModalCloseButton />
          <ModalBody mx="auto">
            <PhotoEditor onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
