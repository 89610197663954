import React from 'react';
import { Flex, Center, Heading, Button, Stack, Text, VStack } from '@companydotcom/potion';
import { User } from '@companydotcom/types';
import { useNavigate } from 'react-router-dom';
import { PageHeading } from '../../../../../../components/elements';
import { AcgMemberships } from '../../../../../../services/acg/acg.types';
import { useCreateCartForInvoiceMutation } from '../../../../../../services/acg/acg-api';
import { useAppDispatch } from '../../../../../../hooks';
import { setRenewInvoice, setSelectedPackage } from '../../../../acg-checkout/checkout-slice';
import { useAcgCartStorage } from '../../../../shared';

export interface BecomeAMemberLapsedProps {
  isMobile?: boolean;
  globalUser?: User;
  memberData?: AcgMemberships | any;
}

export const BecomeAMemberLapsed: React.FC<BecomeAMemberLapsedProps> = props => {
  const { isMobile, globalUser, memberData: membership } = props;
  const { memberships } = membership;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createCart, { isLoading }] = useCreateCartForInvoiceMutation();
  const { addMembershipToLocalCart } = useAcgCartStorage();

  const handleOnClick = async () => {
    try {
      dispatch(setSelectedPackage(memberships?.[0]));

      // RHYTHM MOCK DATA
      const res = await createCart({
        user: globalUser as User,
        payload: {
          invoiceId: memberships?.[0]?.openInvoices?.invoiceRecordKey,
        },
      }).unwrap();

      if (res?.length) {
        dispatch(setRenewInvoice(res));
        addMembershipToLocalCart({ ...res[0], ...memberships?.[0] });
        navigate('/acg-shopping-cart', { state: { flow: 'checkout' } });
      }
    } catch (err) {
      throw new Error();
    }
  };

  return (
    <Center
      className="become-a-member__lapsed-member"
      px={[0, 4]}
      borderRadius="lg"
      flexDirection="column"
    >
      <PageHeading heading="Become A Member" width="full" maxW={908} />
      <Flex
        bg="white"
        mt={[4]}
        p={[4, 0]}
        width="full"
        maxW={908}
        flexDirection="column"
        alignItems="center"
      >
        <VStack mb={isMobile ? 6 : 0} mt={[0, 12]}>
          <Heading size={isMobile ? 'md' : 'hs-xl'} textAlign="center">
            Your membership has expired. Renew your membership to regain access to your member
            benefits.
          </Heading>
        </VStack>
        {!isMobile && (
          <Stack
            spacing={4}
            fontSize={18}
            fontWeight="bold"
            my={10}
            alignItems="center"
            textAlign="center"
          >
            <Text>Member-Exclusive Events and Programming</Text>
            <Text>Member-Only Discounts</Text>
            <Text>Access to the ACG Member Directory</Text>
            <Text>Middle Market Insights Webinars</Text>
          </Stack>
        )}
        <Button size="lg" onClick={handleOnClick} isLoading={isLoading} mb={[0, 12]}>
          CONTINUE
        </Button>
      </Flex>
    </Center>
  );
};
