import React from 'react';
import {
  Flex,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  useMediaQuery,
  useTabStepper,
} from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { useSource } from '@companydotcom/providers';
import { useTranslation, TFunction } from 'react-i18next';
import {
  BusinessProfileStep,
  InvoiceStep,
  // PaymentInformationStep
} from '.';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { MobilePageTabs } from '../../../components/elements';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';

const accountInformationSteps = (t: TFunction<'translations', undefined>) => [
  {
    slug: 'account-business-profile',
    label: t('miop.businessProfile.label'),
    component: <BusinessProfileStep />,
  },
  // NOTE: per Ross, hiding this step for now to account for weird Zuora bug in DEF-1511
  // {
  //   slug: 'account-payment-information',
  //   label: t('miop.paymentInformation.header'),
  //   component: <PaymentInformationStep />,
  // },
  {
    slug: 'account-invoice',
    label: t('miop.invoices.view.header'),
    component: <InvoiceStep />,
  },
];

const accountInformationPartnerBillingSteps = (t: TFunction<'translations', undefined>) => [
  {
    slug: 'account-business-profile',
    label: t('miop.businessProfile.label'),
    component: <BusinessProfileStep />,
  },
];

export interface AccountInformationProps {}

export const AccountInformation = () => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const source = useSource();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const isPartnerBilled = globalUser?.account?.allowPartnerBilling;
  const steps =
    isPartnerBilled || source?.invoiceDisabled
      ? accountInformationPartnerBillingSteps(t)
      : accountInformationSteps(t);
  const { tabIndex, handleTabsChange, goToStep } = useTabStepper({ steps });

  return (
    <PageLayout>
      <PageLayoutContent>
        <Tabs isLazy variant="page" index={tabIndex} onChange={handleTabsChange}>
          {steps.length > 1 && isMobile ? (
            <MobilePageTabs steps={steps} tabIndex={tabIndex} goToStep={goToStep} />
          ) : (
            steps.length > 1 && (
              <TabList>
                {globalUser?.account && steps.map(step => <Tab key={step.slug}>{step.label}</Tab>)}
              </TabList>
            )
          )}
          <TabPanels mt={[0, null, 4]} width="full">
            {!globalUser?.account ? (
              <Flex justifyContent="center">
                <AppSpinner />
              </Flex>
            ) : (
              steps.map(step => <TabPanel key={step.slug}>{step.component}</TabPanel>)
            )}
          </TabPanels>
        </Tabs>
      </PageLayoutContent>
    </PageLayout>
  );
};
