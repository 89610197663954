import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import {
  Flex,
  Box,
  StylesProvider,
  useMultiStyleConfig,
  Accordion,
  AccordionItem,
} from '@companydotcom/potion';
import { RSelect, AppSpinner } from '@companydotcom/ui';
import { useSource } from '@companydotcom/providers';
import { useTranslation } from 'react-i18next';
// import { getUserLite } from '../../../services/user/old-api/user-svc';
import { formatChildProducts } from '../../../features/add-new-user-flow/utils';
import { useUserProfileContext } from '../../../providers/user-profile-context';
import { isEmailProductActive, getChildProductsAndSeatCount } from '../utils';
import { ChildUserAccordion } from '../../../components/elements/child-user-accordion';
import {
  useGetGlobalUserQuery,
  useLazyGetSeatCountQuery,
  useLazyGetUserLiteQuery,
} from '../../../services/user/user-api';
import { PageHeading } from '../../../components/elements';
import { useLazyGetEligibleChildProductsQuery } from '../../../services/product/product-api';

interface AdditionalUsersStepProps {
  locale?: string;
}

export const AdditionalUsersStep: React.FC<AdditionalUsersStepProps> = props => {
  const { locale } = props;
  const { data: globalUser } = useGetGlobalUserQuery();
  const [getUserLiteQuery] = useLazyGetUserLiteQuery();
  const [getSeatCount] = useLazyGetSeatCountQuery();
  const [getEligibleChildProducts] = useLazyGetEligibleChildProductsQuery();
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();
  const [loading, setLoading] = useState(isEmpty(userProfileState?.childUsers));
  const { t } = useTranslation('translations');
  const source = useSource();
  const styles = useMultiStyleConfig('MyProfileStep', props);
  const isEmailActive = globalUser ? isEmailProductActive?.(globalUser) : false;

  useEffect(() => {
    let isMounted = true;
    async function getChildUserInformation() {
      try {
        const data =
          globalUser?.account?.users &&
          !isEmpty(globalUser?.account.users) &&
          (await Promise.all(
            globalUser?.account.users
              .filter(user => !user?.isPrimary)
              .map(child => getUserLiteQuery?.({ userId: child?.userId ?? '', locale }).unwrap()),
          ));

        if (data) {
          dispatchUserProfileState?.({
            type: 'UPDATE_STATE',
            payload: { filteredUsers: data, childUsers: data },
          });
        }

        if (globalUser && globalUser.accountId && source?.sourceId) {
          const productsAndSeatCount = await getChildProductsAndSeatCount(
            getEligibleChildProducts,
            getSeatCount,
            globalUser.userId,
            globalUser.accountId,
            source?.sourceId,
            isEmailActive,
            locale,
          );
          if (productsAndSeatCount) {
            if (
              productsAndSeatCount?.emailSeatCount &&
              !isEmpty(productsAndSeatCount?.emailSeatCount)
            ) {
              dispatchUserProfileState?.({
                type: 'UPDATE_STATE',
                payload: { emailSeatCount: productsAndSeatCount.emailSeatCount },
              });
            }

            dispatchUserProfileState?.({
              type: 'UPDATE_STATE',
              payload: {
                eligibleChildProducts: formatChildProducts(
                  productsAndSeatCount.processedChildProducts,
                ),
              },
            });

            setLoading(false);
          }
        }
      } catch (error) {
        console.log('\u{1F6A8} Error getting user products or seat count.', error);
      }
    }
    if (isMounted && isEmpty(userProfileState?.childUsers)) {
      getChildUserInformation();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const sortedUsers = userProfileState?.filteredUsers?.sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a.lastName && b.lastName && a.lastName > b.lastName
      ? 1
      : a.lastName && b.lastName && b.lastName > a.lastName
      ? -1
      : 0,
  );

  return (
    <StylesProvider value={styles}>
      <Box className="potion-additionalUsersStep__container" sx={styles.container}>
        <PageHeading heading={t('miop.userProfile.additionalUsersStep.header')} mb={6} />

        <Flex flexDirection="column">
          <Box maxWidth={424} mb={10}>
            <RSelect
              name="searchUser"
              label={t('miop.userProfile.additionalUsersStep.search.label')}
              placeholder={
                loading ? t('miop.userProfile.additionalUsersStep.search.loading') : undefined
              }
              helperText={t('miop.userProfile.additionalUsersStep.search.helperText')}
              errors={{}}
              isSearchable
              isClearable
              // @ts-ignore
              options={userProfileState?.childUsers ?? []}
              touched={{}}
              loadingMessage={() => t('miop.userProfile.additionalUsersStep.search.loading')}
              isLoading={loading}
              onChange={opt => {
                if (opt === null) {
                  dispatchUserProfileState?.({
                    type: 'UPDATE_STATE',
                    payload: { filteredUsers: userProfileState?.childUsers },
                  });
                } else {
                  dispatchUserProfileState?.({
                    type: 'UPDATE_STATE',
                    payload: {
                      filteredUsers: userProfileState?.childUsers?.filter(
                        child =>
                          opt.firstName === child.firstName && opt.lastName === child.lastName,
                      ),
                    },
                  });
                }
              }}
              getOptionLabel={(opt: { firstName: string; lastName: string }) =>
                `${opt.firstName} ${opt.lastName}`
              }
            />
          </Box>
          {loading ? (
            <AppSpinner containerStyles={{ width: 'full' }} />
          ) : (
            <Accordion width="full" allowMultiple allowToggle>
              {sortedUsers?.map((child, index) => (
                <AccordionItem
                  key={index}
                  mb={4}
                  borderWidth="1px"
                  borderColor="gray.200"
                  borderRadius="none"
                  boxShadow="md"
                  isDisabled={child.userStatus === 'pending' || child.phone === null}
                >
                  {({ isExpanded }) => (
                    <ChildUserAccordion
                      childUser={child}
                      isExpanded={isExpanded}
                      // avatarColor={
                      //   theme?.colors?.avatar_colors?.[index % theme?.colors?.avatar_colors?.length]
                      // }
                      isParentEmailProductActive={isEmailActive}
                    />
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </Flex>
      </Box>
    </StylesProvider>
  );
};
