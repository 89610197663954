import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Box, Flex, Heading, Button, ButtonGroup } from '@companydotcom/potion';
import { DisplayTimestamp } from '@companydotcom/ui';
import { Appointment } from '@companydotcom/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { TileSpinner } from '../tile-spinner';
import { TileText } from '../tile-text';
import type { TileComponentProps } from '../../types';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);

export interface TileDisplayAppointmentProps extends TileComponentProps {}

export const TileDisplayAppointment: React.FC<TileDisplayAppointmentProps> = props => {
  const { tile, text = '', variant, errorText, description, componentTheme } = props;
  const [existingAppointment, setAppointment] = useState<Appointment | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { data: globalUser } = useGetGlobalUserQuery();
  const { t } = useTranslation();
  const dateAndTime = t('tiles.displayAppointment.dateAndTime');
  let reScheduleUrl = '#';
  let cancelUrl = '#';
  if (existingAppointment?.metadata) {
    cancelUrl = JSON.parse(existingAppointment?.metadata).cancel_url;
    reScheduleUrl = JSON.parse(existingAppointment?.metadata).reschedule_url;
  }
  useEffect(() => {
    // Find the appointment for the tile that isn't fulfilled
    const appt = globalUser?.appointments?.find(
      app => app?.productId === tile?.productId && !app?.fulfilled,
    );

    if (appt) {
      setIsLoading(false);
      setAppointment(appt);
    }

    if (appt && globalUser?.appointments?.length === 0) {
      setIsLoading(false);
      setError(true);
    }
  }, [globalUser?.appointments, tile?.productId]);

  if (isLoading) {
    return (
      <Box className="tile-display-appointment" sx={componentTheme}>
        <Text textStyle="md" mt={4} mb={5}>
          {text ?? dateAndTime}
        </Text>
        <TileSpinner />
      </Box>
    );
  }

  if (error) {
    return (
      <>
        <Text textStyle="md" mt={4} mb={5}>
          {text ?? dateAndTime}
        </Text>
        <Text maxWidth={238} color="text_error">
          {t('tiles.displayAppointment.somethingWrong')}
        </Text>
      </>
    );
  }

  if (variant === 'webhook') {
    return (
      <Flex
        sx={componentTheme}
        flexDirection="column"
        className="tile-display-appointment"
        width="100%"
        height="100%"
        align="center"
      >
        {existingAppointment?.appointmentTime &&
        Math.round(existingAppointment?.appointmentTime / 1000) >= Date.now() ? (
          existingAppointment?.metadata && (
            <>
              <Text textStyle="md" mt={4} mb={5}>
                {dateAndTime}
              </Text>
              <Heading mx="auto" color="green.400" size="md" sx={{ maxWidth: 228, flexGrow: 1 }}>
                {existingAppointment?.appointmentTime &&
                  dayjs(new Date(existingAppointment?.appointmentTime / 1000))
                    .tz('America/Chicago')
                    .format('MMMM D, YYYY h:mm a z')}
              </Heading>
              <ButtonGroup>
                <Button
                  variant="outline"
                  onClick={() => {
                    window.open(cancelUrl, '_blank');
                  }}
                >
                  {t('common.buttons.cancel')}
                </Button>
                <Button
                  onClick={() => {
                    window.open(reScheduleUrl, '_blank');
                  }}
                >
                  {t('common.buttons.reschedule')}
                </Button>
              </ButtonGroup>
            </>
          )
        ) : (
          <>
            <TileText text={description ?? ''} />
            <Text textStyle="md" mt="auto" fontWeight="bolder">
              {errorText}
            </Text>
          </>
        )}
      </Flex>
    );
  }

  return (
    <Box className="tile-display-appointment">
      {existingAppointment?.appointmentTime && existingAppointment.appointmentTime >= Date.now() ? (
        <DisplayTimestamp
          timeStamp={Math.round(existingAppointment?.appointmentTime / 1000)}
          label={t('tiles.displayAppointment.yourUpcomingAppointment')}
          dateFormat="MMMM DD [at] h:mm[]a zz"
        />
      ) : (
        <Text textStyle="md" fontWeight="bolder">
          {text}
        </Text>
      )}
    </Box>
  );
};
