import {
  VStack,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faArrowRotateRight, faTrash } from '@fortawesome/pro-solid-svg-icons';

export interface PhotoEditorControlsProps {
  setScaleValue?: (e: number) => void;
  rotateAvatar?: () => void;
  onDeleteOpen?: () => void;
}

export const PhotoEditorControls = (props: PhotoEditorControlsProps) => {
  const { setScaleValue, rotateAvatar, onDeleteOpen } = props;
  return (
    <VStack position="relative" spacing={0}>
      <Text color="white" position="absolute" top={-7}>
        Drag to reposition photo
      </Text>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={6}
        bgColor="blackAlpha.800"
        width="full"
        spacing={0}
      >
        <FontAwesomeIcon
          icon={faTrash}
          onClick={onDeleteOpen}
          boxSize="22px"
          color="white"
          sx={{ cursor: 'pointer' }}
        />
        <PhotoSlider setScaleValue={setScaleValue} />
        <FontAwesomeIcon
          icon={faArrowRotateRight}
          onClick={rotateAvatar}
          boxSize="22px"
          color="white"
          sx={{ cursor: 'pointer' }}
        />
      </Flex>
    </VStack>
  );
};

function PhotoSlider(props: PhotoEditorControlsProps) {
  const { setScaleValue } = props;

  return (
    <Flex width="full" maxWidth={290}>
      <Text color="white" mr={2}>
        ZOOM
      </Text>
      <Slider
        onChange={(e: number) => setScaleValue?.(e)}
        aria-label="zoom-slider"
        defaultValue={1.3}
        min={1}
        max={2}
        step={0.1}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Flex>
  );
}
