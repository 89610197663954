import React from 'react';
import { Box, Text, Heading, Button, Center } from '@companydotcom/potion';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputField } from '@companydotcom/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useUpdateAccountBusinessMutation } from '../../../services/user/user-api';
// import { updateAccountBusiness } from '../../../services/user/old-api/user-svc';

const getBusinessTaxIdSchema = () =>
  yup.object().shape({
    businessTaxId: yup.string().required('forms.businessTaxId.inputs.businessTaxId.error'),
  });

export const BusinessTaxId = ({ productName, handleDataFormFill, formValues }: any) => {
  const [updateAccountBusiness] = useUpdateAccountBusinessMutation();
  const { t } = useTranslation();

  const { register, handleSubmit, formState, setError } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getBusinessTaxIdSchema()),
    defaultValues: {
      businessTaxId: '',
    },
  });
  const { isSubmitting, isValid, errors } = formState;

  const onSubmit = async () => {
    try {
      // SEND BUSINESS TAX ID TO APERIA HERE: NEEDS HOOKED UP to APERIA-SVC
      // fake-sendBusinessTaxId(values.businessTaxId),
      await updateAccountBusiness(formValues).then(() => {
        handleDataFormFill();
      });
    } catch (err) {
      console.log(err);
      handleDataFormFill();
      setError('businessTaxId', {
        type: 'manual',
        message: 'Error updating Tax ID',
      });
    }
  };

  return (
    <Center
      className="form-businessTaxId__container"
      flexDirection="column"
      width="full"
      py={[8, 12]}
      px={4}
    >
      <Box textAlign="center" maxWidth={[360]}>
        <Heading as="h1" size="hs-xl">
          {t('forms.businessTaxId.heading')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          {t('forms.businessTaxId.subheading', { productName })}
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('common.misc.allFieldsRequired')}
        </Text>
      </Box>
      <>
        <Center
          flexDirection="column"
          width="full"
          minWidth={[228, 430]}
          my={[9, null, 12]}
          maxWidth={430}
        >
          <InputField
            register={register}
            name="businessTaxId"
            label={t('forms.businessTaxId.inputs.businessTaxId.label')}
            errors={errors}
            helperText={t('forms.businessTaxId.inputs.businessTaxId.helperText')}
          />
        </Center>
        <Box textAlign="center">
          <Button
            size="lg"
            onClick={handleSubmit(onSubmit)}
            variant="outline"
            isLoading={isSubmitting}
            isDisabled={!isValid || isSubmitting}
          >
            {t('common.buttons.submit')}
          </Button>
        </Box>
      </>
    </Center>
  );
};
