import type { SystemStyleObject } from '../../styled-system';

const parts = ['text', 'icon'];

const baseStyleText: SystemStyleObject = {
  color: 'red.500',
  mt: 2,
  fontSize: 'sm',
  lineHeight: 'normal',
};

const baseStyleIcon: SystemStyleObject = {
  marginEnd: '0.5em',
  color: 'red.500',
};

const baseStyle: SystemStyleObject = {
  text: baseStyleText,
  icon: baseStyleIcon,
};

export default {
  parts,
  baseStyle,
};
