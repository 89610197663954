import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Flex, Text } from '@companydotcom/potion';
/**
 * @param {string} month
 * @param {string} day
 * @param {string} formattedTime
 * @param {string} name
 * @param {int} listLength for conditional styling
 * @param {int} i index of list item for conditional styling
 */
export const TimestampedItem = ({ month, day, formattedTime, name, listLength, i }) => (_jsxs(Flex, Object.assign({ height: 44, alignItems: "center", sx: {
        borderTop: 'solid 1px #D8D8D8',
        borderBottom: listLength - 1 === i ? 'solid 1px #D8D8D8' : 0,
        borderRight: 0,
        borderLeft: 0,
    } }, { children: [_jsxs(Text, Object.assign({ mr: listLength < 1 ? 7 : 4, fontSize: "14px", fontWeight: "500", color: "#566e7d", opacity: "0.8", width: 32, textAlign: "left" }, { children: [month, "/", day] }), void 0), _jsx(Text, Object.assign({ mr: 6, fontSize: "14px", color: "#566e7d", fontWeight: "500", opacity: "0.8", width: listLength < 1 ? 'auto' : 72, textAlign: "left" }, { children: formattedTime }), void 0), _jsx(Text, Object.assign({ width: listLength < 1 ? 'auto' : 152, color: "#566e7d", fontSize: "14px", textAlign: "left", sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }, { children: name }), void 0)] }), void 0));
