const EXTENSIONS_FRAGMENT = `
extensions {
  name
  size
  variant
  colorScheme
  defaultProps {
    size
    variant
    colorScheme
  }
  components
}
`;

export const THEME_CONFIG_FRAGMENT = `
  themeConfig {
    themeId
    ${EXTENSIONS_FRAGMENT}
    overrides
    toastConfig {
      position
      size
      isClosable
      duration
    }
  }
`;
