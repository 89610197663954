import React, { useEffect } from 'react';
import {
  FlexProps,
  Flex,
  useTabStepper,
  TabPanel,
  TabPanels,
  Tabs,
  Heading,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  BoxProps,
} from '@companydotcom/potion';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { faEllipsis } from '@fortawesome/pro-light-svg-icons';
import {
  FaceCakeScheduleTile,
  FaceCakeDesignTile,
  FaceCakeIntegrationTile,
  FaceCakeContentCreationTile,
  FaceCakeContentReviewTile,
  FaceCakeContentApprovedTile,
  FaceCakeContentPublishedTile,
} from './components';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { useGetFacecakeStatusQuery } from '../../../services/facecake/facecake-api';
import { AppSpinner, FontAwesomeIcon } from '@companydotcom/ui';

const tileStates = [
  {
    slug: 'consult',
    component: <FaceCakeScheduleTile />,
  },
  {
    slug: 'design',
    component: <FaceCakeDesignTile />,
  },
  {
    slug: 'integration',
    component: <FaceCakeIntegrationTile />,
  },
  {
    slug: 'creation',
    component: <FaceCakeContentCreationTile />,
  },
  {
    slug: 'review',
    component: <FaceCakeContentReviewTile />,
  },
  {
    slug: 'approved',
    component: <FaceCakeContentApprovedTile />,
  },
  {
    slug: 'published',
    component: <FaceCakeContentPublishedTile />,
  },
];

interface ManageFaceCakeTileStatusProps {
  setShowAssetsAlert?: { readonly on: () => void; readonly off: () => void };
}

export const ManageFaceCakeTileStatus = ({ setShowAssetsAlert }: ManageFaceCakeTileStatusProps) => {
  const location = useLocation()?.state as Location;
  const isScheduled = location?.['isScheduled']; // TODO: mock data, toggles schedule tile state, from top rail
  const { data: globalUser, isLoading } = useGetGlobalUserQuery();
  const { data: facecakeStatus } = useGetFacecakeStatusQuery(
    globalUser?.userId ? { userId: globalUser?.userId } : skipToken,
  );

  const { tabIndex, handleTabsChange, nextStep, previousStep, goToStep, isFirstStep } =
    useTabStepper({
      steps: tileStates,
      initialStep: facecakeStatus?.status,
    });

  // TODO: to view other tile states from dropdown in top rail
  useEffect(() => {
    if (location?.['defaultStep']) {
      if (isFirstStep && !isScheduled) {
        setShowAssetsAlert?.on();
      } else {
        setShowAssetsAlert?.off();
      }
      goToStep(location?.['defaultStep']);
    } else {
      setShowAssetsAlert?.on();
    }
  }, [goToStep, isFirstStep, isScheduled, location, setShowAssetsAlert]);

  const sharedProps = {
    nextStep,
    previousStep,
    goToStep,
    isScheduled,
    tabIndex,
  };

  return (
    <TileCard showGrayHeading={!isLoading && tabIndex !== 0}>
      {isLoading ? (
        <AppSpinner />
      ) : (
        <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange} height="100%">
          <TabPanels height="100%">
            {tileStates.map(step => (
              <TabPanel height="100%" key={step.slug}>
                {React.cloneElement(step.component, sharedProps)}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </TileCard>
  );
};

interface TileCardProps extends FlexProps {
  showGrayHeading?: boolean;
}

const TileCard = ({ children, showGrayHeading, ...rest }: TileCardProps) => {
  const styleProps = {
    backgroundColor: 'blackAlpha.200',
    color: 'blackAlpha.500',
    height: '32px',
    margin: '0',
    width: '100%',
  };

  return (
    <Flex height="100%" flexDir="column" bg="blackAlpha.100" {...rest}>
      {showGrayHeading ? (
        <Box sx={styleProps}>
          <Heading
            mt="3px"
            size="md"
            textTransform="uppercase"
            textAlign="center"
            color="blackAlpha.500"
          >
            Virtual Try-On Status
          </Heading>
        </Box>
      ) : null}
      <Flex
        className="tile-card"
        flexDir="column"
        justify="center"
        align="center"
        textAlign="center"
        p="6"
        height="100%"
        position="relative"
      >
        {children}
        {process.env.REACT_APP_DEBUG_MODE_ENABLED === 'true' && (
          <TileStatusMenu position="absolute" top="2" right="2" />
        )}
      </Flex>
    </Flex>
  );
};

const TileStatusMenu = (props: BoxProps) => {
  const navigate = useNavigate();
  const states = [
    {
      value: 'consult',
      label: 'First Use',
    },
    {
      value: 'consult',
      label: 'First Use/Scheduled',
      isScheduled: true,
    },
    {
      value: 'design',
      label: 'Step 1 - Design',
    },
    {
      value: 'integration',
      label: 'Step 2 - Integration',
    },
    {
      value: 'creation',
      label: 'Step 3 - Creation',
    },
    {
      value: 'review',
      label: 'Step 4 - Review',
    },
    {
      value: 'approved',
      label: 'Step 5 - Approved',
    },
    {
      value: 'published',
      label: 'Step 6 - Published',
    },
  ];

  return (
    <Box {...props}>
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          icon={<FontAwesomeIcon boxSize="6" icon={faEllipsis} />}
          size="sm"
          variant="ghost"
          colorScheme="pink"
        >
          Tile Status
        </MenuButton>
        <MenuList defaultValue={'consult'}>
          {states.map((s, i) => (
            <MenuItem
              key={i}
              value={s.value}
              onClick={() =>
                navigate('/', { state: { defaultStep: s.value, isScheduled: s.isScheduled } })
              }
            >
              {s.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
