import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { UserTile, Appointment } from '@companydotcom/types';
import { Text, Flex, Button, Heading, ButtonGroup } from '@companydotcom/potion';
import { TileSpinner } from '../tile-spinner';
import type { TileComponentProps } from '../../types';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);

export interface ScheduledAppointmentProps extends TileComponentProps {
  tile?: UserTile;
}

export const TileScheduledAppointment: React.FC<ScheduledAppointmentProps> = props => {
  const [pollingInterval, setPollingInterval] = useState(2000);
  const { data: globalUser } = useGetGlobalUserQuery({
      pollingInterval,
    },
  );
  const { tile, componentTheme } = props;
  const [existingAppointment, setAppointment] = useState<Appointment | undefined>(undefined);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  let reScheduleUrl = '#';
  let cancelUrl = '#';
  if (existingAppointment?.metadata) {
    cancelUrl = JSON.parse(existingAppointment?.metadata).cancel_url;
    reScheduleUrl = JSON.parse(existingAppointment?.metadata).reschedule_url;
  }

  useEffect(() => {
    const appt = globalUser?.appointments
      ?.filter(
        appt =>
          appt?.productId === tile?.productId &&
          appt?.appointmentTime &&
          appt?.appointmentTime > Date.now() &&
          !appt.fulfilled,
      )
      .sort((a: any, b: any) => {
        return b.dateCreated - a.dateCreated;
      })[0];

    if (appt && appt.metadata) {
      setLoading(false);
      // @ts-ignore
      setAppointment(appt);
      setPollingInterval(0);
    }

    if (appt && globalUser?.appointments?.length === 0) {
      setLoading(false);
      setError(true);
    }
  }, [globalUser, tile?.productId]);

  return (
    <Flex
      className="tile-scheduled-appointment"
      width="full"
      height="100%"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      textAlign="center"
      sx={componentTheme}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {error ? (
        <>
          <Text textStyle="md" mt={4} mb={5}>
            Date and Time of Appointment:
          </Text>
          <Text maxWidth={238} color="red.500">
            Something went wrong. Please try again.
          </Text>
        </>
      ) : loading ? (
        <>
          <Text textStyle="md" mt={4} mb={5}>
            Date and Time of Appointment:
          </Text>
          <TileSpinner />
        </>
      ) : (
        existingAppointment?.metadata && (
          <>
            <Text textStyle="md" mt={4} mb={5}>
              Date and Time of Appointment:
            </Text>
            <Heading color="green.400" size="hs-md" sx={{ maxWidth: 228 }}>
              {dayjs(new Date((existingAppointment?.appointmentTime as number) / 1000))
                .tz('America/Chicago')
                .format('MMMM D, YYYY h:mm a z')}
            </Heading>
            <ButtonGroup justifyContent="center" mt="auto" width="full" spacing={8}>
              <Button
                variant="outline"
                onClick={() => {
                  window.open(cancelUrl, '_blank');
                }}
              >
                {t('common.buttons.cancel')}
              </Button>
              <Button
                onClick={() => {
                  window.open(reScheduleUrl, '_blank');
                }}
              >
                {t('common.buttons.reschedule')}
              </Button>
            </ButtonGroup>
          </>
        )
      )}
    </Flex>
  );
};
