/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
// import { Flex, Text, Box, Stack, Skeleton, HStack } from '@companydotcom/potion';
import { Flex, Text, Box, Link, Button } from '@companydotcom/potion';
import { useMitt, useSource } from '@companydotcom/providers';
import { companyConstants } from '@companydotcom/helpers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppSpinner } from '@companydotcom/ui';
import {
  ExtendedSubscriptionRatePlan,
  SubscriptionTier,
  User,
  UserTile,
} from '@companydotcom/types';
import { isEmpty } from 'lodash';
import { PaymentMethodsList } from './payment-methods-list';
import { savePaymentMethod } from './payment-method-helpers';
//import { PartnerBilling } from './partner-billing';
//import { ZuoraForm } from '../../../components/elements/zuora-form';
import { ChargeBeeFormNew } from '../../../components/elements/chargeBee/chargeBee-form';
import { useLazyPublishTileEventQuery } from '../../../services/event/event-api';
import {
  useLazyGetAccountPaymentDetailsQuery,
  useLazyGetAllPaymentSourceByCustomerQuery,
} from '../../../services/payment/payment-api';
import { useProcessOrderSummaryMutation } from '../../../services/user/user-api';
import ChargebeePaymentForm from '../../../components/elements/chargeBee/chargebeeForm';
import { any } from 'prop-types';

// Commented zuora payment form temporarily until a new payment method is introduced

// const paymentMethodViews: any = {
//   zuora: ZuoraForm,
//   paymentMethods: PaymentMethodsList,
//   partnerBilling: PartnerBilling,
// };

interface PaymentMethodProps {
  // email?: string;
  //sourceId?: string;
  user?: User;
  tile?: UserTile;
  onDataUpdate: (data: any) => void;
  selectedRatePlans?: ExtendedSubscriptionRatePlan[];
  selectedProducts?: any;
  selectedTiers?: SubscriptionTier[] | [];
  goToStep?: (arg: string | number) => void;
  paymentMethodView?: any;
  setSubmitting?: any;
  setError?: any;
  // loading?: boolean;
  //submitting?: any;
  //formParams?: any;
  handleUpdatePaymentMethod?: any;
  paymentMethod?: any;
  externalFlow?: boolean;
  orderId?: string;
  eventSource?: any;
  //setPaymentError?: any;
  quantity?: number;
  setPaymentData?: (arg: any) => void;
  setPaymentMethod?: any;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = props => {
  const {
    //email,
    //sourceId,
    user,
    tile,
    selectedRatePlans,
    onDataUpdate,
    selectedProducts,
    selectedTiers,
    goToStep,
    paymentMethodView,
    setSubmitting,
    setError,
    // loading,
    //submitting,
    //formParams,
    handleUpdatePaymentMethod,
    paymentMethod,
    externalFlow,
    orderId,
    eventSource,
    //setPaymentError,
    quantity,
    setPaymentData,
    setPaymentMethod,
  } = props;
  const { emitter } = useMitt();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [publishTileEvent] = useLazyPublishTileEventQuery();
  const source = useSource();
  let sourceID = source.sourceId;
  if (sourceID === 'company') {
    sourceID = 'grandio';
  }
  let helpAnchorTag = null;
  if (sourceID === 'grandio') {
    helpAnchorTag = (
      <Link
        as="a"
        mt={[2]}
        href={`mailto:${t('containers.contactPage.customerSuccess.contactEmail')}`}
        sx={{
          textAlign: ['center', null, 'left'],
          color: '#960200',
          textDecoration: 'underline',
        }}
      >
        {`${t('containers.contactPage.customerSuccess.contactEmail')}.`}
      </Link>
    );
  }
  const [processOrderSummary] = useProcessOrderSummaryMutation();
  const zuoraPaymentMethodId = user?.account?.zuoraPaymentMethodId as string;
  const allowPartnerBilling = user?.account?.allowPartnerBilling;
  const zuoraProductRatePlanChargeId = !isEmpty(selectedRatePlans)
    ? selectedRatePlans?.[0].ratePlanChargeId
    : undefined;
  // eslint-disable-next-line no-nested-ternary
  const qty = orderId ? undefined : quantity && quantity >= 1 ? quantity : selectedTiers?.[0]?.qty;
  const zuoraProductRatePlanId = orderId ? undefined : selectedRatePlans?.[0].ratePlanId;
  const immediateActivate = orderId ? undefined : selectedRatePlans?.[0].immediateActivate;
  const [getAccountPaymentDetails] = useLazyGetAccountPaymentDetailsQuery();

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isFirstTimePayment, setIsFirstTimePayment] = useState(true);
  const [cardNo, setCardNo] = useState('');
  const [cbPaymentData, setCbPaymentData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [getAllPaymentSourceByCustomer] = useLazyGetAllPaymentSourceByCustomerQuery();

  const getProductIds = useCallback(() => {
    if (!tile || !tile.productId) {
      return (
        (!isEmpty(selectedProducts) && selectedProducts?.[0].productId) ||
        selectedRatePlans?.[0].productId
      );
    }
    return tile.productId;
  }, [selectedProducts, selectedRatePlans, tile]);

  useEffect(() => {
    (async () => {
      const customerId = user?.account?.chargebeeCustomerId as string;
      const paymentMethod = await getAllPaymentSourceByCustomer({ customerId })
        .unwrap()
        .then(
          paymentMethods =>
            paymentMethods &&
            JSON.parse(paymentMethods).find((pm: any) => pm?.payment_source?.status === 'valid'),
        )
        .then(pmo => pmo.payment_source)
        .catch(err => {
          console.log(err);
          setIsFirstTimePayment(false);
          return null;
        })
        .finally(() => setIsLoading(false));
      if (paymentMethod) {
        setCbPaymentData(paymentMethod);
        setIsFirstTimePayment(false);
        setCardNo(paymentMethod?.card?.masked_number);
        setPaymentMethod({
          cardHolderInfo: {
            cardHolderName: user?.firstName + ' ' + user?.lastName,
            zipCode: '',
          },
          cardNumber: paymentMethod?.card?.masked_number,
          cardType: paymentMethod?.card?.brand,
          defaultPaymentMethod: false,
          expirationMonth: paymentMethod?.card?.expiry_month,
          expirationYear: paymentMethod?.card?.expiry_year,
          id: paymentMethod?.id,
        });
      } else {
        setIsFirstTimePayment(true);
      }
    })();
  }, [getAllPaymentSourceByCustomer, user?.account?.chargebeeCustomerId]);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.confirmPurchase, () => {
      if (paymentMethodView === 'zuora') {
        window.Z.submit();
      } else if (user) {
        savePaymentMethod(
          processOrderSummary,
          null,
          zuoraProductRatePlanId,
          user,
          getProductIds(),
          qty,
          immediateActivate,
          zuoraPaymentMethodId,
          goToStep,
          setSubmitting,
          setError,
          tile,
          emitter,
          externalFlow as boolean,
          orderId as string,
          eventSource,
          //@ts-ignore
          zuoraProductRatePlanChargeId,
          selectedRatePlans as ExtendedSubscriptionRatePlan[],
          paymentMethod,
          publishTileEvent,
          getAccountPaymentDetails,
          selectedTiers,
          setPaymentData,
          navigate,
        );
      }
    });

    return () => {
      emitter.off(companyConstants.platformEvents.confirmPurchase);
    };
  }, [
    emitter,
    eventSource,
    externalFlow,
    getAccountPaymentDetails,
    getProductIds,
    goToStep,
    immediateActivate,
    navigate,
    orderId,
    paymentMethod,
    paymentMethodView,
    processOrderSummary,
    publishTileEvent,
    qty,
    selectedRatePlans,
    selectedTiers,
    setError,
    setPaymentData,
    setSubmitting,
    tile,
    user,
    zuoraPaymentMethodId,
    zuoraProductRatePlanChargeId,
    zuoraProductRatePlanId,
  ]);

  const billingInformation = t('components.paymentMethod.billingInformation');
  const tpaymentMethod = t('components.paymentMethod.paymentMethod');

  const handleRadioSelect = () => {
    console.log('Clicked payment method with card selection');
  };

  const handlePayment = (isPaymentSuccess: boolean, cardNo: string, paymentData: any) => {
    setIsFirstTimePayment(false);
    setIsPaymentSuccess(isPaymentSuccess);
    setCardNo(cardNo);
    if (paymentData) {
      // @ts-ignore
      setPaymentData(paymentData);
    }
  };

  return sourceID === 'grandio' ? (
    <>
      <Flex
        alignItems="start"
        alignSelf="start"
        flexDirection="column"
        maxW={[null, null, 392]}
        px={[4, null, 0]}
      >
        <Text fontWeight="medium" textStyle="xl" pb={[2]}>
          {allowPartnerBilling ? billingInformation : tpaymentMethod}
        </Text>
        <Box width="100%" borderTop="1px solid" borderColor="gray.300" position="relative">
          <Text
            color="#960200"
            fontFamily="Inter"
            fontSize="18px"
            width="407px"
            height="81px"
            marginTop="26px"
            textAlign="left"
            fontWeight="400"
          >
            You’ve encountered a problem. If you would like help making a payment, you can reach us
            at
            <Text color="#960200 !important" fontFamily="Inter" fontSize="18px" fontWeight="400">
              {helpAnchorTag}
            </Text>
          </Text>
        </Box>
      </Flex>
      <Button
        size="lg"
        position="absolute"
        bottom="-73px"
        left="0"
        right="0"
        width="fit-content"
        marginLeft="auto"
        marginRight="auto"
        isDisabled={true}
      >
        Confirm Purchase
      </Button>
    </>
  ) : (
    <Flex
      alignItems="start"
      alignSelf="start"
      flexDirection="column"
      maxW={[null, null, 392]}
      px={[4, null, 0]}
      maxWidth="392px"
      paddingInline="0px"
      Position="relative"
    >
      <Text fontWeight="medium" textStyle="xl" pb={[2]}>
        {allowPartnerBilling ? billingInformation : tpaymentMethod}
      </Text>
      <Box
        width="100%"
        borderTop="1px solid"
        borderColor="gray.300"
        display="flex"
        borderTopWidth="1px"
        flexDirection="column"
      >
        {isLoading ? (
          <AppSpinner marginTop="100px" />
        ) : isFirstTimePayment ? (
          <ChargebeePaymentForm
            processOrderSummary={processOrderSummary}
            goToStep={goToStep}
            user={user}
            selectedRatePlans={selectedRatePlans}
            selectedProducts={selectedProducts}
            selectedTiers={selectedTiers}
            handlePayment={handlePayment}
          />
        ) : (
          <PaymentMethodsList
            handleRadioSelect={handleRadioSelect}
            handleUpdatePaymentMethod={handleUpdatePaymentMethod}
            paymentMethod={paymentMethod}
            processOrderSummary={processOrderSummary}
            goToStep={goToStep}
            user={user}
            selectedRatePlans={selectedRatePlans}
            selectedProducts={selectedProducts}
            selectedTiers={selectedTiers}
            handlePayment={handlePayment}
          />
        )}
      </Box>
    </Flex>
  );
};
