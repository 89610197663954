import React from 'react';
import { Box, Heading } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { AppSpinner } from '@companydotcom/ui';

export interface MaxPaymentMethodsProps {
  isMobile?: boolean;
  isLoading?: boolean;
}

export const MaxSavedPaymentTypes: React.FC<MaxPaymentMethodsProps> = ({ isMobile, isLoading }) => {
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <AppSpinner
        containerStyles={{
          position: 'absolute',
          top: '20%',
          left: '30%',
        }}
      />
    );
  }

  return (
    <Box borderWidth="1px" p={8} textAlign="center" backgroundColor="white">
      <Heading size={isMobile ? 'hs-lg' : 'hs-xl'} fontWeight="semibold" color="#717174b5">
        {t('acg.savedPaymentMethods.form.maxPaymentMethods')}
      </Heading>
    </Box>
  );
};
