import { SystemStyleObject } from '@companydotcom/potion';

export default {
  divider: {
    borderTopWidth: 0,
    borderBottomWidth: '3px',
    opacity: 1,
    borderColor: 'blue.400',
    marginTop: 4,
    maxWidth: 12,
  },
} as Record<string, SystemStyleObject>;
