import * as React from 'react';
import { potion, forwardRef, ThemingProps, HTMLPotionProps } from '../../system';
import { SystemProps, SystemStyleObject } from '../../styled-system';
import { cx, __DEV__, createContext } from '../../utils';

export interface ButtonGroupProps extends HTMLPotionProps<'div'>, ThemingProps<'Button'> {
  /**
   * If `true`, the borderRadius of button that are direct children will be altered
   * to look flushed together
   */
  isAttached?: boolean;
  /**
   * If `true`, all wrapped button will be disabled
   */
  isDisabled?: boolean;
  /**
   * The spacing between the buttons
   * @default '0.5rem'
   * @type SystemProps["marginRight"]
   */
  spacing?: SystemProps['marginRight'];
}

interface ButtonGroupContext extends ThemingProps<'ButtonGroup'> {
  isDisabled?: boolean;
}

const [ButtonGroupProvider, useButtonGroup] = createContext<ButtonGroupContext>({
  strict: false,
  name: 'ButtonGroupContext',
});

export { useButtonGroup };

export const ButtonGroup = forwardRef<ButtonGroupProps, 'div'>((props, ref) => {
  const {
    size,
    variant,
    className,
    colorScheme,
    spacing = '0.5rem',
    isAttached,
    isDisabled,
    ...rest
  } = props;

  const _className = cx('potion-button__group', className);

  const context = React.useMemo(
    () => ({ size, variant, colorScheme, isDisabled }),
    [size, variant, isDisabled, colorScheme],
  );

  let groupStyles: SystemStyleObject = {
    display: 'inline-flex',
  };

  if (isAttached) {
    groupStyles = {
      ...groupStyles,
      '> *:first-of-type:not(:last-of-type)': { borderEndRadius: 0 },
      '> *:not(:first-of-type):not(:last-of-type)': { borderRadius: 0 },
      '> *:not(:first-of-type):last-of-type': { borderStartRadius: 0 },
    };
  } else {
    groupStyles = {
      ...groupStyles,
      '& > *:not(style) ~ *:not(style)': { marginStart: spacing },
    };
  }

  return (
    <ButtonGroupProvider value={context}>
      <potion.div ref={ref} role="group" __css={groupStyles} className={_className} {...rest} />
    </ButtonGroupProvider>
  );
});

if (__DEV__) {
  ButtonGroup.displayName = 'ButtonGroup';
}
