import React from 'react';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  __DEV__,
  useDisclosure,
  ButtonProps,
  forwardRef,
  useStyleConfig,
  Icon,
  useBreakpointValue,
} from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { AddAProductForm } from './add-a-product-form';

export const BYOP = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const breakPointStyles = {
    mobile: {
      backgroundColor: 'transparent',
      color: 'white',
    },
  };
  const breakPoint = useBreakpointValue({ base: breakPointStyles.mobile, md: {} });

  return (
    <>
      <BYOPButton onClick={onOpen} sx={breakPoint}>
        {t('components.topRail.addNew')}
      </BYOPButton>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <AddAProductForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export interface BYOPButtonProps extends ButtonProps {}

/**
 * BYOPButton
 *
 * BYOPButton that lives on the Tiles page, top left.
 *
 */
export const BYOPButton = forwardRef<BYOPButtonProps, 'button'>((props, ref) => {
  const styles = useStyleConfig('BYOP', props);

  return (
    // @ts-ignore
    <Button
      size="sm"
      ref={ref}
      leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} boxSize={5} />}
      sx={styles}
      {...props}
    />
  );
});

if (__DEV__) {
  BYOPButton.displayName = 'BYOPButton';
}
