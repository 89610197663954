import _get from 'lodash/get';
import { EmptyObject } from '@companydotcom/types';
import { scroller } from 'react-scroll';
import { companyConstants } from '@companydotcom/helpers';
import dcHelpers from '.';

const scrollToTile = (element: string) => {
  scroller.scrollTo(element, {
    duration: 800,
    delay: 0.1,
    offset: -50,
    smooth: true,
  });
};

export const initialDataCollectorState = {
  actionIdx: 0,
  actions: [],
  currentEvent: undefined,
  activeForm: undefined,
  allowModalClose: true,
  dataToValidate: [],
  tempData: {},
};

export const dataCollectorReducer = (state: any, action: any) => {
  const { dataToValidate, activeForm, currentEvent } = state;
  let stateUpdates: any | EmptyObject = {};
  switch (action.type) {
    // triggered by busEvt, performs setup
    case 'NEW_EVENT': {
      const { newEvent, processAction } = action.payload;
      stateUpdates = { currentEvent: newEvent };
      stateUpdates.allowModalClose = true;
      stateUpdates.actions = JSON.parse(JSON.stringify(newEvent.actions)); // actions changing has useEffect

      do {
        stateUpdates = {
          ...stateUpdates,
          ...processAction(stateUpdates.actions[stateUpdates.actionIdx || 0], {
            ...state,
            ...stateUpdates,
          }),
        };
      } while (stateUpdates.actionIdx && !stateUpdates.activeForm);
      return { ...initialDataCollectorState, ...stateUpdates };
    }

    case 'HANDLE_DATA_FORM_FILL': {
      const { dataCollectorState, processData, processAction } = action.payload;
      const shownForm = dcHelpers.dataCollectorForms.find(({ form }) => activeForm === form);
      if (dataCollectorState) {
        stateUpdates.tempData = { ...stateUpdates.tempData, ...dataCollectorState };
      }
      const dataToValidateCopy = dataToValidate.slice();
      if (!shownForm) {
        // User manually closed modal during submission; allow DC to stay shut down
        return initialDataCollectorState;
      }

      shownForm.dataElements.forEach(dataValue => {
        const dataIndex = dataToValidateCopy.indexOf(dataValue);
        if (dataIndex > -1) {
          dataToValidateCopy.splice(dataIndex, 1);
        }
      });

      stateUpdates = {
        ...stateUpdates,
        ...processData(dataToValidateCopy, { ...state, ...stateUpdates }),
      };

      while (stateUpdates.actionIdx && !stateUpdates.activeForm) {
        stateUpdates = {
          ...stateUpdates,
          ...processAction(state.actions[stateUpdates.actionIdx], {
            ...state,
            ...stateUpdates,
          }),
        };
      }
      return { ...state, ...stateUpdates };
    }
    case 'RESET': {
      const { scrollElement, emitter } = action.payload;
      if (
        _get(currentEvent, 'product.slug') === 'bookkeeping' ||
        _get(currentEvent, 'product.slug') === 'payroll'
      ) {
        emitter.emit(companyConstants.platformEvents.closeModal, currentEvent);
      }

      // FOR ATX-179 POC - RECODE/ REORGANIZE IF WE ARE GOING WITH LANDBOT AFTER THE POC - Remove check for product id
      if (
        scrollElement &&
        currentEvent &&
        currentEvent.productId !== '785d02a4-2e7a-11e8-ae75-120b17a64360' &&
        currentEvent.product
      ) {
        scrollToTile(_get(currentEvent, 'product.name').toLowerCase().replace(/\s/g, '-'));
      }

      return initialDataCollectorState;
    }
    default:
      console.error('event type not recognized', action.type);
      return state;
  }
};
