import React from 'react';
import {
  InputField,
  useGetCurrentlySelectedCountry,
  PhoneNumberInputField,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { Box, Text, Button, Heading, Center } from '@companydotcom/potion';
import { companyHelpers, companyConstants } from '@companydotcom/helpers';
import { useTranslation, Trans } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { useMitt } from '@companydotcom/providers';
import { parsePhoneNumber } from 'libphonenumber-js';
import {
  useUpdateAccountBusinessNoCityStateMutation,
  useUpdateUserMutation,
} from '../../../services/user/user-api';
import type { DataCollectorFormProps } from '../../../features/data-collector';
import { useLazyGetCityStateFromZipCodeQuery } from '../../../services/listings/listing-api';
import yup from '../../../lib/yup';

const getFirstTimeFormSchema = () =>
  yup.object().shape({
    address: yup.string().required('common.inputs.businessStreetAddress.error'),
    businessName: yup.string().required('common.inputs.businessName.error'),
    firstName: yup.string().required('common.inputs.firstName.error'),
    lastName: yup.string().required('common.inputs.lastName.error'),
    phone: yup.string().phone().nullable(),
    zipCode: yup
      .string()
      .required('common.inputs.postalCode.error')
      .test('zip code', 'common.inputs.postalCode.validation', val =>
        companyHelpers.validateZipCode(val),
      ),
  });

export interface FirstTimeFormProps extends DataCollectorFormProps {
  handleClose?: any;
}

export const FirstTimeForm: React.FC<FirstTimeFormProps> = ({ globalUser, handleClose }) => {
  const { emitter } = useMitt();
  const [updateUser] = useUpdateUserMutation();
  const [getCityStateFromZipCode] = useLazyGetCityStateFromZipCodeQuery();
  const [updateAccountBusinessNoCityState] = useUpdateAccountBusinessNoCityStateMutation();
  const { t } = useTranslation();
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();

  const defaultValues: FieldValues = {
    firstName: globalUser?.firstName || '',
    lastName: globalUser?.lastName || '',
    phone: globalUser?.phone && country ? parsePhoneNumber(globalUser?.phone, country).number : '',
    accountId: globalUser?.account?.accountId || '',
    businessId: globalUser?.account?.businessPrimary?.businessId || '',
    businessName: globalUser?.account?.businessPrimary?.name || '',
    zipCode: globalUser?.account?.businessPrimary?.address?.zipCode || '',
    address: globalUser?.account?.businessPrimary?.address?.addressLine1 || '',
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getFirstTimeFormSchema()),
    defaultValues,
    context: {
      country,
    },
  });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      if (globalUser) {
        const cityStateRes = await getCityStateFromZipCode({ zipCode: values.zipCode }).unwrap();

        const city = cityStateRes?.data?.city?.longName;
        const state = cityStateRes?.data?.state?.shortName;

        await Promise.all([
          updateUser({
            userId: globalUser.userId as string,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: getFormattedPhoneNumber(values.phone, country ?? 'US'),
          }),
          updateAccountBusinessNoCityState({
            userId: globalUser.accountId,
            accountId: globalUser?.account?.accountId,
            businessName: values.businessName,
            zipCode: values.zipCode,
            city,
            state,
            address: values.address,
            businessId: globalUser?.account?.businessPrimary?.businessId,
          }),
        ]);
        // Added a GAEvent for createYourProfile
        //  GAEvent('createYourProfile');
        // Added a GAEvent for NED2 Form(First Time Form) confirmation
        //  GAEvent('NED2ModalCTA', 'First Time Form', 'Confirm');
        await handleClose();
        emitter.emit(companyConstants.platformEvents.dataCollectorComplete);
      }
    } catch (err) {
      console.log('Error!: ', err);
    }
  };

  return (
    <Center
      className="form-firstTimeForm__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box textAlign="center">
        <Heading as="h1" size="hs-xl">
          {t('forms.firstTimeProfile.heading')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          <Trans i18nKey="forms.firstTimeProfile.subheading">
            Get started by creating your profile. <br /> This will be used to start setting up your
            products.
          </Trans>
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('common.misc.allFieldsRequired')}
        </Text>
      </Box>
      <Box as="form" className="form-firstTimeForm__form" maxWidth={430} width="full" mt={[8, 10]}>
        <InputField
          className="form-firstTimeForm__firstNameField"
          data-test="form-firstTimeForm__firstNameField"
          register={register}
          name="firstName"
          label={t('common.inputs.firstName.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        />
        <InputField
          className="form-firstTimeForm__lastNameField"
          data-test="form-firstTimeForm__lastNameField"
          register={register}
          name="lastName"
          label={t('common.inputs.lastName.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        />

        <PhoneNumberInputField
          name="phone"
          label={t('common.inputs.phoneNumber.label')}
          className="form-firstTimeForm__phoneField"
          data-test="form-firstTimeForm__phoneField"
          control={control}
          country={country}
          onCountryChange={onCountryChange}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        />

        <InputField
          className="form-firstTimeForm__businessNameField"
          data-test="form-firstTimeForm__businessNameField"
          register={register}
          name="businessName"
          label={t('common.inputs.businessName.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        />

        <InputField
          className="form-firstTimeForm__addressField"
          data-test="form-firstTimeForm__addressField"
          register={register}
          name="address"
          label={t('common.inputs.businessStreetAddress.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        />

        <InputField
          className="form-firstTimeForm__zipCodeField"
          data-test="form-firstTimeForm__zipCodeField"
          register={register}
          name="zipCode"
          label={t('common.inputs.businessZipCode.label')}
          errors={errors}
        />

        <Box textAlign="center" mt={[8, 12]}>
          <Button
            className="form-firstTimeForm__submitButton"
            data-test="form-firstTimeForm__submitButton"
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >
            {t('forms.firstTimeProfile.submitButton')}
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
