import React from 'react';
import { AnyRegularField } from './fields';

// Code inspired by Prismic's SliceZone component:
// https://github.com/prismicio/prismic-react/blob/master/src/SliceZone.tsx

/**
 * Slice - Sections of your website
 *
 * @see More details: {@link https://prismic.io/docs/core-concepts/slices}
 */
export interface Slice<
  SliceType = string,
  PrimaryFields extends Record<string, AnyRegularField> = Record<string, AnyRegularField>,
> {
  slice_type: SliceType;
  fields: PrimaryFields;
}

/**
 * The minimum required properties to represent a Slice for the
 * `<SliceZone>` component.
 *
 * @typeParam SliceType - Type name of the Slice.
 */
export type SliceLike<SliceType extends string = string> = Pick<Slice<SliceType>, 'slice_type'>;

/**
 * A looser version of the `SliceZone` type using `SliceLike`.
 *
 *
 * @typeParam TSlice - The type(s) of a Slice in the Slice Zone.
 */
export type SliceZoneLike<TSlice extends SliceLike> = readonly TSlice[];

/**
 * React props for a component rendering content from a Slice using the
 * `<SliceZone>` component.
 *
 * @typeParam TSlice - The Slice passed as a prop.
 * @typeParam TContext - Arbitrary data passed to `<SliceZone>` and made
 *   available to all Slice components.
 */
export type SliceComponentProps<TSlice extends SliceLike = SliceLike, TContext = unknown> = {
  /**
   * Slice data for this component.
   */
  slice: TSlice;

  /**
   * The index of the Slice in the Slice Zone.
   */
  index: number;

  /**
   * All Slices from the Slice Zone to which the Slice belongs.
   */
  // TODO: We have to keep this list of Slices general due to circular
  // reference limtiations. If we had another generic to determine the full
  // union of Slice types, it would include TSlice. This causes TypeScript to
  // throw a compilation error.
  slices: SliceZoneLike<SliceLike>;

  /**
   * Arbitrary data passed to `<SliceZone>` and made available to all Slice components.
   */
  context: TContext;
};

/**
 * A React component to be rendered for each instance of its Slice.
 *
 * @typeParam TSlice - The type(s) of a Slice in the Slice Zone.
 * @typeParam TContext - Arbitrary data made available to all Slice components.
 */
export type SliceComponentType<
  TSlice extends SliceLike = SliceLike,
  TContext = unknown,
> = React.ComponentType<SliceComponentProps<TSlice, TContext>>;

/**
 * This Slice component can be used as a reminder to provide a proper implementation.
 *
 * This is also the default React component rendered when a component mapping
 * cannot be found in `<SliceZone>`.
 */
export const TODOSliceComponent =
  process.env.NODE_ENV === 'production'
    ? () => null
    : <TSlice extends SliceLike, TContext>({
        slice,
      }: SliceComponentProps<TSlice, TContext>): JSX.Element | null => {
        React.useEffect(() => {
          console.warn(
            `[SliceZone] Could not find a component for Slice type "${slice.slice_type}"`,
            slice,
          );
        }, [slice]);

        return (
          <section data-slice-zone-todo-component="" data-slice-type={slice.slice_type}>
            Could not find a component for Slice type &ldquo;{slice.slice_type}
            &rdquo;
          </section>
        );
      };

/**
 * A record of Slice types mapped to a React component. The component will be
 * rendered for each instance of its Slice.
 *
 * @typeParam TSlice - The type(s) of a Slice in the Slice Zone.
 * @typeParam TContext - Arbitrary data made available to all Slice components.
 */
export type SliceZoneComponents<TSlice extends SliceLike = SliceLike, TContext = unknown> =
  // This is purposely not wrapped in Partial to ensure a component is provided
  // for all Slice types. <SliceZone> will render a default component if one is
  // not provided, but it *should* be a type error if an explicit component is
  // missing.
  //
  // If a developer purposely does not want to provide a component, they can
  // assign it to the TODOSliceComponent exported from this package. This
  // signals to future developers that it is a placeholder and should be
  // implemented.
  {
    [SliceType in keyof Record<TSlice['slice_type'], never>]: SliceComponentType<
      Extract<TSlice, SliceLike<SliceType>> extends never
        ? SliceLike
        : Extract<TSlice, SliceLike<SliceType>>,
      TContext
    >;
  };

/**
 * React props for the `<SliceZone>` component.
 *
 * @typeParam TSlice - The type(s) of a Slice in the Slice Zone.
 * @typeParam TContext - Arbitrary data made available to all Slice components.
 */
export type SliceZoneProps<TSlice extends SliceLike = SliceLike, TContext = unknown> = {
  /**
   * List of Slice data from the Slice Zone.
   */
  slices?: SliceZoneLike<TSlice>;

  /**
   * A record mapping Slice types to React components.
   */
  components?: SliceZoneComponents<TSlice, TContext>;

  /**
   * The React component rendered if a component mapping from the `components`
   * prop cannot be found.
   */
  defaultComponent?: SliceComponentType<TSlice, TContext>;

  /**
   * Arbitrary data made available to all Slice components.
   */
  context?: TContext;
};

/**
 * Renders content from a Slice Zone using React components for each
 * type of Slice.
 *
 * If a component is not provided for a type of Slice, a default component can
 * be provided. A fallback component is provided by default that will not be
 * rendered in a production build of your app.
 *
 * @typeParam TSlice - The type(s) of a Slice in the Slice Zone.
 * @typeParam TContext - Arbitrary data made available to all Slice components.
 *
 * @returns The Slice Zone's content as React components.
 */
export const SliceZone = <TSlice extends SliceLike, TContext>({
  slices = [],
  components = {} as SliceZoneComponents<TSlice, TContext>,
  defaultComponent = TODOSliceComponent,
  context = {} as TContext,
}: SliceZoneProps<TSlice, TContext>): JSX.Element => {
  const renderedSlices = React.useMemo(() => {
    return slices.map((slice, index) => {
      const Comp = (components[slice.slice_type as keyof typeof components] ||
        defaultComponent) as SliceComponentType<TSlice, TContext>;

      const key = `${index}-${JSON.stringify(slice)}`;

      return <Comp key={key} slice={slice} index={index} slices={slices} context={context} />;
    });
  }, [components, context, defaultComponent, slices]);

  return <>{renderedSlices}</>;
};
