import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['container', 'header', 'item', 'expandIcon', 'divider'];

const baseStyleContainer: SystemStyleObject = {
  width: 'full',
  mb: 5,
};

const baseStyleHeader: SystemStyleObject = {
  justifyContent: 'space-between',
  width: 'full',
  color: 'blackAlpha.800',
};

const baseStyleIcon: SystemStyleObject = {
  color: 'blackAlpha.800',
};

const baseStyleItem: SystemStyleObject = {
  paddingTop: 12,
  paddingBottom: 12,
  WebkitOverflowScrolling: 'touch',
};

const baseStyleDivider: SystemStyleObject = {
  opacity: 1,
  borderBottomWidth: '4px',
  mt: 2,
};

const baseStyle = () => ({
  container: baseStyleContainer,
  header: baseStyleHeader,
  icon: baseStyleIcon,
  item: baseStyleItem,
  divider: baseStyleDivider,
});

export default {
  parts,
  baseStyle,
};
