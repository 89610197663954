import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { GlobalTheme, DevTools } from '@companydotcom/ui';
import { PotionProvider, extendTheme, mergeThemeOverride } from '@companydotcom/potion';
import { merge, omitBy, isNil } from 'lodash';
import { CuiProvider } from './cui-context';
import { SourceProvider } from './source-provider';
import { AssetsProvider, defaultAssets } from './assets-provider';
import { mapThemeExtensions } from '../utils';
import { SourceConfigProvider } from './source-config-provider';
import { MittProvider } from './emitter';
export const GlobalProvider = ({ children, source, sourceConfig, theme, debugMode = false, }) => {
    var _a, _b;
    const parsedOverrides = typeof ((_a = source.themeConfig) === null || _a === void 0 ? void 0 : _a.overrides) === 'string'
        ? JSON.parse(source.themeConfig.overrides)
        : undefined;
    const overrides = mergeThemeOverride(GlobalTheme, theme, parsedOverrides);
    const mergedTheme = extendTheme(overrides, ...mapThemeExtensions((_b = source === null || source === void 0 ? void 0 : source.themeConfig) === null || _b === void 0 ? void 0 : _b.extensions));
    const srcCtx = React.useMemo(() => source, [source]);
    const srcConfigCtx = React.useMemo(() => sourceConfig, [sourceConfig]);
    const images = merge(defaultAssets, omitBy(source === null || source === void 0 ? void 0 : source.images, isNil));
    const assetsCtx = React.useMemo(() => images, [images]);
    return (_jsx(SourceProvider, Object.assign({ value: srcCtx }, { children: _jsx(SourceConfigProvider, Object.assign({ value: srcConfigCtx }, { children: _jsx(AssetsProvider, Object.assign({ value: assetsCtx }, { children: _jsx(MittProvider, { children: _jsx(CuiProvider, { children: _jsxs(PotionProvider, Object.assign({ resetCSS: true, theme: mergedTheme }, { children: [children, debugMode && _jsx(DevTools, {}, void 0)] }), void 0) }, void 0) }, void 0) }), void 0) }), void 0) }), void 0));
};
