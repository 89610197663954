import React, { useCallback, useEffect } from 'react';
import { Flex } from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { useSource } from '@companydotcom/providers';
import { companyConstants, companyHelpers, platformHelpers } from '@companydotcom/helpers';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../providers';

export interface LocationState {
  referrer?: string;
  redirect?: string;
  showCreateAccount?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  website?: string;
  phone?: string;
  qualifiedLead?: string;
  productCategory?: string[];
  accessories?: string;
  other?: string;
  organization?: string;
}

export interface LoginProps {
  isAuthenticated: boolean;
}

export const Login: React.FC<LoginProps> = ({ isAuthenticated }) => {
  const auth0 = useAuth();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const source = useSource();

  const handleLogin = useCallback(() => {
    const showCreateAccount =
      searchParams.get('showcreateaccount') || locationState?.showCreateAccount;
    const planId = searchParams.get('planId') || searchParams.get('planid');
    const productid = searchParams.get('productid');
    const redirectUrl = searchParams.get('redirectUrl');
    const qualifiedLead = locationState?.qualifiedLead;

    if (auth0?.user && isAuthenticated) {
      platformHelpers.legalIncPaymentRedirect(searchParams, auth0, navigate);
      if (planId && productid) {
        const redirectTo = companyHelpers.routeBuilder(
          source?.sourceId,
          companyConstants.hosts.account,
        );
        window.location.replace(`${redirectTo}/account/subscriptions/`);
      } else if (redirectUrl) {
        window.location.replace(`${redirectUrl}`);
      } else {
        navigate('/');
      }
    } else {
      if (qualifiedLead) {
        const state = {
          showCreateAccount,
          source: source?.sourceId,
          firstName: locationState?.firstName,
          lastName: locationState?.lastName,
          email: locationState?.email,
          phone: locationState?.phone,
          productCategory: locationState?.productCategory,
          accessories: locationState?.accessories,
          other: locationState?.other,
          website: locationState?.website,
          referrer: locationState?.referrer ?? '/',
          organization: locationState?.organization,
        };
        auth0?.login({
          custom_state: window.btoa(JSON.stringify(state)),
          appState: state,
          showCreateAccount,
          source: source?.sourceId,
        });
      } else {
        // add a referrer to state, allowing us to redirect back to the initial url
        const state = {
          planid: planId,
          productid,
          showCreateAccount,
          source: source?.sourceId,
          referrer: locationState?.referrer ?? '/',
          searchParams: location?.search,
        };

        for (const pair of searchParams.entries()) {
          state[pair[0]] = pair[1];
        }

        auth0?.login({
          custom_state: window.btoa(JSON.stringify(state)),
          appState: state,
          showCreateAccount,
          source: source?.sourceId,
        });
      }
    }
  }, [
    auth0,
    isAuthenticated,
    location?.search,
    locationState,
    navigate,
    searchParams,
    source?.sourceId,
  ]);

  useEffect(() => {
    handleLogin();
  }, [handleLogin]);

  return (
    <Flex justifyContent="center" alignItems="center" width="full" height="100vh">
      <AppSpinner />
    </Flex>
  );
};
