import * as React from 'react';
import {
  potion,
  forwardRef,
  omitThemingProps,
  ThemingProps,
  useStyleConfig,
  HTMLPotionProps,
} from '../../system';
import { cx, __DEV__ } from '../../utils';

/**
 * Layout component used to visually separate content in a list or group.
 * It display a thin horizontal or vertical line, and renders a `hr` tag.
 */
export const Divider = forwardRef<DividerProps, 'hr'>((props, ref) => {
  const {
    borderLeftWidth,
    borderBottomWidth,
    borderTopWidth,
    borderRightWidth,
    borderWidth,
    borderStyle,
    borderColor,
    ...styles
  } = useStyleConfig('Divider', props);
  const { className, orientation = 'horizontal', __css, ...rest } = omitThemingProps(props);

  const dividerStyles = {
    vertical: {
      borderLeftWidth: borderLeftWidth || borderRightWidth || borderWidth || '1px',
      height: '100%',
    },
    horizontal: {
      borderBottomWidth: borderBottomWidth || borderTopWidth || borderWidth || '1px',
      width: '100%',
    },
  };

  return (
    <potion.hr
      ref={ref}
      aria-orientation={orientation}
      {...rest}
      __css={{
        ...styles,
        border: '0',

        borderColor,
        borderStyle,
        ...dividerStyles[orientation],
        ...__css,
      }}
      className={cx('potion-divider', className)}
    />
  );
});

export interface DividerProps extends HTMLPotionProps<'div'>, ThemingProps<'Divider'> {
  orientation?: 'horizontal' | 'vertical';
}

if (__DEV__) {
  Divider.displayName = 'Divider';
}
