import React from 'react';
import { Button, Text } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const Error404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Text textStyle="lg" fontWeight="medium" color="red.500" mb={12}>
        {t('subpage.generalError.404.heading')}
      </Text>
      <Button size="lg" variant="outline" onClick={() => navigate(-1)}>
        {t('subpage.generalError.404.goBack')}
      </Button>
    </>
  );
};
