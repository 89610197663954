import React from 'react';
import {
  StatNumber,
  Stat,
  Box,
  useStyleConfig,
  Heading,
  Center,
  Text,
  Link,
} from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import { useTranslation, Trans } from 'react-i18next';
import type { TileComponentProps } from '../../types';
import { Rating } from '../../../../components/elements/rating';

interface EventDataReturn {
  averageRating?: number;
  counts?: {
    fiveStar: number;
    fourStar: number;
    lastTwoWeeks: number;
    lastWeek: number;
    oneStar: number;
    threeStar: number;
    total: number;
    twoStar: number;
  };
  latestReview?: {
    authorName: string;
    comments: any[];
    content: string;
    id: number;
    publisherDate: number;
    publisherId: string;
    rating: number;
    title: string;
  };
  needsResponse?: { count: number; url: string };
}

export interface TileRatingProps extends TileComponentProps {}

export const TileRating: React.FC<TileRatingProps> = props => {
  const { tile, eventData, text, componentTheme } = props;
  const { loading, data, error } = useEventData<EventDataReturn>(eventData);
  const styles = useStyleConfig('Rating');

  const ratingInfoData = {
    reviewAmount:
      error || tile?.stateCurrent === 'inactive' || !data?.counts?.total
        ? '—'
        : data?.counts?.total,
    date:
      error || tile?.stateCurrent === 'inactive' || !data?.latestReview?.publisherDate
        ? '—/—/—'
        : data?.latestReview?.publisherDate,
    needsResponse:
      error || tile?.stateCurrent === 'inactive' || !data?.needsResponse
        ? null
        : data?.needsResponse,
  };

  if (error || loading || tile?.stateCurrent === 'inactive') {
    return (
      <Center className="tile__tileRating" mb="auto" sx={componentTheme}>
        <Center flexDirection="column" ml={6}>
          <Stat>
            <StatNumber fontSize="5xl" sx={{ color: styles?.['color'] }}>
              -.-
            </StatNumber>
          </Stat>
          <Rating scale={5} rating={0} />
        </Center>
        <TileRatingInfo variant="average" data={ratingInfoData} />
      </Center>
    );
  }

  return (
    <Center className="tile__tileRating" flexDirection="column" mb="auto" sx={componentTheme}>
      {text && (
        <Text textStyle="md" mb={4} textAlign="center">
          {text}
        </Text>
      )}
      <Center>
        <Center flexDirection="column" ml={6}>
          <Stat>
            <StatNumber fontSize="5xl" sx={{ color: styles['color'] }}>
              {data?.averageRating ?? 0}
            </StatNumber>
          </Stat>
          <Rating scale={5} rating={data?.averageRating ?? 0} />
        </Center>
        <TileRatingInfo data={ratingInfoData} />
      </Center>
    </Center>
  );
};

const TileRatingInfo = ({ data }: any) => {
  const { t, i18n } = useTranslation();

  return (
    <Box ml={6} className="tile_tileRatingInfo">
      <Heading size="hs-sm">{t('components.rating.averageRating')}</Heading>
      {data.needsResponse ? (
        <>
          <Text textStyle="md">
            {/* @ts-ignore */}
            {t('components.rating.fromReviews', { count: data?.reviewAmount ?? 0 })}
          </Text>
          <Link href={data?.needsResponse?.url} isExternal>
            {t('components.rating.respondTo', { count: data?.needsResponse?.count })}
          </Link>
        </>
      ) : (
        <Text textStyle="md" color="#4c6372">
          <Trans i18nKey="components.rating.fromReviewsAsOf" count={data?.reviewAmount as number}>
            from {{ reviewAmount: data?.reviewAmount }} total reviews <br /> as of
          </Trans>
          {typeof data?.date === 'string'
            ? '—/—/—'
            : // @ts-ignore
              new Date(data?.date).toLocaleDateString(i18n.language)}
        </Text>
      )}
    </Box>
  );
};
