var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormControl } from '@companydotcom/potion';
import potionComponents from './potion-components';
const usePotionSelectProps = (_a) => {
    var _b;
    var { styles, components = {}, theme, size = 'md', colorScheme = 'gray', isDisabled, isInvalid, inputId, tagVariant, hasStickyGroupHeaders = false, selectedOptionStyle = 'color', selectedOptionColor = 'blue', focusBorderColor, errorBorderColor, potionStyles = {} } = _a, props = __rest(_a, ["styles", "components", "theme", "size", "colorScheme", "isDisabled", "isInvalid", "inputId", "tagVariant", "hasStickyGroupHeaders", "selectedOptionStyle", "selectedOptionColor", "focusBorderColor", "errorBorderColor", "potionStyles"]);
    // Combine the props passed into the component with the props that can be set
    // on a surrounding form control to get the values of `isDisabled` and
    // `isInvalid`
    const inputProps = useFormControl({ isDisabled, isInvalid });
    // Ensure that the size used is one of the options, either `sm`, `md`, or `lg`
    let realSize = size;
    const sizeOptions = ['sm', 'md', 'lg'];
    if (!sizeOptions.includes(size)) {
        realSize = 'md';
    }
    // Ensure that the tag variant used is one of the options, either `subtle`,
    // `solid`, or `outline` (or undefined)
    let realTagVariant = tagVariant;
    const tagVariantOptions = ['subtle', 'solid', 'outline'];
    if (tagVariant !== undefined) {
        if (!tagVariantOptions.includes(tagVariant)) {
            realTagVariant = 'subtle';
        }
    }
    // Ensure that the tag variant used is one of the options, either `subtle`,
    // `solid`, or `outline` (or undefined)
    let realSelectedOptionStyle = selectedOptionStyle;
    const selectedOptionStyleOptions = ['color', 'check'];
    if (!selectedOptionStyleOptions.includes(selectedOptionStyle)) {
        realSelectedOptionStyle = 'color';
    }
    // Ensure that the color used for the selected options is a string
    let realSelectedOptionColor = selectedOptionColor;
    if (typeof selectedOptionColor !== 'string') {
        realSelectedOptionColor = 'blue';
    }
    const select = Object.assign(Object.assign({ components: Object.assign(Object.assign({}, potionComponents), components), colorScheme, size: realSize, tagVariant: realTagVariant, selectedOptionStyle: realSelectedOptionStyle, selectedOptionColor: realSelectedOptionColor, 
        // isDisabled and isInvalid can be set on the component
        // or on a surrounding form control
        isDisabled: inputProps.disabled, isInvalid: !!inputProps['aria-invalid'], inputId: inputId || inputProps.id, hasStickyGroupHeaders, placeholderColor: 'gray.400', potionStyles,
        focusBorderColor,
        errorBorderColor }, props), { 
        // aria-invalid can be passed to react-select, so we allow that to
        // override the `isInvalid` prop
        'aria-invalid': ((_b = props['aria-invalid']) !== null && _b !== void 0 ? _b : inputProps['aria-invalid']) ? true : undefined });
    return select;
};
export default usePotionSelectProps;
