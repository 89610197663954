import React from 'react';
import * as yup from 'yup';
import unset from 'lodash/unset';
import { Box, Text, Heading, Button, Center, VStack } from '@companydotcom/potion';
import { InputField, SelectField, ReactSelectFieldAsync } from '@companydotcom/ui';
import { companyHelpers } from '@companydotcom/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { User } from '@companydotcom/types';
import stateOptions from './state-options';
import { useUpdateAccountBusinessMutation } from '../../../services/user/user-api';
// import { updateAccountBusiness } from '../../../services/user/old-api/user-svc';

const getBusinessProfileFormSchema = () =>
  yup.object().shape({
    address: yup.string().required('Please enter an address'),
    businessCategory: yup
      .object()
      .shape({
        fullName: yup.string(),
        id: yup.string(),
        name: yup.string(),
        publisher: yup.string().oneOf(['GMB', 'YEXT']),
        __typename: yup.string().notRequired(),
      })
      .required('Please select a category for your business'),
    businessName: yup.string().required('Please enter a business name'),
    city: yup.string().required('Please enter a city'),
    numOfEmployees: yup.string().required('Please enter number of employees'),
    state: yup.string().required('Please enter a state'),
    zipCode: yup
      .string()
      .required()
      .test('zip code', 'Please enter a valid zip code', val =>
        companyHelpers.validateZipCode(val),
      ),
  });

export interface BusinessProfileFormProps {
  productName?: string;
  handleDataFormFill?: (state?: any) => void;
  globalUser?: User;
  handleAutocompleteLookup?: (val: any, publisher?: string) => void;
}

export const BusinessProfileForm = ({
  productName,
  handleDataFormFill,
  globalUser,
  handleAutocompleteLookup,
}: BusinessProfileFormProps) => {
  const [updateAccountBusiness] = useUpdateAccountBusinessMutation();
  const { t } = useTranslation();

  const defaultValues = {
    accountId: globalUser?.account?.accountId || '',
    businessId: globalUser?.account?.businessPrimary?.businessId || '',
    businessName: globalUser?.account?.businessPrimary?.name || '',
    city: globalUser?.account?.businessPrimary?.address?.city || '',
    state: globalUser?.account?.businessPrimary?.address?.state || '',
    zipCode: globalUser?.account?.businessPrimary?.address?.zipCode || '',
    address: globalUser?.account?.businessPrimary?.address?.addressLine1 || '',
    numOfEmployees: globalUser?.account?.businessPrimary?.numberOfEmployees || undefined,
    businessCategory:
      globalUser?.account?.businessPrimary?.categories?.filter(
        cat => cat?.publisher === 'GMB',
      )?.[0] ||
      globalUser?.account?.listing?.categories?.[0] ||
      {},
  };

  const { register, handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getBusinessProfileFormSchema()),
    defaultValues,
  });
  const { isSubmitting, isValid, errors } = formState;

  const categoryOptions = (value: any) => {
    if (handleAutocompleteLookup) {
      const categories = handleAutocompleteLookup(value);
      return categories;
    }
  };

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      await updateAccountBusiness({
        userId: globalUser?.userId,
        accountId: globalUser?.accountId,
        businessName: values.businessName,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        address: values.address,
        businessId: globalUser?.account?.businessPrimary?.businessId,
        numberOfEmployees: values.numOfEmployees,
        categories: [
          ...(globalUser?.account?.businessPrimary?.categories || [{}])
            .map(cat => {
              if (cat?.__typename) {
                // @ts-ignore
                cat = unset(cat, '__typename');
              }
              return cat;
            })
            .filter(rec => rec?.publisher === 'GMB'),
          {
            fullName: values?.businessCategory?.fullName,
            id: values?.businessCategory?.id,
            name: values?.businessCategory?.name,
            publisher: values?.businessCategory?.publisher,
          },
        ],
      }).then(() => {
        if (handleDataFormFill) {
          handleDataFormFill();
        }
      });
    } catch (err) {
      console.log(err);
      if (handleDataFormFill) {
        handleDataFormFill();
      }
    }
  };

  return (
    <Center
      className="form-businessProfileForm__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box textAlign="center">
        <Heading as="h1" size="hs-xl">
          {t('forms.businessProfile.heading')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          {t('forms.businessProfile.subheading', { productName })}
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('common.misc.allFieldsRequired')}
        </Text>
      </Box>
      <VStack
        as="form"
        className="form-businessProfileForm__form"
        width="full"
        maxWidth={430}
        justifyContent="center"
        alignItems="center"
        mt={[8, 10]}
        spacing={6}
      >
        <InputField
          register={register}
          name="businessName"
          label={t('common.inputs.businessName.label')}
          errors={errors}
        />
        <InputField
          register={register}
          name="address"
          label={t('common.inputs.address.label')}
          errors={errors}
        />
        <InputField
          register={register}
          name="city"
          label={t('common.inputs.city.label')}
          errors={errors}
        />

        <SelectField
          register={register}
          name="state"
          label={t('common.inputs.state.label')}
          errors={errors}
        >
          {stateOptions.map(opt => (
            <option value={opt.value} key={opt.value}>
              {opt.label}
            </option>
          ))}
        </SelectField>

        <InputField
          register={register}
          name="zipCode"
          label={t('common.inputs.zipCode.label')}
          errors={errors}
        />

        <InputField
          register={register}
          name="numOfEmployees"
          type="number"
          min="0"
          label={t('forms.businessProfile.inputs.numOfEmployees.label')}
          errors={errors}
        />
        <ReactSelectFieldAsync
          className="businessProfileForm__businessCategoryField"
          data-test="businessProfileForm__businessCategoryField"
          control={control}
          errors={errors}
          name="businessCategory"
          label={t('common.inputs.businessCategory.label')}
          loadOptions={categoryOptions}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => (option.id === null ? undefined : option.id)}
        />

        {errors.businessCategory && (
          <Text mt={2} fontSize={['12px']} width="full" textAlign="left" color="text_error">
            {t('common.inputs.businessCategory.error')}
          </Text>
        )}
      </VStack>
      <Box textAlign="center" mt={[8, 12]}>
        <Button
          className="form-businessProfileForm_submitButton"
          data-test="form-businessProfileForm_submitButton"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
          isLoading={isSubmitting}
          size="lg"
        >
          {t('common.buttons.confirm')}
        </Button>
      </Box>
    </Center>
  );
};
