import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseButtonLayout } from '../../features/facecake/layouts/close-button-layout';
import { FaceCakeScheduleAConsultation } from '../../features/facecake/facecake-calendly';

export const FaceCakeScheduleAConsultationPage = () => {
  const navigate = useNavigate();
  return (
    <CloseButtonLayout
      onBackClick={() => {
        navigate('/');
      }}
    >
      <FaceCakeScheduleAConsultation />
    </CloseButtonLayout>
  );
};
