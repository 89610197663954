import React from 'react';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Text } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';

export const Error401 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Text textStyle="lg" fontWeight="medium" color="red.500" mb={12}>
        {t('subpage.generalError.401.heading')}
      </Text>
      <ButtonGroup size="lg" mb={12} spacing={8}>
        <Button variant="outline" onClick={() => navigate(-1)}>
          {t('subpage.generalError.401.goBack')}
        </Button>
        <Button as={NavLink} to="/login">
          {t('subpage.generalError.401.logIn')}
        </Button>
      </ButtonGroup>
    </>
  );
};
