import React, { useState, useEffect, useCallback } from 'react';
import { Box, useTabStepper, Tabs, TabPanels, TabPanel, Button } from '@companydotcom/potion';
import { User } from '@companydotcom/types';
import { FontAwesomeIcon, AppSpinner } from '@companydotcom/ui';
import { useNavigate } from 'react-router-dom';
import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons';
import { HowItWorks, DarkWebForm, DarkWebResults, DarkWebDetailedResults } from '..';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import {
  useGetScanStatusQuery,
  useLazyGetLatestScanResultsQuery,
} from '../../../services/dark-web-scan/dark-web-scan-api';
import { updateDarkWebScanResults } from '../dark-web-scan-slice';
import { useAppDispatch, useAwaitableFacade } from '../../../hooks';
import { useTranslation } from 'react-i18next';
const darkWebSteps = [
  {
    slug: 'how-it-works',
    label: 'How it Works',
    component: <HowItWorks />,
  },
  {
    slug: 'dark-web-form',
    label: 'Dark Web Form',
    component: <DarkWebForm />,
  },
  {
    slug: 'dark-web-results',
    label: 'Dark Web Results',
    component: <DarkWebResults />,
  },
  {
    slug: 'dark-web-detailed-result',
    label: 'Dark Web Detailed Results',
    component: <DarkWebDetailedResults />,
  },
];

export interface DarkWebScanSharedProps {
  nextStep?: () => void;
  previousStep?: (arg0: number | string) => void;
  isLoading?: boolean;
  fetchingResults?: boolean;
  darkWebScan?: any;
  setDarkWebScan?: () => void;
  detailedTag?: any;
  setDetailedTag?: () => void;
  userScanInformation?: any;
  darkScanStatus?: any;
  globalUser?: User;
}

export const DarkWebScan = () => {
  const { data: globalUser } = useGetGlobalUserQuery({
    refetchOnMountOrArgChange: true,
  });
  const snsInterface = useAwaitableFacade(globalUser, 'darkWebScan');
  const [getLatestResults, { isLoading: fetchingResults }] = useLazyGetLatestScanResultsQuery();
  const { data: darkScanStatus } = useGetScanStatusQuery({
    user: globalUser as User,
    snsInterface,
  });
  const [detailedTag, setDetailedTag] = useState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tabIndex, handleTabsChange, nextStep, goToStep, previousStep } = useTabStepper({
    steps: darkWebSteps,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initialStepLogic = useCallback(
    async (scanInformation: any) => {
      if (scanInformation && scanInformation?.firstScan && scanInformation?.available) {
        goToStep('how-it-works');
      } else if (!scanInformation?.available) {
        const res = await getLatestResults({ snsInterface }).unwrap();
        dispatch(updateDarkWebScanResults(res.results.scan));
        goToStep('dark-web-results');
      } else {
        goToStep('dark-web-form');
      }
      setIsLoading(false);
    },
    [dispatch, getLatestResults, goToStep, snsInterface],
  );

  const sharedProps = {
    detailedTag,
    setDetailedTag,
    darkScanStatus,
    globalUser,
    nextStep,
    isLoading,
    fetchingResults,
  };

  useEffect(() => {
    if (globalUser?.userId && darkScanStatus) {
      initialStepLogic(darkScanStatus);
    }
    // eslint-disable-next-line
  }, [darkScanStatus, globalUser?.userId]);

  return (
    <Box className="company__dark-web-scan">
      <Button
        alignSelf="flex-start"
        variant="ghost"
        pl={1}
        leftIcon={<FontAwesomeIcon icon={faArrowLeftLong} />}
        onClick={() => {
          if (tabIndex === 3) {
            previousStep?.();
          } else {
            navigate('/');
          }
        }}
      >
        {tabIndex === 3 ? t('containers.darkWebScan.toResults') : t('common.buttons.toDashboard')}
      </Button>
      <Box maxWidth={1100} width="full" className="dark-web-scan">
        <Tabs
          isLazy
          layerStyle="formContainer"
          variant="unstyled"
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <TabPanels>
            {isLoading || fetchingResults ? (
              <AppSpinner containerStyles={{ flexDirection: 'row', height: '800px' }} />
            ) : (
              darkWebSteps.map((step, index) => (
                <TabPanel key={index}>{React.cloneElement(step.component, sharedProps)}</TabPanel>
              ))
            )}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
