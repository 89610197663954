import React from 'react';
import { Image, Text, VStack, HStack, useDisclosure, Button, Flex } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { useEventData } from '@companydotcom/ui';
import type { TileComponentProps } from '../../types';
import { Rating } from '../../../../components/elements/rating';

export interface TileRecentReviewProps extends TileComponentProps {}

export const TileRecentReview: React.FC<TileRecentReviewProps> = props => {
  const { eventData, componentTheme } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = useEventData<any>(eventData);
  const { t, i18n } = useTranslation();

  return (
    <VStack className="tile-recentReview" px={4} sx={componentTheme}>
      <HStack justifyContent="space-between" width="full">
        <HStack>
          <Image
            borderRadius="100%"
            width={12}
            height={12}
            src={`${
              process.env.REACT_APP_IMG_URL
            }/visibility-assets/static/${data?.latestReview?.publisherId?.toUpperCase()}.png`}
          />
          <VStack alignItems="flex-start" spacing={1}>
            <Text textStyle="sm">
              {data?.latestReview?.publisherDate
                ? new Date(data?.latestReview?.publisherDate).toLocaleDateString(i18n.language)
                : ''}
            </Text>
            <Text textStyle="sm">
              {data?.latestReview?.authorName ? data?.latestReview?.authorName : ''}
            </Text>
          </VStack>
        </HStack>
        <Rating scale={5} rating={data?.latestReview?.rating} />
      </HStack>
      <Text textStyle="sm" overflowY="scroll" noOfLines={isOpen ? undefined : 2} maxHeight={139}>
        {!data?.latestReview ? t('components.review.noReviewsYet') : data?.latestReview?.content}
      </Text>
      <Flex justifyContent="flex-end" width="full">
        <Button variant="link" fontSize="sm" onClick={isOpen ? onClose : onOpen}>
          {isOpen ? t('components.review.seeLess') : t('components.review.seeMore')}
        </Button>
      </Flex>
    </VStack>
  );
};
