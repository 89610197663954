import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import {
  AppSpinner,
  PageDivider,
  useGetCurrentlySelectedCountry,
  PhoneNumberInputField,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { Box, Text, Button, Heading, Center } from '@companydotcom/potion';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useAuth } from '../../../providers';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { useLazyGenerateAndSendOtpQuery } from '../../../services/enrollment/enrollment-api';
import { useResendEmailActivationMutation } from '../../../services/auth/auth-api';
import yup from '../../../lib/yup';

// import { resendEmailActivation } from '../../../services/auth/old-api/auth-svc';
// import { generateAndSendOtp } from '../../../services/enrollment/old-api/enrollment-svc';

const getVerifyPhoneSchema = () =>
  yup.object().shape({
    phone: yup.string().phone().required('Please enter a valid phone number').nullable(),
  });

export interface VerifyPhoneProps {
  isResend?: boolean;
  isBack?: boolean;
  onSubmit?: () => void;
  secondVerification?: boolean;
}

export const VerifyPhone = ({
  isResend,
  isBack,
  onSubmit,
  secondVerification,
}: VerifyPhoneProps) => {
  const [emailVerificationButtonLoading, setEmailVerificationButtonLoading] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const auth0 = useAuth();
  const { t } = useTranslation();
  const { data: globalUser, isLoading } = useGetGlobalUserQuery();
  const [generateAndSendOtp] = useLazyGenerateAndSendOtpQuery();
  const [resendEmailActivation] = useResendEmailActivationMutation();
  const storedPhoneNo = localStorage.getItem('user_phoneno');
  const { country, onCountryChange } = useGetCurrentlySelectedCountry('US');

  const defaultValues: FieldValues = {
    phone: isResend
      ? storedPhoneNo
      : (globalUser?.phone && country && parsePhoneNumber(globalUser?.phone, country)?.number) ||
        '',
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getVerifyPhoneSchema()),
    context: {
      country,
    },
    defaultValues,
  });

  useEffect(() => {
    if (globalUser?.phone && country && !isResend) {
      reset({
        phone: parsePhoneNumber(globalUser?.phone, country).number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalUser?.phone]);

  useEffect(() => {
    if (isResend && storedPhoneNo && country) {
      reset({
        phone: parsePhoneNumber(storedPhoneNo, country).number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResend]);

  const sendEmailVerification = async () => {
    try {
      if (auth0?.user && auth0?.user.email) {
        setEmailVerificationButtonLoading(true);
        await resendEmailActivation({ email: auth0.user.email }).unwrap();
        if (onSubmit) {
          onSubmit();
        }
        setEmailVerificationButtonLoading(false);
        navigate({ pathname: '/verify', search });
      } else {
        setEmailVerificationButtonLoading(false);
        navigate('/logout');
      }
    } catch (err) {
      setEmailVerificationButtonLoading(false);
      if (!onSubmit) {
        navigate('/logout');
      }
    }
  };

  const submitHandler = async (values: typeof defaultValues) => {
    try {
      if (values.phone && country) {
        const phoneno = getFormattedPhoneNumber(values.phone, country);
        const userId = auth0?.user?.userId;
        if (userId) {
          const res = await generateAndSendOtp({
            userId,
            phoneNumber: phoneno,
            useEmail: false,
          }).unwrap();
          if (res.resp.includes('200')) {
            localStorage.setItem('user_phoneno', phoneno);
            if (onSubmit) {
              onSubmit();
            } else {
              navigate({ pathname: '/verifycode', search });
            }
          }
        }
      }
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  // eslint-disable-next-line no-nested-ternary
  const title = secondVerification
    ? t('subpage.verifyPhone.secureYourAccount')
    : isBack
    ? t('subpage.verifyPhone.welcome')
    : `${isResend ? t('subpage.verifyPhone.resend') : t('subpage.verifyPhone.getA')} ${t(
        'subpage.verifyPhone.verificationCode',
      )}`;

  const subheading = isBack
    ? t('subpage.verifyPhone.subheadingIsBack')
    : t('subpage.verifyPhone.subheadingEnter');

  return (
    <Center
      className="dashboard-verifyPhone__container"
      maxWidth="2xl"
      flexDirection="column"
      layerStyle="formContainer"
      boxShadow={!secondVerification ? 'lg' : 'none'}
      py={!secondVerification ? [8, 12] : 0}
      px={4}
      borderRadius={!secondVerification ? 'md' : 'none'}
      border={!secondVerification ? 'inherit' : 'none'}
    >
      {!auth0?.user || isLoading ? (
        <AppSpinner containerStyles={{ minH: 'sm' }} />
      ) : (
        <>
          <Box maxWidth={[480]} textAlign={['left', 'center']}>
            <Heading size="hs-2xl" maxWidth={[480]} width="full">
              {title}
            </Heading>
            <PageDivider width="50px" mt="4" alignSelf="flex-start" display={['block', 'none']} />

            <Text textStyle="md" mt={5}>
              {subheading}
            </Text>
            <Text mt={2}>{t('subpage.verifyPhone.caption')}</Text>
          </Box>
          <Box as="form" maxWidth={['100%', 328]} width="full" textAlign="center" mt={[8, 10]}>
            <PhoneNumberInputField
              name="phone"
              label={t('common.inputs.phoneNumber.label')}
              className="dashboard-verifyPhone__phoneField"
              data-test="dashboard-verifyPhone__phoneField"
              control={control}
              country={country}
              onCountryChange={onCountryChange}
              errors={errors}
            />
            <Box width={['full', 'auto']}>
              <Button
                onClick={handleSubmit(submitHandler)}
                type="submit"
                size="lg"
                marginY={12}
                isLoading={isSubmitting}
                isDisabled={!isValid}
                className="dashboard-verifyPhone__button"
                data-test="dashboard-verifyPhone__button"
                width={['full', 'inherit']}
              >
                {t('subpage.verifyPhone.submitButton')}
              </Button>
            </Box>
          </Box>
          <Box textAlign="center">
            {!secondVerification && (
              <Trans i18nKey="subpage.verifyPhone.emailVerify">
                <Text fontSize="sm">
                  Don’t want to use your phone?
                  <Button
                    onClick={sendEmailVerification}
                    isDisabled={emailVerificationButtonLoading}
                    variant="link"
                    role="button"
                    fontSize="sm"
                    _hover={{
                      textDecoration: 'none',
                    }}
                    className="dashboard-verifyPhone__emailVerificationLink"
                    data-test="dashboard-verifyPhone__emailVerificationLink"
                  >
                    Send an email verification
                  </Button>
                  .
                </Text>
              </Trans>
            )}
            <Text fontSize="sm" mt={[16, 20]}>
              {t('subpage.verifyPhone.ratesApply')}
            </Text>
          </Box>
        </>
      )}
    </Center>
  );
};
