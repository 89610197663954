import React from 'react';
import { Box, Text, Heading, Button, Center } from '@companydotcom/potion';
import { CheckCircleIcon } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { useUserProfileContext } from '../../../providers/user-profile-context';

export const NewUserAddedStep = () => {
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();
  const { t } = useTranslation();

  const closeModal = () => {
    dispatchUserProfileState?.({ type: 'RESET_ALL_USER_PROFILE_STATE' });
  };

  return (
    <Center bg="white" flexDirection="column" pt={14} pb={16} px={4}>
      <Box maxWidth={[310, 749]} sx={{ textAlign: 'center' }} wordBreak="break-all">
        <CheckCircleIcon mb={6} />
        <Heading size="hs-2xl">{`${t('miop.userProfile.addNewUser.success.new')} ${
          userProfileState?.productIntention
            ? `${t('miop.userProfile.addNewUser.success.email')} `
            : ''
        }${t('miop.userProfile.addNewUser.success.userAdded')}`}</Heading>
        <Text textStyle="2xl" mt={4} sx={{ wordWrap: 'break-word' }}>
          {t('miop.userProfile.addNewUser.success.sentEmailTo')}{' '}
          <Button variant="link" whiteSpace="normal" fontSize="2xl" userSelect="none">
            {userProfileState?.newUserInformation?.personalEmail ||
              userProfileState?.newUserInformation?.firstName}
          </Button>
        </Text>
        <Box as="hr" layerStyle="divider" mt={10} mb={6} maxW="full" />
        <Text textStyle="md" mx="auto">
          {userProfileState?.productIntention
            ? t('miop.userProfile.addNewUser.success.copyWithEmail')
            : t('miop.userProfile.addNewUser.success.copyNoEmail')}
        </Text>
      </Box>
      <Box mt={10} sx={{ textAlign: 'center' }}>
        <Button size="lg" onClick={closeModal}>
          {t('common.buttons.gotIt')}
        </Button>
      </Box>
    </Center>
  );
};
