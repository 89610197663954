import React from 'react';
import { TabPanel } from '@companydotcom/potion';

import { TileCarousel } from '../tile-carousel';
import { TileSpinner } from '../tile-spinner';
import { TileAcgMediaGrowthTv, TileAcgMediaNews, TileAcgMediaMagazines } from './steps';

import {
  useGetGrowthTvFeedV2Query,
  useGetMagazinesFeedV2Query,
  useGetNewsTrendsFeedV2Query,
} from '../../../../services/acg/acg-api-v2';

export const TileAcgMediaFeed: React.FC<{}> = () => {
  const { data: growthTvData, isLoading: growthTvIsLoading } = useGetGrowthTvFeedV2Query({});
  const { data: magazineData, isLoading: magazineIsLoading } = useGetMagazinesFeedV2Query({});
  const { data: newsTrendsData, isLoading: newsTrendsIsLoading } = useGetNewsTrendsFeedV2Query({});

  const children = [
    <TileAcgMediaGrowthTv {...growthTvData} />,
    <TileAcgMediaMagazines {...magazineData} />,
    <TileAcgMediaNews {...newsTrendsData} />,
  ];

  return growthTvIsLoading || magazineIsLoading || newsTrendsIsLoading ? (
    <TileSpinner />
  ) : (
    <TileCarousel
      viewLabels={['latest from growthtv', 'latest from magazines', 'latest from news & trends']}
    >
      {children?.map((c, k) => (
        <TabPanel
          key={k}
          display="flex"
          flexDirection="column"
          width="full"
          height="inherit"
          padding={0}
          alignItems="center"
        >
          {React.cloneElement(c)}
        </TabPanel>
      ))}
    </TileCarousel>
  );
};
