import React from 'react';
import { Button, Flex, Text } from '@companydotcom/potion';
import { User, AcgMembership, Maybe } from '@companydotcom/types';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../hooks';
import { setRenewInvoice, setSelectedPackage } from '../../../../acg/acg-checkout/checkout-slice';
import { getFriendlyACGDate, useAcgCartStorage } from '../../../../acg';
import { useCreateCartForInvoiceMutation } from '../../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../../services/user/user-api';

export interface TileAcgMembershipPurchaseStepProps extends AcgMembership {
  chapterName: Maybe<string>;
}

// TODO - retype this properly
export const TileAcgMembershipPurchaseStep: React.FC<
  TileAcgMembershipPurchaseStepProps
> = props => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const { chapterName, expireDate, openInvoices } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createCartForInvoice, { isLoading }] = useCreateCartForInvoiceMutation();
  const { addMembershipToLocalCart } = useAcgCartStorage();
  const { t } = useTranslation();

  const handleOnClick = async () => {
    try {
      dispatch(setSelectedPackage(props));

      const res = await createCartForInvoice({
        user: globalUser as User,
        payload: {
          invoiceId: openInvoices?.invoiceRecordKey ?? '',
        },
      }).unwrap();

      if (res && res?.length) {
        dispatch(setRenewInvoice(res));
        addMembershipToLocalCart({ ...res[0], ...props });
        navigate('/acg-shopping-cart', { state: { flow: 'checkout' } });
      }
    } catch (err) {
      throw new Error();
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="full"
      flexGrow={1}
      px={4}
      mt={2}
      justifyContent="space-between"
    >
      <Text textStyle="md">
        <Trans i18nKey="acg.membershipStatus.expired.body" values={{ chapterName }}>
          <Text as="span" color="error" fontWeight="700" display="inline">
            Your membership has expired.
          </Text>
        </Trans>
      </Text>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Text fontWeight="700" mb={2} textAlign="center">
            Chapter(s): {chapterName}
          </Text>
          <Text color="error" fontWeight="700" mb={4} textAlign="center">
            Membership Exp: {expireDate ? getFriendlyACGDate(expireDate) : '-'}
          </Text>
        </Flex>
        <Button
          variant="tile"
          onClick={handleOnClick}
          isLoading={isLoading}
          size="md"
          type="submit"
        >
          {t(`acg.membershipStatus.expired.cta`)}
        </Button>
      </Flex>
    </Flex>
  );
};
