import React from 'react';
import { Center } from '@companydotcom/potion';
import { LeadGen } from '../../features/facecake/facecake-unqualified-flow';

export const FaceCakeLeadGenPage = () => {
  return (
    <Center width="full">
      <LeadGen />
    </Center>
  );
};
