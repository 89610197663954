import { Flex, VStack, Stack, Text, Skeleton } from '@companydotcom/potion';
import type { Maybe } from '@companydotcom/types';
import { QAndA, RegisteredEvent } from '../../../../services/acg/acg.types';
import { MyEventsSelectedItemsList } from './my-events-selected-item-list';

export interface MyEventsViewGuestListProps
  extends Partial<
    Pick<
      RegisteredEvent,
      | 'registrationId'
      | 'registrantName'
      | 'organizationName'
      | 'badgeName'
      | 'registrantType'
      | 'invoiceDetails'
    >
  > {
  variant?: 'multiple' | 'single';
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  individualType?: Maybe<string> | null;
  title?: Maybe<string>;
  qAndA?: Partial<QAndA>[];
}

export const MyEventsViewGuestList = ({
  variant,
  isLoading,
  isError,
  registrantName,
  organizationName,
  badgeName,
  title,
  qAndA,
  registrantType,
  individualType,
  invoiceDetails,
}: MyEventsViewGuestListProps) => {
  return (
    <Flex
      width="full"
      className="view-guest-list"
      pt={4}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderTopWidth={variant === 'multiple' ? '1px' : 'none'}
    >
      <Stack
        direction={['column', null, null, 'row']}
        spacing={[0, 8, 14]}
        width="full"
        align="flex-start"
        mb={12}
      >
        <VStack width="full" spacing={4} alignItems="start">
          <Text fontWeight="semibold" fontSize="lg">
            Registrant Information
          </Text>
          <VStack spacing={1} alignItems="start">
            <Text>Registrant Name: </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text textAlign="left" alignSelf="start" fontWeight="semibold" fontSize="md">
                {isLoading ? '&#32&#32&#32&#32&#32' : isError ? '' : registrantName}
              </Text>
            </Skeleton>
          </VStack>
          <VStack spacing={1} alignItems="start">
            <Text>Registrant Type:</Text>
            <Skeleton isLoaded={!isLoading}>
              <Text textAlign="left" alignSelf="start" fontWeight="semibold" fontSize="md">
                {isLoading ? '&#32&#32&#32&#32&#32' : isError ? '' : registrantType}
              </Text>
            </Skeleton>
          </VStack>
          <VStack spacing={1} alignItems="start">
            <Text>Function/Role:</Text>
            <Skeleton isLoaded={!isLoading}>
              <Text textAlign="left" alignSelf="start" fontWeight="semibold" fontSize="md">
                {isLoading ? '&#32&#32&#32&#32&#32' : isError ? '' : individualType}
              </Text>
            </Skeleton>
          </VStack>
        </VStack>
        <VStack width="full" spacing={4} alignItems="start" mt={[12, 0]}>
          <Text fontWeight="semibold" fontSize="lg">
            Badge Information
          </Text>
          <VStack spacing={1} alignItems="start">
            <Text>Badge First Name: </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text textAlign="left" alignSelf="start" fontWeight="semibold" fontSize="md">
                {isLoading ? '&#32&#32&#32&#32&#32' : isError ? '' : badgeName}
              </Text>
            </Skeleton>
          </VStack>
          <VStack spacing={1} alignItems="start">
            <Text>Organization: </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text textAlign="left" alignSelf="start" fontWeight="semibold" fontSize="md">
                {isLoading
                  ? '&#32&#32&#32&#32&#32'
                  : isError
                  ? ''
                  : organizationName ?? `&#32&#32&#32&#32&#32`}
              </Text>
            </Skeleton>
          </VStack>
          <VStack spacing={1} alignItems="start">
            <Text>Title: </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text textAlign="left" alignSelf="start" fontWeight="semibold" fontSize="md">
                {isLoading ? '&#32&#32&#32&#32&#32' : isError ? '' : title}
              </Text>
            </Skeleton>
          </VStack>
        </VStack>
      </Stack>
      {qAndA && qAndA.length > 0 && (
        <VStack width="full" spacing={4} alignItems="start" mb={12} borderBottomWidth="1px" pb={12}>
          <Text fontWeight="semibold" fontSize="lg">
            Questions From The Event Organizers
          </Text>
          {qAndA.map((qAndA, index) => (
            <VStack key={index} spacing={1} alignItems="start">
              <Text>{qAndA.question}</Text>
              <Text textAlign="left" alignSelf="start" fontWeight="semibold" fontSize="md">
                {qAndA.answer}
              </Text>
            </VStack>
          ))}
        </VStack>
      )}
      {invoiceDetails && <MyEventsSelectedItemsList purchased passedEvent data={invoiceDetails} />}
    </Flex>
  );
};
