import React, { useState, useCallback, useEffect } from 'react';
import _get from 'lodash/get';
import { LinearProgress } from './linear-progress';
import { CircleProgress } from './circle-progress';
import { CircleWithLegend } from './circle-with-legend';
import type { TileComponentProps } from '../../types';
import {
  getDatasetKey,
  getMetadata,
  getColors,
  quantitativeProgress,
  quantitativeCircleNoData,
  barChart,
} from '../../../../utils';

export interface TileChartProps extends TileComponentProps {}

export const TileChart: React.FC<TileChartProps> = props => {
  const { params } = props;

  const generateData = useCallback(() => {
    return _get(props, getDatasetKey(props));
  }, [props]);

  const generateMetadata = useCallback(() => {
    return getMetadata(props);
  }, [props]);

  const [data, setData] = useState(generateData());
  const [metadata, setMetadata] = useState(generateMetadata());

  useEffect(() => {
    setData(generateData());
    setMetadata(generateMetadata());
  }, [generateData, generateMetadata]);

  switch (props.variant) {
    case 'circle_progress':
      return (
        <CircleProgress chartData={quantitativeProgress(data)} dataColor={getColors({ params })} />
      );
    case 'circle_with_legend': //this used for threat advice education
      return (
        <CircleWithLegend
          chartData={quantitativeCircleNoData(data)}
          dataColor={getColors({ params })}
        />
      );
    case 'linear_progress':
      return <LinearProgress chartData={barChart(data, metadata)} {...props} />;
    default:
      return <div>Chart type not defined.</div>;
  }
};
