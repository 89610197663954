import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  useMultiStyleConfig,
  Button,
  Heading,
  StylesProvider,
  UnorderedList,
  ListItem,
} from '@companydotcom/potion';
import { NavLink } from 'react-router-dom';
import { PasswordInputField } from '@companydotcom/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import { parseUserProducts } from '../../../services/product/utils';
import { useAuth } from '../../../providers';
import { useAwaitableFacade, useToast } from '../../../hooks';
import { PageHeading } from '../../../components/elements';
import {
  useGetGlobalUserQuery,
  useLazyGetUserLiteQuery,
  useUpdateMailboxPasswordMutation,
} from '../../../services/user/user-api';
// import {
// updateMailboxPassword,
// getUserLite
// } from '../../../services/user/old-api/user-svc';

const { REACT_APP_EMAIL_PRODUCTID } = process.env;

const EmailPasswordSettingsStepSchema = () =>
  yup.object().shape({
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), undefined], 'common.inputs.confirmNewPassword.validation')
      .required('common.inputs.confirmNewPassword.error'),
    newPassword: yup
      .string()
      .required('common.inputs.newPassword.error')
      .min(8, 'common.inputs.newPassword.validation')
      .test('isValidPass', 'common.inputs.newPassword.validation2', (value = '') => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbole = /[!@#%&]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
        conditions.forEach(condition => (condition ? validConditions++ : null));
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }),
  });

interface EmailPasswordSettingsStepProps {
  external?: boolean;
}

export const EmailPasswordSettingsStep = ({
  external,
  ...rest
}: EmailPasswordSettingsStepProps) => {
  const { data: globalUser } = useGetGlobalUserQuery({
    refetchOnMountOrArgChange: true,
  });
  const [getUserLiteQuery] = useLazyGetUserLiteQuery();
  const [updateMailboxPassword] = useUpdateMailboxPasswordMutation();
  const [isEmailProductActive, setIsEmailProductActive] = useState(undefined);
  const [isEmailActive, setIsEmailActive] = useState<boolean | undefined>(undefined);
  const { t } = useTranslation();
  const auth0 = useAuth();
  const toast = useToast();
  const snsInterface = useAwaitableFacade(globalUser, 'mailBox');
  const styles = useMultiStyleConfig('EmailPasswordSettingsStep', rest);

  const defaultValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const { register, handleSubmit, setError, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(EmailPasswordSettingsStepSchema()),
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const { isSubmitting, errors, isValid } = formState;

  useEffect(() => {
    if (globalUser && globalUser.products) {
      const parsed = parseUserProducts(globalUser.products);
      const foundProduct = parsed.find(
        (accProd: any) =>
          accProd.productId === REACT_APP_EMAIL_PRODUCTID && !!accProd.dateActivated,
      );
      if (parsed.length > 0 && isEmailProductActive === undefined) {
        setIsEmailProductActive(foundProduct);
      }
      setIsEmailActive(!(foundProduct === undefined || null));
    }
  }, [globalUser, isEmailProductActive]);

  const manualErrors = [
    {
      type: 'manual',
      name: 'newPassword',
      message: 'common.misc.tryAgain',
    },
    {
      type: 'manual',
      name: 'confirmNewPassword',
      message: 'common.misc.tryAgain',
    },
  ];

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      // Handle different user logic if user comes in from /change-email-password route as globalUser is not available
      if (globalUser) {
        if (external && auth0?.user?.userId) {
          await getUserLiteQuery({ userId: auth0?.user?.userId }).then(() =>
            updateMailboxPassword({ password: values.confirmNewPassword, snsInterface }).then(
              (res: any) => {
                const result = JSON.parse(res);
                if (result.statusCode === 200) {
                  toast({
                    description: t('miop.passwordManagement.snackbar.success'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  });
                } else {
                  manualErrors.forEach(({ name, type, message }) =>
                    // @ts-ignore
                    setError(name, { type, message }),
                  );
                  toast({
                    description: t('miop.passwordManagement.snackbar.error'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  });
                }
              },
            ),
          );
        } else {
          await updateMailboxPassword({ password: values.confirmNewPassword, snsInterface }).then(
            res => {
              const result = res;
              if (result) {
                toast({
                  description: t('miop.passwordManagement.snackbar.success'),
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                });
              } else {
                manualErrors.forEach(({ name, type, message }) =>
                  // @ts-ignore
                  setError(name, { type, message }),
                );
                toast({
                  description: t('miop.passwordManagement.snackbar.error'),
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                });
              }
            },
          );
        }
      }
    } catch (err) {
      console.log('Error!', err);
      toast({
        description: t('miop.passwordManagement.snackbar.error'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <StylesProvider value={styles}>
      <Box
        className="potion-emailPasswordSettingsStep__container"
        sx={styles.container}
        py={external ? [10, 13] : 0}
      >
        <PageHeading heading={t('miop.passwordManagement.email.header')} mb={6} />
        <Box as="form" className="potion-emailPasswordSettingsStep__form" sx={styles.form}>
          <Box textAlign="left" mb={[6]}>
            {isEmailActive ? (
              <Box>
                <Heading size="hs-md" mb={[6]}>
                  {t('miop.passwordManagement.email.active.header')}
                </Heading>
                <Heading size="hs-sm" mb={[3]}>
                  {t('miop.passwordManagement.email.active.subheader')}
                </Heading>
                <Text textStyle="sm" mb={[2]}>
                  <Trans i18nKey="miop.passwordManagement.email.active.requirements" />
                </Text>
                <UnorderedList textStyle="sm" pl={2}>
                  <ListItem>{t('miop.passwordManagement.email.active.bullet1')}</ListItem>
                  <ListItem>{t('miop.passwordManagement.email.active.bullet2')}</ListItem>
                  <ListItem>{t('miop.passwordManagement.email.active.bullet3')}</ListItem>
                  <ListItem>{t('miop.passwordManagement.email.active.bullet4')}</ListItem>
                  <ListItem>{t('miop.passwordManagement.email.active.bullet4')}</ListItem>
                </UnorderedList>
              </Box>
            ) : (
              <Text textStyle="md">{t('miop.passwordManagement.email.inactive.copy')}</Text>
            )}
          </Box>
        </Box>
        <>
          {isEmailActive && (
            <Box bg="neutral_gray_100">
              <Box maxWidth={430} pt={[10]} pb={[10]}>
                <PasswordInputField
                  register={register}
                  name="newPassword"
                  label={t('common.inputs.newPassword.label')}
                  errors={errors}
                  formControlStyles={{ mb: 6 }}
                />
                <PasswordInputField
                  register={register}
                  name="confirmNewPassword"
                  label={t('common.inputs.confirmNewPassword.label')}
                  errors={errors}
                />
              </Box>
              <Box textAlign="center" mt={[8]}>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  size="lg"
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                  sx={styles.formButton}
                >
                  {t('common.buttons.save')}
                </Button>
              </Box>
            </Box>
          )}
        </>

        {!isEmailActive && (
          <Box sx={{ textAlign: 'center' }} mt={[8, null, 14]}>
            <Button sx={styles.formButton} as={NavLink} to="/">
              {t('common.buttons.toDashboard')}
            </Button>
          </Box>
        )}
      </Box>
    </StylesProvider>
  );
};
