import { QueryPublishTileEventArgs } from '@companydotcom/types';
import { emptySplitApi } from '../../store/empty-split-api';
import { PUBLISH_TILE_EVENT_QUERY, POST_EVENTBRIDGE_QUERY } from './graphql/event-query';

const productsUsingLegacySnsTopics = [
  '785d02a4-2e7a-11e8-ae75-120b17a64360',
  '5a5e560c-8167-4780-bd23-fc90a050b8ec',
];
// yext and uberall/blm and blr

export const EventApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    publishTileEvent: builder.query<any, QueryPublishTileEventArgs>({
      providesTags: ['User', 'DataCollector'],
      query: ({ topic, message, attributes }) => ({
        method: 'query',
        document: PUBLISH_TILE_EVENT_QUERY,
        variables: productsUsingLegacySnsTopics.includes(attributes.entityId)
          ? { topic, message, attributes }
          : { topic: 'event-bus', message, attributes: { ...attributes, eventType: topic } },
      }),
      transformResponse: (rawResult: { data: { publishTileEvent: any } }) =>
        rawResult.data.publishTileEvent,
    }),
    postEventBridge: builder.query<any, QueryPublishTileEventArgs>({
      providesTags: ['User', 'DataCollector'],
      query: ({ topic, message, attributes }) => ({
        method: 'query',
        document: POST_EVENTBRIDGE_QUERY,
        variables: productsUsingLegacySnsTopics.includes(attributes.entityId)
          ? { topic, message, attributes }
          : { topic: 'event-bus', message, attributes: { ...attributes, eventType: topic } },
      }),
      transformResponse: (rawResult: { data: { publishTileEvent: any } }) =>
        rawResult.data.publishTileEvent,
    }),
  }),
});

export const {
  usePublishTileEventQuery,
  useLazyPublishTileEventQuery,
  usePostEventBridgeQuery,
  useLazyPostEventBridgeQuery,
} = EventApi;
