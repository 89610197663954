import React from 'react';
import {
  Box,
  Flex,
  Checkbox,
  CheckboxGroup,
  Button,
  Heading,
  Center,
  Stack,
  Text,
} from '@companydotcom/potion';
import { useForm } from 'react-hook-form';
import { User, Listing } from '@companydotcom/types';
import { PendingVerification } from './shared-blpcomponents';
import { useAwaitableFacade, useToast } from '../../../../hooks';
import { useUpdateListingMutation } from '../../../../services/listings/listing-api';

interface PaymentAcceptedProps {
  user?: User;
  listing?: Listing;
  setListing?: (arg: Listing) => void;
  showPendingVerification?: boolean;
}

export const PaymentAccepted = ({
  user,
  listing,
  setListing,
  showPendingVerification,
}: PaymentAcceptedProps) => {
  const [updateListing] = useUpdateListingMutation();
  const snsInterface = useAwaitableFacade(user, 'listing');
  const toast = useToast();

  const defaultValues = {
    amex: listing?.paymentOptions?.includes('amex'),
    masterCard: listing?.paymentOptions?.includes('masterCard'),
    visa: listing?.paymentOptions?.includes('visa'),
    discover: listing?.paymentOptions?.includes('discover'),
    debitCard: listing?.paymentOptions?.includes('debitCard'),
    cash: listing?.paymentOptions?.includes('cash'),
    check: listing?.paymentOptions?.includes('check'),
    dinersClub: listing?.paymentOptions?.includes('dinersClub'),
    invoice: listing?.paymentOptions?.includes('invoice'),
    storeCard: listing?.paymentOptions?.includes('storeCard'),
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const defaultCheck = (values: any) => Object.keys(values).filter(type => values[type]);

  const handleDefault = defaultCheck(defaultValues);

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      const updateParams = {
        id: user?.account?.listingId ?? '',
        paymentOptions: Object.keys(values).filter(type => {
          return values[type];
        }),
      };
      // await updateListing({ input: updateParams })
      //   .unwrap()
      //   .then((updatedListing: Listing) => {
      //     setListing?.(updatedListing);
      //     toast({
      //       description: 'Your changes have been synced!',
      //       status: 'success',
      //       duration: 9000,
      //       isClosable: true,
      //       position: 'bottom-right',
      //     });
      //   });

      //if (user) {
      await updateListing({ listingInput: updateParams, snsInterface, source: user?.source })
        .unwrap()
        .then(updatedListing => {
          setListing?.(updatedListing);
          toast({
            description: 'Your changes have been synced!',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });
        });
      //}
    } catch (err) {
      console.log('Error!', err);
    }
  };

  return (
    <Center
      className="blp-payment-accepted"
      flexDirection="column"
      pt={[12, null, 3]}
      pb={[12, null, 15]}
      width="full"
      px={4}
      textAlign="center"
      justifyContent="flex-start"
    >
      {showPendingVerification && <PendingVerification />}
      <Box maxW="xl">
        <Heading as="h1" size="hs-lg">
          Payment Accepted
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          Select what forms are payment are accepted at your business.
        </Heading>
      </Box>
      <Center flexDirection="column">
        <Flex width="full" alignItems="flex-start" flexDirection="column" mt={12} maxWidth={475}>
          <Text textStyle="lg" fontWeight="medium" mb={4}>
            Credit and Debit
          </Text>
          <CheckboxGroup defaultValue={handleDefault}>
            <Stack spacing={[1, 5]} direction={['column']}>
              <Checkbox {...register('amex')} value="amex">
                American Express (AMEX)
              </Checkbox>
              <Checkbox {...register('masterCard')} value="masterCard">
                Master card
              </Checkbox>
              <Checkbox {...register('discover')} value="discover">
                Discover
              </Checkbox>
              <Checkbox {...register('visa')} value="visa">
                Visa
              </Checkbox>
              <Checkbox {...register('debitCard')} value="debitCard">
                Debit Card
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </Flex>

        <Flex width="full" flexDirection="column" alignItems="flex-start" mt={[8, null, 12]}>
          <Text textStyle="lg" fontWeight="medium" mb={4}>
            Additional Forms of Payment
          </Text>
          <CheckboxGroup defaultValue={handleDefault}>
            <Stack spacing={[1, 5]} direction={['column']}>
              <Checkbox {...register('cash')} value="cash">
                Cash
              </Checkbox>
              <Checkbox {...register('check')} value="check">
                Check
              </Checkbox>
              <Checkbox {...register('dinersClub')} value="dinersClub">
                Diner's Club
              </Checkbox>
              <Checkbox {...register('invoice')} value="invoice">
                Invoice
              </Checkbox>
              <Checkbox {...register('storeCard')} value="storeCard">
                Store Card
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </Flex>
        <Box mt={14}>
          <Button
            size="lg"
            isLoading={isSubmitting}
            isDisabled={!isValid || isSubmitting || showPendingVerification}
            variant="outline"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Box>
      </Center>
    </Center>
  );
};
