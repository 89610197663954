var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CloseButton, Divider, MenuIcon, Spinner, StylesProvider, Tag, TagCloseButton, TagLabel, potion, createIcon, useMultiStyleConfig, useStyles, useTheme, } from '@companydotcom/potion';
import { cleanCommonProps } from './utils';
// Taken from the @chakra-ui/icons package to prevent needing it as a dependency
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/icons/src/ChevronDown.tsx
const ChevronDown = createIcon({
    displayName: 'ChevronDownIcon',
    d: 'M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z',
});
// Use the CheckIcon component from the chakra menu
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/menu/src/menu.tsx#L301
const CheckIcon = props => (_jsx("svg", Object.assign({ viewBox: "0 0 14 14", width: "1em", height: "1em" }, props, { children: _jsx("polygon", { fill: "currentColor", points: "5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" }, void 0) }), void 0));
const SelectContainer = (props) => {
    const { children, className, cx, innerProps, isDisabled, isRtl, selectProps: { potionStyles }, } = props;
    const initialStyles = {
        position: 'relative',
        direction: isRtl ? 'rtl' : undefined,
        // When disabled, react-select sets the pointer-state to none which prevents
        // the `not-allowed` cursor style from chakra from getting applied to the
        // Control when it is disabled
        pointerEvents: 'auto',
    };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.container)
        ? potionStyles.container(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ className: cx({
            '--is-disabled': isDisabled,
            '--is-rtl': isRtl,
        }, className), sx: sx }, innerProps, { children: children }), void 0));
};
const ValueContainer = (props) => {
    const { children, className, cx, isMulti, hasValue, selectProps: { size, potionStyles }, } = props;
    const px = {
        sm: '0.75rem',
        md: '1rem',
        lg: '1rem',
    };
    const initialStyles = {
        d: 'flex',
        alignItems: 'center',
        flex: 1,
        p: `0.125rem ${px[size]}`,
        flexWrap: 'wrap',
        WebkitOverflowScrolling: 'touch',
        position: 'relative',
        overflow: 'hidden',
    };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.valueContainer)
        ? potionStyles.valueContainer(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ className: cx({
            'value-container': true,
            'value-container--is-multi': isMulti,
            'value-container--has-value': hasValue,
        }, className), sx: sx }, { children: children }), void 0));
};
const IndicatorsContainer = (props) => {
    const { children, className, cx, selectProps: { potionStyles }, } = props;
    const initialStyles = {
        d: 'flex',
        alignItems: 'center',
        alignSelf: 'stretch',
        flexShrink: 0,
    };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.indicatorsContainer)
        ? potionStyles.indicatorsContainer(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ className: cx({
            indicators: true,
        }, className), sx: sx }, { children: children }), void 0));
};
const Control = (props) => {
    const { className, cx, children, innerRef, innerProps, isDisabled, isFocused, menuIsOpen, selectProps: { size, isInvalid, potionStyles, focusBorderColor, errorBorderColor }, } = props;
    const inputStyles = useMultiStyleConfig('Input', {
        focusBorderColor,
        errorBorderColor,
        size,
    });
    const heights = {
        sm: 8,
        md: 10,
        lg: 12,
    };
    const initialStyles = Object.assign(Object.assign({}, inputStyles.field), { d: 'flex', p: 0, overflow: 'hidden', h: 'auto', minH: heights[size] });
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.control)
        ? potionStyles.control(initialStyles, props)
        : initialStyles;
    return (_jsx(StylesProvider, Object.assign({ value: inputStyles }, { children: _jsx(Box, Object.assign({ ref: innerRef, className: cx({
                control: true,
                'control--is-disabled': isDisabled,
                'control--is-focused': isFocused,
                'control--menu-is-open': menuIsOpen,
            }, className), sx: sx }, innerProps, { "data-focus": isFocused ? true : undefined, "data-invalid": isInvalid ? true : undefined, "data-disabled": isDisabled ? true : undefined }, { children: children }), void 0) }), void 0));
};
const Placeholder = (props) => {
    const { children, className, cx, innerProps, selectProps: { placeholderColor, potionStyles }, } = props;
    const initialStyles = {
        color: placeholderColor,
        mx: '0.125rem',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.placeholder)
        ? potionStyles.placeholder(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ className: cx({
            placeholder: true,
        }, className), sx: sx }, innerProps, { children: children }), void 0));
};
// Multi Value
const MultiValue = (props) => {
    const { children, className, components, cx, data, innerProps, isDisabled, isFocused, removeProps, selectProps, } = props;
    const { Container, Label, Remove } = components;
    const { potionStyles } = selectProps;
    const containerInitialStyles = { m: '0.125rem' };
    const containerSx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.multiValue)
        ? potionStyles.multiValue(containerInitialStyles, props)
        : containerInitialStyles;
    const labelSx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.multiValueLabel)
        ? potionStyles.multiValueLabel({}, props)
        : {};
    const removeSx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.multiValueRemove)
        ? potionStyles.multiValueRemove({}, props)
        : {};
    return (_jsxs(Container, Object.assign({ data: data, innerProps: Object.assign({ className: cx({
                'multi-value': true,
                'multi-value--is-disabled': isDisabled,
            }, className) }, innerProps), sx: containerSx, selectProps: selectProps }, { children: [_jsx(Label, Object.assign({ data: data, innerProps: {
                    className: cx({
                        'multi-value__label': true,
                    }, className),
                }, sx: labelSx, selectProps: selectProps }, { children: children }), void 0), _jsx(Remove, { data: data, innerProps: Object.assign({ className: cx({
                        'multi-value__remove': true,
                    }, className), 'aria-label': `Remove ${children || 'option'}` }, removeProps), sx: removeSx, selectProps: selectProps, isFocused: isFocused }, void 0)] }), void 0));
};
const MultiValueContainer = (props) => {
    const { children, innerProps, data, selectProps, sx } = props;
    return (_jsx(Tag, Object.assign({}, innerProps, { 
        // react-select Fixed Options example:
        // https://react-select.com/home#fixed-options
        variant: data.variant || selectProps.tagVariant || (data.isFixed ? 'solid' : 'subtle'), colorScheme: data.colorScheme || selectProps.colorScheme, size: selectProps.size, sx: sx }, { children: children }), void 0));
};
const MultiValueLabel = (props) => {
    const { children, innerProps, sx } = props;
    return (_jsx(TagLabel, Object.assign({}, innerProps, { sx: sx }, { children: children }), void 0));
};
const MultiValueRemove = (props) => {
    const { children, innerProps, isFocused, data, sx } = props;
    // @ts-ignore `isFixed` is not found on the default Option object
    // not sure how to extend it internally
    if (data.isFixed) {
        return null;
    }
    return (
    // @ts-ignore the `innerProps` type is not compatible with the props
    // accepted by the `TagCloseButton`. The most likely solution in the long
    // term is using a `chakra.button` instead of a TagCloseButton and styling
    // it using the multi style config of a tag close button.
    _jsx(TagCloseButton, Object.assign({}, innerProps, { sx: sx, tabIndex: -1, "data-focus": isFocused ? true : undefined }, { children: children }), void 0));
};
// Single Value
const SingleValue = (props) => {
    const { children, className, cx, isDisabled, innerProps, selectProps: { potionStyles }, } = props;
    const initialStyles = {
        label: 'singleValue',
        mx: '0.125rem',
        maxWidth: `calc(100% - 0.5rem)`,
        overflow: 'hidden',
        position: 'absolute',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        top: '50%',
        transform: 'translateY(-50%)',
    };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.singleValue)
        ? potionStyles.singleValue(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ className: cx({
            'single-value': true,
            'single-value--is-disabled': isDisabled,
        }, className), sx: sx }, innerProps, { children: children }), void 0));
};
// Indicators
const IndicatorSeparator = (props) => {
    const { className, cx, selectProps: { potionStyles }, } = props;
    const initialStyles = { opacity: 1 };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.indicatorSeparator)
        ? potionStyles.indicatorSeparator(initialStyles, props)
        : initialStyles;
    return (_jsx(Divider, { className: cx({ 'indicator-separator': true }, className), sx: sx, orientation: "vertical" }, void 0));
};
const ClearIndicator = (props) => {
    const { className, cx, innerProps, isFocused, selectProps: { size, potionStyles }, } = props;
    const initialStyles = { mx: 1 };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.clearIndicator)
        ? potionStyles.clearIndicator(initialStyles, props)
        : initialStyles;
    return (
    // @ts-ignore the `innerProps` type is meant for a div element, not a
    // button like this is.  I'm not sure how to cast the type for these
    // props into a type that the `CloseButton` component will be happe with
    _jsx(CloseButton, Object.assign({ className: cx({
            indicator: true,
            'clear-indicator': true,
        }, className), size: size, sx: sx, tabIndex: -1, "data-focused": isFocused ? true : undefined, "aria-label": "Clear selected options" }, innerProps), void 0));
};
const DropdownIndicator = (props) => {
    const { className, cx, innerProps, selectProps: { size, potionStyles }, } = props;
    const { addon } = useStyles();
    const iconSizes = {
        sm: 4,
        md: 5,
        lg: 6,
    };
    const iconSize = iconSizes[size];
    const initialStyles = Object.assign(Object.assign({}, addon), { d: 'flex', alignItems: 'center', justifyContent: 'center', h: '100%', borderRadius: 0, borderWidth: 0, background: 'transparent', paddingX: 2 });
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.dropdownIndicator)
        ? potionStyles.dropdownIndicator(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({}, innerProps, { className: cx({
            indicator: true,
            'dropdown-indicator': true,
        }, className), sx: sx }, { children: _jsx(ChevronDown, { h: iconSize, w: iconSize }, void 0) }), void 0));
};
const LoadingIndicator = (props) => {
    const { className, cx, innerProps, selectProps: { size, potionStyles }, } = props;
    const spinnerSizes = {
        sm: 'xs',
        md: 'sm',
        lg: 'md',
    };
    const spinnerSize = spinnerSizes[size];
    const initialStyles = { mr: 3 };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.loadingIndicator)
        ? potionStyles.loadingIndicator(initialStyles, props)
        : initialStyles;
    return (_jsx(Spinner, Object.assign({ className: cx({
            indicator: true,
            'loading-indicator': true,
        }, className), sx: sx }, innerProps, { size: spinnerSize }), void 0));
};
// Menu components
const Menu = (props) => {
    const { className, cx, children, innerProps, innerRef, placement, selectProps: { size, potionStyles }, } = props;
    const menuStyles = useMultiStyleConfig('Menu', {});
    const chakraTheme = useTheme();
    const borderRadii = {
        sm: chakraTheme.radii.sm,
        md: chakraTheme.radii.md,
        lg: chakraTheme.radii.md,
    };
    const initialStyles = Object.assign(Object.assign(Object.assign({ position: 'absolute' }, (placement === 'bottom' && { top: '100%' })), (placement === 'top' && { bottom: '100%' })), { my: '8px', w: '100%', zIndex: 1, overflow: 'hidden', rounded: borderRadii[size] });
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.menu)
        ? potionStyles.menu(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ ref: innerRef, className: cx({ menu: true }, className), sx: sx }, innerProps, { children: _jsx(StylesProvider, Object.assign({ value: menuStyles }, { children: children }), void 0) }), void 0));
};
const MenuList = (props) => {
    const { className, cx, innerRef, children, maxHeight, isMulti, selectProps: { size, potionStyles }, } = props;
    const { list } = useStyles();
    const chakraTheme = useTheme();
    const borderRadii = {
        sm: chakraTheme.radii.sm,
        md: chakraTheme.radii.md,
        lg: chakraTheme.radii.md,
    };
    const initialStyles = Object.assign(Object.assign({}, list), { maxH: `${maxHeight}px`, overflowY: 'auto', borderRadius: borderRadii[size] });
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.menuList)
        ? potionStyles.menuList(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ className: cx({
            'menu-list': true,
            'menu-list--is-multi': isMulti,
        }, className), sx: sx, ref: innerRef }, { children: children }), void 0));
};
const Group = (props) => {
    const { children, className, cx, theme, getStyles, Heading, headingProps, label, selectProps } = props;
    const { potionStyles } = selectProps;
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.group) ? potionStyles.group({}, props) : {};
    return (_jsxs(Box, Object.assign({ className: cx({ group: true }, className), sx: sx }, { children: [_jsx(Heading, Object.assign({}, headingProps, { selectProps: selectProps, cx: cx, theme: theme, getStyles: getStyles }, { children: label }), void 0), _jsx(Box, { children: children }, void 0)] }), void 0));
};
const GroupHeading = (props) => {
    const { cx, className, children, selectProps: { size, hasStickyGroupHeaders, potionStyles }, } = props;
    const { groupTitle, list: { bg }, } = useStyles();
    const chakraTheme = useTheme();
    const fontSizes = {
        sm: chakraTheme.fontSizes.xs,
        md: chakraTheme.fontSizes.sm,
        lg: chakraTheme.fontSizes.md,
    };
    const paddings = {
        sm: '0.4rem 0.8rem',
        md: '0.5rem 1rem',
        lg: '0.6rem 1.2rem',
    };
    const initialStyles = Object.assign(Object.assign({}, groupTitle), { fontSize: fontSizes[size], p: paddings[size], m: 0, borderBottomWidth: hasStickyGroupHeaders ? '1px' : 0, position: hasStickyGroupHeaders ? 'sticky' : 'static', top: -2, bg });
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.groupHeading)
        ? potionStyles.groupHeading(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ className: cx({ 'group-heading': true }, className), sx: sx }, { children: children }), void 0));
};
const Option = (props) => {
    const { className, cx, innerRef, innerProps, children, isFocused, isDisabled, isSelected, selectProps: { size, isMulti, hideSelectedOptions, selectedOptionStyle, selectedOptionColor, potionStyles, }, } = props;
    const { item } = useStyles();
    const paddings = {
        sm: '0.3rem 0.6rem',
        md: '0.4rem 0.8rem',
        lg: '0.5rem 1rem',
    };
    // Don't create exta space for the checkmark if using a multi select with
    // options that dissapear when they're selected
    const showCheckIcon = selectedOptionStyle === 'check' && (!isMulti || hideSelectedOptions === false);
    const shouldHighlight = selectedOptionStyle === 'color' && isSelected;
    const initialStyles = Object.assign(Object.assign(Object.assign(Object.assign({}, item), { d: 'flex', alignItems: 'center', w: '100%', textAlign: 'start', fontSize: size, p: paddings[size], bg: isFocused ? item._focus.bg : 'transparent' }), (shouldHighlight && {
        bg: `${selectedOptionColor}.500`,
        color: 'white',
        _active: { bg: `${selectedOptionColor}.500` },
    })), (isDisabled && item._disabled));
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.option)
        ? potionStyles.option(initialStyles, props)
        : initialStyles;
    return (_jsxs(Box, Object.assign({ role: "button", className: cx({
            option: true,
            'option--is-disabled': isDisabled,
            'option--is-focused': isFocused,
            'option--is-selected': isSelected,
        }, className), sx: sx, ref: innerRef }, innerProps, { disabled: isDisabled ? true : undefined }, { children: [showCheckIcon && (_jsx(MenuIcon, Object.assign({ fontSize: "0.8em", marginEnd: "0.75rem", opacity: isSelected ? 1 : 0 }, { children: _jsx(CheckIcon, {}, void 0) }), void 0)), children] }), void 0));
};
// Messages
const LoadingMessage = (props) => {
    const { children, className, cx, innerProps, selectProps: { size, placeholderColor, potionStyles }, } = props;
    const fontSizes = {
        sm: '0.875rem',
        md: '1rem',
        lg: '1.125rem',
    };
    const paddings = {
        sm: '6px 9px',
        md: '8px 12px',
        lg: '10px 15px',
    };
    const initialStyles = {
        color: placeholderColor,
        textAlign: 'center',
        p: paddings[size],
        fontSize: fontSizes[size],
    };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.loadingMessage)
        ? potionStyles.loadingMessage(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ className: cx({
            'menu-notice': true,
            'menu-notice--loading': true,
        }, className), sx: sx }, innerProps, { children: children }), void 0));
};
const NoOptionsMessage = (props) => {
    const { children, className, cx, innerProps, selectProps: { size, placeholderColor, potionStyles }, } = props;
    const fontSizes = {
        sm: '0.875rem',
        md: '1rem',
        lg: '1.125rem',
    };
    const paddings = {
        sm: '6px 9px',
        md: '8px 12px',
        lg: '10px 15px',
    };
    const initialStyles = {
        color: placeholderColor,
        textAlign: 'center',
        p: paddings[size],
        fontSize: fontSizes[size],
    };
    const sx = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.noOptionsMessage)
        ? potionStyles.noOptionsMessage(initialStyles, props)
        : initialStyles;
    return (_jsx(Box, Object.assign({ className: cx({
            'menu-notice': true,
            'menu-notice--no-options': true,
        }, className), sx: sx }, innerProps, { children: children }), void 0));
};
const Input = (props) => {
    const { className, cx, value, selectProps: { potionStyles }, } = props;
    const _a = cleanCommonProps(props), { innerRef, isDisabled, isHidden, inputClassName } = _a, innerProps = __rest(_a, ["innerRef", "isDisabled", "isHidden", "inputClassName"]);
    const spacingStyle = {
        gridArea: '1 / 2',
        font: 'inherit',
        minW: '2px',
        border: 0,
        m: 0,
        outline: 0,
    };
    const initialContainerStyles = {
        flex: '1 1 auto',
        display: 'inline-grid',
        gridArea: '1 / 1 / 2 / 3',
        gridTemplateColumns: '0 min-content',
        color: 'inherit',
        _after: Object.assign({ content: 'attr(data-value) " "', visibility: 'hidden', whiteSpace: 'pre', p: 0 }, spacingStyle),
    };
    const containerStyles = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.inputContainer)
        ? potionStyles.inputContainer(initialContainerStyles, props)
        : initialContainerStyles;
    const initialInputStyles = Object.assign({ label: 'input', color: 'inherit', bg: 0, opacity: isHidden ? 0 : 1, w: '100%', py: '0.125rem' }, spacingStyle);
    const inputStyles = (potionStyles === null || potionStyles === void 0 ? void 0 : potionStyles.input)
        ? potionStyles.input(initialInputStyles, props)
        : initialInputStyles;
    return (_jsx(Box, Object.assign({ className: cx({ 'input-container': true }, className), "data-value": value || '', sx: containerStyles }, { children: _jsx(potion.input, Object.assign({ className: cx({ input: true }, inputClassName), ref: innerRef, sx: inputStyles, disabled: isDisabled }, innerProps), void 0) }), void 0));
};
const potionComponents = {
    ClearIndicator,
    Control,
    DropdownIndicator,
    Group,
    GroupHeading,
    IndicatorSeparator,
    IndicatorsContainer,
    Input,
    LoadingIndicator,
    LoadingMessage,
    Menu,
    MenuList,
    MultiValue,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    NoOptionsMessage,
    Option,
    Placeholder,
    SelectContainer,
    SingleValue,
    ValueContainer,
};
export default potionComponents;
