import React, { useEffect, useMemo, useState } from 'react';
import {
  Center,
  Box,
  Stack,
  Heading,
  Button,
  Divider,
  SimpleGrid,
  Flex,
  Icon,
  Text,
  Checkbox,
  Skeleton,
} from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import {
  faCheck,
  faCheckCircle,
  faCircleExclamation,
  faClose,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { User } from '@companydotcom/types';
import { useNavigate } from 'react-router-dom';
import { AutoRenewHeader } from '../components/auto-renew-header';
import { PaymentTile } from '../components/auto-renew-saved-payment-tile';
import { useAppDispatch, useAppSelector, useToast } from '../../../../hooks';
import { select } from '../auto-renew-slice';
import type { AutoRenewProps } from '../types/auto-renew.types';
import { useSetAutoRenewOnMembershipMutation } from '../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { platformHelpers } from '@companydotcom/helpers';

// TODO - update with proper types
export interface PaymentOptionsProps extends AutoRenewProps {
  membershipDataLoading?: boolean;
  chapterDataFromTile?: {
    chapterNames?: string[];
  };
}

export const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  goToStep,
  membershipData,
  paymentMethodKey,
  paymentData,
  isLoading,
  membershipDataLoading,
  chapterDataFromTile,
}) => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [setAutoRenewOnMembership, { isLoading: autoRenewLoading }] =
    useSetAutoRenewOnMembershipMutation();

  // STATE
  const selectedMethod = useAppSelector(state => state.acgAutoRenew.selectedPayment);
  const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
  const [prevCheckedItems, setPrevCheckedItems] = useState<boolean[]>([]);
  const [disableSave, setDisableSave] = useState<boolean>(true);

  const radioOtions = useMemo(() => {
    return paymentData?.savedPaymentMethods?.map((method: any, i: any) => {
      return {
        label: <PaymentTile {...{ ...method, isLoading, paymentMethodKey }} key={i} width="100%" />,
      };
    });
  }, [isLoading, paymentData, paymentMethodKey]);

  // USEFORM & HELPERS
  const {
    register,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      position: [],
    },
  });

  useEffect(() => {
    if (paymentData?.savedPaymentMethods && paymentMethodKey) {
      const currentAutoRenewPaymentMethod = paymentData?.savedPaymentMethods?.find(
        (payment: any) => payment?.merchantAccountTokens?.[0]?.merchantToken === paymentMethodKey,
      );
      dispatch(select(currentAutoRenewPaymentMethod));
      const paymentIndex = paymentData?.savedPaymentMethods?.indexOf(currentAutoRenewPaymentMethod);
      const checked = paymentData?.savedPaymentMethods?.map(
        (item: any, index: number) => index === paymentIndex,
      );
      setPrevCheckedItems(checked);
      setCheckedItems(checked);
    } else {
      setCheckedItems(new Array(paymentData?.savedPaymentMethods?.length).fill(false));
      setPrevCheckedItems(new Array(paymentData?.savedPaymentMethods?.length).fill(false));
    }
  }, [dispatch, paymentData, paymentMethodKey]);

  const onSubmit = async () => {
    if (checkedItems.includes(true)) {
      try {
        dispatch(select(selectedMethod));
        toast.closeAll();

        await setAutoRenewOnMembership({
          user: globalUser as User,
          payload: {
            autoRenew: true,
            paymentInfo: {
              paymentMethodType:
                selectedMethod?.paymentMethodType === 'ach'
                  ? 'electronic check'
                  : selectedMethod?.paymentMethodType,
              cardType: selectedMethod?.cardType,
              cardNumber: selectedMethod?.cardNumber,
              accountType: selectedMethod?.accountType,
              accountNumber: selectedMethod?.accountNumber,
              merchantAccountId: selectedMethod?.merchantAccountTokens[0]?.merchantAccountId,
              merchantToken: selectedMethod?.merchantAccountTokens[0]?.merchantToken,
            },
          },
        })
          .unwrap()
          .then(() => {
            setDisableSave(true);
            const index = checkedItems.indexOf(true);
            const newPrevChecked = paymentData?.savedPaymentMethods?.map(
              (item: any, i: number) => i === index,
            );
            setPrevCheckedItems(newPrevChecked || []);
            toast({
              render: () => (
                <Flex flexDirection="row" color="white" p={3} bg="green.500" borderRadius="6px">
                  <Icon
                    p={1}
                    pr={2}
                    className="fa-solid"
                    as={FontAwesomeIcon}
                    icon={faCheckCircle}
                    boxSize="16px"
                  />
                  <Flex flexDirection="column" pr={2}>
                    <Text fontWeight={700}>{t('acg.autoRenew.methodSaved')}</Text>
                    <Text>
                      Your membership will automatically renew with the{' '}
                      {selectedMethod && selectedMethod?.paymentMethodType === 'credit card'
                        ? `${
                            selectedMethod?.cardType
                              ? platformHelpers.capitalizeFirstLetter(selectedMethod?.cardType)
                              : 'credit card'
                          } ending in ${selectedMethod?.cardNumber}`
                        : `ACH ending in ${selectedMethod?.accountNumber}`}
                    </Text>
                  </Flex>
                  <Icon
                    translateX={2}
                    translateY={4}
                    as={FontAwesomeIcon}
                    sx={{
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                    icon={faClose}
                    boxSize="18px"
                    onClick={() => toast.closeAll()}
                  />
                </Flex>
              ),
              duration: 7000,
              status: 'success',
              isClosable: true,
              position: 'top-right',
            });
          });
      } catch (err) {
        setDisableSave(false);
        console.log('Error saving payment method', err);
      }
    } else {
      removeMethod();
    }
  };

  const handleAddPayment = () => {
    if (paymentData && paymentData?.recordCount < 5) {
      goToStep?.('add-payment');
    } else {
      toast.closeAll();
      toast({
        render: () => (
          <Flex flexDirection="row" p={3} bg="orange.50" borderRadius="6px" maxWidth="full">
            <Icon
              p={1}
              pr={2}
              className="fa-solid"
              as={FontAwesomeIcon}
              icon={faCircleExclamation}
              boxSize="16px"
              color="#F2B035"
            />
            <Flex flexDirection="column" pr={2}>
              <Text fontWeight={700}>{t('acg.autoRenew.limitReached')}</Text>
              <Button
                variant="link"
                size="md"
                padding={0}
                onClick={() => navigate('/payment-methods')}
              >
                {t('acg.autoRenew.editOrRemoved')}
              </Button>
            </Flex>
            <Icon
              translateX={2}
              translateY={4}
              as={FontAwesomeIcon}
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              icon={faClose}
              boxSize="18px"
              onClick={() => toast.closeAll()}
            />
          </Flex>
        ),
        duration: null,
        status: 'warning',
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const removeMethod = async () => {
    toast.closeAll();
    dispatch(select([]));
    setDisableSave(true);

    const defaultMethod = paymentData?.savedPaymentMethods?.find(
      (method: any) => method?.merchantAccountTokens[0]?.merchantToken === paymentMethodKey,
    );

    await setAutoRenewOnMembership({
      user: globalUser as User,
      payload: {
        autoRenew: false,
        paymentInfo: {
          paymentMethodType:
            defaultMethod?.paymentMethodType === 'ach'
              ? 'electronic check'
              : defaultMethod?.paymentMethodType,
          cardType: defaultMethod?.cardType,
          cardNumber: defaultMethod?.cardNumber,
          accountType: defaultMethod?.accountType,
          accountNumber: defaultMethod?.accountNumber,
          merchantAccountId: defaultMethod?.merchantAccountTokens[0]?.merchantAccountId,
          merchantToken: defaultMethod?.merchantAccountTokens[0]?.merchantToken,
        },
      },
    })
      .unwrap()
      .then(() => {
        toast({
          render: () => (
            <Flex flexDirection="row" color="white" p={3} bg="green.500" borderRadius="6px">
              <Icon
                p={1}
                pr={2}
                className="fa-solid"
                as={FontAwesomeIcon}
                icon={faCheckCircle}
                boxSize="16px"
              />
              <Flex flexDirection="column" pr={2}>
                <Text fontWeight={700}>{t('acg.autoRenew.methodRemoved')}</Text>
                <Text>
                  ACG {membershipData?.memberships?.[0]?.chapterName} Membership will not
                  automatically renew. Select a payment method again to auto-renew this membership.
                </Text>
              </Flex>
              <Icon
                translateX={2}
                translateY={4}
                as={FontAwesomeIcon}
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                icon={faClose}
                boxSize="18px"
                onClick={() => toast.closeAll()}
              />
            </Flex>
          ),
          duration: 7000,
          status: 'success',
          isClosable: true,
          position: 'top-right',
        });
      });
  };

  const handleOnChange = (position: number) => {
    setDisableSave(false);
    toast.closeAll();
    dispatch(select(paymentData?.savedPaymentMethods[position]));
    const updatedCheckedState = checkedItems.map((item, index) =>
      index === position ? !item : false,
    );
    setCheckedItems(updatedCheckedState);
    if (checkedItems[position]) {
      const checkedItemsCopy = checkedItems.slice();
      checkedItemsCopy[position] = false;
      setCheckedItems(checkedItemsCopy);
    }

    if (
      updatedCheckedState.length === prevCheckedItems.length &&
      updatedCheckedState.every((value, index) => value === prevCheckedItems[index])
    ) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  return (
    <Center
      className="auto-renew"
      flexDirection="column"
      bg={['transparent', 'white']}
      py={[0, 8, 14]}
      px={[0, 4]}
    >
      <Box width="full" maxW={908}>
        <AutoRenewHeader {...{ ...chapterDataFromTile, membershipData }} />
        <Stack spacing={2} color="blackAlpha.500" mt={12}>
          <Heading size="lg" fontWeight="semibold" textAlign={['center', null, 'left']}>
            {t('acg.autoRenew.selectAnOption')}
          </Heading>
        </Stack>
        {!isLoading && paymentData?.recordCount === 0 ? (
          <AppSpinner my={12} />
        ) : (
          paymentData?.savedPaymentMethods &&
          paymentData?.savedPaymentMethods?.length && (
            <Box as="form" className="auto-renew-payment-options_selectOptionRadio" py={6}>
              <SimpleGrid
                columns={[1, 2, 2, 3]}
                columnGap={[null, 4, 6, 6]}
                rowGap={6}
                justifyContent="center"
                justifyItems="center"
              >
                {isLoading ? (
                  <Skeleton />
                ) : (
                  radioOtions?.map((option: any, index: number) => {
                    return (
                      <Stack
                        key={index}
                        p={2}
                        maxW="17.75rem"
                        justify="space-between"
                        bg={['white', 'gray.50']}
                        borderRadius="4px"
                        flexDirection="row-reverse"
                      >
                        <Checkbox
                          {...register('position')}
                          name="position"
                          value={index}
                          height={2}
                          justifyContent="space-between"
                          alignItems="flex-start"
                          size="lg"
                          isChecked={checkedItems[index]}
                          onChange={() => handleOnChange(index)}
                        />
                        {option.label}
                      </Stack>
                    );
                  })
                )}
              </SimpleGrid>
            </Box>
          )
        )}
        <Box>
          <Divider display={['none', 'block']} />
          <Stack
            direction={['column-reverse', null, 'row']}
            justify="space-between"
            align="center"
            pt={[4, 8]}
            spacing={[4, null, null, 0]}
          >
            <Button
              size="sm"
              variant="link"
              onClick={handleAddPayment}
              leftIcon={<FontAwesomeIcon icon={faCheck} />}
              fontWeight="semibold"
            >
              {t('acg.autoRenew.addPaymentMethodButton')}
            </Button>
            <Button
              width={['2xs', 'xs']}
              isLoading={isSubmitting || membershipDataLoading || autoRenewLoading}
              isDisabled={disableSave}
              size="lg"
              onClick={handleSubmit(onSubmit)}
            >
              {t('common.buttons.save')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Center>
  );
};
