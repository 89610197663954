import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Text, Box } from '@companydotcom/potion';
import Iframe from 'react-iframe';
import Script from 'react-load-script';
import { companyConstants, companyHelpers } from '@companydotcom/helpers';
import { useCuiContext, useMitt } from '@companydotcom/providers';
import { User, UserTile } from '@companydotcom/types';
import { companyQLSecure } from '../../../services/secure-app-sync-client';
import {
  mapLandbotDataToQueryString,
  invokeDefaultTileAction,
  // cuiController
} from '../utils';
import { Confetti } from './confetti';
import userMutation from '../../../services/user/old-api/user-mutation-old';
import { gtm } from '../../../lib/google-tag-manager';
import {
  useCreateDomainMutation,
  useUpdateUserAccountDomainMutation,
  useUpdateUserMutation,
} from '../../../services/user/user-api';
import { useLazyGetUserCuisQuery, useUpdateUserCuisMutation } from '../../../services/cui/cui-api';
// import {
// updateUserAccountDomain,
// createDomain,
// } from '../../../services/user/old-api/user-svc';

declare global {
  interface Window {
    LandbotAP?: any;
  }
}

export interface LandbotProps {
  providerId: string;
  iframeId: string;
  dataRequired: any;
  landbotStatusHandler?: any;
  cuiId: string;
  tile: UserTile;
  user: User;
}

export const Landbot: React.FC<LandbotProps> = ({
  providerId = '',
  iframeId = 'landbot-iframe',
  dataRequired = {},
  landbotStatusHandler,
  cuiId = '',
  tile,
  user,
}) => {
  const [updateUser] = useUpdateUserMutation();
  const [updateUserAccountDomain] = useUpdateUserAccountDomainMutation();
  const [createDomain] = useCreateDomainMutation();
  const [landbotExists, setLandbotExists] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [scriptError, setScriptError] = useState(false);
  const [querystring, setQueryString] = useState({});
  const [confetti, setConfetti] = useState(false);
  const [tileTransStarted, setTileTransStarted] = useState(false);
  const [interactionStarted, setInteractionStarted] = useState(false);
  const [getUserCuis] = useLazyGetUserCuisQuery();
  const [updateCuis] = useUpdateUserCuisMutation();

  const { emitter } = useMitt();

  const myBot = useRef({});

  const { dispatchUserCuis } = useCuiContext();

  const handleScriptLoad = () => {
    setScriptLoaded(true);
  };

  const handleScriptError = () => {
    setScriptError(true);
  };

  const fireTileStateChangeToLB = useCallback(
    myLB => {
      if (tileTransStarted && tile?.stateCurrent === 'active') {
        myLB.send('product-activation-success');
      }
    },
    [tile?.stateCurrent, tileTransStarted],
  );

  const fireDefaultTileBtnEvent = useRef<any>(null);

  useEffect(() => {
    if (!landbotExists && scriptLoaded) {
      const myLandbot = new window.LandbotAP(iframeId);

      // Triggers when landbot is loaded
      myLandbot.on('company-event', async () => {
        myLandbot.send('landbot-listen');
        await updateCuis({ userId: user.userId, cuiId });
        // cuiController.set({ userId: user?.userId, cuiId });
      });

      // Dismiss Event - Permanant Dismiss CUI
      myLandbot.on('dismiss', async () => {
        await updateCuis({ userId: user?.userId, cuiId, isDismissed: true });
        // cuiController.set({ userId: user?.userId, cuiId, isDismissed: true });
      });

      myLandbot.on('triggerDefaultAction', () => {
        fireDefaultTileBtnEvent?.current?.();
      });

      myLandbot.on('close', (data: any) => {
        const delay = data && data.delay ? data.delay : 3000;
        setTimeout(async () => {
          landbotStatusHandler();
          const res = await getUserCuis({ userId: user.userId }).unwrap();
          dispatchUserCuis({ type: 'INIT', payload: { userCuis: res?.userCUIs || [] } });
          // cuiController.get(user?.userId, dispatchUserCuis);
        }, delay);
      });

      myLandbot.on('provision', (data: any) => {
        const { ratePlanId, quantity: qty, immediateActivate } = data;

        setTileTransStarted(true);

        if (ratePlanId) {
          const event = {
            type: 'openPaywall',
            metadata: {
              products: [tile.product],
              tile,
              ratePlanId,
              eventSource: 'cui',
              qty: qty === undefined ? 1 : qty,
              immediateActivate,
            },
          };

          emitter.emit(companyConstants.platformEvents.openPaywall, event);
        } else {
          fireDefaultTileBtnEvent.current();
        }
      });

      myLandbot.on('completed', async () => {
        await updateCuis({
          userId: user?.userId,
          cuiId,
          isDismissed: true,
          isCompleted: true,
        });
        // cuiController.set({
        //   userId: user?.userId,
        //   cuiId,
        //   isDismissed: true,
        //   isCompleted: true,
        // });
      });

      myLandbot.on('gtm-event', (data: any) => {
        gtm(data.event);
      });

      myLandbot.on('update-business', (data: any) => {
        const business = user?.account?.business?.[0];
        // @ts-ignore
        const mutation = userMutation.updateAccountBusiness?.({
          accountId: user?.account?.accountId,
          businessName: business?.name,
          city: business?.address?.city,
          state: business?.address?.state,
          zipCode: business?.address?.zipCode,
          address: business?.address?.addressLine1,
          businessId: business?.businessId,
          numberOfEmployees: user?.account?.businessPrimary?.numberOfEmployees,
          // category: user?.account?.businessPrimary?.category,
          ...data,
        });
        companyQLSecure().mutate({ mutation });
      });

      myLandbot.on('update-account-domain', async (data: any) => {
        await updateUserAccountDomain({
          accountId: user?.account?.accountId,
          fqdnCompany: user?.account?.fqdnCompany ? user?.account.fqdnCompany : '',
          fqdnCustom: user?.account?.fqdnCustom ? user?.account.fqdnCustom : '',
          isEmailFQDNCustom: user?.account?.isEmailFQDNCustom,
          isWebFQDNCustom: user?.account?.isWebFQDNCustom,
          ...data,
        });

        const domainOptions = {
          fqdn: data.fqdnCompany ? data.fqdnCompany : data.fqdnCustom,
          domainName: data.fqdnCompany
            ? companyHelpers.validateCompanyDomain(data.fqdnCompany)
            : companyHelpers.validateCompanyDomain(data.fqdnCustom),
          zone: process.env.REACT_APP_CLIENT_DOMAIN as string,
          domainType: 'customer',
          address: `${process.env.REACT_APP_DOMAIN_ADDRESS}`,
          isRequiredAlias: tile?.product?.slug === 'weebly' ? 1 : 0,
          isRequiredMX: tile?.product?.slug === 'email_rackspace' ? 1 : 0,
        };
        await createDomain({ options: domainOptions });
      });

      myLandbot.on('update-user', (data: any) => {
        const input = {
          userId: user?.userId,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user?.phone,
          ...data,
        };
        updateUser(input);
      });

      // Close Event - Automatically Closes Landbot After Timeout, Get's new CUI State
      myLandbot.on('close', (data: any) => {
        const delay = data && data.delay ? data.delay : 3000;
        console.log('close event fired');
        setTimeout(async () => {
          landbotStatusHandler();
          console.log('closed landbot ui and overlay');
          const res = await getUserCuis({ userId: user.userId }).unwrap();
          dispatchUserCuis({ type: 'INIT', payload: { userCuis: res?.userCUIs || [] } });
          // cuiController.get(user?.userId, dispatchUserCuis);
        }, delay);
      });

      myLandbot.on('refresh', () => {
        emitter.emit(companyConstants.platformEvents.tilesRefresh);
      });

      myLandbot.on('confetti', (data: any) => {
        setConfetti(data);
      });

      const { paymentSucceeded, paymentFailed, cancelPurchase } = companyConstants.platformEvents;

      emitter.on(paymentSucceeded, () => {
        myLandbot.send(paymentSucceeded);
      });

      emitter.on(paymentFailed, () => {
        myLandbot.send(paymentFailed);
      });

      emitter.on(cancelPurchase, () => {
        myLandbot.send(cancelPurchase);
      });

      // This event needs to be fired from Landbot at a state from where
      // it does not want to get reset/ restarted (typically being right after
      // the welcome block).
      myLandbot.on('interaction-started', () => {
        setInteractionStarted(true);
      });

      setLandbotExists(true);
      myBot.current = myLandbot;
      return () => {
        emitter.off(paymentSucceeded);
        emitter.off(paymentFailed);
        emitter.off(cancelPurchase);
      };
    }

    fireDefaultTileBtnEvent.current = () => {
      invokeDefaultTileAction(user, tile, tile?.stateCurrent, emitter);
    };

    fireTileStateChangeToLB(myBot.current);
  }, [
    emitter,
    createDomain,
    cuiId,
    dispatchUserCuis,
    fireTileStateChangeToLB,
    getUserCuis,
    iframeId,
    landbotExists,
    landbotStatusHandler,
    scriptLoaded,
    tile,
    updateCuis,
    updateUser,
    updateUserAccountDomain,
    user,
  ]);

  useEffect(() => {
    if (!interactionStarted) {
      // @ts-ignore
      setQueryString(mapLandbotDataToQueryString?.(dataRequired, user));
    }
  }, [dataRequired, user, interactionStarted]);

  useEffect(() => {
    return () => {
      setLandbotExists(false);
    };
  }, []);

  return (
    <>
      <Script
        url="https://static.landbot.io/umicore/UmiAccessPoint.js"
        crossorigin
        onLoad={handleScriptLoad}
        onError={handleScriptError}
      />
      {scriptError ? (
        <Text variant="heading6" color="text_error" fontWeight="bold">
          Error loading script!
        </Text>
      ) : (
        <>
          {confetti && <Confetti confetti={confetti} />}
          <Box
            className="landbot"
            sx={{
              zIndex: 15,
              height: '100%',
              WebkitOverflowScrolling: 'touch',
              overflow: 'auto',
            }}
            width="full"
          >
            <Iframe
              url={`https://landbot.io/u/${providerId}/index.html?${encodeURI(
                querystring as string,
              )}`}
              name={iframeId}
              frameBorder={0}
              width="100%"
              height="100%"
            />
          </Box>
        </>
      )}
    </>
  );
};
