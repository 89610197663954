import { Text, Button, VStack, Center, Box, useMultiStyleConfig } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { TileSpinner } from '../tile-spinner';
import {
  useGetAcgQueryArgs,
  useGetMemberCommitteesV2Query,
} from '../../../../services/acg/acg-api-v2';

export const TileAcgCommittees = () => {
  const styles = useMultiStyleConfig('TileSubheader', {});
  const navigate = useNavigate();

  const args = useGetAcgQueryArgs();
  const { data, isLoading, isError } = useGetMemberCommitteesV2Query({ ...args });

  if (isLoading) {
    return <TileSpinner />;
  }

  if (
    (Array.isArray(data) && data.length === 0) ||
    (!Array.isArray(data) && !data?.committees?.length) ||
    isError
  ) {
    return (
      <>
        <Box className="tile__subheaderDivider" sx={{ ...styles.divider }} />
        <Center flexDirection="column" justifyContent="space-between" height="full" mt={7}>
          <Text textAlign="left" px={4}>
            You are not a member of any committees. Participating in committees is a great way to
            expand your ACG network and stay engaged. Contact your chapter to learn more.
          </Text>
          <Button onClick={() => navigate('my-profile')}>See Your Profile</Button>
        </Center>
      </>
    );
  }

  return (
    <>
      <Box className="tile__subheaderDivider" sx={{ ...styles.divider }} />
      <Center flexDirection="column" justifyContent="space-between" height="full" mt={7}>
        <VStack maxHeight={248} spacing={4} alignItems="flex-start" overflowY="auto">
          {!Array.isArray(data) &&
            data?.committees?.map((comm, i) => <Text key={i}>{comm?.name}</Text>)}
        </VStack>
        <Button onClick={() => navigate('my-profile')}>See Your Profile</Button>
      </Center>
    </>
  );
};
