import React from 'react';
import { useWatch, Controller, Control } from 'react-hook-form';
import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Flex,
  Divider,
  Checkbox,
  SimpleGrid,
  Skeleton,
  CheckboxGroup,
} from '@companydotcom/potion';

interface MemberFilterInterface {
  label: string;
  name: string;
  items: string[] | any[] | undefined;
  control: Control<any>;
  isDisabled?: boolean;
  borderBottom?: boolean;
}

export const MemberFilter: React.FC<MemberFilterInterface> = ({
  label,
  name,
  items,
  control,
  borderBottom = false,
  isDisabled,
}) => {
  const itemLength = useWatch({ name, control });

  return (
    <AccordionItem
      isDisabled={isDisabled}
      borderWidth="1px"
      borderBottomWidth={borderBottom ? '1px' : '0px'}
      borderStyle="solid"
      borderColor="gray.300"
      width="full"
    >
      <AccordionButton height="60px">
        <Flex width="full" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Text fontWeight="normal" textStyle="lg">
            {label}
          </Text>
          <Skeleton height="14px" isLoaded={!isDisabled}>
            <Flex height="full" flexDirection="row" justifyContent="center" alignItems="center">
              <Text>
                {itemLength?.length} of {items?.length} selected
              </Text>
              <AccordionIcon ml={1} />
            </Flex>
          </Skeleton>
        </Flex>
      </AccordionButton>
      <AccordionPanel>
        <Divider />
        <MemoizedCheckboxes name={name} control={control} items={items} />
      </AccordionPanel>
    </AccordionItem>
  );
};

const MemoizedCheckboxes = React.memo(
  ({ name, control, items }: { name: string; control: Control<any>; items?: any[] | string[] }) => {
    return (
      <Flex flexDirection="column" p={2}>
        <Controller
          name={name}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <SimpleGrid columns={[1, null, 2, 3, 3]} width="full" spacingX={2}>
              <CheckboxGroup {...rest}>
                {items?.map((item, i) => {
                  const record = item.recordName ? item.recordName : item;
                  const chapter = item.chapterName;
                  const value = item?.abbreviation ?? item.chapterId ?? record;
                  return (
                    <Checkbox
                      key={i}
                      ref={ref}
                      isChecked={rest.value?.includes(value)}
                      value={value}
                      aria-label={value}
                      maxW={276}
                      onChange={e => {
                        if (!rest.value?.includes(e.target.value)) {
                          rest.onChange([...rest.value, e.target.value]);
                          return;
                        }
                        const newTopics = rest.value?.filter(
                          (topic: string) => topic !== e.target.value,
                        );
                        rest.onChange(newTopics);
                      }}
                    >
                      {chapter ?? record}
                    </Checkbox>
                  );
                })}
              </CheckboxGroup>
            </SimpleGrid>
          )}
        />
      </Flex>
    );
  },
);
