import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Box, Button, Center } from '@companydotcom/potion';
import { InputField, SelectField } from '@companydotcom/ui';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import type { UpdateAcgUserInput } from '@companydotcom/types';
import { useAuth } from '../../../../../providers';
import type { RegistrationSharedProps } from './acg-new-user-registration';
import { useGetGlobalUserQuery } from '../../../../../services/user/user-api';
import { acgRefData } from '../../../shared';
import {
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
  useUpdateAcgUserV2Mutation,
} from '../../../../../services/acg/acg-api-v2';

const acgPersonalInformationSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('common.inputs.firstName.error'),
    individualType: yup
      .string()
      .required('acg.firstTimeFlow.personalInformation.inputs.individualTypeOptionLabels.error'),
    lastName: yup.string().required('common.inputs.lastName.error'),
    organization: yup
      .string()
      .required('acg.firstTimeFlow.personalInformation.inputs.organization.error'),
    prefix: yup.string(),
    reference: yup
      .string()
      .required('acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.error'),
    title: yup.string().required('acg.firstTimeFlow.personalInformation.inputs.title.error'),
  });

export const PersonalInformation: React.FC<RegistrationSharedProps> = ({
  setFormValues,
  goToStep,
  closeAllToasts,
}) => {
  const args = useGetAcgQueryArgs();
  const updateUserArgs = useGetAcgQueryArgs(['email', 'source']);
  const { t } = useTranslation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [updateAcgUser] = useUpdateAcgUserV2Mutation();
  const authUser = useAuth();

  const { data } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const defaultValues: Partial<UpdateAcgUserInput['payload']> = {
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    middleName: data?.middleName || '',
    organization: data?.organization || '',
    title: data?.title || '',
    prefix: data?.prefix || '',
    individualType: data?.individualType || '',
    reference: data?.reference || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(acgPersonalInformationSchema()),
    defaultValues,
  });

  const onSubmit = async (submitData: typeof defaultValues) => {
    try {
      setFormValues?.((prevState: any) => ({ ...prevState, ...submitData }));
      if (globalUser) {
        await updateAcgUser({
          ...updateUserArgs,
          payload: {
            ...submitData,
            lastProfileUpdateVersion: data?.lastProfileUpdateVersion ?? 1,
            prefix: submitData?.prefix !== '' ? submitData?.prefix : undefined,
            middleName: submitData?.middleName !== '' ? submitData?.middleName : undefined,
          },
        });
        authUser?.refreshUser();
        closeAllToasts?.();
        goToStep?.(1);
      }
    } catch (err) {
      console.log('Error!', err);
    }
  };

  return (
    <Center
      className="acg-registration-personalInformation__container"
      flexDirection="column"
      px={[0, 4]}
      textAlign="center"
    >
      <Box
        as="form"
        className="acg-registration-personalInformation__form"
        maxWidth={430}
        width="full"
      >
        <SelectField
          className="acg-registration-personalInformation__prefixField"
          data-test="acg-registration-personalInformation__prefixField"
          register={register}
          name="prefix"
          label={t('acg.firstTimeFlow.personalInformation.inputs.prefixOptionLabels.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        >
          {acgRefData?.prefix?.map(
            (opt, i) =>
              opt && (
                <option key={i} value={opt.recordName}>
                  {opt.recordName}
                </option>
              ),
          )}
        </SelectField>
        <InputField
          className="acg-registration-personalInformation__firstName"
          data-test="acg-registration-personalInformation__firstName"
          register={register}
          name="firstName"
          label={t('common.inputs.firstName.label')}
          helperText={t('common.misc.required')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="acg-registration-personalInformation__middleName"
          data-test="acg-registration-personalInformation__middleName"
          register={register}
          name="middleName"
          label={t('common.inputs.middleName.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="acg-registration-personalInformation__lastName"
          data-test="acg-registration-personalInformation__lastName"
          register={register}
          name="lastName"
          label={t('common.inputs.lastName.label')}
          helperText={t('common.misc.required')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="acg-registration-personalInformation__organization"
          data-test="acg-registration-personalInformation__organization"
          register={register}
          name="organization"
          helperText={t('common.misc.required')}
          label={t('acg.firstTimeFlow.personalInformation.inputs.organization.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="acg-registration-personalInformation__title"
          data-test="acg-registration-personalInformation__title"
          register={register}
          name="title"
          helperText={t('common.misc.required')}
          label={t('acg.firstTimeFlow.personalInformation.inputs.title.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <SelectField
          className="acg-registration-personalInformation__individualTypeField"
          data-test="acg-registration-personalInformation__individualTypeField"
          register={register}
          name="individualType"
          helperText={t('common.misc.required')}
          label={t('acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        >
          <option value=""> </option>
          {acgRefData?.individualType?.map((opt, i) => (
            <option key={i} value={opt.recordName}>
              {opt.recordName}
            </option>
          ))}
        </SelectField>
        <SelectField
          className="acg-registration-personalInformation__referenceField"
          data-test="acg-registration-personalInformation__referenceField"
          register={register}
          name="reference"
          helperText={t('common.misc.required')}
          label={t('acg.firstTimeFlow.personalInformation.inputs.individualTypeOptionLabels.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        >
          <option value=""> </option>
          {acgRefData?.referer?.map((opt, i) => (
            <option key={i} value={opt.recordName}>
              {opt.recordName}
            </option>
          ))}
        </SelectField>

        <Box textAlign="center" mt={[8, 12]}>
          <Button
            className="acg-registration-personalInformation__submitButton"
            data-test="acg-registration-personalInformation__submitButton"
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isDisabled={!isValid}
            whiteSpace="normal"
          >
            {t('common.buttons.continue')}
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
