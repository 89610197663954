import React, { useState, useEffect, useCallback } from 'react';
import { useMitt } from '@companydotcom/providers';
import {
  useTabStepper,
  Tabs,
  TabPanels,
  TabPanel,
  Flex,
  Box,
  Text,
  Icon,
  Heading,
  useMediaQuery,
  useBreakpointValue,
  Center,
  UseTabStepperReturn,
} from '@companydotcom/potion';
import { AppSpinner, PageDivider } from '@companydotcom/ui';
import { companyConstants } from '@companydotcom/helpers';
import type { AcgUserProfile } from '@companydotcom/types';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClose } from '@fortawesome/pro-regular-svg-icons';
import { PersonalInformation } from './acg-new-user-registration-personal-information';
import { AddressInformation } from './acg-new-user-registration-address-information';
import { AcgReferenceData, RegistrationFormValues } from '../../../../../services/acg/acg.types';
import { BecomeAMember } from '../become-a-member';
import { useToast } from '../../../../../hooks';
import { useGetGlobalUserQuery } from '../../../../../services/user/user-api';
import {
  usePrefetch as usePrefetchV2,
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
} from '../../../../../services/acg/acg-api-v2';
import { Checkout } from '../../../acg-checkout';
import { AcgStepper } from '../../components/acg-registration-stepper';

const RegistrationSteps = (orderConfirmationHandler: () => void) => [
  {
    slug: 'personal-information',
    component: <PersonalInformation />,
    label: 'Personal Information',
  },
  {
    slug: 'address-information',
    component: <AddressInformation />,
    label: 'Address Information',
  },
  { slug: 'join', component: <BecomeAMember />, label: 'Become a Member' },
  {
    slug: 'checkout',
    component: (
      <Checkout variant="unstyled" onOrderConfirmationComplete={orderConfirmationHandler} />
    ),
  },
];
export interface RegistrationSharedProps extends Partial<Pick<UseTabStepperReturn, 'goToStep'>> {
  setFormValues?: React.Dispatch<React.SetStateAction<RegistrationFormValues>>;
  formValues?: RegistrationFormValues;
  handleClose?: any;
  isSubpage?: boolean | undefined;
  closeAllToasts?: () => void;
  refData?: Partial<AcgReferenceData>;
  setIsBecomingMember?: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
}

export const AcgNewUserRegistrationFlow = ({
  handleClose,
  setIsBecomingMember,
}: RegistrationSharedProps) => {
  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-xl' });
  const { emitter } = useMitt();
  const toast = useToast();
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({});
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isPrefetched, setIsPrefetched] = useState(false);
  const { data: globalUser, isLoading: isUserLoading } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();

  const orderConfirmationHandler = useCallback(
    () => {
      emitter.emit(companyConstants.platformEvents.dataCollectorComplete);
    },
    // eslint-disable-next-line
    [emitter],
  );

  const { data, isLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const prefetchChapters = usePrefetchV2('getAllChaptersAndPackagesV2');
  const prefetchMemberships = usePrefetchV2('getMembershipsV2');

  useEffect(() => {
    if (globalUser && !isPrefetched) {
      prefetchChapters({ user: globalUser });
      prefetchMemberships({ ...args });
      setIsPrefetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { tabIndex, currentStep, handleTabsChange, goToStep } = useTabStepper({
    steps: RegistrationSteps(orderConfirmationHandler),
    initialStep: determineInitialRegistrationStep(data),
  });

  function closeAllToasts() {
    toast.closeAll();
  }

  const sharedProps = {
    handleClose,
    formValues,
    setFormValues,
    goToStep,
    closeAllToasts,
    setIsBecomingMember,
  };

  useEffect(() => {
    if (!isLoading && !isUserLoading && !data?.isRegistered) {
      toast({
        render: () => (
          <Flex flexDirection="row" color="white" p={3} bg="green.500">
            <Icon
              p={1}
              pr={2}
              className="fa-solid"
              as={FontAwesomeIcon}
              icon={faCheckCircle}
              boxSize="16px"
            />
            <Flex flexDirection="column" pr={2}>
              <Text fontWeight={700}>
                {t('acg.firstTimeFlow.personalInformation.toast.heading')}
              </Text>
              <Text>{t('acg.firstTimeFlow.personalInformation.toast.text')}</Text>
            </Flex>
            <Icon
              translateX={2}
              translateY={4}
              as={FontAwesomeIcon}
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              icon={faClose}
              boxSize="18px"
              onClick={() => toast.closeAll()}
            />
          </Flex>
        ),
        duration: null,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    }

    return () => {
      toast.closeAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isRegistered, isLoading]);

  if (isUserLoading || isLoading) {
    return (
      <Center flexGrow={1} width="full">
        <AppSpinner my={12} />
      </Center>
    );
  }

  return (
    <Tabs
      className="acg-registration"
      isLazy
      variant="unstyled"
      layerStyle="formContainer"
      index={tabIndex}
      onChange={handleTabsChange}
      maxWidth={currentStep?.slug === 'checkout' ? '5xl' : '4xl'}
      backgroundColor={['transparent', 'white']}
      boxShadow={['none', 'lg']}
      mt={[0, 12]}
      height="full"
    >
      <Box my={[0, 12]}>
        {tabIndex === 3 ? null : (
          <Box mx="auto" sx={{ textAlign: 'center' }}>
            <Flex flexDirection="column" alignItems="center">
              <Heading size={headingSize}>{t('acg.firstTimeFlow.heading')}</Heading>
              {isMobile && <PageDivider width={50} mt={2} />}
            </Flex>

            {isMobile ? (
              <Heading size="hs-sm" m={6} color="#717174B2">
                Step {tabIndex + 1} of 3:{' '}
                {RegistrationSteps(orderConfirmationHandler)[tabIndex].label}
              </Heading>
            ) : (
              <AcgStepper
                steps={RegistrationSteps(orderConfirmationHandler)}
                activeStep={tabIndex}
                inactiveColor="#D1D1D1;
              "
              />
            )}
          </Box>
        )}
        <TabPanels>
          {RegistrationSteps(orderConfirmationHandler).map(step => (
            <TabPanel key={step.slug}>{React.cloneElement(step.component, sharedProps)}</TabPanel>
          ))}
        </TabPanels>
      </Box>
    </Tabs>
  );
};

// If all this information exists in the ACG user profile, then we can skip the first step
const determineInitialRegistrationStep = (data?: AcgUserProfile) => {
  if (
    (data?.workAddress || data?.homeAddress) &&
    data?.preferredPhoneType &&
    data?.preferredAddressType
  ) {
    return 'join';
  } else if (
    data?.firstName &&
    data?.lastName &&
    data?.organization &&
    data?.title &&
    data?.individualType &&
    data?.reference &&
    data?.[data?.preferredAddressType!]
  ) {
    return 'address-information';
  }
  return 0;
};
