import React from 'react';
import { Flex, Text } from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import type { TileComponentProps } from '../../types';

export interface TilePublisherSuggestionsProps extends TileComponentProps {}

export const TilePublisherSuggestions: React.FC<TilePublisherSuggestionsProps> = props => {
  const { eventData, text } = props;
  const { data, loading } = useEventData<any>(eventData);

  const determineValueColor = (value: number) => {
    if (value > 0) {
      return 'orange.500';
    }
    return 'black';
  };

  return (
    <Flex flexDirection="column" mt={6} height="100%">
      <Text
        fontSize="64px"
        color={loading ? '#d8d8d8' : determineValueColor(data?.suggestionCount)}
      >
        {loading ? '- -' : data?.suggestionCount}
      </Text>
      <Text
        fontWeight={500}
        fontSize={6}
        color={loading ? '#d8d8d8' : determineValueColor(data?.suggestionCount)}
      >
        {text}
      </Text>
    </Flex>
  );
};
