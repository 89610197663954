var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, Checkbox, FormControl, FormHelperText, __DEV__, } from '@companydotcom/potion';
import { TranslationErrorMessage } from './translation-error-message';
export const CheckboxField = forwardRef((props, ref) => {
    var _a;
    const { register, name, helperText, children, formControlStyles, errors } = props, rest = __rest(props, ["register", "name", "helperText", "children", "formControlStyles", "errors"]);
    return (_jsxs(FormControl, Object.assign({ id: name, isInvalid: errors && !!(errors === null || errors === void 0 ? void 0 : errors[name]), ref: ref, sx: formControlStyles }, { children: [_jsx(Checkbox, Object.assign({}, register(name), rest, { children: children }), void 0), helperText && !(errors === null || errors === void 0 ? void 0 : errors[name]) && _jsx(FormHelperText, { children: helperText !== null && helperText !== void 0 ? helperText : '' }, void 0), errors && (_jsx(TranslationErrorMessage, Object.assign({ errors: errors, name: name }, { children: errors && (errors === null || errors === void 0 ? void 0 : errors[name]) && ((_a = errors === null || errors === void 0 ? void 0 : errors[name]) === null || _a === void 0 ? void 0 : _a.message) }), void 0))] }), void 0));
});
if (__DEV__) {
    CheckboxField.displayName = 'CheckboxField';
}
