import React from 'react';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { Checkout } from '../../../features/facecake/facecake-checkout';

export const FaceCakeCheckoutPage: React.FC = () => {
  return (
    <PageLayout mx="auto">
      <PageLayoutContent>
        <Checkout
          containerProps={{
            py: [0, 12],
          }}
        />
      </PageLayoutContent>
    </PageLayout>
  );
};
