import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Center,
  Flex,
  Text,
  Heading,
  Button,
  Table,
  Thead,
  Th,
  Tr,
  Td,
  Tbody,
  Tfoot,
  TableContainer,
  Link,
  Stack,
  SimpleGrid,
} from '@companydotcom/potion';
import { FontAwesomeIcon, AppSpinner } from '@companydotcom/ui';
import { useTranslation, Trans } from 'react-i18next';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { NavLink } from 'react-router-dom';
import { DarkWebTag } from '../components/dark-web-tag';
import { tagMappings, recommendations } from '../utils/dark-web-scan-helpers';
import { useAppSelector } from '../../../hooks';
import { selectDarkWebScanResults } from '../dark-web-scan-slice';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';

export const DarkWebResults: React.FC<any> = ({
  nextStep,
  setDetailedTag,
  recommendationType,
  fetchingResults,
  isLoading,
}) => {
  const currentDarkWebResults = useAppSelector(selectDarkWebScanResults);
  const [filteredRecs, setFilteredRecs] = useState(Array);
  const { t } = useTranslation();
  useGetGlobalUserQuery({
    refetchOnMountOrArgChange: true,
  });
  /* This should not work but it does.... I'll need to handle this correctly as part of my bugfixes. The issue is, in order to access tags from a useEffect, increment the leaks found and then
      assemble a table from them and STILL insure the value is reset every time the darkwebscan is hit we need it defined as state, but accessed via . paradime
  */
  const [tags] = useState([
    {
      text: t('containers.darkWebScan.detailedResults.account1'),
      id: 'accountNumberOne',
      results: 0,
    },
    {
      text: t('containers.darkWebScan.detailedResults.account2'),
      id: 'accountNumberTwo',
      results: 0,
    },
    {
      text: t('common.inputs.email.labelLong'),
      id: 'email',
      results: 0,
    },
    {
      text: t('containers.darkWebScan.detailedResults.ssnOrEin'),
      id: 'ssn',
      results: 0,
    },
  ]);

  //Needed to choose which of our 20 something recommendations to show
  const chooseRecs = useCallback(() => {
    const recs: typeof recommendationType[] = [];
    const allTags = currentDarkWebResults?.reduce?.((acc: Array<string>, currentVal: any) => {
      if (currentVal.tags !== undefined) {
        const foundTags = currentVal.tags?.filter(
          (v: string, i: number) => currentVal?.tags?.indexOf(v) === i,
        );
        acc.push(...foundTags);
      }
      return acc;
    }, []);
    // Map through all the tags, if a recoomendation has keys that match the tag, then return the reccomendation
    const goodRecs = allTags?.reduce?.((acc: typeof recommendationType[], currentVal: string) => {
      recommendations.forEach(rec => {
        //@ts-ignore
        tagMappings[rec.id].keys.forEach(key => {
          if (currentVal.toLowerCase() === key.toLowerCase()) {
            acc.push(rec);
          }
        });
      });
      return acc;
    }, []);

    for (let i = 0; i < 3; i++) {
      const index = Math.floor(Math.random() * (goodRecs?.length ?? 0));
      if (goodRecs && index < goodRecs?.length) {
        recs.push(goodRecs?.[index]);
        goodRecs?.splice(index, 1);
      }
    }
    setFilteredRecs(recs);
  }, [currentDarkWebResults]);

  const parseResults = useCallback(
    (scan = currentDarkWebResults) => {
      scan?.forEach?.((result: any) => {
        tags?.forEach?.(tag => {
          //Map result to input field
          if (result.count > 0 && tag.id === result?.key) {
            tag.results = tag.results + 1;
          }
        });
      });
    },
    [currentDarkWebResults, tags],
  );

  const linkText = useCallback(
    (text: string) => {
      const regEx = /(https?:\/\/[^\s]+)/g;
      return text.split(regEx).map((part, index) =>
        index % 2 === 0 ? (
          part
        ) : (
          <Link isExternal href={part}>
            {/* @ts-ignore */}
            {t(part)}
          </Link>
        ),
      );
    },
    [t],
  );
  // We agreed on using friendly "terms" to the results of the darkWebScan in order to associate the scan to the input.

  useEffect(() => {
    if (currentDarkWebResults && currentDarkWebResults.length) {
      parseResults();
      chooseRecs();
    }
  }, [chooseRecs, currentDarkWebResults, parseResults]);

  return (
    <Center
      className="dws-results"
      flexDirection="column"
      pt={[12, null, 10]}
      pb={[12, null, 16]}
      width="full"
      px={4}
    >
      <Box textAlign="center" maxWidth={732} mb={12}>
        <Heading as="h1" size="hs-xl">
          {t('containers.darkWebScan.results.header')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          {t('containers.darkWebScan.results.subheader')}
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('containers.darkWebScan.results.subheader2')}
        </Text>
      </Box>
      {fetchingResults || isLoading ? (
        <AppSpinner />
      ) : (
        <Flex flexDirection="column" width="full">
          <TableContainer>
            <Table
              variant="simple"
              borderRadius="md"
              borderColor="gray.200"
              borderWidth="1px"
              borderStyle="solid"
            >
              <Thead backgroundColor="gray.100">
                <Tr>
                  <Th>{t('containers.darkWebScan.results.dataType')}</Th>
                  <Th>{t('containers.darkWebScan.results.results')}</Th>
                  <Th>{t('containers.darkWebScan.results.status')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {tags.map(tag => (
                  <Tr key={tag.id}>
                    <Td fontWeight="medium">{tag.text}</Td>
                    <Td>
                      {tag.results > 0
                        ? `${tag.results} ${t('containers.darkWebScan.results.leaks')}`
                        : tag.results}
                    </Td>
                    <Td fontWeight="medium" color={tag.results > 0 ? 'red.500' : 'green.400'}>
                      {tag.results > 0
                        ? t('containers.darkWebScan.results.detected').toUpperCase()
                        : t('containers.darkWebScan.results.notDetected').toUpperCase()}
                    </Td>
                    {tag.results >= 1 ? (
                      <Td mr={2}>
                        <Button
                          variant="link"
                          rightIcon={<FontAwesomeIcon icon={faArrowRightLong} />}
                          onClick={() => {
                            setDetailedTag(tag);
                            nextStep();
                          }}
                        >
                          {t('containers.darkWebScan.results.viewResults')}
                        </Button>
                      </Td>
                    ) : (
                      <Td />
                    )}
                  </Tr>
                ))}
              </Tbody>
              <Tfoot>
                <Tr height="16px" backgroundColor="gray.100">
                  <Th />
                  <Th />
                  <Th />
                  <Th />
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </Flex>
      )}
      {!fetchingResults && !isLoading && currentDarkWebResults && (
        <Flex flexDirection="column" px={[1, 1, 1, 13]} my={14} width="full">
          <Box textAlign={['left', null, 'center']} mb={13}>
            <Heading as="h1" size="hs-xl" mb={4}>
              {t('containers.darkWebScan.results.recommendationsHeader')}
            </Heading>
            <Trans i18nKey="containers.darkWebScan.results.recommendationsSubheader">
              <Text textStyle="md">
                Recommendations are based off of the information found in the results that may have
                been breached. Brought to you by our expert security partners at
                <Link isExternal href="https://hacknotice.com/">
                  HackNotice.
                </Link>
              </Text>
            </Trans>
          </Box>
          <Stack mt={14} direction={['column']} width="full">
            {filteredRecs.map((rec: any) => (
              <SimpleGrid columns={[1, null, 2]} spacing={4}>
                <Flex flexDirection="column" pl={[0, null, null, 11]} alignItems="start" mb={3}>
                  <DarkWebTag
                    key={rec.id}
                    text={t(rec.tag)}
                    color={tagMappings[rec.id].color}
                    icon={tagMappings[rec.id].icon}
                  />
                </Flex>
                <Flex flexDirection="column">
                  <Text fontWeight="bold" textStyle="lg" mb={2}>
                    {t(rec.subheading)}
                  </Text>
                  {rec.text.map((text: string, index: number) => (
                    <Text key={index} textStyle="lg" mb={2}>
                      {linkText(text)}
                    </Text>
                  ))}
                </Flex>
              </SimpleGrid>
            ))}
          </Stack>
          <Flex flexDirection={['column', null, 'row']} justifyContent="center" mt={8}>
            <Button size="lg" as={NavLink} to="/">
              {t('common.buttons.toDashboard')}
            </Button>
          </Flex>
        </Flex>
      )}
    </Center>
  );
};
