export const badgeCountParser = (eventData = {}, params: any) => {
  let badgeCount = 0;

  if (params) {
    params.forEach((param: any) => {
      //eventDataKey values in Dynamo correspond to messages/mentions/emails a user has
      if (param?.key === 'eventDataKey') {
        // @ts-ignore
        badgeCount += eventData?.body?.payload?.param?.value || 0;
      }
    });
  }
  return badgeCount;
};
