import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useMitt } from '@companydotcom/providers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Center, UseTabStepperReturn } from '@companydotcom/potion';
import { InputField, SelectField } from '@companydotcom/ui';
import { companyConstants } from '@companydotcom/helpers';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../providers';
import { useGetGlobalUserQuery } from '../../../../../services/user/user-api';
import { acgRefData } from '../../../shared';
import { hasCompletedAddressInformation } from '../../utils';
import {
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
  useUpdateAcgUserV2Mutation,
} from '../../../../../services/acg/acg-api-v2';

const acgPersonalInformationSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('Your First Name is required to complete your registration'),
    individualType: yup
      .string()
      .required('Your Individual Type is required to complete your registration'),
    lastName: yup.string().required('Your Last Name is required to complete your registration'),
    organization: yup
      .string()
      .required('Your Organization is required to complete your registration'),
    prefix: yup.string(),
    reference: yup.string().notRequired(),
    middleName: yup.string().notRequired(),
    title: yup.string().required('Your Title is required to complete your registration'),
  });

export interface ExistingUserPersonalInformationProps
  extends Partial<Pick<UseTabStepperReturn, 'nextStep'>> {}

export const ExistingUserPersonalInformation = ({
  nextStep,
}: ExistingUserPersonalInformationProps) => {
  const { emitter } = useMitt();
  const { t } = useTranslation();
  const [updateAcgUser] = useUpdateAcgUserV2Mutation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const updateUserArgs = useGetAcgQueryArgs(['email', 'source']);

  const { data: acgUser } = useGetAcgUserProfileV2Query({
    ...args,
    email: globalUser?.email,
    accountId: globalUser?.accountId,
  });
  const authUser = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();

  const defaultValues = {
    firstName: acgUser?.firstName || '',
    lastName: acgUser?.lastName || '',
    middleName: acgUser?.middleName || '',
    organization: acgUser?.organization || '',
    title: acgUser?.title || '',
    prefix: acgUser?.prefix || '',
    individualType: acgUser?.individualType || '',
    reference: acgUser?.reference || '',
  };

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(acgPersonalInformationSchema()),
    defaultValues,
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      if (globalUser) {
        await updateAcgUser({
          ...updateUserArgs,
          payload: {
            ...data,
            lastProfileUpdateVersion: acgUser?.lastProfileUpdateVersion ?? 1,
            prefix: data?.prefix !== '' ? data?.prefix : undefined,
            middleName: data?.middleName !== '' ? data?.middleName : undefined,
          },
        })
          .unwrap()
          .then(res => {
            authUser?.refreshUser();
            if (hasCompletedAddressInformation(res!)) {
              if (search) {
                navigate({ pathname: '/redirect', search });
              } else {
                emitter.emit(companyConstants.platformEvents.dataCollectorComplete, { path: '/' });
              }
            } else {
              nextStep?.();
            }
          });
      }
    } catch (err) {
      console.log('Error!', err);
    }
  };

  return (
    <Center
      className="acg-existing-registration-personalInformation__container"
      flexDirection="column"
      px={[0, 4]}
      textAlign="center"
    >
      <Box
        as="form"
        className="acg-existing-registration-personalInformation__form"
        maxWidth={430}
        width="full"
      >
        <SelectField
          className="acg-existing-registration-personalInformation__prefixField"
          data-test="acg-existing-registration-personalInformation__prefixField"
          register={register}
          name="prefix"
          label={t('acg.firstTimeFlow.personalInformation.inputs.prefixOptionLabels.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        >
          {acgRefData?.prefix?.map(
            (opt, i) =>
              opt && (
                <option key={i} value={opt.recordName}>
                  {opt.recordName}
                </option>
              ),
          )}
        </SelectField>
        <InputField
          className="acg-existing-registration-personalInformation__firstName"
          data-test="acg-existing-registration-personalInformation__firstName"
          register={register}
          name="firstName"
          label={t('common.inputs.firstName.label')}
          helperText={t('common.misc.required')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="acg-existing-registration-personalInformation__middleName"
          data-test="acg-existing-registration-personalInformation__middleName"
          register={register}
          name="middleName"
          label={t('common.inputs.middleName.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="acg-existing-registration-personalInformation__lastName"
          data-test="acg-existing-registration-personalInformation__lastName"
          register={register}
          name="lastName"
          label={t('common.inputs.lastName.label')}
          helperText={t('common.misc.required')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="acg-existing-registration-personalInformation__organization"
          data-test="acg-existing-registration-personalInformation__organization"
          register={register}
          name="organization"
          helperText={t('common.misc.required')}
          label={t('acg.firstTimeFlow.personalInformation.inputs.organization.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <InputField
          className="acg-existing-registration-personalInformation__title"
          data-test="acg-existing-registration-personalInformation__title"
          register={register}
          name="title"
          helperText={t('common.misc.required')}
          label={t('acg.firstTimeFlow.personalInformation.inputs.title.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        />
        <SelectField
          className="acg-existing-registration-personalInformation__individualTypeField"
          data-test="acg-existing-registration-personalInformation__individualTypeField"
          register={register}
          name="individualType"
          helperText={t('common.misc.required')}
          label={t('acg.firstTimeFlow.personalInformation.inputs.referenceOptionLabels.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        >
          <option value=""> </option>
          {acgRefData?.individualType?.map((opt, i) => (
            <option key={i} value={opt.recordName}>
              {opt.recordName}
            </option>
          ))}
        </SelectField>
        <SelectField
          className="acg-existing-registration-personalInformation__referenceField"
          data-test="acg-existing-registration-personalInformation__referenceField"
          register={register}
          name="reference"
          label={t('acg.firstTimeFlow.personalInformation.inputs.individualTypeOptionLabels.label')}
          formControlStyles={{ marginBottom: 6 }}
          errors={errors}
        >
          <option value=""> </option>
          {acgRefData?.referer?.map((opt, i) => (
            <option key={i} value={opt.recordName}>
              {opt.recordName}
            </option>
          ))}
        </SelectField>

        <Box textAlign="center" mt={[8, 12]}>
          <Button
            className="acg-existing-registration-personalInformation__submitButton"
            data-test="acg-existing-registration-personalInformation__submitButton"
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            whiteSpace="normal"
            isDisabled={!isValid}
          >
            {hasCompletedAddressInformation(acgUser)
              ? 'Go To My Acg'
              : t('common.buttons.continue')}
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
