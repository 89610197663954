import {
  ExtendedSubscriptionStatus,
  ExtendedSubscriptionProduct,
  Maybe,
} from '@companydotcom/types';
// import { getEnrollmentStatus } from '../../../services/enrollment/old-api/enrollment-svc';

/**
 * Takes an array of Subscription products and returns an object with their default state to toggle
 * @param arr - An array of products available for the parent to activate for the child user
 * @param remainingSeatCount - The remaining number of seats for Email
 * @param isEmailProductActiveForParent - Checks to see if email has been purchased
 * @returns - An object with [product-name]: boolean
 */
export const getDefaultToggleState = (
  arr: ExtendedSubscriptionStatus[],
  remainingSeatCount?: Maybe<number>,
  isEmailProductActiveForParent?: Maybe<boolean>,
) => {
  const mappedItems = arr.map(item => item?.products?.[0]?.slug);

  return mappedItems
    .filter(i => i)
    .reduce((obj: any, item: any) => {
      return {
        ...obj,
        [item]: !(
          (item === 'email_rackspace' &&
            ((remainingSeatCount && remainingSeatCount <= 0) || !remainingSeatCount)) ||
          (item === 'email_rackspace' && !isEmailProductActiveForParent)
        ),
      };
    }, {});
};

/**
 * Used to format a product name to match image file names
 * @param string - a products name
 * @returns - a lowercase product name with spaces replaces by dashes
 */
export const formatProductName = (string: string) => {
  return string.toLowerCase().replace(/\s/g, '-');
};

/**
 * Takes an array of eligible child products and returns a simplified version
 * @param products - An array of products returned from getEligibleChildProducts
 * @returns - Returns an array of products
 */
export const formatChildProducts = (products: ExtendedSubscriptionStatus[]) => {
  const arr: ExtendedSubscriptionProduct[] = [];
  products.forEach(
    product => product.products && arr.push(product?.products?.[0] as ExtendedSubscriptionProduct),
  );
  return arr;
};

export const waitForSuccessfulEnrollmentStatus = async (
  enrollmentId: string,
  sourceId: string,
  getEnrollmentStatus: any,
): Promise<{
  externalIdentifier: string;
  email: string;
  status: string;
  enrollmentId: string;
}> => {
  return new Promise(resolve => {
    const interval = setInterval(async () => {
      await getEnrollmentStatus({ enrollmentId, sourceId }).then((enrollment: any) => {
        if (enrollment.status === 'fulfilled') {
          clearInterval(interval);
          resolve(enrollment);
        }
      });
    }, 6000);
  });
};
