import { companyQL } from '@companydotcom/services';

import eventQuery from './event-query-old';

const productsUsingLegacySnsTopics = [
  '785d02a4-2e7a-11e8-ae75-120b17a64360',
  '5a5e560c-8167-4780-bd23-fc90a050b8ec',
]; // yext and uberall/blm and blr

/**
 * @deprecated
 * @param param0
 * @returns
 */
export const publishTileEvent = async ({ topic, message, attributes }: any) => {
  try {
    let res;
    if (productsUsingLegacySnsTopics.includes(attributes.entityId)) {
      res = await companyQL.query({
        query: eventQuery.publishTileEvent,
        variables: { topic, message, attributes },
      });
    } else {
      res = await companyQL.query({
        query: eventQuery.publishTileEvent,
        variables: {
          topic: 'event-bus',
          message,
          attributes: { ...attributes, eventType: topic },
        },
      });
    }
    return res;
  } catch (err) {
    console.log('Publish Fetch Event error: ', err);
    return err;
  }
};
