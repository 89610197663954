import React from 'react';

export const GfDataLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="140" height="32.18" viewBox="0 0 218.485 50.308">
    <g id="Group_5317" data-name="Group 5317" transform="translate(0 136.184)">
      <path
        id="Path_15482"
        data-name="Path 15482"
        d="M4.337-134.016a2.168,2.168,0,0,1-2.168,2.168A2.168,2.168,0,0,1,0-134.016a2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        fill="#6d6f70"
      />
      <path
        id="Path_15483"
        data-name="Path 15483"
        d="M4.337-115.819a2.168,2.168,0,0,1-2.168,2.168A2.168,2.168,0,0,1,0-115.819a2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(0 -11.475)"
        fill="#6d6f70"
      />
      <path
        id="Path_15484"
        data-name="Path 15484"
        d="M4.337-40.963a2.168,2.168,0,0,1-2.168,2.168A2.168,2.168,0,0,1,0-40.963a2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(0 -58.678)"
        fill="#6d6f70"
      />
      <path
        id="Path_15485"
        data-name="Path 15485"
        d="M4.337-22.765A2.168,2.168,0,0,1,2.168-20.6,2.168,2.168,0,0,1,0-22.765a2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(0 -70.153)"
        fill="#6d6f70"
      />
      <path
        id="Path_15486"
        data-name="Path 15486"
        d="M4.337-97.622a2.168,2.168,0,0,1-2.168,2.168A2.168,2.168,0,0,1,0-97.622,2.168,2.168,0,0,1,2.168-99.79a2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(0 -22.95)"
        fill="#6d6f70"
      />
      <path
        id="Path_15487"
        data-name="Path 15487"
        d="M4.337-79.328A2.168,2.168,0,0,1,2.168-77.16,2.168,2.168,0,0,1,0-79.328,2.168,2.168,0,0,1,2.168-81.5a2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(0 -34.485)"
        fill="#70bd43"
      />
      <path
        id="Path_15488"
        data-name="Path 15488"
        d="M4.337-61.035a2.168,2.168,0,0,1-2.168,2.168A2.168,2.168,0,0,1,0-61.035,2.168,2.168,0,0,1,2.168-63.2a2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(0 -46.021)"
        fill="#6d6f70"
      />
      <path
        id="Path_15489"
        data-name="Path 15489"
        d="M22.458-134.016a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-11.427)"
        fill="#6d6f70"
      />
      <path
        id="Path_15490"
        data-name="Path 15490"
        d="M22.458-115.819a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-11.427 -11.475)"
        fill="#6d6f70"
      />
      <path
        id="Path_15491"
        data-name="Path 15491"
        d="M22.458-40.963a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-11.427 -58.678)"
        fill="#6d6f70"
      />
      <path
        id="Path_15492"
        data-name="Path 15492"
        d="M22.458-22.765A2.168,2.168,0,0,1,20.289-20.6a2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-11.427 -70.153)"
        fill="#6d6f70"
      />
      <path
        id="Path_15493"
        data-name="Path 15493"
        d="M22.458-97.622a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-11.427 -22.95)"
        fill="#6d6f70"
      />
      <path
        id="Path_15494"
        data-name="Path 15494"
        d="M22.458-79.328a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168A2.168,2.168,0,0,1,20.289-81.5a2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-11.427 -34.485)"
        fill="#70bd43"
      />
      <path
        id="Path_15495"
        data-name="Path 15495"
        d="M22.458-61.035a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168A2.168,2.168,0,0,1,20.289-63.2a2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-11.427 -46.021)"
        fill="#6d6f70"
      />
      <path
        id="Path_15496"
        data-name="Path 15496"
        d="M40.574-134.016a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-22.851)"
        fill="#6d6f70"
      />
      <path
        id="Path_15497"
        data-name="Path 15497"
        d="M40.574-115.819a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-22.851 -11.475)"
        fill="#6d6f70"
      />
      <path
        id="Path_15498"
        data-name="Path 15498"
        d="M40.574-40.963a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-22.851 -58.678)"
        fill="#6d6f70"
      />
      <path
        id="Path_15499"
        data-name="Path 15499"
        d="M40.574-22.765A2.168,2.168,0,0,1,38.406-20.6a2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-22.851 -70.153)"
        fill="#6d6f70"
      />
      <path
        id="Path_15500"
        data-name="Path 15500"
        d="M40.574-97.622a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-22.851 -22.95)"
        fill="#6d6f70"
      />
      <path
        id="Path_15501"
        data-name="Path 15501"
        d="M40.574-79.328a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168A2.168,2.168,0,0,1,38.406-81.5a2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-22.851 -34.485)"
        fill="#70bd43"
      />
      <path
        id="Path_15502"
        data-name="Path 15502"
        d="M59.125-115.819a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-34.549 -11.475)"
        fill="#6d6f70"
      />
      <path
        id="Path_15503"
        data-name="Path 15503"
        d="M59.125-97.622a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-34.549 -22.95)"
        fill="#6d6f70"
      />
      <path
        id="Path_15504"
        data-name="Path 15504"
        d="M59.125-79.424a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-34.549 -34.425)"
        fill="#70bd43"
      />
      <path
        id="Path_15505"
        data-name="Path 15505"
        d="M59.125-61.131a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168A2.168,2.168,0,0,1,56.957-63.3a2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-34.549 -45.96)"
        fill="#6d6f70"
      />
      <path
        id="Path_15506"
        data-name="Path 15506"
        d="M78.255-97.573A2.168,2.168,0,0,1,76.087-95.4a2.169,2.169,0,0,1-2.168-2.168,2.169,2.169,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-46.612 -22.98)"
        fill="#6d6f70"
      />
      <path
        id="Path_15507"
        data-name="Path 15507"
        d="M78.255-79.377a2.168,2.168,0,0,1-2.168,2.168,2.169,2.169,0,0,1-2.168-2.168,2.169,2.169,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-46.612 -34.455)"
        fill="#70bd43"
      />
      <path
        id="Path_15508"
        data-name="Path 15508"
        d="M78.255-61.083a2.168,2.168,0,0,1-2.168,2.168,2.169,2.169,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-46.612 -45.991)"
        fill="#6d6f70"
      />
      <path
        id="Path_15509"
        data-name="Path 15509"
        d="M96.227-79.377a2.168,2.168,0,0,1-2.169,2.168,2.169,2.169,0,0,1-2.168-2.168,2.169,2.169,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.169,2.168"
        transform="translate(-57.945 -34.455)"
        fill="#70bd43"
      />
      <path
        id="Path_15510"
        data-name="Path 15510"
        d="M59.125-42.838a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168,2.168,2.168,0,0,1,2.168-2.168,2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-34.549 -57.495)"
        fill="#6d6f70"
      />
      <path
        id="Path_15511"
        data-name="Path 15511"
        d="M40.574-61.035a2.168,2.168,0,0,1-2.168,2.168,2.168,2.168,0,0,1-2.168-2.168A2.168,2.168,0,0,1,38.406-63.2a2.168,2.168,0,0,1,2.168,2.168"
        transform="translate(-22.851 -46.021)"
        fill="#6d6f70"
      />
      <path
        id="Path_15512"
        data-name="Path 15512"
        d="M214.649-118.8H236.5v3.727H219.075V-104.5h15.281v3.727H219.075v15.234h-4.426Z"
        transform="translate(-135.355 -10.963)"
        fill="#575c5f"
      />
      <path
        id="Path_15513"
        data-name="Path 15513"
        d="M299.992-91h7.36c3.028,0,11.181-.838,11.181-13.09,0-7.92-2.935-12.718-11.087-12.718h-7.454Zm-4.426-29.536H306.98c10.25,0,15.98,5.124,15.98,15.84,0,11.134-4.892,17.423-15.98,17.423H295.566Z"
        transform="translate(-186.38 -9.87)"
        fill="#575c5f"
      />
      <path
        id="Path_15514"
        data-name="Path 15514"
        d="M453.317-116.8H442.229v-3.727h26.6v3.727H457.742v29.536h-4.425Z"
        transform="translate(-278.864 -9.871)"
        fill="#575c5f"
      />
      <path
        id="Path_15515"
        data-name="Path 15515"
        d="M401.178-105.074h-.093L394.92-89.7h12.42Z"
        transform="translate(-249.032 -19.618)"
        fill="#73bf43"
      />
      <path
        id="Path_15516"
        data-name="Path 15516"
        d="M383.9-115.086l6.162,15.371,4.912,12.251h5.215l-14.1-33.487h-4.05L367.515-87.464h5.214l4.91-12.251,6.165-15.371Z"
        transform="translate(-231.75 -9.606)"
        fill="#575c5f"
      />
      <path
        id="Path_15517"
        data-name="Path 15517"
        d="M535.312-105.074h-.094L529.054-89.7h12.419Z"
        transform="translate(-333.615 -19.618)"
        fill="#73bf43"
      />
      <path
        id="Path_15518"
        data-name="Path 15518"
        d="M518.029-115.086l6.161,15.371L529.1-87.464h5.214l-14.1-33.487h-4.05l-14.52,33.487h5.215l4.91-12.251,6.164-15.371Z"
        transform="translate(-316.332 -9.606)"
        fill="#575c5f"
      />
      <path
        id="Path_15519"
        data-name="Path 15519"
        d="M151.417-105.581v16.394c-3.244,1.973-8.55,3.055-12.591,3.055-11.273,0-17.8-8.032-17.8-17.476,0-9.863,7.185-17.472,17.332-17.472,6.1,0,9.815,1.644,12.4,4.321l-3.194,3.429a12.6,12.6,0,0,0-9.206-3.522c-7.61,0-12.542,5.965-12.542,13.245,0,7.516,5.591,13.247,12.918,13.247a17.55,17.55,0,0,0,8.171-1.737v-9.253h-7.375v-4.229Z"
        transform="translate(-76.318 -9.524)"
        fill="#575c5f"
      />
      <path
        id="Path_15520"
        data-name="Path 15520"
        d="M369.3-14.1h3.289v.543H369.3Zm-.456,2.54h-.635l2.412-6.627h.646l2.41,6.627h-.634l-2.077-5.84h-.045Z"
        transform="translate(-232.19 -74.41)"
        fill="#6d6f70"
      />
      <path
        id="Path_15521"
        data-name="Path 15521"
        d="M391.249-18.184v6.627h-.589l-3.914-5.549h-.053v5.549h-.605v-6.627h.586l3.928,5.556h.051v-5.556Z"
        transform="translate(-243.462 -74.41)"
        fill="#6d6f70"
      />
      <path
        id="Path_15522"
        data-name="Path 15522"
        d="M411.231-14.151h3.443v.841h-3.443Zm-.178,2.595h-1.062l2.385-6.627h1.155l2.385,6.627h-1.062l-1.873-5.423h-.053Z"
        transform="translate(-258.535 -74.41)"
        fill="#6d6f70"
      />
      <path
        id="Path_15523"
        data-name="Path 15523"
        d="M432.913-16.183H431.9a1.525,1.525,0,0,0-.216-.569,1.589,1.589,0,0,0-.388-.417,1.668,1.668,0,0,0-.513-.259,2.029,2.029,0,0,0-.6-.088,1.862,1.862,0,0,0-1.024.289,1.954,1.954,0,0,0-.711.844,3.2,3.2,0,0,0-.261,1.359,3.217,3.217,0,0,0,.261,1.366,1.928,1.928,0,0,0,.713.842,1.878,1.878,0,0,0,1.018.284,2.052,2.052,0,0,0,.6-.086,1.716,1.716,0,0,0,.513-.254,1.58,1.58,0,0,0,.39-.413,1.55,1.55,0,0,0,.221-.561l1.011,0a2.765,2.765,0,0,1-.313.9,2.512,2.512,0,0,1-.593.705,2.674,2.674,0,0,1-.823.458,3.044,3.044,0,0,1-1.01.161,2.885,2.885,0,0,1-1.533-.409,2.834,2.834,0,0,1-1.061-1.173,4,4,0,0,1-.386-1.822,3.976,3.976,0,0,1,.388-1.823,2.85,2.85,0,0,1,1.062-1.171,2.881,2.881,0,0,1,1.53-.409,3.136,3.136,0,0,1,.985.15,2.64,2.64,0,0,1,.823.44,2.447,2.447,0,0,1,.605.705,2.767,2.767,0,0,1,.324.949"
        transform="translate(-269.384 -74.255)"
        fill="#6d6f70"
      />
      <path
        id="Path_15524"
        data-name="Path 15524"
        d="M450.032-16.244a2.109,2.109,0,0,0-.25-.529,1.715,1.715,0,0,0-.374-.4,1.591,1.591,0,0,0-.494-.254,2.008,2.008,0,0,0-.608-.088,1.826,1.826,0,0,0-1.009.289,1.959,1.959,0,0,0-.71.843,3.187,3.187,0,0,0-.261,1.358,3.188,3.188,0,0,0,.262,1.362,1.939,1.939,0,0,0,.719.844,1.9,1.9,0,0,0,1.038.288,1.936,1.936,0,0,0,.943-.22,1.546,1.546,0,0,0,.625-.623,1.951,1.951,0,0,0,.221-.949l.259.048h-1.9V-15.1H451.1v.754a2.839,2.839,0,0,1-.354,1.448,2.432,2.432,0,0,1-.977.946,2.973,2.973,0,0,1-1.424.333,2.964,2.964,0,0,1-1.577-.414,2.806,2.806,0,0,1-1.057-1.177,4.031,4.031,0,0,1-.378-1.81,4.32,4.32,0,0,1,.22-1.424,3.108,3.108,0,0,1,.62-1.073,2.7,2.7,0,0,1,.942-.676,2.954,2.954,0,0,1,1.186-.235,3.092,3.092,0,0,1,1,.158,2.738,2.738,0,0,1,.829.45,2.572,2.572,0,0,1,.6.691,2.569,2.569,0,0,1,.324.885Z"
        transform="translate(-280.822 -74.255)"
        fill="#6d6f70"
      />
      <path
        id="Path_15525"
        data-name="Path 15525"
        d="M476.416-16.267h-.609a1.947,1.947,0,0,0-.243-.629,1.994,1.994,0,0,0-.442-.506,2.011,2.011,0,0,0-.6-.337,2.16,2.16,0,0,0-.728-.119,2.1,2.1,0,0,0-1.143.327,2.292,2.292,0,0,0-.825.959,3.51,3.51,0,0,0-.309,1.548,3.513,3.513,0,0,0,.309,1.553,2.281,2.281,0,0,0,.825.956,2.1,2.1,0,0,0,1.143.325,2.16,2.16,0,0,0,.728-.12,2.038,2.038,0,0,0,.6-.335,1.972,1.972,0,0,0,.442-.507,1.965,1.965,0,0,0,.243-.631h.609a2.66,2.66,0,0,1-.3.849,2.541,2.541,0,0,1-.563.688,2.578,2.578,0,0,1-.783.46,2.79,2.79,0,0,1-.975.164,2.667,2.667,0,0,1-1.481-.419,2.826,2.826,0,0,1-1.014-1.183,4.109,4.109,0,0,1-.368-1.8,4.108,4.108,0,0,1,.368-1.8,2.824,2.824,0,0,1,1.014-1.182,2.664,2.664,0,0,1,1.481-.419,2.815,2.815,0,0,1,.975.163,2.519,2.519,0,0,1,.783.46,2.579,2.579,0,0,1,.563.687,2.645,2.645,0,0,1,.3.851"
        transform="translate(-296.961 -74.256)"
        fill="#6d6f70"
      />
      <path
        id="Path_15526"
        data-name="Path 15526"
        d="M493.971-15.025a3.577,3.577,0,0,0-.3-1.526,2.3,2.3,0,0,0-.812-.972,2.09,2.09,0,0,0-1.168-.336,2.085,2.085,0,0,0-1.165.336,2.3,2.3,0,0,0-.813.971,3.562,3.562,0,0,0-.3,1.527,3.57,3.57,0,0,0,.3,1.524,2.3,2.3,0,0,0,.812.972,2.078,2.078,0,0,0,1.168.338,2.1,2.1,0,0,0,1.17-.337,2.286,2.286,0,0,0,.813-.972,3.548,3.548,0,0,0,.294-1.526m.589,0a4.094,4.094,0,0,1-.369,1.8,2.847,2.847,0,0,1-1.014,1.184,2.661,2.661,0,0,1-1.484.419,2.662,2.662,0,0,1-1.484-.419,2.827,2.827,0,0,1-1.012-1.184,4.11,4.11,0,0,1-.367-1.8,4.107,4.107,0,0,1,.367-1.8,2.819,2.819,0,0,1,1.014-1.184,2.662,2.662,0,0,1,1.481-.419,2.662,2.662,0,0,1,1.484.419,2.842,2.842,0,0,1,1.014,1.184,4.091,4.091,0,0,1,.369,1.8"
        transform="translate(-308.251 -74.255)"
        fill="#6d6f70"
      />
      <path
        id="Path_15527"
        data-name="Path 15527"
        d="M508.3-18.184h.7l2.4,5.772h.06l2.4-5.772h.7v6.627h-.569V-16.9h-.052l-2.235,5.348h-.55L508.921-16.9h-.052v5.348h-.57Z"
        transform="translate(-320.527 -74.41)"
        fill="#6d6f70"
      />
      <path
        id="Path_15528"
        data-name="Path 15528"
        d="M530.023-11.557v-6.627h2.139a2.353,2.353,0,0,1,1.18.27,1.781,1.781,0,0,1,.716.733,2.234,2.234,0,0,1,.241,1.042,2.247,2.247,0,0,1-.24,1.044,1.774,1.774,0,0,1-.711.734,2.343,2.343,0,0,1-1.175.27h-1.683v-.543h1.667a1.719,1.719,0,0,0,.864-.2,1.252,1.252,0,0,0,.513-.535,1.709,1.709,0,0,0,.17-.774,1.71,1.71,0,0,0-.17-.773,1.235,1.235,0,0,0-.515-.533,1.75,1.75,0,0,0-.87-.194h-1.519v6.083Z"
        transform="translate(-334.226 -74.409)"
        fill="#6d6f70"
      />
      <path
        id="Path_15529"
        data-name="Path 15529"
        d="M543.025-14.1h3.287v.543h-3.287Zm-.456,2.54h-.635l2.41-6.627h.648l2.41,6.627h-.635l-2.077-5.84h-.045Z"
        transform="translate(-341.738 -74.41)"
        fill="#6d6f70"
      />
      <path
        id="Path_15530"
        data-name="Path 15530"
        d="M564.972-18.184v6.627h-.589l-3.915-5.549h-.053v5.549h-.6v-6.627h.586l3.927,5.556h.052v-5.556Z"
        transform="translate(-353.01 -74.41)"
        fill="#6d6f70"
      />
      <path
        id="Path_15531"
        data-name="Path 15531"
        d="M576.864-18.184h.7l1.964,3.145h.058l1.965-3.145h.7l-2.391,3.757v2.87h-.6v-2.87Z"
        transform="translate(-363.763 -74.41)"
        fill="#6d6f70"
      />
    </g>
  </svg>
);
