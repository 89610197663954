import React from 'react';
import { Text } from '@companydotcom/potion';

import { PhoneNumberText } from './phone-number-text';
import { AppointmentText } from './appointment-text';
import { SeatCountText } from './seat-count-text';

import type { TileComponentProps } from '../../types';

export interface TileSubheaderProps extends TileComponentProps {}

export const TileStatusText: React.FC<TileSubheaderProps> = props => {
  const { tile, variant, text, appointments, product, eventData, componentTheme } = props;

  switch (variant) {
    case 'aboveButtonText':
      return (
        <Text textStyle="sm" textAlign="center" mb={4} sx={componentTheme}>
          {text}
        </Text>
      );
    case 'seatCountRemaining':
      return <SeatCountText eventData={eventData} text={text} componentTheme={componentTheme} />;
    case 'appointment':
      return (
        <AppointmentText
          text={text}
          tile={tile}
          appointments={appointments}
          product={product}
          componentTheme={componentTheme}
        />
      );
    case 'phonenumber':
      return <PhoneNumberText text={text} componentTheme={componentTheme} />;
    default:
      return <Text color="red.500">variant missing or wrong. current variant: {variant}</Text>;
  }
};
