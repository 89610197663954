import React, { useState } from 'react';
import { Center, Heading, Box, VStack, Text, Stack, Button } from '@companydotcom/potion';
import * as yup from 'yup';
import { CheckboxField, PageDivider } from '@companydotcom/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useBottomScrollListener } from '../../../hooks';

const getTermsAndConditionsSchema = () =>
  yup.object().shape({
    termsChecked: yup
      .boolean()
      .oneOf([true], 'common.misc.required')
      .required('common.misc.required'),
  });

export const TermsAndConditions = (props: any) => {
  const { nextStep } = props;
  const { t } = useTranslation();
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const defaultValues = {
    termsChecked: false,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getTermsAndConditionsSchema()),
    defaultValues,
  });

  const scrollRef = useBottomScrollListener(() => {
    setHasScrolledToBottom(true);
  });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      console.log(values);
      localStorage.setItem('facecake_termsChecked', JSON.stringify(values?.termsChecked));
      nextStep?.();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Center
      className="termsAndConditions"
      py={[4, 12]}
      pr={[0, 3]}
      pl={[0, 12]}
      flexDir="column"
      layerStyle={{ base: undefined, sm: 'formContainer' }}
      maxWidth={[null, '2xl']}
      // NOTE: Height of container is viewport minus all the extra vertical padding/margins from the layouts around it
      height="calc(100vh - 180px)"
    >
      <Heading
        alignSelf={['flex-start', 'center']}
        textAlign={['left', 'center']}
        size="hs-2xl"
        mb={['4', '12']}
      >
        FaceCake Terms & Conditions
      </Heading>
      <PageDivider width="50px" mb="6" alignSelf="flex-start" display={['block', 'none']} />
      <VStack spacing={{ base: '8', sm: '14' }} overflow="hidden">
        <Box
          //  @ts-ignore
          ref={scrollRef}
          bgColor="white"
          overflowY="auto"
          overflowX="hidden"
          p={['3', '0']}
          pr={['3', '6']}
          pb={['4', '0']}
          shadow={['lg', 'none']}
        >
          <Stack pr={['3', '6']} fontSize={{ base: 'md', sm: 'lg' }}>
            <Text as="span" fontWeight="bold" textAlign="center" mb={4}>
              {t('icommerce.termsAndConditions.heading')}
            </Text>
            <Text>
              <Trans
                i18nKey="icommerce.termsAndConditions.one"
                components={{ u: <u />, strong: <b /> }}
              />
              <Trans
                i18nKey="icommerce.termsAndConditions.two"
                components={{ u: <u />, strong: <b /> }}
              />
              <Trans
                i18nKey="icommerce.termsAndConditions.three"
                components={{ u: <u />, strong: <b /> }}
              />
              <Trans
                i18nKey="icommerce.termsAndConditions.four"
                components={{ u: <u />, strong: <b /> }}
              />
              <Trans
                i18nKey="icommerce.termsAndConditions.five"
                components={{ u: <u />, strong: <b /> }}
              />
              <Trans
                i18nKey="icommerce.termsAndConditions.six"
                components={{ u: <u />, strong: <b /> }}
              />
              <Trans
                i18nKey="icommerce.termsAndConditions.seven"
                components={{ u: <u />, strong: <b /> }}
              />
              <Trans
                i18nKey="icommerce.termsAndConditions.eight"
                components={{ u: <u />, strong: <b /> }}
              />
              <Trans
                i18nKey="icommerce.termsAndConditions.nine"
                components={{ u: <u />, strong: <b /> }}
              />
              <Trans
                i18nKey="icommerce.termsAndConditions.ten"
                components={{ u: <u />, strong: <b /> }}
              />
            </Text>
          </Stack>
        </Box>
        <Box w="full" pr={['0', '12']}>
          <Stack
            align="center"
            direction={{ base: 'column', sm: 'row' }}
            spacing={{ base: '12' }}
            justify="space-between"
          >
            <CheckboxField
              display="inline-flex"
              size="lg"
              className="facecake_termsAndConditions_termsCheckedCheckbox"
              data-test="facecake_termsAndConditions_termsCheckedCheckbox"
              register={register}
              errors={errors}
              name="termsChecked"
              verticalAlign="middle"
              isDisabled={!hasScrolledToBottom}
              opacity={!hasScrolledToBottom ? '.5' : 'inherit'}
            >
              <Text as="span">I agree to the Terms & Conditions</Text>
            </CheckboxField>
            <Box>
              <Button
                className="facecake_termsAndConditions_submitButton"
                data-test="facecake_termsAndConditions_submitButton"
                size="lg"
                isLoading={isSubmitting}
                isDisabled={!isValid}
                onClick={handleSubmit(onSubmit)}
                width={['full', 'inherit']}
              >
                {t('common.buttons.continue')}
              </Button>
            </Box>
          </Stack>
        </Box>
      </VStack>
    </Center>
  );
};
