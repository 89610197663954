import gql from 'graphql-tag';

export const GET_ACCOUNT_PAYMENT_DETAILS = gql`
  query GetPaymentDetails($zuoraAccountId: String!) {
    getPaymentDetails(id: $zuoraAccountId)
  }
`;

export const GET_PAYMENT_METHOD_DATA_BY_ID = gql`
  query GetPaymentMethodDataById($paymentMethodId: String!) {
    getPaymentMethodDataById(paymentMethodId: $paymentMethodId)
  }
`;

export const GET_ALL_PAYMENT_SOURCE_BY_CUSTOMER = gql`
  query GetAllPaymentSourceByCustomer($customerId: String!) {
    getAllPaymentSourceByCustomer(customerId: $customerId)
  }
`;

export const GET_ZUORA_INVOICE_SUMMARY = gql`
  query GetZuoraInvoiceSummary($zuoraAccountId: String!) {
    getZuoraInvoiceSummary(zuoraAccountId: $zuoraAccountId) {
      billCycleDay
      invoices {
        id
        invoiceNumber
        invoiceDate
        amount
      }
    }
  }
`;

export const GET_ZUORA_INVOICE_PDF = gql`
  query GetZuoraInvoicePdf($zuoraInvoiceId: String!) {
    getZuoraInvoicePdf(zuoraInvoiceId: $zuoraInvoiceId)
  }
`;

export const GET_PAYMENT_FORM = gql`
  query GetPaymentForm($isLocal: Boolean, $sourceId: String!) {
    getPaymentForm(isLocal: $isLocal, sourceId: $sourceId) {
      id
      signature
      token
      tenantId
      key
      success
      style
      submitEnabled
      locale
      url
    }
  }
`;

export const GET_PRODUCT_RATE_PLAN = gql`
  query GetProductRatePlan($accountId: String, $locale: String, $productId: String) {
    getProductRatePlan(accountId: $accountId, locale: $locale, productId: $productId) {
      description
      eligibleProducts
      enterpriseQty
      features
      includedInMembership
      isActive
      linkedTiles
      name
      partnerRatePlanId
      price
      tiers {
        chargeModel
        endingUnit
        partnerTierId
        price
        startingUnit
      }
      zuoraProductRatePlanChargeId
      zuoraProductRatePlanId
    }
  }
`;

export const GET_CUSTOMER_CB_INVOICES = gql`
  query GetInvoiceListByCustomer($customerId: String!) {
    getInvoiceListByCustomer(customerId: $customerId)
  }
`;

export const GET_CB_INVOICE_BY_ID = gql`
  query GetInvoiceByInvoiceId($invoiceId: String!) {
    getInvoiceByInvoiceId(invoiceId: $invoiceId)
  }
`;

export const GET_CB_INVOICE_PDF_BY_ID = gql`
  query GetInvoicePdfByInvoiceId($invoiceId: String!) {
    getInvoicePdfByInvoiceId(invoiceId: $invoiceId)
  }
`;
