const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },
  red: {
    50: '#FFDCDB',
    100: '#FFB9B8',
    200: '#FF7370',
    300: '#FF2C29',
    400: '#E00400',
    500: '#960200',
    600: '#7A0200',
    700: '#5C0200',
    800: '#3D0100',
    900: '#1F0100',
  },
  orange: {
    50: '#FCEBE3',
    100: '#FADBCC',
    200: '#F4B394',
    300: '#EF8E61',
    400: '#EA6A2E',
    500: '#c94c14',
    600: '#B54612',
    700: '#A23E10',
    800: '#82320D',
    900: '#61250A',
  },

  yellow: {
    50: '#FDFAE8',
    100: '#FBF7D5',
    200: '#F7EEAB',
    300: '#F3E57C',
    400: '#EFDD52',
    500: '#EBD428',
    600: '#C9B313',
    700: '#95860E',
    800: '#675C0A',
    900: '#332E05',
  },

  green: {
    50: '#E7F9E7',
    100: '#CEF3CF',
    200: '#9DE6A0',
    300: '#6CDA70',
    400: '#3BCE40',
    500: '#29a32d',
    600: '#259329',
    700: '#218224',
    800: '#1B6A1D',
    900: '#124914',
  },

  teal: {
    50: '#E8F6F8',
    100: '#D1ECF0',
    200: '#9FD8E0',
    300: '#71C6D1',
    400: '#3EB1C1',
    500: '#2F8591',
    600: '#256A74',
    700: '#1D5259',
    800: '#13353A',
    900: '#0A1C1F',
  },

  blue: {
    50: '#E0F0FF',
    100: '#C2E1FF',
    200: '#80C1FF',
    300: '#42A4FF',
    400: '#0586FF',
    500: '#0067C5',
    600: '#00529E',
    700: '#003D75',
    800: '#00284D',
    900: '#001529',
  },

  cyan: {
    50: '#EEFAFC',
    100: '#D8F5F8',
    200: '#B0EAF2',
    300: '#89E0EB',
    400: '#62D5E4',
    500: '#3CCADE',
    600: '#20ADC0',
    700: '#188290',
    800: '#105760',
    900: '#082B30',
  },

  purple: {
    50: '#F7E8FC',
    100: '#EED2F9',
    200: '#DDA4F4',
    300: '#CB72EE',
    400: '#BA45E8',
    500: '#A81BDE',
    600: '#8516B1',
    700: '#631084',
    800: '#440B5B',
    900: '#22062D',
  },

  pink: {
    50: '#FFEBFB',
    100: '#FFDBF8',
    200: '#FFB8F2',
    300: '#FF8FEA',
    400: '#FF6BE4',
    500: '#FF47DD',
    600: '#FF05D1',
    700: '#C2009E',
    800: '#85006C',
    900: '#420036',
  },
};

export default colors;
