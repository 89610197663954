import React from 'react';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { MyAcgProfile } from '../../../features/acg/acg-my-profile';

export const AcgMyProfile = () => {
  return (
    <PageLayout>
      <PageLayoutContent>
        <MyAcgProfile />
      </PageLayoutContent>
    </PageLayout>
  );
};
