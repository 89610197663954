import React from 'react';
import { Flex, ButtonGroup, Button, Text } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { FetchedPaymentProps } from './saved-payment-payment-tile';

interface DeleteTileProps extends FetchedPaymentProps {
  onClose?: () => void;
  isAutoRenew?: boolean;
}

export const DeleteConfirmationTile = ({
  isDeletingMethod,
  isAutoRenew,
  paymentMethodType,
  cardNumber,
  accountNumber,
  cardType,
  merchantAccountTokens,
  recordKey,
  onDelete,
  onClose,
}: Partial<DeleteTileProps>) => {
  const { t } = useTranslation();
  const autoRenewProps = {
    paymentMethodType,
    cardNumber,
    accountNumber,
    cardType,
    merchantAccountTokens,
    recordKey,
    onClose,
  };

  return (
    <Flex
      maxW="auto"
      bg="blackAlpha.50"
      borderRadius="md"
      flexDirection="column"
      alignItems="center"
      py={10}
      px={7}
    >
      <Text fontWeight="semibold" align="center" lineHeight={6}>
        {t('acg.savedPaymentMethods.tiles.delete.areYouSure')}
      </Text>
      <ButtonGroup mt={6}>
        <Button variant="outline" onClick={onClose} isDisabled={isDeletingMethod}>
          {t('acg.savedPaymentMethods.tiles.delete.no')}
        </Button>
        <Button
          onClick={() => {
            if (isAutoRenew) {
              onDelete?.(autoRenewProps);
            } else {
              onDelete?.({ recordKey, onClose });
            }
          }}
          isLoading={isDeletingMethod}
        >
          {t('acg.savedPaymentMethods.tiles.delete.yes')}
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
