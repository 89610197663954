import React from 'react';
import { Text, Link, Button, Box } from '@companydotcom/potion';
import type { TileComponentProps } from '../tile-component';

//? NOTE: This component is pretty much the same as TileConditionText and TileClickText

export interface TileUnderButtonTextProps extends TileComponentProps {}

// @ts-ignore
export const TileUnderButtonText: React.FC<TileUnderButtonTextProps> = props => {
  const { pubEvents, eventHandler, componentTheme, children, text } = props;
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    eventHandler?.(pubEvents, e);
  };

  return (
    <Box className="tile-underButtonText" mt={4} sx={componentTheme}>
      {children ? (
        children?.map((c, i) =>
          c?.variant === 'anchor' && c?.href ? (
            <Link key={i} textStyle="sm" href={c.href} isExternal>
              {c?.text}
            </Link>
          ) : pubEvents ? (
            <Button key={i} fontSize="sm" variant="link" onClick={handleClick}>
              {c?.text}
            </Button>
          ) : (
            <Text key={i} as="span" textStyle="sm">
              {c?.text}
            </Text>
          ),
        )
      ) : (
        <Text as="span" textStyle="sm">
          {text}
        </Text>
      )}
    </Box>
  );
};
