import React, { useEffect } from 'react';
import { companyConstants } from '@companydotcom/helpers';
import { Center, Box, Button, Text, Stack, FormControl } from '@companydotcom/potion';
import { useMitt } from '@companydotcom/providers';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PageHeading } from '../../../../../../components/elements';
import { useAcgCartStorage } from '../../../../shared';
import { PaginatedSelect } from '../components/paginated-select';
import { BecomeMemberSharedProps } from './become-a-member';
import { useTranslation } from 'react-i18next';
import { GetAllAcgChaptersAndPackagesResponse } from '@companydotcom/types';

export const PackageSelect = ({
  isSubpage,
  packageKeyParam,
  setIsBecomingMember,
  packagesAndChapters,
  goToStep,
  isLoading,
  register,
  handleSubmit,
  control,
  reset,
  errors,
  isValid,
  setIsValid,
  getValues,
}: BecomeMemberSharedProps) => {
  const { emitter } = useMitt();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const { addPackageToLocalCart } = useAcgCartStorage();
  const webCode = searchParams.get('WebCode')?.toLowerCase();

  const onSubmit = (data: { acgPackage: string }) => {
    const filteredPackage = packagesAndChapters?.find(
      (item: GetAllAcgChaptersAndPackagesResponse) =>
        item.packageMembershipType === data?.acgPackage,
    );
    if (filteredPackage) {
      addPackageToLocalCart(filteredPackage);
    }
    goToStep?.('chapter-select');
  };

  const onChange = () => {
    setIsValid?.on();
  };

  useEffect(() => {
    if (!getValues?.()?.['acgPackage'] && isValid) {
      setIsValid?.off();
    }
  }, [getValues, isValid, setIsValid]);

  return (
    <Center
      className="acg-package-selection__container"
      flexDirection="column"
      bg={['transparent', 'white']}
      textAlign="center"
      px={[0, 4]}
      py={isSubpage ? [0, 12] : 0}
    >
      <Box
        as="form"
        width="full"
        className="acg-package-selection__form"
        maxW={isSubpage ? 908 : 608}
      >
        <Stack width="full" spacing={[8, 12]} mb={[4, 12]}>
          <PageHeading heading={t('acg.firstTimeFlow.subpage.packageHeading')} />
          <Text>{t('acg.firstTimeFlow.subpage.packageThankYou')}</Text>
        </Stack>

        <FormControl id="package-select">
          <PaginatedSelect
            data={packagesAndChapters}
            onSubmit={handleSubmit(onSubmit)}
            onChange={onChange}
            isValid={isValid}
            isPackageScreen
            isLoading={isLoading}
            packageKeyParam={packageKeyParam}
            formProps={{
              control,
              reset,
              register,
              errors,
              name: 'acgPackage',
            }}
          />
        </FormControl>
        {!isSubpage && (
          <Button
            mt={3}
            className="acg-registration-becomeAMember_submitButton"
            data-test="acg-registration-becomeAMember_submitButton"
            size="sm"
            variant="ghost"
            whiteSpace="normal"
            isDisabled={isLoading}
            onClick={() => {
              setIsBecomingMember?.off();
              if (
                webCode === 'eventregfees' ||
                webCode === 'acgmembershipdirectory' ||
                webCode === 'acgmemberdirectory'
              ) {
                navigate({ pathname: '/redirect', search });
              } else {
                emitter.emit(companyConstants.platformEvents.dataCollectorComplete, { path: '/' });
              }
            }}
          >
            {webCode === 'eventregfees'
              ? 'BECOME A MEMBER LATER, THEN REGISTER FOR EVENT'
              : 'BECOME A MEMBER LATER AND GO TO MY ACG'}
          </Button>
        )}
      </Box>
    </Center>
  );
};
