import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Text,
  StylesProvider,
  useMultiStyleConfig,
  Heading,
  Button,
  VStack,
} from '@companydotcom/potion';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _merge from 'lodash/merge';
import _sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { companyHelpers } from '@companydotcom/helpers';
import { InputField, SelectField } from '@companydotcom/ui';
import { stateOptions } from '../utils/state-options';
import { generateYears } from '../../../utils';
import { useAppDispatch, useToast } from '../../../hooks';
import { updateUser } from '../../../features/users';
import {
  useGetGlobalUserQuery,
  useUpdateAccountBusinessMutation,
  useUpdateLoanMutation,
} from '../../../services/user/user-api';
import { PageHeading } from '../../../components/elements';
// import {
// updateLoanData,
//  updateAccountBusiness
// } from '../../../services/user/old-api/user-svc';

const getBusinessProfileSchema = () =>
  yup.object().shape({
    businessAddress: yup.string().required('common.inputs.businessAddress.error'),
    businessName: yup.string().required('common.inputs.businessName.error'),
    city: yup.string().required('common.inputs.city.error'),
    numEmployees: yup
      .number()
      .typeError('common.inputs.businessName.error')
      .notRequired()
      .transform((cv, ov) => (ov === '' ? undefined : cv))
      .max(9999, 'common.inputs.businessName.validation'),
    state: yup.string().required('common.inputs.state.error'),
    zipCode: yup
      .string()
      .required('common.inputs.postalCode.error')
      .test('zip code', 'common.inputs.postalCode.validation', val =>
        companyHelpers.validateZipCode(val),
      ),
  });

interface IndustryProps {
  id: string;
  isActive: boolean;
  label: string;
  refType: string;
  value: number;
}

export interface BusinessProfileStepProps {}

export const BusinessProfileStep: React.FC<BusinessProfileStepProps> = props => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [updateAccountBusiness] = useUpdateAccountBusinessMutation();
  const [updateLoanData] = useUpdateLoanMutation();
  const [industryOptions, setIndustryOptions] = useState<IndustryProps[]>([]);
  const { t } = useTranslation();
  const styles = useMultiStyleConfig('BusinessProfileStep', props);
  const yearOptions = generateYears(1900);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const defaultValues = {
    businessName: globalUser?.account?.businessPrimary?.name ?? '',
    businessAddress: globalUser?.account?.businessPrimary?.address?.addressLine1 ?? '',
    zipCode: globalUser?.account?.businessPrimary?.address?.zipCode ?? '',
    city: globalUser?.account?.businessPrimary?.address?.city ?? '',
    numEmployees: globalUser?.account?.businessPrimary?.numberOfEmployees ?? '',
    website: globalUser?.account?.businessPrimary?.website ?? '',
    industry: globalUser?.account?.loanPrimary?.industry ?? '',
    state: globalUser?.account?.businessPrimary?.address?.state ?? '',
    yearFounded: globalUser?.account?.loanPrimary?.yearFounded ?? null,
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(getBusinessProfileSchema()),
    defaultValues,
  });

  useEffect(() => {
    // TODO: Doesn't work for plat environment
    const url = `${process.env.REACT_APP_BASE_URL}/referencevalues/type/industry/status/active`;
    const fetchData = async () => {
      await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(data => {
          setIndustryOptions(_sortBy(data.result.data, 'value'));
        })
        .catch(rejected => {
          console.log('Failed to fetch reference values: ', rejected);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    setValue('industry', globalUser?.account?.loanPrimary?.industry ?? '');
  }, [globalUser, industryOptions, setValue]);

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      await Promise.all([
        updateAccountBusiness({
          userId: globalUser?.userId,
          accountId: globalUser?.accountId,
          businessId: globalUser?.account?.businessPrimary?.businessId,
          businessName: data.businessName,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
          address: data.businessAddress,
          numberOfEmployees: data.numEmployees,
          website: data.website,
          category: data.industry,
          yearsInBusiness: dayjs().diff(data.yearFounded, 'year'),
        }),
        updateLoanData({
          accountId: globalUser?.accountId ?? '',
          loans: [
            {
              industry: data.industry,
              yearFounded: data.yearFounded ?? null,
            },
          ],
        }),
      ]).then(() => {
        const payload = _merge(globalUser, {
          account: {
            businessPrimary: {
              address: {
                addressLine1: data.businessAddress,
                city: data.city,
                state: data.state,
                zipCode: data.zipCode,
              },
              category: data.industry,
              website: data.website,
              numberOfEmployees: data.numEmployees,
              name: data.businessName,
            },
            loanPrimary: {
              industry: data.industry,
              yearFounded: data.yearFounded,
            },
          },
        });

        dispatch(updateUser(payload));

        toast({
          description: t('miop.businessProfile.snackbar'),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      });
    } catch (err) {
      toast({
        description: t('miop.businessProfile.snackbarError'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.log('Error!', err);
    }
  };

  return (
    <StylesProvider value={styles}>
      <Box className="potion-businessProfileStep__container" sx={styles.container}>
        <PageHeading heading={t('miop.businessProfile.navLabel')} mb={6} />
        <Box
          as="form"
          className="potion-businessProfileStep__form"
          sx={styles.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box mb={6}>
            <Heading as="h3" size="hs-md" mb={2}>
              {t('miop.businessProfile.header')}
            </Heading>
            <Text textStyle="md">{t('common.misc.allFieldsRequiredUnless')}</Text>
          </Box>
          <VStack className="potion-businessProfileStep__fields" sx={styles.fields} spacing={6}>
            <InputField
              register={register}
              name="businessName"
              label={t('common.inputs.businessName.label')}
              errors={errors}
            />
            <InputField
              register={register}
              name="businessAddress"
              label={t('common.inputs.businessAddress.label')}
              errors={errors}
            />
            <InputField
              register={register}
              name="zipCode"
              label={t('common.inputs.postalCode.label')}
              errors={errors}
            />
            <InputField
              register={register}
              name="city"
              label={t('common.inputs.city.label')}
              errors={errors}
            />
            <SelectField
              register={register}
              name="state"
              label={t('common.inputs.state.label')}
              errors={errors}
            >
              {stateOptions.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </SelectField>
          </VStack>
          <Box mb={6}>
            <Heading as="h3" size="hs-md" mb={2}>
              {t('miop.businessProfile.header2')}
            </Heading>
            <Text textStyle="md">{t('common.misc.allFieldsRequiredUnless')}</Text>
          </Box>
          <VStack className="potion-businessProfileStep__fields" sx={styles.fields} spacing={6}>
            <InputField
              register={register}
              name="website"
              label={t('common.inputs.businessWebsite.labelOptional')}
              errors={errors}
            />
            <SelectField
              register={register}
              name="yearFounded"
              label={t('common.inputs.yearFounded.labelOptional')}
              errors={errors}
            >
              {yearOptions.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </SelectField>
            <SelectField
              register={register}
              name="industry"
              label={t('common.inputs.industry.labelOptional')}
              errors={errors}
            >
              {industryOptions.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </SelectField>
            <InputField
              register={register}
              name="numEmployees"
              label={t('common.inputs.numEmployees.labelOptional')}
              errors={errors}
              type="number"
              min="0"
            />
          </VStack>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              className="potion-businessProfileStep__formButton"
              data-test="potion-businessProfileStep__formButton"
              sx={styles.formButton}
              type="submit"
              size="lg"
              isLoading={isSubmitting}
              isDisabled={!isValid}
            >
              {t('common.buttons.save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </StylesProvider>
  );
};
