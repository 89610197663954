import React, { useEffect } from 'react';
import { Button, Flex, Text } from '@companydotcom/potion';
import { AcgMembership, Maybe } from '@companydotcom/types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface TileAcgMembershipEnrollAutoRenewStepProps extends AcgMembership {
  handlePrefetchingSavedPayments?: any;
  chapterName: Maybe<string>;
}

export const TileAcgMembershipEnrollAutoRenewStep: React.FC<
  TileAcgMembershipEnrollAutoRenewStepProps
> = ({ chapterName, expireDate, joinDate, handlePrefetchingSavedPayments }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const autoRenewProps = {
    chapterName,
    expireDate,
    joinDate,
  };

  useEffect(() => {
    handlePrefetchingSavedPayments();
  }, [handlePrefetchingSavedPayments]);

  return (
    <Flex
      flexDirection="column"
      width="full"
      flexGrow={1}
      px={4}
      mt={2}
      justifyContent="space-between"
    >
      <Text>{t(`acg.membershipStatus.enrollInAutoRenew.body`)}</Text>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Text fontWeight="bold" mb={2} textAlign="center">
            Chapter(s): {chapterName}
          </Text>
          <Text fontWeight="bold" mb={4} textAlign="center">
            Membership Exp: {expireDate}
          </Text>
        </Flex>
        <Button
          variant="tile"
          onClick={() => navigate('/auto-renew', { state: autoRenewProps })}
          size="md"
          type="submit"
        >
          {t(`acg.membershipStatus.enrollInAutoRenew.cta`)}
        </Button>
        <Button onClick={() => navigate('/my-profile')} size="sm" mt={4} variant="link">
          See Your Profile
        </Button>
      </Flex>
    </Flex>
  );
};
