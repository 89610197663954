import type { AcgUserProfile, AcgUserAddress, Maybe } from '@companydotcom/types';

export const hasCompletedPersonalInformation = (user?: AcgUserProfile) => {
  if (
    user &&
    user.firstName &&
    user.lastName &&
    user.organization &&
    user.title &&
    user.individualType
  ) {
    return true;
  }

  return false;
};

export const hasCompletedAddressInformation = (user?: Maybe<AcgUserProfile>) => {
  const primaryAddress = user?.[user?.preferredAddressType!] as AcgUserAddress;

  if (
    user &&
    primaryAddress &&
    primaryAddress?.state &&
    primaryAddress.addressLine1 &&
    primaryAddress.city &&
    primaryAddress.state &&
    primaryAddress.postalCode
  ) {
    return true;
  }

  return false;
};
