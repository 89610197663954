import React from 'react';
import { Box, Link, Text, Heading, Button, Center } from '@companydotcom/potion';
import { NavLink } from 'react-router-dom';
import { CircleExclamationIcon } from '@companydotcom/ui';

export const BLPError = ({
  setFirstTimeTileFlow,
}: {
  setFirstTimeTileFlow?: (arg: boolean) => void;
}) => {
  return (
    <Center
      className="fttf-error-screen"
      flexDirection="column"
      pb={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box sx={{ textAlign: 'center' }} maxWidth={475} mb={[13]}>
        <CircleExclamationIcon mx="auto" mb={[4]} />
        <Heading as="h1" size="hs-xl">
          Something went wrong
        </Heading>
        <Text textStyle="md" mt={4} mb={[5]}>
          Please try again later or{' '}
          <Link as={NavLink} to="/help">
            Contact Support
          </Link>{' '}
        </Text>
      </Box>
      <Button size="lg" onClick={() => setFirstTimeTileFlow?.(false)}>
        Back to Business Listings Profile
      </Button>
    </Center>
  );
};
