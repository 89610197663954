import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Button,
  Heading,
  Alert,
  AlertTitle,
  AlertDescription,
  Link,
  Center,
  HStack,
  Icon,
  SimpleGrid,
} from '@companydotcom/potion';
// import { useMitt } from '@companydotcom/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';
// import { companyConstants } from '@companydotcom/helpers';
import { User, UserTile } from '@companydotcom/types';
import { NavLink } from 'react-router-dom';
import { PaymentMethod } from '../payment-method';
import { OrderSummary } from '../order-summary/order-summary';
import { getPaymentMethod, fetchOrderSummary } from '../utils';
import { getPaywallContentOptions } from '../../../utils/helpers/purchasing-helpers';
import { CartStateProps } from '../../../state/reducers/cart-reducer';
import { PaywallStateProps } from '../../../state/reducers/paywall-reducer';
import { useLazyPublishTileEventQuery } from '../../../services/event/event-api';
import {
  useLazyGetAccountPaymentDetailsQuery,
  useLazyGetPaymentMethodDataByIdQuery,
  useLazyGetPaymentFormQuery,
} from '../../../services/payment/payment-api';

interface CheckoutProps {
  sourceId?: string;
  cancelPayment?: () => void;
  user?: User;
  externalFlow?: boolean;
  orderId?: string;
  productId?: string;
  goToStep?: (newStep: string | number, data?: any) => void;
  tile?: UserTile;
  email?: string;
  emailQuantity?: number;
  cartState?: CartStateProps;
  paywallState?: PaywallStateProps;
  setPaymentData?: (arg: any) => void;
}

export const Checkout = ({
  sourceId,
  cancelPayment,
  user,
  externalFlow,
  orderId,
  productId,
  goToStep,
  cartState,
  paywallState,
  tile,
  email,
  setPaymentData,
}: CheckoutProps) => {
  // const { emitter } = useMitt();
  const [publishTileEvent] = useLazyPublishTileEventQuery();
  const [getPaymentForm] = useLazyGetPaymentFormQuery();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkoutState, setCheckoutState] = useState(true);
  const [error, setError] = useState(null);
  const [formParams, setFormParams] = useState({});
  const [paymentMethodView, setPaymentMethodView] = useState('zuora');
  const [paymentMethod, setPaymentMethod] = useState({ cardHolderInfo: {} });
  const [externalOrderSummary, setExternalOrderSummary] = useState([]);
  const [externalLoading, setExternalLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [getAccountPaymentDetails] = useLazyGetAccountPaymentDetailsQuery();
  const [getPaymentMethodDataById] = useLazyGetPaymentMethodDataByIdQuery();

  const zuoraAccountId = user?.account?.zuoraAccountId;
  const zuoraPaymentMethodId = user?.account?.zuoraPaymentMethodId;
  const allowPartnerBilling = user?.account?.allowPartnerBilling;
  const contentOptions =
    paywallState?.userProduct?.products?.[0] &&
    getPaywallContentOptions(paywallState?.userProduct?.products?.[0]);
  const quantityLabel =
    contentOptions?.['QUANTITY_LABEL'] && contentOptions['QUANTITY_LABEL'].replace(/^"|"$/g, '');
  const { t } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    if (orderId && isMounted) {
      setExternalLoading(true);
      fetchOrderSummary(
        user,
        orderId,
        productId,
        setExternalOrderSummary,
        setExternalLoading,
        publishTileEvent,
      );
    }
    return () => {
      isMounted = false;
    };
  }, [user, orderId, productId, sourceId, setExternalOrderSummary, publishTileEvent]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getPaymentMethod(
        getAccountPaymentDetails,
        getPaymentMethodDataById,
        getPaymentForm,
        sourceId,
        zuoraAccountId,
        zuoraPaymentMethodId,
        setFormParams,
        setLoading,
        setPaymentMethodView,
        setError,
        setPaymentMethod,
        allowPartnerBilling,
      );
    }
    return () => {
      isMounted = false;
    };
  }, [
    sourceId,
    zuoraAccountId,
    zuoraPaymentMethodId,
    allowPartnerBilling,
    getAccountPaymentDetails,
    getPaymentMethodDataById,
    getPaymentForm,
  ]);

  const handleUpdatePaymentMethod = () => {
    setPaymentMethodView('zuora');
  };
  const onDataUpdate = (data: any) => {
    setCheckoutState(data);
  };
  const paymentMethodProps = {
    loading,
    submitting,
    setLoading,
    setSubmitting,
    handleUpdatePaymentMethod,
    paymentMethod,
    error,
    setError,
    formParams,
    paymentMethodView,
    user,
    externalFlow,
    eventSource: paywallState?.eventSource,
    setPaymentError,
    setPaymentData,
    setPaymentMethod,
  };

  const sharedProps = {
    sourceId,
    cancelPayment,
    user,
    externalFlow,
    orderId,
    productId,
    eventSource: paywallState?.eventSource,
    goToStep,
    selectedRatePlans: cartState?.selectedRatePlans,
    selectedTiers: cartState?.selectedTiers,
    selectedProducts: paywallState?.userProduct?.products,
    tile,
    email,
    quantityLabel,
    quantity: paywallState?.quantity,
  };

  return (
    <Center
      className="paywall__checkout"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      mb={12}
      mt={10}
      width="100%"
      maxW={825}
      mx="auto"
    >
      <Heading size="hs-2xl">{t('paywall.checkout.header')}</Heading>
      <Text mt={1} textStyle="md">
        {t('paywall.checkout.subheader')}
      </Text>
      <HStack justifyContent="center" alignItems="center" spacing={2} mt={2} mb={[11, 14, 15, 12]}>
        <Icon as={FontAwesomeIcon} icon={faLock} boxSize={3} />
        <Text textStyle="md">{t('paywall.checkout.secure')}</Text>
      </HStack>
      <Flex flexDirection="column">
        <SimpleGrid
          minChildWidth={['100%', 392]}
          columns={[1, null, 2]}
          spacingX={[4, null, null, 10]}
          position="relative"
        >
          {((cartState?.selectedRatePlans && cartState?.selectedRatePlans?.length > 0) ||
            externalOrderSummary) && (
            <>
              <PaymentMethod {...paymentMethodProps} {...sharedProps} onDataUpdate={onDataUpdate} />
              <OrderSummary
                {...sharedProps}
                externalOrderSummary={externalOrderSummary}
                externalLoading={externalLoading}
              />
            </>
          )}
        </SimpleGrid>
        {paymentError && (
          <Alert maxWidth={756} mx="auto" status="error" flexDirection="column" marginY={8}>
            <AlertTitle mb={2}>Payment Error:</AlertTitle>
            <Trans i18nKey="paywall.checkout.error.copy">
              <AlertDescription textStyle="sm" textAlign="center">
                There was an error processing your payment. Please try again or
                <Link as={NavLink} to="/help">
                  contact support
                </Link>
                for additional help.
              </AlertDescription>
            </Trans>
          </Alert>
        )}
      </Flex>
      {/* <Button
        mt={8}
        size="lg"
        // Commented zuora payment form temporarily until a new payment method is introduced
        // isLoading={loading || submitting || externalLoading}
        isDisabled={checkoutState}
        onClick={() => {
          emitter.emit(companyConstants.platformEvents.confirmPurchase, {
            busEventSource: 'checkout',
          });
        }}
      >
        {t(submitting ? 'paywall.checkout.submitting' : 'paywall.checkout.confirm')}
      </Button> */}
      {!externalFlow && !submitting ? (
        <Button
          mt={12}
          variant="link"
          marginRight="4px"
          marginTop="91px"
          onClick={() => {
            cancelPayment?.();
          }}
        >
          {t('paywall.checkout.cancel')}
        </Button>
      ) : null}
    </Center>
  );
};
