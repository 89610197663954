import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text, Heading, Button } from '@companydotcom/potion';
import { Maybe } from '@companydotcom/types';

interface AccordionTitleProps {
  productName: string;
  price: number | null;
  isExpanded?: boolean;
  isSelected?: boolean;
  activeRatePlanName?: Maybe<string>;
  availableProductStep?: boolean;
  numberOfRatePlans?: number;
  isMobile?: boolean;
  activeTierDescription?: string;
  includedInMembership?: Maybe<boolean>;
  isPackage?: boolean;
}

export const AccordionTitle = ({
  productName,
  price,
  isExpanded,
  activeRatePlanName = '',
  availableProductStep,
  numberOfRatePlans,
  isMobile,
  isSelected,
  activeTierDescription,
  includedInMembership,
  isPackage,
}: AccordionTitleProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      className="subscription-accordion-title"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ userSelect: 'none' }}
      width="full"
    >
      <Box textAlign="left">
        <Flex flexDirection={['column', null, null, 'row']}>
          <Heading size="hs-md" mb={2}>
            {productName || ''}
          </Heading>
          {activeRatePlanName && !isPackage && !includedInMembership && (
            <Text fontSize="sm" mb={[2, null, null, 0]} ml={[0, null, null, 2]}>
              {`— ${activeRatePlanName}`}
              {activeTierDescription && ` (${activeTierDescription})`}
            </Text>
          )}
        </Flex>
        <Button as="p" variant="link" pointerEvents="none" _hover={{ textDecoration: 'none' }}>
          <Text as="span" display="inline-block" width="10px" mr={1}>
            {isExpanded ? '—' : '+'}
          </Text>
          {t('miop.subscriptions.accordionCTA')}
        </Button>
      </Box>
      <Box>
        {availableProductStep ? (
          <Text
            pl={1}
            color={isSelected ? 'pricing' : 'gray.300'}
            textStyle="md"
            fontWeight="medium"
            textAlign="right"
          >
            {numberOfRatePlans && numberOfRatePlans > 1 ? t('miop.subscriptions.from') : null}
            {` $${`${price?.toFixed(2)}` || 0}/${t('miop.subscriptions.month')}`}
          </Text>
        ) : (
          <>
            <Text
              textStyle="lg"
              fontWeight="medium"
              color="pricing"
              textAlign={['right', null, 'center']}
            >
              {includedInMembership && !isSelected
                ? t('miop.subscriptions.included')
                : `$${price || 0}`}
            </Text>
            <Text
              textStyle="sm"
              fontWeight="bold"
              color="pricing"
              textAlign={['right', null, 'center']}
            >
              {isMobile ? t('miop.subscriptions.perMonth') : ''}
            </Text>
          </>
        )}
      </Box>
    </Flex>
  );
};
