import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Text,
  Button,
  Heading,
  Avatar,
  Stack,
  useBreakpointValue,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

export const ChildUserAccordionTitle = ({
  firstName,
  lastName,
  phone,
  userStatus,
  isOpen,
  avatarColor,
  // eslint-disable-next-line
  setEditorOpen,
  resendEmailHandler,
  isResendEmailLoading,
  childUserAvatar,
}: {
  firstName?: string;
  lastName?: string;
  phone?: string;
  userStatus?: string;
  isOpen?: boolean;
  avatarColor?: string;
  setEditorOpen?: any;
  resendEmailHandler?: any;
  isResendEmailLoading?: boolean;
  childUserAvatar?: string;
}) => {
  const { t } = useTranslation();
  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-lg' });
  return (
    <Flex className="child-user-accordion-title" flexDirection="row" width="full">
      <Avatar
        as={Button}
        mr={[4, null, 6]}
        src={childUserAvatar}
        name={`${firstName} ${lastName}`}
        className="child-avatar"
        size="lg"
        alt="User Avatar"
        bg={avatarColor ?? 'teal.400'}
        _hover={{ bg: avatarColor ?? 'teal.400' }}
        color="white"
        cursor="pointer"
      />
      <Stack
        alignItems={['flex-start', null, 'center']}
        justifyContent={['flex-start', null, 'space-between']}
        width="full"
        direction={['column', null, 'row']}
        textAlign="left"
      >
        <Heading textAlign="left" size={headingSize}>{`${firstName} ${lastName}`}</Heading>
        <Flex flexDirection="row" alignItems="center" pl={2}>
          {(userStatus === 'pending' || phone === null || phone === '') && (
            <Text mr={2} sx={{ whiteSpace: 'nowrap' }}>
              {t('miop.userProfile.childUser.pending')} -{' '}
              {isResendEmailLoading ? (
                t('miop.userProfile.childUser.sendingEmail')
              ) : (
                <Button variant="link" onClick={resendEmailHandler || undefined}>
                  {t('miop.userProfile.childUser.resendEmail')}
                </Button>
              )}
            </Text>
          )}
          {userStatus !== 'pending' && phone !== null && phone !== '' && (
            <FontAwesomeIcon color="blue.500" icon={isOpen ? faChevronUp : faChevronDown} />
          )}
        </Flex>
      </Stack>
    </Flex>
  );
};
