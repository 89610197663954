const stateOptions = [
  {
    value: 'AL',
    label: 'Alabama',
    className: 'first__option',
    refType: 'state',
  },
  { value: 'AK', label: 'Alaska', refType: 'state' },
  { value: 'AZ', label: 'Arizona', refType: 'state' },
  { value: 'AR', label: 'Arkansas', refType: 'state' },
  { value: 'CA', label: 'California', refType: 'state' },
  { value: 'CO', label: 'Colorado', refType: 'state' },
  { value: 'CT', label: 'Connecticut', refType: 'state' },
  { value: 'DE', label: 'Delaware', refType: 'state' },
  { value: 'FL', label: 'Florida', refType: 'state' },
  { value: 'GA', label: 'Georgia', refType: 'state' },
  { value: 'HI', label: 'Hawaii', refType: 'state' },
  { value: 'ID', label: 'Idaho', refType: 'state' },
  { value: 'IL', label: 'Illinois', refType: 'state' },
  { value: 'IN', label: 'Indiana', refType: 'state' },
  { value: 'IA', label: 'Iowa', refType: 'state' },
  { value: 'KS', label: 'Kansas', refType: 'state' },
  { value: 'KY', label: 'Kentucky', refType: 'state' },
  { value: 'LA', label: 'Louisiana', refType: 'state' },
  { value: 'ME', label: 'Maine', refType: 'state' },
  { value: 'MD', label: 'Maryland', refType: 'state' },
  { value: 'MA', label: 'Massachusetts', refType: 'state' },
  { value: 'MI', label: 'Michigan', refType: 'state' },
  { value: 'MN', label: 'Minnesota', refType: 'state' },
  { value: 'MS', label: 'Mississippi', refType: 'state' },
  { value: 'MO', label: 'Missouri', refType: 'state' },
  { value: 'MT', label: 'Montana', refType: 'state' },
  { value: 'NE', label: 'Nebraska', refType: 'state' },
  { value: 'NV', label: 'Nevada', refType: 'state' },
  { value: 'NH', label: 'New Hampshire', refType: 'state' },
  { value: 'NJ', label: 'New Jersey', refType: 'state' },
  { value: 'NM', label: 'New Mexico', refType: 'state' },
  { value: 'NY', label: 'New York', refType: 'state' },
  { value: 'NC', label: 'North Carolina', refType: 'state' },
  { value: 'ND', label: 'North Dakota', refType: 'state' },
  { value: 'OH', label: 'Ohio', refType: 'state' },
  { value: 'OK', label: 'Oklahoma', refType: 'state' },
  { value: 'OR', label: 'Oregon', refType: 'state' },
  { value: 'PA', label: 'Pennsylvania', refType: 'state' },
  { value: 'RI', label: 'Rhode Island', refType: 'state' },
  { value: 'SC', label: 'South Carolina', refType: 'state' },
  { value: 'SD', label: 'South Dakota', refType: 'state' },
  { value: 'TN', label: 'Tennessee', refType: 'state' },
  { value: 'TX', label: 'Texas', refType: 'state' },
  { value: 'UT', label: 'Utah', refType: 'state' },
  { value: 'VT', label: 'Vermont', refType: 'state' },
  { value: 'VA', label: 'Virginia', refType: 'state' },
  { value: 'WA', label: 'Washington', refType: 'state' },
  { value: 'WV', label: 'West Virginia', refType: 'state' },
  { value: 'WI', label: 'Wisconsin', refType: 'state' },
  { value: 'WY', label: 'Wyoming', refType: 'state' },
  { value: 'AS', label: 'American Samoa', refType: 'state' },
  { value: 'DC', label: 'District of Columbia', refType: 'state' },
  { value: 'GU', label: 'Guam', refType: 'state' },
  { value: 'MP', label: 'Northern Mariana Islands', refType: 'state' },
  { value: 'PR', label: 'Puerto Rico', refType: 'state' },
  {
    value: 'UM',
    label: 'United States Minor Outlying Islands',
    refType: 'state',
  },
  { value: 'VI', label: 'Virgin Islands, U.S.', refType: 'state' },
];

export default stateOptions;
