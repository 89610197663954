import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  VStack,
  StackDivider,
  Heading,
  Pagination,
  usePagination,
  PaginationContainer,
  PaginationItem,
  Tag,
  Text,
  TagLabel,
  TagCloseButton,
  Flex,
  Button,
  Center,
  Wrap,
  WrapItem,
  useBreakpointValue,
  useBoolean,
} from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { NoResults } from './no-results-tile';
import { MemberSearchResultsProps, newSearchProps } from '../../utils';
import { useAppSelector, useAppDispatch } from '../../../../../hooks';
import { MemberTile } from './individual-member';
import {
  updateMembers,
  clearSearch,
  removeFilter,
  setPageNumber,
  memberSearchQuery,
  updateSearchFilters,
  getMembers,
  getTotalResults,
  getPageNumber,
  getSearchQuery,
  getFilters,
} from '../../member-search-slice';

import { useLazySearchAcgMemberDirectoryV2Query } from '../../../../../services/acg/acg-api-v2';

export const MemberSearchResults: React.FC<MemberSearchResultsProps> = props => {
  // STATE
  const { reset, memberDirectoryGoToStep, acgRefData, setValue } = props;
  const [isLoading, setIsLoading] = useBoolean();
  const allMembers = useAppSelector(getMembers);
  const query = useAppSelector(getSearchQuery);
  const filters = useAppSelector(getFilters);
  const [filteredSearch, setFilteredSearch] = useState<{}>();

  // HOOKS
  const [searchMemberDirectory] = useLazySearchAcgMemberDirectoryV2Query();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Pagination logic
  const pageNumber = useAppSelector(getPageNumber);
  const showingRange = pageNumber * 10 - 9;
  const [pages, setPages] = useState<number>(0);
  const totalResults = useAppSelector(getTotalResults);
  const [paginationSearch, setPageSearch] = useState<{}>();
  const paginationBreakPoint = useBreakpointValue({ base: 0, md: 1 });

  const { items } = usePagination({
    count: pages,
    defaultPage: pageNumber,
    onChange: (e: any, value: number) => handlePageClick(value),
    siblingCount: paginationBreakPoint,
    boundaryCount: 1,
  });

  const handleFilterClick = async (filter: string) => {
    try {
      if (filters.length > 1) {
        const newSearch = newSearchProps({ data: query, filter, refData: acgRefData, setValue });
        dispatch(setPageNumber(1));
        dispatch(removeFilter(filter));
        setFilteredSearch(newSearch);
        setIsLoading.on();
        await searchMemberDirectory(
          {
            searchParams: newSearch,
          },
          true,
        )
          .unwrap()
          .then(result => {
            dispatch(updateSearchFilters({ data: newSearch, refData: acgRefData }));
            dispatch(updateMembers(result));
            dispatch(memberSearchQuery(newSearch));
            setIsLoading.off();
          })
          .catch(err => {
            if (err) throw new Error();
          });
      } else {
        reset?.();
        dispatch(clearSearch([]));
        dispatch(memberSearchQuery({}));
      }
    } catch (err) {
      console.log('Error in FILTERCLICK: ', err);
    }
  };

  // NOTE: each page click fires a new request, results are cached
  const handlePageClick = async (pgNumber: number) => {
    try {
      setIsLoading.on();
      if (filteredSearch) {
        dispatch(setPageNumber(pgNumber));
        await searchMemberDirectory(
          {
            searchParams: { ...filteredSearch, page: pgNumber - 1 },
          },
          true,
        )
          .unwrap()
          .then(data => {
            setIsLoading.off();
            dispatch(updateMembers(data));
          })
          .catch(err => {
            if (err) throw new Error();
          });
      } else if (paginationSearch) {
        dispatch(setPageNumber(pgNumber));
        await searchMemberDirectory(
          {
            searchParams: { ...paginationSearch, page: pgNumber - 1 },
          },
          true,
        )
          .unwrap()
          .then(data => {
            setIsLoading.off();
            dispatch(updateMembers(data));
          })
          .catch(err => {
            if (err) throw new Error();
          });
      }
    } catch (err) {
      setIsLoading.off();
      console.log('Error in PAGECLICK: ', err);
    }
  };

  useEffect(() => {
    if (totalResults > 10) {
      setPages(Math.ceil(totalResults / 10));
    }
    setPageSearch(query);
  }, [query, totalResults]);

  return (
    <Center flexDirection="column" bg={['transparent', 'white']} pb={12} maxW={908} width="full">
      <Box width="full" bg="white">
        {filters?.length ? (
          <Box>
            <Heading size="hs-xl" pb={6} textAlign={['center', 'center', 'center', 'start']}>
              Search Results
            </Heading>
            <Flex
              direction={['column', 'column', 'column', 'row']}
              align={['center', 'center', 'center', 'flex-start']}
              justify="space-between"
            >
              <Stack
                direction="column"
                maxW={['50%', '50%', 'full']}
                align={['center', 'center', 'center', 'flex-start']}
                pr={[0, 0, 6]}
              >
                <Text textStyle="sm">Selected Filters</Text>
                <Wrap spacing={2} justify={['center', 'center', 'center', 'flex-start']}>
                  {filters.map((filter: string, index: number) => {
                    return (
                      <WrapItem key={index}>
                        <Center key={index}>
                          <Tag variant="solid" size="sm" key={index}>
                            <TagLabel>{filter}</TagLabel>
                            <TagCloseButton onClick={() => handleFilterClick(filter)} key={index} />
                          </Tag>
                        </Center>
                      </WrapItem>
                    );
                  })}
                </Wrap>
              </Stack>
              <Stack pt={[5, 5, 5, 0]}>
                <Button
                  variant="link"
                  size="sm"
                  alignItems="baseline"
                  fontWeight="semibold"
                  minW="11rem"
                  onClick={() => {
                    reset?.();
                    dispatch(clearSearch([]));
                    dispatch(memberSearchQuery({}));
                    dispatch(setPageNumber(1));
                  }}
                >
                  CLEAR FILTERS
                </Button>
              </Stack>
            </Flex>
          </Box>
        ) : null}
        {allMembers?.length && filters?.length ? (
          <>
            <Stack width="full">
              <VStack mt={6}>
                {!isLoading && (
                  <Box as="span" fontSize="xs" alignSelf={['center', 'center', 'flex-start']}>
                    {`Showing ${showingRange} - ${
                      allMembers?.length + showingRange - 1
                    } of ${totalResults}`}
                  </Box>
                )}
                <Pagination variant="outline" size="sm" width="full">
                  {isLoading ? (
                    <AppSpinner
                      containerStyles={{ mx: 'auto', mb: 12, mt: 10 }}
                      description={t(`acg.memberSearch.search.loadingTextForUpdating`)}
                    />
                  ) : (
                    <Stack align="center" divider={<StackDivider spacing={6} />}>
                      {allMembers?.map((memberProps: any) => {
                        return (
                          <MemberTile
                            {...{ ...memberProps, memberDirectoryGoToStep }}
                            key={memberProps.recordKey}
                          />
                        );
                      })}
                      {totalResults > 10 ? (
                        <PaginationContainer justifyContent="center" rounded="0" py={[4]}>
                          {items.map((item, index) => (
                            <PaginationItem {...item} rounded="0" key={index} />
                          ))}
                        </PaginationContainer>
                      ) : null}
                    </Stack>
                  )}
                </Pagination>
              </VStack>
            </Stack>
          </>
        ) : null}
        {filters?.length && !allMembers?.length ? (
          isLoading ? (
            <AppSpinner
              containerStyles={{ mx: 'auto', mt: 10, p: 10 }}
              description={t(`acg.memberSearch.search.loadingTextForUpdating`)}
            />
          ) : (
            <NoResults />
          )
        ) : null}
      </Box>
    </Center>
  );
};
