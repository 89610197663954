import React from 'react';
import _findKey from 'lodash/findKey';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { HStack, Image, Text, VStack } from '@companydotcom/potion';

const { REACT_APP_IMG_URL } = process.env;

export const DirectoryStatus = (props: any) => {
  const { report } = props;
  const { t } = useTranslation();
  let status = '';
  let errorStatus = '';
  let color = '';
  let iconName = '';
  let toolTip = '';

  const getListingScore = () => {
    const scoreObj = {
      phoneStatus: { counter: 0 },
      addressStatus: { counter: 0 },
      nameStatus: { counter: 0 },
    };

    if (report?.phoneStatus !== 'pass') {
      scoreObj.phoneStatus.counter += 1;
    }
    if (report?.addressStatus !== 'pass') {
      scoreObj.addressStatus.counter += 1;
    }
    if (report?.nameStatus !== 'pass') {
      scoreObj.nameStatus.counter += 1;
    }

    const getSingleItem = () => {
      const singleItem = _findKey(scoreObj, (a: any) => {
        return a.counter === 1;
      });

      switch (singleItem) {
        case 'phoneStatus':
          return 'components.directory.phoneStatus';
        case 'addressStatus':
          return 'components.directory.addressStatus';
        case 'nameStatus':
          return 'components.directory.nameStatus';
        default:
          return '';
      }
    };

    const setErrorAndTooltip = () => {
      errorStatus = (function parseErrorStatus() {
        const raw = _get(report, ['statusDetails', 0, 'message']);
        if (raw && raw.length > 20) {
          toolTip = raw;
          return raw.substring(0, 19).concat('...');
        }
        return raw || '';
      })();
    };

    const values = Object.values(scoreObj);
    const sum = values.reduce((acc, obj) => {
      return acc + obj.counter;
    }, 0);

    switch (report?.syncStatus) {
      case 'fail':
        status = 'components.directory.fail';
        color = 'inherit';
        iconName = 'missing_x_icon';
        break;
      case 'pass':
        if (sum >= 2) {
          status = 'components.directory.pass2';
          errorStatus = 'components.directory.multipleErrors';
          color = 'red.500';
          iconName = 'error_exclamation_icon';
        } else if (sum === 1) {
          status = 'components.directory.pass1';
          errorStatus = getSingleItem();
          color = 'red.500';
          iconName = 'error_exclamation_icon';
        } else {
          status = 'components.directory.pass';
          color = 'text_success';
          iconName = 'success_check_icon';
        }
        break;
      case 'live':
        status = 'components.directory.live';
        color = 'text_success';
        iconName = 'success_check_icon';
        break;
      case 'inProgress':
        status = 'components.directory.inProgress';
        errorStatus = 'components.directory.noActionNeeded';
        color = 'orange.500';
        iconName = 'progress_check_icon';
        break;
      case 'actionNeeded':
        status = 'components.directory.actionNeeded';
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'void' is not assignable to type 'string'.
        errorStatus = setErrorAndTooltip();
        color = 'red.500';
        iconName = 'error_exclamation_icon';
        break;
      case 'unavailable':
      default:
        status = 'components.directory.unavailable';
        color = 'orange.500';
        iconName = 'missing_x_icon';
    }
  };

  getListingScore();

  return (
    <HStack width={['80%', null, '50%']} spacing={0} pl={['48px', null, 0]}>
      <Image
        boxSize={[4, null, 7]}
        mr={[2, null, 0]}
        ml={[0, null, 2]}
        src={`${REACT_APP_IMG_URL}/icons/status_icons/${iconName}.svg`}
        alt={iconName}
        order={[2, null, 1]}
      />
      <VStack spacing={0} flexDirection="column" alignItems="flex-start">
        <Text color={color} textStyle={['md', null, 'xl']} fontWeight="medium">
          {/* @ts-ignore */}
          {t(status)}
        </Text>
        {errorStatus !== '' ? (
          <Text textStyle="md" data-tip={toolTip} color={color}>
            {/* @ts-ignore */}
            {t(errorStatus)}
          </Text>
        ) : null}
      </VStack>
    </HStack>
  );
};
