import React, { useState, useCallback, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Cell, ResponsiveContainer } from 'recharts';
import _get from 'lodash/get';
import {
  Stack,
  Box,
  Flex,
  Text,
  Image,
  Button,
  Center,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
  HStack,
  useBreakpointValue,
  useDisclosure,
} from '@companydotcom/potion';
import { isEmpty } from 'lodash';
import { useAssets, useSource, useMitt } from '@companydotcom/providers';
import { useEventData } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { BLRData } from '../types';
import { Directory } from './directory';
import { DirectoryStatus } from './directory-status';
// import { getProductSSO } from '../../../services/user/old-api/user-svc';
import { reportButtonHandler } from '../utils';
import { TileComponentProps } from '../../tile-components/types';
import { barChart, getMetadata, getDatasetKey } from '../../../utils';
import { useLazyGetProductSsoQuery } from '../../../services/user/user-api';

export interface BusinessListingsReportProps extends TileComponentProps {
  targetTileState?: any;
  userId?: string;
  toggleModal?: () => void;
}

export const BusinessListingsReport: React.FC<BusinessListingsReportProps> = props => {
  const { targetTileState, account, eventData, userId, toggleModal, params } = props;
  const [getProductSSO] = useLazyGetProductSsoQuery();
  const { isOpen, onToggle } = useDisclosure();
  const source = useSource();
  const { emitter } = useMitt();
  const { t } = useTranslation();
  const assets = useAssets();
  const { data: eData } = useEventData<BLRData>(eventData);
  const [directories, setDirectories] = useState<any[]>([]);
  const mobileHeader = useBreakpointValue({
    base: t('components.businessListingsReport.listingResults'),
    md: t('components.businessListingsReport.locationOfListing'),
  });

  const generateData = useCallback(() => {
    return _get(props, getDatasetKey(props));
  }, [props]);

  const generateMetadata = useCallback(() => {
    return getMetadata(props);
  }, [props]);

  const [data, setData] = useState(generateData());
  const [metadata, setMetadata] = useState(generateMetadata());

  useEffect(() => {
    setData(generateData());
    setMetadata(generateMetadata());
  }, [generateData, generateMetadata]);

  useEffect(() => {
    const relevantSlugs = {
      directory_yext: ['foursquare', 'yelp', 'facebook', 'googleplaces'],
    };
    if (eData?.directories && !isEmpty(eData.directories)) {
      relevantSlugs[targetTileState?.slug].forEach((slug: string) => {
        setDirectories([
          eData?.directories?.find?.(item => item?.slug === slug),
          ...eData?.directories?.filter(item => item?.slug !== slug),
        ]);

        if (!eData?.directories?.[0]) {
          eData?.directories.shift();
        }
      });
    }
  }, [eData?.directories, targetTileState?.slug]);

  const samlSso = params ? params?.find?.(param => param?.key === 'href')?.value : undefined;

  return (
    <Center
      className="business-listings-report"
      flexDirection="column"
      textAlign="center"
      pt={5}
      pb={20}
    >
      <Image
        boxSize={['56px', null, '72px']}
        src={`${assets?.tile_icon}business-listings-report.svg`}
      />
      <Text px={4} mt={2} textTransform="uppercase" fontWeight="medium" color="secondary.600">
        {t('components.businessListingsReport.heading')}
      </Text>
      <Box px={4} mt={6} maxW="container.sm">
        <Heading size="hs-2xl">
          {t('components.businessListingsReport.reportFor', {
            businessName: account?.businessPrimary?.name,
            interpolation: { escapeValue: false },
          })}
        </Heading>
        <Text textStyle="lg" mt={2}>
          {eData?.type === 'scan'
            ? t('components.businessListingsReport.headingMissing', {
                count: eData?.directories?.length,
              })
            : t('components.businessListingsReport.headingView')}
        </Text>
      </Box>

      <Flex
        flexDirection={['column', null, 'row']}
        justifyContent="center"
        alignItems="center"
        my={[8, null, 12]}
        mx="auto"
      >
        <Box width="full" px={4}>
          <ListingsLinearProgress chartData={barChart(data, metadata)} />
        </Box>
      </Flex>
      <VStack width="full" textAlign="left" spacing={7}>
        <Box bg="gray.100" width="full">
          <HStack width="full" p={4} maxW="container.md" mx="auto" justifyContent="center">
            <Text width="50%" textStyle={['md', null, 'xl']} fontWeight="medium">
              {mobileHeader}
            </Text>
            <Text
              width="50%"
              textStyle="xl"
              fontWeight="medium"
              display={['none', null, 'inherit']}
            >
              {t('components.businessListingsReport.status')}
            </Text>
          </HStack>
        </Box>

        {directories?.slice(0, 4)?.map(report => (
          <Stack
            direction={['column', null, 'row']}
            px={4}
            key={report?.slug}
            maxW="container.md"
            width="full"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <Directory report={report} />
            <DirectoryStatus report={report} />
          </Stack>
        ))}
        {isOpen &&
          directories?.slice(4, eData?.directories?.length)?.map(report => (
            <Stack
              direction={['column', null, 'row']}
              px={4}
              key={report?.slug}
              maxW="container.md"
              width="full"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
              <Directory report={report} />
              <DirectoryStatus report={report} />
            </Stack>
          ))}
      </VStack>

      <Button px={4} variant="link" onClick={onToggle} mt={12}>
        {isOpen
          ? t('components.businessListingsReport.seeLessResults')
          : t('components.businessListingsReport.seeMoreResults')}
      </Button>

      <Box mt={8} px={4}>
        <Button
          size="lg"
          onClick={() =>
            reportButtonHandler(
              targetTileState,
              samlSso,
              source,
              toggleModal,
              emitter,
              getProductSSO,
              userId,
            )
          }
        >
          {t('components.businessListingsReport.improveYourScoreNow')}
        </Button>
      </Box>
    </Center>
  );
};

export interface MainScore {
  label: string;
  value: number;
}

export interface Score {
  text: string;
  value: number;
}

export interface LinearProgressEventData {
  mainScore?: MainScore;
  scores?: Score[];
}

export interface LinearProgressProps extends TileComponentProps {
  chartData: LinearProgressEventData;
}

const ListingsLinearProgress: React.FC<LinearProgressProps> = props => {
  const {
    chartData: { scores, mainScore },
  } = props;
  const getColor = (p: number) => {
    if (p >= 90) {
      return 'green';
    }
    if (p >= 60) {
      return 'orange';
    }
    if (p < 60) {
      return 'red';
    }
    return 'gray.500';
  };

  return (
    <Stack
      direction={['column', null, 'row']}
      className="tile__chart-linear-progress"
      width="full"
      minW={[270, null, 600]}
    >
      <Stat minW={[150, null, 220]}>
        <VStack>
          <StatNumber
            lineHeight={1}
            fontSize={['4xl', null, '6xl']}
            color={
              mainScore?.value || mainScore?.value === 0 ? getColor(mainScore?.value) : 'gray.500'
            }
          >
            {Number.isNaN(mainScore?.value) || mainScore?.value === 0 || !mainScore?.value
              ? `0%`
              : `${Math.trunc(mainScore?.value)}%`}
          </StatNumber>
          <StatLabel fontSize="16px" mt={0}>
            {mainScore?.label}
          </StatLabel>
        </VStack>
      </Stat>
      <ResponsiveContainer width="100%" height={90}>
        <BarChart
          data={scores}
          layout="vertical"
          maxBarSize={100}
          margin={{
            top: 0,
            right: 40,
            left: 4,
            bottom: -32,
          }}
        >
          <XAxis
            type="number"
            axisLine={false}
            domain={[0, 100]}
            min={0}
            max={100}
            strokeWidth={0}
            dataKey="value"
            tick={false}
          />
          <YAxis
            width={104}
            type="category"
            dataKey="text"
            textAnchor="end"
            interval={0}
            minTickGap={0}
            axisLine={false}
            strokeWidth={0}
            style={{
              fontSize: '13px',
            }}
          />
          <Bar
            barSize={8}
            dataKey="value"
            strokeWidth={0}
            radius={6}
            background={{ fill: '#d8d8d8', radius: 6 }}
          >
            {scores?.map((entry: any, index: number) => (
              <Cell key={index} fill={Number.isNaN(entry.value) ? 'gray' : getColor(entry.value)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
};
