import React from 'react';
import {
  Button,
  HStack,
  Link,
  Text,
  Stack,
  Tooltip,
  Badge,
  Box,
  VStack,
  useBreakpointValue,
  SkeletonText,
} from '@companydotcom/potion';
import {
  faEnvelope,
  faBuilding,
  faMobile,
  faGlobe,
  faHome,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
} from '../../../../services/acg/acg-api-v2';

interface ContactInformationProps {
  onOpen: () => void;
  setFormSlug?: (formSlug: string, data?: any) => void;
}

export const ContactInformation = ({
  setFormSlug,
}: // onOpen
ContactInformationProps) => {
  const args = useGetAcgQueryArgs();
  const { data: globalUser } = useGetGlobalUserQuery();
  const { t } = useTranslation();
  const { data: acgUserProfileData, isLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  return (
    <Box
      bg={['white', 'inherit']}
      p={[4, 0]}
      borderRadius={4}
      boxShadow={['md', 'none']}
      position="relative"
    >
      <Stack direction={['column', 'row']} alignItems={['flex-start']} mb={4} textAlign="left">
        <Text textStyle="lg" fontWeight="bold">
          {t('acg.myProfile.contactInformation.contactInformation')}
        </Text>
      </Stack>
      {isLoading ? (
        <SkeletonText noOfLines={6} spacing="4" width="full" />
      ) : (
        <VStack
          className="contact_information"
          spacing={4}
          align="flex-start"
          width={['full', 'auto']}
          p={['0', '2']}
          pr={['6', '9']}
          position={['initial', 'relative']}
          _hover={{ bg: ['none', 'gray.200'], '.edit-button': { display: ['inline-flex'] } }}
        >
          {/* EMAIL */}
          <VStack align="flex-start" spacing="2" width={['full', 'auto']}>
            {acgUserProfileData?.emailAddress && (
              <ContactItem
                isLink
                isPrimary
                href={`mailto:${acgUserProfileData?.emailAddress}`}
                icon="primary-email"
              >
                {acgUserProfileData?.emailAddress}
              </ContactItem>
            )}
            {acgUserProfileData?.emailAddressSecondary && (
              <ContactItem
                isLink
                isPrimary={false}
                href={`mailto:${acgUserProfileData?.emailAddressSecondary}`}
                icon="secondary-email"
              >
                {acgUserProfileData?.emailAddressSecondary}
              </ContactItem>
            )}
          </VStack>

          {/* PHONE */}
          <VStack
            flexDirection={
              acgUserProfileData?.preferredPhoneType === 'mobilePhone' ? 'column-reverse' : 'column'
            }
            align="flex-start"
            spacing="2"
            width={['full', 'auto']}
          >
            {acgUserProfileData?.workPhone && (
              <ContactItem
                isPrimary={acgUserProfileData?.preferredPhoneType === 'workPhone'}
                icon="work-phone"
              >
                {acgUserProfileData?.workPhone}
                {acgUserProfileData?.workPhoneExtension
                  ? ` ext ${acgUserProfileData?.workPhoneExtension}`
                  : undefined}
              </ContactItem>
            )}
            {acgUserProfileData?.mobilePhone && (
              <ContactItem
                isPrimary={acgUserProfileData?.preferredPhoneType === 'mobilePhone'}
                icon="mobile-phone"
              >
                {acgUserProfileData?.mobilePhone}
                {acgUserProfileData?.mobilePhoneExtension
                  ? ` ext ${acgUserProfileData?.mobilePhoneExtension}`
                  : undefined}
              </ContactItem>
            )}
          </VStack>

          {/* WEBSITE */}
          {acgUserProfileData?.website && (
            <VStack align="flex-start" spacing="2" width={['full', 'auto']}>
              <ContactItem
                isLink
                href={acgUserProfileData?.website}
                isPrimary={false}
                icon="website"
              >
                {acgUserProfileData?.website}
              </ContactItem>
            </VStack>
          )}

          {/* ADDRESSES */}
          <VStack
            flexDirection={
              acgUserProfileData?.preferredAddressType === 'homeAddress'
                ? 'column-reverse'
                : 'column'
            }
            align="flex-start"
            spacing="2"
            width={['full', 'auto']}
          >
            {typeof acgUserProfileData?.workAddress !== 'string' &&
              acgUserProfileData?.workAddress?.addressLine1 && (
                <ContactItem
                  isPrimary={acgUserProfileData?.preferredAddressType === 'workAddress'}
                  icon="work-address"
                >
                  {`${acgUserProfileData?.workAddress?.addressLine1 ?? ''} ${
                    acgUserProfileData?.workAddress?.addressLine2 ?? ''
                  } ${acgUserProfileData?.workAddress?.city}, ${
                    acgUserProfileData?.workAddress?.state ?? ''
                  } ${acgUserProfileData?.workAddress?.postalCode ?? ''}, ${
                    acgUserProfileData?.workAddress?.country ?? ''
                  }`}
                </ContactItem>
              )}
            {typeof acgUserProfileData?.homeAddress !== 'string' &&
              acgUserProfileData?.homeAddress?.addressLine1 && (
                <ContactItem
                  isPrimary={acgUserProfileData?.preferredAddressType === 'homeAddress'}
                  icon="home-address"
                >
                  {`${acgUserProfileData?.homeAddress?.addressLine1 ?? ''} ${
                    acgUserProfileData?.homeAddress?.addressLine2 ?? ''
                  } ${acgUserProfileData?.homeAddress?.city}, ${
                    acgUserProfileData?.homeAddress?.state ?? ''
                  } ${acgUserProfileData?.homeAddress?.postalCode ?? ''}, ${
                    acgUserProfileData?.homeAddress?.country ?? ''
                  }`}
                </ContactItem>
              )}
          </VStack>
          <Button
            className="edit-button"
            display={['inline-flex', 'none']}
            position="absolute"
            right="2"
            mt="0 !important"
            top="2"
            variant="link"
            minW="auto"
            onClick={() => setFormSlug?.('add-new-contact-info')}
          >
            <FontAwesomeIcon icon={faPencil} boxSize={['4', '6']} />
          </Button>
        </VStack>
      )}
    </Box>
  );
};

const iconMap = {
  'primary-email': faEnvelope,
  'secondary-email': faEnvelope,
  'work-phone': faBuilding,
  'mobile-phone': faMobile,
  website: faGlobe,
  'work-address': faBuilding,
  'home-address': faHome,
};

const tooltipMap = {
  'primary-email': 'Primary Email',
  'secondary-email': 'Secondary Email',
  'work-phone': 'Work Phone',
  'mobile-phone': 'Mobile Phone',
  website: 'Website',
  'work-address': 'Work Address',
  'home-address': 'Home Address',
};

interface ContactItemProps {
  children: React.ReactNode;
  isPrimary: boolean;
  href?: string;
  isLink?: boolean;
  icon?: keyof typeof iconMap;
}

const ContactItem = ({ children, isLink, isPrimary, href, icon }: ContactItemProps) => {
  const isMobile = useBreakpointValue({ base: true, sm: false });
  return (
    <HStack spacing="2" textAlign="left" width="full" align="flex-start">
      {icon && (
        <Tooltip
          isDisabled={isMobile}
          hasArrow
          shouldWrapChildren
          label={tooltipMap[icon]}
          bg="gray.700"
          color="white"
          placement="left"
        >
          <FontAwesomeIcon icon={iconMap[icon]} />
        </Tooltip>
      )}
      {isLink ? (
        <Link
          display={['inline-block', 'inline']}
          isExternal
          wordBreak="break-all"
          fontSize="sm"
          href={href}
        >
          {children}
        </Link>
      ) : (
        <Text wordBreak="break-word" display={['inline-block', 'inline']} fontSize="sm">
          {children}
        </Text>
      )}
      {isPrimary && (
        <Badge ml={[1]} colorScheme="green" variant="solid" display="inline">
          PRIMARY
        </Badge>
      )}
    </HStack>
  );
};
