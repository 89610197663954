import {
  Maybe,
  Query,
  Mutation,
  AcgRssFeedResponse,
  GetAcgRecommendedEventsResponse,
  GetAcgMemberCommitteesResponse,
  GetAcgReferenceDataResponse,
  GetAllAcgChaptersAndPackagesResponse,
  GetAcgInvoicesByUserResponse,
  GetAcgMemberDirectoryResponse,
  AcgUserProfile,
  UpdateAcgUserInput,
  UpdateAcgMembershipInput,
  StoreRhythmTokenAndContactIdInput,
  StoreRhythmTokenAndContactIdResponse,
  AcgMemberDirectorySearchInput,
} from '@companydotcom/types';
import { useAuth } from '../../providers';
import { emptySplitApi } from '../../store/empty-split-api';
import { queries } from './graphql/acg-queries';
import { mutations } from './graphql/acg-mutations';

export const AcgApiV2 = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getMembershipsV2: builder.query<
      Query['getMemberships'],
      { userId: string; accessToken: string; contactId: string; stateCurrent?: string }
    >({
      providesTags: ['AcgMembership'],
      keepUnusedDataFor: 60,
      query: variables => {
        return {
          method: 'query',
          document: queries.GET_MEMBERSHIPS,
          variables,
        };
      },
      transformResponse: (rawResult: { data: { getMemberships: Query['getMemberships'] } }) => {
        return rawResult?.data?.getMemberships;
      },
    }),
    getMemberCommitteesV2: builder.query<
      GetAcgMemberCommitteesResponse,
      { accessToken: string; contactId: string }
    >({
      query: variables => {
        return {
          method: 'query',
          document: queries.GET_MEMBER_COMMITTEES,
          variables,
        };
      },
      transformResponse: (rawResult: {
        data: { getMemberCommittees: GetAcgMemberCommitteesResponse };
      }) => {
        return rawResult?.data?.getMemberCommittees;
      },
    }),
    getMagazinesFeedV2: builder.query<AcgRssFeedResponse, {}>({
      query: () => ({
        method: 'query',
        document: queries.GET_MAGAZINES_FEED,
      }),
      transformResponse: (rawResult: { data: { getMagazinesFeed: AcgRssFeedResponse } }) => {
        return rawResult?.data?.getMagazinesFeed;
      },
    }),
    getGrowthTvFeedV2: builder.query<AcgRssFeedResponse, {}>({
      query: () => ({
        method: 'query',
        document: queries.GET_GROWTH_TV_FEED,
      }),
      transformResponse: (rawResult: { data: { getGrowthTvFeed: AcgRssFeedResponse } }) => {
        return rawResult?.data?.getGrowthTvFeed;
      },
    }),
    getNewsTrendsFeedV2: builder.query<AcgRssFeedResponse, {}>({
      query: () => ({
        method: 'query',
        document: queries.GET_NEWS_TRENDS_FEED,
      }),
      transformResponse: (rawResult: { data: { getNewsTrendsFeed: AcgRssFeedResponse } }) => {
        return rawResult?.data?.getNewsTrendsFeed;
      },
    }),
    getRecommendedEventsV2: builder.query<
      GetAcgRecommendedEventsResponse,
      { residentState: string; chapterNames?: [] | Maybe<string | undefined>[] }
    >({
      query: variables => ({
        method: 'query',
        document: queries.GET_RECOMMENDED_EVENTS,
        variables,
      }),
      transformResponse: (rawResult: {
        data: { getRecommendedEvents: GetAcgRecommendedEventsResponse };
      }) => {
        return rawResult?.data?.getRecommendedEvents;
      },
    }),
    getUserPurchasedEventsV2: builder.query<
      Query['getUserPurchasedEvents'],
      { accessToken: string; contactId: string }
    >({
      providesTags: ['AcgPurchasedEvents'],
      query: variables => ({
        method: 'query',
        document: queries.GET_USER_PURCHASED_EVENTS,
        variables,
      }),
      transformResponse: (rawResult: {
        data: { getUserPurchasedEvents: Query['getUserPurchasedEvents'] };
      }) => {
        return rawResult?.data?.getUserPurchasedEvents?.map(event => ({
          ...event,
          eventLocation: event?.eventLocation ? event.eventLocation.split(',').join(', ') : '',
        }));
      },
    }),
    getAcgReferenceDataV2: builder.query<
      GetAcgReferenceDataResponse,
      { accessToken: string; referenceData: [] | Maybe<string | undefined>[] }
    >({
      providesTags: ['AcgReferenceData'],
      keepUnusedDataFor: 180,
      query: variables => ({
        method: 'query',
        document: queries.GET_ACG_REFERENCE_DATA,
        variables,
      }),
      transformResponse: (rawResult: {
        data: { getAcgReferenceData: GetAcgReferenceDataResponse };
      }) => {
        if (rawResult.data.getAcgReferenceData.eventsReferenceData) {
          rawResult.data.getAcgReferenceData.eventsReferenceData = JSON.parse(
            rawResult.data.getAcgReferenceData.eventsReferenceData,
          );
        }

        if (rawResult.data.getAcgReferenceData.membershipReferenceData) {
          rawResult.data.getAcgReferenceData.membershipReferenceData = JSON.parse(
            rawResult.data.getAcgReferenceData.membershipReferenceData,
          );
        }

        return rawResult?.data?.getAcgReferenceData;
      },
    }),
    getAllChaptersAndPackagesV2: builder.query<[GetAllAcgChaptersAndPackagesResponse], {}>({
      providesTags: ['AcgChapterData'],
      keepUnusedDataFor: 180,
      query: () => ({
        method: 'query',
        document: queries.GET_ALL_CHAPTERS_AND_PACKAGES,
      }),
      transformResponse: (rawResult: {
        data: { getAllChaptersAndPackages: [GetAllAcgChaptersAndPackagesResponse] };
      }) => {
        return rawResult?.data?.getAllChaptersAndPackages;
      },
    }),
    getInvoicesByUserV2: builder.query<
      GetAcgInvoicesByUserResponse,
      { accessToken: string; contactId: string }
    >({
      providesTags: ['AcgInvoices'],
      query: variables => ({
        method: 'query',
        document: queries.GET_INVOICES_BY_USER,
        variables,
      }),
      transformResponse: (rawResult: {
        data: { getInvoicesByUser: GetAcgInvoicesByUserResponse };
      }) => {
        if (!rawResult?.data?.getInvoicesByUser?.invoices) {
          rawResult.data.getInvoicesByUser.invoices = [];
        }

        return rawResult?.data?.getInvoicesByUser;
      },
    }),
    getAcgUserProfileV2: builder.query<
      AcgUserProfile,
      {
        accessToken: string;
        contactId: string;
        userId: string;
        accountId?: Maybe<string>;
        email?: Maybe<string>;
      }
    >({
      providesTags: ['AcgUser'],
      query: variables => ({
        method: 'query',
        document: queries.GET_ACG_USER_PROFILE,
        variables,
      }),
      transformResponse: (rawResult: { data: { getAcgUserProfile: AcgUserProfile } }) => {
        if (rawResult?.data?.getAcgUserProfile?.workAddress?.__typename) {
          delete rawResult?.data?.getAcgUserProfile?.workAddress?.__typename;
        }

        if (rawResult?.data?.getAcgUserProfile?.homeAddress?.__typename) {
          delete rawResult?.data?.getAcgUserProfile?.homeAddress?.__typename;
        }

        return rawResult?.data?.getAcgUserProfile;
      },
    }),
    searchAcgMemberDirectoryV2: builder.query<
      GetAcgMemberDirectoryResponse,
      {
        searchParams: AcgMemberDirectorySearchInput;
      }
    >({
      keepUnusedDataFor: 60,
      query: variables => ({
        method: 'query',
        document: queries.GET_ACG_MEMBER_DIRECTORY,
        variables,
      }),
      transformResponse: (rawResult: {
        data: { getMemberDirectory: GetAcgMemberDirectoryResponse };
      }) => {
        return rawResult?.data?.getMemberDirectory;
      },
    }),
    updateAcgUserV2: builder.mutation<Mutation['updateAcgUserProfile'], UpdateAcgUserInput>({
      invalidatesTags: ['AcgUser'],
      query: variables => ({
        method: 'mutation',
        document: mutations.UPDATE_ACG_USER_PROFILE_MUTATION,
        variables: { input: variables },
      }),
      transformResponse: (rawResult: { data: { updateAcgUserProfile: Maybe<AcgUserProfile> } }) => {
        return rawResult?.data?.updateAcgUserProfile;
      },
      // async onQueryStarted({ user, payload }, { dispatch, queryFulfilled }) {
      //   const patchResult = dispatch(
      //     AcgApiV2.util.updateQueryData('getAcgUserProfileV2', { user }, draft => {
      //       for (const key in payload) {
      //         draft[key] = payload[key];
      //       }
      //     }),
      //   );
      //   try {
      //     await queryFulfilled;
      //   } catch {
      //     patchResult.undo();
      //   }
      // },
    }),
    storeRhythmTokenAndContactIdV2: builder.mutation<
      Mutation['storeRhythmTokenAndContactId'],
      StoreRhythmTokenAndContactIdInput
    >({
      invalidatesTags: [
        'AcgMembership',
        'AcgUser',
        'AcgPurchasedEvents',
        'AcgInvoices',
        'AcgRegisteredEvent',
      ],
      query: variables => ({
        method: 'mutation',
        document: mutations.STORE_RHYTHM_TOKEN_AND_CONTACT_MUTATION,
        variables: { input: variables },
      }),
      transformResponse: (rawResult: {
        data: { storeRhythmTokenAndContactId: Maybe<StoreRhythmTokenAndContactIdResponse> };
      }) => {
        return rawResult?.data?.storeRhythmTokenAndContactId;
      },
    }),
    updateMembershipV2: builder.mutation<Mutation['updateMembership'], UpdateAcgMembershipInput>({
      invalidatesTags: ['AcgMembership'],
      query: variables => ({
        method: 'mutation',
        document: mutations.UPDATE_ACG_MEMBERSHIP,
        variables: { input: variables },
      }),
      transformResponse: (rawResult: {
        data: { updateMembership: Mutation['updateMembership'] };
      }) => {
        return rawResult?.data?.updateMembership;
      },
    }),
  }),
});

export const {
  usePrefetch,
  // Queries
  useGetMembershipsV2Query,
  useGetMemberCommitteesV2Query,
  useGetNewsTrendsFeedV2Query,
  useGetGrowthTvFeedV2Query,
  useGetMagazinesFeedV2Query,
  useGetRecommendedEventsV2Query,
  useGetUserPurchasedEventsV2Query,
  useGetAcgReferenceDataV2Query,
  useGetAllChaptersAndPackagesV2Query,
  useGetInvoicesByUserV2Query,
  useGetAcgUserProfileV2Query,
  useLazyGetAcgUserProfileV2Query,
  useLazySearchAcgMemberDirectoryV2Query,
  // Mutations
  useUpdateAcgUserV2Mutation,
  useStoreRhythmTokenAndContactIdV2Mutation,
  useUpdateMembershipV2Mutation,
} = AcgApiV2;

interface CommonArgs {
  userId: string;
  accessToken: string;
  contactId: string;
  email?: string;
  source?: string[];
}

const optionalArgs = ['email', 'source'] as const;

export const useGetAcgQueryArgs = (opts?: typeof optionalArgs) => {
  const auth = useAuth();

  const commonArgs: CommonArgs = {
    userId: auth?.user?.userId || '',
    accessToken: auth?.user?.token || '',
    contactId: auth?.user?.['http://rhythmsoftware.com/contact_id'] || '',
  };

  if (opts?.some(x => x === 'email')) {
    commonArgs.email = auth?.user?.email;
  }

  if (opts?.some(x => x === 'source')) {
    commonArgs.source = auth?.user?.['https://company.com/user_authorization']?.groups;
  }

  return commonArgs;
};
