import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@companydotcom/potion';
import type { TileComponentProps } from '../../types';

export interface TileDateProps extends TileComponentProps {}

export const TileDate: React.FC<TileDateProps> = props => {
  const [date, setDate] = useState<string | undefined>();
  const { t, i18n } = useTranslation();

  const { eventData } = props;

  useEffect(() => {
    setDate(
      eventData && !eventData.fetching
        ? new Date(eventData?.body?.dateCreated).toLocaleDateString(i18n.language ?? 'en-US')
        : undefined,
    );
  }, [eventData, i18n.language]);

  return (
    <Text as="span" textStyle="xs" opacity={0.4}>
      {date ?? t('common.misc.loading...')}
    </Text>
  );
};
