var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { __DEV__, NumberInput, FormControl, FormLabel, NumberInputField as CNumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, FormHelperText, } from '@companydotcom/potion';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';
import { TranslationErrorMessage } from './translation-error-message';
export const NumberInputField = props => {
    const { errors, hideNumberStepper, helperText, name, label, max, min, formControlStyles, inputStyles, control, precision } = props, rest = __rest(props, ["errors", "hideNumberStepper", "helperText", "name", "label", "max", "min", "formControlStyles", "inputStyles", "control", "precision"]);
    return (_jsx(Controller, { control: control, name: name, render: (_a) => {
            var _b;
            var _c = _a.field, { ref } = _c, restField = __rest(_c, ["ref"]);
            return (_jsxs(FormControl, Object.assign({ id: name, isInvalid: errors && !!get(errors, name), sx: formControlStyles }, { children: [_jsx(FormLabel, Object.assign({ htmlFor: name }, { children: label !== null && label !== void 0 ? label : '' }), void 0), _jsxs(NumberInput, Object.assign({}, restField, { sx: inputStyles, precision: precision }, { children: [_jsx(CNumberInputField, Object.assign({ ref: ref, name: restField.name, "data-test": `numberinput-${name}`, max: max, min: min }, rest), void 0), !hideNumberStepper && (_jsxs(NumberInputStepper, { children: [_jsx(NumberIncrementStepper, {}, void 0), _jsx(NumberDecrementStepper, {}, void 0)] }, void 0))] }), void 0), helperText && !get(errors, name) && _jsx(FormHelperText, { children: helperText !== null && helperText !== void 0 ? helperText : '' }, void 0), errors && (_jsx(TranslationErrorMessage, Object.assign({ errors: errors, name: name }, { children: errors && get(errors, name) && ((_b = get(errors, name)) === null || _b === void 0 ? void 0 : _b.message) }), void 0))] }), void 0));
        } }, void 0));
};
if (__DEV__) {
    NumberInputField.displayName = 'NumberInputField';
}
