import { companyConstants } from '@companydotcom/helpers';

export const getColors = ({ params }: any) => {
  if (!params) {
    return undefined;
  }

  const colorParams = params ? params.find((param: any) => param.key === 'colorRange') : undefined;

  // @ts-ignore
  return colorParams ? JSON.parse(colorParams.value) : companyConstants.d3.colors?.pieChartDefault;
};

export const getDatasetKey = ({ params }: any) => {
  return params ? params?.find((param: any) => param?.key === 'datasetKey')?.value : undefined;
};

export const getMetadata = ({ params }: any) => {
  const metadata = params && params?.find((param: any) => param?.key === 'displayMetadata');
  return metadata ? JSON.parse(metadata.value) : undefined;
};

export const quantitativeLinear = (dataset: any) => {
  return dataset
    ? Object.keys(dataset).map(key => {
        return {
          text: key,
          value: dataset[key],
        };
      })
    : undefined;
};

export const quantitativeCircleNoData = (dataset: any) => {
  if (!dataset) {
    return [{ text: 'Not Active', value: 100, key: 1 }];
  }

  return [
    { text: 'Active', value: dataset, key: 0 },
    { text: 'Not Active', value: 100 - dataset, key: 1 },
  ];
};

export const quantitativeProgress = (dataset: any) => {
  if (!dataset) {
    return undefined;
  }

  return [
    { text: 'complete', value: dataset, key: 0 },
    { text: 'incomplete', value: 100 - dataset, key: 1 },
  ];
};

export const barChart = (dataset: any, metadata = []) => {
  // @ts-ignore
  let scores = metadata?.keyLabelMap?.slice(1)?.reduce?.(
    (acc: any, tuple: any) => ({
      ...acc,
      [tuple[1]]: 0,
    }),
    {},
  );
  let mainScore;
  if (dataset && dataset.status === 'fail') {
    return {
      status: 'fail',
      scores: quantitativeLinear(scores),
      averageScore: 0,
    };
  }

  if (dataset) {
    // @ts-ignore
    scores = metadata.keyLabelMap.slice(1).reduce(
      (acc: any, tuple: any) => ({
        ...acc,
        [tuple[1]]: dataset?.payload?.score?.[tuple[0]] * 100,
      }),
      {},
    );
    mainScore = {
      // @ts-ignore
      label: metadata.keyLabelMap?.[0][1],
      // @ts-ignore
      value: dataset?.payload?.score?.[metadata?.keyLabelMap?.[0][0]] * 100,
    };
  }

  return {
    mainScore,
    scores: quantitativeLinear(scores),
    // @ts-ignore
    colors: metadata?.keyLabelMap?.slice(1).map?.(tuple => tuple[2]),
  };
};
