import React from 'react';
import { Flex } from '@companydotcom/potion';
import { PieChart, Pie, Cell, Label, Legend } from 'recharts';

import type { TileComponentProps } from '../../types';

export interface ChartData {
  text: string;
  value: number;
  key: number;
}

export interface CircleWithLegendProps extends TileComponentProps {
  chartData?: ChartData[];
  dataColor?: any;
}

export const CircleWithLegend: React.FC<CircleWithLegendProps> = props => {
  const { chartData, dataColor } = props;

  const renderColorfulLegendText = (value: string) => {
    return <span style={{ color: '#2D3748', fontWeight: '500', paddingLeft: '2px' }}>{value}</span>;
  };
  return (
    <Flex
      className="tile__chart-circle-with-legend"
      flexDirection="column"
      alignItems="left"
      justifyContent="left"
      flex={1}
    >
      <PieChart width={220} height={180}>
        <Legend
          iconType="circle"
          layout="vertical"
          verticalAlign="bottom"
          align="right"
          wrapperStyle={{ fontFamily: 'Heebo', fontSize: '16', right: '-44px', bottom: '64px' }}
          formatter={renderColorfulLegendText}
        />
        <Pie
          data={chartData}
          cx={70}
          innerRadius={40}
          outerRadius={70}
          paddingAngle={0}
          dataKey="value"
          nameKey="text"
        >
          {chartData?.length == 1 ? (
            <Cell fill={dataColor[1]} />
          ) : (
            chartData?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={dataColor?.[index % dataColor?.length]} />
            ))
          )}

          <Label
            fontSize="20px"
            color="#4C6372"
            fontWeight={500}
            value={
              !chartData
                ? 'Loading...'
                : `${chartData?.find?.(i => i?.text === 'Active')?.value ?? '0'}%`
            }
            position="center"
          />
        </Pie>
      </PieChart>
    </Flex>
  );
};
