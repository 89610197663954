import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AcgPurchasedEvent } from '@companydotcom/types';
import type { RootState } from '../../../store';

export interface MyEventsState {
  activeEvent?: Partial<AcgPurchasedEvent>;
}

export const initialState: MyEventsState = {
  activeEvent: undefined,
};

export const acgMyEventsSlice = createSlice({
  name: 'acgMyEvents',
  initialState,
  reducers: {
    updateActiveEvent: (state, action: PayloadAction<Partial<AcgPurchasedEvent>>) => {
      state.activeEvent = action.payload;
    },
  },
});

export const { updateActiveEvent } = acgMyEventsSlice.actions;

export const selectActiveEvent = (state: RootState) => state.acgMyEvents.activeEvent;

export default acgMyEventsSlice.reducer;
