import { graphQL } from '@companydotcom/helpers';

const { fixNull } = graphQL;

const COMPONENT_THEME_FRAGMENT = `
align
alignContent
alignItems
alignSelf
animation
appearance
area
autoColumns
autoFlow
autoRows
background
backgroundAttachment
backgroundClip
backgroundColor
backgroundImage
backgroundPosition
backgroundRepeat
backgroundSize
bg
bgAttachment
bgClip
bgColor
bgGradient
bgImage
bgPosition
bgRepeat
bgSize
border
borderBottom
borderBottomColor
borderBottomEndRadius
borderBottomLeftRadius
borderBottomRadius
borderBottomRightRadius
borderBottomStartRadius
borderBottomStyle
borderBottomWidth
borderColor
borderEnd
borderEndColor
borderEndRadius
borderEndStyle
borderEndWidth
borderLeft
borderLeftColor
borderLeftRadius
borderLeftStyle
borderLeftWidth
borderRadius
borderRight
borderRightColor
borderRightRadius
borderRightStyle
borderRightWidth
borderStart
borderStartColor
borderStartRadius
borderStartStyle
borderStartWidth
borderStyle
borderTop
borderTopColor
borderTopEndRadius
borderTopLeftRadius
borderTopRadius
borderTopRightRadius
borderTopStartRadius
borderTopStyle
borderTopWidth
borderWidth
borderX
borderY
bottom
boxShadow
boxSize
boxSizing
color
column
columnGap
cursor
d
direction
display
fill
flex
flexBasis
flexDir
flexDirection
flexGrow
flexShrink
flexWrap
float
fontFamily
fontSize
fontStyle
fontWeight
gap
gridArea
gridAutoColumns
gridAutoFlow
gridAutoRows
gridColumn
gridColumnGap
gridGap
gridRow
gridRowGap
gridTemplateAreas
gridTemplateColumns
gridTemplateRows
h
height
justify
justifyContent
justifyItems
justifySelf
left
letterSpacing
lineHeight
m
margin
marginBottom
marginEnd
marginLeft
marginRight
marginStart
marginTop
maxH
maxHeight
maxW
maxWidth
mb
me
minH
minHeight
minW
minWidth
ml
mr
ms
mt
mx
my
objectFit
objectPosition
opacity
order
outline
overflow
overflowWrap
overflowX
overflowY
p
padding
paddingBottom
paddingEnd
paddingLeft
paddingRight
paddingStart
paddingTop
pb
pe
pl
pointerEvents
pos
position
pr
ps
pt
px
py
resize
right
row
rowGap
stroke
templateAreas
templateColumns
templateRows
textAlign
textDecoration
textOverflow
textShadow
textTransform
top
transform
transformOrigin
transition
userSelect
verticalAlign
visibility
w
whiteSpace
width
wordBreak
wrap
zIndex
`;

const userQuery = {
  getUser: (userId: string, locale: string) => `query getUser {
    getUser(userId: "${userId}", locale: "${locale}") {
      userId
      seoId
      accountId
      userCUIs {
        cuiId
        name
        priority
        type
        tileName
        trigger
        isActive
        dataRequired
        dateUpdated
      }
      mads
      appointments {
        appointmentId
        userId
        productId
        dateCreated
        dateUpdated
        dateExpires
        appointmentTime
        fulfilled
        metadata
      }
      account {
        allowPartnerBilling
        defaultPartnerBilled
        accountId
        seoStatus {
          gmbConnectStatus
        }
        accountStatus
        business {
          businessId
          name
          address {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          categories {
            id
            name
            fullName
            publisher
          }
          website
        }
        businessPrimary {
          businessId
          name
          website
          address {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          numberOfEmployees
          categories {
            id
            name
            fullName
            publisher
          }
        }
        Products {
          isActive
          ratePlanName
          productId
        }
        listingId
        listing {
          id
          name
          address
          addressExtra
          listingUrl
          hours{
            closed
            periods{
              from
              to
            }
            day
          }
          phones {
            type
            number
          }
          specialOffer {
            description
            title
          }
          hideAddress
          zip
          city
          state
          country
          categories {
            name
            publisher
            id
            fullName
          }
          description
          socialProfiles {
            url
            description
            type
          }
          images {
            url
            description
            type
          }
          videos {
            url
            description
            type
          }
          keywords
          paymentOptions
          hideAddress
          emails {
            email
            description
          }
          gmbStatus
          gmbLocationId
          services
          languages
          serviceArea {
            businessType
            radius {
              latlng {
                latitude
                longitude
              }
              radiusKm
            }
            places {
              placeInfos {
                name
                placeId
              }
            }
          }
          urls {
            url
            description
            type
          }
          content {
            type
            name
            title
            timeStart
            currency
            price
            images {
              url
              description
              type
            }
            image {
              url
              description
              type
            }
            description
          }
        }
        loans {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          dateCreated
          dateUpdated
          yearFounded
          monthFounded
        }
        loanPrimary {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          dateCreated
          dateUpdated
          yearFounded
          monthFounded
        }
        zuoraPaymentMethodId
        zuoraAccountId
        dateCreated
        dateUpdated
        isWebFQDNCustom
        isEmailFQDNCustom
        fqdnCompany
        fqdnCustom
        hasFQDN
        users {
          isPrimary
          userId
        }
        vendorData
        mads
      }
      vendorData
      products
      mailboxFQDN
      mailbox
      marketoPersonId
      firstName
      lastName
      avatar
      email
      role
      phone
      source
      userStatus
      userSettings {
        showAllTiles
      }
      userTileStates {
        seqNo
        isHidden
        tileId
        stateCurrent
      }
      userTileGroups {
        seqNo
        isHidden
        groupId
        color
        tileGroup {
          name
        }
      }
      userSettings {
        showAllTiles
      }
      userDataCollectors {
        dataCollectorSlug
        priority
        dateCreated
        dateUpdated
        isActive
        provider
        providerId
        tileName
        trigger
        type
        isDismissible
        path
      }
    }
  }`,
  getUserLite: (userId: string, locale: string) => `query getUser {
    getUser(userId: "${userId}", locale: "${locale}") {
      userId
      seoId
      accountId
      appointments {
        appointmentId
        userId
        productId
        dateCreated
        dateUpdated
        dateExpires
        appointmentTime
        metadata
      }
      account {
        allowPartnerBilling
        defaultPartnerBilled
        accountId
        seoStatus {
          gmbConnectStatus
        }
        accountStatus
        business {
          businessId
          name
          address {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          categories {
            id
            name
            fullName
            publisher
          }
          website
        }
        businessPrimary {
          businessId
          name
          website
          address {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          numberOfEmployees
          categories {
            id
            name
            fullName
            publisher
          }
        }
        Products {
          isActive
          ratePlanName
          productId
        }
        loans {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          dateCreated
          dateUpdated
          yearFounded
          monthFounded
        }
        loanPrimary {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          dateCreated
          dateUpdated
          yearFounded
          monthFounded
        }
        zuoraPaymentMethodId
        zuoraAccountId
        dateCreated
        dateUpdated
        isWebFQDNCustom
        isEmailFQDNCustom
        fqdnCompany
        fqdnCustom
        hasFQDN
        users {
          isPrimary
          userId
        }
        mads
      }
      products
      mailboxFQDN
      mailbox
      marketoPersonId
      firstName
      lastName
      avatar
      email
      role
      phone
      source
      userStatus
      mads
    }
  }`,
  getProductSSO: ({ userId, actionType, productSlug }: any) => `query getProductSSO {
    getProductSSO(input: {
      userId: ${fixNull(userId)}
      actionType: ${fixNull(actionType)}
      productSlug: ${fixNull(productSlug)}
    })
  }`,
  getDomain: (fqdn: string) => `query getDomain{
    getDomain(fqdn: ${fixNull(fqdn)})
  }`,
  getSeatCount: (accountId: string, productId: string) => `query getSeatCount {
    getSeatCount(productId: "${productId}" accountId: "${accountId}") {
      status
      totalEntitledSeats
      remainingEntitledSeats
      productSlug
      accountId
    }
  }`,
  getUserByEmail: (email: string) => `query getUserByEmail {
    getUserByEmail (email: "${email}") {
      marketoPersonId
      dateCreated
      accountId
      role
      lastName
      userId
      source
      email
      userStatus
      firstName
      dateUpdated
      mailbox
      mailboxFQDN
    }
  }`,
  getUserTileStates: (userId: string) => `query getUserTileStates {
    getUserTileStates(userId: "${userId}") {
      tileId
      stateCurrent
      statePrevious
      isHidden
      seqNo
      groupId
    }
  }`,
  getUserTileGroups: (userId: string) => `query getUserTileGroups {
    getUserTileGroups(userId: "${userId}") {
      tileGroup {
        name
      }
      color
      isHidden
      seqNo
      groupId
    }
  }`,
  fetchMids: (email: any, source: string) => `query getMidList {
    getMidList (email: "${email}" source: "${source}")
  }`,
  getEnrolledUser: (userId: any) => `query getUser {
    getUser (userId: "${userId}") {
      userId
      email
      accountId
    }
  }`,
  getUserTiles: (userId: string, locale: string) => `query getUserTiles {
    getUserTiles (userId: "${userId}", locale: "${locale}") {
      learnMoreUri
      vendorLogoUri
      targetTile
      tileId
      tileVariant
      tileName
      tileSlug
      tileIcon
      tileHasBadge
      tileHasLock
      tileLockIcon
      dateCreated
      productId
      dateCreated
      sourceProductName
      seqNo
      stateCurrent
      groupId
      isHidden
      dateUpdated
      dateExpires
      isNew
      roles
      tileLinks {
        targetTile
      }
      product {
        productId
        description
        isPackage
        isLeadGen
        isActive
        name
        slug
        purchaseRelUrl
        ssoUrl
        ratePlans {
          description
          includedInMembership
          isActive
          name
          price
          zuoraProductRatePlanId
          zuoraProductRatePlanChargeId
          partnerRatePlanId
          enterpriseQty
          eligibleProducts
          tiers {
            startingUnit
            endingUnit
            price
            partnerTierId
            chargeModel
          }
        }
        dateCreatedNumber
        dateUpdatedNumber
      }
      tileStates {
        stateName
        tileEvents {
          componentTypes
          eventType
          topic
          interactive
          triggerType
          pollInterval
          pollTimeout
        }
        tileComponents {
          href
          imageUrl
          gtmId
          target
          learnMoreUri
          errorText
          type
          text
          variant
          target
          statusType
          description
          dataConfig {
            type
            dataKey
            imageUrl
            label
          }
          operatingSystem
          componentTheme {
           ${COMPONENT_THEME_FRAGMENT}
          }
          disabled {
            disabledText
            sources
          }
          views {
            name
            type
            variant
          }
          params {
            key
            value
          }
          pubEvents {
            type
            actions {
              eventType
              responseField
              type
              dataRequired
              dataToValidate
              actionParams {
                key
                value
              }
            }
          }
          children {
            icon
            href
            imageUrl
            gtmId
            type
            target
            text
            variant
            statusType
            description
            dataConfig {
              type
              dataKey
              imageUrl
              label
            }
            operatingSystem
            componentTheme {
              ${COMPONENT_THEME_FRAGMENT}
            }
            params {
              key
              value
            }
            pubEvents {
              type
              actions {
                type
                dataRequired
                dataToValidate
                actionParams {
                  key
                  value
                }
              }
            }
            children {
              icon
              href
              imageUrl
              gtmId
              type
              target
              text
              variant
              statusType
              description
              dataConfig {
                type
                dataKey
                imageUrl
                label
              }
              operatingSystem
              componentTheme {
                ${COMPONENT_THEME_FRAGMENT}
              }
              params {
                key
                value
              }
              pubEvents {
                type
                actions {
                  eventType
                  responseField
                  type
                  dataRequired
                  dataToValidate
                  actionParams {
                    key
                    value
                  }
                }
              }
              children{
                icon
                href
                imageUrl
                description
                icon
                gtmId
                type
                target
                text
                description
                variant
                statusType
                dataConfig {
                  type
                  dataKey
                  imageUrl
                  label
                }
                pubEvents {
                  type
                  actions {
                    eventType
                    responseField
                    type
                    dataRequired
                    dataToValidate
                    actionParams {
                      key
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`,
  getUserGroups: (userId: any, locale: string) => `query getUserGroups {
    getUserGroups (userId: "${userId}", locale: "${locale}") {
      groupId
      isHidden
      seqNo
      color
      roles
      name
    }
  }`,
  getUserByEmailAndSource: (email: string, sourceId: string) => `query getUserByEmailAndSource {
    getUserByEmailAndSource (email: "${email}", source: "${sourceId}") {
      marketoPersonId
      dateCreated
      accountId
      role
      lastName
      userId
      source
      email
      userStatus
      firstName
      dateUpdated
      mailbox
      mailboxFQDN
    }
  }`,
};

export default userQuery;
