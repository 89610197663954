import React, { useContext, useEffect, ReactNode, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Client, IdToken } from '@auth0/auth0-spa-js';
import {
  determineLogoutDestination,
  handleExternalConnections,
  localStorageDevtool,
  AUTH0_CUSTOM_RULE_KEY,
  AuthFactory,
  getSourceIdFromGroups,
  getQueryParamsFromSessionStorage,
} from './auth-context-helpers';
import type { Auth0User } from './auth-context-helpers';
// import { useLinkAccountsMutation } from '../../services/auth/auth-api';

// https://auth0.com/docs/libraries/auth0-single-page-app-sdk

export interface LocalAuth0ClientType extends Auth0Client {
  audience?: string;
  managementClientId?: string;
  managementClientSecret?: string;
  tenant?: string;
  samlConnectionId?: string;
}

export interface Auth0ContextInterface {
  login: (options: any) => Promise<void>;
  checkSession: (redirect?: string, searchParams?: string) => Promise<void>;
  logout: (source?: string) => void;
  handleCallback: (redirect?: string) => Promise<void>;
  checkSource: (source: string) => boolean;
  user: Auth0User | undefined;
  getUserSource: () => string;
  refreshUser: () => Promise<void>;
  getTokenSilently: (args: any) => Promise<string>;
  getTokenClaims: () => Promise<IdToken | undefined>;
  isAuthenticated: Promise<boolean>;
}

interface Auth0ProviderInterface {
  children: ReactNode;
  auth0Client: Auth0Client;
}

export const MasterAuthInterface = AuthFactory();

export const Auth0Provider = ({ children, auth0Client }: Auth0ProviderInterface) => {
  // const [linkAccounts] = useLinkAccountsMutation();
  const [user, setUser] = useState<Auth0User | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const cancel = setInterval(() => {
      auth0Client.checkSession().catch(() => {
        auth0Client.logout();
      });
    }, 90000); // 15 min, per Auth0 Recommendation
    return () => {
      clearInterval(cancel);
    };
  }, [auth0Client]);

  const contextValue = useMemo(() => {
    const login = async (options: any) => {
      if (!auth0Client) {
        console.error('auth0Client has not been initialized');
        return;
      }
      await auth0Client.loginWithRedirect(options);
    };

    const checkAuthResultAndRedirect = (
      token: string,
      state?: any,
      redirect?: string,
      searchParams?: string,
    ) => {
      if (!auth0Client) {
        console.error('auth0Client has not been initialized');
        return;
      }
      // @ts-ignore
      const appState: Record<string, any> = token.appState || state || {};

      auth0Client.getUser().then(authUser => {
        if (authUser) {
          handleExternalConnections(authUser, handleCallback);
          localStorageDevtool({
            ...authUser,
            userId: authUser.sub?.split('|')[1] || '',
            [AUTH0_CUSTOM_RULE_KEY]: authUser[AUTH0_CUSTOM_RULE_KEY] || null,
            email: authUser.email || '',
            isAuthenticated: true,
            isPhoneVerified: authUser[AUTH0_CUSTOM_RULE_KEY]?.phone_verified === true,
            isEmailVerified:
              authUser.email_verified || authUser[AUTH0_CUSTOM_RULE_KEY]?.email_code_verified,
            acquisitionProduct: appState?.sourceForMarketo,
          });
          setUser({
            ...authUser,
            userId: authUser.sub?.split('|')[1] || '',
            [AUTH0_CUSTOM_RULE_KEY]: authUser[AUTH0_CUSTOM_RULE_KEY] || null,
            email: authUser.email || '',
            isAuthenticated: true,
            isPhoneVerified: authUser[AUTH0_CUSTOM_RULE_KEY]?.phone_verified === true,
            isEmailVerified:
              authUser.email_verified || authUser[AUTH0_CUSTOM_RULE_KEY]?.email_code_verified,
            acquisitionProduct: appState?.sourceForMarketo,
            token,
          });
        } else {
          return;
        }

        const localPhone = authUser[AUTH0_CUSTOM_RULE_KEY]?.phone_verified === true;
        const localEmail =
          authUser.email_verified || authUser[AUTH0_CUSTOM_RULE_KEY]?.email_code_verified;

        if (!localPhone && !localEmail) {
          /* START INITIAL ENTRY BEHAVIOR */

          if (appState && appState.productid && appState.planid) {
            navigate(
              { pathname: '/payment', search: searchParams },
              {
                state: {
                  userId: authUser.sub?.split('|')[1],
                  email: authUser.email,
                  productId: state.productid,
                  planId: appState.planid,
                },
              },
            );
          }

          // ROUTE TO LEGAL INC
          if (appState && appState.productid === process.env.REACT_APP_LEGALINC_PRODUCTID) {
            navigate(
              { pathname: '/payment', search: searchParams },
              {
                state: {
                  userId: authUser.sub?.split('|')[1],
                  email: authUser.email,
                  productId: appState.productid,
                  planId: appState.planid,
                  orderid: appState.orderid,
                  referrer: appState.referrer,
                },
              },
            );
          }

          const authorization = authUser[AUTH0_CUSTOM_RULE_KEY];

          if (authorization) {
            const sourceId = getSourceIdFromGroups(authorization.groups);
            // TODO: Refactor this so that it is data driven
            if (
              sourceId === 'company' ||
              sourceId === 'localhero' ||
              sourceId === 'cox' ||
              sourceId === 'metabaseq' ||
              sourceId === 'dd' ||
              sourceId === 'acg' ||
              sourceId === 'icommerce'
            ) {
              navigate({
                pathname: '/verifyphone',
                search: getQueryParamsFromSessionStorage() || searchParams || appState?.params,
              });
            } else {
              navigate({
                pathname: '/verify',
                search: getQueryParamsFromSessionStorage() || searchParams || appState?.params,
              });
            }
          }

          /* END INITIAL ENTRY BEHAVIOR */
        } else {
          /* START RETURN ENTRY BEHAVIOR */
          if (appState?.productid) {
            navigate(
              { pathname: '/payment', search: searchParams || appState?.params },
              {
                state: {
                  userId: authUser.sub?.split('|')[1],
                  email: authUser.email,
                  productId: appState.productid,
                  planId: appState.planid,
                  orderid: appState.orderid,
                  referrer: appState.referrer,
                },
              },
            );
          }

          if (appState?.redirectUrl) {
            window.location.replace(`${appState.redirectUrl}`);
          }

          navigate({
            pathname: appState.referrer || redirect || '/',
            search: searchParams || appState?.params,
          });
        }
      });
    };

    const checkSession = async (redirect?: string, searchParams?: string) => {
      if (!auth0Client) {
        console.error('auth0Client has not been initialized');
        return;
      }
      const token = await auth0Client.getTokenSilently().catch(() => {
        navigate(
          { pathname: '/login', search: searchParams },
          { replace: true, state: { referrer: redirect } },
        );
      });
      if (token) {
        checkAuthResultAndRedirect(token, null, redirect, searchParams);
      } else {
        // All query params are lost through redirect, so we must Store query params for the session in case of auth0 failure to drive persistent flows.
        sessionStorage.setItem('queryParams', JSON.stringify(searchParams));

        navigate(
          {
            pathname: '/login',
            search: getQueryParamsFromSessionStorage() || searchParams,
          },
          { replace: true, state: { referrer: redirect } },
        );
      }
    };

    const logout = (source = '') => {
      const logoutHref = determineLogoutDestination(source);
      // clear appsync cache
      // clear poll interval if exists
      auth0Client?.logout({
        returnTo: logoutHref,
        federated: true,
      });
    };

    const handleCallback = async (redirect?: string) => {
      if (!auth0Client) {
        console.error('auth0Client has not been initialized');
        return;
      }
      try {
        const { appState } = await auth0Client.handleRedirectCallback();
        if (appState?.source === 'icommerce' && appState?.showCreateAccount === 'true') {
          localStorage.setItem('pre_user_reg', JSON.stringify(appState));
        }
        // NOTE: Testing Safari Browser locally, this condition will throw you in an infinite loop.  See workaround here:
        // https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari
        // https://community.auth0.com/t/failed-silent-auth-login-required/56752/13
        // This will also infinite loop in incognito mode because Cookies cant be set.  You can temporarily change cookie settings to allow all in Chrome
        const token = await auth0Client.getTokenSilently({ ignoreCache: true });
        checkAuthResultAndRedirect(
          token,
          appState,
          appState?.referrer || redirect,
          appState?.searchParams,
        );
      } catch (err) {
        console.log('Error detected, retrying login', err);
        navigate(
          { pathname: '/login', search: getQueryParamsFromSessionStorage() },
          { replace: true },
        );
      }
    };

    const getUserSource = () => {
      return getSourceIdFromGroups(user?.[AUTH0_CUSTOM_RULE_KEY]?.groups) || 'grandio';
    };

    const checkSource = (source: string) => {
      return getUserSource() === source;
    };

    const refreshUser = async () => {
      const token = await auth0Client.getTokenSilently({ ignoreCache: true });
      const authUser = await auth0Client.getUser();
      if (authUser) {
        localStorageDevtool({
          ...authUser,
          userId: authUser?.sub?.split('|')[1] || '',
          [AUTH0_CUSTOM_RULE_KEY]: authUser?.[AUTH0_CUSTOM_RULE_KEY] || null,
          email: authUser.email || '',
          isAuthenticated: true,
          isPhoneVerified: authUser[AUTH0_CUSTOM_RULE_KEY]?.phone_verified === true,
          isEmailVerified:
            authUser.email_verified || authUser[AUTH0_CUSTOM_RULE_KEY]?.email_code_verified,
        });
        setUser({
          ...authUser,
          userId: authUser?.sub?.split('|')[1] || '',
          [AUTH0_CUSTOM_RULE_KEY]: authUser?.[AUTH0_CUSTOM_RULE_KEY] || null,
          email: authUser.email || '',
          isAuthenticated: true,
          isPhoneVerified: authUser[AUTH0_CUSTOM_RULE_KEY]?.phone_verified === true,
          isEmailVerified:
            authUser.email_verified || authUser[AUTH0_CUSTOM_RULE_KEY]?.email_code_verified,
          token,
        });
      }
    };
    return {
      login,
      checkSession,
      logout,
      user,
      handleCallback,
      checkSource,
      getUserSource,
      refreshUser,
      isAuthenticated: auth0Client.isAuthenticated(),
      getTokenSilently: auth0Client.getTokenSilently,
      getTokenClaims: auth0Client.getIdTokenClaims,
    };
  }, [auth0Client, navigate, user]);

  return <Auth0Context.Provider value={contextValue}>{children}</Auth0Context.Provider>;
};

const Auth0Context = React.createContext<Auth0ContextInterface | undefined>(undefined);
export const useAuth = () => useContext(Auth0Context);
