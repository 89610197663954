import React, { useEffect } from 'react';
import { useMitt } from '@companydotcom/providers';
import { Flex, Icon, Text, Button, IconProps } from '@companydotcom/potion';
import { companyConstants } from '@companydotcom/helpers';
import { useNavigate } from 'react-router-dom';
import type { TileComponentProps } from '../../types';

export interface TilePendingVerificationProps extends TileComponentProps {}

export const TilePendingVerification: React.FC<TilePendingVerificationProps> = props => {
  const { emitter } = useMitt();
  const { tile, componentTheme, account } = props;

  useEffect(() => {
    const listing = account?.listing;
    const { gmbStatus } = listing || { id: null, gmbStatus: null };

    if (gmbStatus === 'verified' && tile) {
      emitter.emit(companyConstants.tileActions.transitionState, {
        tileId: tile?.tileId,
        productId: tile?.productId,
        stateCurrent: 'active',
        statePrevious: 'pre-active',
        product: {
          slug: 'blp',
        },
      });
    }
    // eslint-disable-next-line
  }, [account?.listing, tile]);

  return <IncompleteVerification componentTheme={componentTheme} />;
};

const IncompleteVerification: React.FC<TileComponentProps> = props => {
  const { componentTheme } = props;
  const navigate = useNavigate();

  const navToBLP = () => navigate('/business-listings-profile');

  return (
    <Flex
      className="tile-tilePendingVerification"
      alignItems="center"
      flexDirection="column"
      flexGrow={1}
      sx={componentTheme}
    >
      <BlpIcon color="orange.300" h="64px" w="175px" mb={4} />

      <Text textStyle="md" px={4} textAlign="center" mb="auto">
        Your postcard is on the way! You can access your Business Listings Profile when you receive
        it.
      </Text>

      <Button isDisabled onClick={navToBLP}>
        Verification Pending
      </Button>

      <Text textStyle="sm" mt={4}>
        Questions?{' '}
        <Button
          variant="link"
          fontSize="inherit"
          _hover={{
            textDecoration: 'none',
          }}
          onClick={() => window.open(`${process.env.REACT_APP_GRANDIO_URL}/contact-us`)}
        >
          Contact Support.
        </Button>
      </Text>
    </Flex>
  );
};

const BlpIcon = (props: IconProps) => {
  return (
    // @ts-ignore
    <Icon viewBox="0 0 178 66" {...props}>
      <g transform="translate(1 2)" fill="none" fillRule="evenodd">
        <rect
          width="95.455"
          height="62.701"
          x="79.545"
          fill="#FFF"
          stroke="currentColor"
          strokeWidth="2.406"
          rx="1.604"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2.406"
          d="M29.409 9.826H71.66M.468 29.479h63.636m-31.818 11.23h23.465m35.492-16.845h23.465m-23.465-7.487h23.465M91.243 31.35h23.465m-23.465 7.487h23.465M36.029 52.874h38.439m75.265-42.58c5.752.624 10.067.936 12.943.936 2.876 0 5.113-.312 6.709-.936m-19.652 6.083c5.752.624 10.067.936 12.943.936 2.876 0 5.113-.312 6.709-.936"
        />
        <rect
          width="13.102"
          height="13.102"
          x="147.861"
          y="7.487"
          fill="#FFF"
          stroke="currentColor"
          strokeWidth="2.406"
          rx=".802"
        />
        <rect width="36.497" height="4" x="132.888" y="29.947" fill="currentColor" rx=".936" />
        <rect width="29.011" height="4" x="132.888" y="41.176" fill="currentColor" rx=".936" />
      </g>
    </Icon>
  );
};
