import React, { useMemo, useRef, useEffect, useState } from 'react';
import {
  Center,
  Flex,
  Stack,
  VStack,
  Text,
  Link,
  Icon,
  Box,
  SimpleGrid,
  useMediaQuery,
  useDisclosure,
  Button,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { faEnvelope, faGlobe, faPhone, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { isEmpty } from 'lodash';
import { ProfileSection } from './profile-section';
import { ProfileTitle } from './profile-title';
import { ProfileBio, FullBioModal } from './profile-bio';
import { PageHeading } from '../../../../components/elements';
import { HiddenField } from '../../../../services/acg/acg.types';
import { ProfileAvatar } from '../../../../components/elements/profile-avatar/profile-avatar';
import { Maybe, AcgMembership, AcgUserProfile } from '@companydotcom/types';

export const AcgPublicProfile = ({
  profileData,
  memberships,
  userImage,
  chapterIds,
  hiddenFields,
  isMemberSearch,
}: {
  profileData: AcgUserProfile;
  memberships?: Maybe<AcgMembership>[];
  userImage?: string | null;
  chapterIds?: string[];
  hiddenFields?: Maybe<string>[];
  isMemberSearch?: boolean;
}) => {
  const { t } = useTranslation();
  const bioRef = useRef<HTMLParagraphElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showViewFullBioButton, setShowViewFullBioButton] = useState(false);

  useEffect(() => {
    if (bioRef?.current?.clientHeight === 210) {
      setShowViewFullBioButton(true);
    }
  }, [bioRef]);

  const alignItems = ['center', 'enter', 'center', 'flex-start'];

  const primaryAddress = useMemo(() => {
    if (profileData?.preferredAddressType === 'homeAddress' && !isEmpty(profileData?.homeAddress)) {
      return profileData?.homeAddress;
    }

    if (profileData?.preferredAddressType === 'workAddress' && !isEmpty(profileData?.workAddress)) {
      return profileData?.workAddress;
    }

    // @ts-ignore
    if (profileData?.city || profileData?.state || profileData?.country) {
      return {
        // @ts-ignore
        city: profileData?.city ?? '',
        // @ts-ignore
        state: profileData?.state ?? '',
        // @ts-ignore
        country: profileData?.country ?? '',
      };
    }
    return {};
  }, [profileData]);

  const primaryPhone = useMemo(() => {
    if (isMemberSearch) {
      // @ts-ignore
      return profileData?.phone;
    }
    if (profileData?.preferredPhoneType === 'mobilePhone') {
      return profileData?.mobilePhone;
    }
    return profileData?.workPhone;
  }, [
    isMemberSearch,
    profileData?.mobilePhone,
    // @ts-ignore
    profileData?.phone,
    profileData?.preferredPhoneType,
    profileData?.workPhone,
  ]);

  const primaryEmailAddress = profileData?.emailAddress;
  const primaryWebsite = profileData?.website;

  const [isMobile] = useMediaQuery('(max-width: 30em)');

  const avatarSize = isMobile ? 'lg' : '2xl';

  return (
    <Center
      className="acg-myProfile__publicProfile"
      flexDirection="column"
      bg={['transparent', 'white']}
      py={[0, 8, 12]}
      px={[0, 4]}
    >
      <Box width="full" maxW={908}>
        <PageHeading heading={t('acg.myProfile.memberProfile')} />
        <Stack
          mt={[4, 12]}
          direction={['column', null, null, null, 'row']}
          spacing={[0, 8]}
          justifyContent="center"
        >
          <VStack
            flexWrap="wrap"
            width="100%"
            alignItems={alignItems}
            spacing={4}
            bg={['white', 'inherit']}
            pt={[4, 0]}
            boxShadow={['lg', 'none']}
          >
            <Stack
              width="100%"
              spacing={2}
              alignItems="center"
              justify="center"
              direction={['column', null, null, 'row']}
            >
              <ProfileAvatar
                size={avatarSize}
                name={`${profileData?.firstName} ${profileData?.lastName}`}
                bg="white"
                src={userImage ?? undefined}
                isEditorDisabled
              />
              <ProfileTitle
                canEdit={false}
                firstName={profileData?.firstName}
                lastName={profileData?.lastName}
                middleName={profileData?.middleName}
                suffix={profileData?.suffix}
                title={profileData?.title}
                prefix={profileData?.prefix}
                organization={profileData?.organization}
                twitterName={profileData?.twitterName}
                linkedInName={profileData?.linkedInName}
              />
            </Stack>
            {profileData?.bio !== '' && <ProfileBio bioRef={bioRef} value={profileData?.bio} />}
            {showViewFullBioButton && (
              <Button size="sm" display={['none', 'inherit']} variant="outline" onClick={onOpen}>
                VIEW FULL BIO
              </Button>
            )}
          </VStack>
          <VStack
            bg={['white', 'inherit']}
            alignItems={['center', 'flex-start']}
            flexWrap="wrap"
            width="100%"
            spacing={5}
            padding={[4, 0]}
          >
            <ProfileItem
              heading={t('acg.myProfile.profilePages.functionRole')}
              value={profileData?.individualType ?? ''}
            />
            {typeof primaryAddress !== 'string' &&
              !isEmpty(primaryAddress) &&
              !hiddenFields?.includes(HiddenField.PREFERRED_ADDRESS) && (
                <ProfileItem heading={t('acg.myProfile.profilePages.location')}>
                  {primaryAddress?.city &&
                    primaryAddress?.state &&
                    primaryAddress?.country &&
                    `${primaryAddress?.city}, ${primaryAddress?.state}, ${primaryAddress?.country}`}
                </ProfileItem>
              )}
            {primaryEmailAddress && !hiddenFields?.includes(HiddenField.EMAIL_ADDRESS) && (
              <ContactItem icon={faEnvelope} value={primaryEmailAddress ?? ''} isLink email />
            )}
            {primaryPhone && !hiddenFields?.includes(HiddenField.PREFERRED_PHONE) && (
              <ContactItem
                icon={faPhone}
                value={
                  primaryPhone
                  // `(${primaryPhone?.phoneNumber.slice(0, 3)}) ${primaryPhone?.phoneNumber.slice(
                  //   3,
                  //   6,
                  // )}-${primaryPhone?.phoneNumber.slice(6, 10)}` ?? ''
                }
              />
            )}
            {primaryWebsite && !hiddenFields?.includes(HiddenField.WEBSITE) && (
              <ContactItem icon={faGlobe} value={primaryWebsite ?? ''} isLink website />
            )}
            {memberships && memberships?.length > 1 ? (
              <ProfileItemsList
                heading={`${t('acg.myProfile.profilePages.chapter')}`}
                values={memberships?.map(membership => membership?.chapterName || '')}
              />
            ) : (
              <ProfileItem
                heading={`${t('acg.myProfile.profilePages.chapter')}`}
                value={memberships?.[0]?.chapterName || chapterIds?.join(', ')}
              />
            )}
          </VStack>
        </Stack>
        <SimpleGrid mt={9} spacing={8} columns={[1, null, null, 2]}>
          {!isEmpty(profileData?.areaOfExpertise) && (
            <ProfileSection heading={t('acg.myProfile.profilePages.expertise.expertise')}>
              {profileData?.areaOfExpertise?.map(area => (
                <Text key={area} textStyle="sm">
                  {area}
                </Text>
              ))}
            </ProfileSection>
          )}
          {!isEmpty(profileData?.marketArea) && (
            <ProfileSection heading={t('acg.myProfile.profilePages.marketArea.marketArea')}>
              {profileData?.marketArea?.map(area => (
                <Text key={area} textStyle="sm">
                  {area}
                </Text>
              ))}
            </ProfileSection>
          )}

          {!isEmpty(profileData?.industry) && (
            <ProfileSection heading={t('acg.myProfile.profilePages.industries.industries')}>
              {profileData?.industry?.map(industry => (
                <Text key={industry} textStyle="sm">
                  {industry}
                </Text>
              ))}
            </ProfileSection>
          )}

          {profileData?.transactionType && (
            <ProfileSection
              heading={t('acg.myProfile.profilePages.transactionType.transactionType')}
            >
              <Text textStyle="sm">{profileData?.transactionType}</Text>
            </ProfileSection>
          )}
        </SimpleGrid>
      </Box>
      <FullBioModal isOpen={isOpen} onClose={onClose} bioText={profileData?.bio} />
    </Center>
  );
};

interface ProfileItemProps {
  heading: string;
  value?: string;
  children?: React.ReactNode;
}
const ProfileItem: React.FC<ProfileItemProps> = ({ heading, value, children }) => {
  return (
    <Flex direction="column" alignItems={['center', 'center', 'flex-start']}>
      <Text textStyle="sm">{heading}</Text>
      <Text textStyle={['sm', 'lg']} fontWeight="bold">
        {value || children}
      </Text>
    </Flex>
  );
};

interface ProfileItemsListProps {
  heading: string;
  values: Array<string>;
}

const ProfileItemsList: React.FC<ProfileItemsListProps> = ({ heading, values }) => (
  <Flex direction="column" alignItems={['center', 'center', 'flex-start']}>
    <Text textStyle="sm">{heading}</Text>
    <Flex flexDirection="row">
      <Text textStyle={['sm', 'lg']} fontWeight="bold">
        {values.map((value, i) => (i === values.length - 1 ? value : `${value}, `))}
      </Text>
    </Flex>
  </Flex>
);

interface ContactItemProps {
  icon: IconDefinition;
  value: string;
  isLink?: boolean;
  email?: boolean;
  website?: boolean;
}
const ContactItem: React.FC<ContactItemProps> = ({
  icon,
  value,
  isLink = false,
  email = false,
  website = false,
}) => {
  if (isLink && email) {
    return (
      <Stack direction={['column', 'row']} align={['center']} spacing={3} className="contact-item">
        <Icon as={FontAwesomeIcon} icon={icon} />
        <Link
          textStyle={['sm', 'lg']}
          fontWeight="bold"
          isExternal
          href={`mailto:${value}`}
          wordBreak="break-all"
        >
          {value}
        </Link>
      </Stack>
    );
  }
  if (isLink && website) {
    return (
      <Stack direction={['column', 'row']} align={['center']} spacing={3} className="contact-item">
        <Icon as={FontAwesomeIcon} icon={icon} />
        <Link
          textStyle={['sm', 'lg']}
          fontWeight="bold"
          isExternal
          href={value}
          wordBreak="break-all"
        >
          {value}
        </Link>
      </Stack>
    );
  }
  return (
    <Stack direction={['column', 'row']} align={['center']} spacing={3} className="contact-item">
      <Icon as={FontAwesomeIcon} icon={icon} />
      <Text textStyle={['sm', 'lg']} fontWeight="bold">
        {value}
      </Text>
    </Stack>
  );
};
