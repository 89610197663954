import gql from 'graphql-tag';

export const SET_NEW_ZUORA_DEFAULT_PAYMENT_METHOD = gql`
  mutation SetNewZuoraDefaultPaymentMethod($body: NewZuoraDefaultPaymentMethodInput!) {
    setNewZuoraDefaultPaymentMethod(body: $body)
  }
`;

export const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
    createPaymentMethod(input: $input)
  }
`;
