import React, { useState, useEffect } from 'react';
import { Flex, Box, Image, Heading, Button, Link, Text, Progress } from '@companydotcom/potion';
import { NavLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useSource } from '@companydotcom/providers';
import { CheckCircleIcon } from '@companydotcom/ui';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export const AppointmentResult = ({
  onClose,
  existingScheduledNotif,
  eventHandler,
  events,
}: any) => {
  const [status, setStatus] = useState('loading');
  const [loadingExpires] = useState(Date.now() + 25000);
  const { t } = useTranslation();
  const source = useSource();

  useEffect(() => {
    if (status === 'loading') {
      const loadingInterval = setInterval(() => {
        if (loadingExpires < Date.now()) {
          setStatus('failure');
          clearInterval(loadingInterval);
        }
      }, 4000);
      return () => clearInterval(loadingInterval);
    }
  }, [loadingExpires, status]);

  useEffect(() => {
    if (
      existingScheduledNotif?.body?.payload?.appointment?.appointmentTime &&
      !existingScheduledNotif?.body?.payload?.appointment?.error
    ) {
      eventHandler?.(events, { type: 'success' }, existingScheduledNotif);
      setStatus('success');
    } else if (!existingScheduledNotif) {
      setStatus('nodata');
    } else if (existingScheduledNotif?.body?.payload?.appointment?.error) {
      setStatus('failure');
    } else if (
      existingScheduledNotif?.body?.payload?.appointment?.error &&
      existingScheduledNotif?.body?.payload?.appointment?.errorTitle
    ) {
      setStatus('appt_existing');
    }
  }, [existingScheduledNotif, eventHandler, events]);

  switch (status) {
    case 'success':
      return (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" py={14}>
          <Box textAlign="center" width="full">
            <CheckCircleIcon mb={10} />
            <Heading size="hs-xl">{t('components.appointmentScheduler.confirmed')}</Heading>
            <Text textStyle="lg">{t('tiles.appointmentScheduler.confirmText')}</Text>
            <Text textStyle="lg" mb={10}>
              {dayjs
                .unix(
                  Math.round(
                    existingScheduledNotif?.body?.payload?.appointment?.appointmentTime / 1000,
                  ),
                )
                .tz('America/New_York')
                .format('MMMM D[,] YYYY [at] h:mm A z')}
              .
            </Text>
            <Button size="lg" variant="outline" onClick={onClose}>
              {t('common.buttons.done')}
            </Button>
          </Box>
        </Flex>
      );
    case 'failure':
      return (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" py={14}>
          <Box textAlign="center" width="full">
            <Box pt={[3, null, 8]} pb={[4]} width="full">
              <Image
                src={`${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/${source.sourceId}/images/icons/icon-error.png`}
                height={[10, null, '108px']}
                width={[10, null, '108px']}
                mb={[6, null, 8]}
                order={[2, 1]}
                mx="auto"
              />
              <Heading size="hs-xl">
                {existingScheduledNotif?.body?.payload?.appointment?.errorTitle ||
                  'Something Went Wrong'}
              </Heading>
              <Text textStyle="lg" mt={[4]} mb={[10, null, 12]} mx={['auto']} maxWidth={['450px']}>
                {existingScheduledNotif?.body?.payload?.appointment?.errorMessage || (
                  <Trans i18nKey="components.appointmentScheduler.somethingWrong">
                    <Link as={NavLink} to="/help">
                      contact support
                    </Link>
                    .
                  </Trans>
                )}
              </Text>
              <Button size="lg" variant="outline" onClick={onClose}>
                {t('common.buttons.back')}
              </Button>
            </Box>
          </Box>
        </Flex>
      );
    case 'appt_existing':
      return (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" py={14}>
          <Box textAlign="center" width="full">
            <Box pt={[3, null, 8]} pb={[4]} width="full">
              <Image
                src={`${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/${source?.sourceId}/images/icons/icon-error.png`}
                height={[10, null, '108px']}
                width={[10, null, '108px']}
                mb={[6, null, 8]}
                order={[2, 1]}
                mx="auto"
              />
              <Heading size="hs-xl">
                {existingScheduledNotif?.body?.payload?.appointment?.errorTitle}
              </Heading>
              <Text textStyle="lg" mt={[4]} mb={[10, null, 11]} mx={['auto']} maxWidth={['452px']}>
                {existingScheduledNotif?.body?.payload?.appointment?.errorMessage}
              </Text>
              <Button size="lg" variant="outline" onClick={onClose}>
                {t('common.buttons.back')}
              </Button>
            </Box>
          </Box>
        </Flex>
      );
    case 'loading':
    default:
      return (
        <Flex
          width="full"
          mt="44px"
          mb="120px"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={4}
        >
          <Heading size="hs-xl" px={[4, null, 8]} textAlign="center">
            {t('components.appointmentScheduler.confirming')}
          </Heading>
          <Box maxWidth="680px" width="full" mt={14}>
            <Progress width="full" maxW={680} size="lg" value={40} isIndeterminate />
          </Box>
        </Flex>
      );
  }
};
