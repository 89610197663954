import React from 'react';
import { Box, Heading, Flex, Image, Text, Switch, Button } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import type { ChildEmailProductStatus } from '../../../pages/user-profile';

export interface ChooseUserProductProps {
  productName: string;
  productDescription: string;
  productImage?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  deleteAccountHandler?: () => void;
  reactivateAccountHandler?: () => void;
  activateAccountHandler?: () => void;
  toggleHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  warningMessage?: React.ReactNode;
  includedInMembership?: boolean;
  control?: any;
  name?: string;
  isSuspended?: boolean;
  productStatus?: ChildEmailProductStatus | null;
  actionLoading?: 'reactivating' | 'deleting' | 'suspending' | 'activating';
}

export const ChooseUserProduct = ({
  productName = '',
  productDescription = '',
  productImage = '',
  isDisabled = false,
  isSelected = false,
  includedInMembership = false,
  toggleHandler,
  deleteAccountHandler,
  reactivateAccountHandler,
  activateAccountHandler,
  warningMessage,
  actionLoading,
  productStatus,
}: ChooseUserProductProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      className="choose-user-product"
      width="full"
      flexDirection="column"
      justifyContent="space-between"
      py={5}
      alignItems="flex-start"
    >
      <Flex width="full" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
        <Flex flexDirection="row" alignItems="flex-start">
          <Image mr={2} width="38px" src={productImage || ''} alt={productName} />
          <Box>
            <Heading size="hs-lg">{productName}</Heading>
            <Text textStyle="md" mt={2} maxWidth={420}>
              {productDescription}
            </Text>
          </Box>
        </Flex>
        {includedInMembership ? (
          <Text textStyle="md">{t('components.chooseUserProduct.included')}</Text>
        ) : (
          <Switch
            size="lg"
            onChange={toggleHandler}
            isChecked={isSelected}
            isDisabled={isDisabled}
          />
        )}
      </Flex>
      {warningMessage || null}
      {productStatus === 'inactive' && activateAccountHandler && (
        <>
          <Text mt={6} color="red.500" textAlign="center" mx="auto">
            {t('components.chooseUserProduct.accountDeleted')}
          </Text>
          <Button
            variant="link"
            onClick={actionLoading !== 'activating' ? activateAccountHandler : undefined}
            sx={{ mx: 'auto', mt: 1 }}
            isLoading={actionLoading === 'activating'}
          >
            {actionLoading === 'activating'
              ? t('common.buttons.loading')
              : t('components.chooseUserProduct.activateAccount')}
          </Button>
        </>
      )}
      {productStatus === 'disabled' && reactivateAccountHandler && (
        <>
          <Text mt={6} color="red.500" textAlign="center" mx="auto">
            {t('components.chooseUserProduct.accountSuspended')}
          </Text>
          <Button
            variant="link"
            onClick={actionLoading !== 'reactivating' ? reactivateAccountHandler : undefined}
            sx={{ mx: 'auto', mt: 1 }}
            isLoading={actionLoading === 'activating'}
          >
            {actionLoading === 'reactivating'
              ? t('common.buttons.loading')
              : t('components.chooseUserProduct.reactivateAccount')}
          </Button>
        </>
      )}
      {(productStatus === 'active' || productStatus === 'pending') && deleteAccountHandler && (
        <Button
          variant="link"
          onClick={
            actionLoading !== 'deleting' && actionLoading !== 'suspending'
              ? deleteAccountHandler
              : undefined
          }
          sx={{ mx: 'auto', mt: 5 }}
          isLoading={actionLoading === 'deleting' || actionLoading === 'suspending'}
        >
          {actionLoading === 'deleting' || actionLoading === 'suspending'
            ? t('common.buttons.loading')
            : t('components.chooseUserProduct.deleteAccount')}
        </Button>
      )}
    </Flex>
  );
};
