import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Icon,
  InputRightElement,
  InputGroup,
  Flex,
  Text,
  Input,
  useBoolean,
  Stack,
  FormControl,
} from '@companydotcom/potion';
import { useMitt } from '@companydotcom/providers';
import { useTranslation } from 'react-i18next';
import type { User } from '@companydotcom/types';
import { companyConstants } from '@companydotcom/helpers';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useNavigate,
  //  useLocation, useSearchParams
} from 'react-router-dom';
import type { BecomeMemberSharedProps } from '../index';
import { PaginatedSelect } from '../components/paginated-select';
import { Chapter } from '../../../../../../services/acg/acg.types';
import { PageHeading } from '../../../../../../components/elements';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import {
  setSelectedChapter,
  getSelectedChapter,
  setOrderConfirmationData,
} from '../../../../acg-checkout/checkout-slice';
import {
  useCreateCartMutation,
  useDoPaymentMutation,
  useLazySubmitMembershipApplicationQuery,
  useLazyDeleteDraftMembershipsQuery,
} from '../../../../../../services/acg/acg-api';
import { useAcgCartStorage } from '../../../../shared';
import { useGetGlobalUserQuery } from '../../../../../../services/user/user-api';

export const ChapterSelect = ({
  goToRegStep,
  goToStep,
  isSubpage,
  chapterKeyParam,
  packageKeyParam,
  setIsBecomingMember,
  register,
  handleSubmit,
  control,
  reset,
  errors,
  setError,
  getValues,
  defaultValues,
}: BecomeMemberSharedProps) => {
  const { emitter } = useMitt();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const chapter = useAppSelector(getSelectedChapter);

  const [doPayment] = useDoPaymentMutation();
  const [addChapterToCart] = useCreateCartMutation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [submitMembershipApplication] = useLazySubmitMembershipApplicationQuery();
  const [deleteDraftMemberships] = useLazyDeleteDraftMembershipsQuery();
  const { addMembershipToLocalCart, addChapterToLocalCart, getLocalCart } = useAcgCartStorage();
  const currentPackage = getLocalCart()?.selectedPackage;

  // const { search } = useLocation();
  // const [searchParams] = useSearchParams();
  // const webCode = searchParams.get('WebCode')?.toLowerCase();

  const [page, setPage] = useState(1);
  const [isValid, setIsValid] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean();
  const [filteredData, setFilteredData] = useState<Chapter[]>();

  const sortedChapters = useMemo(
    () =>
      currentPackage?.chapters
        ? currentPackage?.chapters?.sort((a: { chapterName: string }, b: { chapterName: string }) =>
            a?.chapterName?.localeCompare(b?.chapterName),
          )
        : [],
    [currentPackage?.chapters],
  );
  const isInternational = useMemo(
    () =>
      chapter?.duesProducts?.[0]?.chapterTypeName.includes('EU') ||
      chapter?.duesProducts?.[0]?.chapterTypeName.includes('UK'),
    [chapter?.duesProducts],
  );

  const navigateAfterPurchase = () => {
    setIsBecomingMember?.off();
    if (isInternational) {
      if (isSubpage) {
        navigate('/checkout', { state: { flow: 'checkout' } });
      } else {
        goToRegStep?.('checkout');
      }
    } else if (goToRegStep && !isInternational) {
      emitter.emit(companyConstants.platformEvents.dataCollectorComplete, {
        path: '/acg-shopping-cart',
      });
    } else {
      navigate('/acg-shopping-cart', { state: { flow: 'checkout' } });
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading?.on();
      if (chapter && chapter?.duesProducts?.length > 1) {
        goToStep?.('plan-select');
      } else if (chapter && chapter?.duesProducts?.length === 1 && globalUser) {
        await deleteDraftMemberships({
          user: globalUser,
        }).unwrap();

        const applicationId = await submitMembershipApplication({
          user: globalUser,
          payload: {
            name: globalUser?.email as string,
            autoRenew: false,
            packageId: chapter?.duesProducts[0]?.packageId,
            chapterId: chapter?.chapterId,
            processId: chapter?.duesProducts[0]?.joinProcessId,
            membershipTypeId: chapter?.duesProducts[0]?.membershipTypeId,
            customFields: [
              {
                custom_field_id: 'membership-applications__membership_gain__c',
                string_value: 'Nothing',
              },
            ],
          },
        }).unwrap();

        const cart = await addChapterToCart({
          user: globalUser as User,
          items: {
            memberships: [
              {
                applicationId,
                packageId: chapter?.duesProducts[0]?.packageId,
                chapterId: chapter?.chapterId,
                processId: chapter?.duesProducts[0]?.joinProcessId,
                membershipTypeId: chapter?.duesProducts[0]?.membershipTypeId,
                customFields: [
                  {
                    custom_field_id: 'membership-applications__membership_gain__c',
                    string_value: 'Nothing',
                  },
                ],
              },
            ],
            events: [],
          },
        }).unwrap();

        if (isInternational) {
          const response = await doPayment({
            user: globalUser,
            payload: {
              paymentInfo: {
                autoRenew: false,
              },
            },
          }).unwrap();
          dispatch(setOrderConfirmationData(response));
        }
        addMembershipToLocalCart({ ...cart, ...chapter, ...chapter?.duesProducts[0] });
        navigateAfterPurchase();
      }
      setIsLoading?.off();
    } catch (err) {
      console.log('Error adding chapter to cart on chapter select:', err);
    }
  };

  const onChange = (value?: string) => {
    const filteredChapter = sortedChapters?.find((item: Chapter) => item.chapterName === value);
    if (filteredChapter) {
      addChapterToLocalCart(filteredChapter);
      dispatch?.(setSelectedChapter(filteredChapter));
    }
    setIsValid?.on();
  };

  const handleSearchOnChange = (target: string) => {
    const results = sortedChapters?.filter((chapter: Chapter) =>
      chapter.chapterName.toLowerCase().startsWith(target.toLowerCase()),
    );
    if (results?.length) {
      setError('chapterSearch', {
        type: 'manual',
        message: false,
      });
    } else {
      setIsValid?.off();
      setError('chapterSearch', {
        type: 'manual',
        message: true,
      });
    }
    setFilteredData(results);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!getValues?.()?.['acgChapter'] && isValid) {
        setFilteredData([]);
        setIsValid?.off();
      }
      return () => {
        mounted = false;
      };
    }
  }, [getValues, isValid, setIsValid, sortedChapters]);

  // TODO: finds a chapter from a key param sent over from acg.org
  // useEffect(() => {
  //   if (chapterKeyParam) {
  //     const filteredChapter = packagesAndChapters?.find((item: any) => item.recordKey === chapterKeyParam);
  //     if (filteredChapter) {
  //       addChapterToLocalCart(filteredChapter);
  //       dispatch?.(setSelectedChapter(filteredChapter));
  //     }
  //     setIsValid?.on();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (packageKeyParam) {
    goToStep?.(2);
  }

  return (
    <Center
      className="acg-package-selection__container"
      flexDirection="column"
      bg={['transparent', 'white']}
      textAlign="center"
      px={[0, 4]}
      py={isSubpage ? [0, 12] : 0}
    >
      <Box
        as="form"
        width="full"
        className="acg-package-selection__form"
        maxW={isSubpage ? 908 : 608}
      >
        <Stack width="full" spacing={[8, 12]} mb={[4, 12]}>
          <PageHeading
            heading={t('acg.firstTimeFlow.subpage.chapterHeading')}
            width="full"
            maxW={908}
          />
          <Text mx={4} width="full">
            {t('acg.firstTimeFlow.subpage.chapterDescription')}
          </Text>
        </Stack>

        <Flex
          width="full"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          mb={[5, null, 8]}
        >
          <FormControl id="chapter-search">
            <InputGroup alignItems="center" width="full">
              <Input
                {...register('chapterSearch')}
                name="chapterSearch"
                className="acg-registration-chapterSearch"
                data-test="acg-registration-chapterSearch"
                placeholder="Search for a Chapter..."
                onChange={e => {
                  handleSearchOnChange(e.target.value);
                  setPage(1);
                }}
                errors={errors}
              />
              <InputRightElement height="100%" mr={2}>
                <Icon as={FontAwesomeIcon} icon={faSearch} />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Flex>
        <FormControl>
          <PaginatedSelect
            data={filteredData?.length ? filteredData : sortedChapters}
            onSubmit={handleSubmit(onSubmit)}
            onChange={onChange}
            type="chapter"
            isValid={isValid}
            page={page}
            setPage={setPage}
            noDataError={errors?.chapterSearch?.message}
            isLoading={isLoading}
            chapterKeyParam={chapterKeyParam}
            formProps={{
              control,
              reset,
              register,
              errors,
              name: 'acgChapter',
            }}
          />
        </FormControl>
        {/* TODO: refactor for events when navigating from acg.org */}
        {/* {!isSubpage ? (
          <Button
            mt={2}
            className="acg-registration-becomeAMember_submitButton"
            data-test="acg-registration-becomeAMember_submitButton"
            size="sm"
            variant="ghost"
            whiteSpace="normal"
            isDisabled={isLoading}
            onClick={() => {
              setIsBecomingMember?.off();
              if (
                webCode === 'eventregfees' ||
                webCode === 'acgmembershipdirectory' ||
                webCode === 'acgmemberdirectory'
              ) {
                navigate({ pathname: '/redirect', search });
              } else {
                emitter.emit(companyConstants.platformEvents.dataCollectorComplete, { path: '/' });
              }
            }}
          >
            {webCode === 'eventregfees'
              ? 'BECOME A MEMBER LATER, THEN REGISTER FOR EVENT'
              : 'BECOME A MEMBER LATER AND GO TO MY ACG'}
          </Button>
        ) : ( */}
        {!isSubpage && (
          <Button
            mt={2}
            isDisabled={isLoading}
            size="sm"
            variant="ghost"
            onClick={() => {
              setPage?.(1);
              reset?.(defaultValues);
              setIsValid?.off();
              setFilteredData([]);
              navigate('/registration', { state: { defaultStep: 'package-select' } });
            }}
          >
            {t('acg.firstTimeFlow.subpage.backToPackage')}
          </Button>
        )}
      </Box>
    </Center>
  );
};
