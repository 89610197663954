import { useEffect, useMemo } from 'react';
import {
  Flex,
  Box,
  Heading,
  SimpleGrid,
  VStack,
  FormLabel,
  CheckboxGroup,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  useBreakpointValue,
  Divider,
  Button,
  useToast,
  Icon,
  Spacer,
  Link,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Center,
  ModalOverlay,
  Stack,
} from '@companydotcom/potion';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Controller, useForm, FieldValues, useWatch } from 'react-hook-form';
import {
  InputField,
  PageDivider,
  TranslationErrorMessage,
  FontAwesomeIcon,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { useNavigate } from 'react-router-dom';
import { faCheckCircle, faClose } from '@fortawesome/pro-regular-svg-icons';
import {
  useGetGlobalUserQuery,
  useUpdateAccountBusinessMutation,
  useUpdateUserMutation,
} from '../../../services/user/user-api';
import { FaceCakePageLayout } from '../layouts';
import { useAwaitableFacade } from '../../../hooks';
import { PhotoEditor } from '../../../components/elements';
import { useSubmitProfileDataMutation } from '../../../services/facecake/facecake-api';
import { ProfileAvatar } from '../../../components/elements/profile-avatar/profile-avatar';
import yup from '../../../lib/yup';

const radioOptions = [
  { label: '1-50', value: '0' },
  { label: '51-200', value: '1' },
  { label: '201-499', value: '2' },
  { label: '500+', value: '3' },
];

const getProfileSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('common.inputs.firstName.error'),
    lastName: yup.string().required('common.inputs.lastName.error'),
    organization: yup.string().required('common.inputs.organization.error'),
    email: yup.string().required('common.inputs.email.error'),
    phone: yup.string().phone().nullable(),
    additionalInterest: yup.array().nullable(),
    website: yup.string().required('Please enter a valid website'),
    productCategory: yup.array().min(1, 'At least one must be selected').nullable(),
    accessories: yup.string().when('productCategory', {
      is: (productCategory: string[]) => productCategory?.includes('Accessories'),
      then: yup.string().required('common.misc.required'),
    }),
    other: yup.string().when('productCategory', {
      is: (productCategory: string[]) => productCategory?.includes('Other'),
      then: yup.string().required('common.misc.required'),
    }),
    numberOfSkus: yup.string().required('Required'),
    tryOn: yup.string().required('Required'),
  });

export const FaceCakeProfile = () => {
  const { t } = useTranslation();
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();
  const { data: globalUser, isLoading } = useGetGlobalUserQuery();
  const toast = useToast();
  const navigate = useNavigate();
  const snsInterface = useAwaitableFacade(globalUser);

  const [updateUser] = useUpdateUserMutation();
  const [submitProfileData] = useSubmitProfileDataMutation();
  const [updateAccountBusiness] = useUpdateAccountBusinessMutation();
  const { isOpen: isAvatarOpen, onOpen: onAvatarOpen, onClose: onAvatarClose } = useDisclosure();

  const avatarSize = useBreakpointValue({ base: '2xl', md: 'xl' });
  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-2xl' });

  const defaultValues: FieldValues = useMemo(
    () => ({
      prefix:
        globalUser?.mads?.['user-facecake'].find?.((i: any) => i.key === 'prefix')?.value || '',
      firstName: globalUser?.firstName || '',
      lastName: globalUser?.lastName || '',
      // @ts-ignore
      organization: globalUser?.organization || '',
      email: globalUser?.email || '',
      phone: globalUser?.phone ? parsePhoneNumber(globalUser?.phone, country).number : '',
      additionalInterest:
        globalUser?.account?.mads?.['user-facecake'].find?.(
          (i: any) => i.key === 'additionalInterest',
        )?.value || ([] as any),
      website: globalUser?.account?.business?.[0]?.website || '',
      productCategory:
        globalUser?.account?.mads?.['user-facecake'].find?.((i: any) => i.key === 'productType')
          ?.value || ([] as any),
      accessories:
        globalUser?.account?.mads?.['user-facecake'].find?.((i: any) => i.key === 'accessories')
          ?.value || '',
      other:
        globalUser?.account?.mads?.['user-facecake'].find?.((i: any) => i.key === 'other')?.value ||
        '',
      numberOfSkus:
        globalUser?.account?.mads?.['user-facecake'].find?.((i: any) => i.key === 'numberOfSkus')
          ?.value || '',
      tryOn:
        globalUser?.account?.mads?.['user-facecake'].find?.(
          (i: any) => i.key === 'availableForTryOnCount',
        )?.value || '',
    }),
    [
      country,
      globalUser?.account?.business,
      globalUser?.account?.mads,
      globalUser?.email,
      globalUser?.firstName,
      globalUser?.lastName,
      globalUser?.mads,
      // @ts-ignore
      globalUser?.organization,
      globalUser?.phone,
    ],
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getProfileSchema()),
    defaultValues: defaultValues,
    context: { country },
  });

  const productCategory = useWatch({ name: 'productCategory', control });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      const userRes = await updateUser({
        userId: globalUser?.userId as string,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        organization: values?.organization,
        phone: getFormattedPhoneNumber(values?.phone, country ?? 'US'),
      });
      const accountRes = await updateAccountBusiness({
        userId: globalUser?.userId,
        accountId: globalUser?.accountId,
        website: values?.website,
      });
      const profileRes = await submitProfileData({
        snsInterface,
        payload: {
          productCategory: values?.productCategory,
          numberOfSkus: values?.numberOfSkus,
          accessories: values?.accessories,
          other: values?.other,
          tryOn: values?.tryOn,
          additionalInterest: values?.additionalInterest,
        },
      });
      if (userRes && accountRes && profileRes) {
        toast({
          render: () => (
            <Flex
              flexDirection="row"
              color="white"
              p={3}
              bg="green.500"
              borderRadius="6px"
              mt={[0, 7]}
            >
              <Icon
                p={1}
                pr={2}
                className="fa-solid"
                as={FontAwesomeIcon}
                icon={faCheckCircle}
                boxSize="24px"
              />
              <Flex flexDirection="column" pr={2}>
                <Heading size="hl-md" fontWeight="700">
                  Profile successfully updated.
                </Heading>
              </Flex>
              <Spacer />
              <Icon
                translateX={2}
                translateY={4}
                as={FontAwesomeIcon}
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                icon={faClose}
                boxSize="16px"
                display="absolute"
                onClick={() => toast.closeAll()}
              />
            </Flex>
          ),
          duration: 7000,
          status: 'success',
          isClosable: true,
          position: 'top',
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    toast({
      render: () => (
        <Flex flexDirection="row" color="white" p={3} bg="green.500" borderRadius="6px" mt={[0, 7]}>
          <Icon
            p={1}
            pr={2}
            className="fa-solid"
            as={FontAwesomeIcon}
            icon={faCheckCircle}
            boxSize="24px"
          />
          <Flex
            as={Link}
            color="white"
            flexDirection="column"
            pr={2}
            onClick={() => {
              onAvatarOpen();
              toast.closeAll();
            }}
          >
            <Heading size="hl-md" fontWeight="700">
              Make it your own!
            </Heading>
            <Heading size="hs-sm" fontWeight="700">
              Upload a profile photo.
            </Heading>
          </Flex>
          <Spacer />
          <Icon
            translateX={2}
            translateY={4}
            as={FontAwesomeIcon}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
            }}
            icon={faClose}
            boxSize="16px"
            display="absolute"
            onClick={() => toast.closeAll()}
          />
        </Flex>
      ),
      duration: 7000,
      status: 'success',
      isClosable: true,
      position: 'top',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FaceCakePageLayout>
      <Flex flexDir={['column', null, 'row']} justify="space-between">
        <Box>
          <Heading size="hs-2xl">{t('icommerce.profilePage.heading')}</Heading>
          <PageDivider width="50px" mt={['4', null, '6']} alignSelf="flex-start" />
        </Box>
        <ProfileAvatar
          isLoading={isLoading}
          alignSelf={['center', null, 'auto']}
          size={avatarSize}
          mt={['6', null, '0']}
          src={globalUser?.avatar as string}
          onClick={() => onAvatarOpen()}
        />
        <Modal isOpen={isAvatarOpen} onClose={onAvatarClose} size="2xl">
          <ModalOverlay />
          <ModalContent textAlign="center">
            <Center flexDirection="column" my={[6, 12]}>
              <Heading size={headingSize}>Upload Avatar Photo</Heading>
              <ModalCloseButton />
              <ModalBody mx="auto">
                <PhotoEditor onClose={onAvatarClose} successToast />
              </ModalBody>
            </Center>
          </ModalContent>
        </Modal>
      </Flex>
      <SimpleGrid mt="12" as="form" columns={{ base: 1, md: 2 }} columnGap={['0', null, '16']}>
        <VStack spacing="12">
          <InputField
            className="facecake-profile__firstNameField"
            data-test="facecake-profile__firstNameField"
            register={register}
            name="firstName"
            label={t('common.inputs.firstName.label')}
            errors={errors}
          />
          <InputField
            className="facecake-profile__lastNameField"
            data-test="facecake-profile__lastNameField"
            register={register}
            name="lastName"
            label={t('common.inputs.lastName.label')}
            errors={errors}
          />
          <InputField
            className="facecake-profile__organizationField"
            data-test="facecake-profile__organizationField"
            register={register}
            name="organization"
            label={t('common.inputs.organization.label')}
            errors={errors}
          />
          <InputField
            className="facecake-profile__emailField"
            data-test="facecake-profile__emailField"
            register={register}
            name="email"
            label={t('common.inputs.email.label')}
            errors={errors}
          />

          <PhoneNumberInputField
            name="phone"
            label="Phone"
            className="facecake-profile__phoneField"
            data-test="facecake-profile__phoneField"
            control={control}
            country={country}
            onCountryChange={onCountryChange}
            errors={errors}
          />
          <InputField
            className="facecake-profile__websiteField"
            data-test="facecake-profile__websiteField"
            register={register}
            name="website"
            label="Website"
            errors={errors}
          />
        </VStack>
        <VStack
          spacing="12"
          bgColor={['none', null, 'blackAlpha.50']}
          mt={['6', null, '0']}
          padding={['0', null, '4']}
          borderRadius="base"
        >
          <FormControl
            id="additionalInterest"
            isInvalid={errors && !!errors?.['additionalInterest']}
          >
            <FormLabel htmlFor="additionalInterest">Additional areas of interest:</FormLabel>
            <Controller
              name="additionalInterest"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <CheckboxGroup {...rest}>
                  <VStack spacing="3" align="flex-start">
                    <Checkbox
                      value="Homepage Product Promotion"
                      key={1}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_Homepage-Product-Promotion"
                    >
                      Homepage Product Promotion
                    </Checkbox>
                    <Checkbox
                      value="AR Ads"
                      key={2}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_AR-ADs"
                    >
                      AR Ads
                    </Checkbox>
                    <Checkbox
                      value="Mobile App: iOS"
                      key={3}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_Mobile-App:-iOS"
                    >
                      Mobile App: iOS
                    </Checkbox>
                    <Checkbox
                      value="Mobile App: Android"
                      key={4}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_Mobile-App:-Android"
                    >
                      Mobile App: Android
                    </Checkbox>
                  </VStack>
                </CheckboxGroup>
              )}
            />
          </FormControl>
          <FormControl id="productCategory" isInvalid={errors && !!errors?.['productCategory']}>
            <FormLabel
              className="facecake-lead-gen__productCategory"
              name="productCategory"
              label="Product Category"
            >
              In addition to eyewear, I am interested in:
            </FormLabel>
            <Controller
              name="productCategory"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <CheckboxGroup {...rest}>
                  <VStack spacing="3" align="flex-start">
                    <Checkbox
                      value="Jewelry"
                      key={0}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_Jewelry"
                    >
                      Jewelry
                    </Checkbox>
                    <Checkbox
                      value="Makeup"
                      key={1}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_Make-Up"
                    >
                      Makeup
                    </Checkbox>
                    <Checkbox
                      value="Clothing"
                      key={2}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_Clothing"
                    >
                      Clothing
                    </Checkbox>
                    <Checkbox
                      value="Handbags"
                      key={3}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_Handbags"
                    >
                      Handbags
                    </Checkbox>
                    <Checkbox
                      value="Accessories"
                      key={4}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_Accessories"
                    >
                      Accessories
                    </Checkbox>
                    {productCategory?.includes('Accessories') ? (
                      <InputField
                        className="facecake-lead-gen__accessories"
                        data-test="facecake-lead-gen__accessories"
                        register={register}
                        name="accessories"
                        errors={errors}
                        width={['343px', '340px']}
                      />
                    ) : null}
                    <Checkbox
                      value="Other"
                      key={5}
                      ref={ref}
                      size="lg"
                      className="facecake-form-getToKnowYou__checkboxField"
                      data-test="facecake-form-getToKnowYou__checkboxField_Other"
                    >
                      Other
                    </Checkbox>
                    {productCategory?.includes('Other') ? (
                      <InputField
                        className="facecake-lead-gen__other"
                        data-test="facecake-lead-gen__other"
                        register={register}
                        name="other"
                        errors={errors}
                        width={['343px', '340px']}
                      />
                    ) : null}
                  </VStack>
                </CheckboxGroup>
              )}
            />
            {errors && (
              <TranslationErrorMessage errors={errors} name="productCategory">
                {/* @ts-ignore */}
                {errors?.['productCategory']?.message}
              </TranslationErrorMessage>
            )}
          </FormControl>
          <Controller
            name="numberOfSkus"
            control={control}
            render={({ field }) => (
              <FormControl id="numberOfSkus" isInvalid={errors && !!errors?.['numberOfSkus']}>
                <FormLabel htmlFor="numberOfSkus">Current number of eyewear styles?</FormLabel>
                <RadioGroup {...field}>
                  <VStack spacing="3" align="flex-start">
                    {radioOptions.map((option, i) => (
                      <Radio
                        key={i}
                        value={option.value}
                        className="facecake-profile__radioField"
                        data-test={`facecake-profile__radioField_${option?.value}`}
                      >
                        {option.label}
                      </Radio>
                    ))}
                  </VStack>
                </RadioGroup>
                {errors && (
                  <TranslationErrorMessage errors={errors} name="numberOfSkus">
                    {errors?.['numberOfSkus']?.message}
                  </TranslationErrorMessage>
                )}
              </FormControl>
            )}
          />
          <InputField
            className="facecake-profile__tryOnField"
            data-test="facecake-profile__tryOnField"
            register={register}
            name="tryOn"
            label="How many eyewear styles are available for Try-On?"
            errors={errors}
          />
        </VStack>
      </SimpleGrid>
      <Divider
        borderColor="gray.300"
        orientation="horizontal"
        my="6"
        display={['none', null, 'inherit']}
      />
      <Stack justifyContent="space-between" direction={['column', 'row']}>
        <Button
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
          isLoading={isSubmitting}
          width={['full', 'auto']}
          size="lg"
          mt={['12', null, '0']}
        >
          {t('icommerce.profilePage.submitButton')}
        </Button>
        <Button
          variant="outline"
          onClick={() => navigate('/logout', { replace: true })}
          isDisabled={isSubmitting}
          width={['full', 'auto']}
          size="lg"
          mt={['12', null, '0']}
        >
          {t('icommerce.profilePage.logoutButton')}
        </Button>
      </Stack>
    </FaceCakePageLayout>
  );
};
