import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Center, Box, Button, useBreakpointValue, Heading, Flex } from '@companydotcom/potion';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { TextareaField, PageDivider } from '@companydotcom/ui';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  useGetAcgUserProfileV2Query,
  useGetAcgQueryArgs,
  useUpdateAcgUserV2Mutation,
} from '../../../../services/acg/acg-api-v2';

const EditBioSchema = () => yup.object().shape({ bio: yup.string().notRequired() });

export interface EditBioProps {
  onClose: () => void;
  isMobile: boolean;
  formData?: any;
}

export const EditBio = ({ onClose, isMobile, formData }: EditBioProps) => {
  const { t } = useTranslation();
  const args = useGetAcgQueryArgs();
  const updateUserArgs = useGetAcgQueryArgs(['email', 'source']);
  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-2xl' });
  const { data: globalUser } = useGetGlobalUserQuery();
  const [updateAcgUser, { isLoading }] = useUpdateAcgUserV2Mutation();
  const { data: acgUserProfileData } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const defaultValues = {
    bio: formData?.bio || '',
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(EditBioSchema()),
    defaultValues,
  });

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      if (globalUser) {
        updateAcgUser({
          ...updateUserArgs,
          payload: {
            lastProfileUpdateVersion: acgUserProfileData?.lastProfileUpdateVersion ?? 1,
            bio: data.bio === '' ? ' ' : data.bio,
          },
        });
        onClose();
      }
    } catch (error) {
      console.log('EditBio onSubmit error: ', error);
    }
  };
  return (
    <Center flexDirection="column" as="form">
      <Heading size={headingSize} fontWeight="700" mt={[6, 12]} mb={[2, 2, 12]}>
        {t('acg.myProfile.forms.editBio.bio')}
      </Heading>
      {isMobile && <PageDivider width={50} mb={12} />}
      <Flex justify="center" width="full">
        <TextareaField
          register={register}
          name="bio"
          minH={200}
          maxLength={4000}
          autoFocus
          formControlStyles={{
            maxW: 560,
          }}
        />
      </Flex>
      <Box my={12}>
        <Button
          variant="outline"
          mr="2"
          onClick={onClose}
          size="sm"
          isDisabled={isSubmitting || isLoading}
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          size="sm"
          isLoading={isSubmitting || isLoading}
          isDisabled={!isValid || !isDirty}
        >
          {t('common.buttons.save')}
        </Button>
      </Box>
    </Center>
  );
};
