import React from 'react';
import { AppSpinner } from '@companydotcom/ui';
import { useSourceConfig } from '@companydotcom/providers';
import { RouteMap, Maybe } from '@companydotcom/types';
import { RedirectZone } from '../../../../components/elements';

export const AcgRedirectZone = () => {
  const sourceConfig = useSourceConfig();

  return sourceConfig?.redirectConfig?.routeMap ? (
    <RedirectZone
      keyParam="WebCode"
      routes={mapRedirectConfigToRouteMap(sourceConfig?.redirectConfig?.routeMap)}
    />
  ) : (
    <AppSpinner
      className="acg-redirect_spinner"
      containerStyles={{
        minHeight: '100vh',
        flexGrow: 1,
      }}
    />
  );
};

export const mapRedirectConfigToRouteMap = (sourceRedirects?: Maybe<Array<Maybe<RouteMap>>>) => {
  if (!sourceRedirects) {
    return {};
  }

  return sourceRedirects.reduce((acc, redirect) => {
    if (redirect) {
      return { ...acc, [redirect.param]: redirect.path };
    }
    return {
      ...acc,
    };
  }, {} as Record<string, string>);
};

// Sample links for testing external routing

/* EVENT */
// http://acg-dev.companydev.localhost:3000/redirect?Site=acg&WebCode=EventRegFees&RegPath=EventRegFees&Reg_evt_key=fcdd8f21-b0ab-42b7-97f1-d756faf1ad91&ObjectKeyFrom=1a83491a-9853-4c87-86a4-f7d95601c2e2&ParentObject=CentralizedOrderEntry&ParentDataObject=Registrant&DoNotSave=yes&action=Add&WizardStep=de752fe0-7419-4c3c-a8c6-33b7905ab783

/* Membership Directory */
// http://acg-dev.companydev.localhost:3000/redirect?Site=acg&WebCode=ACGMembershipDirectory

/* JOIN */
// http://acg-dev.companydev.localhost:3000/redirect?webcode=ACGChapterMemberships&Site=acg

/* JOIN WITH SPECIFIC CHAPTER/PACKAGE */
// http://acg-dev.companydev.localhost:3000/redirect?Site=acg&WebCode=CSCAddMembershipM&DoNotSave=yes&ParentObject=CentralizedOrderEntry&ParentDataObject=Membership&ObjectKeyFrom=1a83491a-9853-4c87-86a4-f7d95601c2e2&mbr_asn_code=ACG&mbr_pak_prd_key=8dedf8e2-666a-488b-b88f-e297f23971dd&mbr_chp_cst_key=edee5c7a-19a3-4064-8661-8dfd59632941&mbr_mbt_key=9fabf54a-1875-46f8-994c-03c17668e70f&mbr_mbs_key=2c8f7f46-5907-4ee1-abc5-dcdd9f4bb955

/* JOIN WITH SPECIFIC CHAPTER */
// http://acg-dev.companydev.localhost:3000/redirect?Site=acg&WebCode=CSCAddMembershipM&DoNotSave=yes&ParentObject=CentralizedOrderEntry&ParentDataObject=Membership&ObjectKeyFrom=1a83491a-9853-4c87-86a4-f7d95601c2e2&mbr_asn_code=ACG&mbr_chp_cst_key=edee5c7a-19a3-4064-8661-8dfd59632941&mbr_mbt_key=9fabf54a-1875-46f8-994c-03c17668e70f&mbr_mbs_key=2c8f7f46-5907-4ee1-abc5-dcdd9f4bb955

// LOGIN
// http://acg-dev.companydev.localhost:3000/redirect?Site=acg&WebCode=LoginRequired&URL_Success=https%3A%2F%2Fsandbox.sd.acg.org%2F%3Fnetforum_token%3D%7Btoken%7D

// http://acg-dev.companydev.localhost:3000/redirect?Site=ACG&WebCode=LoginRequired&URL_Success=https%3A%2F%2Fwww.acg.org%2F%3Fnetforum_token%3D%7Btoken%7D
// CANADIAN
// http://acg-stage.companydev.localhost:3000/event-registration?Site=ACG&WebCode=EventRegFees&RegPath=EventRegFees&Reg_evt_key=fc13d61f-1a90-47e7-8d63-be5ecff5cbd6
