import { useSearchParams } from 'react-router-dom';
import { AddGuest } from '../../acg-add-guest';
import { useAppSelector } from '../../../../hooks';
import { selectActiveRegistrant } from '../event-registration-slice';
import { EventForRegistration, EventForPurchaseGuest } from '../../../../services/acg/acg.types';

interface EventRegistrationAddGuestProps {
  atCapacity?: boolean;
}

export const EventRegistrationAddGuest = (props: EventRegistrationAddGuestProps) => {
  const [searchParams] = useSearchParams();
  const registrant = useAppSelector(selectActiveRegistrant);

  const createEventForPurchase = (
    _formData?: Record<string, any>,
    eventForRegistration?: EventForRegistration,
    guestsToRegister?: EventForPurchaseGuest[],
  ) => {
    const showWaitListAlert =
      eventForRegistration?.hasWaitList &&
      eventForRegistration?.eventCapacity &&
      eventForRegistration?.totalRegistered &&
      guestsToRegister &&
      eventForRegistration?.totalWaitList
        ? eventForRegistration?.hasWaitList &&
          eventForRegistration.totalRegistered + guestsToRegister?.length + 1 >
            eventForRegistration.eventCapacity
        : false;

    return {
      showWaitListAlert,
      waitListedRegistrants: eventForRegistration?.totalWaitList,
      eventCapacity: eventForRegistration?.eventCapacity,
      totalRegistered: eventForRegistration?.totalRegistered,
      eventRecordKey: eventForRegistration?.eventKey,
      registrant: {
        registrationKey: registrant?.registrantId,
        badgeName: registrant?.badgeName,
        organizationName: registrant?.organizationName,
        title: registrant?.title,
        fees: registrant?.fees,
        customQuestions: registrant?.customQuestions || {},
      },
      guests: guestsToRegister,
      registrantType: eventForRegistration?.registrantAndFees?.[0]?.registrantType,
    };
  };

  return (
    <AddGuest
      variant="event-registration"
      createEventForPurchaseHandler={createEventForPurchase}
      eventId={searchParams.get('Reg_evt_key') as string}
      {...props}
    />
  );
};
