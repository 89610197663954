import React from 'react';
import { Box } from '@companydotcom/potion';
import { useLocation, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Paywall } from '../../features/paywall';
import { useGetGlobalUserQuery } from '../../services/user/user-api';

export interface PaymentStateProps {
  email?: string | null;
  productId?: string | null;
  productid?: string | null;
  planId?: string | null;
  planid?: string | null;
  userId?: string | null;
  orderid?: string | null;
  referrer?: string | null;
}

export const Payment = () => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const location = useLocation();
  const paymentState = (location?.state as PaymentStateProps) || {};
  const { productid, planid } = paymentState;
  const [searchParams] = useSearchParams();
  let { email, productId, planId, orderid, referrer, userId } = paymentState;

  // 3rd party flow
  if (isEmpty(paymentState) || paymentState?.referrer?.includes('payment')) {
    planId = searchParams.get('planId') || searchParams.get('planid');
    productId = searchParams.get('productId') || searchParams.get('productid');
    email = searchParams.get('email');
    orderid = searchParams.get('orderid');
    referrer = searchParams.get('referrer');
    userId = searchParams.get('userId') || globalUser?.userId;
  } else if (planId === undefined && planid) {
    planId = planid;
  }
  const directFlow = !isEmpty(planId);

  return (
    <Box className="payment_page">
      <Paywall
        productId={productId || productid}
        planId={planId}
        userId={userId}
        email={email}
        orderid={orderid}
        referrer={referrer}
        directFlow={directFlow}
        externalFlow
      />
    </Box>
  );
};
