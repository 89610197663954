import * as React from 'react';
import { potion, forwardRef, ThemingProps, HTMLPotionProps } from '../../system';
import { cx, __DEV__, createContext } from '../../utils';
import { useRadioGroup, UseRadioGroupProps, UseRadioGroupReturn } from './use-radio-group';

export interface RadioGroupContext
  extends Pick<UseRadioGroupReturn, 'onChange' | 'value' | 'name' | 'isDisabled' | 'isFocusable'>,
    Omit<ThemingProps<'Radio'>, 'orientation'> {}

const [RadioGroupProvider, useRadioGroupContext] = createContext<RadioGroupContext>({
  name: 'RadioGroupContext',
  strict: false,
});

export { useRadioGroupContext };

type Omitted = 'onChange' | 'value' | 'defaultValue' | 'defaultChecked' | 'children';
export interface RadioGroupProps
  extends UseRadioGroupProps,
    Omit<HTMLPotionProps<'div'>, Omitted>,
    Omit<ThemingProps<'Radio'>, 'orientation'> {
  children: React.ReactNode;
}

/**
 * Used for multiple radios which are bound in one group,
 * and it indicates which option is selected.
 */
export const RadioGroup = forwardRef<RadioGroupProps, 'div'>((props, ref) => {
  const { colorScheme, size, variant, children, className, isDisabled, isFocusable, ...rest } =
    props;

  const { value, onChange, getRootProps, name, htmlProps } = useRadioGroup(rest);

  const group = React.useMemo(
    () => ({
      name,
      size,
      onChange,
      colorScheme,
      value,
      variant,
      isDisabled,
      isFocusable,
    }),
    [name, size, onChange, colorScheme, value, variant, isDisabled, isFocusable],
  );

  const groupProps = getRootProps(htmlProps, ref);
  const _className = cx('potion-radio-group', className);

  return (
    <RadioGroupProvider value={group}>
      <potion.div {...groupProps} className={_className}>
        {children}
      </potion.div>
    </RadioGroupProvider>
  );
});

if (__DEV__) {
  RadioGroup.displayName = 'RadioGroup';
}
