import { Dict } from '../utils';
import { SystemStyleObject } from '../styled-system';

export interface StyleConfig {
  baseStyle?: SystemStyleObject;
  sizes?: { [size: string]: SystemStyleObject };
  variants?: { [variant: string]: SystemStyleObject };
  defaultProps?: {
    size?: string;
    variant?: string;
    colorScheme?: string;
  };
}

export interface MultiStyleConfig {
  baseStyle?: { [part: string]: SystemStyleObject };
  sizes?: { [size: string]: { [part: string]: SystemStyleObject } };
  variants?: { [variants: string]: { [part: string]: SystemStyleObject } };
  defaultProps?: StyleConfig['defaultProps'];
}

export interface GlobalStyleProps {
  colorScheme: string;
  theme: Dict;
}

export type GlobalStyles = {
  global?: SystemStyleObject | ((props: GlobalStyleProps) => SystemStyleObject);
};

export type JSXElementStyles = {
  [K in keyof JSX.IntrinsicElements]?: SystemStyleObject;
};

export type Styles = GlobalStyles & JSXElementStyles;

export function orient(options: {
  orientation?: 'vertical' | 'horizontal';
  vertical: any;
  horizontal: any;
}) {
  const { orientation, vertical, horizontal } = options;
  if (!orientation) return {};
  return orientation === 'vertical' ? vertical : horizontal;
}

export type StyleFunctionProps = {
  colorScheme: string;
  colorMode: 'light' | 'dark';
  orientation?: 'horizontal' | 'vertical';
  theme: Dict;
  [key: string]: any;
};

export type SystemStyleFunction = (props: StyleFunctionProps) => SystemStyleObject;
