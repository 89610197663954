import React from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useTabStepper,
  Flex,
  useMediaQuery,
} from '@companydotcom/potion';
import { BasicInformation } from './basic-information';
import { HoursOfOperation } from './hours-of-operation';
import { PaymentAccepted } from './payment-accepted';
import { ContactInformation } from './contact-information';
import { SocialMedia } from './social-media';
import { RecommendedSteps } from './recommended-steps';
import { MobilePageTabs } from '../../../../components/elements';
import { PublisherStatusList } from './publisher-status-list';

const blpSteps = [
  {
    slug: 'basic-information',
    label: 'Basic Information',
    component: <BasicInformation />,
  },
  {
    slug: 'hours-of-operation',
    label: 'Hours of Operation',
    component: <HoursOfOperation />,
  },
  {
    slug: 'payment-accepted',
    label: 'Payment Accepted',
    component: <PaymentAccepted />,
  },
  {
    slug: 'contact-information',
    label: 'Contact Information',
    component: <ContactInformation />,
  },
  {
    slug: 'social-media',
    label: 'Social Media & Photos',
    component: <SocialMedia />,
  },
];

export interface BLPProfileFlowProps {
  sharedComponentState?: any;
}

export const BLPProfileFlow: React.FC<BLPProfileFlowProps> = props => {
  const { sharedComponentState } = props;
  const { tabIndex, handleTabsChange, goToStep } = useTabStepper({ steps: blpSteps });
  const [isMobile] = useMediaQuery('(max-width: 48em)');

  return (
    <Tabs variant="page" index={tabIndex} onChange={handleTabsChange}>
      {isMobile ? (
        <MobilePageTabs steps={blpSteps} tabIndex={tabIndex} goToStep={goToStep} />
      ) : (
        <TabList width="full">
          {blpSteps.map(step => (
            <Tab key={step.slug}>{step.label}</Tab>
          ))}
        </TabList>
      )}
      <TabPanels mt={[0, null, null, 4]} width="full">
        {blpSteps.map(step => (
          <TabPanel key={step.slug}>
            <Flex width="full" flexDirection={['column', null, null, 'row']}>
              <Flex
                width="full"
                flexDirection="column"
                pt={3}
                px={[0, null, null, 4]}
                maxW={[null, null, null, 344]}
                borderRightStyle="solid"
                borderRightWidth={[0, null, null, 1]}
                borderRightColor="gray.200"
                className="reccomended-steps-wrapper"
              >
                {sharedComponentState.showRecSteps && (
                  <RecommendedSteps
                    goToStep={goToStep}
                    setShowRecSteps={sharedComponentState?.setShowRecSteps}
                    restartGmbProcess={sharedComponentState.restartGmbProcess}
                    listing={
                      sharedComponentState?.listing?.data
                        ? sharedComponentState.listing.data
                        : sharedComponentState.listing
                    }
                  />
                )}
                <PublisherStatusList
                  showRecSteps={sharedComponentState.showRecSteps}
                  listing={
                    sharedComponentState?.listing?.data
                      ? sharedComponentState.listing.data
                      : sharedComponentState.listing
                  }
                  restartGmbProcess={sharedComponentState.restartGmbProcess}
                />
              </Flex>
              {React.cloneElement(step.component, {
                ...sharedComponentState,
              })}
            </Flex>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
