import gql from 'graphql-tag';

export const SET_USER_CUI = gql`
  mutation SetUserCUI(
    $userId: String!
    $cuiId: String!
    $isDismissed: Boolean
    $isCompleted: Boolean
  ) {
    updateUserCUI(
      userId: $userId
      cuiId: $cuiId
      isDismissed: $isDismissed
      isCompleted: $isCompleted
    )
  }
`;
