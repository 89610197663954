import { potion, HTMLPotionProps } from '../../system';

export interface SpacerProps extends HTMLPotionProps<'div'> {}

/**
 * A flexible flex spacer that expands along the major axis of its containing flex layout.
 * It renders a `div` by default, and takes up any available space.
 *
 * @see Docs https://chakra-ui.com/flex#using-the-spacer
 */
export const Spacer = potion('div', {
  baseStyle: {
    flex: 1,
    justifySelf: 'stretch',
    alignSelf: 'stretch',
  },
});

Spacer.displayName = 'Spacer';
