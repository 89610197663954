import React, { useState, useEffect, useCallback } from 'react';
import {
  Center,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  PaginationContainer,
  Pagination,
  PaginationItem,
  HStack,
  usePagination as useTabPagination,
  useBreakpointValue,
  VStack,
  useMediaQuery,
  TableContainer,
  Skeleton,
} from '@companydotcom/potion';
import { PageDivider, FontAwesomeIcon, AppSpinner } from '@companydotcom/ui';
import { useTable, useSortBy, Column, Cell, usePagination } from 'react-table';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { faCaretDown as faCaretDownLight } from '@fortawesome/pro-light-svg-icons';
import type { InvoicesSharedProps } from './invoices';
import { formatCheckoutPrice, getFriendlyACGDate } from '../../../acg/shared';
import { useLazyGetInvoicePdfByInvoiceIdQuery } from '../../../../services/payment/payment-api';

const getFormatedDate = (timestamp: number) => {
  const timestampMilliseconds = timestamp * 1000;
  const date = new Date(timestampMilliseconds);
  // Get the components of the US date (month, day, and year)
  const month = date.getUTCMonth() + 1; // Months are 0-indexed, so we add 1
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  // Format the date as a string in US format (MM/DD/YYYY)
  const formattedDate = `${month.toString().padStart(2, '0')}/${day
    .toString()
    .padStart(2, '0')}/${year}`;
  return formattedDate;
};

export const InvoicesList = (props: InvoicesSharedProps) => {
  const { nextStep, invoiceData = [''], isLoading, setCurrentInvoice } = props;

  const data = React.useMemo(() => invoiceData, [invoiceData]);

  const [getInvoicePdfByInvoiceId] = useLazyGetInvoicePdfByInvoiceIdQuery();
  const getInvoiceDownloadUrl = useCallback(
    async (invoiceId: string) => {
      const { data } = await getInvoicePdfByInvoiceId({ invoiceId });
      fetch(JSON.parse(data).download_url)
        .then(response => {
          const downloadBlob = response.blob();
          return downloadBlob;
        })
        .then(pdfData => {
          const url = window.URL.createObjectURL(pdfData);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'invoice.pdf';
          link.click();
          window.URL.revokeObjectURL(url);
        });
    },
    [getInvoicePdfByInvoiceId],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Invoice #',
        accessor: 'invoiceNumber',
        Cell: ({ cell }: { cell: Cell }) =>
          isLoading ? <Skeleton height={5} /> : <Text fontSize="md">{cell?.value ?? ''}</Text>,
      },
      {
        Header: 'Invoice Total',
        accessor: 'amount',
        Cell: ({ cell }: { cell: Cell }) =>
          isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Text fontSize="md">{`$${formatCheckoutPrice(cell?.value / 100)}` ?? ''}</Text>
          ),
      },
      {
        Header: 'Invoice Date',
        accessor: 'invoiceDate',
        Cell: ({ cell }: { cell: Cell }) =>
          isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Text fontSize="md">{getFormatedDate(cell?.value) ?? ''}</Text>
          ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ cell }: { cell: Cell }) =>
          isLoading ? (
            <Skeleton height={5} />
          ) : (
            <Button
              variant="outline"
              size="lg"
              value={cell.row.values.name}
              onClick={() => getInvoiceDownloadUrl(cell?.row?.values?.invoiceNumber)}
            >
              Download
            </Button>
          ),
      },
    ],
    [getInvoiceDownloadUrl, isLoading],
  );

  const headingSize = useBreakpointValue({ base: 'xl', sm: '2xl' });

  return (
    <Center
      className="invoices__invoicesListStep"
      flexDirection="column"
      bg="transparent"
      py={[0, 8, 12]}
      px={[0, 4]}
    >
      <Box width="full" maxW={908} p={4}>
        <Box width="full" textAlign="left" mb={7}>
          <Heading size={headingSize}>Invoices</Heading>
          <PageDivider mt={[2, 6]} mx="inherit" />
        </Box>

        <SortableTable
          nextStep={nextStep}
          setCurrentInvoice={setCurrentInvoice}
          isLoading={isLoading}
          columns={columns}
          data={data}
        />
      </Box>
    </Center>
  );
};
interface SortableTableProps {
  columns: Column<object>[];
  data: any;
  nextStep?: () => void;
  setCurrentInvoice?: (arg: any | {}) => void;
  isLoading: boolean | undefined;
}

export const SortableTable = ({ data, columns, isLoading }: SortableTableProps) => {
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const [pages, setPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageCount = pageNumber * 10 - 9;

  const [getInvoicePdfByInvoiceId] = useLazyGetInvoicePdfByInvoiceIdQuery();
  const getInvoiceDownloadUrl = useCallback(
    async (invoiceId: string) => {
      const { data } = await getInvoicePdfByInvoiceId({ invoiceId });
      fetch(JSON.parse(data).download_url)
        .then(response => {
          const downloadBlob = response.blob();
          return downloadBlob;
        })
        .then(pdfData => {
          const url = window.URL.createObjectURL(pdfData);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'invoice.pdf';
          link.click();
          window.URL.revokeObjectURL(url);
        });
    },
    [getInvoicePdfByInvoiceId],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, gotoPage } = useTable(
    {
      columns,
      //@ts-ignore
      data,
      initialState: {
        pageSize: isMobile ? 6 : 10,
        sortBy: [
          {
            id: 'invoiceDate',
            desc: true,
          },
        ],
      },
      autoResetHiddenColumns: false,
    },
    useSortBy,
    usePagination,
  );
  const paginationBreakPoint = useBreakpointValue({ base: 0, md: 1 });

  const { items } = useTabPagination({
    count: pages,
    onChange: (e: any, value: number) => {
      gotoPage(value - 1);
      setPageNumber(value);
    },
    siblingCount: paginationBreakPoint,
  });

  useEffect(() => {
    if (data) {
      setPages(Math.ceil(data.length / 10));
    }
  }, [data]);

  return (
    <>
      {!isMobile &&
        (isLoading ? (
          <Box height="21px" mb={4} />
        ) : (
          <Heading size="hl-xs" fontWeight="700" mb={4}>
            {`Showing ${data.length ? pageCount : '0'} -
            ${
              data.length <= 10
                ? data.length
                : pageNumber * 10 > data.length
                ? data.length
                : pageNumber * 10
            }
            of ${data?.length}`}
          </Heading>
        ))}
      <TableContainer>
        <Table {...getTableProps()}>
          {!isMobile && (
            <Thead opacity={isLoading ? 0.5 : 1} borderWidth="1px" borderColor="gray.300">
              {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Th
                      bgColor="white"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      py="8px"
                      textTransform="none"
                      borderColor="gray.300"
                    >
                      {column.Header !== '' ? (
                        <HStack spacing={1}>
                          <Heading size="hl-md" fontWeight="700" color="black">
                            {column.render('Header')}
                          </Heading>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                icon={faCaretDown}
                                boxSize={5}
                                aria-label="sorted descending"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCaretUp}
                                boxSize={5}
                                aria-label="sorted ascending"
                              />
                            )
                          ) : (
                            <FontAwesomeIcon
                              icon={faCaretDownLight}
                              boxSize={5}
                              aria-label="sorted ascending"
                            />
                          )}
                        </HStack>
                      ) : null}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
          )}
          {isLoading ? (
            <Tbody>
              <Tr>
                <Td border="0" colSpan={5}>
                  <AppSpinner
                    description="Retrieving your invoices..."
                    containerStyles={{
                      mt: 12,
                      mx: 'auto',
                    }}
                  />
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {!data || data.length === 0 ? (
                <Tr borderBottomWidth="1px">
                  <Td textStyle="md" textAlign="center" py={8} colSpan={columns?.length ?? 5}>
                    There are no invoices to display
                  </Td>
                </Tr>
              ) : (
                page.map((row, i) => {
                  prepareRow(row);
                  return isMobile ? (
                    isLoading ? (
                      <Skeleton key={i} height={5} />
                    ) : (
                      <Box
                        key={i}
                        borderRadius="sm"
                        shadow="md"
                        mb={2}
                        className="invoice"
                        bg="white"
                        p={4}
                      >
                        <VStack width="full" align="flex-start">
                          <Text fontSize="sm" fontWeight="700">
                            {/* @ts-ignore */}
                            {data && data?.[i]?.id}
                          </Text>
                          <HStack width="full" align="flex-end" justify="space-between">
                            <VStack align="flex-start">
                              <Text textStyle="sm">
                                {/* @ts-ignore */}
                                {getFriendlyACGDate(data?.[i].date)}
                              </Text>
                              <Text textStyle="xs">
                                {/* @ts-ignore */}
                                {`$${formatCheckoutPrice(data?.[i]?.amount_paid)}`}
                              </Text>
                            </VStack>
                            <Button
                              colorScheme="blue"
                              color="blue.500"
                              variant="outline"
                              size="sm"
                              onClick={() => getInvoiceDownloadUrl(data?.[i]?.invoiceNumber)}
                            >
                              Download
                            </Button>
                          </HStack>
                        </VStack>
                      </Box>
                    )
                  ) : (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <Td
                          borderColor="gray.300"
                          borderBottomWidth="1px"
                          py={7}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </Td>
                      ))}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!data ||
      data.length === 0 ||
      isLoading ||
      data.length <= 10 ||
      (isMobile && data.length <= 6) ? null : (
        <Center mt={6}>
          <Pagination variant="outline" size="sm" colorScheme="pink">
            <PaginationContainer>
              {/* @ts-ignore */}
              {items.map((item, i) => (
                <PaginationItem key={i} {...item} />
              ))}
            </PaginationContainer>
          </Pagination>
        </Center>
      )}
    </>
  );
};
