import React from 'react';
import { Flex, Text } from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import type { TileComponentProps } from '../../types';

export interface EngagementStatusProps {
  statuses: {
    label: string;
    quantity?: string | number;
    dataForNoOfDays?: number;
  }[];
}

export interface TileSocialMediaEngagementStatusProps extends TileComponentProps {}

export const TileSocialMediaEngagementStatus: React.FC<
  TileSocialMediaEngagementStatusProps
> = props => {
  const { eventData, componentTheme } = props;
  const { data } = useEventData<EngagementStatusProps>(eventData);

  return (
    <Flex
      className="tile__social-media-engagement-status"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="full"
      px={6}
      flexGrow={1}
      sx={componentTheme}
    >
      {data?.statuses
        .filter(status => status.label.includes('Engagements'))
        .map(item => (
          <StatusCard
            key={item.label}
            label={item.label}
            quantity={item.quantity}
            dataForNoOfDays={item.dataForNoOfDays}
          />
        ))}
    </Flex>
  );
};

interface StatusCardProps {
  label: string;
  quantity?: string | number;
  dataForNoOfDays?: number;
}

function StatusCard({ label, quantity, dataForNoOfDays }: StatusCardProps) {
  function renderIcon(data?: string) {
    switch (data) {
      case 'Engagements':
        return <EngagementsIcon />;
      default:
        return <EngagementsIcon />;
    }
  }
  return (
    <Flex
      width="full"
      className="status-card"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      marginBottom="auto"
      borderRadius="sm"
      py={2}
      px={4}
    >
      <Flex flexDirection="row" alignItems="center" justifyContent="flex-start">
        {renderIcon(label)}
      </Flex>
      <Flex mt={4}>
        {quantity?.toString() ? (
          <Text textStyle="xl" fontWeight="bold" mr={2} sx={{ alignItems: 'left' }}>
            {quantity}
          </Text>
        ) : (
          <Text
            textStyle="xl"
            fontWeight="bold"
            fontSize={5}
            color="red.500"
            mr={2}
            sx={{ alignItems: 'left' }}
          >
            !
          </Text>
        )}
        <Text textStyle="lg" mt={1} sx={{ alignItems: 'right' }}>
          {label}
        </Text>
      </Flex>
      <Text textStyle="sm">Over the last {`${dataForNoOfDays}`} days</Text>
    </Flex>
  );
}

function EngagementsIcon({ color = '#084066' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="118" height="88" viewBox="0 0 118 88">
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF" stroke={color} strokeWidth="2">
          <g>
            <path
              d="M114.333 15.667c.553 0 1.053.224 1.415.585.361.362.585.862.585 1.415h0V73.84c0 .553-.224 1.053-.585 1.414-.362.362-.862.586-1.415.586h0-11.715c-.066 4.073-.418 7.09-1.094 9.042-.262.758-.563 1.339-.936 1.72-.248.253-.529.397-.855.397-2.301 0-7.175-3.881-14.686-11.159h0-52.714c-.552 0-1.052-.224-1.414-.586-.362-.361-.586-.861-.586-1.414h0V17.667c0-.553.224-1.053.586-1.415.362-.361.862-.585 1.414-.585h0z"
              transform="translate(-3240 -249) translate(3240 249)"
            />
            <path
              d="M85 1c.552 0 1.052.224 1.414.586.362.362.586.862.586 1.414h0v56.175c0 .552-.224 1.052-.586 1.414-.362.362-.862.586-1.414.586h0-11.715c-.066 4.073-.419 7.09-1.094 9.042-.263.757-.563 1.338-.936 1.719-.249.253-.529.397-.855.397-2.302 0-7.176-3.88-14.686-11.158h0H3c-.552 0-1.052-.224-1.414-.586-.362-.362-.586-.862-.586-1.414h0V3c0-.552.224-1.052.586-1.414C1.948 1.224 2.448 1 3 1h0z"
              transform="translate(-3240 -249) translate(3240 249) matrix(-1 0 0 1 88 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
