import React from 'react';
import { Flex, Box, Text, useMultiStyleConfig, Stack } from '@companydotcom/potion';
import { StepperProps } from '@companydotcom/ui';

export const AcgStepper: React.FC<StepperProps> = props => {
  const { steps, activeStep } = props;
  const styles = useMultiStyleConfig('Stepper', props);
  const stepperSteps = steps.filter(s => s?.label);

  if (!Array.isArray(steps) || !steps.length) {
    console.warn('🚨 ~ Stepper ~ steps is not an array or is empty');
  }

  return (
    <Flex
      className="stepper"
      flexDirection="row"
      alignItems="flex-end"
      justifyContent="center"
      py={[12]}
      sx={styles.container}
    >
      {stepperSteps &&
        stepperSteps?.map(({ label, slug }, i) => {
          return (
            <Stack key={slug} pr={[1]} width={['144px']} spacing={0}>
              <Text
                fontSize="sm"
                fontWeight="medium"
                textAlign="center"
                lineHeight={6}
                width={28}
                alignSelf="center"
                // @ts-ignore
                color={
                  i === activeStep
                    ? styles.activeStep?.['color'] ?? 'inherit'
                    : styles.inactiveStep?.['color']
                    ? styles.inactiveStep?.['color']
                    : styles.label ?? 'inherit'
                }
              >
                {label}
              </Text>
              <Box
                className="stepper-step"
                sx={i === activeStep ? styles.activeStep : styles.inactiveStep}
                height="10px"
                mt={0}
                borderTopLeftRadius={i === 0 ? 'md' : 'none'}
                borderBottomLeftRadius={i === 0 ? 'md' : 'none'}
                borderBottomRightRadius={i === stepperSteps?.length - 1 ? 'md' : 'none'}
                borderTopRightRadius={i === stepperSteps?.length - 1 ? 'md' : 'none'}
              />
            </Stack>
          );
        })}
    </Flex>
  );
};
