import gql from 'graphql-tag';

export const UPDATE_LISTING_MUTATION = gql`
  mutation updateListing($input: UpdateListingInput!) {
    updateListing(input: $input)
  }
`;

export const SUPPRESS_LISTING = gql`
  mutation SuppressListing($listingId: String!) {
    suppressListing(id: $listingId)
  }
`;
