import React, { useState } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { useSource } from '@companydotcom/providers';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export interface AppRoutesProps {
  isAuthenticated: boolean;
  email: any;
}

export const AppRoutes: React.FC<AppRoutesProps> = props => {
  const { sourceId } = useSource();
  const { isAuthenticated, email } = props;
  const [isResend, setIsResend] = useState(false);
  const navigate = useNavigate();

  const routes = [
    ...protectedRoutes({ setIsResend, isResend, navigate, sourceId }),
    ...publicRoutes(isAuthenticated, email, sourceId),
  ];

  const element = useRoutes(routes);

  return <>{element}</>;
};
