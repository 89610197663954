import { TFunction } from 'react-i18next';

export const monthDropdownValues = (t: TFunction) => [
  {
    value: '01',
    label: t('common.months.january'),
    refType: 'monthFounded',
  },
  {
    value: '02',
    label: t('common.months.february'),
    refType: 'monthFounded',
  },
  {
    value: '03',
    label: t('common.months.march'),
    refType: 'monthFounded',
  },
  {
    value: '04',
    label: t('common.months.april'),
    refType: 'monthFounded',
  },
  {
    value: '05',
    label: t('common.months.may'),
    refType: 'monthFounded',
  },
  {
    value: '06',
    label: t('common.months.june'),
    refType: 'monthFounded',
  },
  {
    value: '07',
    label: t('common.months.july'),
    refType: 'monthFounded',
  },
  {
    value: '08',
    label: t('common.months.august'),
    refType: 'monthFounded',
  },
  {
    value: '09',
    label: t('common.months.september'),
    refType: 'monthFounded',
  },
  {
    value: '10',
    label: t('common.months.october'),
    refType: 'monthFounded',
  },
  {
    value: '11',
    label: t('common.months.november'),
    refType: 'monthFounded',
  },
  {
    value: '12',
    label: t('common.months.december'),
    refType: 'monthFounded',
  },
];

/**
 * Get years array
 * @param {Number} fromYear years should start from default to 1900
 */
export function generateYears(fromYear = 1900) {
  let currentYear = new Date().getFullYear();
  const yearArray = [];
  yearArray.push({
    value: currentYear,
    label: currentYear,
    refType: 'yearFounded',
  });
  // the year should start from fromYear
  const yearToStart = currentYear - fromYear;
  for (let i = yearToStart; i > 0; i--) {
    currentYear -= 1;
    yearArray.push({
      value: currentYear,
      label: currentYear,
      refType: 'yearFounded',
    });
  }
  return yearArray;
}

export function generateFutureYears(from = 2022, to = 14) {
  const start = from;
  const end = from + to;
  const myArray = [];
  for (let i = start; i <= end; i += 1) {
    myArray.push({ value: i.toString(), label: i.toString() });
  }
  return myArray;
}
