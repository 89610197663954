import React, { useState } from 'react';
import { Link, HStack, useMediaQuery, Box, Skeleton, Text, BoxProps } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import type { Maybe } from '@companydotcom/types';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { EditButton } from './edit-button';

interface Props {
  prefix: Maybe<string>;
  suffix: Maybe<string>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  title: Maybe<string>;
  middleName: Maybe<string>;
  organization: Maybe<string>;
  twitterName: Maybe<string>;
  linkedInName: Maybe<string>;
  formSlug: string;
  onOpen: () => void;
  setFormSlug: (slug: string) => void;
  canEdit?: boolean;
}

export type ProfileTitleProps =
  | (Props & { isLoading?: false })
  | ((Partial<Props> & {
      isLoading?: boolean;
    }) &
      Omit<BoxProps, 'title' | 'prefix'>);

export const ProfileTitle: React.FC<ProfileTitleProps> = props => {
  const {
    canEdit = false,
    formSlug,
    onOpen,
    setFormSlug,
    title,
    prefix,
    firstName,
    lastName,
    isLoading,
    organization,
    twitterName,
    linkedInName,
    middleName,
    suffix,
    children,
    ...rest
  } = props;
  const [isMobile] = useMediaQuery('(max-width: 48rem)');
  const [isHover, setIsHover] = useState(false);

  const titleLoading = isLoading;

  const handleEditButtonClick = (slug: string) => {
    onOpen?.();
    setFormSlug?.(slug);
  };

  return (
    <Box
      className="profile-title"
      textAlign={['center', 'left']}
      borderRadius={4}
      bg={isHover && canEdit && !titleLoading ? 'gray.200' : 'white'}
      position="relative"
      onMouseEnter={titleLoading ? undefined : () => setIsHover(true)}
      onMouseLeave={titleLoading ? undefined : () => setIsHover(false)}
      p={4}
      width="full"
      {...rest}
    >
      {((isMobile && canEdit) || (!isMobile && isHover && canEdit)) && (
        <EditButton
          setFormSlug={formSlug ? () => handleEditButtonClick(formSlug) : undefined}
          position="absolute"
          top={2}
          right={2}
        />
      )}
      <Skeleton mb={1} height={titleLoading ? '36px' : 'inherit'} isLoaded={!titleLoading}>
        <Text lineHeight="36px" textStyle="3xl" fontWeight="bold" mb={1} pr={5}>
          {prefix && `${prefix} `}
          {firstName && `${firstName} `}
          {middleName && `${middleName} `}
          {lastName && `${lastName}`}
          {suffix && `, ${suffix}`}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={!titleLoading}>
        <Text fontWeight="bold" textStyle="lg" mb={1}>
          {title}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={!titleLoading}>
        <Text fontWeight="bold" textStyle="sm" mb={1}>
          {organization}
        </Text>
      </Skeleton>
      <HStack justifyContent={['center', null, 'flex-start']}>
        {titleLoading ? (
          <>
            <Skeleton height="4" width="4" />
            <Skeleton height="4" width="4" />
          </>
        ) : (
          <>
            {twitterName && (
              <Link href={twitterName} isExternal>
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
            )}
            {linkedInName && (
              <Link href={linkedInName} isExternal>
                <FontAwesomeIcon icon={faLinkedin} />
              </Link>
            )}
          </>
        )}
        {children}
      </HStack>
    </Box>
  );
};
