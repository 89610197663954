import { User } from '@companydotcom/types';
import { GET_USER_CUIS } from './graphql/cui.query';
import { emptySplitApi } from '../../store/empty-split-api';
import { SET_USER_CUI } from './graphql/cui-mutation';

export const CuiApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getUserCuis: builder.query<Partial<User>, { userId: string }>({
      query: ({ userId }) => ({
        method: 'query',
        document: GET_USER_CUIS,
        variables: {
          userId,
        },
      }),
      providesTags: [],
      transformResponse: (rawResult: { data: { getUser: { userCUIs: any } } }) => {
        const response = rawResult.data.getUser;
        return response;
      },
    }),
    updateUserCuis: builder.mutation<
      any,
      { userId: string; cuiId: string; isDismissed?: boolean; isCompleted?: boolean }
    >({
      query: ({ userId, cuiId, isDismissed = false, isCompleted = false }) => ({
        method: 'mutation',
        document: SET_USER_CUI,
        variables: {
          userId,
          cuiId,
          isDismissed,
          isCompleted,
        },
      }),
      transformResponse: (rawResult: { data: { updateUserCuis: any } }) => {
        const response = rawResult.data.updateUserCuis;
        return response;
      },
    }),
  }),
});

export const { useLazyGetUserCuisQuery, useUpdateUserCuisMutation } = CuiApi;
