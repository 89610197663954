import React from 'react';
import { Container, Center } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { CloseButtonLayout } from './close-button-layout';

export interface FaceCakePageLayoutProps {
  children: React.ReactNode;
}

export const FaceCakePageLayout = (props: FaceCakePageLayoutProps) => {
  const { children } = props;

  const navigate = useNavigate();

  return (
    <CloseButtonLayout onBackClick={() => navigate('/')}>
      <Center mt={['4', null, '12', '40']} px="4" mb={['20', null, '40']}>
        <Container maxW="50rem" w="full">
          {children}
        </Container>
      </Center>
    </CloseButtonLayout>
  );
};
