import { Box, Text, VStack, Button } from '@companydotcom/potion';

export const EventRegistrationNotEligible = () => {
  return (
    <Box width="full" maxW={908}>
      <Box width="full" textAlign={['center', 'left']} mb={7}>
        <Text
          mt={[8, 12]}
          lineHeight={1.25}
          textStyle={['lg', '3xl']}
          fontWeight="bold"
          opacity={0.7}
        >
          We’re sorry, but you’re not eligible to register for this event.
        </Text>
        <VStack align="flex-start" textAlign="left" spacing={4} mt={12}>
          <Text textStyle="lg" fontWeight="bold">
            There are many reasons you may not be eligible. Most of the time, this is because events
            are for chapter members. Otherwise, please contact this chapter for assistance or{' '}
            <Button
              onClick={() => window.open(`${process.env.REACT_APP_ACG_BASE_URL}/events`)}
              variant="link"
              fontWeight="bold"
              fontSize="lg"
            >
              search for additional events.
            </Button>
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};
