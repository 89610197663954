import type { SystemStyleObject } from '@companydotcom/potion';

const baseStyleButton = {
  backgroundColor: 'white',
  color: 'blue.400',
  boxShadow: 'sm',
  _hover: {
    backgroundColor: 'blackAlpha.50',
  },
  _active: {
    backgroundColor: 'blackAlpha.100',
  },
} as SystemStyleObject;

const baseStyle = baseStyleButton;

export default {
  baseStyle,
};
