import { UserTile } from '@companydotcom/types';

export const getTargetTileState = (tile: UserTile, tiles: UserTile[]) => {
  const targetTile =
    tile.targetTile &&
    tiles.find((userTile: UserTile) => userTile?.product?.slug === tile?.targetTile);
  if (targetTile) {
    const targetTileState = targetTile?.tileStates?.find(
      state => state?.stateName === targetTile?.stateCurrent,
    );
    const targetTileComponents = targetTileState ? targetTileState.tileComponents : [];
    return {
      targetTileComponents,
      productId: targetTile.productId,
      slug: tile.targetTile,
      stateCurrent: targetTile.stateCurrent,
      isHidden: targetTile.isHidden,
    };
  }
  return undefined;
};

export const getCurrentTileState = (tileStates: any, stateCurrent: any) =>
  tileStates.find((state: any) => state.stateName === stateCurrent) || tileStates[0];
