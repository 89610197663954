var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import valid from 'card-validator';
import { ReactNumberFormatField } from './react-number-format-field';
/**
 * Adds the appropriate spaces as  determined  by the card-validator library
 * @param value
 * @returns
 */
export const prettyCardNumber = (cardNumber) => {
    var _a;
    const card = (_a = valid === null || valid === void 0 ? void 0 : valid.number(cardNumber)) === null || _a === void 0 ? void 0 : _a.card;
    if (card) {
        // @ts-ignore
        const offsets = [].concat(0, card.gaps, cardNumber.length);
        const components = [];
        for (let i = 0; offsets[i] < cardNumber.length; i++) {
            const start = offsets[i];
            const end = Math.min(offsets[i + 1], cardNumber.length);
            components.push(cardNumber.substring(start, end));
        }
        return components.join(' ');
    }
    return cardNumber;
};
export const CreditCardNumberInput = (_a) => {
    var { control, name } = _a, rest = __rest(_a, ["control", "name"]);
    return (_jsx(ReactNumberFormatField, Object.assign({ name: name, type: "text", format: prettyCardNumber, removeFormatting: removeAllSpacesFromString, control: control }, rest), void 0));
};
export const removeAllSpacesFromString = (value) => {
    var _a;
    return (_a = value === null || value === void 0 ? void 0 : value.replace) === null || _a === void 0 ? void 0 : _a.call(value, /\s/g, '');
};
export const countAllSpacesInString = (value) => {
    var _a, _b;
    return ((_b = (_a = value === null || value === void 0 ? void 0 : value.split) === null || _a === void 0 ? void 0 : _a.call(value, ' ')) === null || _b === void 0 ? void 0 : _b.length) - 1;
};
