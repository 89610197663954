import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonGroup, __DEV__ } from '@companydotcom/potion';
import { LanguageSwitcher } from './language-switcher';
import { ThemeEditor } from './theme-editor';
export const DevTools = () => {
    return (_jsx(_Fragment, { children: _jsxs(ButtonGroup, Object.assign({ display: ['none', 'inherit'], spacing: 0, position: "fixed", className: "control", zIndex: "tooltip", size: "sm", sx: {
                bottom: '0px',
                left: '0px',
                zIndex: '5000',
            } }, { children: [_jsx(LanguageSwitcher, {}, void 0), _jsx(ThemeEditor, {}, void 0)] }), void 0) }, void 0));
};
if (__DEV__) {
    DevTools.displayName = 'DevTools';
}
