import React from 'react';
import {
  Flex,
  Box,
  Text,
  Button,
  Link,
  Heading,
  Center,
  useBreakpointValue,
} from '@companydotcom/potion';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageDivider, PinCodeField } from '@companydotcom/ui';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../providers';
// import { verifyOtp } from '../../../services/enrollment/old-api/enrollment-svc';
import { useVerifyOtpMutation } from '../../../services/enrollment/enrollment-api';

const TARGET_LENGTH = 6;

const getVerifyCodeSchema = () =>
  yup.object().shape({
    code: yup
      .string()
      .min(TARGET_LENGTH, `subpage.verifyCode.inputs.code.length`)
      .test('code', 'subpage.verifyCode.inputs.code.test', (val: any) =>
        (typeof val === 'string' && parseInt(val, 10)) > 0 ? val : undefined,
      )
      .required('subpage.verifyCode.inputs.code.required'),
  });

export interface VerifyCodeProps {
  handleResend: () => void;
  isEmailVerification?: boolean;
  email?: string;
  onSubmit?: () => void;
  secondVerification?: boolean;
  boxShadow?: boolean;
}

export const VerifyCode = ({
  handleResend,
  isEmailVerification,
  email,
  secondVerification,
}: VerifyCodeProps) => {
  const auth0 = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const defaultValues = { code: '' };
  const [verifyOtp] = useVerifyOtpMutation();

  const contactSupportLink = t('subpage.verifyCode.contactLink')
    ? t('subpage.verifyCode.contactLink')
    : null;

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getVerifyCodeSchema()),
    defaultValues: defaultValues ?? {},
  });

  const resendCode = () => {
    handleResend();
  };

  const submitHandler = async (values: typeof defaultValues) => {
    try {
      const phoneno = localStorage.getItem('user_phoneno');
      const userenteredCode = values.code;
      const code = JSON.stringify(parseInt(userenteredCode, 10));
      const user_id = auth0?.user?.userId;

      if (user_id) {
        const res = await verifyOtp({
          userId: user_id,
          phoneNumber: phoneno,
          code,
          useEmail: isEmailVerification,
          email: email || '',
        }).unwrap();

        if (
          res &&
          res.resp.includes('200') &&
          res?.result?.response?.message?.includes('OTP verified successfully')
        ) {
          await auth0?.refreshUser();
          if (search) {
            navigate({ pathname: '/redirect', search });
          } else {
            navigate('/');
          }
        }

        if (typeof res?.result?.response === 'string' && res?.result?.response?.includes('Error')) {
          setError('code', {
            message: 'subpage.verifyCode.inputs.code.doesntMatch',
          });
        }
      } else {
        setError('code', {
          message: 'subpage.verifyCode.inputs.code.doesntMatch',
        });
      }
    } catch (error) {
      console.log('catch error', error);
      console.log(error);
      setError('code', {
        message: 'subpage.verifyCode.inputs.code.doesntMatch',
      });
    }
  };

  const pinInputSize = useBreakpointValue({ base: 'md', sm: 'lg' });

  return (
    <Center
      className="dashboard-verifyCode__container"
      maxWidth="2xl"
      flexDirection="column"
      layerStyle="formContainer"
      boxShadow={!secondVerification ? 'lg' : 'none'}
      py={!secondVerification ? [8, 12] : 0}
      px={4}
      borderRadius={!secondVerification ? 'md' : 'none'}
      border={!secondVerification ? 'inherit' : 'none'}
    >
      <Box textAlign={['left', 'center']}>
        <Heading size="hs-2xl">{t('subpage.verifyCode.heading')}</Heading>
        <PageDivider width="50px" mt="4" alignSelf="flex-start" display={['block', 'none']} />

        <Text maxWidth={400} textStyle="md" mt={5} mx="auto">
          {t('subpage.verifyCode.subheading')}{' '}
          {isEmailVerification
            ? t('subpage.verifyCode.email')
            : t('subpage.verifyCode.textMessage')}
          .
        </Text>

        <>
          <Flex
            as="form"
            width="full"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={[8, 10]}
          >
            <Box textAlign="center">
              <PinCodeField
                autoFocus
                errors={errors}
                name="code"
                control={control}
                length={TARGET_LENGTH}
                size={pinInputSize}
              />
            </Box>
            <Box width={['full', 'auto']}>
              <Button
                type="submit"
                size="lg"
                marginY={12}
                onClick={handleSubmit(submitHandler)}
                isLoading={isSubmitting}
                isDisabled={!isValid}
                width={['full', 'inherit']}
              >
                {t('subpage.verifyCode.submitButton')}
              </Button>
            </Box>
          </Flex>
        </>
      </Box>
      <Box textAlign="center">
        <Text textStyle="sm">
          <Trans i18nKey="subpage.verifyCode.didntGetCode">
            Didn’t get the code?
            <Button
              variant="link"
              fontSize="sm"
              _hover={{
                textDecoration: 'none',
              }}
              onClick={resendCode}
            >
              Resend verification code.
            </Button>
          </Trans>
        </Text>

        <Trans i18nKey="subpage.verifyCode.needHelp">
          <Text textStyle="sm" mt={[16, 20]}>
            Need Help?
            <Link
              href={contactSupportLink ?? `${process.env.REACT_APP_GRANDIO_URL}/contact-us`}
              isExternal
            >
              Contact support
            </Link>
          </Text>
        </Trans>
      </Box>
    </Center>
  );
};
