import { isEmpty } from 'lodash';
import {
  User,
  ExtendedSubscriptionStatus,
  ExtendedSubscriptionRatePlan,
} from '@companydotcom/types';
import { NavigateFunction } from 'react-router-dom';
import { CartActionType } from '../../../state/reducers/cart-reducer';
import {
  checkProductForTiers,
  processAllRatePlanTiers,
} from '../../../utils/helpers/purchasing-helpers';
// import { getUserLite } from '../../../services/user/old-api/user-svc';
// import { getUserProduct } from '../../../services/product/old-api/product-svc';
import { PaywallActionType } from '../../../state/reducers/paywall-reducer';

// Weebly external: http://dashboard.localhost:3000/login?productid=af62e060-06e4-11e8-9306-120b17a64360&site=183185860389005591&user_id=137427712&hash=b4ddd0c4e64afe5f9ac940474c8f4c2a251e5f5d&plan=Starter&upgrade_type=&upgrade_id=&plan_ids=1%2C2
// Tech support: http://dashboard.localhost:3000/login?showcreateaccount=true&productid=af62e046-06e4-11e8-9306-120b17a64360&planid=2c92c0f8537e1b8a0153806742bd7347&sourceForMarketo=Tech%20Support
// Legal Inc Flow: http://dashboard.localhost:3000/login?orderid=971377&referrer=legalinc&productid=9d73c3ac-1ea3-4223-af60-7438dc8a16b1
// LegalInc Flow from marketing site: http://dashboard.localhost:3000/login?showcreateaccount=true&productid=9d73c3ac-1ea3-4223-af60-7438dc8a16b1&sourceForMarketo=BusinessFormation
export const initExternalFlow = async (
  productId: string,
  // userId: string,
  navigate: NavigateFunction,
  goToStep: any,
  dispatchPaywallState: React.Dispatch<PaywallActionType>,
  dispatchCartState: React.Dispatch<CartActionType>,
  userProduct: ExtendedSubscriptionStatus,
  orderId?: string | null,
  planId?: string | null,
  referrer?: string | null,
  // locale?: string,
  userLite?: User,
  refetch?: () => void,
) => {
  try {
    dispatchPaywallState({ type: 'SET_PAYWALL_STATE', payload: { loading: true } });

    if (productId === process.env.REACT_APP_LEGALINC_PRODUCTID && referrer !== 'legalinc') {
      dispatchPaywallState({
        type: 'SET_PAYWALL_STATE',
        payload: { legalIncOpen: true, loading: false },
      });
    }

    // const tryForUser = async (user: User | null) => {
    //   if (user) {
    //     return user;
    //   }
    //   const userRes = await getUserLite(userId);
    //   if (userRes) {
    //     return userRes;
    //   }
    //   return tryForUser(user);
    // };
    // const user = await tryForUser(null);

    // The above function was recursive to get user data. Passed a refetch function that will requery if the user data doesn't exist
    if (userLite) {
      dispatchPaywallState({ type: 'SET_PAYWALL_STATE', payload: { externalUser: userLite } });
      // const userProduct = await getUserProduct(userLite.userId, productId, locale);
      const hasTiers = checkProductForTiers(userProduct);
      const processedUserProduct: ExtendedSubscriptionStatus = {
        ...userProduct,
        products: userProduct.products?.map(product => processAllRatePlanTiers(product)),
      };

      dispatchPaywallState({
        type: 'SET_PAYWALL_STATE',
        payload: { userProduct: processedUserProduct },
      });
      if (!userProduct || !userProduct.products || isEmpty(userProduct.products)) {
        // redirect to error page
        navigate('/paymenterror');
      }
      const selectedPlan = processedUserProduct?.products?.[0]?.ratePlans?.find(
        plan => plan?.ratePlanId && plan.ratePlanId === planId,
      );

      if (selectedPlan && !hasTiers) {
        dispatchCartState({ type: 'ADD_RATE_PLAN', payload: selectedPlan });
        goToStep('checkout');
      } else if (!selectedPlan && orderId) {
        goToStep('checkout');
      } else {
        dispatchCartState({
          type: 'ADD_RATE_PLAN',
          payload: processedUserProduct?.products?.[0]
            ?.ratePlans?.[0] as ExtendedSubscriptionRatePlan,
        });
        goToStep('product-select');
      }
    } else {
      refetch?.();
    }

    dispatchPaywallState({ type: 'SET_PAYWALL_STATE', payload: { loading: false } });
  } catch (error) {
    console.error(error);
  }
};
