import { isEmpty } from 'lodash';
import {
  User,
  ExtendedSubscriptionStatus,
  ExtendedSubscriptionRatePlan,
} from '@companydotcom/types';
import { CartActionType } from '../../../state/reducers/cart-reducer';
import { processAllRatePlanTiers } from '../../../utils/helpers/purchasing-helpers';
import { OpenPaywallEvent } from '../types';
// import { getUserProduct } from '../../../services/product/old-api/product-svc';
import { PaywallActionType } from '../../../state/reducers/paywall-reducer';

export const initInternalFlow = async (
  evt: OpenPaywallEvent,
  getUserProduct: any,
  user: User,
  dispatchPaywallState: React.Dispatch<PaywallActionType>,
  dispatchCartState: React.Dispatch<CartActionType>,
  locale?: string,
) => {
  try {
    const {
      metadata: { tile, products, eventSource },
    } = evt;

    dispatchPaywallState({
      type: 'SET_PAYWALL_STATE',
      payload: {
        loading: true,
        eventSource,
      },
    });

    const userProduct = await getUserProduct({
      userId: user.userId,
      productId: tile?.productId || (products?.[0]?.productId as string),
      locale,
    })
      .unwrap()
      .catch((error: any) => console.error('Rejected! ', error));

    const processedUserProduct: ExtendedSubscriptionStatus = {
      ...userProduct,
      products: userProduct.products?.map((product: any) => processAllRatePlanTiers(product)),
    };

    dispatchPaywallState({
      type: 'SET_PAYWALL_STATE',
      payload: { userProduct: processedUserProduct, tile },
    });

    // Automatically put the first available rate plan into the users cart and active tier
    if (
      !isEmpty(processedUserProduct?.products?.[0]?.ratePlans) &&
      processedUserProduct?.products?.[0]?.ratePlans &&
      processedUserProduct?.products?.[0].ratePlans.length > 0
    ) {
      const activeRatePlan = processedUserProduct.products[0].ratePlans.find(
        plan => plan?.isActive,
      ) as ExtendedSubscriptionRatePlan;

      const activeTier = userProduct?.products?.[0]?.ratePlans?.[0]?.tiers?.find?.(
        (tier: { isActive: any }) => tier?.isActive,
      );

      dispatchCartState({
        type: 'UPDATE_RATE_PLAN',
        payload: activeRatePlan || processedUserProduct.products[0].ratePlans[0],
      });

      if (activeTier) {
        dispatchCartState({
          type: 'ADD_SUBSCRIPTION_TIER',
          payload: activeTier,
        });
      }
    }

    dispatchPaywallState({ type: 'TOGGLE_PAYWALL_LOADING', payload: false });
  } catch (error) {
    console.error(error);
  }
};
