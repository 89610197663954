import React from 'react';
import { Flex, Box, Text, VStack, HStack, StackDivider } from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import ContentLoader from 'react-content-loader';
import type { TileComponentProps } from '../../types';

interface Incident {
  value: number | string;
  label: string;
}
interface IncidentSummaryProps {
  incidents: Incident[];
}

export interface TileIncidentSummaryProps extends TileComponentProps {}

export const TileIncidentSummary: React.FC<TileIncidentSummaryProps> = props => {
  const { eventData } = props;
  const { loading, error, data } = useEventData<IncidentSummaryProps>(eventData);

  // const mockIncidentSummaryData = [
  //   { label: 'Risky Devices', value: 179 },
  //   { label: 'Open Threats', value: 167 },
  //   { label: 'Open Vulnerabilities', value: 40 },
  // ];

  return (
    <Flex flexDirection="column" className="tile__incidentSummary" my="auto" width="full" flex={1}>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        justify="center"
        alignItems="center"
      >
        {data?.incidents?.map((threat, i) => (
          <IncidentItem
            key={i}
            error={error}
            loading={loading}
            value={threat?.value}
            label={threat?.label}
          />
        ))}
      </VStack>
    </Flex>
  );
};

const IncidentItem = ({
  value,
  label,
  loading,
  error,
}: {
  value?: string | number;
  label?: string;
  loading?: boolean;
  error?: boolean;
}) => (
  <HStack width="full" justifyContent="space-between" maxW={200}>
    {loading && <Loader />}
    {error && (
      <>
        <Box minWidth="32px">
          <Text fontWeight="bold" textStyle="md">
            !
          </Text>
        </Box>
        <Box ml={6} mr="auto">
          <Text textStyle="md">{label}</Text>
        </Box>
      </>
    )}
    {!loading && !error && (
      <>
        <Box minWidth="32px">
          <Text fontWeight="bold" textStyle="md">
            {value}
          </Text>
        </Box>
        <Box ml={6}>
          <Text textStyle="md">{label}</Text>
        </Box>
      </>
    )}
  </HStack>
);

const Loader = () => (
  <ContentLoader
    speed={2}
    width={242}
    height={28}
    viewBox="0 0 242 28"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
    <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
    <rect x="0" y="0" rx="0" ry="0" width="49" height="28" />
    <rect x="57" y="0" rx="0" ry="0" width="185" height="28" />
  </ContentLoader>
);
