import gql from 'graphql-tag';

export const GET_AUTOCOMPLETE_RESULTS = gql`
  query GetAutocompleteResults($searchTerm: String!) {
    getAutocompleteResults(queryString: $searchTerm) {
      ... on CategoryRecord {
        id
        name
        fullName
      }
    }
  }
`;

export const GET_LOCATION_AUTOCOMPLETE_RESULTS = gql`
  query GetLocationAutocomplete($searchTerm: String!) {
    getLocationAutocomplete(input: $searchTerm) {
      placeId
      name
    }
  }
`;

export const GET_CITY_STATE_FROM_ZIP = gql`
  query GetCityStateFromZip($zipCode: String!) {
    getCityStateFromZip(zipCode: $zipCode)
  }
`;
