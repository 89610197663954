import { EventForRegistration } from './../../../../services/acg/acg.types';

export const checkEventStatus = (
  eventForRegistration?: EventForRegistration,
  numberOfGuests?: number,
  cart?: any,
) => {
  return {
    shouldShouldWaitlistAlert:
      eventForRegistration && shouldShouldWaitlistAlert(eventForRegistration, numberOfGuests, cart),
    shouldShowEventFullAlert:
      eventForRegistration && shouldShowEventFullAlert(eventForRegistration, numberOfGuests, cart),
    shouldShowEventOverCapacityAlert:
      eventForRegistration &&
      shouldShowEventOverCapacityAlert(eventForRegistration, numberOfGuests, cart),
  };
};

/**
 * There are guests OR no guests
 * the available capacity is 0
 * the event has a waitlist
 */
function shouldShouldWaitlistAlert(
  eventForRegistration: EventForRegistration,
  numberOfGuests: number = 0,
  cart: any,
) {
  const availableCapacity =
    eventForRegistration?.eventCapacity - eventForRegistration?.totalRegistered;
  const PackageProductName = cart?.items?.event?.guests[0]?.registrantType;
  const currentOrder = PackageProductName === 'Guest/Spouse' ? numberOfGuests : 1 + numberOfGuests;

  if (
    eventForRegistration?.hasWaitList &&
    eventForRegistration?.eventCapacity &&
    eventForRegistration?.eventCapacityEnabled &&
    availableCapacity - currentOrder < 0
  ) {
    return true;
  }

  return false;
}

/**
 * There are guests OR no guests
 * the available capacity is 0
 * There is no waitlist
 */
function shouldShowEventFullAlert(
  eventForRegistration: EventForRegistration,
  numberOfGuests: number = 0,
  cart: any,
) {
  const availableCapacity =
    eventForRegistration?.eventCapacity - eventForRegistration?.totalRegistered;
  const PackageProductName = cart?.items?.event?.guests[0]?.registrantType;
  const currentOrder = PackageProductName === 'Guest/Spouse' ? numberOfGuests : 1 + numberOfGuests;

  if (
    !eventForRegistration?.hasWaitList &&
    eventForRegistration?.eventCapacity &&
    eventForRegistration?.eventCapacityEnabled &&
    availableCapacity - currentOrder < 0
  ) {
    return true;
  }
  return false;
}

/**
 * The registration has guests
 * the available capacity is less than the order
 * the available capacity is 1 or more
 */
function shouldShowEventOverCapacityAlert(
  eventForRegistration: EventForRegistration,
  numberOfGuests: number = 0,
  cart: any,
) {
  const availableCapacity =
    eventForRegistration?.eventCapacity - eventForRegistration?.totalRegistered;
  const PackageProductName = cart?.items?.event?.guests[0]?.registrantType;
  const currentOrder = PackageProductName === 'Guest/Spouse' ? numberOfGuests : 1 + numberOfGuests;
  if (
    !eventForRegistration?.hasWaitList &&
    eventForRegistration?.eventCapacity &&
    eventForRegistration?.eventCapacityEnabled &&
    availableCapacity - currentOrder <= 0
  ) {
    return true;
  }
  return false;
}
