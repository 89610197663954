import { isSameDay, isSameDate } from '@companydotcom/helpers';
import { useBreakpointValue } from '@companydotcom/potion';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { EventForRegistration, TimezoneMode } from './../../../../services/acg/acg.types';

dayjs.extend(utc);
dayjs.extend(timezone);

function formatDateForAcg(
  mode: TimezoneMode = TimezoneMode.SPECIFIC,
  date: Date,
  format: string = 'MMMM DD, YYYY h:mmA',
  eventTimezone: string = 'America/New_York',
) {
  if (mode === TimezoneMode.LOCAL) {
    const userTimezone = dayjs.tz.guess();
    return dayjs
      .utc(date)
      .tz(userTimezone || undefined)
      .format(format);
  }
  return dayjs
    .utc(date)
    .tz(eventTimezone || undefined)
    .format(format);
}

export const useFormatAcgEventDate = ({
  eventStartDate,
  eventEndDate,
  eventTimezone,
  eventTimezoneMode,
  eventTimezoneAbbreviation,
}: Partial<
  Pick<
    EventForRegistration,
    | 'eventStartDate'
    | 'eventEndDate'
    | 'eventTimezoneMode'
    | 'eventTimezoneAbbreviation'
    | 'eventTimezone'
    | 'eventTimezoneOffset'
  >
>) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const defaultFormat = isMobile ? 'MMM DD, YYYY h:mmA' : 'MMMM DD, YYYY h:mmA';

  // Never show timezone abbreviation if mode is set to local
  const abrv =
    eventTimezoneMode !== TimezoneMode.LOCAL && eventTimezoneAbbreviation
      ? ` ${eventTimezoneAbbreviation}`
      : '';

  if (!eventStartDate || !eventEndDate) {
    return undefined;
  }
  // Same day and time
  if (isSameDate(eventStartDate, eventEndDate)) {
    return `${formatDateForAcg(
      eventTimezoneMode,
      eventStartDate,
      defaultFormat,
      eventTimezone,
    )}${abrv}`;
  }

  // Same day, different time
  if (isSameDay(eventStartDate, eventEndDate)) {
    return `${formatDateForAcg(
      eventTimezoneMode,
      eventStartDate,
      defaultFormat,
      eventTimezone,
    )} - ${formatDateForAcg(eventTimezoneMode, eventEndDate, 'h:mmA', eventTimezone)}${abrv}`;
  }

  return `${formatDateForAcg(
    eventTimezoneMode,
    eventStartDate,
    defaultFormat,
    eventTimezone,
  )} - ${formatDateForAcg(eventTimezoneMode, eventEndDate, defaultFormat, eventTimezone)}${abrv}`;
};
