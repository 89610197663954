import { createContext } from '@companydotcom/potion';

export interface SideNavContext {
  onSideNavToggle?: () => void;
  isSideNavOpen?: boolean;
  isLoading?: boolean;
}

export const [SideNavProvider, useSideNavContext] = createContext<SideNavContext>({
  name: 'SideNavContext',
  errorMessage:
    'useSideNavContext: `context` is undefined. Seems you forgot to wrap the side nav components in `<SideNav />`',
});
