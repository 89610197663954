import React from 'react';
import { Text } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { companyConstants, companyHelpers } from '@companydotcom/helpers';
import { useAuth } from '../../../../providers';

const Redirector = () => {
  const auth0 = useAuth();
  const source = auth0?.getUserSource();
  const redirectTo = companyHelpers.routeBuilder(source, companyConstants.hosts.dashboard);
  setTimeout(() => {
    window.location.replace(redirectTo);
  }, 3000);
  return null;
};

export const Error421 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Text textStyle="lg" fontWeight="medium" color="red.500" mb={12}>
        {t('subpage.generalError.421.heading')}
      </Text>
      <Redirector />
    </>
  );
};
