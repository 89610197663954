const parts = ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption'];

type Dict = Record<string, any>;

const baseStyle = {
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'collapse',
    width: 'full',
  },
  th: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'wider',
    textAlign: 'start',
  },
  td: {
    textAlign: 'start',
  },
  caption: {
    mt: 4,
    fontFamily: 'heading',
    textAlign: 'center',
    fontWeight: 'medium',
  },
};

const numericStyles = {
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
};

const simpleVariant = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    th: {
      color: `gray.600`,
      borderBottomWidth: '1px',
      borderColor: `${c}.100`,
      ...numericStyles,
    },
    td: {
      borderBottomWidth: '1px',
      borderColor: `${c}.100`,
      ...numericStyles,
    },
    caption: {
      color: `gray.600`,
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
};

const stripedVariant = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    th: {
      color: `gray.600`,
      borderBottomWidth: '1px',
      borderColor: `${c}.100`,
      ...numericStyles,
    },
    td: {
      borderBottomWidth: '1px',
      borderColor: `${c}.100`,
      ...numericStyles,
    },
    caption: {
      color: `gray.600`,
    },
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          'th, td': {
            borderBottomWidth: '1px',
            borderColor: `${c}.100`,
          },
          td: {
            background: `${c}.50`,
          },
        },
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
};

const variants = {
  simple: simpleVariant,
  striped: stripedVariant,
  unstyled: {},
};

const sizes = {
  sm: {
    th: {
      px: '4',
      py: '1',
      lineHeight: '4',
      fontSize: 'xs',
    },
    td: {
      px: '4',
      py: '2',
      fontSize: 'sm',
      lineHeight: '4',
    },
    caption: {
      px: '4',
      py: '2',
      fontSize: 'xs',
    },
  },
  md: {
    th: {
      px: '6',
      py: '3',
      lineHeight: '4',
      fontSize: 'xs',
    },
    td: {
      px: '6',
      py: '4',
      lineHeight: '5',
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'sm',
    },
  },
  lg: {
    th: {
      px: '8',
      py: '4',
      lineHeight: '5',
      fontSize: 'sm',
    },
    td: {
      px: '8',
      py: '5',
      lineHeight: '6',
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'md',
    },
  },
};

const defaultProps = {
  variant: 'simple',
  size: 'md',
  colorScheme: 'gray',
};

export default {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
