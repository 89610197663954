import { Flex, Button, Text } from '@companydotcom/potion';

export const EventRegistrationCapacityReached = () => {
  return (
    <Flex
      width="full"
      maxW={908}
      borderWidth="1px"
      justifyContent="center"
      alignItems="center"
      p={['4', null, '7']}
      mt="12"
    >
      <Text fontSize="3xl" textAlign="center" fontWeight="bold" opacity="0.7" maxW={844}>
        We’re sorry, this event has reached maximum capacity. We hope to see you{' '}
        <Button
          onClick={() => window.open(`${process.env.REACT_APP_ACG_BASE_URL}/events`)}
          variant="link"
          fontWeight="bold"
          fontSize="3xl"
        >
          at another event.
        </Button>
      </Text>
    </Flex>
  );
};
