import { SubpageLayout, SubpageContent, SubpageHeader, SubpageFooter } from '@companydotcom/ui';
import { useAssets, useSource } from '@companydotcom/providers';
import { Text, Box, Center, Heading } from '@companydotcom/potion';
export const ShutOffscreen = () => {
  const source = useSource();
  const assets = useAssets();
  return (
    <>
      <SubpageLayout>
        <SubpageHeader logo={assets?.logo} />
        <SubpageContent>
          <Center
            textAlign="center"
            flexDirection="column"
            layerStyle="formContainer"
            p={10}
            width="704px"
            mx="auto"
          >
            <Box textAlign="center">
              <Box width="608px" height="72px">
                {/* ShutOff screen message for ACG source */}
                <Heading
                  color="#717174"
                  fontFamily="Lato"
                  fontSize="30px"
                  lineHeight="36px"
                  letterSpacing="0px"
                  textAlign="center"
                >
                  Sorry, You’re Unable to Access
                  <br /> My ACG Right Now.
                </Heading>
              </Box>
              <Text
                mt={9}
                textAlign="center"
                fontSize="16px"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0px"
                fontFamily="Heebo"
              >
                We are working diligently to restore My ACG.
              </Text>
            </Box>
          </Center>
        </SubpageContent>
        <SubpageFooter footerLogo={assets?.footer_logo} footerLinks={source?.footerLinks} />
      </SubpageLayout>
    </>
  );
};
