import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Heading, Button, Center } from '@companydotcom/potion';
import { CircleExclamationIcon } from '@companydotcom/ui';

export const ErrorStep = () => {
  const { t } = useTranslation();

  return (
    <Center bg="white" flexDirection="column" py={104} px={4}>
      <Box maxWidth={[310, 455]} sx={{ textAlign: 'center' }}>
        <CircleExclamationIcon mb={6} />
        <Heading size="hs-2xl">{t('common.misc.somethingWentWrong')}</Heading>
        <Text textStyle="2xl" mt={4}>
          {t('common.misc.tryAgain')}
        </Text>
      </Box>
      <Box mt={10} sx={{ textAlign: 'center' }}>
        <Button size="lg">{t('common.buttons.gotIt')}</Button>
      </Box>
    </Center>
  );
};
