import React, { useState, useEffect } from 'react';
import { Flex, Text, Heading, CloseButton, Box } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { CalendlyForm } from '../../../components/forms/calendly/calendly';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';

export const ScheduleWalkThrough = ({ handleClose }: any) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    if (globalUser?.firstName && globalUser?.lastName) {
      console.log('names fetched..');
      setLoading(false);
    }
  }, [globalUser?.firstName, globalUser?.lastName]);

  return (
    <Box>
      <CloseButton style={{ position: 'absolute', right: 15, top: 6 }} onClick={handleClose} />
      <Flex
        className="fttf-calendly"
        flexDirection="column"
        maxWidth={560}
        justifyContent="center"
        mx="auto"
        sx={{ textAlign: 'center' }}
        mb={[11]}
        mt={[8, null, 12]}
        px={4}
      >
        <Heading as="h1" size="hs-xl">
          {t('gmbProfileFlow.scheduleWalkthrough.heading')}
        </Heading>
        <Text textStyle={['lg', 'xl']} mt={[5]}>
          {t('gmbProfileFlow.scheduleWalkthrough.subheading')}
        </Text>
        {!loading && <CalendlyForm url={process.env.REACT_APP_CALENDLY_URL ?? ''} />}
      </Flex>
    </Box>
  );
};
