import type { User } from '@companydotcom/types';
import { emptySplitApi } from '../../store/empty-split-api';
import { DarkScanResponse } from './dark-web-scan.types';

const tileData = {
  productId: 'ba5cb81a-15d7-11eb-adc1-0242ac120002',
  tileId: '0bbaa7d8-1855-11eb-adc1-0242ac120002',
};

export const DarkWebScanApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getScanStatus: builder.query<any, { snsInterface: any; user: User }>({
      queryFn: async ({ snsInterface, user }) => {
        try {
          const res = await snsInterface.getNotifData(
            tileData,
            'fetch',
            'getScanStatus',
            user,
            {},
            true,
          );
          return res.body.payload
            ? { data: res.body.payload }
            : { error: true, meta: { message: 'No response--getScanStatus' } };
        } catch (err) {
          console.error(err);
          return { error: true, meta: { message: 'Error fetching latest scan--getScanStatus' } };
        }
      },
    }),
    scanDarkWeb: builder.query<
      DarkScanResponse,
      { snsInterface: any; searchTerms: { term: string; key: string }[]; mockScan?: boolean }
    >({
      keepUnusedDataFor: 300,
      queryFn: async ({ searchTerms, snsInterface, mockScan }) => {
        try {
          const res = await snsInterface.getNotifData(
            tileData,
            'fetch',
            'scanDarkWeb',
            { searchTerms, mockScan },
            {},
            false,
          );
          return res
            ? { data: res.body.payload }
            : { error: true, meta: { message: 'No response--scanDarkWeb' } };
        } catch (err) {
          console.error(err);
          return { error: true, meta: { message: 'Error fetching latest scan--scanDarkWeb' } };
        }
      },
    }),
    getLatestScanResults: builder.query<any, { snsInterface: any }>({
      queryFn: async ({ snsInterface }) => {
        try {
          const res = await snsInterface.getNotifData(
            tileData,
            'fetch',
            'getLatestScanResults',
            {},
            {},
            true,
          );
          return res
            ? { data: res.body.payload }
            : { error: true, meta: { message: 'No response--getLatestScanResults' } };
        } catch (err) {
          console.error(err);
          return {
            error: true,
            meta: { message: 'Error fetching latest scan--getLatestScanResults' },
          };
        }
      },
    }),
  }),
});

export const { useLazyScanDarkWebQuery, useLazyGetLatestScanResultsQuery, useGetScanStatusQuery } =
  DarkWebScanApi;
