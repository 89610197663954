/* eslint-disable new-cap */
import { useState, useRef, BaseSyntheticEvent } from 'react';
import {
  Box,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  useBreakpointValue,
  Icon,
  Heading,
  Spacer,
} from '@companydotcom/potion';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCheckCircle, faClose } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'react-hook-form';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { DEGREES } from './constants';
import { PhotoEditorControls } from './photo-editor-controls';
import { FileUpload } from '../../file-upload';
import {
  useGetGlobalUserQuery,
  useUploadUserAvatarMutation,
  useDeleteUserAvatarMutation,
} from '../../../../services/user/user-api';
import { useToast } from '../../../../hooks';

export interface PhotoEditorProps {
  onClose?: () => void;
  /** Function that fires on successful upload */
  onUploadComplete?: () => void;
  successToast?: boolean;
}

type FormValues = {
  file_: FileList;
};

export const PhotoEditor = ({ onClose, onUploadComplete, successToast }: PhotoEditorProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { file_: undefined },
  });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { data: globalUser } = useGetGlobalUserQuery();
  const [scaleValue, setScaleValue] = useState(1.3);
  const [file, setFile] = useState(undefined);
  const [rotateIndex, setRotateIndex] = useState(0);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const setEditorRef = useRef<any>();
  const cancelRef = useRef<any>();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [uploadAvatar] = useUploadUserAvatarMutation();
  const [deleteAvatar] = useDeleteUserAvatarMutation();
  const onDeleteOpen = () => setDeleteOpen(true);
  const onDeleteClose = () => setDeleteOpen(false);
  const rotateAvatar = () => {
    setRotateIndex((rotateIndex + 1) % DEGREES.length);
  };
  const toast = useToast();

  const validateFiles = (value: FileList) => {
    if (value.length < 1) {
      return 'File is required';
    }
    for (const file of Array.from(value)) {
      const fsMb = file.size / (1024 * 1024);
      const MAX_FILE_SIZE = 1;
      if (fsMb > MAX_FILE_SIZE) {
        return 'Max file size is 1mb';
      }
    }
    return true;
  };

  const savePicture = async () => {
    try {
      if (setEditorRef) {
        setSubmitting(true);
        const dataUri = setEditorRef?.current?.getImage().toDataURL();

        const localImageUrl = await fetch(dataUri)
          .then(res => res.blob())
          .then(blob => window.URL.createObjectURL(blob));

        if (dataUri && globalUser?.userId && localImageUrl) {
          await uploadAvatar({
            dataUri,
            userId: globalUser?.userId,
            localImageUrl,
          })
            .unwrap()
            .then(res => {
              if (res && onUploadComplete) {
                onUploadComplete();
              }
              if (res && successToast) {
                toast({
                  render: () => (
                    <Flex
                      flexDirection="row"
                      color="white"
                      p={3}
                      bg="green.500"
                      borderRadius="6px"
                      mt={[0, 7]}
                    >
                      <Icon
                        p={1}
                        pr={2}
                        className="fa-solid"
                        as={FontAwesomeIcon}
                        icon={faCheckCircle}
                        boxSize="24px"
                      />
                      <Flex flexDirection="column" pr={2}>
                        <Heading size="hl-md" fontWeight="700">
                          Profile successfully updated.
                        </Heading>
                      </Flex>
                      <Spacer />
                      <Icon
                        translateX={2}
                        translateY={4}
                        as={FontAwesomeIcon}
                        sx={{
                          ':hover': {
                            cursor: 'pointer',
                          },
                        }}
                        icon={faClose}
                        boxSize="16px"
                        display="absolute"
                        onClick={() => toast.closeAll()}
                      />
                    </Flex>
                  ),
                  duration: 7000,
                  status: 'success',
                  isClosable: true,
                  position: 'top',
                });
              }
            })
            .catch(err => console.log(err));

          if (onClose) {
            setFile(undefined);
            onClose();
          }

          setSubmitting(false);
        } else {
          console.log('Saving failed!');
          setSubmitting(false);
        }
      }
    } catch (err) {
      console.log('Error saving avatar', err);
    }
  };

  const handleDeleteAvatar = async () => {
    try {
      if (globalUser?.userId) {
        setDeleteButtonLoading(true);
        await deleteAvatar({ userId: globalUser.userId });
        setFile(undefined);
        setDeleteButtonLoading(false);
        onDeleteClose();
      }
    } catch (err) {
      console.log('Error deleting avatar', err);
    }
  };

  return (
    <Flex maxWidth="500px" flexDirection="column">
      {/* @ts-ignore */}
      <Dropzone
        onDrop={dropped => {
          console.log('dropped', dropped);
        }}
        noClick
      >
        {({ getRootProps }) => (
          // @ts-ignore
          <Box {...getRootProps()} mx="auto">
            {isMobile ? (
              <AvatarEditor
                ref={setEditorRef}
                image={file ?? ''}
                width={60}
                height={60}
                borderRadius={999}
                border={[130, 75]}
                color={[0, 0, 0, 0.5]}
                scale={scaleValue}
                rotate={DEGREES[rotateIndex]}
                className="photo-editor-canvas"
              />
            ) : (
              <AvatarEditor
                ref={setEditorRef}
                image={file ?? ''}
                width={200}
                height={190}
                borderRadius={999}
                border={[150, 75]}
                color={[0, 0, 0, 0.5]}
                scale={scaleValue}
                rotate={DEGREES[rotateIndex]}
                className="photo-editor-canvas"
              />
            )}
          </Box>
        )}
      </Dropzone>
      <PhotoEditorControls
        setScaleValue={setScaleValue}
        rotateAvatar={rotateAvatar}
        onDeleteOpen={onDeleteOpen}
      />
      <Flex as="form" flexDir="column" onSubmit={handleSubmit(savePicture)} mt={10}>
        <FormControl isInvalid={!!errors.file_} isRequired>
          <FormLabel display="none">File input</FormLabel>

          <FileUpload
            accept="image/*"
            register={register('file_', { validate: validateFiles })}
            alignItems="flex-end"
            onChange={(e: BaseSyntheticEvent) => setFile(e?.target?.files?.[0])}
          >
            <Button px={8} size="sm" mr={2} leftIcon={<FontAwesomeIcon icon={faCaretUp} />}>
              Upload Photo
            </Button>
            {/* @ts-ignore */}
            <Input readOnly variant="flushed" value={file?.name ?? ''} />
          </FileUpload>

          <FormErrorMessage>{errors.file_ && errors?.file_.message}</FormErrorMessage>
        </FormControl>
        <Box alignSelf="flex-end" my={5}>
          <Button
            type="submit"
            variant="outline"
            size="lg"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={!file || !isEmpty(errors) || isSubmitting}
          >
            Save
          </Button>
        </Box>
      </Flex>

      <AlertDialog isOpen={isDeleteOpen} leastDestructiveRef={cancelRef} onClose={onDeleteClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Avatar
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>
            <AlertDialogFooter>
              <Button isDisabled={isDeleteButtonLoading} ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button
                isLoading={isDeleteButtonLoading}
                colorScheme="red"
                onClick={handleDeleteAvatar}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
