import * as React from 'react';
import {
  potion,
  forwardRef,
  omitThemingProps,
  ThemingProps,
  useStyleConfig,
  HTMLPotionProps,
} from '../../system';
import { cx, __DEV__ } from '../../utils';

export interface HeadingProps extends HTMLPotionProps<'h2'>, ThemingProps<'Heading'> {}

export const Heading = forwardRef<HeadingProps, 'h2'>((props, ref) => {
  const styles = useStyleConfig('Heading', props);
  const { className, ...rest } = omitThemingProps(props);

  return (
    <potion.h2
      ref={ref}
      className={cx('potion-heading', props.className)}
      {...rest}
      __css={styles}
    />
  );
});

if (__DEV__) {
  Heading.displayName = 'Heading';
}
