import React from 'react';
import * as yup from 'yup';
import _get from 'lodash/get';
import { Box, Text, Button, Heading, Center, VStack } from '@companydotcom/potion';
import { InputField } from '@companydotcom/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { User, Listing } from '@companydotcom/types';
import { PendingVerification } from './shared-blpcomponents';
import { useAwaitableFacade, useToast } from '../../../../hooks';
import { useUpdateListingMutation } from '../../../../services/listings/listing-api';

const typeMap = [
  {
    type: 'FACEBOOK',
    key: 'facebook',
    group: 'socialProfiles',
  },
  {
    type: 'TWITTER',
    key: 'twitter',
    group: 'socialProfiles',
  },
  {
    type: 'INSTAGRAM',
    key: 'instagram',
    group: 'socialProfiles',
  },
  {
    type: 'LINKEDIN',
    key: 'linkedIn',
    group: 'socialProfiles',
  },
  {
    type: 'VIDEO',
    key: 'video1',
    group: 'videos',
  },
  {
    type: 'IMAGE',
    key: 'photo1',
    group: 'images',
  },
  {
    type: 'IMAGE',
    key: 'photo2',
    group: 'images',
  },
  {
    type: 'IMAGE',
    key: 'photo3',
    group: 'images',
  },
  {
    type: 'IMAGE',
    key: 'photo4',
    group: 'images',
  },
  {
    type: 'LOGO',
    key: 'logo',
    group: 'images',
  },
];

const validImageFormats = ['png', 'jpeg', 'jpg', 'tiff', 'svg'];
const imageError = 'Link must end in .png, .jpg, .jpeg, .tiff, or .svg';

const SocialMediaSchema = yup.object().shape({
  facebook: yup.string().url('Must be a valid url'),
  instagram: yup.string().url('Must be a valid url'),
  linkedin: yup.string().url('Must be a valid url'),
  logo: yup
    .string()
    .url('Must be a valid url')
    .test(
      'is-image',
      imageError,
      value => !value || validImageFormats.includes(value.split('.').reverse()[0]),
    ),
  photo1: yup
    .string()
    .url('Must be a valid url')
    .test(
      'is-image',
      imageError,
      value => !value || validImageFormats.includes(value.split('.').reverse()[0]),
    ),
  photo2: yup
    .string()
    .url('Must be a valid url')
    .test(
      'is-image',
      imageError,
      value => !value || validImageFormats.includes(value.split('.').reverse()[0]),
    ),
  photo3: yup
    .string()
    .url('Must be a valid url')
    .test(
      'is-image',
      imageError,
      value => !value || validImageFormats.includes(value.split('.').reverse()[0]),
    ),
  photo4: yup
    .string()
    .url('Must be a valid url')
    .test(
      'is-image',
      imageError,
      value => !value || validImageFormats.includes(value.split('.').reverse()[0]),
    ),
  twitter: yup.string().url('Must be a valid url'),
  video1: yup.string().url('Must be a valid url'),
});

interface SocialMediaProps {
  user?: User;
  listing?: Listing;
  setListing?: (arg: Listing) => void;
  showPendingVerification?: boolean;
}

export const SocialMedia = ({
  user,
  listing,
  setListing,
  showPendingVerification,
}: SocialMediaProps) => {
  const toast = useToast();
  const [updateListing] = useUpdateListingMutation();
  const snsInterface = useAwaitableFacade(user, 'listing');

  const { register, handleSubmit, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(SocialMediaSchema),
    defaultValues: (() => {
      const profiles = listing?.socialProfiles || [];
      const images = listing?.images || [];
      return {
        facebook: profiles.find(x => x?.type === 'FACEBOOK')?.url || '',
        twitter: profiles.find(x => x?.type === 'TWITTER')?.url || '',
        instagram: profiles.find(x => x?.type === 'INSTAGRAM')?.url || '',
        linkedIn: profiles.find(x => x?.type === 'LINKEDIN')?.url || '',
        video1: _get(listing, 'videos.0.url', ''),
        photo1: images.filter(x => x?.type === 'IMAGE')?.[0]?.url || '',
        photo2: images.filter(x => x?.type === 'IMAGE')?.[1]?.url || '',
        photo3: images.filter(x => x?.type === 'IMAGE')?.[2]?.url || '',
        photo4: images.filter(x => x?.type === 'IMAGE')?.[3]?.url || '',
        logo: images.find(x => x?.type === 'LOGO')?.url || '',
      };
    })(),
  });
  const { isSubmitting, isValid, errors } = formState;

  const onSubmit = async (values: any) => {
    typeMap.forEach((type: any) => {
      type.typeFound = false;
    });
    const updateParams: any = {};

    updateParams.socialProfiles = [];
    updateParams.videos = [];
    updateParams.images = [];

    typeMap.forEach((type: any) => {
      if (!type.typeFound && values[type.key]) {
        updateParams[type.group].push({
          url: values[type.key],
          type: type.type,
        });
      }
    });

    try {
      if (user) {
        await updateListing({
          listingInput: { source: user?.source, id: user.account?.listingId, ...updateParams },
          snsInterface,
        })
          .unwrap()
          .then(updatedListing => {
            setListing?.(updatedListing);
            toast({
              description: 'Your changes have been synced!',
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
          });
      }

      // await updateListing({
      //   input: {
      //     id: user?.account?.listingId,
      //     ...updateParams,
      //   },
      // })
      //   .unwrap()
      //   .then((updatedListing: Listing) => {
      //     setListing?.(updatedListing);
      //     toast({
      //       description: 'Your changes have been synced!',
      //       status: 'success',
      //       duration: 9000,
      //       isClosable: true,
      //       position: 'bottom-right',
      //     });
      //   });
    } catch (err) {
      console.log('Error updating Listing', err);
    }
  };

  const prefixHttps = (event: any) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0 && value.slice(0, 8) !== 'https://') {
      event.target.value = `https://${value}`;
    }
  };

  return (
    <Center
      className="blp-social-media"
      flexDirection="column"
      pt={[12, null, 3]}
      pb={[12, null, 15]}
      width="full"
      px={4}
      textAlign="center"
      justifyContent="flex-start"
    >
      {showPendingVerification && <PendingVerification />}
      <Box maxW="xl">
        <Heading as="h1" size="hs-lg">
          Social Media & Photos
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          Add your social media information and photos so that customers can find you on social
          media and see pictures from your business.
        </Heading>
        <Text textStyle="md" mt={4}>
          All fields are required unless marked optional
        </Text>
      </Box>
      <Box maxWidth={475} as="form" mt={14} width="full">
        <Box width="full">
          <Text textAlign="left" textStyle="lg" fontWeight="medium" mb={4}>
            Social Media Links
          </Text>
          <VStack spacing={6}>
            <InputField
              register={register}
              name="facebook"
              onBlur={prefixHttps}
              label="Facebook Link (optional)"
              errors={errors}
            />
            <InputField
              register={register}
              name="twitter"
              label="Twitter Link (optional)"
              onBlur={prefixHttps}
              errors={errors}
            />
            <InputField
              register={register}
              name="instagram"
              label="Instagram Link (optional)"
              errors={errors}
              onBlur={prefixHttps}
            />
            <InputField
              register={register}
              name="linkedIn"
              label="LinkedIn Link (optional)"
              errors={errors}
              onBlur={prefixHttps}
            />
          </VStack>
        </Box>
        <Box width="full" py={[4]} mt={[8, null, 12]}>
          <Text textAlign="left" textStyle="lg" fontWeight="medium" mb={4}>
            Media Links
          </Text>
          <VStack spacing={6}>
            <InputField
              register={register}
              name="video1"
              label="Video Link (optional)"
              margin="normal"
              errors={errors}
              onBlur={prefixHttps}
            />
            <InputField
              register={register}
              name="photo1"
              label="Photo Link 1 (optional)"
              errors={errors}
              onBlur={prefixHttps}
            />
            <InputField
              register={register}
              name="photo2"
              label="Photo Link 2 (optional)"
              errors={errors}
              onBlur={prefixHttps}
            />
            <InputField
              register={register}
              name="photo3"
              label="Photo Link 3 (optional)"
              errors={errors}
              onBlur={prefixHttps}
            />
            <InputField
              register={register}
              name="photo4"
              label="Photo Link 4 (optional)"
              errors={errors}
              onBlur={prefixHttps}
            />
            <InputField
              register={register}
              name="logo"
              label="Logo Link (optional)"
              errors={errors}
              onBlur={prefixHttps}
            />
          </VStack>
        </Box>
        <Box mt={14}>
          <Button
            size="lg"
            isLoading={isSubmitting}
            isDisabled={!isValid || isSubmitting || showPendingVerification}
            variant="outline"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
