import React from 'react';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { ACGShoppingCart } from '../../../features/acg/acg-shopping-cart';

export const ACGShoppingCartPage: React.FC = () => {
  return (
    <PageLayout>
      <PageLayoutContent>
        <ACGShoppingCart />
      </PageLayoutContent>
    </PageLayout>
  );
};
