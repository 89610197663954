import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { DarkScanResult } from '../../services/dark-web-scan/dark-web-scan.types';

export interface DarkWebScanState {
  results?: DarkScanResult[];
}

export const initialState: DarkWebScanState = {
  results: [],
};

export const darkWebScanSlice = createSlice({
  name: 'darkWebScan',
  initialState,
  reducers: {
    updateDarkWebScanResults: (state, action: PayloadAction<DarkScanResult[]>) => {
      state.results = action.payload;
    },
  },
});

export const { updateDarkWebScanResults } = darkWebScanSlice.actions;

export const selectDarkWebScanResults = (state: RootState) => state.darkWebScan.results;

export default darkWebScanSlice.reducer;
