import React from 'react';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { Invoices } from '../../../features/acg/acg-invoices';

export const InvoicesPage: React.FC = () => {
  return (
    <PageLayout>
      <PageLayoutContent>
        <Invoices />
      </PageLayoutContent>
    </PageLayout>
  );
};
