import React from 'react';
import {
  Flex,
  FlexProps,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  IconButton,
  IconButtonProps,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  useBreakpointValue,
  forwardRef,
  Icon,
  Image,
  HStack,
  Button,
  Stack,
  Modal,
  ModalBody,
  ModalContent,
  useBoolean,
} from '@companydotcom/potion';
import { useSource } from '@companydotcom/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faShoppingCart } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BYOP } from '../../../../features/byop';
import { useAcgCartStorage } from '../../../../features/acg';

export interface MobileSideNavProps extends FlexProps {}

export const MobileSideNav: React.FC<MobileSideNavProps> = props => {
  const { children, ...rest } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalOpen, setModalOpen] = useBoolean();
  const source = useSource();
  const btnRef = React.useRef(null);
  const styles = useMultiStyleConfig('MobileSideNav', props);
  const openLogic = useBreakpointValue({ base: isOpen, md: false });
  const { getLocalCart } = useAcgCartStorage();
  const cart = getLocalCart();
  const { t } = useTranslation();

  return (
    <StylesProvider value={styles}>
      {/* @ts-ignore */}
      <MobileSideNavContainer
        className="potion-mobilesidenav__container"
        sx={styles.container}
        {...rest}
        pb={12}
      >
        <Image
          alt={`${source.sourceId} Logo`}
          src={
            source.images?.sidenav_logo ??
            `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/${
              source.sourceId ?? 'company'
            }/images/logo.svg`
          }
          sx={styles.logo}
        />
        <HStack spacing={6}>
          {source?.sourceId === 'acg' ? (
            <HStack spacing={6}>
              <Button
                variant="link"
                as={NavLink}
                to="/acg-shopping-cart"
                leftIcon={
                  <Icon
                    as={FontAwesomeIcon}
                    icon={faShoppingCart}
                    color="gray.500"
                    sx={{ position: 'relative' }}
                  />
                }
              >
                {cart?.items?.event ||
                (cart?.items?.memberships && cart?.items?.memberships?.length > 0) ? (
                  <Icon
                    as={FontAwesomeIcon}
                    icon={faCircle}
                    color="green.500"
                    sx={{ position: 'absolute', width: '8px', right: '10px', top: '-5px' }}
                  />
                ) : (
                  ''
                )}
              </Button>
              <Button
                margin={0}
                padding={0}
                variant="ghost"
                size="sm"
                fontWeight="semibold"
                leftIcon={<Icon as={FontAwesomeIcon} icon={faSearch} color="gray.500" />}
                iconSpacing={0}
                onClick={() => setModalOpen.on()}
              >
                <Icon
                  as={FontAwesomeIcon}
                  icon={faCaretDown}
                  style={{ width: '8px', marginLeft: '0px' }}
                  color="gray.500"
                />
              </Button>
              <Modal isOpen={modalOpen} onClose={() => setModalOpen.off()}>
                <ModalContent borderWidth="1px" width="auto" pt={4} pb={4}>
                  <ModalBody>
                    <Stack align="flex-start" spacing={3}>
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() =>
                          window.open(`${process.env.REACT_APP_ACG_BASE_URL || ''}/events`)
                        }
                      >
                        {t(`acg.events.topRailText`)}
                      </Button>
                      <Button variant="link" size="sm" as={NavLink} to="/member-directory">
                        {t(`acg.memberSearch.search.searchNavText`)}
                      </Button>
                    </Stack>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </HStack>
          ) : (
            <HStack spacing={6}>
              <BYOP />
            </HStack>
          )}

          <MobileSideNavHamburger ref={btnRef} onClick={onOpen} aria-label="Hamburger menu" />
        </HStack>
      </MobileSideNavContainer>

      <Drawer
        size="sidenav"
        isOpen={openLogic ?? isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bgColor="#FFFFFF">{children}</DrawerContent>
      </Drawer>
    </StylesProvider>
  );
};

export const MobileSideNavContainer: React.FC<FlexProps> = props => {
  const styles = useStyles();

  // @ts-ignore
  return <Flex className="potion-mobilesidenav__container" {...props} sx={styles.container} />;
};

export const MobileSideNavHamburger = forwardRef<IconButtonProps, 'button'>((props, ref) => {
  const styles = useStyles();
  return (
    // @ts-ignore
    <IconButton
      ref={ref}
      className="potion-mobilesidenav__hamburger"
      variant="unstyled"
      sx={styles.hamburger}
      icon={<Icon as={FontAwesomeIcon} icon={faBars} boxSize="28px" />}
      {...props}
    />
  );
});
