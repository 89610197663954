import { FaceCakePageLayout } from '../../features/facecake/layouts/page-layout';
import { ChangePasswordForm } from '../password-management/components/change-password-form';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Box, Heading, Text } from '@companydotcom/potion';
import { useSourceConfig } from '@companydotcom/providers';
import { useGetGlobalUserQuery, useUpdateUserPasswordMutation } from '../../services/user/user-api';
import { checkForPasswordBreach } from '../password-management/api/check-for-password-breach';
import { useToast } from '../../hooks/use-toast';
import { PageDivider } from '@companydotcom/ui';

export const FaceCakePasswordManagementPage = () => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [updatePassword] = useUpdateUserPasswordMutation();
  const sourceConfig = useSourceConfig();
  const { t } = useTranslation();
  const defaultValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const ChangeAccountPasswordStepSchema = () =>
    yup.object().shape({
      confirmNewPassword: yup
        .string()
        .matches(/^\S*$/, 'common.inputs.password.error')
        .oneOf([yup.ref('newPassword'), undefined], 'common.inputs.confirmNewPassword.validation')
        .min(
          sourceConfig.passwordRequirements?.min || 8,
          t('miop.passwordManagement.minCharacter', {
            minimum: sourceConfig.passwordRequirements?.min,
          }),
        )
        .required('common.inputs.confirmNewPassword.error'),
      newPassword: yup
        .string()
        .matches(/^\S*$/, 'common.inputs.password.error')
        .min(
          sourceConfig.passwordRequirements?.min || 8,
          t('miop.passwordManagement.minCharacter', {
            minimum: sourceConfig.passwordRequirements?.min,
          }),
        )
        .max(
          sourceConfig.passwordRequirements?.max || 20,
          t('miop.passwordManagement.maxCharacter', {
            maximum: sourceConfig.passwordRequirements?.max,
          }),
        )
        .test(
          'contains symbol',
          t('miop.passwordManagement.symbol', {
            symbol: sourceConfig.passwordRequirements?.specialChars,
          }),
          value => {
            if (
              sourceConfig.passwordRequirements?.specialChars === '' ||
              sourceConfig.passwordRequirements?.specialChars === undefined
            ) {
              return true;
            }
            if (value && sourceConfig.passwordRequirements?.specialChars) {
              for (const s of sourceConfig.passwordRequirements?.specialChars) {
                if (value.includes(s)) {
                  return true;
                }
              }
            }
            return false;
          },
        )
        .required('common.inputs.newPassword.error'),
    });

  const { register, handleSubmit, setError, formState, reset } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(ChangeAccountPasswordStepSchema()),
    defaultValues,
  });
  const toast = useToast();
  const { isSubmitting, isValid, errors } = formState;
  const [dataBreach, setDataBreach] = useState(false);

  const manualErrors = [
    {
      type: 'manual',
      name: 'newPassword',
      message: 'Please try again',
    },
    {
      type: 'manual',
      name: 'confirmNewPassword',
      message: 'Please try again',
    },
  ];

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      setDataBreach(false);
      const breachAttempts = await checkForPasswordBreach(values.confirmNewPassword);

      if (breachAttempts > 0) {
        setDataBreach(true);
        manualErrors.forEach(({ name, type, message }) =>
          setError(name as 'newPassword' | 'confirmNewPassword', { type, message }),
        );
        return;
      }

      // This resolves into a GQL error, but end-to-end saves the password on the front & backend for the user. Tech debt: will need to be resolved in the future
      await updatePassword({
        userId: globalUser?.userId,
        password: values?.newPassword,
      });

      toast({
        description: 'Password Successfully Changed',
        status: 'success',
        position: 'top',
        isClosable: false,
      });
      reset();
    } catch (err) {
      toast({
        description: t('miop.passwordManagement.snackbar.error'),
        status: 'error',
        position: 'top',
      });
      reset();
      console.log('Error!', err);
    }
  };
  return (
    <FaceCakePageLayout>
      <Box mb={6}>
        <Heading size="hs-2xl" textAlign="left">
          Password Management
        </Heading>
        <PageDivider mt={[2, 4]} mx="inherit" />
      </Box>
      <Box textAlign="left" mb={12} mt={[6, 12]}>
        <Text textStyle="md">{t('miop.passwordManagement.requirements')}</Text>
      </Box>
      <ChangePasswordForm register={register} errors={errors} dataBreach={dataBreach} />
      <Box textAlign="left" mt={[8]}>
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          size="lg"
          isDisabled={!isValid}
          isLoading={isSubmitting}
          width={['full', 'inherit']}
        >
          {t('common.buttons.save')}
        </Button>
      </Box>
    </FaceCakePageLayout>
  );
};
