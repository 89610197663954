import React from 'react';
import { Tabs, TabPanels, TabPanel, useTabStepper, useMediaQuery } from '@companydotcom/potion';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { SearchStep } from './member-search-search-step';
import { NonMembership } from './member-search-nonMember';
import { PendingMembership } from './member-search-pendingMember';
import { LapsedMember } from './member-search-lapsedMember';
import { AcgReferenceData } from '../../../../services/acg/acg.types';
import { useGetAcgQueryArgs, useGetMembershipsV2Query } from '../../../../services/acg/acg-api-v2';

const memberSearchSteps = [
  {
    slug: 'search-step',
    component: <SearchStep />,
  },
  {
    slug: 'join',
    component: <NonMembership />,
  },
  {
    slug: 'pending',
    component: <PendingMembership />,
  },
  {
    slug: 'lapsed',
    component: <LapsedMember />,
  },
];

export interface MemberSearchProps {
  memberDirectoryGoToStep?: (arg: number | string) => void;
  acgRefData?: AcgReferenceData;
}

export const MemberSearch: React.FC<MemberSearchProps> = ({
  memberDirectoryGoToStep,
  acgRefData,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const { data: membership } = useGetMembershipsV2Query({ ...args });

  const { tabIndex, handleTabsChange } = useTabStepper({
    steps: memberSearchSteps,
  });

  const sharedProps = {
    isMobile,
    memberDirectoryGoToStep,
    acgRefData,
  };

  return (
    <Tabs variant="page" index={tabIndex} onChange={handleTabsChange}>
      <TabPanels>
        <TabPanel>
          {membership?.status === 'Active' &&
            React.cloneElement(memberSearchSteps[0].component, sharedProps)}
          {membership?.status === 'Inactive' &&
            React.cloneElement(memberSearchSteps[1].component, sharedProps)}
          {membership?.status === 'Pending' &&
            React.cloneElement(memberSearchSteps[2].component, sharedProps)}
          {membership?.status === 'Lapsed' &&
            React.cloneElement(memberSearchSteps[3].component, {
              membership,
              globalUser,
              sharedProps,
            })}
          {!membership?.status && React.cloneElement(memberSearchSteps[1].component, sharedProps)}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
