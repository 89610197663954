export default {
    // Normal text styles
    xs: {
        fontSize: '12px',
        lineHeight: 1.5,
    },
    sm: {
        fontSize: '14px',
        lineHeight: 1.5,
    },
    md: { fontSize: '16px', lineHeight: 1.5 },
    lg: { fontSize: '18px', lineHeight: 1.5 },
    xl: { fontSize: '20px', lineHeight: 1.5 },
    '2xl': {
        fontSize: '24px',
        lineHeight: 1.5,
    },
    '3xl': {
        fontSize: '30px',
        lineHeight: 1.5,
    },
    '4xl': {
        fontSize: '36px',
        lineHeight: 1.5,
    },
    '5xl': {
        fontSize: '48px',
        lineHeight: 1.5,
    },
    '6xl': {
        fontSize: '60px',
        lineHeight: 1.5,
    },
};
