import React, { useState, useEffect } from 'react';
import { Tabs, TabPanels, TabPanel, useTabStepper } from '@companydotcom/potion';
import { InvoicesList } from './invoices-list';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { useGetInvoiceListByCustomerQuery } from '../../../../services/payment/payment-api';

const invoicesSteps = [
  {
    slug: 'invoices-list',
    component: <InvoicesList />,
  },
];

export interface InvoicesSharedProps {
  nextStep?: () => void;
  previousStep?: (arg0: number | string) => void;
  invoiceData?: any;
  isLoading?: boolean;
  currentInvoice?: any;
  setCurrentInvoice?: (arg: any | {}) => void;
}

export interface LocationStateProps {
  step: 'invoices-list' | 'invoices-details';
  openInvoices: {
    invoiceKey: string;
    invoiceNumber: string;
  };
}

export const FaceCakeInvoices = () => {
  const [currentInvoice, setCurrentInvoice] = useState<any | undefined | {}>({});

  const { tabIndex, handleTabsChange, nextStep, previousStep } = useTabStepper({
    steps: invoicesSteps,
  });

  const { data: globalUser } = useGetGlobalUserQuery();
  const [invoiceData, setInvoiceData] = useState([]);

  const { data: invoices, isLoading } = useGetInvoiceListByCustomerQuery({
    customerId: globalUser?.account?.chargebeeCustomerId || '',
  });

  const originalInvoiceData = React.useMemo(
    () => (invoices ? JSON.parse(invoices)?.invoices : []),
    [invoices],
  );

  useEffect(() => {
    if (originalInvoiceData && originalInvoiceData.length > 0) {
      const tempInvoiceData = originalInvoiceData.map(
        ({ id, amount_paid, date }: { id: string; amount_paid: number; date: string }) => ({
          invoiceNumber: id,
          amount: amount_paid,
          invoiceDate: date,
        }),
      );
      setInvoiceData(tempInvoiceData);
    }
  }, [originalInvoiceData]);

  const sharedProps = {
    nextStep,
    previousStep,
    invoiceData,
    currentInvoice,
    setCurrentInvoice,
    isLoading,
  };

  return (
    <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
      <TabPanels>
        {invoicesSteps.map(step => (
          <TabPanel key={step.slug}>{React.cloneElement(step.component, sharedProps)}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
