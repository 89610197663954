import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import {
  Tabs,
  TabPanels,
  TabPanel,
  useTabStepper,
  Center,
  Box,
  Accordion,
  Text,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import {
  useGetAcgQueryArgs,
  useGetUserPurchasedEventsV2Query,
  useGetAcgUserProfileV2Query,
  useGetMembershipsV2Query,
} from '../../../../services/acg/acg-api-v2';
import { EventRegistrationNotEligible } from './event-registration-not-eligible';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { EventRegistrationDemographicQuestions } from './event-registration-demographic-questions';
import { BackButtonLayout } from '../../../../components/layouts';
import { EventRegistrationRegisterSelf } from './event-registration-register-self';
import { EventRegistrationCapacityReached } from './event-registration-capacity-reached';
import { EventsHeader, EventsTitle, useFormatAcgEventDate } from '../../shared';
import { selectActiveRegistrant } from '../event-registration-slice';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { useGetEventForRegistrationQuery } from '../../../../services/acg/acg-api';
import { EventRegistrationAddGuest } from './event-registration-add-guest';
import { MyEventsViewGuestList } from '../../acg-my-events';
import { selectGuestsToRegister } from '../../acg-add-guest/add-guest-slice';
import { updateActiveEvent } from '../../acg-my-events/my-events-slice';
import { QAndA } from '../../../../services/acg/acg.types';
import { EventRegistrationWaitlist } from './event-registration-waitlist';

const steps = [
  {
    slug: 'register-self',
    component: <EventRegistrationRegisterSelf />,
  },
  {
    slug: 'add-new-guest',
    component: <EventRegistrationAddGuest />,
  },
  {
    slug: 'demographic-questions',
    component: <EventRegistrationDemographicQuestions />,
  },
  {
    slug: 'not-eligible',
    component: <EventRegistrationNotEligible />,
  },
  {
    slug: 'full',
    component: <EventRegistrationCapacityReached />,
  },
  {
    slug: 'waitlist',
    component: <EventRegistrationWaitlist />,
  },
];

// Sample link to use for testing
// http://acg.dashboard.localhost:3000/event-registration?Reg_evt_key=DXP-TESTING-EVENT-3
// http://acg.dashboard.localhost:3000/event-registration?Reg_evt_key=ACG-TEST-EVENT-1

export const EventRegistration = () => {
  const location: any = useLocation();
  // console.log('location param:', location?.state);
  // State
  const [initialStep, setInitialStep] = useState<string | number>(
    location?.state?.lastStep ? location?.state?.lastStep : 'register-self',
  );
  const activeRegistrant = useAppSelector(selectActiveRegistrant);
  const guestsToRegister = useAppSelector(selectGuestsToRegister);

  const dispatch = useAppDispatch();
  // Hooks
  const [searchParams] = useSearchParams();
  const regEventKey = searchParams.get('Reg_evt_key');

  const args = useGetAcgQueryArgs();
  const { data: globalUser, isLoading: isGlobalUserLoading } = useGetGlobalUserQuery();

  const { data: acgUserProfile, isLoading: isAcgUserProfileLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );
  const { data: membershipData, isLoading: isMembershipsLoading } = useGetMembershipsV2Query({
    ...args,
  });

  const isAcgMember = membershipData?.status?.toLowerCase() === 'active';

  const {
    data: eventForRegistration,
    isFetching: isAcgEventLoading,
    isError,
  } = useGetEventForRegistrationQuery(
    globalUser && regEventKey
      ? {
          user: globalUser,
          eventId: regEventKey,
        }
      : skipToken,
  );
  console.log('🫵 -----------------------------------------🫵');
  console.log('🫵 : EventRegistration : isError', isError);
  console.log('🫵 -----------------------------------------🫵');

  const { data: purchasedEvents } = useGetUserPurchasedEventsV2Query(
    eventForRegistration?.isAlreadyRegistered ? args : skipToken,
  );

  const navigate = useNavigate();

  const { tabIndex, handleTabsChange, nextStep, previousStep, goToStep, currentStep } =
    useTabStepper({
      steps,
      initialStep,
    });

  const showWaitListAlert = useMemo(() => {
    if (
      eventForRegistration?.eventCapacity &&
      eventForRegistration?.totalRegistered &&
      eventForRegistration?.hasWaitList &&
      eventForRegistration?.totalRegistered >= eventForRegistration?.eventCapacity
    ) {
      return true;
    }
    return false;
  }, [
    eventForRegistration?.eventCapacity,
    eventForRegistration?.hasWaitList,
    eventForRegistration?.totalRegistered,
  ]);

  // Logic to determine what step to send the user to, if any
  useEffect(() => {
    // If event is member only, or if event has already happened, or if theres no packages for guests or registrants
    if (
      (eventForRegistration?.isEventMemberOnly && !isAcgMember) ||
      dayjs().utc().isAfter(eventForRegistration?.eventEndDate) ||
      eventForRegistration?.registrantAndFees?.every(o => !o.guests && !o.packages)
    ) {
      goToStep('not-eligible');
    }

    // If event is passed capacity, but there is no waitlist enabled on the event
    if (
      eventForRegistration?.eventCapacityEnabled &&
      !eventForRegistration?.hasWaitList &&
      eventForRegistration?.eventCapacity &&
      eventForRegistration?.totalRegistered &&
      eventForRegistration?.totalRegistered >= eventForRegistration?.eventCapacity
    ) {
      goToStep('full');
    }

    // If the event is eligible for waitlist signup
    if (showWaitListAlert || eventForRegistration?.onWaitList) {
      goToStep('waitlist');
    }
    if (initialStep == undefined) {
      setInitialStep(0);
    } else {
      setInitialStep(tabIndex);
    }
  }, [
    eventForRegistration,
    acgUserProfile,
    goToStep,
    showWaitListAlert,
    isAcgMember,
    tabIndex,
    initialStep,
  ]);

  // Routes an already registered user to the registered event page, coming from an external source
  useEffect(() => {
    const alreadyRegisteredForEvent =
      eventForRegistration?.isAlreadyRegistered &&
      purchasedEvents?.find(e => e?.eventId === regEventKey);
    if (
      alreadyRegisteredForEvent &&
      currentStep.slug !== 'waitlist' &&
      !eventForRegistration?.onWaitList
    ) {
      const { eventName, eventId, id, eventStartDate, eventEndDate } = alreadyRegisteredForEvent;
      dispatch(
        updateActiveEvent({
          eventName,
          eventId,
          id,
          eventStartDate,
          eventEndDate,
        }),
      );
      navigate('/my-events', { state: { defaultStep: 'my-events-edit' } });
    }
  }, [
    currentStep.slug,
    dispatch,
    eventForRegistration?.isAlreadyRegistered,
    eventForRegistration?.onWaitList,
    navigate,
    purchasedEvents,
    regEventKey,
  ]);

  const date = useFormatAcgEventDate({
    eventStartDate: eventForRegistration?.eventStartDate,
    eventEndDate: eventForRegistration?.eventEndDate,
    eventTimezone: eventForRegistration?.eventTimezone,
    eventTimezoneMode: eventForRegistration?.eventTimezoneMode,
    eventTimezoneAbbreviation: eventForRegistration?.eventTimezoneAbbreviation,
  });

  return (
    <BackButtonLayout
      isDisplayed={tabIndex > 0}
      onBackClick={() => {
        if (tabIndex === 3 || tabIndex === 4 || tabIndex === 5) {
          navigate('/');
        } else if (tabIndex === 2 && !activeRegistrant?.guestRegistrationType) {
          goToStep('register-self');
        } else {
          previousStep?.();
        }
      }}
    >
      <Tabs variant="page" index={tabIndex} onChange={handleTabsChange}>
        <TabPanels>
          {steps.map(step => (
            <TabPanel key={step.slug}>
              <Center
                flexDirection="column"
                bg={['transparent', 'white']}
                py={[0, 8, 12]}
                px={[0, 4]}
              >
                <Box width="full" maxW={908}>
                  {tabIndex !== 2 ? (
                    <Box>
                      <EventsHeader text="Event Registration" />
                      {!isAcgEventLoading &&
                      !isAcgUserProfileLoading &&
                      !isMembershipsLoading &&
                      eventForRegistration &&
                      tabIndex !== 3 ? (
                        <Box pt={4}>
                          <EventsTitle
                            eventName={eventForRegistration?.eventName}
                            eventDate={date}
                            viewAttendeeListHandler={() => {
                              dispatch(
                                updateActiveEvent({
                                  eventName: eventForRegistration?.eventName,
                                  eventId: eventForRegistration?.eventKey,
                                  eventStartDate:
                                    eventForRegistration?.eventStartDate as unknown as string,
                                  eventEndDate:
                                    eventForRegistration?.eventEndDate as unknown as string,
                                }),
                              );
                              navigate('../my-events', {
                                state: { defaultStep: 'my-events-attendee-list' },
                              });
                            }}
                            showDivider={
                              !eventForRegistration ||
                              (currentStep.slug === 'register-self' && !guestsToRegister.length) ||
                              (!activeRegistrant && !guestsToRegister.length && tabIndex < 3)
                            }
                          />
                          {activeRegistrant && currentStep.slug !== 'register-self' && (
                            <Accordion mt={12} mb={showWaitListAlert ? 0 : 12} allowToggle>
                              <AccordionItem borderTopWidth="0px" borderBottomWidth="1px">
                                <AccordionButton py={4}>
                                  <Text
                                    flex="1"
                                    textAlign="left"
                                    fontWeight="semibold"
                                    fontSize="md"
                                  >
                                    {`Attendee: ${activeRegistrant?.fullName}`}
                                  </Text>
                                  <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pt={0}>
                                  <MyEventsViewGuestList
                                    variant="multiple"
                                    {...activeRegistrant}
                                    registrantName={
                                      activeRegistrant?.fullName! as string | undefined
                                    }
                                    registrantType={
                                      eventForRegistration?.registrantAndFees?.find?.(
                                        x =>
                                          x?.registrantType?.registrantTypeId ===
                                          activeRegistrant?.registrantType,
                                      )?.registrantType?.registrantTypeName ||
                                      activeRegistrant?.registrantType
                                    }
                                    qAndA={
                                      eventForRegistration?.registrantAndFees
                                        ?.find?.(
                                          x =>
                                            x?.registrantType?.registrantTypeId ===
                                            activeRegistrant?.registrantType,
                                        )
                                        ?.customQuestions?.filter(
                                          x => activeRegistrant?.customQuestions?.[x?.questionId],
                                        )
                                        .map(q => ({
                                          ...q,
                                          question: q?.questionName,
                                          answer:
                                            activeRegistrant?.customQuestions?.[
                                              q?.questionId
                                            ]?.toString(),
                                        })) as Partial<QAndA>[]
                                    }
                                  />
                                </AccordionPanel>
                              </AccordionItem>
                              {guestsToRegister.map((guest, i) => (
                                <AccordionItem borderTopWidth="0px" borderBottomWidth="1px" key={i}>
                                  <AccordionButton py={4}>
                                    <Text
                                      flex="1"
                                      textAlign="left"
                                      fontWeight="semibold"
                                      fontSize="md"
                                    >
                                      {`Guest: ${guest?.firstName} ${guest?.lastName}`}
                                    </Text>
                                    <AccordionIcon />
                                  </AccordionButton>
                                  <AccordionPanel pt={0}>
                                    <MyEventsViewGuestList
                                      variant="multiple"
                                      registrantName={`${guest?.firstName} ${guest?.lastName}`}
                                      registrantType={'blah blah'}
                                      {...guest}
                                    />
                                  </AccordionPanel>
                                </AccordionItem>
                              ))}
                            </Accordion>
                          )}
                        </Box>
                      ) : null}
                    </Box>
                  ) : null}
                  {isAcgEventLoading ||
                  isAcgUserProfileLoading ||
                  isGlobalUserLoading ||
                  isError ? (
                    <AppSpinner
                      containerStyles={{ mx: 'auto', mb: 24, mt: 20 }}
                      description="Fetching your event..."
                    />
                  ) : (
                    <Box>
                      {React.cloneElement(step.component, {
                        nextStep,
                        previousStep,
                        goToStep,
                        eventForRegistration,
                        showWaitListAlert,
                      })}
                    </Box>
                  )}
                </Box>
              </Center>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </BackButtonLayout>
  );
};
