const extensions = {
    Button: {
        variants: {
            link: {
                _hover: {
                    textDecoration: 'none',
                },
            },
        },
    },
    Input: {
        variants: {
            outline: {
                field: {
                    background: 'white',
                },
            },
        },
    },
    NumberInput: {
        variants: {
            outline: {
                field: {
                    background: 'white',
                },
            },
        },
    },
    Textarea: {
        variants: {
            outline: {
                background: 'white',
            },
        },
    },
    Select: {
        variants: {
            outline: {
                field: {
                    background: 'white',
                },
            },
        },
    },
    Checkbox: {
        baseStyle: {
            control: {
                backgroundColor: 'white',
            },
        },
    },
    Radio: {
        baseStyle: {
            control: {
                backgroundColor: 'white',
            },
        },
    },
};
export default extensions;
