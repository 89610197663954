import * as React from 'react';
import { Box, List, Text, Button, ListItem, ListIcon, UnorderedList } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useEventData } from '@companydotcom/ui';
import type { TileComponentProps } from '../../types';
import { TileSpinner } from '../tile-spinner';

export interface CommitteProps {
  text?: string;
  href?: string;
  variant?: string;
  totalCommitteesFound?: string;
  committees: {
    name?: string;
    committeeRecordKey?: number;
    hasWorkspace?: string;
    position?: string;
    startDate?: string;
    endDate?: string;
  }[];
}

export interface TileWithListItemProps extends TileComponentProps {}

export const TileWithListItem: React.FC<TileWithListItemProps> = props => {
  const { variant, eventData } = props;
  const { loading, data } = useEventData<CommitteProps>(eventData);

  if (loading || !data) {
    return <TileSpinner />;
  }

  const iconStyles = {
    marginRight: 2,
  };

  const listIconStyles = {
    textAlign: 'left',
    width: 332,
    margin: 0,
  };

  const listStyles = {
    textAlign: 'left',
    padding: 5,
    width: 310,
    margin: 0,
    height: 259,
  };

  return (
    <>
      {data?.totalCommitteesFound !== '0' && Array.isArray(data?.committees) && variant ? (
        <Box mb="auto" className="tileComponent__textContainer" sx={listStyles}>
          {data?.committees?.map(item => (
            <UnorderedList>
              {
                {
                  list: (
                    <List spacing={3}>
                      <ListItem key={item.committeeRecordKey} padding={1}>
                        {item?.name}
                      </ListItem>
                    </List>
                  ),
                  listLink: item?.hasWorkspace === '1' && (
                    <List spacing={3}>
                      <ListItem key={item.committeeRecordKey} padding={1}>
                        <Button variant="link" size="md" _hover={{ textDecoration: 'none' }}>
                          {item?.name}
                        </Button>
                      </ListItem>
                    </List>
                  ),
                }[variant]
              }
            </UnorderedList>
          ))}
        </Box>
      ) : (
        [props]?.map((item: any, index: number) => {
          return variant === 'listIcon' ? (
            <Box mb="auto" className="tileComponent__textContainer" sx={listIconStyles}>
              <List spacing={3}>
                <ListItem key={index} padding={3}>
                  <ListIcon
                    as={FontAwesomeIcon}
                    className="fa-solid"
                    sx={iconStyles}
                    icon={faCheck}
                    color="green.500"
                  />
                  {item?.text}
                </ListItem>
              </List>
            </Box>
          ) : (
            <Text className="tileComponent__text" textStyle="md" key={index}>
              {data?.text}
            </Text>
          );
        })
      )}
    </>
  );
};
