import { useEffect } from 'react';
import { Notification } from '@companydotcom/types';
import { companyQL } from '@companydotcom/services';
import { client } from '../../lib/appsync';
import { SUBSCRIBE_TO_NOTIFICATIONS_DOCUMENT } from './graphql/notification-subscriptions';
import notificationMutations from './old-api/notification-mutation-old';
import notificationQuery from './old-api/notification-query-old';
import { companyQLSecure } from '../secure-app-sync-client';
import { EventData } from './notification.types';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';

export interface NotificationBody {
  payload: any;
  status: string;
  triggerEventId: string;
  tileId: string;
  eventType: string;
  componentTypes: string[];
  dateCreated: Date;
}

/**
 * @param notificationId
 * @param secondsToExpiration
 * @returns
 */
export const updateNotificationHandled = async (
  notificationId: string,
  secondsToExpiration: number,
) => {
  try {
    const mutation = notificationMutations.updateNotification({
      id: notificationId,
      handled: 1,
      dateExpires: Math.floor(new Date().getTime() / 1000) + secondsToExpiration,
    });
    const response = await companyQL.mutate({ mutation });
    return response;
  } catch (err) {
    console.log('Update Notification Error: ', err);
  }
};

export const useSubscribeToNotifications = (
  userId: string,
  callback?: (notification: Notification | Error) => void,
) => {
  useEffect(() => {
    if (userId) {
      const sub = API.graphql<GraphQLSubscription<{ onCreateNotification: Notification }>>(
        graphqlOperation(
          `subscription onCreateNotification($userId: String!, $requestId: String) {
               onCreateNotification(userId: $userId, requestId: $requestId) {
                 id
                 body
                 productId
                 notificationType
                 deliveryType
                 dateCreated
                 handled
                 userId
                 requestId
               }
           }`,
          { userId },
        ),
      ).subscribe({
        next: async ({ value }) => {
          let notification = value?.data?.onCreateNotification;
          if (notification?.body && typeof notification?.body === 'string') {
            notification!.body = JSON.parse(decodeURIComponent(notification.body));
          }

          switch (notification?.notificationType) {
            case 'invitation':
            case 'verificationEmailSent':
            case 'fetchEvent':
            case 'snackbar':
            case 'general':
              callback?.(notification);
              break;
            case 'tileStateUpdated':
              callback?.(notification);
              break;
            default:
              callback?.(new Error('unhandled notificationType: '));
          }
        },
        error: (error: any) => console.warn(error),
      });

      return () => {
        sub.unsubscribe();
      };
    }
  }, [callback, userId]);
};

/**
 * Legacy subscription helper function.
 * Should be removed when all notifications are migrated to the new subscription system with RTK.
 * @param userId
 * @param callback
 * @returns
 */
export const subscribeToNotifications = async (
  userId: string,
  callback?: (notification: Notification | Error) => void,
) => {
  // if you change the subscription query's returning values here
  // you MUST also update all invocations of createNotification to return the same values
  // or else the subscription will no longer work

  const sub = API.graphql<GraphQLSubscription<{ onCreateNotification: Notification }>>(
    graphqlOperation(
      `subscription onCreateNotification($userId: String!, $requestId: String) {
           onCreateNotification(userId: $userId, requestId: $requestId) {
             id
             body
             productId
             notificationType
             deliveryType
             dateCreated
             handled
             userId
             requestId
           }
       }`,
      { userId },
    ),
  ).subscribe({
    next: async ({ value }) => {
      let notification = value?.data?.onCreateNotification;
      if (notification?.body && typeof notification?.body === 'string') {
        notification!.body = JSON.parse(decodeURIComponent(notification.body));
      }

      switch (notification?.notificationType) {
        case 'invitation':
        case 'verificationEmailSent':
        case 'fetchEvent':
        case 'snackbar':
        case 'general':
          callback?.(notification);
          break;
        case 'tileStateUpdated':
          callback?.(notification);
          break;
        default:
          callback?.(new Error('unhandled notificationType: '));
      }
    },
    error: (error: any) => console.warn(error),
  });

  return sub;
};

export const fetchLargePayload = async (respPayloadCacheId: string) => {
  const res = await companyQLSecure().query({
    query: notificationQuery.fetchLargePayload(respPayloadCacheId),
  });
  return res.data.fetchLargePayload;
};

/**
 * Used to generate a subscription from a createNotification call.
 * You can then do something with the subscription (ex: subscription.subscribe({next: ... }))
 * Be sure to unsubscribe (ex: subscription.unsubscribe())
 * @example const subscription = getNotificationSubscription(userId);
 * @param userId
 * @returns
 */
export const getNotificationSubscription = (userId: string) => {
  const subscription = client.subscribe({
    // @ts-ignore some stupid dependency type mismatch with graphql and appsync
    query: SUBSCRIBE_TO_NOTIFICATIONS_DOCUMENT,
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  return subscription;
};

/**
 * Grabs a notfication from an eventData call, parses it,
 * and returns it if it matches the given eventType
 * @param eventData
 * @param eventType
 * @returns
 */
export const getNotificationByEventType = <T>(eventData: EventData<T>, eventType: string) => {
  const notification = eventData.data?.onCreateNotification;

  if (typeof notification.body === 'string') {
    notification.body = JSON.parse(decodeURIComponent(notification.body));
  }

  if (notification.body.eventType === eventType) {
    return notification;
  }

  return;
};
