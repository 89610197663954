import React, { useMemo } from 'react';
import { Box, Grid, GridItem, HStack, Stack, Text, Badge } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { platformHelpers } from '@companydotcom/helpers';
import { SavedPaymentMethod } from '../../../../services/acg/acg.types';
import { CCIconSwitch } from '../utils/creditCardhelper';
export interface PaymentTileProps extends SavedPaymentMethod {
  paymentMethodKey?: string; // payment method tied to current membership (merchantTokenId)
}

export const PaymentTile: React.FC<PaymentTileProps> = props => {
  const {
    cardNumber,
    cardExpiry,
    cardHolderName,
    city,
    postalCode,
    state,
    paymentMethodType,
    addressLine1,
    addressLine2,
    cardType,
    accountNumber,
    accountHolderName,
    country,
    paymentMethodKey,
    merchantAccountTokens,
    paymentNickName,
    customFields,
  } = props;

  const defaultMethod = useMemo(
    () => merchantAccountTokens?.[0]?.merchantToken === paymentMethodKey,
    [paymentMethodKey, merchantAccountTokens],
  );

  const { t } = useTranslation();
  return (
    <Box fontSize="sm" width="284px" px={1}>
      {paymentMethodType && paymentMethodType === 'credit card'
        ? cardNumber && (
            <>
              <HStack align="center" justifyContent={['space-evenly', 'flex-start']}>
                {cardType ? <CCIconSwitch {...{ cardType }} /> : null}
                <Text fontWeight="bold" color={['#717174', '#000000']}>
                  {`${platformHelpers.capitalizeFirstLetter(
                    paymentNickName,
                  )} ending in ${cardNumber}`}
                </Text>
              </HStack>
              <Grid
                templateColumns={[
                  'repeat(2, 1fr)',
                  'repeat(2, -1fr)',
                  'repeat(2, -1fr)',
                  'repeat(2, 1fr)',
                ]}
                mb={4}
                columnGap={4}
                rowGap={2}
              >
                <GridItem>
                  <Text fontWeight="medium">{t('common.paymentInfo.expiration')}</Text>
                </GridItem>
                <GridItem>
                  <Text>{`${cardExpiry?.split('-').reverse().join('/')}`}</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="medium">{t('common.paymentInfo.name')}</Text>
                </GridItem>
                <GridItem>
                  <Text wordBreak="break-all">{cardHolderName}</Text>
                </GridItem>
                <GridItem as={Stack} justifyContent="space-between">
                  <Text fontWeight="medium">{t('common.paymentInfo.billingAddress')}</Text>
                  {defaultMethod && (
                    <Badge variant="solid" colorScheme="green" width="max-content">
                      Default
                    </Badge>
                  )}
                </GridItem>
                <GridItem>
                  <Text>{addressLine1}</Text>
                  {addressLine2 && <Text>{addressLine2}</Text>}
                  <Text>{`${city}, ${state}`}</Text>
                  <Text>{postalCode}</Text>
                  <Text>{country}</Text>
                </GridItem>
              </Grid>
            </>
          )
        : accountNumber && (
            <>
              <HStack align="center" justifyContent={['space-evenly', 'flex-start']}>
                <Text
                  fontWeight="bold"
                  mr={2}
                  my={2}
                  color={['#717174', '#000000']}
                >{`${platformHelpers.capitalizeFirstLetter(
                  paymentNickName,
                )} ending in ${accountNumber}`}</Text>
              </HStack>
              <Grid
                templateColumns={[
                  'repeat(2, 1fr)',
                  'repeat(2, -1fr)',
                  'repeat(2, -1fr)',
                  'repeat(2, 1fr)',
                ]}
                mb={4}
                columnGap={4}
                rowGap={2}
              >
                <GridItem>
                  <Text fontWeight="medium">Accountholder</Text>
                </GridItem>
                <GridItem>
                  <Text wordBreak="break-all">{accountHolderName}</Text>
                </GridItem>
                <GridItem wordBreak="break-all">
                  <Text fontWeight="medium">Account</Text>
                </GridItem>
                <GridItem>
                  <Text>{accountNumber}</Text>
                </GridItem>
                <GridItem as={Stack} justifyContent="space-between">
                  <Text fontWeight="medium" mb={6}>
                    Type
                  </Text>
                  {defaultMethod && (
                    <Badge variant="solid" colorScheme="green" width="max-content">
                      Default
                    </Badge>
                  )}
                </GridItem>
                <GridItem>
                  <Text>{customFields?.account_type === 'S' ? 'Savings' : 'Checking'}</Text>
                </GridItem>
              </Grid>
            </>
          )}
    </Box>
  );
};
