import React from 'react';
import valid from 'card-validator';

import { getSymbolFromCurrency } from '@companydotcom/helpers';
import { AmexIcon, VisaIcon, MastercardIcon, prettyCardNumber } from '@companydotcom/ui';

import { useGetAllChaptersAndPackagesV2Query } from '../../../../services/acg/acg-api-v2';
import {
  AcgUserProfile,
  PaymentOption,
  PurchaseTypes,
  SavedPaymentMethod,
} from '../../../../services/acg/acg.types';
import { acgRefData } from '../../shared/acg-ref-data';
import { sortedUniqBy } from 'lodash';

export const useGetAllChapters = () => {
  const { chapters, ...rest } = useGetAllChaptersAndPackagesV2Query(
    {},
    {
      selectFromResult: ({ data, ...rest }) => {
        const sorted = data
          ?.map((p: any) => {
            return p.chapters;
          })
          .flat()
          .sort((a, b) => a.chapterName.localeCompare(b.chapterName));
        return {
          chapters: sortedUniqBy(sorted, a => a.chapterId),
          ...rest,
        };
      },
    },
  );

  return {
    chapters,
    ...rest,
  };
};

/**
 * Searches ACG profile data for a billing address.  If it exists, return it, otherwise return primary address.
 * @param acgUserProfileData
 */
export const findDefaultBillingAddress = (acgUserProfileData: AcgUserProfile) => {
  const billingAddress = acgUserProfileData?.[acgUserProfileData?.preferredAddressType];

  return billingAddress;
};

/**
 * Returns a payment option based on whatever payment type you pass in.
 * @param paymentOptions an array of payment options from the getPaymentOptions query for ACG
 * @param selectedPaymentType an accepted payment type
 * @returns
 */

export const getPaymentMethodTypeFromCardType = (
  selectedPaymentType: PurchaseTypes,
  currencyCode: 'USD' | 'CAD',
): PaymentOption | undefined | any => {
  if (!acgRefData.paymentMethod) {
    console.warn('Missing payment data: ', acgRefData.paymentMethod);
  }

  if (currencyCode === 'USD') {
    if (selectedPaymentType === 'visa') {
      return acgRefData.paymentMethod?.find(po => po.apmMethod === 'VISA - USD - Vantiv');
    }
    if (selectedPaymentType === 'mastercard') {
      return acgRefData.paymentMethod?.find(po => po.apmMethod === 'MC - USD - Vantiv');
    }
    if (selectedPaymentType === 'american-express') {
      return acgRefData.paymentMethod?.find(po => po.apmMethod === 'Amex - USD - Vantiv');
    }
    if (selectedPaymentType === 'ach') {
      return acgRefData.paymentMethod?.find(po => po.apmMethod === 'ACH');
    }
    return undefined;
  }

  if (currencyCode === 'CAD') {
    if (selectedPaymentType === 'visa') {
      return acgRefData.paymentMethod?.find(po => po.apmMethod === 'VISA - CAD - Vantiv');
    }
    if (selectedPaymentType === 'mastercard') {
      return acgRefData.paymentMethod?.find(po => po.apmMethod === 'MC - CAD - Vantiv');
    }
    if (selectedPaymentType === 'american-express') {
      return acgRefData.paymentMethod?.find(po => po.apmMethod === 'AMEX - CAD - Vantiv');
    }
    if (selectedPaymentType === 'ach') {
      return acgRefData.paymentMethod?.find(po => po.apmMethod === 'ACH');
    }
    return undefined;
  }
};

/** Replaces all but the last four digits of a credit card with asterisks */
export const maskCreditCardNumber = (number: string) => {
  if (!number) {
    return null;
  }
  const removeSpaces = number.split(' ').join('');
  return removeSpaces.replace(/\d(?=\d{4})/g, '*');
};

export const savedPaymentIconMap = (paymentType?: string) => {
  switch (paymentType?.toLowerCase()) {
    case 'visa':
      return <VisaIcon boxSize={9} />;
    case 'mastercard':
      return <MastercardIcon boxSize={9} />;
    case 'amex':
      return <AmexIcon boxSize={9} />;
    default:
      return (
        <>
          <VisaIcon boxSize={9} />
          <MastercardIcon boxSize={9} />
          <AmexIcon boxSize={9} />
        </>
      );
  }
};

/** Function to return an object for proper checkout */
export const getCheckoutOrderData = <T extends Record<string, any>>(
  values: T,
  selectedPaymentMethod?: string | 'credit card' | 'ach',
  paymentData?: any,
): any => {
  const billingAddressData = values?.savePaymentInformation
    ? {
        country: values?.billingDetails?.country,
        addressLine1: values?.billingDetails?.addressLine1,
        addressLine2: values?.billingDetails?.addressLine2,
        city: values?.billingDetails?.city,
        state: values?.billingDetails?.state,
        postalCode: values?.billingDetails?.postalCode,
        paymentNickName: values?.cardNickname,
      }
    : {};

  if (selectedPaymentMethod === 'credit card') {
    return {
      paymentMethodType: selectedPaymentMethod,
      // Return last 4 digits of the card for Bluepay
      cardNumber: values.cardDetails?.cardNumber?.replaceAll(/\s/g, '')?.slice(-4),
      cardHolderName: values?.cardDetails?.cardHolderName,
      // Replace any dashes with spaces (ex: american-express needs to be american express)
      cardType: valid.number(values.cardDetails?.cardNumber)?.card?.type?.replace(/-/g, ' '),
      autoRenew: values?.autoRenew,
      ...billingAddressData,
    };
  }

  if (selectedPaymentMethod === 'ach') {
    return {
      paymentMethodType: 'electronic check',
      accountHolderName: values?.achDetails?.accountHolderName,
      accountNumber: values?.achDetails?.accountNumber?.slice(-4),
      accountType: values?.achDetails?.accountType,
      autoRenew: values?.autoRenew,
      ...billingAddressData,
    };
  }

  // Formats a saved payment method for api
  const selectedSavedMethod = paymentData?.find(
    (method: SavedPaymentMethod) => method?.recordKey === selectedPaymentMethod,
  );
  return {
    paymentMethodType: selectedSavedMethod?.paymentMethodType,
    cardHolderName: selectedSavedMethod?.cardHolderName,
    cardNumber: selectedSavedMethod?.cardNumber,
    cardType: selectedSavedMethod?.cardType,
    cardExpiry: selectedSavedMethod?.cardExpiry,
    accountHolderName: selectedSavedMethod?.accountHolderName,
    accountNumber: selectedSavedMethod?.accountNumber,
    accountType: selectedSavedMethod?.accountType,
    merchantAccountId: selectedSavedMethod?.merchantAccountTokens?.[0]?.merchantAccountId,
    merchantToken: selectedSavedMethod?.merchantAccountTokens?.[0]?.merchantToken,
  };
};

export const getCurrency = (currencyCode?: string) => {
  if (!currencyCode) {
    return '$';
  }
  return getSymbolFromCurrency(currencyCode || 'USD');
};

/**
 * Returns a string with the properly formated saved payment method
 * @param savedPayment
 * @returns
 */
// TODO: retype this to SavedPaymentMethod
export const transformSavedPaymentLabels = (savedPayment?: any) => {
  function cardNumberLabel(card: any) {
    return `SAVED: ${card?.paymentNickName ? `${card.paymentNickName} ` : ''}${prettyCardNumber(
      card?.cardNumber as string,
    )} (EXP: ${card.cardExpiry || ''})`;
  }

  function achLabel(account: any) {
    return `SAVED: ${
      account?.paymentNickName
        ? `${account.paymentNickName} `
        : `${account.accountType} Account Ending in `
    }${account?.accountNumber?.slice(-4)}`;
  }

  return (
    savedPayment?.map((sp: any) => ({
      value: sp.recordKey as string,
      label: sp?.cardNumber ? cardNumberLabel(sp) : achLabel(sp),
    })) ?? []
  );
};
