import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import type { TileComponentProps } from '../../types';

const Loader = () => (
  <ContentLoader
    style={{ marginTop: '20px' }}
    speed={2}
    width={282}
    height={82}
    viewBox="0 0 282 82"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="282" height="82" />
  </ContentLoader>
);

export interface PhishingEligibilityProps {
  isEligible?: boolean;
}

export interface TilePhishingEligibilityProps extends TileComponentProps {}

export const TilePhishingEligibility: React.FC<TilePhishingEligibilityProps> = props => {
  const { eventData, eventHandler, pubEvents } = props;
  const { t } = useTranslation();
  const [eligibility, setEligibility] = useState('');
  const [eligibilityLoaded, setEligibilityLoaded] = useState(false);
  const { loading, error, data } = useEventData<PhishingEligibilityProps>(eventData);

  useEffect(() => {
    // Here its imp to use both true and false in order to show proper tile state
    if (data?.isEligible === true) {
      setEligibility('eligible');
    } else if (data?.isEligible === false) {
      setEligibility('inEligible');
    }
    setEligibilityLoaded(true);
  }, [data?.isEligible]);

  return (
    <Flex
      flexDirection="column"
      className="phishing-protection-tile-eligibility"
      textAlign="center"
      width="full"
      maxWidth={280}
      height="100%"
    >
      {(loading && !error) || !eligibilityLoaded ? (
        <Loader />
      ) : eligibility && eligibility === 'eligible' ? (
        <>
          <Text variant="p" mt={[4]}>
            {t('tiles.appointmentScheduler.scheduleAppointmentDescription')}
          </Text>
          <Alert my={2} status="success">
            <AlertIcon />
            <Box flex={1} textAlign="left">
              <AlertTitle>Eligible</AlertTitle>
              <AlertDescription>Schedule appointment</AlertDescription>
            </Box>
          </Alert>
          <Box mt="auto">
            <Button
              onClick={() =>
                // @ts-ignore
                eventHandler && eventHandler(pubEvents, { type: 'eligible' })
              }
            >
              {/* added translation text for scheduleAppointment */}
              {t('tiles.appointmentScheduler.scheduleAppointment')}
            </Button>
          </Box>
        </>
      ) : eligibility === 'inEligible' ? (
        <>
          <Text textStyle="md">{t('tiles.appointmentScheduler.CheckEligibilityDescription')}</Text>
          <Alert my={2} status="warning">
            <AlertIcon />
            <Box flex={1} textAlign="left">
              <AlertTitle>Not Eligible</AlertTitle>
              <AlertDescription>No action necessary</AlertDescription>
            </Box>
          </Alert>
          <Box mt="auto">
            <Button
              onClick={() =>
                eventHandler &&
                // @ts-ignore
                eventHandler(pubEvents, { type: 'inEligible' })
              }
            >
              {/* added translation text for CheckEligibility */}

              {t('tiles.appointmentScheduler.CheckEligibility')}
            </Button>
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </Flex>
  );
};
