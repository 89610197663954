import React from 'react';
import { Center, Heading, Text } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';

export const CancelConfirmed = () => (
  <Center
    textAlign="center"
    flexDirection="column"
    layerStyle="formContainer"
    maxWidth={704}
    width="full"
    mx="auto"
    px={4}
    py={[8, null, 12]}
  >
    <FontAwesomeIcon mb={[8]} color="success" icon={faCircleCheck} boxSize={[14, 28]} />
    <Heading maxW="md" size="hs-2xl">
      Successfully Declined Invitation
    </Heading>
    <Text textStyle="md" maxW="md" mt={8}>
      You have opted out of the offered services.
    </Text>
  </Center>
);
