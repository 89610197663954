import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Flex, Text } from '@companydotcom/potion';
import { User } from '@companydotcom/types';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../hooks';
import { setRenewInvoice, setSelectedPackage } from '../../../../acg/acg-checkout/checkout-slice';
import { getFriendlyACGDate, useAcgCartStorage } from '../../../../acg';
import { useCreateCartForInvoiceMutation } from '../../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../../services/user/user-api';

// TODO - type this properly
export const TileAcgMembershipRenewStep: React.FC<any> = props => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const { chapterName, expireDate, openInvoices } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createCart, { isLoading }] = useCreateCartForInvoiceMutation();
  const { addMembershipToLocalCart } = useAcgCartStorage();
  const { t } = useTranslation();

  const handleOnClick = async () => {
    try {
      dispatch(setSelectedPackage(props));
      const res = await createCart({
        user: globalUser as User,
        payload: {
          invoiceId: openInvoices?.invoiceRecordKey ?? '',
        },
      }).unwrap();

      if (res?.length) {
        dispatch(setRenewInvoice(res));
        addMembershipToLocalCart({ ...res[0], ...props });
        navigate('/acg-shopping-cart', { state: { flow: 'checkout' } });
      }
    } catch (err: unknown) {
      console.error('Error renewing: ', err);
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="full"
      flexGrow={1}
      px={4}
      mt={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Text>
        <Trans i18nKey="acg.membershipStatus.renewMembership.body">
          <Text as="span" color="error" fontWeight="700" display="inline">
            Your membership is expiring soon.
          </Text>
        </Trans>
      </Text>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Text fontWeight="700" mb={2} textAlign="center">
            Chapter(s): {chapterName}
          </Text>
          <Text fontWeight="700" color="error" mb={4} textAlign="center">
            Membership Exp: {expireDate ? getFriendlyACGDate(expireDate) : '-'}
          </Text>
        </Flex>
        <Button
          variant="tile"
          onClick={handleOnClick}
          isLoading={isLoading}
          size="md"
          type="submit"
        >
          {t(`acg.membershipStatus.renewMembership.cta`)}
        </Button>
      </Flex>
    </Flex>
  );
};
