import React from 'react';
import { Image, Box, Button, Heading, Center, Text } from '@companydotcom/potion';
import { isEmpty, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAssets } from '@companydotcom/providers';
import {
  ExtendedSubscriptionRatePlan,
  ExtendedSubscriptionTier,
  UserTile,
  Maybe,
} from '@companydotcom/types';
import { RatePlanOptions } from '../../../components/elements/rate-plan-options/rate-plan-options';
import { PaywallRatePlan } from './paywall-rate-plan';
import {
  checkProductForTiers,
  getPaywallContentOptions,
  determineIfFreeUpgradeAvailable,
} from '../../../utils/helpers/purchasing-helpers';
import { CartStateProps } from '../../../state/reducers/cart-reducer';
import { PaywallStateProps } from '../../../state/reducers/paywall-reducer';

interface ProductSelectProps {
  tile?: UserTile;
  nextStep?: () => void;
  addToCart?: (
    ratePlan: ExtendedSubscriptionRatePlan,
    tier?: Maybe<ExtendedSubscriptionTier> | [],
  ) => void;
  addSelectedTier?: (
    tier: ExtendedSubscriptionTier | null,
    cart?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
    ratePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
  ) => void;
  cartState?: CartStateProps;
  paywallState?: Maybe<PaywallStateProps>;
}

export const ProductSelect = ({
  tile,
  nextStep,
  addToCart,
  addSelectedTier,
  cartState,
  paywallState,
}: ProductSelectProps) => {
  const { t } = useTranslation();
  const assets = useAssets();
  const hasTiers = paywallState?.userProduct && checkProductForTiers(paywallState?.userProduct);

  const contentOptions =
    paywallState?.userProduct?.products?.[0] &&
    getPaywallContentOptions(paywallState?.userProduct?.products?.[0]);

  const quantityLabel = contentOptions?.['QUANTITY_LABEL']?.replace(/^"|"$/g, '');

  const determineIfRatePlanDisabled = (
    plan: ExtendedSubscriptionRatePlan,
    index: number,
    selectedTiers?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>> | [],
    ratePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
  ) => {
    const activeRatePlanIndex = ratePlans?.findIndex(plan => plan?.isActive);
    if (plan.isActive) {
      return true;
    }
    if (
      determineIfFreeUpgradeAvailable(
        plan,
        index,
        selectedTiers,
        ratePlans,
        cartState?.selectedRatePlans,
      ) ||
      selectedTiers?.[0]?.isEnterprise ||
      (!hasTiers && activeRatePlanIndex && index < activeRatePlanIndex) ||
      (hasTiers &&
        selectedTiers?.[0]?.isActive &&
        selectedTiers?.[0]?.ratePlanId !== plan.ratePlanId)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Center
      className={`paywall__productSelect_${paywallState?.userProduct?.products?.[0]?.name}`}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mb={12}
    >
      <Box textAlign="center" my={10}>
        {!isEmpty(tile) ? (
          <Image
            src={
              tile?.tileIcon
                ? tile.tileIcon
                : `${assets?.tile_icon}${kebabCase(tile?.tileName ?? tile?.tileSlug ?? '')}.svg`
            }
            alt={tile?.tileName ?? t('paywall.productSelect.iconDefaultAltText')}
            boxSize={14}
          />
        ) : null}
        <Heading size="hs-2xl">{t('paywall.productSelect.header')}</Heading>
        <Text textStyle="xl" fontWeight="medium" maxWidth="2xl" mt={6}>
          {t('paywall.productSelect.subheader')}
        </Text>
      </Box>
      {paywallState?.userProduct?.products?.[0] && (
        <RatePlanOptions
          product={paywallState?.userProduct?.products?.[0]}
          ratePlanClickHandler={addToCart}
          tierChangeHandler={addSelectedTier}
          determineIfDisabledHandler={determineIfRatePlanDisabled}
          PackageComponent={PaywallRatePlan}
          hasTiers={hasTiers}
          selectedTiers={cartState?.selectedTiers}
          dropdownOptions={
            !isEmpty(cartState?.selectedRatePlans) ? cartState?.selectedRatePlans?.[0].tiers : []
          }
          selectedRatePlans={cartState?.selectedRatePlans}
          packageHeader={t('paywall.productSelect.packageHeader', {
            product: paywallState?.userProduct?.products?.[0].name,
          })}
          dropdownHeader={t('paywall.productSelect.dropdownHeader', { quantityLabel })}
          containerStyles={{
            bg: 'gray.50',
            justifyContent: 'center',
            alignContent: 'center',
            py: 5,
            px: 4,
          }}
        />
      )}
      <Box mt={[8, 12]}>
        <Button
          size="lg"
          variant="outline"
          isDisabled={
            isEmpty(cartState) ||
            cartState?.selectedRatePlans?.[0]?.name === 'None' ||
            (!hasTiers && cartState?.selectedRatePlans?.[0]?.isActive) ||
            cartState?.selectedRatePlans?.[0]?.includedInMembership ||
            cartState?.selectedTiers?.[0]?.isEnterprise ||
            cartState?.selectedTiers?.[0]?.isActive ||
            //* This description text is added progromatically in provision-svc
            cartState?.selectedTiers?.[0]?.description.includes('(Included in Membership)') ||
            (hasTiers && isEmpty(cartState?.selectedTiers)) ||
            (hasTiers && !cartState?.selectedTiers?.[0])
          }
          onClick={nextStep}
        >
          {t('paywall.productSelect.button')}
        </Button>
      </Box>
    </Center>
  );
};
