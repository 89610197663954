import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text, RadioGroup, Stack, FormControl, FormLabel } from '@companydotcom/potion';
import {
  ExtendedSubscriptionTier,
  ExtendedSubscriptionRatePlan,
  ExtendedSubscriptionProduct,
  Maybe,
} from '@companydotcom/types';
import { RSelect, ActionMeta } from '@companydotcom/ui';
import { isEmpty, startCase, toLower, isNumber } from 'lodash';
import {
  getPaywallContentOptions,
  calculateEstimatedRatePlanPrice,
} from '../../../utils/helpers/purchasing-helpers';
import { RatePlanWarningMessages } from './rate-plan-warning-messages';

interface RatePlanClickHandlerProps {
  <T>(
    ratePlan: T,
    tier?: Maybe<ExtendedSubscriptionTier>,
    selectedRatePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
    selectedTiers?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>>,
    index?: Maybe<number>,
    availableProductStep?: Maybe<boolean>,
  ): void;
}
export interface RatePlanOptionsProps {
  product: ExtendedSubscriptionProduct;
  PackageComponent: React.ComponentType<any>;
  ratePlanClickHandler?: RatePlanClickHandlerProps;
  hasTiers?: Maybe<boolean>;
  tierChangeHandler?: (
    option: ExtendedSubscriptionTier | null,
    selectedRatePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
    ratePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
    selectedTiers?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>>,
    e?: ActionMeta<Maybe<ExtendedSubscriptionTier>>,
  ) => void;
  selectedTiers?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>>;
  selectedRatePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>;
  containerStyles?: any;
  determineIfDisabledHandler?: (
    plan: ExtendedSubscriptionRatePlan,
    index: number,
    selectedTier?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>>,
    ratePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
    product?: ExtendedSubscriptionProduct,
  ) => boolean;
  determineIfSelectedHandler?: (index: number) => void;
  packageHeader?: string;
  dropdownHeader?: string;
  dropdownOptions?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>>;
  minTierPreselected?: boolean;
  availableProductStep?: boolean;
  isPackage?: boolean;
  dropdownValue?: ExtendedSubscriptionTier | null;
  isClearable?: boolean;
}

const determineDefaultDropdownValue = (
  cart: Array<Maybe<ExtendedSubscriptionRatePlan>> | [],
  selectedTiers: Array<Maybe<ExtendedSubscriptionTier>> | [],
  product: ExtendedSubscriptionProduct, // find active tier
) => {
  if (product?.ratePlans?.some(p => p?.ratePlanId === selectedTiers?.[0]?.ratePlanId)) {
    if (
      !isEmpty(cart) &&
      cart?.[0] !== undefined &&
      cart?.[0]?.tiers?.some(tier => tier?.isActive) &&
      selectedTiers.length == 0
    ) {
      return cart?.[0]?.tiers?.find(tier => tier?.isActive);
    }
    if (!isEmpty(selectedTiers) && selectedTiers?.[0] !== undefined) {
      return selectedTiers;
    }
    if (product && product?.ratePlans?.find(rp => rp?.isActive)) {
      return product?.ratePlans?.find(rp => rp?.isActive)?.tiers?.find(t => t?.isActive);
    }
  }
};

export const RatePlanOptions = ({
  ratePlanClickHandler,
  PackageComponent,
  product,
  hasTiers,
  tierChangeHandler,
  selectedTiers,
  selectedRatePlans,
  determineIfDisabledHandler,
  determineIfSelectedHandler,
  containerStyles,
  packageHeader,
  dropdownHeader,
  dropdownOptions,
  minTierPreselected,
  availableProductStep,
  isPackage,
  dropdownValue,
  isClearable,
}: RatePlanOptionsProps) => {
  const contentOptions = product && getPaywallContentOptions(product);
  const numberOfRatePlans = product.ratePlans?.length;
  const quantityLabel =
    contentOptions?.['QUANTITY_LABEL'] &&
    startCase(toLower(contentOptions['QUANTITY_LABEL'].replace(/^"|"$/g, '')));
  const { t } = useTranslation();

  const description = t('components.ratePlanOptions.selectNumberOfContacts');
  const currNum = t('components.ratePlanOptions.currentNumberOfQty', {
    qtyLabel: quantityLabel?.toLowerCase(),
  });

  const currentTier = selectedTiers?.find(t => t?.productName === product.name) ||
    product?.ratePlans?.find(rp => rp?.isActive)?.tiers?.find(t => t?.isActive) || {
      description,
      qty: null,
      price: 0,
    };

  return (
    <>
      {hasTiers && dropdownOptions?.length && (
        <>
          {dropdownHeader && (
            <Text textStyle="md" fontWeight="medium" mb={4}>
              {dropdownHeader}
            </Text>
          )}
          <Box width="100%" maxWidth={332} mb={availableProductStep ? 0 : 10}>
            <FormControl>
              <FormLabel>
                {t('components.ratePlanOptions.numberOf', {
                  qtyLabel: quantityLabel,
                })}
              </FormLabel>
              <RSelect
                placeholder={t('components.ratePlanOptions.selectNumberOf', {
                  qtyLabel: quantityLabel,
                })}
                defaultValue={determineDefaultDropdownValue(
                  selectedRatePlans || [],
                  selectedTiers || [],
                  product,
                )}
                options={dropdownOptions}
                isClearable={isClearable}
                getOptionLabel={option => {
                  return `${option?.description.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')}${
                    hasTiers && option?.isActive ? currNum : ''
                  }`;
                }}
                value={dropdownValue}
                getOptionValue={option => option?.description ?? ''}
                isOptionDisabled={option => option?.isDisabled ?? false}
                onChange={(option, e) =>
                  tierChangeHandler?.(
                    option,
                    selectedRatePlans,
                    product?.ratePlans,
                    selectedTiers,
                    e,
                  )
                }
              />
            </FormControl>
          </Box>
          {selectedTiers?.some(tier => tier?.productName === product?.name) && (
            <RatePlanWarningMessages
              isEnterprise={selectedTiers.some(
                tier => tier?.isEnterprise && tier?.productName === product?.name,
              )}
              // TODO: We need better or generic logic for detecting free upgrade.  This only works for email marketing product right now
              freeUpgrade={
                !selectedTiers?.some(tier => tier?.isEnterprise) &&
                selectedTiers?.some(tier => tier?.description === currentTier?.description) &&
                selectedTiers?.some(
                  tier =>
                    tier?.productName === product?.name &&
                    isNumber(tier?.qty) &&
                    tier.qty > 10000 && // Hardcoded value should be removed and logic for detecting Free Upgrade , Enterprise level messages should get configured from DB
                    // @ts-ignore-next-line
                    currentTier?.qty > 10000,
                )
              }
              productName={product.name}
              selectedPlan={selectedRatePlans?.[0]}
              minTierPreselected={minTierPreselected}
              quantityLabel={quantityLabel}
            />
          )}
        </>
      )}

      <Flex
        className="rate-plan-options-container"
        flexDirection="column"
        mx="auto"
        width="full"
        maxWidth={numberOfRatePlans && numberOfRatePlans <= 2 ? '756px' : undefined}
      >
        {packageHeader && (
          <Text textAlign="left" fontWeight="medium" textStyle="md" mb={4}>
            {packageHeader}
          </Text>
        )}

        <RadioGroup
          name="packages"
          onChange={ratePlanClickHandler}
          value={
            hasTiers
              ? product.ratePlans?.find?.(rp => rp?.tiers?.find?.(t => t?.isActive))?.name ??
                (selectedRatePlans?.[0]?.name as string)
              : (selectedRatePlans?.[0]?.name as string)
          }
        >
          <Stack
            className="package-container"
            justifyContent="space-evenly"
            direction={['column', null, null, 'row']}
            align="stretch"
            height="100%"
            flexWrap="wrap"
            {...containerStyles}
          >
            {product.ratePlans?.map((ratePlan, index) => {
              return (
                <PackageComponent
                  key={index}
                  availableProductStep={availableProductStep}
                  estimatedPrice={calculateEstimatedRatePlanPrice(
                    ratePlan,
                    !isEmpty(selectedTiers) &&
                      selectedTiers?.some(
                        tier => tier !== undefined && product.productId === ratePlan?.productId,
                      )
                      ? ratePlan?.tiers?.find(
                          tier =>
                            tier?.description ===
                            selectedTiers?.find(t => t?.description === tier?.description)
                              ?.description,
                        )
                      : ratePlan?.tiers?.find(tier => tier?.isActive),
                  )}
                  numberOfRatePlans={numberOfRatePlans}
                  hasTiers={hasTiers}
                  ratePlanClickHandler={() => {
                    ratePlanClickHandler?.(
                      ratePlan,
                      selectedTiers?.find(
                        tier =>
                          tier &&
                          tier.description ===
                            ratePlan?.tiers?.find(
                              rtier => rtier && rtier.description === tier.description,
                            )?.description,
                      ) ||
                        product.ratePlans
                          ?.find(p => p?.tiers?.find(t => t?.isActive))
                          ?.tiers?.find(t => t?.isActive),
                      selectedRatePlans,
                      selectedTiers,
                      index,
                      availableProductStep,
                    );
                  }}
                  disabled={
                    ratePlan &&
                    determineIfDisabledHandler?.(
                      ratePlan,
                      index,
                      selectedTiers,
                      product.ratePlans,
                      product,
                    )
                  }
                  isSelected={
                    (ratePlan &&
                      !determineIfDisabledHandler?.(
                        ratePlan,
                        index,
                        selectedTiers,
                        product.ratePlans,
                        product,
                      ) &&
                      determineIfSelectedHandler?.(index)) ||
                    (!selectedRatePlans?.length && ratePlan?.isActive)
                  }
                  isPackage={isPackage}
                  noOfPlans={product.ratePlans ? product.ratePlans.length : 0}
                  {...ratePlan}
                />
              );
            })}
          </Stack>
        </RadioGroup>
      </Flex>
    </>
  );
};
