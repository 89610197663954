const styles = {
  global: () => ({
    body: {
      fontFamily: 'body',
      color: 'gray.800',
      bg: 'white',
      transitionProperty: 'background-color',
      transitionDuration: 'normal',
      lineHeight: 'base',
      overflow: 'auto',
    },
    '*::placeholder': {
      color: 'gray.400',
    },
    '*, *::before, &::after': {
      borderColor: 'gray.200',
      wordWrap: 'break-word',
    },
  }),
};

export default styles;
