import * as React from 'react';
import { potion, HTMLPotionProps, forwardRef } from '../../system';

export interface CenterProps extends HTMLPotionProps<'div'> {}

export const Center = potion('div', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export interface AbsoluteCenterProps extends HTMLPotionProps<'div'> {
  axis?: 'vertical' | 'horizontal' | 'both';
}

const centerStyles = {
  horizontal: {
    insetStart: '50%',
    transform: 'translateX(-50%)',
  },
  vertical: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  both: {
    insetStart: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const AbsoluteCenter = forwardRef<AbsoluteCenterProps, 'div'>((props, ref) => {
  const { axis = 'both', ...rest } = props;

  return <potion.div ref={ref} __css={centerStyles[axis]} {...rest} position="absolute" />;
});
