import type {
  Account,
  User,
  UserGroup,
  UserTileState,
  UserTile,
  UserTileStateInputLite,
  UpdateUserInput,
  UpdateAccountInput,
  MutationUpsertAvatarArgs,
  MutationUpdateUserDataCollectorArgs,
  UserTileGroupInput,
  UserTileGroup,
  ProcessOrderInput,
  CreateDomainInput,
  UpdateDomainInput,
  LoanInput,
  ChildUserInput,
  UserProductInput,
  SsoUrlInput,
  SeatCountSummary,
  Appointment,
} from '@companydotcom/types';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { companyHelpers } from '@companydotcom/helpers';
import { emptySplitApi } from '../../store/empty-split-api';
import { useAuth } from '../../providers';
import {
  GET_USER_QUERY,
  GET_USER_TILES_QUERY,
  GET_USER_GROUPS_QUERY,
  GET_USER_LITE_QUERY,
  GET_USER_TILE_STATES,
  GET_PRODUCT_SSO,
  GET_DOMAIN,
  GET_SEAT_COUNT,
  GET_USER_BY_EMAIL,
  GET_USER_TILE_GROUPS,
  GET_ENROLLED_USER,
  GET_USER_BY_EMAIL_AND_SOURCE,
  FETCH_MIDS_QUERY,
} from './graphql/user-queries';
import {
  UPDATE_USER_MUTATION,
  SET_USER_TILE_STATE_MUTATION,
  SET_USER_TILE_STATE_LITE_MUTATION,
  SET_USER_AVATAR_MUTATION,
  DELETE_USER_AVATAR_MUTATION,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_DATA_COLLECTOR,
  UPDATE_USER_ACCOUNT_DOMAIN,
  SET_USER_TILE_GROUP_MUTATION,
  UPDATE_ACCOUNT_BUSINESS,
  UPDATE_ACCOUNT_BUSINESS_NO_CITY_STATE,
  PROCESS_ORDER_SUMMARY,
  UPDATE_ALIAS,
  UPDATE_MX,
  UPDATE_LOAN,
  ACTIVATE_USER,
  CHANGE_ENROLLMENT_ID,
  UPDATE_ZUORA_PAYMENT_METHOD_ID,
  CREATE_CALENDLY_APPOINTMENT,
  CREATE_CHILD_USER,
  UPDATE_USER_PRODUCT,
  CREATE_DOMAIN,
  UPDATE_CHILD_USER,
  UPDATE_USER_ACCOUNT,
} from './graphql/user-mutations';
import { SUBSCRIBE_TO_APPOINTMENTS } from './graphql/user-subscription';

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    // ============================= SUBSCRIPTIONS =============================
    //
    /** Unable to implement */
    subscribeToAppointments: builder.query<Appointment, { userId: string }>({
      providesTags: ['Appointments'],
      query: ({ userId }) => ({
        method: 'query',
        document: SUBSCRIBE_TO_APPOINTMENTS,
        variables: { userId },
      }),
      transformResponse: (rawResult: { data: { onCreateUserAppointment: any } }) => {
        const appointment = rawResult.data.onCreateUserAppointment;
        if (typeof appointment.body === 'string') {
          appointment.body = JSON.parse(decodeURIComponent(appointment.body));
        }
        return appointment;
      },
    }),
    // ============================= QUERIES =============================
    //
    getUser: builder.query<User, { userId: string; locale?: string }>({
      query: ({ userId, locale = 'en' }) => ({
        method: 'query',
        document: GET_USER_QUERY,
        variables: {
          userId,
          locale,
        },
      }),
      providesTags: result =>
        result?.userDataCollectors
          ? [
              ...result?.userDataCollectors?.map(dc => ({
                type: 'DataCollector' as const,
                id: dc?.dataCollectorSlug,
              })),
              'User',
            ]
          : ['User', 'DataCollector'],
      transformResponse: (rawResult: { data: { getUser: User } }) => {
        const response = rawResult.data.getUser;

        if (response?.account?.vendorData) {
          try {
            const { vendorData } = response?.account;
            if (typeof vendorData === 'string') {
              response.account.vendorData = JSON.parse(vendorData);
            }
          } catch (err) {
            console.error(err);
            response.account.vendorData = companyHelpers.parseUserProducts(
              response.account.vendorData,
            );
          }
        }

        if (response?.account?.mads) {
          const { mads } = response.account;
          if (typeof mads === 'string') {
            response.account.mads = JSON.parse(mads)[0];
          }
        }

        if (response?.mads) {
          const { mads } = response;
          response.mads = JSON.parse(mads)[0];
        }

        return rawResult.data.getUser;
      },
    }),
    getUserTiles: builder.query<UserTile[], { userId: string; locale?: string }>({
      providesTags: result =>
        result
          ? [...result.map(tile => ({ type: 'UserTile' as const, id: tile.tileId }))]
          : ['UserTile'],
      query: ({ userId, locale = 'en' }) => ({
        method: 'query',
        document: GET_USER_TILES_QUERY,
        variables: {
          userId,
          locale,
        },
      }),
      transformResponse: (rawResult: { data: { getUserTiles: UserTile[] } }) => {
        return rawResult.data.getUserTiles;
      },
    }),
    getUserGroups: builder.query<UserGroup[], { userId: string; locale?: string }>({
      providesTags: result =>
        result
          ? [...result.map(group => ({ type: 'UserGroup' as const, id: group.groupId as string }))]
          : ['UserGroup'],
      query: ({ userId, locale = 'en' }) => ({
        method: 'query',
        document: GET_USER_GROUPS_QUERY,
        variables: {
          userId,
          locale,
        },
      }),
      transformResponse: (rawResult: { data: { getUserGroups: UserGroup[] } }) => {
        return rawResult.data.getUserGroups;
      },
    }),
    getUserLite: builder.query<User, { userId: string; locale?: string }>({
      providesTags: ['User'],
      query: ({ userId, locale = 'en' }) => ({
        method: 'query',
        document: GET_USER_LITE_QUERY,
        variables: {
          userId,
          locale,
        },
      }),
      transformResponse: (rawResult: { data: { getUser: User } }) => {
        return rawResult.data.getUser;
      },
    }),
    getUserTileStates: builder.query<UserTileState[], { userId: string }>({
      providesTags: result =>
        result
          ? [...result.map(tile => ({ type: 'UserTileState' as const, id: tile.tileId }))]
          : ['UserTileState'],
      query: ({ userId }) => ({
        method: 'query',
        document: GET_USER_TILE_STATES,
        variables: {
          userId,
        },
      }),
      transformResponse: (rawResult: { data: { getUserTileStates: UserTileState[] } }) => {
        return rawResult.data.getUserTileStates;
      },
    }),
    // NEW API QUERIES -- UNTESTED
    getProductSso: builder.query<any, { input: SsoUrlInput }>({
      providesTags: [],
      query: ({ input }) => ({
        method: 'query',
        document: GET_PRODUCT_SSO,
        variables: {
          input,
        },
      }),
      transformResponse: (rawResult: { data: { getProductSSO: string } }) => {
        return rawResult.data.getProductSSO;
      },
    }),
    getDomain: builder.query<any, { fqdn: string }>({
      query: ({ fqdn }) => ({
        method: 'query',
        document: GET_DOMAIN,
        variables: { fqdn },
      }),
      transformResponse: (rawResult: { data: { getDomain: string } }) => {
        return JSON.parse(rawResult.data.getDomain).resultObj;
      },
    }),
    getSeatCount: builder.query<SeatCountSummary, { accountId: string; productId: string }>({
      query: ({ accountId, productId }) => ({
        method: 'query',
        document: GET_SEAT_COUNT,
        variables: {
          accountId,
          productId,
        },
      }),
      transformResponse: (rawResult: { data: { getSeatCount: SeatCountSummary } }) =>
        rawResult.data.getSeatCount,
    }),
    getUserByEmail: builder.query<User, { email: string }>({
      query: ({ email }) => ({
        method: 'query',
        document: GET_USER_BY_EMAIL,
        variables: { email },
      }),
      transformResponse: (rawResult: { data: { getUserByEmail: User } }) => {
        return rawResult.data.getUserByEmail;
      },
    }),
    getUserTileGroups: builder.query<UserTileGroup[], { userId: string }>({
      query: ({ userId }) => ({
        method: 'query',
        document: GET_USER_TILE_GROUPS,
        variables: userId,
      }),
      transformResponse: (rawResult: { data: { getUserTileGroups: UserTileGroup[] } }) =>
        rawResult.data.getUserTileGroups,
    }),
    fetchMidList: builder.query<any, { email: string; source: string }>({
      query: ({ email, source }) => ({
        method: 'query',
        document: FETCH_MIDS_QUERY,
        variables: {
          email,
          source,
        },
      }),
      transformResponse: (rawResult: { data: { getMidList: any } }) => rawResult.data.getMidList,
    }),
    getEnrolledUser: builder.query<User, { userId: string }>({
      query: ({ userId }) => ({
        method: 'query',
        document: GET_ENROLLED_USER,
        variables: userId,
      }),
      transformResponse: (rawResult: { data: { getEnrolledUser: User } }) =>
        rawResult.data.getEnrolledUser,
    }),
    getUserByEmailAndSource: builder.query<User, { email: string; source: string }>({
      query: ({ email, source }) => ({
        method: 'query',
        document: GET_USER_BY_EMAIL_AND_SOURCE,
        variables: {
          email,
          source,
        },
      }),
      transformResponse: (rawResult: { data: { getUserByEmailAndSource: User } }) =>
        rawResult.data.getUserByEmailAndSource,
    }),
    // ============================= MUTATIONS =============================
    //
    setUserTileState: builder.mutation<UserTileState, { input: any }>({
      invalidatesTags: (result, error, arg) => [{ type: 'UserTileState', id: arg.input.tileId }],
      query: ({ input }) => ({
        method: 'mutation',
        document: SET_USER_TILE_STATE_MUTATION,
        variables: {
          input,
        },
      }),
      transformResponse: (rawResult: { data: { setUserTileState: UserTileState } }) => {
        return rawResult.data.setUserTileState;
      },
    }),
    setUserTileStateLite: builder.mutation<
      UserTileState,
      { input: UserTileStateInputLite & Pick<UserTileState, 'statePrevious' | 'stateCurrent'> }
    >({
      query: ({ input }) => {
        if (input.statePrevious && input.stateCurrent !== input.statePrevious) {
          throw new Error('Use full setUserTileState for state changes');
        }
        return {
          method: 'mutation',
          document: SET_USER_TILE_STATE_LITE_MUTATION,
          variables: {
            input,
          },
        };
      },
      transformResponse: (rawResult: { data: { setUserTileStateLite: UserTileState } }) => {
        return rawResult.data.setUserTileStateLite;
      },
    }),
    setUserTileGroup: builder.mutation<UserTileGroup, { input: UserTileGroupInput }>({
      invalidatesTags: (result, error, arg) => [{ type: 'UserGroup', id: arg.input.groupId }],
      query: ({ input }) => ({
        method: 'mutation',
        document: SET_USER_TILE_GROUP_MUTATION,
        variables: { input },
      }),
      transformResponse: (rawResult: { data: { setUserTileGroup: UserTileGroup } }) => {
        return rawResult.data.setUserTileGroup;
      },
    }),
    // Formally updateUserProfile, now just updateUser
    updateUser: builder.mutation<User, UpdateUserInput>({
      invalidatesTags: ['User'],
      query: input => {
        if (input.phone && input.firstName && input.lastName) {
          input.phone = input.phone.replace(/([-() ])/g, '');
          input.firstName = input.firstName.replace(/\\/g, '').replace(/"/g, '\\"');
          input.lastName = input.lastName.replace(/\\/g, '').replace(/"/g, '\\"');
        }
        return {
          method: 'mutation',
          document: UPDATE_USER_MUTATION,
          variables: {
            input,
          },
        };
      },
      transformResponse: (rawResult: {
        data: {
          updateUser: User;
        };
      }) => {
        return rawResult.data.updateUser;
      },
    }),
    uploadUserAvatar: builder.mutation<
      string,
      MutationUpsertAvatarArgs & { localImageUrl: string }
    >({
      query: ({ dataUri, userId }) => ({
        method: 'mutation',
        document: SET_USER_AVATAR_MUTATION,
        variables: {
          userId,
          dataUri,
        },
      }),
      async onQueryStarted({ userId, localImageUrl }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUser', { userId }, draft => {
            draft.avatar = localImageUrl;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          // URL.revokeObjectURL(imageURL);
          patchResult.undo();
        }
      },
      transformResponse: (rawResult: { data: { upsertAvatar: string } }) => {
        return rawResult.data.upsertAvatar;
      },
    }),
    deleteUserAvatar: builder.mutation<string, { userId: string }>({
      query: ({ userId }) => ({
        method: 'mutation',
        document: DELETE_USER_AVATAR_MUTATION,
        variables: {
          userId,
        },
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUser', { userId }, draft => {
            draft.avatar = undefined;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      transformResponse: (rawResult: {
        data: {
          deleteAvatar: string;
        };
      }) => {
        return rawResult.data.deleteAvatar;
      },
    }),
    updateUserPassword: builder.mutation<any, { userId?: string; password: string }>({
      query: ({ userId, password }) => ({
        method: 'mutation',
        document: UPDATE_USER_PASSWORD,
        variables: {
          auth0UserID: `auth0|${userId}`,
          password,
        },
      }),
    }),
    updateUserDataCollector: builder.mutation<any, MutationUpdateUserDataCollectorArgs>({
      invalidatesTags: (result, error, arg) => [
        { type: 'DataCollector', id: arg.dataCollectorSlug },
        'DataCollector',
        'User',
      ],
      query: ({ userId, dataCollectorSlug, markDismissed, markCompleted, markSeen }) => ({
        method: 'mutation',
        document: UPDATE_USER_DATA_COLLECTOR,
        variables: {
          userId,
          dataCollectorSlug,
          markDismissed,
          markCompleted,
          markSeen,
        },
      }),
      async onQueryStarted(
        { userId, dataCollectorSlug, markCompleted },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUser', { userId }, draft => {
            const dcToRemove =
              markCompleted &&
              draft.userDataCollectors?.findIndex(
                dc => dc?.dataCollectorSlug === dataCollectorSlug,
              );

            if (typeof dcToRemove !== 'undefined' && typeof dcToRemove === 'number') {
              draft.userDataCollectors?.splice(dcToRemove, 1);
            }

            return draft;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateUserAccountDomain: builder.mutation<Account, UpdateAccountInput>({
      invalidatesTags: ['User'],
      query: options => ({
        method: 'mutation',
        document: UPDATE_USER_ACCOUNT_DOMAIN,
        variables: {
          options,
        },
      }),
    }),
    updateUserAccount: builder.mutation<Account, UpdateAccountInput>({
      invalidatesTags: ['User'],
      query: options => ({
        method: 'mutation',
        document: UPDATE_USER_ACCOUNT,
        variables: {
          options,
        },
      }),
    }),
    // NEW MUTATION REFACTOR
    updateAccountBusiness: builder.mutation<Account, any>({
      invalidatesTags: ['User', 'UserTileState'],
      query: options => {
        return {
          method: 'mutation',
          document: UPDATE_ACCOUNT_BUSINESS,
          variables: {
            input: {
              accountId: options.accountId,
              business: [
                {
                  businessId: options.businessId,
                  address: {
                    city: options.city,
                    state: options.state,
                    zipCode: options.zipCode,
                    addressLine1: options.address,
                    addressLine2: options.address2,
                  },
                  name: options.businessName,
                  ...(options.categories ? { categories: options.categories } : {}),
                  numberOfEmployees: options.numberOfEmployees,
                  website: options.website,
                  yearsInBusiness: JSON.stringify(options.yearsInBusiness),
                },
              ],
            },
          },
        };
      },
      transformResponse: (rawResult: { data: { updateAccount: any } }) => {
        return rawResult.data.updateAccount;
      },
    }),
    updateAccountBusinessNoCityState: builder.mutation<Account, any>({
      invalidatesTags: ['User'],
      query: options => ({
        method: 'mutation',
        document: UPDATE_ACCOUNT_BUSINESS_NO_CITY_STATE,
        variables: {
          input: {
            accountId: options.accountId,
            business: [
              {
                businessId: options.businessId,
                address: {
                  city: options.city,
                  state: options.state,
                  zipCode: options.zipCode,
                  addressLine1: options.address,
                  addressLine2: options.address2,
                },
                name: options.businessName,
                ...(options.categories ? { categories: options.categories } : {}),
              },
            ],
          },
        },
      }),
    }),
    updateChildUserProfile: builder.mutation<Account, any>({
      invalidatesTags: ['User'],
      query: options => {
        const phone = options?.phone?.replace(/([-() ])/g, '');
        const firstName = options?.firstName?.replace(/\\/g, '').replace(/"/g, '\\"');
        const lastName = options?.lastName?.replace(/\\/g, '').replace(/"/g, '\\"');
        const status =
          options?.['status'] && options?.['status'] === 'pending' ? 'active' : options?.['status'];
        return {
          method: 'mutation',
          document: UPDATE_CHILD_USER,
          variables: {
            input: {
              ...options,
              phone,
              firstName,
              lastName,
              status,
            },
          },
        };
      },
    }),
    // Have to use Awaitable Facade for this (rackSpace)
    updateMailboxPassword: builder.mutation<any, { password: string; snsInterface: any }>({
      invalidatesTags: ['User'],
      queryFn: async ({ password, snsInterface }) => {
        try {
          const tileData = {
            productId: 'f5327182-4604-11e8-842f-0ed5f89f718b',
            tileId: '190e526f-b197-448a-bf14-2e60a332099e',
          };
          const resp = await snsInterface.getNotifData(
            tileData,
            'fetch',
            'updateMailbox',
            { password },
            {},
            true,
          );
          return resp ? { data: resp.body.payload } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error updating mailbox password' } };
        }
      },
    }),
    // Idk if this will work, confusing schema and different gql mutations
    updateMailbox: builder.mutation<User, { snsInterface: any; mailbox: string }>({
      invalidatesTags: ['User'],
      queryFn: async ({ snsInterface, mailbox }) => {
        try {
          const tileData = {
            productId: 'f5327182-4604-11e8-842f-0ed5f89f718b',
            tileId: '190e526f-b197-448a-bf14-2e60a332099e',
          };
          const resp = await snsInterface.getNotifData(
            tileData,
            'fetch',
            'updateMailbox',
            { mailbox },
            {},
            true,
          );
          return resp.body.payload
            ? { data: resp.body.payload }
            : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error updating mailbox userName' } };
        }
      },
    }),
    processOrderSummary: builder.mutation<any, ProcessOrderInput>({
      invalidatesTags: ['User', 'UserTile', 'UserTileState', 'UserProducts'],
      query: options => ({
        method: 'mutation',
        document: PROCESS_ORDER_SUMMARY,
        variables: {
          input: options,
        },
      }),
      transformResponse: (rawResult: { data: { processOrder: any } }) =>
        JSON.parse(rawResult.data.processOrder.payload),
    }),
    createDomain: builder.mutation<any, { options: CreateDomainInput }>({
      invalidatesTags: ['User'],
      query: ({ options }) => ({
        method: 'mutation',
        document: CREATE_DOMAIN,
        variables: {
          body: options,
        },
      }),
      transformResponse: (rawResult: { data: { createDomain: any } }) =>
        rawResult.data.createDomain,
    }),
    updateDomain: builder.mutation<any, { options: UpdateDomainInput }>({
      invalidatesTags: ['User'],
      query: ({ options }) => ({
        method: 'mutation',
        document: options.isRequiredAlias ? UPDATE_ALIAS : UPDATE_MX,
        variables: {
          body: options,
        },
      }),
    }),
    updateLoan: builder.mutation<Account, UpdateAccountInput>({
      invalidatesTags: ['User'],
      query: input => {
        const {
          applicationId,
          yearsInBusiness,
          industry,
          creditEstimate,
          purpose,
          annualRevenue,
          amountRequired,
          status,
          yearFounded,
          monthFounded,
        } = input?.loans?.[0] as LoanInput;
        return {
          method: 'mutation',
          document: UPDATE_LOAN,
          variables: {
            input: {
              accountId: input.accountId,
              loans: [
                {
                  applicationId,
                  yearsInBusiness,
                  industry,
                  creditEstimate,
                  purpose,
                  annualRevenue,
                  amountRequired,
                  status,
                  yearFounded,
                  monthFounded: monthFounded || 0,
                },
              ],
              loanPrimary: {
                applicationId,
                yearsInBusiness,
                industry,
                creditEstimate,
                purpose,
                annualRevenue,
                amountRequired,
                status,
                yearFounded,
                monthFounded: monthFounded || 0,
              },
            },
          },
        };
      },
      transformResponse: (rawResult: { data: { updateAccount: any } }) => {
        return rawResult.data.updateAccount;
      },
    }),
    activateUser: builder.mutation<User, { userEmail: any; ownerEmail: any; enrollmentId: any }>({
      invalidatesTags: ['User'],
      query: ({ userEmail, ownerEmail, enrollmentId }) => ({
        method: 'mutation',
        document: ACTIVATE_USER,
        variables: {
          userEmail,
          ownerEmail,
          enrollmentId,
        },
      }),
    }),
    changeEnrollmentId: builder.mutation<any, { enrollmentId: string; oldEnrollmentId?: string }>({
      invalidatesTags: ['User'],
      query: ({ enrollmentId, oldEnrollmentId }) => ({
        method: 'mutation',
        document: CHANGE_ENROLLMENT_ID,
        variables: {
          enrollmentId,
          oldEnrollmentId,
        },
      }),
    }),
    updateZuoraPaymentMethodId: builder.mutation<
      Account,
      { accountId: string; zuoraPaymentMethodId: string }
    >({
      invalidatesTags: ['User'],
      query: ({ accountId, zuoraPaymentMethodId }) => ({
        method: 'mutation',
        document: UPDATE_ZUORA_PAYMENT_METHOD_ID,
        variables: {
          accountId,
          zuoraPaymentMethodId,
        },
      }),
    }),
    // deleteMailBox: unused, no gql schema or queries in appsync
    createCalendlyAppointment: builder.mutation<
      any,
      { userId: string; productId: string; url: string }
    >({
      invalidatesTags: ['User'],
      query: ({ userId, productId, url }) => ({
        method: 'mutation',
        document: CREATE_CALENDLY_APPOINTMENT,
        variables: {
          userId,
          productId,
          url,
        },
      }),
      transformResponse: (rawResult: { data: { createCalendlyAppointment: string } }) =>
        JSON.parse(rawResult.data.createCalendlyAppointment),
    }),
    createChildUser: builder.mutation<
      { processId: string; status: string; enrollmentId: string },
      { childUser: ChildUserInput; source: string }
    >({
      invalidatesTags: ['User'],
      query: ({ childUser, source }) => ({
        method: 'mutation',
        document: CREATE_CHILD_USER,
        variables: {
          childUser: {
            email: childUser.email,
            userEmail: childUser.email,
            externalIdentifier: childUser.externalIdentifier,
            firstName: childUser.firstName,
            lastName: childUser.lastName,
            parentAccountId: childUser.parentAccountId,
            role: childUser.role,
          },
          source,
        },
      }),
      transformResponse: (rawResult: { data: { createChildEmailUser: string } }) => {
        return JSON.parse(rawResult.data.createChildEmailUser);
      },
    }),
    updateUserProduct: builder.mutation<any, { input: UserProductInput }>({
      invalidatesTags: ['User'],
      query: ({ input }) => ({
        method: 'mutation',
        document: UPDATE_USER_PRODUCT,
        variables: {
          input,
        },
      }),
    }),
  }),
});

// export const selectUser = () => userApi.endpoints.getUser.select({ userId: '' });

export const {
  usePrefetch,
  // queries
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUserTilesQuery,
  useLazyGetUserTilesQuery,
  useGetUserGroupsQuery,
  useLazyGetUserGroupsQuery,
  useGetUserLiteQuery,
  useLazyGetUserLiteQuery,
  useGetUserTileStatesQuery,
  useLazyGetUserByEmailQuery,
  useLazyGetProductSsoQuery,
  useLazyGetDomainQuery,
  useLazyGetSeatCountQuery,
  useLazyFetchMidListQuery,
  useLazySubscribeToAppointmentsQuery,
  // mutations
  useUpdateUserMutation,
  useSetUserTileStateMutation,
  useSetUserTileStateLiteMutation,
  useSetUserTileGroupMutation,
  useUploadUserAvatarMutation,
  useDeleteUserAvatarMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserDataCollectorMutation,
  useUpdateUserAccountDomainMutation,
  useUpdateAccountBusinessMutation,
  useUpdateAccountBusinessNoCityStateMutation,
  useUpdateChildUserProfileMutation,
  useUpdateMailboxPasswordMutation,
  useUpdateMailboxMutation,
  useProcessOrderSummaryMutation,
  useCreateDomainMutation,
  useUpdateLoanMutation,
  useChangeEnrollmentIdMutation,
  useUpdateZuoraPaymentMethodIdMutation,
  useCreateChildUserMutation,
  useUpdateUserAccountMutation,
} = userApi;

type UseQueryOptions = {
  pollingInterval?: number;
  refetchOnReconnect?: boolean;
  refetchOnFocus?: boolean;
  skip?: boolean;
  refetchOnMountOrArgChange?: boolean | number;
  // selectFromResult?: (result: UseQueryStateDefaultResult) => any
};

export const useGetGlobalUserQuery = (options?: UseQueryOptions) => {
  const auth = useAuth();

  return useGetUserQuery(
    auth?.user?.userId ? { userId: auth?.user?.userId as string } : skipToken,
    options,
  );
};
