import type { User, AcgUserProfile } from '@companydotcom/types';
import { emptySplitApi } from '../../store/empty-split-api';
import {
  // AcgUserProfile,
  // AcgReferenceDataPayload,
  // AcgReferenceData,
  AcgMemberships,
  // AcgCommittees,
  GetPaymentOptionsRes,
  AddToCartInput,
  DoPaymentResponseRhythm,
  // Feed,
  EventAttendee,
  PurchasedEvent,
  // InvoiceData,
  // InvoiceDataRhythm,
  CartForInvoice,
  GetSavedPaymentMethodsRhythm,
  RegisteredEvent,
  SearchIndividualResponse,
  ApplyDiscountResponse,
  // RecommendedEventsPayload,
  // RecommendedEventsResponse,
  AddGuestsToRegisteredEvent,
  AddGuestsToRegisteredEventResponse,
  SubmitMembershipApplication,
  // StoreRhythmTokenAndContactId,
  CreateCartResponse,
  DoPayment,
  AddOrUpdateStoredPaymentPayload,
  // HiddenField,
  // MemberSearchProps,
  EventForRegistration,
  UpdateRegisteredEventPayload,
  SubmitEventApplication,
  // AcgPackages,
  // MemberSearchResults,
} from './acg.types';
import AcgSvc from './acg-svc';

export const AcgApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    // getAcgUserProfile: builder.query<AcgUserProfile, { user: User }>({
    //   providesTags: ['AcgUser'],
    //   queryFn: async ({ user }) => {
    //     try {
    //       const resp = await AcgSvc(user).getUserProfile<AcgUserProfile>();
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error fetching ACG user profile' } };
    //     }
    //   },
    // }),
    // updateAcgUser: builder.mutation<
    //   AcgUserProfile,
    //   { user: User; payload: Partial<AcgUserProfile> & { lastProfileUpdateVersion: number } }
    // >({
    //   invalidatesTags: ['AcgUser'],
    //   queryFn: async ({ user, payload }) => {
    //     try {
    //       const res: AcgUserProfile = await AcgSvc(user).updateUser(payload);
    //       return res
    //         ? { data: res }
    //         : {
    //             error: true,
    //             meta: { message: 'No response received while updating user profile' },
    //           };
    //     } catch (error) {
    //       console.log(error);
    //       return { error: true, meta: { message: 'Error updating ACG user profile' } };
    //     }
    //   },
    //   async onQueryStarted({ user, payload }, { dispatch, queryFulfilled }) {
    //     const patchResult = dispatch(
    //       AcgApi.util.updateQueryData('getAcgUserProfile', { user }, draft => {
    //         for (const key in payload) {
    //           draft[key] = payload[key];
    //         }
    //       }),
    //     );
    //     try {
    //       await queryFulfilled;
    //     } catch {
    //       patchResult.undo();
    //     }
    //   },
    // }),
    // syncProfileAvatar: builder.mutation<AcgUserProfile, { user: User }>({
    //   queryFn: async ({ user }) => {
    //     try {
    //       const res: AcgUserProfile = await AcgSvc(user).updateUser({ syncProfilePic: true });
    //       return res
    //         ? { data: res }
    //         : {
    //             error: true,
    //             meta: { message: 'No response recieved syncing avatar with NetFroum' },
    //           };
    //     } catch (error) {
    //       return { error: true, meta: { message: `Error syncing ACG user profile: ${error}` } };
    //     }
    //   },
    // }),
    // searchAcgMemberDirectory: builder.query<
    //   MemberSearchResults,
    //   { user: User; member: Partial<MemberSearchProps> }
    // >({
    //   keepUnusedDataFor: 60,
    //   queryFn: async ({ user, member }) => {
    //     try {
    //       const resp: MemberSearchResults = await AcgSvc(user).searchMemberDirectory(member);
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       console.log(error);
    //       return { error: true, meta: { message: 'Error searching for ACG members' } };
    //     }
    //   },
    // }),
    // getAcgReferenceData: builder.query<
    //   AcgReferenceData,
    //   { user: User; payload: AcgReferenceDataPayload }
    // >({
    //   providesTags: ['AcgReferenceData'],
    //   keepUnusedDataFor: 180,
    //   queryFn: async ({ user, payload }) => {
    //     try {
    //       const resp: AcgReferenceData = await AcgSvc(user).getReferenceData(payload);
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       console.error(error);
    //       return { error: true, meta: { message: 'Error getting ACG reference data' } };
    //     }
    //   },
    // }),
    getMemberships: builder.query<AcgMemberships, { user: User; stateCurrent?: string }>({
      providesTags: ['AcgMembership'],
      keepUnusedDataFor: 60,
      queryFn: async ({ user, stateCurrent }) => {
        try {
          const resp = await AcgSvc(user).getMemberships<AcgMemberships>(stateCurrent);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error fetching ACG memberships' } };
        }
      },
    }),
    // updateMembership: builder.mutation<
    //   AcgMemberships,
    //   { user: User; hiddenFieldsFromMembershipDirectory: Partial<HiddenField[]> }
    // >({
    //   invalidatesTags: ['AcgMembership'],
    //   queryFn: async ({ user, hiddenFieldsFromMembershipDirectory }) => {
    //     try {
    //       const resp = await AcgSvc(user).updateMembership<AcgMemberships>(
    //         hiddenFieldsFromMembershipDirectory,
    //       );
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error updating ACG memberships' } };
    //     }
    //   },
    //   async onQueryStarted(
    //     { user, hiddenFieldsFromMembershipDirectory },
    //     { dispatch, queryFulfilled },
    //   ) {
    //     const patchResult = dispatch(
    //       AcgApi.util.updateQueryData('getMemberships', { user }, draft => {
    //         if (hiddenFieldsFromMembershipDirectory) {
    //           // @ts-ignore
    //           draft['hiddenFieldsFromMembershipDirectory'] = hiddenFieldsFromMembershipDirectory;
    //         }
    //       }),
    //     );
    //     try {
    //       await queryFulfilled;
    //     } catch {
    //       patchResult.undo();
    //     }
    //   },
    // }),
    // getMemberCommittees: builder.query<AcgCommittees | [], { user: User }>({
    //   queryFn: async ({ user }) => {
    //     try {
    //       const resp = await AcgSvc(user).getMemberCommittees<AcgCommittees>();
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error fetching ACG memberships' } };
    //     }
    //   },
    // }),
    getCart: builder.query<AddToCartInput, { user: User }>({
      providesTags: ['AcgCart'],
      queryFn: async ({ user }) => {
        try {
          const resp = await AcgSvc(user).getCart<AddToCartInput>();
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error fetching ACG cart' } };
        }
      },
    }),
    addToCart: builder.mutation<any, { user: User; items: AddToCartInput }>({
      invalidatesTags: ['AcgCart'],
      queryFn: async ({ user, items }) => {
        try {
          const resp: any = await AcgSvc(user).addToCart(items);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error adding to ACG cart' } };
        }
      },
    }),
    createCart: builder.mutation<CreateCartResponse, { user: User; items: any }>({
      invalidatesTags: ['AcgCart'],
      queryFn: async ({ user, items }) => {
        try {
          const resp: CreateCartResponse = await AcgSvc(user).createCart(items);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error creating ACG cart' } };
        }
      },
    }),
    /** @deprecated */
    getPaymentOptions: builder.query<GetPaymentOptionsRes, { user: User }>({
      queryFn: async ({ user }) => {
        try {
          const resp = await AcgSvc(user).getPaymentOptionsForCart<GetPaymentOptionsRes>();

          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error getting payment options' } };
        }
      },
    }),
    getPublicUserProfile: builder.query<
      AcgUserProfile,
      { user: User; recordKey: string; objectsToGet?: string[] }
    >({
      keepUnusedDataFor: 180,
      queryFn: async ({ user, recordKey, objectsToGet }) => {
        try {
          const resp = await AcgSvc(user).getPublicUserProfile<AcgUserProfile>(
            recordKey,
            objectsToGet,
          );
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error fetching ACG public user profile' } };
        }
      },
    }),
    // getAllChaptersAndPackages: builder.query<Array<AcgPackages>, { user: User }>({
    //   providesTags: ['AcgChapterData'],
    //   keepUnusedDataFor: 180,
    //   queryFn: async ({ user }) => {
    //     try {
    //       const resp = await AcgSvc(user).getChapters<Array<AcgPackages>>();
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error fetching ACG chapters' } };
    //     }
    //   },
    // }),
    // getGrowthTvFeed: builder.query<Feed, { user: User }>({
    //   queryFn: async ({ user }) => {
    //     try {
    //       const resp = await AcgSvc(user).getGrowthTvFeed<Feed>();
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error fetching ACG Growth TV Feed' } };
    //     }
    //   },
    // }),
    // getMagazinesFeed: builder.query<Feed, { user: User }>({
    //   queryFn: async ({ user }) => {
    //     try {
    //       const resp = await AcgSvc(user).getMagazinesFeed<Feed>();
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error fetching ACG Magazines Feed' } };
    //     }
    //   },
    // }),
    // getNewsTrendsFeed: builder.query<Feed, { user: User }>({
    //   queryFn: async ({ user }) => {
    //     try {
    //       const resp = await AcgSvc(user).getNewsTrendsFeed<Feed>();
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error fetching ACG News Trends Feed' } };
    //     }
    //   },
    // }),
    // TODO: RETYPE THIS
    doPayment: builder.mutation<DoPaymentResponseRhythm, { user: User; payload: any }>({
      invalidatesTags: [
        'AcgMembership',
        'AcgUser',
        'AcgPurchasedEvents',
        'AcgInvoices',
        'AcgRegisteredEvent',
      ],
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).doPayment<any>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: `Error completing purchase: ${error}` } };
        }
      },
      async onQueryStarted({ user }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          AcgApi.util.updateQueryData('getPurchasedEvents', { user }, draft => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            draft ? (draft = []) : null;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    doPaymentForOpenInvoice: builder.mutation<any, { user: User; payload: DoPayment }>({
      invalidatesTags: [
        'AcgMembership',
        'AcgUser',
        'AcgPurchasedEvents',
        'AcgInvoices',
        'AcgRegisteredEvent',
      ],
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).doPaymentForOpenInvoice<any>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: `Error completing purchase: ${error}` } };
        }
      },
      async onQueryStarted({ user }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          AcgApi.util.updateQueryData('getPurchasedEvents', { user }, draft => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            draft ? (draft = []) : null;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    applyDiscount: builder.mutation<ApplyDiscountResponse, { user: User; discountCodes: string[] }>(
      {
        invalidatesTags: ['AcgCart'],
        queryFn: async ({ user, discountCodes }) => {
          try {
            const resp: ApplyDiscountResponse = await AcgSvc(user).applyDiscount(discountCodes);
            return resp ? { data: resp } : { error: true, meta: { message: resp } };
          } catch (error) {
            return { error: true, meta: { message: 'Error applying discount' } };
          }
        },
      },
    ),
    // TODO: RETYPE THIS
    // getInvoicesByUser: builder.query<any, { user: User }>({
    //   providesTags: ['AcgInvoices'],
    //   queryFn: async ({ user }) => {
    //     try {
    //       const resp = await AcgSvc(user).getInvoicesByUser<any>();
    //       return { data: resp || [] };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error getting invoices' } };
    //     }
    //   },
    // }),
    downloadInvoice: builder.query<any, { user: User; invoiceId: string }>({
      queryFn: async ({ user, invoiceId }) => {
        try {
          const resp = await AcgSvc(user).downloadInvoice<any>(invoiceId);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error getting invoice URL' } };
        }
      },
    }),
    clearCart: builder.mutation<{ success: boolean; message: string }, { user: User }>({
      invalidatesTags: ['AcgCart'],
      queryFn: async ({ user }) => {
        try {
          const resp = await AcgSvc(user).clearCart<{ success: boolean; message: string }>();
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error clearing cart' } };
        }
      },

      async onQueryStarted({ user }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          AcgApi.util.updateQueryData('getCart', { user }, draft => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            'memberships' in draft ? (draft.memberships = []) : null;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getPurchasedEvents: builder.query<PurchasedEvent[], { user: User }>({
      providesTags: ['AcgPurchasedEvents'],
      queryFn: async ({ user }) => {
        try {
          const resp = await AcgSvc(user).getPurchasedEvents<PurchasedEvent[]>();

          return {
            data:
              resp?.map(event => ({
                ...event,
                eventLocation: event.eventLocation ? event.eventLocation.split(',').join(', ') : '',
              })) || [],
          };
        } catch (error) {
          return { error: true, meta: { message: 'Error getting purchased events' } };
        }
      },
    }),
    getEventForRegistration: builder.query<EventForRegistration, { user: User; eventId: string }>({
      queryFn: async ({ user, eventId }) => {
        try {
          const resp = await AcgSvc(user).getEventForRegistration<EventForRegistration>(eventId);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return {
            error: true,
            meta: { message: `Error getting event for registration: ${error}` },
          };
        }
      },
    }),
    getEventAttendees: builder.query<EventAttendee[], { user: User; eventId: string }>({
      keepUnusedDataFor: 300,
      queryFn: async ({ user, eventId }) => {
        try {
          const resp = await AcgSvc(user).getEventAttendees<EventAttendee[]>(eventId);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error getting event attendees' } };
        }
      },
    }),
    // TODO: RETYPE THIS
    getSavedPaymentMethods: builder.query<GetSavedPaymentMethodsRhythm, { user: User }>({
      providesTags: ['AcgSavedPayments'],
      queryFn: async ({ user }) => {
        try {
          const resp = await AcgSvc(user).getSavedPaymentMethods<GetSavedPaymentMethodsRhythm>();
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return {
            error: true,
            meta: { message: `Error getting saved payment methods: ${error}` },
          };
        }
      },
    }),
    addOrUpdateSavedPayments: builder.mutation<
      any,
      { user: User; payload: AddOrUpdateStoredPaymentPayload }
    >({
      invalidatesTags: ['AcgCart', 'AcgSavedPayments', 'AcgMembership'],
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).addOrUpdateStoredPaymentMethod(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error editing payment methods' } };
        }
      },
    }),
    // TODO: type this
    deleteStoredPaymentMethod: builder.mutation<any, { user: User; payload: any }>({
      invalidatesTags: ['AcgCart', 'AcgSavedPayments', 'AcgMembership'],
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).deleteStoredPaymentMethod<any>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error editing payment methods' } };
        }
      },
    }),
    setAutoRenewOnMembership: builder.mutation<
      { res: boolean },
      { user: User; payload: Partial<AddOrUpdateStoredPaymentPayload> }
    >({
      invalidatesTags: ['AcgSavedPayments', 'AcgMembership'],
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).setAutoRenewOnMembership<{ res: boolean }>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error setting auto renew' } };
        }
      },
    }),
    createCartForInvoice: builder.mutation<
      CartForInvoice[],
      { user: User; payload: { invoiceId: string; invoiceIdMember?: string } }
    >({
      invalidatesTags: ['AcgMembership', 'AcgUser'],
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).createCartForInvoice<any>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: `Error completing purchase: ${error}` } };
        }
      },
    }),
    getRegisteredEvent: builder.query<RegisteredEvent, { user: User; registrationId: string }>({
      keepUnusedDataFor: 300,
      providesTags: result =>
        result
          ? [{ type: 'AcgRegisteredEvent', id: result.registrationId }]
          : ['AcgRegisteredEvent'],
      queryFn: async ({ user, registrationId }) => {
        try {
          const resp = await AcgSvc(user).getRegisteredEvent<RegisteredEvent>(registrationId);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: `Error getting registered events: ${error}` } };
        }
      },
    }),
    updateRegisteredEvent: builder.mutation<
      { updateSuccess: Record<string, string> },
      {
        user: User;
        payload: UpdateRegisteredEventPayload;
      }
    >({
      invalidatesTags: ['AcgRegisteredEvent', { type: 'AcgRegisteredEvent' }],
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).updateRegisteredEvent<{
            updateSuccess: Record<string, string>;
          }>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error completing purchase' } };
        }
      },
    }),
    addContactToWaitlist: builder.mutation<
      { datePlacedOnWaitList: Date; id: string; status: string },
      {
        user: User;
        payload: { eventId: string };
      }
    >({
      invalidatesTags: ['AcgRegisteredEvent', 'AcgPurchasedEvents'],
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).addContactToWaitlist<{
            datePlacedOnWaitList: Date;
            id: string;
            status: string;
          }>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error adding contact to waitlist' } };
        }
      },
      async onQueryStarted({ user, payload: { eventId } }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          // Updates the given event optimistically to up the totalRegistered total
          AcgApi.util.updateQueryData('getEventForRegistration', { user, eventId }, draft => {
            draft.totalRegistered = draft.totalRegistered + 1;
            draft.totalWaitList = draft.totalWaitList + 1;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    searchIndividual: builder.query<
      SearchIndividualResponse[],
      { user: User; firstName?: string; lastName?: string; email?: string }
    >({
      keepUnusedDataFor: 300,
      queryFn: async ({ user, firstName, lastName, email }) => {
        try {
          const resp: SearchIndividualResponse[] = await AcgSvc(user).searchIndividual<any>(
            firstName,
            lastName,
            email,
          );
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error searching individual user' } };
        }
      },
    }),
    addGuestsToRegisteredEventCart: builder.mutation<
      AddGuestsToRegisteredEventResponse,
      { user: User; payload: AddGuestsToRegisteredEvent }
    >({
      invalidatesTags: ['AcgCart'],
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).addGuestsToRegisteredEventCart<any>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: `Error adding guests to event cart: ${error}` } };
        }
      },
    }),
    // getRecommendedEvents: builder.query<
    //   RecommendedEventsResponse,
    //   { user: User; payload: RecommendedEventsPayload }
    // >({
    //   queryFn: async ({ user, payload }) => {
    //     try {
    //       const resp = await AcgSvc(user).getRecommendedEvents<any>(payload);
    //       return resp ? { data: resp } : { error: true, meta: { message: resp } };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error adding guests to event cart' } };
    //     }
    //   },
    // }),
    // storeRhythmTokenAndContactId: builder.mutation<
    //   { status?: string },
    //   { user: User; payload: StoreRhythmTokenAndContactId }
    // >({
    //   invalidatesTags: [
    //     'AcgMembership',
    //     'AcgUser',
    //     'AcgPurchasedEvents',
    //     'AcgInvoices',
    //     'AcgRegisteredEvent',
    //   ],
    //   queryFn: async ({ user, payload }) => {
    //     try {
    //       const resp = await AcgSvc(user).storeRhythmTokenAndContactId<any>(payload);
    //       return { data: resp };
    //     } catch (error) {
    //       return { error: true, meta: { message: 'Error storing Rhythm token and contactId' } };
    //     }
    //   },
    // }),
    submitMembershipApplication: builder.query<
      string,
      { user: User; payload: SubmitMembershipApplication }
    >({
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).submitMembershipApplication<any>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: 'Error adding guests to event cart' } };
        }
      },
    }),
    submitEventApplication: builder.query<string, { user: User; payload: SubmitEventApplication }>({
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).submitEventApplication<any>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: `Error submitting event application: ${error}` } };
        }
      },
    }),
    deleteDraftMemberships: builder.query<any, { user: User }>({
      queryFn: async ({ user }) => {
        try {
          const resp = await AcgSvc(user).deleteDraftMemberships<any>();
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: `Error deleting draft memberships: ${error}` } };
        }
      },
    }),
    deleteDraftEventRegistrations: builder.query<any, { user: User; payload: any }>({
      queryFn: async ({ user, payload }) => {
        try {
          const resp = await AcgSvc(user).deleteDraftEventRegistrations<any>(payload);
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return {
            error: true,
            meta: { message: `Error deleting draft event registrations: ${error}` },
          };
        }
      },
    }),
  }),
});

export const {
  usePrefetch,
  // Queries
  useGetPublicUserProfileQuery,
  // useGetMemberCommitteesQuery,
  // useGetMembershipsQuery,
  // useGetAcgUserProfileQuery,
  // useGetAllChaptersAndPackagesQuery,
  // useLazySearchAcgMemberDirectoryQuery,
  // useLazyGetAcgUserProfileQuery,
  useGetCartQuery,
  useGetPaymentOptionsQuery,
  // useGetGrowthTvFeedQuery,
  // useGetMagazinesFeedQuery,
  // useGetNewsTrendsFeedQuery,
  // useGetInvoicesByUserQuery,
  useDownloadInvoiceQuery,
  useGetEventAttendeesQuery,
  useGetPurchasedEventsQuery,
  useGetEventForRegistrationQuery,
  useLazyGetEventForRegistrationQuery,
  useGetSavedPaymentMethodsQuery,
  // useGetAcgReferenceDataQuery,
  useGetRegisteredEventQuery,
  useLazySearchIndividualQuery,
  // useGetRecommendedEventsQuery,
  useLazySubmitMembershipApplicationQuery,
  useLazySubmitEventApplicationQuery,
  useLazyDeleteDraftEventRegistrationsQuery,
  useLazyDeleteDraftMembershipsQuery,
  // Mutations
  useDoPaymentMutation,
  useAddOrUpdateSavedPaymentsMutation,
  useCreateCartForInvoiceMutation,
  // useUpdateAcgUserMutation,
  useAddToCartMutation,
  useClearCartMutation,
  useCreateCartMutation,
  // useSyncProfileAvatarMutation,
  useSetAutoRenewOnMembershipMutation,
  useUpdateRegisteredEventMutation,
  useApplyDiscountMutation,
  useAddGuestsToRegisteredEventCartMutation,
  useDoPaymentForOpenInvoiceMutation,
  useDeleteStoredPaymentMethodMutation,
  // useStoreRhythmTokenAndContactIdMutation,
  // useUpdateMembershipMutation,
  useAddContactToWaitlistMutation,
} = AcgApi;
