import * as React from 'react';
import {
  potion,
  forwardRef,
  omitThemingProps,
  ThemingProps,
  useMultiStyleConfig,
  useStyles,
  HTMLPotionProps,
  StylesProvider,
} from '../../system';
import { SystemStyleObject } from '../../styled-system';

import { cx, createContext } from '../../utils';
import { CheckIcon, InfoIcon, WarningIcon } from './icons';

const STATUSES = {
  info: { icon: InfoIcon, colorScheme: 'blue' },
  warning: { icon: WarningIcon, colorScheme: 'orange' },
  success: { icon: CheckIcon, colorScheme: 'green' },
  error: { icon: WarningIcon, colorScheme: 'red' },
};

export type AlertStatus = keyof typeof STATUSES;

interface AlertContext {
  status: AlertStatus;
}

const [AlertProvider, useAlertContext] = createContext<AlertContext>({
  name: 'AlertContext',
  errorMessage:
    'useAlertContext: `context` is undefined. Seems you forgot to wrap alert components in `<Alert />`',
});

interface AlertOptions {
  /**
   * The status of the alert
   */
  status?: AlertStatus;
}

export interface AlertProps extends HTMLPotionProps<'div'>, AlertOptions, ThemingProps<'Alert'> {}

/**
 * Alert is used to communicate the state or status of a
 * page, feature or action
 */
export const Alert = forwardRef<AlertProps, 'div'>((props, ref) => {
  const { status = 'info', ...rest } = omitThemingProps(props);
  const colorScheme = props.colorScheme ?? STATUSES[status].colorScheme;

  const styles = useMultiStyleConfig('Alert', { ...props, colorScheme });

  const alertStyles: SystemStyleObject = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    ...styles.container,
  };

  return (
    <AlertProvider value={{ status }}>
      <StylesProvider value={styles}>
        <potion.div
          role="alert"
          ref={ref}
          {...rest}
          className={cx('potion-alert', props.className)}
          __css={alertStyles}
        />
      </StylesProvider>
    </AlertProvider>
  );
});

export interface AlertTitleProps extends HTMLPotionProps<'div'> {}

export const AlertTitle = forwardRef<AlertTitleProps, 'div'>((props, ref) => {
  const styles = useStyles();

  return (
    <potion.div
      ref={ref}
      {...props}
      className={cx('potion-alert__title', props.className)}
      __css={styles.title}
    />
  );
});

export interface AlertDescriptionProps extends HTMLPotionProps<'div'> {}

export const AlertDescription = forwardRef<AlertDescriptionProps, 'div'>((props, ref) => {
  const styles = useStyles();
  const descriptionStyles: SystemStyleObject = {
    display: 'inline',
    ...styles.description,
  };

  return (
    <potion.div
      ref={ref}
      {...props}
      className={cx('potion-alert__desc', props.className)}
      __css={descriptionStyles}
    />
  );
});

export interface AlertIconProps extends HTMLPotionProps<'span'> {}

export const AlertIcon: React.FC<AlertIconProps> = props => {
  const { status } = useAlertContext();
  const { icon: BaseIcon } = STATUSES[status];
  const styles = useStyles();

  return (
    <potion.span
      display="inherit"
      {...props}
      className={cx('potion-alert__icon', props.className)}
      __css={styles.icon}
    >
      <BaseIcon w="100%" h="100%" />
    </potion.span>
  );
};
