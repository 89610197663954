import React from 'react';
import { Flex, HStack, VStack, Text, Wrap, WrapItem } from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import ContentLoader from 'react-content-loader';
import type { TileComponentProps } from '../../types';

interface Status {
  label: string;
  quantity?: string | number;
}

interface ContentProps {
  deviceStatuses: Status[];
}

export interface TileDeviceSummaryProps extends TileComponentProps {}

export const TileDeviceSummary: React.FC<TileDeviceSummaryProps> = props => {
  const { eventData } = props;
  const { loading, error, data } = useEventData<ContentProps>(eventData);

  // const mockData = [
  //   { label: 'Total Devices', quantity: 5 },
  //   { label: 'Installed', quantity: 5 },
  //   { label: 'Online', quantity: undefined },
  //   { label: 'Pending', quantity: 5 },
  // ];

  return (
    <Flex className="tile__tileDeviceSummary" flex={1}>
      <Wrap spacing={4} mx="auto" align="center" justify="center">
        {data?.deviceStatuses?.map(item => (
          <StatusCard
            error={error}
            loading={loading}
            key={item.label}
            label={item.label}
            quantity={item.quantity}
          />
        ))}
      </Wrap>
    </Flex>
  );
};

interface StatusCardProps {
  label?: string;
  quantity?: string | number;
  error?: boolean;
  loading?: boolean;
}

function StatusCard({ label, quantity, error, loading }: StatusCardProps) {
  function renderIcon(data?: string) {
    switch (data) {
      case 'Pending':
        return <PendingIcon width={36} />;
      case 'Installed':
        return <InstalledIcon width={36} />;
      case 'Total Devices':
        return <TotalDevicesIcon width={36} />;
      case 'Online':
        return <OnlineIcon width={36} />;
      default:
        return null;
    }
  }
  return (
    <WrapItem
      className="status-card"
      boxShadow="md"
      borderRadius="sm"
      pt={2}
      pb={3}
      px={4}
      width={136}
    >
      <VStack alignItems="flex-start">
        <Text textStyle="md">{`${label}:`}</Text>
        <HStack mt={1} flexDirection="row" justifyContent="flex-start" alignItems="center">
          {loading && !error ? (
            <Loader />
          ) : (
            <>
              {renderIcon(label)}
              {error ? <Text textStyle="md">!</Text> : <Text textStyle="md">{quantity}</Text>}
            </>
          )}
        </HStack>
      </VStack>
    </WrapItem>
  );
}

function TotalDevicesIcon({ width = 16, height = 24, color = '#4C6372' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 24">
      <g fill="none" fillRule="evenodd" opacity=".799">
        <g stroke={color} strokeWidth="2">
          <g transform="translate(-2544 -269) translate(2544 269)">
            <rect width="14" height="22" x="1" y="1" rx="2" />
            <rect width="14" height="14" x="1" y="4.556" rx="2" />
          </g>
        </g>
      </g>
    </svg>
  );
}

function InstalledIcon({ width = 24, height = 24, color = '#24BE51' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g stroke={color} strokeWidth="2">
          <g>
            <g opacity=".802" transform="translate(-2692 -271) translate(2692 271)">
              <circle cx="12" cy="12" r="11" />
            </g>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.5 12.5L11.144 15.274 16.103 8.757"
              transform="translate(-2692 -271) translate(2692 271)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

function OnlineIcon({ width = 26, height = 22, color = '#24BE51' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 26 22">
      <g fill="none" fillRule="evenodd">
        <g stroke={color} strokeWidth="2">
          <g transform="translate(-2156 -360) translate(2157 361)">
            <circle cx="12" cy="9.546" r="3.364" />
            <path
              strokeLinecap="round"
              d="M8.075 2.994c-2.223 1.335-3.711 3.77-3.711 6.552 0 2.772 1.477 5.2 3.687 6.537m7.854.027c2.235-1.333 3.731-3.774 3.731-6.564 0-2.827-1.536-5.295-3.82-6.616"
            />
            <path
              strokeLinecap="round"
              d="M5.568.032C2.245 1.902 0 5.462 0 9.546c0 4.089 2.25 7.652 5.578 9.52m12.879-.02C21.767 17.173 24 13.62 24 9.546 24 5.438 21.73 1.861 18.375 0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

function PendingIcon({ width = 24, height = 26, color = '#F5843C' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 26">
      <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <g stroke={color} strokeWidth="2">
          <g>
            <path
              d="M18.051 4.36C16.142 2.749 13.683 1.779 11 1.779c-6.075 0-11 4.974-11 11.11C0 19.026 4.925 24 11 24s11-4.975 11-11.111M14.52 5.778L18.04 4.444M17.676.665L18.04 4.444"
              transform="translate(-2691 -357) translate(2692 358)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

const Loader = () => (
  <ContentLoader
    speed={2}
    width={64}
    height={24}
    viewBox="0 0 64 24"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="64" height="24" />
  </ContentLoader>
);
