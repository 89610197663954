import React from 'react';
import { Flex, Box, Image, Link } from '@companydotcom/potion';
import { AcgRssFeedResponse } from '@companydotcom/types';

export const VideoFeed: React.FC<AcgRssFeedResponse> = ({
  href,
  imageUrl,
  text,
  data,
}: AcgRssFeedResponse) => {
  return (
    <Flex width="full" flexDirection="column" alignItems="center">
      <Box
        as="a"
        href={href!}
        target="_blank"
        width="fit-content"
        backgroundColor="black"
        sx={{ position: 'relative', textAlign: 'center' }}
      >
        <Image src={imageUrl!} alt={data?.title!} fallbackSrc={imageUrl!} height={150} mx="auto" />
        <Box
          className="image-overlay"
          opacity={0.2}
          backgroundColor="black"
          sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        />
        <PlayCircleIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>

      <Link mt={2} isExternal href={href!} sx={{ lineClamp: 2, mx: 1, textAlign: 'center' }}>
        {text ?? ''}
      </Link>
    </Flex>
  );
};

const PlayCircleIcon = (props: any) => (
  <Box {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="white" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
    </svg>
  </Box>
);
