import React from 'react';
import { Flex } from '@companydotcom/potion';

import type { TileComponentProps } from '../../types';

export interface TileSwitchProps extends TileComponentProps {}

export const TileSwitch: React.FC<TileSwitchProps> = props => {
  const { children } = props;

  return (
    <Flex className="tile__tileSwitch" flexDirection="column" alignItems="center" flex={1}>
      {children}
    </Flex>
  );
};
