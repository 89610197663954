import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import type { DocumentNode } from 'graphql';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import AppSyncClient from 'aws-appsync';

export const graphqlBaseQuery = (options: {
  client: AppSyncClient<NormalizedCacheObject>;
}): BaseQueryFn<
  {
    // ts-comment Need to figure out why DocumentNode type doesn't work
    document: DocumentNode | any;
    variables?: any;
    method?: 'query' | 'mutation';
  },
  unknown,
  // TODO: This arg is for the type of error, but we don't know the exact shape of our errors.  Should figure this out
  unknown,
  {}
> => {
  const client = options.client;

  return async ({ document, variables, method = 'query' }) => {
    try {
      if (method === 'mutation') {
        return {
          data: await client.mutate({
            mutation: document,
            variables,
            fetchPolicy: 'no-cache',
          }),
        };
      }

      return {
        data: await client.query({ query: document, variables, fetchPolicy: 'no-cache' }),
      };
    } catch (error) {
      if (!error) throw error;
      return { error };
    }
  };
};
