import React, { useState, useCallback } from 'react';
import { Tabs, TabPanels, TabPanel, useTabStepper } from '@companydotcom/potion';
import { EmailProvider } from './steps/email-provider-step';
import { EmailOwnership } from './steps/email-ownership-step';

const emailEligibilityFormSteps = [
  {
    slug: 'email-provider',
    component: <EmailProvider />,
  },
  {
    slug: 'email-ownership',
    component: <EmailOwnership />,
  },
];

export interface DataCollectorFormProps {
  handleDataFormFill: (formData?: any) => void;
}

export const EmailEligibilityForm: React.FC<DataCollectorFormProps> = ({ handleDataFormFill }) => {
  const [formData, setFormData] = useState({});
  const { tabIndex, handleTabsChange, previousStep, nextStep } = useTabStepper({
    steps: emailEligibilityFormSteps,
    scrollOnStepChange: false,
  });

  const onComplete = useCallback(
    (newData: any, submit: boolean) => {
      if (newData.mailboxCount) {
        newData.mailboxCount = newData.mailboxCount.value;
      }
      const newFormData = { ...formData, ...newData };
      setFormData(newFormData);
      if (submit) {
        handleDataFormFill(newFormData);
      }
    },
    [formData, handleDataFormFill],
  );

  return (
    <Tabs className="email-eligibility-form" index={tabIndex} onChange={handleTabsChange}>
      <TabPanels>
        {emailEligibilityFormSteps.map(step => (
          <TabPanel key={step.slug}>
            {React.cloneElement(step.component, { onComplete, previousStep, nextStep })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
