import React from 'react';
import {
  HStack,
  useMultiStyleConfig,
  SystemStyleObject,
  omitThemingProps,
  Text,
  Skeleton,
  Icon,
  Link,
  LinkProps,
} from '@companydotcom/potion';
import { NavLink as RouteLinkProps } from '@companydotcom/types';
import { NavLink as RouteLink, useMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faTableColumns } from '@fortawesome/pro-solid-svg-icons';
import {
  faTableColumns as faTableColumnsRegular,
  faCalendarCheck as faCalendarCheckRegular,
  faHandshake as faHandshakeRegular,
  faFileInvoice as faFileInvoiceRegular,
  faCreditCardFront as faCreditCardFrontRegular,
  faCalendarStar as faCalendarStarRegular,
  faLock as faLockRegular,
  faArrowRightFromBracket as faArrowRightFromBracketRegular,
  faCircleQuestion as faCircleQuestionRegular,
} from '@fortawesome/pro-regular-svg-icons';
import { faTableColumns as faTableColumnsLight } from '@fortawesome/pro-light-svg-icons';
import {
  DashboardIcon,
  HelpIcon,
  UserProfileIcon,
  AccountInformationIcon,
  SubscriptionsIcon,
  PasswordManagementIcon,
  LogOutIcon,
  GfDataIcon,
} from './nav-icons';

const iconMap = {
  solid: {
    'table-columns': faTableColumns,
  },
  regular: {
    'table-columns': faTableColumnsRegular,
    'calendar-check': faCalendarCheckRegular,
    handshake: faHandshakeRegular,
    'file-invoice': faFileInvoiceRegular,
    'credit-card-front': faCreditCardFrontRegular,
    'calendar-star': faCalendarStarRegular,
    lock: faLockRegular,
    'arrow-right-from-bracket': faArrowRightFromBracketRegular,
    'circle-question': faCircleQuestionRegular,
  },
  light: {
    'table-columns': faTableColumnsLight,
  },
  default: {
    dashboard: <DashboardIcon />,
    help: <HelpIcon />,
    'my-profile': <UserProfileIcon />,
    'account-information': <AccountInformationIcon />,
    subscriptions: <SubscriptionsIcon />,
    'password-management': <PasswordManagementIcon />,
    'log-out': <LogOutIcon />,
    'gf-data': <GfDataIcon />,
  },
};

export interface NavLinkProps extends RouteLinkProps, Omit<LinkProps, 'isExternal'> {
  isLoading?: boolean;
  isSideNavOpen?: boolean;
}

export const NavLink: React.FC<NavLinkProps> = props => {
  const styles = useMultiStyleConfig('SideNav', props);
  const { iconVariant, icon, label, isExternal, isSideNavOpen, isLoading, path } =
    omitThemingProps(props);
  const isActive = useMatch({ path: path || '/' });

  const navIcon =
    icon && iconVariant ? iconMap[iconVariant][icon] : iconMap['default'][icon ?? 'dashboard'];

  const linkStyles = {
    borderRadius: 'none',
    height: 'auto',
    ...styles.navLink,
  } as SystemStyleObject;

  function renderLinkChildren() {
    return (
      <HStack align="center" spacing={4} width="full" p={3}>
        <Skeleton height={6} isLoaded={!isLoading}>
          {!iconVariant || iconVariant === 'default' ? (
            <Icon viewBox="0 0 26 26" boxSize="24px">
              {navIcon}
            </Icon>
          ) : (
            <FontAwesomeIcon icon={navIcon} boxSize="24px" />
          )}
        </Skeleton>
        {isSideNavOpen && (
          <Skeleton isLoaded={!isLoading}>
            <Text>{label}</Text>
          </Skeleton>
        )}
      </HStack>
    );
  }

  if (isExternal && path) {
    return (
      <Link href={path} isExternal sx={linkStyles} className="potion-navGroup__link">
        {renderLinkChildren()}
      </Link>
    );
  }

  return (
    <Link
      as={RouteLink}
      to={path ?? '/'}
      // exact={path === '/'}
      variant="unstyled"
      sx={linkStyles}
      className="potion-navGroup__link"
      data-active={isActive}
    >
      {renderLinkChildren()}
    </Link>
  );
};
