import React from 'react';
import { Link as NavLink, useLocation } from 'react-router-dom';
import { Flex, Box, Text, Link, Button, Heading } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';

export interface LocationState {
  productId?: string;
  planId?: string;
}

export const PaymentError = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { productId = undefined, planId = undefined } = (location?.state || {}) as LocationState;

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={[10]}
      px={[6, 6, 11, 14]}
      py={[6, 6, 11, 14]}
      bg="white"
      maxWidth="3xl"
      width="full"
      mx="auto"
      layerStyle="formContainer"
    >
      <FontAwesomeIcon mb={8} color="error" icon={faCircleQuestion} boxSize={[10, null, '108px']} />
      <Heading size="hs-2xl">{t('subpage.paymentError.heading')}</Heading>
      <Text textStyle="md" mt={8}>
        {t('subpage.paymentError.subheading')}
      </Text>
      <Box mt={14}>
        <Button
          size="lg"
          as={Link}
          to="/payment"
          state={{
            productid: productId,
            planid: planId,
          }}
        >
          {t('subpage.paymentError.buttonText')}
        </Button>
      </Box>
      <Text textStyle="lg" my={2}>
        {t('subpage.paymentError.or')}
      </Text>
      <Link as={NavLink} to="/">
        {t('subpage.paymentError.cancelPurchase')}
      </Link>
    </Flex>
  );
};
