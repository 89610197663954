import gql from 'graphql-tag';

export const ENROLLMENT_SET_VALID_EMAIL = gql`
  mutation EnrollmentSetValidEmail($input: enrollmentSetValidEmailInput!) {
    enrollmentSetValidEmail(input: $input)
  }
`;

export const OPT_OUT_BY_EXT_ID = gql`
  mutation OptOutByExternalIdentifier($input: OptOutExternalIdInput!) {
    optOutByExternalIdentifier(input: $input)
  }
`;

export const RECAPTCHA_VERIFICATION = gql`
  mutation RecaptchaVerification($token: String!) {
    recaptchaVerification(token: $token)
  }
`;

export const RESET_PASSWORD_EMAIL = gql`
  mutation ResetPasswordEmail($email: String, $source: String) {
    resetPasswordEmail(email: $email, source: $source)
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOtp(
    $userId: String!
    $phoneNumber: String
    $email: String
    $useEmail: Boolean
    $code: String
  ) {
    verifyOtp(
      userId: $userId
      phoneNumber: $phoneNumber
      email: $email
      useEmail: $useEmail
      code: $code
    )
  }
`;

export const ENROLL_SINGLE_USER_V2 = gql`
  mutation EnrollSingleUserV2($body: EnrollSingleUserV2Input!, $source: String!) {
    enrollSingleUserV2(body: $body, source: $source)
  }
`;
