import React from 'react';
import { Box, Center, Heading, useBreakpointValue, Text } from '@companydotcom/potion';
import { PageDivider } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { CalendlyForm } from '../../../components/forms/calendly/calendly';

export const FaceCakeScheduleAConsultation = () => {
  const { t } = useTranslation();
  const headingSize = useBreakpointValue({ base: 'xl', sm: '2xl' });

  return (
    <Center
      className="facecake_calendly"
      flexDirection="column"
      bg="transparent"
      py={[0, 8, 12]}
      px={[0, 4]}
    >
      <Box width="full" maxW={830} p={4}>
        <Heading size={headingSize}>{t('icommerce.scheduleAConsultation.header')}</Heading>
        <PageDivider mt={[6, 6]} mb={[6, 12]} mx="inherit" />
        <Text>{t('icommerce.scheduleAConsultation.pageBody')}</Text>
      </Box>
      {/* TODO: change the url to faceCake url */}
      <CalendlyForm url={process.env.REACT_APP_CALENDLY_URL ?? ''} />
    </Center>
  );
};
