import React from 'react';
import { Center, useBreakpointValue, Heading, Box, Button } from '@companydotcom/potion';
import { useForm } from 'react-hook-form';
import { SelectField, PageDivider } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import {
  useGetAcgQueryArgs,
  useGetAcgReferenceDataV2Query,
  useGetAcgUserProfileV2Query,
  useUpdateAcgUserV2Mutation,
} from '../../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { skipToken } from '@reduxjs/toolkit/dist/query';

export interface EditTransactionTypeProps {
  onClose: () => void;
  isMobile: boolean;
}

export const EditTransactionType = ({ onClose, isMobile }: EditTransactionTypeProps) => {
  const { t } = useTranslation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const updateUserArgs = useGetAcgQueryArgs(['email', 'source']);
  const { data: acgUserProfileData } = useGetAcgUserProfileV2Query(
    globalUser ? { ...args, accountId: globalUser?.accountId } : skipToken,
  );
  const { data: acgRefData, isLoading } = useGetAcgReferenceDataV2Query({
    ...args,
    referenceData: ['transactionType'],
  });
  const defaultValues = {
    transactionType: acgUserProfileData?.transactionType ?? [],
  };
  const [updateAcgUser, { isLoading: isUpdating }] = useUpdateAcgUserV2Mutation();

  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-2xl' });
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      if (globalUser) {
        const payload = {
          lastProfileUpdateVersion: acgUserProfileData?.lastProfileUpdateVersion ?? 1,
          transactionType:
            typeof data?.transactionType === 'string'
              ? [data?.transactionType]
              : data?.transactionType,
        };

        await updateAcgUser({
          ...updateUserArgs,
          payload,
        });
        onClose();
      }
    } catch (error) {
      console.log('EditTransactionType onSubmit error: ', error);
    }
  };

  return (
    <Center flexDirection="column">
      <Heading size={headingSize} fontWeight="700" mt={[6, 12]} mb={[2, 2, 12]}>
        {t('acg.myProfile.profilePages.transactionType.transactionType')}
      </Heading>
      {isMobile && <PageDivider width={50} mb={12} />}
      <Box maxWidth="460px" width="100%">
        <SelectField
          isLoading={isLoading}
          register={register}
          name="transactionType"
          label={t('acg.myProfile.profilePages.transactionType.transactionType')}
        >
          <option value=""> </option>
          {acgRefData?.transactionType
            ? acgRefData?.transactionType.map(opt => (
                <option key={opt?.recordName} value={opt?.recordName!}>
                  {opt?.recordName}
                </option>
              ))
            : []}
        </SelectField>
      </Box>
      <Box my={12}>
        <Button
          variant="outline"
          mr="2"
          onClick={onClose}
          size="sm"
          isDisabled={isUpdating || isSubmitting}
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          size="sm"
          isLoading={isSubmitting || isUpdating || isLoading}
          isDisabled={!isValid || !isDirty}
        >
          {t('common.buttons.save')}
        </Button>
      </Box>
    </Center>
  );
};
