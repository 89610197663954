import React, { useState, useEffect, useMemo, useRef } from 'react';
import type { UserTile, TileState, Notification, Cui, User } from '@companydotcom/types';
import { useTranslation } from 'react-i18next';
import { useAssets, useSource, useMitt } from '@companydotcom/providers';
import { kebabCase } from 'lodash';
import { useDisclosure } from '@companydotcom/potion';
import { platformHelpers, companyConstants, companyHelpers } from '@companydotcom/helpers';
import { NewFlag } from './new-flag';
import {
  Tile,
  TileHeader,
  TileContent,
  TileBody,
  TileFooter,
  TileLock,
  TileProductIcon,
} from '../../../components/elements/tile';
import { getTargetTileState, getCurrentTileState } from '../utils';
import { TileProvider, useNotifications, useAuth } from '../../../providers';
// import { publishTileEvent } from '../../../services/event/old-api/event-svc';
// import { updateByopTile } from '../../../services/tile/old-api/tile-service';
import { TileComponent } from '../../tile-components';
import { TileSettings } from './tile-settings';
import { CuiIcon } from './cui-icon';
import { CuiTileOverlay } from './cui-tile-overlay';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { useLazyPublishTileEventQuery } from '../../../services/event/event-api';
import { useUpdateByopTileMutation } from '../../../services/tile/tile-api';
import { useAppSelector } from '../../../hooks';
import { selectIsUserDataCollectorsEmpty } from '../../data-collector/data-collector-slice';

export interface DashboardTileProps {
  tile: UserTile;
  tiles: UserTile[];
  refreshUserTileData: any;
  userCui?: Cui;
}

export const DashboardTile: React.FC<DashboardTileProps> = ({
  tile,
  tiles,
  refreshUserTileData,
  userCui,
}) => {
  const { tileStates, stateCurrent, tileHasLock, product } = tile;
  const { data: globalUser } = useGetGlobalUserQuery();
  const [publishTileEvent] = useLazyPublishTileEventQuery();
  const [updateByopTile] = useUpdateByopTileMutation();
  const { emitter } = useMitt();
  const { t } = useTranslation();
  const auth0 = useAuth();
  const assets = useAssets();
  const source = useSource();
  const notifications = useNotifications();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [hasPollingInitiated, setHasPollingInitiated] = useState(false);
  const [tileNotifications, setTileNotifications] = useState<Notification[]>([]);
  const [currentTileState, setCurrentTileState] = useState<TileState>(
    getCurrentTileState(tileStates, stateCurrent),
  );
  const isUserDataCollectorsEmpty = useAppSelector(selectIsUserDataCollectorsEmpty);

  const targetTileState = React.useMemo(() => getTargetTileState(tile, tiles), [tile, tiles]);
  const firstTimeUser = !!globalUser?.userDataCollectors?.length && !isUserDataCollectorsEmpty;
  const isLocked =
    tileHasLock &&
    (currentTileState?.stateName === 'buy' || currentTileState?.stateName === 'scheduled');

  const useInterval = (callback: any, delay: number) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        // @ts-ignore
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  const poll = async () => {
    if (currentTileState.tileEvents && currentTileState.tileEvents.length) {
      currentTileState.tileEvents.forEach(async tileEvent => {
        if (!tileEvent?.interactive) {
          await publishTileEvent(
            platformHelpers.formatPublishTileEventInput(tileEvent, {
              user: globalUser,
              account: globalUser?.account,
              tile,
            }),
          );
          useInterval;
        }
      });
    }
  };

  useEffect(() => {
    if (currentTileState.stateName !== stateCurrent) {
      setCurrentTileState(getCurrentTileState(tileStates, stateCurrent));
    }
  }, [stateCurrent, tileStates, currentTileState.stateName]);

  if (!hasPollingInitiated) {
    poll();
    setHasPollingInitiated(true);
  }

  useInterval(async () => {
    poll();
  }, 360000);

  useEffect(() => {
    const getNotifications = (notifs: Notification[]) =>
      notifs?.filter(({ body }: any) => body?.tileId === tile?.tileId);
    setTileNotifications(getNotifications(notifications));
    const latestNotification = notifications[notifications.length - 1];
    const updateIsRelevant = (notif: any) => {
      if (!notif || !notif.body || notif.notificationType !== 'tileStateUpdated') {
        return false;
      }
      if (tile.tileId === notif.body.tileId) {
        return true;
      }
      if (tile.tileLinks) {
        return tile.tileLinks.some(({ targetTile }: any) => targetTile === notif.body.tileId);
      }
      return false;
    };
    if (updateIsRelevant(latestNotification)) {
      if (
        // @ts-ignore
        latestNotification?.body?.stateCurrent === currentTileState.stateName &&
        currentTileState.tileEvents &&
        currentTileState.tileEvents.length
      ) {
        currentTileState.tileEvents.forEach(async (tileEvent: any) => {
          if (!tileEvent.interactive) {
            publishTileEvent(
              platformHelpers.formatPublishTileEventInput(tileEvent, {
                user: globalUser,
                account: globalUser?.account,
                tile,
              }),
            );
          }
        });
      }
    }
  }, [
    currentTileState.stateName,
    currentTileState.tileEvents,
    globalUser,
    notifications,
    publishTileEvent,
    tile,
  ]);

  const handleTileEvents = async (
    events: any,
    e: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => {
    try {
      // below line to remove synthetic event warning on console.
      // e.persist();
      if (!events) {
        console.log('No events to handle');
        console.log(`Tile ID: ${tile.tileId}`);
      } else {
        const evtToHandle = events.find((evt: any) => evt.type === e.type);
        if (evtToHandle) {
          // Set user product status
          let userProducts = [];
          let productStatus = 'active';
          if (globalUser?.products) {
            if (typeof globalUser?.products === 'string') {
              try {
                userProducts = JSON.parse(globalUser?.products);
              } catch (err) {
                userProducts = companyHelpers.parseUserProducts(globalUser?.products);
              }
            } else {
              userProducts = globalUser?.products;
            }
            const userProduct = userProducts.find((p: any) => p.productId === tile.productId);
            productStatus = userProduct && userProduct.status ? userProduct.status : 'active';
          }
          if (
            tile.productId === 'byop' &&
            (typeof tile.hasBeenLaunched === 'undefined' ||
              (typeof tile.hasBeenLaunched !== 'undefined' &&
                tile.hasBeenLaunched === false &&
                tile.isHidden !== true)) &&
            globalUser
          ) {
            await updateByopTile({
              byopTileId: tile.tileId,
              userId: globalUser?.userId,
              isHidden: tile.isHidden,
              seqNo: tile.seqNo,
              hasBeenLaunched: true,
            });
            refreshUserTileData();
          }

          const extendedEvt = {
            ...evtToHandle,
            tileId: tile.tileId,
            productId: tile.productId,
            product: tile.product,
            tile,
            sourceProductName: tile.sourceProductName,
            productStatus,
            statePrevious: currentTileState.stateName,
            data,
          };

          emitter.emit(companyConstants.platformEvents.dataCollectorInitiated, extendedEvt);
          return true;
        }
        console.log(`Platform doesn't handle event: ${e.type}`);
      }
    } catch (err: any) {
      console.log('Error handling tile events: ', err);
    }
  };

  const ctx = useMemo(() => tile, [tile]);

  return (
    <TileProvider value={ctx}>
      <Tile
        variant={tile?.tileVariant}
        className={`${tile.tileSlug}__${tile?.stateCurrent}` ?? 'dashboard-tile'}
      >
        {isLocked && <TileLock />}
        {userCui && (
          <>
            {tile.stateCurrent !== 'active' && (
              <CuiIcon
                className="cui__owlIcon"
                position="absolute"
                right={-6}
                top={4}
                onClick={onToggle}
              />
            )}
            <CuiTileOverlay
              isOpen={isOpen}
              onToggle={onToggle}
              onClose={onClose}
              tile={tile}
              user={globalUser as User}
              userCui={userCui}
            />
          </>
        )}
        <TileProductIcon
          src={tile?.tileIcon ?? `${assets?.tile_icon}${kebabCase(tile?.tileSlug ?? '')}.svg`}
          fontSize="xs"
          alt={tile?.tileName ?? tile?.tileSlug ?? 'Tile Icon'}
        />
        {tile?.isNew && <NewFlag />}
        <TileContent>
          <TileSettings refreshUserTileData={refreshUserTileData} />
          <TileHeader>
            {tile && currentTileState.tileComponents
              ? currentTileState?.tileComponents
                  .filter(c => c?.type === 'TileHeader')
                  .map(o => <TileComponent key={o?.type} {...o} />)
              : tile.tileName}
          </TileHeader>
          <TileBody>
            {tile
              ? currentTileState?.tileComponents
                  ?.filter(tc => tc?.type !== 'TileHeader' && tc?.type !== 'TileFooter')
                  .map((tileComponent, idx) => {
                    return (
                      <TileComponent
                        userId={globalUser?.userId}
                        key={idx}
                        eventHandler={handleTileEvents}
                        product={product}
                        account={globalUser?.account}
                        tile={tile}
                        appointments={globalUser?.appointments}
                        firstTimeUser={firstTimeUser}
                        stateCurrent={currentTileState}
                        emitter={emitter}
                        source={source}
                        notifications={tileNotifications}
                        targetTileState={targetTileState}
                        role={globalUser?.role}
                        user={globalUser}
                        auth={auth0}
                        {...tileComponent}
                      />
                    );
                  })
              : t('tiles.loadingTile')}
          </TileBody>
          <TileFooter>
            {tile && currentTileState.tileComponents
              ? currentTileState?.tileComponents
                  .filter(c => c?.type === 'TileFooter')
                  // @ts-ignore
                  .map(o => <TileComponent key={o?.type} {...o} />)
              : tile.tileName}
          </TileFooter>
        </TileContent>
      </Tile>
    </TileProvider>
  );
};
