import React from 'react';
import {
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Icon,
  Button,
  VStack,
  useMultiStyleConfig,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export interface Steps {
  slug?: string;
  label?: string;
  component?: JSX.Element;
  url?: string;
}

export interface MobilePageTabsProps {
  steps: Steps[];
  tabIndex: number;
  goToStep: (arg: string | number) => void;
}

export const MobilePageTabs = ({ steps, tabIndex, goToStep, ...rest }: MobilePageTabsProps) => {
  const styles = useMultiStyleConfig('Tabs', { variant: 'page', ...rest });
  return (
    <Popover matchWidth gutter={0}>
      <PopoverTrigger>
        <Flex
          as={Button}
          variant="unstyled"
          border="1px solid"
          borderRadius="md"
          sx={{
            ...styles.tab,
            ...styles.tab['_selected'],
            _last: { borderBottomLeftRadius: 'none', borderBottomRightRadius: 'none' },
          }}
          width="full"
          py={2}
          px={6}
          justifyContent="space-between"
          alignItems="center"
        >
          {steps[tabIndex].label}
          <Icon as={FontAwesomeIcon} icon={faChevronDown} />
        </Flex>
      </PopoverTrigger>
      <PopoverContent width="full" borderTopRightRadius="none" borderTopLeftRadius="none">
        <PopoverBody width="full" padding={0}>
          <VStack spacing={0}>
            {steps.map((step, i) => (
              <Button
                key={i}
                width="full"
                variant="unstyled"
                textAlign="left"
                sx={{
                  ...styles.tab,
                  _notFirst: {
                    paddingX: 4,
                  },
                  borderRadius: 'none',
                  _first: {
                    borderRadius: 'none',
                    paddingX: 4,
                  },
                  _last: {
                    borderBottomRightRadius: 'md',
                    borderBottomLeftRadius: 'md',
                    paddingX: 4,
                  },
                }}
                onClick={() => goToStep(i)}
              >
                {step.label}
              </Button>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
