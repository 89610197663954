import { getColor } from '../../theme-tools';

const parts = ['root', 'tablist', 'tab', 'tabpanel', 'indicator'];

type Dict = Record<string, any>;

function baseStyleRoot(props: Dict) {
  const { orientation } = props;
  return {
    display: orientation === 'vertical' ? 'flex' : 'block',
  };
}

function baseStyleTab(props: Dict) {
  const { isFitted } = props;

  return {
    flex: isFitted ? 1 : undefined,
    transition: 'all 0.2s',
    _focus: {
      zIndex: 1,
      boxShadow: 'outline',
    },
  };
}

function baseStyleTablist(props: Dict) {
  const { align = 'start', orientation } = props;

  const alignments = {
    end: 'flex-end',
    center: 'center',
    start: 'flex-start',
  };

  return {
    justifyContent: alignments[align],
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
  };
}

const baseStyleTabpanel = { p: 4 };

const baseStyle = (props: Dict) => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel,
});

const sizes = {
  sm: {
    tab: {
      py: '0.25rem',
      px: '1rem',
      fontSize: '0.85rem',
    },
  },
  md: {
    tab: {
      fontSize: '1rem',
      py: '0.5rem',
      px: '1rem',
    },
  },
  lg: {
    tab: {
      fontSize: '1.15rem',
      py: '0.75rem',
      px: '1rem',
    },
  },
};

function variantLine(props: Dict) {
  const { colorScheme: c, orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderProp = orientation === 'vertical' ? 'borderLeft' : 'borderBottom';
  const marginProp = isVertical ? 'ml' : 'mb';

  return {
    tablist: {
      [borderProp]: '2px solid',
      borderColor: 'inherit',
    },
    tab: {
      [borderProp]: '2px solid',
      borderColor: 'transparent',
      [marginProp]: '-2px',
      _selected: {
        color: `${c}.600`,
        borderColor: 'currentColor',
      },
      _active: {
        bg: 'gray.200',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  };
}

function variantEnclosed(props: Dict) {
  const { colorScheme: c } = props;
  return {
    tab: {
      borderTopRadius: 'md',
      border: '1px solid',
      borderColor: 'transparent',
      mb: '-1px',
      _selected: {
        color: `${c}.600`,
        borderColor: 'inherit',
        borderBottomColor: `white`,
      },
    },
    tablist: {
      mb: '-1px',
      borderBottom: '1px solid',
      borderColor: 'inherit',
    },
  };
}

function variantEnclosedColored(props: Dict) {
  const { colorScheme: c } = props;
  return {
    tab: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: `gray.50`,
      mb: '-1px',
      _notLast: {
        mr: '-1px',
      },
      _selected: {
        bg: `#fff`,
        color: `${c}.600`,
        borderColor: 'inherit',
        borderTopColor: 'currentColor',
        borderBottomColor: 'transparent',
      },
    },
    tablist: {
      mb: '-1px',
      borderBottom: '1px solid',
      borderColor: 'inherit',
    },
  };
}

function variantSoftRounded(props: Dict) {
  const { colorScheme: c, theme } = props;
  return {
    tab: {
      borderRadius: 'full',
      fontWeight: 'semibold',
      color: 'gray.600',
      _selected: {
        color: getColor(theme, `${c}.700`),
        bg: getColor(theme, `${c}.100`),
      },
    },
  };
}

function variantSolidRounded(props: Dict) {
  const { colorScheme: c } = props;
  return {
    tab: {
      borderRadius: 'full',
      fontWeight: 'semibold',
      color: 'gray.600',
      _selected: {
        color: `#fff`,
        bg: `${c}.600`,
      },
    },
  };
}

const variantUnstyled = {
  tabpanel: {
    padding: 0,
  },
};

const variants = {
  line: variantLine,
  enclosed: variantEnclosed,
  'enclosed-colored': variantEnclosedColored,
  'soft-rounded': variantSoftRounded,
  'solid-rounded': variantSolidRounded,
  unstyled: variantUnstyled,
};

const defaultProps = {
  size: 'md',
  variant: 'line',
  colorScheme: 'blue',
};

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
