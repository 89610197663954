import { Box, Button, Heading, Center, Text, VStack } from '@companydotcom/potion';
import { parsePhoneNumber } from 'libphonenumber-js';
import {
  InputField,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
} from '@companydotcom/ui';
import { platformHelpers } from '@companydotcom/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { User, Listing } from '@companydotcom/types';
import { PendingVerification } from './shared-blpcomponents';
import { useAwaitableFacade, useToast } from '../../../../hooks';
import { useUpdateListingMutation } from '../../../../services/listings/listing-api';
import yup from '../../../../lib/yup';

const getContactInformationSchema = () =>
  yup.object().shape({
    businessEmail: yup
      .string()
      .matches(/^[a-zA-Z@0-9._-]*$/, {
        message: 'Incorrect format. Please use only numbers, letters, hyphens, and underscores.',
        excludeEmptyString: true,
      })
      .email('Not a valid email address'),
    businessPhoneNumber: yup.string().phone().required('Please enter your phone number').nullable(),
  });

interface ContactInformationProps {
  user?: User;
  listing?: Listing;
  setListing?: (arg: Listing) => void;
  showPendingVerification?: boolean;
  // listingSvc?: any;
}

export const ContactInformation = ({
  user,
  listing,
  setListing,
  showPendingVerification,
}: // listingSvc,
ContactInformationProps) => {
  const [updateListing] = useUpdateListingMutation();
  const snsInterface = useAwaitableFacade(user, 'listing');
  const { country: businessCountry, onCountryChange: businessOnCountryChange } =
    useGetCurrentlySelectedCountry();
  const { country: alternateCountry, onCountryChange: alternateOnCountryChange } =
    useGetCurrentlySelectedCountry();
  const { country: faxCountry, onCountryChange: faxOnCountryChange } =
    useGetCurrentlySelectedCountry();

  const toast = useToast();
  const defaultValues: FieldValues = {
    businessEmail: listing?.emails?.[0]?.email || '',
    businessPhoneNumber: listing?.phones?.find?.(x => x?.type === 'MAIN')
      ? parsePhoneNumber(
          listing?.phones?.find?.(x => x?.type === 'MAIN')?.number || '',
          businessCountry,
        ).number
      : '',
    alternatePhoneNumber: listing?.phones?.find?.(x => x?.type === 'ALTERNATE')
      ? parsePhoneNumber(
          listing?.phones?.find?.(x => x?.type === 'ALTERNATE')?.number || '',
          alternateCountry,
        ).number
      : '',
    website: listing?.urls?.find?.(x => x?.type === 'WEBSITE')?.url || '',
    faxNumber: listing?.phones?.find?.(x => x?.type === 'FAX')
      ? parsePhoneNumber(listing?.phones?.find?.(x => x?.type === 'FAX')?.number || '', faxCountry)
          .number
      : '',
  };
  const { register, handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getContactInformationSchema()),
    defaultValues,
    context: { country: businessCountry },
  });
  const { isSubmitting, isValid, errors } = formState;

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      const updateParams: any = {};
      if (values.website) {
        updateParams.urls = [
          {
            url: values.website,
            type: 'WEBSITE',
          },
        ];
      }

      const unmask = (phone: any) =>
        phone
          .replace(/([-()])/g, '') // irregular whitespace characters in the mask
          .split(' ')
          .join('');

      let phoneTypes = {
        ALTERNATE: 'alternatePhoneNumber',
        MAIN: 'businessPhoneNumber',
        FAX: 'faxNumber',
      };

      updateParams.phones = listing?.phones ? listing.phones.slice() : [];

      const getPhoneType = (type: string) => {
        let index = updateParams?.phones.findIndex((item: any) => item.type === type);
        if (index === -1) return null;

        return {
          phoneNumber: updateParams?.phones[index],
          index,
        };
      };

      // Handles the creating and updating for the 3 different phone types
      Object.keys(phoneTypes).forEach((type: string) => {
        let currentFormValue = values[phoneTypes[type]];

        if (currentFormValue) {
          let existingPhoneNumber = getPhoneType(type);

          let currentNumber = {
            number: platformHelpers.formatPhoneNumberToUS(unmask(currentFormValue)),
            type,
          };

          // If there is a number and its different from the current Listing number swap it else push it
          existingPhoneNumber && existingPhoneNumber !== currentFormValue
            ? updateParams.phones.splice(existingPhoneNumber.index, 1, currentNumber)
            : updateParams.phones.push(currentNumber);
        }
      });

      updateParams.emails = listing?.emails ? listing.emails.slice() : [];
      if (updateParams.emails.length) {
        if (values.businessEmail) {
          const bizEmail = {
            description: 'businessEmail',
            email: values.businessEmail,
          };
          updateParams.emails.unshift(bizEmail);
          updateParams.emails.splice(1, 1);
        } else {
          updateParams.emails.splice(0, 1);
        }
      } else if (values.businessEmail) {
        updateParams.emails.push({
          email: values.businessEmail,
          description: 'businessEmail',
        });
      }

      if (user) {
        const updatedListing = await updateListing({
          listingInput: { id: user?.account?.listingId, ...updateParams },
          snsInterface,
        }).unwrap();
        setListing?.(updatedListing);
        toast({
          description: 'Your changes have been synced!',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    } catch (err) {
      toast({
        description: 'Error saving changes',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      });
      console.log('Error!', err, ErrorEvent);
    }
  };

  return (
    <Center
      className="blp-contact-information"
      flexDirection="column"
      pt={[12, null, 3]}
      pb={[12, null, 15]}
      width="full"
      px={4}
      textAlign="center"
      justifyContent="flex-start"
    >
      {showPendingVerification && <PendingVerification />}
      <Box maxW="xl">
        <Heading as="h1" size="hs-lg">
          Contact Information
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          Add your preferred contact information so that customers can know how to reach your
          business.
        </Heading>
        <Text textStyle="md" mt={4}>
          All fields are required unless marked optional
        </Text>
      </Box>
      <Box maxWidth={475} as="form" mt={14} width="full">
        <VStack spacing={6} width="full">
          <InputField
            register={register}
            name="businessEmail"
            autoFocus
            label="Business Email (optional)"
            errors={errors}
          />
          <PhoneNumberInputField
            name="businessPhoneNumber"
            label="Business Phone Number"
            control={control}
            country={businessCountry}
            onCountryChange={businessOnCountryChange}
            errors={errors}
          />

          <PhoneNumberInputField
            name="alternatePhoneNumber"
            label="Alternate Phone Number (optional)"
            control={control}
            country={alternateCountry}
            onCountryChange={alternateOnCountryChange}
            errors={errors}
          />

          <InputField
            register={register}
            name="website"
            label="Website (optional)"
            errors={errors}
          />
          <PhoneNumberInputField
            name="faxNumber"
            label="Fax Number (optional)"
            control={control}
            country={faxCountry}
            onCountryChange={faxOnCountryChange}
            errors={errors}
          />
        </VStack>
        <Box mt={14}>
          <Button
            size="lg"
            variant="outline"
            isLoading={isSubmitting}
            isDisabled={!isValid || isSubmitting || showPendingVerification}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
