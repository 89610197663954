import { SystemStyleFunction } from '../../theme-tools';

type Dict = Record<string, any>;

const baseStyle = {
  lineHeight: '1.2',
  borderRadius: 'md',
  fontWeight: 'semibold',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _focus: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 0.5,
    boxShadow: 'none',
    cursor: 'not-allowed',
  },
  _hover: {
    textDecoration: 'none',
    background: 'initial',
  },
};

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors?. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

function variantSolid(props: Dict) {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    const bg = `gray.100`;

    return {
      bg,
      _hover: {
        bg: `gray.200`,
        _disabled: {
          bg,
        },
      },
      _active: { bg: `gray.300` },
    };
  }

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
  } = accessibleColorMap[c] || {};

  const background = bg;

  return {
    bg: background,
    color,
    _hover: {
      bg: hoverBg,
      _disabled: {
        bg: background,
      },
    },
    _active: { bg: activeBg },
  };
}

function variantGhost(props: Dict) {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    return {
      color: `inherit`,
      _hover: {
        bg: `gray.100`,
      },
      _active: { bg: `gray.200` },
    };
  }

  return {
    color: `${c}.600`,
    bg: 'transparent',
    _hover: {
      bg: `${c}.50`,
    },
    _active: {
      bg: `${c}.100`,
    },
  };
}

function variantOutline(props: Dict) {
  const { colorScheme: c } = props;
  const borderColor = `gray.200`;
  return {
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    ...variantGhost(props),
  };
}

const variantLink: SystemStyleFunction = (props: Dict) => {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    verticalAlign: 'baseline',
    color: `${c}.500`,
    borderRadius: 'none',
    whiteSpace: 'normal',
    _hover: {
      textDecoration: 'underline',
      _disabled: {
        textDecoration: 'none',
      },
    },
    _active: {
      color: `${c}.700`,
    },
  };
};

const variantUnstyled = {
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: 0,
  p: 0,
};

const variants = {
  outline: variantOutline,
  solid: variantSolid,
  ghost: variantGhost,
  unstyled: variantUnstyled,
  link: variantLink,
};

const sizes = {
  lg: {
    h: 12,
    minW: 12,
    fontSize: 'lg',
    px: 6,
  },
  md: {
    h: 10,
    minW: 10,
    fontSize: 'md',
    px: 4,
  },
  sm: {
    h: 8,
    minW: 8,
    fontSize: 'sm',
    px: 3,
  },
  xs: {
    h: 6,
    minW: 6,
    fontSize: 'xs',
    px: 2,
  },
};

const defaultProps = {
  variant: 'solid',
  size: 'md',
  colorScheme: 'teal',
};

export default { baseStyle, variants, sizes, defaultProps };
