import React, { useState } from 'react';
import { Center, useBreakpointValue, Heading, Box, Button } from '@companydotcom/potion';
import { useForm, useFieldArray } from 'react-hook-form';
import { PageDivider } from '@companydotcom/ui';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useGetAcgQueryArgs,
  useGetAcgReferenceDataV2Query,
  useGetAcgUserProfileV2Query,
  useUpdateAcgUserV2Mutation,
} from '../../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { AdditiveList } from '../../../../components/elements';

export interface EditExpertiseProps {
  onClose: () => void;
  isMobile: boolean;
}

export const EditExpertise = ({ onClose, isMobile }: EditExpertiseProps) => {
  const { t } = useTranslation();
  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-2xl' });
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const updateUserArgs = useGetAcgQueryArgs(['email', 'source']);
  const { data: acgUserProfileData } = useGetAcgUserProfileV2Query({
    ...args,
    email: globalUser?.email,
    accountId: globalUser?.accountId,
  });
  const { data: acgRefData, isLoading } = useGetAcgReferenceDataV2Query({
    ...args,
    referenceData: ['areaOfExpertise'],
  });
  const defaultValues = {
    areaOfExpertise: acgUserProfileData?.areaOfExpertise?.map(i => ({ name: i })) ?? [],
  };
  const [showOptions, setShowOptions] = useState(isEmpty(defaultValues.areaOfExpertise));
  const [updateAcgUser, { isLoading: isUpdating }] = useUpdateAcgUserV2Mutation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm({
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({ name: 'areaOfExpertise', control });

  const addData = (e: React.ChangeEvent<HTMLSelectElement>) => {
    append({
      name: e.target?.options?.[e.target.selectedIndex]?.text,
    });
    setShowOptions(false);
  };

  const removeData = (index: number) => {
    if (fields?.length === 1) {
      setShowOptions(true);
    }

    remove(index);
  };

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      if (globalUser) {
        const payload = {
          lastProfileUpdateVersion: acgUserProfileData?.lastProfileUpdateVersion ?? 1,
          areaOfExpertise: data?.areaOfExpertise.map(i => i.name),
        };

        updateAcgUser({
          ...updateUserArgs,
          payload,
        });
        onClose();
      }
    } catch (error) {
      console.log('EditExpertise onSubmit error: ', error);
    }
  };

  return (
    <Center flexDirection="column">
      <Heading size={headingSize} fontWeight="700" mt={[6, 12]} mb={[2, 2, 12]}>
        {t('acg.myProfile.profilePages.expertise.expertise')}
      </Heading>
      {isMobile && <PageDivider width={50} mb={12} />}
      <Box maxWidth="460px" width="100%">
        <AdditiveList
          removeHandler={removeData}
          addHandler={addData}
          fields={fields}
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          label="Add Expertise"
          isLoading={isUpdating || isLoading}
        >
          {acgRefData?.areaOfExpertise
            ? acgRefData.areaOfExpertise?.map(opt => (
                <option
                  key={opt?.recordName}
                  value={opt?.recordName!}
                  disabled={fields.some(field => field.name === opt?.recordName)}
                >
                  {opt?.recordName}
                </option>
              ))
            : []}
        </AdditiveList>
      </Box>
      <Box my={12}>
        <Button
          variant="outline"
          mr="2"
          onClick={onClose}
          size="sm"
          isDisabled={isUpdating || isSubmitting}
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          size="sm"
          isLoading={isSubmitting || isUpdating}
          isDisabled={!isValid || !isDirty}
        >
          {t('common.buttons.save')}
        </Button>
      </Box>
    </Center>
  );
};
