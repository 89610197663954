import { Notification } from '@companydotcom/types';
// import { getNotificationList } from '../../../services/notification/old-api/notification-svc';

export const getNotifications = async (
  getNotificationList: any,
  userId: string,
  setNotifications: any,
  setErrors: any,
) => {
  try {
    const newNotifications = (await getNotificationList({ userId }))
      .unwrap()
      .filter((item: Notification) => item.handled === 0)
      .map((item: Notification) => {
        return {
          ...item,
          body: item.body && JSON.parse(decodeURIComponent(item.body)),
        };
      });
    setNotifications(newNotifications);
    return newNotifications;
  } catch (getNotificationsError) {
    setErrors((prevNotifications: any) => {
      return {
        ...prevNotifications,
        ...{ getNotificationsError },
      };
    });
  }
  return [];
};
