import React, { useState, useEffect } from 'react';
import { useTabStepper, Tabs, TabPanels, TabPanel } from '@companydotcom/potion';
import { User, Source } from '@companydotcom/types';
import { useAuth } from '../../../providers';
import { VerifyPhoneWrapper } from './verify-phone-wrapper';
import { VerifyCodeWrapper } from './verify-code-wrapper';
import { VerifyEmailWrapper } from './verify-email-wrapper';

const checkVerificationSteps = [
  { slug: 'email-verification', component: <VerifyEmailWrapper /> },
  { slug: 'enter-phone', component: <VerifyPhoneWrapper /> },
  { slug: 'enter-code', component: <VerifyCodeWrapper /> },
];

export interface CheckVerificationProps {
  user?: User;
  source?: Source;
  reason?: any;
  onVerificationComplete?: any;
  additionalVerificationCondition?: boolean;
  cancelPayment?: any;
}

export const CheckVerification = ({
  user,
  source,
  additionalVerificationCondition,
  reason,
  cancelPayment,
  onVerificationComplete,
}: CheckVerificationProps) => {
  const auth0 = useAuth();
  const [verificationRequired, setVerificationRequired] = useState(true);
  const [verificationType, setVerificationType] = useState('unknown');
  const [isBack, setIsBack] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const { tabIndex, handleTabsChange, goToStep } = useTabStepper({
    steps: checkVerificationSteps,
    scrollOnStepChange: false,
  });

  useEffect(() => {
    if (source?.sourceId !== 'company') {
      setVerificationRequired(false);
    } else if (additionalVerificationCondition) {
      const phoneVerified = auth0?.user?.isPhoneVerified;
      const emailVerified = auth0?.user?.isEmailVerified;
      let index = -1;
      if (!phoneVerified) {
        setVerificationRequired(true);
        setVerificationType('enter-phone');
        // Fix to show proper model based on verification type
        index = checkVerificationSteps.findIndex(step => step.slug === 'email-verification');
        if (index !== -1) {
          checkVerificationSteps.splice(index, 1);
        }
      } else if (!emailVerified) {
        setVerificationRequired(true);
        setVerificationType('email-verification');
        // Fix to show proper model based on verification type
        index = checkVerificationSteps.findIndex(step => step.slug === 'enter-phone');
        if (index !== -1) {
          checkVerificationSteps.splice(index, 1);
        }
      } else {
        setVerificationRequired(false);
      }
    } else {
      setVerificationRequired(false);
    }
  }, [additionalVerificationCondition, auth0, source?.sourceId]);

  useEffect(() => {
    if (!verificationRequired && !hasCompleted) {
      onVerificationComplete();
      setHasCompleted(true);
    }
  }, [verificationRequired, onVerificationComplete, hasCompleted]);

  if (verificationType === 'unknown') {
    return null;
  }

  return (
    <Tabs
      isLazy
      id="check-verification"
      className="check-verification"
      variant="unstyled"
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <TabPanels>
        {checkVerificationSteps.map(step => (
          <TabPanel key={step.slug}>
            {React.cloneElement(step.component, {
              source,
              email: user?.email,
              user,
              verificationType,
              setIsBack,
              isBack,
              reason,
              cancelPayment,
              isEmailVerification: verificationType === 'email-verification',
              goToStep,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
