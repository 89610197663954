import React, { useState, useEffect } from 'react';
import { Box, Text, Heading, VStack, Flex, Icon, useMediaQuery } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { Listing } from '@companydotcom/types';

export interface RecommendedStepsProps {
  goToStep: (e: string | number) => void;
  restartGmbProcess: () => void;
  listing: Listing;
  setShowRecSteps: (show: boolean) => void;
}

export const RecommendedSteps: React.FC<RecommendedStepsProps> = props => {
  const { goToStep, restartGmbProcess, listing, setShowRecSteps } = props;
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const [hoursStep, setHoursStep] = useState('orange.300');
  const [paymentStep, setPaymentStep] = useState('gray.300');
  const [socialStep, setSocialStep] = useState('gray.300');
  const [uploadStep, setUploadStep] = useState('gray.300');
  const [gmbStep, setGmbStep] = useState('gray.300');

  const recItems = [
    {
      label: t('components.recommendedSteps.stepOne'),
      handler: () => goToStep('hours-of-operation'),
      colorState: hoursStep,
    },
    {
      label: t('components.recommendedSteps.stepTwo'),
      handler: () => goToStep('payment-accepted'),
      colorState: paymentStep,
    },
    {
      label: t('components.recommendedSteps.stepThree'),
      handler: () => goToStep('social-media'),
      colorState: socialStep,
    },
    {
      label: t('components.recommendedSteps.stepFour'),
      handler: () => goToStep('social-media'),
      colorState: uploadStep,
    },
    {
      label: t('components.recommendedSteps.stepFive'),
      handler:
        gmbStep === 'green.400'
          ? undefined
          : () => {
              restartGmbProcess();
            },
      colorState: gmbStep,
    },
  ];

  // Hours Render
  useEffect(() => {
    return listing?.hours && listing?.hours !== null ? setHoursStep('green.400') : undefined;
  }, [listing?.hours]);

  // Payment Render
  useEffect(() => {
    if (
      hoursStep === 'green.400' &&
      (!listing?.paymentOptions || !listing?.paymentOptions?.length)
    ) {
      setPaymentStep('orange.300');
    } else if (listing?.paymentOptions && listing?.paymentOptions?.length) {
      setPaymentStep('green.400');
    }
  }, [listing?.paymentOptions, hoursStep]);

  // Social Render
  useEffect(() => {
    if (
      paymentStep === 'green.400' &&
      (!listing?.socialProfiles || !listing?.socialProfiles?.length)
    ) {
      setSocialStep('orange.300');
    } else if (listing?.socialProfiles && listing?.socialProfiles?.length) {
      setSocialStep('green.400');
    }
  }, [listing?.socialProfiles, paymentStep]);

  // Upload Render
  useEffect(() => {
    if (
      socialStep === 'green.400' &&
      (!listing?.videos || !listing?.images || !listing?.videos.length || !listing?.images?.length)
    ) {
      setUploadStep('orange.300');
    } else if (
      listing?.videos &&
      listing?.images &&
      listing?.videos?.length &&
      listing?.images?.length
    ) {
      setUploadStep('green.400');
    }
  }, [listing?.videos, listing?.images, socialStep]);

  // gmb Render
  useEffect(() => {
    if (
      listing?.gmbStatus === 'optIn' ||
      listing?.gmbStatus === 'optOut' ||
      listing?.gmbStatus === 'locationCreationFailed'
    ) {
      if (uploadStep === 'green.400' || listing?.gmbLocationId) {
        setGmbStep('orange.300');
      } else {
        setGmbStep('gray.300');
      }
    } else {
      setGmbStep('green.400');
    }
  }, [listing?.gmbStatus, socialStep, listing?.gmbLocationId, uploadStep]);

  useEffect(() => {
    if (
      hoursStep === 'green.400' &&
      paymentStep === 'green.400' &&
      socialStep === 'green.400' &&
      uploadStep === 'green.400' &&
      gmbStep === 'green.400'
    ) {
      setShowRecSteps(false);
    }
  }, [hoursStep, paymentStep, socialStep, uploadStep, setShowRecSteps, gmbStep]);

  return (
    <Box className="recommended-steps">
      <Heading size="hs-md" mb={4}>
        {t('components.recommendedSteps.heading')}
      </Heading>
      <Text textStyle="sm">{t('components.recommendedSteps.message')}</Text>

      <VStack width="full" spacing={3} mt={8}>
        {isMobile ? (
          <StepItem item={recItems?.find?.(o => o?.colorState !== 'green.400')} />
        ) : (
          recItems.map(i => <StepItem key={i.label} item={i} />)
        )}
      </VStack>
    </Box>
  );
};

const StepItem = ({ item }: any) => {
  return (
    <Flex
      key={item?.label}
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      width="full"
      px={4}
      py={3}
      border="1px solid"
      borderColor="gray.100"
      boxShadow="md"
      onClick={item?.handler}
      minHeight={16}
      height="100%"
      cursor="pointer"
    >
      <Text textStyle="sm" maxWidth={240}>
        {item?.label}
      </Text>
      <Icon color={item?.colorState} as={FontAwesomeIcon} boxSize={8} icon={faCheckCircle} />
    </Flex>
  );
};
