import foundations from './foundations';
import components from './components';
import styles from './styles';

export const theme = {
  ...foundations,
  components: { ...components },
  styles,
};

export type Theme = typeof theme;

export * from './theme.types';
export * from './utils';

export default theme;
