import { Listing } from '@companydotcom/types';
import { emptySplitApi } from '../../store/empty-split-api';
import { SUPPRESS_LISTING } from './graphql/listing-mutation';
import {
  GET_AUTOCOMPLETE_RESULTS,
  GET_CITY_STATE_FROM_ZIP,
  GET_LOCATION_AUTOCOMPLETE_RESULTS,
} from './graphql/listing-query';

const tileData = {
  productId: 'dd7cc931-04ae-4d95-adfb-e30384cd03ad',
  tileId: 'db4f02c1-3c13-4031-a6a9-8ac72de16a57',
};

export const ListingApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    //NEW APIs USING AWAITABLE FACADE
    updateListing: builder.mutation<
      Listing,
      { listingInput: any; snsInterface: any; source?: any }
    >({
      invalidatesTags: ['User'],
      queryFn: ({ snsInterface, listingInput }) => {
        try {
          return snsInterface
            .getNotifData(tileData, 'fetch', 'updateListing', listingInput, {}, false)
            .then((notif: any) => {
              return notif
                ? { data: notif?.body?.payload?.listing?.data }
                : {
                    error: true,
                    meta: { message: 'No response recieved while updating listing' },
                  };
            });
        } catch (err) {
          console.log(err);
          return { error: true, meta: { message: 'Error updating listing' } };
        }
      },
    }),
    getListingBySlug: builder.query<Listing, { slug: string; snsInterface: any }>({
      queryFn: async ({ slug, snsInterface }) => {
        try {
          const res = await snsInterface.getNotifData(
            tileData,
            'fetch',
            'searchListings',
            { slug },
            {},
            false,
          );

          return res
            ? { data: res.body.payload.listing[0] }
            : { error: true, meta: { message: 'No response recieved while fetching listing' } };
        } catch (err) {
          console.error(err);
          return { error: true, meta: { message: 'Error fetching listing--getListingBySlug' } };
        }
      },
    }),
    searchListings: builder.query<
      Listing[],
      { name: string; address: string; category: string; snsInterface: any }
    >({
      queryFn: async ({ name, address, category, snsInterface }) => {
        try {
          const res = await snsInterface.getNotifData(
            tileData,
            'fetch',
            'searchListings',
            { name, address, category },
            {},
            true,
          );

          return res
            ? { data: res.body.payload }
            : { error: true, meta: { message: 'No response received while fetching listing' } };
        } catch (err) {
          console.error(err);
          return { error: true, meta: { message: 'Error fetching listing--searchListing' } };
        }
      },
    }),
    getListingAutocompleteResults: builder.query<
      Listing,
      { searchTerm: string; snsInterface: any }
    >({
      queryFn: async ({ snsInterface, searchTerm }) => {
        try {
          const res = await snsInterface.getNotifData(
            tileData,
            'fetch',
            'searchListings',
            { name: searchTerm },
            {},
            true,
          );

          return res
            ? { data: res.body.payload }
            : { error: true, meta: { message: 'No response received while fetching listing' } };
        } catch (err) {
          console.error(err);
          return {
            error: true,
            meta: { message: 'Error fetching listing--getListingAutocompleteResults' },
          };
        }
      },
    }),
    getVerificationOptions: builder.mutation<string[], { snsInterface: any; listingId: string }>({
      queryFn: async ({ snsInterface, listingId }) => {
        try {
          const res = await snsInterface.getNotifData(
            tileData,
            'fetch',
            'getVerificationOptions',
            { listingId },
            {},
            false,
          );

          return res
            ? { data: res.body.payload.verificationOptions }
            : {
                error: true,
                meta: { message: 'No response received while fetching verification options' },
              };
        } catch (err) {
          console.error(err);
          return {
            error: true,
            meta: { message: 'Error fetching options--getVerificationOptions' },
          };
        }
      },
    }),
    checkVerificationStatus: builder.mutation<Listing, { listingId: string; snsInterface?: any }>({
      queryFn: ({ listingId, snsInterface }) => {
        try {
          return snsInterface
            .getNotifData(tileData, 'fetch', 'checkVerificationStatus', { listingId }, {}, false)
            .then((notif: any) => {
              return notif
                ? { data: notif?.body?.payload?.listing }
                : { error: true, meta: { message: 'No response received while checking status' } };
            });
        } catch (err) {
          console.error(err);
          return {
            error: true,
            meta: { message: 'Error checking status--checkVerificationStatus' },
          };
        }
      },
    }),
    createListing: builder.mutation<
      {
        success: boolean;
        error: string;
        data: Listing;
      },
      { listingInput: Listing; snsInterface?: any }
    >({
      queryFn: ({ listingInput, snsInterface }) => {
        try {
          return snsInterface
            .getNotifData(
              tileData,
              'transition',
              'activate',
              listingInput,
              {
                stateCurrent: 'active',
                statePrevious: 'inactive',
              },
              true,
            )
            .then((notif: any) => {
              return notif
                ? {
                    data: {
                      success: !notif.body?.payload?.error,
                      error: notif?.body?.payload?.error,
                      data: notif?.body?.payload.listing,
                    },
                  }
                : { error: true, meta: { message: 'No response recieved while creating listing' } };
            });
        } catch (err) {
          return { error: true, meta: { message: 'Error creating listing--createListing' } };
        }
      },
    }),
    startVerification: builder.mutation<
      any,
      { snsInterface: any; listingId: string; verificationMethod: string }
    >({
      queryFn: async ({ snsInterface, listingId, verificationMethod }) => {
        try {
          const res = await snsInterface.getNotifData(
            tileData,
            'transition',
            'startVerification',
            { listingId, verificationMethod },
            {
              stateCurrent: verificationMethod === 'NOT_NOW' ? 'active' : 'hold',
              statePrevious: 'active',
            },
            false,
          );
          return res
            ? {
                data: {
                  success: res.body?.payload?.success,
                  error: res?.body?.payload?.error,
                  data: res?.body?.payload.data,
                },
              }
            : { error: true, meta: { message: 'No response from blp ' } };
        } catch (err) {
          return { error: true, meta: { message: 'Error in--startVerification' } };
        }
      },
    }),
    completeVerification: builder.mutation<
      {
        success: boolean;
        error: string;
        data: Listing;
      },
      { listingId: string; verificationCode: string; snsInterface: any }
    >({
      queryFn: async ({ snsInterface, listingId, verificationCode }) => {
        try {
          const res = await snsInterface.getNotifData(
            tileData,
            'transition',
            'startVerification',
            { listingId, verificationCode },
            {
              stateCurrent: 'active',
              statePrevious: 'hold',
            },
            false,
          );
          return res
            ? {
                data: {
                  success: !res.body?.payload?.error,
                  error: res?.body?.payload?.error,
                  data: res?.body?.payload.listing,
                },
              }
            : { error: true, meta: { message: 'No response from blp ' } };
        } catch (err) {
          return { error: true, meta: { message: 'Error in--completeVerification' } };
        }
      },
    }),
    // NEW APIS WITHOUT AWAITABLE FACADE
    suppressListing: builder.mutation<any, { listingId: string }>({
      query: ({ listingId }) => ({
        method: 'mutation',
        document: SUPPRESS_LISTING,
        variables: {
          input: listingId,
        },
      }),
      transformResponse: (rawResult: { data: { suppressListing: any } }) =>
        JSON.parse(rawResult.data.suppressListing).data,
    }),
    getAutocompleteResults: builder.query<any, { searchTerm: string }>({
      query: ({ searchTerm }) => ({
        method: 'query',
        document: GET_AUTOCOMPLETE_RESULTS,
        variables: {
          searchTerm: searchTerm.split(' ').join(' + ').concat('*'),
        },
      }),
      transformResponse: (rawResult: { data: { getAutocompleteResults: [] } }) =>
        rawResult.data.getAutocompleteResults,
    }),
    getLocationAutocompleteResults: builder.query<any, { searchTerm: string }>({
      query: ({ searchTerm }) => ({
        method: 'query',
        document: GET_LOCATION_AUTOCOMPLETE_RESULTS,
        variables: { searchTerm },
      }),
      transformResponse: (rawResult: { data: { getLocationAutocomplete: [] } }) =>
        rawResult.data.getLocationAutocomplete,
    }),
    getCityStateFromZipCode: builder.query<any, { zipCode: string }>({
      query: ({ zipCode }) => ({
        method: 'query',
        document: GET_CITY_STATE_FROM_ZIP,
        variables: {
          zipCode,
        },
      }),
      transformResponse: (rawResult: {
        data: {
          getCityStateFromZip:
            | {
                data: { city: { longName: string }; state: { shortName: string } };
                status: string;
              }
            | string;
        };
      }) => {
        if (typeof rawResult.data.getCityStateFromZip === 'string') {
          return JSON.parse(rawResult.data.getCityStateFromZip);
        }
        return rawResult.data.getCityStateFromZip;
      },
    }),
  }),
});

export const {
  useUpdateListingMutation,
  useGetVerificationOptionsMutation,
  useLazyGetLocationAutocompleteResultsQuery,
  useLazyGetCityStateFromZipCodeQuery,
  useCreateListingMutation,
  useStartVerificationMutation,
  useCompleteVerificationMutation,
  useCheckVerificationStatusMutation,
} = ListingApi;
