import React from 'react';
import { ContactPage } from '../pages/contact-page';
import { EmailPasswordSettingsStep } from '../pages/password-management/components/email-password-settings-step';
import {
  Logout,
  Login,
  PaymentError,
  Reverify,
  VerifyBusiness,
  CancelBusiness,
  CancelConfirmed,
  ContactSupport,
  PasswordReset,
  GeneralError,
  CountryFilter,
  VerifyEmailStdPage,
} from '../pages/subpages';
import Subpage from '../pages/subpages/subpage';
import { ShutOffscreen } from '../components/forms/ShutOffScreen/ShutOffscreen';
import Callback from './callback/callback';
import { OneColumnLayout } from '../components/layouts';
import { FaceCakeEnterPriseContactPage } from '../pages/facecake-enterprise-contact-us';
import { FaceCakeLeadGenPage } from '../pages/facecake-lead-gen';

export const publicRoutes = (isAuthenticated = false, email: any, sourceId: any) => [
  // These routes have no layout surrounding them
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: 'login',
    element: <Login isAuthenticated={isAuthenticated} />,
  },
  {
    path: '/change-email-password',
    element: <EmailPasswordSettingsStep external />,
  },
  {
    path: sourceId === 'acg' ? 'shut-off' : '',
    element: <ShutOffscreen />,
  },

  // Public routes that need a subpage layout
  {
    element: <Subpage />,
    children: [
      {
        path: 'callback',
        element: <Callback />,
      },
      {
        path: 'paymenterror',
        element: <PaymentError />,
      },
      {
        path: 'reverify',
        element: <Reverify />,
      },
      {
        path: 'verifybusiness',
        element: <VerifyBusiness />,
      },
      // should only be for fis source
      {
        path: 'cancelbusiness',
        element: <CancelBusiness />,
      },
      {
        path: 'verifystd',
        element: <VerifyEmailStdPage email={email} />,
      },
      // should only be for fis source
      {
        path: 'cancelconfirmed',
        element: <CancelConfirmed />,
      },
      {
        path: 'support',
        element: <ContactSupport />,
      },
      {
        path: 'start',
        element: <PasswordReset />,
      },
      {
        path: 'contact-us',
        element: <ContactPage />,
      },
      {
        path: 'unauthorized',
        element: <GeneralError statusCode={401} />,
      },
      {
        path: 'restricted',
        element: <CountryFilter />,
      },
      {
        path: '*',
        element: <GeneralError statusCode={404} />,
      },
    ],
  },
  {
    element: <OneColumnLayout />,
    children: [
      {
        path: 'facecake',
        children: [
          {
            path: 'lead-gen',
            element: <FaceCakeLeadGenPage />,
          },
          {
            path: 'enterprise-contact-us',
            element: <FaceCakeEnterPriseContactPage />,
          },
        ],
      },
    ],
  },
];
