import React, { useState, useEffect } from 'react';
import { AppSpinner } from '@companydotcom/ui';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../providers';

const SecuredRoute = () => {
  const auth0 = useAuth();
  const [called, setCalled] = useState(false);
  const { pathname, search } = useLocation();

  useEffect(() => {
    const checkSession = async () => {
      try {
        if (!called && auth0) {
          setCalled(true);
          await auth0?.checkSession(pathname, search);
        } else if (called && !auth0?.user) {
          await auth0?.refreshUser();
        }
      } catch (err) {
        console.log('Error in secured route: ', err);
      }
    };

    checkSession();
  }, [auth0, called, pathname, search]);

  if (auth0?.user) {
    return <Outlet />;
  }

  return (
    <AppSpinner
      className="dashboard-secured-route__spinner"
      containerStyles={{
        minH: '100vh',
        flexGrow: 1,
      }}
    />
  );
};

export default SecuredRoute;
