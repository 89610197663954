import React, { useState, useEffect } from 'react';
import { Text } from '@companydotcom/potion';
import { companyConstants, companyHelpers } from '@companydotcom/helpers';
import { useTranslation } from 'react-i18next';
import { useMitt } from '@companydotcom/providers';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import type { TileComponentProps } from '../../types';

dayjs.extend(timezone);

export const AppointmentText = ({ tile, product, text, appointments }: TileComponentProps) => {
  const [isFetching, setIsFetching] = useState(true);
  const [value, setValue] = useState<string>();

  const { t } = useTranslation();
  const noActiveAppointments = t('tiles.statusText.appointmentText.noActiveAppointments');
  const { emitter } = useMitt();

  useEffect(
    () => {
      if (appointments && appointments?.length && product?.productId) {
        const { elapsedAppointment, activeAppointment } = companyHelpers.getProductAppointments(
          appointments,
          product?.productId,
        );
        if (elapsedAppointment && !activeAppointment) {
          const evt = {
            tileId: tile?.tileId,
            stateCurrent: 'inactive',
            statePrevious: 'scheduled',
            productId: tile?.productId,
            product,
          };
          emitter.emit(companyConstants.platformEvents.tileNextAction, evt);
        } else if (activeAppointment && (elapsedAppointment || !elapsedAppointment)) {
          const appointment =
            activeAppointment && activeAppointment?.appointmentTime
              ? `${dayjs(Math.round(activeAppointment?.appointmentTime / 1000))
                  .tz('America/New_York')
                  .format('MMMM D[,] YYYY [at] h:mm A z')}`
              : '0';
          setValue(appointment);
          setIsFetching(false);
        }
      } else {
        setValue(noActiveAppointments);
        setIsFetching(false);
      }
    },
    // eslint-disable-next-line
    [appointments, noActiveAppointments, product, tile?.productId, tile?.tileId],
  );

  if (isFetching) {
    return null;
  }

  return (
    <>
      <Text textStyle="md">{text}</Text>
      <Text textStyle="md" fontWeight={500}>
        {value}
      </Text>
    </>
  );
};
