import { graphQL } from '@companydotcom/helpers';
import { ChildUserInput } from '@companydotcom/types';

const { fixNull } = graphQL;

const userMutation = {
  updateUserProduct: ({
    userId,
    productId,
    ratePlanId,
    paymentMethodId,
    qty,
    immediateActivate,
  }: any) => {
    const immediateActivateDefault = !!immediateActivate;
    const updateUserProduct = `mutation updateUserProduct {
  updateUserProduct(input: {
    userId: "${userId}",
    productId: "${productId}",
    ratePlanId: "${ratePlanId}",
    paymentMethodId:"${paymentMethodId}",
    quantity: ${qty},
    immediateActivate: ${immediateActivateDefault}
  })
}`;
    return updateUserProduct;
  },
  processOrderSummary: `mutation processOrder ($input: ProcessOrderInput!) {
    processOrder(input: $input) {
      requestSuccessful
      code
      errors
      payload
    }
  }`,
  setUserTileGroup: `mutation setUserTileGroup ($input: UserTileGroupInput!) {
    setUserTileGroup(input: $input) {
      userId
      groupId
      seqNo
      isHidden
      color
      tileGroup {
        name
      }
    }
  }`,
  setUserTileState: `mutation setUserTileState ($input: UserTileStateInput!) {
    setUserTileState(input: $input) {
      tileId
      userId
      productId
      productSlug
      stateCurrent
      statePrevious
      isHidden
      seqNo
    }
  }`,
  setUserTileStateLite: `mutation setUserTileStateLite ($input: UserTileStateInputLite!) {
    setUserTileStateLite(input: $input) {
      tileId
      userId
      stateCurrent
      isHidden
      seqNo
    }
  }`,
  updateUserProfile: `mutation updateUser ($input: UpdateUserInput!) {
    updateUser(input: $input) {
      userId
      firstName
      lastName
      phone
      mailbox
      userStatus
    }
  }`,
  updateUserPassword: (userId: any, password: any) => `mutation updateUserPassword {
    updateUserPassword(input: {
      auth0UserID: "auth0|${userId}",
      password: "${password}"
   })
  }`,
  createChildUser: (childUser: ChildUserInput, source: string) => `mutation createChildEmailUser{
    createChildEmailUser(
      childUser: {
        email: "${childUser.email}"
        userEmail: "${childUser.email}"
        externalIdentifier: "${childUser.externalIdentifier}"
        firstName: "${childUser.firstName}"
        lastName: "${childUser.lastName}"
        parentAccountId: "${childUser.parentAccountId}"
        role: "${childUser.role}"
    }, source: "${source}" )
   }`,
  updateChildUserProfile: ({
    userId,
    firstName,
    lastName,
    phone,
    status,
  }: any) => `mutation updateUser {
    updateUser(input: {
      userId: ${fixNull(userId)}
      firstName: ${fixNull(firstName)}
      lastName: ${fixNull(lastName)}
      phone: ${fixNull(phone)}
      userStatus: ${fixNull(status)}
    }) {
      userId
      firstName
      lastName
      phone
      userStatus
    }
  }`,
  updateUserAccountDomain: ({
    accountId,
    fqdnCompany,
    fqdnCustom,
    isEmailFQDNCustom,
    isWebFQDNCustom,
  }: any) => `mutation updateAccount {
    updateAccount(input: {
      accountId: "${accountId}"
      fqdnCompany: ${fixNull(fqdnCompany)}
      fqdnCustom: ${fixNull(fqdnCustom)}
      isEmailFQDNCustom: ${isEmailFQDNCustom}
      isWebFQDNCustom: ${isWebFQDNCustom}
    }) {
      accountId
    }
  }`,
  createDomain: ({
    fqdn,
    domainName,
    zone,
    domainType,
    address,
    isRequiredAlias,
    isRequiredMX,
  }: any) => `mutation createDomain {
    createDomain(body: {
      fqdn: ${fixNull(fqdn)}
      domainName: ${fixNull(domainName)}
      zone: ${fixNull(zone)}
      domainType: ${fixNull(domainType)}
      address: ${fixNull(address)}
      isRequiredAlias: ${isRequiredAlias}
      isRequiredMX: ${isRequiredMX}
    })
  }`,
  updateAlias: ({ fqdn, isRequiredAlias }: any) => `mutation updateAlias {
    updateAlias(body: {
      fqdn: ${fixNull(fqdn)}
      isRequiredAlias: ${isRequiredAlias}
    })
  }`,
  updateMX: ({ fqdn, isRequiredMX }: any) => `mutation updateMX {
    updateMX(body: {
      fqdn: ${fixNull(fqdn)}
      isRequiredMX: ${isRequiredMX}
    })
  }`,
  updateAccountBusiness: `mutation updateAccount ($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      accountStatus
      business {
        address {
          state
          city
          zipCode
          addressLine1
        }
        name
        numberOfEmployees
        website
        categories {
          id
          name
          fullName
          publisher
        }
      }
      dateCreated
      dateUpdated
      isEmailFQDNCustom
      isWebFQDNCustom
      fqdnCompany
      fqdnCustom
      hasFQDN
    }
  }`,
  updateAccountBusinessNoCityState: `mutation updateAccount ($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      accountStatus
      business {
        address {
          zipCode
          addressLine1
        }
        name
        categories {
          fullName
          name
          id
          publisher
        }
      }
      dateCreated
      dateUpdated
      isEmailFQDNCustom
      isWebFQDNCustom
      fqdnCompany
      fqdnCustom
      hasFQDN
    }
  }`,
  updateUserMailbox: (userId: any, mailbox: any) => `mutation updateUser {
    updateUser(input: {
      userId: ${fixNull(userId)}
      mailbox: ${fixNull(mailbox)}
    }){
      userId
      mailbox
    }
  }`,
  upsertAvatar: (dataUri: any, userId: any) => `mutation upsertAvatar {
    upsertAvatar(
      dataUri: ${fixNull(dataUri)},
      userId: ${fixNull(userId)}
    )
  }`,
  deleteAvatar: (userId: any) => `mutation deleteAvatar {
    deleteAvatar(
      userId: ${fixNull(userId)}
    )
  }`,
  activateUser: (userEmail: any, ownerEmail: any, enrolmentId: any) => `mutation activateUser{
    activateUser(userEmail: "${userEmail}", ownerEmail:"${ownerEmail}",enrollmentId: "${enrolmentId}")
  }`,
  updateLoan: () => `mutation updateAccount ($input: UpdateAccountInput!){
      updateAccount(input: $input) {
        accountId
        loans {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          monthFounded
          yearFounded
        }
        loanPrimary {
          applicationId
          yearsInBusiness
          industry
          creditEstimate
          purpose
          annualRevenue
          amountRequired
          status
          monthFounded
          yearFounded
        }
      }
  }`,
  createCalendlyAppointment: `mutation createCalendlyAppointment ($userId: String!, $productId: String!, $url: String!){
      createCalendlyAppointment(userId: $userId, productId: $productId, url: $url)
    }`,

  changeEnrollmentIdForUser: (
    enrollmentId: any,
    oldEnrollmentId: any,
  ) => `mutation changeEnrollmentIdForUser{
    changeEnrollmentIdForUser(newEnrollmentId:"${enrollmentId}",oldEnrollmentId: "${oldEnrollmentId}")
  }`,

  updateMailboxPassword: (password: any, domain: any, mailbox: any) => {
    return `mutation updateMailboxPassword{
              updateMailboxPassword(input: {
                domain:"${domain}"
                mailbox: "${mailbox}"
                password:"${password}"
            })
          }`;
  },
};

export default userMutation;
