import { useState, useRef } from 'react';
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Heading,
  ModalBody,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useBoolean,
  Button,
} from '@companydotcom/potion';
import Uppy, { UppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { useUppy } from '@uppy/react';
import { useGetGlobalUserQuery, useUpdateUserMutation } from '../../../../services/user/user-api';
import { ProfileAvatar } from '../../../../components/elements/profile-avatar/profile-avatar';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { UppyPhotoEditor } from '../../../../components/elements';
import { useGetAcgProfileImage } from '../../shared';
import {
  useUpdateAcgUserV2Mutation,
  useGetAcgUserProfileV2Query,
  useGetAcgQueryArgs,
} from '../../../../services/acg/acg-api-v2';

export const AcgAvatarUploader = () => {
  const args = useGetAcgQueryArgs();
  const { isOpen: isAvatarOpen, onOpen: onAvatarOpen, onClose: onAvatarClose } = useDisclosure();
  const { data: globalUser } = useGetGlobalUserQuery();
  const { data: acgUserProfileData } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );
  const { acgAvatarUrl } = useGetAcgProfileImage();
  const [file, setFile] = useState<UppyFile | string | undefined>(acgAvatarUrl);

  return (
    <>
      <ProfileAvatar
        src={acgAvatarUrl}
        size="2xl"
        name={`${acgUserProfileData?.firstName} ${acgUserProfileData?.lastName}`}
        bg="white"
        mb={[4, 0]}
        onClick={onAvatarOpen}
      />
      <Modal isOpen={isAvatarOpen} onClose={onAvatarClose} size="lg">
        <ModalOverlay />
        <ModalContent p="0" textAlign="center">
          <Center flexDir="column" my="12" textAlign="center">
            <Heading size="hs-xl" mb="6">
              Upload Photo
            </Heading>
            <ModalCloseButton />
            <ModalBody p="0">
              <Uploader file={file} setFile={setFile} onAvatarModalClose={onAvatarClose} />
            </ModalBody>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
};

const Uploader = ({ file, setFile, onAvatarModalClose }: any) => {
  const editor = useRef(null);
  const cancel = useRef(null);
  const [isDeleteOpen, setDeleteOpen] = useBoolean(false);
  const args = useGetAcgQueryArgs();
  const updateUserArgs = useGetAcgQueryArgs(['email', 'source']);
  const { data: globalUser } = useGetGlobalUserQuery();
  const { data: acgUserProfileData, isLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );
  const [updateUser, { isLoading: isUserUpdating }] = useUpdateUserMutation();
  const [updateAcgUser, { isLoading: isUpdating }] = useUpdateAcgUserV2Mutation();
  const { query } = useGetAcgProfileImage();

  const getRhythmSignedUrl = async (file: UppyFile) => {
    const token = args?.accessToken;

    const query = new URLSearchParams({
      contentType: file?.type ?? 'image/jpg',
      public: 'true',
    }).toString();

    if (token) {
      const res = await fetch(
        `https://platform.api.rhythmsoftware.com/fileUploads/${process.env.REACT_APP_RHYTHM_TENANT}/signedUrlRequest/rolodex-contacts/${file?.name}?${query}`,
        {
          method: 'POST',
          headers: {
            Authorization: token,
          },
        },
      );
      const data = await res.json();
      return data;
    }
  };

  const uppy = useUppy(() => {
    return new Uppy({
      id: 'acg-avatar',
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
      },
    }).use(AwsS3, {
      async getUploadParameters(file) {
        const { url, signed_url } = await getRhythmSignedUrl(file);

        // NOTE: Need to set the url on the file metadata in order to access it in the uppy methods.
        uppy.setFileMeta(file.id, { url });

        return {
          method: 'PUT',
          url: signed_url,
          headers: {
            'Content-Type': file.type ?? '',
          },
        };
      },
    });
  });

  const onAvatarUpload = async () => {
    // @ts-ignore
    const canvas = editor.current?.getImage()?.toDataURL();
    const blob = await fetch(canvas).then(res => res.blob());

    if (blob) {
      if (file?.id) {
        // Set the file state to the cropped/rotated version
        uppy.setFileState(file?.id, {
          ...file,
          data: blob,
        });
      } else {
        uppy.addFile({
          name: 'image.jpg',
          type: blob?.type,
          data: blob,
        });
      }
    }

    uppy.upload().then(async result => {
      if (result.failed.length > 0) {
        console.error('Errors:');
        result.failed.forEach(file => {
          console.error(file.error);
        });
      }

      if (result.successful.length > 0) {
        const pictureUrl = result.successful?.[0]?.meta?.url as string;

        const payload = {
          lastProfileUpdateVersion: acgUserProfileData?.lastProfileUpdateVersion ?? 1,
          profilePicture: pictureUrl,
        };
        const updatedData = await updateAcgUser({
          ...updateUserArgs,
          payload,
        }).unwrap();

        if (globalUser?.userId) {
          const avatar = updatedData?.profilePicture?.replace(
            'service://platform-file-uploads/v1',
            'https://platform.api.rhythmsoftware.com/fileUploads',
          );

          await updateUser({
            userId: globalUser?.userId,
            avatar,
          });

          setFile(`${avatar}/image?${query}`);
          onAvatarModalClose();
        }
      }
    });
  };

  const onAvatarDelete = async () => {
    const payload = {
      lastProfileUpdateVersion: acgUserProfileData?.lastProfileUpdateVersion ?? 1,
      profilePicture: '',
    };

    if (globalUser?.userId) {
      await updateAcgUser({
        ...updateUserArgs,
        payload,
      });

      await updateUser({
        userId: globalUser?.userId,
        avatar: '',
      });

      setFile(undefined);
      setDeleteOpen.off();
    }
  };

  return (
    <>
      <UppyPhotoEditor
        file={file}
        setFile={setFile}
        uppy={uppy}
        uploadHandler={onAvatarUpload}
        onDeleteOpen={() => setDeleteOpen.on()}
        isLoading={isLoading || isUpdating || isUserUpdating}
        editorRef={editor}
      />
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancel}
        onClose={() => setDeleteOpen.off()}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Avatar
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancel}
                isDisabled={isLoading || isUpdating || isUserUpdating}
                onClick={() => setDeleteOpen.off()}
              >
                Cancel
              </Button>
              <Button
                isLoading={isLoading || isUpdating || isUserUpdating}
                colorScheme="red"
                onClick={async () => await onAvatarDelete()}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
