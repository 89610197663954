import React from 'react';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { SavedPaymentMethods } from '../../../features/acg/acg-saved-payment-methods';

export const SavedPaymentMethodsPage: React.FC = () => {
  return (
    <PageLayout>
      <PageLayoutContent>
        <SavedPaymentMethods />
      </PageLayoutContent>
    </PageLayout>
  );
};
