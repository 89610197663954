import React, { useMemo } from 'react';
import { Flex, TabPanel, Button, Text } from '@companydotcom/potion';
import { NavLink } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isSameMonth } from '@companydotcom/helpers';
import { Maybe, AcgRecommendedEventsDetail } from '@companydotcom/types';
import { TileCarousel } from '../tile-carousel';
import { TileSpinner } from '../tile-spinner';
import type { TileComponentProps } from '../../types';
import {
  useGetMembershipsV2Query,
  useGetAcgQueryArgs,
  useGetRecommendedEventsV2Query,
  useGetAcgUserProfileV2Query,
} from '../../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { stateOptions } from '../../../../pages/account-information/utils/state-options';
import { dataMap, unixTimeStampToReadableDate } from './utils';

export interface TileAcgRecommendedEventsProps extends TileComponentProps {}

export const TileAcgRecommendedEvents: React.FC<TileAcgRecommendedEventsProps> = () => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const { data: memberships, isLoading } = useGetMembershipsV2Query({ ...args });
  const { data: profileInfo } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  // If nonMember, use the user's primary state for recommended events
  const primaryAddress = useMemo(
    () => profileInfo?.[profileInfo?.preferredAddressType!],
    [profileInfo],
  );
  const userStateLocation = useMemo(
    () => stateOptions.find(state => state.value === primaryAddress?.state),
    [primaryAddress?.state],
  );

  const dataPayload = dataMap(userStateLocation?.value ?? '', memberships?.memberships ?? []);

  const { data: recEvents, isLoading: recEventsLoading } = useGetRecommendedEventsV2Query({
    residentState: dataPayload?.residentState,
    chapterNames: dataPayload?.chapterNames,
  });

  const viewLabels = useMemo(() => {
    if (!recEventsLoading) {
      const res =
        recEvents?.region?.[0]?.locationName === '' ||
        recEvents?.region?.[0]?.locationName === 'undefined'
          ? ['Featured']
          : ['Regional', 'Featured'];

      const chapterName = recEvents?.chapter?.flatMap(m => {
        if (typeof m?.locationName === 'string' && m?.locationEvents && m?.locationEvents.length) {
          if (m?.locationName === '') {
            return [];
          }
          if (m?.locationName === 'Member at Large') {
            return 'Global';
          }
          return m?.locationName;
        }
        return [];
      });

      return chapterName?.concat(res);
    }
  }, [recEvents, recEventsLoading]);

  const tabs = useMemo(() => {
    return viewLabels?.map((label, i) => {
      if (label) {
        if (label === 'Regional') {
          return (
            <EventList
              events={recEvents?.region?.[0]?.locationEvents}
              res={recEvents}
              variant="region"
            />
          );
        }
        if (label === 'Featured') {
          return <EventList events={recEvents?.featured} res={recEvents} variant="featured" />;
        }
        if (label === 'Global') {
          return (
            <EventList
              events={recEvents?.chapter?.[i]?.['Member at Large']}
              res={recEvents}
              variant="chapter"
            />
          );
        }
        if (memberships?.totalMembershipsFound === 0) {
          const events = recEvents?.chapter?.filter(e => e?.locationName === label);

          return (
            <EventList events={events?.[0]?.locationEvents} res={recEvents} variant="chapter" />
          );
        }
        return (
          <EventList
            events={recEvents?.chapter?.[i]?.locationEvents}
            res={recEvents}
            variant="chapter"
          />
        );
      }
    });
  }, [memberships, recEvents, viewLabels]);

  const buttonProps = {
    display: 'inline-block',
    maxWidth: 'full',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  return !isLoading && !recEventsLoading ? (
    <Flex
      flexDirection="column"
      flexGrow={1}
      width="full"
      justifyContent="center"
      alignItems="center"
    >
      <TileCarousel viewLabels={viewLabels}>
        {tabs?.map((c, k) => {
          const chapterId = c?.props?.events?.[0]?.chapterId;
          const url =
            viewLabels?.[k] === 'Featured'
              ? `${process.env.REACT_APP_ACG_BASE_URL}/events`
              : viewLabels?.[k] === 'Regional'
              ? `${process.env.REACT_APP_ACG_BASE_URL}/events?keys=&field_region_target_id=${recEvents?.region?.[0]?.locationName}`
              : `${process.env.REACT_APP_ACG_BASE_URL}/events?keys=&chapter_tid${encodeURI(
                  `[${chapterId}]`,
                )}=${chapterId}`;
          return (
            <TabPanel
              key={k}
              display="flex"
              flexDirection="column"
              width="full"
              height="100%"
              padding={0}
              alignItems="center"
              justifyContent="space-between"
            >
              {c && React.cloneElement(c)}
              <Button
                variant="tile"
                onClick={() => window.open(url)}
                size="md"
                type="submit"
                sx={buttonProps}
              >
                {`SEARCH ${
                  viewLabels?.[k] === 'Regional'
                    ? 'Region'
                    : viewLabels?.[k] === 'Featured'
                    ? 'All'
                    : viewLabels?.[k]
                }`}
              </Button>
            </TabPanel>
          );
        })}
      </TileCarousel>
    </Flex>
  ) : (
    <TileSpinner />
  );
};

const EventList: React.FC<{
  events?: Maybe<Maybe<AcgRecommendedEventsDetail>[]>;
  variant: 'chapter' | 'region' | 'featured';
  res: any;
}> = ({ events, variant, res }) => {
  return (
    <Flex flexDirection="column" maxHeight="246px" overflow="auto" width="full" px={4}>
      {events?.map((event, key) =>
        event ? (
          <EventItem
            key={key}
            event={{ ...event }}
            i={key}
            variant={variant}
            length={events?.length}
            res={res}
          />
        ) : null,
      )}
    </Flex>
  );
};

const EventItem: React.FC<{
  event: AcgRecommendedEventsDetail;
  i: number;
  variant: 'chapter' | 'region' | 'featured';
  length: number;
  res: any;
}> = ({ event: { startDate, endDate, name, url }, i, res, variant, length }) => {
  const formattedStartDate = unixTimeStampToReadableDate(startDate ?? '');
  const formattedEndDate = unixTimeStampToReadableDate(endDate ?? '');
  const sameMonth = isSameMonth(formattedStartDate, formattedEndDate);
  const chapterName = res?.chapter?.[0]?.locationName;
  function decodeHtml(html: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
  return (
    <Flex flexDirection="column" mb={i === length - 1 ? 0 : 5}>
      <Flex flexDirection="row" justifyContent="space-between">
        <Text fontSize="xs" opacity={0.7}>
          {formattedStartDate === formattedEndDate || endDate === ''
            ? formattedStartDate
            : !sameMonth
            ? `${formattedStartDate.slice(
                0,
                formattedStartDate.length - 6,
              )} - ${formattedEndDate.slice(0, formattedEndDate.length - 6)}, ${new Date(
                formattedEndDate,
              ).getFullYear()}`
            : `${formattedStartDate.slice(0, formattedStartDate.length - 6)} - ${new Date(
                formattedEndDate,
              ).getDate()}, ${new Date(formattedEndDate).getFullYear()}`}
        </Text>
      </Flex>
      <Flex flexDirection="column" alignItems="start">
        <Button
          as={NavLink}
          target="_blank"
          to={`//${url?.slice(8)}`}
          variant="link"
          mb={1.5}
          textAlign="left"
          lineHeight="tall"
        >
          {decodeHtml(name ?? '')}
        </Button>
      </Flex>
      {length >= 10 && i === length - 1 ? (
        <Text fontSize="sm" borderBottomWidth="1px" mt={6} pb={5}>
          {variant === 'chapter' &&
            `There are more events for your chapter. Please search ${chapterName} to view them.`}
          {variant === 'region' &&
            'There are more events in your region. Please search your region to view them.'}
          {variant === 'featured' &&
            'Search all events and start making the right connections today.'}
        </Text>
      ) : (
        ''
      )}
    </Flex>
  );
};
