import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link, Text, Heading, Center } from '@companydotcom/potion';
import { CircleExclamationIcon } from '@companydotcom/ui';

export const ContactSupport = () => {
  const { t } = useTranslation();
  return (
    <Center
      flexDirection="column"
      maxWidth={704}
      width="full"
      mx="auto"
      layerStyle="formContainer"
      py={[12, null, 14]}
      px={4}
    >
      <CircleExclamationIcon />
      <Heading mt={[8]} size="hs-2xl" mb={4} textAlign="center">
        {t('subpage.contactSupport.header')}
      </Heading>
      <Trans i18nKey="subpage.contactSupport.contactDetails">
        <Text maxW="md" textAlign="center" textStyle="md">
          We’re having trouble validating your account. Please contact support at
          <Link href="tel:888-486-8156">888-486-8156</Link>
          or at
          <Link as="span" href="mailto:support@company-corp.com">
            support@company-corp.com
          </Link>
          .
        </Text>
      </Trans>
    </Center>
  );
};
