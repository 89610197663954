var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Spinner, keyframes, Box, useStyleConfig, Text, } from '@companydotcom/potion';
import { isEmpty } from 'lodash';
import { Global } from '@emotion/react';
export const AppSpinner = props => {
    const { className, containerStyles, description, descriptionStyles } = props, rest = __rest(props, ["className", "containerStyles", "description", "descriptionStyles"]);
    const styles = useStyleConfig('AppSpinner', Object.assign({}, rest));
    return (_jsx(Center, Object.assign({ className: className || 'app-spinner', flexDirection: "column" }, containerStyles, { children: isEmpty(styles) ? (_jsxs(_Fragment, { children: [_jsx(UnThemedSpinner, Object.assign({ sx: styles }, rest), void 0), description && _jsx(Text, Object.assign({}, descriptionStyles, { children: description }), void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(Spinner, Object.assign({ sx: styles }, rest), void 0), description && (_jsx(Text, Object.assign({ mt: 2 }, descriptionStyles, { children: description }), void 0))] }, void 0)) }), void 0));
};
const spin = keyframes `
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
const UnThemedSpinner = () => (_jsxs(_Fragment, { children: [_jsx(Global, { styles: {
                '*': { boxSizing: 'border-box' },
                body: { margin: 0, minHeight: '100%', width: '100%' },
            } }, void 0), _jsx(Box, Object.assign({ sx: {
                display: 'inline-block',
                borderTop: '4px solid currentcolor',
                borderRight: '4px solid currentcolor',
                borderBottomStyle: 'solid',
                borderLeftStyle: 'solid',
                borderRadius: '99999px',
                borderBottomWidth: '4px',
                borderLeftWidth: '4px',
                borderBottomColor: '#E2E8F0',
                borderLeftColor: '#E2E8F0',
                animation: `0.65s linear 0s infinite normal none running ${spin}`,
                width: '3rem',
                height: '3rem',
                color: '#A0AEC0',
            } }, { children: _jsx(Box, Object.assign({ as: "span", sx: {
                    border: '0px',
                    clip: 'rect(0px, 0px, 0px, 0px)',
                    height: '1px',
                    width: '1px',
                    margin: '-1px',
                    padding: '0px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    position: 'absolute',
                } }, { children: "Loading..." }), void 0) }), void 0)] }, void 0));
