import React, { useState } from 'react';
import { Flex, Text, Box, Button, Heading } from '@companydotcom/potion';
import { InputField } from '@companydotcom/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { User } from '@companydotcom/types';
import { useTranslation } from 'react-i18next';
import { useUserProfileContext } from '../../../providers/user-profile-context';
import { allocateChildUserSeat } from '../../../pages/user-profile/utils';
import { useUpdateUserMutation, useLazyGetUserTilesQuery } from '../../../services/user/user-api';
import { useLazyPublishTileEventQuery } from '../../../services/event/event-api';

const getCreateAUsernameStepSchema = () =>
  yup.object().shape({
    username: yup.string().min(2).required('common.misc.allFieldsRequired'),
  });

interface CreateAUsernameStepProps {
  user?: User;
  goToStep?: (arg: string | number) => void;
  locale?: string;
}

export const CreateAUsernameStep = ({ user, goToStep, locale }: CreateAUsernameStepProps) => {
  const [publishTileEvent] = useLazyPublishTileEventQuery();
  const [updateUser] = useUpdateUserMutation();
  const [getUserTiles] = useLazyGetUserTilesQuery();
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getCreateAUsernameStepSchema()),
    defaultValues: {
      username: userProfileState?.newUserInformation?.firstName,
    },
  });
  const { t } = useTranslation();
  const watchUsername = watch('username');

  const onSubmit = async (data: { username?: string }) => {
    // If we are just granting an existing user access to email, do it here, otherwise go to loading step
    // to handle creating the user and granting access there
    if (
      userProfileState?.productIntention &&
      userProfileState.existingChildUser?.userId &&
      user?.account
    ) {
      setIsLoading(true);

      const { data: userTiles } = await getUserTiles({
        userId: userProfileState.existingChildUser?.userId,
        locale,
      });

      const tileIdFromProductId = userTiles?.find(
        tile => tile.productId === userProfileState.productIntention?.product.productId,
      )?.tileId;

      if (tileIdFromProductId && !isEmpty(userProfileState.existingChildUser)) {
        await updateUser({
          userId: userProfileState.existingChildUser?.userId,
          mailbox: data.username,
        });

        await allocateChildUserSeat(
          {
            ...userProfileState.existingChildUser,
            mailbox: data.username,
            mailboxFQDN: user?.mailboxFQDN,
          } as User,
          tileIdFromProductId,
          user?.account,
          publishTileEvent,
        );

        dispatchUserProfileState?.({
          type: 'UPDATE_FILTERED_USER',
          payload: {
            ...userProfileState.existingChildUser,
            mailbox: data.username,
            mailboxFQDN: user?.mailboxFQDN,
          } as User,
        });

        if (userProfileState?.chosenProducts?.email_rackspace) {
          dispatchUserProfileState?.({
            type: 'DECREASE_AVAILABLE_SEAT_COUNT',
            payload: { qty: 1 },
          });
        }
        setIsLoading(false);
        userProfileState.onManageSeatSuccess?.();
        goToStep?.('new-user-added');
      }
    } else {
      dispatchUserProfileState?.({ type: 'UPDATE_STATE', payload: { username: data.username } });
      setIsLoading(false);
      goToStep?.('loading');
    }
  };

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" pt={14} pb={16} px={4}>
      <Box maxWidth={[310, 455]} sx={{ textAlign: 'center' }}>
        <Heading size="hs-2xl">{t('miop.userProfile.addNewUser.header')}</Heading>
        <Text textStyle="2xl" mt={4}>
          {`${
            userProfileState?.initialNewUserStep === 'create-a-username'
              ? ''
              : t('miop.userProfile.addNewUser.createUsername.cta')
          } ${t('miop.userProfile.addNewUser.createUsername.cta')}`}
        </Text>
        <Text textStyle="md" mt={2}>
          {t('miop.userProfile.addNewUser.createUsername.copy')}
        </Text>
        <Text mt={6}>
          <Button variant="link" userSelect="none" as="span">
            {watchUsername}
          </Button>
          @{user?.account?.fqdnCompany || user?.account?.fqdnCustom}
        </Text>
      </Box>
      <Flex as="form" flexDirection="column" width="full" maxWidth={386} mt={6}>
        <InputField
          register={register}
          name="username"
          autoFocus
          label="Username"
          errors={errors}
        />

        <Box mt={10} sx={{ textAlign: 'center' }}>
          <Button
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting || isLoading}
            isDisabled={!isValid || isSubmitting || !isEmpty(errors) || isLoading}
          >
            {t('miop.userProfile.addNewUser.createUsername.cta')}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
