import { useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Text,
  VStack,
  Stack,
  FormLabel,
  FormControl,
  Flex,
  Divider,
  Skeleton,
  AlertDescription,
  UseTabStepperReturn,
} from '@companydotcom/potion';
import { InputField, TextareaField } from '@companydotcom/ui';
import type { Maybe } from '@companydotcom/types';
import { getDirtyValues } from '@companydotcom/helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MyEventsSelectedItemsList } from './my-events-selected-item-list';
import { useUpdateRegisteredEventMutation } from '../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { RegisteredEvent, CustomQuestion, ADAFields } from '../../../../services/acg/acg.types';
import {
  eventsFormSliceMapper,
  formatQuestionsForYup,
  formatAnswersForDispatch,
  getCustomQuestionsSchema,
  filterOutAdaFields,
} from '../../shared';
import { MyEventsDemographicQuestions } from './my-events-demographic-questions';
import { updateGuestToRegister } from '../../acg-add-guest/add-guest-slice';
import { useAppDispatch } from '../../../../hooks';

const createYupSchema = (data?: CustomQuestion[]) => {
  const answersSchema = getCustomQuestionsSchema(data);

  return yup.object().shape({
    badge: yup.object().shape({
      firstName: yup.string().required('Please enter a Badge First Name'),
      organizationName: yup.string().required('Please enter an Organization'),
      title: yup.string().required('Please enter a Title'),
    }),
    customQuestions: yup.object().shape(answersSchema),
  });
};

export interface MyEventsEditGuestListFormProps
  extends Partial<
      Pick<
        RegisteredEvent,
        | 'registrationId'
        | 'registrantName'
        | 'organizationName'
        | 'badgeName'
        | 'qAndA'
        | 'registrantType'
        | 'invoiceDetails'
        | 'onWaitList'
      >
    >,
    Partial<Pick<UseTabStepperReturn, 'previousStep' | 'goToStep'>> {
  variant: 'multiple' | 'single';
  shouldShowGuestButton?: boolean;
  isGuest?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  title?: string;
  isUnregisteredGuest?: boolean;
  waitListLimit?: string;
  totalWaitList?: string;
  individualType?: Maybe<string>;
  customQuestions?: CustomQuestion[];
  emailAddress?: string;
}

export const MyEventsEditGuestListForm = ({
  goToStep,
  variant,
  previousStep,
  isLoading,
  isSuccess,
  isError,
  registrantName,
  organizationName,
  badgeName,
  title,
  qAndA,
  registrationId,
  registrantType,
  individualType,
  invoiceDetails,
  shouldShowGuestButton,
  isUnregisteredGuest,
  emailAddress,
  isGuest,
  onWaitList,
  waitListLimit,
  totalWaitList,
  customQuestions,
}: MyEventsEditGuestListFormProps) => {
  const dispatch = useAppDispatch();
  const [pageError, setPageError] = useState(false);
  const { data: globalUser } = useGetGlobalUserQuery();
  const [updateRegisteredEvent] = useUpdateRegisteredEventMutation();

  const defaultValues = {
    badge: {
      firstName: badgeName ?? '',
      organizationName: organizationName ?? '',
      title: title ?? '',
    },
    customQuestions: { ...formatQuestionsForYup(qAndA) },
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting, isValid, dirtyFields, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(createYupSchema(customQuestions)),
    defaultValues,
  });
  const watchAllFields = watch();

  useEffect(() => {
    if (isSuccess) {
      reset({
        badge: {
          firstName: badgeName,
          organizationName: organizationName,
          title: title,
        },
        customQuestions: {
          ...formatQuestionsForYup(qAndA),
        },
      });
    }
  }, [badgeName, isSuccess, organizationName, qAndA, reset, title]);

  const saveSubmitHandler = async (data: typeof defaultValues) => {
    try {
      const dirtyValues: Partial<typeof defaultValues> = getDirtyValues(dirtyFields, data);
      // If the guest we are updating isn't registered yet and only lives in state, we just want to update local state
      if (isUnregisteredGuest && emailAddress) {
        dispatch(updateGuestToRegister({ emailAddress, badge: { ...dirtyValues.badge } }));
      } else if (globalUser && registrationId) {
        const payload = {
          registrationId: registrationId,
          badge: dirtyValues.badge,
          customQuestions: { ...formatAnswersForDispatch(data.customQuestions) },
        };

        await updateRegisteredEvent({
          user: globalUser,
          payload,
        });
        previousStep?.();
      }
    } catch (err) {
      console.log('Error updating registered event', err);
      setPageError(true);
    }
  };

  const addGuestSubmitHandler = () => {
    goToStep?.('my-events-add-guest');
  };

  return (
    <Box
      as="form"
      className="edit-guest-list-form"
      maxW={908}
      borderTopWidth={variant === 'multiple' ? '1px' : '0px'}
      pt={4}
    >
      {pageError && variant === 'single' && (
        <Alert status="error" variant="solid" mb={[8, 12]}>
          <AlertIcon />
          {/* // TODO: Add error message */}
          Error
        </Alert>
      )}
      {isGuest && onWaitList && !isLoading ? (
        <Alert status="warning" my={5}>
          <AlertIcon />
          <AlertDescription fontSize="sm" lineHeight="5">
            <Text fontWeight="bold">Your guest is on the Waitlist for this Event</Text>
            <Text>
              If they’re moved off the waitlist, they will be notified of this change and you will
              be charged for their registration.
            </Text>
            {waitListLimit !== null && (
              <Text fontWeight="bold" fontStyle="italic">
                {`Currently, there are ${totalWaitList} of ${waitListLimit} people on the waitlist.`}
              </Text>
            )}
          </AlertDescription>
        </Alert>
      ) : null}
      <Stack
        direction={['column', null, 'row']}
        align="flex-start"
        width="full"
        spacing={[6, null, 8, 12]}
      >
        <VStack width="full" spacing={6} alignItems="flex-start">
          <Text
            lineHeight={1.25}
            textStyle={['lg', variant === 'single' ? '3xl' : 'xl']}
            fontWeight="bold"
            opacity={0.7}
          >
            Registrant Information
          </Text>
          <FormControl>
            <FormLabel>Registrant Name:</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <Text fontWeight={600}>
                {isLoading ? '&#32&#32&#32&#32&#32' : isError ? '' : registrantName || ''}
              </Text>
            </Skeleton>
          </FormControl>
          <FormControl>
            <FormLabel>Registrant Type:</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <Text fontWeight={600}>
                {isLoading ? '&#32&#32&#32&#32&#32' : isError ? '' : registrantType || ''}
              </Text>
            </Skeleton>
          </FormControl>
          <FormControl>
            <FormLabel>Function/Role:</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <Text fontWeight={600}>
                {isLoading ? '&#32&#32&#32&#32&#32' : isError ? '' : individualType || ''}
              </Text>
            </Skeleton>
          </FormControl>

          {!isGuest && customQuestions?.some(q => q.questionId === ADAFields.ADA) && (
            <TextareaField
              register={register}
              name={`customQuestions.${ADAFields.ADA}`}
              label="ADA Requirements"
              errors={errors}
              isLoading={isLoading}
            />
          )}
          {!isGuest && customQuestions?.some(q => q.questionId === ADAFields.DIETARY) && (
            <TextareaField
              register={register}
              name={`customQuestions.${ADAFields.DIETARY}`}
              label="Dietary Restrictions"
              errors={errors}
              isLoading={isLoading}
            />
          )}
        </VStack>
        <VStack width="full" spacing={6} alignItems="flex-start">
          <Text
            lineHeight={1.25}
            textStyle={['lg', variant === 'single' ? '3xl' : 'xl']}
            fontWeight="bold"
            opacity={0.7}
          >
            Badge Information
          </Text>
          <InputField
            isLoading={isLoading}
            register={register}
            label="Badge First Name"
            name="badge.firstName"
            errors={errors}
            helperText="Required"
          />
          <InputField
            isLoading={isLoading}
            register={register}
            name="badge.organizationName"
            label="Organization"
            helperText="Required"
            errors={errors}
          />
          <InputField
            isLoading={isLoading}
            register={register}
            label="Title"
            name="badge.title"
            errors={errors}
            helperText="Required"
          />
        </VStack>
      </Stack>
      {customQuestions && filterOutAdaFields(customQuestions)?.length > 0 && (
        <MyEventsDemographicQuestions
          variant={variant}
          register={register}
          errors={errors}
          control={control}
          data={eventsFormSliceMapper(customQuestions, 'customQuestions')}
          watchAllFields={watchAllFields}
          setValue={setValue}
        />
      )}
      <Divider pt={16} />
      {invoiceDetails && <MyEventsSelectedItemsList purchased data={invoiceDetails} mt={12} />}

      <Flex flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
        <Button
          size="lg"
          type="submit"
          isLoading={isLoading || isSubmitting}
          isDisabled={!isValid || !isDirty}
          onClick={handleSubmit(saveSubmitHandler)}
          maxW="196px"
        >
          {variant === 'multiple' ? 'Save' : 'Save And Back'}
        </Button>
        {variant === 'single' && shouldShowGuestButton && (
          <Button
            className="myEventsEditGuestListForm__addGuestButton"
            data-test="myEventsEditGuestListForm__addGuestButton"
            isLoading={isLoading}
            isDisabled={isSubmitting}
            onClick={addGuestSubmitHandler}
            size="sm"
            variant="ghost"
            maxW="196px"
            mt={6}
            mb={2}
          >
            ADD GUEST
          </Button>
        )}
      </Flex>
    </Box>
  );
};
