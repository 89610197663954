import React from 'react';
import { Flex } from '@companydotcom/potion';
import { VerifyCode } from '../../../pages/subpages/verify-code/verify-code';

export const VerifyCodeWrapper = ({ goToStep, verificationType, setIsBack, email }: any) => {
  return (
    <Flex className="verify-code-wrapper" maxWidth={704} width="full" py={14} mx="auto" flex={[1]}>
      <VerifyCode
        isEmailVerification={verificationType === 'email-verification'}
        handleResend={() => {
          setIsBack(true);
          goToStep(verificationType);
        }}
        email={email}
        secondVerification
      />
    </Flex>
  );
};
