import React, { useState, useEffect } from 'react';
import { Maybe, AcgInvoice } from '@companydotcom/types';
import { Tabs, TabPanels, TabPanel, useTabStepper } from '@companydotcom/potion';
import { useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { InvoicesList } from './invoices-list';
import { InvoicesDetails } from './invoices-details';
import { BackButtonLayout } from '../../../../components/layouts';
import { useDownloadInvoiceQuery } from '../../../../services/acg/acg-api';
import {
  useGetAcgQueryArgs,
  useGetInvoicesByUserV2Query,
} from '../../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

const invoicesSteps = [
  {
    slug: 'invoices-list',
    component: <InvoicesList />,
  },
  {
    slug: 'invoices-details',
    component: <InvoicesDetails />,
  },
];

// TODO: retype invoices
export interface InvoicesSharedProps {
  nextStep?: () => void;
  previousStep?: (arg0: number | string) => void;
  invoiceData?: any;
  isLoading?: boolean;
  currentInvoice?: any;
  setCurrentInvoice?: (arg: any | {}) => void;
  currentDownloadUrl?: { downloadUrl: string };
  downloadUrlLoading?: boolean;
}

export interface LocationStateProps {
  step: 'invoices-list' | 'invoices-details';
  openInvoices: {
    invoiceKey: string;
    invoiceNumber: string;
  };
}

// TODO: retype this
export const Invoices = () => {
  const [currentInvoice, setCurrentInvoice] = useState<any>({});

  const { tabIndex, handleTabsChange, nextStep, previousStep, goToStep } = useTabStepper({
    steps: invoicesSteps,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationStateProps;

  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();

  const { data: invoices, isLoading } = useGetInvoicesByUserV2Query({ ...args });

  const { data: currentDownloadUrl, isLoading: downloadUrlLoading } = useDownloadInvoiceQuery(
    globalUser && currentInvoice
      ? {
          user: globalUser,
          invoiceId: currentInvoice.invoiceKey,
        }
      : skipToken,
  );

  const invoiceData = invoices?.invoices;

  useEffect(() => {
    if (
      invoiceData &&
      invoiceData?.length &&
      state?.step === 'invoices-details' &&
      (state?.openInvoices?.invoiceKey || state?.openInvoices?.invoiceNumber)
    ) {
      const openInvoice = invoiceData?.find(
        (invoice: Maybe<AcgInvoice>) =>
          invoice?.invoiceKey === state?.openInvoices?.invoiceKey ||
          invoice?.invoiceKey === state?.openInvoices?.invoiceNumber,
      );
      setCurrentInvoice(openInvoice);
      goToStep?.(state?.step);
    }
  }, [
    goToStep,
    invoiceData,
    state?.openInvoices?.invoiceNumber,
    state?.openInvoices?.invoiceKey,
    state?.step,
  ]);

  const sharedProps = {
    nextStep,
    previousStep,
    invoiceData,
    isLoading,
    currentInvoice,
    setCurrentInvoice,
    currentDownloadUrl,
    downloadUrlLoading,
  };

  return (
    <BackButtonLayout
      isDisplayed={tabIndex > 0}
      onBackClick={() => (state?.step ? navigate(-1) : previousStep?.())}
    >
      <Tabs isLazy variant="page" index={tabIndex} onChange={handleTabsChange}>
        <TabPanels>
          {invoicesSteps.map(step => (
            <TabPanel key={step.slug}>{React.cloneElement(step.component, sharedProps)}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </BackButtonLayout>
  );
};
