import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, Text, Heading, Button, Center } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { Link as NLink } from 'react-router-dom';
import { CircleExclamationIcon } from '../icons';
export const Error = (props) => {
    const { t } = useTranslation();
    if (!props || props.sourceId !== 'company') {
        return (_jsxs(Center, Object.assign({ flexDirection: "column", width: "full", py: [8, 12], px: 4 }, { children: [_jsx(CircleExclamationIcon, {}, void 0), _jsx(Heading, Object.assign({ as: "h1", size: "hs-xl" }, { children: t('components.error.title') }), void 0), _jsxs(Text, Object.assign({ textStyle: "md", mt: 4 }, { children: [t('components.error.callSupport'), _jsx(Button, Object.assign({ variant: "link", userSelect: "none", whiteSpace: "normal" }, { children: "888-486-8156." }), void 0)] }), void 0)] }), void 0));
    }
    return (_jsxs(Center, Object.assign({ flexDirection: "column", width: "full", py: [8, 12], px: 4 }, { children: [_jsx(CircleExclamationIcon, {}, void 0), _jsx(Heading, Object.assign({ as: "h1", size: "hs-xl" }, { children: t('components.error.title') }), void 0), _jsxs(Text, Object.assign({ textStyle: "md", mt: 4 }, { children: [t('components.error.somethingWrong'), _jsxs(Link, Object.assign({ as: NLink, to: "/contact-us", state: {
                            from: 'error',
                            sourceId: `${props.sourceId}`,
                            userId: `${props.userId}`,
                        }, isExternal: true }, { children: [t('components.error.contactSupport'), ' '] }), void 0), t('components.error.additionalHelp')] }), void 0)] }), void 0));
};
