import * as React from 'react';
import {
  potion,
  forwardRef,
  omitThemingProps,
  ThemingProps,
  useStyleConfig,
  HTMLPotionProps,
} from '../../system';
import { cx, __DEV__ } from '../../utils';

export interface BadgeProps extends HTMLPotionProps<'span'>, ThemingProps<'Badge'> {}

/**
 * React component used to display notifications, messages, or
 * statuses in different shapes and sizes.
 */
export const Badge = forwardRef<BadgeProps, 'span'>((props, ref) => {
  const styles = useStyleConfig('Badge', props);
  const { className, ...rest } = omitThemingProps(props);

  return (
    <potion.span
      ref={ref}
      className={cx('potion-badge', props.className)}
      {...rest}
      __css={{
        display: 'inline-block',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        ...styles,
      }}
    />
  );
});

if (__DEV__) {
  Badge.displayName = 'Badge';
}
