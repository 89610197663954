var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { __DEV__, FormControl, FormLabel, FormHelperText, Textarea, Skeleton, } from '@companydotcom/potion';
import { get } from 'lodash';
import { TranslationErrorMessage } from './translation-error-message';
export const TextareaField = props => {
    var _a;
    const { errors, register, name, label, helperText, control, isLoading, formControlStyles } = props, rest = __rest(props, ["errors", "register", "name", "label", "helperText", "control", "isLoading", "formControlStyles"]);
    return (_jsxs(FormControl, Object.assign({ id: name, isInvalid: errors && !!get(errors, name), sx: formControlStyles }, { children: [_jsx(FormLabel, Object.assign({ htmlFor: name }, { children: label !== null && label !== void 0 ? label : '' }), void 0), _jsx(Skeleton, Object.assign({ isLoaded: !isLoading }, { children: _jsx(Textarea, Object.assign({}, register(name), { "data-test": `textarea-${name}` }, rest), void 0) }), void 0), helperText && !get(errors, name) && _jsx(FormHelperText, { children: helperText !== null && helperText !== void 0 ? helperText : '' }, void 0), errors && (_jsx(TranslationErrorMessage, Object.assign({ errors: errors, name: name }, { children: errors && get(errors, name) && ((_a = get(errors, name)) === null || _a === void 0 ? void 0 : _a.message) }), void 0))] }), void 0));
};
if (__DEV__) {
    TextareaField.displayName = 'TextareaField';
}
