import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Center, Box, Heading, Text, Button, useMediaQuery } from '@companydotcom/potion';
import type { CheckoutSharedProps } from '..';
import { useAcgCartStorage } from '../../shared';
import { Trans, useTranslation } from 'react-i18next';

export const CheckoutOrderInternational: React.FC<CheckoutSharedProps> = props => {
  const { onOrderConfirmationComplete } = props;
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const webCode = searchParams.get('WebCode')?.toLowerCase();
  const { clearLocalCart, getLocalCart } = useAcgCartStorage();
  const chapter = getLocalCart()?.items?.memberships?.[0];
  const { t } = useTranslation();

  const goToDashboard = () => {
    onOrderConfirmationComplete?.();
    clearLocalCart();
    if (search && !search.includes('confirmation')) {
      navigate({ pathname: '/redirect', search });
    } else {
      navigate('/', { replace: true });
    }
  };

  const goToRedirect = () => {
    onOrderConfirmationComplete?.();
    clearLocalCart();
    if (
      webCode === 'eventregfees' ||
      webCode === 'acgmembershipdirectory' ||
      webCode === 'acgmemberdirectory'
    ) {
      navigate({ pathname: '/redirect', search });
    } else {
      navigate('/', { replace: true });
    }
  };

  useEffect(() => {
    const params = { confirmation: 'membership-international' };

    if (search && params) {
      setSearchParams({ ...Object.fromEntries([...searchParams]), ...params });
    } else {
      setSearchParams(params);
    }
  }, [onOrderConfirmationComplete, search, searchParams, setSearchParams]);

  return (
    <Center
      className="checkout__checkoutOrderInternationalPendingStep"
      flexDir="column"
      bg={['transparent', 'white']}
      px={[0, 4]}
    >
      <Box width="full" maxW={608} textAlign="center">
        <Heading size={isMobile ? 'hs-md' : 'hs-2xl'}>
          {t('acg.checkout.orderInternationalStep.header', {
            internationalChapter: chapter?.lineItems?.[0]?.name,
          })}
        </Heading>
        <Text textStyle="lg" fontWeight="semibold" mb={5} mt={12}>
          {t('acg.checkout.orderInternationalStep.requestSubmitted', {
            chapter: chapter?.chapterName,
          })}
        </Text>
        <Text textStyle="lg" fontWeight="semibold">
          <Trans i18nKey="acg.checkout.orderInternationalStep.goToDashboard">
            You can go to your
            <Button
              fontWeight="semibold"
              size="lg"
              whiteSpace="normal"
              variant="link"
              onClick={goToDashboard}
            >
              My ACG Dashboard
            </Button>
            and access all features as a chapter member.
          </Trans>
        </Text>
        <Center mt={[6, 8]} textAlign="center" flexDir="column" width="full">
          <Box id="goToMyACGButton" alignSelf="center" pt={4}>
            <Button
              size="lg"
              onClick={
                webCode === 'eventregfees' ||
                webCode === 'acgmembershipdirectory' ||
                webCode === 'acgmemberdirectory'
                  ? goToRedirect
                  : goToDashboard
              }
            >
              {webCode === 'eventregfees' ? 'GO TO EVENT REGISTRATION' : 'GO TO MY ACG'}
            </Button>
          </Box>
        </Center>
      </Box>
    </Center>
  );
};
