import { ComponentStyleConfig } from '@companydotcom/potion';

const parts = ['divider', 'subtitle', 'price'];

const baseStyle = {
  divider: {
    width: 5,
    height: '1px',
    opacity: 0.3,
    borderBottom: '1px solid',
    borderColor: 'gray.700',
    mx: 'auto',
    mb: 2,
  },
  subtitle: { mb: 2 },
  price: { mb: 2, color: 'pricing' },
};

export default {
  parts,
  baseStyle,
} as ComponentStyleConfig;
