import {
  Stack,
  Flex,
  Text,
  Button,
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
} from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';

export const EventFullAlert = ({
  variant,
  cancelOrderHandler,
  isCancelled,
  setIsCancelled,
  mt,
  mb,
  width,
}: {
  variant: 'eventAlreadyFull' | 'eventOverCapacity';
  mt?: string | number;
  mb?: string | number;
  width?: string;
  cancelOrderHandler?: () => void;
  isCancelled?: boolean;
  setIsCancelled?: (x: boolean) => void;
}) => {
  const navigate = useNavigate();

  if (isCancelled) {
    return (
      <Flex
        justify="center"
        align="center"
        borderWidth="1px"
        flexDir="column"
        width={width || 'inherit'}
        p={['4', null, '7']}
        mt={12}
        textAlign="center"
      >
        <Text
          textStyle={{ base: 'xl', md: '3xl' }}
          textAlign="center"
          fontWeight="bold"
          opacity="0.7"
        >
          You have canceled this event registration.
        </Text>

        <Button size="lg" mt="12" onClick={() => navigate('/')}>
          Back To My ACG
        </Button>

        <Button
          variant="ghost"
          size="sm"
          mt="4"
          onClick={() => window.open(`${process.env.REACT_APP_ACG_BASE_URL}/events`)}
        >
          SEARCH FOR ANOTHER EVENT
        </Button>
      </Flex>
    );
  }

  return (
    <Alert
      status="error"
      variant="solid"
      bgColor="red.700"
      mt={typeof mt !== 'undefined' ? mt : '12'}
      mb={mb}
    >
      <AlertIcon />
      <Stack>
        <AlertTitle>
          {variant === 'eventOverCapacity'
            ? 'This event will be full after you register.'
            : 'This event is now full.'}
        </AlertTitle>
        <AlertDescription>
          {variant === 'eventOverCapacity'
            ? 'The event has reached total capacity since you began your registration. You can register, but unfortunately you can no longer register your guests. Their packages and sessions have been removed from your cart.'
            : 'The event has reached total capacity since you began your registration. We’re sorry for the inconvenience.'}
        </AlertDescription>
        {cancelOrderHandler && (
          <Box>
            <Button
              bgColor="white"
              color="red.700"
              px={[7]}
              size="sm"
              variant="ghost"
              onClick={() => {
                cancelOrderHandler();
                setIsCancelled?.(true);
              }}
            >
              Cancel Order
            </Button>
          </Box>
        )}
      </Stack>
    </Alert>
  );
};
