export const getLogos = (socialMediaName?: string) => {
  switch (socialMediaName) {
    case 'Facebook':
      return `${process.env.REACT_APP_IMG_URL}/soci-vendor-logos/facebook-copy-2.png`;
    case 'Twitter':
      return `${process.env.REACT_APP_IMG_URL}/soci-vendor-logos/twitter-copy.png`;
    case 'Instagram':
      return `${process.env.REACT_APP_IMG_URL}/soci-vendor-logos/instagram-copy.png`;
    case 'Linkedin':
      return `${process.env.REACT_APP_IMG_URL}/soci-vendor-logos/linked-in-icon-copy.png`;
    case 'GMB':
      return `${process.env.REACT_APP_IMG_URL}/soci-vendor-logos/gmb-logo.png`;
    default:
      return '';
  }
};
