import { Flex, Box, Text, Button, Heading, VStack } from '@companydotcom/potion';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import {
  InputField,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { useGetGlobalUserQuery, useUpdateUserMutation } from '../../../../services/user/user-api';
import yup from '../../../../lib/yup';

const getConfirmContactInfoSchema = () =>
  yup.object().shape({
    email: yup.string().required('common.inputs.email.error'),
    firstName: yup.string().required('common.inputs.firstName.error'),
    lastName: yup.string().required('common.inputs.lastName.error'),
    phone: yup.string().phone().nullable().required('common.inputs.phoneNumber.error'),
  });

export const ConfirmContactInfo = ({ selectedApptTimestamp, submitUserChoice, nextStep }: any) => {
  const [updateUser] = useUpdateUserMutation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const { t } = useTranslation();
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();

  const handleConfirmContact = () => {
    submitUserChoice(selectedApptTimestamp);
    nextStep();
  };

  const defaultValues: FieldValues = {
    firstName: globalUser?.firstName || '',
    lastName: globalUser?.lastName || '',
    phone: globalUser?.phone ? parsePhoneNumber(globalUser?.phone, country).number : '',
    email: globalUser?.email || '',
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getConfirmContactInfoSchema()),
    defaultValues,
    context: { country },
  });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      await updateUser({
        userId: globalUser?.userId as string,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: getFormattedPhoneNumber(values.phone, country ?? 'US'),
        email: values.email,
      });
      handleConfirmContact();
    } catch (err) {
      console.log('Error!: ', err);
    }
  };

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" width="full" py={14}>
      <Box textAlign="center" maxWidth="xl">
        <Heading size="hs-xl">{t('forms.confirmContactInfo.heading')}</Heading>
        <Text textStyle="lg" mt={5}>
          {t('forms.confirmContactInfo.subheading')}
        </Text>
        <Text textStyle="md">{t('common.misc.allFieldsRequired')}</Text>
      </Box>

      <Flex
        as="form"
        flexDirection="column"
        width="100%"
        mt={14}
        alignItems="center"
        justifyContent="flex-start"
      >
        <VStack spacing={6} width="full" maxWidth="md">
          <InputField
            register={register}
            name="firstName"
            label={t('common.inputs.firstName.label')}
            errors={errors}
          />
          <InputField
            register={register}
            name="lastName"
            label={t('common.inputs.lastName.label')}
            errors={errors}
          />

          <PhoneNumberInputField
            name="phone"
            label={t('common.inputs.phoneNumber.label')}
            className="form-confirmContactInfo__phoneField"
            data-test="form-confirmContactInfo__phoneField"
            control={control}
            country={country}
            onCountryChange={onCountryChange}
            errors={errors}
          />

          <InputField
            register={register}
            name="email"
            label={t('common.inputs.email.label')}
            errors={errors}
          />
        </VStack>

        <Box mx="auto" mt={[5, 14]}>
          <Button
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
          >
            {t('forms.confirmContactInfo.formButton')}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
