import AppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { MasterAuthInterface } from '../../providers';

const appSyncClient = new AppSyncClient({
  url: process.env.REACT_APP_COMPANYQL_URL as string,
  region: process.env.REACT_APP_REGION as string,
  auth: {
    type: 'OPENID_CONNECT',
    jwtToken: async () => MasterAuthInterface.getCore().then(auth => auth.getTokenSilently()),
  },
  disableOffline: true,
});

export const client = new AppSyncClient({
  url: process.env.REACT_APP_COMPANYQL_URL ?? '',
  region: process.env.REACT_APP_REGION ?? '',
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: process.env.REACT_APP_COMPANYQL_API_KEY ?? '',
  },
  disableOffline: true,
});

export default appSyncClient;
