import React from 'react';
import {
  Container,
  Alert,
  AlertTitle,
  AlertDescription,
  VStack,
  Text,
  StackProps,
  Heading,
  useBreakpointValue,
  Grid,
  GridItem,
  Flex,
  Spacer,
  LinkBox,
  LinkOverlay,
  useBoolean,
} from '@companydotcom/potion';
import { FontAwesomeIcon, PageDivider } from '@companydotcom/ui';
import { faRepeat, faFileInvoice, faLock } from '@fortawesome/pro-thin-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ManageFaceCakeTileStatus } from '../facecake-tile-status/manange-tile-status';

export const FaceCakeAccountManagement = () => {
  const { t } = useTranslation();
  const headingSize = useBreakpointValue({ base: 'hs-2xl', xl: 'hs-3xl' });
  const cardOrder = useBreakpointValue({ base: undefined, xl: 4 });

  // TODO: Replace this with show asset alert logic in wireup ticky
  const [showAssetsAlert, setShowAssetsAlert] = useBoolean();

  const navigationItems = [
    {
      link: '/facecake/plan-management',
      title: t('icommerce.accountManagement.planManagement'),
      icon: faRepeat,
    },
    {
      link: '/facecake/invoices',
      title: t('icommerce.accountManagement.invoices'),
      icon: faFileInvoice,
    },
    {
      link: '/facecake/password-management',
      title: t('icommerce.accountManagement.passwordManagement'),
      icon: faLock,
    },
  ];

  return (
    <Container maxW={['100rem']} centerContent px={['4', '8']} pt={['6', '12']} pb="5rem">
      <Heading alignSelf="flex-start" size={headingSize}>
        {t('icommerce.accountManagement.heading')}
      </Heading>
      <PageDivider width="50px" mt="4" alignSelf="flex-start" />

      <Grid
        templateRows={{
          base: undefined,
          lg: `repeat(2, 1fr)`,
          xl: `repeat(${showAssetsAlert ? 2 : 1}, 1fr)`,
        }}
        templateColumns={{
          base: `repeat(${showAssetsAlert ? 12 : 20}, minmax(0, 1fr))`,
        }}
        mt="6"
        gap={{ base: '4', md: '6', lg: '8' }}
      >
        {showAssetsAlert && (
          <GridItem rowSpan={{ base: 0, lg: 1 }} colSpan={{ base: 12, lg: 8 }}>
            <AssetsAlert />
          </GridItem>
        )}
        <GridItem
          rowSpan={showAssetsAlert ? { base: 0, lg: 1 } : undefined}
          colSpan={showAssetsAlert ? { base: 12, lg: 4 } : { base: 20, lg: 8 }}
          order={showAssetsAlert ? undefined : cardOrder}
        >
          <ManageFaceCakeTileStatus setShowAssetsAlert={setShowAssetsAlert} />
        </GridItem>
        {navigationItems.map(item => (
          <GridItem
            key={item.title}
            colSpan={showAssetsAlert ? { base: 12, lg: 4 } : { base: 20, lg: 4 }}
          >
            <LinkBox h="full">
              <NavigationCard>
                <LinkOverlay as={Link} to={item.link}>
                  <Text fontSize="2xl">{item.title}</Text>
                </LinkOverlay>
                <FontAwesomeIcon icon={item.icon} boxSize={{ base: '12', xl: '16' }} />
              </NavigationCard>
            </LinkBox>
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
};

const AssetsAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert
      status="info"
      h="full"
      alignItems="flex-start"
      p={['4', '6']}
      background="blackAlpha.700"
      color="white"
    >
      <FontAwesomeIcon
        icon={faInfoCircle}
        color="white"
        boxSize={{ base: '4', sm: '8' }}
        pr={['2', '4']}
      />
      <Flex flexDir="column" h="full">
        <VStack align="flex-start" textAlign="left" spacing="2">
          <AlertTitle mb="4" fontSize={{ base: 'xl', xl: '3xl' }}>
            {t('icommerce.accountManagement.alert.heading')}
          </AlertTitle>
          <AlertDescription
            fontSize={{ base: 'lg', xl: '2xl' }}
            lineHeight={{ base: 'base', xl: 'base' }}
          >
            {t('icommerce.accountManagement.alert.description')}
          </AlertDescription>
        </VStack>
        <Spacer />
        {/* <Box alignSelf="center" mt={{ base: '6', xl: '12' }}> */}
        {/* <Button
            variant="outline"
            colorScheme="whiteAlpha"
            color="inherit"
            _hover={{ bg: 'whiteAlpha.50' }}
            _active={{ bg: 'whiteAlpha.100' }}
            size={buttonSize}
          >
            {t('icommerce.accountManagement.alert.button')}
          </Button> */}
        {/* </Box> */}
      </Flex>
    </Alert>
  );
};

const NavigationCard = (props: StackProps) => {
  return (
    <VStack
      className="navigation-card"
      height="full"
      justify="center"
      align="center"
      py={{ base: '8', lg: '12' }}
      px="8"
      bg="white"
      shadow="md"
      spacing={{ base: '4', lg: '8' }}
      transition="ease 200ms"
      _hover={{
        bg: 'blackAlpha.100',
      }}
      _active={{
        bg: 'blackAlpha.200',
      }}
      textAlign="center"
      {...props}
    >
      {props.children}
    </VStack>
  );
};
