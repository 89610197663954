"use strict";
exports.__esModule = true;
exports.getDirtyValues = void 0;
/**
 * For use with react-hook-form submit handlers, to get only the values of the form that were changed
 * @param dirtyFields
 * @param allValues
 * @returns
 */
function getDirtyValues(dirtyFields, allValues) {
    // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
    // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields))
        return allValues;
    // Here, we have an object
    return Object.fromEntries(Object.keys(dirtyFields).map(function (key) { return [key, getDirtyValues(dirtyFields[key], allValues[key])]; }));
}
exports.getDirtyValues = getDirtyValues;
