import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Center,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Pagination,
  usePagination as useTabPagination,
  PaginationContainer,
  PaginationItem,
  useBreakpointValue,
  useMediaQuery,
  Input,
  InputGroup,
  InputRightElement,
  Heading,
  isEmpty,
} from '@companydotcom/potion';
import { FontAwesomeIcon, AppSpinner } from '@companydotcom/ui';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import {
  useTable,
  useSortBy,
  Column,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import { useAppSelector } from '../../../../hooks';
import { selectActiveEvent } from '../my-events-slice';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { useGetEventAttendeesQuery } from '../../../../services/acg/acg-api';
import { EventAttendee } from '../../../../services/acg/acg.types';

export const MyEventsAttendeeList = () => {
  const [searchParams] = useSearchParams();
  const { data: globalUser } = useGetGlobalUserQuery();
  const activeEvent = useAppSelector(selectActiveEvent);
  const { data: eventAttendeesList, isLoading } = useGetEventAttendeesQuery(
    (globalUser && activeEvent?.eventId) ||
      (globalUser && searchParams.get('evt_key') !== undefined)
      ? {
          user: globalUser,
          eventId: (searchParams.get('evt_key') || activeEvent?.eventId) ?? '',
        }
      : skipToken,
  );

  const tableData = React.useMemo((): EventAttendee[] => {
    return !eventAttendeesList || isEmpty(eventAttendeesList) ? [] : eventAttendeesList;
  }, [eventAttendeesList]);

  const columns: Column<EventAttendee>[] = React.useMemo(
    () => [
      {
        Header: 'Attendee Name',
        accessor: 'name',
        Cell: ({ cell }) => <Text textStyle="sm">{cell?.value ?? ''}</Text>,
      },
      {
        Header: 'Title',
        accessor: 'title',
        disableGlobalFilter: true,
        Cell: ({ cell }) => <Text textStyle="sm">{cell?.value ?? ''}</Text>,
      },
      {
        Header: 'Organization',
        accessor: 'organizationName',
        disableGlobalFilter: true,
        Cell: ({ cell }) => <Text textStyle="sm">{cell?.value}</Text>,
      },
    ],
    [],
  );

  return (
    <Box mt={10}>
      <SortableTable isLoading={isLoading} columns={columns} data={tableData} />
    </Box>
  );
};
interface SortableTableProps {
  columns: Column<EventAttendee>[];
  data: EventAttendee[];
  isLoading?: boolean;
}

const GlobalFilter = ({ globalFilter, setGlobalFilter }: any) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <InputGroup>
      <Input
        mb={8}
        name="attendee"
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search for an individual..."
      />
      <InputRightElement children={<FontAwesomeIcon icon={faSearch} color="gray.400" />} />
    </InputGroup>
  );
};

export function SortableTable({ data, columns, isLoading }: SortableTableProps) {
  const [isMobile] = useMediaQuery('(max-width: 30em)');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: isMobile ? 10 : 20 },
      autoResetHiddenColumns: false,
      autoResetPage: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const paginationBreakPoint = useBreakpointValue({ base: 0, md: 1 });

  const handlePaginationClick = (e: any, value: number) => {
    gotoPage(value - 1);
  };

  const { items } = useTabPagination({
    page: state.pageIndex + 1,
    count: pageCount,
    onChange: handlePaginationClick,
    siblingCount: paginationBreakPoint,
  });

  return (
    <>
      {isLoading ? (
        <AppSpinner
          description="Finding registered attendees..."
          containerStyles={{
            my: 12,
            mx: 'auto',
          }}
        />
      ) : (
        <>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            handlePaginationClick={handlePaginationClick}
          />
          <TableContainer borderWidth="1px" p={3} bg="white">
            <Table variant="striped" {...getTableProps()}>
              {(state.globalFilter && !page) || page.length === 0 ? (
                <Thead>
                  <Tr>
                    <Td borderBottomWidth={0}>
                      <Heading
                        size="hs-xl"
                        padding={5}
                        py={7}
                        whiteSpace="normal"
                        textAlign="center"
                        color="#717174"
                        opacity={0.8}
                      >
                        There are no members related to this search. Please try your search again.
                      </Heading>
                    </Td>
                  </Tr>
                </Thead>
              ) : (
                !isMobile && (
                  <Thead>
                    {headerGroups.map(headerGroup => (
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <Th
                            fontSize="sm"
                            color="green.500"
                            fontWeight="bold"
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            {column.Header !== '' ? <Text>{column.render('Header')}</Text> : null}
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                )
              )}
              {isLoading ? null : (
                <Tbody {...getTableBodyProps()}>
                  {!data || data.length === 0 ? (
                    <Tr borderBottomWidth="1px" bg="white">
                      <Td
                        textStyle="md"
                        textAlign="center"
                        bg="white"
                        py={8}
                        colSpan={columns?.length ?? 3}
                      >
                        There are no attendees to display
                      </Td>
                    </Tr>
                  ) : (
                    page.map(row => {
                      prepareRow(row);
                      return !isMobile ? (
                        <Tr borderBottomWidth="1px" {...row.getRowProps()}>
                          {row.cells.map(cell => (
                            <Td
                              fontSize="sm"
                              sx={{ borderBottomWidth: '0' }}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </Td>
                          ))}
                        </Tr>
                      ) : (
                        <Tr
                          sx={{ display: 'flex', flexDirection: 'column' }}
                          borderBottomWidth="1px"
                          {...row.getRowProps()}
                        >
                          {row.cells.map(cell => (
                            <Td
                              role="row"
                              fontSize="sm"
                              py={1}
                              sx={{ borderBottomWidth: '0' }}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </Td>
                          ))}
                        </Tr>
                      );
                    })
                  )}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </>
      )}

      {!data ||
      data.length === 0 ||
      isLoading ||
      data.length <= 20 ||
      (isMobile && data.length <= 6) ||
      (state.globalFilter && !page) ||
      page.length === 0 ? null : (
        <Center mt={6}>
          <Pagination variant="outline" size="sm">
            <PaginationContainer>
              {items.map((item, i) => (
                <PaginationItem key={i} {...item} />
              ))}
            </PaginationContainer>
          </Pagination>
        </Center>
      )}
    </>
  );
}
