import React from 'react';
import { Text, Alert, AlertIcon, Button } from '@companydotcom/potion';
import { platformHelpers } from '@companydotcom/helpers';
import type { TileComponentProps } from '../tile-component';

export interface TileDynamicWarningProps extends TileComponentProps {}

export const TileDynamicWarning: React.FC<TileDynamicWarningProps> = props => {
  const { eventHandler, pubEvents, params, eventData, componentTheme } = props;
  const textParamName =
    eventData && eventData?.body?.status === 'pass' ? 'fullText' : 'error_fullText';
  const fullText = JSON.parse(platformHelpers.getComponentParamValue(params, textParamName));
  const keywords = JSON.parse(platformHelpers.getComponentParamValue(params, 'keywords'));

  const handleClick = (text: string) => {
    const actionTypeForText = params?.find?.(({ key }: any) => key === text)?.value;
    return () =>
      eventHandler?.(
        pubEvents,
        { type: actionTypeForText },
        // @ts-ignore
        eventData && _get(eventData, 'body.payload.paywallData'),
      );
  };

  return (
    <Alert status="warning" sx={componentTheme}>
      <AlertIcon />
      <Text>
        {fullText.map((text: string, i: number) => {
          if (keywords.includes(text)) {
            return (
              <Button fontSize="sm" variant="link" onClick={handleClick(text)}>
                {text}
              </Button>
            );
          }
          return (
            /* allows and empty string in the array to be used as a line break */
            <Text key={i} textStyle="sm" as={text ? 'span' : 'div'}>
              {text}
            </Text>
          );
        })}
      </Text>
    </Alert>
  );
};
