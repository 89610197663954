import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, IconButton, Tabs, TabList, TabPanels, Tab, TabPanel, __DEV__, } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintRoller } from '@fortawesome/pro-solid-svg-icons';
import { ColorEditor } from './color-editor';
const editorSteps = [
    {
        slug: 'colors',
        component: _jsx(ColorEditor, {}, void 0),
    },
];
export const ThemeEditor = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { icon: _jsx(FontAwesomeIcon, { icon: faPaintRoller }, void 0), "aria-label": "Open Dev Tools", colorScheme: "pink", onClick: onOpen, cursor: "pointer", sx: { borderRadius: 'none' } }, void 0), _jsxs(Drawer, Object.assign({ size: "md", isOpen: isOpen, placement: "right", onClose: onClose }, { children: [_jsx(DrawerOverlay, {}, void 0), _jsxs(DrawerContent, { children: [_jsx(DrawerCloseButton, {}, void 0), _jsx(DrawerHeader, { children: "Theme Editor" }, void 0), _jsx(DrawerBody, { children: _jsxs(Tabs, { children: [_jsx(TabList, { children: editorSteps.map(step => (_jsx(Tab, { children: step.slug.toUpperCase() }, step.slug))) }, void 0), _jsx(TabPanels, Object.assign({ backgroundColor: "gray.50" }, { children: editorSteps.map(step => (_jsx(TabPanel, Object.assign({ borderWidth: "1px", borderColor: "gray.50" }, { children: step.component }), step.slug))) }), void 0)] }, void 0) }, void 0)] }, void 0)] }), void 0)] }, void 0));
};
if (__DEV__) {
    ThemeEditor.displayName = 'ThemeEditor';
}
