import themeSizes from '../foundations/sizes';
import { isDark, randomColor } from '../../theme-tools';

const parts = ['container', 'excessLabel', 'badge', 'label'];

const baseStyleBadge = {
  transform: 'translate(25%, 25%)',
  borderRadius: 'full',
  border: '0.2em solid',
  borderColor: 'white',
};

const baseStyleExcessLabel = {
  bg: 'gray.200',
};

function baseStyleContainer(props: any) {
  const { name, theme } = props;
  const bg = name ? randomColor({ string: name }) : 'gray.400';
  const isBgDark = isDark(bg)(theme);

  let color = 'white';
  if (!isBgDark) color = 'gray.800';

  const borderColor = 'white';

  return {
    bg,
    color,
    borderColor,
    verticalAlign: 'top',
  };
}

const baseStyle = (props: any) => ({
  badge: baseStyleBadge,
  excessLabel: baseStyleExcessLabel,
  container: baseStyleContainer(props),
});

function getSize(size: string) {
  const themeSize = themeSizes[size];
  return {
    container: {
      width: size,
      height: size,
      fontSize: `calc(${themeSize ?? size} / 2.5)`,
    },
    excessLabel: {
      width: size,
      height: size,
    },
    label: {
      fontSize: `calc(${themeSize ?? size} / 2.5)`,
      lineHeight: size !== '100%' ? themeSize ?? size : undefined,
    },
  };
}

const sizes = {
  '2xs': getSize('4'),
  xs: getSize('6'),
  sm: getSize('8'),
  md: getSize('12'),
  lg: getSize('16'),
  xl: getSize('24'),
  '2xl': getSize('32'),
  full: getSize('100%'),
};

const defaultProps = {
  size: 'md',
};

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
