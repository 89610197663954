import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, TabPanels, TabPanel, useTabStepper, BoxProps } from '@companydotcom/potion';
import { CheckoutOrder } from './checkout-order';
import { CheckoutOrderConfirmation } from './checkout-order-confirmation';

const checkoutSteps = [
  {
    slug: 'checkout-order',
    component: <CheckoutOrder />,
  },
  {
    slug: 'checkout-order-confirmation',
    component: <CheckoutOrderConfirmation />,
  },
];

export interface CheckoutSharedProps {
  goToStep?: (arg: number | string) => void;
  /** An additional function that fires on the order confirmation step once complete  */
  onOrderConfirmationComplete?: () => void;
  /** An additional function that fires once a purchase is successful  */
  onSuccessfulPurchase?: () => void;
  /** Style variant */
  variant?: 'unstyled' | 'page';
  containerProps?: BoxProps;
}

export const Checkout: React.FC<Omit<CheckoutSharedProps, 'goToStep'>> = props => {
  const {
    onOrderConfirmationComplete,
    onSuccessfulPurchase,
    variant = 'unstyled',
    containerProps,
  } = props;
  const { currentStep, tabIndex, handleTabsChange, goToStep } = useTabStepper({
    steps: checkoutSteps,
    initialStep: 0,
  });
  const location = useLocation();
  const [orderConfirmationData, setOrderConfirmationData] = useState({});

  const determineIfBackButtonsDisplayed = (currentStepSlug?: string) => {
    return location.pathname.includes('checkout') && currentStepSlug === 'checkout-order';
  };

  return (
    <Tabs
      className="facecake-checkout"
      isLazy
      variant={variant}
      index={tabIndex}
      onChange={handleTabsChange}
      mt={determineIfBackButtonsDisplayed(currentStep?.slug) ? 0 : [0, 12]}
    >
      <TabPanels>
        {checkoutSteps.map(step => (
          <TabPanel sx={containerProps} className={step?.slug ?? ''} padding={0} key={step.slug}>
            {React.cloneElement(step.component, {
              goToStep,
              onOrderConfirmationComplete,
              onSuccessfulPurchase,
              orderConfirmationData,
              setOrderConfirmationData,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
