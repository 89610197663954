import {
  EnrollmentSetValidEmailInput,
  GenerateOtpResponse,
  GetEnrollmentRes,
  MutationVerifyOtpArgs,
  OptOutExternalIdInput,
  UserData,
} from '@companydotcom/types';
import {
  VERIFY_OTP,
  RESET_PASSWORD_EMAIL,
  OPT_OUT_BY_EXT_ID,
  ENROLLMENT_SET_VALID_EMAIL,
  RECAPTCHA_VERIFICATION,
  ENROLL_SINGLE_USER_V2,
} from './graphql/enrollment-mutation';
import { emptySplitApi } from '../../store/empty-split-api';
import {
  GET_ENROLLMENT_STATUS_QUERY,
  GET_ENROLLMENT_BY_EMAIL_QUERY,
  GENERATE_AND_SEND_OTP,
} from './graphql/enrollment-query';
import { client } from '../../lib/appsync';

export const EnrollmentApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getEnrollmentStatus: builder.query<any, { enrollmentId: string; sourceId?: string }>({
      query: ({ enrollmentId, sourceId }) => ({
        method: 'query',
        document: GET_ENROLLMENT_STATUS_QUERY,
        variables: {
          enrollmentId,
          source: sourceId,
        },
      }),
      transformResponse: (rawResult: { data: { getEnrollmentStatus: any } }) =>
        rawResult.data.getEnrollmentStatus,
    }),
    getEnrollmentByUserEmail: builder.query<GetEnrollmentRes, { email: string }>({
      query: ({ email }) => ({
        method: 'query',
        document: GET_ENROLLMENT_BY_EMAIL_QUERY,
        variables: {
          email,
        },
      }),
      transformResponse: (rawResult: {
        data: {
          getEnrollmentByUserEmail: string;
        };
      }) => {
        return JSON.parse(rawResult.data.getEnrollmentByUserEmail) as GetEnrollmentRes;
      },
    }),
    generateAndSendOtp: builder.query<GenerateOtpResponse, UserData>({
      query: data => ({
        method: 'query',
        document: GENERATE_AND_SEND_OTP,
        variables: data,
      }),
      transformResponse: (rawResult: {
        data: {
          generateAndSendOtp: string;
        };
      }) => {
        return JSON.parse(rawResult.data.generateAndSendOtp);
      },
    }),
    verifyOtp: builder.mutation<any, MutationVerifyOtpArgs>({
      query: data => ({
        method: 'mutation',
        document: VERIFY_OTP,
        variables: data,
      }),
      transformResponse: (rawResult: {
        data: {
          verifyOtp: string;
        };
      }) => {
        return JSON.parse(rawResult.data.verifyOtp);
      },
    }),
    resetPasswordEmail: builder.mutation<any, { email: string; source: string }>({
      query: ({ email, source }) => ({
        method: 'mutation',
        document: RESET_PASSWORD_EMAIL,
        variables: {
          email,
          source,
        },
      }),
      transformResponse: (rawResult: {
        data: {
          resetPasswordEmail: string;
        };
      }) => {
        return JSON.parse(rawResult.data.resetPasswordEmail);
      },
    }),
    optOutByExternalIdentifier: builder.mutation<any, OptOutExternalIdInput>({
      query: data => ({
        method: 'mutation',
        document: OPT_OUT_BY_EXT_ID,
        variables: {
          input: data,
        },
      }),
      transformResponse: (rawResult: {
        data: {
          optOutByExternalIdentifier: string;
        };
      }) => {
        return JSON.parse(rawResult.data.optOutByExternalIdentifier);
      },
    }),
    enrollmentSetValidEmail: builder.mutation<any, EnrollmentSetValidEmailInput>({
      query: data => ({
        method: 'mutation',
        document: ENROLLMENT_SET_VALID_EMAIL,
        variables: {
          input: data,
        },
      }),
      transformResponse: (rawResult: {
        data: {
          enrollmentSetValidEmail: string;
        };
      }) => {
        return JSON.parse(rawResult.data.enrollmentSetValidEmail);
      },
    }),
    recaptchaVerification: builder.mutation<any, { token: string }>({
      query: ({ token }) => ({
        method: 'mutation',
        document: RECAPTCHA_VERIFICATION,
        variables: {
          token,
        },
      }),
      transformResponse: (rawResult: { data: { recaptchaVerification: string } }) =>
        JSON.parse(rawResult.data.recaptchaVerification),
    }),
    /** SENDS DATA TO MARKETO */
    enrollSingleUserV2: builder.mutation<any, any>({
      queryFn: async data => {
        try {
          const resp = await client.mutate({
            // @ts-ignore
            mutation: ENROLL_SINGLE_USER_V2,
            variables: { body: data, source: 'icommerce' },
          });
          return resp ? { data: resp } : { error: true, meta: { message: resp } };
        } catch (error) {
          return { error: true, meta: { message: `Error enrolling single user, ${error}` } };
        }
      },
    }),
  }),
});

export const {
  useLazyGetEnrollmentStatusQuery,
  useGetEnrollmentByUserEmailQuery,
  useGenerateAndSendOtpQuery,
  useLazyGenerateAndSendOtpQuery,
  // MUTATIONS
  useRecaptchaVerificationMutation,
  useEnrollmentSetValidEmailMutation,
  useOptOutByExternalIdentifierMutation,
  useResetPasswordEmailMutation,
  useVerifyOtpMutation,
  useEnrollSingleUserV2Mutation,
} = EnrollmentApi;
