import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Heading,
  Radio,
  error,
  Center,
  Stack,
  RadioGroup,
  ButtonGroup,
  useBoolean,
} from '@companydotcom/potion';
import * as yup from 'yup';
import { Listing } from '@companydotcom/types';
import { AppSpinner } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { GmbSharedProps } from './gmb-profile-flow';
import {
  useGetVerificationOptionsMutation,
  useStartVerificationMutation,
} from '../../../services/listings/listing-api';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { useAwaitableFacade } from '../../../hooks';

const getGmbVerificationOptionsSchema = () =>
  yup.object().shape({
    selectedOption: yup
      .mixed()
      .oneOf(['SUPPORT_EMAIL', 'SCHEDULE', 'EMAIL', 'SMS', 'PHONE_CALL', 'NOT_NOW']),
  });

export const GmbVerificationOptions: React.FC<GmbSharedProps> = ({
  listingId,
  setGmbFailed,
  // listingSvc,
  previousStep,
  goToStep,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useBoolean(true);
  const { data: globalUser } = useGetGlobalUserQuery();
  const [getVerificationOptions] = useGetVerificationOptionsMutation();
  const [startVerification] = useStartVerificationMutation();
  const snsInterface = useAwaitableFacade(globalUser, 'listing');
  const [permittedVerificationOptions, setPermittedVerificationOptions] = useState<string[]>([
    'SCHEDULE',
    'NOT_NOW',
  ]);
  const defaultValues = { selectedOption: 'SUPPORT_EMAIL' };
  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getGmbVerificationOptionsSchema()),
    defaultValues,
  });

  const allVerificationOptions = [
    {
      label: 'Send me a postcard and follow up with me via email',
      value: 'SUPPORT_EMAIL',
      default: true,
    },
    {
      label: 'Send me a postcard and I’ll schedule a time to get help setting up',
      value: 'SCHEDULE',
    },
    {
      label: 'Call my business phone with the verification code',
      value: 'PHONE_CALL',
    },
    {
      label: 'Send a text message with the verification code to my phone',
      value: 'SMS',
    },
    {
      label: 'Send me an email with the verification code',
      value: 'EMAIL',
    },
    {
      label: 'I am not interested in verifying my business right now',
      value: 'NOT_NOW',
    },
  ];

  const setVerification = useCallback(async () => {
    if (!listingId) {
      setPermittedVerificationOptions(['SUPPORT_EMAIL', 'SCHEDULE', 'NOT_NOW']);
      setLoading.off();
      error({ condition: true, message: 'no listing id provided to lookup verification options' });
    }
    if (listingId) {
      await getVerificationOptions({ listingId, snsInterface })
        .unwrap()
        .then(options => {
          setPermittedVerificationOptions(options);
          setLoading.off();
        })
        .catch(err => {
          error({ condition: true, message: err });
          setPermittedVerificationOptions(['SUPPORT_EMAIL', 'SCHEDULE', 'NOT_NOW']);
          setLoading.off();
        });
    }
  }, [getVerificationOptions, listingId, setLoading, snsInterface]);

  useEffect(() => {
    setVerification();
    //   // listingSvc
    //   //   .getVerificationOptions(listingId)
    //   //   .then(({ data: options }: { data: any }) => {
    //   //     // @ts-ignore
    //   //     setPermittedVerificationOptions(options);
    //   //     setLoading(false);
    //   //   })
    //   //   .catch((err: any) => {
    //   //     error({ condition: true, message: err });
    //   //     setPermittedVerificationOptions(['SUPPORT_EMAIL', 'SCHEDULE', 'NOT_NOW']);
    //   //     setLoading(false);
    //   //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      if (globalUser && listingId) {
        await startVerification({
          snsInterface,
          listingId,
          verificationMethod: values.selectedOption,
        })
          .unwrap()
          .then(({ success, error, data }: { success: boolean; error: any; data: Listing }) => {
            if (success) {
              if (data.gmbStatus === 'pendingDirectVerification') {
                goToStep?.(3);
              }
              if (values.selectedOption === 'SCHEDULE') {
                goToStep?.(6);
              }
              if (
                values.selectedOption === 'NOT_NOW' ||
                values.selectedOption === 'SUPPORT_EMAIL'
              ) {
                goToStep?.(4);
              }
            } else {
              setGmbFailed?.on();
              goToStep?.(4);
              error({ condition: true, message: error });
            }
          });
      }

      // listingSvc
      //   .startVerification(listingId, values.selectedOption)
      //   .then(({ success, error, data }: { success: boolean; error: any; data: Listing }) => {
      //     if (success) {
      //       if (data.gmbStatus === 'pendingDirectVerification') {
      //         goToStep?.(3);
      //       }
      //       if (values.selectedOption === 'SCHEDULE') {
      //         goToStep?.(6);
      //       }
      //       if (values.selectedOption === 'NOT_NOW' || values.selectedOption === 'SUPPORT_EMAIL') {
      //         goToStep?.(4);
      //       }
      //     } else {
      //       setGmbFailed?.on();
      //       goToStep?.(4);
      //       error({ condition: true, message: error });
      //     }
      //   });
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  return (
    <Center
      className="gmb-verificationOptions__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Heading as="h1" size="hs-lg">
        {t('gmbProfileFlow.verificationOptions.heading')}
      </Heading>
      <Heading as="h2" size="hs-md" mt={[5]}>
        {t('gmbProfileFlow.verificationOptions.verify')}
      </Heading>

      <Box
        as="form"
        className="gmb-verificationOptions__form"
        maxWidth={480}
        width="full"
        textAlign="left"
        mt={[8, 10]}
      >
        <Heading size="hs-sm" textAlign="left" mb={6}>
          {t('gmbProfileFlow.verificationOptions.verifyOption')}
        </Heading>
        {loading ? (
          <Box my={[7]}>
            <AppSpinner />
          </Box>
        ) : (
          <Controller
            name="selectedOption"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                className="gmb-verificationOptions__selectedOptionRadio"
                data-test="gmb-verificationOptions__selectedOptionRadio"
              >
                <Stack spacing={3} direction="column" textAlign="left">
                  {permittedVerificationOptions &&
                    allVerificationOptions
                      .filter(fullOption =>
                        permittedVerificationOptions?.includes(fullOption.value),
                      )
                      .map(option => {
                        return (
                          <Radio key={option.value} value={option.value}>
                            {t(`gmbProfileFlow.verificationOptions.options.${option.value}` as any)}
                          </Radio>
                        );
                      })}
                </Stack>
              </RadioGroup>
            )}
          />
        )}
      </Box>
      <Box textAlign="center" mt={[8, 12]}>
        <ButtonGroup size="lg">
          <Button
            onClick={() => {
              previousStep?.();
            }}
            className="gmb-verificationOptions__backButton"
            data-test="gmb-verificationOptions__backButton"
            variant="outline"
          >
            {t('common.buttons.back')}
          </Button>

          <Button
            onClick={handleSubmit(onSubmit)}
            className="gmb-verificationOptions__submitButton"
            data-test="gmb-verificationOptions__submitButton"
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >
            {t('common.buttons.submit')}
          </Button>
        </ButtonGroup>
      </Box>
    </Center>
  );
};
