import React from 'react';
import {
  Box,
  Button,
  Pagination,
  PaginationContainer,
  PaginationItem,
  usePagination,
  Text,
  Flex,
  Radio,
  Heading,
  RadioGroup,
  useMediaQuery,
} from '@companydotcom/potion';
import { Controller } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { GetAllAcgChaptersAndPackagesResponse } from '@companydotcom/types';

// TODO: retype this
interface PlanSelectProps {
  data?: GetAllAcgChaptersAndPackagesResponse[] | any[];
  onChange?: (arg?: string) => void;
  onSubmit?: (arg0: any) => void;
  goToStep?: () => void;
  noDataError?: boolean;
  isValid?: boolean;
  type?: 'chapter' | 'plan';
  page?: number;
  setPage?: (arg0: any) => void;
  displayOnly?: boolean;
  isLoading?: boolean;
  chapterKeyParam?: string;
  packageKeyParam?: string;
  isPackageScreen?: boolean;
  formProps?: {
    register?: any;
    handleSubmit?: any;
    control?: any;
    reset?: any;
    errors?: any;
    isValid?: any;
    name?: string;
  };
}

const getChapterPrice = (chapter: any, type?: 'chapter' | 'plan') => {
  if (type === 'plan') {
    return `$${chapter?.price.toFixed(2)}`;
  }

  if (
    chapter?.duesProducts?.[0]?.chapterTypeName.includes('EU') ||
    chapter?.duesProducts?.[0]?.chapterTypeName.includes('UK')
  ) {
    return chapter?.duesProducts?.[0]?.chapterInternationalDues;
  }

  const prices = chapter.duesProducts
    .map((packages: any) => packages?.price.toFixed(2))
    .sort((a: number, b: number) => a - b);

  if (prices[0] === prices[prices.length - 1]) {
    return `$${prices[0]}`;
  }

  // Check whether the price is float
  const price = `$${prices[0]}`;
  const prevPrice = `$${prices[prices.length - 1]}`;
  return `${price} - ${prevPrice}`;
};

/**
 *
 * @param data @type {Array<GetAllAcgChaptersAndPackagesResponse | ChaptersRhythm>} - Array of Chapters or ChapterProducts
 * @param onSubmit @type {(arg0: any) => void} - Function that will be run when submitting
 * @param onChange @type {(arg0: any) => void} - Function that will be run when a value is selected
 * @param noDataError @type {boolean} - Show a message if there is no data supplied?
 * @param isValid @type {boolean} - External submission validation. isValid must be true in order to submit.
 * @param page @type {number} - The page currently selected in the pagination.
 * @param setPage @type {(arg0: number) => void} - Set the value of page.
 * @param type @type {() => void} - Whether or not this paginated select deals with chapters or chapterPackages
 * @param displayOnly @type {boolean} - Should the values of this component be selectable at all?
 */
export const PaginatedSelect = ({
  data = [],
  onSubmit,
  noDataError,
  type,
  isValid,
  onChange,
  page = 1,
  setPage,
  displayOnly,
  isLoading,
  // chapterKeyParam,
  // packageKeyParam,
  isPackageScreen,
  formProps,
}: PlanSelectProps) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const resultsPerPage = isMobile ? 10 : 20;

  const shouldRenderItem = (idx: number) => {
    return idx >= resultsPerPage * (page - 1) && idx < page * resultsPerPage;
  };

  const handlePagination = (event: any, value: any) => {
    setPage?.(value);
  };

  const { items } = usePagination({
    count: Math.ceil(data?.length / resultsPerPage),
    onChange: handlePagination,
    boundaryCount: 0,
    siblingCount: 1,
  });

  const sharedProps = {
    shouldRenderItem,
    displayOnly,
    isMobile,
    onChange,
    type,
    t,
  };

  return (
    <Flex flexDirection="column" alignItems="center" letterSpacing={0.8}>
      <Box
        sx={{ border: 'solid', borderWidth: '1px', borderColor: '#D1D1D1' }}
        width="full"
        mb={4}
        bgColor={['white', 'transparent']}
        p={[3]}
      >
        {noDataError ? (
          <Heading as="h2" size="hs-xl" padding={5} py={7} color="#717174b5">
            {t('acg.firstTimeFlow.subpage.noChapters')}
          </Heading>
        ) : (
          <>
            {!isMobile && <SelectHeading isPackageScreen={isPackageScreen} />}
            <BecomeAMemberRadios
              control={formProps?.control}
              items={data}
              props={sharedProps}
              name={formProps?.name}
            />
          </>
        )}
      </Box>
      {data?.length > resultsPerPage && !noDataError && (
        <Pagination mt={6}>
          <PaginationContainer>
            {items.map((item, idx) => (
              <PaginationItem key={idx} {...item} />
            ))}
          </PaginationContainer>
        </Pagination>
      )}
      {!displayOnly && (
        <Button
          isLoading={isLoading}
          isDisabled={!isValid}
          mt={8}
          mb={2}
          size="lg"
          width={isMobile ? 'full' : 'inherit'}
          onClick={(e: any) => onSubmit?.(e)}
        >
          {type === 'plan' ? 'ADD TO CART' : t('common.buttons.continue')}
        </Button>
      )}
    </Flex>
  );
};

const BecomeAMemberRadios = ({ name, control, items, props }: any) => {
  const { shouldRenderItem, displayOnly, isMobile, onChange, type, t } = props;
  const radioSelectLabel = type === 'chapter' ? 'chapterName' : 'productName';
  return (
    <Flex flexDirection="column">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup
            size="lg"
            {...field}
            onChange={label => {
              field.onChange(label);
              onChange?.(label);
            }}
          >
            {items?.map((chapter: any, idx: number) => {
              const value = !chapter?.[radioSelectLabel]
                ? chapter?.packageMembershipType
                : chapter[radioSelectLabel];
              return (
                shouldRenderItem(idx) && (
                  <Flex
                    key={idx}
                    flexDirection={['column', 'row', 'row']}
                    justifyContent={['center', 'space-between']}
                    alignItems={['start', 'center']}
                    bg={idx % 2 == 0 ? '#F2F2F2' : 'white'}
                    size={['lg', 'md']}
                    width="full"
                    padding={4}
                    textStyle="xs"
                    height={isMobile ? 20 : 14}
                  >
                    {!displayOnly ? (
                      <Radio key={idx} value={value}>
                        <Text textAlign="left" fontSize="sm">
                          {mapPackageName(value, t)?.label ?? value}
                        </Text>
                        <Text color="orange.500" textAlign="left" fontWeight="bold" fontSize="xs">
                          {chapter?.notes}
                        </Text>
                      </Radio>
                    ) : (
                      <Text ml={[5, 0]}>{mapPackageName(value, t)?.label ?? value}</Text>
                    )}
                    {name !== 'acgPackage' && (
                      <Text ml={[7, 0]} fontSize="sm">
                        {getChapterPrice(chapter, type) ?? ''}
                      </Text>
                    )}
                  </Flex>
                )
              );
            })}
          </RadioGroup>
        )}
      />
    </Flex>
  );
};

const SelectHeading = ({ isPackageScreen }: { isPackageScreen?: boolean }) => {
  const { t } = useTranslation();
  if (!isPackageScreen) {
    return (
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        px={5}
        py={3}
        textTransform="uppercase"
      >
        <Text fontWeight="bold" color="pricing">
          {t('acg.myProfile.profilePages.chapter')}
        </Text>
        <Text fontWeight="bold" color="pricing">
          COST
        </Text>
      </Flex>
    );
  }
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      px={5}
      py={3}
      textTransform="uppercase"
    >
      <Text fontWeight="bold" color="pricing">
        {t('acg.firstTimeFlow.subpage.membershipPackage')}
      </Text>
    </Flex>
  );
};

const mapPackageName = (data: string, trans: TFunction) => {
  const packages = [
    {
      label: trans('acg.firstTimeFlow.subpage.packageName.US'),
      value: 'Member - Global',
    },
    {
      label: trans('acg.firstTimeFlow.subpage.packageName.CA'),
      value: 'Member - Canadian Global',
    },
    {
      label: trans('acg.firstTimeFlow.subpage.packageName.UK'),
      value: 'Member - UK Global',
    },
    {
      label: trans('acg.firstTimeFlow.subpage.packageName.EU'),
      value: 'EU Global',
    },
  ];

  return packages?.find(p => p.value === data);
};
