import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseButtonLayout } from '../../features/facecake/layouts/close-button-layout';
import { FaceCakeInvoices } from '../../features/facecake/facecake-invoices';

export const FaceCakeInvoicesPage = () => {
  const navigate = useNavigate();
  return (
    <CloseButtonLayout
      onBackClick={() => {
        navigate('/');
      }}
    >
      <FaceCakeInvoices />
    </CloseButtonLayout>
  );
};
