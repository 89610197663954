import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { platformHelpers } from '@companydotcom/helpers';
import { User } from '@companydotcom/types';
import { subscribeToNotifications } from '../services/notification/notification-svc';
import { useLazyPublishTileEventQuery } from '../services/event/event-api';
import { useLazyFetchLargePayloadQuery } from '../services/notification/notification-api';

/**
 *
 * @param user
 * @param service
 * @param timeoutWait
 * @returns
 */
export function useAwaitableFacade(
  user?: Partial<User>,
  service?: 'listing' | 'acg' | 'darkWebScan' | 'mailBox',
  timeoutWait?: number,
) {
  const [publishTileEvent] = useLazyPublishTileEventQuery();
  const [fetchLargePayload] = useLazyFetchLargePayloadQuery();
  const [cache, setCache] = useState({});
  const [promiseResolvers, setPromiseResolvers] = useState({});

  const emitEvent = async (
    tile: { productId: string; tileId: string },
    eventType: string,
    action: string,
    payload: any,
    metadata: any,
    componentTypes: string[],
  ) => {
    const eventId = uuidv4();
    const event = platformHelpers.formatPublishTileEventInput(
      {
        eventType: action,
        component: null,
        topic: eventType,
        componentTypes,
        ...metadata,
      },
      {
        user,
        tile,
      },
      payload,
      eventType,
      eventId,
    );
    await publishTileEvent(event);
    return eventId;
  };

  let getNotifData = async (
    { productId, tileId }: { productId: string; tileId: string },
    eventType: 'webhook' | 'fetch' | 'transition',
    action: string,
    payload: Record<string, any>,
    metadata: Record<string, any>,
    bypassCache: boolean,
  ) => {
    const componentTypes = serviceReducer(action, service ?? '');
    const stringifiedPayload = JSON.stringify(payload);
    const eventId = await emitEvent(
      { productId, tileId },
      eventType,
      action,
      payload,
      metadata,
      componentTypes,
    );
    if (
      !bypassCache &&
      cache[productId] &&
      cache[productId][action] &&
      cache[`${productId}-${action}`].status !== 'failed' &&
      cache[`${productId}-${action}`].payload === stringifiedPayload
    ) {
      return cache[`${productId}-${action}`].promise;
    }
    const promise = new Promise((resolver, rej) => {
      const timeout = setTimeout(() => {
        const newCache = {
          [`${productId}-${action}`]: {
            status: 'failed',
          },
        };
        setCache({ ...cache, ...newCache });
        rej(Error('Request Timed Out'));
      }, timeoutWait || 15000);

      const newPromiseResolvers = {
        [eventId]: (data: any) => {
          clearTimeout(timeout);
          resolver(data);
        },
      };
      setPromiseResolvers({ ...promiseResolvers, ...newPromiseResolvers });
    });

    const newCache = {
      [`${productId}-${action}`]: {
        status: 'pending',
        payload: stringifiedPayload,
        promise,
      },
    };
    setCache({ ...cache, ...newCache });
    return promise;
  };

  useEffect(() => {
    subscribeToNotifications(user?.userId as string, async (notification: any) => {
      if (promiseResolvers[notification.body.triggerEventId]) {
        if (notification?.body?.payload?.respPayloadCacheId) {
          const res = await fetchLargePayload({
            respPayloadCacheId: notification?.body?.payload?.respPayloadCacheId,
          }).unwrap();
          notification.body.payload = JSON.parse(JSON.parse(res));
        }

        cache[`${notification.productId}-${notification.body.eventType}`] = 'complete';
        promiseResolvers[notification.body.triggerEventId](notification);
      }
    });
    return () => {};
  }, [cache, fetchLargePayload, promiseResolvers, user?.userId]);

  if (!user) {
    getNotifData = () => {
      throw new Error(
        '"user" is not defined by the time this hook attempted to fire an event. Please ensure user is defined before initializing this hook.',
      );
    };
  }

  return { getNotifData };
}

export type SnsFacadeReturn = ReturnType<typeof useAwaitableFacade>;

function serviceReducer(action: string, service: string) {
  let componentTypes;
  switch (service) {
    case 'listing':
      componentTypes = ['ListingSvc', action];
      return componentTypes;
    case 'acg':
      componentTypes = ['AcgSvc', action];
      return componentTypes;
    case 'darkWebScan':
      componentTypes = ['DarkWebScan', action];
      return componentTypes;
    case 'mailbox':
      componentTypes = ['updateMailbox', action];
      return componentTypes;
    default:
      return [action];
  }
}
