import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Link, Icon } from '@companydotcom/potion';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { useSource } from '@companydotcom/providers';
import { Listing } from '@companydotcom/types';
import { isEmpty } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { BLPProfileFlow } from '../blp-profile-flow';
import { FirstTimeTileFlow } from '../first-time-tile-flow';
import { useAppDispatch, useAwaitableFacade } from '../../../hooks';
import { updateUserAccount } from '../../../features/users';
import { useGetGlobalUserQuery, useGetUserTilesQuery } from '../../../services/user/user-api';
import { useCheckVerificationStatusMutation } from '../../../services/listings/listing-api';
// import ListingSvc from '../../../services/listings/listing-svc';

interface LocationStateProps {
  initialStep?: number;
}
export interface BusinessListingsProfileProps {
  handleClose?: any;
}

export const BusinessListingsProfile: React.FC<BusinessListingsProfileProps> = ({
  handleClose,
}) => {
  const source = useSource();
  const { data: globalUser } = useGetGlobalUserQuery();
  const { data: userTiles } = useGetUserTilesQuery(
    globalUser
      ? {
          userId: globalUser?.userId,
          locale: source?.i18nConfig?.lng,
        }
      : skipToken,
  );
  const [checkVerificationStatus] = useCheckVerificationStatusMutation();
  const snsInterface = useAwaitableFacade(globalUser, 'listing');
  const location = useLocation();
  const state = location.state as LocationStateProps;
  const dispatch = useAppDispatch();
  const [firstTimeTileFlow, setFirstTimeTileFlow] = useState(isEmpty(globalUser?.account?.listing));

  const [fttfInitialStep, setFttfInitialStep] = useState(
    (() => {
      switch (globalUser?.account?.listing?.gmbStatus) {
        case 'optIn':
          return 'business-verification'; // business-verification step;
        case 'pendingDirectVerification':
          return 'verification-code'; // verification-code step';
        default:
          if (state?.initialStep) {
            return state?.initialStep;
          }
          return 0;
      }
    })(),
  );
  const [verificationOption, setVerificationOption] = useState('');
  const [listingId, setListingId] = useState(globalUser?.account?.listing?.id);
  const [listingUrl, setListingUrl] = useState(globalUser?.account?.listing?.listingUrl);
  const [listing, setListing] = useState<Listing>(globalUser?.account?.listing || ({} as Listing));
  const [showRecSteps, setShowRecSteps] = useState(true);
  const [showPendingVerification, setShowPendingVerification] = useState(
    listing
      ? listing.gmbStatus === 'pendingExternalVerification' || listing.gmbStatus === 'externalClaim'
      : false,
  );
  const [isSecondChanceGmb, setSecondChanceGmb] = useState(false);
  const [BLPTile, setBLPTile]: any = useState({});
  // TODO: very confused why this is set in state, not touching as part of refactor
  // const [listingSvc] = useState(ListingSvc(globalUser as User));

  const checkStatus = useCallback(async () => {
    if (listingId) {
      await checkVerificationStatus({ listingId, snsInterface })
        .unwrap()
        .then(possiblyUpdatedListing => {
          if (possiblyUpdatedListing.gmbStatus === 'verified') {
            setListing(possiblyUpdatedListing);
            dispatch(updateUserAccount({ listing }));
          }
        })
        .catch(err => console.log('Rejected! Error checking verification status: ', err));
    }
  }, [checkVerificationStatus, dispatch, listing, listingId, snsInterface]);

  useEffect(() => {
    dispatch(updateUserAccount({ listingId }));
  }, [dispatch, listingId]);

  useEffect(() => {
    let mounted = true;
    if (listing && mounted) {
      dispatch(updateUserAccount({ listing }));

      const listingIsPendingVerification =
        listing.gmbStatus === 'pendingExternalVerification' ||
        listing.gmbStatus === 'externalClaim';

      if (listingIsPendingVerification) {
        checkStatus();
      }
      // if (listingIsPendingVerification) {
      //   listingSvc.checkVerificationStatus(listing.id).then((possiblyUpdatedListing: Listing) => {
      //     if (possiblyUpdatedListing.gmbStatus === 'verified') {
      //       setListing(possiblyUpdatedListing);
      //       dispatch(updateUserAccount({ listing }));
      //     }
      //   });
      // }
      setShowPendingVerification(listingIsPendingVerification);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restartGmbProcess = () => {
    setSecondChanceGmb(true);
    setFirstTimeTileFlow(true);
    setFttfInitialStep(0);
  };

  useEffect(() => {
    if (userTiles) {
      const tile = userTiles?.find?.(t => t?.tileSlug === 'business-listings-profile');
      setBLPTile(tile);
    }
  }, [userTiles]);

  return (
    <PageLayout>
      <PageLayoutContent>
        <Box maxWidth={1100} width="full" className="business-listings-profile">
          {!firstTimeTileFlow && listingUrl && (
            <Box sx={{ textAlign: 'right' }} mb={[0, null, 10]}>
              <Button
                as={Link}
                isExternal
                href={listingUrl}
                leftIcon={<Icon as={FontAwesomeIcon} boxSize={4} icon={faArrowUpRightFromSquare} />}
              >
                View Live Listing
              </Button>
            </Box>
          )}
          {firstTimeTileFlow ? (
            <FirstTimeTileFlow
              initialStep={fttfInitialStep}
              sharedComponentState={{
                verificationOption,
                setVerificationOption,
                listingId,
                setListingId,
                setListingUrl,
                user: globalUser,
                handleClose,
                setFirstTimeTileFlow,
                listing: listing || {},
                setListing,
                isSecondChanceGmb,
                setShowPendingVerification,
                BLPTile,
                // listingSvc,
              }}
            />
          ) : (
            <BLPProfileFlow
              sharedComponentState={{
                user: globalUser,
                listing,
                setListing,
                showPendingVerification,
                BLPTile,
                restartGmbProcess,
                setShowRecSteps,
                showRecSteps,
                // listingSvc,
              }}
            />
          )}
        </Box>
      </PageLayoutContent>
    </PageLayout>
  );
};
