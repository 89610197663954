export interface Days {
  name: string;
  id: number;
  googleName: string;
  timeSeries: TimeSeries[];
  isOpen: boolean;
  hours: Hour[];
}

export interface Hour {
  name: string;
  id: number;
  googleName: string;
  busy: boolean;
}

export interface TimeSeries {
  startHourId: number;
  endHourId: number;
}
const defaultTimeSeries = {
  startHourId: -1,
  endHourId: -1,
};

const initialHours = [
  { name: '12:00 AM', id: 1, googleName: '00:00', busy: false },
  { name: '12:30 AM', id: 2, googleName: '00:30', busy: false },
  { name: '1:00 AM', id: 3, googleName: '01:00', busy: false },
  { name: '1:30 AM', id: 4, googleName: '01:30', busy: false },
  { name: '2:00 AM', id: 5, googleName: '02:00', busy: false },
  { name: '2:30 AM', id: 6, googleName: '02:30', busy: false },
  { name: '3:00 AM', id: 7, googleName: '03:00', busy: false },
  { name: '3:30 AM', id: 8, googleName: '03:30', busy: false },
  { name: '4:00 AM', id: 9, googleName: '04:00', busy: false },
  { name: '4:30 AM', id: 10, googleName: '04:30', busy: false },
  { name: '5:00 AM', id: 11, googleName: '05:00', busy: false },
  { name: '5:30 AM', id: 12, googleName: '05:30', busy: false },
  { name: '6:00 AM', id: 13, googleName: '06:00', busy: false },
  { name: '6:30 AM', id: 14, googleName: '06:30', busy: false },
  { name: '7:00 AM', id: 15, googleName: '07:00', busy: false },
  { name: '7:30 AM', id: 16, googleName: '07:30', busy: false },
  { name: '8:00 AM', id: 17, googleName: '08:00', busy: false },
  { name: '8:30 AM', id: 18, googleName: '08:30', busy: false },
  { name: '9:00 AM', id: 19, googleName: '09:00', busy: false },
  { name: '9:30 AM', id: 20, googleName: '09:30', busy: false },
  { name: '10:00 AM', id: 21, googleName: '10:00', busy: false },
  { name: '10:30 AM', id: 22, googleName: '10:30', busy: false },
  { name: '11:00 AM', id: 23, googleName: '11:00', busy: false },
  { name: '11:30 AM', id: 24, googleName: '11:30', busy: false },
  { name: '12:00 PM', id: 25, googleName: '12:00', busy: false },
  { name: '12:30 PM', id: 26, googleName: '12:30', busy: false },
  { name: '1:00 PM', id: 27, googleName: '13:00', busy: false },
  { name: '1:30 PM', id: 28, googleName: '13:30', busy: false },
  { name: '2:00 PM', id: 29, googleName: '14:00', busy: false },
  { name: '2:30 PM', id: 30, googleName: '14:30', busy: false },
  { name: '3:00 PM', id: 31, googleName: '15:00', busy: false },
  { name: '3:30 PM', id: 32, googleName: '15:30', busy: false },
  { name: '4:00 PM', id: 33, googleName: '16:00', busy: false },
  { name: '4:30 PM', id: 34, googleName: '16:30', busy: false },
  { name: '5:00 PM', id: 35, googleName: '17:00', busy: false },
  { name: '5:30 PM', id: 36, googleName: '17:30', busy: false },
  { name: '6:00 PM', id: 37, googleName: '18:00', busy: false },
  { name: '6:30 PM', id: 38, googleName: '18:30', busy: false },
  { name: '7:00 PM', id: 39, googleName: '19:00', busy: false },
  { name: '7:30 PM', id: 40, googleName: '19:30', busy: false },
  { name: '8:00 PM', id: 41, googleName: '20:00', busy: false },
  { name: '8:30 PM', id: 42, googleName: '20:30', busy: false },
  { name: '9:00 PM', id: 43, googleName: '21:00', busy: false },
  { name: '9:30 PM', id: 44, googleName: '21:30', busy: false },
  { name: '10:00 PM', id: 45, googleName: '22:00', busy: false },
  { name: '10:30 PM', id: 46, googleName: '22:30', busy: false },
  { name: '11:00 PM', id: 47, googleName: '23:00', busy: false },
  { name: '11:30 PM', id: 48, googleName: '23:30', busy: false },
  { name: '12:00 AM', id: 49, googleName: '00:00', busy: false },
];

const initialDays = [
  {
    name: 'Monday',
    id: 0,
    googleName: 'MONDAY',
    timeSeries: [defaultTimeSeries],
    isOpen: false,
    hours: initialHours,
  },
  {
    name: 'Tuesday',
    id: 1,
    googleName: 'TUESDAY',
    timeSeries: [defaultTimeSeries],
    isOpen: false,
    hours: initialHours,
  },
  {
    name: 'Wednesday',
    id: 2,
    googleName: 'WEDNESDAY',
    timeSeries: [defaultTimeSeries],
    isOpen: false,
    hours: initialHours,
  },
  {
    name: 'Thursday',
    id: 3,
    googleName: 'THURSDAY',
    timeSeries: [defaultTimeSeries],
    isOpen: false,
    hours: initialHours,
  },
  {
    name: 'Friday',
    id: 4,
    googleName: 'FRIDAY',
    timeSeries: [defaultTimeSeries],
    isOpen: false,
    hours: initialHours,
  },
  {
    name: 'Saturday',
    id: 5,
    googleName: 'SATURDAY',
    timeSeries: [defaultTimeSeries],
    isOpen: false,
    hours: initialHours,
  },
  {
    name: 'Sunday',
    id: 6,
    googleName: 'SUNDAY',
    timeSeries: [defaultTimeSeries],
    isOpen: false,
    hours: initialHours,
  },
];

// Company Directory
// hours: [
//   {
//     day: 'MON',
//     periods: [
//       {
//         from: '00:00',
//         to: '00:00',
//       },
//     ],
//     closed: true,
//   },
//   {
//     day: 'TUE',
//     periods: [
//       {
//         from: '08:00',
//         to: '18:00',
//       },
//     ],
//     closed: false,
//   },

// const googleSelectFormat = [..., {
//   "openDay": 'MONDAY',
//   "openTime": '00:00',
//   "closeDay": 'MONDAY',
//   "closeTime": '12:30',
// }]

// YEXT hours
// Contains the daily hours, holiday hours, and reopen date for the Entity.

// Each day is represented by a sub-field of hours. (e.g. monday, tuesday, etc.) Open times can be specified per day through the openIntervals field and the isClosed flag. Similarly, holiday hours are represented by the holidayHours sub-field. Setting the reopenDate sub-field indicates that the business is temporarily closed and will reopen on the specified date. SPECIAL CASES:

// To indicate that an Entity is open 24 hours on a specific day, set start to 00:00 and end to 23:59 in openIntervals for that day.
// To indicate that an Entity has split hours on specific day (e.g., open from 9:00 AM to 12:00 PM and again from 1:00 PM to 5:00 PM), supply up to two openIntervals values with non-overlapping sets of hours.
// If you are providing openIntervals, you may not set isClosed to true for that day.
// "hours": {
//   "friday": {
//      "isClosed": true,
//      "openIntervals": [
//        {
//          "end": "string",
//          "start": "string"
//        }
//      ]
//   }, // for midnight closure: unclear

const setHoursBusy = (hours: any, tsIndex: any, startHourId = -1, endHourId = -1) => {
  if (startHourId === -1 || endHourId === -1) {
    return hours;
  }
  hours.forEach(({ id, busy }: any) => {
    if (id >= 0 && id < startHourId && busy === `ts-${tsIndex}`) {
      hours[id - 1].busy = false;
    } else if (id >= startHourId && id <= endHourId) {
      hours[id - 1].busy = `ts-${tsIndex}`;
    } else if (id > endHourId && id < hours.length && busy === `ts-${tsIndex}`) {
      hours[id - 1].busy = false;
    }
  });
};

const recalculateHours = (day: any) => {
  day.hours.forEach(({ id }: any) => {
    day.hours[id - 1].busy = false;
  });
  day.timeSeries.forEach(({ startHourId, endHourId }: any, tsIdx: any) => {
    setHoursBusy(day.hours, tsIdx, startHourId, endHourId);
  });
};

/* eslint-disable no-plusplus */
const onStartHourSet = (days: any, dayId: any, tsIndex: any, hourId: any) => {
  const today = days.find(({ id }: any) => id === dayId);
  const thisTs = today.timeSeries[tsIndex];
  thisTs.startHourId = Number(hourId);
  thisTs.startError = false;
  setHoursBusy(today.hours.slice(), tsIndex, thisTs.startHourId, thisTs.endHourId);
  return days;
};

const onEndHourSet = (days: any, dayId: any, tsIndex: any, hourId: any) => {
  const today = days.find(({ id }: any) => id === dayId);
  const thisTs = today.timeSeries[tsIndex];
  thisTs.endHourId = Number(hourId);
  thisTs.endError = false;
  setHoursBusy(today.hours.slice(), tsIndex, thisTs.startHourId, thisTs.endHourId);
  return days;
};

function addTimeSeriesToDay(days: any, dayId: any) {
  days.find(({ id }: any) => id === dayId).timeSeries.push({ ...defaultTimeSeries });
  return days;
}

function removeTimeSeriesFromDays(days: any, dayId: any, tsIdx: any) {
  days.find(({ id }: any) => id === dayId).timeSeries.splice(tsIdx, 1);
  recalculateHours(days.find(({ id }: any) => id === dayId));
  return days;
}

function toggleDayOpen(days: any, dayId: any, isOpen: any) {
  days.find(({ id }: any) => id === dayId).isOpen = !!isOpen;
  return days;
}

function markErrors(days: any) {
  days.forEach((day: any) => {
    if (day.isOpen) {
      day.timeSeries
        .sort((a: any, b: any) => {
          if (a.startHourId === -1 && a.endHourId === -1) {
            return 100; // unfilled ts goes last
          }
          return a.startHourId - b.startHourId;
        })
        .forEach((ts: any, tsIdx: any) => {
          if (ts.startHourId !== -1 && ts.endHourId !== -1) {
            // these are fine
          } else if (tsIdx !== 0 && ts.startHourId === -1 && ts.endHourId === -1) {
            // these are also fine
          } else {
            ts.startError = ts.startHourId === -1;
            ts.endError = ts.endHourId === -1;
          }
        });
    }
  });
  return days;
}

const timeSeriesReducer = (days: any, action: any) => {
  switch (action.type) {
    case 'TOGGLE_DAY_OPEN':
      return toggleDayOpen(days, action.payload.dayId, action.payload.isOpen).slice();
    case 'ADD_SERIES':
      return addTimeSeriesToDay(days, action.payload.dayId).slice();
    case 'REMOVE_SERIES':
      return removeTimeSeriesFromDays(days, action.payload.dayId, action.payload.tsIdx).slice();
    case 'SET_START_HOUR':
      return onStartHourSet(
        days,
        action.payload.dayId,
        action.payload.tsIdx,
        action.payload.hourId,
      ).slice();
    case 'SET_END_HOUR':
      return onEndHourSet(
        days,
        action.payload.dayId,
        action.payload.tsIdx,
        action.payload.hourId,
      ).slice();
    case 'MARK_ERRORS':
      return markErrors(days).slice();
    default:
      return days;
  }
};

/* eslint-enable no-plusplus */
export function createInitialState(existingListing: any) {
  const days = JSON.parse(JSON.stringify(initialDays)); // break object equivalence deeply on all base objects

  if (existingListing) {
    days.forEach((day: any) => {
      const existingDay = existingListing[day.id];

      if (existingDay.closed) {
        day.isOpen = false;
      } else {
        day.isOpen = true;
        day.timeSeries = [];
        existingDay.periods.forEach((per: any) => {
          day.timeSeries.push({
            startHourId: day.hours.find(({ googleName }: any) => googleName === per.from).id,
            endHourId: day.hours.find(({ googleName }: any) => googleName === per.to).id,
          });
        });
        recalculateHours(day);
      }
    });
  }
  return days;
}

export function exportSubmissionFormat(days: any) {
  return days.reduce((result: any, day: any) => {
    let invalid = false;
    if (!result) {
      return false;
    }
    if (day.isOpen) {
      const dayRecord: any = {
        day: day.googleName,
        closed: false,
        periods: [],
      };
      day.timeSeries
        .sort((a: any, b: any) => {
          if (a.startHourId === -1 && a.endHourId === -1) {
            return 100; // unfilled ts goes last
          }
          return a.startHourId - b.startHourId;
        })
        .forEach((ts: any, tsIdx: any) => {
          if (ts.endHourId !== -1) {
            // good if timeseries is all filled
            dayRecord.periods.push({
              from: day.hours[ts.startHourId - 1].googleName,
              to: day.hours[ts.endHourId - 1].googleName,
            });
          } else if (tsIdx !== 0 && ts.startHourId === -1 && ts.endHourId === -1) {
            // or omit a timeseries that was added but is empty
            // still flag 1st timeseries left empty when day is open
          } else {
            invalid = true;
          }
        });
      result.push(dayRecord);
    } else {
      result.push({
        day: day.googleName,
        closed: true,
        periods: [{ from: '00:00', to: '00:00' }],
      });
    }
    return invalid ? false : result;
  }, []);
}

export default timeSeriesReducer;

// import React, { useState, useReducer } from 'react';
// import {
//   Box,
//   Flex,
//   Text,
//   Button,
//   Heading,
//   Center,
//   Select,
//   useToast,
//   Checkbox,
//   Switch,
// } from '@companydotcom/potion';
// import _get from 'lodash/get';
// import { User, Listing } from '@companydotcom/types';
// import { RSelect } from '@companydotcom/ui';
// import daysReducer, {
//   createInitialState,
//   exportSubmissionFormat,
//   Days,
//   TimeSeries,
// } from '../utils/hours-helpers';
// import { AddItemButton, PendingVerification } from './shared-blpcomponents';

// interface HoursOfOperationProps {
//   user?: User;
//   listing?: Listing;
//   setListing?: (arg: Listing) => void;
//   showPendingVerification?: boolean;
//   listingSvc?: any;
// }

// export const HoursOfOperation = ({
//   user,
//   listing,
//   setListing,
//   showPendingVerification,
//   listingSvc,
// }: HoursOfOperationProps) => {
//   const [days, dispatch] = useReducer(daysReducer, createInitialState(listing?.hours));
//   const [submitting, setSubmitting] = useState(false);
//   const [showHours, setShowHours] = useState(true);
//   const toast = useToast();

//   const handleSubmit = () => {
//     setSubmitting(true);
//     const result = exportSubmissionFormat(days);
//     if (!result) {
//       dispatch({ type: 'MARK_ERRORS' });
//     } else {
//       const updateParams = {
//         hours: result,
//         showHours,
//         id: user?.account?.listingId,
//       };
//       listingSvc
//         .updateListing(updateParams)
//         .then((updatedListing: Listing) => {
//           setListing?.(updatedListing);
//           toast({
//             description: 'Your changes have been synced!',
//             status: 'success',
//             duration: 9000,
//             isClosable: true,
//             position: 'bottom-right',
//           });
//           setSubmitting(false);
//         })
//         .catch((err: string) => {
//           console.log('error submitting: ', err);
//           setSubmitting(false);
//         });
//     }
//   };

//   return (
//     <Center
//       className="blp-hours-of-operation"
//       flexDirection="column"
//       pt={[12, null, 3]}
//       pb={[12, null, 15]}
//       width="full"
//       px={4}
//       textAlign="center"
//       justifyContent="flex-start"
//     >
//       {showPendingVerification && <PendingVerification />}
//       <Flex maxWidth={679} flexDirection="column" alignItems="center">
//         <Box width={[0.8]} sx={{ textAlign: 'center' }}>
//           <Heading as="h1" size="hs-lg">
//             Hours of Operation
//           </Heading>
//           <Heading as="h2" size="hs-md" mt={[5]}>
//             Add your hours of operations so that customers know when the business is open.
//           </Heading>
//         </Box>
//         <Box width="full" pt={[12]} px={[3, null, '0px']}>
//           <Text textAlign="left" textStyle="lg" fontWeight="medium" mb={4}>
//             Hours of Operation
//           </Text>
//           {days.map((day: Days) => (
//             <SeriesBlock
//               key={day.id}
//               day={day}
//               onClose={(tsIdx: number) =>
//                 dispatch({ type: 'REMOVE_SERIES', payload: { dayId: day.id, tsIdx } })
//               }
//               onStartHourSet={(tsIdx: number, hourId: number) => {
//                 dispatch({
//                   type: 'SET_START_HOUR',
//                   payload: { dayId: day.id, tsIdx, hourId },
//                 });
//               }}
//               onEndHourSet={(tsIdx: number, hourId: number) =>
//                 dispatch({ type: 'SET_END_HOUR', payload: { dayId: day.id, tsIdx, hourId } })
//               }
//               onToggleDayOpen={() =>
//                 dispatch({
//                   type: 'TOGGLE_DAY_OPEN',
//                   payload: { dayId: day.id, isOpen: !day.isOpen },
//                 })
//               }
//               addTimeSeries={() => {
//                 dispatch({ type: 'ADD_SERIES', payload: { dayId: day.id } });
//               }}
//             />
//           ))}
//           <Flex flexDirection={['row']} justifyContent="space-between">
//             <Checkbox isChecked={showHours} onChange={() => setShowHours(!showHours)}>
//               Show Hours of Operation
//             </Checkbox>
//           </Flex>
//           <Box mt={14}>
//             <Button
//               size="lg"
//               onClick={handleSubmit}
//               variant="outline"
//               isLoading={submitting}
//               isDisabled={submitting || showPendingVerification}
//             >
//               Save
//             </Button>
//           </Box>
//         </Box>
//       </Flex>
//     </Center>
//   );
// };

// interface SeriesBlockProps {
//   day: Days;
//   onClose: any;
//   onStartHourSet: any;
//   onEndHourSet: any;
//   onToggleDayOpen: any;
//   addTimeSeries: any;
// }

// export const SeriesBlock = ({
//   day,
//   onClose,
//   onStartHourSet,
//   onEndHourSet,
//   onToggleDayOpen,
//   addTimeSeries,
// }: SeriesBlockProps) => {
//   const getStartHours = (ts: TimeSeries) => {
//     return ts.startHourId === -1
//       ? [{ name: '--:-- AM', id: -1, disabled: true }].concat(
//           day.hours.map(hour => ({
//             ...hour,
//             disabled:
//               // (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
//               ts.endHourId !== -1 && hour.id <= ts.endHourId,
//             disabledText: 'unavailable',
//           })),
//         )
//       : day.hours.map(hour => ({
//           ...hour,
//           disabled:
//             // (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
//             ts.endHourId !== -1 && hour.id <= ts.endHourId,
//           disabledText: 'unavailable',
//         }));
//   };

//   return (
//     <Flex
//       width="full"
//       flexDirection={['column', null, 'row']}
//       flexWrap={['wrap']}
//       justifyContent={['space-between']}
//       alignItems={['flex-start']}
//       mb={[4]}
//       px={[4, 6]}
//       sx={{
//         borderRadius: '4px',
//         border: 'solid 1px #e2e6e8',
//       }}
//     >
//       <Flex
//         flexDirection="row"
//         width="250px"
//         height={[day.isOpen ? null : '90px', null, '90px']}
//         pt={[day.isOpen ? 8 : null, null, '0px']}
//         mr={[0, null, 4]}
//         justifyContent="flex-start"
//         alignItems="center"
//       >
//         <Text width={['120px']} marginRight={[2]}>
//           {day.name}
//         </Text>
//         <Box ml={4}>
//           <Switch
//             isChecked={day.isOpen}
//             value={day.isOpen.toString()}
//             onChange={onToggleDayOpen}
//             color={day.isOpen ? 'blue.500' : 'gray.500'}
//           >
//             {day.isOpen ? 'OPEN' : 'CLOSED'}
//           </Switch>
//         </Box>
//       </Flex>
//       {day.isOpen ? (
//         <Flex py={[1, null, 3]} flexDirection="column" alignItems="flex-end">
//           {day.timeSeries.map((ts, tsIdx: number) => (
//             <Flex
//               key={tsIdx}
//               flexWrap="wrap"
//               maxWidth="400px"
//               flexDirection="row"
//               justifyContent="flex-end"
//               alignItems="baseline"
//             >
//               <Flex
//                 flexDirection={['row']}
//                 width={[0.5]}
//                 minWidth="200px"
//                 justifyContent={['flex-end', null, 'flex-end']}
//                 alignItems="baseline"
//               >
//                 <Text textStyle="md">From</Text>
//                 <Select
//                   name="startHour"
//                   value={ts.startHourId}
//                   isDisabled={ts.startHourId === -1}
//                   onChange={(e: any) => {
//                     onStartHourSet(tsIdx, e.target.value);
//                   }}
//                   // helperText={ts.startError ? 'Please pick a time' : ''}
//                 >
//                   {getStartHours(ts).map(o => (
//                     <option disabled={o.disabled} value={o.id}>
//                       {/* @ts-ignore */}
//                       {o.googleName}
//                     </option>
//                   ))}
//                   {/* {ts.startHourId === -1
//                     ? [{ name: '--:-- AM', id: -1, disabled: true }].concat(
//                         day.hours.map(hour => ({
//                           ...hour,
//                           disabled:
//                             // (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
//                             ts.endHourId !== -1 && hour.id <= ts.endHourId,
//                           disabledText: 'unavailable',
//                         })),
//                       )
//                     : day.hours.map(hour => ({
//                         ...hour,
//                         disabled:
//                           // (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
//                           ts.endHourId !== -1 && hour.id <= ts.endHourId,
//                         disabledText: 'unavailable',
//                       }))} */}
//                 </Select>
//               </Flex>
//               <Flex
//                 flexDirection={['row']}
//                 width={[0.5]}
//                 minWidth="200px"
//                 justifyContent={['flex-end', null, 'flex-end']}
//                 alignItems="baseline"
//               >
//                 <Text textStyle="md">To</Text>
//                 <Select
//                   name="endHour"
//                   isDisabled={ts.startHourId === -1}
//                   value={ts.endHourId}
//                   onChange={(e: any) => {
//                     onEndHourSet(tsIdx, e.target.value);
//                   }}
//                   // helperText={ts.endError ? 'Please pick a time' : ''}
//                 >
//                   <option>blah</option>
//                   {/* {ts.endHourId === -1
//                     ? [{ name: '--:-- AM', id: -1, disabled: true }].concat(
//                         day.hours.map(hour => ({
//                           ...hour,
//                           disabled:
//                             // (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
//                             ts.startHourId !== -1 && hour.id <= ts.startHourId,
//                           disabledText: 'unavailable',
//                         })),
//                       )
//                     : day.hours.map(hour => ({
//                         ...hour,
//                         disabled:
//                           // (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
//                           ts.startHourId !== -1 && hour.id <= ts.startHourId,
//                         disabledText: 'unavailable',
//                       }))} */}
//                 </Select>
//               </Flex>
//             </Flex>
//           ))}
//           <Flex
//             width="full"
//             my={[4]}
//             mr={-5}
//             flexDirection={['row']}
//             justifyContent="flex-end"
//             alignItems="baseline"
//           >
//             <Flex flexDirection={['row']} justifyContent={['flex-end']} width="full">
//               <AddItemButton
//                 trigger={
//                   <Text
//                     as="button"
//                     color="dashboard_primary_color"
//                     fontSize={3}
//                     fontWeight="normal"
//                     sx={{
//                       cursor: 'pointer',
//                       appearance: 'none',
//                       background: 'none',
//                       border: 'none',
//                     }}
//                   >
//                     {day.timeSeries.length > 1 ? 'remove hours' : 'add more hours'}
//                   </Text>
//                 }
//                 clicked={day.timeSeries.length > 1}
//                 onClick={(e: any) => {
//                   if (day.timeSeries.length > 1) {
//                     onClose(day.timeSeries.length - 1);
//                   } else {
//                     addTimeSeries(e);
//                   }
//                 }}
//               />
//             </Flex>
//           </Flex>
//         </Flex>
//       ) : (
//         <Flex width={[1, null, 0.6]} />
//       )}
//     </Flex>
//   );
// };
