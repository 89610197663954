import React from 'react';
import {
  Center,
  CenterProps,
  Box,
  Heading,
  BoxProps,
  HStack,
  StackProps,
  Text,
  Grid,
  GridProps,
  GridItemProps,
  GridItem,
  TextProps,
  Divider,
  Stack,
  useMediaQuery,
  Flex,
  FlexProps,
  StackDivider,
} from '@companydotcom/potion';
import { FontAwesomeIcon, PageDivider } from '@companydotcom/ui';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

/**
 * CheckoutLayout
 *
 * React component that houses the Checkout component.
 *
 */
export interface CheckoutLayoutProps extends CenterProps {}
export const CheckoutLayout: React.FC<CheckoutLayoutProps> = props => {
  const { children, ...rest } = props;

  return (
    <Center
      className="checkout__checkoutLayoutContainer"
      flexDirection="column"
      bg={['transparent', 'white']}
      px={[0, 4]}
      py={[12]}
    >
      <Box width="full" maxW={908} {...rest}>
        {children}
      </Box>
    </Center>
  );
};

/**
 * CheckoutHeader
 *
 * React component that houses the Checkout Header and Page Divider components.
 *
 */
export interface CheckoutHeaderProps extends BoxProps {
  source?: 'acg' | 'facecake';
}
export const CheckoutHeader: React.FC<CheckoutHeaderProps> = props => {
  const { source, children, ...rest } = props;
  const [isMobile] = useMediaQuery('(max-width: 30em)');

  return (
    <Box
      className="checkout__checkoutHeader"
      width="full"
      textAlign={source === 'acg' ? ['center', 'left'] : 'left'}
      {...rest}
    >
      <Heading
        size={source === 'acg' ? (isMobile ? 'hs-md' : 'hs-2xl') : isMobile ? 'hs2xl' : 'hs-2xl'}
      >
        {children}
      </Heading>
      <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
    </Box>
  );
};

/**
 * CheckoutAlertContent
 *
 * React component that houses the any Alert and/or disclaimer and SecureAndEncrypted components.
 *
 */
export interface CheckoutAlertContentProps extends CenterProps {}
export const CheckoutAlertContent: React.FC<CheckoutAlertContentProps> = props => {
  const { children, ...rest } = props;

  return (
    <Center
      className="checkout__checkoutAlertContent"
      my={[8, 8]}
      flexDir="column"
      textAlign="center"
      {...rest}
    >
      {children}
    </Center>
  );
};

// CHECKOUT ORDER SCREENS //

/**
 * CheckoutSecureAndEncrypted
 *
 * React component that houses the Secure and Encrypted Order icon and text.
 *
 */

export const CheckoutSecureAndEncrypted = (props: StackProps) => {
  const { t } = useTranslation();

  return (
    <HStack
      className="checkout__checkoutOrderStep_secureAndEncrypted"
      display={['none', 'flex']}
      alignItems="baseline"
      {...props}
    >
      <FontAwesomeIcon icon={faLock} />
      <Text textStyle="md" fontWeight="bold">
        {t('common.misc.orderSecureAndEncrypted')}
      </Text>
    </HStack>
  );
};

/**
 * CheckoutContent
 *
 * React component that houses the Payment Options and Order Summary components.
 *
 */
export interface CheckoutContentProps extends GridProps {}
export const CheckoutContent: React.FC<CheckoutContentProps> = props => {
  const { children, ...rest } = props;

  return (
    <Grid
      className="checkout__checkoutOrderStep_checkoutContent"
      gridTemplateColumns={['1fr', null, '1fr 292px']}
      gap={[6, 8]}
      mb={8}
      {...rest}
    >
      {children}
    </Grid>
  );
};

/**
 * CheckoutPaymentOptionsHeader
 *
 * React component that houses the Payment Options Header component.
 *
 */
export interface CheckoutPaymentOptionsProps extends TextProps {
  source?: 'facecake' | 'acg';
}
export const CheckoutPaymentOptionsHeader = (props: CheckoutPaymentOptionsProps) => {
  const { source, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Text
      className="checkout__checkoutOrderStep_paymentOptionsHeader"
      textAlign={source === 'acg' ? ['center', 'left'] : 'left'}
      fontWeight="semibold"
      textStyle="3xl"
      opacity={0.7}
      mb={6}
      {...rest}
    >
      {t('acg.checkout.orderStep.paymentOptions')}
    </Text>
  );
};

/**
 * CheckoutOrderStepOrderSummary
 *
 * React component that houses the Payment Options Header component.
 *
 */
export interface CheckoutOrderStepOrderSummaryProps extends GridItemProps {
  bgColor?: any;
}
export const CheckoutOrderStepOrderSummary: React.FC<
  CheckoutOrderStepOrderSummaryProps
> = props => {
  const { bgColor, children, ...rest } = props;
  const { t } = useTranslation();

  return (
    <GridItem
      className="checkout__checkoutOrderStep_orderSummary"
      maxW={['100%', 292]}
      width="full"
      bgColor={['white', bgColor]}
      py={6}
      px={8}
      borderRadius="md"
      boxShadow={['md', 'none']}
      {...rest}
    >
      <Text fontWeight="semibold" textStyle="3xl" opacity={0.7}>
        {t('acg.checkout.orderStep.yourOrder')}
      </Text>
      {children}
    </GridItem>
  );
};

/**
 * CheckoutDiscountAndPlaceOrder
 *
 * React component that houses the Discount component and/or Place Order button.
 *
 */
export interface CheckoutDiscountAndPlaceOrderProps extends BoxProps {
  discount?: Boolean;
  borderColor?: 'black';
}
export const CheckoutDiscountAndPlaceOrder: React.FC<
  CheckoutDiscountAndPlaceOrderProps
> = props => {
  const { discount, borderColor, children, ...rest } = props;

  return (
    <Box className="checkout__checkoutOrderStep_discountAndPlaceOrder" {...rest}>
      <Divider
        display={['none', 'block']}
        borderColor={borderColor === 'black' ? 'black' : 'inherit'}
      />
      <Stack
        direction={['column', null, null, 'row']}
        justify={discount ? 'space-between' : 'flex-end'}
        align="center"
        pt={[4, 8]}
        spacing={[4, null, null, 0]}
      >
        {children}
      </Stack>
    </Box>
  );
};

// CHECKOUT ORDER CONFIRMATION SCREENS //

/**
 * OrderConfirmationHeaderContainer
 *
 * React component that houses the Header component and Print Icon component on the Checkout Order Confirmation screen.
 *
 */
export interface OrderConfirmationHeaderContainerProps extends BoxProps {}
export const OrderConfirmationHeaderContainer: React.FC<
  OrderConfirmationHeaderContainerProps
> = props => {
  const { children, ...rest } = props;

  return (
    <Box
      className="checkout__orderConfirmationStep_header"
      width="full"
      textAlign={['center', 'left']}
      {...rest}
    >
      <Stack direction="row" justify="space-between" display="flex">
        {children}
      </Stack>
    </Box>
  );
};

/**
 * OrderConfirmationContentContainer
 *
 * React component that houses the Order Information and Payment Information components on the Checkout Order Confirmation screen.
 *
 */
export interface OrderConfirmationContentContainerProps extends FlexProps {}
export const OrderConfirmationContentContainer: React.FC<
  OrderConfirmationContentContainerProps
> = props => {
  const { children, ...rest } = props;

  return (
    <Flex
      className="checkout__orderConfirmationStep_orderContentContainer"
      mx={[0, 3]}
      mt={12}
      direction="column"
      textAlign={['center', 'left']}
      {...rest}
    >
      {children}
    </Flex>
  );
};

/**
 * OrderInformationContainer
 *
 * React component that houses the Order Information component on the Checkout Order Confirmation screen.
 *
 */
export interface OrderInformationContainerProps extends BoxProps {}
export const OrderInformationContainer: React.FC<OrderInformationContainerProps> = props => {
  const { children, ...rest } = props;
  const [isMobile] = useMediaQuery('(max-width: 30em)');

  return (
    <Box
      className="checkout__orderConfirmationStep_orderInfoContainer"
      textAlign="left"
      bg={['white', 'transparent']}
      p={[4, 0]}
      boxShadow={['lg', 'none']}
      id="mainContentBoxShadow"
      {...rest}
    >
      {children}
      {isMobile ? null : <Divider mt={12} />}
    </Box>
  );
};

/**
 * OrderInformationHeader
 *
 * React component that houses the Order Information component on the Checkout Order Confirmation screen.
 *
 */
export interface OrderInformationHeaderProps extends BoxProps {
  fontWeight?: string;
  opacity?: string;
}
export const OrderInformationHeader = (props: OrderInformationHeaderProps) => {
  const { opacity, fontWeight, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Heading
      className="checkout__orderConfirmationStep_orderInfoHeader"
      id="orderInfoHeader"
      size="hs-xl"
      fontWeight={fontWeight}
      opacity={opacity}
      mb={6}
      {...rest}
    >
      {t(`acg.checkout.orderConfirmationStep.orderInfo.header`)}
    </Heading>
  );
};

/**
 * OrderInformationContent
 *
 * React component that houses the Order Information content (Items and Prices) on the Checkout Order Confirmation screen.
 *
 */
export interface OrderInformationContentProps extends BoxProps {}
export const OrderInformationContent: React.FC<OrderInformationContentProps> = props => {
  const { children, ...rest } = props;

  return (
    <Stack
      className="checkout__orderConfirmationStep_orderInfoContent"
      id="order-content"
      divider={<StackDivider />}
      spacing={6}
      {...rest}
    >
      {children}
    </Stack>
  );
};

/**
 * ItemAndPrice
 *
 * React component that houses the Order Items and Price headings on the Checkout Order Confirmation screen.
 *
 */
export const ItemAndPrice = (props: StackProps) => {
  const { t } = useTranslation();

  return (
    <HStack
      className="checkout__orderConfirmationStep_itemAndPrice"
      id="itemAndPrice"
      display={['none', 'inherit']}
      justify="space-between"
      {...props}
    >
      <Heading size="hs-md" fontWeight="700">
        {t(`acg.checkout.orderConfirmationStep.orderInfo.item`)}
      </Heading>
      <Heading size="hs-md" fontWeight="700">
        {t(`acg.checkout.orderConfirmationStep.orderInfo.price`)}
      </Heading>
    </HStack>
  );
};

/**
 * ItemRow
 *
 * React component that houses an Order Item Row on the Checkout Order Confirmation screen.
 *
 */
export const ItemRow = (props: StackProps) => {
  const { children, ...rest } = props;

  return (
    <Stack
      className="checkout__orderConfirmationStep_itemRow"
      direction={['column', 'row']}
      justify="space-between"
      spacing={[3, 10]}
      {...rest}
    >
      {children}
    </Stack>
  );
};

/**
 * OrderTotalContainer
 *
 * React component that houses the Order SubTotal and Total on the Checkout Order Confirmation screen.
 *
 */
export interface OrderTotalContainerProps extends StackProps {}
export const OrderTotalContainer: React.FC<OrderTotalContainerProps> = props => {
  const { children, ...rest } = props;

  return (
    <Stack
      className="checkout__orderConfirmationStep_totalContainer"
      alignSelf={['center', 'flex-end']}
      spacing={6}
      width={['full', 'auto']}
      id="totalContainer"
      {...rest}
    >
      {children}
    </Stack>
  );
};

/**
 * OrderSubTotal
 *
 * React component that houses the Order SubTotal on the Checkout Order Confirmation screen.
 *
 */
export interface OrderSubTotalProps extends StackProps {}
export const OrderSubTotal: React.FC<OrderSubTotalProps> = props => {
  const { children, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Stack
      className="checkout__orderConfirmationStep_subTotal"
      spacing={[0, 16]}
      justify="space-between"
      direction={['column', 'row']}
      id="subtotal"
      {...rest}
    >
      <Text textStyle="lg">{t(`acg.checkout.orderConfirmationStep.orderInfo.subtotal`)}</Text>
      {children}
    </Stack>
  );
};

/**
 * OrderTotal
 *
 * React component that houses the Order Total on the Checkout Order Confirmation screen.
 *
 */
export interface OrderTotalProps extends StackProps {}
export const OrderTotal: React.FC<OrderTotalProps> = props => {
  const { children, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Stack
      className="checkout__orderConfirmationStep_total"
      direction={['column', 'row']}
      width={['full', 'auto']}
      justify="space-between"
      {...rest}
    >
      <Heading size="hs-lg" fontWeight="700">
        {t(`acg.checkout.orderConfirmationStep.orderInfo.total`)}
      </Heading>
      {children}
    </Stack>
  );
};
