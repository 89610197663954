import React, { useEffect, useReducer } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTabStepper,
  useMediaQuery,
} from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ExtendedSubscriptionStatus, User } from '@companydotcom/types';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { CurrentSubscriptionStep, AvailableProductStep } from '../steps';
import { getAllSubscriptionData } from '../utils/subscription-helpers';
import {
  subscriptionReducer,
  initialSubscriptionState,
} from '../../../state/reducers/subscriptions-reducer';
import { MobilePageTabs } from '../../../components/elements';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { useGetUserProductsQuery } from '../../../services/product/product-api';

const subscriptionSteps = (t: TFunction<'translations', undefined>) => [
  {
    slug: 'current-subscription',
    label: t('miop.subscriptions.currentSubscription.header'),
    component: <CurrentSubscriptionStep />,
  },
  {
    slug: 'available-products',
    label: t('miop.subscriptions.availableProducts.header'),
    component: <AvailableProductStep />,
  },
];

export const Subscriptions = () => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const { data: userProducts } = useGetUserProductsQuery(
    globalUser ? { userId: globalUser?.userId } : skipToken,
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const steps = subscriptionSteps(t);
  const [subscriptionState, dispatchSubscriptionState] = useReducer(
    subscriptionReducer,
    initialSubscriptionState,
  );
  const { tabIndex, handleTabsChange, goToStep } = useTabStepper({ steps });
  const [isMobile] = useMediaQuery('(max-width: 48em)');

  useEffect(() => {
    const fetchProductSubscriptionData = async (
      globalUser: User,
      userProducts: ExtendedSubscriptionStatus,
    ) => {
      const subscriptionData = await getAllSubscriptionData(globalUser, userProducts);
      dispatchSubscriptionState({
        type: 'UPDATE_STATE',
        payload: {
          currentSubscriptionPrice: subscriptionData.currentSubscriptionPrice,
          currentSubscriptionProducts: subscriptionData.currentSubscriptionProducts,
          availableProducts: subscriptionData.availableProducts,
          allSubscriptionData: [
            ...subscriptionData.currentSubscriptionProducts,
            ...subscriptionData.availableProducts,
          ],
        },
      });
    };
    if (globalUser && globalUser?.userId && globalUser?.account?.Products && userProducts) {
      fetchProductSubscriptionData(globalUser, userProducts);
    }
  }, [globalUser, userProducts]);

  const sharedProps = {
    dispatchSubscriptionState,
    subscriptionState,
    user: globalUser,
  };

  //* Redirect child users
  if (globalUser?.role === 'user') {
    navigate('/');
  }

  return (
    <PageLayout>
      <PageLayoutContent>
        <Tabs variant="page" index={tabIndex} onChange={handleTabsChange}>
          {isMobile ? (
            <MobilePageTabs steps={steps} tabIndex={tabIndex} goToStep={goToStep} />
          ) : (
            <TabList>
              {steps.map(step => (
                <Tab key={step.slug}>{step.label}</Tab>
              ))}
            </TabList>
          )}
          <TabPanels mt={[0, null, null, 4]} width="full">
            {steps.map(step => (
              <TabPanel padding={0} key={step.slug}>
                {React.cloneElement(step.component, sharedProps)}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </PageLayoutContent>
    </PageLayout>
  );
};
