import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Image, Center } from '@companydotcom/potion';

import type { TileComponentProps } from '../../types';

export interface TileDocIconProps extends TileComponentProps {}

export const TileDocIcon: React.FC<TileDocIconProps> = props => {
  const { eventData } = props;
  const { t } = useTranslation();
  const docsReady = t('tiles.docIcon.docsReady');
  const docsWaiting = t('tiles.docIcon.docsWaiting');
  const payload = eventData?.body?.payload?.['Final Packet'];
  const text = payload && payload.length > 0 ? docsReady : docsWaiting;
  const imageUrl =
    payload && payload.length > 0
      ? `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/legalinc/images/Legal2.svg`
      : `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/legalinc/images/LegalAnimation.svg`;

  return (
    <Center className="tile-doc-icon" height="100%" flexDirection="column">
      <Image src={imageUrl} />
      <Text mt={[6]}>{text}</Text>
    </Center>
  );
};
