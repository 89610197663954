import { useGetUserQuery } from '../../../services/user/user-api';

// on the click of launch Questionnaire open in new tab
export const launchLink = (accountId?: string) => {
  window.open(`${process.env.REACT_APP_LEGALINC}?accountId=${accountId}`, '_blank');
};

const getAccountId = async (userId: string, setAccountId: any) => {
  // if enrollment user gives null value...call it self in iteration
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: globalUser } = useGetUserQuery({ userId });
  if (!globalUser) {
    getAccountId(userId, setAccountId); // again call same method
  } else {
    if (globalUser.accountId) {
      launchLink(globalUser?.accountId);
    }

    setAccountId(globalUser.accountId);
  }
};

export const launchQuestionnaire = async (
  userId: string,
  setAccountId: any,
  accountId?: string,
) => {
  if (!accountId) {
    getAccountId(userId, setAccountId);
  }
};
