import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['sidenavContainer', 'mainContentContainer'];

// Controls the width of the Sidenav when expanded or closed
const baseStyleSideNavContainer: SystemStyleObject = {
  display: ['none', null, 'inherit'],
  zIndex: 'docked',
};

const baseStyleMainContainer: SystemStyleObject = {
  minHeight: '100vh',
  position: 'relative',
  overflowX: 'hidden',
  backgroundColor: 'gray.50',
  overflowY: 'hidden',
  pt: [6, null, 10],
  px: [4, null, 8],
};

const baseStyle = {
  sidenavContainer: baseStyleSideNavContainer,
  mainContainer: baseStyleMainContainer,
};

export default {
  parts,
  baseStyle,
};
