import gql from 'graphql-tag';

export const SUBSCRIBE_TO_APPOINTMENTS = gql`
  subscription OnCreateUserAppointment($userId: String!) {
    onCreateUserAppointment(userId: $userId) {
      appointmentId
      userId
      productId
      dateCreated
      dateUpdated
      dateExpires
      appointmentTime
      fulfilled
      url
      metadata
    }
  }
`;
