import React, { useState, useEffect, useCallback } from 'react';
import { Button, Text, Link, Icon } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { NavLink } from 'react-router-dom';
import { useSource, useMitt } from '@companydotcom/providers';
import { companyConstants, companyHelpers, platformHelpers } from '@companydotcom/helpers';
import { badgeCountParser } from './utils';
import type { TileComponentProps } from '../tile-component';

export interface TileButtonProps extends TileComponentProps {
  onClick?: (event: any) => void;
  disableCarousel?: boolean;
  children?: any;
}

export const TileButton: React.FC<TileButtonProps> = props => {
  const {
    componentTheme,
    eventData,
    params,
    pubEvents,
    eventHandler,
    onClick,
    operatingSystem,
    disabled: disabledConfig,
    variant,
    disableCarousel,
    href,
    tile,
    children,
    text,
    gtmId,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const source = useSource();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [badgeCount, setBadgeCount] = useState<number | undefined>(undefined);
  const [fetchedOnClick, setFetchedOnClick] = useState<any>(null);

  const { emitter } = useMitt();

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.closeModal, (evt: any) => {
      if (tile?.tileId === evt.tileId) {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 5500);
      }
    });

    return () => {
      emitter.off(companyConstants.platformEvents.closeModal);
    };
  }, [emitter, tile?.tileId]);

  useEffect(() => {
    // TODO: This logic is specific to one tile, LegalInc.  Needs to be refactored to be generic
    if (
      eventData?.body?.payload?.['Final Packet'] &&
      Array.isArray(eventData?.body.payload['Final Packet'])
    ) {
      setFetchedOnClick(eventData?.body.payload['Final Packet'][0].url);
    }

    // This checks the params property on the TileButton component,
    // sees if theres an hrefDataPath, then returns the value of the given accessor from eventData
    const foundFetchedHref = platformHelpers.getComponentParamValue(
      params || [],
      'hrefDataPath',
      eventData,
    );
    if (foundFetchedHref) {
      setFetchedOnClick(foundFetchedHref);
    }
  }, [eventData, params]);

  useEffect(() => {
    if (eventData && params?.some(param => param?.key === 'eventDataKey')) {
      const count = badgeCountParser(eventData, params);
      if (count > 0) {
        setBadgeCount(count);
      }
    }
  }, [params, eventData]);

  // These only check the first key/value pair!!
  const actionType = pubEvents && pubEvents?.[0]?.actions?.[0]?.type;
  const actionHref =
    actionType === companyConstants.tileActions.navigate
      ? companyHelpers.getValidHTTPUrl(pubEvents?.[0]?.actions?.[0]?.actionParams?.[0]?.value)
      : undefined;
  const actionNavEvent =
    actionType === companyConstants.tileActions.navEvent
      ? pubEvents?.[0]?.actions?.[0]?.actionParams?.[0]?.value
      : undefined;
  // NOTE: stepReference is a key that can exist on navigate pubEvents in Dynamo, that is sent along with react-router to set the correct view on flows
  const stepReference =
    pubEvents?.[0]?.actions?.[0]?.actionParams?.[1]?.key === 'stepReference'
      ? pubEvents?.[0]?.actions?.[0]?.actionParams?.[1]?.value
      : undefined;

  const handleClick = useCallback(
    (event: any) => {
      if (
        eventHandler &&
        actionType !== companyConstants.tileActions.navigate &&
        actionType !== companyConstants.tileActions.navEvent
      ) {
        eventHandler(pubEvents, event);
      }

      if (fetchedOnClick) {
        return window.open(fetchedOnClick, '_blank');
      }

      if (onClick) {
        event.preventDefault();
        return onClick(event);
      }

      setIsLoading(
        actionType === companyConstants.tileActions.navigateSSO ||
          actionType === companyConstants.tileActions.navigatePurchase ||
          actionType === companyConstants.tileActions.navigate ||
          actionType === companyConstants.tileActions.activate ||
          actionType === companyConstants.tileActions.transitionState ||
          actionType === companyConstants.tileActions.checkVerification,
      );
      // Give 5 seconds before re-trying to redirect again
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
      return true;
    },
    [eventHandler, actionType, fetchedOnClick, onClick, pubEvents],
  );

  if (operatingSystem && navigator.platform.indexOf(operatingSystem) === -1) {
    return null;
  }

  const isLinkProps = () => {
    const url = href || actionHref || actionNavEvent;

    if (!url || actionType === companyConstants.tileActions.skynetSSO) {
      return {};
    }

    //if needing to go to a step out of order OR
    // if url is a relative path, its an internal link
    if (url && url.charAt(0) == '/') {
      return {
        as: NavLink,
        to: url,
        state: { toStep: stepReference },
      };
    }

    return {
      isExternal: (href || actionHref) ?? undefined,
      as: href || actionHref ? Link : undefined,
      href: href || actionHref,
    };
  };

  if (disabledConfig && source?.sourceId && disabledConfig.sources.includes(source?.sourceId)) {
    return (
      <Text textStyle="md" textAlign="center">
        {disabledConfig.disabledText}
      </Text>
    );
  }
  if (variant === 'IconButton') {
    // @ts-ignore
    return (
      // @ts-ignore
      <Button
        size="md"
        rightIcon={<Icon as={FontAwesomeIcon} boxSize={4} icon={faArrowUpRightFromSquare} />}
        // If its an href event, we dont need a click handler
        onClick={href || actionHref ? undefined : handleClick}
        // badgeCount={badgeCount}
        {...isLinkProps()}
        sx={componentTheme}
      >
        {text || children}
      </Button>
    );
  }

  return (
    // @ts-ignore
    <Button
      variant={variant === 'disabled' ? 'tile' : variant || 'tile'}
      size="md"
      isLoading={variant === 'pending' || isLoading || eventData?.fetching}
      isDisabled={variant === 'disabled' || disableCarousel}
      id={gtmId || undefined}
      // If its an href event, we dont need a click handler
      onClick={href || actionHref ? undefined : handleClick}
      // badgeCount={badgeCount}
      {...isLinkProps()}
      sx={componentTheme}
    >
      {text || children}
    </Button>
  );
};
