import * as React from 'react';
import {
  potion,
  forwardRef,
  HTMLPotionProps,
  omitThemingProps,
  PropsOf,
  ThemingProps,
  useMultiStyleConfig,
} from '../../system';
import { SystemProps, SystemStyleObject } from '../../styled-system';
import { callAll, cx, Omit, __DEV__ } from '../../utils';
import { useCheckboxGroupContext } from './checkbox-group';
import { CheckboxIcon } from './checkbox-icon';
import { useCheckbox, UseCheckboxProps } from './use-checkbox';

const CheckboxControl = potion('span', {
  baseStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'top',
    userSelect: 'none',
    flexShrink: 0,
  },
});

const Label = potion('label', {
  baseStyle: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'top',
    position: 'relative',
    _disabled: {
      cursor: 'not-allowed',
    },
  },
});

type CheckboxControlProps = Omit<HTMLPotionProps<'div'>, keyof UseCheckboxProps>;

type BaseInputProps = Pick<PropsOf<'input'>, 'onBlur' | 'checked' | 'defaultChecked'>;

export interface CheckboxProps
  extends CheckboxControlProps,
    BaseInputProps,
    ThemingProps<'Checkbox'>,
    UseCheckboxProps {
  /**
   * The spacing between the checkbox and its label text
   * @default 0.5rem
   * @type SystemProps["marginLeft"]
   */
  spacing?: SystemProps['marginLeft'];
  /**
   * The color of the checkbox icon when checked or indeterminate
   */
  iconColor?: string;
  /**
   * The size of the checkbox icon when checked or indeterminate
   */
  iconSize?: string | number;
  /**
   * The checked icon to use
   *
   * @type React.ReactElement
   * @default CheckboxIcon
   */
  icon?: React.ReactElement;
}

/**
 * Checkbox
 *
 * React component used in forms when a user needs to select
 * multiple values from several options.
 */
export const Checkbox = forwardRef<CheckboxProps, 'input'>((props, ref) => {
  const group = useCheckboxGroupContext();

  const mergedProps = { ...group, ...props } as CheckboxProps;
  const styles = useMultiStyleConfig('Checkbox', mergedProps);

  const ownProps = omitThemingProps(props);

  const {
    spacing = '0.5rem',
    className,
    children,
    iconColor,
    iconSize,
    icon = <CheckboxIcon />,
    isChecked: isCheckedProp,
    isDisabled = group?.isDisabled,
    onChange: onChangeProp,
    ...rest
  } = ownProps;

  let isChecked = isCheckedProp;
  if (group?.value && ownProps.value) {
    isChecked = group.value.includes(ownProps.value);
  }

  let onChange = onChangeProp;
  if (group?.onChange && ownProps.value) {
    onChange = callAll(group.onChange, onChangeProp);
  }

  const { state, getInputProps, getCheckboxProps, getLabelProps, getRootProps } = useCheckbox({
    ...rest,
    isDisabled,
    isChecked,
    onChange,
  });

  const iconStyles: SystemStyleObject = React.useMemo(
    () => ({
      opacity: state.isChecked || state.isIndeterminate ? 1 : 0,
      transform: state.isChecked || state.isIndeterminate ? 'scale(1)' : 'scale(0.95)',
      fontSize: iconSize,
      color: iconColor,
      ...styles.icon,
    }),
    [iconColor, iconSize, state.isChecked, state.isIndeterminate, styles.icon],
  );

  const clonedIcon = React.cloneElement(icon, {
    __css: iconStyles,
    isIndeterminate: state.isIndeterminate,
    isChecked: state.isChecked,
  });

  return (
    <Label
      __css={styles.container}
      className={cx('potion-checkbox', className)}
      {...getRootProps()}
    >
      <input className="potion-checkbox__input" {...getInputProps({}, ref)} />
      <CheckboxControl
        __css={styles.control}
        className="potion-checkbox__control"
        {...getCheckboxProps()}
      >
        {clonedIcon}
      </CheckboxControl>
      {children && (
        <potion.span
          className="potion-checkbox__label"
          {...getLabelProps()}
          __css={{
            marginStart: spacing,
            ...styles.label,
          }}
        >
          {children}
        </potion.span>
      )}
    </Label>
  );
});

if (__DEV__) {
  Checkbox.displayName = 'Checkbox';
}
