"use strict";
/**
 *
 * @param argument
 * @param message - Optional message to display
 * @description This function is a wrapper around the Array.find method to ensure its type safe.
 * @example ensure(product.ratePlans.find((plan: IRatePlan) => plan.allowVariableSeatCount)).price;
 * @see https://stackoverflow.com/questions/54738221/typescript-array-find-possibly-undefind/54738437
 */
exports.__esModule = true;
exports.ensure = void 0;
var ensure = function (argument, message) {
    if (message === void 0) { message = 'This value was promised to be there.'; }
    if (argument === undefined || argument === null) {
        throw new TypeError(message);
    }
    return argument;
};
exports.ensure = ensure;
