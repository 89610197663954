const handleNavEvent = (navEvent: any) => {
  const { type, metadata } = navEvent;
  switch (true) {
    case metadata.component === 'profile':
      return {
        type,
        metadata: {
          ...metadata,
          height: '713px',
        },
      };
    case metadata.component === 'password':
      return {
        type,
        metadata: {
          ...metadata,
          height: '778px',
        },
      };
    case metadata.component === 'account':
      return {
        type,
        metadata: {
          ...metadata,
          height: '1237px',
        },
      };
    case metadata.component === 'subscriptions':
      return {
        type,
        metadata: {
          ...metadata,
          height: '1504px',
        },
      };
    case !!type:
      return { ...navEvent };
    default:
      return { ...navEvent };
  }
};

const handleUrlNavEvent = (url: string, navLinks: any) => {
  const settingsGroup = navLinks.find((navGroup: any) => navGroup.title === 'Settings');
  const navLink = settingsGroup.links.find((link: any) => link.url === url);
  return {
    type: 'openSubpage',
    metadata: {
      url: navLink.url,
      component: navLink.component,
      label: navLink.label,
    },
  };
};

export { handleNavEvent, handleUrlNavEvent };
