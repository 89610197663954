import React from 'react';
import { PageLayout, PageLayoutContent } from '../../../components/layouts';
import { DarkWebScan } from '../../../features/dark-web-scan/steps/dark-web-scan';

export const DarkWebScanPage: React.FC = () => {
  return (
    <PageLayout>
      <PageLayoutContent>
        <DarkWebScan />
      </PageLayoutContent>
    </PageLayout>
  );
};
