import React from 'react';
import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import _toUpper from 'lodash/toUpper';
import { useTranslation } from 'react-i18next';
import { Image, Text, Link, HStack, VStack } from '@companydotcom/potion';
import { slugToNameMap } from '../utils';

type Props = {
  report: {
    slug?: string;
    listingUrl?: string;
    syncStatus?: string;
  };
};
export const Directory = (props: Props) => {
  const { report } = props;
  const { t } = useTranslation();

  const nameFilter = (name: any) => {
    let newName = slugToNameMap?.[name];
    if (!newName) {
      newName = _startCase(_toLower(name?.replace(/_/g, ' ')));
    }
    return newName;
  };

  return (
    <HStack width={['80%', null, '50%']} spacing={0}>
      <Image
        mr={[2, null, 7]}
        src={`${process.env.REACT_APP_IMG_URL}/visibility-assets/static/${_toUpper(
          report?.slug,
        )}.png`}
        fallbackSrc={`${process.env.REACT_APP_IMG_URL}/visibility-assets/static/logo-missing.svg`}
        boxSize={['40px', null, '72px']}
      />
      <VStack textAlign="left" spacing={0}>
        <Text fontWeight="medium" textStyle={['md', null, 'xl']}>
          {nameFilter(report?.slug)}
        </Text>
        {report?.listingUrl === null || report?.syncStatus === 'fail' ? null : (
          <Link href={report?.listingUrl} isExternal width="full" fontSize={['sm', null, 'md']}>
            {t('components.directory.viewListing')}
          </Link>
        )}
      </VStack>
    </HStack>
  );
};
