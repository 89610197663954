import React from 'react';
import { Text, Flex, Box, Heading, Link } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import { NavLink } from 'react-router-dom';
import { useEventData } from '@companydotcom/ui';
import { TileButton } from '../tile-button';
import { TileText } from '../tile-text';
import type { TileComponentProps } from '../../types';

const Loader = () => (
  <ContentLoader
    speed={2}
    width={184}
    height={56}
    viewBox="0 0 184 56"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
    <rect x="0" y="0" rx="0" ry="0" width="184" height="24" />
    <rect x="32" y="32" rx="0" ry="0" width="120" height="24" />
  </ContentLoader>
);

export interface NextAvailableScanProps {
  available: boolean;
  firstScan: boolean;
  scansAvailable: number;
  nextAvailableDate?: string;
}

export interface TileNextAvailableScanProps extends TileComponentProps {}

export const TileNextAvailableScan: React.FC<TileNextAvailableScanProps> = props => {
  const { componentTheme, eventData } = props;
  const { t } = useTranslation();
  const { loading, error, data } = useEventData<NextAvailableScanProps>(eventData);

  const renderComponent = (results: any) => {
    // If tile is loading
    if (loading && !error) {
      return (
        <>
          <Text textStyle="md" mt={4} mb={5}>
            Date of Next Available Scan:
          </Text>
          <Loader />
          <Box mt="auto">
            <TileButton variant="disabled" text={t('containers.darkWebScan.form.submit')} />
          </Box>
          <Link as={NavLink} to="/dark-web-scan" mt={4}>
            View Previous Scan
          </Link>
        </>
      );
    }

    // if user hasn't run a scan yet
    if (results && results?.firstScan === true) {
      return (
        <>
          <TileText text={t('containers.darkWebScan.form.activeData')} />
          <Box mt="auto">
            <TileButton href="/dark-web-scan" text={t('containers.darkWebScan.form.submit')} />
          </Box>
        </>
      );
    }

    // If no scan is available
    if (results && !results.available) {
      return (
        <>
          <Text textStyle="md" mt={4} mb={5}>
            Date of Next Available Scan:
          </Text>
          <Heading textAlign="center" size="hs-md" sx={{ maxWidth: 228 }}>
            {results?.nextAvailableDate || ''}
          </Heading>
          <Box mt="auto">
            <TileButton variant="disabled" text={t('containers.darkWebScan.form.submit')} />
          </Box>
          <Link as={NavLink} to="/dark-web-scan" mt={4}>
            View Previous Scan
          </Link>
        </>
      );
    }

    // If scan is available
    if (results && results.available && results.scansAvailable >= 1) {
      return (
        <>
          <Text textStyle="md" mt={4} mb={5}>
            Date of Next Available Scan:
          </Text>
          <Heading textAlign="center" color="green.400" size="hs-md" sx={{ maxWidth: 228 }}>
            {results?.nextAvailableDate || ''}
          </Heading>
          <Box mt="auto">
            <TileButton href="/dark-web-scan" text={t('containers.darkWebScan.form.submit')} />
          </Box>
          <Link as={NavLink} to="/dark-web-scan" mt={4}>
            View Previous Scan
          </Link>
        </>
      );
    }

    return null;
  };

  return (
    <Flex
      className="dark-web-scan-next-available-scan"
      width="full"
      height="100%"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      sx={componentTheme}
    >
      {error ? (
        <>
          <Text textStyle="md" mt={4} mb={5}>
            Date of Next Available Scan:
          </Text>
          <Text maxWidth={238} color="red.500">
            Something went wrong. Please try again.
          </Text>
          <Box mt="auto">
            <TileButton text={t('containers.darkWebScan.form.submit')} />
          </Box>
          <Link as={NavLink} to="/dark-web-scan" mt={4}>
            View Previous Scan
          </Link>
        </>
      ) : (
        renderComponent(data)
      )}
    </Flex>
  );
};
