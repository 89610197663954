import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useMultiStyleConfig,
  Icon,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  useMediaQuery,
  useTabStepper,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation, TFunction } from 'react-i18next';
import { useSource } from '@companydotcom/providers';
import { PageLayout, PageLayoutHeader, PageLayoutContent } from '../../../components/layouts';
import { useUserProfileContext } from '../../../providers/user-profile-context';
import { isAddNewUserAvailable } from '../utils';
import { AdditionalUsersStep } from './additional-users-step';
import { MyProfileStep } from './my-profile-step';
import { AddNewUserFlow } from '../../../features/add-new-user-flow';
import { MobilePageTabs } from '../../../components/elements';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';

const userProfileSteps = (t: TFunction<'translations', undefined>) => [
  {
    slug: 'my-profile',
    label: t('miop.userProfile.myProfileStep.header'),
    component: <MyProfileStep />,
  },
  {
    slug: 'additional-users',
    label: t('miop.userProfile.additionalUsersStep.header'),
    component: <AdditionalUsersStep />,
  },
];

interface UserProfileProps {}

export const UserProfile: React.FC<UserProfileProps> = props => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const source = useSource();
  const styles = useMultiStyleConfig('UserProfile', props);
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 48em)');

  const isLoading = globalUser && globalUser.account === undefined;

  // Only show tabs if user is not a child and if a child has been added to the account
  const shouldShowAdditionalUsersTab =
    (!isLoading &&
      globalUser?.role !== 'user' &&
      globalUser?.account?.users &&
      globalUser?.account?.users?.length > 1) ||
    (!isLoading &&
      userProfileState.filteredUsers &&
      globalUser?.role !== 'user' &&
      userProfileState.filteredUsers.length > 0);

  const allowAddUser =
    source.sourceTiles && globalUser?.role !== 'user' && isAddNewUserAvailable(source?.sourceTiles);

  const toggleModal = () => {
    dispatchUserProfileState?.({
      type: 'TOGGLE_NEW_USER_MODAL',
    });
  };

  const handleCloseModal = () => {
    toggleModal();
    userProfileState?.onModalClose?.();
    dispatchUserProfileState?.({ type: 'RESET_ALL_USER_PROFILE_STATE' });
  };

  const sharedUserFlowProps = {
    sourceId: source.sourceId,
    user: globalUser,
  };

  const steps = userProfileSteps(t);

  const { tabIndex, handleTabsChange, goToStep } = useTabStepper({
    steps: shouldShowAdditionalUsersTab ? steps : steps.slice(0, 1),
  });

  return (
    <>
      <PageLayout>
        {allowAddUser && (
          <PageLayoutHeader>
            <Button
              size="sm"
              leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} boxSize={5} />}
              sx={styles.addNewUserButton}
              onClick={toggleModal}
            >
              {t('miop.userProfile.addUserCta')}
            </Button>
          </PageLayoutHeader>
        )}
        <PageLayoutContent>
          <Tabs variant="page" index={tabIndex} onChange={handleTabsChange}>
            {isMobile && shouldShowAdditionalUsersTab ? (
              <MobilePageTabs steps={steps} tabIndex={tabIndex} goToStep={goToStep} />
            ) : (
              <TabList>
                {shouldShowAdditionalUsersTab &&
                  steps.map(step => <Tab key={step.slug}>{step.label}</Tab>)}
              </TabList>
            )}
            <TabPanels mt={4} width="full">
              {steps.map(step => (
                <TabPanel key={step.slug}>{step.component}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </PageLayoutContent>
      </PageLayout>
      <Modal isOpen={userProfileState.newUserFlowOpen!} onClose={handleCloseModal} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <AddNewUserFlow {...sharedUserFlowProps} />
        </ModalContent>
      </Modal>
    </>
  );
};
