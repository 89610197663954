import { platformHelpers } from '@companydotcom/helpers';
import { ActionParam } from '@companydotcom/types';

export const findChildConditionMatch = (
  switchParams: ActionParam[],
  children: any,
  eventData: any,
) => {
  if (!eventData || eventData.fetching) {
    return 0; // use first child as default/loading state
  }
  const eventCurrentValue = platformHelpers.getComponentParamValue(
    switchParams,
    'conditionPath',
    eventData,
  );

  const idx = children.findIndex((child: any) => {
    const childTargetValue = platformHelpers.getComponentParamValue(child.params, 'conditionValue');
    if (
      childTargetValue &&
      eventCurrentValue &&
      childTargetValue?.toString() === eventCurrentValue?.toString()
    ) {
      return true;
    }
    return 0;
  });
  return idx === -1 ? 0 : idx;
};
