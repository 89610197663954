import { User } from '@companydotcom/types';
import {
  getNotificationByEventType,
  getNotificationSubscription,
} from '../notification/notification-svc';
import { SnsFacadeReturn } from '../../hooks';
import { emptySplitApi } from '../../store/empty-split-api';
import { ProfileDataInput, ContactUsEmailInput, FacecakeStatuses } from './facecake.types';
import { RootState } from '../../store';
import { EventData } from '../notification/notification.types';

const tileData = {
  productId: 'user-facecake',
  tileId: 'user-facecake',
};

export const FaceCakeApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    submitProfileData: builder.mutation<
      any,
      { snsInterface: SnsFacadeReturn; payload: Partial<ProfileDataInput> }
    >({
      invalidatesTags: ['User'],
      queryFn: async ({ snsInterface, payload }) => {
        try {
          return snsInterface
            .getNotifData(tileData, 'transition', 'submitProfileData', payload, {}, true)
            .then(notif => {
              return notif
                ? { data: notif?.body }
                : {
                    error: true,
                    meta: { message: 'No response recieved while submitting profile data' },
                  };
            });
        } catch (error) {
          console.log(error);
          return { error: true, meta: { message: 'Error submitting facecake profile data' } };
        }
      },
    }),
    sendContactUsEmail: builder.mutation<any, { snsInterface: any; payload: ContactUsEmailInput }>({
      invalidatesTags: ['User'],
      queryFn: async ({ snsInterface, payload }) => {
        try {
          return snsInterface
            .getNotifData(tileData, 'transition', 'sendContactUsEmail', payload, {}, true)
            .then((notif: any) => {
              return notif
                ? { data: notif?.body }
                : {
                    error: true,
                    meta: { message: 'No response recieved while sending contact email' },
                  };
            });
        } catch (error) {
          console.log(error);
          return { error: true, meta: { message: 'Error sending contact email' } };
        }
      },
    }),
    getFacecakeStatus: builder.query<{ status: FacecakeStatuses }, { userId: string }>({
      queryFn: ({ userId }, { getState }) => {
        // Gets current users facecake status. Set upon first firing of webhook with the users id
        const currentUserStatus = (
          (getState() as RootState).api.queries[`getUser({"userId":"${userId}"})`]?.data as User
        )?.mads?.['user-facecake']?.find((m: any) => m?.key === 'status')?.value;

        return { data: { status: currentUserStatus } };
      },
      async onCacheEntryAdded(
        { userId },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        const subscription = getNotificationSubscription(userId).subscribe({
          next: async (eventData: EventData<FacecakeStatuses>) => {
            await cacheDataLoaded;

            try {
              const notification = getNotificationByEventType(eventData, 'getStatus');

              if (notification) {
                updateCachedData(draft => {
                  draft.status = notification.body.payload;
                });
              }
            } catch {}
          },
          error: (err: any) => {
            console.error(err);
          },
        });

        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        subscription.unsubscribe();
      },
    }),
  }),
});

export const {
  useSubmitProfileDataMutation,
  useSendContactUsEmailMutation,
  useGetFacecakeStatusQuery,
} = FaceCakeApi;
