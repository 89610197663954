import React from 'react';
import { Box, Heading, useMediaQuery, BoxProps } from '@companydotcom/potion';
import { PageDivider } from '@companydotcom/ui';

export interface PageHeadingProps extends BoxProps {
  heading: string;
}

export const PageHeading: React.FC<PageHeadingProps> = ({ heading, ...rest }) => {
  const [isMobile] = useMediaQuery('(max-width: 30em)');

  return (
    <Box className="page-heading" {...rest}>
      <Heading size={isMobile ? 'hs-md' : 'hs-2xl'} textAlign={['center', 'left']}>
        {heading}
      </Heading>
      <PageDivider mt={[2, 4]} mx={['auto', 'inherit']} />
    </Box>
  );
};
