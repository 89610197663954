import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { SelectField, useEventData, AppSpinner } from '@companydotcom/ui';
import { Flex, Text, List, ListItem, Center, Heading } from '@companydotcom/potion';

import type { TileComponentProps } from '../../types';

const defaultOptions = [
  {
    id: '',
    label: '',
    selected: true,
    disabled: true,
    hidden: true,
  },
];

export interface TileSelectTableProps extends TileComponentProps {}

export const TileSelectTable: React.FC<TileSelectTableProps> = props => {
  const { eventData, variant, params, dataConfig, description } = props;
  const { loading, data } = useEventData<any>(eventData);

  const dataOptions = useCallback(() => {
    return data?.[dataConfig?.[0]?.dataKey as string] ?? [];
  }, [data, dataConfig]);

  const [selectedOption, setSelectedOption] = useState({});
  const [options, setOptions] = useState(defaultOptions);

  const { register, handleSubmit } = useForm({ defaultValues: { select: '' } });

  useEffect(() => {
    if (defaultOptions?.[0]?.label) {
      // @ts-ignore
      defaultOptions[0].label = description;
      setOptions(defaultOptions.concat(dataOptions()));
    }
  }, [dataOptions, description]);

  const onSubmit = (values: any) => {
    // @ts-ignore
    setSelectedOption(options?.find(({ name }) => name === values?.select));
  };

  return (
    <Flex className="tile-select-table" flexDirection="column" width="full" px={4}>
      <SelectField register={register} name="select" onChange={handleSubmit(onSubmit)}>
        {options.map(o => (
          <option id={o.id} value={o.id}>
            {o.label}
          </option>
        ))}
      </SelectField>
      <List
        width="full"
        mt={2}
        spacing={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        borderRadius="lg"
        border={variant === 'noBorder' ? 'none' : `solid 1px`}
        borderColor="cyan.600"
      >
        {params?.map(({ key, value }: any, i) => (
          <>
            {loading ? (
              <AppSpinner />
            ) : (
              <ListItem
                width="full"
                borderBottom={
                  params?.length - 1 === i || variant === 'noBorder' ? 'none' : `solid 1px`
                }
                borderColor="cyan.600"
                backgroundColor={
                  variant === 'noBorder'
                    ? 'none'
                    : // create gradient effect down the list
                      `rgba(68, 159, 194, ${i + 1 >= 1 ? 0.15 - i * 0.05 : 0.15})`
                }
              >
                <Center width="full" px={2} minHeight={10}>
                  <Heading size="md" mr={[variant === 'noBorder' ? 4 : 8]}>
                    {selectedOption?.[key] || '- -'}
                  </Heading>
                  <Text textStyle="sm">{value}</Text>
                </Center>
              </ListItem>
            )}
          </>
        ))}
      </List>
    </Flex>
  );
};
