import React, { useState, useEffect } from 'react';
import { CircleEnvelopeIcon } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { useSource } from '@companydotcom/providers';
import { Box, Center, Text, Heading, Button } from '@companydotcom/potion';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { resetPasswordEmail } from '../../../services/enrollment/old-api/enrollment-svc';
import { getAuthUser } from '../../../services/auth/old-api/auth-svc';

const sendActivation = (email: string, source: string) =>
  resetPasswordEmail(email, source).catch(err => {
    console.log(err);
    throw new Error('Password email failed to send.');
  });

const getUser = (userId: string) =>
  getAuthUser(userId).catch(err => {
    console.log(err);
    throw new Error(`Problem fetching user (user ID: ${userId})`);
  });

// TODO: refactor to RTK, the getAuthUser call is not functional
export const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState();
  const [isResent, setIsResent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { t } = useTranslation();
  const source = useSource();
  const navigate = useNavigate();

  const userId = searchParams.get('userId');

  useEffect(() => {
    if (userId) {
      getUser(userId)
        .then(userInfo => {
          if (userInfo.last_password_reset) {
            navigate(process.env.REACT_APP_UNAUTH_REDIRECT ?? '/', { replace: true });
          } else {
            sendActivation(userInfo.email, source?.sourceId);
            setEmail(userInfo.email);
          }
        })
        .catch(err => console.error(err));
    }
  }, [navigate, source?.sourceId, userId]);

  const resendActivation = async () => {
    setIsSending(true);
    if (email) {
      await sendActivation(email, source?.sourceId).catch(err => console.error(err));
    }
    setIsSending(false);
    setIsResent(true);
  };

  return email ? (
    <Center
      maxWidth="2xl"
      flexDirection="column"
      layerStyle="formContainer"
      px={4}
      py={[8, null, 12]}
      textAlign="center"
    >
      <CircleEnvelopeIcon mb={6} />
      <Box maxW="md">
        {(isSending && (
          <Heading as="h1" size="hs-2xl">
            {t('subpage.passwordReset.sending')}
          </Heading>
        )) ||
          (isResent && (
            <Heading as="h1" size="hs-2xl">
              {t('subpage.passwordReset.emailResent')}
            </Heading>
          )) || (
            <Heading as="h1" size="hs-2xl">
              {t('subpage.passwordReset.almostThere')}
            </Heading>
          )}
        <Heading size="hs-lg" my={2}>
          {t('subpage.passwordReset.sentEmailTo', { email })}
        </Heading>
        <Text textStyle="md">{t('subpage.passwordReset.clickLinkInEmail')}</Text>
        <Text textStyle="sm" mt={12}>
          {t('subpage.passwordReset.cantFindEmail')}
          <Button fontSize="sm" variant="link" onClick={resendActivation} role="button">
            {t('subpage.passwordReset.sendAgain')}
          </Button>
          <br />
          {t('subpage.passwordReset.needHelp')}
        </Text>
      </Box>
    </Center>
  ) : null;
};
