import { useEffect, useCallback, useMemo, useState } from 'react';
import { useMitt } from '@companydotcom/providers';
import { useNavigate, useLocation } from 'react-router-dom';
import type { DataCollector } from '@companydotcom/types';
import { companyConstants } from '@companydotcom/helpers';
import {
  useGetGlobalUserQuery,
  useUpdateUserDataCollectorMutation,
} from '../../../services/user/user-api';
import {
  updateIsUserDataCollectorsEmpty,
  selectIsUserDataCollectorsEmpty,
} from '../data-collector-slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';

export const DataCollectorController = () => {
  const { emitter } = useMitt();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isUserDataCollectorsEmpty = useAppSelector(selectIsUserDataCollectorsEmpty);
  const [isDataCollectorRendered, setIsDataCollectorRendered] = useState(false);
  const { data: globalUser } = useGetGlobalUserQuery();
  const [updateUserDataCollector] = useUpdateUserDataCollectorMutation();

  const nextDc = useMemo(
    () =>
      !isUserDataCollectorsEmpty &&
      globalUser?.userDataCollectors?.find((dc: DataCollector | null) => dc?.trigger === 'auto'),
    [globalUser?.userDataCollectors, isUserDataCollectorsEmpty],
  );

  const handleTrigger = useCallback(
    (dcConfig: DataCollector) => {
      if (dcConfig) {
        if (dcConfig?.path) {
          navigate({ pathname: dcConfig?.path, search: location.search });
        } else if (dcConfig?.provider === 'dataCollector' && isDataCollectorRendered) {
          emitter.emit(companyConstants.platformEvents.dataCollectorInitiated, {
            eventIdentifier: dcConfig.dataCollectorSlug,
            allowModalClose: dcConfig.isDismissible,
            actions: [
              {
                type: 'triggerForm',
                actionParams: [{ key: 'formName', value: dcConfig.providerId }],
                skipDataValidation: true,
                eventIdentifier: dcConfig.providerId,
                allowClose: dcConfig.isDismissible,
              },
            ],
          });
        } else {
          emitter.emit(companyConstants.platformEvents.dataCollectorInitiated, {
            actions: [
              {
                type: 'publishDcComplete',
              },
            ],
          });
        }
      }
    },

    [isDataCollectorRendered, emitter, location.search, navigate],
  );

  useEffect(() => {
    if (nextDc) {
      handleTrigger(nextDc);
    }
  }, [handleTrigger, nextDc]);

  useEffect(() => {
    emitter.on('isDataCollectorRendered', () => {
      setIsDataCollectorRendered(true);
    });

    return () => {
      emitter.off('isDataCollectorRendered');
    };
  }, [emitter]);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.dataCollectorComplete, (e: any) => {
      if (globalUser?.userDataCollectors?.length && globalUser?.userDataCollectors?.length <= 1) {
        dispatch(updateIsUserDataCollectorsEmpty(true));
      }

      if (nextDc) {
        updateUserDataCollector({
          userId: globalUser?.userId as string,
          dataCollectorSlug: nextDc.dataCollectorSlug,
          markCompleted: true,
        })
          .unwrap()
          .then(() => {
            if (e?.path) {
              navigate(
                { pathname: e?.path, search: location?.search },
                { replace: e?.replace ?? false },
              );
            }
          })
          .catch(err => {
            console.log('ERROR UPDATING DC: ', err);
          });
      }
    });

    return () => {
      emitter.off(companyConstants.platformEvents.dataCollectorComplete);
    };
  }, [
    emitter,
    globalUser?.userDataCollectors?.length,
    globalUser?.userId,
    location?.search,
    navigate,
    nextDc,
    updateUserDataCollector,
    dispatch,
  ]);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.dataCollectorDismissed, (e: any) => {
      if (nextDc && e.eventIdentifier === nextDc.providerId) {
        updateUserDataCollector({
          userId: globalUser?.userId as string,
          dataCollectorSlug: nextDc.dataCollectorSlug,
          markDismissed: true,
        });
      }
    });

    return () => {
      emitter.off(companyConstants.platformEvents.dataCollectorDismissed);
    };
  }, [emitter, globalUser?.userId, nextDc, updateUserDataCollector]);

  return null;
};
