"use strict";
// This components purpose is to provide a global store for use with mocking stories and tests
exports.__esModule = true;
exports.MockData = void 0;
exports.MockData = {
    mockUser: {
        userId: '5f186fb3c27c130014e66daf',
        seoId: null,
        accountId: 'cdd370b5-c213-4dcb-941e-38bd5a4b2709',
        userCUIs: [
            {
                cuiId: '9da64954-d86c-40ac-929e-59730e79fd27',
                name: 'coco-yext-listings',
                priority: 1,
                type: 'tile',
                tileName: 'yext-business-listings-manager',
                trigger: 'peek',
                isActive: 'true',
                dataRequired: [
                    'firstName',
                    'lastName',
                    'phone',
                    'account.businessPrimary.name',
                    'account.businessPrimary.address.addressLine1',
                    'account.businessPrimary.address.city',
                    'account.businessPrimary.address.state',
                    'account.businessPrimary.address.zipCode',
                ],
                dateUpdated: 1571430407421,
                __typename: 'CUI'
            },
            {
                cuiId: '32d733f5-fc18-4f3f-9c64-f86c53443d25',
                name: 'coco-website-weebly',
                priority: 5,
                type: 'tile',
                tileName: 'website',
                trigger: 'peek',
                isActive: 'true',
                dataRequired: [
                    'firstName',
                    'userId',
                    'account.businessPrimary.name',
                    'account.businessPrimary.address.city',
                    'account.businessPrimary.address.zipCode',
                ],
                dateUpdated: 1579004964000,
                __typename: 'CUI'
            },
        ],
        appointments: [],
        account: {
            accountId: 'cdd370b5-c213-4dcb-941e-38bd5a4b2709',
            seoStatus: {
                gmbConnectStatus: 'Not listed',
                __typename: 'SeoStatus'
            },
            accountStatus: 'active',
            business: [
                {
                    businessId: '559b781c-8f9b-4b8f-aec2-38e568de74b0',
                    name: "Torchy's Tacos",
                    address: {
                        addressLine1: '1801 E 51st St Bldg D',
                        city: null,
                        state: null,
                        zipCode: '78723',
                        __typename: 'Address'
                    },
                    categories: null,
                    website: null,
                    __typename: 'Business'
                },
            ],
            businessPrimary: {
                businessId: '559b781c-8f9b-4b8f-aec2-38e568de74b0',
                name: "Torchy's Tacos",
                website: null,
                address: {
                    addressLine1: '1801 E 51st St Bldg D',
                    city: null,
                    state: null,
                    zipCode: '78723',
                    __typename: 'Address'
                },
                numberOfEmployees: null,
                categories: null,
                __typename: 'Business'
            },
            Products: [
                {
                    isActive: true,
                    ratePlanName: null,
                    productId: '4b92ba48-06ec-11e8-9306-120b17a64360',
                    __typename: 'AccountProduct'
                },
            ],
            listingId: 'a5e75581-a706-454c-9f7a-08898b9f7169',
            listing: {
                id: 'a5e75581-a706-454c-9f7a-08898b9f7169',
                name: "Torchy's T",
                address: '1025 Springdale',
                listingUrl: 'https://directory-plat.company-corp.com/business/view/torchys-t-1',
                hours: null,
                phones: [
                    {
                        type: 'MAIN',
                        number: '(940) 782-3440',
                        __typename: 'ListingPhone'
                    },
                ],
                specialOffer: null,
                hideAddress: false,
                zip: '78721',
                city: 'Austin',
                state: 'TN',
                country: 'US',
                categories: [
                    {
                        name: 'Tack shop',
                        publisher: null,
                        id: 'gcid:tack_shop',
                        fullName: 'Tack shop',
                        __typename: 'CategoryRecord'
                    },
                ],
                description: '',
                socialProfiles: null,
                images: null,
                videos: null,
                keywords: null,
                paymentOptions: null,
                emails: null,
                gmbStatus: 'optIn',
                gmbLocationId: null,
                services: null,
                languages: null,
                serviceArea: null,
                urls: [
                    {
                        url: '',
                        description: null,
                        type: 'WEBSITE',
                        __typename: 'ListingUrl'
                    },
                ],
                content: null,
                __typename: 'Listing'
            },
            loans: null,
            loanPrimary: null,
            zuoraPaymentMethodId: null,
            zuoraAccountId: null,
            dateCreated: 1595437007275,
            dateUpdated: 1595437229982,
            isWebFQDNCustom: false,
            isEmailFQDNCustom: false,
            fqdnCompany: null,
            fqdnCustom: null,
            hasFQDN: false,
            users: [
                {
                    isPrimary: true,
                    userId: '5f186fb3c27c130014e66daf',
                    __typename: 'AccountUser'
                },
                {
                    isPrimary: false,
                    userId: '5f186fb3c27c130014e66daf',
                    __typename: 'AccountUser'
                },
            ],
            vendorData: {
                'directory-svc-refactor': {},
                'nav-svc': {}
            },
            __typename: 'Account'
        },
        vendorData: '{"directory-svc-refactor":{},"nav-svc":{}}',
        products: '[{productId=dd7cc931-04ae-4d95-adfb-e30384cd03ad, dateActivated=1595437137595}, {productId=ed51229e-84be-481f-a755-3f5a571da49c, dateActivated=1595437151764}]',
        mailboxFQDN: null,
        mailbox: null,
        marketoPersonId: '1050979',
        firstName: 'Jacob',
        lastName: 'Granberry',
        avatar: null,
        email: 'jacob.granberry+7222020@company-corp.com',
        role: 'admin',
        phone: '5127826942',
        source: 'company',
        userStatus: 'active',
        userSettings: null,
        userTileStates: [
            {
                seqNo: null,
                isHidden: null,
                tileId: '0f1d6187-2a83-4002-a4c9-ba9c0ad57271',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: '190e526f-b197-448a-bf14-2e60a332099e',
                stateCurrent: 'buy',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: '1a0605d4-e7ee-4582-9ccd-c2fe23ce3bb5',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: '1dcf38f2-39b8-4b5a-8f9a-b3cbb1bc3767',
                stateCurrent: 'buy',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: '5806d8a3-8d41-4e8e-a9e0-bfdb7527ff01',
                stateCurrent: 'buy',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: '7303db67-795f-4d3a-86cf-83d965af578b',
                stateCurrent: 'buy',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: '839ffb44-658a-4082-89cc-2e9339083b02',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: '872f6138-3354-48d1-9c9a-2384df435265',
                stateCurrent: 'active',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: '8e2a1be0-27a5-4711-ae7b-d96ad3f3b206',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: '956c318f-334f-4640-8185-7519bc7be11b',
                stateCurrent: 'buy',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'a8624d5b-b207-49a5-9c2c-f3e658095d5e',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'aa8b6923-d113-42e8-934b-d4b2f42de10b',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'c2f9c2e3-d5c1-4e0c-be09-6b394157bf44',
                stateCurrent: 'buy',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'c30791ed-93a8-4623-80e6-fc3827f9a13f',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'd6129324-d5bc-4127-bbd6-0e9dbcf1517a',
                stateCurrent: 'buy',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'd8e39d82-4e4f-400b-89ee-0d8068f2961f',
                stateCurrent: 'buy',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'db4f02c1-3c13-4031-a6a9-8ac72de16a57',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'e01909a4-c206-4910-81ad-1ea5043475eb',
                stateCurrent: 'active',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'e47c52d5-c1d1-4840-b591-2991136a03f7',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
            {
                seqNo: null,
                isHidden: null,
                tileId: 'e7ecabf4-c572-4f25-beed-2f2d1a72ba38',
                stateCurrent: 'inactive',
                __typename: 'UserTileState'
            },
        ],
        userTileGroups: [],
        __typename: 'User'
    },
    mockListing: {
        urls: [{ type: 'WEBSITE', url: 'https://www.google.com' }]
    },
    mockProductData: {
        currentSubscriptionProducts: [
            {
                name: 'Website',
                description: 'Build a professional small business website in minutes, with no technical skills required!',
                slug: 'weebly',
                productId: 'af62e060-06e4-11e8-9306-120b17a64360',
                subscriptions: [
                    {
                        name: 'Free',
                        features: [
                            'Drag & drop builder',
                            '3 Pages',
                            'Displays ads',
                            '500 MB storage',
                            'Company.com subdomain',
                            'Connect your domain',
                        ],
                        includedInMembership: true,
                        eligibleProducts: [
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '76b3b510-4b08-4e6c-88b4-1a7f9aa3ac23',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '3bc89c9f-e752-436b-8d8b-3de487b8ed75',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                        ],
                        partnerRatePlanId: 5,
                        price: 0,
                        isActive: true
                    },
                    {
                        zuoraProductRatePlanId: '2c92c0f9537e24620153806534b91f8d',
                        features: [
                            'Unlimited Pages',
                            'No ads',
                            'Unlimited storage',
                            'SSL Security',
                            'Advanced site stats',
                        ],
                        includedInMembership: false,
                        partnerRatePlanId: 1,
                        price: 9,
                        name: 'Starter',
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '4b92baea-06ec-11e8-9306-120b17a64360',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                        ],
                        isActive: false,
                        featurePrefix: 'Everything in Free plus:'
                    },
                    {
                        zuoraProductRatePlanId: '2c92c0f960debc0d0160e12eb3dd46e6',
                        features: [
                            'Site search',
                            'Password Protection',
                            'Video backgrounds',
                            'HD Video & Audio',
                        ],
                        includedInMembership: false,
                        partnerRatePlanId: 2,
                        price: 16,
                        name: 'Pro',
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '4b92baea-06ec-11e8-9306-120b17a64360',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                        ],
                        isActive: false,
                        featurePrefix: 'Everything in Starter plus:'
                    },
                ],
                tileId: '839ffb44-658a-4082-89cc-2e9339083b02',
                tileName: 'Website',
                stateCurrent: 'inactive',
                aLaCarte: true,
                hideBuyOption: true,
                ratePlans: [
                    {
                        name: 'Free',
                        features: [
                            'Drag & drop builder',
                            '3 Pages',
                            'Displays ads',
                            '500 MB storage',
                            'Company.com subdomain',
                            'Connect your domain',
                        ],
                        includedInMembership: true,
                        eligibleProducts: [
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '76b3b510-4b08-4e6c-88b4-1a7f9aa3ac23',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '3bc89c9f-e752-436b-8d8b-3de487b8ed75',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                        ],
                        partnerRatePlanId: 5,
                        price: 0,
                        isActive: true
                    },
                    {
                        zuoraProductRatePlanId: '2c92c0f9537e24620153806534b91f8d',
                        features: [
                            'Unlimited Pages',
                            'No ads',
                            'Unlimited storage',
                            'SSL Security',
                            'Advanced site stats',
                        ],
                        includedInMembership: false,
                        partnerRatePlanId: 1,
                        price: 9,
                        name: 'Starter',
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '4b92baea-06ec-11e8-9306-120b17a64360',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                        ],
                        isActive: false,
                        featurePrefix: 'Everything in Free plus:'
                    },
                    {
                        zuoraProductRatePlanId: '2c92c0f960debc0d0160e12eb3dd46e6',
                        features: [
                            'Site search',
                            'Password Protection',
                            'Video backgrounds',
                            'HD Video & Audio',
                        ],
                        includedInMembership: false,
                        partnerRatePlanId: 2,
                        price: 16,
                        name: 'Pro',
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '4b92baea-06ec-11e8-9306-120b17a64360',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                        ],
                        isActive: false,
                        featurePrefix: 'Everything in Starter plus:'
                    },
                ],
                minPrice: 9
            },
            {
                name: 'Business Listings Report',
                description: 'Get a personalized report instantly that shows your online visibility.!',
                slug: 'business_listings_report_yext',
                productId: '18919ca1-333e-4f5b-a96c-318015ed6410',
                subscriptions: [
                    {
                        name: 'Free',
                        includedInMembership: true,
                        features: [
                            'Free online business listings report',
                            'See how accurate and complete your listings are',
                            'Scans for your business information in over 50 online directories',
                            'Report shows which directories have incomplete or missing information',
                            'Optional upgrade to Business Listings Manager to fix incomplete or missing data',
                        ],
                        eligibleProducts: [
                            'cc1e33f4-2f10-47c8-9312-76280cc1da6d',
                            '4b92bb26-06ec-11e8-9306-120b17a64360',
                            '76b3b510-4b08-4e6c-88b4-1a7f9aa3ac23',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '3bc89c9f-e752-436b-8d8b-3de487b8ed75',
                            '4b92bad5-06ec-11e8-9306-120b17a64360',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'fbed3167-4da2-4c57-89f0-24865a862186',
                            'f4242e3a-a69c-43ef-8617-a5f35d1d1cbf',
                            'c6207bd8-9cc7-4d5e-96cf-34706ecf43d1',
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            '4b92babc-06ec-11e8-9306-120b17a64360',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '4adb3697-72c1-455b-bbdb-a958a86c5741',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                        ],
                        price: 0,
                        isActive: true
                    },
                ],
                tileId: '8e2a1be0-27a5-4711-ae7b-d96ad3f3b206',
                tileName: 'Business Listings Report',
                stateCurrent: 'inactive',
                aLaCarte: false,
                hideBuyOption: false,
                ratePlans: [
                    {
                        name: 'Free',
                        includedInMembership: true,
                        features: [
                            'Free online business listings report',
                            'See how accurate and complete your listings are',
                            'Scans for your business information in over 50 online directories',
                            'Report shows which directories have incomplete or missing information',
                            'Optional upgrade to Business Listings Manager to fix incomplete or missing data',
                        ],
                        eligibleProducts: [
                            'cc1e33f4-2f10-47c8-9312-76280cc1da6d',
                            '4b92bb26-06ec-11e8-9306-120b17a64360',
                            '76b3b510-4b08-4e6c-88b4-1a7f9aa3ac23',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '3bc89c9f-e752-436b-8d8b-3de487b8ed75',
                            '4b92bad5-06ec-11e8-9306-120b17a64360',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'fbed3167-4da2-4c57-89f0-24865a862186',
                            'f4242e3a-a69c-43ef-8617-a5f35d1d1cbf',
                            'c6207bd8-9cc7-4d5e-96cf-34706ecf43d1',
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            '4b92babc-06ec-11e8-9306-120b17a64360',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '4adb3697-72c1-455b-bbdb-a958a86c5741',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                        ],
                        price: 0,
                        isActive: true
                    },
                ],
                minPrice: 0
            },
            {
                name: 'Business Listings Profile',
                description: 'Increase your visibility and get listed in the Company.com directory.',
                slug: 'blp',
                productId: 'dd7cc931-04ae-4d95-adfb-e30384cd03ad',
                subscriptions: [
                    {
                        name: 'Free',
                        includedInMembership: true,
                        features: [
                            'List your business for free on Google My Business',
                            'List your business for free in the Company.com Directory',
                            'Keep your listings in sync with each other and manage them in one place',
                            'Keep your listings in sync with Business Listings Manager (Coming Soon)',
                        ],
                        eligibleProducts: [
                            'f334f800-28be-4d5a-a151-63fc7f6cb55d',
                            '0e520235-e0e8-4c1a-ac97-b5a86914c3cd',
                            'cc1e33f4-2f10-47c8-9312-76280cc1da6d',
                            '4b92bb26-06ec-11e8-9306-120b17a64360',
                            'dbe22c24-2eee-47df-8490-eacc8c5cea50',
                            '76b3b510-4b08-4e6c-88b4-1a7f9aa3ac23',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '3bc89c9f-e752-436b-8d8b-3de487b8ed75',
                            '4b92bad5-06ec-11e8-9306-120b17a64360',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'fbed3167-4da2-4c57-89f0-24865a862186',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            'f4242e3a-a69c-43ef-8617-a5f35d1d1cbf',
                            'c6207bd8-9cc7-4d5e-96cf-34706ecf43d1',
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            '4b92babc-06ec-11e8-9306-120b17a64360',
                            '9e15ed0d-5003-42fe-8e2d-e5aa2f91eb43',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '72cd8f3d-047d-40ab-8f3f-d534cf940560',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '56e48604-f9fd-4fbf-9ce0-d61ded1e175b',
                            '4adb3697-72c1-455b-bbdb-a958a86c5741',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                        ],
                        price: 0,
                        isActive: true
                    },
                ],
                tileId: 'db4f02c1-3c13-4031-a6a9-8ac72de16a57',
                tileName: 'Business Listings Profile',
                stateCurrent: 'active',
                aLaCarte: false,
                hideBuyOption: false,
                ratePlans: [
                    {
                        name: 'Free',
                        includedInMembership: true,
                        features: [
                            'List your business for free on Google My Business',
                            'List your business for free in the Company.com Directory',
                            'Keep your listings in sync with each other and manage them in one place',
                            'Keep your listings in sync with Business Listings Manager (Coming Soon)',
                        ],
                        eligibleProducts: [
                            'f334f800-28be-4d5a-a151-63fc7f6cb55d',
                            '0e520235-e0e8-4c1a-ac97-b5a86914c3cd',
                            'cc1e33f4-2f10-47c8-9312-76280cc1da6d',
                            '4b92bb26-06ec-11e8-9306-120b17a64360',
                            'dbe22c24-2eee-47df-8490-eacc8c5cea50',
                            '76b3b510-4b08-4e6c-88b4-1a7f9aa3ac23',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '3bc89c9f-e752-436b-8d8b-3de487b8ed75',
                            '4b92bad5-06ec-11e8-9306-120b17a64360',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'fbed3167-4da2-4c57-89f0-24865a862186',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            'f4242e3a-a69c-43ef-8617-a5f35d1d1cbf',
                            'c6207bd8-9cc7-4d5e-96cf-34706ecf43d1',
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            '4b92babc-06ec-11e8-9306-120b17a64360',
                            '9e15ed0d-5003-42fe-8e2d-e5aa2f91eb43',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '72cd8f3d-047d-40ab-8f3f-d534cf940560',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '56e48604-f9fd-4fbf-9ce0-d61ded1e175b',
                            '4adb3697-72c1-455b-bbdb-a958a86c5741',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                        ],
                        price: 0,
                        isActive: true
                    },
                ],
                minPrice: 0
            },
        ],
        currentSubscriptionPrice: 46,
        availableProducts: [
            {
                name: 'Email',
                description: 'An email package with a custom domain for reaching your network and customers with your company’s brand',
                slug: 'email_rackspace',
                productId: 'f5327182-4604-11e8-842f-0ed5f89f718b',
                subscriptions: [
                    {
                        name: 'None',
                        includedInMembership: true,
                        features: [],
                        eligibleProducts: ['4b92ba48-06ec-11e8-9306-120b17a64360'],
                        price: 0,
                        isActive: true
                    },
                    {
                        zuoraProductRatePlanId: '2c92c0f960e620730160fa9fa4b32d3c',
                        features: [
                            '1 user',
                            '25GB storage per user',
                            'Webmail',
                            'Calendar',
                            'Contacts',
                            'Support for mail clients',
                            'Bring your own domain',
                        ],
                        includedInMembership: false,
                        partnerRatePlanId: 3,
                        price: 3,
                        zuoraProductRatePlanChargeId: '2c92c0f960e620730160fa9fa4c22d3e',
                        name: 'Starter',
                        eligibleProducts: ['4b92ba48-06ec-11e8-9306-120b17a64360'],
                        seatCount: 1,
                        isActive: false
                    },
                ],
                tileId: '9ba54fd4-b593-4a5a-b3e9-f6ae417b3be1',
                tileName: 'Email',
                stateCurrent: 'buy',
                aLaCarte: true,
                hideBuyOption: true,
                ratePlans: [
                    {
                        zuoraProductRatePlanId: '2c92c0f960e620730160fa9fa4b32d3c',
                        features: [
                            '1 user',
                            '25GB storage per user',
                            'Webmail',
                            'Calendar',
                            'Contacts',
                            'Support for mail clients',
                            'Bring your own domain',
                        ],
                        includedInMembership: false,
                        partnerRatePlanId: 3,
                        price: 3,
                        zuoraProductRatePlanChargeId: '2c92c0f960e620730160fa9fa4c22d3e',
                        name: 'Starter',
                        eligibleProducts: ['4b92ba48-06ec-11e8-9306-120b17a64360'],
                        seatCount: 1,
                        isActive: false
                    },
                ],
                minPrice: 3
            },
            {
                name: 'Hiring',
                description: ' A powerful online recruiting platform to help you find the perfect candidate.',
                slug: 'hire',
                productId: 'af62df02-06e4-11e8-9306-120b17a64360',
                subscriptions: [
                    {
                        name: 'None',
                        includedInMembership: true,
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                        ],
                        partnerRatePlanId: 1,
                        price: 0,
                        isActive: true
                    },
                    {
                        zuoraProductRatePlanId: '2c92c0f962052d4f0162266ab9124bb9',
                        features: [
                            'Automated applicant workflow & tracking',
                            'Sourcing',
                            'Screening',
                            'Job board posting',
                            'Limited job boards',
                        ],
                        includedInMembership: false,
                        partnerRatePlanId: 2,
                        price: 20,
                        name: 'Individual Pricing',
                        eligibleProducts: [
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '4b92baea-06ec-11e8-9306-120b17a64360',
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                        ],
                        isActive: false
                    },
                ],
                tileId: '5806d8a3-8d41-4e8e-a9e0-bfdb7527ff01',
                tileName: 'Hiring',
                stateCurrent: 'buy',
                aLaCarte: true,
                hideBuyOption: false,
                ratePlans: [
                    {
                        zuoraProductRatePlanId: '2c92c0f962052d4f0162266ab9124bb9',
                        features: [
                            'Automated applicant workflow & tracking',
                            'Sourcing',
                            'Screening',
                            'Job board posting',
                            'Limited job boards',
                        ],
                        includedInMembership: false,
                        partnerRatePlanId: 2,
                        price: 20,
                        name: 'Individual Pricing',
                        eligibleProducts: [
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            '4b92baea-06ec-11e8-9306-120b17a64360',
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                        ],
                        isActive: false
                    },
                ],
                minPrice: 20
            },
            {
                name: 'Equipment Protection',
                description: 'Repair and replacement coverage for your office equipment.\n ',
                slug: 'warranty',
                productId: 'af62df61-06e4-11e8-9306-120b17a64360',
                subscriptions: [
                    {
                        name: 'None',
                        includedInMembership: true,
                        eligibleProducts: [
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                        ],
                        price: 0,
                        isActive: true
                    },
                    {
                        zuoraProductRatePlanId: '2c92c0f9537e245d01538065faad6da0',
                        name: 'Individual Pricing',
                        features: [
                            '"No Lemon" guarantee',
                            'Covers up to 4 pieces of office equipment',
                            'Surge protection benefit included',
                            'Covers manufacturer breakdowns',
                        ],
                        includedInMembership: false,
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            '4b92baea-06ec-11e8-9306-120b17a64360',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                        ],
                        price: 15,
                        isActive: false
                    },
                ],
                tileId: 'd6129324-d5bc-4127-bbd6-0e9dbcf1517a',
                tileName: 'Equipment Protection',
                stateCurrent: 'buy',
                aLaCarte: true,
                hideBuyOption: false,
                ratePlans: [
                    {
                        zuoraProductRatePlanId: '2c92c0f9537e245d01538065faad6da0',
                        name: 'Individual Pricing',
                        features: [
                            '"No Lemon" guarantee',
                            'Covers up to 4 pieces of office equipment',
                            'Surge protection benefit included',
                            'Covers manufacturer breakdowns',
                        ],
                        includedInMembership: false,
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            '4b92baea-06ec-11e8-9306-120b17a64360',
                            '4b92bb11-06ec-11e8-9306-120b17a64360',
                            'df5378f4-e8ed-4210-8157-42fcc38a9dc1',
                            '74fd7f30-30fd-41fc-aace-d3241c2a1a4f',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            'f4af70ab-26da-4b6f-b873-0071453bc525',
                            '2ca381c0-7ca6-4a07-960f-ec88db43c6ae',
                            '60f24bb7-fb83-44a1-b485-a6a32d4bc8ce',
                        ],
                        price: 15,
                        isActive: false
                    },
                ],
                minPrice: 15
            },
            {
                name: 'Tax Advice',
                description: 'Talk to a registered tax professional as often as you’d like.',
                slug: 'tax_advice',
                productId: 'f53ac239-a224-47b5-b181-95954bc002ce',
                subscriptions: [
                    {
                        zuoraProductRatePlanId: '2c92c0f9661ff98b01662033390247ec',
                        name: 'Starter',
                        features: [
                            'Unlimited advice',
                            'Licensed tax professionals',
                            'Review of prior year return',
                            'Resource center',
                        ],
                        includedInMembership: false,
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '4b92babc-06ec-11e8-9306-120b17a64360',
                            '4adb3697-72c1-455b-bbdb-a958a86c5741',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '9e15ed0d-5003-42fe-8e2d-e5aa2f91eb43',
                        ],
                        price: 30,
                        isActive: false
                    },
                    {
                        zuoraProductRatePlanId: '2c92c0f86883364401689d5debf02f71',
                        name: 'Pro',
                        features: [
                            'Everything in Starter',
                            'Audit defense by licensed tax professionals',
                            'Research and documentation',
                            'Communication handled for you',
                        ],
                        includedInMembership: false,
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '4b92babc-06ec-11e8-9306-120b17a64360',
                            '4adb3697-72c1-455b-bbdb-a958a86c5741',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '9e15ed0d-5003-42fe-8e2d-e5aa2f91eb43',
                        ],
                        price: 40,
                        isActive: false,
                        featurePrefix: 'Everything in Starter plus:'
                    },
                ],
                tileId: 'd8e39d82-4e4f-400b-89ee-0d8068f2961f',
                tileName: 'Tax Advice',
                stateCurrent: 'buy',
                aLaCarte: true,
                hideBuyOption: false,
                ratePlans: [
                    {
                        zuoraProductRatePlanId: '2c92c0f9661ff98b01662033390247ec',
                        name: 'Starter',
                        features: [
                            'Unlimited advice',
                            'Licensed tax professionals',
                            'Review of prior year return',
                            'Resource center',
                        ],
                        includedInMembership: false,
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '4b92babc-06ec-11e8-9306-120b17a64360',
                            '4adb3697-72c1-455b-bbdb-a958a86c5741',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '9e15ed0d-5003-42fe-8e2d-e5aa2f91eb43',
                        ],
                        price: 30,
                        isActive: false
                    },
                    {
                        zuoraProductRatePlanId: '2c92c0f86883364401689d5debf02f71',
                        name: 'Pro',
                        features: [
                            'Everything in Starter',
                            'Audit defense by licensed tax professionals',
                            'Research and documentation',
                            'Communication handled for you',
                        ],
                        includedInMembership: false,
                        eligibleProducts: [
                            '4b92ba48-06ec-11e8-9306-120b17a64360',
                            'cea1160e-72a7-4de7-9a43-05d26249ef82',
                            '4b92babc-06ec-11e8-9306-120b17a64360',
                            '4adb3697-72c1-455b-bbdb-a958a86c5741',
                            '3fe1b52b-d942-426f-9fda-bd2fb2aac923',
                            '562f50a4-7c18-4561-87b7-92849475eaeb',
                            '9e15ed0d-5003-42fe-8e2d-e5aa2f91eb43',
                        ],
                        price: 40,
                        isActive: false,
                        featurePrefix: 'Everything in Starter plus:'
                    },
                ],
                minPrice: 30
            },
        ]
    }
};
