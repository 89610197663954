import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import valid from 'card-validator';
import { isEmpty } from 'lodash';
import {
  Center,
  Box,
  Heading,
  Stack,
  Divider,
  Grid,
  GridItem,
  Button,
  Flex,
  Icon,
  Text,
  useToast,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { faCheckCircle, faClose } from '@fortawesome/pro-regular-svg-icons';
import { User } from '@companydotcom/types';
import { AutoRenewHeader } from '../components/auto-renew-header';
import { getCheckoutOrderSchema, CreditCardForm } from '../../../../components/forms/checkout';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { savePayment, select } from '../auto-renew-slice';
import type { AutoRenewProps } from '../types/auto-renew.types';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  useSetAutoRenewOnMembershipMutation,
  useAddOrUpdateSavedPaymentsMutation,
} from '../../../../services/acg/acg-api';
import { getSavePaymentPayload } from '../../acg-saved-payment-methods/utils';
import { maskCreditCardNumber } from '../../acg-checkout';

export interface AddPaymentProps extends AutoRenewProps {
  chapterDataFromTile?: {
    chapterNames?: string[];
    expireDate?: string;
  };
}

export const AddPayment: React.FC<AddPaymentProps> = ({
  goToStep,
  membershipData,
  BluePay,
  chapterDataFromTile,
}) => {
  // HOOKs
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [setAutoRenewOnMembership, { isLoading: autoRenewLoading }] =
    useSetAutoRenewOnMembershipMutation();
  const [savePaymentMethod, { isLoading: savingMethodLoading }] =
    useAddOrUpdateSavedPaymentsMutation();

  //STATE
  const savedMethods = useAppSelector(state => state.acgAutoRenew.savedPayments);
  // TODO uncomment when rhythm supports ach
  // const paymentMethodOptions = [
  //   { label: t('acg.checkout.orderStep.creditCard'), value: 'credit card' },
  //   { label: t('acg.checkout.orderStep.ach'), value: 'ach' },
  // ];
  const defaultValues = {
    paymentMethod: 'credit card',
    makeDefault: false,
    autoRenew: true,
    cardNickname: '',
    cardDetails: {
      cardHolderName: '',
      cardNumber: '',
      expirationMonth: `0${new Date().getMonth() + 1}`,
      expirationYear: new Date().getFullYear(),
      cardCvv: '',
    },
    achDetails: {
      accountHolderName: '',
      accountNumber: '',
      accountType: '',
      routingNumber: '',
    },
    billingDetails: {
      country: 'United States',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
    },
  };

  // USEFORM HOOK & HELPERS
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getCheckoutOrderSchema(true)),
    defaultValues: defaultValues ?? {},
  });

  // const selectedPaymentMethod = useWatch({ name: 'paymentMethod', control });
  const selectedCreditCard = useWatch({ name: 'cardDetails.cardNumber', control });
  const card = valid.number(selectedCreditCard);

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      if (!globalUser) {
        throw new Error('global user info data missing');
      }
      if (savedMethods?.length < 5) {
        dispatch(savePayment({ ...values, ...card }));
        dispatch(select({ ...values, ...card }));
        toast.closeAll();

        const autoRenewPayload = getSavePaymentPayload(values);

        /** BLUEPAY LOGIC -----START----- */
        const tokenData =
          // TODO uncomment when rhythm supports ach
          // values?.paymentMethod === 'ach'
          //   ? {
          //       PAYMENT_TYPE: 'ACH',
          //       ACH_ACCOUNT: values?.achDetails?.accountNumber,
          //       ACH_ROUTING: values?.achDetails?.routingNumber,
          //       ACH_ACCOUNT_TYPE: values?.achDetails?.accountType,
          //       NAME1: values?.achDetails?.accountHolderName,
          //     }
          //   :
          {
            CARD_ACCOUNT: values?.cardDetails?.cardNumber,
            CARD_CVV2: values?.cardDetails?.cardCvv,
            EXPMO: values?.cardDetails?.expirationMonth,
            EXPYR: values?.cardDetails?.expirationYear,
          };

        await BluePay?.createToken(
          tokenData,
          async (res: { MESSAGE: string; TRANS_ID: string }) => {
            if (res.MESSAGE === 'INFORMATION STORED') {
              const tokens = {
                merchantAccountId: 'ACG-PRIMARY',
                merchantToken: res.TRANS_ID,
              };
              if (values?.makeDefault || isEmpty(savedMethods)) {
                await Promise.all([
                  setAutoRenewOnMembership({
                    user: globalUser as User,
                    payload: {
                      autoRenew: true,
                      paymentInfo: {
                        ...autoRenewPayload,
                        ...tokens,
                      },
                    },
                  }),
                  savePaymentMethod({
                    user: globalUser,
                    payload: {
                      paymentInfo: {
                        autoRenew: true,
                        ...autoRenewPayload,
                        ...tokens,
                      },
                    },
                  }),
                ]);
              } else {
                await savePaymentMethod({
                  user: globalUser,
                  payload: {
                    paymentInfo: {
                      autoRenew: true,
                      ...autoRenewPayload,
                      ...tokens,
                    },
                  },
                });
              }

              toast({
                render: () => (
                  <Flex flexDirection="row" color="white" p={3} bg="green.500" borderRadius="6px">
                    <Icon
                      p={1}
                      pr={2}
                      className="fa-solid"
                      as={FontAwesomeIcon}
                      icon={faCheckCircle}
                      boxSize="16px"
                    />
                    <Flex flexDirection="column" pr={2}>
                      <Text fontWeight={700}>{t('acg.autoRenew.methodSaved')}</Text>
                      <Text>
                        {values?.paymentMethod === 'credit card'
                          ? `Your membership will automatically renew with the ${
                              card?.card?.niceType ? card?.card?.niceType : 'credit card'
                            } ending in ${values.cardDetails?.cardNumber.slice(
                              values.cardDetails?.cardNumber.length - 4,
                            )}`
                          : `Your membership will automatically renew with the ACH ending in ${values.achDetails?.accountNumber.slice(
                              values.achDetails?.accountNumber.length - 4,
                            )}`}
                      </Text>
                    </Flex>
                    <Icon
                      translateX={2}
                      translateY={4}
                      as={FontAwesomeIcon}
                      sx={{
                        ':hover': {
                          cursor: 'pointer',
                        },
                      }}
                      icon={faClose}
                      boxSize="18px"
                      onClick={() => toast.closeAll()}
                    />
                  </Flex>
                ),
                duration: 7000,
                status: 'success',
                isClosable: true,
                position: 'top-right',
              });
              reset(defaultValues);
              clearErrors();
              goToStep?.('payment-options');
            } else {
              throw new Error('could not encrypt payment');
            }
          },
        );
        /** BLUEPAY LOGIC -----END----- */
      }
    } catch (err) {
      console.log('Error saving payment method', err);
      Sentry.captureException(err, scope => {
        scope.setTransactionName('BluepayAutoRenew');
        scope.setTag('api', 'bluepay');
        scope.setContext('Payment', {
          card_number: maskCreditCardNumber(values?.cardDetails?.cardNumber),
        });
        return scope;
      });
    }
  };

  return (
    <Center
      className="auto-renew"
      flexDirection="column"
      bg={['transparent', 'white']}
      py={[0, 8, 14]}
      px={[0, 4]}
    >
      <Box width="full" maxW={908}>
        <AutoRenewHeader {...{ ...chapterDataFromTile, membershipData }} />
        <Stack spacing={2} color="blackAlpha.500" mt={12}>
          <Heading size="lg" fontWeight="semibold">
            {t('acg.autoRenew.addPaymentFor')}
          </Heading>
        </Stack>
        <Grid gridTemplateColumns={['1fr', null, '1fr 292px']} gap={[6, 8]} mb={8} mt={6}>
          <GridItem as="form">
            {/* TODO uncomment when rhythm supports ach */}
            {/* <SelectField
              register={register}
              name="paymentMethod"
              label="Payment Method"
              formControlStyles={{ maxW: ['100%', 276] }}
            >
              {paymentMethodOptions.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </SelectField>
            {selectedPaymentMethod === 'credit card' && (
              <HStack maxH={6} mt={2}>
                <VisaIcon boxSize={9} />
                <MastercardIcon boxSize={9} />
                <AmexIcon boxSize={9} />
              </HStack>
            )} */}
            {/* {selectedPaymentMethod === 'credit card' ? ( */}
            <Box mt={6}>
              <CreditCardForm
                displayBillingAddressForm
                isCheckout={false}
                watch={watch}
                control={control}
                setValue={setValue}
                setError={setError}
                selectedCreditCard={card}
                register={register}
                errors={errors}
                getValues={getValues}
                addNewType
                showSavePaymentMethodRadio
              />
            </Box>
            {/* TODO uncomment when rhythm supports ach */}
            {/* ) : (
              <Box mt={6}>
                <ACHForm
                  displayBillingAddressForm // have to submit billing address to save payment
                  isCheckout={false}
                  control={control}
                  watch={watch}
                  register={register}
                  errors={errors}
                  getValues={getValues}
                  addNewType
                />
              </Box>
            )} */}
          </GridItem>
        </Grid>
        <Box>
          <Divider display={['none', 'block']} />
          <Stack
            direction={['column-reverse', null, 'row-reverse']}
            justify="space-between"
            alignItems={['center', 'right']}
            pt={[4, 8]}
            spacing={[4, null, null, 0]}
          >
            <Button
              width={['2xs', 'xs']}
              isLoading={isSubmitting || autoRenewLoading || savingMethodLoading}
              isDisabled={!isValid}
              size="lg"
              onClick={handleSubmit(onSubmit)}
            >
              {t('common.buttons.save')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Center>
  );
};
