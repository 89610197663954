import React from 'react';
import {
  Flex,
  Text,
  Box,
  Button,
  Heading,
  RadioGroup,
  Radio,
  Stack,
  ButtonGroup,
  UnorderedList,
  ListItem,
  Link,
  Tooltip,
  Icon,
} from '@companydotcom/potion';
import { InputField, SelectField, CheckboxField } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const getEmailOwnershipSchema = () =>
  yup.object().shape({
    adminEmail: yup
      .string()
      .test('adminEmailOrNoneChecked', 'common.inputs.email.error', function testEmail(value) {
        return this.parent.noAdminChecked || yup.string().email().required().isValid(value);
      }),
    mailboxCount: yup
      .string()
      .required('forms.emailEligibility.emailOwnership.inputs.mailboxCount.error'),
    noAdminChecked: yup.boolean(),
    ownsDomain: yup
      .string()
      .required('forms.emailEligibility.emailOwnership.inputs.ownsDomain.error'),
    termsAccepted: yup.boolean().oneOf([true, false]),
  });

export interface EmailOwnershipProps {
  onComplete?: (formData: any, submit: boolean) => void;
  previousStep?: () => void;
}

export const EmailOwnership = ({ onComplete, previousStep }: EmailOwnershipProps) => {
  const { t } = useTranslation();
  const defaultValues = {
    ownsDomain: '',
    adminEmail: '',
    noAdminChecked: false,
    termsAccepted: false,
    mailboxCount: { value: '', label: '' },
  };

  const {
    register,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getEmailOwnershipSchema()),
    defaultValues,
  });

  const mailboxOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    {
      value: 'more',
      label: t('forms.emailEligibility.emailOwnership.inputs.mailboxCount.moreThan3'),
    },
  ];

  const radioOptions = [
    { label: t('common.misc.yes'), value: 'Yes' },
    { label: t('common.misc.no'), value: 'No' },
  ];

  return (
    <Flex
      className="email-ownership-step"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg="white"
      py={[8, 12]}
      px={4}
    >
      <Box sx={{ textAlign: 'center' }} maxWidth={498} width="full">
        <Heading size="hs-xl" mb={3}>
          {t('forms.emailEligibility.emailOwnership.heading')}
        </Heading>
        <Heading size="hs-lg" mt={10}>
          {t('forms.emailEligibility.emailOwnership.subheading')}
        </Heading>
        <Box
          width="full"
          p={[4, 10]}
          my={6}
          bg="gray.50"
          border="1px solid"
          borderColor="gray.100"
          borderRadius="md"
          textAlign="left"
        >
          <Heading size="hs-md" mb={6}>
            {t('forms.emailEligibility.emailOwnership.criteria.heading')}
          </Heading>
          <UnorderedList>
            <ListItem>
              <Text textStyle="md">
                {t('forms.emailEligibility.emailOwnership.criteria.listItemOne')}
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="md">
                {t('forms.emailEligibility.emailOwnership.criteria.listItemTwo')}
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="md">
                {t('forms.emailEligibility.emailOwnership.criteria.listItemThree')}
              </Text>
            </ListItem>
            <ListItem listStyleType="none">
              <Text textStyle="sm">
                {t('forms.emailEligibility.emailOwnership.criteria.listItemFour')}
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
        <Text textStyle="md">{t('common.misc.allFieldsRequired')}</Text>
        <Flex
          mx="auto"
          as="form"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt={10}
          maxWidth={383}
          width="full"
          sx={{
            textAlign: 'left',
          }}
        >
          <Text textStyle="lg" mb={5} width="full">
            {t('forms.emailEligibility.emailOwnership.ownDomainQuestion')}
          </Text>
          <Controller
            name="ownsDomain"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                width="full"
                className="inky-emailOwnershipStep__selectedOptionRadio"
                data-test="inky-emailOwnershipStep__selectedOptionRadio"
              >
                <Stack spacing={3} direction="column" textAlign="left">
                  {radioOptions.map((option, i) => {
                    return (
                      <Radio key={i} value={option.value}>
                        {option.label}
                      </Radio>
                    );
                  })}
                </Stack>
              </RadioGroup>
            )}
          />

          <Text textStyle="lg" width="full" mt={12} mb={4}>
            {t('forms.emailEligibility.emailOwnership.mailboxAmountQuestion')}
          </Text>
          <SelectField
            register={register}
            name="mailboxCount"
            label={t('forms.emailEligibility.emailOwnership.inputs.mailboxCount.label')}
            errors={errors}
          >
            {mailboxOptions.map(opt => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </SelectField>

          <Text textStyle="lg" width="full" mt={12} mb={4} position="relative">
            {t('forms.emailEligibility.emailOwnership.adminEmailAddress')}
            <Box display="inline-block" as="span">
              <Tooltip
                shouldWrapChildren
                label={t('forms.emailEligibility.emailOwnership.adminEmailDisclaimerText')}
                fontSize="md"
              >
                <Icon
                  color="blue.500"
                  as={FontAwesomeIcon}
                  icon={faCircleExclamation}
                  boxSize={3}
                />
              </Tooltip>
            </Box>
          </Text>
          <InputField
            name="adminEmail"
            register={register}
            label={t('forms.emailEligibility.emailOwnership.inputs.adminEmail.label')}
            errors={errors}
          />

          <CheckboxField mt={7} register={register} errors={errors} name="noAdminChecked">
            {t('forms.emailEligibility.emailOwnership.inputs.noAdminChecked.error')}
          </CheckboxField>

          <CheckboxField mt={7} register={register} errors={errors} name="termsAccepted">
            {t('forms.emailEligibility.emailOwnership.checkThisBox')}{' '}
            <Link isExternal href="https://www.inky.com/hubfs/INKY_EULA.pdf">
              {t('common.misc.termsAndConditions')}
            </Link>
          </CheckboxField>

          <ButtonGroup size="lg" mt={8} spacing={12}>
            <Button
              variant="outline"
              onClick={() => {
                previousStep?.();
              }}
            >
              {t('common.buttons.back')}
            </Button>
            <Button
              isDisabled={!isValid || isSubmitting}
              type="submit"
              onClick={handleSubmit((values: any) => {
                values.ownsDomain = values.ownsDomain === 'Yes';
                onComplete?.(values, true);
              })}
            >
              {t('common.buttons.submit')}
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </Flex>
  );
};
