import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { FaceCakeLayout } from '../layouts';
import { OneColumnLayout } from '../../../components/layouts';
import { FaceCakeQualifiedFlow } from '../facecake-qualified-flow';
import { FaceCakeCheckoutPage } from '../../../pages/facecake-checkout';
import { FaceCakeAccountManagementPage } from '../../../pages/facecake-account-management';
import { FaceCakePasswordManagementPage } from '../../../pages/facecake-password-management';
import { FaceCakePlanManagementPage } from '../../../pages/facecake-plan-management';
import { FaceCakeInvoicesPage } from '../../../pages/facecake-invoices';
import { FaceCakeUploadLogo } from '../facecake-upload-logo';
import { FaceCakeProfile } from '../facecake-profile';
import { FaceCakeSupport } from '../facecake-support';
import { FaceCakeScheduleAConsultationPage } from '../../../pages/facecake-schedule-a-consultation';

export const faceCakeRoutes: RouteObject[] = [
  {
    element: <FaceCakeLayout />,
    children: [
      {
        path: '/',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <FaceCakeAccountManagementPage />,
          },
          {
            path: 'facecake',
            children: [
              {
                path: 'password-management',
                element: <FaceCakePasswordManagementPage />,
              },
              {
                path: 'invoices',
                element: <FaceCakeInvoicesPage />,
              },
              {
                path: 'plan-management',
                element: <FaceCakePlanManagementPage />,
              },
              {
                path: 'schedule',
                element: <FaceCakeScheduleAConsultationPage />,
              },
              {
                path: 'help',
                element: <FaceCakeSupport />,
              },
              {
                path: 'upload-logo',
                element: <FaceCakeUploadLogo />,
              },
              {
                path: 'profile',
                element: <FaceCakeProfile />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    element: <OneColumnLayout />,
    children: [
      {
        path: 'facecake',
        children: [
          {
            path: 'qualified',
            element: <FaceCakeQualifiedFlow />,
          },
        ],
      },
    ],
  },
  {
    element: <OneColumnLayout hideFooter />,
    children: [
      {
        path: 'facecake',
        children: [
          {
            path: 'qualified',
            element: <FaceCakeQualifiedFlow />,
          },
          {
            path: 'checkout',
            // FaceCake checkout page
            element: <FaceCakeCheckoutPage />,
          },
        ],
      },
    ],
  },
];
