import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['container', 'hamburger'];

const baseStyleContainer: SystemStyleObject = {
  bgColor: 'blue.800',
  padding: 4,
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: 'sm',
  zIndex: 1,
};

const baseStyleHamburger: SystemStyleObject = {
  color: 'white',
  borderRadius: undefined,
};

const baseStyleLogo: SystemStyleObject = {
  height: 8,
};

const baseStyle = {
  container: baseStyleContainer,
  hamburger: baseStyleHamburger,
  logo: baseStyleLogo,
};

export default {
  parts,
  baseStyle,
};
