import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Heading, Center, Text, Link } from '@companydotcom/potion';
import { CheckCircleIcon, AppSpinner } from '@companydotcom/ui';
import { launchQuestionnaire } from './legal-inc-redirect-helper';

interface LegalIncProps {
  userId?: string;
}

export const LegalIncRedirect = ({ userId }: LegalIncProps) => {
  const { t } = useTranslation();
  const [accountId, setAccountId] = useState(undefined);

  useEffect(() => {
    if (userId) {
      launchQuestionnaire(userId, setAccountId, accountId);
    }
  }, [accountId, userId]);

  return (
    <Center
      px={4}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      alignSelf="center"
      mt={[12, 16]}
      mb={[8]}
      textAlign="center"
    >
      {accountId ? (
        <>
          <CheckCircleIcon />
          <Heading size="hs-2xl" my={[6]}>
            {t('subpage.legalIncRedirect.heading')}
          </Heading>
          <Text textStyle="xl" mb={[16]} sx={{ maxWidth: 368 }} textAlign="center">
            <Trans i18nKey="subpage.legalIncRedirect.clickToLaunch">
              Your Business Formation Questionnaire should now be open in a new tab. If not,
              <Link
                fontSize="xl"
                usExternal
                href={`${process.env.REACT_APP_LEGALINC}?accountId=${accountId}`}
              >
                click here to launch.
              </Link>
            </Trans>
          </Text>
        </>
      ) : (
        <AppSpinner className="paywall__legalIncLoader" containerStyles={{ height: 600 }} />
      )}
    </Center>
  );
};
