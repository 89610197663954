import React, { useState } from 'react';
import {
  VStack,
  Button,
  Text,
  Link,
  Stack,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  useMediaQuery,
  Box,
  Skeleton,
  SimpleGrid,
  SkeletonText,
} from '@companydotcom/potion';
import { NavLink } from 'react-router-dom';
import { isEmpty, sortBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { faArrowLeftLong } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { ProfileTitle } from '../components/profile-title';
import { ContactInformation } from '../components/contact-information';
import { ProfileSection } from '../components/profile-section';
import {
  AddNewContactInfo,
  EditBio,
  EditDirectoryPreferences,
  EditExpertise,
  EditIndustry,
  EditMarketArea,
  EditPersonalInformation,
  EditTransactionType,
} from '../forms';
import { ProfileBio } from '../components/profile-bio';
import { ProfileAvatar } from '../../../../components/elements/profile-avatar/profile-avatar';
import { FormData, HiddenField } from '../../../../services/acg/acg.types';
import {
  useGetAcgQueryArgs,
  useGetMembershipsV2Query,
  useGetMemberCommitteesV2Query,
  useGetAcgUserProfileV2Query,
} from '../../../../services/acg/acg-api-v2';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { MembershipsCarousel } from '../components/memberships-carousel';
import { PageHeading } from '../../../../components/elements';
import { AcgAvatarUploader } from '../components/acg-avatar-uploader';

export interface PrivateProfileProps {
  goToStep?: (arg: number | string) => void;
}

export const PrivateProfile = ({ goToStep }: PrivateProfileProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 30rem)');
  const [formSlug, setFormSlug] = useState('');
  const [formData, setFormData] = useState<FormData>();
  const args = useGetAcgQueryArgs();
  const { data: globalUser, isLoading: isGlobalUserLoading } = useGetGlobalUserQuery();
  const {
    data: acgUserProfileData,
    isLoading,
    isError,
  } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const { data: membershipData, isLoading: isMembershipsLoading } = useGetMembershipsV2Query({
    ...args,
  });

  const isAcgMember = membershipData?.status?.toLowerCase() === 'active';

  const { data: committeeData, isLoading: isCommiteesLoading } = useGetMemberCommitteesV2Query({
    ...args,
  });

  const handleFormSelection = (formSlug: string, data?: FormData) => {
    onOpen();
    setFormSlug(formSlug);

    if (data) {
      setFormData(data);
    }
  };

  return (
    <Center
      className="acg-myProfile__privateProfile"
      flexDirection="column"
      bg={['transparent', 'white']}
      py={[0, 8, 12]}
      px={[0, 4]}
    >
      <Box width="full" maxW={908}>
        <PageHeading heading={t('acg.myProfile.myAcgProfile')} />
        <Stack
          my={[4, 12]}
          direction={['column', null, null, null, 'row']}
          spacing={[4, 8]}
          justifyContent="center"
        >
          <VStack
            flexWrap="wrap"
            width="100%"
            alignItems={['center', null, 'flex-start']}
            spacing={4}
          >
            <Stack
              width="100%"
              spacing={2}
              alignItems="center"
              justify="center"
              direction={['column', null, 'row']}
            >
              {isLoading || isGlobalUserLoading ? (
                <ProfileAvatar isLoading mb={[4, 0]} />
              ) : (
                <AcgAvatarUploader />
              )}
              {isLoading ? (
                <ProfileTitle
                  formSlug="edit-personal-information"
                  isLoading
                  boxShadow={['md', 'none']}
                />
              ) : (
                <ProfileTitle
                  formSlug="edit-personal-information"
                  setFormSlug={setFormSlug}
                  onOpen={onOpen}
                  canEdit
                  firstName={acgUserProfileData?.firstName}
                  middleName={acgUserProfileData?.middleName}
                  lastName={acgUserProfileData?.lastName}
                  title={acgUserProfileData?.title}
                  prefix={acgUserProfileData?.prefix}
                  suffix={acgUserProfileData?.suffix}
                  organization={acgUserProfileData?.organization}
                  twitterName={acgUserProfileData?.twitterName}
                  linkedInName={acgUserProfileData?.linkedInName}
                  boxShadow={['md', 'none']}
                >
                  <Button
                    mt={2}
                    isLoading={isLoading}
                    size="sm"
                    display={['inherit', 'none']}
                    variant="outline"
                    isDisabled={isError || !acgUserProfileData || isMembershipsLoading}
                    onClick={() => goToStep?.(1)}
                  >
                    {t('acg.myProfile.profilePages.viewPublicProfile')}
                  </Button>
                </ProfileTitle>
              )}
            </Stack>
            <ProfileBio
              canEdit
              formSlug="edit-bio"
              onOpen={onOpen}
              setFormSlug={handleFormSelection}
              value={!isLoading ? acgUserProfileData?.bio : ''}
              isLoading={isLoading}
              boxShadow={['md', 'none']}
            />
            <Button
              isLoading={isLoading}
              size="sm"
              display={['none', 'inherit']}
              variant="outline"
              isDisabled={isError || !acgUserProfileData || isMembershipsLoading}
              onClick={() => goToStep?.(1)}
            >
              {t('acg.myProfile.profilePages.viewPublicProfile')}
            </Button>
          </VStack>
          <VStack align="flex-start" flexWrap="wrap" width="100%" spacing={[4, 8]}>
            {isLoading ? (
              <SkeletonText w="100%" maxW={438} noOfLines={4} />
            ) : (
              <ContactInformation onOpen={onOpen} setFormSlug={handleFormSelection} />
            )}

            <Box
              width="full"
              bg={['white', 'inherit']}
              textAlign={['center', 'left']}
              p={[4, 0]}
              boxShadow={['md', 'none']}
              borderRadius={[4, 0]}
            >
              <Text textStyle="xl" fontWeight="bold" mb={2}>
                {t('acg.myProfile.profilePages.individualType')}
              </Text>
              {isLoading ? (
                <Skeleton height={5} />
              ) : (
                <Text textStyle="sm">{acgUserProfileData?.individualType ?? ''}</Text>
              )}
            </Box>
          </VStack>
        </Stack>
        <SimpleGrid spacing={[4, 8]} columns={[1, null, null, 2]}>
          {isAcgMember && (
            <MembershipsCarousel membershipData={membershipData} isLoading={isMembershipsLoading} />
          )}
          {isAcgMember &&
            !Array.isArray(committeeData) &&
            committeeData?.totalCommitteesFound !== 0 && (
              <ProfileSection heading={t('acg.myProfile.profilePages.leadership')}>
                {sortBy(committeeData?.committees, 'name').map((comm, i) => (
                  <Text key={`${comm?.recordKey}${i}`} textStyle="sm">
                    {`${comm?.name}${comm?.position ? ` - ${comm?.position}` : ''}`}
                  </Text>
                ))}
              </ProfileSection>
            )}
          {isAcgMember &&
          !Array.isArray(committeeData) &&
          committeeData?.totalCommitteesFound !== 0 ? (
            <ProfileSection
              heading={t('acg.myProfile.profilePages.committees')}
              isLoading={isCommiteesLoading}
            >
              {sortBy(committeeData?.committees, 'name').map((comm, i) => (
                <Text key={`${comm?.recordKey}${i}`} textStyle="sm">
                  {comm?.name}
                </Text>
              ))}
            </ProfileSection>
          ) : null}
          {isAcgMember &&
            !Array.isArray(committeeData) &&
            committeeData?.totalCommitteesFound !== 0 &&
            committeeData?.committees?.some?.(c => c?.hasWorkspace) && (
              <ProfileSection
                heading={t('acg.myProfile.profilePages.workspaces')}
                isLoading={isCommiteesLoading}
              >
                {sortBy(committeeData?.committees, 'name')
                  .filter(comm => comm?.hasWorkspace)
                  .map((comm, i) => (
                    <Text key={i}>{comm?.name}</Text>
                  ))}
              </ProfileSection>
            )}
          <ProfileSection isLoading={isLoading} heading="Resources">
            <Link as={NavLink} textStyle="sm" to="/member-directory">
              Member Directory
            </Link>
            <Link textStyle="sm" href="https://www.acg.org/mmp-certification" isExternal>
              MMP Certification
            </Link>
          </ProfileSection>
          <ProfileSection
            heading={t('acg.myProfile.profilePages.expertise.expertise')}
            canEdit
            onOpen={onOpen}
            setFormSlug={setFormSlug}
            formSlug="edit-expertise"
            isLoading={isLoading}
          >
            {isEmpty(acgUserProfileData?.areaOfExpertise) ? (
              <Text textStyle="sm" opacity={0.5}>
                {t('acg.myProfile.profilePages.expertise.helperText')}
              </Text>
            ) : (
              acgUserProfileData?.areaOfExpertise?.map((area, i) => (
                <Text key={i} textStyle="sm">
                  {area}
                </Text>
              ))
            )}
          </ProfileSection>
          <ProfileSection
            heading={t('acg.myProfile.profilePages.marketArea.marketArea')}
            canEdit
            onOpen={onOpen}
            setFormSlug={setFormSlug}
            formSlug="edit-market-area"
            isLoading={isLoading}
          >
            {isEmpty(acgUserProfileData?.marketArea) ? (
              <Text textStyle="sm" opacity={0.5}>
                {t('acg.myProfile.profilePages.marketArea.helperText')}
              </Text>
            ) : (
              acgUserProfileData?.marketArea?.map((area, i) => (
                <Text key={i} textStyle="sm">
                  {area}
                </Text>
              ))
            )}
          </ProfileSection>
          <ProfileSection
            heading={t('acg.myProfile.profilePages.industries.industries')}
            canEdit
            onOpen={onOpen}
            setFormSlug={setFormSlug}
            formSlug="edit-industry"
            isLoading={isLoading}
          >
            {isEmpty(acgUserProfileData?.industry) ? (
              <Text textStyle="sm" opacity={0.5}>
                {t('acg.myProfile.profilePages.industries.helperText')}
              </Text>
            ) : (
              acgUserProfileData?.industry?.map((ind, i) => (
                <Text key={i} textStyle="sm">
                  {ind}
                </Text>
              ))
            )}
          </ProfileSection>
          <ProfileSection
            heading={t('acg.myProfile.profilePages.transactionType.transactionType')}
            canEdit
            onOpen={onOpen}
            setFormSlug={setFormSlug}
            formSlug="edit-transaction-type"
            isLoading={isLoading}
          >
            {acgUserProfileData?.transactionType ? (
              <Text textStyle="sm">{acgUserProfileData?.transactionType}</Text>
            ) : (
              <Text textStyle="sm" opacity={0.5}>
                {t('acg.myProfile.profilePages.transactionType.helperText')}
              </Text>
            )}
          </ProfileSection>
          {isAcgMember && (
            <ProfileSection
              heading={t(
                'acg.myProfile.profilePages.memberDirectoryPreferences.memberDirectoryPreferences',
              )}
              canEdit
              onOpen={onOpen}
              setFormSlug={setFormSlug}
              formSlug="edit-directory-preferences"
              isLoading={isLoading}
            >
              {membershipData?.hiddenFieldsFromMembershipDirectory?.length === 0 && (
                <Text textStyle="sm" opacity={0.5}>
                  {t('acg.myProfile.profilePages.memberDirectoryPreferences.helperText')}
                </Text>
              )}
              {membershipData?.hiddenFieldsFromMembershipDirectory?.includes(
                HiddenField.EMAIL_ADDRESS,
              ) && (
                <Text textStyle="sm">
                  {t(
                    'acg.myProfile.profilePages.memberDirectoryPreferences.excludePrimaryEmailFromDirectory',
                  )}
                </Text>
              )}
              {membershipData?.hiddenFieldsFromMembershipDirectory?.includes(
                HiddenField.PREFERRED_PHONE,
              ) && (
                <Text textStyle="sm">
                  {t(
                    'acg.myProfile.profilePages.memberDirectoryPreferences.excludePrimaryPhoneFromDirectory',
                  )}
                </Text>
              )}
              {membershipData?.hiddenFieldsFromMembershipDirectory?.includes(
                HiddenField.PREFERRED_ADDRESS,
              ) && (
                <Text textStyle="sm">
                  {t(
                    'acg.myProfile.profilePages.memberDirectoryPreferences.excludePrimaryLocationFromDirectory',
                  )}
                </Text>
              )}
              {membershipData?.hiddenFieldsFromMembershipDirectory?.includes(
                HiddenField.WEBSITE,
              ) && (
                <Text textStyle="sm">
                  {t(
                    'acg.myProfile.profilePages.memberDirectoryPreferences.excludePrimaryWebsiteFromDirectory',
                  )}
                </Text>
              )}
            </ProfileSection>
          )}
        </SimpleGrid>
      </Box>
      <FormRenderer
        isOpen={isOpen}
        onClose={onClose}
        formSlug={formSlug}
        isMobile={isMobile}
        formData={formData}
      />
    </Center>
  );
};

interface FormSwitchProps {
  formSlug: string;
  onClose: () => void;
  isMobile: boolean;
  formData?: any;
}

export const FormSwitch: React.FC<FormSwitchProps> = ({
  formSlug,
  onClose,
  formData,
  isMobile,
  ...rest
}) => {
  const sharedProps = {
    onClose,
    isMobile,
    formData,
    ...rest,
  };

  switch (formSlug) {
    case 'add-new-contact-info':
      return <AddNewContactInfo {...sharedProps} />;
    case 'edit-bio':
      return <EditBio {...sharedProps} />;
    case 'edit-directory-preferences':
      return <EditDirectoryPreferences {...sharedProps} />;
    case 'edit-expertise':
      return <EditExpertise {...sharedProps} />;
    case 'edit-industry':
      return <EditIndustry {...sharedProps} />;
    case 'edit-market-area':
      return <EditMarketArea {...sharedProps} />;
    case 'edit-personal-information':
      return <EditPersonalInformation {...sharedProps} />;
    case 'edit-transaction-type':
      return <EditTransactionType {...sharedProps} />;
    default:
      return null;
  }
};

interface FormRenderProps {
  isOpen: boolean;
  formSlug: string;
  onClose: () => void;
  isMobile: boolean;
  formData?: FormData;
}

export const FormRenderer: React.FC<FormRenderProps> = ({
  isOpen,
  onClose,
  formSlug,
  isMobile,
  formData,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size={
        formSlug === 'edit-personal-information' || formSlug === 'add-new-contact-info'
          ? '5xl'
          : '3xl'
      }
    >
      <ModalOverlay />
      <ModalContent
        my={[null, '60px']}
        px={[4, null]}
        height={['inherit', 'auto']}
        overflow="auto"
        backgroundColor={['gray.50', 'white']}
      >
        {isMobile ? (
          <Button
            alignSelf="flex-start"
            variant="link"
            mt={2}
            leftIcon={<FontAwesomeIcon icon={faArrowLeftLong} />}
            onClick={onClose}
          >
            {t('common.buttons.back')}
          </Button>
        ) : (
          <ModalCloseButton />
        )}
        {FormSwitch({ formSlug, onClose, isMobile, formData, ...rest })}
      </ModalContent>
    </Modal>
  );
};
