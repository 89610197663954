import { isEmpty } from 'lodash';
import {
  RegistrantAndFee,
  EventForRegistration,
  GuestWithPackages,
} from '../../../../services/acg/acg.types';

export function useShowAddGuestButton(
  eventForRegistration?: EventForRegistration,
  selectedRegistration?: GuestWithPackages | RegistrantAndFee,
  guestsToRegister?: number,
) {
  // Be sure to include the user registrering to counts
  const SELF_REGISTRANT = 1;
  const AVAILABLE_CAPACITY =
    eventForRegistration?.eventCapacity &&
    eventForRegistration?.totalRegistered &&
    eventForRegistration?.eventCapacity - eventForRegistration?.totalRegistered;

  const CURRENT_ORDER = SELF_REGISTRANT + (guestsToRegister || 0);

  if (eventForRegistration?.isEventMemberOnly) {
    return false;
  }

  // If there are no guest fees on the selected registrant type, you cant add a guest
  if (
    !selectedRegistration ||
    (selectedRegistration &&
      'guests' in selectedRegistration &&
      (isEmpty(selectedRegistration?.guests) || !selectedRegistration?.guests))
  ) {
    return false;
  }

  // Some registrant fees allow a max number of guests per sign up
  if (
    selectedRegistration &&
    'registrantType' in selectedRegistration &&
    guestsToRegister &&
    selectedRegistration?.registrantType?.maxGuestsPerRegistration &&
    guestsToRegister + SELF_REGISTRANT >
      selectedRegistration?.registrantType.maxGuestsPerRegistration
  ) {
    return false;
  }

  // max guest limit check
  if (
    AVAILABLE_CAPACITY &&
    guestsToRegister &&
    eventForRegistration?.eventCapacityEnabled &&
    AVAILABLE_CAPACITY - CURRENT_ORDER <= 0
  ) {
    return false;
  }

  // capacity check
  if (
    AVAILABLE_CAPACITY &&
    guestsToRegister &&
    !eventForRegistration?.hasWaitList &&
    eventForRegistration?.eventCapacity &&
    eventForRegistration?.eventCapacityEnabled &&
    eventForRegistration?.eventCapacity > 0 &&
    AVAILABLE_CAPACITY - CURRENT_ORDER <= 0
  ) {
    return false;
  }

  if (
    eventForRegistration?.eventCapacityEnabled &&
    AVAILABLE_CAPACITY &&
    AVAILABLE_CAPACITY === 1
  ) {
    return false;
  }

  // wait list check
  if (
    guestsToRegister &&
    eventForRegistration?.hasWaitList &&
    eventForRegistration?.totalWaitList &&
    eventForRegistration?.totalRegistered &&
    eventForRegistration?.eventCapacity &&
    eventForRegistration?.totalRegistered +
      eventForRegistration?.totalWaitList +
      guestsToRegister +
      SELF_REGISTRANT >
      eventForRegistration?.eventCapacity
  ) {
    return false;
  }

  return true;
}
