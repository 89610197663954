import React, { useState, useEffect, useCallback } from 'react';
import { Text, Button } from '@companydotcom/potion';
import { useEventData } from '@companydotcom/ui';
import { companyConstants } from '@companydotcom/helpers';
import { useMitt, useSource } from '@companydotcom/providers';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';
import type { TileComponentProps } from '../../types';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { useLazyGetProductRatePlanQuery } from '../../../../services/payment/payment-api';
// import { SourceSvc } from '@companydotcom/services';

export interface TileConditionTextProps extends TileComponentProps {}

export const TileConditionText: React.FC<TileConditionTextProps> = props => {
  const { variant, eventData, stateCurrent, eventHandler, pubEvents, componentTheme, ...rest } =
    props;
  const { loading } = useEventData<any>(eventData);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    eventHandler?.(pubEvents, e);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setIsLoading(loading || variant === 'liveData' || stateCurrent?.stateName === 'pending');
    }
    return () => {
      mounted = false;
    };
  }, [loading, stateCurrent?.stateName, variant]);

  const shared = {
    isLoading,
    onClick: handleClick,
    componentTheme,
    ...rest,
  };

  if (variant === 'conference') {
    return <ConferenceTileText {...shared} />;
  }

  return <ConditionText variant="primary" {...shared} />;
};

export interface ConditionTextProps extends TileComponentProps {
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ConditionText = (props: ConditionTextProps) => {
  const { t } = useTranslation();
  const { variant, errorText, target, text, isLoading, componentTheme, onClick } = props;

  if (variant === 'primary') {
    if (target && text?.includes(target)) {
      return (
        <Text className="tile-conditionText" textStyle="sm" sx={componentTheme}>
          {reactStringReplace(text, target, (match, i) => (
            <Button
              fontSize="sm"
              variant="link"
              onClick={onClick}
              key={i}
              isLoading={isLoading}
              whiteSpace="normal"
            >
              {isLoading ? `${t('common.buttons.loading')}...` : match}
            </Button>
          ))}
        </Text>
      );
    }
    return (
      <Text className="tile-conditionText" textStyle="sm" sx={componentTheme}>
        {text}
      </Text>
    );
  }

  if (variant === 'error') {
    <Text color="red.500" sx={componentTheme}>
      {errorText}
    </Text>;
  }
  return <Text color="red.500">ConditionText component needs variant</Text>;
};

// const { getProductRatePlan } = SourceSvc?.();

const ConferenceTileText = (props: ConditionTextProps) => {
  const { emitter } = useMitt();
  const { tile, onClick, componentTheme } = props;
  const { data: globalUser } = useGetGlobalUserQuery();
  const source = useSource();
  const [isLoading, setIsLoading] = useState(false);
  const [productTier, setProductTier] = useState<string>();
  const [getProductRatePlan] = useLazyGetProductRatePlanQuery();
  const { t } = useTranslation();

  const fetchProductTier = useCallback(async () => {
    const tier = await getProductRatePlan({
      accountId: globalUser?.account?.accountId as string,
      productId: tile?.productId as string,
      locale: source?.i18nConfig?.lng,
    }).unwrap();
    const tierName = tier ? tier.name : undefined;
    setProductTier(tierName ?? '');
    setIsLoading(false);
  }, [
    getProductRatePlan,
    globalUser?.account?.accountId,
    tile?.productId,
    source?.i18nConfig?.lng,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      fetchProductTier();
    }
    return () => {
      isMounted = false;
    };
  }, [globalUser?.account?.accountId, tile?.productId, fetchProductTier]);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.paymentSucceeded, () => {
      setIsLoading(true);
    });

    return () => {
      emitter.off(companyConstants.platformEvents.paymentSucceeded);
    };
  }, [emitter]);

  if (isLoading) {
    return <ConditionText isLoading={isLoading} componentTheme={componentTheme} />;
  }

  if (globalUser?.role !== 'user') {
    return productTier && productTier === 'Starter' ? null : (
      <ConditionText
        variant="primary"
        onClick={onClick}
        text={t('tiles.conditionText.conferenceTile.upgradeToStarterAdditional')}
        target={t('tiles.conditionText.conferenceTile.upgradeToStarter')}
        componentTheme={componentTheme}
      />
    );
  }

  return null;
};
