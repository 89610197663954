import React from 'react';
import { Heading, Button, Icon, Box, Text, Center, Link, ButtonGroup } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/pro-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { useStartVerificationMutation } from '../../../../services/listings/listing-api';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { useAwaitableFacade } from '../../../../hooks';

export interface CallingYourPhoneProps {
  goToStep?: (arg: string | number) => void;
  verificationOption?: string;
  verification?: string;
  listingId?: string;
  // listingSvc?: any;
}

export const CallingYourPhone: React.FC<CallingYourPhoneProps> = props => {
  const {
    verificationOption,
    goToStep,
    verification,
    listingId,
    //  listingSvc
  } = props;
  const { data: globalUser } = useGetGlobalUserQuery();
  const [startVerification] = useStartVerificationMutation();
  const snsInterface = useAwaitableFacade(globalUser, 'listing');

  const handleSubmit = () => {
    goToStep?.('blp-congrats');
  };

  return (
    <Center
      className="fttf-calling-your-phone"
      flexDirection="column"
      pb={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box maxW="xl">
        <Icon
          mx="auto"
          color="green.500"
          as={FontAwesomeIcon}
          boxSize={[14, 28]}
          icon={faPhoneVolume}
          mb={[5, null, 8]}
        />
        <Heading as="h1" size="hs-xl">
          Calling Your Business Phone...
        </Heading>
        {verificationOption === 'call' ? (
          <Text textStyle="lg" mt={[5]}>
            You should recieve a phone call to the number listed in your business profile in the
            next few minutes. If you do not receive a phone call, check your phone number in the
            last step or{' '}
            <Button
              variant="link"
              fontSize="lg"
              onClick={() => {
                goToStep?.(6);
              }}
            >
              schedule a time to talk with a Customer Success Representative.
            </Button>
          </Text>
        ) : (
          <Text textStyle="lg" mt={[5]}>
            You should receive a phone call to the number listed in your business profile in the
            next business day. If you do not receive a phone call, check your phone number in the
            last step or{' '}
            <Button
              variant="link"
              fontSize="lg"
              onClick={() => {
                // listingSvc.startVerification(listingId, selectedVerification);
                if (globalUser && listingId && verification) {
                  startVerification({
                    snsInterface,
                    listingId,
                    verificationMethod: verification,
                  });
                }
              }}
            >
              resend the phone call.
            </Button>
          </Text>
        )}

        <ButtonGroup mt={12} size="lg" spacing={6}>
          <Button
            variant="outline"
            onClick={() => {
              goToStep?.(2);
            }}
          >
            Back
          </Button>
          <Button ml={[6]} onClick={handleSubmit}>
            Got it!
          </Button>
        </ButtonGroup>
        <Box mt={[12, null, 16]} sx={{ textAlign: 'center' }}>
          <Text variant="caption">
            Need Help?{' '}
            <Link as={NavLink} to="/help">
              Contact Support
            </Link>
          </Text>
        </Box>
      </Box>
    </Center>
  );
};
