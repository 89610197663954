import { companyConstants } from '@companydotcom/helpers';

export const reportButtonHandler = async (
  targetTileState: any,
  samlSso: any,
  source: any,
  toggleModal: any,
  emitter: any,
  getProductSSO: any,
  userId: any,
) => {
  switch (targetTileState.stateCurrent) {
    case 'inactive': {
      emitter.emit(companyConstants.tileActions.remoteActivate, targetTileState.productId);
      toggleModal();
      break;
    }
    case 'active': {
      const newTab = window.open(
        `${process.env.REACT_APP_IMG_URL}/website/html/loading.html`,
        '_blank',
      );
      if (samlSso && newTab) {
        newTab.location.href = samlSso;
      } else {
        const res = await getProductSSO({
          userId,
          actionType: 'navigateSSO',
          productSlug: targetTileState.slug,
        }).unwrap();

        if (res.ssoUrl && newTab) {
          newTab.location.href = res.ssoUrl;
        }
        // getProductSSO({
        //   userId,
        //   actionType: 'navigateSSO',
        //   productSlug: targetTileState.slug,
        // }).then((ssoUrl: any) => {
        //   if (newTab) {
        //     newTab.location.href = ssoUrl;
        //   }
        // });
      }
      break;
    }
    case 'buy': {
      const btnData = targetTileState.targetTileComponents.find(
        (component: any) => component.type === 'TileButton',
      );
      const btnEle = document.getElementById(btnData.gtmId);
      if (btnEle) {
        btnEle.click();
      }
      toggleModal();
      break;
    }
    default: {
      throw new Error(`Target tile "stateCurrent" not recognized: ${targetTileState.stateCurrent}`);
    }
  }
};

export const slugToNameMap = {
  getfave: 'Get Fave',
  citysearch: 'Cityserach',
  yelp: 'Yelp',
  yelp_api: 'Yelp',
  showmelocal: 'ShowMeLocal',
  tupalo: 'Tupalo',
  yellowmoxie: 'YellowMoxie',
  avantar: 'Avantar',
  citysquares: 'CitySquares',
  navmii: 'Navmii',
  uscitynet: 'USCity',
  '8coupons': '8coupons',
  elocal: 'eLocal',
  pointcom: 'PointCom',
  ablocal: 'ABLocal',
  opendi: 'Opendi',
  voteforthebest: 'Vote for the Best',
  '2findlocal': '2FindLocal',
  mylocalservices: 'My Local Services',
  n49ca: 'n49',
  ibegin: 'iBegin',
  iglobal: 'iGlobal',
  credibilityreview: 'Credibility Review',
  insiderpages: 'Insider Pages',
  brownbooknet: 'Brownbook',
  bizwikicom: 'Bizwiki',
  aroundme: 'AroundMe',
  hotfrog: 'Hotfrog',
  localdatabase: 'Local Database',
  propertycapsule: 'Property Capsule',
  merchantcircle: 'Merchant Circle',
  superpages: 'Superpages',
  yellowpagecitycom: 'YellowPageCity',
  chamberofcommercecom: 'Chamber of Commerce',
  americantownscom: 'AmericanTowns',
  yellowpagesgoesgreen: 'Yellow Pages Goes Green',
  cylex: 'Cylex',
  localstack: 'LocalStack',
  yalwa: 'Yalwa',
  ezlocal: 'EZlocal',
  localpages: 'Local Pages',
  ypcom: 'YP',
  golocal247: 'GoLocal247',
  findopen: 'FindOpen',
  facebook: 'Facebook',
  foursquare: 'Foursquare',
  mapquest: 'MapQuest',
  localcom: 'Local.com',
  yahoo: 'Yahoo',
  bing: 'Bing',
  whereto: 'WhereTo',
  yasabe: 'Yasabe',
  publicreputation: 'pr.business',
  googleplaces: 'Google My Business',
  google: 'Google My Business',
  google_maps: 'Google Maps',
  where_to: 'WhereTo',
  brownbook: 'Brownbook',
  waze: 'Waze',
  googlemybusiness: 'Google My Business',
  here: 'Here',
  tomtom: 'TomTom',
  telenav: 'Telenav',
  apple: 'Apple Maps',
};
