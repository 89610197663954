export const COMPONENT_THEME_FRAGMENT = `
align
alignContent
alignItems
alignSelf
animation
appearance
area
autoColumns
autoFlow
autoRows
background
backgroundAttachment
backgroundClip
backgroundColor
backgroundImage
backgroundPosition
backgroundRepeat
backgroundSize
bg
bgAttachment
bgClip
bgColor
bgGradient
bgImage
bgPosition
bgRepeat
bgSize
border
borderBottom
borderBottomColor
borderBottomEndRadius
borderBottomLeftRadius
borderBottomRadius
borderBottomRightRadius
borderBottomStartRadius
borderBottomStyle
borderBottomWidth
borderColor
borderEnd
borderEndColor
borderEndRadius
borderEndStyle
borderEndWidth
borderLeft
borderLeftColor
borderLeftRadius
borderLeftStyle
borderLeftWidth
borderRadius
borderRight
borderRightColor
borderRightRadius
borderRightStyle
borderRightWidth
borderStart
borderStartColor
borderStartRadius
borderStartStyle
borderStartWidth
borderStyle
borderTop
borderTopColor
borderTopEndRadius
borderTopLeftRadius
borderTopRadius
borderTopRightRadius
borderTopStartRadius
borderTopStyle
borderTopWidth
borderWidth
borderX
borderY
bottom
boxShadow
boxSize
boxSizing
color
column
columnGap
cursor
d
direction
display
fill
flex
flexBasis
flexDir
flexDirection
flexGrow
flexShrink
flexWrap
float
fontFamily
fontSize
fontStyle
fontWeight
gap
gridArea
gridAutoColumns
gridAutoFlow
gridAutoRows
gridColumn
gridColumnGap
gridGap
gridRow
gridRowGap
gridTemplateAreas
gridTemplateColumns
gridTemplateRows
h
height
justify
justifyContent
justifyItems
justifySelf
left
letterSpacing
lineHeight
m
margin
marginBottom
marginEnd
marginLeft
marginRight
marginStart
marginTop
maxH
maxHeight
maxW
maxWidth
mb
me
minH
minHeight
minW
minWidth
ml
mr
ms
mt
mx
my
objectFit
objectPosition
opacity
order
outline
overflow
overflowWrap
overflowX
overflowY
p
padding
paddingBottom
paddingEnd
paddingLeft
paddingRight
paddingStart
paddingTop
pb
pe
pl
pointerEvents
pos
position
pr
ps
pt
px
py
resize
right
row
rowGap
stroke
templateAreas
templateColumns
templateRows
textAlign
textDecoration
textOverflow
textShadow
textTransform
top
transform
transformOrigin
transition
userSelect
verticalAlign
visibility
w
whiteSpace
width
wordBreak
wrap
zIndex
`;
