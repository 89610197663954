import React from 'react';
import {
  Text,
  Heading,
  SkeletonText,
  Tabs,
  TabPanels,
  TabPanel,
  IconButton,
  VStack,
} from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { GetAcgMembershipsResponse } from '@companydotcom/types';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { ProfileSection } from './profile-section';
import { getFriendlyACGDate } from '../../shared/utils';

export interface MembershipsCarouselProps {
  isLoading: boolean;
  membershipData?: GetAcgMembershipsResponse;
}

export const MembershipsCarousel: React.FC<MembershipsCarouselProps> = props => {
  const { isLoading, membershipData } = props;

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <ProfileSection
      heading={`My Membership${
        membershipData && membershipData?.totalMembershipsFound !== 1 ? 's' : ''
      }`}
    >
      {isLoading ? (
        <SkeletonText noOfLines={3} width="full" />
      ) : membershipData?.totalMembershipsFound === 1 ? (
        <VStack spacing={2} align={['center', 'flex-start']}>
          <MembershipItem
            heading="Chapter"
            value={membershipData?.memberships?.[0]?.chapterName!}
          />
          <MembershipItem
            heading="Member Since"
            isDate
            value={membershipData?.memberships?.[0]?.joinDate!}
          />
          <MembershipItem
            heading="Member Expires"
            isDate
            value={membershipData?.memberships?.[0]?.expireDate!}
          />
        </VStack>
      ) : (
        <Tabs
          variant="unstyled"
          width="full"
          index={tabIndex}
          onChange={handleTabsChange}
          justifyContent="center"
          align="center"
          flexDirection="row"
          display="flex"
        >
          <IconButton
            size="sm"
            aria-label="View previous membership"
            icon={<FontAwesomeIcon icon={faChevronLeft} color="blue.500" boxSize={5} />}
            isDisabled={tabIndex === 0}
            onClick={() => setTabIndex(tabIndex - 1)}
            alignSelf="center"
          />
          <TabPanels width="full" p={0}>
            {membershipData?.memberships?.map((membership, i) => (
              <TabPanel width="full" key={i} px={2}>
                <VStack spacing={2} align={['center', 'flex-start']}>
                  <MembershipItem heading="Chapter" value={membership?.chapterName!} />
                  <MembershipItem heading="Member Since" isDate value={membership?.joinDate!} />
                  <MembershipItem heading="Member Expires" isDate value={membership?.expireDate!} />
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
          <IconButton
            size="sm"
            aria-label="View next membership"
            icon={<FontAwesomeIcon icon={faChevronRight} color="blue.500" boxSize={5} />}
            isDisabled={
              membershipData ? tabIndex === membershipData?.memberships?.length! - 1 : true
            }
            onClick={() => setTabIndex(tabIndex + 1)}
            alignSelf="center"
          />
        </Tabs>
      )}
    </ProfileSection>
  );
};

export interface MembershipItemProps {
  heading: string;
  value: string | Date;
  isDate?: boolean;
}

export const MembershipItem: React.FC<MembershipItemProps> = ({ heading, value, isDate }) => {
  return (
    <Text textStyle="sm" textAlign={['center', null, 'left']}>
      <Heading as="span" display={['block', 'inline']} size="hs-sm">
        {heading}:&nbsp;
      </Heading>
      {isDate ? getFriendlyACGDate(value, 'MM/DD/YYYY') : value}
    </Text>
  );
};
