import React from 'react';
import { Text, Flex, Box, Button, Heading, Input, Link } from '@companydotcom/potion';
import { useTranslation, Trans } from 'react-i18next';
import { useGenerateAndSendOtpQuery } from '../../../services/enrollment/enrollment-api';
// import { generateAndSendOtp } from '../../../services/enrollment/old-api/enrollment-svc';

export const VerifyEmailWrapper = ({ goToStep, user, isBack, reason }: any) => {
  const { t } = useTranslation();
  const { isLoading } = useGenerateAndSendOtpQuery({
    email: user.email,
    userId: user.userId,
    useEmail: true,
  });

  // const [isLoading, setIsLoading] = useState(false);
  // SNS listener here?
  // const advance = () => {
  //   // go to code screen
  //   // setIsLoading(false);
  //   goToStep('enter-code');
  // };

  const sendEmail = async () => {
    if (!isLoading) {
      goToStep('enter-code');
    }
    // setIsLoading(true);
    // await generateAndSendOtp({
    //   email: user.email,
    //   userId: user.userId,
    //   useEmail: true,
    // });
    // advance();
  };

  const checkout = t('components.verifyEmailWrapper.checkout');
  const activateYourProduct = t('components.verifyEmailWrapper.activateYourProduct');
  const resendEmailVerification = t('components.verifyEmailWrapper.resendEmailVerification');
  const secureYourAccount = t('components.verifyEmailWrapper.secureYourAccount');

  return (
    <Box className="verify-email-wrapper" maxWidth={704} width="full" py={14} mx="auto" flex={[1]}>
      <Flex textAlign="center" alignItems="center" flexDirection="column" maxWidth={600} mx="auto">
        <Heading pt={[2]} size="hs-2xl">
          {isBack ? resendEmailVerification : secureYourAccount}
        </Heading>
        <Text pt={[2]} textStyle="md" textAlign="center">
          {t('components.verifyEmailWrapper.proceed', {
            reason: reason === 'purchase' ? checkout : activateYourProduct,
          })}
        </Text>
        <Text pt={[2]} textStyle="sm" mt={2}>
          {t('components.verifyEmailWrapper.helpsConfirm')}
        </Text>
        <Text pt={[2]} textStyle="sm" pb={[10, 12]}>
          {t('components.verifyEmailWrapper.alreadyRecieved')}{' '}
          <Button
            variant="link"
            fontSize="sm"
            onClick={() => (!isLoading ? goToStep('enter-code') : null)}
          >
            {t('components.verifyEmailWrapper.enterVerificationCode')}
          </Button>
        </Text>
        <Flex mx="auto" maxWidth="400px" flexDirection="row" justifyContent="center" width="full">
          <Input isDisabled value={user?.email} />
        </Flex>
        <Button
          size="lg"
          mt={12}
          isLoading={isLoading}
          _hover={{
            textDecoration: 'none',
          }}
          onClick={sendEmail}
        >
          {t('components.verifyEmailWrapper.sendEmail')}
        </Button>
        <Text mt={[7, 12]} textStyle="sm">
          <Trans i18nKey="components.verifyEmailWrapper.needHelp">
            Need help?
            <Link href={`${process.env.REACT_APP_GRANDIO_URL}/contact-us`} isExternal>
              Contact Support.
            </Link>
          </Trans>
        </Text>
      </Flex>
    </Box>
  );
};
