import React, { useMemo, useState, useEffect } from 'react';
import { platformHelpers } from '@companydotcom/helpers';
import { TileButton, TileButtonProps } from './tile-button';

export const paramOptions = {
  pollingText: {
    key: 'pollingText',
    required: false,
    default: 'Please wait...',
  },
  restartText: {
    key: 'restartText',
    required: false,
    default: 'Try Again',
  },
  pollingVariant: {
    key: 'pollingVariant',
    required: false,
    default: 'disabled',
  },
  restartVariant: {
    key: 'restartVariant',
    required: false,
    default: 'secondary',
  },
};

export interface PollingAwareButtonProps extends TileButtonProps {
  isPolling?: boolean;
}

export const TilePollingButton = ({ params, isPolling, ...props }: PollingAwareButtonProps) => {
  const [showPolling, setShowPolling] = useState<any>('initial');

  useEffect(() => {
    setShowPolling((current: any) => {
      return current === 'initial' ? isPolling || 'initial' : isPolling;
    });
  }, [isPolling]);

  const text = useMemo(() => {
    const paramName = showPolling ? 'pollingText' : 'restartText';
    return (
      platformHelpers.getComponentParamValue(params, paramName) || paramOptions[paramName].default
    );
  }, [params, showPolling]);

  const variant = useMemo(() => {
    const paramName = showPolling ? 'pollingVariant' : 'restartVariant';
    return (
      platformHelpers.getComponentParamValue(params, paramName) || paramOptions[paramName].default
    );
  }, [params, showPolling]);

  return <TileButton {...props} variant={variant} text={text} />;
};
