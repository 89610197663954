import React from 'react';
import { Text, Box, useMultiStyleConfig } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import type { TileComponentProps } from '../../types';
import { useTile } from '../../../../providers';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

export interface TileSubheaderProps extends TileComponentProps {}

export const TileSubheader: React.FC<TileSubheaderProps> = props => {
  const styles = useMultiStyleConfig('TileSubheader', props);
  const { data: globalUser } = useGetGlobalUserQuery();
  const { variant, text, componentTheme } = props;
  const { t } = useTranslation();
  const from = t('common.misc.from').toUpperCase();
  const perMonth = t('common.misc.perMonth').toUpperCase();
  const tile = useTile();

  const getLowestPrice = () => {
    if (tile?.product && tile?.product.ratePlans && tile?.product.ratePlans.length > 0) {
      const sortedRatePlans = tile?.product.ratePlans
        .filter(plan =>
          globalUser?.account?.Products?.some(accountProduct =>
            plan?.eligibleProducts
              ? plan.eligibleProducts.includes(accountProduct?.productId as string)
              : false,
          ),
        )
        .filter(plan => plan?.price && plan.price > 0)
        .sort((a: any, b: any) => a?.price - b?.price)
        .map(plan => plan?.price);

      if (sortedRatePlans && sortedRatePlans.length < 1) return 0;

      return sortedRatePlans.length > 1
        ? `${from} $${sortedRatePlans[0]}${perMonth}`
        : `${t('common.misc.currencySymbol')}${sortedRatePlans[0]}${perMonth}`;
    }

    return 0;
  };

  switch (variant) {
    case 'divider' || !variant:
      return <SubheaderDivider componentTheme={{ ...styles.divider, ...componentTheme }} />;
    case 'subtitle':
      return (
        <Text
          className="tile__subheaderText"
          as="span"
          textStyle="xs"
          sx={{ ...styles.subtitle, ...componentTheme }}
        >
          {text}
        </Text>
      );
    case 'price': {
      const lowestPrice = getLowestPrice();
      return !lowestPrice ? (
        <SubheaderDivider componentTheme={{ ...styles.divider, ...componentTheme }} />
      ) : (
        <Text
          className="tile__subheaderText"
          textStyle="xs"
          sx={{ ...styles.price, ...componentTheme }}
        >
          {lowestPrice}
        </Text>
      );
    }
    default:
      return <SubheaderDivider componentTheme={{ ...styles.divider, ...componentTheme }} />;
  }
};

const SubheaderDivider = (props: TileComponentProps) => (
  <Box className="tile__subheaderDivider" sx={props.componentTheme} />
);
