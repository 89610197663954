const parts = ['circleEnvelope'];
const baseStyleCircleEnvelope = {
    boxSize: [20, null, 108],
    color: 'success',
};
const baseStyleCheckCircle = {
    boxSize: [20, null, 108],
    color: 'success',
};
const baseStyleCircleExclamation = {
    boxSize: [20, null, 108],
    color: 'error',
};
const baseStyle = {
    circleEnvelope: baseStyleCircleEnvelope,
    checkCircle: baseStyleCheckCircle,
    circleExclamation: baseStyleCircleExclamation,
};
export default {
    parts,
    baseStyle,
};
