import React, { useMemo } from 'react';

import { Text, Flex, Icon } from '@companydotcom/potion';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { TileSpinner } from '../tile-spinner';
import type { TileComponentProps } from '../../types';
import { useGetAcgQueryArgs, useGetMembershipsV2Query } from '../../../../services/acg/acg-api-v2';

export interface TileAcgMembershipChecklistProps extends TileComponentProps {}

export const TileAcgMembershipChecklist: React.FC<TileAcgMembershipChecklistProps> = ({
  stateCurrent,
}) => {
  const args = useGetAcgQueryArgs();
  const { isLoading: isMembershipLoading } = useGetMembershipsV2Query(
    stateCurrent?.stateName
      ? {
          stateCurrent: stateCurrent?.stateName,
          ...args,
        }
      : skipToken,
  );

  const { t } = useTranslation();

  const whyJoinTokens = useMemo(
    () => [
      { label: t('acg.membershipStatus.nonMember.body.exclusiveEvents') },
      { label: t(`acg.membershipStatus.nonMember.body.specialMemberRates`) },
      { label: t(`acg.membershipStatus.nonMember.body.membershipDirectory`) },
      { label: t(`acg.membershipStatus.nonMember.body.mmgMagazine`) },
      { label: t(`acg.membershipStatus.nonMember.body.memberOnlyOffers`) },
    ],
    [t],
  );

  return !isMembershipLoading ? (
    <Flex flexDirection="column" width="full" flexGrow={1} px={4} my={[7]}>
      {whyJoinTokens.map((item, i) => (
        <Text key={i} alignSelf="start" display="inherit" fontWeight="normal" mb={2}>
          <Icon
            className="fa-solid"
            as={FontAwesomeIcon}
            icon={faCheck}
            boxSize="22px"
            mr={[2]}
            color="success"
          />
          {item.label}
        </Text>
      ))}
    </Flex>
  ) : (
    <TileSpinner />
  );
};
