import { UserGroup, UserTile } from '@companydotcom/types';
// import {
//   setUserTileGroup,
//   updateUserProfile,
//   setUserTileStateLite,
// } from '../../../services/user/user-svc';
// import { updateByopTile } from '../../../services/tile/old-api/tile-service';

const getVisibleGroups = (groups: Array<UserGroup>, showAllTiles: boolean) =>
  showAllTiles ? groups : groups?.filter?.((group: UserGroup) => !group?.isHidden);

const getVisibleTiles = (tiles: UserTile[], showAllTiles: boolean) =>
  showAllTiles ? tiles : tiles?.filter?.((tile: UserTile) => !tile?.isHidden);

export const filterSettingReducer = (filterState: any, action: any) => {
  const {
    showAllTiles,
    updateUser,
    setUserTileGroup,
    setUserTileStateLite,
    allGroups,
    allTiles,
    updateByopTile,
  } = filterState;
  switch (action.type) {
    case 'SET_SHOW_ALL':
      if (action.payload && action.payload.updateUser) {
        updateUser({
          input: {
            userId: action.payload.userId,
            userSettings: { showAllTiles: action.payload.showAllTiles },
          },
        });
      }
      if (action.payload.showAllTiles !== showAllTiles) {
        return {
          ...filterState,
          showAllTiles: action.payload.showAllTiles || false,
          visibleGroups: getVisibleGroups(action.payload.groups, action.payload.showAllTiles),
          visibleTiles: getVisibleTiles(action.payload.tiles, action.payload.showAllTiles),
        };
      }
      return filterState;
    case 'TILE_INPUT_CHANGED':
      return {
        ...filterState,
        allTiles: action.payload.tiles,
        visibleTiles: getVisibleTiles(action.payload.tiles, showAllTiles),
      };
    case 'GROUP_INPUT_CHANGED':
      return {
        ...filterState,
        allGroups: action.payload.groups,
        visibleGroups: getVisibleGroups(action.payload.groups, showAllTiles),
      };
    case 'TILE_FILTER_CHANGED':
      {
        console.log('TILE_FILTER_CHANGED Action: ', action);
        const targetTiles = allTiles.find(
          (tile: UserTile) => tile.tileId === action.payload.tileId,
        );
        if (targetTiles) {
          const newAllTiles = allTiles.slice();
          const targetTilesIndex = newAllTiles.findIndex(
            (tile: any) => action.payload.tileId === tile.tileId,
          );
          newAllTiles[targetTilesIndex] = { ...targetTiles, isHidden: action.payload.isHidden };

          if (action.payload.productId === 'byop') {
            updateByopTile({
              byopTileId: action.payload.tileId,
              userId: action.payload.userId,
              isHidden: action.payload.isHidden,
              seqNo: newAllTiles[targetTilesIndex].seqNo,
              hasBeenLaunched: action.payload.hasBeenLaunched,
            });
          } else {
            setUserTileStateLite({
              input: {
                userId: action.payload.userId,
                tileId: action.payload.tileId,
                isHidden: action.payload.isHidden,
                seqNo: newAllTiles[targetTilesIndex].seqNo,
                stateCurrent: newAllTiles[targetTilesIndex].stateCurrent,
              },
            });
          }
          return {
            ...filterState,
            allTiles: newAllTiles,
            visibleTiles: getVisibleTiles(newAllTiles, showAllTiles),
          };
        }
      }
      return filterState;
    case 'GROUP_FILTER_CHANGED': {
      const targetGroup = allGroups.find(
        (group: UserGroup) => action.payload.groupId === group.groupId,
      );
      if (targetGroup) {
        const newAllGroups = allGroups.slice();
        const targetGroupIndex = newAllGroups.findIndex(
          (group: UserGroup) => action.payload.groupId === group.groupId,
        );
        newAllGroups[targetGroupIndex] = { ...targetGroup, isHidden: action.payload.isHidden };
        setUserTileGroup({
          input: {
            userId: action.payload.userId,
            groupId: action.payload.groupId,
            isHidden: action.payload.isHidden,
          },
        });
        return {
          ...filterState,
          allGroups: newAllGroups,
          visibleGroups: getVisibleGroups(newAllGroups, showAllTiles),
        };
      }
      return filterState;
    }
    case 'GROUP_FILTER_CHANGED_MULTI': {
      const newAllGroups = action.payload?.allGroups?.slice();
      if (action.payload.groupSettings.length) {
        action.payload.groupSettings.forEach((groupSetting: any) => {
          const groupIdx = newAllGroups.findIndex(
            (group: UserGroup) => groupSetting.groupId === group.groupId,
          );
          if (groupIdx > -1) {
            newAllGroups[groupIdx] = { ...newAllGroups[groupIdx], ...groupSetting };
          }
          setUserTileGroup({
            input: {
              userId: action.payload.userId,
              groupId: groupSetting.groupId,
              isHidden: groupSetting.isHidden,
            },
          });
        });
      }
      return {
        ...filterState,
        allGroup: newAllGroups,
        visibleGroups: getVisibleGroups(newAllGroups, action.payload.showAllTiles),
      };
    }
    default:
      return filterState;
  }
};
