import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Box, useMultiStyleConfig, StylesProvider, Text, Button } from '@companydotcom/potion';
import { useSourceConfig, useSource } from '@companydotcom/providers';
import { checkForPasswordBreach } from '../api/check-for-password-breach';
import { useToast } from '../../../hooks';
import {
  useGetGlobalUserQuery,
  useUpdateUserPasswordMutation,
} from '../../../services/user/user-api';
import { PageHeading } from '../../../components/elements';
import { ChangePasswordForm } from './change-password-form';
import { useAuth } from '../../../providers';
import { determineLogoutDestination } from '../../../providers/auth-provider/auth-context-helpers';

export interface ChangeAccountPasswordProps {}

export const ChangeAccountPasswordStep: React.FC<ChangeAccountPasswordProps> = props => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [updatePassword] = useUpdateUserPasswordMutation();
  const sourceConfig = useSourceConfig();
  const source = useSource();
  const authUser = useAuth();
  const { t } = useTranslation();
  const defaultValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const ChangeAccountPasswordStepSchema = () =>
    yup.object().shape({
      confirmNewPassword: yup
        .string()
        .matches(/^\S*$/, 'common.inputs.password.error')
        .oneOf([yup.ref('newPassword'), undefined], 'common.inputs.confirmNewPassword.validation')
        .min(
          sourceConfig.passwordRequirements?.min || 8,
          t('miop.passwordManagement.minCharacter', {
            minimum: sourceConfig.passwordRequirements?.min,
          }),
        )
        .required('common.inputs.confirmNewPassword.error'),
      newPassword: yup
        .string()
        .matches(/^\S*$/, 'common.inputs.password.error')
        .min(
          sourceConfig.passwordRequirements?.min || 8,
          t('miop.passwordManagement.minCharacter', {
            minimum: sourceConfig.passwordRequirements?.min,
          }),
        )
        .max(
          sourceConfig.passwordRequirements?.max || 20,
          t('miop.passwordManagement.maxCharacter', {
            maximum: sourceConfig.passwordRequirements?.max,
          }),
        )
        .test(
          'contains symbol',
          t('miop.passwordManagement.symbol', {
            symbol: sourceConfig.passwordRequirements?.specialChars,
          }),
          value => {
            if (
              sourceConfig.passwordRequirements?.specialChars === '' ||
              sourceConfig.passwordRequirements?.specialChars === undefined
            ) {
              return true;
            }
            if (value && sourceConfig.passwordRequirements?.specialChars) {
              for (const s of sourceConfig.passwordRequirements?.specialChars) {
                if (value.includes(s)) {
                  return true;
                }
              }
            }
            return false;
          },
        )
        .required('common.inputs.newPassword.error'),
    });

  const { register, handleSubmit, setError, formState, reset } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(ChangeAccountPasswordStepSchema()),
    defaultValues,
  });
  const toast = useToast();
  const { isSubmitting, isValid, errors } = formState;
  const [dataBreach, setDataBreach] = useState(false);
  const styles = useMultiStyleConfig('ChangeAccountPasswordStep', props);

  const manualErrors = [
    {
      type: 'manual',
      name: 'newPassword',
      message: 'Please try again',
    },
    {
      type: 'manual',
      name: 'confirmNewPassword',
      message: 'Please try again',
    },
  ];

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      setDataBreach(false);
      const breachAttempts = await checkForPasswordBreach(values.confirmNewPassword);

      if (breachAttempts > 0) {
        setDataBreach(true);
        manualErrors.forEach(({ name, type, message }) =>
          setError(name as 'newPassword' | 'confirmNewPassword', { type, message }),
        );
        return;
      }

      // This resolves into a GQL error, but end-to-end saves the password on the front & backend for the user. Tech debt: will need to be resolved in the future
      await updatePassword({
        userId: globalUser?.userId,
        password: values?.newPassword,
      });

      toast({
        description: t('miop.passwordManagement.snackbar.success'),
        status: 'success',
        position: source.sourceId === 'icommerce' ? 'top' : 'top-right',
      });
      reset();
      if (source && source.sourceId && source.sourceId === 'acg') {
        logout();
      }
    } catch (err) {
      toast({
        description: t('miop.passwordManagement.snackbar.error'),
        status: 'error',
        position: source.sourceId === 'icommerce' ? 'top' : 'top-right',
      });
      reset();
      console.log('Error!', err);
    }
  };

  const logout = () => {
    // clear appsync cache
    // clear poll interval if exists
    const logoutHref = determineLogoutDestination(source.sourceId);
    authUser?.logout(logoutHref);
    const newRedirectionURl = window.location.origin;
    window.location.replace(`${newRedirectionURl}/login`);
  };

  return (
    <StylesProvider value={styles}>
      <Box className="potion-changeAccountPasswordStep__container" sx={styles.container}>
        <PageHeading heading="Password Management" mb={6} />
        <Box as="form" className="potion-changeAccountPasswordStep__form" sx={styles.form}>
          <Box textAlign="left" mb={[6]}>
            <Text textStyle="md">{t('miop.passwordManagement.requirements')}</Text>
          </Box>
          <ChangePasswordForm
            register={register}
            errors={errors}
            dataBreach={dataBreach}
            formStyles={styles.form}
            fieldStyles={styles.fields}
          />
        </Box>
        <Box textAlign="center" mt={[8]}>
          <Button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            size="lg"
            isDisabled={!isValid}
            isLoading={isSubmitting}
            sx={styles.formButton}
            width={['full', 'inherit']}
          >
            {t('common.buttons.save')}
          </Button>
        </Box>
      </Box>
    </StylesProvider>
  );
};
