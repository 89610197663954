import React, { useState, useReducer } from 'react';

import {
  Box,
  Flex,
  Text,
  Switch,
  FormControl,
  FormLabel,
  Center,
  Heading,
  Button,
  Checkbox,
  Select,
  FormHelperText,
  useBoolean,
  Icon,
} from '@companydotcom/potion';
import _get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/pro-solid-svg-icons';
import { User, Listing } from '@companydotcom/types';
import daysReducer, {
  createInitialState,
  exportSubmissionFormat,
  Hour,
  TimeSeries,
} from '../utils/hours-helpers';
import { PendingVerification } from './shared-blpcomponents';
import { useAwaitableFacade, useToast } from '../../../../hooks';
import { useUpdateListingMutation } from '../../../../services/listings/listing-api';

interface HoursOfOperationProps {
  user?: User;
  listing?: Listing;
  setListing?: (arg: Listing) => void;
  showPendingVerification?: boolean;
  // listingSvc?: any;
}

export const HoursOfOperation = ({
  user,
  listing,
  setListing,
  showPendingVerification,
}: // listingSvc,
HoursOfOperationProps) => {
  const toast = useToast();
  const [days, dispatch] = useReducer(daysReducer, createInitialState(_get(listing, 'hours')));
  const [submitting, setSubmitting] = useState(false);
  const [updateListing] = useUpdateListingMutation();
  const [showHours, setShowHours] = useState(true);
  const snsInterface = useAwaitableFacade(user, 'listing');

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const result = exportSubmissionFormat(days);
      if (!result) {
        dispatch({ type: 'MARK_ERRORS' });
        toast({
          description: 'Invalid time submitted',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      } else {
        const updateParams = {
          hours: result,
          showHours,
          id: user?.account?.listingId as string,
        };
        // await listingSvc
        //   .updateListing({ input: updateParams })
        //   .unwrap()
        //   .then((updatedListing: Listing) => {
        //     setListing?.(updatedListing);
        //     toast({
        //       description: 'Your changes have been synced!',
        //       status: 'success',
        //       duration: 9000,
        //       isClosable: true,
        //       position: 'bottom-right',
        //     });
        //     setSubmitting(false);
        //   })
        //   .catch((err: string) => {
        //     console.log('error submitting: ', err);
        //     setSubmitting(false);
        //   });

        if (user) {
          await updateListing({ listingInput: updateParams, snsInterface, source: user?.source })
            .unwrap()
            .then(updatedListing => {
              setListing?.(updatedListing);
              toast({
                description: 'Your changes have been synced!',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
              });
              setSubmitting(false);
            })
            .catch((err: string) => {
              console.log('error submitting: ', err);
              setSubmitting(false);
            });
        }
      }
    } catch (err) {
      console.log('Error!', err);
    }
  };

  return (
    <Center
      className="blp-hours-of-operation"
      flexDirection="column"
      pt={[12, null, 3]}
      pb={[12, null, 15]}
      width="full"
      px={[0, null, 4]}
      textAlign="center"
      justifyContent="flex-start"
    >
      {showPendingVerification && <PendingVerification />}
      <Flex maxWidth={679} flexDirection="column" alignItems="center">
        <Box>
          <Heading as="h1" size="hs-lg">
            Hours of Operation
          </Heading>
          <Heading as="h2" size="hs-md" mt={[5]}>
            Add your hours of operations so that customers know when the business is open.
          </Heading>
        </Box>
        <Box width="full" pt={[12]} px={[3, null, '0px']}>
          <Text textAlign="left" textStyle="lg" fontWeight="medium" mb={4}>
            Hours of Operation
          </Text>
          {days.map((day: any) => (
            <SeriesBlock
              key={day.id}
              day={day}
              onClose={(tsIdx: any) =>
                dispatch({ type: 'REMOVE_SERIES', payload: { dayId: day.id, tsIdx } })
              }
              onStartHourSet={(tsIdx: any, hourId: number) => {
                dispatch({
                  type: 'SET_START_HOUR',
                  payload: { dayId: day.id, tsIdx, hourId },
                });
              }}
              onEndHourSet={(tsIdx: any, hourId: any) =>
                dispatch({ type: 'SET_END_HOUR', payload: { dayId: day.id, tsIdx, hourId } })
              }
              onToggleDayOpen={() =>
                dispatch({
                  type: 'TOGGLE_DAY_OPEN',
                  payload: { dayId: day.id, isOpen: !day.isOpen },
                })
              }
              addTimeSeries={() => {
                dispatch({ type: 'ADD_SERIES', payload: { dayId: day.id } });
              }}
            />
          ))}
          <Flex flexDirection={['row']} justifyContent="space-between">
            <Checkbox isChecked={showHours} onChange={() => setShowHours(!showHours)}>
              Show Hours of Operation
            </Checkbox>
          </Flex>
          <Box sx={{ textAlign: 'center' }} mt={[5, 14]}>
            <Button
              size="lg"
              onClick={handleSubmit}
              id="CTA__submit"
              variant="outline"
              isLoading={submitting}
              isDisabled={submitting || showPendingVerification}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Flex>
    </Center>
  );
};

interface SeriesBlockProps {
  day: any;
  onClose: any;
  onStartHourSet: any;
  onEndHourSet: any;
  onToggleDayOpen: any;
  addTimeSeries: any;
}

interface ExtendedHour extends Hour {
  disabled?: boolean;
  disabledText?: string;
}

export const SeriesBlock = ({
  day,
  onClose,
  onStartHourSet,
  onEndHourSet,
  onToggleDayOpen,
  addTimeSeries,
}: SeriesBlockProps) => {
  const getDefaultStartHourId = (ts: TimeSeries) => {
    return ts.startHourId !== -1 ? ts.startHourId : -1;
  };

  const getDefaultEndHourId = (ts: TimeSeries) => {
    return ts.endHourId !== -1 ? ts.endHourId : -1;
  };
  const [addHours] = useBoolean(false);

  const getStartHours = (ts: TimeSeries, tsIdx: number) => {
    return ts.startHourId === -1
      ? [{ name: '12:00 AM', id: -1, disabled: true }].concat(
          day.hours.map((hour: Hour) => ({
            ...hour,
            disabled:
              // @ts-ignore
              (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
              (ts.endHourId !== -1 && hour.id <= ts.endHourId),
            disabledText: 'unavailable',
          })),
        )
      : day.hours.map((hour: Hour) => ({
          ...hour,
          disabled:
            // @ts-ignore
            (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
            (ts.endHourId !== -1 && hour.id <= ts.endHourId),
          disabledText: 'unavailable',
        }));
  };

  const getEndHours = (ts: TimeSeries, tsIdx: number) => {
    return ts.endHourId === -1
      ? [{ name: '12:00 AM', id: -1, disabled: true }].concat(
          day.hours.map((hour: Hour) => ({
            ...hour,
            disabled:
              // @ts-ignore
              (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
              (ts.startHourId !== -1 && hour.id <= ts.startHourId),
            disabledText: 'unavailable',
          })),
        )
      : day.hours.map((hour: Hour) => ({
          ...hour,
          disabled:
            // @ts-ignore
            (!!hour.busy && hour.busy !== `ts-${tsIdx}`) ||
            (ts.startHourId !== -1 && hour.id <= ts.startHourId),
          disabledText: 'unavailable',
        }));
  };
  return (
    <Flex
      width="full"
      flexDirection={['column', null, 'row']}
      flexWrap={['wrap']}
      justifyContent={['space-between']}
      alignItems={['flex-start']}
      mb={[4]}
      px={[4, 6]}
      sx={{
        borderRadius: '4px',
        border: 'solid 1px #e2e6e8',
      }}
    >
      <Flex
        flexDirection="row"
        width="250px"
        height={[day.isOpen ? null : '90px', null, '90px']}
        pt={[day.isOpen ? 8 : null, null, '0px']}
        mr={[0, null, 4]}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Text minW={100} textAlign="left" marginRight={[2]}>
          {day.name}
        </Text>
        <Box ml={4}>
          <FormControl display="flex" alignItems="center">
            <Switch id="daytoggle" isChecked={day.isOpen} onChange={onToggleDayOpen} />
            <FormLabel ml={4} htmlFor="daytoggle" mb="0">
              {day.isOpen ? 'OPEN' : 'CLOSED'}
            </FormLabel>
          </FormControl>
        </Box>
      </Flex>
      {day.isOpen ? (
        <Flex flexDirection="column" alignItems="center">
          {day.timeSeries.map((ts: TimeSeries, tsIdx: number) => (
            <Flex
              height="90px"
              _notFirst={{ mt: 5 }}
              key={tsIdx}
              flexWrap="wrap"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Flex
                my={[3, null, 0]}
                mr={6}
                flexDirection={['row']}
                justifyContent="flex-start"
                alignItems="baseline"
              >
                <Text textAlign="left" textStyle="md" mr={4}>
                  From
                </Text>
                <Select
                  maxW={135}
                  name="startHour"
                  value={getDefaultStartHourId(ts)}
                  onChange={(e: any) => {
                    onStartHourSet(tsIdx, e.target.value);
                  }}
                >
                  {getStartHours(ts, tsIdx).map((opt: ExtendedHour) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </Select>
                {/* @ts-ignore */}
                <FormHelperText>{ts.startError ? 'Please pick a time' : ''}</FormHelperText>
              </Flex>
              <Flex flexDirection={['row']} justifyContent="flex-start" alignItems="baseline">
                <Text textAlign="left" textStyle="md" mr={4}>
                  To
                </Text>
                <Select
                  maxW={135}
                  name="endHour"
                  value={getDefaultEndHourId(ts)}
                  onChange={(e: any) => {
                    onEndHourSet(tsIdx, e.target.value);
                  }}
                >
                  {getEndHours(ts, tsIdx).map((opt: ExtendedHour) => (
                    <option key={opt.id} value={opt.id} disabled={opt.disabled}>
                      {opt.name}
                    </option>
                  ))}
                </Select>
                {/* @ts-ignore */}
                <FormHelperText>{ts.startError ? 'Please pick a time' : ''}</FormHelperText>
              </Flex>
            </Flex>
          ))}
          <Flex
            width="full"
            my={[4]}
            mr={-5}
            flexDirection={['row']}
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Button
              onClick={(e: any) => {
                if (day.timeSeries.length > 1) {
                  onClose(day.timeSeries.length - 1);
                } else {
                  addTimeSeries(e);
                }
              }}
              display="inline-flex"
              alignItems="center"
              variant="unstyled"
              borderRadius="none"
              size="sm"
              fontSize={['sm', 'md']}
              py={0}
              mt={4}
              leftIcon={
                <Icon as={FontAwesomeIcon} icon={addHours ? faCircleMinus : faCirclePlus} />
              }
            >
              {day.timeSeries.length > 1 ? 'remove hours' : 'add more hours'}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex width={[1, null, 0.6]} />
      )}
    </Flex>
  );
};
