import React, { useMemo } from 'react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@companydotcom/potion';
import { PageDivider } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { AcgMemberships } from '../../../../services/acg/acg.types';
import { getFriendlyACGDate } from '../../shared';

export interface AutoRenewHeaderProps {
  chapterDataFromTile?: {
    chapterNames?: string[];
    expireDate?: string;
  };
  membershipData?: AcgMemberships;
}

export const AutoRenewHeader: React.FC<AutoRenewHeaderProps> = ({
  membershipData,
  chapterDataFromTile,
}) => {
  const { t } = useTranslation();
  const headingSize = useBreakpointValue({ base: 'md', sm: 'xl' });
  const chapterNames = useMemo(() => {
    if (chapterDataFromTile) {
      return chapterDataFromTile?.chapterNames;
    }
    return membershipData?.memberships?.map(m => m?.chapterName);
  }, [chapterDataFromTile, membershipData?.memberships]);

  const expireDate = useMemo(() => {
    if (chapterDataFromTile) {
      return chapterDataFromTile?.expireDate;
    }
    return membershipData?.memberships?.[0]?.expireDate;
  }, [chapterDataFromTile, membershipData?.memberships]);

  return (
    <Box textAlign={['center', 'left']}>
      <Heading size={headingSize}>{t('acg.autoRenew.heading')}</Heading>
      <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
      <Alert
        backgroundColor="#F0FFF4"
        border="1px solid #428521"
        variant="subtle"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={12}
      >
        <AlertTitle fontSize="3xl" lineHeight={9} justifyContent="center" alignSelf="center" py={4}>
          {chapterNames?.map((name: string) => `ACG ${name} Chapter`)}
        </AlertTitle>
        <AlertDescription fontSize={14} maxW="70%" lineHeight={4}>
          <Stack spacing={3} alignItems="center">
            <Stack direction={['column', 'row']} pb={4}>
              <Text fontWeight="bold">{t('acg.autoRenew.expires')}</Text>
              <Text>{expireDate ? getFriendlyACGDate(expireDate, 'MMMM D, YYYY') : '-'}</Text>
            </Stack>
          </Stack>
        </AlertDescription>
      </Alert>
    </Box>
  );
};
