import React, { RefObject, useState } from 'react';
import type { Maybe } from '@companydotcom/types';
import {
  useMediaQuery,
  Text,
  Box,
  SkeletonText,
  BoxProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Center,
  Button,
  Heading,
  Flex,
} from '@companydotcom/potion';
import { EditButton } from './edit-button';

type BioProps = {
  value?: Maybe<string>;
  canEdit?: boolean;
  formSlug?: string;
  onOpen?: () => void;
  setFormSlug?: (formSlug: string, data?: any) => void;
  isLoading?: boolean;
  bioRef?: RefObject<HTMLParagraphElement>;
} & BoxProps;

export const ProfileBio: React.FC<BioProps> = ({
  value,
  canEdit = false,
  formSlug,
  onOpen,
  setFormSlug,
  isLoading = false,
  bioRef,
  ...rest
}) => {
  const [isMobile] = useMediaQuery('(max-width: 48rem)');
  const [isHover, setIsHover] = useState(false);

  const handleEditButtonClick = (slug: string, data: any) => {
    onOpen?.();
    setFormSlug?.(slug, data);
  };

  return (
    <Box
      className="profile-bio"
      textAlign={['center', 'left']}
      p={2}
      borderRadius={4}
      alignItems={['center', 'flex-start']}
      backgroundColor={isHover && !isLoading && canEdit ? 'gray.200' : 'white'}
      onMouseEnter={isLoading ? undefined : () => setIsHover(true)}
      onMouseLeave={isLoading ? undefined : () => setIsHover(false)}
      position="relative"
      width="100%"
      {...rest}
    >
      {isLoading ? (
        <SkeletonText noOfLines={3} />
      ) : (
        <>
          {((isMobile && canEdit) || (!isMobile && isHover && canEdit)) && (
            <EditButton
              setFormSlug={
                formSlug ? () => handleEditButtonClick(formSlug, { bio: value }) : undefined
              }
              position="absolute"
              right={2}
              top={2}
              opacity={1}
              zIndex={1}
            />
          )}
          <Text
            ref={bioRef}
            wordBreak="break-word"
            fontFamily="inherit"
            whiteSpace="pre-wrap"
            textStyle="sm"
            maxH={210}
            maxW={390}
            noOfLines={10}
            overflowY="hidden"
            textOverflow="ellipsis"
            opacity={!value || value === '' || value === ' ' ? 0.5 : 1}
            pr={5}
          >
            {!value || value === '' || value === ' '
              ? 'Your bio is great place to tell other members about your experience and specific areas you conduct business…'
              : value}
          </Text>
        </>
      )}
    </Box>
  );
};

type FullBioModalProps = {
  isOpen: boolean;
  onClose: () => void;
  bioText?: Maybe<string>;
};

export const FullBioModal: React.FC<FullBioModalProps> = ({ isOpen, onClose, bioText }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        my={[null, '60px']}
        px={[24]}
        height={['inherit', 'auto']}
        maxW={956}
        overflow="auto"
        backgroundColor={['gray.50', 'white']}
      >
        <ModalCloseButton />
        <Center>
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Heading size="hs-xl" fontWeight="700" mt={[6, 12]} mb={[2, 2, 12]} textAlign="center">
              Bio
            </Heading>
            <Text wordBreak="break-word" fontFamily="inherit" whiteSpace="pre-wrap">
              {bioText}
            </Text>
            <Button
              size="sm"
              my={50}
              display={['none', 'inherit']}
              variant="outline"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </Flex>
        </Center>
      </ModalContent>
    </Modal>
  );
};
