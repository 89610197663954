import React, { useMemo } from 'react';
import { Center } from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { AcgPublicProfile } from '../../../features/acg/acg-my-profile';
import { useAppSelector } from '../../../hooks';
import { getPublicProfileData } from '../../../features/acg/acg-member-search/member-search-slice';
import { useGetAllChapters } from '../../../features/acg/acg-checkout';
import { useGetAcgMemberProfileImage } from '../../../features/acg/shared';

export interface PublicProfileProps {
  goToStep?: (arg: number | string) => void;
}

export interface LocationStateProps {
  recordKey: string;
  imageUrl: string;
  chapterIds: string;
}

export const MemberPublicProfile: React.FC<PublicProfileProps> = () => {
  const acgUserProfileData = useAppSelector(getPublicProfileData);

  const memberAvatarUrl = useGetAcgMemberProfileImage(acgUserProfileData?.imageUrl);

  const { chapters } = useGetAllChapters();

  const chapterNames = useMemo(() => {
    if (acgUserProfileData?.chapterIds) {
      const ids = acgUserProfileData?.chapterIds?.split(', ');
      return ids?.map((id: string) => chapters?.find(c => c?.chapterId === id)?.chapterName);
    }
  }, [acgUserProfileData?.chapterIds, chapters]);

  if (!acgUserProfileData) {
    return (
      <Center flexGrow={1} width="full">
        <AppSpinner my={12} />
      </Center>
    );
  }

  // console.log('acg member public profile url:', memberAvatarUrl)
  return (
    <AcgPublicProfile
      userImage={memberAvatarUrl}
      profileData={acgUserProfileData}
      chapterIds={chapterNames}
      isMemberSearch
    />
  );
};
