import React, { useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {
  Tabs,
  TabPanels,
  TabPanel,
  useTabStepper,
  Center,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@companydotcom/potion';
import { isEmpty } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEventPassed } from '@companydotcom/helpers';
import type { AcgUserProfile } from '@companydotcom/types';
import { BackButtonLayout } from '../../../../components/layouts';
import { MyEventsList } from './my-events-list';
import { MyEventsEditGuestList } from './my-events-edit-guest-list';
import { MyEventsAttendeeList } from './my-events-attendee-list';
import { MyEventsAddGuest } from './my-events-add-guest';
import {
  EventsHeader,
  EventsTitle,
  useFormatAcgEventDate,
  useShowAddGuestButton,
} from '../../shared';
import { useAppSelector } from '../../../../hooks';
import { selectActiveEvent } from '../my-events-slice';
import {
  useGetRegisteredEventQuery,
  useGetEventForRegistrationQuery,
} from '../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { selectGuestsToRegister } from '../../acg-add-guest/add-guest-slice';
import {
  MyEventsEditGuestListForm,
  MyEventsEditGuestListFormProps,
} from '../components/my-events-edit-guest-list-form';
import { MyEventsViewGuestList } from '../components/my-events-view-guest-list';
import { EventForRegistration, Guest, RegisteredEvent } from '../../../../services/acg/acg.types';
import {
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
} from '../../../../services/acg/acg-api-v2';

const steps = [
  {
    slug: 'my-events-list',
    component: <MyEventsList />,
  },
  {
    slug: 'my-events-edit',
    component: <MyEventsEditGuestList />,
  },
  {
    slug: 'my-events-add-guest',
    component: <MyEventsAddGuest />,
  },
  {
    slug: 'my-events-attendee-list',
    component: <MyEventsAttendeeList />,
  },
];

export interface LocationStateProps {
  defaultStep:
    | 'my-events-list'
    | 'my-events-edit'
    | 'my-events-add-guest'
    | 'my-events-attendee-list';
}

export const MyEvents = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const state = location.state as LocationStateProps;

  const guestsToRegister = useAppSelector(selectGuestsToRegister);
  const activeEvent = useAppSelector(selectActiveEvent);

  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const { data: acgUser, isLoading: isAcgUserProfileLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const {
    data: registeredEvent,
    isLoading,
    isError,
  } = useGetRegisteredEventQuery(
    activeEvent?.id && globalUser
      ? {
          user: globalUser,
          registrationId: activeEvent?.id,
        }
      : skipToken,
  );

  const { data: eventForRegistration, isLoading: isAcgEventLoading } =
    useGetEventForRegistrationQuery(
      registeredEvent?.eventId && globalUser
        ? { user: globalUser, eventId: registeredEvent?.eventId }
        : skipToken,
    );

  const { tabIndex, handleTabsChange, nextStep, previousStep, goToStep, isLastStep, currentStep } =
    useTabStepper({
      steps,
      initialStep: state?.defaultStep,
    });

  useEffect(() => {
    if (
      searchParams.get('WebCode')?.toLowerCase() === 'attendeelist' &&
      searchParams.get('evt_key')
    ) {
      goToStep('my-events-attendee-list');
    }
  }, [goToStep, searchParams]);

  const date = useFormatAcgEventDate({
    eventStartDate: activeEvent?.eventStartDate as unknown as Date,
    eventEndDate: activeEvent?.eventEndDate as unknown as Date,
    eventTimezone: registeredEvent?.eventTimezone,
    eventTimezoneMode: registeredEvent?.eventTimezoneMode,
    eventTimezoneAbbreviation: registeredEvent?.eventTimezoneAbbreviation,
  });

  const shouldShowGuestButton = useShowAddGuestButton(
    eventForRegistration,
    eventForRegistration?.registrantAndFees?.[0]?.guests?.[0],
    guestsToRegister.length,
  );

  return (
    <BackButtonLayout
      isDisplayed={tabIndex > 0}
      onBackClick={() => {
        if (state?.defaultStep) {
          navigate('/');
          return;
        }

        if (isLastStep) {
          goToStep?.(0);
          return;
        }

        previousStep?.();
      }}
    >
      <Tabs variant="page" index={tabIndex} onChange={handleTabsChange} isLazy>
        <TabPanels>
          {steps.map(step => (
            <TabPanel key={step.slug}>
              <Center
                className={`myEvents__${step.slug}`}
                flexDirection="column"
                bg={['transparent', 'white']}
                py={[0, 8, 12]}
                px={[0, 4]}
                mx={[3, 0]}
              >
                <Box maxW={908} width="full">
                  <EventsHeader
                    text={
                      currentStep?.slug === 'my-events-attendee-list'
                        ? 'Attendee List'
                        : 'My Registrations'
                    }
                  />
                  {currentStep.slug !== 'my-events-list' && (
                    <>
                      <EventsTitle
                        eventName={activeEvent?.eventName ?? ''}
                        eventDate={date}
                        viewAttendeeListHandler={() => goToStep('my-events-attendee-list')}
                        showAttendeeList={
                          registeredEvent?.listRegistrantsOnWeb &&
                          currentStep?.slug !== 'my-events-attendee-list'
                        }
                        showInvoiceButton={
                          (!registeredEvent?.registrantType?.toLowerCase()?.includes('guest') &&
                            !!activeEvent?.registrationInvoice) ||
                          !!registeredEvent?.invoiceDetails
                        }
                        showDivider={
                          isEmpty(registeredEvent?.guests) &&
                          isEmpty(guestsToRegister) &&
                          currentStep?.slug !== 'my-events-attendee-list'
                        }
                        invoiceCode={
                          registeredEvent?.invoiceDetails
                            ? registeredEvent?.invoiceDetails[0]?.invoiceCode
                            : ''
                        }
                        guestData={registeredEvent?.guests?.length ? registeredEvent?.guests : []}
                        isLoading={isLoading || isAcgUserProfileLoading || isAcgEventLoading}
                        onWaitList={registeredEvent?.onWaitList}
                        isEventPassed={isEventPassed(activeEvent?.eventEndDate ?? '')}
                      />
                      {registeredEvent &&
                        (!isEmpty(registeredEvent?.guests) || !isEmpty(guestsToRegister)) &&
                        currentStep?.slug !== 'my-events-attendee-list' && (
                          <Accordion
                            allowToggle
                            borderTopWidth="1px"
                            borderLeftWidth="1px"
                            borderRightWidth="1px"
                            width="full"
                            my={12}
                          >
                            {formatDataForGuestList(
                              registeredEvent,
                              acgUser,
                              eventForRegistration,
                              guestsToRegister,
                            ).map((guest, i) => (
                              <AccordionItem borderTopWidth="0px" borderBottomWidth="1px" key={i}>
                                <AccordionButton py={4}>
                                  <Text
                                    flex="1"
                                    textAlign="left"
                                    fontWeight="semibold"
                                    fontSize="md"
                                  >
                                    {`${i === 0 ? 'Attendee:' : 'Guest:'} ${guest?.registrantName}`}
                                  </Text>
                                  <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pt={0}>
                                  {activeEvent?.eventEndDate &&
                                  isEventPassed(activeEvent?.eventEndDate) ? (
                                    <MyEventsViewGuestList
                                      variant="multiple"
                                      isLoading={isLoading || isAcgUserProfileLoading}
                                      isError={isError}
                                      previousStep={previousStep}
                                      goToStep={goToStep}
                                      shouldShowGuestButton={shouldShowGuestButton}
                                      {...guest}
                                    />
                                  ) : (
                                    <MyEventsEditGuestListForm
                                      variant="multiple"
                                      isLoading={isLoading || isAcgUserProfileLoading}
                                      isError={isError}
                                      previousStep={previousStep}
                                      goToStep={goToStep}
                                      shouldShowGuestButton={shouldShowGuestButton}
                                      {...guest}
                                    />
                                  )}
                                </AccordionPanel>
                              </AccordionItem>
                            ))}
                          </Accordion>
                        )}
                    </>
                  )}
                  {React.cloneElement(step.component, {
                    nextStep,
                    previousStep,
                    goToStep,
                  })}
                </Box>
              </Center>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </BackButtonLayout>
  );
};

const formatDataForGuestList = (
  registeredEvent?: RegisteredEvent,
  acgUser?: AcgUserProfile,
  eventForRegistration?: EventForRegistration,
  guestsToRegister?: Partial<Guest>[],
): Partial<MyEventsEditGuestListFormProps>[] => {
  const registeredGuest = registeredEvent?.guests?.map(guest => ({
    ...guest,
    isGuest: true,
    title: guest?.title,
    registrationId: guest?.registrationId,
    registrantName: guest?.name,
    invoiceDetails: {
      invoiceKey: guest?.invoiceKey,
      productName: guest?.productName,
      price: guest?.price,
      quantity: guest?.quantity,
      type: guest?.type,
      registrationDate: guest?.registrationDate,
    },
  }));

  return [
    {
      ...registeredEvent,
      individualType: acgUser?.individualType,
      customQuestions: eventForRegistration?.registrantAndFees?.find(
        fee => fee.registrantType?.registrantTypeId === registeredEvent?.registrantTypeId,
      )?.customQuestions,
      title: registeredEvent?.jobTitle,
    },
  ].concat(
    // @ts-ignore
    registeredGuest,
    guestsToRegister?.map(g => ({
      ...g,
      isGuest: true,
      isUnregisteredGuest: true,
      name: g?.name,
      organizationName: g?.organizationName,
      isSuccess: true,
      invoiceDetails: {
        invoiceKey: g?.invoiceKey,
        productName: g?.productName,
        price: g?.price,
        quantity: g?.quantity,
        type: g?.type,
        registrationDate: g?.registrationDate,
      },
    })),
  );
};
