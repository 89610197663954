import Button from './button';

const defaultBaseStyle = {
  ...Button.baseStyle,
  borderRadius: 'full',
};

const defaultProps = {
  ...Button.defaultProps,
  colorScheme: 'gray',
};

export default {
  baseStyle: defaultBaseStyle,
  variants: Button.variants,
  sizes: Button.sizes,
  defaultProps,
};
