import React from 'react';
import { Heading, Text, Flex, Box, Button, Divider } from '@companydotcom/potion';
import { CircleEnvelopeIcon } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { useSource } from '@companydotcom/providers';

export const VerifyEmailPage = (props: any) => {
  const { isSending, isResent, productName, email, resendVerification } = props;
  const source = useSource();
  const { t } = useTranslation();
  return (
    <Flex
      className="verify-email-page"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Box mt={[12, 16]} mb={[4, 8]}>
        <CircleEnvelopeIcon />
      </Box>

      {(isSending && <Heading size="hs-2xl">{t('subpage.verifyEmail.isSending')}</Heading>) ||
        (isResent && <Heading size="hs-2xl">{t('subpage.verifyEmail.emailResent')}</Heading>) || (
          <Heading size="hs-2xl">
            {t('subpage.verifyEmail.welcomeMessage', {
              sourceName: source?.sourceId === 'company' ? 'grandio' : 'Company.com',
            })}
          </Heading>
        )}
      {productName && !isResent && (
        <>
          <Heading size="hs-md" mt={3}>
            {t('subpage.verifyEmail.thanksForPurchasing', { productName })}
          </Heading>
          <Divider borderColor="gray.300" my={9} mx="auto" width={333} />
        </>
      )}
      <Text textStyle="md" mt={12} fontWeight="bold">
        {t('subpage.verifyEmail.sentTo')}
        <Button
          variant="link"
          fontSize="md"
          fontWeight="bold"
          cursor="text"
          whiteSpace="normal"
          _hover={{
            textDecoration: 'none',
          }}
        >
          {email}
        </Button>
      </Text>
      <Text textStyle="md" mt={[6, 12]} maxW="md">
        {t('subpage.verifyEmail.confirmText')}
      </Text>
      <Text textStyle="sm" mt={12} mb={[13, 16]}>
        {t('subpage.verifyEmail.cantFindEmail')}

        <Button
          fontSize="sm"
          variant="link"
          isLoading={isSending}
          onClick={() => resendVerification()}
        >
          {t('subpage.verifyEmail.sendAgain')}
        </Button>
      </Text>
    </Flex>
  );
};
