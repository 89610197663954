import { createContext } from '@companydotcom/potion';
import { UserTile, Notification } from '@companydotcom/types';

export interface TilesContextProps {
  tiles: UserTile[];
  getUpdatedUserTileData: (notif?: Notification) => Promise<void>;
}

const [TilesProvider, useTiles] = createContext<TilesContextProps>({
  name: 'TilesContext',
  errorMessage:
    'useTiles: `tiles` is undefined. Seems you forgot to wrap the app in `<TilesProvider />` ',
});
export { TilesProvider, useTiles };
