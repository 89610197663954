const parts = ['tile', 'content', 'header', 'footer', 'body', 'lock', 'productIcon'];

const baseStyleTile = {
  backgroundColor: 'white',
  maxWidth: 360,
  height: 436,
  borderRadius: 'md',
  border: '1px solid',
  borderColor: 'gray.100',
  boxShadow: 'lg',
};

const baseStyleContent = {
  height: '100%',
};

const baseStyleHeader = {
  px: 4,
  pt: 10,
  pb: 1,
  fontSize: '2xl',
  fontWeight: 'semibold',
  textAlign: 'center',
};

const baseStyleBody = {
  px: 4,
  pt: 0,
  pb: 4,
  flex: 1,
};

const baseStyleFooter = {
  mx: 4,
};

const baseStyleLock = {
  width: '56px',
  height: '56px',
  borderTopColor: 'pricing',
  borderRightColor: 'transparent',
  borderTopWidth: '56px',
  borderRightWidth: '56px',
};

const baseStyleProductIcon = {
  width: 16,
  height: 16,
  marginX: 'auto',
  backgroundColor: 'white',
  borderRadius: '50%',
  zIndex: 5,
  left: 0,
  right: 0,
  top: -7,
};

const baseStyle = {
  tile: baseStyleTile,
  content: baseStyleContent,
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  lock: baseStyleLock,
  productIcon: baseStyleProductIcon,
};

export default {
  parts,
  baseStyle,
};
