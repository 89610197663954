import React, { useEffect } from 'react';
import { useEventData } from '@companydotcom/ui';
import { Text } from '@companydotcom/potion';
import type { TileComponentProps } from '../../types';
import { useUserProfileContext } from '../../../../providers';
import { TileSpinner } from '../tile-spinner';

interface SeatCountTextPayload {
  remainingEntitledSeats: number;
  totalEntitledSeats: number;
  accountId: string;
  productSlug: string;
  status: number;
}

export const SeatCountText = ({ eventData, text }: TileComponentProps) => {
  const { loading, data } = useEventData<SeatCountTextPayload>(eventData);
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();

  useEffect(() => {
    dispatchUserProfileState({ type: 'UPDATE_STATE', payload: { emailSeatCount: data } });
  }, [data, dispatchUserProfileState]);

  if (loading || userProfileState.emailSeatCount?.remainingEntitledSeats === undefined) {
    return <TileSpinner />;
  }

  return (
    <Text textStyle="md" marginBottom={2}>{`${text} ${
      userProfileState.emailSeatCount?.remainingEntitledSeats &&
      userProfileState.emailSeatCount?.remainingEntitledSeats <= 0
        ? '0'
        : userProfileState.emailSeatCount?.remainingEntitledSeats
    }`}</Text>
  );
};
