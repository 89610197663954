import {
  Account,
  UserTileState,
  User,
  ExtendedSubscriptionStatus,
  SeatCountSummary,
  Maybe,
  EmptyObject,
  SourceTile,
  ExtendedSubscriptionProduct,
} from '@companydotcom/types';
import { isEmpty } from 'lodash';
import { platformHelpers, companyHelpers } from '@companydotcom/helpers';
import { processAllRatePlanTiers } from '../../../utils/helpers/purchasing-helpers';
// import { publishTileEvent } from '../../../services/event/old-api/event-svc';
// import {
//   getUserLite,
//   getUserTileStates,
//   getSeatCount,
// } from '../../../services/user/old-api/user-svc';
// import { getEligibleChildProducts } from '../../../services/product/old-api/product-svc';

interface ParsedUserProduct {
  productId: string;
  status?: Maybe<string>;
  dateActivated?: Maybe<number>;
}

export const toggleChildUserAccess = async (
  userId: string,
  product: ExtendedSubscriptionProduct,
  account: Account,
  tileId: string,
  enabled: boolean,
  publishTileEvent: any,
  userLite?: User,
  userTileState?: UserTileState[],
) => {
  // const childUser = await getUserLite(userId);
  const childUserProducts: Array<ParsedUserProduct> = userLite?.products
    ? JSON.parse(userLite.products)
    : [];
  const childUserProduct = childUserProducts
    ? childUserProducts?.find?.(cp => cp?.productId === product?.productId)
    : undefined;
  const userHasVendorAccount =
    (childUserProduct && childUserProduct.status === 'active') ||
    childUserProduct?.status === 'disabled';
  // const userTilesList: Array<UserTileState> | undefined = await getUserTileStates(userId);

  const tileEvent = await publishTileEvent(
    platformHelpers.formatPublishTileEventInput(
      {
        eventType: userHasVendorAccount ? 'manageSeat' : 'activate',
        statePrevious: (
          (userTileState || []).find(uts => uts.tileId === tileId) || { stateCurrent: 'inactive' }
        ).stateCurrent,
        stateCurrent: enabled ? 'active' : 'disabled',
      },
      {
        user: userLite,
        account,
        tile: { tileId, productId: product.productId },
        product,
      },
      { enabled },
      'transition',
    ),
  );

  return JSON.parse(tileEvent?.data?.publishTileEvent)?.ResponseMetadata
    ? { success: true }
    : { success: false };
};

export const allocateChildUserSeat = async (
  user: User,
  tileId: string,
  account: Account,
  publishTileEvent: any,
) => {
  const tileEvent = await publishTileEvent(
    platformHelpers.formatPublishTileEventInput(
      {
        eventType: 'activate',
        statePrevious: 'disabled',
        stateCurrent: 'active',
        tileId,
      },
      {
        user,
        account,
        tile: {
          tileId,
          productId: process.env.REACT_APP_EMAIL_PRODUCTID,
        },
        product: tileId,
      },
      { enabled: true },
      'transition',
    ),
  );

  return tileEvent;
};

export const releaseChildUserSeat = async (
  userId: string,
  product: ExtendedSubscriptionProduct,
  account: Account,
  tileId: string,
  publishTileEvent: any,
  userLite?: User,
) => {
  // const childUser = await getUserLite(userId);
  const tileEvent = await publishTileEvent(
    platformHelpers.formatPublishTileEventInput(
      {
        eventType: 'releaseSeat',
        statePrevious: 'inactive', // This doesnt really matter
        stateCurrent: 'disabled',
        tileId,
      },
      {
        user: userLite,
        account,
        tile: { tileId, productId: product.productId },
        product: tileId, // This doesn't really matter
      },
      {},
      'transition',
    ),
  );

  return JSON.parse(tileEvent?.data?.publishTileEvent)?.ResponseMetadata
    ? { success: true }
    : { success: false };
};
interface ParsedProduct {
  productId: string;
  dateActivated: number;
  status: string;
}

interface ParsedProduct {
  productId: string;
  dateActivated: number;
  status: string;
}

/**
 * Email product should have both been purchased at some point (isActive === true)
 * AND the user set up a domain with rackspace (user.account.hasFQDN)
 * @param user - The user object
 */
export const isEmailProductActive = (user: User | User) => {
  let userProducts = [];
  if (user.products) {
    if (typeof user.products === 'string') {
      try {
        userProducts = JSON.parse(user.products);
      } catch (err) {
        userProducts = companyHelpers.parseUserProducts(user.products);
      }
    } else {
      userProducts = user.products;
    }
  }
  return !!(
    userProducts &&
    (userProducts as ParsedProduct[])?.some(
      product => product.productId === process.env.REACT_APP_EMAIL_PRODUCTID,
    ) &&
    user.account?.hasFQDN
  );
};

// /**
//  * Email product should have both been purchased at some point (isActive === true)
//  * AND the user set up a domain with rackspace (user.account.hasFQDN)
//  * @param childUserProducts - Parsed object of user products
//  */
// export const isChildEmailProductActivated = (
//   childUserProducts: { dateActivated: number; productId: string; status: string }[] | [],
// ) => {
//   return childUserProducts?.some?.(
//     i => i.productId === process.env.REACT_APP_EMAIL_PRODUCTID && i.status === 'active',
//   );
// };

export type ChildEmailProductStatus = 'disabled' | 'active' | 'pending' | 'inactive';

/**
 * If product status is INACTIVE, the seat has been DELETED.
 * If product status is DISABLED, the seat has been SUSPENDED.
 * If product status is PENDING, the child has been granted a seat but hasn't activated their tile yet.
 * if product is ACTIVE, the user has been granted a seat and has activated their tile.
 * @param childUserProducts - Parsed object of user products
 */
export const getChildEmailProductStatus = (
  childUserProducts: { dateActivated: number; productId: string; status: string }[] | [],
): ChildEmailProductStatus | undefined => {
  return childUserProducts?.find?.(i => i.productId === process.env.REACT_APP_EMAIL_PRODUCTID)
    ?.status as ChildEmailProductStatus;
};

/**
 * Gets eligible child products for a parent user, and if email is active, the available seat count
 * @param user
 * @param sourceId
 * @param isEmailActive
 * @returns
 */
export const getChildProductsAndSeatCount = async (
  getEligibleChildProducts: any,
  getSeatCount: any,
  userId: string,
  accountId: string,
  sourceId: string,
  isEmailActive: boolean,
  locale?: string,
) => {
  try {
    let results: {
      emailSeatCount: SeatCountSummary | EmptyObject;
      processedChildProducts: ExtendedSubscriptionStatus[];
    } = {
      emailSeatCount: {},
      processedChildProducts: [],
    };
    const eligibleChildProducts: ExtendedSubscriptionStatus[] = await getEligibleChildProducts({
      userId,
      sourceId,
      locale,
    }).unwrap();
    //* Need to process eligilbe products to add productname, rateplanids etc to tiers.
    const processedChildProducts = eligibleChildProducts
      .filter(p => !isEmpty(p.products))
      .map(item => ({
        ...item,
        products: item.products?.map(product => processAllRatePlanTiers(product)),
      }));

    results = {
      ...results,
      processedChildProducts,
    };

    //* See if the email product exits
    const emailProduct = processedChildProducts.find(
      product => product?.products?.[0]?.slug === 'email_rackspace',
    );

    if (isEmailActive && emailProduct?.products && accountId) {
      const emailSeatCount = await getSeatCount({
        accountId,
        productId: emailProduct?.products?.[0]?.productId as string,
      }).unwrap();

      results = {
        ...results,
        emailSeatCount,
      };
    }

    return results;
  } catch (error) {
    console.log('\u{1F6A8} Error getting user products or seat count.', error);
  }
};

/**
 * Determines whether a given source should allow the admin to add additional users
 * @param sourceTiles - An array of sourceTiles
 */
export const isAddNewUserAvailable = (sourceTiles: Array<Maybe<SourceTile>>) => {
  return sourceTiles.some(tile => tile?.roles?.includes('user'));
};

/**
 * Similar to getDefaultToggleState function, but for use with the ChildUserAccordion component
 * @param arr
 * @param childProducts
 * @param remainingSeatCount
 * @param isEmailProductActiveForParent
 * @param childProductStatus
 * @returns - An object with [product-name]: boolean
 */
export const getDefaultToggleStateForChild = (
  eligibleProducts?: ExtendedSubscriptionProduct[],
  childProducts?: { dateActivated: number; productId: string; status: string }[] | [],
  remainingSeatCount?: Maybe<number>,
  isEmailProductActiveForParent?: Maybe<boolean>,
  childProductStatus?: ChildEmailProductStatus,
) => {
  const mappedItems = eligibleProducts?.map(item => item?.slug);

  return (
    (mappedItems?.reduce((obj, item) => {
      const isOn = () => {
        if (
          item === 'email_rackspace' &&
          isEmailProductActiveForParent &&
          !isEmpty(childProducts) &&
          childProductStatus !== 'inactive'
        ) {
          if (
            childProductStatus === 'active' ||
            childProductStatus === 'pending' ||
            !isEmpty(childProducts)
          ) {
            return true;
          }
        }
        return false;
      };
      return {
        ...obj,
        [item]: isOn(),
      };
    }, {}) as { [key: string]: boolean }) || {}
  );
};

export const determineIfProductDisabled = (
  parentEmailActive?: Maybe<boolean>,
  chosenProductValue?: Maybe<boolean>,
  remainingSeats?: Maybe<number>,
  productStatus?: ChildEmailProductStatus,
) => {
  if (!parentEmailActive) return true;
  if (!chosenProductValue && remainingSeats === 0 && productStatus !== 'active') return true;
  if (!remainingSeats && productStatus !== 'active') return true;
  return false;
};
