import React, { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Trans, useTranslation } from 'react-i18next';
import {
  useTabStepper,
  Tabs,
  TabPanels,
  TabPanel,
  Heading,
  useMediaQuery,
  useBreakpointValue,
  Alert,
  AlertDescription,
  Center,
  AlertIcon,
  potion,
} from '@companydotcom/potion';
import { PageDivider, AppSpinner } from '@companydotcom/ui';
import { useGetGlobalUserQuery } from '../../../../../services/user/user-api';
import { ExistingUserPersonalInformation } from './acg-existing-user-registration-personal-information';
import { ExistingUserAddressInformation } from './acg-existing-user-registration-address-information';
import { hasCompletedPersonalInformation } from '../../utils';
import {
  useGetAcgQueryArgs,
  useGetAcgUserProfileV2Query,
} from '../../../../../services/acg/acg-api-v2';

const steps = [
  { slug: 'personal-information', component: <ExistingUserPersonalInformation /> },
  { slug: 'address-information', component: <ExistingUserAddressInformation /> },
];

export interface AcgExistingUserRegistrationProps {
  defaultStep?: 'personal-information' | 'address-information';
}

export const AcgExistingUserRegistration = ({
  defaultStep = 'personal-information',
}: AcgExistingUserRegistrationProps) => {
  const args = useGetAcgQueryArgs();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data: globalUser, isLoading: isUserLoading } = useGetGlobalUserQuery();
  const { data, isLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );
  const { tabIndex, handleTabsChange, nextStep, goToStep } = useTabStepper({
    steps,
    initialStep: defaultStep,
  });
  const headingSize = useBreakpointValue({ base: 'hs-lg', md: 'hs-xl' });
  const { t } = useTranslation();

  useEffect(() => {
    if (hasCompletedPersonalInformation(data)) {
      goToStep(1);
    }
  }, [data, goToStep]);

  const sharedProps = {
    nextStep,
  };

  if (isUserLoading || isLoading) {
    return (
      <Center flexGrow={1} width="full">
        <AppSpinner my={12} />
      </Center>
    );
  }

  return (
    <Tabs
      className="existing-acg-user-registration"
      index={tabIndex}
      onChange={handleTabsChange}
      isLazy
      variant="unstyled"
      layerStyle="formContainer"
      maxWidth="4xl"
      backgroundColor={['transparent', 'white']}
      boxShadow={['none', 'lg']}
      mt={[0, 12]}
    >
      <Center flexDirection="column" alignItems="center" maxW={608} mx="auto" mt={[0, 12]}>
        <Heading as="h1" size={headingSize} textAlign="center">
          {t('acg.existingUserFlow.heading')}
        </Heading>
        {isMobile && <PageDivider width={50} mt={2} />}
        <Alert status="warning" borderRadius="md" mt={6} mb={12}>
          <AlertIcon />
          <AlertDescription textStyle={['sm']}>
            <Trans i18nKey="acg.existingUserFlow.welcomeMessage">
              <potion.span fontWeight="bold">
                Welcome to the new My ACG. We’ve detected incomplete profile information that was
                not present in the previous system.
              </potion.span>
              The highlighted information is required by ACG during registration. This information
              may have been deleted after you previously registered, or is now required by ACG.
            </Trans>
          </AlertDescription>
        </Alert>
      </Center>
      <TabPanels mb={[0, 12]}>
        {steps.map(step => (
          <TabPanel key={step.slug}>{React.cloneElement(step.component, sharedProps)}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
