import React from 'react';
import {
  Box,
  Link,
  Text,
  Button,
  Heading,
  Center,
  useBreakpointValue,
  ButtonGroup,
} from '@companydotcom/potion';
import { Listing } from '@companydotcom/types';
import isInt from 'validator/lib/isInt';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PinCodeField } from '@companydotcom/ui';
import { NavLink } from 'react-router-dom';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  useCompleteVerificationMutation,
  useStartVerificationMutation,
} from '../../../../services/listings/listing-api';
import { useAwaitableFacade } from '../../../../hooks';

const verificationTextMap = ({ selectedVerification = '' }) => {
  if (selectedVerification === 'EMAIL') {
    return 'email';
  }
  if (selectedVerification === 'SMS') {
    return 'text message';
  }

  return 'phone';
};

const CODE_LENGTH = 5;

const schema = yup.object().shape({
  code: yup
    .string()
    .min(CODE_LENGTH, 'gmbProfileFlow.verificationCode.inputs.code.min')
    .test('code', 'gmbProfileFlow.verificationCode.inputs.code.test', val =>
      typeof val === 'string' ? isInt(val) : undefined,
    )
    .required('gmbProfileFlow.verificationCode.inputs.code.required'),
});

export interface VerificationCodeProps {
  previousStep?: () => void;
  goToStep?: (arg: string | number) => void;
  verificationOption?: any;
  listingId?: string;
  // listingSvc?: any;
}

export const VerificationCode: React.FC<VerificationCodeProps> = props => {
  const {
    verificationOption,
    listingId,
    // listingSvc,
    previousStep,
    goToStep,
  } = props;
  const { data: globalUser } = useGetGlobalUserQuery();
  const [startVerification] = useStartVerificationMutation();
  const [completeVerification] = useCompleteVerificationMutation();
  const { t } = useTranslation();
  const snsInterface = useAwaitableFacade(globalUser, 'listing');
  const defaultValues = {
    code: '',
  };
  const {
    handleSubmit,
    setError,
    control,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const pinInputSize = useBreakpointValue({ base: 'md', sm: 'lg' });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      if (globalUser && listingId) {
        await completeVerification({ snsInterface, listingId, verificationCode: values.code })
          .unwrap()
          .then(({ success, error }: { success: boolean; error: string; data: Listing }) => {
            if (success) {
              goToStep?.(4);
            } else {
              console.error(error);
              if (error === 'Invalid PIN.') {
                setError('code', { message: error });
              }
            }
          });
      }
      // listingSvc
      //   .completeVerification(listingId as string, values.code)
      //   .then(({ success, error }: { success: true; error: string }) => {
      //     if (success) {
      //       goToStep?.(4);
      //     } else {
      //       console.error(error);
      //       if (error === 'Invalid PIN.') {
      //         setError('code', { message: error });
      //       }
      //     }
      //   });
    } catch (err) {
      console.log('Error!', err);
    }
  };

  return (
    <Center
      className="fttf-verification-code"
      flexDirection="column"
      pb={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box maxW="xl">
        <Heading as="h1" size="hs-xl">
          Enter Verification Code
        </Heading>
        <Text textStyle="md" mt={4}>
          {`Please enter the 5 digit verification code you received by ${verificationTextMap(
            verificationOption,
          )}.`}
        </Text>
      </Box>
      <Center marginY={12}>
        <PinCodeField
          autoFocus
          length={CODE_LENGTH}
          control={control}
          name="code"
          size={pinInputSize}
          errors={errors}
        />
      </Center>

      <ButtonGroup size="lg" spacing={6}>
        <Button
          variant="outline"
          onClick={() => {
            previousStep?.();
          }}
        >
          {t('common.buttons.back')}
        </Button>
        <Button isLoading={isSubmitting} isDisabled={!isValid} onClick={handleSubmit(onSubmit)}>
          {t('gmbProfileFlow.verificationCode.enterCode')}
        </Button>
      </ButtonGroup>

      <Box mt={14} sx={{ textAlign: 'center' }}>
        <Text textStyle="sm">
          {verificationOption === 'CALL_ME'
            ? t('gmbProfileFlow.verificationCode.noCall')
            : t('gmbProfileFlow.verificationCode.noVerification', {
                selectedVerification: verificationTextMap(verificationOption),
              })}{' '}
          <Button
            variant="link"
            fontSize="sm"
            onClick={() => {
              // listingSvc.startVerification(listingId, selectedVerification);
              if (globalUser && listingId) {
                startVerification({
                  snsInterface,
                  listingId,
                  verificationMethod: verificationOption,
                });
              }
            }}
          >
            {verificationOption === 'CALL_ME'
              ? t('gmbProfileFlow.verificationCode.callAgain')
              : t('gmbProfileFlow.verificationCode.resendVerification', {
                  selectedVerification: verificationTextMap(verificationOption),
                })}
          </Button>
        </Text>

        <Text textStyle="sm" mt={15}>
          Need Help?{' '}
          <Link as={NavLink} to="/help">
            Contact Support
          </Link>
        </Text>
      </Box>
    </Center>
  );
};
