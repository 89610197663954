import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppSpinner } from '@companydotcom/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTabStepper, Tabs, TabPanels, TabPanel, Box, useBoolean } from '@companydotcom/potion';
import { PlanSelect } from './become-a-member-plan-select';
import { ChapterSelect } from './become-a-member-chapter-select';
import type { RegistrationSharedProps } from '../../steps/acg-new-user-registration';

import {
  useGetMembershipsV2Query,
  useGetAcgQueryArgs,
  useGetAllChaptersAndPackagesV2Query,
} from '../../../../../../services/acg/acg-api-v2';
import { LocationStateProps } from '../../../../acg-invoices';
import { PackageSelect } from './become-a-member-package-select';
import { BackButtonLayout } from '../../../../../../components/layouts';
import { GetAllAcgChaptersAndPackagesResponse } from '@companydotcom/types';

const becomeAMemberSteps = [
  {
    slug: 'package-select',
    component: <PackageSelect />,
  },
  {
    slug: 'chapter-select',
    component: <ChapterSelect />,
  },
  {
    slug: 'plan-select',
    component: <PlanSelect />,
  },
];

export const becomeAMemberSchema = yup.object().shape({
  acgPackage: yup.string(),
  acgChapter: yup.string(),
  chapterSearch: yup.string().notRequired(),
  acgPlan: yup.string().notRequired(),
});

export interface BecomeMemberSharedProps {
  goToStep?: (arg0: string | number) => void;
  /** goToRegStep is the goToStep function from the acg-registration flow. necessary to rename due to multiple instantiations of this function */
  goToRegStep?: (arg0: string | number) => void;
  packagesAndChapters?: GetAllAcgChaptersAndPackagesResponse[];
  isSubpage?: boolean | undefined;
  setIsBecomingMember?: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
  chapterKeyParam?: string;
  packageKeyParam?: string;
  isLoading?: boolean;
  register?: any;
  control?: any;
  errors?: any;
  isValid?: boolean;
  setError?: any;
  handleSubmit?: any;
  setIsValid?: any;
  reset?: (values?: any) => void;
  getValues?: () => void;
  defaultValues?: {
    acgPackage: string;
    acgChapter: string;
    chapterSearch: string;
    acgPlan: string;
  };
}

export const BecomeAMember = (props: RegistrationSharedProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [isValid, setIsValid] = useBoolean();
  const state = useLocation().state as LocationStateProps;
  const { goToStep: goToRegStep, isSubpage, setIsBecomingMember } = props;

  const args = useGetAcgQueryArgs();
  const { tabIndex, handleTabsChange, goToStep, previousStep } = useTabStepper({
    steps: becomeAMemberSteps,
  });
  const { data: packagesAndChapters, isLoading } = useGetAllChaptersAndPackagesV2Query({});
  const { isLoading: isMembershipsLoading } = useGetMembershipsV2Query({ ...args });

  const { mbr_chp_cst_key: chapterKeyParam, mbr_pak_prd_key: packageKeyParam } = useMemo(
    () => Object.fromEntries([...searchParams]),
    [searchParams],
  );
  const sortPackages = useCallback((packages?: GetAllAcgChaptersAndPackagesResponse[]) => {
    const sorted = [];
    sorted.push(packages?.[2], packages?.[1], packages?.[3], packages?.[0]);
    return sorted;
  }, []);

  const defaultValues = {
    acgPackage: '',
    acgChapter: '',
    chapterSearch: '',
    acgPlan: '',
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onChange', resolver: yupResolver(becomeAMemberSchema) });

  const sharedProps = {
    goToStep,
    packagesAndChapters: sortPackages(packagesAndChapters),
    isSubpage,
    goToRegStep,
    setIsBecomingMember,
    chapterKeyParam,
    packageKeyParam,
    isLoading,
    register,
    handleSubmit,
    control,
    reset,
    errors,
    isValid,
    setIsValid,
    setError,
    getValues,
    defaultValues,
  };

  if (state?.['defaultStep']) {
    goToStep?.(state['defaultStep']);
    delete state['defaultStep'];
  }

  if ((isLoading || isMembershipsLoading) && isSubpage) {
    return (
      <AppSpinner
        description={t('acg.firstTimeFlow.loadingState')}
        containerStyles={{ height: '700px' }}
      />
    );
  }

  return (
    <BackButtonLayout
      onBackClick={() => {
        if (tabIndex === 0) {
          navigate('/');
        } else if (tabIndex === 1) {
          previousStep();
          reset(defaultValues);
          setIsValid?.off();
        } else {
          previousStep();
        }
      }}
      isDisplayed={!isLoading && !isMembershipsLoading && !!isSubpage}
    >
      <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
        <TabPanels>
          {becomeAMemberSteps.map(step => (
            <TabPanel key={step.slug}>
              {(isLoading || isMembershipsLoading) && !isSubpage ? (
                <Box my={14}>
                  <AppSpinner description="Retrieving ACG Packages..." />
                </Box>
              ) : (
                React.cloneElement(step.component, { ...sharedProps })
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </BackButtonLayout>
  );
};
