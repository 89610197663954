import React, { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Text,
  useMultiStyleConfig,
  StylesProvider,
  Table,
  Thead,
  Tbody,
  Link,
} from '@companydotcom/potion';
import { NavLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import {
  NoInvoices,
  Headers,
  LoadingInvoiceRow,
  InvoiceRow,
  InvoiceType,
} from './invoice-components';
import { useGetGlobalUserQuery } from '../../../services/user/user-api';
import { PageHeading } from '../../../components/elements';
import {
  useLazyGetInvoicePdfByInvoiceIdQuery,
  useLazyGetInvoiceListByCustomerQuery,
} from '../../../services/payment/payment-api';
import { AppSpinner } from '@companydotcom/ui';
import dayjs from 'dayjs';

export const InvoiceStep: React.FC<any> = () => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const styles = useMultiStyleConfig('InvoiceStep', {});
  const [invoices, setInvoices] = useState<InvoiceType[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const { t } = useTranslation();
  const [getInvoiceListByCustomer] = useLazyGetInvoiceListByCustomerQuery();
  const [getInvoicePdf] = useLazyGetInvoicePdfByInvoiceIdQuery();
  let url: string | null | undefined;

  useEffect(() => {
    let isMounted = true;
    const fetchInvoices = async () => {
      if (globalUser?.account?.chargebeeCustomerId && isMounted) {
        const fetchedInvoiceDataResp = await getInvoiceListByCustomer({
          customerId: globalUser?.account?.chargebeeCustomerId,
        }).unwrap();

        const fetchedInvoiceResp = JSON.parse(fetchedInvoiceDataResp);
        setInvoices(fetchedInvoiceResp.invoices);
        setLoading(false);
      }
    };
    if (
      !invoices &&
      globalUser &&
      globalUser.account?.chargebeeCustomerId &&
      !loading &&
      isMounted
    ) {
      setLoading(true);
      fetchInvoices();
    }

    return () => {
      isMounted = false;
    };
  }, [invoices, setInvoices, globalUser, loading, getInvoiceListByCustomer]);

  const nextInvoiceDate: any = (invoices: InvoiceType[]) => {
    const timestampsList: number[] = [];
    if (invoices && invoices.length > 0) {
      invoices.map(invoice => {
        timestampsList.push(invoice.date);
      });
      const timestamp = timestampsList.reduce(
        (accumulator, currentValue) => (accumulator < currentValue ? accumulator : currentValue),
        timestampsList[0],
      );
      return dayjs.unix(timestamp).add(1, 'month').format('MM/DD/YYYY');
    }
    return undefined;
  };

  const openPdf = async (downloadURL: string) => {
    return fetch(downloadURL)
      .then(response => {
        return response.blob();
      })
      .then(
        pdfData => {
          url = window.URL.createObjectURL(pdfData);
          const popupContainer = document.createElement('div');
          popupContainer.style.cssText =
            'position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9999; background-color: rgba(0, 0, 0, 0.5);';

          const popupContent = document.createElement('div');
          popupContent.style.cssText =
            'display: flex; flex-direction: column; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 80%; height: 80%; background-color: white;';

          const closeBtn = document.createElement('button');
          closeBtn.ariaLabel = 'Close';
          closeBtn.className = 'potion-modal__close-btn css-dv37b3';
          // closeBtn.textContent = 'X';
          closeBtn.innerHTML =
            '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"><i class="fa fa-close"></i>';
          // transition-property: var(--transition-property-common);
          closeBtn.style.cssText =
            'position: relative; outline: transparent solid 2px; outline-offset: 2px; display: flex; -webkit-box-align: center; align-items: center; -webkit-box-pack: center; justify-content: center; flex-shrink: 0; border-radius: 0.375rem; transition-duration: 200ms; width: 32px; height: 32px; font-size: 12px; position: relative; right: -84rem; transition-property: var(--transition-property-common); transition-duration: var(--transition-duration-normal);';

          closeBtn.addEventListener('click', () => {
            document.body.removeChild(popupContainer);
          });

          const iframe = document.createElement('iframe');
          iframe.src = `${url}#toolbar=0`;
          iframe.style.cssText = 'width: 100%; height: calc(100% - 40px); border: none;';

          popupContent.appendChild(closeBtn);
          popupContent.appendChild(iframe);
          popupContainer.appendChild(popupContent);
          document.body.appendChild(popupContainer);
          setPdfLoading(false);
        },
        err => {
          console.log(err);
        },
      );
  };

  const displayPdf = async (invoiceId: string) => {
    setPdfLoading(true);
    // fetch invoice pdf from chargebee
    const { data } = await getInvoicePdf({ invoiceId });
    await openPdf(JSON.parse(data).download_url);
  };

  const downloadPdf = async (invoice: InvoiceType) => {
    setPdfLoading(true);
    const { data } = await getInvoicePdf({ invoiceId: invoice.id });
    fetch(JSON.parse(data).download_url)
      .then(response => {
        const downloadBlob = response.blob();
        return downloadBlob;
      })
      .then(pdfData => {
        const url = window.URL.createObjectURL(pdfData);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'invoice.pdf';
        link.click();
        window.URL.revokeObjectURL(url);
        setPdfLoading(false);
      });
  };

  return (
    <StylesProvider value={styles}>
      <Box className="potion-invoiceStep__container" sx={styles.container}>
        <PageHeading heading={t('miop.invoices.view.header')} mb={6} />
        <Flex flexDirection="column" mt={[4, null, 6]} mb={0} width="100%">
          {globalUser &&
          !globalUser.account?.chargebeeCustomerId &&
          (!invoices || invoices?.length < 1) ? (
            <NoInvoices />
          ) : (
            <>
              <Box>
                <Text textStyle="xl" fontWeight="medium">
                  {t('miop.invoices.view.tableTitle')}
                </Text>
                <Text mt={2} textStyle="md">
                  {t('miop.invoices.view.tableDateLabel')} {nextInvoiceDate(invoices)}
                </Text>
              </Box>
              <Table mt={[8, null, 12]} variant="simple">
                <Thead fontSize="lg">
                  <Headers />
                </Thead>
                <Tbody>
                  {invoices &&
                    invoices.map((invoice, i) => (
                      <InvoiceRow
                        key={i}
                        invoice={invoice}
                        displayPdf={displayPdf}
                        downloadPdf={downloadPdf}
                        // downloading={}
                      />
                    ))}
                  {loading && <LoadingInvoiceRow />}
                </Tbody>
              </Table>
              <Box mt={14}>
                <Trans i18nKey="miop.invoices.view.contactSupport">
                  <Text variant="caption" textAlign="center">
                    To view older invoices, please
                    <Link as={NavLink} to="/help">
                      contact support
                    </Link>
                    .
                  </Text>
                </Trans>
              </Box>
            </>
          )}
        </Flex>
      </Box>
      {pdfLoading && (
        <div style={{ zIndex: '9999', position: 'absolute', top: '250px', left: '500px' }}>
          <AppSpinner containerStyles={{ height: '400px' }} />
        </div>
      )}
    </StylesProvider>
  );
};
