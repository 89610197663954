import React, { useRef, useState } from 'react';
import '../facecake-checkout/chargeBee.css';
import { useTranslation } from 'react-i18next';
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
} from '@chargebee/chargebee-js-react-wrapper';
import { useLocalCartStorage } from '../../../../../dashboard/src/hooks/use-local-cart-storage';
import { Button, VStack, Text } from '@companydotcom/potion';
import { SubscriptionTier, User } from '@companydotcom/types';
import { AppSpinner } from '@companydotcom/ui';
// import { PaymentError } from '../../../pages/subpages/payment-error/payment-error';
declare const window: any;

interface CBFormProps {
  processOrderSummary?: any;
  globalUser?: User;
  selectedProducts?: any;
  selectedTiers?: SubscriptionTier[] | [];
  goToStep?: (arg: string | number) => void;
  quantity?: number;
  setOrderConfirmationData: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChargebeePaymentFormfc: React.FC<CBFormProps> = props => {
  const { processOrderSummary, goToStep, globalUser, setOrderConfirmationData } = props;
  // Initialize chargebee.js
  window.Chargebee.init({
    site: process.env.REACT_APP_CHARGEBEE_SITE,
    publishableKey: process.env.REACT_APP_PUBLISHABLE_KEY,
  });
  const { getLocalCart } = useLocalCartStorage();
  const selectedPlan = getLocalCart()?.selectedPlan;
  const { t } = useTranslation();
  const options = {
    classes: {
      focus: 'focus',
      invalid: 'invalid',
      empty: 'empty',
      complete: 'complete',
    },
    style: {
      base: {
        color: '#333',
        fontWeight: '500',
        fontFamily: 'Roboto, Segoe UI, Helvetica Neue, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':focus': {
          color: '#424770',
        },
        '::placeholder': {
          color: 'transparent',
        },
        ':focus::placeholder': {
          color: '#7b808c',
        },
        ':-webkit-autofill': {
          webkitTextColor: '#333',
        },
      },
      invalid: {
        color: '#e41029',
        ':focus': {
          color: '#e44d5f',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    },
    locale: 'en',
    placeholder: {
      expiry: 'MM / YY',
      cvv: 'CVV',
    },
    fonts: ['https://fonts.googleapis.com/css?family=Roboto:300,500,600'],
  };

  const cardRef = useRef<any>();
  const [isLoading, setIsLoading] = useState(false);
  const createOrders = () => {
    const orders: any[] = [];
    orders.push({
      productId: '238a1ecf-06fe-4ea9-8502-05bfbeeba612',
      ratePlanId: selectedPlan?.zuoraProductRatePlanId,
      dxpPlanId: selectedPlan?.id,
    });
    return orders;
  };

  const createCustomer = async (globalUser?: any, orders?: any, cbToken?: any) => {
    const processOrderResponse = await processOrderSummary({
      userId: globalUser?.userId as string,
      partnerBillingEnabled: globalUser.account?.allowPartnerBilling,
      //@ts-ignore
      orderSummary: orders,
      cbToken: cbToken,
    }).unwrap();
    return processOrderResponse;
  };

  const onSubmit = () => {
    const orders = createOrders();
    // Call the tokenize method through the ref
    //@ts-ignore
    cardRef.current
      .tokenize()
      .then(async (data: { token: any }) => {
        // Turning on the loading state
        setIsLoading(true);
        try {
          await createCustomer(globalUser, orders, data.token).then(async resp => {
            setIsLoading(true);
            if (resp) {
              setOrderConfirmationData({
                orderConfirmation: resp[Object.keys(resp)[0]],
              });
              goToStep?.('checkout-order-confirmation');
            } else {
              console.log('Payment failed!');
              throw 'Payment failed!';
            }
          });
        } catch (error) {
          console.log('error try catch...' + error);
        }
      })
      .catch((error: any) => {
        console.log('error then catch...' + error);
      });
  };

  // Here in below states true means disabled
  const [isCardNoErrHidden, setIsCardNoErrHidden] = useState(true);
  const [isCardErrCVVHidden, setIsCardErrCVVHidden] = useState(true);
  const [isCardErrExpHidden, setIsCardErrExpHidden] = useState(true);
  const [isCardNameErrHidden, setIsCardNameErrHidden] = useState(true);
  const [checkoutDisableState, setCheckoutDisableState] = useState(true);
  const isCardFilled = { name: false, no: false, cvv: false, exp: false };
  const [cardFilled, setCardFilled] = useState(isCardFilled);

  const onCardChange = (el: any) => {
    const { field, complete, error } = el;
    switch (field) {
      case 'number':
        if (complete === false || error) {
          setIsCardNoErrHidden(false);
          setCheckoutDisableState(!complete);
          isCardFilled.no = false;
          setCardFilled(isCardFilled);
        } else {
          setIsCardNoErrHidden(true);
          isCardFilled.no = true;
          setCardFilled(isCardFilled);
        }
        break;
      case 'cvv':
        if (complete === false || error) {
          setIsCardErrCVVHidden(false);
          setCheckoutDisableState(!complete);
          isCardFilled.cvv = false;
          setCardFilled(isCardFilled);
        } else {
          setIsCardErrCVVHidden(true);
          isCardFilled.cvv = true;
          setCardFilled(isCardFilled);
        }
        break;
      case 'expiry':
        if (complete === false || error) {
          setIsCardErrExpHidden(false);
          setCheckoutDisableState(!complete);
          isCardFilled.exp = false;
          setCardFilled(isCardFilled);
        } else {
          setIsCardErrExpHidden(true);
          isCardFilled.exp = true;
          setCardFilled(isCardFilled);
        }
        break;
      default:
        break;
    }

    const { no, cvv, exp } = cardFilled;
    setCheckoutDisableState(!(no && cvv && exp));
  };
  const onNameChange = (val: any) => {
    const { no, cvv, exp } = cardFilled;
    const isNameValid = val.trim().length > 1;
    setIsCardNameErrHidden(prevState => {
      if (isNameValid) {
        return true; // Hide the error message
      } else {
        return !prevState; // Toggle the error message visibility
      }
    });
    setCheckoutDisableState(!isNameValid || !(no && cvv && exp));
  };

  return isLoading ? (
    <VStack className="ex1 container">
      <Text as="div" className="ex1-wrap">
        <AppSpinner marginTop="100px" />
      </Text>
    </VStack>
  ) : (
    <VStack className="ex1 container">
      <Text as="div" className="ex1-wrap">
        <Text as="div" className="ex1-fieldset">
          <Text as="div" className="ex1-field">
            <label className="ex1-label">Cardholder Name</label>
            <input
              name="cardDetails.cardHolderName"
              className="ex1-input"
              type="text"
              onChange={(event: any) => {
                onNameChange(event.target.value);
              }}
            />

            <Text as="div" className="ex1-error" hidden={isCardNameErrHidden}>
              {t('acg.checkout.orderStep.inputs.cardholderName.required')}
            </Text>
          </Text>
          <CardComponent
            ref={cardRef}
            className="fieldset field"
            styles={options.style}
            classes={options.classes}
            locale={options.locale}
            placeholder={options.placeholder}
            fonts={options.fonts}
          >
            <Text as="div" className="ex1-field">
              <label className="ex1-label">Cardholder Number</label>
              <CardNumber className="ex1-input" onChange={onCardChange} />
              <Text as="div" className="ex1-error" hidden={isCardNoErrHidden}>
                {t('acg.checkout.orderStep.inputs.cardholderNumber.required')}
              </Text>
            </Text>
            <Text as="div" className="ex1-fields">
              <Text as="div" className="ex1-field">
                <label className="ex1-label">Expiration Date</label>
                <CardExpiry className="ex1-input" onChange={onCardChange} />
                <Text className="ex1-error" hidden={isCardErrExpHidden}>
                  {' '}
                  Please enter a valid expiry date
                </Text>
              </Text>
              <Text as="div" className="ex1-field">
                <label className="ex1-label">Security Code</label>
                <CardCVV className="ex1-input" onChange={onCardChange} />
                <Text
                  as="div"
                  className="ex1-error"
                  style={{ textAlign: 'left' }}
                  hidden={isCardErrCVVHidden}
                >
                  {t('acg.checkout.orderStep.inputs.securityCode.required')}
                </Text>
              </Text>
            </Text>
          </CardComponent>
        </Text>
        <Button
          marginTop="375px"
          width="298px"
          marginLeft="613px"
          position="absolute"
          size="lg"
          onClick={onSubmit}
          isDisabled={checkoutDisableState}
        >
          Place Order
        </Button>
      </Text>
    </VStack>
  );
};

export default ChargebeePaymentFormfc;
