import React from 'react';
import {
  useBreakpointValue,
  Text,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableProps,
} from '@companydotcom/potion';

export interface SubTotalListProps extends TableProps {
  subtotal?: string | number;
  total?: string | number;
  demographicQuestionsExist?: boolean;
  showAddGuestButton?: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  addToCartSubmitHandler: (args: any) => void;
  addGuestSubmitHandler: (args: any) => void;
}

export const SubTotalList = (props: SubTotalListProps) => {
  const {
    subtotal,
    total,
    isSubmitting,
    isValid,
    demographicQuestionsExist,
    showAddGuestButton,
    addGuestSubmitHandler,
    addToCartSubmitHandler,
    ...rest
  } = props;
  const isMobile = useBreakpointValue({ base: true, sm: false });
  return (
    <Table {...rest}>
      <Tbody>
        {subtotal ? (
          <Tr>
            <Td pl={0} py={8}>
              <Text>Subtotal</Text>
            </Td>
            <Td px={0} py={8} isNumeric>
              <Text textStyle="lg" fontWeight="bold">
                {`$${subtotal}`}
              </Text>
            </Td>
          </Tr>
        ) : null}
        <Tr borderBottomWidth={0}>
          <Td pl={0} py={8} borderBottomWidth={0}>
            <Text textStyle="2xl" fontWeight="bold">
              TOTAL
            </Text>
          </Td>
          <Td px={0} py={8} isNumeric borderBottomWidth={0}>
            <Text textStyle="2xl" fontWeight="bold">
              {`$${total ? total : `0.00`}`}
            </Text>
          </Td>
        </Tr>
        <Tr>
          <Td
            textAlign="center"
            colSpan={2}
            px={0}
            pb={0}
            pt={4}
            borderBottomWidth={0}
            justifyContent="center"
          >
            <Button
              size="lg"
              isFullWidth={!isMobile}
              type="submit"
              isLoading={isSubmitting}
              onClick={addToCartSubmitHandler}
              isDisabled={!isValid}
            >
              {demographicQuestionsExist ? 'Continue' : 'Add To Cart'}
            </Button>
            {showAddGuestButton && !demographicQuestionsExist ? (
              <Button
                onClick={addGuestSubmitHandler}
                size="sm"
                mt={4}
                variant="ghost"
                isLoading={isSubmitting}
                isDisabled={!isValid}
              >
                Add to Cart and Add Guest
              </Button>
            ) : (
              ''
            )}
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
