import _get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';
import { companyHelpers, companyConstants, platformHelpers } from '@companydotcom/helpers';
import { UserTile } from '@companydotcom/types';
import { OpenPaywallEvent } from '../../paywall/types';
// import { getProductSSO } from '../../../services/user/old-api/user-svc';
// import { publishTileEvent } from '../../../services/event/old-api/event-svc';
import { subscribeToNotifications } from '../../../services/notification/notification-svc';

const { REACT_APP_IMG_URL } = process.env;

const dataCollectorForms = [
  {
    form: 'user',
    dataElements: ['firstName', 'lastName', 'phone'],
  },
  {
    form: 'business',
    dataElements: [
      'account.businessPrimary.name',
      'account.businessPrimary.address.addressLine1',
      'account.businessPrimary.address.city',
      'account.businessPrimary.address.state',
      'account.businessPrimary.address.zip',
      'account.businessPrimary.numberOfEmployees',
    ],
  },
  {
    form: 'email',
    dataElements: ['mailbox'],
  },
  {
    form: 'domain',
    dataElements: ['account.hasFQDN'],
  },
  {
    form: 'calendly',
    dataElements: ['appointments'],
  },
  {
    form: 'businessFunding',
    dataElements: [
      'account.loanPrimary.creditEstimate',
      'account.loanPrimary.purpose',
      'account.loanPrimary.annualRevenue',
      'account.loanPrimary.amountRequired',
    ],
  },
  {
    form: 'emailEligibility',
    dataElements: [
      'emailProvider',
      'emailDomain',
      'noDomainChecked',
      'ownsEmailDomain',
      'adminEmail',
      'noDomainChecked',
      'termsAccepted',
      'mailboxCount',
    ],
  },
  {
    form: 'check-verification',
    dataElements: [],
  },
  {
    form: 'gmb-first-time-flow',
    dataElements: [],
  },
  {
    form: 'admin-first-time-flow',
    dataElements: [],
  },
  {
    form: 'child-first-time-flow',
    dataElements: [],
  },
];

const handleNonDataAction = (
  // subscribeToNotifications: any,
  // updateNotificationHandled: any,
  action: any,
  { source, userGraph, currentEvent, tiles, formData }: any,
  emitter: any,
  getProductSSO: any,
  publishTileEvent: any,
) => {
  const { product } = currentEvent;
  const slug = product && product.slug;
  // NO MORE DATA REQUIRED - NEXT ACTION
  // Create and object for parameters needed to make a productSSO call
  if (action) {
    switch (action.type) {
      case 'triggerForm':
        return platformHelpers.getComponentParamValue(action.actionParams, 'formName');
      case 'publishDcComplete': {
        emitter.emit(companyConstants.platformEvents.dataCollectorComplete, currentEvent);
        break;
      }
      case 'publishDcDismissed':
        emitter.emit(companyConstants.platformEvents.dataCollectorDismissed, currentEvent);
        break;
      case 'checkVerification':
        return 'check-verification';
      case companyConstants.tileActions.transitionState:
        emitter.emit(companyConstants.platformEvents.tileNextAction, {
          ...currentEvent,
          stateCurrent: action.actionParams[0].value,
        });
        break;
      case companyConstants.tileActions.transitionStateV2: {
        const stateCurrent = platformHelpers.getComponentParamValue(
          action.actionParams,
          'transitionTo',
        );
        const eventType = platformHelpers.getComponentParamValue(action.actionParams, 'eventType');

        emitter.emit(companyConstants.tileActions.transitionStateV2, {
          ...currentEvent,
          stateCurrent,
          eventType,
          payload: formData,
        });
        break;
      }
      case companyConstants.tileActions.navigate:
        if (action.actionParams.length && action.actionParams[0]) {
          const href = action.actionParams.find((p: any) => p.key === 'href');
          const target = action.actionParams.find((p: any) => p.key === 'target');
          if (target) {
            try {
              let newTab: any | undefined;
              if (currentEvent.productId === 'byop') {
                newTab = window.open(`${REACT_APP_IMG_URL}/website/html/loading.html`, '_blank');
                newTab.location.href = companyHelpers.getValidHTTPUrl(action.actionParams[0].value);
              } else {
                window.open(action.actionParams[0].value, target ? target.val : '_blank');
              }
            } catch (err) {
              console.error(err);
            }
          } else if (!href || !href.value) {
            console.log('navigate action href or value not provided');
          } else {
            const isAccountLink = href.value.toLowerCase().indexOf('/account') > -1;
            let host = companyConstants.hosts.account;

            if (host && host.indexOf('/') > -1) {
              host = host?.slice(0, host.indexOf('/'));
            }
            if (isAccountLink) {
              const redirectTo = companyHelpers.routeBuilder(source, host);
              const hrefVal = href.value.split('.com').pop();
              window.open(`${redirectTo}${hrefVal}`, '_blank');
            } else {
              window.open(action.actionParams[0].value, href ? href.val : '_blank');
            }
          }
        }
        break;
      case companyConstants.tileActions.navigateSSO: {
        let newTab: any | undefined;

        const openLoadingWindow = () =>
          window.open(`${REACT_APP_IMG_URL}/website/html/loading.html`, '_blank');

        if (currentEvent.productStatus === 'inactive') {
          return 'error';
        }
        // might be issues here with the promise being executed inside of useReducer
        if (
          !platformHelpers.getComponentParamValue(
            action.actionParams,
            companyConstants.actionParams.emailVerificationRequired,
          )
        ) {
          newTab = openLoadingWindow();
          getProductSSO({
            input: {
              userId: userGraph.userId,
              actionType: action.type,
              productSlug: slug,
            },
          })
            .unwrap()
            .then((res: any) => {
              if (res) {
                newTab.location.href = res;
              } else {
                return 'error';
              }
            })
            .catch((err: any) => {
              console.log(err);
              return 'error';
            });
        }
        break;
      }
      case companyConstants.tileActions.skynetSSO: {
        let newTab: any | undefined;
        if (currentEvent.productStatus === 'inactive') {
          return 'error';
        }
        // might be issues here with the promise being executed inside of useReducer
        const eventId = uuidv4();
        const eventToPublish = platformHelpers.formatPublishTileEventInput(
          {
            eventType: typeof action.eventType !== 'undefined' ? action.eventType : 'sso',
            componentTypes: [],
            topic: 'fetch',
          },
          {
            user: userGraph,
            account: userGraph.account,
            tile: {
              ...tiles.find((tile: UserTile) => tile.tileId === currentEvent.tileId),
            },
          },
          currentEvent.data,
          'fetch',
          eventId,
        );

        // eslint-disable-next-line prefer-const
        newTab = window.open(`${REACT_APP_IMG_URL}/website/html/loading.html`, '_blank');
        const subscribeToSsoEvent: any = subscribeToNotifications(
          userGraph.userId,
          (notif: any) => {
            if (notif.body.triggerEventId === eventId) {
              const ssoUrl = action.responseField
                ? _get(notif, action.responseField)
                : _get(notif, 'body.payload.res.ssoUrl');
              if (ssoUrl) {
                newTab.location = ssoUrl;
              } else {
                return 'error';
              }
            }
          },
        );

        /** RTK attemp at subscriptions, wasn't able to test */
        // const subscribeToSsoEvent = subscribeToNotifications({ userId: userGraph.userId })
        // .then(async(notification: Notification) => {
        //   if (notification.notificationType === 'general') {
        //     if (notification?.body?.['triggerEventId'] === eventId) {
        //       const ssoUrl = action.responseField
        //         ? _get(notification, action.responseField)
        //         : _get(notification, 'body.payload.res.ssoUrl');
        //       if (ssoUrl) {
        //         newTab.location = ssoUrl;
        //       } else {
        //         return 'error';
        //       }
        //     }
        //   } else if (notification.notificationType === 'tileStateUpdated') {
        //      await updateNotificationHandled({ notificationId: notification.id, secondsToExpiration: 86400 });
        //      if (notification?.body?.['triggerEventId'] === eventId) {
        //       const ssoUrl = action.responseField
        //         ? _get(notification, action.responseField)
        //         : _get(notification, 'body.payload.res.ssoUrl');
        //       if (ssoUrl) {
        //         newTab.location = ssoUrl;
        //       } else {
        //         return 'error';
        //       }
        //     }
        //   }
        // })

        subscribeToSsoEvent
          .then(
            new Promise(r =>
              setTimeout(() => {
                // @ts-ignore
                r();
              }, 2000),
            ),
          )
          .then(() => publishTileEvent(eventToPublish))
          .then(
            new Promise(r =>
              setTimeout(() => {
                // @ts-ignore
                r();
              }, 8000),
            ),
          )
          .then(() =>
            subscribeToSsoEvent.unsubscribe ? subscribeToSsoEvent.unsubscribe() : undefined,
          );

        break;
      }
      case companyConstants.tileActions.navigatePurchase: {
        const event: OpenPaywallEvent = {
          type: 'openPaywall',
          metadata: {
            products: [product],
            tile: currentEvent.tile,
            eventSource: 'datacollector',
            ...currentEvent.data,
          },
        };
        emitter.emit(companyConstants.platformEvents.openPaywall, event);
        break;
      }
      case companyConstants.tileActions.navigateMIOP: {
        if (action.actionParams.length && action.actionParams[0]) {
          const href = action.actionParams.find((p: any) => p.key === 'href');
          const comp = action.actionParams.find((p: any) => p.key === 'component');
          const lbl = action.actionParams.find((p: any) => p.key === 'label');

          if (!href || !href.value) {
            console.log('navigate action href or value not provided');
          } else {
            const url = href.value.replace(
              'https://',
              `https://${source.sourceId === 'company' ? '' : `${source.sourceId}.`}`,
            );
            const navEvent = {
              type: 'openIframe',
              metadata: {
                url,
                component: comp ? comp.value : 'profile',
                label: lbl ? lbl.value : 'User Profile',
                eventSource: 'datacollector',
              },
            };

            emitter.emit(companyConstants.platformEvents.navEvent, navEvent);
          }
        }
        break;
      }
      case companyConstants.tileActions.publishFetch: {
        const eventType = platformHelpers.getComponentParamValue(action.actionParams, 'eventType');

        const componentTypes = platformHelpers.getComponentParamValue(
          action.actionParams,
          'componentTypes',
        );

        const topic = platformHelpers.getComponentParamValue(action.actionParams, 'topic');

        publishTileEvent(
          platformHelpers.formatPublishTileEventInput(
            {
              eventType,
              componentTypes,
              topic,
            },
            {
              user: userGraph,
              account: userGraph.account,
              tile: {
                ...tiles.find((tile: UserTile) => tile.tileId === currentEvent.tileId),
              },
            },
            currentEvent.data || formData,
            'fetch',
          ),
        );
        break;
      }
      case companyConstants.tileActions.navEvent: {
        const navType = platformHelpers.getComponentParamValue(action.actionParams, 'type');

        const component = platformHelpers.getComponentParamValue(action.actionParams, 'component');

        const url = platformHelpers.getComponentParamValue(action.actionParams, 'url');

        const link = platformHelpers.getComponentParamValue(action.actionParams, 'link');

        const navEvent = {
          type: navType,
          metadata: {
            component,
            url,
            link,
          },
        };

        emitter.emit(companyConstants.platformEvents.navEvent, navEvent);
        break;
      }
      default:
        console.log('Action type not recognized: ', action.type);
        break;
    }
  }
};

export default {
  handleNonDataAction,
  dataCollectorForms,
};
