import React, { useState, useReducer, useEffect, useCallback } from 'react';
import _isEmpty from 'lodash/isEmpty';
import {
  Tabs,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Center,
  useTabStepper,
  TabPanels,
  TabPanel,
} from '@companydotcom/potion';
import { Error, AppSpinner } from '@companydotcom/ui';
import { companyHelpers, companyConstants } from '@companydotcom/helpers';
import { useSource, useMitt } from '@companydotcom/providers';
import { User } from '@companydotcom/types';

import { useTiles } from '../../../providers/tiles-provider';
import { dataCollectorReducer, initialDataCollectorState } from '../utils/data-collector-reducer';
import { useGetGlobalUserQuery, useLazyGetProductSsoQuery } from '../../../services/user/user-api';
import { useLazyPublishTileEventQuery } from '../../../services/event/event-api';
import {
  useLazyGetAutocompleteResultsSearchQuery,
  useLazyGetGmbCategoryAutocompleteQuery,
} from '../../../services/search/search-api';
import { gtm } from '../../../lib/google-tag-manager';
import dcHelpers from '../utils';

// -- IMPORT FORMS --
import { BusinessProfileForm } from '../../../components/forms/business-profile/business-profile-form';
import { EmailForm } from '../../../components/forms/email/email-form';
import { BusinessFundingForm } from '../../../components/forms/business-funding/business-funding-form';
import { ChildUserProfileForm } from '../../../components/forms/child-user-profile/child-user-profile-form';
import { DomainForm } from '../../../components/forms/domain/domain-form';
import { FirstTimeForm } from '../../../components/forms/first-time-profile/first-time-form';
import { CalendlyForm } from '../../../components/forms/calendly/calendly';
import { GmbProfileFlow } from '../../gmb-profile-flow';
import { BusinessTaxId } from '../../../components/forms/business-tax-id/business-tax-id';
import { CheckVerification } from '../../../components/forms/check-verification';
import { EmailEligibilityForm } from '../../../components/forms/email-eligibility-form';
// -- IMPORT FORMS --

export interface DataCollectorProps {
  getAndSubNotifications?: () => void;
}

export interface DataCollectorFormProps {
  globalUser?: User;
  productSlug?: string;
  locale?: string;
  handleDataFormFill?: any;
  handleAutocompleteLookup?: any;
}

const businessSteps = [
  {
    slug: 'business-profile',
    component: <BusinessProfileForm />,
  },
  {
    slug: 'business-tax-id',
    component: <BusinessTaxId />,
  },
];

/**
 * @description
 * 1. Emitter event dispatches "NEW_EVENT"
 * 2. Reducer "NEW_EVENT" derives initial state from 'firstTimeUser' or sets event's actions to state
 * 3. useEffect for actions/index is triggered, parses dataToValidate and dataRequired dispatches "NEW_DATA_TO_VALIDATE"
 *    3a. if action does not have data values dispatches "NON_DATA_ACTION" then reducer "NON_DATA_ACTION" parses action using helper to perform specialized behavior, advances action.  return to 3.
 *    3b. if actionIdx has outrun actions length.  dispatches "RESET"
 * 4. useEffect for dataToValidate dispatches "PROCESS_DATA"
 * 5. Reducer "PROCESS_DATA" finds a form to ask first data item. Opens modal to that form.
 *    5a. if no form is found for that data, it is dropped return to 4.
 *    5b. if there are no more data items to process, actionIdx is advanced. return to 3.
 * 6. Form does its thing
 * 7. HandleDataFormFill is run and pulls all the fields that the activeForm handled out of the dataToValidate and sets it back to state
 * 8. (3 happens again)
 * 9. (3a case occurs)
 * 10. (3 happens again)
 * 11. (3b case occurs)
 * 13. Reducer "RESET" returns default state and performs scrolling behavior
 */
export const DataCollector = ({ getAndSubNotifications }: DataCollectorProps) => {
  // -- BEGIN STATE LOGIC --
  const { emitter } = useMitt();
  const { data: globalUser } = useGetGlobalUserQuery();
  const { tiles, getUpdatedUserTileData } = useTiles();
  const source = useSource();

  const [getProductSSO] = useLazyGetProductSsoQuery();
  const [publishTileEvent] = useLazyPublishTileEventQuery();
  const [getAutocompleteResults] = useLazyGetAutocompleteResultsSearchQuery();
  const [getGmbCategoryAutocomplete] = useLazyGetGmbCategoryAutocompleteQuery();

  const [formValues, setFormValues] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [eventState, dispatchDCState] = useReducer(dataCollectorReducer, initialDataCollectorState);
  const { tabIndex, handleTabsChange } = useTabStepper({
    steps: businessSteps,
    scrollOnStepChange: false,
  });

  const processData = useCallback(
    (dtv: any, state: any): any => {
      if (dtv && dtv.length > 0) {
        const activeReqData = dtv[0];
        let whichFormHasData: any | string = '';
        dcHelpers.dataCollectorForms.forEach(form => {
          const formHasData = form.dataElements.find(
            data => data.toLowerCase() === activeReqData.toLowerCase(),
          );
          if (formHasData) {
            whichFormHasData = form;
          }
        });
        // If we found a form with that data pop it
        if (whichFormHasData) {
          const { product } = state.currentEvent;
          gtm(`NED2ModalCTA', ${product.name}, 'Get Started`);
          onOpen();
          return {
            activeForm: whichFormHasData.form,
            dataToValidate: dtv,
          };
        }
        console.error(
          `Data Collector does not have a form to handle dataElement ${activeReqData}, skipping value`,
        );
        // protect available bad field value name causing loop
        return processData(dtv.slice(1), state);
      }
      return { actionIdx: state.actionIdx + 1, activeForm: '', dataToValidate: [] };
    },
    [onOpen],
  );

  const processAction = useCallback(
    (activeAction: any, state: any) => {
      if (!activeAction) {
        // we've evaluated all actions for the event
        return initialDataCollectorState;
      }
      let newDataToHandle = [];
      if (
        activeAction.dataToValidate &&
        (activeAction.skipDataValidation === undefined || activeAction.skipDataValidation === false)
      ) {
        newDataToHandle = activeAction.dataToValidate;
      }
      if (activeAction.dataRequired) {
        try {
          // only dataRequired that is NOT on the user graph already should be queued to be asked
          const reqData = companyHelpers.validateData(activeAction.dataRequired, globalUser);
          newDataToHandle = [...new Set([...newDataToHandle, ...reqData])]; // merges without duplication
          // end old check data
        } catch (err) {
          console.log('Error fetching user data: ', err);
        }
      }
      if (newDataToHandle.length) {
        return processData(newDataToHandle, state);
      }
      if (
        !activeAction.dataRequired &&
        (!activeAction.dataToValidate || activeAction.skipDataValidation === true)
      ) {
        // fires off non-form-based events
        // problem: this function sometimes returns a promise. (the return value of the promise is handled internally(mostly) reducer can't be asynchronous)
        const formToOpen = dcHelpers.handleNonDataAction(
          activeAction,
          {
            userGraph: globalUser,
            source,
            currentEvent: state.currentEvent,
            tiles,
            formData: state.tempData,
          },
          emitter,
          getProductSSO,
          publishTileEvent,
        );

        if (formToOpen && typeof formToOpen === 'string') {
          onOpen();
          return {
            activeForm: formToOpen,
            allowModalClose: activeAction.allowClose === undefined ? true : activeAction.allowClose,
          };
        }
      }
      return { actionIdx: state.actionIdx + 1 };
    },
    [emitter, getProductSSO, globalUser, onOpen, processData, publishTileEvent, source, tiles],
  );

  // fire this event exactly once.
  useEffect(() => {
    emitter.emit('isDataCollectorRendered', true);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    emitter.on(companyConstants.platformEvents.dataCollectorInitiated, (busEvt: any) => {
      dispatchDCState({ type: 'NEW_EVENT', payload: { newEvent: busEvt, processAction } });
    });

    return () => {
      emitter.off(companyConstants.platformEvents.dataCollectorInitiated);
    };
  }, [emitter, processAction]);

  const handleClose = async (refreshTiles = false) => {
    if (refreshTiles === true && getUpdatedUserTileData) {
      await getUpdatedUserTileData();
      dispatchDCState({ type: 'RESET', payload: { emitter } });
    } else {
      dispatchDCState({ type: 'RESET', payload: { scrollElement: true, emitter } });
    }
  };

  const handleDataFormFill = (dataCollectorState?: any) => {
    dispatchDCState({
      type: 'HANDLE_DATA_FORM_FILL',
      payload: { dataCollectorState, processAction, processData },
    });
  };

  const handleAutocompleteLookup = async (val: any, publisher?: string) => {
    let results;
    if (publisher === 'GMB') {
      results = await getGmbCategoryAutocomplete({ searchTerm: val })
        .unwrap()
        .catch(error => console.error('rejected', error));
    } else {
      results = await getAutocompleteResults({ searchTerm: val })
        .unwrap()
        .catch(error => console.error('rejected', error));
    }
    return results;
  };
  // -- END STATE LOGIC --

  // -- BEGIN RENDER LOGIC --
  const dataFormSelector = () => {
    const { activeForm, currentEvent, actions, actionIdx } = eventState;

    if (!activeForm || !currentEvent) {
      return null;
    }

    const formProps = {
      globalUser,
      productSlug: currentEvent.product && currentEvent.product.slug,
      locale: source?.i18nConfig?.lng,
      formLabels: source?.forms ? source?.forms.find(form => form?.type === activeForm) : undefined,
      handleDataFormFill,
      handleAutocompleteLookup,
    };

    if (_isEmpty(globalUser) || !globalUser) {
      return (
        <Center py={140}>
          <AppSpinner />
        </Center>
      );
    }

    switch (activeForm) {
      case 'check-verification':
        return (
          <CheckVerification
            user={globalUser}
            onVerificationComplete={handleDataFormFill}
            source={source}
            additionalVerificationCondition
            reason="activation"
          />
        );
      case 'emailEligibility':
        return <EmailEligibilityForm handleDataFormFill={handleDataFormFill} />;
      case 'domain':
        return <DomainForm {...formProps} />;
      case 'email':
        return <EmailForm {...formProps} />;
      case 'business': {
        const requiresTaxId =
          actions?.[actionIdx]?.actionParams?.find?.(
            (param: any) => param?.key === 'needsBusinessTaxId',
          )?.value === 'true';

        return requiresTaxId ? (
          <Tabs isLazy variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
            <TabPanels>
              {businessSteps.map(step => (
                <TabPanel key={step.slug}>
                  {React.cloneElement(step.component, {
                    ...formProps,
                    productName:
                      currentEvent?.sourceProductName || currentEvent?.product?.name || '',
                    formValues,
                    setFormValues,
                  })}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        ) : (
          <BusinessProfileForm
            {...formProps}
            productName={currentEvent?.sourceProductName || currentEvent?.product?.name || ''}
          />
        );
      }
      case 'admin-first-time-flow':
        return (
          <FirstTimeForm
            {...formProps}
            handleClose={async () => {
              await handleClose(true);
              handleDataFormFill();
              await getUpdatedUserTileData();
              if (getAndSubNotifications) {
                getAndSubNotifications();
              }
            }}
          />
        );
      case 'gmb-first-time-flow':
        return (
          <GmbProfileFlow
            {...formProps}
            handleClose={async () => {
              handleDataFormFill();
              await getUpdatedUserTileData();
              if (getAndSubNotifications) {
                getAndSubNotifications();
              }
            }}
          />
        );
      case 'child-first-time-flow':
        return (
          <ChildUserProfileForm
            {...formProps}
            handleClose={async () => {
              handleDataFormFill();
              await getUpdatedUserTileData();
              if (getAndSubNotifications) {
                getAndSubNotifications();
              }
            }}
          />
        );
      case 'calendly':
        return (
          <CalendlyForm
            {...formProps}
            url={
              process.env.NODE_ENV === 'production'
                ? actions[actionIdx].actionParams.find(
                    (param: any) => param.key === process.env.REACT_APP_SCHEDULING_PARAM,
                  ).value
                : actions[actionIdx].actionParams.find((param: any) => param.key === 'data-url')
                    .value || process.env.REACT_APP_CALENDLY_URL
            }
            productId={currentEvent.productId}
            tileId={currentEvent.tileId}
            header={
              actions[actionIdx].actionParams.find((param: any) => param.key === 'header')
                ? actions[actionIdx].actionParams.find((param: any) => param.key === 'header').value
                : 'Schedule a Free Consultation'
            }
            subheader={
              actions[actionIdx].actionParams.find((param: any) => param.key === 'subheader')
                ? actions[actionIdx].actionParams.find((param: any) => param.key === 'subheader')
                    .value
                : `Schedule Your Appointment to Talk with a CBOS ${currentEvent.product.name} Expert`
            }
          />
        );
      case 'businessFunding':
        return (
          <BusinessFundingForm
            {...formProps}
            handleClose={async () => {
              await handleClose(true);
              if (getAndSubNotifications) {
                getAndSubNotifications();
              }
            }}
          />
        );
      case 'error':
        return <Error sourceId={source?.sourceId} userId={globalUser?.userId} />;
      default:
        return null;
    }
  };

  if (!dataFormSelector() || dataFormSelector() === null) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={eventState.allowModalClose}
      size={eventState?.activeForm === 'calendly' ? '3xl' : '2xl'}
    >
      <ModalOverlay />
      <ModalContent>
        {eventState.allowModalClose && <ModalCloseButton />}
        {dataFormSelector()}
      </ModalContent>
    </Modal>
  );
  // -- END RENDER LOGIC --
};
