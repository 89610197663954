import React from 'react';
import { IconProps, Icon } from '@companydotcom/potion';

export const MemberIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 61 67" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5119 13.0955C38.5119 19.792 32.9212 30.5809 26.3986 30.5809C19.8761 30.5809 14.2854 19.792 14.2854 13.0955C14.2854 6.39895 19.8761 1.00452 26.3986 1.00452C33.1075 0.818505 38.5119 6.39895 38.5119 13.0955Z"
        fill="#66CC33"
        fillOpacity="0.2"
        stroke="#66CC33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6966 31.3599C30.6966 31.3599 30.776 34.3185 31.5634 35.1789C32.3508 36.0393 34.9949 36.0566 34.9949 36.0566L26.3984 53.0374L17.8019 36.0566C17.8019 36.0566 22.1001 37.4906 22.1001 31.3599C22.1001 30.7116 24.0091 32.9255 26.3984 32.9255C29.543 32.9255 30.6966 31.3599 30.6966 31.3599Z"
        fill="#66CC33"
        fillOpacity="0.2"
        stroke="#66CC33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8635 55.5288L37.767 36.4192L43.2139 37.4808C43.2139 37.4808 49.9813 38.8964 50.6415 44.2046L51.7969 65.2605L27.8736 66.0001L27.8635 55.5288Z"
        fill="#66CC33"
        fillOpacity="0.2"
        stroke="#66CC33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9333 55.5288L15.0299 36.4192L9.58295 37.4808C9.58295 37.4808 2.81558 38.8964 2.15534 44.2046L0.999938 65.2605L24.9233 66.0001L24.9333 55.5288Z"
        fill="#66CC33"
        fillOpacity="0.2"
        stroke="#66CC33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4642 17.9297L51.571 27.5205C51.3828 27.738 51.1469 27.7849 51.0205 27.7933C50.8903 27.8023 50.6378 27.7853 50.4211 27.5799L45.9586 23.3117C45.4012 22.7788 44.516 22.7968 43.9813 23.3517C43.4464 23.9066 43.4643 24.7884 44.0214 25.3212L48.4884 29.5937C49.1574 30.228 50.0505 30.581 50.9692 30.581C51.0481 30.581 51.1273 30.5784 51.2065 30.5731C52.2073 30.5066 53.1419 30.0218 53.7703 29.243C53.7865 29.2228 53.8022 29.2022 53.8173 29.1812L60.7384 19.5513C61.1881 18.9258 61.0434 18.0556 60.4154 17.6078C59.7872 17.16 58.9136 17.3041 58.4642 17.9297Z"
        fill="#66CC33"
      />
    </Icon>
  );
};
