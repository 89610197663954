import React, { useState } from 'react';
import { Stack, Heading, useMediaQuery, SkeletonText, Skeleton } from '@companydotcom/potion';
import { ProfileBox } from './profile-box';
import { EditButton } from './edit-button';

export interface ProfileSectionProps {
  heading: string;
  formSlug?: string;
  onOpen?: () => void;
  setFormSlug?: (formSlug: string) => void;
  canEdit?: boolean;
  isLoading?: boolean;
}

export const ProfileSection: React.FC<ProfileSectionProps> = ({
  heading,
  canEdit = false,
  children,
  formSlug,
  onOpen,
  setFormSlug,
  isLoading,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 48rem)');
  const [isHover, setIsHover] = useState(false);

  const handleEditButtonClick = (slug: string) => {
    onOpen?.();
    setFormSlug?.(slug);
  };

  return (
    <ProfileBox
      bg={['white', canEdit && isHover ? 'gray.200' : 'gray.50']}
      position="relative"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      p={4}
      boxShadow={['md', 'none']}
    >
      <Skeleton mb={1} height={isLoading ? '14px' : 'inherit'} isLoaded={!isLoading} width="full">
        {((isMobile && canEdit) || (!isMobile && isHover && canEdit)) && (
          <EditButton
            setFormSlug={formSlug ? () => handleEditButtonClick(formSlug) : undefined}
            position="absolute"
            top={2}
            right={2}
          />
        )}
        <Heading size="hs-md" textAlign={['center', null, 'left']} paddingBottom={3}>
          {heading}
        </Heading>
        <Stack alignItems={['center', null, 'flex-start']} width="full">
          {isLoading ? <SkeletonText noOfLines={2} width="full" /> : children}
        </Stack>
      </Skeleton>
    </ProfileBox>
  );
};
