import { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Text,
  Input,
  FormLabel,
  FormControl,
  VStack,
  Stack,
  Alert,
  AlertIcon,
  Skeleton,
  AlertDescription,
} from '@companydotcom/potion';
import { InputField, SelectField } from '@companydotcom/ui';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  SubTotalList,
  SelectedFeesList,
  useAcgCartStorage,
  acgRefData,
  useShowAddGuestButton,
  checkEventStatus,
} from '../../shared';
import {
  useGetEventForRegistrationQuery,
  useLazyGetEventForRegistrationQuery,
} from '../../../../services/acg/acg-api';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  EventFees,
  EventForPurchase,
  SearchIndividualResponse,
} from '../../../../services/acg/acg.types';
import { selectActiveGuest, addGuestToRegister, selectGuestsToRegister } from '../add-guest-slice';
import { AddGuestProps } from '../types';
import {
  determineIfSingleFee,
  getGuestEventFees,
  calculateTotalGuestFees,
  createGuestData,
} from '../utils';
import { AppThunk } from '../../../../store';

export const getMyEventsExistingGuestSchema = (activeGuest?: Partial<SearchIndividualResponse>) =>
  yup.object().shape({
    badgeName: yup.string().required('Please enter a Badge First Name'),
    organizationName: yup.string().required('Please enter an Organization'),
    individualType: activeGuest?.individualType
      ? yup.string().notRequired()
      : yup.string().required(),
    title: yup.string().required('Please enter a Title'),
    fees: yup
      .array()
      .min(1, 'Please select at least one fee')
      .required('Please select at least one fee')
      .nullable(),
  });

export const AddExistingGuest = ({
  goToStep,
  eventId,
  isLoading,
  isError,
  createEventForPurchaseHandler,
  showWaitListAlert,
}: Partial<AddGuestProps>) => {
  const [pageError, setPageError] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeGuest = useAppSelector(selectActiveGuest);
  const guestsToRegister = useAppSelector(selectGuestsToRegister);

  const { clearLocalCart, addEventToLocalCart } = useAcgCartStorage();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [getEvent] = useLazyGetEventForRegistrationQuery();
  const { data: eventForRegistration } = useGetEventForRegistrationQuery(
    eventId && globalUser
      ? {
          user: globalUser,
          eventId,
        }
      : skipToken,
  );

  const hasOneFee = !!determineIfSingleFee(eventForRegistration);

  const defaultValues = {
    badgeName: activeGuest?.firstName ?? '',
    individualType: activeGuest?.individualType ?? '',
    emailAddress: activeGuest?.emailAddress ?? '',
    organizationName: activeGuest?.organization ?? '',
    title: activeGuest?.title ?? '',
    fees: [] as EventFees[],
  };

  // Hooks
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getMyEventsExistingGuestSchema(activeGuest)),
    defaultValues,
  });
  const watchedFees = useWatch({ control, name: 'fees' });

  const guestEventFees = useMemo(() => {
    return getGuestEventFees(eventForRegistration);
  }, [eventForRegistration]);

  useEffect(() => {
    if (eventForRegistration) {
      reset({
        badgeName: activeGuest?.firstName ?? '',
        organizationName: activeGuest?.organization ?? '',
        individualType: activeGuest?.individualType ?? '',
        title: activeGuest?.title ?? '',
        fees: hasOneFee ? guestEventFees : ([] as EventFees[]),
      });
    }
  }, [
    eventForRegistration,
    activeGuest?.firstName,
    activeGuest?.individualType,
    activeGuest?.organization,
    activeGuest?.title,
    guestEventFees,
    hasOneFee,
    reset,
  ]);

  const shouldShowGuestButton = useShowAddGuestButton(
    eventForRegistration,
    eventForRegistration?.registrantAndFees?.[0]?.guests?.[0],
    guestsToRegister.length + 1,
  );
  //** Only applicable if there are NO custom questions available for the event */
  const addGuestSubmitHandler = async (data: typeof defaultValues) => {
    try {
      if (typeof data.fees[0] === 'string') {
        data.fees = guestEventFees?.filter(i => i?.priceKey === (watchedFees?.[0] as any));
      }
      const guestData = createGuestData(data, eventForRegistration, activeGuest);
      // @ts-ignore
      dispatch(addGuestToRegister(guestData));
      goToStep?.('add-guest-search');
    } catch (err) {
      console.log('onSubmit err', err);
      setPageError(true);
    }
  };

  function addGuestToRegisterThunk(data?: typeof defaultValues): AppThunk<EventForPurchase> {
    return (dispatch, getState) => {
      const guestData = createGuestData(data, eventForRegistration, activeGuest);
      // @ts-ignore
      dispatch(addGuestToRegister(guestData));

      const state = getState();

      const eventForPurchase = createEventForPurchaseHandler?.(
        data,
        eventForRegistration,
        state.acgAddGuest.guestsToRegister,
      ) as EventForPurchase;

      return eventForPurchase;
    };
  }

  const addToCartSubmitHandler = async (data: typeof defaultValues) => {
    try {
      if (typeof data.fees[0] === 'string') {
        data.fees = guestEventFees?.filter(i => i?.priceKey === (watchedFees?.[0] as any));
      }
      const eventToPurchase = dispatch(addGuestToRegisterThunk(data));
      clearLocalCart();
      addEventToLocalCart(eventToPurchase);
      if (globalUser && eventId) {
        const latestEventData = await getEvent({ user: globalUser, eventId }).unwrap();
        const {
          shouldShouldWaitlistAlert,
          shouldShowEventFullAlert,
          shouldShowEventOverCapacityAlert,
        } = checkEventStatus(latestEventData, eventToPurchase?.guests?.length);
        navigate('/acg-shopping-cart', {
          state: {
            shouldShouldWaitlistAlert,
            shouldShowEventFullAlert,
            shouldShowEventOverCapacityAlert,
            totalWaitList: latestEventData?.totalWaitList,
          },
        });
      }
    } catch (err) {
      setPageError(true);
    }
  };

  return (
    <Box width="full" maxW={908}>
      {pageError && (
        <Alert status="error" variant="solid" mb={[8, 12]}>
          <AlertIcon />
          Error
        </Alert>
      )}
      {showWaitListAlert && (
        <Alert status="warning" my={10}>
          <AlertIcon />
          <AlertDescription fontSize="sm" lineHeight="5">
            <Text fontWeight="bold">There’s a Waitlist for this Event</Text>
            <Text>
              You can still register for this event and will have a pro forma invoice generated for
              your registration. If you’re moved off the waitlist, you will be notified of this
              change and charged your registration.
            </Text>
            {eventForRegistration?.hasWaitList && (
              <Text fontWeight="bold" fontStyle="italic">
                {eventForRegistration?.totalWaitList === 0
                  ? 'You will be the first person placed on the waitlist unless someone completes an order before you.'
                  : `Currently, there are ${eventForRegistration?.totalWaitList} people on the waitlist.`}
              </Text>
            )}
          </AlertDescription>
        </Alert>
      )}
      <Box as="form">
        <Stack
          direction={['column', null, 'row']}
          align="flex-start"
          width="full"
          spacing={[6, null, 8, 12]}
        >
          <VStack width="full" spacing={6} alignItems="flex-start">
            <Text lineHeight={1.25} textStyle={['lg', '3xl']} fontWeight="bold" opacity={0.7}>
              Guest Information
            </Text>
            <FormControl>
              <FormLabel>Guest Name:</FormLabel>
              <Skeleton isLoaded={!isLoading}>
                <Text fontWeight={600}>
                  {isLoading
                    ? '&#32&#32&#32&#32&#32'
                    : isError
                    ? ''
                    : `${activeGuest?.firstName} ${activeGuest?.lastName}`}
                </Text>
              </Skeleton>
            </FormControl>
            <FormControl>
              <FormLabel>Email:</FormLabel>
              <Skeleton isLoaded={!isLoading}>
                <Text fontWeight={600}>
                  {isLoading
                    ? '&#32&#32&#32&#32&#32'
                    : isError
                    ? ''
                    : activeGuest?.emailAddress?.replace(
                        /^(.)(.*)(.@.*)$/,
                        // @ts-ignore
                        (_, a, b, c) => a + b.replace(/./g, '*') + c,
                      )}
                </Text>
              </Skeleton>
            </FormControl>

            <FormControl>
              <FormLabel>Function/Role:</FormLabel>
              <Skeleton isLoaded={!isLoading}>
                {activeGuest?.individualType && (
                  <Text fontWeight={600}>
                    {isLoading
                      ? '&#32&#32&#32&#32&#32'
                      : isError
                      ? ''
                      : activeGuest?.individualType}
                  </Text>
                )}
                {!activeGuest?.individualType && (
                  <SelectField
                    register={register}
                    isLoading={isLoading}
                    name="individualType"
                    errors={errors}
                    helperText="Required"
                  >
                    <option value="" />
                    {acgRefData.individualType.map(o => (
                      <option key={o?.recordName} value={o?.recordName}>
                        {o?.recordName}
                      </option>
                    ))}
                  </SelectField>
                )}
              </Skeleton>
            </FormControl>
            <FormControl>
              <FormLabel>Registration Type:</FormLabel>
              <Skeleton isLoaded={!isLoading}>
                <Input
                  isReadOnly
                  height={6}
                  value={
                    isLoading
                      ? '&#32&#32&#32&#32&#32'
                      : isError
                      ? ''
                      : eventForRegistration?.registrantAndFees?.[0]?.guests?.[0]?.registrant_type
                          ?.registrantTypeName
                  }
                  outline={0}
                  pl={0}
                  border={0}
                  fontWeight={600}
                />
              </Skeleton>
            </FormControl>
          </VStack>
          <VStack width="full" spacing={6} alignItems="flex-start">
            <Text lineHeight={1.25} textStyle={['lg', '3xl']} fontWeight="bold" opacity={0.7}>
              Badge Information
            </Text>
            <InputField
              isLoading={isLoading}
              register={register}
              label="Badge First Name"
              name="badgeName"
              errors={errors}
              helperText="Required"
            />
            <InputField
              isLoading={isLoading}
              register={register}
              name="organizationName"
              label="Organization"
              helperText="Required"
              errors={errors}
            />
            <InputField
              isLoading={isLoading}
              register={register}
              label="Title"
              name="title"
              errors={errors}
              helperText="Required"
            />
          </VStack>
        </Stack>
        <SelectedFeesList
          hasOneFee={hasOneFee}
          data={eventForRegistration?.registrantAndFees?.[0]?.guests?.[0]?.packages}
          mt={20}
          register={register}
          name="fees"
          fees={watchedFees}
          isAddGuestReg={true}
        />
        <SubTotalList
          isSubmitting={isSubmitting}
          isValid={isValid}
          total={calculateTotalGuestFees(hasOneFee, guestEventFees, watchedFees as any[])}
          maxW={[null, null, 296]}
          ml="auto"
          addGuestSubmitHandler={handleSubmit(addGuestSubmitHandler)}
          addToCartSubmitHandler={handleSubmit(addToCartSubmitHandler)}
          showAddGuestButton={shouldShowGuestButton}
        />
      </Box>
    </Box>
  );
};
