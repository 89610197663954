import React, { useCallback } from 'react';
import * as yup from 'yup';
import _get from 'lodash/get';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, Box, Text, Button, Heading, Stack, Center } from '@companydotcom/potion';
import { companyHelpers } from '@companydotcom/helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactSelectField } from '@companydotcom/ui';

dayjs.extend(timezone);

const processTimestampListIntoDateTimeMap = (timestampList: any) => {
  const availability = {};
  timestampList?.forEach((ts: any) => {
    const timeStampSeconds =
      `${ts.utcTimestamp}`.length === 13 ? Math.round(ts.utcTimestamp / 1000) : ts.utcTimestamp;
    const formatted = dayjs
      .unix(timeStampSeconds)
      .tz('America/New_York')
      .format('MM[-]DD[-]YYYY HH[:]mm')
      .split(' ');
    if (!availability[formatted[0]]) {
      availability[formatted[0]] = {};
    }
    availability[formatted[0]][formatted[1]] = timeStampSeconds;
  });
  return availability;
};

const processTimestampListIntoTimeDateMap = (timestampList: any) => {
  const availability = {};
  timestampList?.forEach((ts: any) => {
    const timeStampSeconds =
      `${ts.utcTimestamp}`.length === 13 ? Math.round(ts.utcTimestamp / 1000) : ts.utcTimestamp;
    const formatted = dayjs
      .unix(timeStampSeconds)
      .tz('America/New_York')
      .format('MM[-]DD[-]YYYY HH[:]mm')
      .split(' ');
    if (!availability[formatted[1]]) {
      availability[formatted[1]] = {};
    }
    availability[formatted[1]][formatted[0]] = timeStampSeconds;
  });
  return availability;
};

const getAppointmentSelectionSchema = () =>
  yup.object().shape({
    date: yup.object().shape({
      label: yup.string().test('specific date', 'Please select a date', val => val !== 'Any Day'),
    }),
    time: yup.object().shape({
      label: yup.string().test('specific time', 'Please select a time', val => val !== 'Any Time'),
    }),
  });

export const AppointmentSelectionForm = ({
  setSelectedApptTimestamp,
  availability,
  availabilityError,
  backupPhoneNumber,
  nextStep,
}: any) => {
  const handleAppointmentSelect = (selection: any) => {
    setSelectedApptTimestamp(selection);
    nextStep();
  };

  const defaultValues = {
    date: {
      value: '',
      label: '',
    },
    time: {
      value: '',
      label: '',
    },
  };
  const availableTimesByDate = processTimestampListIntoDateTimeMap(availability);
  const availableDatesByTime = processTimestampListIntoTimeDateMap(availability);
  const allDates = Object.keys(availableTimesByDate).sort();
  const allTimes = Object.keys(availableDatesByTime).sort();
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    watch,
    control,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(getAppointmentSelectionSchema()),
    defaultValues: {
      date: {
        value: ' ',
        label: t('forms.appointmentSelection.inputs.date.defaultValue'),
      },
      time: {
        value: '',
        label: t('forms.appointmentSelection.inputs.time.defaultValue'),
      },
    },
  });

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      const appointmentTimestamp = _get(availableTimesByDate, [
        values.date.value,
        values.time.value,
      ]);
      handleAppointmentSelect(appointmentTimestamp);
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  const dateOptions = useCallback(
    values => {
      if (availabilityError) {
        return [
          {
            label: t('forms.appointmentSelection.dateOptions.noAvailability'),
            value: 'Error',
            disabled: true,
            failed: true,
          },
        ];
      }
      if (!availability || !availability.length) {
        return [
          {
            label: t('forms.appointmentSelection.dateOptions.loadingDates'),
            value: 'Loading',
            disabled: true,
            loading: true,
          },
        ];
      }
      return [
        ...allDates.map(dateVal => {
          const timeForDate = Object.keys(availableTimesByDate[dateVal])[0];
          return {
            label: dayjs
              .unix(availableTimesByDate[dateVal][timeForDate])
              .tz('America/New_York')
              .format('MM[-]DD[-]YYYY'),
            value: dateVal,
            disabledText: t('common.misc.unavailable'),
            disabled:
              values.time.id === 'any' || companyHelpers.isEmpty(values.time.id)
                ? false
                : !Object.keys(availableDatesByTime[values.time.id]).includes(dateVal),
          };
        }),
        { label: 'Any Day', value: 'any' },
      ];
    },
    [t, availability, availabilityError, allDates, availableDatesByTime, availableTimesByDate],
  );

  const timeOptions = useCallback(
    values => {
      if (availabilityError) {
        return [
          {
            label: 'No availability',
            value: 'Error',
            disabled: true,
            failed: true,
          },
        ];
      }
      if (!availability || !availability.length) {
        return [
          {
            label: 'Loading times...',
            value: 'Loading',
            disabled: true,
            loading: true,
          },
        ];
      }
      return [
        ...allTimes.map(timeVal => {
          const dateForTime = Object.keys(availableDatesByTime[timeVal])[0];
          return {
            label: dayjs
              .unix(availableDatesByTime[timeVal][dateForTime])
              .tz('America/New_York')
              .format('hh[:]mm A'),
            value: timeVal,
            disabledText: t('common.misc.unavailable'),
            disabled:
              values.date.id === 'any' || companyHelpers.isEmpty(values.date.id)
                ? false
                : !Object.keys(availableTimesByDate[values.date.id]).includes(timeVal),
          };
        }),
        { label: 'Any Time', value: 'any' },
      ];
    },
    [t, availability, availabilityError, allTimes, availableDatesByTime, availableTimesByDate],
  );

  const watchAllFields = watch();

  return (
    <Flex
      className="appointment-selection-form"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={14}
    >
      <Box textAlign="center" maxWidth="xl">
        <Heading size="hs-xl">{t('forms.appointmentSelection.heading')}</Heading>
        <Text textStyle="lg" mt={5}>
          {t('forms.appointmentSelection.subheading')}
        </Text>
      </Box>
      <Center as="form" flexDirection={['column', null, 'row']} width="full" maxWidth="xl" mt={14}>
        <Stack direction={['column', 'row']} width="full" spacing={8}>
          <ReactSelectField
            name="date"
            control={control}
            options={dateOptions(watchAllFields)}
            label={t('forms.appointmentSelection.inputs.date.label')}
            errors={errors}
          />

          <ReactSelectField
            name="time"
            control={control}
            options={timeOptions(watchAllFields)}
            label={t('forms.appointmentSelection.inputs.time.label')}
            errors={errors}
          />
        </Stack>
      </Center>
      <Box textAlign="center" mt={[5, 14]}>
        <Button
          size="lg"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          id="CTA__submit"
          isLoading={isSubmitting}
          isDisabled={!isValid || isSubmitting}
        >
          {t('forms.appointmentSelection.formButton')}
        </Button>
        {availabilityError && backupPhoneNumber ? (
          <Text fontSize={[3, null, 5]} mt={[6, null, 8]}>
            <Trans
              i18nKey="forms.appointmentSelection.supportText"
              values={{
                backupPhoneNumber,
              }}
            >
              Please call{' '}
              <Text as="span" color={['dashboard_primary_color']}>
                {backupPhoneNumber}
              </Text>{' '}
              to schedule your appointment
            </Trans>
          </Text>
        ) : (
          <Text fontSize={[3, null, 5]} mt={[6, null, 8]}>
            {' '}
          </Text>
        )}
      </Box>
    </Flex>
  );
};
