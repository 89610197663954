import React, { useEffect, useMemo } from 'react';
import { useMitt } from '@companydotcom/providers';
import { useTranslation } from 'react-i18next';
import type { User } from '@companydotcom/types';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Center, FormControl, Stack, Text, useBoolean } from '@companydotcom/potion';
import type { BecomeMemberSharedProps } from '../index';
import { companyConstants } from '@companydotcom/helpers';
import { PaginatedSelect } from '../components/paginated-select';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { DuesProduct } from '../../../../../../services/acg/acg.types';
import {
  getSelectedChapter,
  setSelectedChapter,
  setOrderConfirmationData,
} from '../../../../acg-checkout/checkout-slice';
import {
  useCreateCartMutation,
  useLazySubmitMembershipApplicationQuery,
  useDoPaymentMutation,
  useLazyDeleteDraftMembershipsQuery,
} from '../../../../../../services/acg/acg-api';
import { useAcgCartStorage } from '../../../../shared';
import { PageHeading } from '../../../../../../components/elements';
import { useGetGlobalUserQuery } from '../../../../../../services/user/user-api';

export const PlanSelect = ({
  isSubpage,
  goToRegStep,
  packageKeyParam,
  setIsBecomingMember,
  register,
  handleSubmit,
  control,
  reset,
  errors,
  getValues,
}: BecomeMemberSharedProps) => {
  const { emitter } = useMitt();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const chapter = useAppSelector(getSelectedChapter);

  const [isValid, setIsValid] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean();

  const [doPayment] = useDoPaymentMutation();
  const [addChapterToCart] = useCreateCartMutation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const { addMembershipToLocalCart } = useAcgCartStorage();
  const [submitMembershipApplication] = useLazySubmitMembershipApplicationQuery();
  const [deleteDraftMemberships] = useLazyDeleteDraftMembershipsQuery();
  const isInternational = useMemo(
    () =>
      chapter?.duesProducts?.[0]?.chapterTypeName.includes('EU') ||
      chapter?.duesProducts?.[0]?.chapterTypeName.includes('UK'),
    [chapter?.duesProducts],
  );

  const navigateAfterPurchase = () => {
    setIsBecomingMember?.off();
    if (isInternational) {
      if (isSubpage) {
        navigate('/checkout', { state: { flow: 'checkout' } });
      } else {
        goToRegStep?.('checkout');
      }
    } else if (goToRegStep && !isInternational) {
      emitter.emit(companyConstants.platformEvents.dataCollectorComplete, {
        path: '/acg-shopping-cart',
      });
    } else {
      navigate('/acg-shopping-cart', { state: { flow: 'checkout' } });
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading?.on();
      if (chapter && chapter?.duesProducts?.length && chapter && globalUser) {
        await deleteDraftMemberships({
          user: globalUser,
        }).unwrap();

        const applicationId = await submitMembershipApplication({
          user: globalUser as User,
          payload: {
            name: globalUser?.email as string,
            autoRenew: false,
            packageId: chapter?.packageId,
            chapterId: chapter?.chapterId,
            processId: chapter?.joinProcessId,
            membershipTypeId: chapter?.membershipTypeId,
            customFields: [
              {
                custom_field_id: 'membership-applications__membership_gain__c',
                string_value: 'Nothing',
              },
            ],
          },
        }).unwrap();

        const cart = await addChapterToCart({
          user: globalUser as User,
          items: {
            memberships: [
              {
                applicationId,
                packageId: chapter?.packageId,
                chapterId: chapter?.chapterId,
                processId: chapter?.joinProcessId,
                membershipTypeId: chapter?.membershipTypeId,
                customFields: [
                  {
                    custom_field_id: 'membership-applications__membership_gain__c',
                    string_value: 'Nothing',
                  },
                ],
              },
            ],
            events: [],
          },
        }).unwrap();

        if (isInternational) {
          const response = await doPayment({
            user: globalUser,
            payload: {
              paymentInfo: {
                autoRenew: false,
              },
            },
          }).unwrap();
          dispatch(setOrderConfirmationData(response));
        }
        addMembershipToLocalCart({ ...cart, ...chapter });
        navigateAfterPurchase();
      }
      setIsLoading?.off();
    } catch (err) {
      console.log('Error adding chapter to cart on plan select:', err);
      setIsLoading?.off();
    }
  };

  const onChange = (e: any) => {
    const newPlan = chapter?.duesProducts.filter((item: DuesProduct) => item.productName === e)[0];
    if (newPlan) {
      dispatch?.(setSelectedChapter({ ...chapter, ...newPlan }));
    }
    setIsValid?.on();
  };

  useEffect(() => {
    if (!getValues?.()?.['acgPlan']) {
      setIsValid?.off();
    }
  }, [getValues, isValid, setIsValid]);

  // TODO: finds a chapter from a key param sent over from acg.org
  // useEffect(() => {
  //   if (packageKeyParam) {
  //     const newPlan = chapter?.duesProducts.filter(
  //       (item: ChapterPackage) => item.productId === packageKeyParam,
  //     )[0];

  //     if (newPlan) {
  //       dispatch?.(setSelectedChapter({ ...chapter, ...newPlan }));
  //     }
  //     setIsValid?.on();
  //   }
  // }, [chapter, dispatch, packageKeyParam, setIsValid]);

  return (
    <Center
      className="acg-package-selection__container"
      flexDirection="column"
      bg={['transparent', 'white']}
      textAlign="center"
      px={[0, 4]}
      py={isSubpage ? [0, 12] : 0}
    >
      <Box
        as="form"
        width="full"
        className="acg-package-selection__form"
        maxW={isSubpage ? 908 : 608}
      >
        <Stack width="full" spacing={[8, 12]} mb={[4, 12]}>
          <PageHeading
            heading={t('acg.firstTimeFlow.subpage.planHeading')}
            width="full"
            maxW={908}
          />

          <Text mx={4} width="full" alignSelf="center">
            Thank you for your interest in joining the{' '}
            <Text as="span" fontWeight="bold">{`${chapter?.chapterName}`}</Text> chapter. Below are
            membership options for this chapter.
          </Text>
        </Stack>
        <FormControl>
          <PaginatedSelect
            data={chapter?.duesProducts}
            onSubmit={handleSubmit?.(onSubmit)}
            onChange={onChange}
            type="plan"
            isValid={isValid}
            isLoading={isLoading}
            packageKeyParam={packageKeyParam}
            formProps={{
              control,
              reset,
              register,
              errors,
              name: 'acgPlan',
            }}
          />
        </FormControl>
        {!isSubpage && (
          <Button
            mt={2}
            isDisabled={isLoading}
            size="sm"
            variant="ghost"
            onClick={() => {
              reset?.({ acgPlan: '', chapterSearch: '' });
              navigate('/registration', { state: { defaultStep: 'chapter-select' } });
            }}
          >
            {t('acg.firstTimeFlow.subpage.backToChapter')}
          </Button>
        )}
      </Box>
    </Center>
  );
};
