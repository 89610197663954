import React, { useState, ComponentState } from 'react';
import { Flex, FormLabel, FormControl, ThemingProps, HTMLPotionProps } from '@companydotcom/potion';
import { SelectField } from '@companydotcom/ui';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

export interface DateSelectProps {
  register: UseFormRegister<any>;
  formValues: ComponentState;
  setValue: UseFormSetValue<any>;
  label: string;
  startYear?: string;
  endYear?: string;
  monthStateKey?: string;
  dayStateKey?: string;
  yearStateKey?: string;
  formControlStyles?: HTMLPotionProps<'div'> & ThemingProps<'FormControl'>;
}

export const DateSelect: React.FC<DateSelectProps> = ({
  formValues,
  label,
  monthStateKey,
  dayStateKey,
  yearStateKey,
  startYear,
  endYear,
  formControlStyles,
  register,
  setValue,
}) => {
  const [month, setMonth] = useState(formValues.month);

  return (
    <FormControl
      sx={{
        ...formControlStyles,
        '.potion-form-control': {
          maxWidth: [null, '3xs'],
          marginRight: [null, 1],
        },
      }}
    >
      <FormLabel>{label}</FormLabel>
      <Flex
        flexDirection={['column', 'row']}
        sx={{
          transform: 'translateY(-8px)',
        }}
      >
        <SelectField
          className="date-select-month"
          register={register}
          name={monthStateKey || 'month'}
          defaultValue={formValues.month}
          onChange={event => {
            setValue(monthStateKey || 'month', event.target.value, {
              shouldDirty: true,
              shouldValidate: true,
            });
            setMonth(event.target.value);
          }}
        >
          {Object.entries(monthOptions).map(([month, k]) => (
            <option key={month} value={k.value}>
              {month}
            </option>
          ))}
        </SelectField>
        <Flex
          sx={{
            justifyContent: ['space-between'],
            '.potion-form-control': {
              marginRight: [null, 1],
              maxWidth: ['152px', null],
              '.date-select-year': {
                minWidth: '90px',
              },
              '.date-select-day': {
                minWidth: '70px',
              },
            },
          }}
        >
          <SelectField
            className="date-select-day"
            register={register}
            name={dayStateKey || 'day'}
            defaultValue={formValues.day}
          >
            {generateDayOptions(month).map(day => day)}
          </SelectField>
          <SelectField
            className="date-select-year"
            register={register}
            name={yearStateKey || 'year'}
            defaultValue={formValues.year}
          >
            {generateYearOptions(startYear, endYear)
              .map(year => year)
              .reverse()}
          </SelectField>
        </Flex>
      </Flex>
    </FormControl>
  );
};

export const generateYearOptions = (
  startYear = '1900',
  endYear = new Date().getFullYear().toString(),
) => {
  let intStartYear = parseInt(startYear, 10);
  const intEndYear = parseInt(endYear, 10);

  const options = [];

  for (intStartYear; intStartYear <= intEndYear; intStartYear++) {
    options.push(<option key={intStartYear}>{intStartYear.toString()}</option>);
  }

  return options;
};

export const generateDayOptions = (month: string) => {
  const days =
    Object.entries(monthOptions).find?.(key => key?.[1]?.value === month)?.[1]?.days ?? 31;

  const options = [];

  for (let i = 1; i <= days; i++) {
    options.push(<option key={i}>{i.toString()}</option>);
  }

  return options;
};

export const monthOptions = {
  January: {
    value: '01',
    days: 31,
  },
  February: {
    value: '02',
    days: 29,
  },
  March: {
    value: '03',
    days: 31,
  },
  April: {
    value: '04',
    days: 30,
  },
  May: {
    value: '05',
    days: 31,
  },
  June: {
    value: '06',
    days: 30,
  },
  July: {
    value: '07',
    days: 31,
  },
  August: {
    value: '08',
    days: 31,
  },
  September: {
    value: '09',
    days: 30,
  },
  October: {
    value: '10',
    days: 31,
  },
  November: {
    value: '11',
    days: 30,
  },
  December: {
    value: '12',
    days: 31,
  },
};
