import React, { useState, useCallback } from 'react';
import { AccordionButton, AccordionPanel } from '@companydotcom/potion';
import { isEmpty, startCase, toLower } from 'lodash';
import { ensure } from '@companydotcom/helpers';
import {
  ExtendedSubscriptionRatePlan,
  ExtendedSubscriptionTier,
  ExtendedSubscriptionProduct,
  Maybe,
} from '@companydotcom/types';
import { ActionMeta } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { SubscriptionRatePlan as SRatePlan } from './subscription-rate-plan';
import { getInitialPlanPrice, determineIfRatePlanDisabled } from '../utils/subscription-helpers';
import { RatePlanOptions } from '../../../components/elements/rate-plan-options/rate-plan-options';
import { AccordionTitle } from './accordion-title';
import {
  getPaywallContentOptions,
  calculateEstimatedRatePlanPrice,
} from '../../../utils/helpers/purchasing-helpers';
import { CartReducerProps } from '../../../state/reducers/cart-reducer';

export interface SubscriptionAccordionProps extends CartReducerProps {
  availableProductStep?: boolean;
  product: ExtendedSubscriptionProduct;
  productPrice?: number | null;
  isMobile?: boolean;
  selectedTiers?: ExtendedSubscriptionTier[] | [];
  isExpanded?: boolean;
}

export const SubscriptionAccordion = ({
  cartState,
  dispatchCartState,
  availableProductStep,
  product,
  productPrice = 0,
  isMobile,
  isExpanded,
}: SubscriptionAccordionProps) => {
  const { t } = useTranslation();
  const [selectedRatePlanIndex, setSelectedRatePlanIndex] = useState<Maybe<number> | undefined>(
    product.ratePlans?.findIndex(plan => plan?.isActive),
  );
  const [selectedRatePlanPrice, setSelectedRatePlanPrice] = useState<number | null | undefined>(
    getInitialPlanPrice(product),
  );
  const [dropdownValue, setDropdownValue] = useState<ExtendedSubscriptionTier | null | undefined>(
    null,
  );
  const contentOptions = product && getPaywallContentOptions(product);
  const quantityLabel =
    contentOptions?.['QUANTITY_LABEL'] &&
    startCase(toLower(contentOptions['QUANTITY_LABEL'].replace(/^"|"$/g, '')));
  const activeRatePlan = product.ratePlans?.find(ratePlan => ratePlan?.isActive === true);
  const hasTiers = product.ratePlans?.some(plan => plan?.tiers && plan.tiers.length > 0);
  const dropdownOptions = product.ratePlans?.some(plan => plan?.isActive)
    ? product.ratePlans?.find(plan => plan?.isActive)?.tiers
    : product.ratePlans?.find(plan => plan?.tiers && plan?.tiers?.length > 0)?.tiers;

  const IS_PACKAGE = product.productId === 'PACKAGE_OPTIONS_SUMMARY';

  const handleTierChange = useCallback(
    (
      tier?: ExtendedSubscriptionTier | null,
      selectedRatePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
      ratePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
      selectedTiers?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>>,
      event?: ActionMeta<Maybe<ExtendedSubscriptionTier>>,
    ) => {
      const foundRatePlan = ratePlans?.find(plan => plan?.ratePlanId === tier?.ratePlanId);
      const tierExistsInCart = selectedTiers?.some(t => t?.ratePlanId === tier?.ratePlanId);
      const productExistsInCart = selectedRatePlans?.some(
        plan =>
          plan?.productId === ratePlans?.find?.(p => p?.productId === plan?.productId)?.productId,
      );

      // A clearable action sent from the react-select dropdown. Should clear all state for the product
      if (event?.action === 'clear') {
        const associatedTier = selectedTiers?.find?.(t => t?.productName === product.name);
        const associatedPlan = selectedRatePlans?.find(p => p?.productName === product.name);

        if (associatedPlan && associatedTier) {
          dispatchCartState?.({
            type: 'REMOVE_SUBSCRIPTION_TIER',
            payload: associatedTier,
          });
          dispatchCartState?.({
            type: 'REMOVE_RATE_PLAN',
            payload: associatedPlan,
          });

          setSelectedRatePlanPrice(getInitialPlanPrice(product));
          setDropdownValue(null);
          setSelectedRatePlanIndex(undefined);
        }
      }

      if (foundRatePlan && !isEmpty(foundRatePlan) && event?.action !== 'clear' && tier) {
        setSelectedRatePlanPrice(calculateEstimatedRatePlanPrice(foundRatePlan, tier));
        setSelectedRatePlanIndex(ratePlans?.findIndex(plan => plan?.name === foundRatePlan.name));

        // If selected tier is already active, you can't repurchase it, so remove it from cart and remove the rate plan associated with it from the cart
        if (tier?.isActive) {
          // totalRatePlans is specifically calculated to perform logic on tiered products with more than one rate plan, ex: Email Marketing product
          const totalRatePlans = ratePlans?.length || 1;
          dispatchCartState?.({
            type: totalRatePlans > 1 ? 'UPDATE_SUBSCRIPTION_TIER' : 'REMOVE_SUBSCRIPTION_TIER',
            payload: selectedTiers?.find(t => t?.ratePlanId === tier.ratePlanId) || tier,
          });
          dispatchCartState?.({
            type: totalRatePlans > 1 ? 'UPDATE_RATE_PLAN' : 'REMOVE_RATE_PLAN',
            payload: foundRatePlan,
          });
          setDropdownValue(
            totalRatePlans > 1
              ? selectedTiers?.find(t => t?.ratePlanId === tier.ratePlanId) || tier
              : null,
          );
          setSelectedRatePlanIndex(ratePlans?.findIndex(plan => plan?.name === foundRatePlan.name));
        }

        // If its a purchasable tier, and its already in the cart, assume user is selecting a new tier. So update the tier and associated plan accordingly
        if (!tier?.isActive && tierExistsInCart) {
          dispatchCartState?.({
            type: 'UPDATE_SUBSCRIPTION_TIER',
            payload: tier,
          });
          dispatchCartState?.({
            type: 'UPDATE_RATE_PLAN',
            payload: foundRatePlan,
          });
          setDropdownValue(tier);
          setSelectedRatePlanIndex(ratePlans?.findIndex(plan => plan?.name === foundRatePlan.name));
        }

        // If a tiered product hasn't been selected, automatically add or update the tier in the cart and add the associated rate plan
        if (!tier?.isActive && !tierExistsInCart) {
          dispatchCartState?.({
            type: productExistsInCart ? 'UPDATE_SUBSCRIPTION_TIER' : 'ADD_SUBSCRIPTION_TIER',
            payload: tier,
          });
          dispatchCartState?.({
            type: productExistsInCart ? 'UPDATE_RATE_PLAN' : 'ADD_RATE_PLAN',
            payload: foundRatePlan,
          });
          setDropdownValue(tier);
          setSelectedRatePlanIndex(ratePlans?.findIndex(plan => plan?.name === foundRatePlan.name));
        }
      }
    },
    [dispatchCartState, product],
  );

  const handleRatePlanClick = useCallback(
    (
      ratePlan: ExtendedSubscriptionRatePlan,
      tier?: Maybe<ExtendedSubscriptionTier>,
      selectedRatePlans?: Maybe<Array<Maybe<ExtendedSubscriptionRatePlan>>>,
      selectedTiers?: Maybe<Array<Maybe<ExtendedSubscriptionTier>>>,
      index?: Maybe<number>,
      availableProductStep?: Maybe<boolean>,
    ) => {
      const ratePlanHasTiers = ratePlan.tiers && ratePlan.tiers.length > 0;
      const tierExistsInCart = selectedTiers?.some(t => t?.description === tier?.description);
      const productExistsInCart = selectedRatePlans?.some(
        plan => plan?.productId === ratePlan.productId,
      );
      const ratePlanExistsInCart = selectedRatePlans?.some(
        plan => plan?.ratePlanId === ratePlan.ratePlanId && plan?.name === ratePlan.name,
      );

      // Sets selected index of plan to display border around plan
      if (!ratePlanExistsInCart) {
        setSelectedRatePlanIndex(availableProductStep && ratePlanExistsInCart ? -1 : index);
      }

      // Sets estimated price of selected plan and tier
      setSelectedRatePlanPrice(calculateEstimatedRatePlanPrice(ratePlan, tier));

      if (ratePlanHasTiers && !ratePlanExistsInCart) {
        const newTier = ensure(ratePlan.tiers?.find(t => t?.description === tier?.description));
        setSelectedRatePlanPrice(calculateEstimatedRatePlanPrice(ratePlan, newTier));

        if (ratePlan.isActive) {
          if (tierExistsInCart) {
            dispatchCartState?.({
              type: availableProductStep ? 'REMOVE_RATE_PLAN' : 'UPDATE_RATE_PLAN',
              payload: ratePlan,
            });

            dispatchCartState?.({
              type: 'UPDATE_SUBSCRIPTION_TIER',
              payload: newTier,
            });
          }

          if (!tierExistsInCart) {
            dispatchCartState?.({
              type: 'REMOVE_RATE_PLAN',
              payload: ratePlan,
            });
          }
        }

        if (!ratePlan.isActive) {
          if (productExistsInCart) {
            dispatchCartState?.({
              type:
                availableProductStep && ratePlanExistsInCart
                  ? 'REMOVE_RATE_PLAN'
                  : 'UPDATE_RATE_PLAN',
              payload: ratePlan,
            });

            dispatchCartState?.({
              type:
                tierExistsInCart && ratePlanExistsInCart
                  ? 'REMOVE_SUBSCRIPTION_TIER'
                  : 'UPDATE_SUBSCRIPTION_TIER',
              payload: newTier,
            });
            setDropdownValue(tierExistsInCart && ratePlanExistsInCart ? null : newTier);
            setSelectedRatePlanPrice(
              tierExistsInCart && ratePlanExistsInCart
                ? getInitialPlanPrice(product)
                : calculateEstimatedRatePlanPrice(ratePlan, newTier),
            );
          }

          if (!productExistsInCart) {
            dispatchCartState?.({
              type: 'UPDATE_SUBSCRIPTION_TIER',
              payload: newTier,
            });
            dispatchCartState?.({ type: 'ADD_RATE_PLAN', payload: ratePlan });
          }
        }
      }

      if (!ratePlanHasTiers) {
        if (ratePlan.isActive) {
          dispatchCartState?.({
            type: 'REMOVE_RATE_PLAN',
            payload: ratePlan,
          });
        }

        if (!ratePlan.isActive) {
          if (productExistsInCart) {
            dispatchCartState?.({
              type:
                availableProductStep && ratePlanExistsInCart
                  ? 'REMOVE_RATE_PLAN'
                  : 'UPDATE_RATE_PLAN',
              payload: ratePlan,
            });
          } else {
            dispatchCartState?.({ type: 'ADD_RATE_PLAN', payload: ratePlan });
          }
        }
      }
    },
    [dispatchCartState, product],
  );

  const determineIfSelectedHandler = (index: number) => {
    return index === selectedRatePlanIndex;
  };

  const getAccordionDescription = (product: ExtendedSubscriptionProduct) => {
    const activeTierQty = product?.ratePlans
      ?.find(plan => plan?.isActive)
      ?.tiers?.find(tier => tier?.isActive)
      ?.description?.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    if (product.slug === 'email_rackspace') {
      return `${activeTierQty} ${quantityLabel}`;
    }
    return activeTierQty;
  };

  return (
    <>
      <AccordionButton py={6} px={[6, null, 10]}>
        <AccordionTitle
          isExpanded={isExpanded}
          productName={product.name}
          price={selectedRatePlanPrice || productPrice}
          activeRatePlanName={activeRatePlan && activeRatePlan.name}
          includedInMembership={activeRatePlan && activeRatePlan.includedInMembership}
          availableProductStep={availableProductStep}
          numberOfRatePlans={product?.ratePlans?.length}
          isMobile={isMobile}
          isPackage={IS_PACKAGE}
          isSelected={
            !isEmpty(cartState?.selectedRatePlans) &&
            cartState?.selectedRatePlans?.some(item => item.productName === product.name)
          }
          activeTierDescription={hasTiers ? getAccordionDescription(product) : undefined}
        />
      </AccordionButton>
      <AccordionPanel>
        <RatePlanOptions
          product={product}
          ratePlanClickHandler={handleRatePlanClick}
          PackageComponent={SRatePlan}
          packageHeader={t('miop.subscriptions.accordionCopy')}
          determineIfDisabledHandler={determineIfRatePlanDisabled}
          hasTiers={hasTiers}
          determineIfSelectedHandler={determineIfSelectedHandler}
          selectedRatePlans={cartState?.selectedRatePlans}
          selectedTiers={cartState?.selectedTiers}
          tierChangeHandler={handleTierChange}
          dropdownValue={dropdownValue}
          dropdownOptions={dropdownOptions || []}
          availableProductStep={availableProductStep}
          isPackage={IS_PACKAGE}
          isClearable
          containerStyles={{
            justifyContent: 'flex-start',
            spacing: 4,
            mt: 4,
          }}
        />
      </AccordionPanel>
    </>
  );
};
