import React, { useRef } from 'react';
import {
  Center,
  Box,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  potion,
  Text,
  VStack,
  TableContainer,
  useMediaQuery,
  HStack,
  Stack,
  useBreakpointValue,
  Image,
} from '@companydotcom/potion';
import {
  PageDivider,
  AmexIcon,
  VisaIcon,
  MastercardIcon,
  FontAwesomeIcon,
} from '@companydotcom/ui';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { useSource } from '@companydotcom/providers';
import type { InvoicesSharedProps } from './invoices';
import { formatCheckoutPrice, getFriendlyACGDate } from '../../shared';
import { sortInvoiceLineItems } from '../utils/index';

export const InvoicesDetails: React.FC<InvoicesSharedProps> = props => {
  const { currentInvoice, currentDownloadUrl } = props;
  const componentRef = useRef(null);
  const handlePrint = () => {
    window.open(currentDownloadUrl?.downloadUrl);
  };

  const getPageMargins = () => {
    return `@page { margin: 4rem !important; }`;
  };

  const source = useSource();

  const [isMobile] = useMediaQuery('(max-width: 30em)');

  const IconMap = {
    amex: <AmexIcon boxSize={9} mr={[1, 3]} />,
    visa: <VisaIcon boxSize={9} mr={[1, 3]} />,
    mastercard: <MastercardIcon boxSize={9} mr={[1, 3]} />,
  };

  const headingSize = useBreakpointValue({ base: 'hs-m', sm: 'hs-xl' });
  const invoiceDateWithoutTime =
    currentInvoice?.invoiceDate?.split(' ')[0] || currentInvoice?.payments?.paidDate;
  return (
    <Center
      className="invoices__invoicesDetailsStep"
      flexDirection="column"
      bg={['transparent', 'white']}
      py={[0, 8, 12]}
      px={[0, 4]}
    >
      <Box width="full" maxW={908} ref={componentRef}>
        <style>{getPageMargins()}</style>
        <Box width="full" textAlign={['center', 'left']} mb={7}>
          {!isMobile ? (
            <>
              <HStack justifyContent="space-between" width="full">
                <Heading size={headingSize}>Invoice Details</Heading>
                <FontAwesomeIcon
                  icon={faPrint}
                  boxSize={8}
                  onClick={handlePrint}
                  cursor="pointer"
                  color="#0099CC"
                  id="printPageButton"
                />
                <Image
                  alt="acg logo"
                  src={
                    source.images?.logo ??
                    `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/${
                      source.sourceId ?? 'company'
                    }/images/logo.svg`
                  }
                  boxSize="150px"
                  display="none"
                  id="logo"
                />
              </HStack>
              <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
            </>
          ) : (
            <Box width="full" textAlign={['center', 'left']} mb={7}>
              <FontAwesomeIcon
                icon={faPrint}
                boxSize={4}
                onClick={handlePrint}
                cursor="pointer"
                color="#0099CC"
                id="printPageButton"
                float="right"
              />
              <Heading fontSize="18px">Invoice Details</Heading>
              <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
            </Box>
          )}
        </Box>

        <Flex mb={6} width="full" flexDirection={['column', 'row']} gap="20%">
          <HStack width="full" alignItems="start" justifyContent="space-between">
            <Stack direction={['column']} alignItems="flex-start" spacing={0}>
              <HStack>
                <Heading size={headingSize} opacity={isMobile ? 1 : 0.7}>
                  {isMobile ? '#: ' : 'Invoice #: '}
                </Heading>
                <Heading size={headingSize}>
                  <potion.span color="pricing">{currentInvoice?.invoiceNumber}</potion.span>
                </Heading>
              </HStack>
              <Box>
                <Text fontSize={['14px', '18px']} opacity={isMobile ? 1 : 0.7}>
                  {isMobile ? 'Date: ' : 'Invoice Date: '}{' '}
                  {getFriendlyACGDate(invoiceDateWithoutTime ?? '')}
                </Text>
              </Box>
            </Stack>
            <Stack mt={[4, 0]} direction={['column']} spacing={0}>
              <HStack>
                <Heading size={headingSize} opacity={isMobile ? 1 : 0.7}>
                  Invoice Total:{' '}
                </Heading>
                <Heading size={headingSize}>
                  <potion.span color="pricing">{`$${
                    formatCheckoutPrice(currentInvoice?.invoiceTotal) ||
                    currentInvoice?.invoiceTotal
                  }`}</potion.span>
                </Heading>
              </HStack>

              <Box align={isMobile ? 'right' : 'left'}>
                <Text fontSize={['14px', '18px']} fontWeight="bold">
                  Paid:{' '}
                  {`$${
                    formatCheckoutPrice(currentInvoice?.invoicePaid) || currentInvoice?.invoicePaid
                  }`}
                </Text>
                <Text fontSize={['14px', '18px']} opacity={isMobile ? 1 : 0.7}>
                  Due:{' '}
                  {`$${
                    formatCheckoutPrice(currentInvoice?.invoiceDues?.toString()) ||
                    currentInvoice?.invoiceDues
                  }`}
                </Text>
              </Box>
            </Stack>
          </HStack>
        </Flex>

        {!isMobile ? (
          <TableContainer>
            <Table
              sx={{
                tableLayout: 'fixed',
              }}
            >
              <Thead borderWidth="1px">
                <Tr>
                  <Th fontSize="lg" width={[150, null, null, '43.5%']}>
                    Product
                  </Th>
                  <Th fontSize="lg" width="14%" textAlign="right">
                    Quantity
                  </Th>
                  <Th fontSize="lg" width="12.5%" textAlign="right">
                    Price
                  </Th>
                  <Th fontSize="lg" width="14.5%" textAlign="right">
                    Discount
                  </Th>
                  <Th fontSize="lg" width="14%" textAlign="right">
                    Amount
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {sortInvoiceLineItems(currentInvoice, false).lineItem?.map(
                  (item: any, i: number) => {
                    return (
                      <Tr key={i}>
                        <Td whiteSpace="normal">{item.lineItemDisplayName}</Td>
                        <Td whiteSpace="normal" textAlign="right">
                          {parseInt(item.lineItemQuantity, 10)}
                        </Td>
                        <Td textAlign="right">
                          {`$${formatCheckoutPrice(item?.lineItemUnitPrice)}`}
                        </Td>
                        <Td
                          whiteSpace="normal"
                          textAlign="right"
                          textColor={item.lineItemDiscount == '0.0000' ? '#717174' : '#CC0033'}
                        >
                          {item.lineItemDiscount !== '0.0000'
                            ? `-$${formatCheckoutPrice(item.lineItemDiscount)}`
                            : `$${formatCheckoutPrice(item.lineItemDiscount)}`}
                        </Td>
                        <Td
                          whiteSpace="normal"
                          textAlign="right"
                          textColor={item.lineItemDiscount !== '0.0000' ? '#CC0033' : '#717174'}
                        >
                          {`$${(
                            parseFloat(formatCheckoutPrice(item.lineItemUnitPrice)) -
                            parseFloat(formatCheckoutPrice(item.lineItemDiscount))
                          ).toFixed(2)}`}
                        </Td>
                      </Tr>
                    );
                  },
                )}
                {currentInvoice?.taxes ? (
                  <Tr>
                    <Td whiteSpace="normal">Taxes</Td>
                    <Td />
                    <Td />
                    <Td />
                    <Td whiteSpace="normal" textAlign="right">
                      {`$${
                        currentInvoice?.taxes.amount === 0
                          ? currentInvoice?.taxes.amount.toFixed(2)
                          : currentInvoice?.taxes.amount
                      }`}
                    </Td>
                  </Tr>
                ) : null}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <>
            {sortInvoiceLineItems(currentInvoice, false).lineItem?.map(
              (lineItem: any, i: number) => {
                return (
                  <Box
                    key={i}
                    width="full"
                    borderRadius="sm"
                    shadow="md"
                    mb={2}
                    className="invoice"
                    bg="white"
                    p={4}
                  >
                    <VStack width="full" align="flex-start">
                      <HStack alignItems="flex-start">
                        <Text textStyle="sm" fontWeight="bold">
                          Product:
                        </Text>
                        <Text textStyle="sm">{lineItem.lineItemDisplayName}</Text>
                      </HStack>
                      <HStack width="full" justify="space-between">
                        <HStack>
                          <Text textStyle="sm" fontWeight="bold">
                            Quantity:
                          </Text>
                          <Text textStyle="sm">{parseInt(lineItem.lineItemQuantity, 10)}</Text>
                        </HStack>
                        <HStack>
                          <Text textStyle="sm" fontWeight="bold">
                            Price:
                          </Text>
                          <Text textStyle="sm">{`$${formatCheckoutPrice(
                            lineItem.lineItemUnitPrice,
                          )}`}</Text>
                        </HStack>
                      </HStack>
                      <HStack width="full" justify="space-between">
                        <HStack>
                          <Text textStyle="sm" fontWeight="bold">
                            Discount:
                          </Text>
                          <Text
                            textStyle="sm"
                            color={lineItem.lineItemDiscount !== '0.0000' ? '#CC0033' : '#717174'}
                          >
                            {lineItem.lineItemDiscount !== '0.0000'
                              ? `-$${formatCheckoutPrice(lineItem.lineItemDiscount)}`
                              : `$${formatCheckoutPrice(lineItem.lineItemDiscount)}`}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text textStyle="sm" fontWeight="bold">
                            Amount:
                          </Text>
                          <Text
                            textStyle="sm"
                            color={lineItem.lineItemDiscount !== '0.0000' ? '#CC0033' : '#717174'}
                          >
                            {`$${(
                              parseFloat(formatCheckoutPrice(lineItem.lineItemUnitPrice)) -
                              parseFloat(formatCheckoutPrice(lineItem.lineItemDiscount))
                            ).toFixed(2)}`}
                          </Text>
                        </HStack>
                      </HStack>
                    </VStack>
                  </Box>
                );
              },
            )}
          </>
        )}
        {!isMobile ? (
          <TableContainer>
            <Table
              sx={{
                tableLayout: 'fixed',
              }}
            >
              {!isMobile && currentInvoice?.payments && (
                <Thead borderWidth="1px">
                  <Tr>
                    <Th fontSize="lg" width="20%">
                      Date
                    </Th>
                    <Th fontSize="lg" width="45%">
                      Paid Amount
                    </Th>
                    <Th fontSize="lg" width="35%">
                      Method
                    </Th>
                  </Tr>
                </Thead>
              )}
              <Tbody>
                {currentInvoice?.payments?.flat().map((payment: any, i: number) => {
                  const dateWithoutTime = payment?.paidDate.split(' ')[0];
                  return (
                    <Tr key={i}>
                      <Td py={8} width="20%">
                        {getFriendlyACGDate(dateWithoutTime || payment?.paidDate)}
                      </Td>
                      <Td py={8} width="40%">{`$${
                        formatCheckoutPrice(payment?.paidAmount) || payment?.paidAmount
                      }`}</Td>
                      <Td py={8} color="black" fontWeight="bold" fontSize="sm" width="40%">
                        {payment.paymentMethod === 'credit card'
                          ? IconMap[payment.creditCardFormat.toLowerCase()]
                          : null}
                        {payment.paymentMethod === 'credit card'
                          ? `${payment.creditCardFormat.toUpperCase()} ending in ${
                              payment?.creditCardNumberDisplay?.slice(-4) ||
                              payment?.creditCardNumberDisplay
                            }`
                          : `ACH Payment ending in ${payment?.accountNumberDisplay?.slice(-4)}`}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Box borderRadius="sm" shadow="md" mb={2} className="invoice" bg="white" p={4}>
            <HStack pb={3}>
              <Text textStyle="sm" fontWeight="bold">
                Taxes:
              </Text>
              <Text textStyle="sm">
                {`$${
                  currentInvoice?.taxes?.amount === 0
                    ? currentInvoice?.taxes?.amount.toFixed(2)
                    : currentInvoice?.taxes?.amount
                }`}
              </Text>
            </HStack>
            {currentInvoice?.payments?.flat().map((payment: any, i: any) => {
              const dateWithoutTime = payment?.paidDate.split(' ')[0];
              return (
                <VStack key={i} width="full" align="flex-start" pb={4}>
                  <Text textStyle="sm">
                    Date: {getFriendlyACGDate(dateWithoutTime || payment?.paidDate)}
                  </Text>
                  <HStack>
                    <Text textStyle="sm" fontWeight="bold">
                      Paid Amount:
                    </Text>
                    <Text textStyle="sm">{`$${
                      formatCheckoutPrice(payment.paidAmount) || payment.paidAmount
                    }`}</Text>
                  </HStack>
                  <Text textStyle="sm" alignItems="center">
                    Method:{' '}
                    <potion.span>
                      {payment.paymentMethod === 'credit card'
                        ? IconMap[payment.creditCardFormat.toLowerCase()]
                        : null}
                    </potion.span>{' '}
                    {payment.paymentMethod === 'credit card'
                      ? `${payment.creditCardFormat.toUpperCase()} ending in ${
                          payment?.creditCardNumberDisplay?.slice(-4) ||
                          payment?.creditCardNumberDisplay
                        }`
                      : `ACH Payment ending in ${payment?.accountNumberDisplay?.slice(-4)}`}
                  </Text>
                </VStack>
              );
            })}
          </Box>
        )}
      </Box>
    </Center>
  );
};
