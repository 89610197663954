// @ts-nocheck

import React, { useState, useEffect } from 'react';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useTabStepper,
  Tabs,
  Text,
  TabPanels,
  TabPanel,
} from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
import { AppointmentResult } from './appointment-result';
import { ConfirmContactInfo } from './confirm-contact-info';
import { AppointmentSelectionForm } from './appointment-selection-form';
import type { TileComponentProps } from '../../types';
import { TileButton } from '../tile-button';

export interface TileAppointmentSchedulerProps extends TileComponentProps {}

export const TileAppointmentScheduler: React.FC<TileAppointmentSchedulerProps> = props => {
  const { tile, notifications, appointments, eventHandler, params, events } = props;
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [availability, setAvailability] = useState([]);
  const [existingScheduledNotif, setScheduledNotif] = useState();
  const [assignedAccountant, setAssignedAccountant] = useState();
  const [availabilityError, setAvailabilityError] = useState(false);
  const [existingAppointment, setExistingAppointment] = useState(
    appointments?.filter(appt => {
      return appt?.productId === tile?.productId && (appt?.appointmentTime as number) > Date.now();
    })?.[0],
  );

  useEffect(() => {
    setExistingAppointment(
      appointments?.filter(
        appt => appt.productId === tile?.productId && (appt.appointmentTime as number) > Date.now(),
      )[0],
    );
  }, [appointments, tile?.productId]);

  useEffect(() => {
    const availabilityNotif = notifications
      ?.filter(notif => notif?.body?.payload?.availability)
      .sort(({ dateCreated: a }: any, { dateCreated: b }: any) => b - a)?.[0];

    if (availabilityNotif) {
      setAvailabilityError(availabilityNotif?.body?.payload?.error || false);
      setAvailability(availabilityNotif?.body?.payload?.availability);
      setAssignedAccountant(availabilityNotif?.body?.payload?.assignedAccountant);
    }

    // look for handled scheduleAppointment notifs to watch for error only
    const scheduledNotif = notifications
      ?.filter(notif => notif?.body?.payload?.appointment)
      .sort(({ dateCreated: a }, { dateCreated: b }) => b - a)[0];
    if (
      !existingScheduledNotif ||
      scheduledNotif?.dateCreated > existingScheduledNotif.dateCreated
    ) {
      setScheduledNotif(scheduledNotif);
      if (
        scheduledNotif &&
        !scheduledNotif.body.payload.error &&
        scheduledNotif.body.payload.appointment
      ) {
        setExistingAppointment(scheduledNotif.body.payload.appointment);
      }
    }
  }, [notifications, existingScheduledNotif]);

  const submitUserChoice = async (selectedAppt: any) => {
    const submissionData = {
      appointmentTime: availability.find(
        dateTranslateMap => dateTranslateMap.utcTimestamp === selectedAppt,
      ),
      assignedAccountant,
    };

    eventHandler?.(props.pubEvents, { type: 'submit' }, submissionData);
  };

  const backupPhoneNumber = (() => {
    const rightParam = params?.find(param => param?.key === 'backupPhoneNumber');
    return rightParam ? rightParam.value : false;
  })();

  const childProps = {
    submitUserChoice,
    availability,
    availabilityError,
    backupPhoneNumber,
    onClose,
    eventHandler,
    events,
    existingScheduledNotif,
  };

  const options = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: 'America/New_York',
  };

  return (
    <>
      {existingAppointment && !existingAppointment?.error ? (
        <>
          <Text textStyle="md">{t('tiles.appointmentScheduler.upcomingAppointment')}</Text>
          <Text>
            {new Intl.DateTimeFormat('en', options).format(existingAppointment?.appointmentTime)}
          </Text>
        </>
      ) : (
        <TileButton onClick={onOpen}>
          {t('tiles.appointmentScheduler.scheduleAppointment')}
        </TileButton>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <AppointmentScheduler {...childProps} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const schedulerSteps = [
  { slug: 'appointment-selection', component: <AppointmentSelectionForm /> },
  { slug: 'confirm-contact-info', component: <ConfirmContactInfo /> },
  { slug: 'appointment-result', component: <AppointmentResult /> },
];

const AppointmentScheduler = (props: any) => {
  const [selectedApptTimestamp, setSelectedApptTimestamp] = useState();
  const { tabIndex, handleTabsChange, nextStep } = useTabStepper({
    steps: schedulerSteps,
    scrollOnStepChange: false,
  });

  return (
    <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
      <TabPanels>
        {schedulerSteps.map(step => (
          <TabPanel key={step.slug}>
            {React.cloneElement(step.component, {
              ...props,
              setSelectedApptTimestamp,
              selectedApptTimestamp,
              nextStep,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
