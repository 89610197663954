import React from 'react';
import { Button, ButtonProps, Icon } from '@companydotcom/potion';

export const CuiIcon = (props: ButtonProps) => (
  //  @ts-ignore
  <Button {...props} variant="unstyled">
    <Icon viewBox="0 0 626 619" boxSize={10} zIndex={0} transform="rotate(45deg) scale(-1,1)">
      <defs>
        <linearGradient id="prefix__b" x1="2%" x2="100%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FFF" />
          <stop offset="100%" stopColor="#FFF" />
        </linearGradient>
        <linearGradient id="prefix__a" x1="116.632%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#97DDD8" />
          <stop offset="100%" stopColor="#347E8F" />
        </linearGradient>
        <linearGradient id="prefix__c" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#97DDD8" />
          <stop offset="100%" stopColor="#347E8F" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(10 10)">
        <path
          fill="url(#prefix__b)"
          stroke="url(#prefix__a)"
          strokeWidth="20"
          d="M302.51 0c167.07 0 302.51 133.89 302.51 299.06 0 71.65-27.5 120.7-61 173.46-6.31 9.94 6.77 73.19 33.38 103.52 17.16 19.57-107.09-27.2-119.27-20.48-52.41 28.94-88.84 42.56-155.62 42.56C135.44 598.12 0 464.23 0 299.06 0 133.89 135.44 0 302.51 0z"
        />
        <circle cx="174.72" cy="308.2" r="88.5" fill="url(#prefix__c)" fillRule="nonzero" />
        <circle cx="430.3" cy="308.2" r="88.5" fill="url(#prefix__c)" fillRule="nonzero" />
        <path
          fill="#FFF"
          d="M227.077 274.2c-2.904 4.864-8.124 7.907-13.82 8.057-8.888 0-15.479-7.203-15.861-15.735-.074-5.309 2.66-10.267 7.207-13.067-27.859-15.217-62.781-7.448-81.423 18.113-18.642 25.561-15.157 60.898 8.125 82.39 23.283 21.49 59.061 22.397 83.419 2.112 24.358-20.285 29.656-55.4 12.353-81.87z"
        />
        <path fill="#084066" d="M358.72 370.68L303.7 468.86 247.49 370.68 303.11 315.06z" />
        <path
          fill="#DEA62F"
          d="M299.84 205.76L171.97 80.41 44.31 205.68 86.98 247.56 171.97 164.16 257.17 247.63zM561.1 205.76L433.23 80.41 305.57 205.68 348.24 247.56 433.23 164.16 518.43 247.63z"
        />
        <circle cx="174.72" cy="308.2" r="39.5" fill="#084066" fillRule="nonzero" />
        <path
          fill="#FFF"
          d="M482.672 274.163c-2.883 4.863-8.06 7.906-13.71 8.058-8.828 0-15.356-7.203-15.736-15.735-.073-5.309 2.64-10.267 7.15-13.067-27.653-15.182-62.286-7.375-80.75 18.203-18.465 25.579-14.973 60.908 8.143 82.375 23.116 21.468 58.607 22.343 82.752 2.04 24.146-20.303 29.375-55.417 12.193-81.874h-.042z"
        />
        <circle cx="430.3" cy="308.2" r="39.5" fill="#084066" fillRule="nonzero" />
        <ellipse cx="192.22" cy="292.2" fill="#FFF" fillRule="nonzero" rx="12" ry="11.5" />
        <ellipse cx="450.8" cy="292.2" fill="#FFF" fillRule="nonzero" rx="12" ry="11.5" />
      </g>
    </Icon>
  </Button>
);
