import React from 'react';
import {
  useMultiStyleConfig,
  useStyles,
  StylesProvider,
  IconButton,
  Icon,
  Flex,
  Image,
  SystemStyleObject,
  BoxProps,
  Box,
  ImageProps,
  FlexProps,
  IconButtonProps,
  VStack,
  Text,
  Link,
  StackProps,
} from '@companydotcom/potion';
import { useSource } from '@companydotcom/providers';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { NavLinks, Maybe } from '@companydotcom/types';
import { Link as ReactRouterLink } from 'react-router-dom';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { platformHelpers } from '@companydotcom/helpers';
import { Profile } from '../../profile';
import { NavGroup, NavGroupHeader, NavGroupLoader } from './nav-group';
import { NavLink } from './nav-link';
import { SideNavProvider, SideNavContext, useSideNavContext } from './use-side-nav';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { useGetAcgProfileImage } from '../../../../features/acg/shared';

export const SideNav: React.FC<SideNavContext> = props => {
  const { onSideNavToggle, isSideNavOpen, isLoading } = props;
  const styles = useMultiStyleConfig('SideNav', props);
  const { data: globalUser } = useGetGlobalUserQuery();
  const source = useSource();

  const { acgAvatarUrl } = useGetAcgProfileImage();

  const ctx = React.useMemo(
    () => ({ onSideNavToggle, isSideNavOpen, isLoading }),
    [onSideNavToggle, isSideNavOpen, isLoading],
  );

  return (
    <SideNavProvider value={ctx}>
      <StylesProvider value={styles}>
        <SideNavContainer>
          <SideNavCloseButton
            display={['none', null, 'inherit']}
            onClick={onSideNavToggle}
            aria-label="Close Sidenav"
          />
          <SideNavBlob />
          <SideNavHeader>
            <SideNavLogo
              display={['none', null, 'inherit']}
              visibility={isSideNavOpen ? 'visible' : 'hidden'}
              alt={`${source.sourceId} Logo`}
              src={
                source.images?.sidenav_logo ??
                `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/${
                  source.sourceId ?? 'company'
                }/images/logo.svg`
              }
              marginLeft={isSideNavOpen ? '52px' : 4}
            />
            <Profile
              isLoading={isLoading}
              isSideNavOpen={isSideNavOpen}
              name={
                !globalUser?.firstName
                  ? undefined
                  : `${globalUser?.firstName} ${globalUser?.lastName}`
              }
              businessName={`${globalUser?.account?.businessPrimary?.name}`}
              avatarUrl={source?.sourceId === 'acg' ? acgAvatarUrl : globalUser?.avatar}
            />
          </SideNavHeader>
          <SideNavBody>
            {globalUser?.role && source?.navConfig ? (
              <>
                {source?.navConfig?.navGroups?.map((navGroup, i) => (
                  <NavGroup key={i}>
                    <NavGroupHeader isLoading={isLoading}>{navGroup?.title}</NavGroupHeader>
                    <VStack spacing={0}>
                      {navGroup?.links &&
                        globalUser?.role &&
                        platformHelpers
                          .filterChildUserNavLinks(navGroup?.links, globalUser?.role as string)
                          ?.map((link, i) => {
                            return (
                              link?.path && (
                                <NavLink
                                  key={i}
                                  {...link}
                                  isLoading={isLoading}
                                  isSideNavOpen={isSideNavOpen}
                                />
                              )
                            );
                          })}
                    </VStack>
                  </NavGroup>
                ))}
                {source?.navConfig?.navCopy && isSideNavOpen && (
                  <SideNavCopy textBlocks={source?.navConfig?.navCopy} />
                )}
              </>
            ) : (
              <NavGroupLoader />
            )}
          </SideNavBody>
          <SideNavFooter />
        </SideNavContainer>
      </StylesProvider>
    </SideNavProvider>
  );
};

export const SideNavContainer: React.FC<BoxProps> = props => {
  const styles = useStyles();
  const containerStyles: SystemStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    height: '100vh',
    width: '100%',
    outline: 0,
    paddingBottom: '3rem',
    ...styles.container,
  };

  return <Box className="sidenav__container" __css={containerStyles} {...props} />;
};

export const SideNavHeader: React.FC<BoxProps> = props => {
  const styles = useStyles();

  return <Box as="header" className="sidenav__header" {...props} sx={styles.header} />;
};

export interface SideNavCopyProps extends StackProps {
  textBlocks: Maybe<NavLinks>[];
}

export const SideNavCopy = (props: SideNavCopyProps) => {
  const { textBlocks } = props;
  const styles = useStyles();

  return (
    // @ts-ignore
    <VStack pl={styles?.navLink?.['pl'] ?? 7} {...styles?.copyWrapper}>
      {textBlocks?.map((copy, i) => (
        <Flex key={i} flexDirection="column">
          {copy?.title && <Text sx={styles.copyTitle}>{copy?.title}</Text>}
          {copy?.links &&
            copy.links.map((link, x) =>
              link?.label && link.isExternal ? (
                <Link key={x} href={link.path ?? ''} isExternal sx={styles.copyLink}>
                  {link?.label}
                </Link>
              ) : (
                <ReactRouterLink key={x} to={link?.path ?? ''}>
                  {link?.label}
                </ReactRouterLink>
              ),
            )}
        </Flex>
      ))}
    </VStack>
  );
};

export const SideNavLogo: React.FC<ImageProps> = props => {
  const styles = useStyles();
  const logoStyles: SystemStyleObject = {
    ...styles.logo,
  };

  return (
    <Image
      className="sidenav__logo"
      {...props}
      loading="eager"
      fallback={<Box boxSize={[6, null, 9]} />}
      sx={logoStyles}
    />
  );
};

export const SideNavBody: React.FC<BoxProps> = props => {
  const styles = useStyles();

  return <Box as="section" className="sidenav__body" {...props} sx={styles.body} />;
};

export const SideNavFooter: React.FC<FlexProps> = props => {
  const styles = useStyles();

  return (
    <Flex
      align="center"
      justify="flex-start"
      className="sidenav__footer"
      {...props}
      sx={styles.footer}
    />
  );
};

export const SideNavCloseButton: React.FC<IconButtonProps> = props => {
  const styles = useStyles();
  const { isSideNavOpen, isLoading } = useSideNavContext();

  const closeButtonContainerStyles = {
    position: 'absolute',
    top: 0,
    insetEnd: 0,
    zIndex: 2,
    ...styles.closeButton,
  };

  return (
    <IconButton
      isDisabled={isLoading}
      icon={
        <Icon
          as={FontAwesomeIcon}
          icon={isSideNavOpen ? faChevronLeft : faChevronRight}
          boxSize={5}
        />
      }
      className="sidenav__close-btn"
      width="40px"
      height="36px"
      {...props}
      sx={closeButtonContainerStyles}
    />
  );
};

// Blob styles generated from here: https://codepen.io/jacobgranberry/pen/qBVqdGJ
export const SideNavBlob: React.FC<BoxProps> = props => {
  const styles = useStyles();

  const blobImageStyles: SystemStyleObject = {
    position: 'absolute',
    left: -1,
    top: 0,
    zIndex: 1,
    ...styles.blob,
  };

  return <Box className="sidenav__blob" {...props} sx={blobImageStyles} />;
};
