import React from 'react';
import { Center, Box, Button, Heading } from '@companydotcom/potion';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { NewFlag } from '../../dashboard-tile';

const TileBase = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="240" height="289">
    <defs>
      <filter
        id="a"
        width="107.4%"
        height="106.1%"
        x="-3.7%"
        y="-2.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.417207239 0 0 0 0 0.493391304 0 0 0 0 0.588621386 0 0 0 0.141077899 0"
        />
      </filter>
    </defs>
    <g fill="none">
      <g transform="translate(5 2.8)">
        <mask id="b" fill="#fff">
          <use />
        </mask>
        <use fill="#000" filter="url(#a)" />
        <path
          fill="#FFF"
          stroke="#F0F0F0"
          d="M.32 2.562v273.916a2.247 2.247 0 002.243 2.242h224.874a2.248 2.248 0 002.243-2.242V2.562A2.247 2.247 0 00227.437.32H2.563A2.248 2.248 0 00.32 2.562z"
        />
        <g mask="url(#b)">
          <g transform="translate(217.222 4.48)">
            <path
              stroke="#FFF"
              d="M3.514.512c-.826 0-1.496.67-1.496 1.496v2.72a1.496 1.496 0 002.992 0v-2.72c0-.826-.67-1.496-1.496-1.496z"
            />
            <rect width="7.028" height="6.735" y="4.145" fill="#FFF" rx=".737" />
            <ellipse cx="4.016" cy="6.476" fill="#FFF" stroke="#FFF" rx="1.004" ry="1" />
            <path
              fill="#FFF"
              stroke="#FFF"
              d="M4.016 6.586a.133.133 0 00-.133.133v1.587a.133.133 0 10.266 0V6.719a.133.133 0 00-.133-.133z"
            />
          </g>
        </g>
      </g>
      <rect
        width="107.97"
        height="24.32"
        x=".64"
        y=".64"
        fill="#dea62f"
        stroke="#dea62f"
        rx="12.16"
        transform="translate(65.056 205.04)"
      />
    </g>
  </svg>
);

const Checkmark = ({ isChecked }: { isChecked: boolean }) => {
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  const tickVariants = {
    pressed: () => ({ pathLength: isChecked ? 0.85 : 0.2 }),
    checked: { pathLength: 1 },
    unchecked: { pathLength: 0 },
  };
  return (
    <motion.svg
      viewBox="0 0 53 53"
      xmlns="http://www.w3.org/2000/svg"
      width="53"
      height="53"
      initial={false}
      animate={isChecked ? 'checked' : 'unchecked'}
      style={{
        position: 'absolute',
        top: -24,
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <g transform="translate(1.919 1.919)" fill="none" fillRule="evenodd" stroke="#3c95ba">
        <circle cx="24.581" cy="24.581" r="24.581" fill="#FFF" strokeWidth="2" />

        <motion.path
          d="m16.158 26.97 6.921 5.498m9.926-13.308-9.926 13.308"
          strokeWidth="4.135"
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={tickVariants}
          style={{ pathLength, opacity }}
          custom={isChecked}
        />
      </g>
    </motion.svg>
  );
};

const TileLoader = ({ animate }: { animate?: boolean }) => (
  <ContentLoader
    speed={1}
    animate={animate}
    viewBox="0 0 202 106"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{
      width: 202,
      height: 106,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 40,
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    <rect x="52" y="1" rx="3" ry="3" width="94" height="23" />
    <rect x="1" y="38" rx="3" ry="3" width="202" height="18" />
    <rect x="12" y="62" rx="0" ry="0" width="180" height="18" />
    <rect x="32" y="86" rx="0" ry="0" width="140" height="18" />
  </ContentLoader>
);

export interface BuildingTileLoaderProps {
  productName?: string;
  onClose?: () => void;
  isLoading?: boolean;
}

export default BuildingTileLoader;
export const BuildingTileLoader = ({
  productName,
  onClose,
  isLoading,
}: BuildingTileLoaderProps) => {
  const { t } = useTranslation();

  return (
    <Center flexDirection="column">
      <Heading size="hs-2xl" mb={4}>
        {isLoading ? 'Building Your New Tile…' : 'New Product Added Successfully!'}
      </Heading>
      {!isLoading && (
        <Heading size="hs-md" mb={2}>
          {t('forms.byop.tileLoaderHeading', { productName })}
        </Heading>
      )}
      <Box
        mx="auto"
        my={14}
        sx={{ position: 'relative' }}
        mt={isLoading ? '76px' : 10}
        width="fit-content"
      >
        {!isLoading && <NewFlag />}
        <Checkmark isChecked={!isLoading} />
        <TileLoader animate={isLoading} />
        <TileBase />
      </Box>
      {!isLoading && (
        <Box>
          <Button size="lg" variant="outline" onClick={onClose}>
            {t('forms.byop.tileLoaderButton')}
          </Button>
        </Box>
      )}
    </Center>
  );
};
