import * as React from 'react';
import { FormControlOptions, useFormControl } from '../form-control';
import {
  potion,
  forwardRef,
  omitThemingProps,
  ThemingProps,
  useStyleConfig,
  HTMLPotionProps,
} from '../../system';
import { SystemStyleObject } from '../../styled-system';
import { cx, omit, __DEV__ } from '../../utils';

interface TextareaOptions {
  /**
   * The border color when the textarea is focused. Use color keys in `theme?.colors?`
   * @example
   * focusBorderColor = "blue.500"
   */
  focusBorderColor?: string;
  /**
   * The border color when the textarea is invalid. Use color keys in `theme?.colors?`
   * @example
   * errorBorderColor = "red.500"
   */
  errorBorderColor?: string;
  /**
   * If `true`, the textarea element will span the full width of its parent
   *
   * @deprecated
   * This component defaults to 100% width,
   * please use the props `maxWidth` or `width` to configure
   */
  isFullWidth?: boolean;
}

type Omitted = 'disabled' | 'required' | 'readOnly';
export interface TextareaProps
  extends Omit<HTMLPotionProps<'textarea'>, Omitted>,
    TextareaOptions,
    FormControlOptions,
    ThemingProps<'Textarea'> {}

/**
 * Textarea is used to enter an amount of text that's longer than a single line
 */
export const Textarea = forwardRef<TextareaProps, 'textarea'>((props, ref) => {
  const styles = useStyleConfig('Textarea', props);
  const { className, rows, ...rest } = omitThemingProps(props);

  const textareaProps = useFormControl<HTMLTextAreaElement>(rest);

  const omitted = ['h', 'minH', 'height', 'minHeight'] as (keyof SystemStyleObject)[];

  const textareaStyles = rows ? omit(styles, omitted) : styles;

  return (
    <potion.textarea
      ref={ref}
      rows={rows}
      {...textareaProps}
      className={cx('potion-textarea', className)}
      __css={textareaStyles}
    />
  );
});

if (__DEV__) {
  Textarea.displayName = 'Textarea';
}
