import gql from 'graphql-tag';

export const GET_MEMBERSHIPS = gql`
  query GetMemberships(
    $accessToken: String = ""
    $contactId: String = ""
    $stateCurrent: String = ""
    $userId: String = ""
  ) {
    getMemberships(
      accessToken: $accessToken
      contactId: $contactId
      stateCurrent: $stateCurrent
      userId: $userId
    ) {
      memberships {
        recordKey
        autoRenew
        expireDate
        renewDate
        chapterName
        joinDate
        status
        invoiceRecordKey
        openInvoices {
          invoiceRecordKey
        }
        renewalPaymentInfo {
          cardType
          paymentOrigin
          merchantAccountTokens {
            merchantAccountId
            merchantToken
          }
        }
      }
      status
      totalMembershipsFound
      hiddenFieldsFromMembershipDirectory
    }
  }
`;

export const GET_MEMBER_COMMITTEES = gql`
  query getMemberCommittees($accessToken: String = "", $contactId: String = "") {
    getMemberCommittees(accessToken: $accessToken, contactId: $contactId) {
      totalCommitteesFound
      committees {
        recordKey
        committeeRecordKey
        name
        position
        startDate
        endDate
        rank
        association
        type
        delete
        showOnline
        hasWorkspace
      }
    }
  }
`;

export const GET_MAGAZINES_FEED = gql`
  query GetMagazinesFeed {
    getMagazinesFeed {
      imageUrl
      href
      text
      data {
        categories
        content
        contentSnippet
        creator
        guid
        isoDate
        link
        pubDate
        title
      }
    }
  }
`;

export const GET_GROWTH_TV_FEED = gql`
  query GetGrowthTvFeed {
    getGrowthTvFeed {
      imageUrl
      href
      text
      data {
        categories
        content
        contentSnippet
        creator
        guid
        isoDate
        link
        pubDate
        title
      }
    }
  }
`;

export const GET_NEWS_TRENDS_FEED = gql`
  query GetNewsTrendsFeed {
    getNewsTrendsFeed {
      imageUrl
      href
      text
      data {
        categories
        content
        contentSnippet
        creator
        guid
        isoDate
        link
        pubDate
        title
      }
    }
  }
`;

export const GET_RECOMMENDED_EVENTS = gql`
  query GetRecommendedEvents($residentState: String!, $chapterNames: [String]) {
    getRecommendedEvents(residentState: $residentState, chapterNames: $chapterNames) {
      chapter {
        locationName
        locationEvents {
          chapterId
          chapterName
          endDate
          eventId
          isFeatured
          name
          netForumEventId
          startDate
          timezone
          url
        }
      }
      featured {
        chapterId
        chapterName
        endDate
        eventId
        isFeatured
        name
        netForumEventId
        startDate
        timezone
        url
      }
      region {
        locationName
        locationEvents {
          chapterId
          chapterName
          endDate
          eventId
          isFeatured
          name
          netForumEventId
          startDate
          timezone
          url
        }
      }
    }
  }
`;

export const GET_USER_PURCHASED_EVENTS = gql`
  query GetUserPurchasedEvents($accessToken: String = "", $contactId: String = "") {
    getUserPurchasedEvents(accessToken: $accessToken, contactId: $contactId) {
      eventEndDate
      eventId
      eventLocation
      eventLocationPlace
      eventName
      eventStartDate
      eventTypeKey
      eventTypeName
      hasWaitList
      id
      listRegistrantsOnWeb
      onWaitList
      registrationInvoice
      eventTimezone
      eventTimezoneAbbreviation
      eventTimezoneMode
      eventTimezoneOffset
    }
  }
`;

export const GET_ACG_REFERENCE_DATA = gql`
  query GetAcgReferenceData($accessToken: String = "", $referenceData: [String]!) {
    getAcgReferenceData(accessToken: $accessToken, referenceData: $referenceData) {
      areaOfExpertise {
        recordName
        abbreviation
      }
      marketArea {
        recordName
        abbreviation
      }
      industry {
        recordName
        abbreviation
      }
      reference {
        recordName
        abbreviation
      }
      transactionType {
        recordName
        abbreviation
      }
      individualType {
        recordName
        abbreviation
      }
      suffix {
        recordName
        abbreviation
      }
      prefix {
        recordName
        abbreviation
      }
      country {
        recordName
        abbreviation
      }
      referer {
        recordName
        abbreviation
      }
      phoneType {
        recordName
        abbreviation
      }
      addressType {
        recordName
        abbreviation
      }
      membershipReferenceData
      eventsReferenceData
      message
    }
  }
`;

export const GET_ALL_CHAPTERS_AND_PACKAGES = gql`
  query GetAllAcgChaptersAndPackagesResponse {
    getAllChaptersAndPackages {
      packageMembershipType
      chapters {
        chapterDescription
        chapterId
        chapterName
        currencyCode
        duesProducts {
          chapterInternationalDues
          chapterTypeId
          chapterTypeName
          joinProcessId
          membershipTypeId
          notes
          packageBusinessUnitId
          packageId
          packageName
          price
          productId
          productName
        }
        updatesWhen
      }
    }
  }
`;

export const GET_INVOICES_BY_USER = gql`
  query GetInvoicesByUser($accessToken: String = "", $contactId: String = "") {
    getInvoicesByUser(accessToken: $accessToken, contactId: $contactId) {
      invoices {
        invoiceKey
        invoiceNumber
        invoiceDate
        invoiceTotal
        invoiceDues
        invoicePaid
        payments {
          paidAmount
          paidDate
          paymentKey
          creditCardNumberDisplay
          paymentType
          paymentMethod
          creditCardFormat
        }
        taxes {
          amount
        }
        lineItem {
          lineItemKey
          lineItemType
          lineItemDisplayName
          lineItemUnitPrice
          lineItemQuantity
          lineItemTotalWithoutDiscount
          lineItemDiscount
          lineItemAmount
          lineItemPaidAmount
          lineItemBalance
          lineItemPaidFlag
        }
      }
    }
  }
`;

export const GET_ACG_USER_PROFILE = gql`
  query GetAcgUserProfile(
    $accessToken: String!
    $contactId: String
    $accountId: String
    $email: String
    $userId: String
  ) {
    getAcgUserProfile(
      accessToken: $accessToken
      contactId: $contactId
      accountId: $accountId
      email: $email
      userId: $userId
    ) {
      ... on AcgUserProfile {
        contactId
        areaOfExpertise
        bio
        designation
        dob
        emailAddress
        emailAddressSecondary
        ethnicity
        firstName
        gender
        individualType
        industry
        isRegistered
        lastName
        lastProfileUpdateVersion
        linkedInName
        marketArea
        middleName
        mobilePhone
        mobilePhoneExtension
        organization
        preferredAddressType
        preferredPhoneType
        prefix
        profilePicture
        reference
        suffix
        title
        transactionType
        twitterName
        website
        workPhone
        workPhoneExtension
        addressLine1
        addressLine2
        addressLine3
        city
        country
        postalCode
        state
        homeAddress {
          addressLine1
          addressLine2
          addressLine3
          city
          country
          postalCode
          state
        }
        workAddress {
          addressLine1
          addressLine2
          addressLine3
          city
          country
          postalCode
          state
        }
      }
      ... on AcgUserProfileFail {
        email
        isRegistered
      }
    }
  }
`;

export const GET_ACG_MEMBER_DIRECTORY = gql`
  query GetAcgMemberDirectory($searchParams: AcgMemberDirectorySearchInput) {
    getMemberDirectory(searchParams: $searchParams) {
      members {
        chapterIds
        contactId
        areaOfExpertise
        bio
        designation
        dob
        emailAddress
        emailAddressSecondary
        ethnicity
        firstName
        gender
        imageUrl
        individualType
        industry
        isRegistered
        lastName
        lastProfileUpdateVersion
        linkedInName
        marketArea
        middleName
        mobilePhone
        mobilePhoneExtension
        organization
        phone
        preferredAddressType
        preferredPhoneType
        prefix
        profilePicture
        recordKey
        reference
        suffix
        title
        transactionType
        twitterName
        website
        workPhone
        workPhoneExtension
        addressLine1
        addressLine2
        addressLine3
        city
        country
        postalCode
        state
        homeAddress {
          addressLine1
          addressLine2
          addressLine3
          city
          country
          postalCode
          state
        }
        workAddress {
          addressLine1
          addressLine2
          addressLine3
          city
          country
          postalCode
          state
        }
      }
      totalNumberOfRows
    }
  }
`;

export const queries = {
  GET_MEMBERSHIPS,
  GET_GROWTH_TV_FEED,
  GET_MAGAZINES_FEED,
  GET_NEWS_TRENDS_FEED,
  GET_RECOMMENDED_EVENTS,
  GET_USER_PURCHASED_EVENTS,
  GET_MEMBER_COMMITTEES,
  GET_ACG_REFERENCE_DATA,
  GET_ALL_CHAPTERS_AND_PACKAGES,
  GET_INVOICES_BY_USER,
  GET_ACG_USER_PROFILE,
  GET_ACG_MEMBER_DIRECTORY,
};
