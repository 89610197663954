import { useStyleConfig, Button, Box } from '@companydotcom/potion';
import { FileInput } from '@uppy/react';
import { FileInputProps } from '@uppy/react/src/FileInput';

export interface FileInputButtonProps extends FileInputProps {
  variant?: string;
  size?: string;
  colorScheme?: string;
}

export const FileInputButton = (props: FileInputButtonProps) => {
  const { uppy, variant, size, colorScheme, ...rest } = props;
  const styles = useStyleConfig('Button', { variant, size, colorScheme });

  return (
    <Box
      sx={{
        '.uppy-FileInput-btn': {
          ...styles,
        },
      }}
    >
      <Button as={FileInput} uppy={uppy} sx={styles} {...rest} />
    </Box>
  );
};
