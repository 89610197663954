const parts = ['requiredIndicator', 'helperText'];

const baseStyleRequiredIndicator = {
  ml: 1,
  color: 'red.500',
};

const baseStyleHelperText = {
  mt: 2,
  color: 'gray.500',
  lineHeight: 'normal',
  fontSize: 'sm',
  textAlign: 'left',
};

const baseStyle = {
  requiredIndicator: baseStyleRequiredIndicator,
  helperText: baseStyleHelperText,
};

export default {
  parts,
  baseStyle,
};
