export const parseUserProducts = (productsInput: any) => {
  if (typeof productsInput === 'string') {
    try {
      return JSON.parse(productsInput);
    } catch (error) {
      console.error(error);
      return productsInput
        .substring(2, productsInput.length - 2)
        .replace(/=/g, ': ')
        .split('}, {')
        .map(p =>
          p.split(', ').reduce((obj, str) => {
            const [key, value] = str.split(': ');
            return { ...obj, [key]: value };
          }, {}),
        );
    }
  }
  return productsInput;
};
