import React from 'react';
import { Text, Heading, Link, Center, Box, Button } from '@companydotcom/potion';
import { NavLink } from 'react-router-dom';
import { CheckCircleIcon } from '@companydotcom/ui';

export const GmbDuplicate = ({
  setFirstTimeTileFlow,
}: {
  setFirstTimeTileFlow?: (arg: boolean) => void;
}) => {
  return (
    <Center flexDirection="column" pb={[8, 12]} px={4}>
      <Box width="full" maxWidth="xl" mx="auto" textAlign="center">
        <CheckCircleIcon mb={[4]} />
        <Heading as="h1" size="hs-xl">
          Your Profile Was Created Successfully!
        </Heading>
        <Text textStyle="lg" mt={4} mb={[5]}>
          Your business listing was created in the Company.com Directory where you can manage the
          listings any time for free from your Business Listings Profile tile.
        </Text>
        <Text textStyle="md">
          If you would like to connect your existing Google My Business listing to your Business
          Listings Profile, please{' '}
          <Link as={NavLink} to="/help">
            contact support
          </Link>{' '}
          and they will help you with the process.
        </Text>
        <Box mt={[14]}>
          <Button size="lg" onClick={() => setFirstTimeTileFlow?.(false)}>
            See My Business Listings Profile
          </Button>
        </Box>
      </Box>
    </Center>
  );
};
