import { useEffect } from 'react';
import { useSource } from '@companydotcom/providers';
import * as Sentry from '@sentry/react';
import { useAuth } from '../../../providers';

export const Logout = () => {
  const auth0 = useAuth();
  const source = useSource();

  useEffect(() => {
    Sentry.setUser(null);
    auth0?.logout(source?.sourceId);
  }, [auth0, source?.sourceId]);

  return null;
};
