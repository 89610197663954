import React from 'react';
import { AppSpinner } from '@companydotcom/ui';
import { useSource } from '@companydotcom/providers';
import { AcgRedirectZone } from '../../features/acg';

/**
 * @description Should route the user to the appropriate page based on the query params
 * This component should only care if the current user is currently logged in or not
 * This component should have no side effects.
 * @returns
 */
export const Redirect = () => {
  const source = useSource();

  if (source?.sourceId === 'acg') {
    return <AcgRedirectZone />;
  }

  return (
    <AppSpinner
      className="redirect__spinner"
      containerStyles={{
        minH: '100vh',
        flexGrow: 1,
      }}
    />
  );
};
