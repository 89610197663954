import React, { useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMitt } from '@companydotcom/providers';
import { companyConstants } from '@companydotcom/helpers';
import { Center, useBoolean } from '@companydotcom/potion';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AppSpinner } from '@companydotcom/ui';
import { useAuth } from '../../../../providers';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import { AcgNewUserRegistrationFlow } from '../new-user-registration';
import { AcgExistingUserRegistration } from '../existing-user-registration';
import { hasCompletedPersonalInformation, hasCompletedAddressInformation } from '../utils';
import { useAcgCartStorage, useAcgRedirect } from '../../shared';
import {
  useGetAcgUserProfileV2Query,
  useGetAcgQueryArgs,
} from '../../../../services/acg/acg-api-v2';

export const AcgRegistration = () => {
  const [isBecomingMember, setIsBecomingMember] = useBoolean(false);
  const auth0 = useAuth();
  const { getLocalCart } = useAcgCartStorage();
  const { search } = useLocation();
  const { redirect } = useAcgRedirect();
  const { emitter } = useMitt();
  const navigate = useNavigate();
  const cart = getLocalCart();
  const args = useGetAcgQueryArgs();
  const { data: globalUser, isLoading: isUserLoading } = useGetGlobalUserQuery();
  const { data: acgUser, isLoading: isAcgUserLoading } = useGetAcgUserProfileV2Query(
    globalUser
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const completedPersonalInformation = useMemo(
    () => hasCompletedPersonalInformation(acgUser),
    [acgUser],
  );

  const completedAddressInformation = useMemo(
    () => hasCompletedAddressInformation(acgUser),
    [acgUser],
  );

  // Determines if user should be on this route or not
  useEffect(() => {
    if (
      !cart?.items?.memberships?.length &&
      !isUserLoading &&
      !isAcgUserLoading &&
      completedPersonalInformation &&
      completedAddressInformation &&
      !isBecomingMember &&
      auth0?.user?.['http://rhythmsoftware.com/contact_id']
    ) {
      if (globalUser?.userDataCollectors?.length) {
        emitter.emit(companyConstants.platformEvents.dataCollectorComplete, {
          path: redirect,
          search,
        });
      } else {
        navigate({ pathname: redirect, search });
      }
    }
  }, [
    acgUser?.isRegistered,
    auth0?.user,
    cart?.items?.memberships?.length,
    completedAddressInformation,
    completedPersonalInformation,
    emitter,
    globalUser?.userDataCollectors?.length,
    isAcgUserLoading,
    isBecomingMember,
    isUserLoading,
    navigate,
    redirect,
    search,
  ]);

  // Routes a new member to shopping cart or checkout
  useEffect(() => {
    if (
      cart?.items?.memberships?.length &&
      !isUserLoading &&
      !isAcgUserLoading &&
      auth0?.user?.['http://rhythmsoftware.com/contact_id'] &&
      completedPersonalInformation &&
      completedAddressInformation
    ) {
      if (globalUser?.userDataCollectors?.length) {
        if (cart?.selectedPackage?.packageMembershipType === 'EU Global') {
          emitter.emit(companyConstants.platformEvents.dataCollectorComplete, {
            path: '/checkout',
          });
        } else {
          emitter.emit(companyConstants.platformEvents.dataCollectorComplete, {
            path: '/acg-shopping-cart',
          });
        }
      } else {
        navigate('/acg-shopping-cart');
      }
    }
  }, [
    auth0?.user,
    cart?.items?.memberships?.length,
    cart?.selectedPackage?.packageMembershipType,
    completedAddressInformation,
    completedPersonalInformation,
    emitter,
    globalUser?.userDataCollectors?.length,
    isAcgUserLoading,
    isUserLoading,
    navigate,
  ]);

  // Detects if existing registration
  if (
    !isUserLoading &&
    !isAcgUserLoading &&
    (!completedPersonalInformation || !completedAddressInformation) &&
    globalUser?.mads?.['user-acg'].find?.((i: any) => i?.key === 'preExistingNetForumUser')
      ?.value === true
  ) {
    return (
      <AcgExistingUserRegistration
        defaultStep={
          completedPersonalInformation && !completedAddressInformation
            ? 'address-information'
            : 'personal-information'
        }
      />
    );
  }

  // Determines if user is brand new registrant
  if (
    (!isUserLoading &&
      !isAcgUserLoading &&
      (!completedPersonalInformation || !completedAddressInformation) &&
      (globalUser?.mads?.['user-acg'].find?.((i: any) => i?.key === 'preExistingNetForumUser')
        ?.value === false ||
        !globalUser?.mads?.['user-acg'].find?.(
          (i: any) => i?.key === 'preExistingNetForumUser',
        ))) ||
    isBecomingMember
  ) {
    return <AcgNewUserRegistrationFlow setIsBecomingMember={setIsBecomingMember} />;
  }

  return (
    <Center flexGrow={1} width="full">
      <AppSpinner description="Validating your account..." />
    </Center>
  );
};
