import React, { useState, SyntheticEvent } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Checkbox,
  Center,
  useStyleConfig,
  ButtonProps,
  forwardRef,
  Icon,
  Text,
  Flex,
} from '@companydotcom/potion';
import { Maybe, UserGroup } from '@companydotcom/types';
import { companyConstants } from '@companydotcom/helpers';
import _union from 'lodash/union';
import { useMitt } from '@companydotcom/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../hooks';

export interface TileGroupFilterProps {
  userGroups: Maybe<Array<Maybe<UserGroup>>>;
  showAllTiles: boolean;
}

export interface FilterSettings {
  groupId: string;
  isHidden: boolean;
  name: string;
}

export const TileGroupFilter: React.FC<TileGroupFilterProps> = props => {
  const { userGroups, showAllTiles = false } = props;
  const toast = useToast();
  const { t } = useTranslation();
  const [filterSettings, setFilterSettings] = useState(
    userGroups?.map(group => ({
      groupId: group?.groupId,
      isHidden: group?.isHidden,
      name: group?.name,
    })),
  );
  const [nextFilterSettings, setNextFilterSettings] = useState(
    userGroups?.map(group => ({
      groupId: group?.groupId,
      isHidden: group?.isHidden,
      name: group?.name,
    })),
  );
  const [showAllSetting, setShowAllSetting] = useState({
    showAllTiles: showAllTiles || false,
    eventType: companyConstants.platformEvents.showAllTiles,
  });
  const [nextShowAllSetting, setNextShowAllSetting] = useState({ ...showAllSetting });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastChecked, setLastChecked] = useState(nextFilterSettings?.[0].name);
  const { emitter } = useMitt();

  const handleShowAllClick = (checked: boolean) => {
    const nextShowAllSettingCopy = { ...nextShowAllSetting };
    nextShowAllSettingCopy.showAllTiles = !checked;
    setNextShowAllSetting(nextShowAllSettingCopy);
    const showAllGroups = nextFilterSettings?.filter(setting => {
      if (!checked && setting.groupId) {
        setting.isHidden = false;
        return setting;
      }
      return undefined;
    });
    if (showAllGroups) {
      setNextFilterSettings(_union(nextFilterSettings, [...showAllGroups]));
    }
  };

  const handleGroupClick = (checked: boolean, groupId: string) => {
    const groupSetting = nextFilterSettings?.find(item => item.groupId === groupId);
    if (groupSetting) {
      groupSetting.isHidden = checked;
      setLastChecked(groupSetting.name);
      setNextFilterSettings(_union(nextFilterSettings, [groupSetting]));
    }
  };
  const undoFilter = (oldFilterSettings: FilterSettings[], oldShowAllSetting: any) => {
    emitter.emit(oldShowAllSetting.eventType, { showAllTiles: oldShowAllSetting.showAllTiles });
    emitter.emit(companyConstants.platformEvents.hideGroupFromFilter, oldFilterSettings);
  };

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (nextFilterSettings?.every(group => group.isHidden)) {
      toast({
        description: `Please unhide another group before hiding ${lastChecked}`,
        status: 'error',
        isClosable: true,
      });
    }

    setFilterSettings(nextFilterSettings);
    setShowAllSetting(nextShowAllSetting);

    emitter.emit(nextShowAllSetting.eventType, { showAllTiles: nextShowAllSetting.showAllTiles });
    emitter.emit(companyConstants.platformEvents.hideGroupFromFilter, nextFilterSettings);
    toast({
      status: 'success',
      isClosable: true,
      position: 'top',
      render: () => (
        <Flex flexDir="row" color="white" p={3} bg="green.500" justifyContent="space-between">
          <Text>{t('components.filterMenu.filtersApplied')}</Text>{' '}
          <Button
            variant="link"
            color="white"
            // @ts-ignore
            onClick={() => undoFilter(filterSettings, showAllSetting)}
          >
            {t('common.misc.undo')}
          </Button>
        </Flex>
      ),
    });
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={TileGroupFilterButton}>Filters</MenuButton>
      <MenuList zIndex="dropdown">
        <MenuItem>
          <Checkbox
            aria-label="Show All Tiles"
            defaultChecked={nextShowAllSetting.showAllTiles}
            onChange={() => handleShowAllClick(nextShowAllSetting.showAllTiles)}
          >
            {t('components.filterMenu.showAllTiles')}
          </Checkbox>
        </MenuItem>
        <MenuDivider />
        {nextFilterSettings?.map(
          group =>
            group.groupId && (
              <React.Fragment key={group?.groupId}>
                <MenuItem key={group.groupId}>
                  <Checkbox
                    aria-label={group.name ?? 'Group checkbox'}
                    defaultChecked={!group.isHidden}
                    onChange={() => handleGroupClick(!group.isHidden, group.groupId as string)}
                  >
                    {group.name}
                  </Checkbox>
                </MenuItem>
                <MenuDivider />
              </React.Fragment>
            ),
        )}
        <Center>
          <Button onClick={onSubmit}>{t('components.filterMenu.applyFilters')}</Button>
        </Center>
      </MenuList>
    </Menu>
  );
};

export const TileGroupFilterButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const styles = useStyleConfig('TileGroupFilter', props);

  return (
    // @ts-ignore
    <Button
      size="sm"
      ref={ref}
      sx={styles}
      rightIcon={<Icon as={FontAwesomeIcon} icon={faBarsFilter} boxSize={5} />}
      {...props}
    />
  );
});
