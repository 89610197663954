import { Box, VStack, Alert, AlertDescription, Link, Icon, HStack } from '@companydotcom/potion';
import { useTranslation, Trans } from 'react-i18next';
import { PasswordInputField } from '@companydotcom/ui';
import { useSourceConfig, useSource } from '@companydotcom/providers';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ChangePasswordFormProps {
  register: any;
  errors: any;
  formStyles?: any;
  fieldStyles?: any;
  dataBreach: boolean;
}

export const ChangePasswordForm = ({
  register,
  errors,
  dataBreach,
  formStyles,
  fieldStyles,
}: ChangePasswordFormProps) => {
  const sourceConfig = useSourceConfig();
  const source = useSource();
  const { t } = useTranslation();

  return (
    <Box className="potion-changeAccountPasswordStep__form" sx={formStyles}>
      <Box className="potion-changeAccountPasswordStep__fields" sx={fieldStyles}>
        <VStack maxWidth={430} spacing={6}>
          <PasswordInputField
            register={register}
            name="newPassword"
            label={t('common.inputs.newPassword.label')}
            helperText={
              sourceConfig.passwordRequirements?.min
                ? t('miop.passwordManagement.minCharacter', {
                    minimum: sourceConfig.passwordRequirements?.min,
                  })
                : t('common.inputs.password.helperText')
            }
            errors={errors}
          />
          <PasswordInputField
            register={register}
            name="confirmNewPassword"
            label={t('common.inputs.confirmNewPassword.label')}
            helperText={
              sourceConfig.passwordRequirements?.min
                ? t('miop.passwordManagement.minCharacter', {
                    minimum: sourceConfig.passwordRequirements?.min,
                  })
                : t('common.inputs.password.helperText')
            }
            errors={errors}
          />
        </VStack>
      </Box>
      {dataBreach && (
        <Alert
          status="warning"
          colorScheme="orange"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          textAlign="left"
          fontSize="sm"
          my={[4]}
        >
          <HStack>
            <Icon
              p={1}
              pr={2}
              className="fa-solid"
              as={FontAwesomeIcon}
              icon={faCircleExclamation}
              boxSize="24px"
              color="orange.500"
              alignSelf="flex-start"
            />
            <AlertDescription mt={4} fontSize="16px" lineHeight="24px">
              <Trans i18nKey="authPages.passwordBreach">
                This password has previously appeared in a data breach in a different website or
                app. For your protection, it can not be used. If you have used it anywhere before,
                we recommend changing it. Please try a different password.
              </Trans>
              <Link
                mt={2}
                color="black"
                fontWeight="700"
                textDecoration="underline"
                href={`${source?.footerLinks?.learnMore ?? '/'}`}
                isExternal
              >
                {t('common.misc.learnMore')}
              </Link>
            </AlertDescription>
          </HStack>

          {/* <AlertDescription mt={4}>
            <Trans i18nKey="authPages.passwordBreach">
              This password has previously appeared in a data breach in a different website or app.
              For your protection, it can not be used. If you have used it anywhere before, we
              recommend changing it.
              <br />
              Please try a different password. <br />
            </Trans>
          </AlertDescription>
          <Link mt={2} href={`${source?.footerLinks?.learnMore ?? '/'}`} isExternal>
            {t('common.misc.learnMore')}
          </Link> */}
        </Alert>
      )}
    </Box>
  );
};
