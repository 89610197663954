import React from 'react';
import {
  useMultiStyleConfig,
  StylesProvider,
  useStyles,
  __DEV__,
  BoxProps,
  Box,
} from '@companydotcom/potion';

export interface PageLayoutProps extends BoxProps {}

export const PageLayout: React.FC<PageLayoutProps> = props => {
  const { children, ...rest } = props;
  const styles = useMultiStyleConfig('PageLayout', rest);

  return (
    <StylesProvider value={styles}>
      <Box className="potion-pageLayout__container" {...rest} sx={styles.container}>
        {children}
      </Box>
    </StylesProvider>
  );
};

if (__DEV__) {
  PageLayout.displayName = 'PageLayout';
}

export interface PageLayoutHeaderProps extends BoxProps {}

/**
 * PageLayoutHeader
 *
 * React component that houses the Page Layout header components, such as buttons.
 *
 */
export const PageLayoutHeader: React.FC<PageLayoutHeaderProps> = props => {
  const { children, ...rest } = props;
  const styles = useStyles();

  return (
    <Box as="header" {...rest} sx={styles.header}>
      {children}
    </Box>
  );
};

if (__DEV__) {
  PageLayoutHeader.displayName = 'PageLayoutHeader';
}

export interface PageLayoutContentProps extends BoxProps {}

/**
 * PageLayoutHeader
 *
 * React component that houses the Page Layout header components, such as buttons.
 *
 */
export const PageLayoutContent: React.FC<PageLayoutContentProps> = props => {
  const { children, ...rest } = props;
  const styles = useStyles();

  return (
    <Box {...rest} sx={styles.content}>
      {children}
    </Box>
  );
};

if (__DEV__) {
  PageLayoutContent.displayName = 'PageLayoutContent';
}
