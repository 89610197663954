import gql from 'graphql-tag';

export const PUBLISH_TILE_EVENT_QUERY = gql`
  query publishTileEvent(
    $topic: String!
    $message: SNSMessageInput!
    $attributes: SNSAttributeInput!
  ) {
    publishTileEvent(topic: $topic, message: $message, attributes: $attributes)
  }
`;

export const POST_EVENTBRIDGE_QUERY = gql`
  query postEventBridge(
    $topic: String!
    $message: SNSMessageInput!
    $attributes: SNSAttributeInput!
  ) {
    postEventBridge(topic: $topic, message: $message, attributes: $attributes)
  }
`;
