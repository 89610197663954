import isEmpty from 'lodash/isEmpty';
import { withDefaultColorScheme, withDefaultVariant, withDefaultSize, withDefaultProps, } from '@companydotcom/potion';
/**
 * Maps extensions from DynamoDB to a readable format for Coco-ui
 */
export function mapThemeExtensions(extensions) {
    if (!Array.isArray) {
        throw new Error('Extensions must be an array');
    }
    if (isEmpty(extensions) || !extensions) {
        return [];
    }
    return extensions.map(ext => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (ext) {
            switch (ext.name) {
                case 'withDefaultColorScheme':
                    return withDefaultColorScheme({
                        colorScheme: (_a = ext === null || ext === void 0 ? void 0 : ext.colorScheme) !== null && _a !== void 0 ? _a : undefined,
                        components: (_b = ext === null || ext === void 0 ? void 0 : ext.components) !== null && _b !== void 0 ? _b : undefined,
                    });
                case 'withDefaultVariant':
                    return withDefaultVariant({
                        variant: (_c = ext.variant) !== null && _c !== void 0 ? _c : undefined,
                        components: (_d = ext.components) !== null && _d !== void 0 ? _d : undefined,
                    });
                case 'withDefaultSize':
                    return withDefaultSize({
                        size: (_e = ext.size) !== null && _e !== void 0 ? _e : undefined,
                        components: (_f = ext.components) !== null && _f !== void 0 ? _f : undefined,
                    });
                case 'withDefaultProps':
                    return withDefaultProps({
                        // @ts-ignore
                        defaultProps: (_g = ext.defaultProps) !== null && _g !== void 0 ? _g : undefined,
                        components: (_h = ext.components) !== null && _h !== void 0 ? _h : undefined,
                    });
                default:
                    throw new Error(`"${ext.name}" is not a valid extension name. Did you check your spelling?`);
            }
        }
        return [];
    });
}
