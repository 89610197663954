import type { SystemStyleObject } from '@companydotcom/potion';

const parts = ['container', 'listItem', 'discountContainer', 'summaryContainer', 'textAlign'];

// type Dict = Record<string, any>;

const baseStyleContainer: SystemStyleObject = {
  maxWidth: '908px',
  bg: 'white',
  width: 'full',
};

const baseStyleListItem: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const baseStyleDiscount: SystemStyleObject = {
  display: 'flex',
  // alignItems: 'flex-end',
};

const baseStyleSummary: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
};

const baseStyleTextAlign: SystemStyleObject = {
  display: 'flex',
  flexDirection: ['column', 'row'],
  justifyContent: 'space-between',
  alignItems: 'baseline',
};

const baseStyle = {
  container: baseStyleContainer,
  listItem: baseStyleListItem,
  discountContainer: baseStyleDiscount,
  summaryContainer: baseStyleSummary,
  textAlign: baseStyleTextAlign,
};

export default {
  parts,
  baseStyle,
};
