import React, { useState, useEffect } from 'react';
import _startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';
import { CircleEnvelopeIcon } from '@companydotcom/ui';
import { Source } from '@companydotcom/types';
import {
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Center,
  Button,
} from '@companydotcom/potion';
import { useAuth } from '../../../../providers';
import { useLazyGetWelcomeEmailQuery } from '../../../../services/auth/auth-api';

// import { getWelcomeEmail } from '../../../../services/auth/old-api/auth-svc';

// const sendVerification = async (email: string, source: Source, userId: string, role: string) => {
//   const res = await getWelcomeEmail(email, { source, userId, role });
//   if (!(res && res.resp.includes('201'))) {
//     console.log('RES: ', res);
//     throw new Error('Verification email failed to send. ');
//   }
//   return res;
// };

export interface AlertNotificationProps {
  source: Source;
  handleClose: any;
  notification: any;
  userId?: string;
  role?: string;
}

export const AlertNotification: React.FC<AlertNotificationProps> = props => {
  const auth0 = useAuth();
  const [email, setEmail] = useState(auth0?.user?.email);
  const { notificationType, id: notificationId } = props.notification;
  const { productName } = props?.notification?.body;
  const { t } = useTranslation();
  const [getWelcomeEmail, { isLoading, isSuccess }] = useLazyGetWelcomeEmailQuery();

  useEffect(() => {
    setEmail(auth0?.user?.email);
  }, [auth0?.user?.email]);

  // const [isSending, setIsSending] = useState(false);
  // const [isResent, setIsResent] = useState(false);

  const resendVerification = async () => {
    if (!isLoading && email) {
      // setIsSending(true);
      await getWelcomeEmail(
        {
          email,
          input: {
            source: props.source,
            userId: props.userId as string,
            role: props.role as string,
          },
        },
        // props.source,
        // props.userId as string,
        // props.role as string,
      ).catch(err => console.error(err));
      // setIsResent(true);
      // setIsSending(false);
    }
  };

  return email && notificationType === 'verificationEmailSent' ? (
    <Modal isOpen onClose={() => props.handleClose(notificationId)} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Center
          mx="auto"
          minH={[null, null, 560]}
          minW={[300, null, 708]}
          pt="48px"
          px="30px"
          pb="64px"
          flexDirection="column"
          textAlign="center"
        >
          <CircleEnvelopeIcon />
          {(isLoading && (
            <Heading size="hs-2xl">{t('components.notifications.alert.heading')}</Heading>
          )) ||
            (isSuccess && (
              <Heading size="hs-2xl">{t('components.notifications.alert.isResent')}</Heading>
            )) || (
              <Heading size="hs-2xl">{t('components.notifications.alert.pleaseVerify')}</Heading>
            )}
          {(productName && !isSuccess && (
            <Text textStyle="xl" mt={2} maxWidth={590}>
              {t('components.notifications.alert.extraSecurity', {
                email,
                productName: _startCase(productName),
              })}
            </Text>
          )) ||
            (isSuccess && (
              <Text textStyle="xl" mt={2} maxWidth={590}>
                {t('components.notifications.alert.emailResent', email)}
              </Text>
            ))}
          <Text textStyle="md" maxWidth={376} my={2} mx="auto">
            {t('components.notifications.alert.clickTheLink', {
              productName: _startCase(productName),
            })}
          </Text>
          <Text textStyle="sm" mt={11}>
            {t('components.notifications.alert.cantFindEmail')}

            <Button variant="link" onClick={resendVerification}>
              {isLoading
                ? t('components.notifications.alert.sending')
                : t('components.notifications.alert.sendAgain')}
            </Button>
          </Text>
        </Center>
      </ModalContent>
    </Modal>
  ) : null;
};
