import React, { useEffect, useState } from 'react';
import { AppSpinner } from '@companydotcom/ui';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Outlet } from 'react-router-dom';
import { useGetEnrollmentByUserEmailQuery } from '../../services/enrollment/enrollment-api';
import { useAuth } from '../../providers';
import { useGetFacecakeColors } from '../../features/facecake/layouts/components/facecake-background-imager';

// This component is used to wrap any components that should be protected via enrollment status
const allowedStatus = ['COMPLETE - MID AUTO-SELECTED', 'COMPLETE'];

const WaitForEnrollment = () => {
  const auth0 = useAuth();
  const facecakeStyles = useGetFacecakeColors();
  const [isShouldBeFetching, setIsShouldBeFetching] = useState(true);

  const { data, isLoading, isError } = useGetEnrollmentByUserEmailQuery(
    isShouldBeFetching && auth0?.user?.email ? { email: auth0.user.email } : skipToken,
    {
      pollingInterval: 2000,
    },
  );

  useEffect(() => {
    if (!isLoading && !isError && data?.status && allowedStatus?.includes(data?.status)) {
      setIsShouldBeFetching(false);
    }
  }, [data?.status, isError, isLoading]);

  return !auth0?.user?.userId ||
    isLoading ||
    (data?.status && !allowedStatus?.includes(data?.status)) ? (
    <AppSpinner
      description="Creating your account. This may take up to 15 seconds..."
      className="dashboard-waitForEnrollment__spinner"
      containerStyles={{
        minHeight: '100%',
        flexGrow: 1,
      }}
      {...facecakeStyles}
    />
  ) : (
    <Outlet />
  );
};

export default WaitForEnrollment;
