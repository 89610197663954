import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  Center,
  Heading,
  Stack,
  StackDivider,
  HStack,
  Flex,
  Alert,
  AlertIcon,
  IconButton,
  Text,
  Divider,
  Icon,
  Button,
  Tooltip,
  Box,
} from '@companydotcom/potion';
import { getSymbolFromCurrency } from '@companydotcom/helpers';
import { PageDivider, FontAwesomeIcon, InputField } from '@companydotcom/ui';
import { faCircleInfo, faXmark } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import {
  formatCheckoutPrice,
  mapEventKeysForPurchase,
  useAcgCartStorage,
  getEventFeeList,
  getSessionIdList,
  checkEventStatus,
} from '../../shared';
import {
  setEventForPurchase,
  setEventDiscount,
  setMembershipDiscount,
  setDiscountCodeString,
  getDiscountCodes,
  getEventDiscount,
  getMembershipDiscount,
} from '../../acg-checkout/checkout-slice';
import { removeGuestToRegister, resetGuestsToRegister } from '../../acg-add-guest/add-guest-slice';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  useAddGuestsToRegisteredEventCartMutation,
  useCreateCartMutation,
  useApplyDiscountMutation,
  useLazySubmitEventApplicationQuery,
  useLazyGetEventForRegistrationQuery,
  useLazyDeleteDraftEventRegistrationsQuery,
} from '../../../../services/acg/acg-api';
import { BackButtonLayout } from '../../../../components/layouts';
import { CheckoutSharedProps } from '../../acg-checkout';
import { Coupon } from '../../../../services/acg/acg.types';
import { WaitlistAlert } from '../../shared/components/waitlist-alert';
import { EventFullAlert } from '../../shared/components/event-full-alert';

export interface ShoppingCartProps extends CheckoutSharedProps {}

interface LocationStateProps {
  flow?: string;
  // These values come from event-registration flows
  shouldShouldWaitlistAlert?: boolean;
  shouldShowEventFullAlert?: boolean;
  shouldShowEventOverCapacityAlert?: boolean;
  totalWaitList?: number;
}

const DiscountSchema = yup.object().shape({
  discountCodeField: yup.string().notRequired().min(1, 'Please enter a valid discount code'),
});

export const ACGShoppingCart: React.FC<ShoppingCartProps> = () => {
  const {
    getLocalCart,
    removeMembershipFromLocalCart,
    removeEventFeeFromLocalCart,
    removeGuestsFromCart,
    clearLocalCart,
    addMembershipToLocalCart,
  } = useAcgCartStorage();
  const { t } = useTranslation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const cart = useMemo(() => getLocalCart(), [getLocalCart]);
  const event = useMemo(() => cart?.items?.event, [cart]);

  const [addGuestToRegisteredEvent, { isLoading: addGuestCartLoading }] =
    useAddGuestsToRegisteredEventCartMutation();
  const [submitEventApplication, { isLoading: eventApplicationLoading }] =
    useLazySubmitEventApplicationQuery();
  const [deleteDraftEventRegistrations, { isLoading: deleteDraftLoading }] =
    useLazyDeleteDraftEventRegistrationsQuery();

  const [createCart, { isLoading: createCartLoading }] = useCreateCartMutation();
  const [applyDiscount] = useApplyDiscountMutation();

  const locationState = useLocation().state as LocationStateProps;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getEvent, { isFetching }] = useLazyGetEventForRegistrationQuery();
  const [isCancelled, setIsCancelled] = useState(false);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [isDraftEventDeleted, setIsDraftEventDeleted] = useState(false);
  const [isError, setIsError] = useState(false);
  isError;
  const eventDiscount = useAppSelector(getEventDiscount);
  const membershipDiscount = useAppSelector(getMembershipDiscount);
  const membership = useMemo(() => cart?.items?.memberships?.[0], [cart]);
  const currencySymbol = useMemo(
    () =>
      !membership?.currencySymbol ||
      !event?.registrant?.fees?.[0]?.eventFees?.currencySymbol ||
      !event?.guests?.[0]?.fees?.[0]?.currencySymbol
        ? getSymbolFromCurrency(
            membership?.currencyCode ||
              event?.registrant?.fees?.[0]?.eventFees?.currencyCode ||
              event?.guests?.[0]?.fees?.[0]?.currencyCode,
          )
        : membership?.currencySymbol ||
          event?.registrant?.fees?.[0]?.eventFees?.currencySymbol ||
          event?.guests?.[0]?.fees?.[0]?.currencySymbol,
    [event?.guests, event?.registrant?.fees, membership?.currencyCode, membership?.currencySymbol],
  );
  // const membershipDiscount = useMemo(
  //   () => membership?.coupons ?? membership?.coupons,
  //   [membership?.coupons],
  // );

  const discountCodes = useAppSelector(getDiscountCodes);
  // Form
  const discountDefaultValues = {
    discountCodeField: '',
  };
  const {
    handleSubmit: handleDiscountSubmit,
    reset: resetDiscountField,
    register: discountRegister,
    setError: setDiscountError,
    formState: { isSubmitting: isDiscountSubmitting, errors: discountErrors },
  } = useForm({
    defaultValues: discountDefaultValues,
    resolver: yupResolver(DiscountSchema),
    mode: 'onSubmit',
  });

  // Fee And Total Calculation
  const eventFeeList = useMemo(() => {
    if (cart && cart?.items && event) {
      return getEventFeeList(event);
    }
  }, [cart, event]);

  const sessionIdList = useMemo(() => {
    if (cart && cart?.items && event) {
      return getSessionIdList(event);
    }
  }, [cart, event]);

  const nonMemberFee = useMemo(() => {
    // todo: uncomment this when NetForum supports it in prod
    // if (eventFeeList) {
    //   return eventFeeList.reduce((acc: any, cur: any) => {
    //     const lowerCaseProduct = cur?.eventFees.productName.toLowerCase();
    //     if (
    //       lowerCaseProduct.includes('non-member') ||
    //       lowerCaseProduct.includes('non member') ||
    //       lowerCaseProduct.includes('nonmember')
    //     ) {
    //       acc = acc + 10;
    //     }

    //     return acc;
    //   }, 0);
    // }
    return 0;
  }, []);

  const subtotal = useMemo((): number => {
    if (membership) {
      if (membershipDiscount && membershipDiscount.length) {
        return membership?.lineItems?.[0]?.unitPrice;
      }
      const price = membership?.subTotal ?? membership?.price;
      return price;
    }
    if (eventFeeList && eventFeeList?.length > 0) {
      return eventFeeList.reduce((acc: any, cur: any) => {
        acc = acc + (cur.price || 0);
        return acc;
      }, 0);
    }
    return 0;
  }, [eventFeeList, membership, membershipDiscount]);

  const total = useMemo(() => {
    if (nonMemberFee) {
      return (subtotal + nonMemberFee).toFixed(2);
    }
    // if (membershipDiscount) {
    //   return membership?.total % 1 != 0 ?  parseInt(membership?.total, 10)?.toFixed(2) :  membership?.total?.toFixed(2);
    // }

    if (membershipDiscount && membershipDiscount.length) {
      const discountTotal = membershipDiscount.reduce((acc, cur: any) => {
        acc = acc + cur.discount;
        return acc;
      }, 0);
      return subtotal - discountTotal;
    }

    if (eventDiscount.length) {
      const discountTotal = eventDiscount.reduce((acc, cur: any) => {
        acc = acc + cur.discount;
        return acc;
      }, 0);
      return subtotal - discountTotal;
    }
    return subtotal;
  }, [nonMemberFee, membershipDiscount, eventDiscount, subtotal]);

  const guestsForEvent = event?.guests?.map(guest => ({
    packageId: guest?.fees?.[0]?.priceKey,
    contactInfo: {
      name: guest?.badgeName,
      emailAddress: guest?.emailAddress,
      firstName: guest?.firstName,
      lastName: guest?.lastName,
      jobTitle: guest?.title,
    },
  }));

  const eventForPurchase = useMemo(
    () => ({
      eventId: event?.eventRecordKey,
      processId: event?.registrantType?.registrantProcessId,
      typeId: event?.registrantType?.registrantTypeId,
      productId:
        event?.registrant?.selectedPackage ||
        event?.registrant?.fees?.[0]?.eventFees?.priceKey ||
        event?.guests?.[0]?.fees?.[0].priceKey,
      badgeInfo: {
        firstName: event?.registrant?.badgeName,
        fullName: event?.registrant?.fullName,
        jobTitle: event?.registrant?.title,
        organizationName: event?.registrant?.organizationName,
      },
      sessions: sessionIdList,
      guestsInfo: guestsForEvent,
      customFields: event?.registrant?.customQuestions,
    }),
    [
      event?.eventRecordKey,
      event?.guests,
      event?.registrant?.badgeName,
      event?.registrant?.customQuestions,
      event?.registrant?.fees,
      event?.registrant?.fullName,
      event?.registrant?.organizationName,
      event?.registrant?.selectedPackage,
      event?.registrant?.title,
      event?.registrantType?.registrantProcessId,
      event?.registrantType?.registrantTypeId,
      guestsForEvent,
      sessionIdList,
    ],
  );

  // Submit Handlers
  const onDiscountSubmit = async (values: typeof discountDefaultValues) => {
    try {
      if (!globalUser) {
        throw new Error('global user info data missing');
      }

      setIsCouponLoading(true);
      const hasGuestAndIsMyEventsFlow = event?.isMyEventsFlow && !!event?.guests?.length;
      const discountType = membership?.chapterName ? 'memberships' : 'events';

      if (!discountCodes?.includes(values.discountCodeField.toLowerCase())) {
        dispatch(setDiscountCodeString(values?.discountCodeField));

        if (discountType === 'events' && event && globalUser) {
          if (!isDraftEventDeleted) {
            await deleteDraftEventRegistrations({
              user: globalUser,
              payload: {
                eventId: event?.eventRecordKey,
              },
            }).unwrap();

            setIsDraftEventDeleted(true);
          }
          const applicationId = await submitEventApplication({
            user: globalUser,
            payload: {
              items: {
                memberships: [],
                events: [eventForPurchase],
              },
            },
          }).unwrap();

          if (hasGuestAndIsMyEventsFlow) {
            await addGuestToRegisteredEvent({
              user: globalUser,
              payload: {
                items: {
                  memberships: [],
                  events: [mapEventKeysForPurchase(event)],
                },
              },
            });
          } else {
            await createCart({
              user: globalUser,
              items: {
                memberships: [],
                events: [
                  {
                    applicationId,
                    ...eventForPurchase,
                  },
                ],
              },
            });
          }
        }

        await applyDiscount({
          user: globalUser,
          discountCodes: discountCodes?.length
            ? [...discountCodes, values?.discountCodeField]
            : [values?.discountCodeField],
        })
          .unwrap()
          .then(res => {
            if (
              (discountType === 'events' && res.discount) ||
              (discountType === 'memberships' && res.discount)
            ) {
              dispatch(setDiscountCodeString(values.discountCodeField.toLowerCase()));
              resetDiscountField(discountDefaultValues);

              if (membership?.chapterName) {
                dispatch(
                  setMembershipDiscount(
                    res.coupons.map(coupon => ({
                      couponTypeId: coupon.couponTypeId,
                      couponId: coupon.couponId,
                      discount: coupon.discount,
                      couponType: coupon.couponType,
                    })),
                  ),
                );
                addMembershipToLocalCart({
                  ...membership,
                  coupons: res.coupons,

                  openInvoices: membership?.openInvoices,
                });
              } else {
                dispatch(
                  setEventDiscount(
                    res.coupons.map(coupon => ({
                      couponTypeId: coupon.couponTypeId,
                      couponId: coupon.couponId,
                      discount: coupon.discount,
                      couponType: coupon.couponType,
                    })),
                  ),
                );
              }
            } else {
              setDiscountError('discountCodeField', {
                type: 'custom',
                message: 'Please enter a valid discount code',
              });
              setIsDraftEventDeleted(false);
            }
            setIsCouponLoading(false);
          })
          .catch(err => {
            setDiscountError('discountCodeField', {
              type: 'custom',
              message: 'Discount code could not be applied. Please try again',
            });
            setIsCouponLoading(false);
            return new Error(err);
          });
      } else {
        setDiscountError('discountCodeField', {
          type: 'custom',
          message: 'This code has already been applied',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCheckoutSubmit = async (checkoutType: 'memberships' | 'events') => {
    try {
      const hasGuestAndIsMyEventsFlow = event?.isMyEventsFlow && !!event?.guests?.length;

      if (checkoutType === 'memberships') {
        navigate('/checkout');
      } else if (checkoutType === 'events' && event && globalUser) {
        dispatch(setEventForPurchase(event));

        if (!isDraftEventDeleted) {
          await deleteDraftEventRegistrations({
            user: globalUser,
            payload: {
              eventId: event?.eventRecordKey,
            },
          }).unwrap();
        }

        const applicationId = await submitEventApplication({
          user: globalUser,
          payload: {
            items: {
              memberships: [],
              events: [eventForPurchase],
            },
          },
        }).unwrap();

        if (hasGuestAndIsMyEventsFlow) {
          await addGuestToRegisteredEvent({
            user: globalUser,
            payload: {
              items: {
                memberships: [],
                events: [
                  {
                    applicationId,
                    ...eventForPurchase,
                  },
                ],
              },
            },
          });
        }

        // Only create the cart of a discount hasn't been applied, because cart was already created in applyDiscount submit handler
        if (!eventDiscount || isEmpty(eventDiscount)) {
          await createCart({
            user: globalUser,
            items: {
              memberships: [],
              events: [
                {
                  applicationId,
                  ...eventForPurchase,
                },
              ],
            },
          });
        }

        if (applicationId) {
          // Checks to see if event is not full before proceeding
          if (globalUser && event?.eventRecordKey) {
            const latestEventData = await getEvent({
              user: globalUser,
              eventId: event?.eventRecordKey,
            }).unwrap();
            const {
              shouldShouldWaitlistAlert,
              shouldShowEventFullAlert,
              shouldShowEventOverCapacityAlert,
            } = checkEventStatus(latestEventData, event?.guests?.length, cart);
            navigate('/checkout', {
              state: {
                shouldShouldWaitlistAlert,
                shouldShowEventFullAlert,
                shouldShowEventOverCapacityAlert,
                totalWaitList: latestEventData?.totalWaitList,
              },
            });
          }
        }
      }
    } catch (err) {
      console.log('error creating cart for checkout', err);
      setIsError(true);
    }
  };

  const cancelOrderHandler = useCallback(() => {
    clearLocalCart();
    dispatch(resetGuestsToRegister([]));
    dispatch(setDiscountCodeString(''));
    dispatch(setEventDiscount([]));
  }, [clearLocalCart, dispatch]);

  // If event is over capacity because of added guests, remove guests
  useEffect(() => {
    if (locationState?.shouldShowEventOverCapacityAlert) {
      removeGuestsFromCart();
      dispatch(resetGuestsToRegister([]));
    }
    // eslint-disable-next-line
  }, [dispatch, locationState?.shouldShowEventOverCapacityAlert]);

  return (
    <BackButtonLayout
      onBackClick={() =>
        locationState?.['flow'] === 'checkout' && event?.eventRecordKey
          ? navigate(
              { pathname: '/event-registration', search: `?Reg_evt_key=${event?.eventRecordKey}` },
              { state: { lastStep: locationState?.['lastStep'] } },
            )
          : locationState?.['flow'] === 'checkout' || event?.eventRecordKey
          ? navigate(-1)
          : navigate('/')
      }
    >
      <Center
        className="shopping-cart"
        flexDirection="column"
        layerStyle="formContainer"
        bg={['transparent', 'white']}
        py={[0, 8, 14]}
        px={[0, 4]}
      >
        <Box width="full" maxW={908}>
          <Box textAlign={['center', 'left']}>
            <Heading size="xl">Shopping Cart</Heading>
            <PageDivider mt={[2, 6]} mx={['auto', 'inherit']} />
          </Box>
          {isError && (
            <Alert variant="solid" status="error" textAlign="left" mt={12}>
              <AlertIcon />
              <Text textStyle="sm">{t('acg.checkout.orderStep.unableToCompleteOrder')}</Text>
            </Alert>
          )}
          {locationState?.shouldShowEventOverCapacityAlert && (
            <EventFullAlert
              variant="eventOverCapacity"
              cancelOrderHandler={cancelOrderHandler}
              setIsCancelled={setIsCancelled}
              isCancelled={isCancelled}
            />
          )}
          {eventFeeList?.length &&
          event?.eventRecordKey &&
          locationState?.shouldShouldWaitlistAlert &&
          locationState?.totalWaitList ? (
            <WaitlistAlert
              eventKey={event?.eventRecordKey}
              waitListedRegistrants={locationState?.totalWaitList}
            />
          ) : locationState?.shouldShowEventFullAlert &&
            !locationState?.shouldShowEventOverCapacityAlert ? (
            <EventFullAlert
              variant="eventAlreadyFull"
              cancelOrderHandler={cancelOrderHandler}
              setIsCancelled={setIsCancelled}
              isCancelled={isCancelled}
            />
          ) : (
            <>
              {!isCancelled ? (
                <Flex
                  maxW="full"
                  width={['auto', 'auto', '908px']}
                  my={12}
                  direction="column"
                  textAlign={['center', 'left']}
                >
                  <Stack divider={<StackDivider />} spacing={6}>
                    <HStack justify="space-between">
                      <Heading size="hs-md">ITEM</Heading>
                      <Heading size="hs-md">PRICE</Heading>
                    </HStack>
                    <Stack divider={<StackDivider />} spacing={6} sx={{ fontSize: '18px' }}>
                      {cart && membership ? (
                        <HStack justify="space-between">
                          <HStack>
                            <IconButton
                              variant="ghost"
                              size="sm"
                              aria-label="delete"
                              icon={<Icon as={FontAwesomeIcon} icon={faXmark} />}
                              onClick={() => {
                                removeMembershipFromLocalCart(
                                  membership.recordKey || membership.id,
                                );
                                dispatch(setDiscountCodeString(''));
                                dispatch(setMembershipDiscount([]));
                              }}
                            />
                            <Text>{membership.productName || membership.displayName}</Text>
                          </HStack>
                          <Text fontWeight="bold">
                            {`${currencySymbol}${formatCheckoutPrice(
                              membership?.price || subtotal,
                            )}`}
                          </Text>
                        </HStack>
                      ) : null}
                      {eventFeeList && eventFeeList.length > 0
                        ? eventFeeList.map((f: any, i: number) => (
                            <HStack key={i} justify="space-between">
                              <HStack>
                                <IconButton
                                  variant="ghost"
                                  size="sm"
                                  aria-label="delete"
                                  icon={<Icon as={FontAwesomeIcon} icon={faXmark} />}
                                  onClick={() => {
                                    removeEventFeeFromLocalCart(
                                      f.priceKey || '',
                                      f.sessionFeeId || f.productName || f.sessionId,
                                      f.guestIndex,
                                      event?.eventRecordKey || '',
                                    );
                                    dispatch(removeGuestToRegister(f.guestIndex));
                                    dispatch(setDiscountCodeString(''));
                                    dispatch(setEventDiscount([]));
                                  }}
                                />
                                <Text>{f.productName || f.sessionName}</Text>
                              </HStack>
                              <Text fontWeight="bold">
                                {`$${formatCheckoutPrice(f.price)}` || 0}
                              </Text>
                            </HStack>
                          ))
                        : null}
                      {cart && !event && !membership ? (
                        <HStack justify="space-between">
                          <Text color="error">There is nothing in your cart</Text>
                          <Text fontWeight="bold">-</Text>
                        </HStack>
                      ) : null}
                    </Stack>
                    <Flex flexDir={['column', 'column', 'row']} width="full" justify="flex-end">
                      <Stack
                        alignSelf={['center', 'flex-end']}
                        spacing={6}
                        width={['full', 'full', '50%', '35%']}
                        sx={{ fontSize: '18px' }}
                      >
                        <Stack direction="column" spacing={6}>
                          <HStack justify="space-between">
                            <Text maxW={136}>Subtotal</Text>
                            <Text fontWeight="bold">
                              {cart && !event && !membership
                                ? '-'
                                : `${currencySymbol}${subtotal?.toFixed(2)}`}
                            </Text>
                          </HStack>
                          {nonMemberFee > 0 && (
                            <Stack
                              direction={['column', 'row']}
                              justify="space-between"
                              alignItems={['flex-start']}
                            >
                              <HStack justify="flex-end" alignItems="flex-end">
                                <Text maxW={136}>Non-Member Transaction Fee</Text>
                                <Tooltip
                                  hasArrow
                                  shouldWrapChildren
                                  label="Each non-member registered is charged an additional $10 Non-Member Transaction Fee"
                                  bg="gray.700"
                                  color="white"
                                  placement="top"
                                >
                                  <FontAwesomeIcon
                                    ml={2}
                                    boxSize={4}
                                    color="blue.500"
                                    icon={faCircleInfo}
                                  />
                                </Tooltip>
                              </HStack>
                              <Text fontWeight="bold">${nonMemberFee?.toFixed(2)}</Text>
                            </Stack>
                          )}
                          {membershipDiscount?.length && (
                            <HStack justify="space-between">
                              <Box>
                                <Text textStyle="lg">Discounts Applied</Text>
                                {membershipDiscount?.map((discountApplied: Coupon, i: number) => (
                                  <Text
                                    key={i}
                                    textStyle="lg"
                                    color="red.500"
                                    textTransform="uppercase"
                                  >
                                    {discountApplied.couponTypeId}
                                  </Text>
                                ))}
                              </Box>
                              {membershipDiscount?.map((discountApplied: Coupon, i: number) => (
                                <Text
                                  key={i}
                                  textStyle="lg"
                                  fontWeight="bold"
                                  mt={1}
                                >{`-${currencySymbol}${discountApplied?.discount?.toFixed(
                                  2,
                                )}`}</Text>
                              ))}
                            </HStack>
                          )}
                          {eventDiscount?.length && (
                            <HStack justify="space-between">
                              <Box>
                                <Text textStyle="lg">Discounts Applied</Text>
                                {eventDiscount?.map((discountApplied: Coupon, i: number) => (
                                  <Text
                                    key={i}
                                    textStyle="lg"
                                    color="red.500"
                                    textTransform="uppercase"
                                  >
                                    {discountApplied.couponTypeId}
                                  </Text>
                                ))}
                              </Box>
                              {eventDiscount?.map((discountApplied: Coupon, i: number) => (
                                <Text
                                  key={i}
                                  textStyle="lg"
                                  fontWeight="bold"
                                  mt={1}
                                >{`-${currencySymbol}${discountApplied?.discount?.toFixed(
                                  2,
                                )}`}</Text>
                              ))}
                            </HStack>
                          )}
                        </Stack>
                        <Divider />

                        <Stack justify="space-between" pb={[6, 14]} direction={['column', 'row']}>
                          <Heading size="hs-lg">TOTAL</Heading>
                          <Heading size="hs-lg">
                            {!event && !membership
                              ? '-'
                              : `${currencySymbol}${
                                  typeof total === 'string'
                                    ? parseInt(total, 10)?.toFixed(2)
                                    : total?.toFixed(2)
                                }`}
                          </Heading>
                        </Stack>
                      </Stack>
                    </Flex>
                  </Stack>
                  <Box>
                    <Stack
                      direction={['column', 'column', null, 'row']}
                      justify="space-between"
                      align="center"
                      pt={[4, 8]}
                      spacing={[4, 4, null, 0]}
                    >
                      {total === 0 ? (
                        <Box />
                      ) : (
                        <Stack flexDir="row" width={['100%', '370px']}>
                          <InputField
                            alignSelf="flex-start"
                            className="shopping-cart_discount-code"
                            placeholder="Discount Code"
                            register={discountRegister}
                            name="discountCodeField"
                            errors={discountErrors}
                          />
                          <Button
                            className="discount-code-apply-button"
                            size="md"
                            variant="outline"
                            isLoading={isDiscountSubmitting}
                            isDisabled={isDiscountSubmitting}
                            onClick={handleDiscountSubmit(onDiscountSubmit)}
                          >
                            APPLY
                          </Button>
                        </Stack>
                      )}
                      <Button
                        alignSelf={['center', 'center', 'center', 'flex-end']}
                        width={['full', '370px', '370px', '35%']}
                        onClick={() =>
                          onCheckoutSubmit(membership?.chapterName ? 'memberships' : 'events')
                        }
                        isLoading={
                          addGuestCartLoading ||
                          createCartLoading ||
                          eventApplicationLoading ||
                          deleteDraftLoading ||
                          isFetching
                        }
                        disabled={isCouponLoading || isError}
                      >
                        Checkout
                      </Button>
                    </Stack>
                  </Box>
                </Flex>
              ) : null}
            </>
          )}
        </Box>
      </Center>
    </BackButtonLayout>
  );
};
