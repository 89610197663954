import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Image, VStack, Heading, Icon, HStack } from '@companydotcom/potion';
import { Listing } from '@companydotcom/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

export interface PublisherStatusListProps {
  listing: Listing;
  restartGmbProcess?: () => void;
  showRecSteps?: boolean;
}

export const PublisherStatusList = ({
  listing,
  restartGmbProcess,
  showRecSteps,
}: PublisherStatusListProps) => {
  const [gmbSyncStatus, setGmbSyncStatus] = useState('gray.300');
  const [subtext, setSubtext] = useState('Get Your Business Listed');
  const [directoryStatus] = useState('green.400');

  const publisherItems = [
    {
      imageSrc: `${process.env.REACT_APP_IMG_URL}/visibility-assets/static/GOOGLE.png`,
      label: 'Google My Business',
      handler:
        gmbSyncStatus === 'gray.300'
          ? () => {
              restartGmbProcess?.();
            }
          : null,
      subtext,
      colorState: gmbSyncStatus,
    },
    {
      imageSrc: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/products/logos/svg/directory.svg`,
      label: 'Company.com Directory',
      colorState: directoryStatus,
    },
  ];

  useEffect(() => {
    switch (listing?.gmbStatus) {
      case 'verified':
        setGmbSyncStatus('green.400');
        setSubtext('Verified');
        break;
      case 'pendingDirectVerification':
      case 'pendingExternalVerification':
        setGmbSyncStatus('orange.300');
        setSubtext('In Verification');
        break;
      case 'externalClaim':
        setGmbSyncStatus('red.500');
        break;
      case 'optIn':
      case 'optOut':
      default:
        setGmbSyncStatus('gray.300');
        setSubtext('Get Your Business Listed');
        break;
    }
  }, [listing?.gmbStatus]);

  return (
    <Box className="publisher-status-list" pt={showRecSteps ? 8 : 0}>
      <Heading size="hs-md" mb={4}>
        My Directory Listings
      </Heading>
      <Text textStyle="sm">Keep your directory listings in sync</Text>
      <VStack width="full" spacing={3} mt={8}>
        {publisherItems.map(i => (
          <StepItem key={i.imageSrc} item={i} />
        ))}
      </VStack>
    </Box>
  );
};

const StepItem = ({ item }: any) => {
  return (
    <Flex
      key={item?.label}
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      width="full"
      px={4}
      py={3}
      border="1px solid"
      borderColor="gray.100"
      boxShadow="md"
      onClick={item?.handler}
      minHeight={16}
      height="100%"
      cursor="pointer"
    >
      <HStack>
        <Image borderRadius="full" boxSize="32px" src={item.imageSrc} />
        <VStack textAlign="left" width="full" spacing={0}>
          <Text width="full" textStyle="md" fontWeight="medium">
            {item?.label}
          </Text>
          {item.subtext && <Text textStyle="sm">{item.subtext}</Text>}
        </VStack>
      </HStack>
      <Icon color={item?.colorState} as={FontAwesomeIcon} boxSize={8} icon={faCheckCircle} />
    </Flex>
  );
};
