var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Image, Text, Link, Stack, useMultiStyleConfig, useStyles, StylesProvider, Box, } from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';
export const SubpageLayout = props => {
    const { children } = props, rest = __rest(props, ["children"]);
    const styles = useMultiStyleConfig('SubpageLayout', props);
    const rootStyles = Object.assign({ flexDirection: 'column' }, styles.layout);
    return (_jsx(StylesProvider, Object.assign({ value: styles }, { children: _jsx(Flex, Object.assign({ width: "100%", flexDirection: "column", className: "subpageLayout__container", sx: rootStyles }, rest, { children: children }), void 0) }), void 0));
};
export const SubpageContent = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const styles = useStyles();
    const contentStyles = Object.assign({ flexDirection: 'column' }, styles.content);
    return (_jsx(Flex, Object.assign({ as: "main", className: "subpageLayout__content" }, rest, { sx: contentStyles }, { children: children }), void 0));
};
export const SubpageHeader = ({ logo }) => {
    const styles = useStyles();
    return (_jsx(Flex, Object.assign({ as: "header", className: "subpageLayout__header", sx: styles.header }, { children: _jsx(Image, { src: logo !== null && logo !== void 0 ? logo : '', alt: "logo", fallback: _jsx(Box, { height: [14, '68px'] }, void 0), sx: styles.logo }, void 0) }), void 0));
};
export const SubpageFooter = ({ footerLinks, footerLogo }) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const getYear = new Date().getFullYear();
    const anchors = [
        {
            href: (footerLinks === null || footerLinks === void 0 ? void 0 : footerLinks.home) || process.env.REACT_APP_WEBSITE_URL,
            text: 'common.footer.home',
            key: 0,
        },
        {
            href: (footerLinks === null || footerLinks === void 0 ? void 0 : footerLinks.aboutUs) || `${process.env.REACT_APP_WEBSITE_URL}/about-us`,
            text: 'common.footer.aboutUs',
            key: 1,
        },
        {
            href: (footerLinks === null || footerLinks === void 0 ? void 0 : footerLinks.contactUs) || `${process.env.REACT_APP_WEBSITE_URL}/contact-us`,
            text: 'common.footer.contactUs',
            key: 2,
            displayOnSubpage: true,
        },
        {
            href: (footerLinks === null || footerLinks === void 0 ? void 0 : footerLinks.termsOfUse) || `${process.env.REACT_APP_WEBSITE_URL}/terms-of-use`,
            text: 'common.footer.termsOfUse',
            key: 3,
            displayOnSubpage: true,
        },
        {
            href: (footerLinks === null || footerLinks === void 0 ? void 0 : footerLinks.privacyPolicy) || `${process.env.REACT_APP_WEBSITE_URL}/privacy-policy`,
            text: 'common.footer.privacyPolicy',
            key: 4,
            displayOnSubpage: true,
        },
    ];
    return (_jsxs(Stack, Object.assign({ as: "footer", className: "subpageLayout__footer", sx: styles.footer }, { children: [_jsx(Image, { src: footerLogo !== null && footerLogo !== void 0 ? footerLogo : '', alt: "footer logo", maxHeight: ['34px'], mb: [6, null, '0px'] }, void 0), _jsxs(Stack, Object.assign({ direction: ['column-reverse', null, 'row'], spacing: [1, 3], alignItems: "center" }, { children: [_jsx(Text, Object.assign({ textStyle: "sm", mr: [0, 2] }, { children: t('common.footer.disclaimerText', { getYear }) }), void 0), anchors.map(({ href, text, key, displayOnSubpage }) => displayOnSubpage && (_jsx(Link, Object.assign({ target: "_blank", textStyle: "sm", href: href }, { children: t(text) }), key)))] }), void 0)] }), void 0));
};
