import React from 'react';
import { Tabs, TabPanels, TabPanel, useTabStepper, Center } from '@companydotcom/potion';
import { AddGuestSearch } from './add-guest-search-guests';
import { AddNewGuest } from './add-guest-add-new-guest';
import { AddExistingGuest } from './add-guest-add-existing-guest';
import { AddGuestProps } from '../types';

const steps = [
  {
    slug: 'add-guest-search',
    component: <AddGuestSearch />,
  },
  {
    slug: 'add-guest-new',
    component: <AddNewGuest />,
  },
  {
    slug: 'add-guest-existing',
    component: <AddExistingGuest />,
  },
];

export const AddGuest = ({
  eventForRegistration,
  variant,
  eventId,
  wrapperStyles,
  createEventForPurchaseHandler,
  showWaitListAlert,
}: AddGuestProps) => {
  const { tabIndex, handleTabsChange, nextStep, previousStep, goToStep } = useTabStepper({
    steps,
  });

  return (
    <Tabs isLazy variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
      <TabPanels>
        {steps.map(step => (
          <TabPanel key={step.slug}>
            <Center className={`addGuest__${step?.slug}`} flexDirection="column" {...wrapperStyles}>
              {React.cloneElement(step.component, {
                eventId,
                nextStep,
                previousStep,
                goToStep,
                variant,
                createEventForPurchaseHandler,
                showWaitListAlert,
                eventForRegistration,
              })}
            </Center>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
