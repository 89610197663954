import { ByopTileInput, MutationUpdateByopTileArgs } from '@companydotcom/types';
import { emptySplitApi } from '../../store/empty-split-api';
import { CREATE_BYOP_TILE } from './graphql/tile-mutation';

export const TileApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    createByopTile: builder.mutation<
      | {
          data: any;
        }
      | {
          error: unknown;
        },
      ByopTileInput
    >({
      query: options => ({
        method: 'mutation',
        document: CREATE_BYOP_TILE,
        variables: {
          input: options,
        },
      }),
      transformResponse: (rawResult: { data: { createByopTile: any } }) =>
        rawResult.data.createByopTile,
    }),
    updateByopTile: builder.mutation<any, MutationUpdateByopTileArgs>({
      query: ({ byopTileId, userId, isHidden, seqNo, hasBeenLaunched }) => ({
        method: 'mutation',
        document: CREATE_BYOP_TILE,
        variables: {
          byopTileId,
          userId,
          isHidden,
          seqNo,
          hasBeenLaunched,
        },
      }),
      transformResponse: (rawResult: { data: { updateByopTile: any } }) =>
        rawResult.data.updateByopTile,
    }),
  }),
});

export const { useCreateByopTileMutation, useUpdateByopTileMutation } = TileApi;
