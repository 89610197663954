import { ComponentStyleConfig } from '@companydotcom/potion';

const parts = ['label', 'icons', 'container'];

type Dict = Record<string, any>;

const baseStyleContainer = { alignItems: 'center', width: 'full' };

const baseStyleLabel = {
  textTransform: 'uppercase',
  fontWeight: 'medium',
  color: 'gray.400',
  letterSpacing: 1.5,
};

function baseStyleIcons(props: Dict) {
  const { colorScheme: c } = props;
  return {
    color: `${c}.500`,
    borderRadius: 'none',
  };
}

const baseStyle = (props: Dict) => ({
  label: baseStyleLabel,
  icons: baseStyleIcons(props),
  container: baseStyleContainer,
});

const defaultProps = {
  colorScheme: 'blue',
};

export default { parts, baseStyle, defaultProps } as ComponentStyleConfig;
