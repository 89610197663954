import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Flex,
  Text,
  Stack,
  Heading,
  Button,
  Center,
  HStack,
  UnorderedList,
  ListItem,
  VStack,
  Tooltip,
} from '@companydotcom/potion';
import { PasswordInputField, InputField, FontAwesomeIcon } from '@companydotcom/ui';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useLazyScanDarkWebQuery } from '../../../services/dark-web-scan/dark-web-scan-api';
import { useAppDispatch, useAwaitableFacade } from '../../../hooks';
import { updateDarkWebScanResults } from '../dark-web-scan-slice';

const DarkWebFormSchema = yup
  .object()
  .shape({
    accountNumberOne: yup.string(),
    accountNumberTwo: yup.string(),
    email: yup.string(),
    ssn: yup.string(),
  })
  .test({
    name: 'at-least-one-required',
    test(values) {
      const ensuredValues = values;
      const anyFieldValid = !!(
        ensuredValues.accountNumberOne ||
        ensuredValues.accountNumberTwo ||
        ensuredValues.email ||
        ensuredValues.ssn
      );
      if (!anyFieldValid) {
        return this.createError({
          path: 'field1 | field2',
          message: 'One field must be set',
        });
      }
      return true;
    },
  });

export const DarkWebForm: React.FC<any> = ({ nextStep, globalUser }) => {
  const [scanDarkWeb] = useLazyScanDarkWebQuery();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const snsInterface = useAwaitableFacade(globalUser, 'darkWebScan');

  const defaultValues = {
    accountNumberOne: '',
    accountNumberTwo: '',
    email: '',
    ssn: '',
  };

  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(DarkWebFormSchema),
    defaultValues,
  });

  const { isSubmitting, isValid } = formState;

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      const searchTerms = Object.entries(values).map(obj => ({
        term: obj[1],
        key: obj[0],
      }));

      if (globalUser) {
        const res = await scanDarkWeb({
          snsInterface,
          searchTerms,
        });
        if (res) {
          // @ts-ignore
          dispatch(updateDarkWebScanResults(res?.data ?? []));
        }
      }
      nextStep?.();
    } catch (err) {
      console.log('Error scanning dark web: ', err);
    }
  };

  return (
    <Center
      className="dws-form"
      flexDirection="column"
      pt={[12, null, 10]}
      pb={[12, null, 16]}
      width="full"
      px={4}
    >
      <Box textAlign="center" maxWidth={732} mb={12}>
        <Heading as="h1" size="hs-xl">
          {t('containers.darkWebScan.form.header')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          {t('containers.darkWebScan.form.subheader')}
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('containers.darkWebScan.form.securityNotice')}
        </Text>
        <HStack spacing={2} flexDirection="row" justifyContent="center" alignItems="center" mt={3}>
          <FontAwesomeIcon color="gray.600" icon={faLock} />
          <Text textStyle="md" textAlign="center">
            {t('containers.darkWebScan.form.secureAndEncrypted')}
          </Text>
          <Tooltip shouldWrapChildren label={t('containers.darkWebScan.form.securityTooltip')}>
            <FontAwesomeIcon boxSize={3} color="blue.400" icon={faCircleExclamation} />
          </Tooltip>
        </HStack>
      </Box>

      <Stack
        spacing={[10, null, 4]}
        justify="center"
        direction={['column', null, null, 'row']}
        width="full"
        as="form"
      >
        <VStack spacing={[10]} alignItems="center" mx="auto" maxW={328}>
          <PasswordInputField
            register={register}
            name="accountNumberOne"
            label={t('containers.darkWebScan.detailedResults.account1')}
          />
          <PasswordInputField
            register={register}
            name="accountNumberTwo"
            label={t('containers.darkWebScan.detailedResults.account2')}
          />
          <InputField register={register} name="email" label={t('common.inputs.email.label')} />
          <PasswordInputField
            register={register}
            name="ssn"
            label={t('containers.darkWebScan.detailedResults.ssnOrEin')}
            helperText={t('containers.darkWebScan.detailedResults.ssnOrEinFull')}
          />
        </VStack>

        <Flex
          className="helpful-tips"
          flexDirection="column"
          textAlign="left"
          bg="gray.100"
          borderRadius="md"
          border="1px solid"
          borderColor="gray.200"
          maxW={498}
          width="full"
          p={[4, null, null, 9]}
          height="fit-content"
          mx="auto"
        >
          <Heading size="hs-md" mb={6}>
            {t('containers.darkWebScan.form.tipsHeader')}
          </Heading>
          <UnorderedList>
            <ListItem>{t('containers.darkWebScan.form.tip1')}</ListItem>
            <ListItem>{t('containers.darkWebScan.form.tip2')}</ListItem>
            <ListItem>{t('containers.darkWebScan.form.tip3')}</ListItem>
            <ListItem>{t('containers.darkWebScan.form.tip4')}</ListItem>
          </UnorderedList>
        </Flex>
      </Stack>
      <Flex flexDirection={['column', null, 'row']} mt={12}>
        <Button
          size="lg"
          type="submit"
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
        >
          {t('containers.darkWebScan.form.submit')}
        </Button>
      </Flex>
    </Center>
  );
};
