import { Button, ButtonProps } from '@companydotcom/potion';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';

export interface EditButtonProps extends ButtonProps {
  formSlug?: string;
  onOpen?: () => void;
  setFormSlug?: () => void;
}

export const EditButton = (props: EditButtonProps) => {
  const { setFormSlug, ...rest } = props;

  return (
    <span>
      <Button minWidth={4} onClick={setFormSlug} cursor="pointer" variant="link" {...rest}>
        <FontAwesomeIcon icon={faPencil} />
      </Button>
    </span>
  );
};
