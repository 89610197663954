import { createContext } from '@companydotcom/potion';
export const defaultAssets = {
    email_sent: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/email_sent.svg`,
    logo: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/logo.svg`,
    footer_logo: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/logo.svg`,
    sidenav_logo: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/logo-white.svg`,
    tile_icon: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/products/logos/svg/`,
    icon_checkmark: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/icons/icon-checkmark.png`,
    icon_error: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/icons/icon-error.png`,
    icon_email: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/images/icons/icon-email.png`,
    learn_more: `${process.env.REACT_APP_IMG_URL}/dashboard_nextgen/company/marketing_pages/`,
};
const [AssetsProvider, useAssets] = createContext({
    strict: false,
    name: 'AssetsContext',
    errorMessage: 'useAssets: `assets` is undefined. Seems you forgot to wrap the app in `<AssetsProvider />` ',
});
export { AssetsProvider, useAssets };
