import React from 'react';
import { Box, Stack, VStack, Text } from '@companydotcom/potion';
import { InputField, SelectCountryField, SelectRegionField } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { UseFormRegister, FieldValues, Control } from 'react-hook-form';

export interface BillingAddressFormProps {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  watch: any;
  errors: unknown;
  isLoading?: boolean;
  displayBillingAddressForm: boolean;
  isSavedPayment?: boolean;
}

export const BillingAddressForm = ({
  displayBillingAddressForm,
  isSavedPayment,
  isLoading,
  errors,
  register,
  control,
  watch,
}: BillingAddressFormProps) => {
  const { t } = useTranslation();
  return (
    <Box
      className="billing-address"
      width="full"
      pt={8}
      display={displayBillingAddressForm && !isSavedPayment ? 'inherit' : 'none'}
    >
      <VStack spacing={6}>
        <Text fontWeight={700} fontSize="lg" alignSelf="flex-start">
          {t('common.paymentInfo.billingAddress')}
        </Text>
        {control && (
          <SelectCountryField
            isLoading={isLoading}
            control={control}
            name="billingDetails.country"
            label={t('common.inputs.country.label')}
          />
        )}
        <InputField
          isLoading={isLoading}
          className="billing-address__addressLine1"
          register={register}
          name="billingDetails.addressLine1"
          placeholder={t('common.inputs.address.placeholder')}
          label={t('common.inputs.address.label')}
          errors={errors}
        />
        <InputField
          isLoading={isLoading}
          className="billing-address__addressLine2"
          register={register}
          name="billingDetails.addressLine2"
          placeholder={t('common.inputs.addressLine2.placeholder')}
          errors={errors}
        />
        <InputField
          isLoading={isLoading}
          className="billing-address__city"
          register={register}
          name="billingDetails.city"
          label={t('common.inputs.city.label')}
          errors={errors}
        />
        <Stack
          direction={['column', null, 'row', 'row']}
          spacing={8}
          width="full"
          align="flex-start"
        >
          {control && (
            <SelectRegionField
              isLoading={isLoading}
              country={watch?.('billingDetails.country')}
              control={control}
              name="billingDetails.state"
              label={t('common.inputs.state.label')}
            />
          )}
          <InputField
            isLoading={isLoading}
            className="billing-address__postal-code"
            register={register}
            name="billingDetails.postalCode"
            label={t('common.inputs.postalCode.label')}
            errors={errors}
          />
        </Stack>
      </VStack>
    </Box>
  );
};
