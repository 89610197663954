import gql from 'graphql-tag';

export const GET_AUTH_USER = gql`
  query GetAuthUser($userId: String!) {
    getAuthUser(userId: $userId)
  }
`;

export const GET_WELCOME_EMAIL = gql`
  query GetWelcomeEmail($email: String!, $input: any) {
    getWelcomeEmail(email: $email, input: $input)
  }
`;
