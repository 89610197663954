import React, { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Text, Button, Heading, Center } from '@companydotcom/potion';
import { ReactNumberFormatField, SelectField, CheckboxField } from '@companydotcom/ui';
import { companyQL } from '@companydotcom/services';
import type { DataCollectorFormProps } from '../../../features/data-collector';
import { monthDropdownValues, generateYears } from '../../../utils';
import { useUpdateLoanMutation } from '../../../services/user/user-api';
// import { updateLoanData } from '../../../services/user/old-api/user-svc';

const getBusinessFundingFormSchema = () =>
  yup.object().shape({
    amountRequired: yup.string().required('forms.businessFunding.inputs.amountRequired.error'),
    annualRevenue: yup.string().required('forms.businessFunding.inputs.annualRevenue.error'),
    creditScore: yup.string().required('forms.businessFunding.inputs.creditScore.error'),
    financePurpose: yup.string().required('forms.businessFunding.inputs.financePurpose.error'),
    monthFounded: yup.string().required('forms.businessFunding.inputs.monthFounded.error'),
    termsChecked: yup
      .boolean()
      .oneOf([true], 'forms.businessFunding.inputs.termsChecked.error')
      .required('forms.businessFunding.inputs.termsChecked.error'),
    yearFounded: yup.string().required('forms.businessFunding.inputs.yearFounded.error'),
  });

const refDataQuery = (refType: string, isActive: boolean, locale: string = 'en') => `{
  getReferenceData(refType: "${refType}" isActive: ${isActive} locale: "${locale}") {
    id
    label
    value
  }
}`;

export interface ReferenceValues {
  id: string;
  label: string;
  value: number;
}

export interface BusinessFundingFormProps extends DataCollectorFormProps {
  handleClose?: any;
}

export const BusinessFundingForm: React.FC<BusinessFundingFormProps> = ({
  globalUser,
  handleClose,
  handleDataFormFill,
}) => {
  const [creditOptions, setCreditOptions] = useState<Array<ReferenceValues>>([]);
  const [purposeOptions, setPurposeOptions] = useState<Array<ReferenceValues>>([]);
  const [revenueOptions, setRevenueOptions] = useState<Array<ReferenceValues>>([]);
  const [updateLoanData] = useUpdateLoanMutation();
  const { t } = useTranslation();

  const defaultValues = {
    firstName: globalUser?.firstName || '',
    lastName: globalUser?.lastName || '',
    phone: globalUser?.phone || '',
    accountId: globalUser?.account?.accountId || '',
    businessId: globalUser?.account?.businessPrimary?.businessId || '',
    businessName: globalUser?.account?.businessPrimary?.name || '',
    email: globalUser?.email || '',
    creditScore: globalUser?.account?.loanPrimary?.creditEstimate,
    financePurpose: globalUser?.account?.loanPrimary?.purpose || '',
    amountRequired: globalUser?.account?.loanPrimary?.amountRequired || '',
    annualRevenue: globalUser?.account?.loanPrimary?.annualRevenue || '',
    yearFounded: globalUser?.account?.loanPrimary?.yearFounded || '',
    monthFounded: globalUser?.account?.loanPrimary?.monthFounded || '',
    termsChecked: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setValue,
    control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getBusinessFundingFormSchema()),
    defaultValues,
  });

  useEffect(() => {
    try {
      (async () => {
        const results = (
          await Promise.all([
            companyQL.query({ query: refDataQuery('creditScore', true) }),
            companyQL.query({ query: refDataQuery('financePurpose', true) }),
            companyQL.query({ query: refDataQuery('annualRevenue', true) }),
          ])
        ).map(queryRes => queryRes.data.getReferenceData.sort((a: any, b: any) => a.id - b.id));
        setCreditOptions([{ label: '', value: '', id: '' }, ...results[0]]);

        setPurposeOptions([{ label: '', value: '', id: '' }, ...results[1]]);
        setRevenueOptions([{ label: '', value: '', id: '' }, ...results[2]]);
      })();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useCallback(() => {
    setValue('creditScore', defaultValues.creditScore);
    setValue('annualRevenue', defaultValues.annualRevenue);
    setValue('financePurpose', defaultValues.financePurpose);
  }, [
    setValue,
    defaultValues.creditScore,
    defaultValues.annualRevenue,
    defaultValues.financePurpose,
  ]);

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      const loans = globalUser?.account?.loans ? globalUser?.account?.loans?.[0] : {};

      const loansBody = {
        ...loans,
        creditEstimate: values.creditScore,
        purpose: values.financePurpose,
        amountRequired: values.amountRequired.replace('$', ''),
        annualRevenue: values.annualRevenue,
        dateCreated: globalUser?.account?.dateCreated,
        dateUpdated: globalUser?.account?.dateUpdated,
        monthFounded: values.monthFounded,
        yearFounded: values.yearFounded as number,
        industry: globalUser?.account?.loans?.[0]?.industry || null,
        yearsInBusiness: globalUser?.account?.loans?.[0]?.yearsInBusiness || null,
      };

      const body = {
        accountId: globalUser?.accountId ?? '',
        loans: [loansBody],
      };
      await updateLoanData(body);
      await handleDataFormFill();
      handleClose();
    } catch (err) {
      console.log('Error!: ', err);
    }
  };

  return (
    <Center
      className="form-businessFundingForm__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box textAlign="center">
        <Heading as="h1" size="hs-xl">
          {t('forms.businessFunding.heading')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          <Trans i18nKey="forms.businessFunding.subheading">
            Please add the following information to <br /> access Business Funding.
          </Trans>
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('common.misc.allFieldsRequired')}
        </Text>
      </Box>
      <Box
        as="form"
        className="form-businessFundingForm__form"
        maxWidth={430}
        width="full"
        mt={[8, 10]}
      >
        <SelectField
          className="form-businessFundingForm__creditScoreField"
          data-test="form-businessFundingForm__creditScoreField"
          register={register}
          name="creditScore"
          label={t('forms.businessFunding.inputs.creditScore.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        >
          {creditOptions.map(opt => (
            <option key={opt.id} value={opt.id}>
              {opt.label}
            </option>
          ))}
        </SelectField>
        <SelectField
          className="form-businessFundingForm__financePurposeField"
          data-test="form-businessFundingForm__financePurposeField"
          register={register}
          name="financePurpose"
          label={t('forms.businessFunding.inputs.financePurpose.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        >
          {purposeOptions.map(opt => (
            <option key={opt.id} value={opt.id}>
              {opt.label}
            </option>
          ))}
        </SelectField>
        <SelectField
          className="form-businessFundingForm__annualRevenueField"
          data-test="form-businessFundingForm__annualRevenueField"
          register={register}
          name="annualRevenue"
          label={t('forms.businessFunding.inputs.annualRevenue.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        >
          {revenueOptions.map(opt => (
            <option key={opt.id} value={opt.id}>
              {opt.label}
            </option>
          ))}
        </SelectField>
        <SelectField
          className="form-businessFundingForm__monthFoundedField"
          data-test="form-businessFundingForm__monthFoundedField"
          register={register}
          name="monthFounded"
          label={t('forms.businessFunding.inputs.monthFounded.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        >
          {monthDropdownValues(t).map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </SelectField>
        <SelectField
          className="form-businessFundingForm__yearFoundedField"
          data-test="form-businessFundingForm__yearFoundedField"
          register={register}
          name="yearFounded"
          label={t('forms.businessFunding.inputs.yearFounded.label')}
          errors={errors}
          formControlStyles={{
            marginBottom: 6,
          }}
        >
          {generateYears(1900).map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </SelectField>
        <ReactNumberFormatField
          className="form-businessFundingForm__amountRequiredField"
          data-test="form-businessFundingForm__amountRequiredField"
          control={control}
          name="amountRequired"
          label={t('forms.businessFunding.inputs.amountRequired.label')}
          thousandSeparator
          prefix="$"
          formControlStyles={{
            marginBottom: 6,
          }}
        />
      </Box>
      <Box textAlign="left" paddingLeft="64px" paddingRight="98px">
        <CheckboxField
          className="form-businessFundingForm__termsCheckedCheckbox"
          data-test="form-businessFundingForm__termsCheckedCheckbox"
          register={register}
          errors={errors}
          name="termsChecked"
          display="inline-block"
          verticalAlign="middle"
          textAlign="justify"
        >
          {' '}
          <Text as="span" fontSize="sm">
            {t('common.misc.iHaveReadBfm')}{' '}
          </Text>
        </CheckboxField>
      </Box>
      <Box textAlign="center" mt={[8, 12]}>
        <Button
          className="form-businessFundingForm__submitButton"
          data-test="form-businessFundingForm__submitButton"
          type="submit"
          size="lg"
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          isDisabled={!isValid}
        >
          {t('common.buttons.confirm')}
        </Button>
      </Box>
    </Center>
  );
};
