import * as yup from 'yup';
import valid from 'card-validator';

const allowedCreditCards = ['american-express', 'visa', 'mastercard'];

export const getCheckoutOrderSchema = (addNewType?: boolean, isAmex?: boolean) =>
  yup.object().shape({
    autoRenew: yup.boolean().notRequired(),
    monthlyInstallments: yup.boolean().notRequired(),
    savePaymentInformation: yup.boolean().notRequired(),
    makeDefault: yup.boolean().notRequired(),
    paymentMethod: yup.string().required(),
    countryForMethod: yup.string().notRequired(),
    cardNickname: yup
      .string()
      .trim()
      .when('savePaymentInformation', {
        is: (savePaymentInformation: boolean) => savePaymentInformation || addNewType,
        then: schema => schema.required('common.misc.required'),
      }),
    cardDetails: yup.object().when('paymentMethod', {
      is: 'credit card',
      then: yup.object().shape({
        cardHolderName: yup
          .string()
          .trim()
          .required('acg.checkout.orderStep.inputs.cardholderName.required'),
        cardNumber: yup
          .string()
          .trim()
          .test('is-valid-cc-number', (val, { createError }) =>
            valid.number(val).isValid &&
            allowedCreditCards.includes(valid.number(val).card?.type ?? '')
              ? true
              : createError({
                  message: 'acg.checkout.orderStep.inputs.cardholderNumber.required',
                }),
          )
          .required('acg.checkout.orderStep.inputs.cardholderNumber.required'),
        expirationMonth: yup
          .string()
          .test('is-valid-date', (val, { createError, parent }) =>
            valid.expirationDate({ month: val, year: parent.expirationYear }).isValid
              ? true
              : createError({ message: 'acg.checkout.orderStep.inputs.expirationMonth.required' }),
          )
          .required('acg.checkout.orderStep.inputs.expirationMonth.required'),
        expirationYear: yup
          .string()
          .test('is-valid-date', (val, { createError, parent }) =>
            valid.expirationDate({ month: parent.expirationMonth, year: val }).isValid
              ? true
              : createError({ message: 'acg.checkout.orderStep.inputs.expirationMonth.required' }),
          )
          .required('acg.checkout.orderStep.inputs.expirationMonth.required'),
        cardCvv: yup
          .string()
          .trim()
          .when('cardNumber', {
            is: (cardNumber: string) =>
              valid.number(cardNumber)?.card?.type === 'american-express' || isAmex,
            then: yup
              .string()
              .test('is-valid-amex-cvv', (val, { createError }) =>
                valid.cvv(val, 4).isValid
                  ? true
                  : createError({ message: 'acg.checkout.orderStep.inputs.securityCode.required' }),
              )
              .required('acg.checkout.orderStep.inputs.securityCode.required'),
            otherwise: yup
              .string()
              .test('is-valid-cvv', (val, { createError }) =>
                valid.cvv(val, 3).isValid
                  ? true
                  : createError({ message: 'acg.checkout.orderStep.inputs.securityCode.required' }),
              )
              .required('acg.checkout.orderStep.inputs.securityCode.required'),
          }),
      }),
    }),
    achDetails: yup.object().when('paymentMethod', {
      is: 'ach',
      then: yup.object().shape({
        accountHolderName: yup
          .string()
          .trim()
          .required('acg.checkout.orderStep.inputs.accountholderName.required'),
        accountNumber: yup
          .string()
          .trim()
          .required('acg.checkout.orderStep.inputs.accountNumber.required'),
        accountType: yup
          .string()
          .trim()
          .required('acg.checkout.orderStep.inputs.accountType.required'),
        routingNumber: yup
          .string()
          .trim()
          .test('is-valid-routing-number', (val, { createError }) =>
            ABARoutingNumberIsValid(val ?? '')
              ? true
              : createError({ message: 'acg.checkout.orderStep.inputs.routingNumber.required' }),
          )
          .required('acg.checkout.orderStep.inputs.routingNumber.required'),
      }),
    }),
    billingDetails: yup.object().when(['savePaymentInformation', 'paymentMethod'], {
      is: (saveInfo: boolean, method: 'credit card' | 'ach') =>
        !!(saveInfo || (method === 'credit card' && addNewType)),
      then: yup.object().shape({
        addressLine1: yup.string().trim().required('common.misc.required'),
        city: yup.string().trim().required('common.misc.required'),
        country: yup.string().required('common.misc.required'),
        postalCode: yup.string().trim().required('common.misc.required'),
        state: yup.string().required('common.misc.required'),
      }),
    }),
  });

export function ABARoutingNumberIsValid(routingNumberToTest: string) {
  if (!routingNumberToTest) {
    //all 0's is technically a valid routing number, but it's inactive
    return false;
  }

  let routing = routingNumberToTest.toString();
  while (routing.length < 9) {
    routing = `0${routing}`;
  }

  //gotta be 9  digits
  const match = routing.match('^\\d{9}$');
  if (!match) {
    return false;
  }

  //The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
  //https://en.wikipedia.org/wiki/Routing_transit_number
  const firstTwo = parseInt(routing.substring(0, 2), 10);
  const firstTwoValid =
    (firstTwo >= 0 && firstTwo <= 12) ||
    (firstTwo >= 21 && firstTwo <= 32) ||
    (firstTwo >= 61 && firstTwo <= 72) ||
    firstTwo === 80;
  if (!firstTwoValid) {
    return false;
  }

  //this is the checksum
  //http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
  const weights = [3, 7, 1];
  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum += parseInt(routing[i], 10) * weights[i % 3];
  }

  return (10 - (sum % 10)) % 10 === parseInt(routing[8], 10);
}
