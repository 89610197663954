import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@companydotcom/potion';
import type { TileComponentProps } from '../../types';
import { BusinessListingsReport, DirectoryLoading } from '../../../business-listings-report';
import { TileButton } from '../tile-button';

export interface TileListingsReportProps extends TileComponentProps {}

export const TileListingsReport: React.FC<TileListingsReportProps> = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const startLoadingBar = () => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  };

  const openReportModal = () => {
    onOpen();
    startLoadingBar();
  };

  return (
    <>
      <TileButton onClick={openReportModal} text={t('tiles.blpReport.viewMyReport')} />
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={0}>
            {loading ? (
              <DirectoryLoading />
            ) : (
              <BusinessListingsReport toggleModal={onClose} {...props} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
