/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Heading, Button, Divider } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { ExtendedSubscriptionRatePlan, User, SubscriptionTier } from '@companydotcom/types';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon, CircleEnvelopeIcon } from '@companydotcom/ui';
import { VerifyEmail } from '../../../pages/subpages/verify-email/verify-email';
import { calculateOrderTotal } from '../../../utils/helpers/purchasing-helpers';
import { CartStateProps } from '../../../state/reducers/cart-reducer';
import { PaywallStateProps } from '../../../state/reducers/paywall-reducer';

export interface PaymentSuccessProps {
  externalFlow?: boolean;
  directFlow?: boolean;
  redirectUrl?: any;
  eventSource?: any;
  setOpen?: () => void;
  user?: User;
  paymentData?: any;
  selectedTiers?: any;
  cartState?: CartStateProps;
  paywallState?: PaywallStateProps;
  toggleModal?: () => void;
}

export const PaymentSuccess: React.FC<PaymentSuccessProps> = props => {
  const {
    externalFlow,
    directFlow,
    redirectUrl,
    eventSource,
    user,
    paymentData,
    cartState,
    paywallState,
    toggleModal,
  } = props;
  if (
    directFlow &&
    cartState?.selectedRatePlans?.[0].productId !== process.env.REACT_APP_LEGALINC_PRODUCTID
  ) {
    return <VerifyEmail hideShadow productName={cartState?.selectedRatePlans?.[0].name ?? ''} />;
  }
  if (externalFlow) {
    return (
      <ExternalSuccess
        product={cartState?.selectedRatePlans?.[0]}
        cart={cartState?.selectedRatePlans}
        redirectUrl={redirectUrl}
        eventSource={eventSource}
      />
    );
  }
  return (
    <InternalSuccess
      user={user}
      cart={!isEmpty(cartState?.selectedRatePlans) ? cartState?.selectedRatePlans : []}
      eventSource={eventSource || paywallState?.eventSource}
      onCompleteHandler={paywallState?.onSuccessHandler}
      setOpen={toggleModal}
      paymentData={paymentData}
      selectedTiers={cartState?.selectedTiers}
    />
  );
};

export const ExternalSuccess = ({ product, cart, redirectUrl }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Flex className="paywall__externalSuccess" flexDirection="column" alignItems="center">
      <Box mt={[12, 16]} mb={[8]} sx={{ textAlign: 'center' }}>
        <CircleEnvelopeIcon />
      </Box>
      <Heading size="hs-2xl">Thank You</Heading>
      <Heading size="hs-md">
        {t('paywall.success.header', {
          productName: product.productName,
          ratePlan: cart[0].name ? ` ${cart[0].name}` : '',
        })}
      </Heading>
      {product?.productName === 'Website' ? (
        <Box my={9} />
      ) : (
        <>
          <Divider sx={{ my: 9 }} width="332px" height="1px" />
          <Button
            mt={[12, 14]}
            mb={[13, 16]}
            size="lg"
            onClick={() => {
              if (redirectUrl) {
                window.open(redirectUrl, '_self');
              } else {
                navigate('/');
              }
            }}
          >
            {redirectUrl
              ? t('paywall.success.continue', { productName: product.name })
              : t('paywall.success.continueDashboard')}
          </Button>
        </>
      )}
    </Flex>
  );
};
setTimeout(ExternalSuccess, 3000);
interface InternalSuccessProps {
  user?: User;
  cart?: ExtendedSubscriptionRatePlan[];
  eventSource?: string;
  setOpen?: () => void;
  paymentData?: any;
  selectedTiers?: SubscriptionTier[];
  onCompleteHandler?: () => void;
}

export const InternalSuccess = ({
  user,
  eventSource,
  setOpen,
  paymentData,
  cart,
  selectedTiers,
  onCompleteHandler,
}: InternalSuccessProps) => {
  const navigate = useNavigate();
  const [paidAmount, setPaidAmount] = useState('');
  const [cardEndingDigits, setCardEndingDigits] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const emailSeatQty =
    selectedTiers?.[0]?.qty &&
    selectedTiers?.[0]?.qty - (cart?.[0].tiers?.find?.(t => t?.isActive)?.qty ?? 0);

  const getLastFourDigits = (cardNumber: any) => {
    return cardNumber
      .split('')
      .filter((i: string) => i !== '*')
      .join('');
  };

  useEffect(() => {
    if (
      !user?.account?.allowPartnerBilling &&
      paymentData &&
      paymentData.card &&
      paymentData.invoice
    ) {
      const {
        card: { masked_number: cardNumber, creditCards },
        invoice: { sub_total, total },
      } = paymentData;
      const totalPrice = cart ? calculateOrderTotal(cart, selectedTiers) : 0;
      setCardEndingDigits(cardNumber ? getLastFourDigits(cardNumber) : '');
      const subTotal: any = sub_total / 100; // convert from cents to dollars
      setPaidAmount(subTotal > totalPrice ? subTotal : totalPrice);
      setLoading(false);
    }
  }, [paymentData, cart, user?.account?.allowPartnerBilling, selectedTiers]);

  return (
    <Flex flexDirection="column" alignItems="center" px={4} bg="white">
      <Box mt={[12, 16]} mb={[8]}>
        <CheckCircleIcon mx="auto" />
      </Box>
      <Heading size="hs-2xl">{t('paywall.success.thankYou')}</Heading>
      <Flex flexDirection="column" p={2} textAlign="center">
        {eventSource === 'addNewUserFlow' ? (
          <>
            <Text variant="h4">{t('paywall.success.emailNotice_other', { emailSeatQty })}</Text>
            <Divider borderColor="gray.300" sx={{ mx: 'auto', minWidth: 332, mt: 6 }} />
          </>
        ) : user?.account?.allowPartnerBilling ? (
          <>
            <Heading size="hs-md">{t('paywall.success.partnerBilled.header')}</Heading>
            <Divider sx={{ my: 9, mx: 'auto' }} width="332px" height="1px" borderColor="gray.300" />
            <Heading size="hs-md">{t('paywall.success.partnerBilled.subheader')}</Heading>
          </>
        ) : (
          <>
            <Text textStyle="lg">
              {loading && paidAmount && cardEndingDigits
                ? t('paywall.success.directBilled.loading')
                : t('paywall.success.directBilled.billNotice', {
                    cardEndingDigits: (cardEndingDigits || '').toString(),
                    price: parseFloat(paidAmount).toFixed(2).toString(),
                  })}
            </Text>
            <Divider sx={{ my: 9, mx: 'auto' }} width="332px" height="1px" />

            <Text textStyle="lg">{t('paywall.success.directBilled.receipt')}</Text>

            <Button
              variant="link"
              fontSize="lg"
              cursor="text"
              whiteSpace="normal"
              _hover={{
                textDecoration: 'none',
              }}
            >{`${user?.email}`}</Button>
          </>
        )}
      </Flex>
      <Text textStyle="md" my={[eventSource === 'addNewUserFlow' ? 6 : 12]}>
        {eventSource === 'addNewUserFlow'
          ? t('paywall.success.copyAddNewUserFlow')
          : t('paywall.success.copy')}
      </Text>
      <Box mb={[13, 16]}>
        <Button
          size="lg"
          onClick={() => {
            if (eventSource === 'miop') {
              navigate('/');
            }
            onCompleteHandler?.();
            setOpen?.();
          }}
        >
          {eventSource === 'addNewUserFlow'
            ? t('common.buttons.continue')
            : t('common.buttons.gotIt')}
        </Button>
      </Box>
    </Flex>
  );
};
