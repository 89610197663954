import dayjs from 'dayjs';
import { convertTimestampToArray, createEvent } from 'ics';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSource } from '@companydotcom/providers';
import { useMemo } from 'react';
import { getFriendlyUTCDate } from '@companydotcom/helpers';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { cloneDeep, uniq } from 'lodash';
import * as yup from 'yup';
import type { AcgPurchasedEvent } from '@companydotcom/types';
import {
  EventForPurchase,
  EventForPurchaseGuest,
  QuestionType,
  CustomQuestion,
  QAndA,
  RegistrantAndFeePackage,
  ADAFields,
} from '../../../../services/acg/acg.types';
import { useGetGlobalUserQuery } from './../../../../services/user/user-api';
import {
  useGetAcgUserProfileV2Query,
  useGetAcgQueryArgs,
} from '../../../../services/acg/acg-api-v2';

export const mapEventKeysForPurchase = (eventForPurchase: EventForPurchase) => {
  const newObj = cloneDeep(eventForPurchase);

  if (newObj.eventCapacity) {
    delete newObj.eventCapacity;
  }

  if (newObj.totalRegistered) {
    delete newObj.totalRegistered;
  }

  if (newObj.showWaitListAlert) {
    delete newObj.showWaitListAlert;
  }

  if (newObj.waitListedRegistrants) {
    delete newObj.waitListedRegistrants;
  }

  if (newObj.isMyEventsFlow) {
    delete newObj.isMyEventsFlow;
  }

  if (newObj?.registrant?.fees && newObj?.registrant.fees?.length) {
    newObj.registrant.fees = newObj.registrant.fees.map(fee => ({
      ...fee,
      priceRecordKey: fee.eventFees.priceKey,
    }));
  }

  if (newObj?.guests && newObj?.guests.length) {
    newObj?.guests.forEach(guest => {
      if (guest?.fees && guest?.fees?.length) {
        guest.fees = guest.fees.map(fee => ({
          ...fee,
          priceRecordKey: fee.priceKey,
        }));
      }
    });
  }

  return newObj;
};

// FRESHWORKS WIDGET FUNCTIONS
export const hideFWWidget = () => {
  // eslint-disable-next-line @typescript-eslint/no-implied-eval
  const func = Function(`return FreshworksWidget('hide', 'launcher')`);
  func();
};

export const showFWWidget = () => {
  // eslint-disable-next-line @typescript-eslint/no-implied-eval
  const func = Function(`return FreshworksWidget('show', 'launcher')`);
  func();
};

export const openFWWidget = () => {
  // eslint-disable-next-line @typescript-eslint/no-implied-eval
  const func = Function(`return FreshworksWidget('open')`);
  func();
};

export const loadFreshworks = () => {
  const existingScript = document.getElementById('freshworks__widget');
  if (!existingScript) {
    const widget = document.createElement('script');
    widget.id = 'freshworks__widget';
    widget.type = 'text/javascript';
    widget.innerHTML = `
    window.fwSettings = {
      'widget_id': 70000003150
    };
    !function () { if ("function" != typeof window.FreshworksWidget) { var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n } }();`;

    const source = document.createElement('script');
    source.id = 'freshworks-src';
    source.type = 'text/javascript';
    source.src = 'https://widget.freshworks.com/widgets/70000003150.js';
    source.async = true;
    source.defer = true;

    document.body.appendChild(widget);
    document.body.appendChild(source);
  }
};

/**
 * Used to map the question object on events queries to the format that the FormSlice expects
 * @param data
 * @param typeKey the type of component to render
 * @param namePrefix formats the name property on the input, for tying into yup and react-hook-form
 * @returns
 */
export function eventsFormSliceMapper(data: CustomQuestion[], namePrefix?: string) {
  const sharedFields = (q: CustomQuestion) => {
    return {
      ...q,
      name: namePrefix ? `${namePrefix}.${q?.questionId}` : q?.questionId,
      label: q.questionName,
      is_required: q?.isRequired,
    };
  };

  return data
    .filter(d => d.questionId !== ADAFields.DIETARY && d.questionId !== ADAFields.ADA)
    ?.map(q => {
      if (q['questionType'] === QuestionType.LARGETEXT) {
        return {
          slice_type: 'textarea',
          fields: sharedFields(q),
        };
      }

      if (q['questionType'] === QuestionType.TEXT) {
        return {
          slice_type: 'input',
          fields: sharedFields(q),
        };
      }

      if (q['questionType'] === QuestionType.FLAG) {
        return {
          slice_type: 'checkbox',
          fields: sharedFields(q),
        };
      }

      if (
        q['questionType'] === QuestionType.NUMBER &&
        q &&
        q?.numberOfDecimalPlaces &&
        q?.numberOfDecimalPlaces > 0
      ) {
        return {
          slice_type: 'currency',
          fields: { ...sharedFields(q), decimal_places: q?.numberOfDecimalPlaces },
        };
      }

      if (q['questionType'] === QuestionType.DATEPICKER) {
        return {
          slice_type: 'datepicker',
          fields: sharedFields(q),
        };
      }

      if (q['questionType'] === QuestionType.NUMBER && !q?.numberOfDecimalPlaces) {
        return {
          slice_type: 'number',
          fields: sharedFields(q),
        };
      }

      if (q['questionType'] === QuestionType.DROPDOWN) {
        return {
          slice_type: 'dropdown',
          fields: {
            ...sharedFields(q),
            options: q?.selectionOptions,
          },
        };
      }

      if (
        q['questionType'] === QuestionType.CHECKBOXGROUP ||
        q['questionType'] === QuestionType.CHECKBOXES
      ) {
        return {
          slice_type: 'checkboxgroup',
          fields: {
            ...sharedFields(q),
            options: q?.selectionOptions,
          },
        };
      }

      return { slice_type: 'text', fields: sharedFields(q) };
    });
}

export type EventsFormSliceMapperReturn = ReturnType<typeof eventsFormSliceMapper>;

export const getCustomQuestionsSchema = (data?: CustomQuestion[]) =>
  data &&
  eventsFormSliceMapper(data)?.reduce((acc, curr) => {
    const createPropSchema = () => {
      if (curr?.slice_type === 'checkbox') {
        return curr?.fields?.is_required
          ? yup.boolean().oneOf([true], 'common.misc.required').required('common.misc.required')
          : yup.boolean().oneOf([true, false]).notRequired();
      }

      if (curr?.slice_type === 'checkboxgroup') {
        return curr?.fields?.is_required
          ? yup
              .array()
              .of(yup.string())
              .min(1, 'common.misc.required')
              .required('common.misc.required')
              .nullable()
          : yup.array().of(yup.string()).notRequired().nullable();
      }

      if (curr?.slice_type === 'datepicker') {
        return curr?.fields?.is_required
          ? yup.date().required('common.misc.required')
          : yup.date().notRequired();
      }

      return curr?.fields?.is_required
        ? yup.string().required('common.misc.required')
        : yup.string().notRequired();
    };

    const propSchema = createPropSchema();
    return {
      ...acc,
      [curr?.fields?.name]: propSchema,
      // Ada requirements
      [ADAFields.ADA]: yup.string().notRequired(),
      // Dietary
      [ADAFields.DIETARY]: yup.string().notRequired(),
    };
  }, {} as any);

/**
 * Formats the date for an ACG event
 */
export const formatEventDate = (eventStartDate: Date, eventEndDate: Date) => {
  return `${dayjs(eventStartDate).format('MMMM DD, YYYY')} - ${dayjs(eventEndDate).format(
    'MMMM DD, YYYY',
  )}`;
};

/**
 * Converts a string in this format: "400.0000" to this: 400.00
 * @param price a string representing a price
 * @returns
 */
export const formatCheckoutPrice = (price?: string | number) => {
  if (!price) return '0.00';
  return typeof price === 'number' ? price?.toFixed(2) : parseFloat(price).toFixed(2);
};

export const getEventFeeList = (eventForPurchase?: EventForPurchase) => {
  if (eventForPurchase) {
    if (
      eventForPurchase.registrant?.fees &&
      (!eventForPurchase.guests || !eventForPurchase?.guests.length)
    ) {
      let arr = [] as any;
      arr.push(eventForPurchase?.registrant?.fees?.[0].eventFees);
      eventForPurchase?.registrant?.sessions?.forEach(session => {
        if (
          session ===
          eventForPurchase?.registrant?.fees?.[0].sessions.find(x => x.sessionId === session)
            ?.sessionId
        ) {
          arr.push(
            eventForPurchase?.registrant?.fees?.[0].sessions.find(x => x.sessionId === session),
          );
        }
        const flatSessFees = eventForPurchase?.registrant?.fees?.[0]?.sessions?.flatMap(x =>
          x?.sessionFees?.flatMap(y => y),
        );

        if (flatSessFees) {
          if (session === flatSessFees.find(fee => fee.sessionFeeId === session)?.sessionFeeId) {
            arr.push(flatSessFees.find(fee => fee.sessionFeeId === session));
          }
        }
      });
      return arr;
    }

    if (eventForPurchase.guests && eventForPurchase.guests.length > 0) {
      const guestFees = eventForPurchase?.guests.reduce(
        (acc: RegistrantAndFeePackage[], cur: EventForPurchaseGuest, guestIndex: number) => {
          if (cur?.fees) {
            const newCur = cloneDeep(cur);
            newCur.fees = newCur.fees?.map(f => ({ ...f, guestIndex }));
            //@ts-ignore
            acc = acc.concat(...(newCur?.fees || []));
          }

          return acc;
        },
        [] as RegistrantAndFeePackage[],
      );
      if (eventForPurchase?.registrant?.fees?.[0]?.eventFees) {
        return [...([eventForPurchase?.registrant?.fees?.[0]?.eventFees] || []), ...guestFees];
      }
      return [...guestFees];
    }
  }
  return [];
};

export const getSessionIdList = (eventForPurchase?: EventForPurchase) => {
  let arr = [] as any;
  eventForPurchase?.registrant?.sessions?.forEach(session => {
    if (
      session ===
      eventForPurchase?.registrant?.fees?.[0].sessions.find(x => x.sessionId === session)?.sessionId
    ) {
      arr.push(
        eventForPurchase?.registrant?.fees?.[0].sessions.find(x => x.sessionId === session)
          ?.sessionId,
      );
    }
    const flatSessFees = eventForPurchase?.registrant?.fees?.[0]?.sessions?.flatMap(x =>
      x?.sessionFees?.flatMap(y => y),
    );

    if (flatSessFees) {
      if (session === flatSessFees.find(fee => fee.sessionFeeId === session)?.sessionFeeId) {
        arr.push(flatSessFees.find(fee => fee.sessionFeeId === session)?.sessionId);
      }
    }
  });
  arr = uniq(arr);
  return arr;
};

/** custom questions with multiple answers (dropdown, checkbox group) expected a semicolon delimited list in order to save */
export function formatAnswersForDispatch(obj: object | Record<string, any>) {
  return Object.keys(obj)?.reduce?.((acc, curr) => {
    return {
      ...acc,
      [curr]: Array.isArray(obj[curr]) ? obj[curr]?.join(',') : obj[curr],
    };
  }, {} as Record<string, any>);
}

/** our form slice zone components need to be in a certain format */
export function formatQuestionsForYup(questions?: QAndA[]) {
  return questions?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr?.qAndAKey]: curr?.answer ?? undefined,
    };
  }, {} as Record<string, any>);
}

/**
 * Checks if a phone number from Rhythm for ACG is a US number or not
 * @param phoneNumber
 * @returns
 */
export function formatRhythmPhoneToUS(phoneNumber: string, countryCode: CountryCode = 'US') {
  // If first item is parens, its US phone number from rhythm
  if (
    (phoneNumber?.charAt(0) === '(' || countryCode === 'US') &&
    phoneNumber?.charAt(0) !== '1' &&
    phoneNumber?.charAt(0) !== '+' &&
    phoneNumber?.substring(0, 1) !== '+1'
  ) {
    return `+${1}${phoneNumber}`;
  }

  return phoneNumber;
}

export function formatRhythmPhoneNumber(phoneNumber: string, countryCode?: CountryCode) {
  if (phoneNumber.charAt(0) === '(') {
    return phoneNumber;
  }

  const phone = parsePhoneNumber(
    phoneNumber.charAt(0) !== '+' ? '+' + phoneNumber : phoneNumber,
    countryCode,
  );

  if (phone) {
    if (phone?.country === 'US' || countryCode === 'US') {
      return phone?.formatNational();
    } else {
      return phone?.formatInternational();
    }
  }
}

/**
 * Retrieves an uploaded image from Rhythm for ACG
 * @param globalUser
 * @param profilePicture
 * @returns
 */

export const useGetAcgMemberProfileImage = (imageUrl: string) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const accessToken = globalUser?.mads?.['user-acg']?.find?.(
    (i: any) => i?.key === 'rhythmAuth0Token',
  )?.value;

  const query = new URLSearchParams({
    access_token: accessToken,
  }).toString();

  let memberAvatarUrl = useMemo(() => {
    if (!imageUrl) {
      return undefined;
    }

    return `${imageUrl?.replace(
      'service://platform-file-uploads/v1',
      'https://platform.api.rhythmsoftware.com/fileUploads',
    )}/image?${query}`;
  }, [imageUrl, query]);

  return memberAvatarUrl;
};

export const useGetAcgProfileImage = () => {
  const source = useSource();
  const { data: globalUser } = useGetGlobalUserQuery();
  const args = useGetAcgQueryArgs();
  const { data: acgUserProfileData } = useGetAcgUserProfileV2Query(
    globalUser && source?.sourceId === 'acg'
      ? { ...args, email: globalUser?.email, accountId: globalUser?.accountId }
      : skipToken,
  );

  const query = new URLSearchParams({
    access_token: args?.accessToken,
  }).toString();

  const acgAvatarUrl = useMemo(() => {
    if (!acgUserProfileData?.profilePicture) {
      return undefined;
    }

    return `${acgUserProfileData?.profilePicture?.replace(
      'service://platform-file-uploads/v1',
      'https://platform.api.rhythmsoftware.com/fileUploads',
    )}/image?${query}`;
  }, [acgUserProfileData?.profilePicture, query]);

  return {
    acgAvatarUrl,
    query,
  };
};
// ******************* DATE HELPERS ******************

/** ACG displays times in UTC format, so this function helps us reuse that plugin  Default format is 'MM/DD/YYYY' */
export const getFriendlyACGDate = (date: string | Date, template?: string) => {
  return getFriendlyUTCDate(date, template ?? 'MM/DD/YYYY');
};

// dd mm yyy
export const readableDate = (arg: string) => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' } as const;
  const toDate = new Date(arg.replace(/-/g, '/').replace(/T.+/, ''));
  return toDate.toLocaleDateString('en-GB', options).slice(0, 15);
};

export const sameMonth = (arg: string, arg1: string) => {
  const start = new Date(arg);
  const end = new Date(arg1);
  return start.getMonth() === end.getMonth();
};

export const generateIcsUrl = (event: AcgPurchasedEvent) => {
  const start = convertTimestampToArray(dayjs(event?.eventStartDate).utc().unix() * 1000, 'local');
  const end = convertTimestampToArray(dayjs(event?.eventEndDate).utc().unix() * 1000, 'local');

  const icsEvent = event?.eventLocation
    ? createEvent({
        start,
        end,
        title: event?.eventName || '',
        location: `${event?.eventLocation}${
          event?.eventLocationPlace ? ` - ${event?.eventLocationPlace}` : ''
        }`,
      })
    : createEvent({
        start,
        end,
        title: event?.eventName || '',
      });

  return encodeURI('data:text/calendar;charset=utf8,' + icsEvent?.value);
};

export const filterOutAdaFields = (customQuestions: CustomQuestion[]) => {
  return customQuestions?.filter(
    q => q.questionId !== ADAFields.ADA && q.questionId !== ADAFields.DIETARY,
  );
};
