const authMutation = {
  createAndSendEmailCode: `mutation createAndSendEmailCode($userId: String!, $email: String) {
    createAndSendEmailCode(userId: $userId, email: $email)
  }`,
  updateVerifyEmailCode: `mutation updateVerifyEmailCode($userId: String!, $email: String) {
    updateVerifyEmailCode(userId: $userId, email: $email)
  }`,
  linkAccounts: `mutation linkAccounts ($input: LinkAccountInput!) {
    linkAccounts(input: $input)
  }`,
};

export default authMutation;
