import React from 'react';
import { Flex, TabPanel, Button, Text, Link, Tag, HStack } from '@companydotcom/potion';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { faCalendarPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@companydotcom/ui';
import { TFunction, useTranslation } from 'react-i18next';
import { isEventPassed, isSameDay, isSameMonth } from '@companydotcom/helpers';
import { TileCarousel } from '../tile-carousel';
import { TileSpinner } from '../tile-spinner';
import { useAppDispatch } from '../../../../hooks';
import { updateActiveEvent } from '../../../acg/acg-my-events/my-events-slice';
import { generateIcsUrl } from '../../../acg/shared';
import {
  useGetAcgQueryArgs,
  useGetUserPurchasedEventsV2Query,
} from '../../../../services/acg/acg-api-v2';
import { AcgPurchasedEvent, Maybe } from '@companydotcom/types';

export const TileAcgMyEvents = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const args = useGetAcgQueryArgs();
  const { data: purchasedEvents, isLoading } = useGetUserPurchasedEventsV2Query(args);

  const viewLabels = ['Attending', 'Past Events'];
  const pastEvents = purchasedEvents?.filter(
    event => event?.eventEndDate && isEventPassed(event?.eventEndDate),
  );
  const currentEvents = purchasedEvents?.filter(
    event => event?.eventEndDate && !isEventPassed(event?.eventEndDate),
  );

  const tabs = [
    <EventList
      events={
        currentEvents && currentEvents?.length > 10 ? currentEvents?.slice(0, 10) : currentEvents
      }
      variant="current"
      t={t}
    />,
    <EventList
      events={pastEvents && pastEvents?.length > 10 ? pastEvents?.slice(0, 10) : pastEvents}
      variant="past"
      t={t}
    />,
  ];

  if (isLoading) {
    return <TileSpinner />;
  }

  return (
    <Flex
      flexDirection="column"
      flexGrow={1}
      width="full"
      justifyContent="center"
      alignItems="center"
    >
      <TileCarousel viewLabels={viewLabels}>
        {tabs?.map((c, k) => (
          <TabPanel
            key={k}
            display="flex"
            flexDirection="column"
            width="full"
            height="100%"
            padding={0}
            alignItems="center"
          >
            {React.cloneElement(c)}
          </TabPanel>
        ))}
      </TileCarousel>
      <Button
        variant="tile"
        onClick={() => {
          navigate('/my-events');
        }}
        width="fit-content"
        size="md"
        type="submit"
      >
        MY REGISTRATIONS
      </Button>
    </Flex>
  );
};

interface EventListProps {
  events?: Maybe<AcgPurchasedEvent>[];
  variant: 'current' | 'past';
  t: TFunction;
}

const EventList = ({ events, variant, t }: EventListProps) => {
  if (events && events?.length) {
    return (
      <Flex flexDirection="column" maxHeight="246px" overflow="auto" width="full" px={4}>
        {events?.map((event, key) =>
          event ? <EventItem key={key} event={event} variant={variant} i={key} t={t} /> : null,
        )}
      </Flex>
    );
  }

  return (
    <Text px={4}>
      {variant === 'past'
        ? t(`acg.events.myEventsTile.noPastEvents`)
        : t(`acg.events.myEventsTile.noUpcomingEvents`)}
    </Text>
  );
};

interface EventItemProps extends Omit<EventListProps, 'events'> {
  i: number;
  event: AcgPurchasedEvent;
}

const EventItem = ({ event, variant, i, t }: EventItemProps) => {
  const { eventStartDate, eventEndDate, eventName, eventId, id, onWaitList, listRegistrantsOnWeb } =
    event;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const sameDay = eventStartDate && eventEndDate && isSameDay(eventStartDate, eventEndDate);
  const sameMonth = eventStartDate && eventEndDate && isSameMonth(eventStartDate, eventEndDate);
  const icsURL = generateIcsUrl(event);

  return (
    <Flex flexDirection="column" mb={i === 9 ? 0 : 5}>
      <Flex flexDirection="row" justifyContent="space-between">
        <Text fontSize="xs" opacity={0.7}>
          {eventEndDate && !sameDay
            ? `${dayjs(eventStartDate).format('MMMM D')}
              - ${
                !sameMonth
                  ? dayjs(eventEndDate).format('MMMM D[,] YYYY')
                  : dayjs(eventEndDate).format('D[,] YYYY')
              }`
            : `${dayjs(eventStartDate).format('MMMM D[,] YYYY')}`}{' '}
        </Text>
        {variant === 'current' ? (
          <Link href={icsURL} download={eventName} display="inherit ">
            <FontAwesomeIcon boxSize={4} icon={faCalendarPlus} />
          </Link>
        ) : (
          ''
        )}
      </Flex>
      <Flex flexDirection="column" alignItems="start">
        <HStack>
          <Button
            variant="link"
            textAlign="left"
            lineHeight="tall"
            onClick={
              onWaitList
                ? () => navigate(`/event-registration/?Reg_evt_key=${eventId}`)
                : () => {
                    dispatch(
                      updateActiveEvent({
                        eventName,
                        eventId,
                        id,
                        eventStartDate,
                        eventEndDate,
                      }),
                    );
                    navigate('/my-events', { state: { defaultStep: 'my-events-edit' } });
                  }
            }
          >
            {eventName}
          </Button>
          {onWaitList && eventStartDate && !isEventPassed(eventStartDate) && (
            <Tag
              backgroundColor="orange.500"
              size="sm"
              color="white"
              fontWeight="bold"
              textTransform="uppercase"
              borderRadius="sm"
              // ml="2"
            >
              Waitlist
            </Tag>
          )}
        </HStack>
        {listRegistrantsOnWeb ? (
          <Button
            variant="outline"
            size="xs"
            onClick={() => {
              dispatch(
                updateActiveEvent({
                  eventName,
                  eventId,
                  id,
                  eventStartDate,
                  eventEndDate,
                }),
              );

              navigate('/my-events', { state: { defaultStep: 'my-events-attendee-list' } });
            }}
          >
            VIEW ATTENDEE LIST
          </Button>
        ) : (
          ''
        )}
      </Flex>
      {i === 9 ? (
        <Text fontSize="sm" borderBottomWidth="1px" mt={6} pb={5}>
          {t(`acg.events.myEventsTile.pastEvents`)}
        </Text>
      ) : (
        ''
      )}
    </Flex>
  );
};
