import React from 'react';
import { Box, Text, Heading, Button, Center, ButtonGroup, Link, Icon } from '@companydotcom/potion';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faMessageSms } from '@fortawesome/pro-solid-svg-icons';
import { CircleEnvelopeIcon } from '@companydotcom/ui';
import { NavLink } from 'react-router-dom';
import type { GmbSharedProps } from './gmb-profile-flow';
import { useStartVerificationMutation } from '../../../services/listings/listing-api';
import { useAwaitableFacade } from '../../../hooks';

const RenderBody: React.FC<GmbSharedProps> = ({ selectedVerification, listingId, globalUser }) => {
  const [startVerification] = useStartVerificationMutation();
  const snsInterface = useAwaitableFacade(globalUser, 'listing');
  const { t } = useTranslation();
  if (selectedVerification === 'SMS') {
    return (
      <>
        <Icon
          mx="auto"
          color="green.500"
          as={FontAwesomeIcon}
          boxSize={[14, 28]}
          icon={faMessageSms}
          mb={[5, null, 8]}
        />
        <Heading as="h1" size="hs-xl">
          {t('gmbProfileFlow.resendingVerification.text.heading')}
        </Heading>
        <Trans i18nKey="gmbProfileFlow.resendingVerification.text.subheading">
          <Text textStyle={['lg', 'xl']} mt={[5]}>
            You should recieve a text message to the number listed in your business profile in the
            next few minutes. If you do not receive a text message, check your phone number in the
            last step or
            <Button
              variant="link"
              fontSize={['md', 'lg']}
              onClick={() => {
                // listingSvc.startVerification(listingId, selectedVerification);
                if (listingId) {
                  startVerification({
                    snsInterface,
                    listingId,
                    verificationMethod: selectedVerification,
                  });
                }
              }}
            >
              resend the text.
            </Button>
          </Text>
        </Trans>
      </>
    );
  }

  if (selectedVerification === 'EMAIL') {
    return (
      <>
        <CircleEnvelopeIcon mx="auto" mb={[5, null, 8]} />
        <Heading as="h1" size="hs-xl">
          {t('gmbProfileFlow.resendingVerification.email.heading')}
        </Heading>
        <Trans i18nKey="gmbProfileFlow.resendingVerification.email.subheading">
          <Text textStyle={['lg', 'xl']} mt={[5]}>
            You should recieve an email in the email address used to create your business profile in
            the next few minutes. If you do not receive a email, you can
            <Button
              variant="link"
              fontSize={['md', 'lg']}
              onClick={() => {
                // listingSvc.startVerification(listingId, selectedVerification);
                if (globalUser && listingId) {
                  startVerification({
                    snsInterface,
                    listingId,
                    verificationMethod: selectedVerification,
                  });
                }
              }}
            >
              resend the email.
            </Button>
          </Text>
        </Trans>
      </>
    );
  }

  return (
    <>
      <Icon
        mx="auto"
        color="green.500"
        as={FontAwesomeIcon}
        boxSize={[14, 28]}
        icon={faPhoneVolume}
        mb={[5, null, 8]}
      />
      <Heading as="h1" size="hs-xl">
        {t('gmbProfileFlow.resendingVerification.call.heading')}
      </Heading>
      <Trans i18nKey="gmbProfileFlow.resendingVerification.call.subheading">
        <Text textStyle={['lg', 'xl']} mt={[5]}>
          You should recieve a phone call to the number listed in your business profile in the next
          few minutes. If you do not receive a phone call, check your phone number in the last step
          or
          <Button
            variant="link"
            fontSize={['md', 'lg']}
            onClick={() => {
              // listingSvc.startVerification(listingId, selectedVerification);
              if (globalUser && listingId) {
                startVerification({
                  snsInterface,
                  listingId,
                  verificationMethod: selectedVerification,
                });
              }
            }}
          >
            resend the phone call.
          </Button>
        </Text>
      </Trans>
    </>
  );
};

export const ResendingVerification: React.FC<GmbSharedProps> = ({
  selectedVerification,
  listingId,
  // listingSvc,
  goToStep,
  previousStep,
}) => {
  const { t } = useTranslation();

  return (
    <Center
      className="gmb-resendingVerification__container"
      flexDirection="column"
      textAlign="center"
      py={[8, 12]}
      px={4}
    >
      <Box maxW="lg">
        <RenderBody
          selectedVerification={selectedVerification}
          listingId={listingId}
          // listingSvc={listingSvc}
        />
        <ButtonGroup size="lg" mt={12} mb={['3rem', '5rem']}>
          <Button
            variant="outline"
            onClick={() => {
              previousStep?.();
            }}
          >
            {t('common.buttons.back')}
          </Button>

          <Button
            onClick={() => {
              goToStep?.(3);
            }}
          >
            {t('common.buttons.gotIt')}
          </Button>
        </ButtonGroup>
      </Box>
      <Trans i18nKey="gmbProfileFlow.resendingVerification.needHelp">
        <Text textStyle="sm">
          Need Help?
          <Link as={NavLink} to="/help">
            Contact Support
          </Link>
        </Text>
      </Trans>
    </Center>
  );
};
