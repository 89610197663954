import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedPaymentMethod } from '../../../services/acg/acg.types';
import type { RootState } from '../../../store';

export interface SavedPaymentsState {
  savedPayments?: SavedPaymentMethod[];
}

export const initialState: SavedPaymentsState = {
  savedPayments: undefined,
};

export const acgSavedPaymentMethodsSlice = createSlice({
  name: 'acgSavedPaymentMethods',
  initialState,
  reducers: {
    savedPaymentMethods: (state, action: PayloadAction<SavedPaymentMethod[]>) => {
      state.savedPayments = action.payload;
    },
    removePaymentMethod: (state, action: PayloadAction<string>) => {
      const remainingMethods = state.savedPayments?.filter(
        method => method?.recordKey !== action.payload,
      );
      state.savedPayments = remainingMethods;
    },
  },
});

export const { removePaymentMethod, savedPaymentMethods } = acgSavedPaymentMethodsSlice.actions;

export const getSavedPaymentMethods = (state: RootState) =>
  state.acgSavedPaymentMethods.savedPayments;

export default acgSavedPaymentMethodsSlice.reducer;
