import { generateStripe, getColor } from '../../theme-tools';

type Dict = Record<string, any>;

const parts = ['track', 'filledTrack', 'panel'];

function filledStyle(props: Dict) {
  const { colorScheme: c, theme: t, isIndeterminate, hasStripe } = props;

  const stripeStyle = generateStripe();

  const bgColor = `${c}.500`;

  const gradient = `linear-gradient(
    to right,
    transparent 0%,
    ${getColor(t, bgColor)} 50%,
    transparent 100%
  )`;

  const addStripe = !isIndeterminate && hasStripe;

  return {
    ...(addStripe && stripeStyle),
    ...(isIndeterminate ? { bgImage: gradient } : { bgColor }),
  };
}

const baseStyleLabel = {
  lineHeight: '1',
  fontSize: '0.25em',
  fontWeight: 'bold',
  color: 'white',
};

const baseStyleTrack = {
  bg: `gray.100`,
};

function baseStyleFilledTrack(props: Dict) {
  return {
    transition: 'all 0.3s',
    ...filledStyle(props),
  };
}

const baseStyle = (props: Dict) => ({
  label: baseStyleLabel,
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack,
});

const sizes = {
  xs: {
    track: { h: '0.25rem' },
  },
  sm: {
    track: { h: '0.5rem' },
  },
  md: {
    track: { h: '0.75rem' },
  },
  lg: {
    track: { h: '1rem' },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'blue',
};

export default {
  parts,
  sizes,
  baseStyle,
  defaultProps,
};
