import { AcgReferenceData, MemberSearchProps } from './../../../../services/acg/acg.types';
import { usStatesandProvinces } from './usStatesAndProvinces';

interface Filters {
  data: Partial<MemberSearchProps>;
  refData?: AcgReferenceData;
  filter?: string;
  setValue?: (name: string, value: unknown, config?: Object) => void;
}

export const transformFilterData = ({ data, refData }: Filters) => {
  const filters = [];
  for (const key in data) {
    if (typeof data[key] === 'string' && data[key].length) {
      filters.push(data[key]);
    } else if (data[key]) {
      data[key].forEach((filter: string) => {
        if (key === 'country' || key === 'state') {
          return filters.push(getLongCode(filter, key, refData));
        }
        if (key === 'primaryChapter') {
          return filters.push(getLongCode(filter, key, refData));
        }
        return filters.push(filter);
      });
    }
  }
  return filters;
};

export const newSearchProps = ({ data, filter, refData, setValue }: Filters) => {
  const result = { ...data };
  for (const key in result) {
    if (key === 'state' || key === 'country') {
      const shortCode = getShortCode(filter, key, refData);
      const leftOverStateOrCountry = result[key]?.filter((el: string) => el !== shortCode);
      result[key] = leftOverStateOrCountry;
      setValue?.(key, leftOverStateOrCountry);
    }
    if (key === 'primaryChapter') {
      const shortCode = getShortCode(filter, key, refData);
      const leftoverChapter = result[key]?.filter((el: string) => el !== shortCode);
      result[key] = leftoverChapter;
      setValue?.(key, leftoverChapter);
    }
    if (typeof result[key] === 'string' && result[key] === filter) {
      result[key] = '';
      setValue?.(key, '');
    } else if (Array.isArray(result[key])) {
      const leftOverFilters = result[key].filter((el: string) => el !== filter);
      result[key] = leftOverFilters;
      setValue?.(key, leftOverFilters);
    }
  }
  return result;
};

export const getShortCode = (
  filter?: string,
  key?: 'state' | 'country' | 'primaryChapter',
  refData?: AcgReferenceData,
) => {
  if (key === 'state') {
    const stateLongCode = usStatesandProvinces.find(s => s.recordName === filter)?.abbreviation;
    return stateLongCode;
  }
  if (key === 'country') {
    const countryLongCode = refData?.country?.find(
      (c: { recordName: string }) => c.recordName === filter,
    )?.abbreviation;
    return countryLongCode;
  }
  const chapterShortCode = refData?.chapters?.find(c => c.chapterName === filter)?.chapterId;
  return chapterShortCode;
};

export const getLongCode = (
  filter?: string,
  key?: 'state' | 'country' | 'primaryChapter',
  refData?: AcgReferenceData,
) => {
  if (key === 'state') {
    const stateShortCode = usStatesandProvinces.find(s => s.abbreviation === filter)?.recordName;
    return stateShortCode;
  }
  if (key === 'country') {
    const countryShortCode = refData?.country?.find(
      (c: { abbreviation: string }) => c.abbreviation === filter,
    )?.recordName;
    return countryShortCode;
  }
  const chapterLongCode = refData?.chapters?.find(c => c.chapterId === filter)?.chapterName;
  return chapterLongCode;
};
