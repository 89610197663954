import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import { Flex, Box } from '@companydotcom/potion';
import { TimestampedItem } from '@companydotcom/ui';

import type { TileComponentProps } from '../../types';

export interface TileScheduleTableProps extends TileComponentProps {}

export const TileScheduleTable: React.FC<TileScheduleTableProps> = props => {
  const { eventData, dataConfig } = props;
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const data = _get(eventData, dataConfig?.[0]?.dataKey as string);

    if (data) {
      setSchedule(data);
      setLoading(false);
    }
  }, [dataConfig, eventData]);

  return loading ? (
    <Box width={296} height={180}>
      <TileScheduleLoader />
    </Box>
  ) : (
    <Box width={296} height="100%" mb={3} sx={{ overflowY: 'auto' }}>
      {schedule.length ? (
        <Flex flexDirection="column" justifyContent="center" alignItems="start">
          {schedule.map(({ dateScheduled, name }, i) => {
            const date = new Date(dateScheduled * 1000);
            const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
            const month = date.getMonth() > 9 ? date.getMonth() : `0${date.getMonth()}`;
            const formattedTime = dayjs(dateScheduled * 1000).format('hh:mmA');

            return (
              <TimestampedItem
                day={day}
                month={month}
                name={name}
                formattedTime={formattedTime}
                i={i}
                listLength={schedule.length}
                key={dateScheduled}
              />
            );
          })}
        </Flex>
      ) : (
        <Box>
          <TimestampedItem
            day="DD"
            month="MM"
            name={t('tiles.scheduleTable.nothingScheduledYet')}
            formattedTime="Time"
            i={0}
            listLength={0}
          />
        </Box>
      )}
    </Box>
  );
};

export const TileScheduleLoader = () => {
  return (
    <ContentLoader height={164} width={296} viewBox="0 0 296 150" speed={2} animate>
      <rect x="0" y="0" rx="3" ry="3" width="45" height="20" />
      <rect x="53" y="0" rx="3" ry="3" width="45" height="20" />
      <rect x="134" y="0" rx="3" ry="3" width="162" height="20" />
      <rect x="0" y="45" rx="3" ry="3" width="45" height="20" />
      <rect x="53" y="45" rx="3" ry="3" width="45" height="20" />
      <rect x="134" y="45" rx="3" ry="3" width="162" height="20" />
      <rect x="0" y="90" rx="3" ry="3" width="45" height="20" />
      <rect x="53" y="90" rx="3" ry="3" width="45" height="20" />
      <rect x="134" y="90" rx="3" ry="3" width="162" height="20" />
      <rect x="0" y="135" rx="3" ry="3" width="45" height="20" />
      <rect x="53" y="135" rx="3" ry="3" width="45" height="20" />
      <rect x="134" y="135" rx="3" ry="3" width="162" height="20" />
    </ContentLoader>
  );
};
