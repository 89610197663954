const enrollmentMutation = {
  enrollmentSetValidEmail: `mutation enrollmentSetValidEmail ($input: enrollmentSetValidEmailInput!) {
    enrollmentSetValidEmail(input: $input)
  }`,
  optOutByExternalIdentifier: `mutation optOutByExternalIdentifier ($input: OptOutExternalIdInput!) {
    optOutByExternalIdentifier(input: $input)
  }`,
  recaptchaVerification: (token: string) => `mutation recaptchaVerification{ recaptchaVerification(
    token:"${token}"
    )
  }`,
  resetPasswordEmail: (
    email: string,
    source: string,
  ) => `mutation resetPasswordEmail{ resetPasswordEmail(
    email:"${email}",
    source:"${source}"
 )
}`,
  verifyOtp: `mutation verifyOtp ($userId: String!, $phoneNumber: String, $email: String, $useEmail: Boolean, $code: String) {
    verifyOtp(
      userId: $userId,
      phoneNumber: $phoneNumber,
      email: $email,
      useEmail: $useEmail,
      code: $code
    )
  }`,
};

export default enrollmentMutation;
