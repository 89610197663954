import React from 'react';
import { Button, Flex } from '@companydotcom/potion';

import { VideoFeed } from '../components';
import { AcgRssFeedResponse } from '@companydotcom/types';

export interface TileAcgMediaGrowthTvProps extends AcgRssFeedResponse {}

export const TileAcgMediaGrowthTv: React.FC<TileAcgMediaGrowthTvProps> = props => {
  return (
    <Flex
      flexDirection="column"
      width="full"
      alignItems="center"
      mt={1}
      flexGrow={1}
      justifyContent="space-between"
    >
      <VideoFeed {...props} />
      <Button
        variant="tile"
        onClick={() => {
          window.open('https://middlemarketgrowth.org/category/news-trends/growthtv/');
        }}
        width="fit-content"
        size="md"
        type="submit"
      >
        GO TO GROWTHTV
      </Button>
    </Flex>
  );
};
