import { emptySplitApi } from '../../store/empty-split-api';
import { UPDATE_NOTIFICATION } from './graphql/notification-mutation';
import { GET_NOTIFICATION_LIST, FETCH_LARGE_PAYLOAD } from './graphql/notification-query';

export const NotificationApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    updateNotificationHandled: builder.mutation<
      any,
      { notificationId: string; secondsToExpiration: number }
    >({
      invalidatesTags: ['Notifications'],
      query: ({ notificationId, secondsToExpiration }) => ({
        method: 'mutation',
        document: UPDATE_NOTIFICATION,
        variables: {
          id: notificationId,
          handled: 1,
          dateExpires: Math.floor(new Date().getTime() / 1000) + secondsToExpiration,
        },
      }),
      transformResponse: (rawResult: { data: { updateNotificationHandled: any } }) =>
        rawResult.data.updateNotificationHandled,
    }),
    getNotificationList: builder.query<any, { userId: string }>({
      providesTags: ['Notifications'],
      query: ({ userId }) => ({
        method: 'query',
        document: GET_NOTIFICATION_LIST,
        variables: {
          userId,
        },
      }),
      transformResponse: (rawResult: { data: { getNotificationList: any } }) =>
        rawResult.data.getNotificationList,
    }),
    fetchLargePayload: builder.query<any, { respPayloadCacheId: string }>({
      query: ({ respPayloadCacheId }) => ({
        method: 'query',
        document: FETCH_LARGE_PAYLOAD,
        variables: {
          respPayloadCacheId,
        },
      }),
      transformResponse: (rawResult: { data: { fetchLargePayload: any } }) =>
        rawResult.data.fetchLargePayload,
    }),
  }),
});

export const {
  useUpdateNotificationHandledMutation,
  useLazyGetNotificationListQuery,
  useLazyFetchLargePayloadQuery,
} = NotificationApi;
